export const STRINGS = {
  CSV_FILES: "Browse your files here",
  UPLOAD_CSV: " Upload your CSV file here and we will take care of the rest",
  UPGRADE_CSV: "Looking to upgrade your Inventory?",
  UPLOAD_CSV_ENRICHMENT: "Upload CSV",
  CSV_ENRICHMENT: "CSV Enrichment",
  csvEnrichment: "csv enrichment",
  PROCESS_AGAIN: "Process Again",
  NO_RECORD_FOUND: "No Record Found",
  LIST: "List",
  COMPANY: "company",
  SELECT_CSV:
    "Select a CSV of either people or companies to enrich it with information ",
  FILES: "Files",
  ENRICH_PEOPLE: "Enrich People",
  ENRICH_COMPANY: "Enrich Companies",
  JOB_TITLE: "Job Title :",
  CSV_UPLOAD_SUCCESS: "Csv File Uploaded Successfully",
  DISABLED: "disabled",
  DEFAULT: "default",
  LIMIT_EXCEED:
    "You have exceeded your credit limit. Please contact ReachIQ Support",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  ADMIN_USER: "admin-user",
  STATUS: "Status",
  FILTER: "Filter :",
  TOTAL_VALID: "Total Valid",
  TOTAL_RECORDS: "Total Records",
  USER_NAME: "User Name",
  NONE: "none",
  NORMAL: "normal",
  FILE_SIZE: "File size should be less than 1gb",
  STRING: "string",
  TEXT_CSV: "text/csv",
  INVALID_TYPE: "Invalid File. Please select Csv files only",
  INTERRUPTED: "Interrupted",
  EXPORT: "Export CSV",
  CANCEL: "Cancel",
  SUBMIT: "Submit",
  FILE_UPLOAD:
    "File Uploaded Successfully, Map the column Headers in your file.",
  IMPORT: "Import",
  ALERT_mESAGE: "Alert Message",
  PLEASE_REACH: " Please reach out to our support team to add more",
  DESC: "desc",
  CONTACT: "Contact",
  ASC: "asc",
  PROCESSING: "processing",
  COMPLETED: "completed",
  UPDATE_ONLY: "update_only",
  PEOPLE: "people",
  FILL_EMPTY: "false",
  FOO: "foo",
  MAP_ATLEAST: "Please select at least one property.",
  LIMIT: "limit exceed.",
  UPLOAD_FILE: "upload_file",
  CREATE_AND_UPDATE: "create_and_update",
  MATCHED: "Select",
  ARE_YOU_SURE: "Are you sure you want to upload this file?  ",
  REACHIQ_PROPERTY: "ReachIQ Property",
  COLUMN_HEADER: "Column Header",
  MAP_THE_COLUMN: " Map the column headers in your file to ReachIQ Property",
  SELECT: "select",
  SELECT_CATEGORY: "Select Category",
  IMPORT_INVENTORY: "Import your inventory",
  YOU_CAN_UPLOAD: "You can upload CSV or XLS files to import your inventory",
  CONFIRMATION: "Confirmation",
  SEARCH: "Search File Name",
  CSV_ENRICHMENT: "CSV Enrichment",
  ENRICH_PEOPLE:"Enrich People",
  SEL_PPL:"Select a CSV of People and enrich their information.",
  ENRICH_COMPANY:"Enrich Companies",
  SEL_CMP:"Select a CSV of Companies and enrich their information.",
  
};
