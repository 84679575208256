import { Menu, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CircularLoader } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { colorsArray, DYNAMIC_TAGS, EDITOR_FONT_FAMILY_OPTIONS, EDITOR_FONT_SIZE_OPTIONS, EDITOR_IMAGE_LIMIT, EMITTER_ERROR, EMITTER_SUCCESS } from "../../utils/constants";
import {
  ImageIcon,
} from "../Icon/MuiIcons";
import { styles } from "./styles";
import {
  editAction,
  getMeetingLinkAction,
} from "../../redux/actions/editorAction";
import { getErrorMessage } from "../../utils/helpers";
import { getRequest, postRequest } from "../../Apis";
import { useNavigate } from "react-router-dom";
import {
  campaignEndpoints,
  templateEndpoints,
} from "../../utils/constants/httpConstants";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { COLORS } from "../../utils/constants/styles";
import { IMAGE_UPLOADED_MESSAGE, IMAGE_UPLOAD_SIZE_WARNING, INVALID_IMAGE_TYPE, MESSAGE_REQUIRED_FIELD, UPLOADING_IMAGE } from "../../utils/constants/messages";
import CustomModal from "../Modals/CustomModal";
import { Button } from "../Buttons";
import { STRINGS } from "./strings";
import TypographyInline from "../Typography/TypographyInline";
import { BRAND, CREAMY_BROWN, ERROR, GREY } from "../../utils/constants/colors";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import InputField from "../InputFields/InputField";
import Image from "../Image/Image";
import { BLUE_TOY } from "../../utils/constants/assets";
import UiDialog from "../../screens/Marketing/components/UiDialog";
import CustomTooltip from "../Tooltip/CustomTooltip";
import IconComponent from "../Icon/IconComponent";
import { borderRadius } from "../../utils/constants/UI/uiConstants";
import Dropdown from "../Dropdown/Dropdown";
import EmojiPicker from 'emoji-picker-react';

function HtmlEditor({
  currentFocus,
  setSubject,
  subject,
  setCurrentFocus = () => { },
  previosData,
  isAddTagNotVisible,
  isMeetingTagNotVisible,
  isAITagNotVisible,
  aiTypeValue,
  editorHeight,
  isUnsubscirbeTagVisible,
  onInput,
  isFocusNotRequired = false,
  getData,
  id = "editor",
  setIsTagPresent = () => { },
  onAttachmentChange = null,
  attachments = [],
  handleDeleteAttachment = () => { },
  caretPosition = 0
}) {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allMettingLinks = useSelector((state) => state.getMeetingLink);
  const editorDataSelector = useSelector((state) => state.editorData);
  const DYNAMIC_FIELDS = [...DYNAMIC_TAGS];
  const initialValuesArr = DYNAMIC_FIELDS?.map((item) => {
    return item?.id;
  });
  const initialValues = initialValuesArr.reduce(
    (acc, curr) => ((acc[curr] = ""), acc),
    {}
  );


  const [showTags, setShowTags] = useState(false);
  const [selection, setSelection] = useState(null);
  const [showColorModal, setShowColorModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [colorAnchorEl, setColorAnchorEl] = React.useState(null);
  const [emojiAnchor, setEmojiAnchor] = useState(null);
  const [search, setSearch] = useState("");
  const [tabChange, setTabChange] = useState(STRINGS.PEOPLE);
  const [isEdit, setIsEdit] = useState("");
  const [tagValues, setTagValues] = useState(initialValues || []);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [linkDisplayText, setLinkDisplayText] = useState("");
  const [webAddressText, setWebAddressText] = useState("");
  const [meetingLinkText, setMeetingLinkText] = useState("");
  const [isLinkInserted, setIsLinkInserted] = useState(false);
  const [showMeetingLinkMenu, setShowMeetingLinkMenu] = useState(false);
  const [hoverId, setHoverId] = useState("");
  const [openAi, setOpenAi] = useState(false);
  const [editorActionsEnable, setEditorActionsEnable] = useState(false);
  const [isMeetingLinkModal, setIsMeetingLinkModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [editorMode, setEditorMode] = useState(editorDataSelector?.view || "text");
  const [fontSizeIdState, setFontSizeIdState] = useState([EDITOR_FONT_SIZE_OPTIONS[0]?.id]);
  const [fontSizeValueState, setFontSizeValueState] = useState([EDITOR_FONT_SIZE_OPTIONS[0]?.label]);
  const [fontFamilyIdState, setFontFamilyIdState] = useState([EDITOR_FONT_FAMILY_OPTIONS[0]?.id]);
  const [fontFamilyValueState, setFontFamilyValueState] = useState([EDITOR_FONT_FAMILY_OPTIONS[0]?.label]);

  const getDefaultValues = () => {
    // GET Request default values.
    (async () => {
      try {
        const response = await getRequest(
          navigate,
          campaignEndpoints.getDefaultValues
        );
        setTagValues(response?.data?.data[0] || initialValues);
      } catch (error) {
      } finally {
      }
    })();
  };

  // to set focus in editor
  const setFocus = () => {
    const el = document.getElementById(id);
    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
    el.focus();
  };

  // get base64 of image
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // to save the selection of editor
  function saveSelection() {
    let sel;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        return sel.getRangeAt(0);
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange();
    }
    return null;
  }

  // to restore the selection of editor
  function restoreSelection(range) {
    let sel;
    if (range) {
      if (window.getSelection) {
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (document.selection && range.select) {
        range.select();
      }
    }
  }

  // to operate all the operation of button (bold, italic, underline, etc..)
  const buttonOperation = (command) => {
    document.execCommand(command);
  };

  // to operate all the operation of dropdown (font size, font family, etc..)
  const valueOperation = (opType, data) => {
    restoreSelection(selection);
    let optValue = data.id[0]
    document.execCommand(opType, false, optValue);
  };

  // to insert the image into editor
  const insertImage = async (e) => {
    const file = e?.target?.files?.[0];
    if (!file) {
      return;
    }
    if (file?.size >= EDITOR_IMAGE_LIMIT) {
      return EventEmitter.emit(
        EMITTER_ERROR,
        IMAGE_UPLOAD_SIZE_WARNING
      );
    }
    if (file && file?.type?.split("/")?.[0] !== STRINGS.IMAGE_SMALL) {
      return EventEmitter.emit(EMITTER_ERROR, INVALID_IMAGE_TYPE);
    }
    try {
      const imageUrl = await getBase64(file);

      //  here upload the image to s3, and replace that image with s3 URL.
      const file_payload = imageUrl?.split("base64,")?.[1];
      const payload = { images: [file_payload] };
      EventEmitter.emit(EMITTER_SUCCESS, UPLOADING_IMAGE);
      const response = await postRequest(
        navigate,
        templateEndpoints.uploadFile,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, IMAGE_UPLOADED_MESSAGE);

      const imageS3Url = response?.data?.data?.[0];
      document.getElementById(id).focus();
      document.execCommand(
        STRINGS.INSERT_HTML_CAMEL_CASE,
        false,
        `<img style="max-height:150px;" src='${imageS3Url}' />`
      );
      e.target.value = "";
    } catch (error) {
      console.log("error----", error);
    }
  };

  //  to open link modal
  const insertLinkModal = () => {
    const selection = saveSelection();
    setSelection(selection);
    setWebAddressText(selection.toString());
    setIsMeetingLinkModal(false);
    setShowLinkModal(true);


    // removing the selected part from the editor
    const selectionInEditor = window.getSelection();
    if (selectionInEditor.rangeCount > 0) {
      var range = selectionInEditor.getRangeAt(0);
      var selectedText = range.toString();

      if (selectedText !== '') {
        range.deleteContents();
      }
    }
  };

  //  to insert link in editor from insertLink modal
  const insertLink = () => {
    if (webAddressText !== "") {
      restoreSelection(selection);


      let node = document.createElement("a");
      node.setAttribute("href", webAddressText);
      node.setAttribute("target", "_blank");
      node.innerHTML = `${linkDisplayText || webAddressText}`;
      node.style = "cursor:pointer;font-family: inherit;";
      window.getSelection().getRangeAt(0).insertNode(node);


      let ele = document.querySelectorAll("a");
      ele.forEach((element) => {
        element.contentEditable = false;
      });
      setShowLinkModal(false);
      setIsLinkInserted(false);

      setWebAddressText("");
      setLinkDisplayText("");
    } else {
      setIsLinkInserted(true);
    }
  };

  //  to close link modal
  const cancelHandler = () => {
    setLinkDisplayText("");
    setWebAddressText("");
    setShowLinkModal(false);
    setIsLinkInserted(false);
  };

  // to insert meeting link in editor
  const insertMeetingLinkToEditor = () => {
    if (meetingLinkText !== "") {
      restoreSelection(selection);
      let node = document.createElement("a");
      node.setAttribute("href", meetingLinkText);
      node.setAttribute("target", "_blank");
      node.setAttribute("data-id", "{{meeting_link}}");
      node.setAttribute("id", meetingLinkText);
      node.innerHTML = `${linkDisplayText || meetingLinkText}`;
      node.style = "cursor:pointer;font-family: inherit;";
      window.getSelection().getRangeAt(0).insertNode(node);

      let ele = document.querySelectorAll("a");
      ele.forEach((element) => {
        element.contentEditable = false;
      });
      setMeetingLinkText("");
      setLinkDisplayText("");
      setShowLinkModal(false);
      setAnchorEl(null);
      setShowMeetingLinkMenu(false);
    }
  };

  // insert link modal UI
  const linkModal = (
    <div className={classes.linkModalOuterContainer}>
      <div className={classes.linkModalInnerContainer}>
        {/* Body of Container */}
        <div className={classes.bodyContainer}>
          <div className={classes.bodySubContainer}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.TEXT}
            />
            <InputField
              size={"sm36"}
              type="text"
              placeholder={STRINGS.ENTER_TEXT_TO_DISPLAY}
              value={linkDisplayText}
              onChange={(event) => setLinkDisplayText(event.target.value)}
            />
          </div>
          <div className={classes.bodySubContainer}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={isMeetingLinkModal ? STRINGS.MEETING_LINK : STRINGS.WEB_ADDRESS}
              isRequired
            />
            <div>
              <InputField size={"sm36"} value={isMeetingLinkModal ? meetingLinkText : webAddressText} onChange={(event) => setWebAddressText(event.target.value)} placeholder={STRINGS.MEETING_LINK_PLACEHOLDER} variant={isLinkInserted ? "error" : "default"} errormessage={MESSAGE_REQUIRED_FIELD} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // to open the dynamic tag menu.
  const insertTag = (event) => {
    getDefaultValues();
    if (currentFocus === STRINGS.SUBJECT_SMALL) {
      setShowTags(true);
      setAnchorEl(event.currentTarget);
    } else {
      const selection1 = saveSelection();
      setSelection(selection1);
      setFocus();
      setAnchorEl(event.currentTarget);
      setShowTags(true);
    }
  };

  // to insert the unsubscribe tag.
  const insertUnsubscribeTag = () => {
    let tag = { label: STRINGS.UNSUBS_TAG, id: STRINGS.UNSUBS_TAG_SMALL };
    insertDynamicTag(tag);
    setIsTagPresent(true);
  };

  // function used in insert dynamic tags
  const insertTagAndCheckFocusNode = (focusNode, node) => {
    if (focusNode?.nodeName === "SPAN") {
      if (focusNode.getAttribute("data-dt") === "dynamicTag") {
        let focusNode = window.getSelection().focusNode;
        let nodeInnerText = focusNode?.innerText;
        let nodeAttrText = focusNode?.getAttribute("data-id");
        let newValue = nodeInnerText.replace(nodeAttrText, "");
        if (newValue.length) {
          focusNode.innerText = nodeAttrText;
          let newNode = document.createElement("span");
          newNode.innerText = `\n`;
          focusNode.parentNode.insertBefore(newNode, focusNode?.nextSibling);
          focusNode.parentNode.insertBefore(node, newNode?.nextSibling);
          setCaretPosition(node);
        }
      }
    } else {
      window.getSelection().getRangeAt(0).insertNode(node);
      setCaretPosition(node);
    }
  };

  const checkIfFocusOnTagAndInsertNewTag = (focusNode, node) => {
    if (focusNode?.parentNode.getAttribute("data-dt") === "dynamicTag") {
      if (
        focusNode?.parentNode.parentNode === document.getElementById(id) &&
        getCaretPosition() === 0
      ) {
        focusNode.parentNode.insertAdjacentElement("beforebegin", node);
        setCaretPosition(node);
      } else {
        focusNode.parentNode.insertAdjacentElement("afterend", node);
        setCaretPosition(node);
      }
    } else {
      window.getSelection().getRangeAt(0).insertNode(node);
      setCaretPosition(node);
    }
  };

  // to insert all the tag into editor
  const insertDynamicTag = (tag) => {
    // creating and setting all the required values of node
    let node = document.createElement("span");
    node.setAttribute("data-dt", "dynamicTag");
    node.setAttribute("data-id", `{{${tag.id}}}`);
    node.innerText = `{{${tag.id}}}`;
    node.style = `background:${COLORS.RED_800};color:${COLORS.RED_100};width:fit-content; border-radius:4px;font-size:small;font-family:inherit;padding: 4px 8px;`;
    // creating and setting all the required values of node

    if (currentFocus === STRINGS.SUBJECT_SMALL) {
      let final_str = subject.substring(0, caretPosition) + `{{${tag.id}}}` + subject.substring(caretPosition, subject?.length)
      setSubject(final_str);
    } else {
      setShowTags(false);
      restoreSelection(selection);
      if (!selection?.toString()?.length) {
        let focusNode = window.getSelection().focusNode;
        if (focusNode.parentNode === document.getElementById(id)) {
          insertTagAndCheckFocusNode(focusNode, node);
        } else {
          checkIfFocusOnTagAndInsertNewTag(focusNode, node);
        }
      }
      // checking selection length before inserting tag. (PS :- tag should not insert if any selection present.)
    }
  };

  // to apply selected color to selected text into editor
  const handleColorSelection = (color, actionType) => {
    restoreSelection(selection);
    document.execCommand(actionType, false, color);
    setShowColorModal(false);
  };

  // to storing selection and open fontColor Picker Modal.
  const handleFontColorModal = (id, event) => {
    const selection1 = saveSelection();
    setSelection(selection1);
    setFocus();
    setShowColorModal(true);
    setColorAnchorEl(event.currentTarget);
  };

  // upload file button
  const fileUploadBtn = (
    <div className="for-center">
      <label htmlFor="file-upload" className="custom-file-upload">
        <ImageIcon className={classes.icon} />
      </label>
      <input
        id="file-upload"
        type="file"
        style={{ display: "none" }}
        onChange={insertImage}
        accept=".jpg,.png,.svg,.jpeg "
      />
    </div>
  );

  // upload attachment button
  const attachMentUploadBtn = (
    <div className="for-center">
      <label htmlFor="file-upload1" className="custom-file-upload">
        {/* <AttachFileIcon className={classes.icon} /> */}
        <IconComponent color={GREY.SECONDARY} fontSize={fontSize.DXS} iconLabel={ICON_LABELS.ATTACH_FILE} />
      </label>
      <input
        multiple={true}
        id="file-upload1"
        type="file"
        style={{ display: "none" }}
        onChange={onAttachmentChange}
      />
    </div>
  );

  // Use AI Assistant Button
  const insertUIAssistant = (
    <button className={classes.aiBtn}>
      <Image src={BLUE_TOY} alt="" className={classes.aicon} />
      <div className={classes.padSett}>{STRINGS.USE_AI}</div>
    </button>
  );

  // to store selection and open all meetings link list menu.
  const insertMeetingLink = (id, event) => {
    const selection = saveSelection();
    setLinkDisplayText(selection?.toString() || "");
    setSelection(selection);
    setFocus();
    dispatch(getMeetingLinkAction(navigate));
    setAnchorEl(event.currentTarget);
    setShowMeetingLinkMenu(true);
  };


  // insert Emoji to editor
  const insertEmojiToEditorHandler = (html) => {
    // Create a temporary div element to hold the content to be inserted
    const tempDiv = document.createElement('div');
    tempDiv.id = `${html?.unified}div`;
    tempDiv.innerHTML = `<span style="font-size=inherit" id=${html.unified}>${html?.emoji}</span>`;

    // Delete any existing selection
    selection.deleteContents();

    // Insert the content from the temporary div
    const fragment = document.createDocumentFragment();
    let node = tempDiv.firstChild;
    while (node) {
      const nextNode = node.nextSibling;
      fragment.appendChild(node);
      node = nextNode;
    }
    selection.insertNode(fragment);
    setEmojiAnchor(null);
    let nodeInserted = document.getElementById(`${html?.unified}`);
    setCaretPosition(nodeInserted);
  }

  // open emoji picker.
  function insertEmojiHandler(id, event) {
    const selection = saveSelection();
    setSelection(selection);
    setEmojiAnchor(event?.currentTarget);
  }

  // to open ai assistant modal.
  const openAiAssistant = () => {
    setOpenAi(!openAi);
  };

  // to open insert meeting link modal.
  const meetingLinkModalHandler = (meeting) => {
    setMeetingLinkText(`https://${meeting?.schedule_page_link}`);
    setShowLinkModal(true);
    setIsMeetingLinkModal(true);
  };

  // all meetings link menu ui
  const meetingLinks = (
    <div className={classes.allLinkContainer}>
      {allMettingLinks?.loading1 ? (
        <div className={classes.loaderContiner}>
          <CircularLoader />
        </div>
      ) : (
        <div>
          {allMettingLinks?.data?.length ? (
            <div>
              {allMettingLinks?.data?.map((meeting) => {
                return (
                  <div className={classes.meetingLinkText}>
                    <TypographyInline color={GREY.SECONDARY} size="sm" label={meeting?.event_title} fontWeight={fontWeight.REGULAR} onClick={() => meetingLinkModalHandler(meeting)} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <TypographyInline color={ERROR.W_500} size="sm" label={STRINGS.NO_LINKS_FOUND} fontWeight={fontWeight.REGULAR} />
            </div>
          )}
        </div>
      )}
    </div>
  );

  // to store searched value in all tags menu search field.
  const handleInputChange = (e, type) => {
    setTagValues({ ...tagValues, [type]: e.target.value });
  };

  // to api call the default values.
  const handleSave = async () => {
    setIsEdit("");
    try {
      const response = await postRequest(
        navigate,
        campaignEndpoints.saveDefaultValues,
        tagValues
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      getDefaultValues();
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || {} || error?.data?.message)
      );
    } finally {
    }
  };

  // tooltip tags menu ui.
  const tooltipTags = (
    <div className={classes.tooltipTagsContainer}>
      <div className={classes.tabMainContainer}>
        <div
          onClick={() => {
            if (tabChange !== STRINGS.PEOPLE) {
              setSearch("");
            }
            setTabChange(STRINGS.PEOPLE);
          }}
          className={classes.tabHeader}
          style={{
            paddingBottom: tabChange === STRINGS.PEOPLE ? "2px" : "0px",
            borderBottom: tabChange === STRINGS.PEOPLE ? `1px solid ${BRAND.PRIMARY}` : "",
          }}
        >
          <TypographyInline color={tabChange === STRINGS.PEOPLE ? BRAND.PRIMARY : GREY.PRIMARY} label={STRINGS.PEOPLE} size="sm" fontWeight={fontWeight.SEMIBOLD} />

        </div>
        <div
          onClick={() => {
            if (tabChange !== STRINGS.COMPANY) {
              setSearch("");
            }
            setTabChange(STRINGS.COMPANY)
          }}
          className={classes.tabHeader}
          style={{
            paddingBottom: tabChange === STRINGS.COMPANY ? "2px" : "0px",
            borderBottom: tabChange === STRINGS.COMPANY ? `1px solid ${BRAND.PRIMARY}` : "",
          }}
        >
          <TypographyInline color={tabChange === STRINGS.COMPANY ? BRAND.PRIMARY : GREY.PRIMARY} label={STRINGS.COMPANY} size="sm" fontWeight={fontWeight.SEMIBOLD} />
        </div>
      </div>
      <div className={classes.containAllTags}>
        <div className={classes.searchBar}>
          <InputField
            size="sm36"
            placeholder={`${STRINGS.SEARCH} ${tabChange} ${STRINGS.TAGS}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        {tabChange === STRINGS.PEOPLE ? (
          <>
            {DYNAMIC_FIELDS.map((tag, index) => {
              return (
                !tag?.companyField &&
                tag?.label?.toLowerCase()?.includes(search?.toLowerCase()) && (
                  <div onMouseEnter={() => setHoverId(tag.id)} onMouseLeave={() => setHoverId("")} className={classes.tagRowContainer} key={index}  >
                    <div className={classes.idValueContainer}>
                      <div className={classes.pointerClass}>
                        <TypographyInline color={GREY.SECONDARY} label={`{{${tag.id}}}`} size="sm" fontWeight={fontWeight.REGULAR} onClick={() => { insertDynamicTag(tag); }} />
                      </div>
                      {isEdit === tag.id ? (
                        <InputField size="sm36" placeholder={tagValues[tag.id]} value={tagValues?.[tag.id]} onChange={(e) => handleInputChange(e, tag?.id)} />
                      ) : (
                        <div className={classes.pointerClass}>
                          <TypographyInline color={BRAND.PRIMARY} label={tagValues[tag.id] || ""} size="sm" fontWeight={fontWeight.REGULAR} onClick={() => { insertDynamicTag(tag); }} />
                        </div>
                      )}
                    </div>
                    {isEdit === tag.id ? (
                      <div>
                        <Button size="sm36" variant="primary" label={STRINGS.SAVE} onClick={handleSave} />
                      </div>
                    ) : (
                      <div>
                        {hoverId === tag.id && (
                          <Button size="sm36" variant="text" label={tagValues[tag.id] ? STRINGS.EDIT : STRINGS.ADD_VALUE} onClick={() => { setIsEdit(`${tag.id}`); }} />
                        )}
                      </div>
                    )}
                  </div>
                )
              );
            })}
          </>
        ) : (
          <div>
            {DYNAMIC_FIELDS.map((tag, index) => {
              return (
                tag?.companyField &&
                tag?.label?.toLowerCase()?.includes(search?.toLowerCase()) && (
                  <div
                    onMouseEnter={() => setHoverId(tag.id)}
                    onMouseLeave={() => setHoverId("")}
                    className={classes.tagRowContainer}
                    key={index}
                  >
                    <div className={classes.idValueContainer}>
                      <div className={classes.pointerClass}>
                        <TypographyInline color={GREY.SECONDARY} label={`{{${tag.id}}}`} size="sm" fontWeight={fontWeight.REGULAR} onClick={() => { insertDynamicTag(tag); }} />
                      </div>
                      {isEdit === tag.id ? (
                        <InputField size="sm36" placeholder={tagValues[tag.id]} value={tagValues?.[tag.id]} onChange={(e) => handleInputChange(e, tag?.id)} />
                      ) : (
                        <div className={classes.pointerClass}>
                          <TypographyInline color={BRAND.PRIMARY} label={tagValues[tag.id] || ""} size="sm" fontWeight={fontWeight.REGULAR} onClick={() => { insertDynamicTag(tag); }} />
                        </div>
                      )}
                    </div>
                    {isEdit === tag.id ? (
                      <div>
                        <Button size="sm36" variant="primary" label={STRINGS.SAVE} onClick={handleSave} />
                      </div>
                    ) : (
                      <div>
                        {hoverId === tag.id && (
                          <Button size="sm36" variant="text" label={tagValues[tag.id] ? STRINGS.EDIT : STRINGS.ADD_VALUE} onClick={() => { setIsEdit(`${tag.id}`); }} />
                        )}
                      </div>
                    )}
                  </div>
                )
              );
            })}
          </div>
        )}
      </div>
    </div>
  );

  // color picker menu ui.
  const tooltipColors = (
    <div className={classes.containAllColors}>
      <div>
        <TypographyInline color={GREY.PRIMARY} label={STRINGS.TEXT_COLOR} size="sm" fontWeight={fontWeight.REGULAR} />
        <div className={classes.colorList}>
          {colorsArray?.map((color) => {
            return (
              <div
                onClick={() => handleColorSelection(color, STRINGS.FORE_COLOR_CAMEL_CASE)}
                style={{ background: color }}
                className={classes.eachColor}
                key={color}
              ></div>
            );
          })}
        </div>
      </div>
      <div>
        <TypographyInline color={GREY.PRIMARY} label={STRINGS.BACKGROUND_COLOR} size="sm" fontWeight={fontWeight.REGULAR} />
        <div className={classes.colorList}>
          {colorsArray?.map((color) => {
            return (
              <div
                onClick={() => handleColorSelection(color, STRINGS.HILITE_COLOR_CAMEL_CASE)}
                style={{ background: color }}
                className={classes.eachColor}
                key={color}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );

  // insert html change handler
  // const insertHtmlHandler = (command, e) => {
  //   if (selectedTags?.includes(command)) {

  //     setEditorMode("html");
  //     let ids = [...selectedTags]?.filter(item => item !== command);
  //     setSelectedTags([...ids]);
  //     let inserted_data = document?.getElementById(id)?.innerText;
  //     document.getElementById(id).innerHTML = inserted_data;

  //   } else {
  //     setEditorMode("text");
  //     setSelectedTags([...selectedTags, command]);
  //     let inserted_data = document?.getElementById(id)?.innerHTML;
  //     document.getElementById(id).innerText = inserted_data;
  //   }
  // }

  // editor actions list.

  const editorActions = [
    {
      label: STRINGS.BOLD,
      id: STRINGS.BOLD_SMALL,
      action: buttonOperation,
      icon: ICON_LABELS.FORMAT_BOLD,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.ITALIC,
      id: STRINGS.ITALIC_SMALL,
      action: buttonOperation,
      icon: ICON_LABELS.FORMAT_ITALIC,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.UNDERLINE,
      id: STRINGS.UNDERLINE_SMALL,
      action: buttonOperation,
      icon: ICON_LABELS.FORMAT_UNDERLINED,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.STRIKE_THROUGH,
      id: STRINGS.STRIKE_THROUGH_CAMEL_CASE,
      action: buttonOperation,
      icon: ICON_LABELS.STRIKE_THROUGH_ICON,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.UNDO,
      id: STRINGS.UNDO_SMALL,
      action: buttonOperation,
      icon: ICON_LABELS.UNDO,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.REDO,
      id: STRINGS.REDO_SMALL,
      action: buttonOperation,
      icon: ICON_LABELS.REDO,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.FONT_COLOR,
      id: STRINGS.FORE_COLOR_CAMEL_CASE,
      action: handleFontColorModal,
      icon: ICON_LABELS.FORMAT_COLOR_TEXT,
      type: STRINGS.BUTTON_SMALL,
      items: [...EDITOR_FONT_SIZE_OPTIONS],
    },
    {
      label: STRINGS.ALIGN_LEFT,
      id: STRINGS.JUSTIFY_LEFT_CAMEL_CASE,
      action: buttonOperation,
      icon: ICON_LABELS.FORMAT_ALIGN_LEFT,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.ALIGN_CENTER,
      id: STRINGS.JUSTIFY_CENTER_CAMEL_CASE,
      action: buttonOperation,
      icon: ICON_LABELS.FORMAT_ALIGN_CENTER,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.ALIGN_RIGHT,
      id: STRINGS.JUSTIFY_RIGHT_CAMEL_CASE,
      action: buttonOperation,
      icon: ICON_LABELS.FORMAT_ALIGN_RIGHT,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.INSERT_IMAGE,
      id: STRINGS.IMAGE_SMALL,
      component: fileUploadBtn,
      type: STRINGS.TAG_SMALL,
    },
    {
      label: STRINGS.ATTACHMENT,
      id: STRINGS.IMAGE_SMALL,
      component: !onAttachmentChange ? null : attachMentUploadBtn,
      type: STRINGS.TAG_SMALL,
    },
    {
      label: STRINGS.INSERT_LINK,
      id: STRINGS.LINK_SMALL,
      action: insertLinkModal,
      icon: ICON_LABELS.INSERT_LINK,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.ADD_EMOJIS,
      id: STRINGS.ADD_EMOJIS_SMALL,
      action: insertEmojiHandler,
      icon: ICON_LABELS.EMOJI_EMOTIONS,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.NUMBERED_LIST,
      id: STRINGS.INSERT_ORDERED_LIST_CAMEL_CASE,
      action: buttonOperation,
      icon: ICON_LABELS.FORMAT_LIST_NUMBERED,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.BULLETED_LIST,
      id: STRINGS.INSERT_UNORDERED_LIST_CAMEL_CASE,
      action: buttonOperation,
      icon: ICON_LABELS.FORMAT_LIST_BULLETED,
      type: STRINGS.BUTTON_SMALL,
    },
    // {
    //   label: STRINGS.INSERT_HTML,
    //   id: STRINGS.INSERT_HTML_SMALL,
    //   action: insertHtmlHandler,
    //   icon: selectedTags?.includes(STRINGS.INSERT_HTML_SMALL) ? ICON_LABELS.CODE_OFF : ICON_LABELS.CODE,
    //   type: STRINGS.BUTTON_SMALL,
    // },
    {
      label: STRINGS.ADD_MEETING_LINK,
      id: STRINGS.MEETING_LINK_SMALL,
      action: insertMeetingLink,
      icon: isMeetingTagNotVisible ? "" : ICON_LABELS.VIDEO_CALL,
      type: STRINGS.BUTTON_SMALL,
    },
    {
      label: STRINGS.FONT_SIZE,
      id: STRINGS.FONT_SIZE_CAMEL_CASE,
      action: valueOperation,
      icon: ICON_LABELS.REDO,
      type: STRINGS.DROPDOWN_SMALL,
      items: [...EDITOR_FONT_SIZE_OPTIONS],
      idState: fontSizeIdState,
      setIdState: setFontSizeIdState,
      valueState: fontSizeValueState,
      setValueState: setFontSizeValueState
    },
    {
      label: STRINGS.FONT_FAMILY,
      id: STRINGS.FONT_NAME_CAMEL_CASE,
      action: valueOperation,
      icon: ICON_LABELS.REDO,
      type: STRINGS.DROPDOWN_SMALL,
      items: [...EDITOR_FONT_FAMILY_OPTIONS],
      idState: fontFamilyIdState,
      setIdState: setFontFamilyIdState,
      valueState: fontFamilyValueState,
      setValueState: setFontFamilyValueState
    },
    {
      label: STRINGS.USE_AI_ASSISTANT,
      id: STRINGS.AI_ASSISTANT_SMALL,
      action: openAiAssistant,
      component: isAITagNotVisible ? null : insertUIAssistant,
      type: STRINGS.TAG_SMALL,
    },
    {
      label: isUnsubscirbeTagVisible ? STRINGS.ADD_UNSUBSCRIBE_TAG : STRINGS.ADD_TAG,
      id: isUnsubscirbeTagVisible ? STRINGS.UNSUBS_TAG_SMALL : STRINGS.DYNAMIC_TAG_SMALL,
      action: isUnsubscirbeTagVisible ? insertUnsubscribeTag : insertTag,
      component: isUnsubscirbeTagVisible
        ? <button className={classes.addUnsubscribeTagBtn}><IconComponent color={CREAMY_BROWN.W_500} fontSize={fontSize.XL} iconLabel={ICON_LABELS.UNSUBSCRIBE} /><TypographyInline color={CREAMY_BROWN.W_500} label={STRINGS.ADD_UNSUBSCRIBE_TAG} size="sm" fontWeight={fontWeight.MEDIUM} /></button>
        : isAddTagNotVisible
          ? null
          : <button className={classes.addTagBtn}><IconComponent color={ERROR.W_500} fontSize={fontSize.XL} iconLabel={ICON_LABELS.DATA_OBJECT} /><TypographyInline color={ERROR.W_500} label={STRINGS.ADD_TAG} size="sm" fontWeight={fontWeight.MEDIUM} /></button>,
      type: STRINGS.TAG_SMALL,
    },
  ];

  // to get the caretPosition in html editor
  function getCaretPosition() {
    if (window.getSelection && window.getSelection().getRangeAt) {
      let range = window.getSelection().getRangeAt(0);
      let selectedObj = window.getSelection();
      let rangeCount = 0;
      let childNodes = selectedObj.anchorNode.parentNode.childNodes;
      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i] == selectedObj.anchorNode) {
          break;
        }
        if (childNodes[i].outerHTML)
          rangeCount += childNodes[i].outerHTML.length;
        else if (childNodes[i].nodeType == 3) {
          rangeCount += childNodes[i].textContent.length;
        }
      }
      return range.startOffset + rangeCount;
    }
    return -1;
  }

  // to remove our whole tag from the html editor if we are pressing backspace to our tag.
  const removeNodeHandler = (event) => {
    if (event?.keyCode === 8) {
      let focusNode = window.getSelection().focusNode.parentNode;
      if (focusNode?.innerText === focusNode?.getAttribute("data-id")) {
        event.preventDefault();
        let innerTextInitial = focusNode.parentNode.innerText;
        let substr = innerTextInitial.substr(
          0,
          focusNode?.getAttribute("data-id").length
        );
        if (substr !== focusNode?.getAttribute("data-id")) {
          let previoussibling = focusNode.previousSibling;
          focusNode.remove();
          setCaretPosition(previoussibling);
        } else if (getCaretPosition() !== 0) {
          let previoussibling = focusNode.previousSibling;
          focusNode.remove();
          setCaretPosition(previoussibling);
        } else {
          let previoussibling = focusNode.previousSibling;
          focusNode.remove();
          setCaretPosition(previoussibling);
        }
      }
    } else if (event?.keyCode === 46) {
      let nextSibling = window.getSelection().focusNode.parentNode.nextSibling;
      let focusNode;
      if (
        window.getSelection().focusNode.parentNode.getAttribute("data-dt") ===
        "dynamicTag" &&
        nextSibling &&
        nextSibling.getAttribute("data-dt") === "dynamicTag"
      ) {
        focusNode = nextSibling;
      } else {
        focusNode = window.getSelection().focusNode.parentNode;
      }
      if (focusNode?.innerText === focusNode?.getAttribute("data-id")) {
        let innerTextInitial = focusNode.parentNode.innerText;
        let substr = innerTextInitial.substr(
          0,
          focusNode?.getAttribute("data-id").length
        );
        event.preventDefault();
        if (substr !== focusNode?.getAttribute("data-id")) {
          let previoussibling = focusNode.previousSibling;
          focusNode.remove();
          setCaretPosition(previoussibling);
        } else if (getCaretPosition() !== 0) {
          let previoussibling = focusNode.previousSibling;
          focusNode.remove();
          setCaretPosition(previoussibling);
        } else {
          let previoussibling = focusNode.previousSibling;
          focusNode.remove();
          setCaretPosition(previoussibling);
        }
      }
    }
  };

  // to set the caret position in html editor
  const setCaretPosition = (node) => {
    let range = document.createRange();
    let sel = window.getSelection();
    range.setStart(node?.childNodes[0], node?.childNodes[0]?.length);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  };

  // this is the main helper of editor, it runs on every event of the conteneditable div.
  const actionCheckHandler = (event) => {
    // setIsDisabledActionBtn(false);
    dispatch(editAction(document.getElementById(id), editorMode));

    if (
      event?.keyCode !== 8 &&
      event?.keyCode !== 37 &&
      event?.keyCode !== 38 &&
      event?.keyCode !== 39 &&
      event?.keyCode !== 40
    ) {
      let focusNode = window.getSelection()?.focusNode?.parentNode;
      if (focusNode?.getAttribute("data-dt") === "dynamicTag") {
        let nodeInnerText = focusNode?.innerText;
        let nodeAttrText = focusNode?.getAttribute("data-id");
        let nodeAttrLength = nodeAttrText?.length;
        let nodeInnerTxtSubStrng = nodeInnerText.substring(0, nodeAttrLength);

        let reverseInnerText = nodeInnerText.split("").reverse().join("");
        let reverseAttrText = nodeAttrText.split("").reverse().join("");
        let reverseStr = reverseInnerText.substring(0, reverseAttrText.length);

        if (nodeInnerTxtSubStrng === nodeAttrText) {
          let newValue = nodeInnerText.replace(nodeAttrText, "");
          if (newValue.length) {
            focusNode.innerText = nodeAttrText;
            let newNode = document.createElement("span");
            newNode.innerText = newValue;
            focusNode.parentNode.insertBefore(newNode, focusNode?.nextSibling);
            setFocus();
          }
        } else if (reverseStr === reverseAttrText) {
          let newValue = reverseInnerText
            .replace(reverseAttrText, "")
            .split("")
            .reverse()
            .join("");
          if (newValue.length) {
            focusNode.innerText = nodeAttrText;
            let newNode = document.createElement("span");
            newNode.innerText = newValue;
            window
              .getSelection()
              .focusNode.insertAdjacentElement("beforebegin", newNode);
            setCaretPosition(newNode);
          }
        }
      }
    } else if (event?.keyCode === 8) {
      event.preventDefault();
    }

    // Making all anchor tag contenteditable false.
    let ele = document.querySelectorAll("a");
    ele.forEach((element) => {
      element.contentEditable = false;
      element.target = "_blank";
    });

    // Checking dynamic tag removing and adding tag color
    let focusNode = window.getSelection()?.focusNode?.parentNode;

    if (focusNode?.nodeName === "SPAN") {
      let focusNodeText = focusNode?.textContent;
      let focusNodeDataId = focusNode?.getAttribute("data-id");
      if (focusNodeDataId) {
        if (focusNodeText !== focusNodeDataId) {
          focusNode.style.color = "black";
          focusNode.style.backgroundColor = "transparent";
        } else {
          focusNode.style.color = "rgb(255, 72, 72)";
          focusNode.style.backgroundColor = "rgb(255, 233, 233)";
        }
      }
    } else {
      let focusNode = undefined;
      let focusElement = window.getSelection()?.focusNode?.parentNode;
      while ((focusElement = focusElement?.parentNode)) {
        if (focusElement?.tagName === "SPAN") {
          focusNode = focusElement;
        }
      }
      let focusNodeText = focusNode?.textContent;
      let focusNodeDataId = focusNode?.getAttribute("data-id");
      if (focusNodeDataId) {
        if (focusNodeText !== focusNodeDataId) {
          window.getSelection().focusNode.parentNode.textContent = `${focusNodeDataId}`;
          focusNode.insertAdjacentHTML("afterend", "&nbsp;");
        }
      }
    }

    // Loop for styling in orderedList, unorderedList, fontFamily and fontName.
    editorActions.forEach((action) => {
      let state = document.queryCommandState(action.id);
      let ele = document.querySelector(`#${action.id}`);
      state
        ? ele?.classList["add"]("editorSelected")
        : ele?.classList["remove"]("editorSelected");
      if (
        action.id === "insertOrderedList" &&
        document.queryCommandState("insertOrderedList")
      ) {
        let ele = document.getElementById(id).querySelectorAll("ol");
        ele.forEach((data) => {
          data.style.paddingLeft = "40px";
        });
      } else if (
        action.id === "insertUnorderedList" &&
        document.queryCommandState("insertUnorderedList")
      ) {
        let ele = document.getElementById(id).querySelectorAll("ul");
        ele.forEach((data) => {
          data.style.paddingLeft = "40px";
        });
      } else if (action.id === "fontName") {
        let ele = document.getElementById(id)?.querySelector("font");
        let currentFont = document.queryCommandValue("fontName");
        if (ele) {
          let currentFontId = currentFont?.replace(/["']/g, "");
          let currentFontLabel = EDITOR_FONT_FAMILY_OPTIONS?.filter(item => item.id?.toLowerCase() === currentFontId?.toLowerCase())?.[0]?.label;

          setFontFamilyIdState([currentFontId]);
          setFontFamilyValueState([currentFontLabel]);
          // document.getElementById("fontName").value = currentFont?.replace(/["']/g, "");
        } else {
          let currentFontId = "Arial";
          let currentFontLabel = EDITOR_FONT_FAMILY_OPTIONS?.[2]?.label;
          setFontFamilyIdState([currentFontId]);
          setFontFamilyValueState([currentFontLabel]);
        }
      } else if (action.id === "fontSize") {
        let ele = document.getElementById(id).querySelector("font");
        let currentFont = document.queryCommandValue("fontSize");
        if (ele) {
          let currentFontId = currentFont;
          let currentFontLabel = EDITOR_FONT_SIZE_OPTIONS?.filter(item => item?.id === currentFontId)?.[0]?.label;
          setFontSizeIdState([currentFontId]);
          setFontSizeValueState([currentFontLabel]);
        } else {
          let currentFontObj = EDITOR_FONT_SIZE_OPTIONS?.filter(item => item?.id === currentFont)?.[0];
          let currentFontId = currentFontObj?.id || EDITOR_FONT_SIZE_OPTIONS?.[0]?.id;
          let currentFontLabel = currentFontObj?.label || EDITOR_FONT_SIZE_OPTIONS?.[0]?.label;
          setFontSizeIdState([currentFontId]);
          setFontSizeValueState([currentFontLabel]);
        }
      }
    });
  };

  // to store typed data into reducer.
  const inputHandler = () => {
    dispatch(editAction(document.getElementById(id), editorMode));
  };

  // insert link modal header
  const modalHeaderData = {
    heading: {
      id: STRINGS.INSERT_LINK,
      label: STRINGS.INSERT_LINK,
    },
  };

  // insert link modal footer
  const modalFooterRightData = (
    <div className={classes.footerContainer}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandler}
      />
      <Button
        label={STRINGS.INSERT}
        variant={"primary"}
        size={"sm36"}
        onClick={isMeetingLinkModal ? insertMeetingLinkToEditor : insertLink}
      />
    </div>
  );

  // insert link modal footer
  const modalFooterLeftData = <div></div>;

  //Initially get focus into editor.
  useEffect(() => {
    !isFocusNotRequired && document.getElementById(id).focus();
    if (previosData) {
      previosData.includes("{{unsubscribe_tag}}")
        ? setIsTagPresent(true)
        : setIsTagPresent(false);
      document.getElementById(id).innerHTML = previosData;
      document.getElementById(id).focus();
    }

    actionCheckHandler();
    return () => {
      dispatch(editAction(""));
    };
  }, [previosData]);

  //set current focus to subject and set state of editor action to make them enable.
  useEffect(() => {
    if (currentFocus === STRINGS.SUBJECT_SMALL) {
      setEditorActionsEnable(true)
    }
  }, [currentFocus]);

  return (
    <>
      <div
        onFocus={() => {
          setEditorActionsEnable(true);
        }}
        onBlur={() => {
          if (currentFocus !== "subject") setEditorActionsEnable(false);
        }}
      >
        {/* Insert Link Modal */}
        <CustomModal
          close={cancelHandler}
          open={showLinkModal}
          header={modalHeaderData}
          children={linkModal}
          footer={{
            left: modalFooterLeftData,
            right: modalFooterRightData,
          }}
        />

        {/* Meetings Link Menu Component */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={showMeetingLinkMenu}
          onClose={(e) => setShowMeetingLinkMenu(false)}
          PaperProps={{ sx: { borderRadius: ` ${borderRadius.primary}!important` } }}
        >
          {meetingLinks}
        </Menu>

        {/* Dynamic Tags Menu Component */}
        <Menu
          id="basic-menu"
          key="dynamicTagMenu"
          anchorEl={anchorEl}
          open={showTags}
          onClose={(e) => setShowTags(false)}
        >
          {tooltipTags}
        </Menu>

        {/* Color Picker Tags Menu Component */}
        <Menu
          id="basic-menu"
          anchorEl={colorAnchorEl}
          open={showColorModal}
          onClose={(e) => setShowColorModal(false)}
        >
          {tooltipColors}
        </Menu>

        {/* Emoji Picker */}
        <Menu
          id="basic-menu"
          anchorEl={emojiAnchor}
          open={Boolean(emojiAnchor)}
          onClose={(e) => setEmojiAnchor(null)}>
          <EmojiPicker onEmojiClick={(data) => insertEmojiToEditorHandler(data)} lazyLoadEmojis={true} className={classes.emojiPicker} />
        </Menu>

        {/* Editor Box here*/}
        <div
          style={{ height: editorHeight || "15.625vw" }}
          contentEditable={true}
          onInput={onInput || inputHandler}
          onKeyUp={(e) => actionCheckHandler(e)}
          onKeyDown={(e) => {
            removeNodeHandler(e);
          }}
          onMouseUp={() => actionCheckHandler()}
          onClick={() => actionCheckHandler()}
          onFocus={() => {
            if (setCurrentFocus) setCurrentFocus("");
          }}
          onBlur={() => {
            setCurrentFocus("");
          }}
          className={classes.editor}
          id={id}
        />
        {/* Editor Actions list */}
        <div
          className={`${classes.containActions} ${!editorActionsEnable ? classes.actionsPointerEvents : ""
            }`}
        >
          {editorActions?.map((action, index) => {
            return action.type === "button" && action?.icon ? (
              <div key={action.id} className={classes.containEachAction}>
                <Tooltip title={action.label}>
                  <button
                    id={action?.id}
                    onClick={(e) => {
                      action.action(action.id, e);
                      actionCheckHandler();
                    }}
                    className={classes.editButton}
                  >
                    <IconComponent color={GREY.SECONDARY} fontSize={fontSize.DXS} iconLabel={action.icon} />
                  </button>
                </Tooltip>
              </div>
            ) : action?.type === "tag" && action.component ? (
              <div key={action.id} className={classes.containEachAction}>
                <Tooltip title={action.label}>
                  <div className={`${classes.tagEditButton} ${selectedTags?.includes(action.id) ? "editorSelected" : ""}`} >
                    <button
                      id="boldData"
                      style={{ padding: "0px" }}
                      className={classes.tagEditButton}
                      onClick={(e) => action.action(e, action)}
                    >
                      {action.component}
                    </button>
                  </div>
                </Tooltip>
              </div>
            ) : (
              action?.type === "dropdown" && <div key={action.id} className={`${classes.containEachAction} ${classes.dropdownHeightContainer}`}>
                <Tooltip title={action.label}>
                  <Dropdown size="xs34" paperWidth={action?.id === "fontName" ? "fit-content" : ""} onChange={(data) => { action.action(action?.id, data); }} dropdownId={action.id} items={action.items} multiple={false} handleClick={() => {
                    const selection1 = saveSelection();
                    setSelection(selection1);
                  }} idState={action.idState} setIdState={action.setIdState} valueState={action.valueState} setValueState={action.setValueState} />
                </Tooltip>
              </div>
            );
          })}
        </div>

        {/* uploaded attachement ui */}
        <div className={classes.flex} >
          {attachments.length ? attachments.map((item) => {
            return <CustomTooltip title={item?.name} key={item.url}  >
              <div className={classes.attachmentContainer} >
                <IconComponent
                  color={GREY.SECONDARY}
                  fontSize={fontSize.SM}
                  iconLabel={ICON_LABELS.FILE_OPEN}
                />

                <TypographyInline
                  color={GREY.SECONDARY}
                  label={item.name?.slice(0, 30) + (item?.name?.length > 30 ? '...' : "")}
                  size={"xs"}
                  fontWeight={fontWeight.MEDIUM}
                  isNoWrap
                />
                <div className={classes.cursor} onClick={() => handleDeleteAttachment(item)} >
                  <IconComponent
                    color={GREY.SECONDARY}
                    fontSize={fontSize.SM}
                    iconLabel={ICON_LABELS.CLOSE}
                  />
                </div>
              </div>
            </CustomTooltip>

          }) : null}
        </div>

        {/* UIAssistant Modal*/}
        <UiDialog open={openAi} setOpen={setOpenAi} getData={getData} type={aiTypeValue} />
      </div>
    </>
  );
}

export default HtmlEditor;
