import { useSelector } from "react-redux";
import { getRequest, postRequest } from "../../Apis";
import {
  crmEndpoints,
  dealsEndpoints,
} from "../../utils/constants/httpConstants";
import {
  dataReferenceMapping,
  decompressString,
  filterColumnQueryKeys,
  filterDynamicQuery,
  getSelectedColumns,
} from "../../utils/helpers";
import {
  DEALS_TABLE_FAIL,
  DEALS_TABLE_REQUEST,
  DEALS_TABLE_SUCCESS,
  PEOPLE_BASIC_DEAL_DETAILS_FAIL,
  PEOPLE_BASIC_DEAL_DETAILS_REQUEST,
  PEOPLE_BASIC_DEAL_DETAILS_SUCCESS,
} from "../constants/dealsConstants";
import { paginationDataAction } from "./customTableActions";
import { applyUpdateColumn, dynamicUpdatedColumn } from "./tableActions";

// action for getting contact list data of crm.
export const getDealsList =
  (sentQuery, navigate, pipelineDropdownId = {}, isLoading = true, disableCardViewLoading = false, allDealsColumn = []) =>
    async (dispatch, getState) => {
      try {
        const table_columns = getSelectedColumns(
          getState().dynamicColumnUpdate?.columns || []
        );
        // got the table columns here, will send them from here.
        const getPropertiesListSelector = getState()?.getPropertiesList?.data;
        // storing all properties into below variable.
        let propertiesPayload = localStorage.getItem("properties")
          ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))
            ?.properties_key_value
          : getPropertiesListSelector?.properties_key_value;

        // updating custom table query and checked row.
        dispatch(
          paginationDataAction({
            checkedRow: [],
            query: {
              ...getState()?.getPaginationQuery.data?.query,
            },
          })
        );
        const filteredQuery = filterDynamicQuery(
          sentQuery?.query,
          propertiesPayload
        );
        // Add columns with respective filter.
        const filteredQueryKeys = filterColumnQueryKeys(filteredQuery);
        for (let i in filteredQueryKeys) {
          if (!table_columns.includes(filteredQueryKeys[i])) {
            table_columns.push(filteredQueryKeys[i]);
          }
        }

        // dispatch columns
        const columns_clone = allDealsColumn?.length ? [...allDealsColumn] : [...getState().dynamicColumnUpdate?.columns];
        for (let i in columns_clone) {
          if (table_columns.includes(columns_clone?.[i]?.["id"])) {
            columns_clone[i].isChecked = true;
          }
        }
        dispatch(dynamicUpdatedColumn(columns_clone));
        //pipelineDropdownId - pipelineId filter in get Deals
        let query = {
          table_columns,
          ...filteredQuery,
          ...pipelineDropdownId,
        };

        try {
          if (isLoading) dispatch({ type: DEALS_TABLE_REQUEST });
          const response = await postRequest(
            navigate,
            dealsEndpoints.getDeals,
            query
          );

          const properties_reference_key_value =
            getState()?.getPropertiesList?.data?.reference_key_value;
          const items = response?.data?.data?.hits || [];

          const hits = dataReferenceMapping(
            items,
            properties_reference_key_value
          );

          // storing owner names.
          let tenant_users = {};
          getState()?.tenantUsersList?.payload?.tenant_user_list?.data?.data?.forEach(ele => {
            tenant_users = {
              ...tenant_users,
              [ele._id]: `${ele?.first_name || ""} ${ele?.last_name || ""}`
            }
          });
          let records = hits?.map((item) => { return { ...item, deal_owner_name: tenant_users?.[item?.deal_owner] } })
          let structured_records = [...records];
          dispatch({
            type: DEALS_TABLE_SUCCESS,
            payload: { ...response?.data?.data, records, query: filteredQuery },
            final_loading: disableCardViewLoading ? false : items?.length ? true : false,
          });

          // api calling for pipeline stages by pipeline id.
          try {
            const URL_ID = `${dealsEndpoints.getPipelineStagesByPipelineId}?id=${pipelineDropdownId?.pipeline_id}`;
            const URL = `${dealsEndpoints.getPipelineStagesByPipelineId}`;
            const responseStages = await getRequest(
              navigate,
              Object.keys(pipelineDropdownId)?.length ? URL_ID : URL
            );

            const getPipelineStagesData = responseStages?.data?.data;

            let matchedArr = [];
            // Loop through structured_records
            for (let i = 0; i < structured_records?.length; i++) {
              const pipeline_id = structured_records[i]?.pipeline_id;
              // Check if pipeline_id exists in getPipelineStagesData
              if (getPipelineStagesData[pipeline_id]) {
                const stage_id = structured_records[i]?.stage_id;
                const pipeline = getPipelineStagesData[pipeline_id];

                // Find the corresponding stage by _id in getPipelineStagesData
                const matchingStage = pipeline?.stages?.filter(
                  (stage) => stage?._id === stage_id
                );

                if (matchingStage) {
                  // Add a new key stage_id_name to matchedArr
                  matchedArr?.push({
                    stage_id_name: matchingStage?.[0]?.name,
                    stages: pipeline?.stages,
                    ...structured_records[i],
                  });
                }
              }
            }
            if (matchedArr?.length) {
              let payload = {
                hits: [...matchedArr],
                total_records: response?.data?.data?.total_records,
                query: filteredQuery,
              }

              dispatch({
                type: DEALS_TABLE_SUCCESS,
                payload: { ...payload },
                final_loading: false
              });
            }

            // sotring stages in every condition
            let payload = {
              ...response?.data?.data,
              hits: [...matchedArr],
              total_records: response?.data?.data?.total_records,
              query: filteredQuery,
            }
            payload.stages = { ...getPipelineStagesData?.[pipelineDropdownId?.pipeline_id] }

            structured_records = [...matchedArr]

            dispatch({
              type: DEALS_TABLE_SUCCESS,
              payload: { ...payload },
              final_loading: false
            });
          } catch (error) {
            console.log("error--->>>>>> ", error);
          }

          // api call and storing associated names in listing.
          (async () => {
            try {
              const owner_ids = structured_records?.map(
                (item) => item?.associated_contact?.[0]
              );

              const updatedOwner = owner_ids?.filter(
                (element) => element !== undefined
              );

              if (updatedOwner?.length) {
                // call stats api and owner api here, do promise.allSettled here.
                const promises = [
                  postRequest(navigate, crmEndpoints.getAssociateNames, {
                    record_ids: updatedOwner,
                  }),
                ];
                const resolved_promises = await Promise.allSettled(promises);
                const associate_names = resolved_promises[0]?.value?.data?.data;
                // associated name has been set.
                structured_records = structured_records?.map((item) => {
                  return (
                    {
                      ...item,
                      associated_contact_name:
                        associate_names?.[item?.associated_contact?.[0] || []] ===
                          undefined
                          ? []
                          : associate_names?.[item?.associated_contact?.[0]],
                    } || ""
                  );
                });

                if (structured_records?.length) {
                  dispatch({
                    type: DEALS_TABLE_SUCCESS,
                    payload: {
                      hits: [...structured_records],
                      total_records: response?.data?.data?.total_records,
                      query: filteredQuery,
                    },
                    final_loading: false,
                  });
                }
              }

            } catch (error) {
              console.log(error);
            }
          })();
        } catch (err) {
          dispatch({ type: DEALS_TABLE_FAIL, payload: err });
        }
      } catch (error) {
        console.log("error------------------> ", error);
      }
    };

export const getPeopleDealDetails =
  (navigate, query = "", data = {}) =>
    async (dispatch) => {
      try {
        if (query === true) {
          dispatch({
            type: PEOPLE_BASIC_DEAL_DETAILS_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({ type: PEOPLE_BASIC_DEAL_DETAILS_REQUEST });
          const response = await getRequest(
            navigate,
            dealsEndpoints.getInitialViews + (query?.length ? query : "")
          );

          dispatch({
            type: PEOPLE_BASIC_DEAL_DETAILS_SUCCESS,
            payload: response?.data?.data,
          });
        }
      } catch (err) {
        dispatch({ type: PEOPLE_BASIC_DEAL_DETAILS_FAIL });
      }
    };
