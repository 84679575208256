import { IMPORT_GET_CUSTOM_FIELDS_FAIL, IMPORT_GET_CUSTOM_FIELDS_REQUEST, IMPORT_GET_CUSTOM_FIELDS_SUCCESS } from "../constants/importConstants";

export const getImportCustomFieldsReducer = (state = {}, action) => {

    switch (action.type) {
      case IMPORT_GET_CUSTOM_FIELDS_REQUEST:
        return { loading: true };
  
      case IMPORT_GET_CUSTOM_FIELDS_SUCCESS:
        return { loading: false, payload: action.payload , error: null };
  
      case IMPORT_GET_CUSTOM_FIELDS_FAIL:
        return { loading: false, error: action.payload, payload: null };
  
      default:
        return state;
    }
  };
  