import React from "react";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import InputField from "../../../components/InputFields/InputField";
import Typography from "../../../components/Typography/Typography";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import classes from "./PropComponent.module.css";
import { objectTypeDropdownValues } from "../../../utils/constants";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";

const CreateGroupModal = ({
  open,
  close,
  createEditGroupHandler = () => {},
  btnLoading = false,
  errorMessage = "",
  isEdit = false,
  groupName,
  setGroupName = () => {},
  objectTypeValue,
  setObjectTypeValue,
  objectTypeId,
  setObjectTypeId,
}) => {
  const modalHeaderData = {
    heading: {
      id: STRINGS.CRT_PROP_SMALL,
      label: isEdit ? STRINGS.EDIT_GROUP : STRINGS.CREATE_GROUP,
    },
  };

  const modalFooterRightData = (
    <div className={classes.createPropFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={close}
      />
      <Button
        label={isEdit ? STRINGS.UPDATE : STRINGS.CREATE}
        variant={"primary"}
        size={"sm36"}
        isLoading={btnLoading}
        onClick={() => {
          createEditGroupHandler();
        }}
      />
    </div>
  );

  return (
    <>
      {open ? (
        <CustomModal
          open={open}
          close={close}
          header={modalHeaderData}
          footer={{ right: modalFooterRightData }}
        >
          <div className={classes.groupModalBody}>
            <div className={classes.groupModalBodyLabelInput}>
              <Typography
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.GROUP_NAME}
                color={GREY.QUATINARY}
                isRequired
              />
              <InputField
                size={"sm36"}
                placeholder={STRINGS.INPUT_PLACEHOLDER}
                value={groupName}
                variant={errorMessage?.grp_name?.length ? "error" : "default"}
                errormessage={
                  errorMessage?.grp_name?.length ? errorMessage?.grp_name : ""
                }
                onChange={(event) => setGroupName(event.target.value)}
              />
            </div>

            <div className={classes.groupModalBodyLabelInput}>
              <Typography
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.OBJECT_TYPE}
                color={GREY.QUATINARY}
                isRequired
              />
              <AutoCompleteCustom
                size={"sm36"}
                LeftIcon=""
                items={objectTypeDropdownValues}
                isLoading={objectTypeDropdownValues?.length ? false : true}
                valueState={objectTypeValue}
                setValueState={setObjectTypeValue}
                idState={objectTypeId}
                setIdState={setObjectTypeId}
                paperHeight={"fit-content"}
                variant={
                  errorMessage?.object_type?.length ? "error" : "default"
                }
                errormessage={
                  errorMessage?.object_type?.length
                    ? errorMessage?.object_type
                    : ""
                }
              />
            </div>
          </div>
        </CustomModal>
      ) : null}
    </>
  );
};

export default CreateGroupModal;
