import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../Apis";
import { campaignEndpoints } from "../../utils/constants/httpConstants";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  SEQUENCE_STATS_DATA,
  STEP_TYPE_COMPONENTS,
} from "../../utils/constants";
import { STRINGS } from "./strings";
import { BRAND, GREY } from "../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import CustomHeader from "../../components/Header/CustomHeader";
import { EMAIL_MARKETING_CAMPAIGN_PATH } from "../../utils/constants/routes";
import EventEmitter from "../../utils/emitter/EventEmitter";
import {
  Logger,
  addTagStyleInHtml,
  compressString,
  decompressString,
  getErrorMessage,
  processStatsKeyValuePairs,
  removeTagStyleFromHtml,
} from "../../utils/helpers";
import StatsSkeleton from "./components/common/StatsSkeleton";
import TypographyInline from "../../components/Typography/TypographyInline";
import StepsSkeleton from "./components/common/StepsSkeleton";
import EachStepLayout from "./components/common/EachStepLayout";
import classes from "./marketing.module.css";
import CustomModal from "../../components/Modals/CustomModal";
import { Button } from "../../components/Buttons";
import InputField from "../../components/InputFields/InputField";
import { HtmlEditor } from "../../components";
import { nameValidation } from "../../utils/validations";
import { putRequest } from "../../Apis/apiWrapper";
const CampaignOverviewScreen = () => {
  const CURRENT_TAB_ID = STRINGS.CAMPAIGN_OVERVIEW_ID;
  const navigate = useNavigate();
  const params = useParams();
  const ids = params?.campaignId?.split("&");
  const campaignId = ids[0];
  const sequenceId = ids[1];
  const [campaignStats, setCampaignStats] = useState({});
  const [statsLoading, setStatsLoading] = useState(true);
  const [stepsLoading, setStepsLoading] = useState(true);
  const [steps, setSteps] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [validations, setValidations] = useState({ subject: "" });
  const [stepPayload, setStepPayload] = useState({});
  const [loading, setLoading] = useState(false);
  const [headerItems, setHeaderItems] = useState([
    {
      id: STRINGS.TYPE_CAMP,
      label: STRINGS.CAMPAIGN_TEXT,
      path: EMAIL_MARKETING_CAMPAIGN_PATH,
    },
  ]);

  useEffect(() => {
    // This is for getting details of campaign.
    (async () => {
      try {
        const campaign_details = await getRequest(
          navigate,
          campaignEndpoints.eachCampaignDetail + "/" + campaignId
        );
        setHeaderItems([
          {
            id: STRINGS.TYPE_CAMP,
            label: STRINGS.CAMPAIGN_TEXT,
            path: EMAIL_MARKETING_CAMPAIGN_PATH,
          },
          {
            id: campaign_details?.data?.data?._id,
            label: campaign_details?.data?.data?.name,
          },
        ]);
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    })();

    // This is for gettings stats of the campaign
    (async () => {
      try {
        setStatsLoading(true);
        const campaign_stats = await postRequest(
          navigate,
          campaignEndpoints.campStats,
          { campaign_ids: [campaignId] }
        );
        setCampaignStats(
          processStatsKeyValuePairs(
            campaign_stats?.data?.records?.[campaignId] || {}
          )
        );
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      } finally {
        setStatsLoading(false);
      }
    })();

    // This is for getting overview steps.
    (async () => {
      try {
        const step_details = await getRequest(
          navigate,
          campaignEndpoints.campaignOverviewSteps + "/" + sequenceId
        );
        setSteps(step_details?.data?.data || []);
      } catch (error) {
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(error?.data?.errors, error?.data?.message)
        );
      } finally {
        setStepsLoading(false);
      }
    })();
  }, [navigate, campaignId, sequenceId]);
  // this useEffect is for gettings campaign details, stats, and overview steps.

  const EMAIL_MARKETING_TABS = [
    {
      label: STRINGS.CAMPAIGN_CONTACTS_LABEL,
      id: STRINGS.CAMPAIGN_CONTACTS_ID,
    },
    {
      label: STRINGS.CAMPAIGN_OVERVIEW_LABEL,
      id: STRINGS.CAMPAIGN_OVERVIEW_ID,
    },

    {
      label: STRINGS.CAMPAIGN_ACTIVITY_LABEL,
      id: STRINGS.CAMPAIGN_ACTIVITY_ID,
    },
    {
      label: STRINGS.CAMPAIGN_SETTINGS_LABEL,
      id: STRINGS.CAMPAIGN_SETTINGS_ID,
    },
  ];

  const handleNavigate = (item) => {
    console.log({ item });
    try {
      navigate(
        `${EMAIL_MARKETING_CAMPAIGN_PATH}/${params?.campaignId}/${item?.id}`
      );
    } catch (error) {
      Logger(error);
    }
  };

  const handleUpdateContent = async () => {
    try {
      setLoading(true);
      const validatedSubject = nameValidation(subject);
      if (validatedSubject.isValid) {
        let customEditorValue = removeTagStyleFromHtml(
          `<div>${document
            .getElementById("editor")
            ?.innerHTML?.toString()}</div>`
        );
        customEditorValue = compressString(
          encodeURIComponent(customEditorValue)
        );
        setValidations({ subject: "" });
        const campaign_id = campaignId;
        const step_id = stepPayload?.step_id;
        const body = customEditorValue;
        const step_type_id = stepPayload?.step_type_id;
        const updated_payload = {
          campaign_id,
          step_id,
          body,
          subject,
          step_type_id,
        };
        const api_req_ack = await putRequest(
          navigate,
          campaignEndpoints.updateScheduledCampaign,
          updated_payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, api_req_ack?.data?.message);
        setShowEdit(!showEdit);
        // This is for getting overview steps.
        (async () => {
          setStepsLoading(true);
          try {
            const step_details = await getRequest(
              navigate,
              campaignEndpoints.campaignOverviewSteps + "/" + sequenceId
            );
            setSteps(step_details?.data?.data || []);
          } catch (error) {
            EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
          } finally {
            setStepsLoading(false);
          }
        })();
      } else {
        setValidations({ subject: validatedSubject.message });
      }
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.errors, error?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (step) => {
    setShowEdit(!showEdit);
    setStepPayload(step);
    setSubject(step?.step_data?.subject);
    let compressed = step?.step_data?.is_compressed
      ? decodeURIComponent(decompressString(step?.step_data?.body))
      : step?.step_data?.body;
    setEmailBody(compressed);
  };

  const STEP_ACTIONS = [
    {
      name: STRINGS.COMMON_EDIT,
      icon: ICON_LABELS.EDIT,
      action: handleEdit,
    },
  ];

  const modalHeaderData = {
    heading: {
      id: STRINGS.EDIT_CAMPAIGN,
      label: STRINGS.EDIT_CAMPAIGN,
    },
  };

  const modalFooterLeftData = <div></div>;

  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={handleEdit}
      />

      <Button
        label={STRINGS.COMMON_UPDATE}
        size={"sm36"}
        variant={"primary"}
        onClick={handleUpdateContent}
        isLoading={loading}
      />
    </div>
  );

  const modalBodyData = (
    <div>
      <div className={classes.fieldContainer}>
        <TypographyInline
          size={"sm"}
          color={GREY.PRIMARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.SUBJECT}
        />
        <InputField
          variant={validations.subject ? "error" : "default"}
          errormessage={validations.subject}
          size={"sm36"}
          placeholder={STRINGS.TYPESUBJECT}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className={classes.fieldContainer1}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.BODY}
        />
        <div className={classes.containEditor}>
          <HtmlEditor
            previosData={addTagStyleInHtml(emailBody || "")}
            editorHeight="280px"
          />
        </div>
      </div>
    </div>
  );

  return (
    <Box className={classes.container1}>
      <CustomHeader items={headerItems} />

      {/* Tabs for selection */}
      <Box className={classes.tabContainer}>
        {EMAIL_MARKETING_TABS?.map((item) => {
          return (
            <Box
              keye={item.id}
              className={
                CURRENT_TAB_ID === item.id ? classes.selectedTab : classes.tab
              }
              onClick={() => handleNavigate(item)}
            >
              <TypographyInline
                size={"sm"}
                color={
                  CURRENT_TAB_ID === item.id ? BRAND.PRIMARY : GREY.SECONDARY
                }
                fontWeight={
                  CURRENT_TAB_ID === item.id
                    ? fontWeight.MEDIUM
                    : fontWeight.REGULAR
                }
                label={item.label}
              />
            </Box>
          );
        })}
      </Box>
      {/* Tabs for selection */}

      {/* Stats  */}
      <Box className={classes.statsParentContainer}>
        {
          <Box className={classes.statsContainer} id="modal_body">
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.STATISTICS}
              className={classes.statsTitle}
            />
            <Box className={classes.pureFlex2}>
              {statsLoading ? (
                <StatsSkeleton />
              ) : (
                SEQUENCE_STATS_DATA?.map((eachStat, index) => {
                  return (
                    <Box
                      className={
                        index === SEQUENCE_STATS_DATA.length - 1
                          ? classes.lastStat
                          : classes.eachStat
                      }
                      key={eachStat.key}
                    >
                      <TypographyInline
                        size={"sm"}
                        color={GREY.PRIMARY}
                        fontWeight={fontWeight.MEDIUM}
                        label={campaignStats?.[eachStat.key] || 0}
                      />
                      <TypographyInline
                        size={"sm"}
                        color={GREY.PRIMARY}
                        fontWeight={fontWeight.REGULAR}
                        label={eachStat.label || ""}
                      />
                    </Box>
                  );
                })
              )}
            </Box>
          </Box>
        }
      </Box>

      {/* Steps List */}

      {stepsLoading ? (
        <StepsSkeleton />
      ) : (
        <Box className={classes.stepsScrollContainer} id="modal_body">
          <Box className={classes.containAllSteps}>
            <Box className={classes.containOverviewSteps}>
              {steps.map((step, index) => {
                return (
                  <Box className={classes.overviewStepSaperator}>
                    <EachStepLayout
                      step={step}
                      type="camp"
                      operation={step.step_type === 1}
                      // headerActions={STEP_ACTIONS}
                      stepIndex={index}
                      stepDetail={{
                        stepNumber: step?.step_number,
                        stepType: step?.step_type,
                      }}
                      body={
                        step?.step_type === 1 ? (
                          <STEP_TYPE_COMPONENTS.Email data={step?.step_data} />
                        ) : step?.step_type === 2 ? (
                          <STEP_TYPE_COMPONENTS.Todo data={step?.step_data} />
                        ) : step?.step_type === 3 ? (
                          <STEP_TYPE_COMPONENTS.Call data={step?.step_data} />
                        ) : null
                      }
                      footer={{
                        left:
                          index === 0
                            ? ""
                            : `${step?.interval} ${STRINGS.DAYS_AFTER_STEP} ${index}`,
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}

      {/* Step Edit Modal */}

      {showEdit && (
        <CustomModal
          width="50vw"
          close={handleEdit}
          open={showEdit}
          header={modalHeaderData}
          children={modalBodyData}
          footer={{
            left: modalFooterLeftData,
            right: modalFooterRightData,
          }}
        />
      )}
    </Box>
  );
};

export default CampaignOverviewScreen;
