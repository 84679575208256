import React, { useState } from "react";
import {
  InputWrapper,
  Root,
  StyledTag,
} from "../../../components/Autocomplete";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import { ErrorMessage } from "../../../components";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { domainValidationForBlacklist } from "../../../utils/validations/inputValidations";
import { getRequest, postRequest } from "../../../Apis";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import { importEndpoints } from "../../../utils/constants/httpConstants";

const MultiSelectTextField = ({
  validationFunction,
  endPoint,
  payloadKey,
  placeholder,
  setOpen,
  getEndpoint = importEndpoints.getBlackListEmail,
  getPayloadKey = STRINGS.BLOCKED_EMAILS,
  setRecords = () => { },
  setMaxRecords = () => { }
}) => {
  const navigate = useNavigate();
  const [inputPayload, setInputPayload] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  let { getRootProps, getInputProps, getTagProps, value, setAnchorEl } =
    useAutocomplete({
      id: "customized-hook",
      inputValue: inputPayload,
      multiple: true,
      // onInputChange: handleInputChange,
      options: [{ name: "" }],
      getOptionLabel: (option) => option.name,
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    const validatedDomain = validationFunction(inputPayload);
    if (validatedDomain.isValid) {
      setErrMsg("");
      value.push({ name: inputPayload?.trim() });
      setInputPayload("");
    } else {
      setErrMsg(validatedDomain.message);
    }
  };

  const handleBlockDomain = async () => {
    const validatedEmail = domainValidationForBlacklist(inputPayload);
    if (value?.length || validatedEmail.isValid) {
      try {
        setErrMsg("");
        setLoading(true);
        const user_emails = [...new Set(value?.map((email) => email?.name))];
        if (validatedEmail.isValid) {
          user_emails.push(inputPayload);
        }
        const payload = { [payloadKey]: user_emails || [validatedEmail] };
        const response = await postRequest(navigate, endPoint, payload);
        const response1 = await getRequest(navigate, getEndpoint);
        setRecords(response1?.data?.data?.[getPayloadKey]);
        setMaxRecords(response1?.data?.data?.total_records);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setOpen(false);
      } catch (error) {
        console.log('error', error);
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.message || STRINGS.SOMETHING_WENT_WRONG
        );
      } finally {
        setLoading(false);
      }
    } else {
      setErrMsg(STRINGS.REQUIRED);
    }
  };

  return (
    <>
      <Root>
        <div {...getRootProps()} style={styles.FORM}>
          <InputWrapper ref={setAnchorEl} style={{ width: "100% " }}>
            {value?.map((option, index) => (
              <StyledTag label={option.name} {...getTagProps({ index })} />
            ))}
            <Box component="form" onSubmit={handleSubmit} style={{ width: "100%" }}>
              <input
                style={styles.input1}
                placeholder={placeholder}
                {...getInputProps()}
                onChange={(e) => setInputPayload(e.target.value)}
              />
            </Box>
          </InputWrapper>
        </div>
      </Root>
      {errMsg && <ErrorMessage message={errMsg} />}
      <div style={styles.BTN}>
        {" "}
        <Button
          label={STRINGS.BLACKLIST}
          size={"sm36"}
          variant={"primary"}
          onClick={handleBlockDomain}
          isLoading={loading}
        />
      </div>
    </>
  );
};

export default React.memo(MultiSelectTextField);
