import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from "./FormsComp.module.css";
import { STRINGS } from '../../strings';
import { ADD_ASSOCIATION_LIST_DEALS, DEALS_FORM_STATIC_FIELDS, EMITTER_ERROR, EMITTER_SUCCESS, formSectionList } from '../../../../utils/constants';
import TypographyInline from '../../../../components/Typography/TypographyInline';
import { BASE, BRAND, GREY } from '../../../../utils/constants/colors';
import { ICON_LABELS, fontSize, fontWeight } from '../../../../utils/constants/UI';
import IconComponent from '../../../../components/Icon/IconComponent';
import SearchField from '../../../../components/InputFields/SearchField';
import { decompressString } from '../../../../utils/helpers';
import { Accordion } from '@mui/material';
import { AccordionDetails, AccordionSummary } from '../../../../components';
import Checkbox from '../../../../components/Buttons/Checkbox/Checkbox';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Footer from './Footer';
import EventEmitter from '../../../../utils/emitter/EventEmitter';
import { putRequest } from '../../../../Apis';
import { formsEndpoints } from '../../../../utils/constants/httpConstants';
import { useNavigate } from 'react-router-dom';
import { FORMS_FIELD_RESET_MESSAGE } from '../../../../utils/constants/messages';
import PreviewModal from './PreviewModal';
import { IconButton, ImageButton } from '../../../../components/Buttons';
import { ASTERISK } from '../../../../utils/constants/assets';
import CustomTooltip from '../../../../components/Tooltip/CustomTooltip';
import FormsDragLoading from './FormsDragLoading';

const DealForm = ({ formFields = {}, getFormsField = () => { }, fieldsLoading = false }) => {
    const navigate = useNavigate();

    const getPropertiesListSelector = useSelector(
        (state) => state.getPropertiesList?.data
    );

    const properties_payload = localStorage.getItem("properties") ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))?.hits?.filter(item => item?.object_type?.toString() === STRINGS.DEALS) : getPropertiesListSelector?.hits?.filter(item => item?.object_type?.toString() === STRINGS.DEALS);
    const propertiesKeyValue = localStorage.getItem("properties") ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))?.properties_key_value : getPropertiesListSelector?.properties_key_value;

    const [selectedFormSection, setSelectedFormSection] = useState({ ...formSectionList?.[0] });
    const [accordionExpanded, setAccordionExpanded] = useState("");
    const [dealsFields, setDealsFields] = useState([...formFields?.Deals || []]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [propertiesPayload, setPropertiesPayload] = useState([]);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [search, setSearch] = useState("");
    const [operationsDisable, setOperationsDisable] = useState(true);

    // returns the classname for accordions according to our reequirement
    const getClassforAccordion = (obj, item, index) => {

        let keys = Object.keys(obj)?.filter(item => item !== STRINGS.OTHER);

        if (keys?.length - 1 !== index) {
            if (accordionExpanded === item || (search?.length && !index)) {
                return classes.summaryContainerOpen;
            } else {
                return classes.summaryContainer;
            }
        } else {
            if (accordionExpanded === item || (search?.length && !index)) {
                return classes.summaryContainerOpen;
            } else {
                return classes.summaryLastContainer;
            }
        }

    }

    // handle which will return UI having accordions for every group.
    const accodionListForGroups = () => {
        // contains all the properties in the keys of grp name or if there is no grp associated then stored in Other key.
        let obj = {};
        let removed_property = [STRINGS.ASSOCIATED_COMPANY_SMALL, STRINGS.ASSOCIATED_CONTACT_SMALL]
        propertiesPayload?.map((item) => {
            if (!removed_property?.includes(item?.id)) {
                if (!DEALS_FORM_STATIC_FIELDS?.includes(item?.id) && item?.in_form) {
                    if (item?.grp_name) {
                        obj = { ...obj || {}, [item?.grp_name]: [...(obj?.[item?.grp_name] || []), { ...item }] };
                    } else {
                        obj = {
                            ...obj || {},
                            Other: [
                                ...obj?.Other || [],
                                { ...item }
                            ]
                        };
                    }
                }
            }
        });

        // returing UI of accordions according to condtion.
        return <>
            {
                Object.keys(obj)?.filter(item => item !== STRINGS.OTHER)?.map((item, index) => {
                    return <Accordion key={`propList${index}`} className={classes.accrodionRoot} onChange={() => {
                        (accordionExpanded === item || (search?.length && !index)) ? setAccordionExpanded("") : setAccordionExpanded(item)
                    }}
                        expanded={accordionExpanded === item || (search?.length && !index)}>
                        <AccordionSummary className={classes.accordionSummary}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <div className={getClassforAccordion(obj, item, index)}>
                                <TypographyInline color={GREY.PRIMARY} label={item} size={"md"} fontWeight={fontWeight.SEMIBOLD} isEllipses />
                                <IconComponent color={GREY.TERTIARY} fontSize={fontSize.DXS} iconLabel={accordionExpanded === item || (search?.length && !index) ? ICON_LABELS.KEYBOARD_ARROW_UP : ICON_LABELS.KEYBOARD_ARROW_DOWN} />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.accordionDetail}
                        >
                            {
                                obj?.[item]?.map((prop, index) => {
                                    return <div key={`${index}${prop?.id}`} className={classes.propertyLabelContainer} >
                                        <Checkbox label={prop?.property_name} checked={dealsFields?.filter(item => item?.field_id === prop?.id)?.length} onChange={() => { handleChangeSelectedProperty(prop) }} />
                                    </div>
                                })
                            }
                        </AccordionDetails>
                    </Accordion>
                })
            }

            {/* return list of prperties which are not associated with any group. */}
            <div className={classes.otherPropertiesListing}>
                {
                    obj?.Other?.map((prop, index) => {
                        return <div key={`${index}${prop?.id}`} className={classes.propertyLabelContainer} >
                            <Checkbox label={prop?.property_name} checked={dealsFields?.filter(item => item?.field_id === prop?.id)?.length} onChange={() => { handleChangeSelectedProperty(prop) }} />
                        </div>
                    })
                }
            </div>
        </>
    }

    // this will handle the UI display according to the selection of section.
    const middleSectionUIHandler = () => {
        switch (selectedFormSection?.id) {
            case STRINGS.ADD_ASSOCIATIONS_SMALL:
                return <div className={classes.associationList} >
                    {
                        ADD_ASSOCIATION_LIST_DEALS?.map((item, index) => {
                            return <Checkbox key={`${item?.id}${index}`} id={item?.id} label={item?.label} checked={dealsFields?.filter(field => field?.field_id === item?.id)?.length} onChange={() => { handleChangeSelectedProperty(item) }} />
                        })
                    }
                </div>

            default:
                return <>
                    <div className={classes.propertiesSearchContainer} >
                        <SearchField size={"sm36"} onChange={searchPropHandler} placeholder={STRINGS.SEARCH} />
                    </div>
                    <div id='modal_body' className={classes.listingContainer} >
                        {accodionListForGroups()}
                    </div>
                </>
        }
    }

    // tooltip UI handler
    const tooltipUI = (field) => {
        let clicked_property = { ...propertiesKeyValue?.[field?.field_id] };
        return <div className={classes.tooltipIcons} >
            <ImageButton LeftIcon={ASTERISK} size={"sm36"} variant={"ghost"} onClick={() => handleChangeRequiredField(field)} />
            <IconButton LeftIcon={ICON_LABELS.DELETE} size={"sm36"} variant={"ghost"} onClick={() => handleChangeSelectedProperty(clicked_property)} />
        </div>
    }

    const handleChangeRequiredField = (field) => {
        let removeClickedField = dealsFields?.filter(item => item?.field_id !== field?.field_id);
        let new_obj = dealsFields?.filter(item => item?.field_id === field?.field_id)[0];
        new_obj.required = !new_obj?.required;
        let new_payload = [...removeClickedField, ...[new_obj]]?.sort((obj1, obj2) => obj1.order - obj2.order);
        setDealsFields(new_payload);
        setOperationsDisable(false);
    }

    // storing values in state of company fields in the property listing.
    const handleChangeSelectedProperty = (item) => {
        let isSelected = dealsFields?.filter(field => field?.field_id === item?.id)?.length;
        let temp_selection = [];
        let all_order = dealsFields?.map(item => { return item.order })
        let max_order = Math.max(...all_order)
        if (!isSelected) {
            temp_selection = [...dealsFields || [], { field_id: item?.id, field_type: item?.field_type?.toString(), order: max_order + 1, operation: false, required: false }]
        } else {
            temp_selection = dealsFields?.filter(field => field?.field_id !== item?.id)
        }
        setDealsFields(temp_selection);
        setOperationsDisable(false);
    }

    // list style returnig the background color when we are dragging which will help in display the draggable area.
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? BASE.WHITE : BASE.WHITE,
    });

    // helper to store the order when we dragging ends.
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(dealsFields);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const temp_items = items?.map((item, index) => {
            return { ...item, order: parseInt(index) }
        })
        setDealsFields(temp_items);
        setOperationsDisable(false);
    };

    // reset fields handler
    const resetFieldshandler = () => {
        if (formFields?.Deals?.length) {
            let fields = formFields?.Deals?.sort((obj1, obj2) => obj1.order - obj2.order);
            setDealsFields([...fields]);
            setPropertiesPayload(properties_payload);
            setOperationsDisable(true);
            EventEmitter.emit(EMITTER_SUCCESS, FORMS_FIELD_RESET_MESSAGE);
        }
    }

    // save/update fields handler
    const saveFieldsHandler = async () => {
        try {
            let payload = {
                type: STRINGS.DEALS,
                fields: [...dealsFields]
            }
            setSaveLoading(true);
            const response = await putRequest(navigate, formsEndpoints.changeFormField, payload);
            await getFormsField();
            EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
            setSaveLoading(false);

        } catch (error) {
            setSaveLoading(false);
            console.log("Error-------->>>>>>>>>>>> ", error);
            EventEmitter.emit(EMITTER_ERROR, error?.message);
        }
    }

    // preview form section handler
    const previewFormHandler = () => {
        setOpenPreviewModal(!openPreviewModal);
    }

    // search property Handler and storing the values in propertiesPayload variable.
    const searchPropHandler = (event) => {
        if (event.target.value) {
            setSearch(event?.target?.value);
            let filtered_field = propertiesPayload?.filter(item => item?.property_name?.toLowerCase()?.includes(event?.target?.value?.toLowerCase()));
            setPropertiesPayload([...filtered_field]);
        }
        else {
            setSearch("");
            setPropertiesPayload(properties_payload);
        }
    }

    useEffect(() => {
        setPropertiesPayload(properties_payload);
        if (formFields?.Deals?.length) {
            let fields = formFields?.Deals?.sort((obj1, obj2) => obj1.order - obj2.order);
            setDealsFields([...fields]);
        }
    }, [formFields])


    return (
        <div className={classes.mainContainer}>
            {/* left part */}
            <div className={classes.formSectionListContainer} >
                {
                    formSectionList?.map((item) =>
                        <div key={item?.id} className={`${selectedFormSection?.id === item?.id ? classes.selectedSectionContainer : classes.sectionContainer}`} onClick={() => { setSelectedFormSection(item); setSearch(""); setPropertiesPayload(properties_payload) }} >
                            <TypographyInline color={selectedFormSection?.id === item?.id ? BRAND.PRIMARY : GREY.SECONDARY} label={item?.label} size={"sm"} fontWeight={fontWeight.MEDIUM} isNoWrap />
                            <IconComponent color={selectedFormSection?.id === item?.id ? BRAND.PRIMARY : GREY.SECONDARY} fontSize={fontSize.XL} iconLabel={ICON_LABELS.KEYBOARD_ARROW_RIGHT} />
                        </div>
                    )
                }
            </div>

            {/* right section consiting property lisiting, preview section and footer. */}
            <div className={classes.formSectionListRightContainer}>

                <div className={classes.propertyListaAndPreview} >
                    {/* middle part property selection, in this it is a function call because in future list can be increased so that switch case will help in this case. */}
                    <div className={classes.propertyListingContainer}>
                        {middleSectionUIHandler()}
                    </div>

                    {/* right part preview of selected properties. */}
                    <div className={classes.propertyPreviewSection}>
                        {/* header part of preview section */}
                        <div className={classes.previewHeader}>
                            <TypographyInline color={BASE.WHITE} label={STRINGS.DEALS} size={"md"} fontWeight={fontWeight.SEMIBOLD} isNoWrap />
                        </div>

                        {
                            fieldsLoading ? <FormsDragLoading /> :
                                <div className={classes.previewBody} id='modal_body'>

                                    {/* static fields of company form */}
                                    <div className={classes.multiValueStaticMainContainer} >
                                        {
                                            dealsFields?.map((item, index) => {
                                                return DEALS_FORM_STATIC_FIELDS?.includes(item?.field_id) && (
                                                    <div key={`staticKeys${index}`} className={classes.multiValueStaticSubContainer}>
                                                        <TypographyInline color={GREY.SECONDARY} label={propertiesKeyValue?.[item?.field_id]?.property_name} size={"sm"} fontWeight={fontWeight.SEMIBOLD} isRequired={item?.required} isEllipses />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {/* draggable area */}
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId="properties_order" >
                                            {
                                                (provided, snapshot) => (
                                                    <div className={classes.multiValueMainContainer}
                                                        {...provided?.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot?.isDraggingOver)}>
                                                        {
                                                            dealsFields.sort((a, b) => a.id - b.id)?.map((item, index) => {
                                                                return !DEALS_FORM_STATIC_FIELDS?.includes(item?.field_id) && (<Draggable key={item?.field_id}
                                                                    draggableId={item?.field_id}
                                                                    index={index} >
                                                                    {(provided) => (
                                                                        <CustomTooltip placement="bottom-end" theme='light' title={tooltipUI(item)} >
                                                                            <div
                                                                                className={classes.multiValueSubContainer}
                                                                                ref={provided.innerRef}
                                                                                {...provided?.draggableProps}
                                                                                {...provided?.dragHandleProps}
                                                                            >
                                                                                <div className={classes.iconContainer}>
                                                                                    <IconComponent
                                                                                        color={GREY.TERTIARY}
                                                                                        fontSize={fontSize.DXS}
                                                                                        iconLabel={ICON_LABELS.DRAG_INDICATOR}
                                                                                    />
                                                                                </div>
                                                                                <div className={classes.inputFieldContainer}>
                                                                                    <TypographyInline isRequired={item?.required} color={GREY.SECONDARY} label={propertiesKeyValue?.[item?.field_id]?.property_name} size={"sm"} fontWeight={fontWeight.SEMIBOLD} isNoWrap />
                                                                                </div>
                                                                            </div></CustomTooltip>
                                                                    )}
                                                                </Draggable>)
                                                            })}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                        }
                    </div>
                </div>

                {/* Footer */}
                <div className={classes.footerSection} >
                    <Footer cancleHandler={resetFieldshandler} previewHandler={previewFormHandler} saveHandler={saveFieldsHandler} saveLoading={saveLoading} disableOperation={operationsDisable} />
                </div>
            </div>

            {/* preview modal */}
            <PreviewModal open={openPreviewModal} close={previewFormHandler} fields={dealsFields} />
        </div>
    )
}

export default DealForm