import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/Buttons";
import CustomHeader from "../../../components/Header/CustomHeader";
import SearchField from "../../../components/InputFields/SearchField";
import { ICON_LABELS } from "../../../utils/constants/UI";
import { STRINGS } from "../strings";
import { tempHeadCompanyCells } from "../../../utils/constants";
import FilterStrip from "./components/FilterStrip";
import classes from "./Crm.module.css";
import { filterQuery } from "../../../utils/helpers";
import {
  allDynamicFilters,
  filterMultipleList,
} from "../../../redux/actions/filterActions";
import EditColumnModal from "./components/EditColumnModal";
import AccounttHeader from "./components/AccounttHeader";
import { crmAccountDataFetch } from "../../../redux/actions/tableActions";
import AccountTable from "./components/AccountTable";
import EmptyScreen from "../../../components/Screens/EmptyScreen";
import { DEALS_EMPTY_SCREEN } from "../../../utils/constants/assets";
import * as qs from "qs";
import AddEditCompany from "./components/AddEditCompany";

const AccountScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const columnsSelector = useSelector((state) => state.dynamicUpdatedColumn);
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);

  const dataFetchSelector = useSelector((state) => state.tableDataFetch);
  const totalCount = dataFetchSelector?.columns?.total_records?.toString();

  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );

  const res = qs.parse(window.location?.search?.slice(1));
  const queryLength = Object.keys(filterQuery(allFiltersSelector)).length;
  const headerItems = [{ id: STRINGS.SALES_SMALL, label: STRINGS.ACCOUNTS }];
  const [addContactOpen, setAddContactOpen] = useState(false);
  const [searchField, setSearchField] = useState(res?.search || "");
  const [accountTableLoading, setAccountTableLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);

  const searchFieldHandler = async (value) => {
    if (value) {
      dispatch(
        filterMultipleList({
          ...filterMultipleListSelector,
          search: value,
          page: 1,
          limit: 25,
          isApiCall: true,
        })
      );
    } else {
      let data = { ...filterMultipleListSelector };
      delete data?.search;
      dispatch(
        filterMultipleList({
          ...data,
          page: 1,
          limit: 25,
          isApiCall: true,
        })
      );
    }
  };

  const addContactHandler = async () => {
    setAddContactOpen(!addContactOpen);
  };

  // clear all if needed required fields.
  const handleClearAll = () => {
    setSearchField("");
  };

  const headerDescription = `${dataFetchSelector?.loading ? STRINGS.LOADING : totalCount
    } ${STRINGS.ACCOUNTS}`;
  useEffect(() => {
    if (
      columnsSelector?.columns
        ?.filter((item) => item?.isChecked)
        ?.map((item) => item?.id)
        ?.includes(STRINGS.COMPANY_NAME_SMALL) &&
      !accountTableLoading &&
      !columnsSelector?.not_api_call &&
      Object.keys(getPropertiesListSelector || {})?.length
    ) {
      (async () => {
        try {
          setAccountTableLoading(true);
          await dispatch(
            crmAccountDataFetch(
              {
                query: {
                  ...allDynamicFiltersSelector,
                },
              },
              navigate,
              true
            )
          );
        } catch (error) {
        } finally {
          setAccountTableLoading(false);
          setLoadingTable(false);
        }
      })();
    }
  }, [
    navigate,
    dispatch,
    allDynamicFiltersSelector,
    queryLength,
    columnsSelector,
    getPropertiesListSelector,
  ]);

  useEffect(() => {
    if (Object.keys(paginationDataSelector?.data?.query || {}).length) {
      let payloadQuery = {
        ...filterMultipleListSelector,
        ...paginationDataSelector?.data?.query,
      };

      if (paginationDataSelector?.data?.query?.sort?.id?.length) {
        payloadQuery = {
          ...payloadQuery,
          sort: paginationDataSelector?.data?.query?.sort?.id,
        };
      }
      if (paginationDataSelector?.data?.query?.sort?.direction) {
        payloadQuery = {
          ...payloadQuery,
          direction: paginationDataSelector?.data?.query?.sort?.direction,
        };
      }

      dispatch(
        allDynamicFilters(
          {
            ...payloadQuery,
          },
          navigate
        )
      );
      let navigateQuery = { ...payloadQuery };
      delete navigateQuery?.isApiCall;
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...navigateQuery,
        }).toString(),
      });
    }
  }, [
    paginationDataSelector?.data?.query?.page,
    paginationDataSelector?.data?.query?.limit,
    paginationDataSelector?.data?.query?.sort,
  ]);

  // debounce search api call for search
  useEffect(() => {
    let getData;
    getData = setTimeout(() => {
      searchFieldHandler(searchField);
    }, 300);
    return () => clearTimeout(getData);
  }, [searchField]);
  
  return (
    <div className={classes.contactScreenMainContainer}>
      {/* Header */}
      <CustomHeader items={headerItems} description={headerDescription} />
      {/* Header */}

      {/* View Tab Header */}
      <AccounttHeader />
      {/* View Tab Header */}

      {dataFetchSelector?.columns?.is_empty ? (
        <div className={classes.dealsEmptyScreenContainer}>
          <EmptyScreen
            imageSrc={DEALS_EMPTY_SCREEN}
            label={STRINGS.EMPTY_COMPANY_SCREEN_HEADING}
            description={STRINGS.EMPTY_COMPANY_SCREEN_DESC}
            buttonLabel={STRINGS.ADD_COMPANY}
            onClick={() => setAddContactOpen(true)}
          />
        </div>
      ) : (
        <>
          <div className={classes.FlexHead}>
            {/* Filter Strip */}
            <FilterStrip clearAllParentHandler={handleClearAll} setSearchField={setSearchField} />
            {/* Filter Strip */}
          </div>

          {/* Table Search Bar and Table Operation Buttons */}
          <div className={classes.tableUpperStripContainer}>
            <div className={classes.searchFieldWidth}>
              <SearchField
                size={"sm36"}
                placeholder={STRINGS.SEARCH}
                value={searchField}
                onChange={(e) => {
                  setSearchField(e?.target?.value);
                }}
              />
            </div>
            <div className={classes.tableUpperStripRightContainer}>
              <EditColumnModal />

              <Button
                LeftIcon={ICON_LABELS.ADD}
                label={STRINGS.ADD_COMPANY}
                size={"sm36"}
                variant="primary"
                onClick={addContactHandler}
              />
            </div>
          </div>
          {/* Table Search Bar and Table Operation Buttons */}

          {/* Table Container */}
          <AccountTable
            heads={(columnsSelector?.columns?.filter(
              (item) => item?.id === STRINGS.COMPANY_NAME_SMALL
            )?.length
              ? columnsSelector?.columns?.filter((item) => !item.is_deal)
              : tempHeadCompanyCells
            )?.filter((item) => item.isChecked)}
            records={dataFetchSelector?.columns?.records || []}
            totalCount={totalCount}
            setSearchField={setSearchField}
            loading={loadingTable}
          />
          {/* Table Container */}
        </>
      )}

      {/* Add Contact Dialog */}
      <AddEditCompany open={addContactOpen} setOpen={setAddContactOpen} />
      {/* Add Contact Dialog */}
    </div>
  );
};

export default AccountScreen;
