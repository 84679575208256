import React from "react";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filterSelectedIndexes, getUniqueListBy } from "../../utils/helpers";
import {
  allFilters,
  filterHeaderEmailVerification,
} from "../../redux/actions/filterActions";
import Checkbox from "../Buttons/Checkbox/Checkbox";

const FilterTypeEmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyHeaderEmailVerification = useSelector(
    (state) => state?.filterHeaderEmailVerification?.payload
  );
  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);

  const handleChange = (item) => {
    item.isChecked = !item.isChecked;
    const result = getUniqueListBy(
      [...companyHeaderEmailVerification, item],
      "index"
    );
    dispatch(filterHeaderEmailVerification(result));
    const new_employee_number = filterSelectedIndexes(result, "index");
    const resultant = [...new_employee_number];
    const payload = [...new Set(resultant)];
    const queryPayload = { email_verification: payload };
    dispatch(allFilters(queryPayload));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        email_verification: payload,
      }).toString(),
    });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterContainer}>
        {companyHeaderEmailVerification.map((item) => {
          return (
            <Box key={item.type} sx={styles.flex}>
              <Checkbox
                onClick={() => handleChange(item)}
                checked={item.isChecked}
                label={item.type}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default FilterTypeEmailVerification;
