import React from "react";
import Typography from "../../../components/Typography/Typography";
import { GREY } from "../../../utils/constants/colors";
import { fontSize, fontWeight } from "../../../utils/constants/UI";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";
import classes from "./PropComponent.module.css"

const CustomTableHeader = ({ label = "", index = 1 }) => {

  return (
    <div
      className={`${classes.tableHeaderContainer} ${!index ? classes.noBottomBorder : classes.noBottomLeftBorder}`}
    >
      {label?.length ? (
        <Typography
          color={GREY.PRIMARY}
          fontWeight={fontWeight.MEDIUM}
          label={label}
          size={"sm"}
          isEllipses
        />
      ) : (
        <div className={classes.tableHeaderCheckboxCell}>
          <Checkbox size={fontSize.XL} />
        </div>
      )}
    </div>
  );
};

export default CustomTableHeader;
