import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { styles } from "./styles";

function CircularLoader(props) {
  const classes = styles();

  return (
    <Box className={classes.container} >
      <CircularProgress
        variant="determinate"
        sx={{
          margin: "0.3rem 1rem",
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={props.size || 40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          margin: "0.3rem 1rem",
          color: props?.loadercolor || "#ff4848",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={props.size || 40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default CircularLoader;
