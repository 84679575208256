import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  patchRequest,
  postRequest,
  putRequest,
} from "../../../../Apis/apiWrapper";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../../utils/constants";
import {
  campaignEndpoints,
  listEndPoints,
  sequenceEndPoints,
  templateEndpoints,
} from "../../../../utils/constants/httpConstants";
import { getErrorMessage, Logger } from "../../../../utils/helpers";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../strings";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import InputField from "../../../../components/InputFields/InputField";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import CustomModal from "../../../../components/Modals/CustomModal";
import { paginationDataAction } from "../../../../redux/actions/customTableActions";
import {
  FOLDER_NAME_LENGTH_ERROR,
  FOLDER_NAME_LONG_LENGTH_ERROR,
  MESSAGE_INVALID_NAME_LENGTH,
} from "../../../../utils/constants/messages";
import classes from "../markComponents.module.css";
import { EMAIL_MARKETING_CAMPAIGN_PATH, SEQUENCE_PATH, TEMPLATE_PATH, LIST_PATH, } from "../../../../utils/constants/routes";

const CreateCommonFolder = ({
  open,
  setOpen,
  setOpenEdit,
  recordId,
  nameFold,
  view,
  type,
  endFunction = () => { },
}) => {
  const [selectedValue, setSelectedValue] = useState(
    view ? view : STRINGS.PRIVATE_ID
  );
  const [name, setName] = useState(nameFold ? nameFold : "");
  const sortSelector = useSelector((state) => state.getPaginationQuery);
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const radioButtonData = [
    { id: STRINGS.PRIVATE_ID, label: STRINGS.PRIVATE_LABEL },
    { id: STRINGS.TEAM_ID, label: STRINGS.TEAM_LABEL },
  ];


  const handleCreateFolder = async (e) => {
    try {
      e.preventDefault();

      if (name?.trim()?.length === 0) {
        setErrMsg(MESSAGE_INVALID_NAME_LENGTH);
        return;
      } else if (name?.trim()?.length < 3) {
        setErrMsg(FOLDER_NAME_LENGTH_ERROR);
        return;
      } else if (name?.trim()?.length > 50) {
        setErrMsg(FOLDER_NAME_LONG_LENGTH_ERROR);
        return;
      } else {
        setErrMsg("");
        if (type === STRINGS.TYPE_TEMP) {
          if (recordId) {
            try {
              const payload = {
                _id: recordId,
                name: encodeURIComponent(name),
                permission: selectedValue,
                type: STRINGS.SMALL_FOLDER,
              };
              setLoading(true);
              const response = await putRequest(
                navigate,
                templateEndpoints.updateFoldTemp,
                payload
              );
              EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

              setOpen(false);
              setLoading(false);
              dispatch(paginationDataAction({ checkedRow: [], query: {} }));
            } catch (err) {
              setErrMsg(
                getErrorMessage(
                  err?.data?.data?.errors || {},
                  err?.data?.message
                )
              );
              Logger(err?.data?.message);
            } finally {
              setLoading(false);
            }
          } else {
            try {
              const payload = {
                name: encodeURIComponent(name),
                permission: selectedValue,
              };
              setLoading(true);
              const response = await postRequest(
                navigate,
                templateEndpoints.createFolderTemplate,
                payload
              );
              const val = response?.data?.data?.id;
              if (response?.data?.status) {
                if (sortSelector?.data?.checkedRow?.length > 0) {
                  const response1 = await putRequest(
                    navigate,
                    templateEndpoints.moveTemplate,
                    {
                      template_ids: sortSelector?.data?.checkedRow,
                      parent: val,
                    }
                  );
                }
              }
              EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
              setOpen(false);
              setLoading(false);
              // dispatch(getTemplateList({},  navigate));
              // dispatch(paginationDataAction({ checkedRow: [], query: {} }));
              navigate(`${TEMPLATE_PATH}/${response?.data?.data?.id}`);
              endFunction();
            } catch (err) {
              setErrMsg(
                getErrorMessage(
                  err?.data?.data?.errors || {},
                  err?.data?.message
                )
              );
            } finally {
              setLoading(false);
            }
          }
        } else if (type === STRINGS.TYPE_SEQ) {
          if (recordId) {
            const payload = {
              record_id: recordId,
              // name: encodeURIComponent(name),
              name,
              permission: selectedValue,
            };
            setLoading(true);
            try {
              const response = await patchRequest(
                navigate,
                sequenceEndPoints.editSequence,
                payload
              );
              EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
              setOpenEdit(false);
              setLoading(false);
              endFunction();
              dispatch(paginationDataAction({ checkedRow: [], query: {} }));
            } catch (err) {
              setLoading(false);
              setErrMsg(
                getErrorMessage(
                  err?.data?.data?.errors || {},
                  err?.data?.message
                )
              );
            }
          } else {
            try {
              // const payload = { name: encodeURIComponent(name), permission: selectedValue };
              const payload = { name, permission: selectedValue };
              setLoading(true);
              const response = await postRequest(
                navigate,
                sequenceEndPoints.createFolder,
                payload
              );
              EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
              setOpen(false);
              setLoading(false);
              const val = response?.data?.id;
              if (response?.status === 201) {
                if (sortSelector?.data?.checkedRow?.length > 0) {
                  const response1 = await postRequest(
                    navigate,
                    sequenceEndPoints.moveSequence,
                    {
                      record_ids: sortSelector?.data?.checkedRow,
                      folder_id: val,
                    }
                  );
                }
              }
              navigate(`${SEQUENCE_PATH}/${response?.data?.id}`);
              endFunction();
            } catch (err) {
              setErrMsg(
                getErrorMessage(
                  err?.data?.data?.errors || {},
                  err?.data?.message
                )
              );
            } finally {
              setLoading(false);
            }
          }
        } else if (type === STRINGS.TYPE_CAMP) {
          if (recordId) {
            try {
              const payload = {
                // name: encodeURIComponent(name),
                name,
                campaign_record_id: recordId,
              };
              setLoading(true);
              const response = await patchRequest(
                navigate,
                campaignEndpoints.updateFolder,
                payload
              );
              dispatch(paginationDataAction({ checkedRow: [], query: {} }));
              endFunction();
              EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

              setOpen(false);
              setLoading(false);
              endFunction();
            } catch (err) {
              setErrMsg(
                getErrorMessage(
                  err?.data?.data?.errors || {},
                  err?.data?.message
                )
              );
            } finally {
              setLoading(false);
            }
          } else {
            try {
              const payload = {
                // name: encodeURIComponent(name),
                name,
              };
              setLoading(true);
              const response = await postRequest(
                navigate,
                campaignEndpoints.createFolder,
                payload
              );
              EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

              setOpen(false);
              endFunction();
              setLoading(false);
              if (response?.status === 201) {
                if (sortSelector?.data?.checkedRow?.length > 0) {
                  const response1 = await postRequest(
                    navigate,
                    campaignEndpoints.moveCampaignToList,
                    {
                      campaign_ids: sortSelector?.data?.checkedRow,
                      folder_id: response?.data?.data?.insertedId,
                    }
                  );
                }
              }
              navigate(`${EMAIL_MARKETING_CAMPAIGN_PATH}/${response?.data?.data?.insertedId}`)
              // endFunction();

            } catch (err) {
              setErrMsg(
                getErrorMessage(
                  err?.data?.data?.errors || {},
                  err?.data?.message
                )
              );
            } finally {
              setLoading(false);
            }
          }
        } else {
          if (recordId) {
            try {
              const payload = {
                _id: recordId,
                list_name: encodeURIComponent(name),
                type: STRINGS.SMALL_FOLDER,
                permission: selectedValue,
              };
              setLoading(true);
              const response = await putRequest(
                navigate,
                listEndPoints.updateFolderList,
                payload
              );
              EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
              dispatch(paginationDataAction({ checkedRow: [], query: {} }));
              endFunction();
              setOpen(false);
              setLoading(false);
            } catch (err) {
              setErrMsg(
                getErrorMessage(
                  err?.data?.data?.errors || {},
                  err?.data?.message
                )
              );
            } finally {
              setLoading(false);
            }
          } else {
            try {
              const payload = {
                list_name: encodeURIComponent(name),
                permission: selectedValue,
                type: STRINGS.SMALL_FOLDER,
              };
              setLoading(true);
              const response = await postRequest(
                navigate,
                listEndPoints.createFolder,
                payload
              );
              console.log({ response });
              EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
              if (response?.status === 201) {
                if (sortSelector?.data?.checkedRow?.length > 0) {

                  const payload1 = {
                    list_ids: sortSelector?.data?.checkedRow,
                    parent: response?.data?.data?.id,
                  };
                  await putRequest(
                    navigate,
                    listEndPoints.moveToFolderList,
                    payload1
                  );
                }
              }
              navigate(`${LIST_PATH}/${response?.data?.data?.id}`);

              // dispatch(paginationDataAction({ checkedRow: [], query: {} }));

              setLoading(false);
              setOpen(false);
              endFunction();
            } catch (err) {
              setErrMsg(
                getErrorMessage(
                  err?.data?.data?.errors || {},
                  err?.data?.message
                )
              );
            } finally {
              setLoading(false);
            }
          }
        }
      }
    } catch (error) { }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: recordId ? STRINGS.EDIT_FOLD : STRINGS.CREATE_FOLDER,
    },
  };

  const modalBodyData = (
    <form onSubmit={handleCreateFolder}>
      <div className={classes.modalBodyContainer}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.ENTER_FOLDER}
        />
        <InputField
          value={name}
          placeholder={STRINGS.ENTER_FOLDER_PLACEHODER}
          variant={errMsg ? "error" : "default"}
          errormessage={errMsg}
          size={"sm36"}
          onChange={(e) => setName(e.target.value)}
        />
        {type !== STRINGS.TYPE_CAMP && (
          <div className={classes.modalPaddingTop}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.SHARED_WITH}
            />
          </div>
        )}
        {type !== STRINGS.TYPE_CAMP && (
          <div className={classes.modalRad1}>
            <RadioButton
              value={selectedValue}
              onChange={handleChange}
              items={radioButtonData}
            />
          </div>
        )}
      </div>
    </form>
  );

  const modalFooterLeftData = <div></div>;

  const modalFooterRightData = (
    <div className={classes.rightFoot}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={recordId ? STRINGS.UPDATE : STRINGS.CREATE}
        size={"sm36"}
        variant={"primary"}
        onClick={handleCreateFolder}
        isLoading={loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      width={"500px"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default CreateCommonFolder;
