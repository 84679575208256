import React, { useEffect, useState } from "react";
import SearchField from "../../../components/InputFields/SearchField";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import CustomTable from "../../../components/Table/CustomTable";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import { useDispatch, useSelector } from "react-redux";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  propertiesFilterKeys,
  propertyListTableHeads,
} from "../../../utils/constants";
import DeletePropertyModal from "./DeletePropertyModal";
import { crmEndpoints } from "../../../utils/constants/httpConstants";
import {
  allPropertiesFilters,
  getPropertiesList,
} from "../../../redux/actions/propertiesAction";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { useNavigate } from "react-router-dom";
import { deleteRequest } from "../../../Apis/apiWrapper";
import { GREY } from "../../../utils/constants/colors";
import FilterTab from "../../../components/FilterTab/FilterTab";
import FieldTypeFilterMenu from "./FieldTypeFilterMenu";
import classes from "./PropComponent.module.css";
import { GroupNameFilterMenu } from "./GroupNameFilterMenu";
import {
  filterCheckInArrayOfObject,
  paginateAndSort,
} from "../../../utils/helpers";
import { paginationDataAction } from "../../../redux/actions/customTableActions";
import TypographyInline from "../../../components/Typography/TypographyInline";

const PropertyListScreen = ({
  createPropertyHandler,
  handleDebounce,
  editPropModalHandler,
  selectedFieldType,
  setSelectedFieldType,
  selectedGroupName,
  setSelectedGroupName,
  searchValue,
  allAppliedFilters,
  setAllAppliedFilters,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDltPropModal, setOpenDltPropModal] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [dltBtnLoading, setDltBtnLoading] = useState(false);
  const [fieldTypeMenuAnchor, setFieldTypeMenuAnchor] = useState(null);
  const [groupNameMenuAnchor, setGroupNameMenuAnchor] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState("");
  const [filteredTableData, setFilteredTableData] = useState([]);

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );
  const allPropertiesFiltersSelector = useSelector(
    (state) => state.allPropertiesFilters
  );
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const page = paginationDataSelector?.data?.query?.page || 1;
  const pageSize = paginationDataSelector?.data?.query?.limit || 25;
  const sortBy = paginationDataSelector?.data?.query?.sort?.id || "";
  const sortOrder = paginationDataSelector?.data?.query?.sort?.direction;

  const tableBodyData = paginateAndSort(
    allAppliedFilters?.length
      ? filteredTableData
      : getPropertiesListSelector?.data?.hits,
    page,
    pageSize,
    sortBy,
    sortOrder,
    searchValue,
    propertiesFilterKeys
  );

  const propertyListTableData = {
    heads: propertyListTableHeads,
    body: tableBodyData?.records,
    operations: [
      {
        id: STRINGS.EDIT_SMALL,
        label: STRINGS.EDIT,
        iconLabel: ICON_LABELS.EDIT,
        function: (row) => editPropModalHandler(row),
      },
      {
        id: STRINGS.DELETE_SMALL,
        label: STRINGS.DELETE,
        iconLabel: ICON_LABELS.DELETE,
        function: (row) => deletePropModalHandler(row),
        variant: STRINGS.ERROR_SMALL,
      },
    ],
    actions: {},
    total_records: tableBodyData?.total_records,
    isLoading: getPropertiesListSelector?.loading,
    disable_key: STRINGS.OPERATION,
    is_selection_not_allowed: true,
  };

  const fieldTypeFilterMenuHandler = (e) => {
    setFieldTypeMenuAnchor(e?.currentTarget);
    setSelectedId(e?.currentTarget?.id);
  };

  const groupNameFilterMenuHandler = (e) => {
    setGroupNameMenuAnchor(e?.currentTarget);
    setSelectedId(e?.currentTarget?.id);
  };

  const fieldTypeFilterHandler = async (value) => {
    setIsFilterApplied(
      value?.length ? STRINGS.FIELD_TYPE_SMALL : allAppliedFilters[0] || ""
    );
    await dispatch(
      allPropertiesFilters(
        {
          query: {
            ...allPropertiesFiltersSelector?.data?.query,
            field_type: value,
          },
        },
        navigate
      )
    );

    await dispatch(
      paginationDataAction({
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          page: 1,
        },
      })
    );
  };

  const groupNameFilterHandler = async (value) => {
    setIsFilterApplied(
      value?.length ? STRINGS.GRP_NAME_SMALL : allAppliedFilters[0] || ""
    );
    await dispatch(
      allPropertiesFilters(
        {
          query: {
            ...allPropertiesFiltersSelector?.data?.query,
            grp_name: value,
          },
        },
        navigate
      )
    );
    await dispatch(
      paginationDataAction({
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          page: 1,
        },
      })
    );
  };

  const deletePropModalHandler = (row) => {
    setPropertyData(row);
    setOpenDltPropModal(true);
  };

  const deletePropertyHandler = async () => {
    let payload = {
      query: {
        id: propertyData?._id,
      },
    };
    try {
      setDltBtnLoading(true);
      const response = await deleteRequest(
        crmEndpoints.deleteProperty,
        payload,
        navigate
      );
      await dispatch(getPropertiesList({}, navigate));
      setDltBtnLoading(false);
      setOpenDltPropModal(!openDltPropModal);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      setDltBtnLoading(false);
      setOpenDltPropModal(!openDltPropModal);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const filterTabValue = [
    {
      id: STRINGS.FIELD_TYPE_SMALL,
      label: STRINGS.FIELD_TYPE,
      rightIcon: ICON_LABELS.ACTION_ARROW,
      // isDisabled: !getPropertiesListSelector?.data?.hits?.length,
      function: fieldTypeFilterMenuHandler,
      isActive: selectedId === STRINGS.FIELD_TYPE_SMALL,
      selectedValue: allPropertiesFiltersSelector?.data?.query?.field_type
        ?.length
        ? allPropertiesFiltersSelector?.data?.query?.field_type?.length
        : "",
    },
    {
      id: STRINGS.GROUP_NAME_SMALL,
      label: STRINGS.GROUP_NAME,
      rightIcon: ICON_LABELS.ACTION_ARROW,
      // isDisabled: !getPropertiesListSelector?.data?.hits?.length,
      function: groupNameFilterMenuHandler,
      isActive: selectedId === STRINGS.GROUP_NAME_SMALL,
      selectedValue: allPropertiesFiltersSelector?.data?.query?.grp_name?.length
        ? allPropertiesFiltersSelector?.data?.query?.grp_name?.length
        : "",
    },
  ];

  useEffect(() => {
    // In this we are storing all applied filters
    let selector = allPropertiesFiltersSelector?.data?.query;
    if (selector) {
      let allKeys = Object.keys(selector);
      let array = [];
      allKeys?.map((key) => {
        if (selector[key]?.length) {
          array.push(key);
        }
      });
      setAllAppliedFilters(array);
      // In this we are storing all applied filters

      // Here filter helper is called in this, we are passing filtered data when filter length is present else we will pass all data when filter length is less.
      let filteredData = filterCheckInArrayOfObject(
        array?.length > 1
          ? filteredTableData
          : getPropertiesListSelector?.data?.hits,
        {
          ...allPropertiesFiltersSelector?.data?.query,
        } || {},
        isFilterApplied
      );
      setFilteredTableData([...filteredData]);
      // Here filter helper is called, in this we are passing filtered data when filter length is present else we will pass all data when filter length is less.
    }
  }, [allPropertiesFiltersSelector?.data?.query]);

  return (
    <div className={classes.scrnMainCtnr}>
      {/* Search Bar, Filters and Create Group Button */}
      <div className={classes.tableUpprContainer}>
        <div className={classes.searhcFieldAndFilterContainer}>
          <div className={classes.searchField}>
            <SearchField
              size={"sm36"}
              placeholder={STRINGS.SEARCH}
              onChange={(e) => {
                handleDebounce(e);
              }}
            />
          </div>
          <div className={classes.filterContainer}>
            <div className={classes.filterLabel}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.SEMIBOLD}
                size={"sm"}
                label={STRINGS.FILTER}
              />
            </div>
            {filterTabValue?.map((filterTab, index) => {
              return (
                <FilterTab
                  label={filterTab?.label}
                  rightIcon={filterTab?.rightIcon}
                  disabled={filterTab?.isDisabled}
                  id={filterTab?.id}
                  key={index}
                  onClick={filterTab?.function}
                  selectedValue={filterTab?.selectedValue}
                  isActive={filterTab?.isActive}
                />
              );
            })}
          </div>
        </div>
        <div>
          <Button
            label={STRINGS.CREATE_PROPERTY}
            size={"sm36"}
            variant={"primary"}
            onClick={createPropertyHandler}
          />
        </div>
      </div>

      {/* Property Table */}
      <div className={classes.listTableContainer}>
        <CustomTable tableData={propertyListTableData} />
      </div>

      {/* Delete Property Modal */}
      <DeletePropertyModal
        open={openDltPropModal}
        close={() => setOpenDltPropModal(!openDltPropModal)}
        propertyName={propertyData?.property_name}
        deleteGroupHandler={deletePropertyHandler}
        btnLoading={dltBtnLoading}
      />

      {/* Field Type Filter Menu */}
      <FieldTypeFilterMenu
        open={Boolean(fieldTypeMenuAnchor)}
        anchorEl={fieldTypeMenuAnchor}
        setFieldTypeMenuAnchor={setFieldTypeMenuAnchor}
        selectedFieldType={selectedFieldType}
        setSelectedFieldType={setSelectedFieldType}
        fieldTypeFilterHandler={fieldTypeFilterHandler}
        setSelectedId={setSelectedId}
      />

      {/* Group Name Filter Menu */}
      <GroupNameFilterMenu
        anchorEl={groupNameMenuAnchor}
        setAnchorEl={setGroupNameMenuAnchor}
        groupNameFilterHandler={groupNameFilterHandler}
        setSelectedGroupName={setSelectedGroupName}
        selectedGroupName={selectedGroupName}
        setSelectedId={setSelectedId}
      />
    </div>
  );
};

export default PropertyListScreen;
