export const filterKeys = {
  import_key: "file_path",
  job_title: "job_title",
  campaign_id: "campaign_id",
  sequence_id: "sequence_id",
  job_title_not: "job_title_not",
  industry: "industry",
  industry_not: "industry_not",
  employee_number: "employee_number",
  seniority: "seniority",
  company_keywords: "company_keywords",
  company_keywords_not: "company_keywords_not",
  company_technologies: "company_technologies",
  company_technologies_not: "company_technologies_not",
  seo_description: "seo_description",
  seo_description_not: "seo_description_not",
  email_verification: "email_verification",
  exist_fields: "exist_fields",
  not_exist_fields: "not_exist_fields",
  company_ids: "company_ids",
  company_ids_not: "company_ids_not",
  company_city: "company_city",
  company_city_not: "company_city_not",
  company_state: "company_state",
  company_state_not: "company_state_not",
  company_country: "company_country",
  company_country_not: "company_country_not",
  person_city: "person_city",
  person_city_not: "person_city_not",
  person_state: "person_state",
  person_state_not: "person_state_not",
  person_country: "person_country",
  person_country_not: "person_country_not",
  company_name: "company_name",
  name: "name",
  deal_owner: "deal_owner",
  deal_amount: "deal_amount",
};

export const filterKeyTypes = {
  anyOf: "anyOf",
  notAnyOf: "notAnyOf",
  isKnown: "isKnown",
  isUnknown: "isUnknown",
};
