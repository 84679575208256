import React, { useEffect, useState } from 'react';
import classes from "./Forms.module.css";
import TypographyInline from '../../../components/Typography/TypographyInline';
import { GREY } from '../../../utils/constants/colors';
import { fontWeight } from '../../../utils/constants/UI';
import { STRINGS } from '../strings';
import { AutoCompleteCustom } from '../../../components/Autocomplete/AutoCompleteCustom';
import { EMITTER_ERROR, FORMS_TYPE_LIST } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { PROFILE_PATH } from '../../../utils/constants/routes';
import { AccountForm, ContactForm, DealForm } from './components';
import EventEmitter from '../../../utils/emitter/EventEmitter';
import { getRequest } from '../../../Apis';
import { formsEndpoints } from '../../../utils/constants/httpConstants';
import * as qs from "qs";

const Forms = () => {
    const navigate = useNavigate();

    const [formTypeId, setFormTypeId] = useState([FORMS_TYPE_LIST[0]?.id]);
    const [formTypeValue, setFormTypeValue] = useState([[FORMS_TYPE_LIST[0]?.label]]);
    const [formFields, setFormFields] = useState({});
    const [fieldsLoading, setFieldsLoading] = useState(false);

    // change the form type i.e, Contact, Compnay or Deal
    const formTypeHandler = (data) => {
        let url = `${PROFILE_PATH}?tab=forms&type=${data?.id?.[0]}`;
        navigate(url);
    }

    // handler for switching forms according to selected form type.
    const formTypeComponent = (type) => {
        switch (type?.[0]) {
            case STRINGS.DEALS_SMALL:
                return (<DealForm formFields={formFields} getFormsField={getFormsField} fieldsLoading={fieldsLoading} />);
            case STRINGS.ACCOUNTS_SMALL:
                return (<AccountForm formFields={formFields} getFormsField={getFormsField} fieldsLoading={fieldsLoading} />);
            default:
                return (<ContactForm formFields={formFields} getFormsField={getFormsField} fieldsLoading={fieldsLoading} />);
        }
    }

    // get form fields api handler.
    const getFormsField = async () => {
        try {
            setFieldsLoading(true);
            const response = await getRequest(navigate, formsEndpoints.getFormsField);
            setFormFields(response?.data?.data || {});
            setFieldsLoading(false);
        } catch (error) {
            setFieldsLoading(false);
            console.log("Error---->>>>>>>>> ", error);
            EventEmitter.emit(EMITTER_ERROR, error?.data?.message)
        }
    }

    useEffect(() => {
        const parsedURL = qs.parse(window.location.search?.slice(1));
        console.log(parsedURL);
        let obj = FORMS_TYPE_LIST?.filter(item => item?.id === parsedURL?.type)[0];
        let data = {
            id: [obj?.id || FORMS_TYPE_LIST?.[0]?.id],
            label: [obj?.label || FORMS_TYPE_LIST?.[0]?.label]
        }
        setFormTypeId([obj?.id || FORMS_TYPE_LIST?.[0]?.id]);
        setFormTypeValue([obj?.label || FORMS_TYPE_LIST?.[0]?.label]);
        formTypeHandler({ ...data });
        getFormsField();
    }, []);

    return (
        <div className={classes.formsMainScreenContainer} >
            {/* forms header cotainer */}
            <div className={classes.formsHeaderContainer}>
                <TypographyInline color={GREY.PRIMARY} size={"lg"} fontWeight={fontWeight.SEMIBOLD} label={STRINGS.FORMS} />
                <TypographyInline color={GREY.SECONDARY} size={"sm"} fontWeight={fontWeight.REGULAR} label={STRINGS.FORMS_DESC} />
            </div>

            {/* forms body container */}
            <div className={classes.formsBodyContainer}>
                {/* select form type dropdown */}
                <div className={classes.selectFormContainer} >
                    <div>
                        <TypographyInline color={GREY.PRIMARY} size={"sm"} fontWeight={fontWeight.SEMIBOLD} label={STRINGS.SELECT_FORM} />
                    </div>
                    <div className={classes.selectFormType}>
                        <AutoCompleteCustom size='sm36' items={FORMS_TYPE_LIST} idState={formTypeId} setIdState={setFormTypeId} valueState={formTypeValue} setValueState={setFormTypeValue} dropdownId='form-type-select' onChange={(data) => formTypeHandler(data)} />
                    </div>
                </div>

                {/* Component Rendering according to form type selection. */}
                {formTypeComponent(formTypeId)}


            </div>
        </div>
    )
}

export default Forms