import React from "react";
import { Box } from "@mui/system";
import { CompanyNameCustomAutoComplete } from "../Autocomplete";
import { styles } from "./styles";
import { leadsEndpoints } from "../../utils/constants/httpConstants";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  allFilters,
  filterHeaderCompanyName,
} from "../../redux/actions/filterActions";
import { initialFilterPayload } from "../../redux/store/storeConstants";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import { STRINGS } from "./strings";
import { requiredKeysOfStorage } from "../../utils/constants";

const FilterTypeCompanyName = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedJobFilter = useSelector(
    (state) => state?.filterHeaderCompanyName?.payload
  );
  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);
  const tableEndpointChangeSelector = useSelector(
    (state) => state.tableEndpointChange
  );
  const companyTableEndpointChangeSelector = useSelector(
    (state) => state.companyTableEndpointChange
  );
  let isSavedContactOrCompany = false;

  if (
    companyTableEndpointChangeSelector?.payload === "Saved Companies" ||
    tableEndpointChangeSelector?.payload === "Saved Prospects"
  ) {
    isSavedContactOrCompany = true;
  }

  const handleCompanyNameChange = (item, filter) => {
    let payload = {
      ...selectedJobFilter,
      [item]: !selectedJobFilter[item],
      isKnown: false,
      isUnknown: false,
    };

    // clear data if column is empty.
    const unCheckPayload = {};
    if (!payload?.anyOf) {
      unCheckPayload["company_ids"] = [];
      localStorage.removeItem("company_ids");
    }
    if (!payload?.notAnyOf) {
      localStorage.removeItem("company_ids_not");
      unCheckPayload["company_ids_not"] = [];
    }

    // clear data if column is empty.

    dispatch(
      allFilters({
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });
    dispatch(filterHeaderCompanyName(payload));
  };

  const handleExistFields = (
    existing_type,
    filter,
    filterKey,
    notFilterKey
  ) => {
    // existing_type = isKnown/ isUnknown
    // filter = job_title/industry/ seniority..

    // clearing localStorage to remove it from applied filters.
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!requiredKeysOfStorage?.includes(key)) {
        localStorage.removeItem(key);
      }
    }
    let payload = {
      ...initialFilterPayload?.payload,
      [existing_type]: !selectedJobFilter[existing_type],
    };

    payload = JSON.parse(JSON.stringify(payload));
    dispatch(filterHeaderCompanyName(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        company_ids: [],
        company_ids_not: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  console.log("selectedJobFilter  ", selectedJobFilter);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containAllFilter}>
        <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.anyOf}
            onClick={() => handleCompanyNameChange("anyOf", "company_name")}
            label={STRINGS.IS_ANY_OF}
          />
        </Box>

        {selectedJobFilter?.anyOf ? (
          <Box component="form">
            <CompanyNameCustomAutoComplete
              filter="company_ids"
              endPoint={
                isSavedContactOrCompany
                  ? leadsEndpoints.getSavedCompanyName
                  : leadsEndpoints.getCompanyName
              }
              type=""
            />
          </Box>
        ) : null}

        <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.notAnyOf}
            onClick={() => handleCompanyNameChange("notAnyOf", "company_name")}
            label={STRINGS.IS_NOT_ANY_OF}
          />
        </Box>

        {selectedJobFilter?.notAnyOf ? (
          <Box>
            {" "}
            <CompanyNameCustomAutoComplete
              filter="company_ids"
              endPoint={
                isSavedContactOrCompany
                  ? leadsEndpoints.getSavedCompanyName
                  : leadsEndpoints.getCompanyName
              }
              type="_not"
            />
          </Box>
        ) : null}

        <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.isKnown}
            onClick={() =>
              handleExistFields(
                "isKnown",
                "company_name",
                "exist_fields",
                "not_exist_fields"
              )
            }
            label={STRINGS.IS_KNOWN}
          />
        </Box>
        <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.isUnknown}
            onClick={() =>
              handleExistFields(
                "isUnknown",
                "company_name",
                "not_exist_fields",
                "exist_fields"
              )
            }
            label={STRINGS.IS_UNKNOWN}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterTypeCompanyName;
