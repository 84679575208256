import Router from "../Router";
import { ThemeProvider } from "@mui/private-theming";
import { Theme } from "../theme";
import { SnackbarProvider } from "notistack";
import { AUTO_HIDE_DURATION, SNACKBAR_CONFIG } from "../utils/constants";
import {
  SNACKBAR_ERROR_ICON,
  SNACKBAR_SUCCESS_ICON,
} from "../utils/constants/assets";
import { SnackbarIcon } from "../components/Snackbar";
import { styles } from "./styles";
import { useLocation } from "react-router-dom";
import React from "react";
import { paginationDataAction } from "../redux/actions/customTableActions";
import { useDispatch } from "react-redux";
import * as qs from "qs";

export const Container = () => {
  const location = useLocation();
  const theme = Theme();
  const classes = styles();
  const dispatch = useDispatch();

  const res = qs.parse(window.location?.search?.slice(1));

  React.useEffect(() => {
    // This function execution is for dispatching location based events.
    (() => {
      dispatch(
        paginationDataAction({
          checkedRow: [],
          query: {},
        })
      );
    })();
  }, [location.pathname]);

  React.useEffect(() => {
    // This function execution is for dispatching location based events.
    (() => {

      const query ={};
      if(res?.page){
        query.page = parseInt(res?.page || 1)
      }

      if(res?.limit){
        query.limit = parseInt(res?.limit || 10)
      }

      if( res?.sort ){
        query.sort = res?.sort ? { id: res?.sort, direction: res?.direction } : {}
      }


      dispatch(
        paginationDataAction({
          checkedRow: [],
          query: query,
        })
      );
    })();
  }, []);

  // Cron for testing styles.

  
  

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        classes={{
          variantError: classes.error,
          variantSuccess: classes.success,
        }}
        maxSnack={1}
        anchorOrigin={SNACKBAR_CONFIG.snackBarPlacement}
        autoHideDuration={AUTO_HIDE_DURATION}
        iconVariant={{
          success: <SnackbarIcon src={SNACKBAR_SUCCESS_ICON} />,
          error: <SnackbarIcon src={SNACKBAR_ERROR_ICON} />,
        }}
      >
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
};
