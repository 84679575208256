import React, { useState, useRef, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import { ICON_LABELS, fontSize } from "../../utils/constants/UI";
import { GREY } from "../../utils/constants/colors";
import { ExpandMoreIcon } from "../Icon/MuiIcons";
import { STRINGS } from "./strings";
import { SCROLL_BAR } from "../../utils/constants/UI/uiConstants";
import { dashboardEndpoints } from "../../utils/constants/httpConstants";
import { getRequest } from "../../Apis";
import { useNavigate } from "react-router-dom";
import SearchField from "./SearchField";
import { DEBOUNCE_DELAY } from "../../utils/constants";
import { debounceHelper } from "../../utils/helpers";
import IconComponent from "../Icon/IconComponent";
import classesCSS from "./Input.module.css";

export const styles =
{
  paperMenu: {
    maxHeight: "30vh",
    // width: window.innerWidth > 1600 ? "32.042vw" : "30.042vw",
    width: "18.073vw",
    marginTop: "3px",
    overflowY: "auto",
    "@media (max-width:1550px)": {
      width: "30.042vw",
    },
    ...SCROLL_BAR,
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track ": {
      innerHeight: "5px",
      outerHeight: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: GREY.DISABLED,
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      backgroundColor: GREY.QUATINARY,
    },
  },
  valuesInput: {
    padding: "8px 10px ",
    color: "#626b7e",
    width: "15.542vw",
    height: "35px",
    border: "none",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "1.4375em",
    borderRadius: "6px",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  inputIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #d3dae3",
    borderRadius: "6px",
    paddingRight: "8px",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#626B7E",
    // marginRight:"8px"
  },
  menuList: {
    height: "100%",
    width: "100%"
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 44px - 36px)",
  },
  iconStyle: {
    fontSize: "16px",
    color: GREY.TERTIARY,
    marginTop: "1px",
  },
  checkboxLabel: {
    padding: "8px 10px",
    overflow: "hidden",
    width: "100%"
  }
};

const MultipleSelectCheckmarks = ({
  items,
  margin,
  setSelectedListValue,
  selectedList,
  type,
  ...props
}) => {
  const classes = styles;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValues, setSelectedValues] = useState(selectedList || []);
  const [selectAll, setSelectAll] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState(items);
  const [displayValues, setDisplayValues] = useState([]);

  const searchInputRef = useRef(null);
  useEffect(() => {
    if (selectedList !== undefined) {
      setSelectedValues(selectedList);
      let tempItems = [...options];
      let filtersIdName = tempItems
        ?.filter((item) => selectedList.includes(item.id || item._id))
        ?.map((item) => {
          return item.name;
        });
      setDisplayValues(filtersIdName);
      setSelectedListValue(selectedList)
      setSelectAll(options?.length === selectedList?.length);
    }
  }, [selectedList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSearchValue(""); // Clear search input when opening the menu
    searchInputRef?.current?.focus(); // Focus on the search input
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectChange = (event) => {
    const { value, checked } = event.target;
    if (value === "All") {
      if (checked) {
        let selectedListVal = options.map(
          (option) => option.value || option?.id || option?._id
        );
        setSelectedValues(selectedListVal);
        setSelectedListValue(selectedListVal);
        let tempItems = [...options];
        let filtersIdName = tempItems
          .filter((item) => selectedListVal.includes(item.id || item._id))
          ?.map((item) => {
            return item.name;
          });
        setDisplayValues(filtersIdName);
        setSelectAll(true);
      } else {
        setSelectedValues([]);
        setSelectedListValue([]);
        setDisplayValues([]);
        setSelectAll(false);
      }
    } else {
      const updatedValues = [...selectedValues];
      if (checked) {
        updatedValues.push(value);
        let tempItems = [...options];
        let filtersIdName = tempItems
          .filter((item) => updatedValues.includes(item.id || item._id))
          ?.map((item) => {
            return item.name;
          });
        setDisplayValues(filtersIdName);
      } else {
        const index = updatedValues.indexOf(value);
        if (index !== -1) {
          updatedValues.splice(index, 1);
          let tempItems = [...options];
          let filtersIdName = tempItems
            .filter((item) => updatedValues.includes(item.id || item._id))
            ?.map((item) => {
              return item.name;
            });
          setDisplayValues(filtersIdName);
        }
      }
      setSelectedValues(updatedValues);
      setSelectedListValue(updatedValues);
      setSelectAll(updatedValues.length === options.length);
    }
  };


  const handleSearchChange = async (event) => {
    if (type[0] === "Sequence Report") {
      const responseSequence = await getRequest(
        navigate,
        `${dashboardEndpoints.getSequenceList}?page=${1}&size=${10}&name=${event.target.value}`
      )
      setOptions(responseSequence?.data?.data)
    }
    else {
      const responseCampaign = await getRequest(
        navigate,
        `${dashboardEndpoints.getCampaignList}?page=${1}&size=${10}&name=${event.target.value}`
      );
      setOptions(responseCampaign?.data?.data)
    }



  };
  const debounceSearch = debounceHelper(handleSearchChange, DEBOUNCE_DELAY);


  return (
    <div id="customCheck">
      <div onClick={handleClick} style={classes.inputIconContainer}>
        <div>
          <input
            variant="outlined"
            placeholder="Select Campaigns"
            value={displayValues}
            style={classes.valuesInput}
            readOnly
          />
        </div>
        <div style={classes.iconContainer}>
          <IconComponent
            iconLabel={ICON_LABELS.ACTION_ARROW}
            style={styles.iconStyle}
          //   onClick={props?.onRightClick}
          />
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // classes={{ paper: props?.paperMenuClass || classes.paperMenu,id: "modal_body" }}
        PaperProps={{ style: props?.papermenuclass || classes.paperMenu, id: "modal_body" }}
        MenuListProps={{ style: classes.menuList }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className={classesCSS.listHoverClass} style={{ padding: "0px", width: "100%" }} onKeyDown={(e) => e.stopPropagation()}>
          <div style={{ ...styles.checkboxLabel }} >
            <SearchField
              size={"sm36"}
              LeftIcon={"search"}
              onChange={debounceSearch}
              placeholder={"Search Campaigns"}
            /></div>
        </div>
        <div className={classesCSS.listHoverClass} onChange={options?.length ? handleSelectChange : null} style={{ padding: "8px 10px" }}>
          <div style={{ ...styles.checkboxLabel, padding: 0 }} >
            <Checkbox
              size={fontSize.SM}
              value="All"
              checked={selectAll}
              disabled={!options?.length}
              // onChange={options.length ? handleSelectChange : null}
              label={"Select All"}
            /></div>
        </div>
        {options.length > 0 ? (
          options.map((option) => {
            return (
              <div className={classesCSS.listHoverClass} style={{ padding: "0px" }} onChange={handleSelectChange} key={option?.value || option?.id || option?._id}>
                <div style={styles.checkboxLabel} >
                  <Checkbox
                    size={fontSize.SM}
                    value={option?.value || option?.id || option?._id}
                    checked={selectedValues.includes(
                      option?.value || option?.id || option?._id
                    )}
                    // onChange={handleSelectChange}
                    label={option?.label || option?.name}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div
            className={classesCSS.listHoverClass}
            ContainerProps={{ style: classes.listItem }}
            style={classes.listItem}
            disabled
          >
            {STRINGS.NO_RECORD_FOUND}
          </div>
        )}
      </Menu>
    </div>
  );
};

export default MultipleSelectCheckmarks;
