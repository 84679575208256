import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BORDER, BRAND, GREY } from "../../utils/constants/colors";
import { fontSize } from "../../utils/constants/UI";
import { innerBottomBorder } from "../../utils/helpers";

const SIZE_MAPPING = {
  xs34: {
    padding: "0px 14px 5.5px 0px !important",
    fontSize: fontSize.XS,
  },
  sm36: {
    padding: "0px 14px 6.5px 0px !important",
    fontSize: fontSize.SM,
  },
  md40: {
    padding: "0px 14px 8.5px 0px !important",
    fontSize: fontSize.MD,
  },
  lg44: {
    padding: "0px 14px 10.5px 0px !important",
    fontSize: fontSize.LG,
  },
};

const styles = makeStyles({
  datePickerMainContainer: {
    width: "100%",
  },
  dateInput: {
    padding: (props) => SIZE_MAPPING[props?.size]?.padding,
    fontSize: (props) => `${SIZE_MAPPING[props?.size]?.fontSize} !important`,
    color: `${GREY.PRIMARY} !important`,
  },
  paeprRoot: {
    marginTop: "2px !important",
    marginLeft: "68px !important",
    width: (props) => props?.paperWidth || "100% !important",
    "& .MuiPickersDay-root": {
      "&.Mui-selected": {
        backgroundColor: `${BRAND.PRIMARY} !important`,
      },
    },
  },
  inputField: {},
});

export default function CustomDatePickerWoBorder({
  size,
  paperWidth,
  ...props
}) {
  const classes = styles({ size, paperWidth, ...props });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        inputFormat="DD/MM/YYYY"
        className={classes.datePickerMainContainer}
        PaperProps={{ classes: { root: classes.paeprRoot } }}
        InputProps={{
          classes: { input: classes.dateInput },
        }}
        // props
        renderInput={(params) => (
          <TextField
            id="custom-datepicker"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& svg": {
                  width: "16px !important",
                  height: "16px !important",
                },
                "& fieldset": {
                  borderColor: BORDER.HOVER,
                  border: "none",
                },
                "&:hover input": {
                  color: "#FF4848 !important",
                },
                "&:hover svg": {
                  color: "#FF4848 !important",
                },
                "&:hover fieldset": {
                  // borderBottom: `1px solid ${GREY.TERTIARY}`,
                  boxShadow: innerBottomBorder(BORDER.DARK, "1px"),
                  borderRadius: "0px",
                },
                "&.Mui-focused fieldset": {
                  boxShadow: innerBottomBorder(GREY.PRIMARY, "1px"),
                  borderRadius: "0px",
                  // borderBottom: `1px solid ${GREY.PRIMARY}`,
                },
              },
            }}
            {...params}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}
