import { LIST_FOLDER_REQUEST,LIST_FOLDER_SUCCESS,LIST_FOLDER_FAIL } from "../constants/listFolderConstants";
import { getRequest, postRequest } from "../../Apis";
import { LOGIN_PATH } from "../../utils/constants/routes";
export const getFolderList=(sentQuery,URL,navigate)=>async(dispatch)=>{
    try{
          dispatch({type:LIST_FOLDER_REQUEST});
          const response=await postRequest(navigate,URL,sentQuery);
          dispatch({
            type:LIST_FOLDER_SUCCESS,
            payload:response?.data?.data
        })
    }
    catch(err){
        if(err?.status===401){
            navigate(LOGIN_PATH)
        }
        dispatch(
            {
                type:LIST_FOLDER_FAIL,
                payload:err?.data
            }
        )
    }
}