import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { ACTIONS, BASE, BORDER, GREY } from "../../utils/constants/colors";
import Typography from "../Typography/Typography";
import { fontWeight, ICON_LABELS } from "../../utils/constants/UI";
import IconComponent from "../Icon/IconComponent";
import classes from "./CustomDialog.module.css";
import { borderRadius, fontSize } from "../../utils/constants/UI/uiConstants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CustomDialog({
  children,
  header,
  footer,
  close,
  remove_padding = false,
  ...props
}) {
  const styles = {
    paeprContainer: {
      height: "100%",
      width: props?.width || "50vw",
      margin: "0px",
      maxHeight: "100vh",
      maxWidth: "100vw",
      marginLeft: "auto",
      borderRadius: borderRadius.none,
      marginRight: props?.marginRight || "0px ",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px 20px",
      background: GREY.QUATINARY,
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "3px",
      borderRadius: borderRadius.primary,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: ACTIONS.DISABLED,
      },
    },
    bodyContainer: {
      padding: remove_padding ? "0px" : "16px",
      height: footer ? "calc(100% - 130px)" : "calc(100% - 62px)",
    },
    footerContainer: {
      padding: "16px 20px",
      borderTop: `1px solid ${BORDER.DEFAULT}`,
    },
    rootDialog: {
      backgroundColor: "rgba(0, 0, 0, 0.5) !important", // Set your desired background color here
    },
  };
  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={props?.onClose}
      aria-describedby="alert-dialog-slide-description"
      className={classes.rootDialog}
      PaperProps={{
        style: styles.paeprContainer,
      }}
      componentsProps={{ backdrop: { style: styles.rootDialog } }}
      {...props}
    >
      {/* Header */}
      <div style={styles.headerContainer}>
        <Typography
          color={BASE.WHITE}
          fontWeight={fontWeight.SEMIBOLD}
          label={header?.heading?.label || ""}
          size="md"
          id={header?.heading?.id}
        />
        <div className={classes.iconContainer} style={styles.iconContainer}>
          <IconComponent
            id={classes.iconContainer}
            color={GREY.TERTIARY}
            fontSize={fontSize.DXS}
            iconLabel={ICON_LABELS.CLOSE_BUTTON}
            onClick={props?.onClose || close}
          />
        </div>
      </div>
      {/* Header */}

      {/* Body */}
      <div id={"modal_body"} style={styles.bodyContainer}>
        {children}
      </div>
      {/* Body */}

      {/* Footer */}
      {footer && <div style={styles.footerContainer}>{footer}</div>}
      {/* Footer */}
    </Dialog>
  );
}
