import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  minHeight: "unset !important",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(45deg)",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0px !important",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
}));
