import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { contactListEndPoints } from "../../../utils/constants/httpConstants";
import { filterQuery, getErrorMessage } from "../../../utils/helpers";
import { postRequest } from "../../../Apis";
import { putRequest } from "../../../Apis/apiWrapper";
import * as qs from "qs";
import { getContactListData } from "../../../redux/actions/contactListactions";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import RadioButton from "../../../components/RadioButton/RadioButton";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import InputField from "../../../components/InputFields/InputField";
import { STRINGS } from "../../Marketing/strings";
import classes from "./LeadsComponent.module.css";

const CreateListModal = ({
  open,
  setOpen,
  recordIds = [],
  bulkSelected = false,
  companyList = false,
  onCreate,
  listId,
  listNameValue,
  view,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = React.useState(
    view ? view : STRINGS.PRIVATE_ID
  );
  const { id } = useParams();
  const [listName, setListName] = useState(listNameValue ? listNameValue : "");
  const [errMsg, setErrMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const filteredQuery = filterQuery(
    useSelector((state) => state?.allFilters?.payload)
  );
  const tablePayload = useSelector((state) => state?.tableDataFetch);

  const tableEndpointChangeSelector = useSelector(
    (state) => state?.tableEndpointChange
  );
  const radioButtonData = [
    { id: STRINGS.PRIVATE_ID, label: STRINGS.PRIVATE_LABEL },

    { id: STRINGS.TEAM_ID, label: STRINGS.TEAM_LABEL },
  ];

  const handleCreate = async (e) => {
    e.preventDefault();
    if (listName?.trim()?.length === 0) {
      setErrMsg(STRINGS.REQUIRED);
      return;
    } else if (listName?.trim()?.length < 3) {
      setErrMsg(STRINGS.LIST_SHOULDBE_THREE);
      return;
    } else if (listName?.trim()?.length > 50) {
      setErrMsg(STRINGS.LIST_SHOULDBE_FIFTY);
      return;
    } else {
      setErrMsg("");
      const URL = companyList
        ? contactListEndPoints.companyCreateList
        : contactListEndPoints.createList;
      let data = {
        list_name: encodeURIComponent(listName),
        permission: selectedValue,
        type: STRINGS.FILE,
        parent: queryObject.folder_id,
      };

      if (recordIds?.length) {
        data.record_ids = recordIds;
      }

      // check for emails and add in key.
      const email_ids = tablePayload?.columns
        ?.filter((item) => recordIds.includes(item?._id) && item.email)
        ?.map((item) => item?.email);
      data.email_ids = email_ids || [];

      // if bulkselected .
      if (bulkSelected) {
        data = { ...data, ...filteredQuery, is_bulk: true };
        delete data.record_ids;
        if (tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT) {
          delete data.is_bulk;
          data.is_save_contact_bulk = true;
        }
      }
      if (tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT) {
        data.is_saved = true;
      }
      if (listId) {
        try {
          setLoading(true);
          const payload = {
            list_id: listId,
            list_name: encodeURIComponent(listName),
            type: STRINGS.FILE,
            permission: selectedValue,
            parent: id,
          };
          const response = await putRequest(
            navigate,
            contactListEndPoints.editList,
            payload
          );
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          if (onCreate) {
            onCreate();
          }
          dispatch(
            getContactListData({
              list_name: "",
              parent: id,
            })
          );

          setListName("");
          setOpen(false);
          setLoading(false);
        } catch (error) {
          setErrMsg(
            getErrorMessage(
              error?.data?.data?.errors || {},
              error?.data?.message
            )
          );
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const response = await postRequest(navigate, URL, data);
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          dispatch(
            getContactListData({
              list_name: "",
              parent: id,
            })
          );
          setListName("");
          if (onCreate) {
            onCreate();
          }
          setOpen(false);
        } catch (error) {
          setErrMsg(
            getErrorMessage(
              error?.data?.data?.errors || {},
              error?.data?.message
            )
          );
        } finally {
          setLoading(false);
        }
      }
    }
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: STRINGS.CREATE_LISST,
    },
  };
  const modalBodyData = (
    <form onSubmit={handleCreate}>
      <div className={classes.modalBodyContainer}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.LIST_NAME}
          isRequired
        />
        <InputField
          value={listName}
          placeholder={STRINGS.TYPELIST}
          size={"sm36"}
          onChange={(e) => setListName(e.target.value)}
          variant={errMsg?.length ? STRINGS.ERROR : STRINGS.DEFAULT}
          errormessage={errMsg?.length ? errMsg : ""}
        />
        <div className={classes.modalPadding}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.SHARED_WITH}
          />
        </div>
        <div className={classes.modalRadio}>
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </div>
      </div>
    </form>
  );

  const modalFooterLeftData = <div></div>;
  const modalFooterRightData = (
    <div className={classes.GapDisplay}>
      <Button
        label={STRINGS.COMMON_CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => {
          setOpen(false);
          setErrMsg("");
          setListName("");
        }}
      />
      <Button
        label={STRINGS.COMMON_CREATE}
        size={"sm36"}
        variant={"primary"}
        onClick={handleCreate}
        isLoading={loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default CreateListModal;
