import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { allFilters } from "../../redux/actions/filterActions";
import { styles } from "./styles";
import IconComponent from "../Icon/IconComponent";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import { BRAND, GREY } from "../../utils/constants/colors";
import TypographyInline from "../Typography/TypographyInline";
import { STRINGS } from "../strings";

const MultiValueFilter = ({
  value,
  filter,
  type = "",
  filterKey,
  clearAction,
  defaultActionValue,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);

  const handleRemoveFilter = () => {
    // if in exist or not exist fields fields
    if (
      !isNaN(allFiltersSelector?.exist_fields?.indexOf(filterKey)) &&
      allFiltersSelector?.exist_fields?.indexOf(filterKey) !== -1
    ) {
      const newExistFields = allFiltersSelector?.exist_fields?.filter(
        (item) => item !== filterKey
      );

      const query = { exist_fields: newExistFields };
      dispatch(allFilters(query));
      // console.log({defaultActionValue})

      dispatch(clearAction(defaultActionValue));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...query,
        }).toString(),
      });
    } else if (
      !isNaN(allFiltersSelector?.not_exist_fields?.indexOf(filterKey)) &&
      allFiltersSelector?.not_exist_fields?.indexOf(filterKey) !== -1
    ) {
      const newExistFields = allFiltersSelector?.not_exist_fields?.filter(
        (item) => item !== filterKey
      );
      const query = { not_exist_fields: newExistFields };
      dispatch(allFilters(query));

      // console.log({defaultActionValue})
      dispatch(clearAction(defaultActionValue));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...query,
        }).toString(),
      });
    } else {
      let query = {};
      if (filterKey?.includes("_not") || filterKey?.includes("_all")) {
        query = { [filterKey]: [] }
      } else {
        query = { [filterKey + type]: [] }
      }
      dispatch(allFilters(query));
      // console.log({defaultActionValue})

      dispatch(clearAction(defaultActionValue));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          [filterKey + type]: [],
        }).toString(),
      });
    }

    // each filter mapping reove
  };

  return (
    <Box sx={styles.selectedFilterBox}>
      <div style={styles.cancelIcon} onClick={handleRemoveFilter}>
        <IconComponent
          iconLabel={ICON_LABELS.CANCEL}
          color={GREY.TERTIARY}
          fontSize={fontSize.MD}
        />
      </div>
      <div style={styles.filterValues}>
        <TypographyInline
          label={filter}
          size={"sm"}
          fontWeight={fontWeight.SEMIBOLD}
          color={GREY.PRIMARY}
          isNoWrap={true}
          isCapitalise={true}
        />
        <TypographyInline
          label={
            type === "_all" ? STRINGS.IS_ALL_EQUAL_TO : type === "_not" ? STRINGS.IS_NOT_EQUAL_TO : STRINGS.IS_EQUAL_TO
          }
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
          isNoWrap={true}
        />
        {value?.map((item, index) => {
          return (
            <TypographyInline
              label={`${item}${value?.length > index + 1 ? ", " : "."}`}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={BRAND.PRIMARY}
              isNoWrap={true}
            />
          );
        })}
      </div>
    </Box>
  );
};

export default MultiValueFilter;
