import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { styles } from "./styles";
import {
  BlacklistContactModal,
  BlacklistDoaminModal,
  BlacklistMappingFieldModal,
  CommonBlacklistTable,
} from "./components";
import {
  EMAIL_TYPES,
  EMITTER_ERROR,
  IMPORT_BLACKLIST_FILES_HEADS,
  IMPORT_BLACKLIST_OPTIONS,
  IMPORT_CONTACTS_KEYS,
  IMPORT_DOMAINS_KEYS,
  SOURCES_TYPES,
  STATUS_TYPES,
} from "../../utils/constants";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import LanguageIcon from "@mui/icons-material/Language";
import { getRequest } from "../../Apis";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { importEndpoints } from "../../utils/constants/httpConstants";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { getErrorMessage, JSONtoQueryString } from "../../utils/helpers";
import {
  CircularLoader,
  EmptyScreen,
  ErrorMessage,
  FullScreenLoader,
} from "../../components";
import { useSelector } from "react-redux";
import { fromToDateValidation } from "../../utils/validations/inputValidations";
import { GREY } from "../../utils/constants/colors";
import TypographyInline from "../../components/Typography/TypographyInline";
import { STRINGS } from "./strings";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import { Button } from "../../components/Buttons";
import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import { AutoCompleteCustom } from "../../components/Autocomplete/AutoCompleteCustom";
import InputField from "../../components/InputFields/InputField";
import BlacklistDeleteModal from "./BlacklistDeleteModal";
import SearchField from "../../components/InputFields/SearchField";
import CustomStyledMenu from "../../components/Menu/CustomStyledMenu";
import BlackListEmptyScreen from "./BlackListEmptyScreen";
import { SEARCH_PROSPECTS_IMAGE } from "../../utils/constants/assets";

const BlacklistImportScreen = ({ closeTour, isTourOpen }) => {
  const location = useLocation();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statusValue, setStatusValue] = useState(
    [STATUS_TYPES[0]?.label] || []
  );
  const [idstatus, setIdStatus] = useState([STATUS_TYPES[0]?.id] || []);
  const [statusValueSource, setStatusValueSource] = useState(
    [SOURCES_TYPES[0]?.label] || []
  );

  const [idstatusSource, setIdStatusSource] = useState(
    [SOURCES_TYPES[0]?.id] || []
  );
  const [statusValueEmail, setStatusValueEmail] = useState(
    [EMAIL_TYPES[0]?.label] || []
  );
  const [idstatusEmail, setIdStatusEmail] = useState(
    [EMAIL_TYPES[0]?.id] || []
  );
  const navigate = useNavigate();
  const [showMappingModal, setShowMappingModal] = useState(false);
  const peopleDynamicFields = IMPORT_BLACKLIST_OPTIONS;
  const [showContactModal, setShowContactModal] = useState(false);
  const [showDomainModal, setShowDomainModal] = useState(false);
  const [showAllFiles, setShowAllFiles] = useState(false);
  const [loading, setLoading] = useState(true);
  const [domainName, setDomainName] = useState("");
  const [fileName, setFileName] = useState("");
  const [open1, setOpen1] = useState(false);
  const [selectedBlacklistLabel, setSelectedBlacklistLabel] = useState("");
  const [searching, setSearching] = useState(true);
  const [selectedHeads, setSelectedHeads] = useState(null);
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxRecords, setMaxRecords] = useState(-1);
  const [isEmpty, setIsEmpty] = useState(false)
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState({});
  const [validation, setValidation] = useState("");

  const [delValue, setDelvalue] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const userDetails = userDetailsSelector?.payload;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setValidation("");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ tab: STRINGS.BLACKLIST }).toString(),
    });
    handleBlackListSelection(filesAvailableList?.[0]);
  }, [navigate]);

  const handleFileUpload = async (e) => {
    const uploadedFile = e?.dataTransfer?.files?.[0] || e?.target?.files?.[0];
    if (uploadedFile?.type !== "text/csv") {
      EventEmitter.emit(EMITTER_ERROR, STRINGS.PLEASE_ADD_CSV);
      return;
    }
    setFile(uploadedFile);
    if (uploadedFile) {
      setShowMappingModal(true);
      e.target.value = "";
      e.dataTransfer.files[0] = "";
      e.target.files[0] = "";
    }
  };

  const filesAvailableList = [
    {
      key: STRINGS.BLOCKED_EMAILS,
      searchKey: STRINGS.EMAIL_SMALL,
      endPoint: importEndpoints.getBlackListEmail,
      keys: IMPORT_CONTACTS_KEYS,
      label: STRINGS.EMAILS_CAPS,
      button: (
        <div>
          <Button
            LeftIcon={ICON_LABELS.ADD}
            label={STRINGS.ADD_EMAIL}
            size={"sm36"}
            variant={"primary"}
            onClick={() =>
              userDetails?.role == STRINGS.ADMIN_USER
                ? setShowContactModal(true)
                : ""
            }
            disabled={userDetails?.role == STRINGS.ADMIN_USER ? false : true}
          />
        </div>
      ),
      button1: (
        <div>
          <Button
            LeftIcon={ICON_LABELS.ADD}
            label={STRINGS.ADD_FILE}
            size={"sm36"}
            variant={"primary"}
            disabled={userDetails?.role == STRINGS.ADMIN_USER ? false : true}
            onClick={() => {
              document.getElementById("file-upload").click();
            }}
          />
          <input
            id="file-upload"
            type="file"
            style={{ display: "none", cursor: "pointer" }}
            onChange={handleFileUpload}
            accept=".csv"
          />
        </div>
      ),
    },
    {
      key: STRINGS.BLOCKED_DOMAINS,
      searchKey: STRINGS.DOMAIN_NAME_SMALL,
      endPoint: importEndpoints.getBlackListDomains,
      keys: IMPORT_DOMAINS_KEYS,
      label: STRINGS.DOMAIN_CAPS,
      button: (
        <div>
          <Button
            LeftIcon={ICON_LABELS.ADD}
            label={STRINGS.DOMAIN}
            size={"sm36"}
            variant={"primary"}
            onClick={() =>
              userDetails?.role == STRINGS.ADMIN_USER
                ? setShowDomainModal(true)
                : ""
            }
            disabled={userDetails?.role == STRINGS.ADMIN_USER ? false : true}
          />
        </div>
      ),
      button1: (
        <div>
          <Button
            LeftIcon={ICON_LABELS.ADD}
            label={STRINGS.ADD_FILE}
            size={"sm36"}
            variant={"primary"}
            disabled={userDetails?.role == STRINGS.ADMIN_USER ? false : true}
            onClick={() => document.getElementById("file-upload").click()}
          />
          <input
            id="file-upload"
            type="file"
            style={{ display: "none", cursor: "pointer" }}
            onChange={handleFileUpload}
            accept=".csv"
          />
        </div>
      ),
    },
    {
      key: STRINGS.IMPORTED_FILES,
      searchKey: STRINGS.FILE_NAME_SMALL,
      endPoint: importEndpoints.getImportedFiles,
      keys: IMPORT_BLACKLIST_FILES_HEADS,
      label: STRINGS.CSV_FILES,
    },
  ];
  // All Filters
  const userNameFilter = (
    <Box sx={styles.GAP}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.DOMAIN_NAME_SMALL}
      />

      <InputField
        size={"sm36"}
        value={domainName}
        onChange={(e) => setDomainName(e.target.value)}
      />
    </Box>
  );

  const fileNameFilter = (
    <Box sx={styles.GAP}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.FILE_NAME}
      />

      <InputField
        size={"sm36"}
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />
    </Box>
  );

  const dateFilter = (
    <Box>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.DATE_RANGE}
      />
      <Box sx={styles.flexx}>
        <div style={styles.filterinputt}>
          {" "}
          <CustomDatePicker
            size={"sm36"}
            value={startDate || null}
            onChange={(dateValue) => setStartDate(dateValue)}
          />
        </div>
        <div style={styles.filterinputt}>
          {" "}
          <CustomDatePicker
            size={"sm36"}
            value={endDate || null}
            onChange={(dateValue) => setEndDate(dateValue)}
          />
        </div>
      </Box>
      {validation ? <ErrorMessage message={validation} /> : null}
    </Box>
  );

  const statusFilter = (
    <Box sx={styles.GAP}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.STATUS}
      />

      <AutoCompleteCustom
        width={"96px"}
        items={STATUS_TYPES}
        LeftIcon=""
        valueState={statusValue}
        setValueState={setStatusValue}
        idState={idstatus}
        setIdState={setIdStatus}
        papermenuclass={styles.paperMenu}
        size={"sm36"}
      />
    </Box>
  );
  const sourceFilter = (
    <Box sx={styles.GAP}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.SOURCE_TYPE}
      />

      <AutoCompleteCustom
        width={"96px"}
        items={SOURCES_TYPES}
        LeftIcon=""
        valueState={statusValueSource}
        setValueState={setStatusValueSource}
        idState={idstatusSource}
        setIdState={setIdStatusSource}
        papermenuclass={styles.paperMenu}
        size={"sm36"}
      />
    </Box>
  );
  const statusFilter1 = (
    <Box sx={styles.GAP}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.STATUS}
      />

      <AutoCompleteCustom
        width={"96px"}
        items={EMAIL_TYPES}
        LeftIcon=""
        valueState={statusValueEmail}
        setValueState={setStatusValueEmail}
        idState={idstatusEmail}
        setIdState={setIdStatusEmail}
        papermenuclass={styles.paperMenu}
        size={"sm36"}
      />
    </Box>
  );

  const filters_list = [
    {
      filter: userNameFilter,
      blocked_emails_filter: false,
      blocked_domains_filter: true,
      imported_files_filter: false,
    },
    {
      filter: fileNameFilter,
      blocked_emails_filter: false,
      blocked_domains_filter: false,
      imported_files_filter: true,
    },
    {
      filter: dateFilter,
      blocked_emails_filter: true,
      blocked_domains_filter: true,
      imported_files_filter: true,
    },
    {
      filter: statusFilter,
      blocked_emails_filter: false,
      blocked_domains_filter: false,
      imported_files_filter: true,
    },
    {
      filter: sourceFilter,
      blocked_emails_filter: false,
      blocked_domains_filter: false,
      imported_files_filter: true,
    },

    {
      filter: statusFilter1,
      blocked_emails_filter: true,
      blocked_domains_filter: false,
      imported_files_filter: false,
    },
  ];

  const handleDelete = async (value) => {
    setOpen1(true);
    setDelvalue(value);
    // Handle Delete here.
  };

  const handleBlackListSelection = async (item) => {
    if (selectedBlacklistLabel !== item?.label) {
      setStartDate("");
      setEndDate("");
    }
    setSelectedBlacklistLabel(item?.label);
    setCurrentPage(1);
    try {

      setSelectedHeads(item?.keys);
      const newQuery = { ...query, page: 1, search: "" };
      setSearch("");
      setQuery(newQuery);

      const queryString = JSONtoQueryString(newQuery);
      const response = await getRequest(navigate, item.endPoint + queryString);
      setIsEmpty(response?.data?.is_empty)
      setRecords(response?.data?.data?.[item?.key]);
      setMaxRecords(response?.data?.data?.total_records);
    } catch (error) {
    } finally {
      setSearching(false);
      setLoading(false);
    }
  };

  const handleSearch = async (e, item) => {
    const currentValue = e?.target?.value;
    setSearch(currentValue);
    setCurrentPage(1);
    try {
      setSearching(true);
      const newQuery = { ...query, page: 1, [item?.searchKey]: currentValue };
      setQuery(newQuery);
      const queryString = JSONtoQueryString(newQuery);
      const response = await getRequest(navigate, item.endPoint + queryString);
      setRecords(response?.data?.data?.[item?.key]);
      setMaxRecords(response?.data?.data?.total_records);
    } catch (error) {
      console.log(error);
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    } finally {
      setSearching(false);
    }
    // }
  };

  const handleApply = async (item) => {
    let strtDate = new Date(startDate);
    let strtDateFormat =
      strtDate?.getFullYear() +
      "-" +
      (strtDate?.getMonth() + 1) +
      "-" +
      strtDate?.getDate();
    let eDate = new Date(endDate);
    let eDateFormat =
      eDate?.getFullYear() +
      "-" +
      (eDate?.getMonth() + 1) +
      "-" +
      eDate?.getDate() || "";

    if (item.key === STRINGS.IMPORTED_FILES) {
      setValidation("");
      const validatedDates =
        strtDateFormat?.length || eDateFormat?.length
          ? fromToDateValidation(startDate, endDate)
          : { isValid: true };

      if (validatedDates.isValid) {
        setIsLoading(true)
        const queryString = `?from=${strtDateFormat == STRINGS.NOT_A_NUM ? "" : strtDateFormat
          }&to=${eDateFormat == STRINGS.NOT_A_NUM ? "" : eDateFormat
          }&status=${idstatus}&file_name=${fileName}&data_for=${idstatusSource}&search=${search}`;
        const response = await getRequest(
          navigate,
          item.endPoint + queryString
        );
        setIsLoading(false)
        setRecords(response?.data?.data?.[item?.key]);
        setMaxRecords(response?.data?.data?.total_records);
      } else {
        setValidation(validatedDates.message);
      }
    } else if (item.key === STRINGS.BLOCKED_DOMAINS) {
      setValidation("");
      const validatedDates =
        strtDateFormat?.length || eDateFormat?.length
          ? fromToDateValidation(startDate, endDate)
          : { isValid: true };

      if (validatedDates.isValid) {
        setIsLoading(true)
        const queryString = `?from=${strtDateFormat == STRINGS.NOT_A_NUM ? "" : strtDateFormat
          }&to=${eDateFormat == STRINGS.NOT_A_NUM ? "" : eDateFormat
          }&domain_name=${domainName}&search=${search}`;
        const response = await getRequest(
          navigate,
          item.endPoint + queryString
        );
        setIsLoading(false)
        setRecords(response?.data?.data?.[item?.key]);
        setMaxRecords(response?.data?.data?.total_records);
      } else {
        setValidation(validatedDates.message);
      }
    } else if (item.key === STRINGS.BLOCKED_EMAILS) {
      const validatedDates =
        strtDateFormat?.length || eDateFormat?.length
          ? fromToDateValidation(startDate, endDate)
          : { isValid: true };

      if (validatedDates.isValid) {
        setValidation("");
        setIsLoading(true)
        const queryString = `?from=${strtDateFormat == STRINGS.NOT_A_NUM ? "" : strtDateFormat
          }&to=${eDateFormat == STRINGS.NOT_A_NUM ? "" : eDateFormat
          }&status=${idstatusEmail}&search=${search}`;
        const response = await getRequest(
          navigate,
          item.endPoint + queryString
        );
        setIsLoading(false)
        setRecords(response?.data?.data?.[item?.key]);
        setMaxRecords(response?.data?.data?.total_records);
      } else {
        setValidation(validatedDates.message);
      }
    }
    setAnchorEl(null)
  };
  const handleReset = async (item) => {
    setStatusValue([STRINGS.ALL]);
    setIdStatus([""]);
    setStatusValueSource([STRINGS.ALL]);
    setIdStatusSource([""]);
    setStatusValueEmail([STRINGS.ALL]);
    setIdStatusEmail([""]);
    setStartDate("");
    setEndDate("");
    setDomainName("");
    setFileName("");

    const response = await getRequest(navigate, item.endPoint);
    setRecords(response?.data?.data?.[item?.key]);
    setMaxRecords(response?.data?.data?.total_records);
  };
  const handleFilterSubmit = (e) => {
    e.preventDefault();
  };

  const emptyButtonListEmailArray = [
    {
      buttonLabel: STRINGS.ADD_EMAIL,
      variant: "primary",
      leftIcon: ICON_LABELS.ADD,
      disabled: userDetails?.role !== STRINGS.ADMIN_USER,
      onClick: () => userDetails?.role == STRINGS.ADMIN_USER ? setShowContactModal(true) : "",
    },
    {
      buttonLabel: STRINGS.ADD_FILE,
      variant: "primary",
      leftIcon: ICON_LABELS.ADD,
      is_file: true,
      disabled: userDetails?.role !== STRINGS.ADMIN_USER,
      onClick: () => document.getElementById("file-upload").click(),
      onChange: handleFileUpload,
    }
  ];

  const emptyButtonListDomainArray = [
    {
      buttonLabel: STRINGS.DOMAIN,
      variant: "primary",
      leftIcon: ICON_LABELS.ADD,
      disabled: userDetails?.role !== STRINGS.ADMIN_USER,
      onClick: () => userDetails?.role == STRINGS.ADMIN_USER ? setShowDomainModal(true) : "",
    },
    {
      buttonLabel: STRINGS.ADD_FILE,
      variant: "primary",
      leftIcon: ICON_LABELS.ADD,
      is_file: true,
      disabled: userDetails?.role !== STRINGS.ADMIN_USER,
      onClick: () => document.getElementById("file-upload").click(),
      onChange: handleFileUpload,
    }
  ];

  const emptyButtonListFileArray = [
    {
      buttonLabel: STRINGS.ADD_FILE,
      variant: "primary",
      leftIcon: ICON_LABELS.ADD,
      is_file: true,
      onClick: () => document.getElementById("file-upload").click(),
      onChange: handleFileUpload,
    }
  ];

  const emptyListSelctedTabObj = {
    Emails: emptyButtonListEmailArray,
    Domain: emptyButtonListDomainArray,
    "CSV Files": emptyButtonListFileArray
  }

  return loading ? (
    <FullScreenLoader />
  ) : (
    <Box sx={styles.containBlacklist}>
      {/* Custom Fields Mapping modal */}
      <Modal open={showMappingModal}>
        <BlacklistMappingFieldModal
          file={file}
          setFile={setFile}
          open={showMappingModal}
          setOpen={setShowMappingModal}
          peopleDynamicFields={peopleDynamicFields}
          selectedBlacklistLabel={selectedBlacklistLabel}
        />
      </Modal>
      <BlacklistDeleteModal
        open={open1}
        setOpen={setOpen1}
        val={delValue}
        setData={setRecords}
      />
      {/* Custom Fields Mapping modal */}

      {/* Blacklist contact modal */}
      <BlacklistContactModal
        open={showContactModal}
        setOpen={setShowContactModal}
        closeTour={closeTour}
        isTourOpen={isTourOpen}
        setRecords={setRecords}
        setMaxRecords={setMaxRecords}
      />
      {/* Blacklist contact modal */}

      {/* Blacklist domain modal */}
      <BlacklistDoaminModal
        open={showDomainModal}
        setOpen={setShowDomainModal}
        closeTour={closeTour}
        isTourOpen={isTourOpen}
        refreshData={setRecords}
        setData={setRecords}
      />
      {/* Blacklist domain modal */}


      <Box sx={styles.padding1rem}>
        <Box sx={styles.flex}>
          <TypographyInline
            size={"md"}
            color={GREY.PRIMARY}
            fontWeight={fontWeight.MEDIUM}
            label={`${STRINGS.BLACKLIST} ${maxRecords >= 1 ? "(" + maxRecords + ")" : ""} `}
          />

        </Box>
      </Box>
      <Box sx={{ width: "100%", height: "calc(100% - 56px)" }}>
        <Box sx={styles.containHeads}>
          {filesAvailableList?.map((item, index) => {
            return (
              <Box key={index} onClick={() => handleBlackListSelection(item)}>
                <p
                  style={
                    selectedBlacklistLabel === item.label
                      ? styles.selectedHeader
                      : styles.header
                  }
                >
                  {item.label}
                </p>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ height: "calc(100% - 36px)" }}>
          {
            <Box sx={isEmpty ? styles.emptyContainTable : styles.containTable}>
              {/* Filters here for all tables */}
              {
                !isEmpty &&
                (
                  filesAvailableList?.map((item, index) => {
                    return (
                      item?.keys === selectedHeads && (
                        <Box key={index} sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }} >
                          <Box
                            sx={{
                              ...styles.flexJustifyBetween,
                              paddingBottom: "1rem",
                              justifyContent: "flex-start",
                              gap: "1rem"
                            }}
                          >
                            <Box>
                              <SearchField
                                size={"sm36"}
                                LeftIcon={"search"}
                                placeholder="Search "
                                value={search}
                                onChange={(e) => handleSearch(e, item)}
                              />
                            </Box>
                            <Box>
                              <Button
                                label={STRINGS.FILTERS}
                                LeftIcon={ICON_LABELS.FILTER_ALT}
                                size={"sm36"}
                                variant={"ghost"}
                                onClick={handleClick}
                              />

                              <CustomStyledMenu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                noMargin={true}
                              // arrow
                              >
                                <Box
                                  component="form"
                                  onSubmit={handleFilterSubmit}
                                  style={styles.containAllFilter}
                                >
                                  {filters_list
                                    ?.filter(
                                      (filter) => filter[`${item?.key}_filter`]
                                    )
                                    .map((filter, index) => {
                                      return (
                                        <Box
                                          key={index}
                                          style={styles.containEachFilter}
                                        >
                                          {filter.filter}
                                        </Box>
                                      );
                                    })}
                                  <Box sx={styles.flexRight}>
                                    <Box sx={styles.containEachFilter}>
                                      <Button
                                        label={STRINGS.RESET}
                                        size={"sm36"}
                                        variant={"ghost"}
                                        onClick={() => handleReset(item)}
                                      />

                                      <Button
                                        label={STRINGS.APPLY}
                                        size={"sm36"}
                                        variant={"primary"}
                                        isLoading={isLoading}
                                        onClick={() => handleApply(item)}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </CustomStyledMenu>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", columnGap: "16px", paddingBottom: "1rem" }}>
                            {
                              filesAvailableList?.filter(
                                (item) => item?.label === selectedBlacklistLabel
                              )?.[0]?.button}

                            {
                              filesAvailableList?.filter(
                                (item) => item?.label === selectedBlacklistLabel
                              )?.[0]?.button1}
                          </Box>
                        </Box>
                      )
                    );
                  })
                )
              }
              {/* Filters here for all tables */}

              {searching ? (
                <Box sx={styles.flexJustifyCenter}>
                  <CircularLoader />
                </Box>
              ) : (
                isEmpty ? (
                  <div style={{ height: "100%" }} >
                    <EmptyScreen
                      buttonsList={emptyListSelctedTabObj?.[selectedBlacklistLabel]}
                      description={STRINGS.YOU_CAN + STRINGS.PROVIDING}
                      imageSrc={SEARCH_PROSPECTS_IMAGE}
                    />
                  </div>
                ) :
                  (filesAvailableList?.map((item, index) => {
                    return (
                      item?.keys === selectedHeads && (
                        <Box key={index}>
                          <CommonBlacklistTable
                            data={records || []}
                            heads={item?.keys || []}
                            selectedField={item}
                            handleDelete={handleDelete}
                            maxRecords={maxRecords}
                            page={currentPage}
                            setPage={setCurrentPage}
                            setData={setRecords}
                            query={query}
                          />
                        </Box>
                      )
                    );
                  })
                  )
              )
              }
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default BlacklistImportScreen;
