import { BORDER } from "../../utils/constants/colors";

const classes = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    height: "34px",
    padding: "0px 8px",
    border: `1px solid ${BORDER.HOVER}`,
    borderRadius: "6px",
    alignItems: "center",
    cursor: "pointer",
    // boxShadow: "0px 8px 10px rgba(24, 24, 28, 0.12)",
  },
  woBorderContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "21px",
    padding: "0px 8px 0px 0px",
    alignItems: "center",
    cursor: "pointer",
  },
  inputContainer_input_focus: {
    outline: "none",
  },
  inputContainer: {
    display: "flex",
    fontSize: "14px",
    fontWeight: "bold",
    gap: "4px",
  },
  superContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    position: "relative",
  },
  type__time_____webkit_inner_spin_button: {
    display: "none",
  },
  type__time_____webkit_calendar_picker_indicator: {
    display: "none",
  },
  timeInput: {
    border: "none",
    padding: "0",
    lineHeight: "20px",
    width: "1.3em",
    color: "#5f5f61",
    fontSize: "inherit",
  },
  timeIcon: {
    fontSize: "1.4em",
    color: "#5F5F61",
  },
  timeIcon_hover: {
    cursor: "pointer",
  },
  listContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    gap: "2%",
    boxShadow:
      "0px 0px 16px rgba(24, 24, 28, 0.12), 0px 12px 8px rgba(24, 24, 28, 0.02)",
    position: "absolute",
    top: "38px",
    backgroundColor: "white",
    zIndex: 100,
  },
  listContainerRelative: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    gap: "2%",
    boxShadow:
      "0px 0px 16px rgba(24, 24, 28, 0.12), 0px 12px 8px rgba(24, 24, 28, 0.02)",
    top: "38px",
    backgroundColor: "white",
    zIndex: 100,
  },
  listHours: {
    display: "flex",
    flexDirection: "column",
    gap: "5%",
    width: "100%",
    fontSize: "14px",
    lineHeight: "20px",
    height: "100%",
    maxHeight: "200px",
    overflow: "scroll",
    cursor: "pointer",
  },
  itemDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "34px",
    width: "100%",
    paddingLeft: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderRadius: "2px",
    color: "rgb(95, 95, 97)",
  },
  itemDivActive: {
    display: "flex",
    height: "34px",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderRadius: "2px",
    backgroundColor: "#FFECEC",
    ":hover": {
      backgroundColor: "red",
    },
  },
  itemDiv_hover: {
    cursor: "pointer",
    backgroundColor: "rgb(237, 237, 237)",
  },
  listMins___webkit_scrollbar: {
    display: "none",
  },
  listHours___webkit_scrollbar: {
    display: "none",
  },
  timeInput_hover: {
    cursor: "pointer",
  },
};
export default classes;
