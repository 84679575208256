import {
  PAGINATION_FAIL,
  PAGINATION_REQUEST,
  PAGINATION_SUCCESS,
} from "../constants/customTableContants";

export const paginationDataReducer = (state = {}, action) => {
  switch (action.type) {
    case PAGINATION_REQUEST:
      return { loading: true };
    case PAGINATION_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case PAGINATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
