import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {getRequest, postRequest} from '../../Apis'
import { campaignEndpoints } from '../../utils/constants/httpConstants';
import { EMITTER_ERROR, EMITTER_SUCCESS, tempHeadCells } from '../../utils/constants';
import { CircularLoader, CustomTextField, FullScreenLoader } from '../../components';
import EventEmitter from '../../utils/emitter/EventEmitter';
import { getErrorMessage } from '../../utils/helpers';
import classes from "./marketing.module.css";

const DefaultValuesScreen = () => {

    const navigate = useNavigate();
    const allColumns = [...tempHeadCells];
    const initialValuesArr  = allColumns?.map((item)=> {return  item?.id } );
    const initialValues = initialValuesArr.reduce((acc,curr)=> (acc[curr]='' , acc),{});

    const [ values, setValues ] = useState( initialValues || [] );
    const [loading, setLoading]= useState(true);
    const [saving , setSaving] = useState(false)

    useEffect(()=>{
        (async () => {
            try {
                const response = await getRequest( navigate, campaignEndpoints.getDefaultValues );
                setValues( response?.data?.data?.[0] || initialValues)
            } catch (error) {
            }finally{
                setLoading(false);
            }
        })();
    },[ navigate ]);

    const handleInputChange =(e, type) => {
        setValues({...values,[type]: e.target.value })
    }

    const handleSave = async () => {
        try {
            setSaving(true);
            const response = await postRequest( navigate , campaignEndpoints.saveDefaultValues ,values );
            EventEmitter.emit( EMITTER_SUCCESS, response?.data?.message );
        } catch (error) {
            EventEmitter.emit( EMITTER_ERROR, getErrorMessage( error?.data?.data?.errors || {} || error?.data?.message ) );
        }finally{
            setSaving(false);
        }
    }

    return loading ? <FullScreenLoader /> : <Box className={classes.backGround} >
        <Box className={ classes.CampaignContainer } >
            <h3 className={ classes.title } >Default Values </h3>
        </Box>
        <Box className={classes.containAllInputs } >
            { allColumns?.map((eachValue, index)=>{
                return <Box key={ index }  className={ classes.containEachInput } >
                    <CustomTextField 
                        placeholder={ eachValue?.label } 
                        value={ values?.[eachValue?.id] } 
                        fullWidth 
                        onChange={ e=>handleInputChange(e, eachValue?.id ) }
                    />
                </Box>
            }) }
        </Box>
        <Box className={ classes.CampaignContainer } >
            { saving ? <CircularLoader /> : <button onClick={ handleSave } className={ classes.updateBtn } >Save Details</button> }
        </Box>
    </Box>
}

export default DefaultValuesScreen;