import {
  CHECK_LOGIN_FAIL,
  CHECK_LOGIN_REQUEST,
  CHECK_LOGIN_SUCCESS,
  INVITED_USERS_LIST_FAIL,
  INVITED_USERS_LIST_REQUEST,
  INVITED_USERS_LIST_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_MAIL_SEND_FAIL,
  RESET_PASSWORD_MAIL_SEND_REQUEST,
  RESET_PASSWORD_MAIL_SEND_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  USERS_LIST_FAIL,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  SIGNATURE_REQUEST,
  SIGNATURE_SUCCESS,
  SIGNATURE_FAIL,
  TENANT_USERS_LIST_REQUEST,
  TENANT_USERS_LIST_SUCCESS,
  TENANT_USERS_LIST_FAIL,
  VERIFY_DOMAIN_REQUEST,
  VERIFY_DOMAIN_SUCCESS,
} from "../constants/authConstants";
import { axios } from "../../config";
import {
  authEndpoints,
  CampaignSettingsEndpoints,
  REGISTRATION_LINK,
} from "../../utils/constants/httpConstants";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  SETTINGS_DATA_MANGEMENT_LIST,
  tenants_api_endpoints,
  SETTINGS_ACCOUNT_SETUP_LIST
} from "../../utils/constants";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/constants/messages";
import { POST, getRequest, postRequest } from "../../Apis/apiWrapper";
import {
  BLOCKED_TENANT_ROUTE_PATH,
  DASHBOARD_PATH,
  INITIAL_ROUTE,
  INVALID_TENANT_ROUTE_PATH,
  LOGIN_PATH,
} from "../../utils/constants/routes";
import { authRoutes } from "../../Router/authRoutes";

export const registerUser = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_REQUEST });
    let response = await axios.post(`/${authEndpoints.register}`, formdata);
    dispatch({ type: REGISTER_SUCCESS, payload: response?.data });
  } catch (err) {
    err?.response?.status >= 500 &&
      CustomEventEmitter.emit(
        EMITTER_ERROR,
        err?.response?.data?.message || err?.message || "server error"
      );
    dispatch({ type: REGISTER_FAIL, payload: err?.response?.data });
  }
};

export const loginUser = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    let response = await axios.post(`/${authEndpoints.login}`, formdata);

    dispatch({ type: LOGIN_SUCCESS, payload: response?.data });
    CustomEventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
  } catch (err) {
    CustomEventEmitter.emit(
      EMITTER_ERROR,
      err?.response?.data?.message || err?.message || "Sever error"
    );

    dispatch({ type: LOGIN_FAIL, payload: err?.response?.data });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_REQUEST });

    let response = await axios.post(`/${authEndpoints.logout}`);

    dispatch({ type: LOGOUT_SUCCESS, payload: response?.data });
    dispatch({
      type: LOGIN_FAIL,
      payload: { status: false, message: "User logged out" },
    });
    dispatch({
      type: CHECK_LOGIN_FAIL,
      payload: { status: false, message: "User logged out." },
    });

    CustomEventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
  } catch (err) {
    CustomEventEmitter.emit(
      EMITTER_ERROR,
      err?.response?.data?.message || err?.message || "Server error"
    );

    dispatch({ type: LOGOUT_FAIL, payload: err?.response?.data });
  }
};

// Refresh Token
export const checkLogin = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_LOGIN_REQUEST });

    // endpoint request here
    const response = await POST(authEndpoints.checkLogin, {}, navigate);
    dispatch({ type: CHECK_LOGIN_SUCCESS, payload: response.data });
    await dispatch(userDetails(response.data.data));
    dispatch(tenantUsersDetail(navigate));

    if (
      response.data.data?.account_setting_setup &&
      response?.data?.data?.general_setting_setup
    ) {

      const role = response.data.data?.role;
      if (role !== 'admin-user') {
        // check for protected routes.
        const protected_path_ids = [...SETTINGS_DATA_MANGEMENT_LIST.map(item => item.id), ...SETTINGS_ACCOUNT_SETUP_LIST.map(item => item.id)];
        for (let i in protected_path_ids) {
          if (window.location.search.includes(protected_path_ids[i])) {
            navigate(DASHBOARD_PATH);
            break;
          }
        }

        // check for root URL.
        if (window.location.pathname === "/") {
          navigate(DASHBOARD_PATH)
        } else {

        }

      } else {
        const validAuthRoutes = [...authRoutes.map(item => item.path), "/"];
        validAuthRoutes.includes(window.location.pathname) ? navigate(DASHBOARD_PATH) : navigate(window.location.pathname + window.location.search)

      }

    } else {
      navigate(INITIAL_ROUTE);
    }
  } catch (err) {
    // if called from login page, then don't emit this message.
    dispatch({ type: CHECK_LOGIN_FAIL, payload: err });
    navigate(LOGIN_PATH);
  }
};

// Refresh Token
export const tenantUsersDetail = (navigate) => async (dispatch, getState) => {
  try {
    let apiDetails = {};
    dispatch({ type: TENANT_USERS_LIST_REQUEST });
    tenants_api_endpoints?.forEach(async (item) => {
      // if endpoint exists.
      if (item?.endpoint) {
        var response;
        if (item?.type === "post") {
          response = await postRequest(navigate, item?.endpoint, {});
        } else {
          response = await getRequest(navigate, item?.endpoint);
        }
        apiDetails = { ...apiDetails, [item?.id]: { ...response } };
        dispatch({
          type: TENANT_USERS_LIST_SUCCESS,
          payload: {
            ...apiDetails,
          },
        });
      }
      // if action exists, it will store data into its reducer.
      else {
        dispatch(item?.action({}, navigate));
      }
    });
  } catch (err) {
    // if called from login page, then don't emit this message.
    console.log("Response erro ", err);
    dispatch({ type: TENANT_USERS_LIST_FAIL, payload: err });
  }
};

export const userDetails = (payload) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: payload });
  } catch (err) {
    dispatch({ type: USER_DETAILS_FAIL, payload: err });
  }
};

export const verifyDomain = (subDomain, navigate = () => { }) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_DOMAIN_REQUEST });
    // endpoint request here
    const response = await axios.post(authEndpoints.verifyDomain, {
      sub_domain: subDomain,
    });
    dispatch({ type: VERIFY_DOMAIN_SUCCESS, payload: response.data });
  } catch (err) {
    if (err?.response?.status === 422) {
      setTimeout(() => {
        window.location.href = REGISTRATION_LINK;
      }, 1000);
    }
    else if (err?.response?.status === 419) {
      navigate(INVALID_TENANT_ROUTE_PATH)
    } else if (err?.response?.status === 420) {
      navigate(BLOCKED_TENANT_ROUTE_PATH)
    } else {
      CustomEventEmitter.emit(
        EMITTER_ERROR,
        err?.response?.data?.message || err?.message || DEFAULT_ERROR_MESSAGE
      );
    }
    // dispatch({ type: RESET_PASSWORD_FAIL, payload: err?.response?.data });
    throw err;
  }
};
// Forgot password action

export const resetPasswordMailSend =
  (email, reset = false) =>
    async (dispatch) => {
      if (reset) {
        dispatch({
          type: RESET_PASSWORD_MAIL_SEND_SUCCESS,
          payload: null,
        });
      } else {
        try {
          dispatch({ type: RESET_PASSWORD_MAIL_SEND_REQUEST });
          let response = await axios.post(
            authEndpoints.sendMailForResetPassword,
            {
              email: email,
            }
          );
          dispatch({
            type: RESET_PASSWORD_MAIL_SEND_SUCCESS,
            payload: response.data,
          });
        } catch (err) {
          dispatch({
            type: RESET_PASSWORD_MAIL_SEND_FAIL,
            payload: err?.response?.data,
          });
        }
      }
    };

export const resetPassword = (formdata, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    // endpoint request here
    const response = await axios.post(authEndpoints.resetPassword, formdata);
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response });
    CustomEventEmitter.emit(
      EMITTER_SUCCESS,
      response?.data?.message || "password updated"
    );

    dispatch(userDetails(response?.data?.data));
    navigate(DASHBOARD_PATH);
  } catch (err) {
    console.log(err, "err");
    //invalid token error showed on emiiter not tested yet

    {
      //after discuss with vishal applying this. If condition
      if (!err?.response?.data?.message == "Invalid data provided") {
        CustomEventEmitter.emit(EMITTER_ERROR, err?.response?.data?.message);
      }
    }

    if (err?.response?.status === 403) {
      CustomEventEmitter.emit(EMITTER_SUCCESS, err?.response?.data?.message);
      navigate(LOGIN_PATH);
    }

    dispatch({ type: RESET_PASSWORD_FAIL, payload: err?.response?.data });
  }
};

// User Invitaion and users actions

export const invitedUsersList = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: INVITED_USERS_LIST_REQUEST });
    const response = await getRequest(navigate, authEndpoints.invitedUsersList);

    dispatch({
      type: INVITED_USERS_LIST_SUCCESS,
      payload: response?.data,
    });
  } catch (err) {
    if (err?.status === 401) {
      navigate(LOGIN_PATH);
    }

    dispatch({ type: INVITED_USERS_LIST_FAIL, payload: err });
  }
};

export const usersList = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: USERS_LIST_REQUEST });
    const response = await getRequest(navigate, authEndpoints.userList);

    dispatch({
      type: USERS_LIST_SUCCESS,
      payload: response?.data,
    });
  } catch (err) {
    if (err?.status === 401) {
      navigate(LOGIN_PATH);
    }

    dispatch({ type: USERS_LIST_FAIL, payload: err });
  }
};
export const getSignatureAction = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: SIGNATURE_REQUEST });
    const response = await getRequest(
      navigate,
      CampaignSettingsEndpoints.getSignature
    );
    dispatch({ type: SIGNATURE_SUCCESS, payload: response?.data });
  } catch (error) {
    if (error?.status === 401) {
      navigate(LOGIN_PATH);
    }
    dispatch({ type: SIGNATURE_FAIL, payload: error });
  }
};
