import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomHeader from "../../../components/Header/CustomHeader";
import {
  ACCOUNT_PATH,
  CRM_PATH,
  DEALS_PATH,
  DEFAULT_PROTECTED_PATH,
} from "../../../utils/constants/routes";
import { STRINGS } from "../strings";
import classes from "./Crm.module.css";
import AvatarImage from "../../../components/Avatar/AvatarImage";
import {
  DUMMY_COMPANY2,
  DUMMY_CONTACT2,
  DUMMY_DEALS2,
} from "../../../utils/constants/assets";
import { Button } from "../../../components/Buttons";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { BRAND, GREY, SUCCESS } from "../../../utils/constants/colors";
import IconComponent from "../../../components/Icon/IconComponent";
import { useState } from "react";
import {
  accountEndPoints,
  crmEndpoints,
  formsEndpoints,
  meetingsEndpoint,
  taskEndpoints,
} from "../../../utils/constants/httpConstants";
import { getRequest } from "../../../Apis";
import InputFieldWoBorder from "../../../components/InputFields/InputFieldWoBorder";
import {
  COUNTRY_LIST,
  DEFAULT_CONTACT_FIELD_ORDER,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../utils/constants";
import { AutoCompleteWoBorder } from "../../../components/Autocomplete/AutoCompleteWoBorder";
import IconOutlinedHover from "../../../components/Icon/IconOutlinedHover";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";
import { postRequest, putRequest } from "../../../Apis/apiWrapper";
import { useSelector } from "react-redux";
import {
  createCompanyURL,
  formatCurrency,
  getContactHeading,
  replaceKeyInArrayOfObj,
  timeStampToDate,
  trimObjectValues,
} from "../../../utils/helpers";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";
import TextArea from "../../../components/InputFields/TextArea";
import {
  AccordionDetails,
  AccordionSummary,
  Anchor,
  HtmlEditor,
} from "../../../components";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { emailValidation } from "../../../utils/validations";
import { Accordion } from "@mui/material";
import SocialButton from "../../../components/Buttons/SocialButton";
import Tabs from "../../../components/Tabs/Tabs";
import IconButton from "../../../components/Buttons/IconButton";
import ContactDetailEmailScreen from "./ContactDetailEmailScreen";
import ContactDetailTaskScreen from "./ContactDetailTaskScreen";
import ContactAttachmentScreen from "./ContactAttachmentScreen";
import ContactNoteScreen from "./ContactNoteScreen";
import ContactDetailMeetingScreen from "./ContactDetailMeetingScreen";
import AddEditCompany from "./components/AddEditCompany";
import ContactDetailTimelineScreen from "./ContactDetailTimelineScreen";
import AvatarOutlined from "../../../components/Avatar/AvatarOutlined";
import Image from "../../../components/Image/Image";
import EmailSendModal from "./components/EmailSendModal";
import AvatarName from "../../../components/Avatar/AvatarName";
import CreateMeetingPopUp from "./components/CreateMeetingPopUp";
import CreateTaskCrmPopUp from "./components/CreateTaskCrmPopUp";
import CreateNoteCrmPopUp from "./components/CreateNoteCrmPopUp";
import PropertyActionsModal from "./components/PropertyActionsModal";
import { nameValidation } from "../../../utils/validations/inputValidations";
import AddDealDialog from "../Deals/components/AddDealDialog";
import ListSkeletonLoader from "../Components/ListSkeletonLoader";

const ContactDetailScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );

  const [dealListLoading, setDealListLoading] = useState(true);
  const checkEmpty = [];
  const [inputData, setInputData] = useState({});
  const [inputDataId, setInputDataId] = useState({});
  const [inputError, setInputError] = useState({});
  const [clipboardValue, setClipBoardValue] = useState("");
  const [contactDetails, setContactDetails] = useState([]);
  const [loading, setLoading] = useState(0);
  const [data, setData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [locationTab, setLocationTab] = useState("");
  const [openAddCompnay, setOpenAddCompany] = useState(false);
  const [openEmailSendModal, setOpenEmailSendModal] = useState(false);
  const [createFlag, setCreateFlag] = useState(false);
  const [createNoteFlag, setCreateNoteFlag] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [assigneeList, SetAssigneeList] = useState([]);
  const [companyOpen, setCompanyOpen] = useState(true);
  const [openActionProperty, setOpenActionProperty] = useState(false);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const [requiredOrder, setRequiredOrder] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [countryList, setCountrylist] = useState(COUNTRY_LIST || []);

  //Deals State
  const [openAddDeals, setOpenAddDeals] = useState(false);
  const [addDeals, setAddDeals] = useState(false);
  const [deals, setDeals] = useState([]);

  // states of email detail screen.
  const [emailLogListData, setEmailLogListData] = useState([]);
  const [emailScreenLoading, setEmailScreenLoading] = useState(true);
  const [totalEmails, setTotalEmails] = useState(0);

  // states of timeline screen
  const [dataList, setDataList] = useState([]);
  const [timeLineScreenLoading, setTimelineScreenLoading] = useState(false);
  const [totalTimeline, setTotalTimeline] = useState(0);

  // states of task screen
  const [taskListData, setTaskListData] = useState("");
  const [taskScreenLoading, setTaskScreenLoading] = useState(true);
  const [totalTasks, setTotaltasks] = useState(0);
  const locationTabs = location?.search?.split("?tabs=");
  const UpdatedLocation = locationTabs[1] === STRINGS.CALLS_SMALL;

  // states of meeting screen
  const [meetingListData, setMeetingListData] = useState("");
  const [meetingScreenLoading, setMeetingScreenLoading] = useState(true);
  const [totalMeetings, setTotalMeetings] = useState(0);

  // states of note screen
  const [noteListData, setNoteListData] = useState("");
  const [noteScreenLoading, setNoteScreenLoading] = useState(true);
  const [totalNotes, setTotalNotes] = useState(0);
  const [companyList2, setCompanyList2] = useState(companyList || []);

  const headerItems = [
    { id: STRINGS.SALES_SMALL, label: STRINGS.CONTACTS, path: CRM_PATH },
    {
      id: STRINGS.SALES_SMALL,
      label: loader ? STRINGS.LOADING : getContactHeading(contactDetails),
    },
  ];

  // helpers of all screen listing.
  const getEmailLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url =
        crmEndpoints.getEmailLogs +
        `?email=${contactDetails?.email || ""}&contact_id=${id || params?.id
        }&page=${count}`;
      !isOnScroll && setEmailScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalEmails(response?.data?.data?.total_records);
      if (isOnScroll) {
        setEmailLogListData([
          ...emailLogListData,
          ...response?.data?.data?.records,
        ]);
      } else setEmailLogListData(response?.data?.data?.records);
      !isOnScroll && setEmailScreenLoading(false);
    } catch (error) {
      setEmailScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  const getTimelineLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${crmEndpoints.getTimelineFeed}/${params?.id
        }?size=${10}&page=${count}`;
      !isOnScroll && setTimelineScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalTimeline(response?.data?.data?.total_records);
      if (isOnScroll) {
        setDataList([...dataList, ...response?.data?.data?.records]);
      } else setDataList(response?.data?.data?.records);
      !isOnScroll && setTimelineScreenLoading(false);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setTimelineScreenLoading(false);
    }
  };

  const getTaskLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${taskEndpoints.getTaskListCrm}/${params?.id}?step_type=${UpdatedLocation ? 3 : 2
        }&page=${count}`;
      !isOnScroll && setTaskScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotaltasks(response?.data?.data?.total_records);
      if (isOnScroll) {
        setTaskListData([...taskListData, ...response?.data?.data?.records]);
      } else setTaskListData(response?.data?.data?.records);
      !isOnScroll && setTaskScreenLoading(false);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    } finally {
      setTaskScreenLoading(false);
    }
  };

  const getMeetingLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${meetingsEndpoint.getCrmMeetings}?contact_id=${params?.id}&page=${count}`;
      !isOnScroll && setMeetingScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalMeetings(response?.data?.data?.total_records);
      if (isOnScroll) {
        setMeetingListData([
          ...meetingListData,
          ...response?.data?.data?.scheduledMeetings,
        ]);
      } else setMeetingListData(response?.data?.data?.scheduledMeetings);
      !isOnScroll && setMeetingScreenLoading(false);
    } catch (error) {
      !isOnScroll && setMeetingScreenLoading(false);

      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const getNoteLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${crmEndpoints.getNotes}?contact_id=${params?.id}&page=${count}`;
      !isOnScroll && setNoteScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalNotes(response?.data?.data?.total_records);
      if (isOnScroll) {
        setNoteListData([...noteListData, ...response?.data?.data?.records]);
      } else setNoteListData(response?.data?.data?.records);
      !isOnScroll && setNoteScreenLoading(false);
    } catch (error) {
      setNoteScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  // helpers of all screen listing.

  const tabData = [
    {
      label: STRINGS.TIMELINE,
      id: STRINGS.TIMELINE_SMALL,
      component: (
        <ContactDetailTimelineScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          dataList={dataList}
          getTimelineLogList={getTimelineLogList}
          screenLoading={timeLineScreenLoading}
          totalTimeline={totalTimeline}
        />
      ),
      path: `${location?.pathname}?tabs=timeline`,
    },
    {
      label: STRINGS.CALLS,
      id: STRINGS.CALLS_SMALL,
      component: (
        <ContactDetailTaskScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          screenLoading={taskScreenLoading}
          taskListData={taskListData}
          setTaskListData={setTaskListData}
          totalTasks={totalTasks}
          getTaskLogList={getTaskLogList}
        />
      ),

      path: `${location?.pathname}?tabs=calls`,
    },
    {
      label: STRINGS.EMAILS,
      id: STRINGS.EMAILS_SMALL,
      component: (
        <ContactDetailEmailScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          getEmailLogList={getEmailLogList}
          emailLogListData={emailLogListData}
          screenLoading={emailScreenLoading}
          totalEmails={totalEmails}
        />
      ),
      path: `${location?.pathname}?tabs=emails`,
    },
    {
      label: STRINGS.MEETINGS,
      id: STRINGS.MEETINGS_SMALL,
      component: (
        <ContactDetailMeetingScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          getMeetingLogList={getMeetingLogList}
          meetingListData={meetingListData}
          setMeetingListData={setMeetingListData}
          screenLoading={meetingScreenLoading}
          totalMeetings={totalMeetings}
        />
      ),
      path: `${location?.pathname}?tabs=meetings`,
    },
    {
      label: STRINGS.NOTES,
      id: STRINGS.NOTE_SMALL,
      component: (
        <ContactNoteScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          getNoteLogList={getNoteLogList}
          noteListData={noteListData}
          setNoteListData={setNoteListData}
          screenLoading={noteScreenLoading}
          totalNotes={totalNotes}
        />
      ),
      path: `${location?.pathname}?tabs=note`,
    },
    {
      label: STRINGS.TASKS,
      id: STRINGS.TASKS_SMALL,
      component: (
        <ContactDetailTaskScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          screenLoading={taskScreenLoading}
          taskListData={taskListData}
          setTaskListData={setTaskListData}
          totalTasks={totalTasks}
          getTaskLogList={getTaskLogList}
        />
      ),
      path: `${location?.pathname}?tabs=tasks`,
    },

    {
      label: STRINGS.ATTACHMENTS,
      id: STRINGS.ATTACHMENTS_SMALL,
      component: (
        <ContactAttachmentScreen
          contactDetails={contactDetails}
          contactId={params?.id}
        />
      ),
      path: `${location?.pathname}?tabs=attachments`,
    },
  ];

  const textCopyHandler = (value) => {
    try {
      setClipBoardValue(value);
      navigator?.clipboard?.writeText(value);

      setTimeout(() => {
        setClipBoardValue("");
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const getContactDetails = async () => {
    try {
      let id = params?.id;
      setLoader(true);
      const response = await getRequest(
        navigate,
        `${crmEndpoints.getContactDetails}/${id}`
      );
      setContactDetails(response?.data?.data);
      setInputData(response?.data?.data);
      setInputDataId(response?.data?.data);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const getFieldsOrder = async () => {
    try {
      const response = await getRequest(navigate, formsEndpoints.getFormsField);
      let columns = [];
      let required_obj = {};
      response?.data?.data?.Contact?.sort(
        (obj1, obj2) => obj1.order - obj2.order
      )?.forEach((element) => {
        columns.push(element?.field_id);
        required_obj = {
          ...required_obj,
          ...{ [element.field_id]: element?.required },
        };
      });
      setRequiredOrder(required_obj);
      setFieldsOrder(columns || []);
    } catch (error) {
      console.log("Error------->>>>>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const getDealsList = async () => {
    try {
      const response = await getRequest(
        navigate,
        `${crmEndpoints.getDealsByContactIds}/${params?.id}`
      );
      setDeals(response?.data?.data?.records || []);
      if (response?.data?.data?.records?.length) {
        setAddDeals(true);
      }
    } catch (error) {
      console.log("erroor", error);
    } finally {
      setDealListLoading(false);
    }
  };

  const ICONS = [
    {
      id: 1,
      icon: ICON_LABELS.EMAIL,
      variant: "orange",
      iconName: "Emails",
      onClick: () => {
        setOpenEmailSendModal(true);
      },
    },
    {
      id: 2,
      icon: ICON_LABELS.CALL,
      variant: "green",
      iconName: "Calls",
      onClick: () => {
        setOpenTask(true);
        setType("call");
      },
    },
    {
      id: 3,
      icon: ICON_LABELS.TASK,
      variant: "yellow",
      iconName: "Tasks",
      onClick: () => {
        setOpenTask(true);
        setType("tasks");
      },
    },
    {
      id: 4,
      icon: ICON_LABELS.STICKY_NOTE,
      variant: "blue",
      iconName: "Notes",
      onClick: () => {
        setCreateNoteFlag(true);
      },
    },
    {
      id: 5,
      icon: ICON_LABELS.EVENT_NOTE,
      variant: "purple",
      iconName: "Meetings",
      onClick: () => {
        setCreateFlag(true);
      },
    },
  ];

  const validationHandler = (inputData) => {
    // getting all the required fields id and storing it into array.
    let required_fields = [];
    let error_arr = [];
    let input_error = { ...inputError };

    Object.keys(requiredOrder)?.forEach((element, index) => {
      if (requiredOrder?.[element]) {
        required_fields.push(element);
      }
    });

    // loop on required ids for checking validation according to field_type
    required_fields?.forEach((element, index) => {
      if (element !== "email") {
        let validate = nameValidation(inputData?.[element]);

        if (validate?.isValid) {
          error_arr?.filter((item) => item !== element);
          input_error = { ...input_error, [element]: "" };
        } else {
          error_arr = [...error_arr, element];
          input_error = { ...input_error, [element]: validate?.message };
        }
      }
    });

    // storing the error into state for displaying error for email
    const validatedEmail = emailValidation(inputData?.email);
    if (validatedEmail?.isValid) {
      error_arr?.filter((item) => item !== "email");
      input_error = { ...input_error, email: "" };
    } else {
      error_arr = [...error_arr, "email"];
      input_error = { ...input_error, email: validatedEmail?.message };
    }
    setInputError({ ...input_error });

    // return value according to error key check in error_arr.
    if (error_arr?.length) {
      return false;
    } else {
      return true;
    }
  };

  const handleContactEditApi = async (e) => {
    e?.preventDefault();

    if (validationHandler(inputData)) {
      setLoading(true);
      const payload = {
        query: {
          ...inputData,
          id: params?.id,
        },
      };

      try {
        setInputError({});
        const URL = crmEndpoints?.editContactPersonDetails;
        const response = await putRequest(
          navigate,
          URL,
          trimObjectValues(payload)
        );

        setLoading(false);
        setData(false);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        let id = params?.id;
        const response2 = await getRequest(
          navigate,
          `${crmEndpoints.getContactDetails}/${id}`
        );
        setLoader(false);
        setContactDetails(response2?.data?.data);
        setInputData(response2?.data?.data);
        setInputDataId(response2?.data?.data);
      } catch (error) {
        setLoading(false);

        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleResetContDetails = () => {
    setInputData(contactDetails);
    setData(false);
    setInputError({});
  };
  const searchHandler = (e, property) => {
    let countryValue = countryList;

    if (property?.id === STRINGS.PERSON_COUNTRY) {
      let data = countryValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setCountrylist(COUNTRY_LIST);
      } else {
        setCountrylist(data);
      }
    } else if (property?.id === STRINGS.COMPANY_ID_SMALL) {
      let dataCompany = companyList?.filter(
        (item) =>
          item?.company_name
            ?.toLowerCase()
            ?.includes(e?.target?.value?.toLowerCase()) ||
          item?.company_website
            ?.toLowerCase()
            ?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value !== "" || undefined) {
        setCompanyList2(dataCompany);
      } else {
        setCompanyList2(companyList);
      }
    }
  };

  const getValuesOfDropdown = (prop) => {
    if (prop?.id === STRINGS.COMPANY_ID_SMALL) {
      let company_list = companyList2?.map((item) => {
        return {
          label: item?.company_name || item?.company_website,
          id: item?._id,
        };
      });
      return company_list;
    } else if (prop?.id === STRINGS.PERSON_COUNTRY) {
      let country_list = countryList?.map((item) => {
        return {
          label: item?.label,
          id: item?.id,
        };
      });

      return country_list;
    } else {
      return replaceKeyInArrayOfObj(prop?.value, "labelValue", "label");
    }
  };

  const type_id_components = (prop) => {
    switch (prop?.field_type[0]) {
      case STRINGS.DATE_PICKER:
        return (
          <CustomDatePicker
            disabled={!Object.keys(contactDetails || {}).length}
            size={"sm36"}
            value={inputData?.[prop?.id] || new Date()}
            onChange={(dateValue) => {
              setInputData({ ...inputData, [prop?.id]: dateValue });
            }}
          />
        );

      case STRINGS.NUMBER:
        return (
          <InputFieldWoBorder
            size={"sm36"}
            type={"number"}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            value={inputData?.[prop?.id] || ""}
            placeholder={`Enter a ${prop?.property_name}`}
            variant={
              !Object.keys(contactDetails || {}).length
                ? STRINGS.DISABLED
                : inputError[prop?.id]?.length
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
            }
          />
        );

      case STRINGS.MULTI_LINE_TEXT_INPUT:
        return (
          <TextArea
            rows={"5"}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            value={inputData?.[prop?.id] || ""}
          />
        );

      case STRINGS.RICH_TEXT_EDITOR:
        return (
          <>
            <div
              className={
                inputError[prop?.id]
                  ? classes.htmlEditorError
                  : classes.htmlEditor
              }
            >
              <HtmlEditor
                editorHeight={"12vh"}
                previosData={inputData[prop?.id] || ""}
                onInput={() => {
                  setInputData({
                    ...inputData,
                    [prop?.id]: document?.getElementById(STRINGS.EDITOR_ID)
                      ?.innerHTML,
                  });
                  setData(true);
                }}
                isAITagNotVisible
                isAddTagNotVisible
                isMeetingTagNotVisible
                isFocusNotRequired
              />
            </div>{" "}
            {inputError[prop?.id] ? (
              <TypographyInline
                label={inputError[prop?.id] || ""}
                color={BRAND.PRIMARY}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
              />
            ) : null}
          </>
        );

      case STRINGS.SINGLE_SELECT:
        return (
          <AutoCompleteWoBorder
            variant={
              !Object.keys(contactDetails || {}).length ? "disabled" : "default"
            }
            dropdownId="preview_dropdown"
            items={getValuesOfDropdown(prop)}
            size={"sm36"}
            valueState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputDataId?.[`${STRINGS.COMPANY_NAME_SMALL}`]]
                : [inputData?.[prop?.id]]
            }
            setValueState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputDataId({
                  ...inputDataId,
                  [`${STRINGS.COMPANY_NAME_SMALL}`]: data[0],
                })
                : setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                });
              setData(true);
            }}
            idState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputDataId?.[`${STRINGS.COMPANY_NAME_SMALL}`]]
                : [inputDataId?.[prop?.id]]
            }
            setIdState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                })
                : setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                });
            }}
            isSearchEnable={
              prop?.property_name === STRINGS.COMPANY_NAME ||
              prop?.property_name === STRINGS.PERSON_COUNTRY_CAPS
            }
            handleSearch={(event) => {
              searchHandler(event, prop);
            }}
          />
        );

      case STRINGS.MULTIPLE_SELECT:
        return (
          <AutoCompleteWoBorder
            dropdownId="preview_dropdown"
            items={replaceKeyInArrayOfObj(
              prop?.value || [],
              "labelValue",
              "label"
            )}
            size={"sm36"}
            multiple
            valueState={inputData?.[prop?.id]}
            setValueState={(data) => {
              setInputData({
                ...inputData,
                [prop?.id]: data,
              });
              setData(true);
            }}
            idState={inputDataId[prop?.id]}
            setIdState={(data) => {
              setInputDataId({
                ...inputDataId,
                [prop?.id]: data,
              });
            }}
          />
        );

      default:
        return (
          <InputFieldWoBorder
            size={"sm36"}
            placeholder={`Enter a ${prop?.property_name}`}
            value={inputData?.[prop?.id] || ""}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            errormessage={inputError[prop?.id]}
            variant={
              !Object.keys(contactDetails || {}).length
                ? STRINGS.DISABLED
                : inputError[prop?.id]?.length
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
            }
          />
        );
    }
  };

  const detailContainerItems = [
    {
      id: STRINGS.EMPLOYEES_SMALL,
      label: STRINGS.EMPLOYEES,
      value: contactDetails?.number_of_employees?.toString(),
    },
    {
      id: STRINGS.FOUNDED_SMALL,
      label: STRINGS.FOUNDED,
      value: contactDetails?.founded?.toString(),
    },
    {
      id: STRINGS.REVENUE_SMALL,
      label: STRINGS.REVENUE,
      value: `${contactDetails?.annual_revenue?.length
        ? `$${contactDetails?.annual_revenue?.toString()} ${STRINGS.ANNUAL}`
        : ""
        }`,
    },
    {
      id: STRINGS.LOCATION_SMALL,
      label: STRINGS.LOCATION,
      value: contactDetails?.company_address?.toString(),
    },
  ];

  const postAction = (tab) => {
    let currentTab = location.search.replace("?tabs=", "");
    if (tab === currentTab || currentTab === "timeline") {
      switch (currentTab) {
        case "emails":
          return getEmailLogList();
        case "calls":
          return getTaskLogList();
        case "meetings":
          return getMeetingLogList();
        case "note":
          return getNoteLogList();
        case "tasks":
          return getTaskLogList();
        default:
          getTimelineLogList();
          break;
      }
    }
  };

  const handleDealNavigate = (item) => {
    navigate(`${DEALS_PATH}/${item?._id}?tabs=timeline`);
  };

  const handleCompanyNavigation = (companyData) => {
    navigate(`${ACCOUNT_PATH}/${companyData?.company_id}?tabs=timeline`);
  };

  useEffect(() => {
    setLoader(true);
    getFieldsOrder();

    (async () => {
      await getContactDetails();
    })();
    // get deals list
    (async () => {
      await getDealsList();
    })();

    (async () => {
      const table_columns = ["company_name", "email", "company_website"];

      const query = { ...table_columns };
      const response = await postRequest(
        navigate,
        accountEndPoints.accountSearch,
        query
      );

      setCompanyList(response?.data?.data?.records);
      setCompanyList2(response?.data?.data?.records);
    })();
  }, [navigate]);

  useEffect(() => {
    const UpdatedLocation = location?.search?.split("?tabs=");
    if (tabData.map((tab) => tab.id).includes(UpdatedLocation[1])) {
      setLocationTab(UpdatedLocation[1]);
    } else {
      // invalid tab
      navigate(DEFAULT_PROTECTED_PATH);
    }
  }, [location]);

  useEffect(() => {
    searchHandler();
  }, [countryList]);

  return (
    <div>
      <CustomHeader items={headerItems} />

      {/* Layout Container contains all three container */}
      <div className={classes.detailLayoutContainer}>
        {/* Contact Detail Container */}
        <div id="modal_body" className={classes.contactDetailLeftContainer}>
          <div
            id="modal_body"
            className={classes.contactDetailLeftSubContainer}
          >
            <div className={classes.contactDetailMainContainer}>
              <div>
                {loader ? (
                  Array.from({ length: 1 })?.map(() => (
                    <SkeletonLoader
                      type="circular"
                      skeletonWidth={80}
                      skeletonHeight={80}
                    />
                  ))
                ) : contactDetails?.first_name || contactDetails?.last_name ? (
                  <AvatarName
                    size={"XXL"}
                    name={getContactHeading(contactDetails)}
                  />
                ) : (
                  <AvatarOutlined size={"XXL"}>
                    <Image src={DUMMY_CONTACT2} alt="" />
                  </AvatarOutlined>
                )}
              </div>

              <div className={classes.contactDetailNameDesig}>
                {loader
                  ? Array.from({ length: 1 })?.map(() => (
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"100%"}
                      skeletonHeight={"30px"}
                      stackWidth={"30%"}
                    />
                  ))
                  : getContactHeading(contactDetails)}
                {loader ? (
                  Array.from({ length: 2 })?.map(() => (
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"100%"}
                      skeletonHeight={"20px"}
                      stackWidth={"60%"}
                    />
                  ))
                ) : (
                  <>
                    <div className={classes.displayAbout}>
                      <TypographyInline
                        color={GREY.SECONDARY}
                        fontWeight={fontWeight.REGULAR}
                        size={"sm"}
                        label={contactDetails?.job_title || ""}
                      />
                    </div>

                    <div className={classes.contactNameContainer}>
                      {contactDetails?.email && (
                        <IconComponent
                          color={GREY.TERTIARY}
                          fontSize={fontSize.LG}
                          iconLabel={ICON_LABELS.EMAIL}
                        />
                      )}
                      <TypographyInline
                        color={GREY.SECONDARY}
                        fontWeight={fontWeight.REGULAR}
                        size={"sm"}
                        label={contactDetails?.email || ""}
                      />
                      {clipboardValue === contactDetails?.email ? (
                        <div
                          id={classes.copiedTextContainer}
                          className={classes.copiedTextContainer}
                        >
                          <IconComponent
                            fontSize={fontSize.SM}
                            color={SUCCESS.W_500}
                            iconLabel={ICON_LABELS.DONE}
                          />
                          <TypographyInline
                            isEllipses
                            color={SUCCESS.W_500}
                            fontWeight={fontWeight.MEDIUM}
                            label={STRINGS.COPIED_LABEL}
                            size={"xs"}
                          />
                        </div>
                      ) : (
                        clipboardValue === contactDetails?.email && (
                          <div
                            onClick={() => {
                              textCopyHandler(contactDetails?.email);
                            }}
                            id={classes.copiedTextContainer}
                          >
                            <IconComponent
                              color={GREY.SECONDARY}
                              fontSize={fontSize.MD}
                              iconLabel={ICON_LABELS.CONTENT_COPY}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}{" "}
              </div>

              <div className={classes.aboutContactHead}>
                {loader
                  ? Array.from({ length: 5 })?.map(() => (
                    <SkeletonLoader
                      type="circular"
                      skeletonWidth={46}
                      skeletonHeight={46}
                      stackWidth={46}
                      stackHeight={46}
                    />
                  ))
                  : Object.keys(contactDetails || {})?.length
                    ? ICONS?.map((i) => (
                      <div>
                        <IconOutlinedHover
                          variant={i?.variant}
                          iconLabel={i?.icon}
                          color={i?.variant}
                          iconName={i?.iconName}
                          size="huge"
                          onClick={i?.onClick}
                        />
                      </div>
                    ))
                    : null}
              </div>
            </div>

            <div className={classes.aboutContact}>
              {loader ? (
                Array.from({ length: 1 })?.map(() => (
                  <div>
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"30%"}
                      skeletonHeight={"20px"}
                      stackWidth={"100%"}
                    />
                  </div>
                ))
              ) : (
                <div className={classes.DisplayFlexAbout}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    size={"sm"}
                    label={STRINGS.ABOUT_CONTACT}
                  />
                </div>
              )}
            </div>

            {loader || getPropertiesListSelector?.loading ? (
              <div className={classes.addContactFormLoaderContainer}>
                {Array.from({ length: 10 })?.map((index) => {
                  return (
                    <div key={`addContact${index}`}>
                      <SkeletonLoader
                        type="rounded"
                        skeletonWidth={"100%"}
                        skeletonHeight={"42px"}
                        stackWidth={"100%"}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={classes.aboutContact}>
                <form
                  className={classes.inputForm}
                  onChange={() => setData(true)}
                  onSubmit={handleContactEditApi}
                >
                  {fieldsOrder?.map((field) => {
                    return (
                      <div className={classes.inputLabelField}>
                        <div className={classes.labelButtonContainer}>
                          <TypographyInline
                            label={
                              getPropertiesListSelector?.data
                                ?.properties_key_value?.[field]
                                ?.property_name || ""
                            }
                            color={GREY.SECONDARY}
                            size={"sm"}
                            fontWeight={fontWeight.MEDIUM}
                            isRequired={requiredOrder?.[field]}
                          />
                        </div>
                        {getPropertiesListSelector?.data?.hits?.filter(
                          (ele) => ele?.object_type[0] === "Contact"
                        )?.length &&
                          type_id_components(
                            getPropertiesListSelector?.data
                              ?.properties_key_value?.[field]
                          )}
                      </div>
                    );
                  })}
                </form>
              </div>
            )}
          </div>
          {data ? (
            <div className={classes.aboutFooter}>
              <Button
                label={STRINGS.CANCEL}
                size={"sm36"}
                variant={"ghost"}
                onClick={() => {
                  handleResetContDetails();
                }}
              />

              <Button
                label={STRINGS.SAVE}
                isLoading={loading}
                size={"sm36"}
                variant={"primary"}
                onClick={() => {
                  handleContactEditApi();
                }}
              />
            </div>
          ) : null}
        </div>

        {/* Contact Activities Container */}
        <div className={classes.contactDetailContainerHead}>
          <div className={classes.contactDetailContainerSubHead}>
            <Tabs
              items={tabData}
              selected={`${location?.pathname}${location?.search}`}
              selectedTabFunc={() => { }}
            />
          </div>
          <div id="modal_body" className={classes.contactDetailContainer}>
            {tabData?.map((item, index) => {
              return item?.id === locationTab && item?.component;
            })}
          </div>
        </div>

        {/* Contact Companies Container */}
        <div className={classes.contactDetailRightContainer}>
          <Accordion
            className={classes.accrodionRoot}
            onChange={() => setCompanyOpen(!companyOpen)}
            expanded={companyOpen}
          >
            <AccordionSummary
              className={classes.accordionSummary}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={
                  companyOpen
                    ? classes.summaryContainerOpen
                    : classes.summaryContainer
                }
              >
                <TypographyInline
                  label={STRINGS.COMPANIES}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.SECONDARY}
                />
                <div className={classes.summaryRightSection}>
                  <div>
                    <Button
                      size={"sm36"}
                      variant={"text"}
                      disabled={!Object.keys(contactDetails || {}).length}
                      label={
                        contactDetails?.company_name ||
                          contactDetails?.company_website ||
                          contactDetails?.company_linkedin_url
                          ? STRINGS.UPDATE
                          : STRINGS.ADD
                      }
                      LeftIcon={ICON_LABELS.ADD}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenAddCompany(true);
                      }}
                    />
                  </div>
                  <div className={classes.verticalLine} />
                  <div className={classes.accordionIcon}>
                    <IconComponent
                      color={GREY.TERTIARY}
                      fontSize={fontSize.DXS}
                      iconLabel={
                        companyOpen
                          ? ICON_LABELS.ACTION_ARROW
                          : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className={classes.accordionDetail}
              id="modal_body"
            >
              {contactDetails?.company_name ||
                contactDetails?.company_website ||
                contactDetails?.company_linkedin_url ? (
                // {/* companies body main container */}
                <div className={classes.companyBodyContainer}>
                  {/* company detail container */}
                  <div className={classes.companyDetailMainContainer}>
                    <AvatarImage
                      size={"extraLarge"}
                      src={
                        createCompanyURL(contactDetails?.company_website) ||
                        DUMMY_COMPANY2
                      }
                      onError={(e) => (e.target.src = DUMMY_COMPANY2)}
                    />
                    <div className={classes.companyDetailsContainer} onClick={() => handleCompanyNavigation(contactDetails)}>
                      <TypographyInline
                        color={GREY.PRIMARY}
                        size={"lg"}
                        fontWeight={fontWeight.SEMIBOLD}
                        label={contactDetails?.company_name || ""}
                        isEllipses
                      />
                      <TypographyInline
                        color={GREY.SECONDARY}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                        label={contactDetails?.industry || ""}
                        isEllipses
                      />
                    </div>
                  </div>
                  {/* company social links  */}
                  <div className={classes.socialLinksContainer}>
                    {/* facebook url  */}
                    {contactDetails?.company_facebook_url && (
                      <div>
                        <SocialButton
                          LeftIcon={ICON_LABELS.FACEBOOK}
                          size={"sm36"}
                          variant={"ghost"}
                          href={contactDetails?.company_facebook_url}
                          tooltipLabel={STRINGS.FACEBOOK}
                        />
                      </div>
                    )}

                    {/* comapny_linkedin_url */}
                    {contactDetails?.company_linkedin_url && (
                      <div>
                        <SocialButton
                          LeftIcon={ICON_LABELS.LINKEDIN}
                          size={"sm36"}
                          variant={"ghost"}
                          href={contactDetails?.company_linkedin_url}
                          tooltipLabel={STRINGS.LINKEDIN}
                        />
                      </div>
                    )}

                    {/* company_twitter_url */}
                    {contactDetails?.company_twitter_url && (
                      <div>
                        <SocialButton
                          LeftIcon={ICON_LABELS.TWITTER}
                          size={"sm36"}
                          variant={"ghost"}
                          href={contactDetails?.company_twitter_url}
                          tooltipLabel={STRINGS.TWITTER}
                        />
                      </div>
                    )}

                    {/* company website */}
                    {contactDetails?.company_website && (
                      <div>
                        <Anchor
                          href={contactDetails?.company_website}
                          target="_blank"
                        >
                          <IconButton
                            LeftIcon={ICON_LABELS.OPEN_IN_NEW}
                            size={"sm36"}
                            variant={"ghost"}
                            tooltipLabel={STRINGS.COMPANY_WEBSITE}
                          />
                        </Anchor>
                      </div>
                    )}
                  </div>

                  {/* Company Description */}
                  <div className={classes.companyDescriptionLabel}>
                    <TypographyInline
                      color={GREY.SECONDARY}
                      label={contactDetails?.seo_description || ""}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                    />
                  </div>

                  {/* Company Details */}
                  <div className={classes.companyDetailMainContainer}>
                    {detailContainerItems?.map((item, index) => {
                      return item?.value?.length ? (
                        <div
                          className={classes.companyDetailContainer}
                          key={`${item?.id}${index}`}
                        >
                          <div>
                            <TypographyInline
                              color={GREY.SECONDARY}
                              label={item?.label}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                          </div>
                          <div>
                            <TypographyInline
                              color={GREY.PRIMARY}
                              label={item?.value}
                              size={"sm"}
                              fontWeight={fontWeight.MEDIUM}
                            />
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : (
                //  when no company exists.
                <div className={classes.noComanyFoundContainer}>
                  <AvatarOutlined size={"extraLarge"}>
                    <Image src={DUMMY_COMPANY2} alt="" />
                  </AvatarOutlined>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={STRINGS.NO_COMPANY_ADDED}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {/* accordion for deals */}
          <Accordion
            className={classes.accrodionRoot}
            onChange={() => setAddDeals(!addDeals)}
            expanded={addDeals}
          >
            <AccordionSummary
              className={classes.accordionSummary}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={
                  addDeals
                    ? classes.summaryContainerOpen
                    : classes.summaryContainer
                }
              >
                <TypographyInline
                  label={STRINGS.DEALS}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.SECONDARY}
                />
                <div className={classes.summaryRightSection}>
                  <div>
                    <Button
                      size={"sm36"}
                      variant={"text"}
                      disabled={!Object.keys(contactDetails || {}).length}
                      label={deals?.deal_name ? STRINGS.UPDATE : STRINGS.ADD}
                      LeftIcon={ICON_LABELS.ADD}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenAddDeals(true);
                      }}
                    />
                  </div>
                  <div className={classes.verticalLine} />
                  <div className={classes.accordionIcon}>
                    <IconComponent
                      color={GREY.TERTIARY}
                      fontSize={fontSize.DXS}
                      iconLabel={
                        addDeals
                          ? ICON_LABELS.ACTION_ARROW
                          : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className={classes.accordionDetail}
              id="modal_body"
            >
              {dealListLoading ? (
                <ListSkeletonLoader />
              ) : deals?.length ? (
                deals?.map((deal) => {
                  return (
                    <div key={deal._id} className={classes.pipelineOuter}>
                      <div
                        className={classes.companyBodyContainer}
                        onClick={() => handleDealNavigate(deal)}
                      >
                        <TypographyInline
                          color={GREY.PRIMARY}
                          label={deal?.deal_name || ""}
                          size={"sm"}
                          fontWeight={fontWeight.SEMIBOLD}
                        />
                        <TypographyInline
                          color={GREY.SECONDARY}
                          label={formatCurrency(deal?.deal_amount)}
                          size={"sm"}
                          fontWeight={fontWeight.MEDIUM}
                        />
                      </div>
                      <div className={classes.displayColumnPipeline}>
                        <div className={classes.flexEachCompanyClosedPipeline}>
                          <TypographyInline
                            color={GREY.TERTIARY}
                            label={STRINGS.EXPECT_CLOSED_DATE}
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                          />
                          <TypographyInline
                            color={GREY.PRIMARY}
                            label={
                              deal?.closed_date?.length
                                ? `${timeStampToDate(deal?.closed_date)}`
                                : "--"
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                          />
                        </div>
                        <div className={classes.flexEachContactPipeline}>
                          <div className={classes.PipelineFlex}>
                            <TypographyInline
                              color={GREY.TERTIARY}
                              label={STRINGS.PIPELINENAME}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                            <TypographyInline
                              color={GREY.PRIMARY}
                              label={deal?.pipeline_name || ""}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                          </div>
                          <div className={classes.PipelineFlex}>
                            <TypographyInline
                              color={GREY.TERTIARY}
                              label={STRINGS.STAGE_NAME_COLON}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                            <TypographyInline
                              color={GREY.PRIMARY}
                              label={deal?.stage_name || "--"}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                //  when no deal exists.
                <div className={classes.noComanyFoundContainer}>
                  <AvatarOutlined size={"extraLarge"}>
                    <Image src={DUMMY_DEALS2} alt="" />
                  </AvatarOutlined>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={STRINGS.NO_DEAL_ADDED}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          {openAddCompnay && (
            <AddEditCompany
              open={openAddCompnay}
              close={() => setOpenAddCompany(false)}
              data={
                contactDetails?.company_website?.length ? contactDetails : {}
              }
              getContactDetails={getContactDetails}
              setInputData={setInputData}
              inputData={inputData}
            />
          )}
          {openAddDeals && (
            <AddDealDialog
              open={openAddDeals}
              setOpen={() => setOpenAddDeals(false)}
              getContactDetails={() => { }}
              getDealsListed={getDealsList}
              contact_id={params?.id}
              updateDeal={true}
            />
          )}
        </div>

        {/* All Screens Modal which will open on click of icons. */}
        {createFlag && (
          <CreateMeetingPopUp
            open={createFlag}
            close={setCreateFlag}
            contact_id={params?.id}
            assigneeList={assigneeList}
            setMeetingListData={setMeetingListData}
            contactDetails={contactDetails}
            checkEmptyConnect={checkEmpty?.isConnectionEmpty}
            SetAssigneeList={SetAssigneeList}
            getMeetings={() => postAction("meetings")}
          />
        )}

        {openTask && (
          <CreateTaskCrmPopUp
            contactDetails={contactDetails}
            open={openTask}
            close={setOpenTask}
            contact_id={params?.id}
            setTaskListData={setTaskListData}
            type={type}
            getTask={() => postAction(type === "call" ? "calls" : "tasks")}
          />
        )}
        {createNoteFlag && (
          <CreateNoteCrmPopUp
            open={createNoteFlag}
            close={setCreateNoteFlag}
            setNoteListData={setNoteListData}
            contact_id={params?.id}
            getNotes={() => postAction("note")}
          />
        )}
        {openEmailSendModal && (
          <EmailSendModal
            open={openEmailSendModal}
            close={() => setOpenEmailSendModal(false)}
            contactId={params?.id}
            contactDetails={contactDetails}
            getEmails={() => postAction("emails")}
          />
        )}

        {openActionProperty && (
          <PropertyActionsModal
            open={openActionProperty}
            close={setOpenActionProperty}
            firstName={contactDetails?.first_name}
            lastName={contactDetails?.last_name}
            contactId={params?.id}
            getFieldsOrder={getFieldsOrder}
          />
        )}
      </div>
    </div>
  );
};

export default ContactDetailScreen;
