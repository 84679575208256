import { makeStyles } from "@mui/styles";

export const styles = makeStyles({
    icon:{
        widht:"40px",
        height:"40px",
    },
    closeIcon:{
        height:"25px", cursor:"pointer", paddingRight:"5px" 
    }
})