import React from "react";
import SuspenseImage from "../Image/SuspenseImage";
import { styles } from "./styles";

const SnackbarIcon = ({ src, ...props }) => {
  const classes = styles();
  return <SuspenseImage src={src} alt="" className={classes.icon} {...props} />;
};

export default SnackbarIcon;
