import { MODE_CHANGE_REQUEST, MODE_CHANGE_FAIL, MODE_CHANGE_SUCCESS } from '../constants/modeConstants';

export const modeChangeReducer = ( state={}, action ) =>{
    switch(action.type){
        case MODE_CHANGE_REQUEST:
            return { loading: true }

        case MODE_CHANGE_SUCCESS:
            return { loading: false, mode: action.payload }
        
        case MODE_CHANGE_FAIL:
            return { loading: false, error: action.payload }
    
        default:
            return state;
    }
}