import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { postRequest } from "../../../../Apis/apiWrapper";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../../utils/constants";
import {
  compressString,
  getErrorMessage,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import { templateEndpoints } from "../../../../utils/constants/httpConstants";
import { getTemplateList } from "../../../../redux/actions/templateListActions";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../strings";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import InputField from "../../../../components/InputFields/InputField";
import CustomModal from "../../../../components/Modals/CustomModal";
import {
  MESSAGE_REQUIRED_FIELD,
  TEMP_NAME_LENGTH_ERROR,
  TEMP_NAME_LONG_LENGTH_ERROR,
} from "../../../../utils/constants/messages";
import classes from "../markComponents.module.css";

const CreateTempModal = ({
  open,
  setOpen,
  sub,
  data,
  myValue,
  getCurrentTemplate,
}) => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const [selectedValue, setSelectedValue] = useState(STRINGS.PRIVATE_ID);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const radioButtonData = [
    { id: STRINGS.PRIVATE_ID, label: STRINGS.PRIVATE_LABEL },
    { id: STRINGS.TEAM_ID, label: STRINGS.TEAM_LABEL },
  ];

  const handleCreateFolder = async () => {
    if (name?.length === 0) {
      setErrMsg(MESSAGE_REQUIRED_FIELD);
      return;
    }
    if (name?.length <= 3) {
      setErrMsg(TEMP_NAME_LENGTH_ERROR);
      return;
    }

    if (name?.length >= 150) {
      setErrMsg(TEMP_NAME_LONG_LENGTH_ERROR);
      return;
    }
    setErrMsg("");
    let customEditorValue = removeTagStyleFromHtml(data);
    customEditorValue = compressString(encodeURIComponent(customEditorValue));
    const payload = {
      name: name,
      subject: sub,
      body: customEditorValue,
      permission: selectedValue,
    };
    setLoading(true);
    try {
      const response = await postRequest(
        navigate,
        templateEndpoints.createTemplate,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      // dispatch(getTemplateList({ name: "", type: STRINGS.SMALL_FILE }, navigate));
      setLoading(false);
      setOpen(!open);
      getCurrentTemplate(name);
    } catch (err) {
      setLoading(false);
      setErrMsg(
        getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: STRINGS.NEW_TEMPLATE,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBodyContainer}>
      <Box className={classes.modalPadding2}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.TYPETEMPLATE}
        />
        <InputField
          value={name}
          isRe
          size={"sm36"}
          variant={errMsg ? "error" : "default"}
          errormessage={errMsg}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box className={classes.containShared}>
        <Box className={classes.modalPadding2}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.SHARED_WITH}
          />
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </Box>
      </Box>
    </div>
  );

  const modalFooterLeftData = <div></div>;
  const modalFooterRightData = (
    <div className={classes.rightFoot}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.CREATE}
        size={"sm36"}
        variant={"primary"}
        onClick={handleCreateFolder}
        isLoading={loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default CreateTempModal;
