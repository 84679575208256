import React, { useEffect, useState } from "react";
import CustomDialog from "../../../../components/Dialog/CustomDialog";
import { Box } from "@mui/material";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import { campaignEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import {
  EACH_CAMPAIGN_STATS_TYPE,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  ICON_BY_STEP_TYPE,
  STATS_HEADERS,
  STEP_TYPE_CHIP_BG,
} from "../../../../utils/constants";
import { STRINGS } from "../strings";
import classes from "../markComponents.module.css";

import IconComponent from "../../../../components/Icon/IconComponent";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { BRAND, GREY } from "../../../../utils/constants/colors";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import {
  decompressString,
  processStatsKeyValuePairs,
  timeStampToDateTime,
} from "../../../../utils/helpers";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
import CustomTable from "../../../../components/Table/CustomTable";
import CampaignStepSkeleton from "../common/CampaignStepSkeleton";
import parse from "html-react-parser";
import { Button } from "../../../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { paginationDataAction } from "../../../../redux/actions/customTableActions";
let quotedPrintable = require("quoted-printable");

const process_email_body = (body) => {
  const start = body.indexOf("<div");
  const end = body.lastIndexOf("/div>");
  const response = body.slice(start, end + 5);
  return response;
};

function decodeBase64(base64String) {
  const base64Chars = STRINGS.ALPHANUMERIC;

  const base64Lookup = new Uint8Array(256);
  for (let i = 0; i < base64Chars.length; i++) {
    base64Lookup[base64Chars.charCodeAt(i)] = i;
  }

  const padding = base64String.endsWith("==")
    ? 2
    : base64String.endsWith("=")
      ? 1
      : 0;
  const byteLength = (base64String.length * 6) / 8 - padding;
  const bytes = new Uint8Array(byteLength);

  let byteIndex = 0;
  let charIndex = 0;
  while (charIndex < base64String.length) {
    const enc1 = base64Lookup[base64String.charCodeAt(charIndex++)];
    const enc2 = base64Lookup[base64String.charCodeAt(charIndex++)];
    const enc3 = base64Lookup[base64String.charCodeAt(charIndex++)];
    const enc4 = base64Lookup[base64String.charCodeAt(charIndex++)];

    const byte1 = (enc1 << 2) | (enc2 >> 4);
    const byte2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    const byte3 = ((enc3 & 3) << 6) | enc4;

    bytes[byteIndex++] = byte1;
    if (byteIndex < byteLength) {
      bytes[byteIndex++] = byte2;
    }
    if (byteIndex < byteLength) {
      bytes[byteIndex++] = byte3;
    }
  }

  const decoder = new TextDecoder("utf-8");
  const decodedString = decoder.decode(bytes);

  return decodedString;
}
const StatsDialog = ({ open, setOpen, statsPayload }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingSteps, setLoadingSteps] = useState(true);
  const [loadingStats, setLoadingStats] = useState(true);
  const [stats, setStats] = useState({});
  const [steps, setSteps] = useState([]);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [isLeadShow, setIsLeadShow] = useState("0");
  const [isUnsubscribeShow, setIsUnsubscribeShow] = useState("0")
  const [selectedStep, setSelectedStep] = useState("");
  const [tableHeads, setTableHeads] = useState([...STATS_HEADERS]);
  const [currentType, setCurrentType] = useState(statsPayload?.head?.id || "");
  const [currentReplyPayload, setCurrentReplyPayload] = useState({});
  const [showReply, setShowReply] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [leadMarking, setLeadMarking] = useState(false);

  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const handleStepSelection = (item) => {
    setSelectedStep(item?._id);
    dispatch(paginationDataAction({
      ...paginationDataSelector?.data, campStats: {
        ...paginationDataSelector?.data?.campStats,
        query: {
          page: 1, limit: 25
        }
      }
    }))
    fetchStatsByStepId(item?._id);
  };

  const fetchStatsByStepId = async (step_id) => {
    try {
      setLoadingStats(true);
      const stats_response = await postRequest(
        navigate,
        campaignEndpoints.stepWise,
        { step_ids: [step_id] }
      );
      setStats(
        processStatsKeyValuePairs(stats_response.data?.records?.[step_id] || {})
      );
      fetchRecordsByType(currentType, step_id);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setLoadingStats(false);
    }
  };

  const fetchRecordsByType = async (type, step_id) => {
    try {
      setDetailsLoading(true);
      const payload = {
        campaign_id: statsPayload?.row?._id,
        step_id: step_id,
        type: type,
        limit: paginationDataSelector?.data?.["campStats"]?.query?.limit || 25,
        page: paginationDataSelector?.data?.["campStats"]?.query?.page || 1,

      };
      const records_payload = await postRequest(
        navigate,
        campaignEndpoints.campInStats,
        payload
      );
      records_payload.data.records.data =
        records_payload.data?.records?.data?.map((item) => {
          return {
            ...item,
            [`${type}_${STRINGS.SMALL_DATE}`]: timeStampToDateTime(
              parseInt(item[`${type}_${STRINGS.SMALL_DATE}`])
            ),
          };
        });
      setRecords(records_payload.data?.records);
      const temp_table_heads = [...tableHeads];
      temp_table_heads.pop();
      if (temp_table_heads?.length >= 3) {
        temp_table_heads.pop();
      }
      // if (type === STRINGS.SMALL_REPLY || type === STRINGS.SENT_SMALL || type === STRINGS.OPEN_SMALL) {
      setTableHeads([
        {
          id: "name",
          ui_id: "user_detail",
          label: "Name",
          width: "14.2vw",
        },
        {
          id: "email",
          ui_id: "stats_data",
          label: "Email",
          width: "14.2vw",
        },
        {
          id: `${type}_${STRINGS.SMALL_DATE}`,
          ui_id: "stats_data",
          label: "Date",
          width: "14.2vw",
        },
        {
          id: STRINGS.SMALL_ACTION,
          ui_id: STRINGS.SMALL_ACTION,
          label: STRINGS.ACTION,
          width: "10.063vw",
        }
      ]);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setDetailsLoading(false);
    }
  };

  const handleTypeChange = (item) => {
    try {
      setCurrentType(item.key);
      dispatch(paginationDataAction({
        ...paginationDataSelector?.data, campStats: {
          ...paginationDataSelector?.data?.campStats,
          query: {
            page: 1, limit: 25
          }
        }
      }))
      fetchRecordsByType(item.key, selectedStep);
    } catch (error) { }
  };

  const handleGetReply = async (campaign_id, step_id, email) => {
    try {
      const URL = `${campaignEndpoints.replyStats}?${STRINGS.STEP_ID}=${step_id}&${STRINGS.CAMPAIGN_ID}=${campaign_id}&${STRINGS.SMALL_EMAIL}=${email}`;
      const reply_payload = await getRequest(navigate, URL);
      console.log("REPST", reply_payload)
      setIsLeadShow(reply_payload?.data?.hits?.hits[0]?._source?.lead)
      setIsUnsubscribeShow(reply_payload?.data?.hits?.hits[0]?._source?.unsubscribe)
      // attachments processing.
      let images_html;
      const attachments_payload =
        reply_payload?.data?.hits?.hits?.[0]?._source?.attachments_data;

      if (attachments_payload?.length) {
        images_html = `<div>`;
        for (let i in attachments_payload) {
          const each_attachment = attachments_payload[i];
          // adding img only for now.
          const each_image = `<img style="width:80px; height: 80px;" src="${each_attachment?.src}" />`;
          images_html += each_image;
        }

        images_html += "</div>";
      }


      let payload_data = { ...reply_payload?.data?.hits?.hits?.[0]?._source }
      let reply = "";
      if (currentType === "reply") {
        if (payload_data?.is_compressed) {
          reply = `${decodeURIComponent(decompressString(payload_data?.reply_html_payload || ""))} ${images_html || ""}`
        } else {
          reply = `<div>${payload_data?.reply_html_payload || ""
            } ${images_html || ""}</div>`;
        }
      } else {
        reply = `<div>${payload_data?.email_body || ""
          } ${images_html || ""}</div>`;

      }

      if (reply) {
        setReplyContent(reply);
      } else {
        // old reply formats.
        const base64Decoded = decodeBase64(
          payload_data?.reply_payload || ""
        );
        const onlyHtml = process_email_body(base64Decoded);
        const html = quotedPrintable.decode(onlyHtml);
        setReplyContent(html);
      }
      //
    } catch (error) {
      console.log("Error ", error);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
    }
  };

  const handleViewReply = (row, head) => {
    try {
      setCurrentReplyPayload(row);
      setShowReply(true);
      handleGetReply(row?.campaign_id, row?.step_id, row?.email);
    } catch (error) { }
  };

  const handleMarkAsUnsubscribe = async () => {
    try {
      setUnsubscribing(true);
      const payload = {
        campaign_id: currentReplyPayload?.campaign_id,
        step_id: currentReplyPayload?.step_id,
        email: currentReplyPayload?.email,
      };
      const response = await postRequest(
        navigate,
        campaignEndpoints.markAsUnsubscribe,
        payload
      );
      setIsUnsubscribeShow("1")
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setUnsubscribing(false);
    }
  };

  const handleMarkAsLead = async () => {
    try {
      setLeadMarking(true);
      const payload = {
        campaign_id: currentReplyPayload?.campaign_id,
        step_id: currentReplyPayload?.step_id,
        email: currentReplyPayload?.email,
      };
      const response = await postRequest(
        navigate,
        campaignEndpoints.markAsLead,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setIsLeadShow("1")
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setLeadMarking(false);
    }
  };

  useEffect(() => {
    // fetch steps details;
    (async () => {
      try {
        if (!steps?.length) {
          const response = await getRequest(
            navigate,
            `${campaignEndpoints.allStats}/${statsPayload?.row?.cam_sequence_id}`
          );
          setSteps(response?.data?.data || []);
          setSelectedStep(response?.data?.data?.[0]?._id);
          fetchStatsByStepId(response?.data?.data?.[0]?._id);
        } else {
          setSelectedStep(selectedStep);
          fetchStatsByStepId(selectedStep);
        }
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error.data.message);
      } finally {
        setLoadingSteps(false);
      }
    })();
  }, [paginationDataSelector?.data?.["campStats"]?.query?.page,
  paginationDataSelector?.data?.["campStats"]?.query?.limit]);

  // Header
  const dialogHeader = {
    heading: {
      id: statsPayload?.row?._id,
      label: statsPayload?.row?.name,
    },
  };

  const statsTableData = {
    heads: tableHeads,
    body: records?.data,
    total_records: records?.count,
    isLoading: detailsLoading || (detailsLoading),
    is_selection_not_allowed: true,
    operations: [
      {
        id: currentType === "reply" ? STRINGS.VIEW_REPLY : STRINGS.VIEW_EMAIL,
        label: currentType === "reply" ? STRINGS.VIEW_REPLY : STRINGS.VIEW_EMAIL,
        iconLabel: ICON_LABELS.VISIBILITY_ON,
        function: handleViewReply,
      },
    ],
    id: "campStats"
  };

  // Dialog Body
  const body = (
    <Box className={classes.statsContainer}>
      {/* Steps Container */}
      <Box className={classes.containSteps}>
        {loadingSteps ? (
          <CampaignStepSkeleton />
        ) : (
          steps.map((item, index) => {
            return (
              item?.step_type === 1 &&
              <Box>
                <Box
                  key={index}
                  className={
                    selectedStep === item?._id
                      ? classes.eachSelectedStatStep
                      : (index > 0 ? classes.eachStatStep1 : classes.eachStatStep)
                  }
                  onClick={(e) => handleStepSelection(item)}
                >
                  <IconComponent
                    color={
                      selectedStep === item?._id
                        ? STEP_TYPE_CHIP_BG[item.step_type]
                        : GREY.SECONDARY
                    }
                    fontSize={fontSize.XL}
                    iconLabel={ICON_BY_STEP_TYPE[item?.step_type]}
                  />
                  <TypographyInline
                    isEllipses={true}
                    label={`${STRINGS.STEP} ${index + 1}`}
                    size={"md"}
                    fontWeight={
                      selectedStep === item?._id
                        ? fontWeight.SEMIBOLD
                        : fontWeight.MEDIUM
                    }
                    color={
                      selectedStep === item?._id
                        ? STEP_TYPE_CHIP_BG[item.step_type]
                        : GREY.SECONDARY
                    }
                  />
                </Box>
                {steps?.length - 1 != index && (
                  <Box className={classes.stepSaperator}></Box>
                )}
              </Box>
            );
          })
        )}
      </Box>
      {/* Stats and Table Container */}
      <Box className={classes.mainContainer}>
        {/* Stats Container */}
        <Box className={classes.statsCountContainer} id="modal_body">
          {EACH_CAMPAIGN_STATS_TYPE.map((item) => {
            return (
              <Box
                key={item.key}
                className={classes.eachCountContainer}
                onClick={() => handleTypeChange(item)}
              >
                {loadingStats ? (
                  <Box>
                    <SkeletonLoader
                      skeletonWidth={16}
                      skeletonHeight={20}
                      variant="rounded"
                    />
                  </Box>
                ) : (
                  <TypographyInline
                    label={stats?.[item.key]}
                    size={"sm"}
                    fontWeight={
                      item?.key === currentType
                        ? fontWeight.MEDIUM
                        : fontWeight.REGULAR
                    }
                    color={
                      item?.key === currentType ? BRAND.PRIMARY : GREY.SECONDARY
                    }
                  />
                )}

                <TypographyInline
                  className={item?.key === currentType && classes.selectedType}
                  label={item.label}
                  size={"sm"}
                  fontWeight={
                    item?.key === currentType
                      ? fontWeight.MEDIUM
                      : fontWeight.REGULAR
                  }
                  color={
                    item?.key === currentType ? BRAND.PRIMARY : GREY.SECONDARY
                  }
                />
              </Box>
            );
          })}
        </Box>
        {/* Table Container */}
        <Box className={classes.tableContainerSt}>
          <CustomTable tableData={statsTableData} />
        </Box>
      </Box>
    </Box>
  );

  // Reply Dialog content

  // header
  const replyHeader = {
    heading: {
      id: currentReplyPayload?.step_id,
      label: `${currentReplyPayload?.first_name || ""} ${currentReplyPayload?.last_name || ""}`,
    },
  };

  const replyBody = <Box>{parse(replyContent)}</Box>;

  const replyFooter = (
    <Box className={classes.footerContainerUn}>
      {isUnsubscribeShow != "1" &&
        <Box>
          <Button
            label={STRINGS.UNSUBSCRIBE}
            size={"sm36"}
            variant={"ghost"}
            onClick={handleMarkAsUnsubscribe}
            isLoading={unsubscribing}
          />
        </Box>
      }
      {isLeadShow != "1" &&
        <Box>
          <Button
            label={STRINGS.MARK_AS_LEAD}
            size={"sm36"}
            variant={"primary"}
            onClick={handleMarkAsLead}
            isLoading={leadMarking}
          />
        </Box>
      }
    </Box>
  );

  return (
    <>
      <CustomDialog
        open={open}
        remove_padding={true}
        header={dialogHeader}
        onClose={() => setOpen(!open)}
        width={"60vw"}
        marginRight={showReply ? "25vw !important" : "0"}
      >
        {body}
      </CustomDialog>
      <CustomDialog
        open={showReply}
        header={replyHeader}
        onClose={() => setShowReply(!showReply)}
        width={"35vw"}
        footer={currentType === "reply" ? replyFooter : null}
      >
        {replyBody}
      </CustomDialog>
    </>
  );
};
export default StatsDialog;
