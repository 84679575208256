import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailValidation, numberValidation, urlValidation } from "../../../utils/validations/inputValidations";
import { crmEndpoints } from "../../../utils/constants/httpConstants";
import { dropdownValuesFromId, filterQuery, replaceKeyInArrayOfObj, trimObjectValues } from "../../../utils/helpers";
import { getSalesContactsList } from "../../../redux/actions/salesTableAction";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";
import InputField from "../../../components/InputFields/InputField";
import TextArea from "../../../components/InputFields/TextArea";
import { HtmlEditor } from "../../../components";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import CustomDialog from "../../../components/Dialog/CustomDialog";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import classes from "./LeadsComponent.module.css"
import { postRequest, putRequest } from "../../../Apis";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { tableDataFetch } from "../../../redux/actions/tableActions";

const AddContactDialog = ({ open, setOpen, isEdit = false, setIsEdit = () => { } }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );
  const allFiltersSelector = useSelector(
    (state) => state?.allFilters?.payload
  );
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery?.data
  );
  const tableDataSelector = useSelector((state) => state.tableDataFetch?.columns);

  const [inputData, setInputData] = useState({});
  const [inputDataId, setInputDataId] = useState({});
  const [inputError, setInputError] = useState({});
  const [propertiesData, setPropertiesData] = useState([]);
  const [loader, SetLoader] = useState(false);
  const [disable, setDisable] = useState(true);

  const closeContactHandler = () => {
    setOpen(!open);
  };

  const validationHandler = (inputData) => {
    const validatedMobile = numberValidation(inputData?.mobile_number);

    const validatedEmail = emailValidation(inputData?.email);

    const validatedContactUrl = urlValidation(inputData?.contact_linkedin_url);

    if (inputData?.email) {
      if (validatedEmail?.isValid) {
        setDisable(false);
        setInputError({
          email: "",
        });
        return true;
      } else {
        setDisable(true);
        setInputError({
          email: validatedEmail?.message,
        });
      }
    }
    if (inputData?.mobile_number) {
      if (validatedMobile?.isValid) {
        setDisable(false);
        setInputError({
          mobile_number: "",
        });
        return true;
      } else {
        setDisable(true);
        setInputError({
          mobile_number: validatedMobile?.message,
        });
      }
    }
    if (inputData?.contact_linkedin_url) {
      if (validatedContactUrl?.isValid) {
        setDisable(false);
        setInputError({
          contact_linkedin_url: "",
        });
        return true;
      } else {
        setDisable(true);
        setInputError({
          contact_linkedin_url: validatedContactUrl?.message,
        });
      }
    } else {
      setDisable(true);
    }
  };

  const saveContactHandler = async (e) => {
    e?.preventDefault();
    // validating all the required field.
    if (validationHandler(inputData)) {
      const filteredQuery = filterQuery(allFiltersSelector);
      SetLoader(true);
      if (!isEdit) {

        try {
          const response = await postRequest(navigate, crmEndpoints.addContact, {
            query: trimObjectValues({ ...inputData }),
          });


          dispatch(tableDataFetch(filteredQuery, navigate));
          closeContactHandler();
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        } catch (error) {
          SetLoader(false);
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        } finally {
          SetLoader(false);
        }
      } else {
        try {
          setInputError({});
          const URL = crmEndpoints?.editContactPersonDetails;
          const payload = {
            query: {
              ...inputData,
              id: paginationDataSelector?.checkedRow[0],
            },
          };
          const response = await putRequest(
            navigate,
            URL,
            trimObjectValues(payload)
          );

          dispatch(tableDataFetch(filteredQuery, navigate));
          closeContactHandler();
          SetLoader(false);
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        } catch (error) {
          SetLoader(false);
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        } finally {
          setIsEdit(false);
          SetLoader(false);
        }
      }
    }
  };

  const searchHandler = (e, property) => {
    let tempItems = [...getPropertiesListSelector?.data?.hits];
    let values = dropdownValuesFromId(tempItems, property?.id);
    let data = [];
    if (property?.id === STRINGS.COMPANY_ID_SMALL) {
      data = values?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
    } else if (property?.id === STRINGS.PERSON_COUNTRY) {
      data = values?.filter((item) =>
        item?.labelValue
          ?.toLowerCase()
          ?.includes(e?.target?.value?.toLowerCase())
      );
    }
    // Storing searched Data into main state.
    let newData = [];
    tempItems?.map((item) => {
      if (
        item?.id === property?.id &&
        item?.field_type?.toString() === STRINGS.SINGLE_SELECT
      ) {
        let obj = {
          ...item,
          value: data,
        };
        newData?.push(obj);
      } else {
        newData.push(item);
      }
    });
    setPropertiesData(newData);
  };

  const dialogHeader = {
    heading: {
      id: "head",
      label: isEdit ? STRINGS.EDIT_CONTACTS : STRINGS.ADD_CONTACTS,
    },
  };

  const dialogFooter = (
    <div className={classes.addDialogFooter}>
      <div>
        <Button
          label={STRINGS.CANCEL}
          size="sm36"
          variant={"ghost"}
          onClick={() => { setOpen(!open); setIsEdit(false); }}
        />
      </div>
      <div>
        <Button
          label={isEdit ? STRINGS.UPDATE : STRINGS.ADD_CONTACTS}
          size="sm36"
          variant={"primary"}
          onClick={() => {
            saveContactHandler();
          }}
          isLoading={loader}
          disabled={disable}
        />
      </div>
    </div>
  );

  const type_id_components = (prop) => {
    switch (prop?.field_type[0]) {
      case STRINGS.DATE_PICKER:
        return (
          <CustomDatePicker
            size={"sm36"}
            value={inputData[prop?.id] || new Date()}
            onChange={(dateValue) =>
              setInputData({ ...inputData, [prop?.id]: dateValue })
            }
          />
        );

      case STRINGS.NUMBER:
        return (
          <InputField
            size={"sm36"}
            type={"number"}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
              if (inputData[prop?.id]?.length) {
                validationHandler({
                  ...inputData,
                  [prop?.id]: e.target.value,
                });
              }
            }}
            value={inputData[prop?.id]}
            placeholder={prop?.property_name}
            variant={inputError[prop?.id]?.length ? "error" : "default"}
            errormessage={inputError[prop?.id]}
          />
        );

      case STRINGS.MULTI_LINE_TEXT_INPUT:
        return (
          <TextArea
            rows={"5"}
            onChange={(e) =>
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              })
            }
            value={inputData[prop?.id]}
          />
        );

      case STRINGS.RICH_TEXT_EDITOR:
        return (
          <div className={classes.htmlEditor}>
            <HtmlEditor
              editorHeight={"12vh"}
              previosData={inputData[prop?.id] || ""}
              onInput={() => {
                setInputData({
                  ...inputData,
                  [prop?.id]: document?.getElementById("editor")?.innerHTML,
                });
              }}
              isAITagNotVisible
              isAddTagNotVisible
              isMeetingTagNotVisible
              isFocusNotRequired
            />
          </div>
        );

      case STRINGS.SINGLE_SELECT:
        return (
          <AutoCompleteCustom
            dropdownId="preview_dropdown"
            items={replaceKeyInArrayOfObj(prop?.value, "labelValue", "label")}
            size={"sm36"}
            valueState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputDataId[prop?.id]]
                : [inputData[prop?.id]]
            }
            setValueState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                })
                : setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                });
            }}
            idState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputData[prop?.id]]
                : [inputDataId[prop?.id]]
            }
            setIdState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                })
                : setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                });
            }}
            isSearchEnable={
              prop?.property_name === STRINGS.COMPANY ||
              STRINGS.PERSON_COUNTRY_CAPS
            }
            handleSearch={(event) => {
              searchHandler(event, prop);
            }}
          />
        );

      case STRINGS.MULTIPLE_SELECT:
        return (
          <AutoCompleteCustom
            dropdownId="preview_dropdown"
            items={replaceKeyInArrayOfObj(prop?.value, "labelValue", "label")}
            size={"sm36"}
            multiple
            valueState={inputData[prop?.id]}
            setValueState={(data) => {
              setInputData({
                ...inputData,
                [prop?.id]: data,
              });
            }}
            idState={inputDataId[prop?.id]}
            setIdState={(data) => {
              setInputDataId({
                ...inputDataId,
                [prop?.id]: data,
              });
            }}
          />
        );

      default:
        return (
          <InputField
            type={
              prop?.id === STRINGS.MOBILE_NUM_SMALL ||
                prop?.id === STRINGS.DIRECT_PHON_NUM_SMALL
                ? "number"
                : "text"
            }
            size={"sm36"}
            placeholder={`Enter ${prop?.property_name === STRINGS.EMAIL_ADDRESS || prop?.property_name === STRINGS.INDUSTRY_CAPS ? "an" : "a"} ${prop?.property_name}`}
            value={inputData[prop?.id]}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
              if (inputData[prop?.id]?.length) {
                validationHandler({
                  ...inputData,
                  [prop?.id]: e.target.value,
                });
              }
            }}
            variant={inputError[prop?.id]?.length ? "error" : "default"}
            errormessage={inputError[prop?.id]}
          />
        );
    }
  };

  useEffect(() => {
    if (open) {
      let static_properties_data = getPropertiesListSelector?.data?.hits?.filter(item => !item?.operation && item?.object_type[0] !== "Deals");
      setPropertiesData(static_properties_data);

      // edit contact dialog and storing data to state.
      if (isEdit) {
        const selected_contact_id = paginationDataSelector?.checkedRow[0];
        const selected_contact_data = tableDataSelector?.filter(item => item?._id === selected_contact_id)?.[0];
        setInputData({ ...selected_contact_data })
      }
    }
  }, [open]);

  console.log("IsEdit______ ", isEdit);

  useEffect(() => {
    validationHandler(inputData);
  }, [inputData]);

  return (
    <CustomDialog
      open={open}
      close={closeContactHandler}
      header={dialogHeader}
      footer={dialogFooter}
      width={"26.042vw"}
    >
      {/* DialogBody */}
      {getPropertiesListSelector?.loading ? (
        <div className={classes.addContactFormLoaderContainer}>
          {Array.from({ length: 20 }).map((index) => {
            return (
              <div key={`addContact${index}`}>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"30px"}
                  stackWidth={"100%"}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <form className={classes.inputForm} onSubmit={saveContactHandler}>
          {propertiesData?.map((prop) => {
            return (
              prop?.in_form && prop?.id !== STRINGS.COMPANY_ID && (
                <div className={classes.inputLabelField}>
                  <div className={classes.labelButtonContainer}>
                    <TypographyInline
                      label={prop?.property_name}
                      color={GREY.QUATINARY}
                      size={"sm"}
                      fontWeight={fontWeight.MEDIUM}
                    // isRequired={prop?.is_required}
                    />
                  </div>
                  {type_id_components(prop)}
                </div>
              )
            );
          })}
        </form>
      )}
      {/* DialogBody */}
    </CustomDialog>
  );
};

export default AddContactDialog;
