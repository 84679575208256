import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../../strings";
import { styles } from "./styles";
import { Button } from "../../../../components/Buttons";
import { GREY } from "../../../../utils/constants/colors";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import { HtmlEditor } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { authEndpoints } from "../../../../utils/constants/httpConstants";
import { postRequest } from "../../../../Apis";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import {
  compressString,
  decompressString,
  getErrorMessage,
} from "../../../../utils/helpers";
import { getRequest, patchRequest } from "../../../../Apis/apiWrapper";

const SignatureModal = ({ open, setOpen, dataRow }) => {
  const classes = styles();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoad,setIsLoad] = useState(false);
  const navigate = useNavigate();

  useEffect(()=> {
   (async () => {
    try{
      const response = await getRequest(navigate, `${authEndpoints.getSignatureRequest}?email=${dataRow.email}`)
      if(response?.data?.message === "No Signature Found"){
        setData("")  
      }
      else{
      setData(decodeURIComponent(decompressString(response?.data?.data?.signature)))
      }
    }
    catch(err){

    }
    finally{

    }
   }

   )();
  },   
  [])

  const pullSignatureRequest = async () => {
    try {
      setLoading(true);
      const response = await postRequest(
        navigate,
        authEndpoints.pullSignatureRequest,
        {
          email: dataRow?.email,
        }
      );
      const compressData = decodeURIComponent(
        decompressString(response?.data?.data)
      );
      setData(compressData);
      const resp = response?.data?.message;
      EventEmitter.emit(EMITTER_SUCCESS, resp);
      setLoading(false);
      // setReload(reload + 1);
      // handleClose();
    } catch (err) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.API_KEY,
      label: STRINGS.EDIT_SIGNATURE,
    },
  };

  const handleSave = async () => {
    try {
      setIsLoad(true)
      let payload = `<div>${document
        .getElementById("editor")
        ?.innerHTML?.toString()}</div>`;
      payload = compressString(encodeURIComponent(payload));
      const response = await patchRequest(
        navigate,
        authEndpoints.saveSignatureRequest,
        {
          signature:payload,
          email: dataRow?.email
        }
      );
      const resp = response?.data?.message;
      EventEmitter.emit(EMITTER_SUCCESS, resp);
      setIsLoad(false)
      setOpen(false)
    } catch (err) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
      );
    }finally{
      setIsLoad(false)
    }
  };

  const modalBodyData = (
    <div>
      <div className={classes.disSpace2}>
        <div className={classes.mTop}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.EMAIL_SIGNATURE}
          />
        </div>
        <div className={classes.mBot}>
          <Button
            label={STRINGS.PULL_SIGN}
            LeftIcon={ICON_LABELS.CLOUD_DOWNLOAD}
            variant={"ghost"}
            size={"xs28"}
            isLoading={loading}
            onClick={pullSignatureRequest}
          />
        </div>
      </div>
      <div className={classes.borderDataSet}>
        <HtmlEditor
          editorHeight="9.167vw"
          previosData={data?.length > 0 ? data || "" : ""}
          isAITagNotVisible
          isAddTagNotVisible
          isMeetingTagNotVisible
        />
      </div>
    </div>
  );
  const modalFooterLeftData = <div></div>;
  const modalFooterRightData = (
    <div className={classes.dFlexGap}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.SAVE_CHG}
        size={"sm36"}
        variant={"primary"}
        onClick={handleSave}
        isLoading={isLoad}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      width={"54.583vw"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default SignatureModal;
