import { postRequest } from "../../Apis";
import { sequenceEndPoints } from "../../utils/constants/httpConstants";
import { SEQUENCE_LIST_FAIL, SEQUENCE_LIST_REQUEST, SEQUENCE_LIST_SUCCESS } from "../constants/sequenceConstants";

export const getSequenceList = (sentQuery, navigate) => async (dispatch) => {
    try { 
      dispatch({type:SEQUENCE_LIST_REQUEST});
      const response = await postRequest(navigate, sequenceEndPoints.listingSequence, sentQuery)
      dispatch({
        type:SEQUENCE_LIST_SUCCESS,
        payload: response?.data,
      });
    } catch (err) {  
      dispatch({ type:SEQUENCE_LIST_FAIL , payload: err });
    }
  };