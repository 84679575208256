import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { styles } from "./styles";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { nameValidation } from "../../../../utils/validations";
import { HtmlEditor } from "../../../../components";
import {
  campaignValidation,
  numberValidation,
  numberValidation2,
  phoneValidation,
} from "../../../../utils/validations/inputValidations";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CampaignSettingsEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { patchRequest } from "../../../../Apis/apiWrapper";
import {
  addTagStyleInHtml,
  getErrorMessage,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import { getSignatureAction } from "../../../../redux/actions/authActions";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../../../Marketing/strings";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { BASE, GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import InputField from "../../../../components/InputFields/InputField";

export const CampaignSetting = () => {
  const classes = styles();
  const signatureSelector = useSelector((state) => state.getSignature);
  const data = signatureSelector?.payload?.data?.signature;
  const [firstname, setFirstname] = useState(data?.first_name || "");
  const [lastname, setLastname] = useState(data?.last_name || "");
  const [jobtitle, setJobtitle] = useState(data?.job_title || "");
  const [companyname, setCompanyname] = useState(data?.company_name || "");
  const [phone, setPhone] = useState(data?.phone_number || "");
  const [addressfirst, setAddressfirst] = useState(data?.address_line_1 || "");
  const [addresssecond, setAddresssecond] = useState(
    data?.address_line_2 || ""
  );
  const [city, setCity] = useState(data?.city || "");
  const [states, setStates] = useState(data?.state || "");
  const [country, setCountry] = useState(data?.country || "");
  const [zipcode, setZipcode] = useState(data?.zip_code || "");
  const [signature, setSignature] = useState(data?.first_name ? true : false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [loading1, setLoading1] = useState(false);
  const getEditorSelector = useSelector((state) => state.editorData);

  const navigate = useNavigate();
  const location = useLocation()
  const [validations, setValidations] = useState({
    firstname: "",
    lastname: "",
    jobtitle: "",
    companyname: "",
    addressfirst: "",
    city: "",
    states: "",
    country: "",
    zipcode: "",
  });



  const signatureHandler = (e) => {
    e.preventDefault();

    const validatedFirstName = campaignValidation("first_name", firstname);
    const validatedLastName = campaignValidation("last_name", lastname);
    const validatedJobtitle = campaignValidation("job", jobtitle);
    const validatedCompanyName = campaignValidation("company", companyname);
    const validatedAddressfirst = campaignValidation("address", addressfirst);
    const validatedCity = campaignValidation("city", city);
    const validatedState = campaignValidation("state", states);
    const validatedCountry = campaignValidation("country", country);
    const validatedZipcode = numberValidation2("zip", zipcode);
    const validatePhone = phone?.length ? phoneValidation(phone) : { isValid: true, message: "" };
    if (
      validatedFirstName.isValid &&
      validatedLastName.isValid &&
      validatedJobtitle.isValid &&
      validatedAddressfirst.isValid &&
      validatedCity.isValid &&
      validatedCompanyName.isValid &&
      validatedCountry.isValid &&
      validatedState.isValid &&
      validatedZipcode.isValid &&
      validatePhone.isValid
    ) {
      setValidations({
        firstname: "",
        lastname: "",
        jobtitle: "",
        companyname: "",
        addressfirst: "",
        city: "",
        states: "",
        country: "",
        zipcode: "",
        phone: ""
      });
    } else {
      setValidations({
        firstname: validatedFirstName.message,
        lastname: validatedLastName.message,
        jobtitle: validatedJobtitle.message,
        companyname: validatedCompanyName.message,
        addressfirst: validatedAddressfirst.message,
        city: validatedCity.message,
        country: validatedCountry.message,
        states: validatedState.message,
        zipcode: validatedZipcode.message,
        phone: validatePhone.message
      });
    }
  };

  const handleUnsubscribe = async () => {
    try {
      setLoading1(true);
      const customEditorValue = `<div>${document
        .getElementById("editor")
        ?.innerHTML?.toString()}</div>`;

      if (
        (getEditorSelector,
          getEditorSelector?.payload?.innerText.includes("{{unsubscribe_tag}}"))
      ) {
        const payload = {
          unsubscribe_message: removeTagStyleFromHtml(customEditorValue),
        };
        const response = await patchRequest(
          navigate,
          CampaignSettingsEndpoints.unsubscribeSignature,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setLoading1(false);
        dispatch(getSignatureAction(navigate));
      } else {
        EventEmitter.emit(EMITTER_ERROR, "Unsubscribe tag is mandatory");
      }
    } catch (err) {
      EventEmitter.emit(EMITTER_ERROR, err);
    } finally {
      setLoading1(false);
    }
  };

  const createCamp = async () => {
    const validatedFirstName = nameValidation(firstname);
    const validatedLastName = nameValidation(lastname);
    const validatedJobtitle = nameValidation(jobtitle);
    const validatedCompanyName = nameValidation(companyname);
    const validatedAddressfirst = nameValidation(addressfirst);
    const validatedCity = nameValidation(city);
    const validatedState = nameValidation(states);
    const validatedCountry = nameValidation(country);
    const validatedZipcode = numberValidation(zipcode);
    const validatePhone = phone?.length ? phoneValidation(phone) : { isValid: true, message: "" };
    if (
      validatedFirstName.isValid &&
      validatedLastName.isValid &&
      validatedJobtitle.isValid &&
      validatedAddressfirst.isValid &&
      validatedCity.isValid &&
      validatedCompanyName.isValid &&
      validatedCountry.isValid &&
      validatedState.isValid &&
      validatedZipcode.isValid && validatePhone.isValid
    ) {
      setValidations({
        firstname: "",
        lastname: "",
        jobtitle: "",
        companyname: "",
        addressfirst: "",
        city: "",
        states: "",
        country: "",
        zipcode: "",
        phone: ""
      });
      try {
        setLoading(true);
        const payload = {
          first_name: firstname,
          last_name: lastname,
          job_title: jobtitle,
          company_name: companyname,
          phone_number: phone?.trim(),
          address_line_1: addressfirst,
          address_line_2: addresssecond,
          city: city,
          state: states,
          country: country,
          zip_code: zipcode,
        };
        const response = await patchRequest(
          navigate,
          CampaignSettingsEndpoints.createCampaignSet,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        dispatch(getSignatureAction(navigate));
        setLoading(false);
      } catch (e) {
        console.log("e", e);
        EventEmitter.emit(EMITTER_ERROR, getErrorMessage([e?.data?.message]));
      } finally {
        setLoading(false);
      }
    } else {
      setValidations({
        firstname: validatedFirstName.message,
        lastname: validatedLastName.message,
        jobtitle: validatedJobtitle.message,
        companyname: validatedCompanyName.message,
        addressfirst: validatedAddressfirst.message,
        city: validatedCity.message,
        country: validatedCountry.message,
        states: validatedState.message,
        zipcode: validatedZipcode.message,
        phone: validatePhone.message
      });
    }
  };

  const cancelHandle = () => {
    setFirstname(data?.first_name || "");
    setLastname(data?.last_name || "");
    setJobtitle(data?.job_title || "");
    setCompanyname(data?.company_name || "");
    setPhone(data?.phone_number || "");
    setAddressfirst(data?.address_line_1 || "");
    setAddresssecond(data?.address_line_2 || "");
    setCity(data?.city || "");
    setStates(data?.state || "");
    setCountry(data?.country || "");
    setZipcode(data?.zip_code || "");
    setSignature(false);
  };

  useEffect(() => {


    (async () => {
      dispatch(getSignatureAction(navigate));
    })();

  }, [dispatch]);

  useEffect(() => {
    const docs = document.getElementById("editor");
    const finalResulttag = docs?.innerHTML?.includes("{{unsubscribe_tag}}");

    const ele = document.getElementById('campaignDefaultContainer');
    ele.scrollTop = 0;

  }, []);



  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ tab: "CampaignDefaults" }).toString(),
    });
    setFirstname(data?.first_name || "");
    setLastname(data?.last_name || "");
    setJobtitle(data?.job_title || "");
    setCompanyname(data?.company_name || "");
    setPhone(data?.phone_number || "");
    setAddressfirst(data?.address_line_1 || "");
    setAddresssecond(data?.address_line_2 || "");
    setCity(data?.city || "");
    setStates(data?.state || "");
    setCountry(data?.country || "");
    setZipcode(data?.zip_code || "");
    setSignature(false);
  }, [navigate, data]);

  return (
    <Box className={classes.container}>
      <Box className={classes.security} id="campaignDefaultContainer" >
        <Box className={classes.headerWrapCamp}>
          <TypographyInline
            size={"md"}
            color={GREY.PRIMARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.CAMPAIGN_DEFAULT}
          />
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.ANY_REACH}
          />
        </Box>
        <Box className={classes.campaignAuto}>
          <div className={classes.paddingBtCamp}>
            <TypographyInline
              size={"sm"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.EMAIL_SIGNATURE}
            />
          </div>
          <div className={classes.paddingBtCamp1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9} lg={10}>
                <Box
                  component="form"
                  onChange={() => setSignature(true)}
                  onSubmit={signatureHandler}
                >
                  <Box className={classes.campaignField}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.SEND_FIRST_NAME}
                              />
                              <InputField
                                variant={
                                  validations?.firstname?.length > 1
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.firstname
                                    ? validations?.firstname
                                    : ""
                                }
                                placeholder={STRINGS.ENTER_SEND_FIRST_NAME}
                                size={"md40"}
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.SEND_LAST_NAME}
                              />
                              <InputField
                                variant={
                                  validations?.lastname
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.lastname
                                    ? validations?.lastname
                                    : ""
                                }
                                placeholder={STRINGS.ENTER_SEND_LAST_NAME}
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.JOB_TITLE}
                              />
                              <InputField
                                variant={
                                  validations?.jobtitle
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.jobtitle
                                    ? validations?.jobtitle
                                    : ""
                                }
                                placeholder={STRINGS.ENTER_JOB_TITLE}
                                value={jobtitle}
                                onChange={(e) => setJobtitle(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.CAMPANY_NAME}
                              />
                              <InputField
                                variant={
                                  validations?.companyname
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.companyname
                                    ? validations?.companyname
                                    : ""
                                }
                                placeholder={STRINGS.ENTER_CAMPANY_NAME}
                                value={companyname}
                                onChange={(e) => setCompanyname(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.PHONE_NUMBER}
                              />
                              <InputField
                                placeholder={STRINGS.ENTER_PHON_NUM}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                size={"md40"}
                                variant={
                                  validations?.phone
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.phone
                                    ? validations?.phone
                                    : ""
                                }
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.ADDRESS_LINE}
                              />
                              <InputField
                                variant={
                                  validations?.addressfirst
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.addressfirst
                                    ? validations?.addressfirst
                                    : ""
                                }
                                placeholder={STRINGS.ENTER_ADD1}
                                value={addressfirst}
                                onChange={(e) => setAddressfirst(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.ADDRESS_LINE2}
                              />
                              <InputField
                                variant={
                                  validations?.addresssecond
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.addresssecond
                                    ? validations?.addresssecond
                                    : ""
                                }
                                placeholder={STRINGS.ENTER_ADD2}
                                value={addresssecond}
                                onChange={(e) => setAddresssecond(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.CITY}
                              />
                              <InputField
                                variant={
                                  validations?.city
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.city ? validations?.city : ""
                                }
                                placeholder={STRINGS.ENTER_CITY}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.STATE}
                              />
                              <InputField
                                variant={
                                  validations?.states
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.states ? validations?.states : ""
                                }
                                placeholder={STRINGS.ENTER_STATE}
                                value={states}
                                onChange={(e) => setStates(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.COUNTRY}
                              />
                              <InputField
                                variant={
                                  validations?.country
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.country ? validations?.country : ""
                                }
                                placeholder={STRINGS.ENTER_COUNTRY}
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.GAP}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.QUATINARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.POSTAL}
                              />
                              <InputField
                                variant={
                                  validations?.zipcode
                                    ? STRINGS.ERROR
                                    : STRINGS.DEFAULT
                                }
                                errormessage={
                                  validations?.zipcode ? validations?.zipcode : ""
                                }
                                placeholder={STRINGS.ENTER_POSTAL}
                                value={zipcode}
                                onChange={(e) => setZipcode(e.target.value)}
                                size={"md40"}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Box className={classes.settingPreview}>
                          <Box className={classes.previewHeader}>
                            <TypographyInline
                              size={"md"}
                              color={BASE.WHITE}
                              fontWeight={fontWeight.MEDIUM}
                              label={STRINGS.SIGNATURE_PREVIEW}
                            />
                          </Box>

                          <TypographyInline
                            size={"md"}
                            color={GREY.PRIMARY}
                            fontWeight={fontWeight.MEDIUM}
                            label={`${firstname} ${lastname}`}
                          />
                          <TypographyInline
                            size={"sm"}
                            color={GREY.SECONDARY}
                            fontWeight={fontWeight.REGULAR}
                            label={jobtitle}
                          />
                          <TypographyInline
                            size={"sm"}
                            color={GREY.SECONDARY}
                            fontWeight={fontWeight.REGULAR}
                            label={companyname}
                          />
                          <TypographyInline
                            size={"sm"}
                            color={GREY.SECONDARY}
                            fontWeight={fontWeight.REGULAR}
                            label={phone}
                          />

                          <p className={classes.margTopp}>
                            {addressfirst}
                            {addressfirst ? "," : ""} {addresssecond}
                            {addresssecond ? "," : ""} {city}
                            {city ? "," : ""} {zipcode}
                            {zipcode ? "," : ""} {states}
                            {states ? "," : ""} {country}
                          </p>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  {signature ? (
                    <Box className={classes.paddingBtn}>
                      <div className={classes.paddingBtnn}>
                        <Button
                          label={STRINGS.COMMON_CANCEL}
                          size={"sm36"}
                          variant={"ghost"}
                          onClick={cancelHandle}
                        />{" "}
                        <Button
                          label={STRINGS.COMMON_UPDATE}
                          size={"sm36"}
                          variant={"primary"}
                          onClick={createCamp}
                          isLoading={loading}
                        />
                      </div>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
          <Box className={classes.seprator}>
            <div className={classes.paddingBtCamp}>
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.UNSUSCRIBE}
              />
            </div>
            <div className={classes.widSet}>
              <Grid item xs={12} lg={9}>
                <Grid item xs={12} lg={12}>
                  <Box className={classes.BORDER}>
                    <HtmlEditor
                      isAITagNotVisible={true}
                      isUnsubscirbeTagVisible
                      isMeetingTagNotVisible
                      editorHeight="130px"
                      previosData={
                        !loading1
                          ? addTagStyleInHtml(
                            signatureSelector?.payload?.data
                              ?.unsubscribe_message ||
                            `If you’d rather not receive future emails from us, you may<span data-dt="dynamicTag" data-id="{{unsubscribe_tag}}" style="background: rgb(255, 233, 233); color: rgb(255, 72, 72); width: fit-content; border-radius: 4px; font-size: small; font-family: inherit; padding: 4px 8px;">{{unsubscribe_tag}}</span>`
                          )
                          : null
                      }
                    />
                  </Box>
                  <Box className={classes.paddingBtn}>
                    <div>
                      {" "}
                      <Button
                        label={STRINGS.COMMON_SAVE}
                        size={"sm36"}
                        disabled={
                          !document
                            ?.getElementById("editor")
                            ?.innerText?.includes?.("{{unsubscribe_tag}}")
                        }
                        variant={"primary"}
                        onClick={handleUnsubscribe}
                        isLoading={loading1}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
