export const GET_DASHBOARD_LIST_REQUEST = "GET_DASHBOARD_LIST_REQUEST"
export const GET_DASHBOARD_LIST_SUCCESS = "GET_DASHBOARD_LIST_SUCCESS"
export const GET_DASHBOARD_LIST_FAIL = "GET_DASHBOARD_LIST_FAIL"

export const GET_DASHBOARD_WIDGET_LIST_REQUEST = "GET_DASHBOARD_WIDGET_LIST_REQUEST"
export const GET_DASHBOARD_WIDGET_LIST_SUCCESS = "GET_DASHBOARD_WIDGET_LIST_SUCCESS"
export const GET_DASHBOARD_WIDGET_LIST_FAIL = "GET_DASHBOARD_WIDGET_LIST_FAIL"

export const GET_CHART_DATA_REQUEST = "GET_CHART_DATA_REQUEST"
export const GET_CHART_DATA_SUCCESS = "GET_CHART_DATA_SUCCESS"
export const GET_CHART_DATA_FAIL = "GET_CHART_DATA_FAIL"

export const GET_WIDGET_COORDINATES = "GET_WIDGET_COORDINATES"

export const GET_CURRENT_DASHBOARD = "GET_CURRENT_DASHBOARD"