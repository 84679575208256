import { createSearchParams } from "react-router-dom";
import {
  DEFAULT_COMPANY_EMPLOYEES_COUNT,
  DEFAULT_EMAIL_VERIFICATION_TYPES,
  DEFAULT_SENIORIRT_TYPES,
  requiredKeysOfStorage,
  tempHeadCells,
} from "../../utils/constants";
import {
  allDynamicFilters,
  allFilters,
  filterHeaderCompanyCity,
  filterHeaderCompanyCountry,
  filterHeaderCompanyKeywords,
  filterHeaderCompanyName,
  filterHeaderCompanySeo,
  filterHeaderCompanyState,
  filterHeaderCompanyTechnologies,
  filterHeaderEmailVerification,
  filterHeaderPersonCity,
  filterHeaderPersonCountry,
  filterHeaderPersonState,
  filterHeaderSearchCompany,
  filterHeaderSearchIndustry,
  filterHeaderSearchJobTitle,
  filterHeaderSearchSeniority,
  filterMultipleList,
} from "../actions/filterActions";
import {
  applyUpdateColumn,
  dynamicColumnUpdateHeaders,
  dynamicUpdatedColumn,
  tableDataFetch,
  updateColumnHeaders,
} from "../actions/tableActions";
import {
  initialCompanyNameFilterPayload,
  initialDynamicQueryPayload,
  initialFilterPayload,
  initialQueryPayload,
} from "./storeConstants";
import { getCurrentDashboard } from "../actions/dashboardActions";
import { getSalesContactsList } from "../actions/salesTableAction";
import { getDealsList } from "../actions/dealsActions";
import { GET_DASHBOARD_LIST_SUCCESS } from "../constants/dashboardConstants";

export const getCheckEmployeeCount = (current_count, selectedCount) => {
  const result = [];
  selectedCount = selectedCount?.map((item) => item?.toString());
  for (let i = 0; i < current_count?.length; i++) {
    if (selectedCount?.indexOf(current_count[i].index?.toString()) === -1) {
      result.push(current_count[i]);
    } else {
      result.push({ ...current_count[i], isChecked: true });
    }
  }
  return result;
};

export const getSelectedSeniority = (current_count, selectedCount) => {
  const result = [];
  for (let i = 0; i < current_count?.length; i++) {
    if (selectedCount?.indexOf(current_count[i].type) === -1) {
      result.push(current_count[i]);
    } else {
      result.push({ ...current_count[i], isChecked: true });
    }
  }
  return result;
};

export const checkExistFields = (query, filter) => {


  let isKnown = false;
  let isUnknown = false;
  let anyOf = false;
  let notAnyOf = false;
  let allOf = false

  if (
    query?.exist_fields?.length &&
    query?.exist_fields?.indexOf(filter) !== -1
  ) {
    isKnown = true;
    isUnknown = false;
  }
  if (
    query?.not_exist_fields?.length &&
    query?.not_exist_fields?.indexOf(filter) !== -1
  ) {
    isUnknown = true;
    isKnown = false;
  }
  if (query?.[filter]?.length > 0) {
    anyOf = true;
  }
  if (query?.[`${filter}_not`]?.length > 0) {
    notAnyOf = true;
  }

  if (query?.[`${filter}_all`]?.length > 0) {
    allOf = true;
  }
  return { payload: { allOf, anyOf, notAnyOf, isKnown, isUnknown } };
};

export const checkDynamicExistFields = (query) => {
  let obj = {};

  for (const id in query) {
    if (
      id !== "page" &&
      id !== "search" &&
      id !== "deal_name" &&
      id !== "sort" &&
      id !== "direction" &&
      id !== "limit"
    ) {
      if (id === "exist_fields") {
        query?.exist_fields.forEach((element) => {
          obj = {
            ...obj,
            [element]: {
              isKnown: true,
              isUnknown: false,
              anyOf: false,
              notAnyOf: false,
            },
            isApiCall: true,
          };
        });
      } else if (id === "not_exist_fields") {
        query?.not_exist_fields.forEach((element) => {
          obj = {
            ...obj,
            [element]: {
              isUnknown: true,
              isKnown: false,
              anyOf: false,
              notAnyOf: false,
            },
            isApiCall: true,
          };
        });
      } else if (id.split("-")[0] === "max") {
        let new_id = id?.replace("max-", "");
        obj = {
          ...obj,
          [new_id]: {
            ...obj?.[new_id],
            max: parseInt(query[id]),
          },
          isApiCall: true,
        };
      } else if (id.split("-")[0] === "min") {
        let new_id = id?.replace("min-", "");
        obj = {
          ...obj,
          [new_id]: {
            ...obj?.[new_id],
            min: parseInt(query[id]),
          },
          isApiCall: true,
        };
      } else if (id.split("-")[0] === "to") {
        let new_id = id?.replace("to-", "");
        obj = {
          ...obj,
          [new_id]: {
            ...obj?.[new_id],
            to: query[id]?.toString(),
          },
          isApiCall: true,
        };
      } else if (id.split("-")[0] === "from") {
        let new_id = id?.replace("from-", "");
        obj = {
          ...obj,
          [new_id]: {
            ...obj?.[new_id],
            from: query[id]?.toString(),
          },
          isApiCall: true,
        };
      } else if (id.startsWith("cb-")) {
        if (id?.includes("number_of_employees")) {
          let ids = [...query[id]];
          let int_ids = ids?.map((id) => parseInt(id));
          obj = {
            ...obj,
            [id]: [...int_ids],
            isApiCall: true,
          };
        } else {
          obj = {
            ...obj,
            [id]: [...query[id]],
            isApiCall: true,
          };
        }
      } else if (id === "name" || id === "search") {
        obj = { ...obj, [id]: query?.[id], isApiCall: true };
      } else {
        if (id?.includes("-not")) {
          let new_id = id?.replace("-not", "");
          obj = {
            ...obj,
            [new_id]: {
              ...obj?.[new_id],
              notAnyOf: true,
              notAnyOfList: [...query?.[id]],
              isKnown: false,
              isUnknown: false,
            },
            isApiCall: true,
          };
        } else {
          obj = {
            ...obj,
            [id]: {
              ...obj?.[id],
              anyOf: true,
              anyOfList: [...query?.[id]],
              isKnown: false,
              isUnknown: false,
            },
            isApiCall: true,
          };
        }
      }
    } else {
      if (id === "deal_name") {
        if (query?.[id]?.length) {
          obj = { ...obj, deal_name: query?.[id]?.toString(), isApiCall: true };
        } else {
          delete obj?.deal_name;
        }
      } else {
        obj = { ...obj, [id]: query?.[id], isApiCall: true };
      }
    }
  }
  return { loading: false, payload: { ...obj } };
};

export const checkExistFieldsCompanyName = (query, filter, callApi = false) => {
  let isKnown = false;
  let isUnknown = false;
  let anyOf = false;
  let notAnyOf = false;

  if (
    query?.exist_fields?.length &&
    query?.exist_fields?.indexOf(filter) !== -1
  ) {
    isKnown = true;
    isUnknown = false;
  }

  if (
    query?.not_exist_fields?.length &&
    query?.not_exist_fields?.indexOf(filter) !== -1
  ) {
    isUnknown = true;
    isKnown = false;
  }
  if (query?.["company_ids"]?.length > 0) {
    anyOf = true;
  }
  if (query?.[`${"company_ids"}_not`]?.length > 0) {
    notAnyOf = true;
  }

  if (callApi && (anyOf || notAnyOf)) {
  }

  return { payload: { anyOf, notAnyOf, isKnown, isUnknown } };
};

export const HandleClearAllFilterFromStore = (dispatch, location, navigate, table_columns = []) => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!requiredKeysOfStorage?.includes(key)) {
      localStorage.removeItem(key);
    }
  }

  dispatch(
    filterHeaderSearchCompany(
      JSON.parse(JSON.stringify(DEFAULT_COMPANY_EMPLOYEES_COUNT))
    )
  );
  dispatch(
    filterHeaderSearchSeniority(
      JSON.parse(JSON.stringify(DEFAULT_SENIORIRT_TYPES))
    )
  );
  dispatch(
    filterHeaderEmailVerification(
      JSON.parse(JSON.stringify(DEFAULT_EMAIL_VERIFICATION_TYPES))
    )
  );
  dispatch(filterHeaderSearchJobTitle(initialFilterPayload));
  dispatch(filterHeaderSearchIndustry(initialFilterPayload));
  dispatch(filterHeaderCompanyKeywords(initialFilterPayload));
  dispatch(filterHeaderCompanyTechnologies(initialFilterPayload));
  dispatch(filterHeaderCompanySeo(initialFilterPayload));
  dispatch(filterHeaderPersonCity(initialFilterPayload));
  dispatch(filterHeaderPersonState(initialFilterPayload));
  dispatch(filterHeaderPersonCountry(initialFilterPayload));
  dispatch(filterHeaderCompanyCity(initialFilterPayload));
  dispatch(filterHeaderCompanyState(initialFilterPayload));
  dispatch(filterHeaderCompanyCountry(initialFilterPayload));

  // handle company name filter here.
  dispatch(filterHeaderCompanyName(initialCompanyNameFilterPayload));
  dispatch(allFilters({ ...initialQueryPayload }, true));
  dispatch(applyUpdateColumn([...tempHeadCells]));
  dispatch(updateColumnHeaders([...tempHeadCells]));
  navigate({
    pathname: location.pathname,
    search: createSearchParams(initialQueryPayload).toString(),
  });

  // clearing all the filters of custom table.

  // handle current dashboard here.
  dispatch(getCurrentDashboard({}));

  // handle dynamic filter.
  dispatch(allDynamicFilters(initialDynamicQueryPayload, navigate));

  dispatch(filterMultipleList(initialDynamicQueryPayload, navigate));

  // removing the dashboard data.
  dispatch(getCurrentDashboard({}));
  dispatch({
    type: GET_DASHBOARD_LIST_SUCCESS,
    payload: {},
  });

  if (table_columns?.length) {
    dispatch(dynamicUpdatedColumn(table_columns));
    dispatch(dynamicColumnUpdateHeaders(table_columns));
  }
};
