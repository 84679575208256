import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import { deleteRequest, getRequest } from "../../../../Apis";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../../utils/constants";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../../strings";
import classes from "./DealsComp.module.css";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import { useNavigate } from "react-router-dom";

const NoteDeleteModal = ({
  id,
  openNote,
  setOpenNote,
  dealId,
  getNoteLogList,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const deleteMethod = async () => {
    setLoading(true);
    try {
      let payload = {
        query: {
          id: id,
          deal_id: dealId,
        },
      };
      const URL = `${crmEndpoints?.deleteNotes}`;
      const response = await deleteRequest(URL, payload, navigate);
      setLoading(false);

      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

      setOpenNote(false);
      getNoteLogList();
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.SMALL_HEAD,
      label: `Delete`,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.ARE_YOU_SURE_DELETE}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpenNote(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={() => deleteMethod()}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <div>
      {" "}
      <CustomModal
        variant={"error"}
        close={() => setOpenNote(false)}
        open={openNote}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
      />
    </div>
  );
};
export default NoteDeleteModal;
