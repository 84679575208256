import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { postRequest } from "../../../Apis";
import { CircularLoader } from "../../../components";
import Image from "../../../components/Image/Image";
import { allFilters } from "../../../redux/actions/filterActions";
import { HandleClearAllFilterFromStore } from "../../../redux/store/storeHelper";
import { DUMMY_CONTACT2, SAD_FACE } from "../../../utils/constants/assets";
import { leadsEndpoints } from "../../../utils/constants/httpConstants";
import { PEOPLE_SCREEN_PATH } from "../../../utils/constants/routes";
import UserDetailModal from "./UserDetailModal";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import AvatarName from "../../../components/Avatar/AvatarName";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import classes from "./LeadsComponent.module.css";
import AvatarOutlined from "../../../components/Avatar/AvatarOutlined";
import { tableEndpointChange } from "../../../redux/actions/tableActions";

const CompanyEmployeesModal = ({
  companyId,
  companyName,
  showUserDetailModal,
  setShowUserDetailModal,
  setEmployeesModal,
  employeeModal,
  savedContactsTrue = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const companyTableEndpointChangeSelector = useSelector((state) => state?.companyTableEndpointChange);

  const [employeeDetail, setEmployeeDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState("");
  useEffect(async () => {
    try {
      setLoading(true);
      const response = savedContactsTrue
        ? await postRequest(navigate, leadsEndpoints.savedContactSearch, {
          company_ids: [companyId],
          table_columns: [STRINGS.FIRST_NAME, STRINGS.LAST_NAME],
        })
        : await postRequest(navigate, leadsEndpoints.search, {
          company_ids: [companyId],
          table_columns: [STRINGS.FIRST_NAME, STRINGS.LAST_NAME],
        });
      setEmployeeDetail(response?.data?.data?.records);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const viewMoreHandler = () => {

    // const enpointValue = `${companyTableEndpointChangeSelector?.payload?.split(" ")?.[0] || STRINGS.NEW} ${STRINGS.PROSPECTS}`

    HandleClearAllFilterFromStore(dispatch, location, navigate);
    // dispatch(tableEndpointChange(enpointValue));
    dispatch(allFilters({ company_ids: [companyId] }));
    navigate({
      pathname: PEOPLE_SCREEN_PATH,
      search: createSearchParams({
        company_ids: [companyId],
      }).toString(),
    });
    // to get these detail in people screen to display company name in add filter modal.
    // window.company_ids = [{ id: companyId, name: companyName }];
  };

  return (
    <Box className={classes.employeeModalMainContainer}>
      {/* Body */}
      {loading ? (
        // Loading state.
        <Box className={classes.loaderContainer2}>
          <CircularLoader />
        </Box>
      ) : !loading && employeeDetail?.length === 0 ? (
        // Employee Data not present.
        <Box className={classes.noRecordsContainer}>
          <Image src={SAD_FACE} key={STRINGS.SAD_FACE} />
          <TypographyInline
            label={STRINGS.NO_RECORD}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />

          <TypographyInline
            label={STRINGS.SERACH_COMPANIES}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
        </Box>
      ) : (
        // Employee Data is present.
        <Box className={classes.employeeModalBody}>
          {employeeDetail?.slice(0, 7)?.map((employee, index) => {
            return (
              <Box
                key={index}
                className={
                  index < 7
                    ? classes.employeeDetailContainer
                    : classes.viewMoreContainer
                }
              >
                <Box className={classes.employeeDetail}>
                  {employee.first_name || employee.last_name ? (
                    <AvatarName
                      size={"small"}
                      index={index + 1}
                      name={`${employee.first_name} ${employee.last_name}`}
                    />
                  ) : (
                    <AvatarOutlined size={"small"}>
                      <Image
                        src={DUMMY_CONTACT2}
                        alt=""
                        style={{
                          height: "18px",
                          width: "18px",
                        }}
                      />
                    </AvatarOutlined>
                  )}
                  <div
                    onClick={() => {
                      setEmployeesModal(true);
                    }}
                  >
                    <TypographyInline
                      label={`${employee.first_name || ""} ${employee.last_name || ""
                        }`}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                      color={GREY.SECONDARY}
                    />
                  </div>
                  <UserDetailModal
                    user={employee}
                    setShowUserDetailModal={setShowUserDetailModal}
                    showUserDetailModal={showUserDetailModal}
                    modalOpen={employeeModal}
                    setIsActive={setIsActive}
                    isCompanyTable
                  />
                </Box>
              </Box>
            );
          })}
          {employeeDetail?.length > 7 && (
            <Button
              size={"sm36"}
              label={STRINGS.VIEW_MORE}
              variant={"text"}
              onClick={viewMoreHandler}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default CompanyEmployeesModal;
