import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { getRequest } from "../../../Apis";
import {
  leadsEndpoints,
  viewEndPoints,
} from "../../../utils/constants/httpConstants";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { deleteRequest } from "../../../Apis/apiWrapper";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import {
  COMPANY_EMPLOYEES_COUNT,
  EMAIL_VERIFICATION_TYPES,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  SENIORIRT_TYPES,
  tempHeadCells,
} from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getPeopleBasicDetails } from "../../../redux/actions/peopleActions";
import { getCompanyBasicDetails } from "../../../redux/actions/companyActions";
import {
  checkExistFields,
  getCheckEmployeeCount,
  getSelectedSeniority,
  HandleClearAllFilterFromStore,
} from "../../../redux/store/storeHelper";
import {
  filterHeaderIndustry,
  filterHeaderJobTitle,
  initialFilterPayload,
  stringQueryList,
} from "../../../redux/store/storeConstants";
import { filterKeys } from "../../../utils/constants/filters";
import {
  allFilters,
  filterHeaderCompanyCity,
  filterHeaderCompanyCountry,
  filterHeaderCompanyKeywords,
  filterHeaderCompanySeo,
  filterHeaderCompanyState,
  filterHeaderCompanyTechnologies,
  filterHeaderEmailVerification,
  filterHeaderPersonCity,
  filterHeaderPersonCountry,
  filterHeaderPersonState,
  filterHeaderSearchCompany,
  filterHeaderSearchIndustry,
  filterHeaderSearchJobTitle,
  filterHeaderSearchSeniority,
} from "../../../redux/actions/filterActions";
import {
  applyUpdateColumn,
  companyDataFetch,
  tableDataFetch,
  updateColumnHeaders,
} from "../../../redux/actions/tableActions";
import AddViewModal from "./AddViewModal";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import { GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import { SHADOW, fontSize } from "../../../utils/constants/UI/uiConstants";
import TypographyInline from "../../../components/Typography/TypographyInline";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import IconComponent from "../../../components/Icon/IconComponent";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";
import { Mixpanel } from "../../../Apis/Mixpanel";
import classes from "./LeadsComponent.module.css";
import ManageViewCard from "../../CRM/Contacts/components/ManageViewCard";
import EmptyViewCard from "./EmptyViewCard";

const AllViewModal = ({
  open,
  setOpen,
  leadType = STRINGS.PEOPLE,
  setOpenAddViewModal = () => { },
  setViewType,
  viewType = ""
}) => {
  const session_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);
  const active_view = session_id
    ? session_id
    : leadType === STRINGS.PEOPLE
      ? STRINGS.ALL_CONTACTS
      : STRINGS.ALL_COMPANIES;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [publicViews, setPublicViews] = useState([]);
  const [privateViews, setPrivateViews] = useState([]);
  const [teamViews, setTeamViews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showActions, setShowActions] = useState("");
  const [viewCardMenu, setViewCardMenu] = useState(null);
  const [privateViewCardMenu, setPrivateViewCardMenu] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [performing, setPerforming] = useState(false);
  const [renameViewPopup, setRenameViewPopup] = useState(false);
  const [renamePopupValue, setRenamePopupValue] = useState({});
  const [viewDetail, setViewDetail] = useState({});
  const [isPin, setIsPin] = useState(0);
  const [isRename, setIsRename] = useState(false);

  const [viewOwners, setViewOwners] = useState([]);
  const peopleBasicDetailsSelector = useSelector(
    (state) => state.peopleBasicDetails
  );
  const companyBasicDetailsSelector = useSelector(
    (state) => state.companyBasicDetails
  );
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const userDetail = userDetailsSelector?.payload?.role;
  async function getViews() {
    setLoading(true);
    try {
      const URL =
        leadType === STRINGS.PEOPLE
          ? viewEndPoints.getAllView
          : viewEndPoints.getCompanyViews;
      const response = await getRequest(navigate, URL);
      setPublicViews(response?.data?.data?.public);
      setPrivateViews(response?.data?.data?.private);
      setTeamViews(response?.data?.data?.team);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }
  const handleChangeView = (query) => {
    HandleClearAllFilterFromStore(dispatch, location, navigate);
    const res = query;
    if (res) {
      for (let i in res) {
        if (
          typeof res[i] === STRINGS.STRING &&
          stringQueryList?.indexOf(i) === -1
        ) {
          res[i] = [res[i]];
        }
      }
    }
    const filterHeaderSeniority = {
      payload: res?.seniority
        ? getSelectedSeniority(SENIORIRT_TYPES, res?.seniority)
        : SENIORIRT_TYPES,
    };

    const filterHeaderCompany = {
      payload: res.employee_number
        ? getCheckEmployeeCount(
          JSON.parse(JSON.stringify(COMPANY_EMPLOYEES_COUNT)),
          res.employee_number
        )
        : JSON.parse(JSON.stringify(COMPANY_EMPLOYEES_COUNT)),
    };

    const initalJobTitleSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.job_title?.length ||
        res?.job_title_not?.length
        ? checkExistFields(res, filterKeys.job_title)
        : JSON.parse(JSON.stringify(filterHeaderJobTitle));

    const initalIndustrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.industry?.length ||
        res?.industry_not?.length
        ? checkExistFields(res, filterKeys.industry)
        : JSON.parse(JSON.stringify(filterHeaderIndustry));

    const InitialEmailVerification = {
      payload: res?.email_verification
        ? getCheckEmployeeCount(
          EMAIL_VERIFICATION_TYPES,
          res?.email_verification
        )
        : EMAIL_VERIFICATION_TYPES,
    };

    const initalCompanyKeywordsSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_keywords?.length ||
        res?.company_keywords_not?.length
        ? checkExistFields(res, STRINGS.COMPANY_KEYWORDS)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyTechnologySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_technologies?.length ||
        res?.company_technologies_not?.length
        ? checkExistFields(res, STRINGS.COMPANY_TECHNOLOGIES)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanySeoSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.seo_description?.length ||
        res?.seo_description_not?.length
        ? checkExistFields(res, STRINGS.SEO_DESCRIPTION)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyCitySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_city?.length ||
        res?.company_city_not?.length
        ? checkExistFields(res, STRINGS.COMPANY_CITY)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyStateSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_state?.length ||
        res?.company_state_not?.length
        ? checkExistFields(res, STRINGS.COMPANY_STATE)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyCountrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_country?.length ||
        res?.company_country_not?.length
        ? checkExistFields(res, STRINGS.COMPANY_COUNTRY)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonCitySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_city?.length ||
        res?.person_city_not?.length
        ? checkExistFields(res, STRINGS.PERSON_CITY)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonStateSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_state?.length ||
        res?.person_state_not?.length
        ? checkExistFields(res, STRINGS.PERSON_STATE)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonCountrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_country?.length ||
        res?.person_country_not?.length
        ? checkExistFields(res, STRINGS.PERSON_COUNTRY)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    dispatch(
      filterHeaderSearchCompany(
        JSON.parse(JSON.stringify(filterHeaderCompany?.payload))
      )
    );
    dispatch(
      filterHeaderSearchSeniority(
        JSON.parse(JSON.stringify(filterHeaderSeniority?.payload))
      )
    );
    dispatch(
      filterHeaderEmailVerification(
        JSON.parse(JSON.stringify(InitialEmailVerification?.payload))
      )
    );
    dispatch(filterHeaderSearchJobTitle(initalJobTitleSelect?.payload));
    dispatch(filterHeaderSearchIndustry(initalIndustrySelect?.payload));
    dispatch(filterHeaderCompanyKeywords(initalCompanyKeywordsSelect?.payload));
    dispatch(
      filterHeaderCompanyTechnologies(initalCompanyTechnologySelect?.payload)
    );
    dispatch(filterHeaderCompanySeo(initalCompanySeoSelect?.payload));
    dispatch(filterHeaderPersonCity(initalPersonCitySelect?.payload));
    dispatch(filterHeaderPersonState(initalPersonStateSelect?.payload));
    dispatch(filterHeaderPersonCountry(initalPersonCountrySelect?.payload));
    dispatch(filterHeaderCompanyCity(initalCompanyCitySelect?.payload));
    dispatch(filterHeaderCompanyState(initalCompanyStateSelect?.payload));
    dispatch(filterHeaderCompanyCountry(initalCompanyCountrySelect?.payload));
    dispatch(allFilters(query));
    navigate({
      pathname: location.pathname,
      search: createSearchParams(query || {}).toString(),
    });
  };

  useEffect(() => {
    if (open) {
      getViews();
    }
  }, [open]);

  const handleMouseEnter = (
    eachView,
    index,
    event,
    owners,
    isPrivate = false,
    is_pin
  ) => {
    setIsPin(is_pin);
    setViewDetail(eachView);
    setViewOwners(owners);
    setShowActions(eachView?.view_id);
    if (isPrivate) {
      setPrivateViewCardMenu(event?.currentTarget);
    } else {
      if (leadType === STRINGS.PEOPLE) {
        if (owners?.length === 1) {
          if (
            !(
              eachView?.view_id ===
              peopleBasicDetailsSelector?.payload?.default_view?.view_id
            )
          )
            setViewCardMenu(event?.currentTarget);
        } else {
          setViewCardMenu(event?.currentTarget);
        }
      } else {
        if (owners?.length === 1) {
          if (
            !(
              eachView?.view_id ===
              companyBasicDetailsSelector?.payload?.default_view?.view_id
            )
          )
            setViewCardMenu(event?.currentTarget);
        } else {
          setViewCardMenu(event?.currentTarget);
        }
      }
    }
  };

  const handleDelete = async () => {
    // call delete action here.
    try {
      const URL =
        leadType === STRINGS.PEOPLE
          ? viewEndPoints.deleteView
          : viewEndPoints.deleteCompanyView;
      setPerforming(true);
      const response = await deleteRequest(
        URL + `?${STRINGS.VIEW_ID}=` + showActions,
        {},
        navigate
      );
      const active_view_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);
      if (showActions === active_view_id) {
        sessionStorage.removeItem(STRINGS.ACTIVE_VIEW_ID);
      }
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
        Mixpanel.track(STRINGS.DELETE_VIEW);

        getViews();
        setPerforming(false);
        setOpenConfirmation(false);
        leadType === STRINGS.PEOPLE
          ? dispatch(getPeopleBasicDetails(navigate))
          : dispatch(getCompanyBasicDetails(navigate));
      }
    } catch (error) {
      setPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handlePin = async (viewDetail) => {
    try {
      setViewCardMenu(null);
      setPrivateViewCardMenu(null);
      const URL =
        leadType === STRINGS.PEOPLE
          ? viewEndPoints.pinView
          : viewEndPoints.pinCompanyView;
      const response = await getRequest(
        navigate,
        URL + `?${STRINGS.VIEW_ID}=` + viewDetail?.view_id
      );
      if (leadType === STRINGS.PEOPLE) {
        let active_view_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);
        let response;
        if (active_view_id?.length) {
          response = await getRequest(
            navigate,
            leadsEndpoints.peopleBasic + `?${STRINGS.VIEW_ID}=${active_view_id}`
          );
        } else {
          response = await getRequest(navigate, leadsEndpoints.peopleBasic);
        }
        await dispatch(
          getPeopleBasicDetails(navigate, true, response?.data?.data)
        );
      } else {
        await dispatch(getCompanyBasicDetails(navigate));
      }

      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        Mixpanel.track(STRINGS.PINNED_VIEW);
        getViews();
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleUnpinView = async (viewDetail) => {
    try {
      let URL =
        leadType === STRINGS.PEOPLE
          ? viewEndPoints?.unpinView
          : viewEndPoints.unpinCompanyView;
      setViewCardMenu(null);
      setPrivateViewCardMenu(null);
      const response = await getRequest(
        navigate,
        URL + `?${STRINGS.VIEW_ID}=` + viewDetail?.view_id
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        leadType === STRINGS.PEOPLE
          ? await dispatch(getPeopleBasicDetails(navigate))
          : await dispatch(getCompanyBasicDetails(navigate));
        getViews();
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  const handleDeleteConf = () => {
    setIsRename(false);
    setOpenConfirmation(true);
    setViewCardMenu(null);
    setPrivateViewCardMenu(null);
  };

  const renameViewHandler = (eachView, type) => {
    setIsRename(true);
    setRenamePopupValue({ eachView: eachView, type: type });
    setRenameViewPopup(true);
    setViewCardMenu(null);
    setPrivateViewCardMenu(null);
  };

  const actionList = [
    {
      type: STRINGS.PIN_VIEW_TO_KEEP_OPEN,
      action: handlePin,
      icon: ICON_LABELS.PUSH_PIN,
    },
    {
      type: STRINGS.EDIT_VIEW,
      action: renameViewHandler,
      icon: ICON_LABELS.EDIT,
    },
    {
      type: STRINGS.DELETE_THIS_VIEW,
      action: handleDeleteConf,
      icon: ICON_LABELS.DELETE,
    },
  ];

  const actionLists = [
    {
      type: STRINGS.PIN_VIEW_TO_KEEP_OPEN,
      action: handlePin,
      icon: ICON_LABELS.PUSH_PIN,
    },

    // {
    //   type: STRINGS.DELETE_THIS_VIEW,
    //   action: handleDeleteConf,
    //   icon: ICON_LABELS.DELETE,
    // },
  ];

  const handleCreateTempActiveView = async (view) => {
    try {
      sessionStorage.setItem(STRINGS.ACTIVE_VIEW_ID, view?.view_id.toString());
      const URL =
        leadType === STRINGS.PEOPLE
          ? leadsEndpoints.peopleBasic
          : leadsEndpoints.companyBasic;
      const action =
        leadType === STRINGS.PEOPLE
          ? getPeopleBasicDetails
          : getCompanyBasicDetails;
      const response = await getRequest(
        navigate,
        URL + `?${STRINGS.VIEW_ID}=` + view?.view_id
      );
      let table_columns =
        response?.data?.data?.active_view?.table_columns || [];
      if (!table_columns.length) {
        table_columns = [
          ...tempHeadCells
            .filter((item) => item.isChecked)
            .map((item) => item.id),
        ];
      }
      const new_table_cells_payload = [];
      for (let i in tempHeadCells) {
        if (table_columns.includes(tempHeadCells[i].id)) {
          new_table_cells_payload.push({
            ...tempHeadCells[i],
            isChecked: true,
          });
        } else {
          new_table_cells_payload.push({
            ...tempHeadCells[i],
            isChecked: false,
          });
        }
      }

      await dispatch(action(navigate, true, response?.data?.data));
      handleChangeView(response?.data?.data?.active_view?.query_string || {});
      dispatch(applyUpdateColumn(new_table_cells_payload));
      dispatch(updateColumnHeaders(new_table_cells_payload));
      setOpen(false);
    } catch (error) { }
  };

  const viewDeleteModalBody = (
    <div>
      {" "}
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.ARE_YOU_SURE_DELETE}
      />
    </div>
  );

  const viewDeleteModalHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.DELETE_THIS_VIEW,
    },
  };

  const viewDeleteModalRightFooter = (
    <div className={classes.viewDeleteModalRightFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpenConfirmation(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={handleDelete}
        isLoading={performing}
      />
    </div>
  );

  const viewDeleteModalLeftFooter = <div></div>;

  const allViewModalBody = (
    <div>
      <Box className={classes.allViewContainer}>
        {/* Public View Container */}
        <Box className={classes.eachViewContain}>
          <Box className={classes.eachViewHeader}>
            <TypographyInline
              size={"sm"}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.STANDARD}
              colr={GREY.PRIMARY}
            />
          </Box>
          <Box className={classes.manageCardContain}>
            {loading ? (
              <div className={classes.loaderContainer}>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"40px"}
                  stackWidth={"100%"}
                />
              </div>
            ) : (
              <>
                {publicViews?.map((eachView) => {
                  const isPeople = leadType === STRINGS.PEOPLE;
                  const selector = isPeople ? peopleBasicDetailsSelector : companyBasicDetailsSelector;
                  const view_id = selector?.payload?.default_view?.view_id;
                  const isDefault = eachView?.view_id === view_id;
                  const active_view_id = selector?.payload?.active_view?.view_id;
                  return (
                    <ManageViewCard
                      label={eachView?.view_name}
                      RightIcon={isDefault ? ICON_LABELS.BOOKMARK : ICON_LABELS.PUSH_PIN}
                      onClick={() => handleCreateTempActiveView(eachView)}
                      isSelected={
                        active_view === STRINGS.ALL_CONTACTS ||
                        active_view === STRINGS.ALL_COMPANIES ||
                        active_view_id === eachView?.view_id ||
                        active_view === eachView?.view_id
                      }
                    />
                  );
                })}
              </>
            )}
          </Box>
        </Box>

        {/* Private View Container */}
        <Box className={classes.eachViewContainer}>
          <Box className={classes.eachViewHeader}>
            <TypographyInline
              size={"sm"}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.PRIVATE_VIEW}
              colr={GREY.PRIMARY}
            />
          </Box>
          <Box className={classes.manageCardContainer}>
            {loading ? (
              Array.from({ length: 5 }).map(() => (
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"55px"}
                  stackWidth={"100%"}
                />
              ))
            ) : privateViews?.length ? (
              <>
                {privateViews?.map((eachView, index) => {
                  const pinnedViewIds =
                    leadType === STRINGS.PEOPLE
                      ? peopleBasicDetailsSelector?.payload?.pinned_view
                        .filter((item) => item?.view_id)
                        .map((view) => {
                          return view.view_id;
                        })
                      : companyBasicDetailsSelector?.payload?.pinned_view
                        .filter((item) => item?.view_id)
                        .map((view) => {
                          return view.view_id;
                        });
                  const owners =
                    eachView?.owner_name === STRINGS.ME ||
                      userDetail === STRINGS.ADMIN_USER
                      ? actionList
                      : actionLists;

                  const defaultViewIds =
                    leadType === STRINGS.PEOPLE
                      ? peopleBasicDetailsSelector?.payload?.default_view
                        ?.view_id
                      : companyBasicDetailsSelector?.payload?.default_view
                        ?.view_id;

                  const isPeople = leadType === STRINGS.PEOPLE;
                  const selector = isPeople ? peopleBasicDetailsSelector : companyBasicDetailsSelector;
                  const active_view_id = selector?.payload?.active_view?.view_id;

                  return (
                    <>
                      <Box>
                        <ManageViewCard
                          label={eachView?.view_name}
                          onwerName={eachView?.owner_name || ""}
                          onClick={() => handleCreateTempActiveView(eachView)}
                          isSelected={active_view_id === eachView?.view_id || active_view === eachView?.view_id}
                          actionOnClick={(e) =>
                            handleMouseEnter(
                              eachView,
                              index,
                              e,
                              owners,
                              true,
                              eachView?.is_pin
                            )
                          }
                          isPinned={pinnedViewIds?.includes(eachView?.view_id)}
                          isDefault={defaultViewIds?.includes(
                            eachView?.view_id
                          )}
                        />
                      </Box>
                    </>
                  );
                })}
              </>
            ) : (
              <EmptyViewCard
                label={STRINGS.NO_PRIVATE_VIEW_CREATED}
                onClick={() => {
                  setRenameViewPopup(true);
                  setIsRename(false);
                  setViewType("private");
                }}
              />
            )}
            <CustomStyledMenu
              anchorEl={viewCardMenu}
              open={Boolean(viewCardMenu)}
              onClose={() => setViewCardMenu(null)}
              noMargin={true}
              isRightAlign={true}
              marginLeft={"-11px !important"}
              shadow={SHADOW.md}
              arrow
            >
              {leadType === STRINGS.PEOPLE ? (
                <Box className={classes.toolTipContainer}>
                  {viewOwners?.map((action, index) => {
                    return !(
                      viewDetail?.view_id ===
                      peopleBasicDetailsSelector?.payload?.default_view
                        ?.view_id && index === 0
                    ) ? (
                      <Box
                        key={action.type}
                        onClick={() =>
                          isPin === 1
                            ? index === 0
                              ? handleUnpinView(viewDetail, STRINGS.TEAM_ID)
                              : action.action(viewDetail, STRINGS.TEAM_ID)
                            : action.action(viewDetail, STRINGS.TEAM_ID)
                        }
                        className={
                          actionList?.length - 1 === index
                            ? classes.borderFlex
                            : classes.noBorderFlex
                        }
                      >
                        <IconComponent
                          color={GREY.TERTIARY}
                          iconLabel={action.icon}
                          fontSize={fontSize.LG}
                        />
                        <TypographyInline
                          label={
                            isPin === 1
                              ? index === 0
                                ? STRINGS.UNPIN_THIS_VIEW
                                : action.type
                              : action.type
                          }
                          size={"sm"}
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                        />
                      </Box>
                    ) : (
                      ""
                    );
                  })}
                </Box>
              ) : (
                <Box className={classes.toolTipContainer}>
                  {viewOwners?.map((action, index) => {
                    console.log(
                      action,
                      isPin,
                      "actionList?.length - 1 === index"
                    );
                    return !(
                      viewDetail?.view_id ===
                      companyBasicDetailsSelector?.payload?.default_view
                        ?.view_id && index === 0
                    ) ? (
                      <Box
                        key={action.type}
                        onClick={() =>
                          isPin === 1
                            ? index === 0
                              ? handleUnpinView(viewDetail, STRINGS.TEAM_ID)
                              : action.action(viewDetail, STRINGS.TEAM_ID)
                            : action.action(viewDetail, STRINGS.TEAM_ID)
                        }
                        className={
                          actionList?.length - 1 === index
                            ? classes.borderFlex
                            : classes.noBorderFlex
                        }
                      >
                        <IconComponent
                          color={GREY.TERTIARY}
                          iconLabel={action.icon}
                          fontSize={fontSize.LG}
                        />
                        <TypographyInline
                          label={
                            isPin === 1
                              ? index === 0
                                ? STRINGS.UNPIN_THIS_VIEW
                                : action.type
                              : action.type
                          }
                          size={"sm"}
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                        />
                      </Box>
                    ) : (
                      ""
                    );
                  })}
                </Box>
              )}
            </CustomStyledMenu>
          </Box>
        </Box>

        {/* Shared View Container */}
        <Box className={classes.eachViewContainer}>
          <Box className={classes.eachViewHeader}>
            <TypographyInline
              size={"sm"}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.SHARED_VIEW}
              colr={GREY.PRIMARY}
            />
          </Box>
          <Box className={classes.manageCardContainer}>
            {loading ? (
              Array.from({ length: 5 }).map((item, index) => (
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"55px"}
                  stackWidth={"100%"}
                />
              ))
            ) : (
              teamViews?.length ?
                <>
                  {teamViews?.map((eachView, index) => {
                    const owners =
                      eachView?.owner_name === STRINGS.ME ||
                        userDetail === STRINGS.ADMIN_USER
                        ? actionList
                        : actionLists;

                    const pinnedViewIds =
                      leadType === STRINGS.PEOPLE
                        ? peopleBasicDetailsSelector?.payload?.pinned_view
                          .filter((item) => item?.view_id)
                          .map((view) => {
                            return view.view_id;
                          })
                        : companyBasicDetailsSelector?.payload?.pinned_view
                          .filter((item) => item?.view_id)
                          .map((view) => {
                            return view.view_id;
                          });
                    const defaultViewIds =
                      leadType === STRINGS.PEOPLE
                        ? peopleBasicDetailsSelector?.payload?.default_view
                          ?.view_id
                        : companyBasicDetailsSelector?.payload?.default_view
                          ?.view_id;

                    const isPeople = leadType === STRINGS.PEOPLE;
                    const selector = isPeople ? peopleBasicDetailsSelector : companyBasicDetailsSelector;
                    const active_view_id = selector?.payload?.active_view?.view_id;

                    return (
                      <Box key={eachView?.view_id}>
                        <ManageViewCard
                          label={eachView?.view_name}
                          onwerName={eachView?.owner_name || ""}
                          onClick={() => handleCreateTempActiveView(eachView)}
                          isSelected={active_view_id === eachView?.view_id || active_view === eachView?.view_id}
                          actionOnClick={(e) => {
                            handleMouseEnter(
                              eachView,
                              index,
                              e,
                              owners,
                              false,
                              eachView?.is_pin
                            );
                          }}
                          isPinned={pinnedViewIds?.includes(eachView?.view_id)}
                          isDefault={defaultViewIds?.includes(eachView?.view_id)}
                        />
                      </Box>
                    );
                  })}
                </> :
                (
                  <EmptyViewCard
                    label={STRINGS.NO_TEAM_VIEW_CREATED}
                    onClick={() => {
                      setRenameViewPopup(true);
                      setIsRename(false);
                      setViewType("team");
                    }}
                  />
                )
            )}
            <CustomStyledMenu
              anchorEl={privateViewCardMenu}
              open={Boolean(privateViewCardMenu)}
              onClose={() => setPrivateViewCardMenu(null)}
              noMargin={true}
              marginLeft={"-11px !important"}
              isRightAlign={true}
              arrow
              shadow={SHADOW.md}
            >
              {leadType === STRINGS.PEOPLE ? (
                <Box className={classes.toolTipContainer}>
                  {viewOwners?.map((action, index) => {
                    return (
                      !(
                        viewDetail?.view_id ===
                        peopleBasicDetailsSelector?.payload?.default_view
                          ?.view_id && index === 0
                      ) && (
                        <Box
                          key={action.type}
                          onClick={() =>
                            isPin === 1
                              ? index === 0
                                ? handleUnpinView(
                                  viewDetail,
                                  STRINGS.PRIVATE_ID
                                )
                                : action.action(viewDetail, STRINGS.PRIVATE_ID)
                              : action.action(viewDetail, STRINGS.PRIVATE_ID)
                          }
                          className={
                            actionList?.length - 1 === index
                              ? classes.borderFlex
                              : classes.noBorderFlex
                          }
                        >
                          <IconComponent
                            color={GREY.TERTIARY}
                            iconLabel={action.icon}
                            fontSize={fontSize.LG}
                          />
                          <TypographyInline
                            label={
                              isPin === 1
                                ? index === 0
                                  ? STRINGS.UNPIN_THIS_VIEW
                                  : action.type
                                : action.type
                            }
                            size={"sm"}
                            color={GREY.SECONDARY}
                            fontWeight={fontWeight.REGULAR}
                          />
                        </Box>
                      )
                    );
                  })}
                </Box>
              ) : (
                <Box className={classes.toolTipContainer}>
                  {viewOwners?.map((action, index) => {
                    return (
                      !(
                        viewDetail?.view_id ===
                        companyBasicDetailsSelector?.payload?.default_view
                          ?.view_id && index === 0
                      ) && (
                        <Box
                          key={action.type}
                          onClick={() =>
                            isPin === 1
                              ? index === 0
                                ? handleUnpinView(
                                  viewDetail,
                                  STRINGS.PRIVATE_ID
                                )
                                : action.action(viewDetail, STRINGS.PRIVATE_ID)
                              : action.action(viewDetail, STRINGS.PRIVATE_ID)
                          }
                          className={
                            actionList?.length - 1 === index
                              ? classes.borderFlex
                              : classes.noBorderFlex
                          }
                        >
                          <IconComponent
                            color={GREY.TERTIARY}
                            iconLabel={action.icon}
                            fontSize={fontSize.LG}
                          />
                          <TypographyInline
                            label={
                              isPin === 1
                                ? index === 0
                                  ? STRINGS.UNPIN_THIS_VIEW
                                  : action.type
                                : action.type
                            }
                            size={"sm"}
                            color={GREY.SECONDARY}
                            fontWeight={fontWeight.REGULAR}
                          />
                        </Box>
                      )
                    );
                  })}
                </Box>
              )}
            </CustomStyledMenu>
          </Box>
        </Box>
      </Box>
    </div>
  );

  const allViewModalHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label:
        leadType === STRINGS.PEOPLE
          ? `${STRINGS.ALL_CONTACT_SAVED_VIEWS} (${publicViews?.length + privateViews?.length + teamViews?.length
          })`
          : `${STRINGS.COMPANY_SAVED_VIEWS} (${publicViews?.length + privateViews?.length + teamViews?.length
          })`,
    },
  };

  return (
    <Box>
      <CustomModal
        variant={"error"}
        close={() => setOpenConfirmation(false)}
        open={openConfirmation}
        header={viewDeleteModalHeader}
        children={viewDeleteModalBody}
        footer={{
          left: viewDeleteModalLeftFooter,
          right: viewDeleteModalRightFooter,
        }}
      />

      {renameViewPopup && (
        <AddViewModal
          setPublicViews={setPublicViews}
          setPrivateViews={setPrivateViews}
          setTeamViews={setTeamViews}
          getViews={getViews}
          isRename={isRename}
          leadType={leadType}
          open={renameViewPopup}
          setOpen={setRenameViewPopup}
          renamePopupValue={renamePopupValue}
          setRenamePopupValue={setRenamePopupValue}
          setViewCardMenu={setViewCardMenu}
          setPrivateViewCardMenu={setPrivateViewCardMenu}
          viewCardMenu={viewCardMenu}
          privateViewCardMenu={privateViewCardMenu}
          viewType={viewType}
          setAllViewModalState={setOpen}
        />
      )}

      <CustomModal
        width={"60.469vw"}
        close={() => setOpen(false)}
        open={open}
        header={allViewModalHeader}
        children={allViewModalBody}
        remove_padding
      />
    </Box>
  );
};

export default AllViewModal;
