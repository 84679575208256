import React from "react";
import { BORDER } from "../../utils/constants/colors";
import { fontSize, lineHeight } from "../../utils/constants/UI";

const SIZE_MAPPING = {
  XXL: {
    width: "80px",
    height: "80px",
    fontSize: fontSize.DSM,
    lineHeight: lineHeight.DSM,
  },
  extraLarge: {
    width: "64px",
    height: "64px",
    fontSize: fontSize.DXS,
    lineHeight: lineHeight.DXS,
  },
  huge: {
    width: "56px",
    height: "56px",
    fontSize: fontSize.XL,
    lineHeight: lineHeight.XL,
  },
  large: {
    width: "48px",
    height: "48px",
    fontSize: fontSize.XL,
    lineHeight: lineHeight.XL,
  },
  medium: {
    width: "40px",
    height: "40px",
    fontSize: fontSize.MD,
    lineHeight: lineHeight.MD,
  },
  small: {
    width: "32px",
    height: "32px",
    fontSize: fontSize.SM,
    lineHeight: lineHeight.SM,
  },
  tiny: {
    width: "24px",
    height: "24px",
    fontSize: fontSize.XS,
    lineHeight: lineHeight.XS,
  },
};

const AvatarOutlined = ({ children, size, ...props }) => {
  const classes = {
    mainContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      cursor: "pointer",
      minWidth: SIZE_MAPPING[size]?.width || "32px",
      minHeight: SIZE_MAPPING[size]?.height || "32px",
      border: `1px solid ${BORDER.LIGHT}`,
      width: SIZE_MAPPING[size]?.width || "32px",
      height: SIZE_MAPPING[size]?.height || "32px",
    },
  };
  return (
    <div style={classes.mainContainer} {...props}>
      {children}
    </div>
  );
};

export default AvatarOutlined;
