import React from "react";
import { useSelector } from "react-redux";
import {
  DASHBOARD_EMPTY_IMAGE,
  EMP_WIDGT_SCREEN,
} from "../../../utils/constants/assets";
import { Styles } from "../Styles";
import { DashboardHeader } from "./DashboardHeader";
import CustomHeader from "../../../components/Header/CustomHeader";
import { STRINGS } from "../strings";
import Image from "../../../components/Image/Image";
import Typography from "../../../components/Typography/Typography";
import { GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import { Button } from "../../../components/Buttons";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const EmptyDashboard = (props) => {
  const classes = Styles();
  const currentDashboardSelector = useSelector(
    (state) => state.getCurrentDashboard
  );
  const userDetailsSelector = useSelector((state) => state?.userDetails);

  const headerData = [
    { id: "empty_screen_dashboard", label: STRINGS.DASHBOARD },
  ];

  return (
    <>
      {props?.isEmpty && <CustomHeader items={headerData} />}
      <div
        className={
          props?.isEmpty
            ? classes.emptyDashboardContainer
            : classes.emptyWidgetCotnainer
        }
      >
        <div className={classes.emptywrap}>
          <div className={classes.imageContentContainer}>
            <Image
              className={classes.dashBoardMainImage}
              src={EMP_WIDGT_SCREEN}
              alt=""
            />
            <div className={classes.contentContainer}>
              <TypographyInline
                label={
                  props.createDashboardFlag
                    ? STRINGS.CREATE_DASHBOARD
                    : STRINGS.ADD_WIDGETS
                }
                color={GREY.PRIMARY}
                fontWeight={fontWeight.BOLD}
                size={"xl"}
              />
              {props.createDashboardFlag ? (
                <div className={classes.emptyDescriptionContainer}>
                  <div className={classes.paddingTopper4}>
                    <TypographyInline
                      label={STRINGS.EMPTY_DASHBOARD_DESCRIPTION}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.REGULAR}
                      size={"sm"}
                    />
                  </div>
                  <div>
                    <TypographyInline
                      label={STRINGS.EMPTY_DASHBOARD_SUB_DESCRIPTION}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.REGULAR}
                      size={"sm"}
                    />
                  </div>
                </div>
              ) : (
                <div className={classes.emptyDescriptionContainer}>
                  <div className={classes.paddingTopper4}>
                    <TypographyInline
                      label={STRINGS.EMPTY_WIDGET_DESCRIPTION}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.REGULAR}
                      size={"sm"}
                    />
                  </div>
                  <div>
                    <TypographyInline
                      label={STRINGS.EMPTY_WDIGET_SUBDESCRIPTION}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.REGULAR}
                      size={"sm"}
                    />
                  </div>
                </div>
              )}
              {props.createDashboardFlag ? (
                <div
                  className={`${classes.buttonContainer} ${classes.paddingTopper16}`}
                >
                  <Button
                    onClick={props?.handleCreatePopUp}
                    size={"sm36"}
                    variant={"primary"}
                    LeftIcon={ICON_LABELS.ADD}
                    label={STRINGS.CREATE_DASHBOARD}
                  />
                </div>
              ) : (
                <div
                  className={`${classes.buttonContainer1} ${classes.paddingTopper16}`}
                >
                  <Button
                    variant={"primary"}
                    size={"sm36"}
                    onClick={props.openwidget}
                    LeftIcon={ICON_LABELS.ADD}
                    disabled={
                      userDetailsSelector.payload?.email !==
                      currentDashboardSelector.payload?.owner_email
                    }
                    label={STRINGS.ADD_WIDGETS_TO_DASHBOARD}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
