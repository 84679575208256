import React, { Fragment } from 'react';
import SkeletonLoader from '../../../../components/Loaders/SkeletonLoader';
import { Box } from '@mui/material';
import classes from "../markComponents.module.css";
const items = [
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
]

const StatsSkeleton = () => {


    return <Fragment>
        {items.map((item, index) => {
            return <Box key={index} className={classes.flexStats} >
                <Box className={classes.containSkeletonStat} >
                    <SkeletonLoader
                        type={item.type1 || "rounded"}
                        skeletonWidth={20}
                        skeletonHeight={20}
                    />
                </Box>
                <Box className={classes.containSkeletonStat} >
                    <SkeletonLoader type={item.type2 || "text"} skeletonWidth={40} />
                </Box>

            </Box>
        })}
    </Fragment>

}

export default StatsSkeleton;