import React, { useState } from "react";
import TypographyInline from "../../Typography/TypographyInline";
import { STRINGS } from "../strings";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import CustomDatePicker from "../../DatePicker/CustomDatePicker";
import classes from "../FilterTypes.module.css";
import { Button } from "../../Buttons";
import { filterMultipleList } from "../../../redux/actions/filterActions";
import { useDispatch, useSelector } from "react-redux";

const FilterDateRange = ({ filterName = "" }) => {
  const dispatch = useDispatch();

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  const [fromDate, setFromDate] = useState(
    filterMultipleListSelector?.[filterName]?.from
  );
  const [toDate, setToDate] = useState(
    filterMultipleListSelector?.[filterName]?.to
  );
  const [isLoading, setIsLoading] = useState(false);
  const [minDate, setMinDate] = useState(null);

  const dispatchDateHandler = (e) => {
    e.preventDefault();
    let data = {
      from: fromDate,
      to: toDate,
    };
    setIsLoading(true);
    dispatch(
      filterMultipleList({
        ...filterMultipleListSelector,
        [filterName]: { ...data },
      })
    );
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (Object?.keys(filterMultipleListSelector?.[filterName] || {})?.length) {
      setFromDate([filterMultipleListSelector?.[filterName]?.from]);
    }
  }, []);

  return (
    <div className={classes.dateFieldContainer}>
      <div className={classes.dateFieldLabelMainContainer}>
        <div className={classes.dateFieldLabelContainer}>
          <div>
            <TypographyInline
              label={STRINGS.FROM}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.PRIMARY}
            />
          </div>
          <div>
            <CustomDatePicker
              size={"sm"}
              value={fromDate}
              onChange={(dateValue) => {
                setFromDate(
                  `${dateValue?.$y}-${dateValue?.$M + 1}-${dateValue?.$D}`
                );
                setMinDate(dateValue);
              }}
            />
          </div>
        </div>

        <div className={classes.dateFieldLabelContainer}>
          <div>
            <TypographyInline
              label={STRINGS.TO}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.PRIMARY}
            />
          </div>
          <div>
            <CustomDatePicker
              size={"sm"}
              value={toDate}
              onChange={(dateValue) => {
                setToDate(
                  `${dateValue?.$y}-${dateValue?.$M + 1}-${dateValue?.$D}`
                );
              }}
              minDate={minDate}
            />
          </div>
        </div>
      </div>

      <div className={classes.filterApplyButton}>
        <Button
          label={STRINGS.APPLY}
          size={"xs28"}
          variant={"primary"}
          isLoading={isLoading}
          onClick={dispatchDateHandler}
        />
      </div>
    </div>
  );
};

export default FilterDateRange;
