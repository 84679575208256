import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "../styles";
import InputField from "../../InputFields/InputField";
import { Button } from "../../Buttons";
import { STRINGS } from "../strings";
import { filterMultipleList } from "../../../redux/actions/filterActions";

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;

const FilterSlider = ({
  min = 0,
  max = 1000,
  defaultMin = 0,
  defaultMax = 200,
  filterName = "",
}) => {
  const dispatch = useDispatch();

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  const [tempMinRevenue, setTempMinRevenue] = useState(null);
  const [value, setValue] = useState([defaultMin, defaultMax]);
  // const [sliderMax, setSliderMax] = useState(max)

  if (tempMinRevenue !== defaultMin) {
    setTempMinRevenue(defaultMin);
    setValue([defaultMin, defaultMax]);
  }

  const handleSliderChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  const handleChange = (e, index) => {
    let res = [];
    // if (e?.target?.value <= 1000) {
    index === 0
      ? (res = [e?.target?.value, value[1]])
      : (res = [value[0], e?.target?.value]);
    // index && setSliderMax(e?.target?.value)
    setValue(res);
    // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      min: parseInt(value[0]),
      max: parseInt(value[1]),
    };
    dispatch(
      filterMultipleList({
        ...filterMultipleListSelector,
        [filterName]: { ...data },
      })
    );
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setValue([defaultMin, defaultMax]);
    let temp_data = { ...filterMultipleListSelector }
    delete temp_data?.[filterName]
    dispatch(
      filterMultipleList(
        {
          ...temp_data,
        },
        false
      )
    );
  };

  React.useEffect(() => {
    if (Object?.keys(filterMultipleListSelector?.[filterName] || {})?.length) {
      setValue([
        filterMultipleListSelector?.[filterName]?.min,
        filterMultipleListSelector?.[filterName]?.max,
      ]);
    }
    else {
      setValue([
        defaultMin,
        defaultMax,
      ]);
    }
  }, [filterMultipleListSelector]);

  return (
    <Box sx={styles.filterAmountContainer}>
      <Box>
        <Box
          sx={{
            ...styles.jobTitleHead,
            columnGap: "15px",
            marginBottom: "10px",
          }}
        >
          <label style={styles.sliderLabel}>
            {value[0]}
          </label>

          <Slider
            sx={styles.slider}
            value={value}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            disableSwap
            min={min}
            max={max}
          />
          <label style={styles.sliderLabel}>
            {value[1]}
          </label>
        </Box>

        <Box sx={styles.containRevenueInputs}>
          <InputField
            size={"xs34"}
            value={value[0]}
            placeholder="0"
            onChange={(e) => handleChange(e, 0)}
            onKeyDown={(e) => e.stopPropagation()}

          />
          <InputField
            size={"xs34"}
            value={value[1]}
            placeholder="1000"
            onChange={(e) => handleChange(e, 1, true)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Box>
        <Box sx={styles.containBtn} onSubmit={handleSubmit} component="form">
          {defaultMax ? (
            <div>
              <Button
                label={STRINGS.RESET}
                size={"sm36"}
                variant={"ghost"}
                onClick={handleClearFilter}
              />
            </div>
          ) : null}

          <div>
            <Button
              label={STRINGS.APPLY}
              size={"sm36"}
              variant={"primary"}
              onClick={handleSubmit}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterSlider;
