import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getCompanyBasicDetails } from "../../../redux/actions/companyActions";
import { allFilters } from "../../../redux/actions/filterActions";
import { applyUpdateColumn, companyDataFetch, companyTableEndpointChange, updateColumnHeaders } from "../../../redux/actions/tableActions";
import { HandleClearAllFilterFromStore } from "../../../redux/store/storeHelper";
import { tempHeadCompanyCells } from "../../../utils/constants";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import FilterTab from "../../../components/FilterTab/FilterTab";
import { paginationDataAction } from "../../../redux/actions/customTableActions";
import { STRINGS } from "../strings";
import classes from "./LeadsComponent.module.css";
import IconComponent from "../../../components/Icon/IconComponent";


const ContactDropdownModalCompany = ({clearSearch}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const querySelector = useSelector(
    (state) => state?.companyBasicDetails?.payload
  );
  const companyTableEndpointChangeSelector = useSelector(
    (state) => state?.companyTableEndpointChange
  );

  const [text, setText] = useState(
    companyTableEndpointChangeSelector?.payload || STRINGS.NEW_COMPANIES
  );
  const [dropDownText, setDropDownText] = useState(
    companyTableEndpointChangeSelector?.payload === STRINGS.SAVED_COMPANIES
      ? STRINGS.NEW_COMPANIES
      : STRINGS.SAVED_COMPANIES
  );

  const handleChange = async () => {
    clearSearch("")
    const newText = text;
    setText(dropDownText);
    setDropDownText(newText);
    await dispatch(getCompanyBasicDetails(navigate));
    await HandleClearAllFilterFromStore(dispatch, location, navigate);
    dispatch(applyUpdateColumn([...tempHeadCompanyCells]));
    dispatch(updateColumnHeaders([...tempHeadCompanyCells]));

    dispatch(
      paginationDataAction({
        checkedRow: [],
        query: {},
      })
    );
    setTimeout(() => {
      dispatch(companyTableEndpointChange(dropDownText));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...querySelector?.default_view?.query_string,
        }).toString(),
      });
      dispatch(allFilters(querySelector?.default_view?.query_string || {}));
      dispatch(
        companyDataFetch(
          querySelector?.default_view?.query_string || {},
          navigate
        )
      );
    }, 50);
    setOpen(false);
  };

  return (
    <div className={classes.filterTab1}>
      <FilterTab
        label={text}
        leftIcon={ICON_LABELS.CONTACT_PAGE}
        rightIcon={ICON_LABELS.ACTION_ARROW}
        onClick={(e) => setOpen(e?.currentTarget)}
        size="large"
      />
      <CustomStyledMenu
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        width={dropDownText === STRINGS.NEW_COMPANIES ? "183px" : "171px"}
        noMargin={true}
        marginTop={"1px !important"}
      >
        <Box className={classes.contactListMenuItem} onClick={handleChange}>
          <IconComponent
            iconLabel={ICON_LABELS.CONTACT_PAGE}
            color={GREY.TERTIARY}
            fontSize={fontSize.MD}
          />
          <TypographyInline
            size={"sm"}
            label={dropDownText}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
          />
        </Box>
      </CustomStyledMenu>
    </div>
  );
};

export default ContactDropdownModalCompany;
