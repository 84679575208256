import { GREY } from "../colors";

export const SHADOW = {
  xs: "0px 1px 2px rgba(24, 24, 28, 0.06), 0px 4px 4px -4px rgba(24, 24, 28, 0.02)",
  sm: "0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04)",
  md: "0px 4px 8px -2px rgba(24, 24, 28, 0.1), 0px 2px 4px -2px rgba(24, 24, 28, 0.06)",
  lg: "0px 32px 41px -23px rgba(24, 24, 28, 0.07), 0px 2px 6px rgba(24, 24, 28, 0.06)",
  xl: "0px 24px 48px -8px rgba(24, 24, 28, 0.11), 0px 48px 64px -36px rgba(24, 24, 28, 0.13)",
  tags: "0px 12px 8px 0px rgba(24, 24, 28, 0.02), 0px 0px 16px 0px rgba(24, 24, 28, 0.12)",
  input: "0px 0px 0px 3px #EBECF0",
  tableCells: "3px 0 3px rgba(0,0,0,.07)",
};

// typography constants
export const fontSize = {
  MIN: "8px",
  MICRO: "10px",
  XS: "12px",
  SM: "14px",
  MD: "16px",
  LG: "18px",
  XL: "20px",
  DXS: "24px",
  DSM: "30px",
  DLG: "48px",
  DXL: "60px",
};

export const lineHeight = {
  MICRO: "12px",
  XS: "18px",
  SM: "20px",
  MD: "24px",
  LG: "27px",
  XL: "30px",
  DXS: "32px",
  DSM: "38px",
  DLG: "60px",
  DXL: "72px",
};

export const fontWeight = {
  REGULAR: 400,
  MEDIUM: 500,
  SEMIBOLD: 600,
  BOLD: 700,
};

export const borderRadius = {
  primary: "6px",
  secondary: "12px",
  tertiary: "3px",
  paginationButton: "4px",
  none: "0px",
  chip: "27px",
};

export const SCROLL_BAR = {
  overflow: "auto",
  // cursor: "pointer",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-track ": {
    innerHeight: "5px",
    outerHeight: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: GREY.DISABLED,
    borderRadius: "3px",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: GREY.QUATINARY,
  },
};
