import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { HtmlEditor } from "../../../../components";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  STEPS_INTERVAL_FIELDS,
} from "../../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../../../../Apis";
import {
  sequenceEndPoints,
  templateEndpoints,
} from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { useDispatch, useSelector } from "react-redux";
import { getAllSteps } from "../../../../redux/actions/stepsActions";
import { patchRequest } from "../../../../Apis/apiWrapper";
import PreviewMail from "./PreviewMail";
import parse from "html-react-parser";
import {
  addTagStyleInHtml,
  compressString,
  decompressString,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import CreateTempModal from "./CreateTempModal";
import { getSignatureAction } from "../../../../redux/actions/authActions";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../strings";
import CustomModal from "../../../../components/Modals/CustomModal";
import { fontWeight } from "../../../../utils/constants/UI";
import { GREY, HELP } from "../../../../utils/constants/colors";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import InputField from "../../../../components/InputFields/InputField";
import Checkbox from "../../../../components/Buttons/Checkbox/Checkbox";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { EMAIL_MARKETING_STEPS_PATH_PREFIX, SEQUENCE_PATH } from "../../../../utils/constants/routes";
import {
  MESSAGE_REQUIRED_FIELD,
  MESSAGE_SUBJECT_REQUIRED,
} from "../../../../utils/constants/messages";
import classes from "../markComponents.module.css";
const CreateAutomatedEmail = ({
  data,
  editType,
  stepNumber,
  stepLength,
  reSubject,
  myId,
  number,
  open,
  setOpen,
}) => {
  const [errMsg, setErrMsg] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listSelector = useSelector((state) => state.getTempList);
  const sequenceId = params.id;
  const fold = params.fold;
  const [checked, setChecked] = useState(false);
  const [isCall, setIsCall] = useState(false);
  const [performing, setPerforming] = useState(false);
  const [subject, setSubject] = useState(data?.step_data?.subject || "");
  const [caretPosition, setCaretPosition] = useState(0);
  const type = "1";
  const [showPreview, setShowPreview] = useState(false);
  const [currentFocus, setCurrentFocus] = useState("");
  const [tempsaName, setTempsaName] = useState("");
  const [previos, setPrevios] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [subjectData, setSubjectData] = useState("");
  const [templateId, setTemplateId] = useState([]);
  const [templateValue, setTemplateValue] = useState([STRINGS.SELECT_TEMPLATE]);
  const [ownr_Name, setOwnr_Name] = useState("");
  const [templateList, setTemplateList] = useState([]);

  const stepsSelector = useSelector((state) => state?.getAllSteps);
  const { payload } = stepsSelector;
  const [intervals, setIntervals] = useState([
    payload?.data[0]?.total_steps === 1 && editType === STRINGS.SMALL_CLONE
      ? "1"
      : parseInt(data?.interval) > 0 ? data?.interval : "1",
  ]);
  const isTemplateSave = false;

  useEffect(() => {
    dispatch(getSignatureAction(navigate));
    if (type === "2") {
      setSubject(STRINGS.RE2 + reSubject);
    }
    if (type === "1" && subject?.includes(STRINGS.RE2)) {
      setSubject("");
    }
    if (isTemplateSave) {
      setTempsaName(listSelector?.data?.data?.records?.[0]?.name);
    }

    // fetch template list and set.
    (async () => {
      try {
        const payload = {
          name: "",
          type: STRINGS.SMALL_FILE,
          is_sequence: true,
          limit: 100,
        };
        const template_list = await postRequest(
          navigate,
          templateEndpoints.templateList,
          payload
        );
        let addSelect = { _id: "123456", name: STRINGS.SELECT_TEMPLATE, subject: "", body: " ", owner_name: "" }
        let list = [addSelect, ...(template_list?.data?.data?.records || [])]
        // let list = template_list?.data?.data?.records?.unshift(addSelect)
        setTemplateList(
          list?.map((item) => {
            return { ...item, id: item?._id, label: item.name };
          })
        );

        // set TemplateValue and templateId here.
        if (data?.step_data) {
          const template = template_list?.data?.data?.records?.filter(
            (item) => item?._id === data?.step_data?.template
          );
          if (template?.length) {
            setTemplateValue([template?.[0]?.name || ""]);
            setTemplateId([template?.[0]?._id || ""]);
          }
        }
      } catch (error) {
        console.log("Error-------->>>>>>>>>> ", error);
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    })();
  }, [type, listSelector]);

  // Use this useEffect for setting templateName and data.
  useEffect(() => {
    if (!isCall) {
      if (templateId?.length) {
        const id = templateId?.[0];
        const templateData = templateList.filter((item) => item?._id === id)?.[0];
        setSubject(templateData?.subject);
        setSubjectData(templateData?.subject)
        if (templateData?.is_compressed) {
          setPrevios(decodeURIComponent(decompressString(templateData?.body)));
        }
        else {
          setPrevios(decodeURI(templateData?.body));
        }
        // setPrevios(decodeURI(templateData?.body));
        setOwnr_Name(templateData?.owner_name);
        if (id === "123456") {
          document.getElementById("editor").innerHTML = ""
        }
      }
    }
    else {
      setIsCall(false)
    }
  }, [templateId]);

  function checkBoxHandle() {
    if (subject.length === 0) {
      EventEmitter.emit(EMITTER_ERROR, MESSAGE_SUBJECT_REQUIRED);
    } else {
      setChecked(!checked);
    }
  }

  const currentTemplate = async (value) => {
    const payload = {
      name: "",
      type: STRINGS.SMALL_FILE,
      is_sequence: true,
      limit: 100,
    };
    const template_list = await postRequest(
      navigate,
      templateEndpoints.templateList,
      payload
    );
    let addSelect = { _id: "123456", name: STRINGS.SELECT_TEMPLATE, subject: "", body: " ", owner_name: "" }
    let list = [addSelect, ...template_list?.data?.data?.records]
    // let list = template_list?.data?.data?.records?.unshift(addSelect)
    setTemplateList(
      list?.map((item) => {
        return { ...item, id: item?._id, label: item.name };
      })
    );
    setTemplateValue([list?.[1]?.name || ""]);
    setTemplateId([list?.[1]?._id || ""]);
  }

  const handleCreateAutomatedEmail = async () => {
    let customEditorValue = removeTagStyleFromHtml(`<div>${document
      .getElementById(STRINGS.SMALL_EDITOR)
      ?.innerHTML?.toString()}</div>`);
    customEditorValue = compressString(encodeURIComponent(customEditorValue))
    if (subject?.trim()?.length === 0) {
      setErrMsg(MESSAGE_REQUIRED_FIELD);
      return;
    }
    setPerforming(true);

    let newCustomEditorValue = customEditorValue;
    if (editType === STRINGS.SMALL_EDIT) {
      try {
        const payload = {
          step_id: data?._id,
          thread: "1",
          subject,
          body: newCustomEditorValue,
          signature: "1",
          interval: intervals?.[0],
          owner: ownr_Name,
        };
        if (templateId?.[0] && !isEditable) {
          payload.template = templateId?.[0];
        }
        else {
          payload.template = "123456";
        }
        setPerforming(true);
        const response = await patchRequest(
          navigate,
          sequenceEndPoints.updateAutomatedEmailStep,
          payload
        );
        if (sequenceId) {

          dispatch(
            getAllSteps(
              sequenceEndPoints.validateSequenceSteps + sequenceId,
              navigate
            )
          );
        }
        setPerforming(false);
        setOpen(false);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
      } catch (error) {
        console.log("Error-------->>>>>>>>>> ", error);
        setPerforming(false);
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    } else {
      if (editType === STRINGS.SMALL_CLONE) {
        if (subject?.trim()?.length === 0) {
          setErrMsg(MESSAGE_REQUIRED_FIELD);
          return;
        }
        try {
          const payload = {
            sequence_id: data?.sequence_id,
            interval: intervals?.[0]?.toString(),
            step_number: stepLength + 1,
            thread: "1",
            subject,
            body: newCustomEditorValue,
            signature: "1",
            owner: ownr_Name,
            is_clone: true
          };
          if (templateId?.[0] && !isEditable) {
            payload.template = templateId?.[0] || "";
          }
          setPerforming(true);
          const response = await postRequest(
            navigate,
            sequenceEndPoints.createAutomatedEmailStep,
            payload
          );
          if (sequenceId) {
            dispatch(
              getAllSteps(
                sequenceEndPoints.validateSequenceSteps + sequenceId,
                navigate
              )
            );
          }
          setPerforming(false);
          setOpen(false);
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
        } catch (error) {
          console.log("Error-------->>>>>>>>>> ", error);
          setPerforming(false);
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        }
      } else {
        if (subject?.trim()?.length === 0) {
          setErrMsg(MESSAGE_REQUIRED_FIELD);
          return;
        }
        try {
          const payload = {
            sequence_id: myId ? myId : params?.id,
            interval: stepNumber > 1 ? intervals?.[0] : "0",
            step_number: stepNumber || 1,
            thread: "1",
            subject,
            body: newCustomEditorValue,
            signature: "1",
            owner: ownr_Name,
          };
          if (templateId?.[0] && !isEditable) {
            payload.template = templateId?.[0];
          }
          setPerforming(true);
          const response = await postRequest(
            navigate,
            sequenceEndPoints.createAutomatedEmailStep,
            payload
          );
          if (sequenceId) {
            dispatch(
              getAllSteps(
                sequenceEndPoints.validateSequenceSteps + sequenceId,
                navigate
              )
            );
          }
          if (fold) {
            navigate(`${SEQUENCE_PATH}/${fold}/${STRINGS.STEPS}/${myId}`)
          }
          else if (myId) {
            navigate(EMAIL_MARKETING_STEPS_PATH_PREFIX + "/" + myId);
          }
          setPerforming(false);
          setOpen(false);
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
        } catch (error) {
          console.log("Error-------->>>>>>>>>> ", error);
          setPerforming(false);
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        }
      }
    }
  };

  const getData = (val) => {
    setIsCall(true)
    setTemplateValue([STRINGS.SELECT_TEMPLATE])
    setTemplateId(["123456"])
    setSubject(val)
    document.getElementById("editor").focus();
  };

  // edit send data in modal
  const getEditedData = (data) => {
    // (data?.step_data?.body ? decodeURI(data?.step_data?.body) : "")
    if (data?.is_compressed) {
      return decodeURIComponent(decompressString(data?.body))
    } else {
      return data?.body;
    }
  }

  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: STRINGS.AUTOMATED_EMAIL,
    },
    steps: {
      id: STRINGS.SMALL_STEP,
      label: number
        ? `${STRINGS.STEP} ${number}`
        : `${STRINGS.STEP} ${stepNumber}`,
      bgcolor: HELP.W_500,
    },
  };
  const modalBodyData = (
    <div>
      <Box>
        {(editType === STRINGS.SMALL_EDIT &&
          (stepNumber >= 2 || parseInt(data?.step_number) >= 2)) ||
          editType === STRINGS.SMALL_CLONE ||
          stepNumber >= 2 ? (
          <>
            <Box className={classes.mTop}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.INTERVALS}
              />
              <Box className={classes.mTop}>
                <AutoCompleteCustom
                  items={STEPS_INTERVAL_FIELDS}
                  LeftIcon=""
                  valueState={intervals}
                  setValueState={setIntervals}
                  size={"sm36"}
                />
              </Box>
            </Box>
          </>
        ) : null}
        <Box className={`${classes.mTop} ${classes.linePadding}`}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.TEMPLATE}
          />
          <Box className={classes.mTop}>
            <AutoCompleteCustom
              items={templateList}
              LeftIcon=""
              valueState={templateValue}
              setValueState={setTemplateValue}
              idState={templateId}
              setIdState={setTemplateId}
              papermenuclass={classes.paperMenu2}
              size={"sm36"}
            />
          </Box>
        </Box>
        <Box className={classes.mTop}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.SUBJECT1}
          />
          <Box className={classes.mTop} >
            <InputField
              value={parse(subject || "")}
              placeholder={STRINGS.ENTER_SUBJECT}
              size={"sm36"}
              variant={errMsg ? "error" : "default"}
              errormessage={errMsg}
              onChange={(e) => {
                setSubject(e?.target?.value);
                setCaretPosition(e?.target?.selectionStart);
                setIsEditable(true);
              }}
              onKeyUp={(e) => setCaretPosition(e?.target?.selectionStart)}
              onKeyDown={(e) => {
                setCaretPosition(e?.target?.selectionStart);;
              }}
              onMouseUp={(e) => setCaretPosition(e?.target?.selectionStart)}
              onClick={(e) => setCaretPosition(e?.target?.selectionStart)}
              data-subject={"subj"}
              onFocus={() => setCurrentFocus(STRINGS.SUBJECT_SMALL)}
            />
          </Box>
        </Box>

        <Box className={classes.mTop}>
          <Box className={classes.flexJustifyBetween}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.BODY}
            />
            {subject &&
              <Box className={classes.flexAlignItemsCenter}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.PREVIEW}
                  onClick={() => {
                    setShowPreview(true);
                  }}
                />
              </Box>
            }
          </Box>
        </Box>
        <Box className={classes.containEditor}>
          <HtmlEditor
            setCurrentFocus={setCurrentFocus}
            currentFocus={currentFocus}
            subject={subject}
            setSubject={setSubject}
            previosData={previos?.length ? addTagStyleInHtml(previos || "") : Object.keys(data?.step_data || {})?.length ? addTagStyleInHtml(getEditedData(data?.step_data)) : ""}
            editorHeight="280px"
            getData={getData}
            aiTypeValue="Step"
            onInput={() => setIsEditable(true)}
            caretPosition={caretPosition}
          />
        </Box>
      </Box>
    </div>
  );
  const modalFooterLeftData = (
    <div>
      {subject &&
        <Box className={classes.flexAlignItemsCenter}>
          {isTemplateSave ? (
            " "
          ) : (
            <Checkbox
              onClick={checkBoxHandle}
              checked={checked}
              disabled={!subject?.length}
              label={
                isTemplateSave
                  ? `${STRINGS.TEMPLATE_NAME} ${tempsaName}`
                  : STRINGS.SAVE_NEW_TEMPLATE
              }
            />
          )}

        </Box>
      }
      <Box className={classes.flexJustifyBetween}>
        <Box className={classes.btnFooter}>
          <Button
            label={
              editType === STRINGS.SMALL_EDIT ? STRINGS.UPDATE : (editType === STRINGS.SMALL_CLONE) ? STRINGS.CLONE : STRINGS.SAVE
            }
            size={"sm36"}
            variant={"primary"}
            onClick={handleCreateAutomatedEmail}
            isLoading={performing}
          />
          <Button
            label={STRINGS.CANCEL}
            variant={"ghost"}
            size={"sm36"}
            onClick={() => setOpen(false)}
          />
        </Box>
      </Box>
    </div>
  );
  return (
    <>
      <CustomModal
        open={open}
        close={() => setOpen(false)}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
        }}
        width={"43.75vw"}
      />
      {showPreview && (
        <PreviewMail
          open={showPreview}
          setOpen={setShowPreview}
          subject={subject}
          body={`<div>${document
            .getElementById(STRINGS.SMALL_EDITOR)
            ?.innerHTML?.toString()}</div>`}
        />
      )}
      {checked && (
        <CreateTempModal
          open={checked}
          setOpen={setChecked}
          sub={subject}
          data={`<div>${document
            .getElementById(STRINGS.SMALL_EDITOR)
            ?.innerHTML?.toString()}</div>`}
          getCurrentTemplate={currentTemplate}
        />
      )}
    </>
  );
};

export default CreateAutomatedEmail;
