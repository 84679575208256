import React, { useEffect, useState } from "react";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import FilterTab from "../../../../components/FilterTab/FilterTab";
import { GREY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  SHADOW,
  fontWeight,
} from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decompressString,
  filterDynamicQuery,
} from "../../../../utils/helpers";
import classes from "./CrmComp.module.css";
import CustomStyledMenu from "../../../../components/Menu/CustomStyledMenu";
import {
  allDynamicFilters,
  filterMultipleList,
} from "../../../../redux/actions/filterActions";
import AddFiltermodal from "./AddFilterModal";
import { HandleClearAllFilterFromStore } from "../../../../redux/store/storeHelper";
import { Button } from "../../../../components/Buttons";
import * as qs from "qs";
import InputField from "../../../../components/InputFields/InputField";
import FilterMultipleCheckbox from "../../../../components/FilterTypes/Dynamic/FilterMultipleCheckbox";
import {
  COMPANY_EMPLOYEES_COUNT_DYNAMIC,
  SENIORIRT_TYPES_DYNAMIC,
} from "../../../../utils/constants";
import FilterMultipleNoSuggestions from "../../../../components/FilterTypes/Dynamic/FilterMultipleNoSuggestions";
import { getDealsList } from "../../../../redux/actions/dealsActions";

const FilterStrip = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryObject = qs.parse(window.location?.search?.slice(1));
  if (queryObject?.page === 1) {
    delete queryObject?.page;
  }
  if (queryObject?.limit === 25) {
    delete queryObject?.limit;
  }
  const queryObjectLength = Object.keys(queryObject || {})?.filter(
    (item) => !["page", "limit"].includes(item)
  )?.length;

  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  let propertiesPayload = localStorage.getItem("properties")
    ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))
      ?.properties_key_value
    : getPropertiesListSelector?.properties_key_value || {};

  const [filterTabAnchor, setFilterTabAnchor] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(-1);
  const [dealNameFilter, setDealNameFilter] = useState("");

  // All Sales Filters Function

  // filter for deal name search
  const handleDealSearch = (data, id, payload) => {
    if (data?.[id]?.length) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(payload, propertiesPayload),
          deal_name: data?.deal_name,
        }).toString(),
      });
      return {
        ...payload,
        deal_name: data?.deal_name,
      };
    } else {
      return { ...payload };
    }
  };

  // filter for associated contact
  const handleAllFiltersQuery = (data, id, payload) => {
    // associated_contact
    if (id !== STRINGS.DEAL_NAME_SMALL) {
      // if any or not anyOf true and isApicall is also true.
      if (
        (filterMultipleListSelector?.[id]?.anyOf ||
          filterMultipleListSelector?.[id]?.notAnyOf) &&
        filterMultipleListSelector?.isApiCall
      ) {
        let temp_data = {
          ...data,
          exist_fields: [...(payload?.exist_fields || [])]?.filter(
            (e) => e !== id
          ),
          not_exist_fields: [...(payload?.not_exist_fields || [])].filter(
            (e) => e !== id
          ),
        };
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            ...filterDynamicQuery(temp_data, propertiesPayload),
            exist_fields: [...(payload?.exist_fields || [])]?.filter(
              (e) => e !== id
            ),
            not_exist_fields: [...(payload?.not_exist_fields || [])]?.filter(
              (e) => e !== id
            ),
          }).toString(),
        });
        return { ...data, ...temp_data };
      }
      // if isKnown is checked
      else if (
        filterMultipleListSelector?.[id]?.isKnown &&
        filterMultipleListSelector?.isApiCall
      ) {
        const queryPayload = {
          exist_fields: [...new Set([...(payload?.exist_fields || []), id])],
          not_exist_fields: [...(payload?.not_exist_fields || [])].filter(
            (e) => e !== id
          ),
          [id]: [],
          [`${id}-not`]: [],
        };

        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            ...queryPayload,
          }).toString(),
        });
        return { ...data, ...queryPayload };
      }
      // if isUnknown is checked
      else if (
        filterMultipleListSelector?.[id]?.isUnknown &&
        filterMultipleListSelector?.isApiCall
      ) {
        const queryPayload = {
          not_exist_fields: [
            ...new Set([...(payload?.not_exist_fields || []), id]),
          ],
          exist_fields: [...(payload?.exist_fields || [])].filter(
            (e) => e !== id
          ),
          [id]: [],
          [`${id}-not`]: [],
        };
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            ...queryPayload,
          }).toString(),
        });
        return { ...data, ...queryPayload };
      }
      // if isKnown is unchecked
      else if (
        (!filterMultipleListSelector?.[id]?.isKnown ||
          !filterMultipleListSelector?.[id]?.isUnknown) &&
        id !== STRINGS.SEARCH_SMALL &&
        id !== STRINGS.NAME_SMALL &&
        filterMultipleListSelector?.isApiCall &&
        id !== STRINGS.PAGE_SMALL &&
        id !== STRINGS.LIMIT_SMALL
      ) {
        const queryPayload = {
          exist_fields: [...(payload?.exist_fields || [])].filter(
            (e) => e !== id
          ),
          not_exist_fields: [...(payload?.not_exist_fields || [])].filter(
            (e) => e !== id
          ),
          [id]: [],
          [`${id}-not`]: [],
        };

        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            ...queryPayload,
          }).toString(),
        });
        return { ...data, ...queryPayload };
      } else if (id === STRINGS.SEARCH_SMALL) {
        if (data?.search?.length) {
          navigate({
            pathname: location.pathname,
            search: createSearchParams({
              ...filterDynamicQuery(payload, propertiesPayload),
              search: data?.search,
            }).toString(),
          });
          return {
            ...payload,
            search: data?.search,
          };
        } else {
          let temp_payload = { ...payload };
          navigate({
            pathname: location.pathname,
            search: createSearchParams({
              ...filterDynamicQuery(temp_payload, propertiesPayload),
            }).toString(),
          });
          return { ...temp_payload };
        }
      } else if (id === STRINGS.NAME_SMALL) {
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            name: data?.name,
          }).toString(),
        });
        return {
          ...payload,
          name: data?.name,
        };
      } else {
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(data, propertiesPayload),
          }).toString(),
        });
        return { ...payload };
      }
    } else {
      return handleDealSearch(data, id, payload);
    }
  };

  // filter for date picker field type
  const handleDateFilterHandler = (data, id, payload) => {
    if (Object?.keys(data?.[id])?.length) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(payload, propertiesPayload),
          [`from-${id}`]: data?.[id]?.from,
          [`to-${id}`]: data?.[id]?.to,
        }).toString(),
      });
      return { ...payload, ...data };
    } else {
      return { ...payload };
    }
  };

  // filter for number field type
  const handleNumberFilterHandler = (data, id, payload) => {
    if (Object?.keys(data?.[id])?.length) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(payload, propertiesPayload),
          [`min-${id}`]: data?.[id]?.min,
          [`max-${id}`]: data?.[id]?.max,
        }).toString(),
      });
      return { ...payload, ...data };
    } else {
      let temp_payload = { ...payload };
      delete temp_payload?.[id];
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(temp_payload, propertiesPayload),
        }).toString(),
      });
      return { ...temp_payload };
    }
  };

  // filter for checkbox field type
  const handleCheckboxFilterHandler = (data, id, payload) => {
    let queryPayload = { [`cb-${id}`]: data?.[`cb-${id}`] };

    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...filterDynamicQuery(payload, propertiesPayload),
        ...queryPayload,
      }).toString(),
    });
    return { ...payload, ...data };
  };

  // Filters UI

  const nameFilterUI = (
    <div className={classes.nameFilterContainer}>
      <InputField
        size={"sm36"}
        onKeyDown={(e) => e.stopPropagation()}
        placeholder={STRINGS.SEARCH}
        onChange={(e) => {
          setDealNameFilter(e?.target?.value);
        }}
        value={filterMultipleListSelector?.deal_name || dealNameFilter}
      />
    </div>
  );

  const jobTitleFilterUI = (
    <div>
      <FilterMultipleNoSuggestions filterName={STRINGS.JOB_TITLE_SMALL} />
    </div>
  );

  const industryFilterUI = (
    <div>
      <FilterMultipleNoSuggestions filterName={STRINGS.INDUSTRY_SMALL} />
    </div>
  );

  const companySizeFilterUI = (
    <div className={classes.companySizeFilter}>
      <FilterMultipleCheckbox
        dispatchAllowed
        filterName={STRINGS.EMPLOYEE_NUMBER_SMALL}
        items={COMPANY_EMPLOYEES_COUNT_DYNAMIC}
      />
    </div>
  );

  const seniorityFilterUI = (
    <div className={classes.seniorityFilter}>
      {" "}
      <FilterMultipleCheckbox
        dispatchAllowed
        filterName={STRINGS.SENIORITY_SMALL}
        items={SENIORIRT_TYPES_DYNAMIC}
      />{" "}
    </div>
  );

  // Getting Count of filters applied to display on filter tab.
  const getAppliedFilterCount = (query, filterType) => {
    let values = filterDynamicQuery(query, propertiesPayload) || {};

    if (
      (values?.exist_fields || [])?.indexOf(filterType) !== -1 ||
      (values?.not_exist_fields || [])?.indexOf(filterType) !== -1
    ) {
      return 1;
    } else if (
      filterType === STRINGS.ASSOCIATED_CONTACT_SMALL ||
      filterType === STRINGS.DEAL_OWNER_SMALL
    ) {
      return (
        (values?.[filterType] || [])?.length +
        (values?.[`${filterType}-not`] || [])?.length
      );
    } else if (
      values?.[`max-${filterType}`]?.toString() &&
      values?.[`min-${filterType}`]?.toString()
    ) {
      return 1;
    } else if (
      filterType === STRINGS.DEAL_NAME_SMALL ||
      filterType === STRINGS.NAME_SMALL
    ) {
      return values?.[filterType]?.length ? 1 : 0;
    } else if (
      `${filterType}` === STRINGS.EMPLOYEE_NUMBER_SMALL ||
      `${filterType}` === STRINGS.SENIORITY_SMALL
    ) {
      return values?.[`cb-${filterType}`]?.length
        ? values?.[`cb-${filterType}`]?.length
        : 0;
    } else {
      return (
        (values?.[filterType] || [])?.length +
        (values?.[`${filterType}-not`] || [])?.length
      );
    }
  };

  // clear all filters.
  const handleClearAllFilter = () => {
    // for company name filter
    HandleClearAllFilterFromStore(dispatch, location, navigate);
    dispatch(getDealsList({ query: {} }, navigate));
    setDealNameFilter("");
  };

  const filterTabOnClickHandler = (e, filterTab) => {
    e?.preventDefault();
    setFilterTabAnchor(e?.currentTarget);
    setSelectedFilter(filterTab?.id);
  };

  const filterTabValue = [
    {
      label: STRINGS.NAME,
      id: 1,
      filterUI: nameFilterUI,
      filter_id: STRINGS.NAME_SMALL,
    },
    {
      label: STRINGS.JOB_TITLE,
      id: 2,
      filterUI: jobTitleFilterUI,
      filter_id: STRINGS.JOB_TITLE_SMALL,
    },
    {
      label: STRINGS.COMPANY_SIZE,
      id: 3,
      filterUI: companySizeFilterUI,
      filter_id: STRINGS.EMPLOYEE_NUMBER_SMALL,
    },
    {
      label: STRINGS.SENIORITY,
      id: 4,
      filterUI: seniorityFilterUI,
      filter_id: STRINGS.SENIORITY_SMALL,
    },
    {
      label: STRINGS.INDUSTRY,
      id: 5,
      filterUI: industryFilterUI,
      filter_id: STRINGS.INDUSTRY_SMALL,
    },
  ];

  useEffect(() => {
    if (
      Object.keys(filterMultipleListSelector || {})?.length &&
      Object.keys(propertiesPayload || {})?.length
    ) {
      let payload = { ...(allDynamicFiltersSelector || {}) };
      let api_call = false;
      for (const temp_id in filterMultipleListSelector) {
        let id = temp_id?.replace("cb-", "");
        switch (propertiesPayload?.[id]?.field_type[0]) {
          case STRINGS.NUMBER:
            api_call = true;
            payload = handleNumberFilterHandler(
              filterMultipleListSelector,
              id,
              payload
            );
            break;
          case STRINGS.DATE_PICKER:
            api_call = true;
            payload = handleDateFilterHandler(
              filterMultipleListSelector,
              id,
              payload
            );
            break;
          case STRINGS.MULTIPLE_CHECKBOX:
            api_call = true;
            payload = handleCheckboxFilterHandler(
              filterMultipleListSelector,
              id,
              payload
            );
            break;
          default:
            if (filterMultipleListSelector?.isApiCall) {
              api_call = true;
            }
            payload = handleAllFiltersQuery(
              filterMultipleListSelector,
              id,
              payload
            );
            break;
        }
      }
      if (api_call) {
        dispatch(allDynamicFilters({ ...payload }, false));
      }
    }
  }, [filterMultipleListSelector]);

  // debounce search api call for search
  useEffect(() => {
    let getData;
    if (dealNameFilter) {
      getData = setTimeout(() => {
        dispatch(
          filterMultipleList({
            ...filterMultipleListSelector,
            name: dealNameFilter,
            isApiCall: true,
          })
        );
      }, 300);
    } else {
      let payload = { ...filterMultipleListSelector, isApiCall: true };
      delete payload?.name;
      getData = setTimeout(() => {
        dispatch(filterMultipleList({ ...payload }));
      }, 300);
    }
    return () => clearTimeout(getData);
  }, [dealNameFilter]);

  return (
    <div className={classes.filterStripContainer}>
      {/* Filters of Table */}
      <div className={classes.filtersMainContainer}>
        <div className={classes.filtersContainer}>
          <div className={classes.filterLabel}>
            <TypographyInline
              color={GREY.SECONDARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={`${STRINGS.FILTER}:`}
              size={"sm"}
            />
          </div>
          {filterTabValue?.map((filterTab, index) => {
            return (
              <div key={filterTab?.id}>
                <FilterTab
                  isActive={selectedFilter === filterTab?.id}
                  label={filterTab?.label}
                  rightIcon={filterTab?.rightIcon || ICON_LABELS.ACTION_ARROW}
                  disabled={filterTab?.isDiabled}
                  id={filterTab?.id}
                  key={index}
                  onClick={(e) => filterTabOnClickHandler(e, filterTab)}
                  selectedValue={
                    parseInt(
                      getAppliedFilterCount(
                        allDynamicFiltersSelector,
                        filterTab.filter_id
                      )
                    )
                      ? parseInt(
                        getAppliedFilterCount(
                          allDynamicFiltersSelector,
                          filterTab.filter_id
                        )
                      )
                      : 0
                  }
                />
                <CustomStyledMenu
                  anchorEl={filterTabAnchor}
                  open={
                    selectedFilter === filterTab?.id && Boolean(filterTabAnchor)
                  }
                  arrow
                  onClose={() => {
                    setFilterTabAnchor(null);
                    setSelectedFilter(-1);
                  }}
                  noMargin={true}
                  isRightAlign={true}
                  shadow={SHADOW.md}
                  marginTop={"11px !important"}
                >
                  {filterTab?.filterUI}
                </CustomStyledMenu>
              </div>
            );
          })}

          {queryObjectLength > 0 ? (
            <div className={classes.addFilter2}>
              <Button
                size={"xs28"}
                LeftIcon={ICON_LABELS.CLOSE}
                label={STRINGS.CLEAR_ALL}
                variant={"text"}
                onClick={handleClearAllFilter}
              />
            </div>
          ) : null}
        </div>

        {/* Add Filter Modal */}
        <div className={classes.addFilterButton}>
          <AddFiltermodal
            dealNameFilter={dealNameFilter}
            setDealNameFilter={setDealNameFilter}
          />
        </div>
        {/* Add Filter Modal */}
      </div>
      {/* Filters of Table */}
    </div>
  );
};

export default FilterStrip;
