import React from "react";
import { Box } from "@mui/system";
import { CircularLoader, ImageIcon } from "../../../components";
import { useEffect, useState } from "react";
import { D3LineChart } from "./d3/D3LineChart";
import { D3PieChart } from "./d3/D3PieChart";
import { D3HBarChart } from "./d3/D3HBarChart";
import { useDispatch, useSelector } from "react-redux";
import { BoxWidget } from "./boxWidget";
import { CommonBlacklistTable } from "../../Leads/components";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  STATS_KEY_VALUES,
  WIDGET_TABLE_LEADS_LIST,
  WIDGET_TABLE_UNSUBSCRIBE_LIST,
} from "../../../utils/constants";
import { Styles } from "../Styles";
import { useNavigate } from "react-router-dom";
import {
  getChartData,
  getDashboardWidgetList,
} from "../../../redux/actions/dashboardActions";
import MultipleSelectCheckmarks from "../../../components/InputFields/MultipleSelectCheckmarks";
import { dashboardEndpoints } from "../../../utils/constants/httpConstants";
import { postRequest } from "../../../Apis";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { D3VBarChart } from "./d3/D3VBarChart";
import { ACTIVITY_CHART_INITIAL, BAR_CHART, BAR_CHART_INITIAL, LINE_CHART_INITIAL, PIECHART_DISABLED, VERTICAL_CHART_INITIAL } from "../../../utils/constants/assets";
import { D3VMultipleBarChartNoDate } from "./d3/D3VMultipleBarChartNoDate";
import { putRequest } from "../../../Apis/apiWrapper";
import CustomModal from "../../../components/Modals/CustomModal";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import IconComponent from "../../../components/Icon/IconComponent";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";
import { capitalizeFirstLetter } from "../../../utils/helpers";

const WidgetDetails = (props) => {
  const classes = Styles();
  const [range, setRange] = useState(["today"]);
  const [rangeLabel, setRangeLabel] = useState([STRINGS.TODAY]);
  const [widgetChart, setWidgetChart] = useState([]);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [widgetIds, setWidgetIds] = useState([]);
  const [selectedListValue, setSelectedListValue] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [reCl, setRecl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [applyFlag, setApplyFlag] = useState(false);
  const [btnType, setBtnType] = useState("");
  const [isActivityBlank, setIsActivityBlank] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const chartDataSelector = useSelector((state) => state.getChartData);

  const dashboardWidgetListSelector = useSelector(
    (state) => state.getDashboardWidgetDetails
  );

  const handleChange = (event) => {
    setRange(event.target.value);
  };

  const handleReport = async () => {
    let filterPayload = {};

    if (btnType === "apply") {
      filterPayload = {
        widget_id: props?.widgetDetails?._id,
        filters: {
          fromDate: new Date(fromDate).getTime(),
          toDate: new Date(toDate).getTime(),
          customType: range[0],
          campaign_id:
            props?.widgetDetails?.data_type === "campaign"
              ? selectedListValue?.filter((item) => item !== "All")
              : null,
          sequence_id:
            props?.widgetDetails?.data_type === "sequence"
              ? selectedListValue?.filter((item) => item !== "All")
              : null,
        },
      };
    } else if (btnType === "reset" || btnType === "") {
      filterPayload = {
        widget_id: props?.widgetDetails?._id,
        filters: {
          fromDate: props?.widgetDetails?.filters?.fromDate,
          toDate: props?.widgetDetails?.filters?.toDate,
          campaign_id: props?.widgetDetails?.filters?.campaign_id,
          sequence_id: props?.widgetDetails?.filters?.sequence_id,
        },
      };
    }

    setIsApiCall(true);
    const response = await putRequest(
      navigate,
      dashboardEndpoints.updateWidgetFilters,
      filterPayload
    );

    if (response.status == 201) {
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    }

    dispatch(
      getDashboardWidgetList(navigate, props?.widgetDetails?.dashboard_id, true)
    );

    setIsApiCall(false);
  };

  const topBox = (id, arr, color, value) => {
    let tempYValues = [];
    arr?.map((temp, i) => {
      return tempYValues.push(Object.values(temp)[1]);
    });
    let tempYsum = tempYValues.reduce((a, b) => a + b, 0);
    return (
      <Box
        className={`${classes.widgetToolBar11} ${id === "total_enrolled"
          ? classes.topBoxMarginTotalEnrolled
          : classes.topBoxMargin
          }`}
      >
        {id === "total_enrolled" ? (
          <Box>
            <div className={classes.statsValue}>
              <TypographyInline
                label={value ? value : tempYsum}
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
                color={GREY.PRIMARY}
              />
            </div>
            <Box className={classes.toolBarNames}>
              <IconComponent
                color={color}
                fontSize={fontSize.SM}
                iconLabel={ICON_LABELS.FIBER_MANUAL_RECORD}
              />
              <TypographyInline
                label={STATS_KEY_VALUES[id]}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
                color={GREY.SECONDARY}
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <Box className={classes.toolBarNames}>
              <IconComponent
                color={color}
                fontSize={fontSize.SM}
                iconLabel={ICON_LABELS.FIBER_MANUAL_RECORD}
              />
              <TypographyInline
                label={STATS_KEY_VALUES[id]}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
                color={GREY.SECONDARY}
              />
            </Box>
            <div className={classes.statsValue}>
              <TypographyInline
                label={value ? value : tempYsum}
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
                color={GREY.PRIMARY}
              />
            </div>
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (props.widgetType) {
      (async () => {
        try {
          let val = new Date().getTime();
          let mod = new Date(val);
          let final = new Date(mod.setHours(0, 0, 0));
          const dateObject = new Date(final);
          const todayDate = dateObject.getTime();
          let widgetIdObj = {
            fromDate: todayDate,
            toDate: new Date().getTime(),
          };
          setIsLoading(true);
          let payload = {
            widget_type: props.widgetType,
            data_type: props.report[0].split(" ")[0].toLowerCase(),
            ...widgetIdObj,
          };
          let response = await postRequest(
            navigate,
            dashboardEndpoints.getInitialReport,
            payload
          );
          setWidgetChart(response?.data?.data);
          setIsLoading(false);
        } catch (error) {
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        }
      })();
    }
  }, [props.widgetType]);

  useEffect(() => {

    if (props?.widgetDetails?.filters) {
      let toDate = props?.widgetDetails?.filters?.toDate;
      let fromDate = props?.widgetDetails?.filters?.fromDate;

      let timeDiffrence = toDate - fromDate;
      let dayDiffrence = Math.floor(timeDiffrence / 1000 / 60 / 60 / 24);
      if (props?.widgetDetails?.filters?.customType === "customRange") {
        setRange(["customRange"]);
        setRangeLabel(STRINGS.CUSTOM_RANGE);
      }
      else {
        if (dayDiffrence === 0) {
          setRange(["today"]);
          setRangeLabel(STRINGS.TODAY);
        } else if (dayDiffrence === 1) {
          setRange(["yesterday"]);
          setRangeLabel(STRINGS.YESTERDAY);
        } else if (dayDiffrence === 7) {
          setRange(["week"]);
          setRangeLabel(STRINGS.WEEK);
        } else if (dayDiffrence === 30) {
          setRange(["month"]);
          setRangeLabel(STRINGS.MONTH);
        } else {
          setRange(["customRange"]);
          setRangeLabel(STRINGS.CUSTOM_RANGE);
        }

      }
      setToDate(props?.widgetDetails?.filters?.toDate);
      setFromDate(props?.widgetDetails?.filters?.fromDate);

      if (props?.widgetDetails?.data_type === "campaign") {
        setSelectedList(props?.widgetDetails?.filters?.campaign_id);
        setSelectedListValue(props?.widgetDetails?.filters?.campaign_id);
      } else if (props?.widgetDetails?.data_type === "sequence") {
        setSelectedList(props?.widgetDetails?.filters?.sequence_id);
        setSelectedListValue(props?.widgetDetails?.filters?.sequence_id);
      }
    }
  }, [props?.widgetDetails?.filters]);

  useEffect(() => {
    if (props?.widgetDetails?._id) {
      setWidgetChart(chartDataSelector?.payload?.[props?.widgetDetails?._id]);
    }

    // stroing true/false for blank activity report graph
    if (props?.widgetDetails?.data_type === "activity" && props?.widgetDetails?.widget_type === "bar-vertical") {
      const chartData = chartDataSelector?.payload?.[props?.widgetDetails?._id];
      const inCompleteCallCount = chartData?.[0]?.arr?.[0]?.y || 0 + chartData?.[0]?.arr?.[0]?.z || 0;
      const inCompleteTodoCount = chartData?.[1]?.arr?.[1]?.y || 0 + chartData?.[1]?.arr?.[1]?.z || 0;
      const completeCallCount = chartData?.[0]?.arr?.[0]?.y || 0 + chartData?.[0]?.arr?.[0]?.z || 0;
      const completeTodoCount = chartData?.[1]?.arr?.[1]?.y || 0 + chartData?.[1]?.arr?.[1]?.z || 0;
      const totalCount = inCompleteCallCount + inCompleteTodoCount + completeCallCount + completeTodoCount;
      setIsActivityBlank(Boolean(totalCount));
    }
  }, [chartDataSelector, props?.widgetDetails]);

  useEffect(() => {
    if (dashboardWidgetListSelector?.payload?.records?.length > 0) {
      let widgetIdArr = [];
      dashboardWidgetListSelector?.payload?.records.forEach((temp, i) => {
        if (temp._id === props?.widgetDetails?._id) {
          widgetIdArr.push({
            id: temp._id,
            fromDate: new Date(fromDate).getTime(),
            toDate: new Date(toDate).getTime(),
            campaign_id:
              props?.widgetDetails?.data_type === "campaign"
                ? selectedListValue?.filter((item) => item !== "All")
                : null,
            sequence_id:
              props?.widgetDetails?.data_type === "sequence"
                ? selectedListValue?.filter((item) => item !== "All")
                : null,
          });
        } else {
          widgetIdArr.push({
            id: temp._id,
            fromDate: null,
            toDate: null,
            campaign_id: null,
            sequence_id: null,
          });
        }
      });
      // console.log("WDGTARR",widgetIdArr)
      setWidgetIds(widgetIdArr);
    }
  }, [
    dashboardWidgetListSelector,
    fromDate,
    toDate,
    selectedListValue,
    props?.widgetDetails,
  ]);

  useEffect(() => {
    const currentDate = new Date().getTime();
    let val = new Date().getTime();
    let mod = new Date(val);
    let final = new Date(mod.setHours(0, 0, 0));
    const dateObject = new Date(final);
    const todayDate = dateObject.getTime();
    // console.log("vjhgjhghj",todayDate,"CURR",currentDate)
    if (range[0] === "today") {
      setToDate(currentDate);
      setFromDate(todayDate);
    } else if (range[0] === "yesterday") {
      let yesterdayDate = currentDate - 24 * 60 * 60 * 1000;
      setToDate(currentDate);
      setFromDate(yesterdayDate);
    } else if (range[0] === "week") {
      let weekDate = currentDate - 7 * 24 * 60 * 60 * 1000;
      setToDate(currentDate);
      setFromDate(weekDate);
    } else if (range[0] === "month") {
      let weekDate = currentDate - 30 * 24 * 60 * 60 * 1000;
      setToDate(currentDate);
      setFromDate(weekDate);
    } else if (range[0] === "customRange") {
      setToDate(props?.widgetDetails?.filters?.toDate || toDate);
      setFromDate(props?.widgetDetails?.filters?.fromDate || fromDate);
    }
  }, [range, props?.widgetDetails?.filters]);

  const submitHandler = async (e) => {
    if (props.widgetType !== undefined) {
      let widgetIdObj;
      if (btnType === "apply") {
        setIsApiCall(true);
        widgetIdObj = {
          fromDate: new Date(fromDate).getTime(),
          toDate: new Date(toDate).getTime(),
          customType: range[0]
        };

        if (props.report[0].split(" ")[0].toLowerCase() === "campaign") {
          widgetIdObj.campaign_id = selectedListValue?.filter(
            (item) => item !== "All"
          );
        } else if (props.report[0].split(" ")[0].toLowerCase() === "sequence") {
          widgetIdObj.sequence_id = selectedListValue?.filter(
            (item) => item !== "All"
          );
        }
      }
      else {
        setRangeLabel(STRINGS.TODAY);
        setRange(["today"]);
        let val = new Date().getTime();
        let mod = new Date(val);
        let final = new Date(mod.setHours(0, 0, 0));
        const dateObject = new Date(final);
        const todayDate = dateObject.getTime();
        widgetIdObj = {
          fromDate: todayDate,
          toDate: new Date().getTime(),
        };
      }

      await props.onAddItem(
        props.widgetType,
        props.report[0].split(" ")[0].toLowerCase(),
        widgetIdObj
      );
      props.setWidgetDetailFlag(!props.widgetDetailFlag);
      props.close();
      props.closeTour();
      props.complteTour();

      setIsApiCall(false);
    } else {
      await handleReport();
      props.setWidgetDetailFlag(!props.widgetDetailFlag);
    }
  };

  const modalHeaderData = {
    heading: {
      id: "widgetDetails",
      label: capitalizeFirstLetter(props?.widgetDetails?.widget_name) || props?.report[0],
    },
    description: {
      id: "widgetDetailDesc",
      label: (!selectedListValue?.length) ? STRINGS.WIDGET_DETAILS_DESC : ""
    }
  };

  const handleResetWidget = async () => {
    setBtnType("reset");
    setResetFlag(true);

    if (props.widgetType) {
      setSelectedList([]);
      setRangeLabel(STRINGS.TODAY);
      setRange(["today"]);
      let val = new Date().getTime();
      let mod = new Date(val);
      let final = new Date(mod.setHours(0, 0, 0));
      const dateObject = new Date(final);
      const todayDate = dateObject.getTime();
      let widgetIdObj = {
        fromDate: todayDate,
        toDate: new Date().getTime(),
      };
      let payload = {
        widget_type: props.widgetType,
        data_type: props.report[0].split(" ")[0].toLowerCase(),
        ...widgetIdObj,
      };
      let response = await postRequest(
        navigate,
        dashboardEndpoints.getInitialReport,
        payload
      );
      setWidgetChart(response?.data?.data);
    } else {
      setSelectedList([]);
      setRangeLabel(STRINGS.TODAY);
      setRange(["today"]);
      let val = new Date().getTime();
      let mod = new Date(val);
      let final = new Date(mod.setHours(0, 0, 0));
      const dateObject = new Date(final);
      const todayDate = dateObject.getTime();
      let widgetIdObj = {
        fromDate: todayDate,
        toDate: new Date().getTime(),
      };
      let payload = {
        widget_type: props.widgetDetails.widget_type,
        data_type: props.widgetDetails.data_type,
        ...widgetIdObj,
      };
      let response = await postRequest(
        navigate,
        dashboardEndpoints.getInitialReport,
        payload
      );
      setWidgetChart(response?.data?.data);
      setRecl(true);
    }
    setResetFlag(false);
  };

  const handleApplyWidget = async () => {
    setBtnType("apply");
    setApplyFlag(true);
    if (selectedListValue?.length > 0 || range[0] !== "today") {
      setRecl(false);
    }
    if (props.widgetType) {
      let widgetIdObj = {
        fromDate: new Date(fromDate).getTime(),
        toDate: new Date(toDate).getTime(),
        customType: range[0]
      };

      if (props.report[0].split(" ")[0].toLowerCase() === "campaign") {
        widgetIdObj.campaign_id = selectedListValue?.filter(
          (item) => item !== "All"
        );
      } else if (props.report[0].split(" ")[0].toLowerCase() === "sequence") {
        widgetIdObj.sequence_id = selectedListValue?.filter(
          (item) => item !== "All"
        );
      }
      let payload;
      payload = {
        widget_type: props.widgetType,
        data_type: props.report[0].split(" ")[0].toLowerCase(),
        ...widgetIdObj,
      };
      let response = await postRequest(
        navigate,
        dashboardEndpoints.getInitialReport,
        payload
      );
      setWidgetChart(response?.data?.data);
    } else if (reCl && selectedListValue?.length < 1 && range[0] === "today") {
      let val = new Date().getTime();
      let mod = new Date(val);
      let final = new Date(mod.setHours(0, 0, 0));
      const dateObject = new Date(final);
      const todayDate = dateObject.getTime();
      let widgetIdObj = {
        fromDate: todayDate,
        toDate: new Date().getTime(),
      };

      if (props.report[0].split(" ")[0].toLowerCase() === "campaign") {
        widgetIdObj.campaign_id = selectedListValue?.filter(
          (item) => item !== "All"
        );
      } else if (props.report[0].split(" ")[0].toLowerCase() === "sequence") {
        widgetIdObj.sequence_id = selectedListValue?.filter(
          (item) => item !== "All"
        );
      }
      let payload;
      payload = {
        widget_type: props.widgetDetails.widget_type,
        data_type: props.widgetDetails.data_type,
        fromDate: todayDate,
        toDate: new Date().getTime(),
      };
      let response = await postRequest(
        navigate,
        dashboardEndpoints.getInitialReport,
        payload
      );
      setWidgetChart(response?.data?.data);
    } else {
      let payload = {
        widget_ids: widgetIds,
      };

      await dispatch(getChartData(navigate, payload, true));
    }

    setApplyFlag(false);
  };

  const cancelHandle = async () => {
    if (props.widgetType) {
      props.setWidgetDetailFlag(!props.widgetDetailFlag);
      setSelectedList([]);
      setRangeLabel(STRINGS.TODAY);
      setRange(["today"]);
      setBtnType("")
      let val = new Date().getTime();
      let mod = new Date(val);
      let final = new Date(mod.setHours(0, 0, 0));
      const dateObject = new Date(final);
      const todayDate = dateObject.getTime();
      let widgetIdObj = {
        fromDate: todayDate,
        toDate: new Date().getTime(),
      };
      let payload = {
        widget_type: props.widgetType,
        data_type: props.report[0].split(" ")[0].toLowerCase(),
        ...widgetIdObj,
      };
      let response = await postRequest(
        navigate,
        dashboardEndpoints.getInitialReport,
        payload
      );
      setWidgetChart(response?.data?.data);
    } else {
      dispatch(
        getDashboardWidgetList(
          navigate,
          props?.widgetDetails?.dashboard_id,
          true
        )
      );
      props.setWidgetDetailFlag(!props.widgetDetailFlag);
    }
  };

  const modalFooterRightData = (
    <Box className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={cancelHandle}
      />
      <Button
        isLoading={isApiCall}
        label={props.widgetType ? STRINGS.CREATE_WIDGET : STRINGS.SAVE_REPORT}
        size={"sm36"}
        variant={"primary"}
        value="close_modal"
        onClick={submitHandler}
      />
    </Box>
  );

  const rangeDropDown = [
    {
      id: "today",
      label: STRINGS.TODAY,
    },
    {
      id: "yesterday",
      label: STRINGS.YESTERDAY,
    },
    {
      id: "week",
      label: STRINGS.WEEK,
    },
    {
      id: "month",
      label: STRINGS.MONTH,
    },
    {
      id: "customRange",
      label: STRINGS.CUSTOM_RANGE,
    },
  ];

  return (
    <CustomModal
      open={props?.widgetDetailFlag}
      close={cancelHandle}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
      width={"72vw"}
      remove_padding
    >
      <Box className={classes.widgetBodyContainer}>
        <Box className={classes.widgetDetailHeader}>
          <Box className={classes.drodownContainer} data-tour="addfilter">
            <Box className={classes.listDropdown}>
              <>
                <TypographyInline
                  label={STRINGS.DATE_RANGE}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.QUATINARY}
                  isRequired
                />
                <Box className={classes.widgetDetailFilter}>
                  <AutoCompleteCustom
                    size={"sm36"}
                    dropdownId={"dfd"}
                    items={rangeDropDown}
                    valueState={[rangeLabel]}
                    setValueState={setRangeLabel}
                    idState={[range]}
                    setIdState={setRange}
                    paperHeight="fit-content"
                  />
                </Box>
              </>
            </Box>
            {(props?.widgetDetails?.data_type === "campaign" ||
              props?.widgetDetails?.data_type === "sequence" ||
              props?.report[0] === STRINGS.CAMPAIGN_REPORT ||
              props?.report[0] === STRINGS.SEQUENCE_REPORT) && (
                <Box className={classes.listDropdown}>
                  <>
                    <TypographyInline
                      label={
                        props?.widgetDetails?.data_type === "campaign" ||
                          props?.report[0] === STRINGS.CAMPAIGN_REPORT
                          ? STRINGS.SELECT_CAMPAIGNS
                          : STRINGS.SELECT_SEQUENCES
                      }
                      size={"sm"}
                      fontWeight={fontWeight.MEDIUM}
                      color={GREY.QUATINARY}
                    />
                    <Box className={classes.widgetDetailFilter}>
                      <MultipleSelectCheckmarks
                        margin={"10px 0px 0px 0px"}
                        items={
                          props?.widgetDetails?.data_type === "campaign" ||
                            props?.report[0] === STRINGS.CAMPAIGN_REPORT
                            ? props.campaignList
                            : props.sequenceList
                        }
                        setSelectedListValue={setSelectedListValue}
                        selectedList={selectedList}
                        type={props?.report}
                      />
                    </Box>
                  </>
                </Box>
              )}
            <Box className={classes.widgetTopBtn}>
              <Button
                label={STRINGS.RESET}
                isLoading={resetFlag}
                size={"sm36"}
                variant={"ghost"}
                onClick={handleResetWidget}
              />
              <Button
                label={STRINGS.APPLY_NEW}
                isLoading={applyFlag}
                size={"sm36"}
                variant={"primary"}
                onClick={handleApplyWidget}
              />
            </Box>
          </Box>
          {range[0] === "customRange" ? (
            <Box className={classes.datePickerContainer}>
              <Box className={classes.selectionCustomBox}>
                <TypographyInline
                  label={STRINGS.FROM}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.QUATINARY}
                />
                <CustomDatePicker
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(newValue);
                  }}
                  size={"sm36"}
                />
              </Box>
              <Box className={classes.selectionCustomBox2}>
                <TypographyInline
                  label={STRINGS.TO}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.QUATINARY}
                />
                <CustomDatePicker
                  value={toDate}
                  onChange={(newValue) => {
                    setToDate(newValue);
                  }}
                  size={"sm36"}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
        {isLoading ? (
          <Box className={classes.loaderStyle}>
            <CircularLoader />
          </Box>
        ) : (
          <Box className={classes.widgetDetailBody}>
            <div id="widgetDetailsTooltip"></div>
            <Box
              className={
                props.widgetType === "table" ||
                  props?.widgetDetails?.widget_type === "table"
                  ? classes.containMainTable
                  : classes.containMain
              }
            >
              <Box className={`${classes.containBox}`}>
                {(props.widgetType === "line" ||
                  props?.widgetDetails?.widget_type === "line") &
                  (widgetChart?.length > 0) ? (
                  widgetChart.filter((item) => item?.arr?.length)
                    .length ? (
                    <D3LineChart
                      ownerName={props.ownerName}
                      activeTooltip="#widgetDetailsTooltip"
                      dataSet={widgetChart}
                      height={(document.documentElement.clientHeight / 100) * 45}
                      width={(document.documentElement.clientWidth / 100) * 45}
                    />
                  ) : (
                    <div className={classes.paddingTopper16}>
                      <ImageIcon
                        className={classes.pieChartImage}
                        image={LINE_CHART_INITIAL}
                        height={
                          document.getElementById(props.index)?.clientHeight *
                          0.6
                        }
                        width={
                          document.getElementById(props.index)?.clientWidth
                        }
                      />
                    </div>
                  )
                ) : null}
                {(props.widgetType === "pie" ||
                  props?.widgetDetails?.widget_type === "pie") &
                  (widgetChart?.length > 0) ? (
                  widgetChart.filter((item) => parseInt(item.value) !== 0)
                    .length ? (
                    <D3PieChart
                      activeTooltip="#widgetDetailsTooltip"
                      dataSet={widgetChart}
                      height={
                        (document.documentElement.clientHeight / 100) * 40
                      }
                      width={(document.documentElement.clientWidth / 100) * 40}
                    />
                  ) : (
                    <ImageIcon
                      className={classes.pieChartImage}
                      margin="45px"
                      height="30vh"
                      image={PIECHART_DISABLED}
                    />
                  )
                ) : null}
                {(props.widgetType === "bar-horizontal" ||
                  props?.widgetDetails?.widget_type === "bar-horizontal") &
                  (widgetChart?.length > 0) ? (
                  widgetChart.filter((item) => parseInt(item.value) !== 0)
                    .length ? (
                    <D3HBarChart
                      activeTooltip="#widgetDetailsTooltip"
                      dataSet={widgetChart}
                      height={
                        (document.documentElement.clientHeight / 100) * 45
                      }
                      width={(document.documentElement.clientWidth / 100) * 45}
                    />
                  ) : (
                    <div className={classes.paddingTopper16}>
                      <ImageIcon
                        className={classes.pieChartImage}
                        image={BAR_CHART_INITIAL}
                        height={
                          document.getElementById(props.index)?.clientHeight *
                          0.6
                        }
                        width={
                          document.getElementById(props.index)?.clientWidth
                        }
                      />
                    </div>
                  )
                ) : null}
                {((props.widgetType === "bar-vertical" &&
                  props.report[0] === STRINGS.ACTIVITY_REPORT) ||
                  (props?.widgetDetails?.widget_type === "bar-vertical" &&
                    props?.widgetDetails?.widget_name ===
                    STRINGS.ACTIVITY_REPORT_SMALL)) &
                  (widgetChart?.length > 0) ? (
                  isActivityBlank ? (
                    <D3VMultipleBarChartNoDate
                      ownerName={props.ownerName}
                      activeTooltip="#widgetDetailsTooltip"
                      dataSet={widgetChart}
                      height={(document.documentElement.clientHeight / 100) * 45}
                      width={(document.documentElement.clientWidth / 100) * 45}
                    />
                  ) : (
                    <div className={classes.paddingTopper16}>
                      <ImageIcon
                        className={classes.pieChartImage}
                        image={ACTIVITY_CHART_INITIAL}
                        height={
                          document.getElementById(props.index)?.clientHeight *
                          0.6
                        }
                        width={
                          document.getElementById(props.index)?.clientWidth
                        }
                      />
                    </div>
                  )
                ) : null}
                {((props.widgetType === "bar-vertical" &&
                  props.report[0] !== STRINGS.ACTIVITY_REPORT) ||
                  (props?.widgetDetails?.widget_type === "bar-vertical" &&
                    props?.widgetDetails?.widget_name !==
                    STRINGS.ACTIVITY_REPORT_SMALL)) &
                  (widgetChart?.length > 0) ? (
                  widgetChart.filter((item) => parseInt(item.value) !== 0)
                    .length ?
                    <D3VBarChart
                      ownerName={props.ownerName}
                      activeTooltip="#widgetDetailsTooltip"
                      dataSet={widgetChart}
                      height={(document.documentElement.clientHeight / 100) * 45}
                      width={(document.documentElement.clientWidth / 100) * 45}
                    /> : <div className={classes.paddingTopper16}>
                      <ImageIcon
                        className={classes.pieChartImage}
                        image={VERTICAL_CHART_INITIAL}
                        height={
                          document.getElementById(props.index)?.clientHeight *
                          0.6
                        }
                        width={
                          document.getElementById(props.index)?.clientWidth
                        }
                      />
                    </div>
                ) : null}
                {(props.widgetType === "box" ||
                  props?.widgetDetails?.widget_type === "box") &
                  (widgetChart?.length > 0) ? (
                  <BoxWidget dataSet={widgetChart} />
                ) : null}
                {(props.widgetType === "table" ||
                  props?.widgetDetails?.widget_type === "table") &
                  (widgetChart?.length >= 0) ? (
                  <CommonBlacklistTable
                    widgetHeight="54vh"
                    widgetWidth="750"
                    heads={
                      props?.report[0] === STRINGS.UNSUBSCRIBE_REPORT ||
                        props?.widgetDetails?.data_type === "unsubscribe"
                        ? WIDGET_TABLE_UNSUBSCRIBE_LIST
                        : WIDGET_TABLE_LEADS_LIST
                    }
                    data={widgetChart}
                    isDashboardTable={true}
                  />
                ) : null}
              </Box>

              <>
                {props?.widgetType !== "box" &&
                  props?.widgetType !== "table" &&
                  props?.widgetDetails?.widget_type !== "box" &&
                  props?.widgetDetails?.widget_type !== "table" ? (
                  <Box className={classes.widthSt}>
                    {props?.widgetType || reCl ? (
                      <>
                        {isLoading === false ? (
                          <Box className={classes.statBox}>
                            {widgetChart?.map((data, i) => {
                              if (data.id === "total_enrolled") {
                                return (
                                  <Box key={i}>
                                    {topBox(
                                      data.id,
                                      data.arr,
                                      data.color,
                                      data?.value
                                    )}
                                  </Box>
                                );
                              } else {
                                return (
                                  <Box
                                    key={i}
                                    className={classes.topBoxContainer}
                                  >
                                    {topBox(
                                      data.id,
                                      data.arr,
                                      data.color,
                                      data?.value
                                    )}
                                  </Box>
                                );
                              }
                            })}
                          </Box>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {chartDataSelector.loading === false ? (
                          <Box className={classes.statBox}>
                            {chartDataSelector?.payload?.[
                              props?.widgetDetails?._id
                            ]?.map((data, i) => {
                              if (data.id === "total_enrolled") {
                                return (
                                  <Box>
                                    {topBox(
                                      data.id,
                                      data.arr,
                                      data.color,
                                      data?.value
                                    )}
                                  </Box>
                                );
                              } else {
                                return (
                                  <Box className={classes.topBoxContainer}>
                                    {topBox(
                                      data.id,
                                      data.arr,
                                      data.color,
                                      data?.value
                                    )}
                                  </Box>
                                );
                              }
                            })}
                          </Box>
                        ) : null}
                      </>
                    )}
                  </Box>
                ) : null}
              </>
            </Box>
          </Box>
        )}
      </Box>
    </CustomModal>
  );
};

export default React.memo(WidgetDetails);
