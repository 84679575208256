import { makeStyles } from "@mui/styles";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import { ACTIONS } from "../../utils/constants/colors";

export const styles = makeStyles({
  editor: {
    // height: "15.625vw",
    overflow: "auto",
    padding: "11px",
    margin: "0.2rem",
    // fontSize: "clamp(12px, 0.729vw, 30px)",
    fontSize: "small",
    display: "inline-block",
    width: "100%",
    fontFamily: "Arial",
    lineHeight: "20px",
    ...TABLE_SCROLL_BAR,
  },
  containActions: {
    padding: "1rem",
    background: "#f7f8f9",
    borderTop: "1px solid #d3dae3",
    display: "flex",
    flexWrap: "wrap",
    columnGap: "5px",
    rowGap: "5px",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
  editButton: {
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    backgroundColor: "transparent",
    border: "none",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      "&span": {
        fontSize: "20px",
      },
      backgroundColor: ACTIONS.DISABLED,
    },
  },
  pointerClass: {
    cursor: "pointer"
  },
  tagEditButton: {
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    backgroundColor: "transparent",
    border: "none",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      "&span": {
        fontSize: "20px",
      },
    },
  },
  editButtonSelected: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    display: "flex",
    marginRight: "0.6rem",
    alignItems: "center",
    color: "#626b7e",
    backgroundColor: "red",
    "@media (max-width:1470px)": {
      marginRight: "0.4rem",
    },
  },
  tagButton: {
    border: "1px solid #d3dae3",
    background: "transparent",
    cursor: "pointer",
    display: "flex",
    // marginRight: "0.6rem",
    alignItems: "center",
    color: "#626b7e",
    padding: "10px 16px",
    borderRadius: "4px",
  },
  aiBtn: {
    borderRadius: "63px",
    padding: "8px 14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    background: "#D2DFFF",
    color: "#326DFF",
    border: "none",
    cursor: "pointer",
  },
  addTagBtn: {
    borderRadius: "63px",
    padding: "8px 14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    background: "#FCEAEA",
    border: "none",
    cursor: "pointer",
  },
  addUnsubscribeTagBtn: {
    borderRadius: "63px",
    padding: "8px 14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    background: "#F5EFF4",
    border: "none",
    cursor: "pointer",
  },
  padSett: {
    paddingTop: "3px",
  },
  icon: {
    color: "#626b7e",
    cursor: "pointer",
    "@media (max-width:1470px)": {
      fontSize: "17px !important",
    },
  },
  aicon: {
    height: "16.67px",
    width: "16.67px",
  },
  padRight: {
    paddingRight: "6px",
  },
  containAllTags: {
    // padding: "0.5rem 1rem",
    background: "white",
    borderRadius: "6px",
    height: "40vh",
    overflow: "auto",
    ...TABLE_SCROLL_BAR,
  },
  eachTag: {
    fontSize: "clamp(12px, 0.729vw, 30px)",
    color: "#102f51",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "12vw",
    // "&:hover": {
    //   color: "#ff4848",
    // },
  },
  eachIdTag: {
    fontSize: "clamp(12px, 0.729vw, 30px)",
    color: "#ff4848",
    cursor: "pointer",
    width: "15vw",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  containEachAction: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  containAllColors: {
    display: "grid",
    padding: "1rem",
    gridTemplateColumns: "1fr 1fr",
    gap: "12px",
    // width: "30vw",
  },
  eachColor: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
  },
  colorList: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
    flexWrap: "wrap",
    paddingTop: "4px",
  },
  label: {
    color: "#102f51",
    fontSize: "clamp(0.8rem, 0.8vw, 2rem)",
    fontWeight: "500",
  },
  searchInput: {
    margin: "8px 0",
    border: "1px solid #d3dae3",
    padding: "8px 10px",
    width: "100%",
    borderRadius: "4px",
  },
  tabMainContainer: {
    display: "flex",
    columnGap: "10px",
    boxShadow: "0px 4px 6px rgb(0 0 0 / 7%)",
    position: "relative",
    padding: "10px",
  },
  tabHeader: {
    cursor: "pointer",
  },
  searchBar: {
    padding: "10px",
  },
  tagRowContainer: {
    padding: "0px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #D3DAE3",
    height: "45px",
    gap: "8px",
    "&:hover": {
      backgroundColor: "#F7F9FB",
    },
  },
  idValueContainer: {
    display: "flex",
    columnGap: "25px",
    alignItems: "center",
  },
  tooltipTagsContainer: {
    // width: "25vw",
  },
  addTagEditButton: {
    fontSize: "clamp(10px, 0.625vw, 20px)",
    padding: "5px 10px",
    fontFamily: "Inter",
    fontWeight: 400,
    color: "#626B7E",
    background: "transparent",
    border: "1px solid #D3DAE3",
    borderRadius: "4px",
    margin: "10px 0px",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  addTagEditButtonContainer: {
    width: "5vw",
    display: "flex",
    justifyContent: "center",
    margin: "0px 10px",
  },
  editUpdateInput: {
    paddingLeft: "10px",
    width: "12vw",
    padding: "2.5px 10px",
    fontSize: "12px",
    color: "#626B7E",
  },
  linkModalOuterContainer: {
    // height: "100vh",
    // width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkModalInnerContainer: {
    width: "35vw",
    border: "none",
    borderRadius: "8px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#D3DAE3",
    padding: "20px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  linkModalHeaderText: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontSize: "clamp(16px, 1.042vw, 28px)",
    fontWeight: 600,
    color: "#212833",
  },
  bodyContainer: {
    backgroundColor: "white",
    // padding: "20px",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    justifyContent: "center",
  },
  bodySubContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    justifyContent: "center",
  },
  labelText: {
    fontSize: "clamp(12px ,0.729vw, 24px)",
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#212833",
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  allLinkContainer: {
    height: "14vh",
    minWidth: "14vw",
    ...TABLE_SCROLL_BAR,
  },
  loaderContiner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  meetingLinkText: {
    padding: "10px 20px !important",
    color: "#626B7E",
    borderBottom: "1px solid #D3DAE3",
    cursor: "pointer",
  },
  norRecordFoundText: {
    padding: "10px 20px",
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "clamp(12px, 0.729vw, 24px)",
    color: "#FF4848",
    cursor: "pointer",
    textAlign: "center",
  },
  actionsPointerEvents: {
    pointerEvents: "none",
    opacity: "30%",
  },
  flex: {
    display: "flex",
    gap: "8px"
  },
  attachmentContainer: {
    display: 'flex',
    gap: "4px",
    alignItems: 'center',
    background: '#f8f8f8',
    padding: "8px",
    borderRadius: "8px",
    width: 'max-content',
    margin: "8px"
  },
  attachment: {
    display: 'flex',
    gap: "8px",
    alignItems: 'center',
  },
  anchor: {
    textDecoration: 'none',
  },
  cursor: {
    cursor: "pointer"
  },
  emojiPicker: {
    border: "none !important"
  },
  dropdownHeightContainer: {
    minHeight: "36px",
    cursor: "pointer"
  }
});

