import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomHeader from "../../../components/Header/CustomHeader";
import {
  CRM_PATH,
  DEALS_BOARD_PATH,
  DEALS_PATH,
  DEFAULT_PROTECTED_PATH,
} from "../../../utils/constants/routes";
import { STRINGS } from "../strings";
import classes from "./Deals.module.css";
import AvatarImage from "../../../components/Avatar/AvatarImage";
import {
  DUMMY_COMPANY2,
  DUMMY_CONTACT2,
} from "../../../utils/constants/assets";
import { Button } from "../../../components/Buttons";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { BRAND, GREY } from "../../../utils/constants/colors";
import IconComponent from "../../../components/Icon/IconComponent";
import { useState } from "react";
import {
  crmEndpoints,
  dealsEndpoints,
  formsEndpoints,
  meetingsEndpoint,
  taskEndpoints,
} from "../../../utils/constants/httpConstants";
import { getRequest } from "../../../Apis";
import InputFieldWoBorder from "../../../components/InputFields/InputFieldWoBorder";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  INITIALS_STAGES,
} from "../../../utils/constants";
import { AutoCompleteWoBorder } from "../../../components/Autocomplete/AutoCompleteWoBorder";
import IconOutlinedHover from "../../../components/Icon/IconOutlinedHover";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";
import { postRequest, putRequest } from "../../../Apis/apiWrapper";
import { useSelector } from "react-redux";
import {
  createCompanyURL,
  generateNameFromEmail,
  replaceKeyInArrayOfObj,
  textEllipsesHandler,
} from "../../../utils/helpers";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";
import TextArea from "../../../components/InputFields/TextArea";
import {
  AccordionDetails,
  AccordionSummary,
  Anchor,
  HtmlEditor,
} from "../../../components";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { nameValidation } from "../../../utils/validations";
import { Accordion } from "@mui/material";
import SocialButton from "../../../components/Buttons/SocialButton";
import Tabs from "../../../components/Tabs/Tabs";
import IconButton from "../../../components/Buttons/IconButton";
import TaskScreen from "./TaskScreen";
import NoteScreen from "./NoteScreen";
import MeetingScreen from "./MeetingScreen";
import AddEditCompany from "./components/AddEditCompany";
import TimelineScreen from "./TimelineScreen";
import AvatarOutlined from "../../../components/Avatar/AvatarOutlined";
import Image from "../../../components/Image/Image";
import EmailSendModal from "./components/EmailSendModal";
import CreateMeetingPopUp from "./components/CreateMeetingPopUp";
import CreateTaskCrmPopUp from "./components/CreateTaskCrmPopUp";
import CreateNoteCrmPopUp from "./components/CreateNoteCrmPopUp";
import AttachmentScreen from "./AttachmentScreen";
import EmailScreen from "./EmailScreen";
import CustomTooltip from "../../../components/Tooltip/CustomTooltip";
import AvatarName from "../../../components/Avatar/AvatarName";
import AddContactDialog from "../Contacts/components/AddContactDialog";

const DealsDetailScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );
  const userDetailsSelector = useSelector((state) => state?.userDetails);

  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  const userDetails = userDetailsSelector?.payload;
  const [inputData, setInputData] = useState({});
  const [inputDataId, setInputDataId] = useState({});
  const [inputError, setInputError] = useState({});
  const [dealDetails, setDealDetails] = useState({});
  const [loading, setLoading] = useState(0);
  const [data, setData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [locationTab, setLocationTab] = useState("");
  const [openAddCompnay, setOpenAddCompany] = useState(false);
  const [openEmailSendModal, setOpenEmailSendModal] = useState(false);
  const [createFlag, setCreateFlag] = useState(false);
  const [createNoteFlag, setCreateNoteFlag] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [assigneeList, SetAssigneeList] = useState([]);
  const checkEmpty = [];
  const [companyOpen, setCompanyOpen] = useState(true);
  const [contactOpen, setContactOpen] = useState(false);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const [requiredOrder, setRequiredOrder] = useState({});
  const [pipelineStageValue, setPipeLineStageValue] = useState([]);
  const [pipelineStageId, setPipeLineStageId] = useState([]);
  const [closeDate, setCloseDate] = useState(null);
  const [associatedContact, setAssociatedContact] = useState([]);
  const [addContactOpen, setAddContactOpen] = useState(false);
  const [items, setItems] = useState(INITIALS_STAGES || []);
  const [associatedCompany, setAssociatedCompany] = useState([]);

  // states of timeline screen
  const [timelineDataList, setTimelineDataList] = useState([]);
  const [timelineScreenLoading, setTimelineScreenLoading] = useState(false);
  const [totalTimeline, setTotalTimeline] = useState(0);

  // staes of task screen
  const [taskListData, setTaskListData] = useState([]);
  const [taskScreenLoading, setTaskScreenLoading] = useState(false);
  const [totalTasks, setTotaltasks] = useState(0);
  const locationTabs = location?.search?.split("?tabs=");
  const UpdatedLocation = locationTabs[1] == STRINGS.CALLS_SMALL;

  // states of email screen
  const [emailLogListData, setEmailLogListData] = useState([]);
  const [emailScreenLoading, setEmailScreenLoading] = useState(false);
  const [totalEmails, setTotalEmails] = useState(0);

  // states of meeting screen
  const [meetingListData, setMeetingListData] = useState("");
  const [meetingScreenLoading, setMeetingScreenLoading] = useState(false);
  const [totalMeetings, setTotalMeetings] = useState(0);

  // states of notes screen
  const [noteListData, setNoteListData] = useState("");
  const [noteScreenLoading, setNoteScreenLoading] = useState(true);
  const [totalNotes, setTotalNotes] = useState(0);

  const headerItems = [
    {
      id: STRINGS.DEALS_SMALL,
      label: STRINGS.DEALS,
      path:
        localStorage.getItem(STRINGS.DEAL_VIEW_SMALL) === STRINGS.BOARD
          ? DEALS_BOARD_PATH
          : DEALS_PATH,
    },
    {
      id: STRINGS.SALES_SMALL,
      label: loader ? STRINGS.LOADING : dealDetails?.deal_name || "",
    },
  ];

  // helpers of all internal screen
  const getTimelineLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${dealsEndpoints.getTimeLineFeed}/${params?.id
        }?size=${10}&page=${count}`;
      !isOnScroll && setTimelineScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalTimeline(response?.data?.data?.total_records);
      if (isOnScroll) {
        setTimelineDataList([
          ...timelineDataList,
          ...response?.data?.data?.records,
        ]);
      } else setTimelineDataList(response?.data?.data?.records);
      !isOnScroll && setTimelineScreenLoading(false);
    } catch (error) {
      setTimelineScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const getTaskLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${taskEndpoints.getDealTask}/${params?.id}?step_type=${UpdatedLocation ? 3 : 2
        }&page=${count}`;
      !isOnScroll && setTaskScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotaltasks(response?.data?.data?.total_records);
      if (isOnScroll) {
        setTaskListData([...taskListData, ...response?.data?.data?.records]);
      } else setTaskListData(response?.data?.data?.records);
      !isOnScroll && setTaskScreenLoading(false);
    } catch (error) {
      setTaskScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  const getEmailLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url =
        dealsEndpoints.getDealsEmail +
        `?email=${dealDetails?.contactData?.email || ""}&deal_id=${id || params?.id
        }&page=${count}`;
      !isOnScroll && setEmailScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalEmails(response?.data?.data?.total_records);
      if (isOnScroll) {
        setEmailLogListData([
          ...emailLogListData,
          ...response?.data?.data?.records,
        ]);
      } else setEmailLogListData(response?.data?.data?.records);
      !isOnScroll && setEmailScreenLoading(false);
    } catch (error) {
      setEmailScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  const getMeetingLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${meetingsEndpoint.getDealsCrmlist}?deal_id=${params?.id}&page=${count}`;
      !isOnScroll && setMeetingScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalMeetings(response?.data?.data?.total_records);
      if (isOnScroll) {
        setMeetingListData([
          ...meetingListData,
          ...response?.data?.data?.crmMeetingsRecord,
        ]);
      } else setMeetingListData(response?.data?.data?.crmMeetingsRecord);
      !isOnScroll && setMeetingScreenLoading(false);
    } catch (error) {
      console.log("Error ->>>> ", { error });
      setMeetingScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const getNoteLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${dealsEndpoints.getNotes}/${params?.id}?page=${count}`;
      !isOnScroll && setNoteScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalNotes(response?.data?.data?.total_records);
      if (isOnScroll) {
        setNoteListData([...noteListData, ...response?.data?.data?.records]);
      } else setNoteListData(response?.data?.data?.records);
      !isOnScroll && setNoteScreenLoading(false);
    } catch (error) {
      !isOnScroll && setNoteScreenLoading(false);

      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  // helpers of all internal screen

  const tabData = [
    {
      label: STRINGS.TIMELINE,
      id: STRINGS.TIMELINE_SMALL,
      component: (
        <TimelineScreen
          dealDetails={dealDetails}
          dealId={params?.id}
          dataList={timelineDataList}
          getTimelineLogList={getTimelineLogList}
          screenLoading={timelineScreenLoading}
          totalTimeline={totalTimeline}
        />
      ),
      path: `${location?.pathname}?tabs=timeline`,
    },
    {
      label: STRINGS.CALLS,
      id: STRINGS.CALLS_SMALL,
      component: (
        <TaskScreen
          dealDetails={dealDetails}
          dealId={params?.id}
          getTaskLogList={getTaskLogList}
          screenLoading={taskScreenLoading}
          taskListData={taskListData}
          setTaskListData={setTaskListData}
          totalTasks={totalTasks}
        />
      ),

      path: `${location?.pathname}?tabs=calls`,
    },
    {
      label: STRINGS.EMAILS,
      id: STRINGS.EMAILS_SMALL,
      component: (
        <EmailScreen
          dealDetails={dealDetails}
          dealId={params?.id}
          getEmailLogList={getEmailLogList}
          screenLoading={emailScreenLoading}
          totalEmails={totalEmails}
          emailLogListData={emailLogListData}
        />
      ),
      path: `${location?.pathname}?tabs=emails`,
    },
    {
      label: STRINGS.MEETINGS,
      id: STRINGS.MEETINGS_SMALL,
      component: (
        <MeetingScreen
          dealDetails={dealDetails}
          dealId={params?.id}
          getMeetingLogList={getMeetingLogList}
          meetingListData={meetingListData}
          setMeetingListData={setMeetingListData}
          screenLoading={meetingScreenLoading}
          totalMeetings={totalMeetings}
        />
      ),
      path: `${location?.pathname}?tabs=meetings`,
    },
    {
      label: STRINGS.NOTES,
      id: STRINGS.NOTE_SMALL,
      component: (
        <NoteScreen
          dealDetails={dealDetails}
          dealId={params?.id}
          getNoteLogList={getNoteLogList}
          noteListData={noteListData}
          setNoteListData={setNoteListData}
          screenLoading={noteScreenLoading}
          totalNotes={totalNotes}
        />
      ),
      path: `${location?.pathname}?tabs=note`,
    },
    {
      label: STRINGS.TASKS,
      id: STRINGS.TASKS_SMALL,
      component: (
        <TaskScreen
          dealDetails={dealDetails}
          dealId={params?.id}
          getTaskLogList={getTaskLogList}
          screenLoading={taskScreenLoading}
          taskListData={taskListData}
          setTaskListData={setTaskListData}
          totalTasks={totalTasks}
        />
      ),
      path: `${location?.pathname}?tabs=tasks`,
    },

    {
      label: STRINGS.ATTACHMENTS,
      id: STRINGS.ATTACHMENTS_SMALL,
      component: (
        <AttachmentScreen dealDetails={dealDetails} dealId={params?.id} />
      ),

      path: `${location?.pathname}?tabs=attachments`,
    },
  ];

  const getDealDetails = async () => {
    try {
      let id = params?.id;
      setLoader(true);
      const response = await getRequest(
        navigate,
        `${dealsEndpoints?.getDealDetails}/${id}`
      );
      setLoader(false);
      setDealDetails(response?.data?.data);
      setCloseDate(response?.data?.data?.closed_date || null);
      setInputData(response?.data?.data || {});
      setInputDataId(response?.data?.data);

      // storing associated contact detaild in table listing.
      const dealDetails = response?.data?.data;
      (async () => {
        const payload = {
          record_ids: dealDetails?.associated_contact,
        };
        const response = await postRequest(
          navigate,
          crmEndpoints.getAssociateContact,
          payload
        );
        setAssociatedContact(response?.data?.data?.records);
        if (dealDetails?.associated_contact?.length) {
          setInputData({
            ...dealDetails,
            associated_contact_name: response?.data?.data?.records[0]
              ?.first_name?.length
              ? `${response?.data?.data?.records[0]?.first_name} ${response?.data?.data?.records[0]?.last_name || ""
              }`
              : `${response?.data?.data?.records[0]?.email}`,
          });
          setInputDataId({
            ...dealDetails,
            associated_contact_name: response?.data?.data?.records[0]
              ?.first_name?.length
              ? `${response?.data?.data?.records[0]?.first_name} ${response?.data?.data?.records[0]?.last_name || ""
              }`
              : `${response?.data?.data?.records[0]?.email}`,
          });
        }
      })();

      // storing pipeline details
      const arr = replaceKeyInArrayOfObj(
        response?.data?.data?.stages,
        "name",
        "label"
      );
      const updatedArray = replaceKeyInArrayOfObj(arr, "_id", "id");
      if (updatedArray?.length) {
        const filteredArr = updatedArray?.filter(
          (item) => item?.id === response?.data?.data?.stage_id
        );
        setItems(updatedArray);
        setPipeLineStageId([filteredArr?.[0]?.id]);
        setPipeLineStageValue([filteredArr?.[0]?.label]);
      } else {
        const arr = replaceKeyInArrayOfObj(INITIALS_STAGES, "name", "label");
        const updatedArray = replaceKeyInArrayOfObj(arr, "_id", "id");
        setItems(updatedArray);
        setPipeLineStageId([updatedArray?.[0]?.id]);
        setPipeLineStageValue([updatedArray?.[0]?.label]);
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const getFieldsOrder = async () => {
    try {
      const response = await getRequest(navigate, formsEndpoints.getFormsField);
      let columns = [];
      let required_obj = {};
      response?.data?.data?.Deals?.sort(
        (obj1, obj2) => obj1.order - obj2.order
      )?.forEach((element) => {
        columns.push(element?.field_id);
        required_obj = {
          ...required_obj,
          ...{ [element.field_id]: element?.required },
        };
      });
      setRequiredOrder(required_obj);
      setFieldsOrder(columns || []);
    } catch (error) {
      console.log("Error------->>>>>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const validationHandler = (inputData) => {
    // getting all the required fields id and storing it into array.
    let required_fields = [];
    let error_arr = [];
    let input_error = { ...inputError };

    Object.keys(requiredOrder)?.forEach((element, index) => {
      if (requiredOrder?.[element]) {
        required_fields.push(element);
      }
    });

    // loop on required ids for checking validation according to field_type
    required_fields?.forEach((element, index) => {
      if (element !== "deal_name" || element !== "pipeline_name") {
        let validate = nameValidation(inputData?.[element]);

        if (validate?.isValid) {
          error_arr?.filter((item) => item !== element);
          input_error = { ...input_error, [element]: "" };
        } else {
          error_arr = [...error_arr, element];
          input_error = { ...input_error, [element]: validate?.message };
        }
      }
    });

    // storing the error into state for displaying error for deal name and pipeline name
    const validatedDealName = nameValidation(inputData?.deal_name);
    // validate deal_name
    if (validatedDealName?.isValid) {
      error_arr?.filter((item) => item !== "deal_name");
      input_error = { ...input_error, deal_name: "" };
    } else {
      error_arr = [...error_arr, "deal_name"];
      input_error = { ...input_error, deal_name: validatedDealName?.message };
    }
    setInputError({ ...input_error });

    // return value according to error key check in error_arr.
    if (error_arr?.length) {
      return false;
    } else {
      return true;
    }
  };

  const handleContactEditApi = async () => {
    delete inputData?.stages;
    delete inputData?.contactData;
    delete inputData?.probability;
    delete inputData?.associated_contact_name;
    delete inputData?.associated_company_name;
    delete inputData?.pipeline_name;
    delete inputData?.stage_name;
    delete inputData?.companyData;
    delete inputData?.company_id;
    delete inputData?.user_id;
    const payload = {
      query: {
        ...inputData,
        // closed_date: closeDate || "",
      },
    };

    // adding associated company in payload if exists.
    if (inputData?.associated_company?.length && inputData?.associated_company)
      payload.query.associated_company = [inputData?.associated_company];

    // adding closed date in payload if exists.
    if (closeDate) payload.query.closed_date = closeDate;

    if (inputDataId?.deal_owner_name?.length) {
      payload.query.deal_owner_name = inputDataId?.deal_owner_name;
    }
    if (inputData?.associated_contact?.length) {
      let isArray = Array?.isArray(inputData?.associated_contact);

      payload.query.associated_contact = isArray
        ? inputData?.associated_contact
        : [inputData?.associated_contact];
    }

    if (validationHandler(inputData)) {
      setLoading(true);
      try {
        setInputError({});
        const URL = crmEndpoints?.editDealsDetails;
        const response = await putRequest(navigate, URL, payload);

        setLoading(false);
        setData(false);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        let id = params?.id;
        const response2 = await getRequest(
          navigate,
          `${dealsEndpoints.getDealDetails}/${id}`
        );
        setLoader(false);

        setDealDetails(response2?.data?.data);
        setInputData(response2?.data?.data || {});
        setInputDataId(response2?.data?.data);
      } catch (error) {
        // console.log(error);
        setLoading(false);

        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    }
  };
  console.log("InputData______ ", inputData, inputDataId);
  const handleResetContDetails = () => {
    setInputError({});
    setInputData({
      ...dealDetails,
      associated_contact_name: associatedContact[0]?.first_name?.length
        ? `${associatedContact[0]?.first_name} ${associatedContact[0]?.last_name}`
        : `${associatedContact[0]?.email}`,
    });
    setInputDataId({
      ...dealDetails,
      associated_contact_name: associatedContact[0]?.first_name?.length
        ? `${associatedContact[0]?.first_name} ${associatedContact[0]?.last_name}`
        : `${associatedContact[0]?.email}`,
    });
    setData(false);
    setCloseDate(dealDetails?.closed_date || null);
    const arr = replaceKeyInArrayOfObj(dealDetails?.stages, "name", "label");
    const updatedArray = replaceKeyInArrayOfObj(arr, "_id", "id");
    if (updatedArray?.length) {
      const filteredArr = updatedArray?.filter(
        (item) => item?.label === dealDetails?.stage_name
      );
      setItems(updatedArray);
      setPipeLineStageId([filteredArr?.[0]?.id]);
      setPipeLineStageValue([filteredArr?.[0]?.label]);
    } else {
      const arr = replaceKeyInArrayOfObj(INITIALS_STAGES, "name", "label");
      const updatedArray = replaceKeyInArrayOfObj(arr, "_id", "id");
      setItems(updatedArray);
      setPipeLineStageId([updatedArray?.[0]?.id]);
      setPipeLineStageValue([updatedArray?.[0]?.label]);
    }
  };

  const handleContactNavigate = (item) => {
    navigate(`${CRM_PATH}/${item}?tabs=timeline`);
  };

  const getItemsList = (filter) => {
    if (filter?.id === STRINGS.DEAL_OWNER_SMALL) {
      let deal_ownerlist = tenantUsersListSelector?.map((item) => {
        return {
          id: item?._id,
          label: `${item?.first_name} ${item?.last_name}`,
        };
      });
      return deal_ownerlist;
    } else if (filter?.id === STRINGS.ASSOCIATED_CONTACT_SMALL) {
      let associated_contact = associatedContact?.map((element) => {
        return {
          id: element?._id,
          label:
            element?.first_name?.length || element?.last_name?.length
              ? `${element?.first_name || ""} ${element?.last_name || ""}`
              : element?.email?.length
                ? `${element?.email}`
                : null,
        };
      });
      return associated_contact;
    } else if (filter?.id === STRINGS.ASSOCIATED_COMPANY_SMALL) {
      let company_list = associatedCompany?.map((item) => {
        return {
          label: item?.company_name,
          id: item?._id,
        };
      });

      return company_list;
    } else {
      return replaceKeyInArrayOfObj(filter?.value, "labelValue", "label") || [];
    }
  };

  // associated contact api handler
  const searchHandler = async (event) => {
    const payload = {
      search: event?.target?.value || "",
    };

    if (params?.id?.length) {
      payload.record_ids = [params?.id];
    } else {
      payload.record_ids = [];
    }
    const response = await postRequest(
      navigate,
      crmEndpoints.getAssociateContact,
      payload
    );

    setAssociatedContact(response?.data?.data?.records);
  };

  // associated company api handler
  const associatedCompanyApiHandler = async (
    event = "",
    inputData = {},
    inputDataId = {}
  ) => {
    const payload = {
      search: event?.target?.value || "",
    };

    if (dealDetails?.associated_company?.length) {
      payload.record_ids = [...dealDetails?.associated_company];
    } else {
      payload.record_ids = [];
    }

    const response = await postRequest(
      navigate,
      dealsEndpoints.getAssociatedCompany,
      payload
    );

    const associatedFilter = response?.data?.data?.records?.filter((item) =>
      dealDetails?.associated_company?.includes(item?._id)
    );

    setInputData({
      ...inputData,
      ...dealDetails,
      associated_company_name: associatedFilter?.[0]?.company_name,
      [STRINGS.ASSOCIATED_COMPANY_SMALL]: associatedFilter?.[0]?._id,
    });
    setInputDataId({
      ...inputDataId,
      ...dealDetails,
      associated_company_name: associatedFilter?.[0]?.company_name,
      [STRINGS.ASSOCIATED_COMPANY_SMALL]: `${associatedFilter?.[0]?.company_name}`,
    });

    setAssociatedCompany(response?.data?.data?.records);
  };

  const type_id_components = (prop) => {
    switch (prop?.field_type[0]) {
      case STRINGS.DATE_PICKER:
        return (
          <CustomDatePicker
            size={"sm36"}
            value={inputData[prop?.id] || new Date()}
            onChange={(dateValue) => {
              setInputData({ ...inputData, [prop?.id]: dateValue });
            }}
          />
        );

      case STRINGS.NUMBER:
        return (
          <InputFieldWoBorder
            size={"sm36"}
            type={"number"}
            onChange={(e) => {
              setInputData({
                ...(inputData || {}),
                [prop?.id]: e.target.value,
              });
            }}
            LeftIcon={
              prop?.id === STRINGS.DEAL_AMOUNT_SMALL
                ? ICON_LABELS.ATTACH_MONEY
                : ""
            }
            value={inputData[prop?.id] || ""}
            variant={
              !Object.keys(dealDetails || {}).length
                ? "disabled"
                : inputError[prop?.id]?.length
                  ? "error"
                  : "default"
            }
            placeholder={`Enter a ${prop?.property_name}`}
            errormessage={inputError[prop?.id]}
          />
        );

      case STRINGS.MULTI_LINE_TEXT_INPUT:
        return (
          <TextArea
            rows={"5"}
            onChange={(e) => {
              setInputData({
                ...(inputData || {}),
                [prop?.id]: e.target.value,
              });
            }}
            value={inputData[prop?.id] || ""}
          />
        );

      case STRINGS.RICH_TEXT_EDITOR:
        return (
          <>
            <div
              className={
                inputError[prop?.id]
                  ? classes.htmlEditorError
                  : classes.htmlEditor
              }
            >
              <HtmlEditor
                editorHeight={"12vh"}
                previosData={inputData[prop?.id] || ""}
                onInput={() => {
                  setInputData({
                    ...inputData,
                    [prop?.id]: document?.getElementById(STRINGS.EDITOR_ID)
                      ?.innerHTML,
                  });
                  setData(true);
                }}
                isAITagNotVisible
                isAddTagNotVisible
                isMeetingTagNotVisible
                isFocusNotRequired
              />
            </div>{" "}
            {inputError[prop?.id] ? (
              <TypographyInline
                label={inputError[prop?.id] || ""}
                color={BRAND.PRIMARY}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
              />
            ) : null}
          </>
        );

      case STRINGS.SINGLE_SELECT:
        return (
          <AutoCompleteWoBorder
            dropdownId="preview_dropdown"
            items={getItemsList(prop)}
            size={"sm36"}
            variant={
              prop?.id === STRINGS.DEAL_OWNER_SMALL ||
                (prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL &&
                  userDetails?.role === "simple-user" &&
                  !Object.keys(dealDetails || {}).length)
                ? "disabled"
                : "default"
            }
            valueState={
              prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                prop?.id === STRINGS.DEAL_OWNER_SMALL ||
                prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
                ? [inputDataId?.[`${prop?.id}_name`]]
                : [inputData?.[prop?.id]]
            }
            setValueState={(data) => {
              prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                prop?.id === STRINGS.DEAL_OWNER_SMALL ||
                prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
                ? setInputDataId({
                  ...inputDataId,
                  [`${prop?.id}_name`]: data[0],
                })
                : setInputData({
                  ...(inputData || {}),
                  [prop?.id]: data[0],
                });
              setData(true);
            }}
            idState={
              prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                prop?.id === STRINGS.DEAL_OWNER_SMALL ||
                prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
                ? [inputDataId?.[`${prop?.id}_name`]]
                : [inputData?.[prop?.id]]
            }
            setIdState={(data) => {
              prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                prop?.id === STRINGS.DEAL_OWNER_SMALL ||
                prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
                ? setInputData({
                  ...(inputData || {}),
                  [prop?.id]: data[0],
                })
                : setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                });
            }}
            isSearchEnable={
              prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
              prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
            }
            handleSearch={(event) => {
              if (prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL)
                searchHandler(event, prop);
              else if (prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL)
                associatedCompanyApiHandler(event);
            }}
          />
        );

      case STRINGS.MULTIPLE_SELECT:
        return (
          <AutoCompleteWoBorder
            variant={
              !Object.keys(dealDetails || {}).length ? "disabled" : "default"
            }
            dropdownId="preview_dropdown"
            items={replaceKeyInArrayOfObj(
              prop?.value || [],
              "labelValue",
              "label"
            )}
            size={"sm36"}
            multiple
            valueState={inputData[prop?.id]}
            setValueState={(data) => {
              setInputData({
                ...(inputData || {}),
                [prop?.id]: data,
              });
              setData(true);
            }}
            idState={inputDataId[prop?.id]}
            setIdState={(data) => {
              setInputDataId({
                ...inputDataId,
                [prop?.id]: data,
              });
            }}
          />
        );

      default:
        return (
          <InputFieldWoBorder
            size={"sm36"}
            placeholder={`Enter a ${prop?.property_name}`}
            value={inputData[prop?.id] || ""}
            onChange={(e) => {
              setInputData({
                ...(inputData || {}),
                [prop?.id]: e.target.value,
              });
            }}
            variant={
              !Object.keys(dealDetails || {}).length
                ? "disabled"
                : inputError[prop?.id]?.length
                  ? "error"
                  : "default"
            }
            errormessage={inputError[prop?.id]}
          />
        );
    }
  };

  const detailContainerItems = [
    {
      id: STRINGS.EMPLOYEES_SMALL,
      label: STRINGS.EMPLOYEES,
      value: dealDetails?.contactData?.[0]?.number_of_employees?.toString(),
    },
    {
      id: STRINGS.FOUNDED_SMALL,
      label: STRINGS.FOUNDED,
      value: dealDetails?.contactData?.[0]?.founded?.toString(),
    },
    {
      id: STRINGS.REVENUE_SMALL,
      label: STRINGS.REVENUE,

      value: `${dealDetails?.contactData?.[0]?.annual_revenue?.length
        ? `$${dealDetails?.contactData?.[0]?.annual_revenue?.toString()} ${STRINGS.ANNUAL
        }`
        : ""
        }`,
    },
    {
      id: STRINGS.LOCATION_SMALL,
      label: STRINGS.LOCATION,
      value: dealDetails?.contactData?.[0]?.company_address?.toString(),
    },
  ];

  const postAction = (tab) => {
    let currentTab = location.search.replace("?tabs=", "");
    if (tab === currentTab || currentTab === "timeline") {
      switch (currentTab) {
        case "emails":
          return getEmailLogList();
        case "calls":
          return getTaskLogList();
        case "meetings":
          return getMeetingLogList();
        case "note":
          return getNoteLogList();
        case "tasks":
          return getTaskLogList();
        default:
          getTimelineLogList();
          break;
      }
    }
  };

  const ICONS = [
    {
      id: 1,
      icon: ICON_LABELS.EMAIL,
      variant: "orange",
      iconName: "Emails",
      onClick: () => {
        setOpenEmailSendModal(true);
      },
    },
    {
      id: 2,
      icon: ICON_LABELS.CALL,
      variant: "green",
      iconName: "Calls",
      onClick: () => {
        setOpenTask(true);
        setType("call");
      },
    },
    {
      id: 3,
      icon: ICON_LABELS.TASK,
      variant: "yellow",
      iconName: "Tasks",
      onClick: () => {
        setOpenTask(true);
        setType("tasks");
      },
    },
    {
      id: 4,
      icon: ICON_LABELS.STICKY_NOTE,
      variant: "blue",
      iconName: "Notes",
      onClick: () => {
        setCreateNoteFlag(true);
      },
    },
    {
      id: 5,
      icon: ICON_LABELS.EVENT_NOTE,
      variant: "purple",
      iconName: "Meetings",
      onClick: () => {
        setCreateFlag(true);
      },
    },
  ];

  useEffect(() => {
    setLoader(true);
    getFieldsOrder();

    (async () => {
      await getDealDetails();
    })();
  }, [navigate]);

  useEffect(() => {
    if (dealDetails?.deal_name?.length) {
      (async () => {
        const payload = {
          record_ids: dealDetails?.associated_contact,
        };
        // get associate contact api
        const response = await postRequest(
          navigate,
          crmEndpoints.getAssociateContact,
          payload
        );
        setAssociatedContact(response?.data?.data?.records);
        if (dealDetails?.associated_contact?.length) {
          setInputData({
            ...dealDetails,
            associated_contact_name: response?.data?.data?.records[0]
              ?.first_name?.length
              ? `${response?.data?.data?.records[0]?.first_name} ${response?.data?.data?.records[0]?.last_name || ""
              }`
              : `${response?.data?.data?.records[0]?.email}`,
          });
          setInputDataId({
            ...dealDetails,
            associated_contact_name: response?.data?.data?.records[0]
              ?.first_name?.length
              ? `${response?.data?.data?.records[0]?.first_name} ${response?.data?.data?.records[0]?.last_name || ""
              }`
              : `${response?.data?.data?.records[0]?.email}`,
          });
        }

        // get company api handler
        associatedCompanyApiHandler(
          "",
          {
            ...dealDetails,
            associated_contact_name: response?.data?.data?.records[0]
              ?.first_name?.length
              ? `${response?.data?.data?.records[0]?.first_name} ${response?.data?.data?.records[0]?.last_name || ""
              }`
              : `${response?.data?.data?.records[0]?.email}`,
          },
          {
            ...dealDetails,
            associated_contact_name: response?.data?.data?.records[0]
              ?.first_name?.length
              ? `${response?.data?.data?.records[0]?.first_name} ${response?.data?.data?.records[0]?.last_name || ""
              }`
              : `${response?.data?.data?.records[0]?.email}`,
          }
        );
      })();
    }
  }, [dealDetails]);

  useEffect(() => {
    const UpdatedLocation = location?.search?.split("?tabs=");
    if (tabData.map((tab) => tab.id).includes(UpdatedLocation[1])) {
      setLocationTab(UpdatedLocation[1]);
    } else {
      // invalid tab
      navigate(DEFAULT_PROTECTED_PATH);
    }
  }, [location]);

  return (
    <div>
      <CustomHeader items={headerItems} />

      {/* Layout Container contains all three container */}
      <div className={classes.detailLayoutContainer}>
        {/* Contact Detail Container */}
        <div id="modal_body" className={classes.dealDetailLeftContainer}>
          <div
            id="modal_body"
            className={classes.contactDetailLeftSubContainer}
          >
            <div className={classes.dealDetailMainContainer}>
              <div className={classes.contactDetailNameDesig}>
                {/* deal name */}
                {loader ? (
                  Array.from({ length: 1 })?.map(() => (
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"100%"}
                      skeletonHeight={"30px"}
                      stackWidth={"60%"}
                    />
                  ))
                ) : (
                  <>
                    <CustomTooltip
                      title={
                        dealDetails?.deal_name?.length > 20
                          ? dealDetails?.deal_name
                          : ""
                      }
                    >
                      <div className={classes.dealNameContainer}>
                        <TypographyInline
                          color={GREY.PRIMARY}
                          fontWeight={fontWeight.BOLD}
                          size={"xl"}
                          label={
                            textEllipsesHandler(dealDetails?.deal_name, 20) ||
                            "--"
                          }
                        />
                      </div>
                    </CustomTooltip>
                  </>
                )}
                {/* deal amount */}
                {loader ? (
                  Array.from({ length: 1 })?.map(() => (
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"100%"}
                      skeletonHeight={"20px"}
                      stackWidth={"60%"}
                    />
                  ))
                ) : dealDetails?.deal_amount?.length ? (
                  <>
                    <div
                      className={
                        dealDetails?.deal_amount
                          ? `${classes.dealNameContainer} ${classes.DealPaddingTop}`
                          : `${classes.dealNameContainer}`
                      }
                    >
                      <IconComponent
                        color={GREY.TERTIARY}
                        fontSize={fontSize.LG}
                        iconLabel={ICON_LABELS.MONETIZATION_ON}
                      />
                      <div className={classes.amountLabelValueContianer}>
                        <TypographyInline
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.SEMIBOLD}
                          size={"sm"}
                          label={STRINGS.AMOUNT_COLON}
                        />
                        <TypographyInline
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                          size={"sm"}
                          label={`$${dealDetails?.deal_amount}`}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.flexColumnContainerHead}>
                {loader ? (
                  Array.from({ length: 3 })?.map(() => (
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"100%"}
                      skeletonHeight={"64px"}
                      stackWidth={"80%"}
                    />
                  ))
                ) : (
                  <>
                    {/* deal close date */}
                    <div className={classes.flexColumnContainer}>
                      <div className={classes.amountLabelValueContianer}>
                        <IconComponent
                          color={GREY.TERTIARY}
                          fontSize={fontSize.MD}
                          iconLabel={ICON_LABELS.INSERT_INVITATION}
                        />
                        <TypographyInline
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                          size={"sm"}
                          label={STRINGS.CLOSE_DATE}
                        />
                      </div>
                      <div className={classes.amountLabelValueContianer}>
                        <CustomDatePicker
                          disabled={!Object.keys(dealDetails || {}).length}
                          disablePast={true}
                          size={"sm36"}
                          value={closeDate}
                          onChange={(dateValue) => {
                            setCloseDate(dateValue);
                            setData(true);
                          }}
                        />
                      </div>
                    </div>

                    {/* deal stage */}
                    <div className={classes.flexColumnContainer}>
                      <div className={classes.amountLabelValueContianer}>
                        <IconComponent
                          color={GREY.TERTIARY}
                          fontSize={fontSize.MD}
                          iconLabel={ICON_LABELS.CALL_TO_ACTION}
                        />
                        <TypographyInline
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                          size={"sm"}
                          label={STRINGS.STAGE}
                        />
                      </div>
                      <div className={classes.amountLabelValueContianer}>
                        <AutoCompleteWoBorder
                          variant={
                            !Object.keys(dealDetails || {}).length
                              ? "disabled"
                              : "default"
                          }
                          items={items}
                          size={"sm36"}
                          valueState={pipelineStageValue}
                          setValueState={setPipeLineStageValue}
                          idState={pipelineStageId}
                          setIdState={setPipeLineStageId}
                          onChange={(data) => {
                            setData(true);
                            setInputData({
                              ...inputData,
                              stage_id: data?.id[0],
                              stage_name: data?.label[0],
                            });
                          }}
                        />
                      </div>
                    </div>
                    {/* pipeline name */}
                    <div className={classes.flexColumnContainer}>
                      <div className={classes.amountLabelValueContianer}>
                        <TypographyInline
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                          size={"sm"}
                          label={STRINGS.PIPELINE_NAME}
                        />
                      </div>
                      <div className={classes.amountLabelValueContianer}>
                        <TypographyInline
                          color={GREY.PRIMARY}
                          fontWeight={fontWeight.MEDIUM}
                          size={"sm"}
                          label={dealDetails?.pipeline_name || ""}
                          isNoWrap
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* operation icons button */}
              <div className={classes.aboutContactHead}>
                {loader
                  ? Array.from({ length: 5 })?.map(() => (
                    <SkeletonLoader
                      type="circular"
                      skeletonWidth={46}
                      skeletonHeight={46}
                      stackWidth={46}
                      stackHeight={46}
                    />
                  ))
                  : Object.keys(dealDetails || {}).length
                    ? ICONS?.map((i) => (
                      <div>
                        <IconOutlinedHover
                          variant={i?.variant}
                          iconLabel={i?.icon}
                          color={i?.variant}
                          iconName={i?.iconName}
                          size="huge"
                          onClick={i?.onClick}
                        />
                      </div>
                    ))
                    : null}
              </div>
            </div>

            {/* about deal view properties section */}
            <div className={classes.aboutContact}>
              {loader ? (
                Array.from({ length: 1 })?.map(() => (
                  <div>
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"30%"}
                      skeletonHeight={"20px"}
                      stackWidth={"100%"}
                    />
                  </div>
                ))
              ) : (
                <div className={classes.DisplayFlexAbout}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    size={"sm"}
                    label={STRINGS.ABOUT_DEAL}
                    isNoWrap
                  />
                </div>
              )}
            </div>

            {loader || getPropertiesListSelector?.loading ? (
              <div className={classes.addContactFormLoaderContainer}>
                {Array.from({ length: 10 })?.map((index) => {
                  return (
                    <div key={`addContact${index}`}>
                      <SkeletonLoader
                        type="rounded"
                        skeletonWidth={"100%"}
                        skeletonHeight={"42px"}
                        stackWidth={"100%"}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              // Mapping our properties according to field order selected in all properties.
              <div className={classes.aboutContact}>
                <form
                  className={classes.inputForm}
                  onChange={() => setData(true)}
                  onSubmit={handleContactEditApi}
                >
                  {fieldsOrder?.map((field) => {
                    return (
                      <div className={classes.inputLabelField}>
                        <div className={classes.labelButtonContainer}>
                          <TypographyInline
                            label={
                              getPropertiesListSelector?.data
                                ?.properties_key_value?.[field]
                                ?.property_name || ""
                            }
                            color={GREY.SECONDARY}
                            size={"sm"}
                            fontWeight={fontWeight.MEDIUM}
                            isRequired={requiredOrder?.[field]}
                          />
                        </div>
                        {getPropertiesListSelector?.data?.hits?.length &&
                          type_id_components(
                            getPropertiesListSelector?.data
                              ?.properties_key_value?.[field]
                          )}
                      </div>
                    );
                  })}
                </form>
              </div>
            )}
          </div>

          {/* reset or save button for any change in deals properties. */}
          {data ? (
            <div className={classes.aboutFooter}>
              <Button
                label={STRINGS.CANCEL}
                size={"sm36"}
                variant={"ghost"}
                onClick={() => {
                  handleResetContDetails();
                }}
              />

              <Button
                disabled={!Object.keys(dealDetails || {}).length}
                label={STRINGS.SAVE}
                isLoading={loading}
                size={"sm36"}
                variant={"primary"}
                onClick={() => {
                  handleContactEditApi();
                }}
              />
            </div>
          ) : null}
        </div>

        {/* Contact Activities Container */}
        <div className={classes.dealDetailContainerHead}>
          <div className={classes.contactDetailContainerSubHead}>
            <Tabs
              items={tabData}
              selected={`${location?.pathname}${location?.search}`}
              selectedTabFunc={() => { }}
            />
          </div>
          <div id="modal_body" className={classes.contactDetailContainer}>
            {tabData?.map((item, index) => {
              return item?.id === locationTab && item?.component;
            })}
          </div>
        </div>

        {/* Contact Companies Accordion Container */}
        <div id="modal_body" className={classes.dealDetailRightContainer}>
          {/* Company Details Accordion */}
          <Accordion
            className={classes.accrodionRoot}
            onChange={() => setCompanyOpen(!companyOpen)}
            expanded={companyOpen}
          >
            <AccordionSummary
              className={classes.accordionSummary}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={
                  companyOpen
                    ? classes.summaryContainerOpen
                    : classes.summaryContainer
                }
              >
                <TypographyInline
                  label={STRINGS.COMPANIES}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.SECONDARY}
                />
                <div className={classes.summaryRightSection}>
                  <div>
                    <Button
                      size={"sm36"}
                      variant={"text"}
                      label={
                        dealDetails?.companyData?.length
                          ? STRINGS.UPDATE
                          : STRINGS.ADD
                      }
                      LeftIcon={ICON_LABELS.ADD}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenAddCompany(true);
                      }}
                      disabled={!Object.keys(dealDetails || {}).length}
                    />
                  </div>
                  <div className={classes.verticalLine} />
                  <div className={classes.accordionIcon}>
                    <IconComponent
                      color={GREY.TERTIARY}
                      fontSize={fontSize.DXS}
                      iconLabel={
                        companyOpen
                          ? ICON_LABELS.ACTION_ARROW
                          : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {dealDetails?.companyData?.length ? (
                // {/* companies body main container */}
                <div className={classes.companyBodyContainer}>
                  {/* company detail container */}
                  <div className={classes.companyDetailMainContainer}>
                    <AvatarImage
                      size={"extraLarge"}
                      src={
                        createCompanyURL(
                          dealDetails?.companyData[0]?.company_website
                        ) || DUMMY_COMPANY2
                      }
                      onError={(e) => (e.target.src = DUMMY_COMPANY2)}
                    />
                    <div className={classes.companyDetailsContainer}>
                      <CustomTooltip
                        title={
                          dealDetails?.companyData[0]?.company_name?.length >=
                            20
                            ? dealDetails?.companyData[0]?.company_name
                            : ""
                        }
                      >
                        <TypographyInline
                          color={GREY.PRIMARY}
                          size={"lg"}
                          fontWeight={fontWeight.SEMIBOLD}
                          label={textEllipsesHandler(
                            dealDetails?.companyData[0]?.company_name || "",
                            20
                          )}
                          isEllipses
                        />
                      </CustomTooltip>
                      <TypographyInline
                        color={GREY.SECONDARY}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                        label={dealDetails?.companyData[0]?.industry || ""}
                        isEllipses
                      />
                    </div>
                  </div>
                  {/* company social links  */}
                  <div className={classes.socialLinksContainer}>
                    {dealDetails?.companyData[0]?.company_facebook_url && (
                      <div>
                        <SocialButton
                          LeftIcon={ICON_LABELS.FACEBOOK}
                          size={"sm36"}
                          variant={"ghost"}
                          href={
                            dealDetails?.companyData[0]?.company_facebook_url
                          }
                        />
                      </div>
                    )}

                    {dealDetails?.companyData[0]?.company_linkedin_url && (
                      <div>
                        <SocialButton
                          LeftIcon={ICON_LABELS.LINKEDIN}
                          size={"sm36"}
                          variant={"ghost"}
                          href={
                            dealDetails?.companyData[0]?.company_linkedin_url
                          }
                        />
                      </div>
                    )}
                    {dealDetails?.companyData[0]?.company_twitter_url && (
                      <div>
                        <SocialButton
                          LeftIcon={ICON_LABELS.TWITTER}
                          size={"sm36"}
                          variant={"ghost"}
                          href={
                            dealDetails?.companyData[0]?.company_twitter_url
                          }
                        />
                      </div>
                    )}
                    {dealDetails?.companyData[0]?.company_website && (
                      <div>
                        <Anchor
                          href={dealDetails?.companyData[0]?.company_website}
                          target="_blank"
                        >
                          <IconButton
                            LeftIcon={ICON_LABELS.OPEN_IN_NEW}
                            size={"sm36"}
                            variant={"ghost"}
                          />
                        </Anchor>
                      </div>
                    )}
                  </div>

                  {/* Company Description */}
                  <div className={classes.companyDescriptionLabel}>
                    <TypographyInline
                      color={GREY.SECONDARY}
                      label={dealDetails?.companyData[0]?.seo_description || ""}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                    />
                  </div>

                  {/* Company Details */}
                  <div className={classes.companyDetailMainContainer}>
                    {detailContainerItems?.map((item, index) => {
                      return item?.value?.length ? (
                        <div
                          className={classes.companyDetailContainer}
                          key={`${item?.id}${index}`}
                        >
                          <div>
                            <TypographyInline
                              color={GREY.SECONDARY}
                              label={item?.label}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                          </div>
                          <div>
                            <TypographyInline
                              color={GREY.PRIMARY}
                              label={item?.value}
                              size={"sm"}
                              fontWeight={fontWeight.MEDIUM}
                            />
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : (
                //  when no company exists.
                <div className={classes.noComanyFoundContainer}>
                  <AvatarOutlined size={"extraLarge"}>
                    <Image src={DUMMY_COMPANY2} alt="" />
                  </AvatarOutlined>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={STRINGS.NO_COMPANY_ADDED}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {/* Contact Detail Accordion */}
          <Accordion
            className={classes.accrodionRoot}
            onChange={() => setContactOpen(!contactOpen)}
            expanded={contactOpen}
          >
            <AccordionSummary className={classes.accordionSummary}>
              <div
                className={
                  contactOpen
                    ? classes.summaryContainerOpen
                    : classes.summaryContainer
                }
              >
                <TypographyInline
                  label={STRINGS.CONTACTS}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.SECONDARY}
                />
                <div className={classes.summaryRightSection}>
                  <div>
                    <Button
                      size={"sm36"}
                      variant={"text"}
                      label={STRINGS.ADD}
                      LeftIcon={ICON_LABELS.ADD}
                      disabled={dealDetails?.contactData?.length}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddContactOpen(true);
                      }}
                    />
                  </div>
                  <div className={classes.verticalLine} />
                  <div className={classes.accordionIcon}>
                    <IconComponent
                      color={GREY.TERTIARY}
                      fontSize={fontSize.DXS}
                      iconLabel={
                        contactOpen
                          ? ICON_LABELS.ACTION_ARROW
                          : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetail}>
              {dealDetails?.contactData?.[0]?.email?.length ? (
                <div
                  key={dealDetails?.contactData?.[0]?.email}
                  className={classes.flexEachContactTopMain}
                >
                  <div
                    className={
                      userDetails?._id === dealDetails?.contactData?.[0]?._id
                        ? classes.flexEachContactTop
                        : classes.flexEachContactTop
                    }
                    onClick={() =>
                      dealDetails?.associated_contact[0] ||
                        userDetails?.role === "admin-user"
                        ? handleContactNavigate(
                          dealDetails?.associated_contact[0]
                        )
                        : ""
                    }
                  >
                    <AvatarName
                      name={` ${generateNameFromEmail(
                        dealDetails?.contactData?.[0]?.email,
                        dealDetails?.contactData?.[0]?.first_name
                          ? dealDetails?.contactData?.[0]?.first_name +
                          " " +
                          dealDetails?.contactData?.[0]?.last_name
                          : ""
                      )}`}
                      size={"medium"}
                    />
                    <div>
                      <TypographyInline
                        color={GREY.PRIMARY}
                        label={`${dealDetails?.contactData?.[0]?.first_name || ""
                          } ${dealDetails?.contactData?.[0]?.last_name || ""}`}
                        size={"sm"}
                        fontWeight={fontWeight.SEMIBOLD}
                      />
                    </div>
                  </div>
                  <div className={classes.flexEachContact}>
                    {/* {dealDetails?.contactData?.[0]?.email ? ( */}
                    <div id="modal_body" className={classes.FlexHeadWithGap}>
                      <IconComponent
                        color={GREY.TERTIARY}
                        fontSize={fontSize.DXS}
                        iconLabel={ICON_LABELS.EMAIL}
                      />
                      <TypographyInline
                        color={GREY.PRIMARY}
                        label={`${STRINGS.EMAIL}:`}
                        size={"sm"}
                        fontWeight={fontWeight.SEMIBOLD}
                      />

                      <CustomTooltip
                        title={
                          dealDetails?.contactData?.[0]?.email?.length > 20
                            ? dealDetails?.contactData?.[0]?.email
                            : ""
                        }
                      >
                        <div className={classes.dealNameContainer}>
                          <TypographyInline
                            color={GREY.TERTIARY}
                            label={textEllipsesHandler(
                              dealDetails?.contactData?.[0]?.email,
                              20
                            )}
                            size={"sm"}
                            fontWeight={fontWeight.SEMIBOLD}
                          />
                        </div>
                      </CustomTooltip>
                    </div>
                    {/* ) : null} */}
                    <div id="modal_body" className={classes.FlexHeadWithGap}>
                      <IconComponent
                        color={GREY.TERTIARY}
                        fontSize={fontSize.DXS}
                        iconLabel={ICON_LABELS.CALL}
                      />
                      <TypographyInline
                        color={GREY.PRIMARY}
                        label={`${STRINGS.PHONE_NUMBER}:`}
                        size={"sm"}
                        fontWeight={fontWeight.SEMIBOLD}
                      />
                      <TypographyInline
                        color={GREY.TERTIARY}
                        label={
                          dealDetails?.contactData?.[0]
                            ?.corporate_phone_number || "--"
                        }
                        size={"sm"}
                        fontWeight={fontWeight.SEMIBOLD}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                //  when no contact exists.
                <div className={classes.noComanyFoundContainer}>
                  <AvatarOutlined size={"extraLarge"}>
                    <Image src={DUMMY_CONTACT2} alt="" />
                  </AvatarOutlined>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={STRINGS.NO_CONTACT_ADDED}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {/* Add Contact Dialog */}
          <AddContactDialog open={addContactOpen} setOpen={setAddContactOpen} />

          {/* add or edit company dialog */}
          {openAddCompnay && (
            <AddEditCompany
              open={openAddCompnay}
              close={() => setOpenAddCompany(false)}
              data={
                dealDetails?.companyData?.length
                  ? dealDetails?.companyData?.[0]
                  : {}
              }
              getDealDetails={getDealDetails}
            />
          )}
        </div>

        {/* All Screens Modal which will open on click of icons. */}
        {createFlag && (
          <CreateMeetingPopUp
            open={createFlag}
            close={setCreateFlag}
            deal_id={params?.id}
            assigneeList={assigneeList}
            setMeetingListData={() => { }}
            dealDetails={dealDetails}
            checkEmptyConnect={checkEmpty?.isConnectionEmpty}
            SetAssigneeList={SetAssigneeList}
            getMeetingLogList={() => postAction("meetings")}
          />
        )}

        {openTask && (
          <CreateTaskCrmPopUp
            dealDetails={dealDetails}
            open={openTask}
            close={setOpenTask}
            deal_id={params?.id}
            setTaskListData={() => { }}
            type={type}
            getTaskLogList={() =>
              postAction(type === "call" ? "calls" : "tasks")
            }
          />
        )}
        {createNoteFlag && (
          <CreateNoteCrmPopUp
            open={createNoteFlag}
            close={setCreateNoteFlag}
            setNoteListData={() => { }}
            deal_id={params?.id}
            getNoteLogList={() => postAction("note")}
          />
        )}
        {openEmailSendModal && (
          <EmailSendModal
            open={openEmailSendModal}
            close={() => setOpenEmailSendModal(false)}
            dealId={params?.id}
            dealDetails={dealDetails}
            getEmails={() => postAction("emails")}
          />
        )}
      </div>
    </div>
  );
};

export default DealsDetailScreen;
