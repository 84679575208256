import React from "react";
import { BASE, NAVY } from "../../utils/constants/colors";
import { fontSize, fontWeight, lineHeight } from "../../utils/constants/UI";

const SIZE_MAPPING = {
  XXL: {
    width: "80px",
    height: "80px",
    fontSize: fontSize.DSM,
    lineHeight: lineHeight.DSM,
  },
  extraLarge: {
    width: "64px",
    height: "64px",
    fontSize: fontSize.DXS,
    lineHeight: lineHeight.DXS,
  },
  huge: {
    width: "56px",
    height: "56px",
    fontSize: fontSize.XL,
    lineHeight: lineHeight.XL,
  },
  large: {
    width: "48px",
    height: "48px",
    fontSize: fontSize.XL,
    lineHeight: lineHeight.XL,
  },
  medium: {
    width: "40px",
    height: "40px",
    fontSize: fontSize.MD,
    lineHeight: lineHeight.MD,
  },
  small: {
    width: "32px",
    height: "32px",
    fontSize: fontSize.SM,
    lineHeight: lineHeight.SM,
  },
  tiny: {
    width: "24px",
    height: "24px",
    fontSize: fontSize.XS,
    lineHeight: lineHeight.XS,
  },
};

const createInitials = (arr) => {
  let result = "";
  for (let i = 0; i < arr?.length; i++) {
    if (arr?.[i]?.[0]) {
      result += arr[i][0];
    }
  }
  return result?.slice(0, 2);
};

const AvatarName = ({ name, size, ...props }) => {
  const classes = {
    mainContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: NAVY.W_500,
      borderRadius: "50%",
      fontWeight: fontWeight.MEDIUM,
      color: BASE.WHITE,
      minWidth: SIZE_MAPPING[size]?.width || "32px",
      minHeight: SIZE_MAPPING[size]?.height || "32px",
      fontSize: SIZE_MAPPING[size].fontSize,
      lineHeight: SIZE_MAPPING[size].lineHeight,
      textTransform: "uppercase"
    },
  };

  const initialsArray = name?.trim()?.split(" ");
  const initials = createInitials(initialsArray);
  return (
    <div style={classes.mainContainer} {...props}>
      {initials}
    </div>
  );
};

export default AvatarName;

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in StatusChip then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props Description:
// name :- label for which you need initials.
// size :- As per mentioned in figma.
