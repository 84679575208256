import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styles } from "./styles";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  SETTINGS_USERS_TABLE_HEADERS,
} from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { usersList } from "../../../redux/actions/authActions";
import CustomTable from "../../../components/Table/CustomTable";
import { STRINGS } from "../strings";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { paginateAndSort } from "../../../utils/helpers";
import { putRequest } from "../../../Apis/apiWrapper";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";

const Users = () => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState([]);
  const [inputDataActive, setInputDataActive] = useState("");

  const userListSelector = useSelector((state) => state.usersList);
  const { loading, payload } = userListSelector;
  const records = payload?.data?.users;
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );
  useEffect(() => {
    dispatch(usersList(navigate));
  }, [navigate, dispatch]);

  const account_status_list = [
    { id: "active", color: "green", label: "Active" },
    { id: "inactive", color: "red", label: "Not Active" },
  ];
  const DataSet = payload?.data?.users?.filter((account) => {
    return account?.account_status !== account_status_list?.id;
  });

  const page = paginationDataSelector?.data?.query?.page || 1;
  const pageSize = paginationDataSelector?.data?.query?.limit || 25;
  const sortBy = paginationDataSelector?.data?.query?.sort?.id || "";
  const sortOrder =
    paginationDataSelector?.data?.query?.sort?.direction || "asc";

  const UserData = {
    heads: SETTINGS_USERS_TABLE_HEADERS,
    // body:payload?.data?.users || [],
    body:
      paginateAndSort(payload?.data?.users, page, pageSize, sortBy, sortOrder)
        ?.records || [],
    status_list: [
      {
        id: "active",
        label: inputData[0],
        // iconLabel: ICON_LABELS.EDIT,
        isVisible:
          (inputData.includes("active") || inputData.includes("inactive")) &&
          inputDataActive,
        function: (row, head, label) => handleChangeStatus(row, head, label),
      },
      {
        id: "inactive",
        label:
          (inputData.includes("active") || inputData.includes("inactive")) &&
            inputDataActive
            ? inputData[1]
            : inputData[0],
        // iconLabel: ICON_LABELS.EDIT,
        isVisible: inputData.length > 1 && inputDataActive,
        function: (row, head, label) => handleChangeStatus(row, head, label),
      },
    ],
    actions: {
      lead_status: {
        function: (row, head, label) => handleStatusShow(row, head, label),
      },
    },
    sortHandler: {},
    total_records: records?.length || 0,
    isLoading: loading,
    is_selection_not_allowed: true,
  };
  console.log("PAYLOAD", UserData.body);
  const handleStatusShow = (row, head, item) => {
    let label = item?.label;
    console.log(row, head, label);
    const accountReal = DataSet?.filter((rows) => rows?._id === row?._id);
    if (accountReal[0]?.account_status == "Suspended") {
      setInputDataActive(true);
      setInputData(["active", "inactive"]);
    } else if (accountReal[0]?.account_status == "not-approved") {
      setInputDataActive(true);
      setInputData(["active", "inactive"]);
    } else if (accountReal[0]?.account_status == "inactive") {
      setInputData(["active"]);
    } else if (accountReal[0]?.account_status == "active") {
      setInputData(["inactive"]);
    } else {
      return null;
    }
  };
  const handleChangeStatus = async (row, head, label) => {
    console.log({ row, head, label });
    const accountReal = DataSet?.filter((rows) => rows?._id === row?._id);

    try {
      if (
        accountReal[0]?.account_status == "Suspended" ||
        accountReal[0]?.account_status == "not-approved"
      ) {
        const payload = {
          account_status: label?.label,
          user_id: row?._id,
        };

        const response = await putRequest(
          navigate,
          authEndpoints.updateUserStatus,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        dispatch(usersList(navigate));
      } else {
        const payload = {
          account_status:
            accountReal[0]?.account_status == "inactive"
              ? "active"
              : "inactive",
          user_id: row?._id,
        };

        const response = await putRequest(
          navigate,
          authEndpoints.updateUserStatus,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        dispatch(usersList(navigate));
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.componentContainer}>
        <Box className={classes.flexBetween}>
          <Box className={classes.padUs}>
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.USERS}
            />
          </Box>
        </Box>
        <Box className={classes.userTableContainer}>
          <CustomTable tableData={UserData} />
        </Box>
      </Box>
    </Box>
  );
};

export default Users;
