import React, { useEffect, useState } from "react";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import { Button } from "../../../../components/Buttons";
import CustomDialog from "../../../../components/Dialog/CustomDialog";
import InputField from "../../../../components/InputFields/InputField";
import { BRAND, GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import { useNavigate } from "react-router-dom";
import classes from "./CrmComp.module.css";
import { crmEndpoints, formsEndpoints } from "../../../../utils/constants/httpConstants";
import { getRequest, postRequest } from "../../../../Apis";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import TextArea from "../../../../components/InputFields/TextArea";
import { HtmlEditor } from "../../../../components";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import {
  dropdownValuesFromId,
  replaceKeyInArrayOfObj,
} from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  COUNTRY_LIST,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { nameValidation, urlValidation } from "../../../../utils/validations/inputValidations";
import { crmAccountDataFetch } from "../../../../redux/actions/tableActions";

const AddEditCompany = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );
  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );
  const [inputData, setInputData] = useState({});
  const [inputDataId, setInputDataId] = useState({});
  const [inputError, setInputError] = useState({});
  const [loader, SetLoader] = useState(false);
  const [countryList, setCountrylist] = useState(COUNTRY_LIST || []);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const [requiredOrder, setRequiredOrder] = useState({});
  const [loading, setLoading] = useState(false);

  const closeContactHandler = () => {
    setOpen(!open);
    setInputData({});
    setInputDataId({});
    setInputError({});
  };

  // validate add company required fields.
  const validationHandler = (inputData) => {

    // getting all the required fields id and storing it into array.
    let required_fields = [];
    let error_arr = [];
    let input_error = { ...inputError };

    Object.keys(requiredOrder)?.forEach((element, index) => {
      if (requiredOrder?.[element]) {
        required_fields.push(element);
      }
    });

    // loop on required ids for checking validation according to field_type
    required_fields?.forEach((element, index) => {
      if (element !== "company_website") {
        let validate = nameValidation(inputData?.[element]);

        if (validate?.isValid) {
          error_arr?.filter(item => item !== element);
          input_error = ({ ...input_error, [element]: "" });
        } else {
          error_arr = [...error_arr, element];
          input_error = ({ ...input_error, [element]: validate?.message });
        }
      }
    })

    // storing the error into state for displaying error for company_website
    const validatedCompanyWebsite = urlValidation(inputData?.company_website);
    if (validatedCompanyWebsite?.isValid) {
      error_arr?.filter(item => item !== "company_website");
      input_error = ({ ...input_error, company_website: "" });
    } else {
      error_arr = [...error_arr, "company_website"];
      input_error = ({ ...input_error, company_website: validatedCompanyWebsite?.message });
    }
    setInputError({ ...input_error });

    // return value according to error key check in error_arr.
    if (error_arr?.length) {
      return false;
    } else {
      return true;
    }
  };

  const saveContactHandler = async (e) => {

    e?.preventDefault();
    // validating all the required field.
    if (validationHandler(inputData)) {
      SetLoader(true);
      try {
        const response = await postRequest(navigate, crmEndpoints.addCompany, {
          query: inputData,
        });
        await dispatch(
          crmAccountDataFetch(
            {
              query: {
                ...allDynamicFiltersSelector,
              },
            },
            navigate,
            true
          )
        );
        closeContactHandler();
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } catch (error) {
        SetLoader(false);

        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      } finally {
        SetLoader(false);
      }
    }
  };

  const searchHandler = (e, property) => {
    let countryValue = countryList;

    let tempItems = [...getPropertiesListSelector?.data?.hits];
    let values = dropdownValuesFromId(tempItems, property?.id);
    let data = [];
    if (property?.id === STRINGS.PERSON_COUNTRY) {
      data = values?.filter((item) =>
        item?.labelValue
          ?.toLowerCase()
          ?.includes(e?.target?.value?.toLowerCase())
      );
    } else if (property?.id === STRINGS.COMPANY_COUNTRY_SMALL) {
      data = countryValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setCountrylist(COUNTRY_LIST);
      } else {
        setCountrylist(data);
      }
    }
    // // Storing searched Data into main state.
    // let newData = [];
    // tempItems?.map((item) => {
    //   if (
    //     item?.id === property?.id &&
    //     item?.field_type?.toString() === STRINGS.SINGLE_SELECT
    //   ) {
    //     let obj = {
    //       ...item,
    //       value: data,
    //     };
    //     newData?.push(obj);
    //   } else {
    //     newData.push(item);
    //   }
    // });

    // setPropertiesData(newData);
  };

  const getValuesOfDropdown = (prop) => {
    if (prop?.id === STRINGS.COMPANY_COUNTRY_SMALL) {
      let country_list = countryList?.map((item) => {
        return {
          label: item?.label,
          id: item?.id,
        };
      });

      return country_list;
    } else {
      return replaceKeyInArrayOfObj(
        prop?.value,
        STRINGS.LABEL_VALUE,
        STRINGS.LABEL
      );
    }
  };
  const dialogHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.ADD_COMPANY,
    },
  };

  const dialogFooter = (
    <div className={classes.addDialogFooter}>
      <div>
        <Button
          label={STRINGS.CANCEL}
          size="sm36"
          variant={"ghost"}
          onClick={() => setOpen(!open)}
        />
      </div>
      <div>
        <Button
          label={STRINGS.SAVE_COMPANY}
          size="sm36"
          variant={"primary"}
          onClick={() => {
            saveContactHandler();
          }}
          isLoading={loader}
        />
      </div>
    </div>
  );

  const type_id_components = (prop) => {
    switch (prop?.field_type[0]) {
      case STRINGS.DATE_PICKER:
        return (
          <CustomDatePicker
            size={"sm36"}
            value={inputData[prop?.id] || new Date()}
            onChange={(dateValue) =>
              setInputData({ ...inputData, [prop?.id]: dateValue })
            }
          />
        );

      case STRINGS.NUMBER:
        return (
          <InputField
            size={"sm36"}
            type={"number"}
            onChange={(e) =>
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              })
            }
            value={inputData[prop?.id]}
            placeholder={`Enter a ${prop?.property_name}`}
          />
        );

      case STRINGS.MULTI_LINE_TEXT_INPUT:
        return (
          <TextArea
            rows={"5"}
            onChange={(e) =>
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              })
            }
            value={inputData[prop?.id]}
          />
        );

      case STRINGS.RICH_TEXT_EDITOR:
        return (
          <><div className={inputError[prop?.id] ? classes.htmlEditorError : classes.htmlEditor}>
            <HtmlEditor
              editorHeight={"12vh"}
              previosData={inputData[prop?.id] || ""}
              onInput={() => {
                setInputData({
                  ...inputData,
                  [prop?.id]: document?.getElementById(STRINGS.EDITOR_ID)
                    ?.innerHTML,
                });
              }}
              isAITagNotVisible
              isAddTagNotVisible
              isMeetingTagNotVisible
              isFocusNotRequired
            />

          </div> {inputError[prop?.id] ? <TypographyInline label={inputError[prop?.id] || ""} color={BRAND.PRIMARY} size={"sm"} fontWeight={fontWeight.REGULAR}
          /> : null}</>
        );

      case STRINGS.SINGLE_SELECT:
        return (
          <AutoCompleteCustom
            dropdownId="preview_dropdown"
            items={getValuesOfDropdown(prop)}
            size={"sm36"}
            valueState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputDataId[prop?.id]]
                : [inputData[prop?.id]]
            }
            setValueState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                })
                : setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                });
            }}
            idState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputData[prop?.id]]
                : [inputDataId[prop?.id]]
            }
            setIdState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                })
                : setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                });
            }}
            isSearchEnable={
              prop?.property_name === STRINGS.COMPANY ||
              STRINGS.PERSON_COUNTRY_CAPS ||
              STRINGS.COMPANY_COUNTRY_SMALL
            }
            handleSearch={(event) => {
              searchHandler(event, prop);
            }}
          />
        );

      case STRINGS.MULTIPLE_SELECT:
        return (
          <AutoCompleteCustom
            dropdownId="preview_dropdown_multi"
            items={replaceKeyInArrayOfObj(
              prop?.value,
              STRINGS.LABEL_VALUE,
              STRINGS.LABEL
            )}
            size={"sm36"}
            multiple
            valueState={inputData[prop?.id]}
            setValueState={(data) => {
              setInputData({
                ...inputData,
                [prop?.id]: data,
              });
            }}
            idState={inputDataId[prop?.id]}
            setIdState={(data) => {
              setInputDataId({
                ...inputDataId,
                [prop?.id]: data,
              });
            }}
          />
        );

      default:
        return (
          <InputField
            size={"sm36"}
            placeholder={`Enter a ${prop?.property_name}`}
            value={inputData[prop?.id]}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            variant={
              inputError[prop?.id]?.length ? STRINGS.ERROR : STRINGS.DEFAULT
            }
            errormessage={inputError[prop?.id]}
          />
        );
    }
  };

  // api handler for get fields order.
  const getFieldsOrder = async () => {
    try {
      setLoading(true)
      const response = await getRequest(
        navigate,
        formsEndpoints.getFormsField
      );
      let columns = [];
      let required_obj = {};
      response?.data?.data?.Company?.sort((obj1, obj2) => obj1.order - obj2.order)?.forEach(element => {
        columns.push(element?.field_id);
        required_obj = { ...required_obj, ...{ [element.field_id]: element?.required } };
      });
      setRequiredOrder(required_obj);
      setFieldsOrder(columns || []);
    } catch (error) {
      console.log("Error------->>>>>> ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getFieldsOrder()
    }
  }, [open]);

  return (
    <CustomDialog
      open={open}
      close={closeContactHandler}
      header={dialogHeader}
      footer={dialogFooter}
      width={"26.042vw"}
    >
      {/* DialogBody */}
      {loading ? (
        <div className={classes.addCompanyFormLoaderContainer}>
          {Array.from({ length: 20 }).map((index) => {
            return (
              <div key={`addContact${index}`}>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"30px"}
                  stackWidth={"100%"}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <form className={classes.inputForm} onSubmit={saveContactHandler}>
          {fieldsOrder?.map((field) => {
            let propertyKeyValue = { ...getPropertiesListSelector?.data?.properties_key_value };
            return (
              <div className={classes.inputLabelField}>
                <div className={classes.labelButtonContainer}>
                  <TypographyInline
                    label={propertyKeyValue?.[field]?.property_name}
                    color={GREY.QUATINARY}
                    size={"sm"}
                    fontWeight={fontWeight.MEDIUM}
                    isRequired={requiredOrder?.[field]}
                  />
                </div>
                {type_id_components(propertyKeyValue?.[field])}
              </div>
            );
          })}
        </form>
      )}
      {/* DialogBody */}
    </CustomDialog>
  );
};

export default AddEditCompany;
