import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import {
  GREY,
  INDIGO,
  NAVY,
  ORANGE,
  PINK,
  PURPLE,
  SUCCESS,
  TEAL,
  WARNING,
} from "../../utils/constants/colors";
import { fontSize, fontWeight } from "../../utils/constants/UI";
import IconComponent from "../Icon/IconComponent";
import Typography from "../Typography/Typography";

const SIZE_MAPPING = {
  orange: {
    leftIconColor: ORANGE.W_500,
    labelColor: GREY.SECONDARY,
    rightIconColor: GREY.SECONDARY,
    hoverBackground: ORANGE.W_50,
  },
  teal: {
    leftIconColor: TEAL.W_400,
    labelColor: GREY.SECONDARY,
    rightIconColor: GREY.SECONDARY,
    hoverBackground: TEAL.W_100,
  },
  pink: {
    leftIconColor: PINK.W_500,
    labelColor: GREY.SECONDARY,
    rightIconColor: GREY.SECONDARY,
    hoverBackground: PINK.W_50,
  },
  purple: {
    leftIconColor: PURPLE.W_500,
    labelColor: GREY.SECONDARY,
    rightIconColor: GREY.SECONDARY,
    hoverBackground: PURPLE.W_50,
  },
  yellow: {
    leftIconColor: WARNING.W_500,
    labelColor: GREY.SECONDARY,
    rightIconColor: GREY.SECONDARY,
    hoverBackground: WARNING.W_50,
  },
  green: {
    leftIconColor: SUCCESS.W_500,
    labelColor: GREY.SECONDARY,
    rightIconColor: GREY.SECONDARY,
    hoverBackground: SUCCESS.W_50,
  },
  blue: {
    leftIconColor: INDIGO.W_500,
    labelColor: GREY.SECONDARY,
    rightIconColor: GREY.SECONDARY,
    hoverBackground: INDIGO.W_50,
  },
  navy: {
    leftIconColor: NAVY.W_500,
    labelColor: GREY.SECONDARY,
    rightIconColor: GREY.SECONDARY,
    hoverBackground: NAVY.W_50,
  },
};

const styles = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px 10px",
    gap: "2px",
    backgroundColor: "transparent",
    borderRadius: "6px",
    cursor: "pointer",
    border: "none",
    width: "100%",
    textTransform: "capitalize",
    "& #righticon": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: (props) => SIZE_MAPPING[props?.variant]?.hoverBackground,
      "& #righticon": {
        display: "flex",
      },
    },
  },
  chipLeftPart: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
  },
  leftIconStyle: {
    fontSize: `${fontSize.MIN} !important`,
    color: (props) => SIZE_MAPPING[props?.variant]?.leftIconColor,
  },
  rightIconStyle: {
    fontSize: `${fontSize.MD} !important`,
    color: (props) => SIZE_MAPPING[props?.variant]?.rightIconColor,
  },
});

const FilterChip = ({ leftIcon, rightIcon, label, variant, ...props }) => {
  const classes = styles({ variant });

  return (
    <button className={classes.mainContainer} {...props}>
      <div className={classes.chipLeftPart}>
        <IconComponent
          iconLabel={leftIcon || "fiber_manual_record"}
          id="lefticon"
          className={classes.leftIconStyle}
        />
        <Typography
          id="label"
          label={label}
          size="sm"
          color={SIZE_MAPPING[variant]?.labelColor}
          fontWeight={fontWeight.SEMIBOLD}
        />
      </div>
      <IconComponent
        iconLabel={rightIcon || "keyboard_arrow_down"}
        id="righticon"
        className={classes.rightIconStyle}
      />
    </button>
  );
};

export default FilterChip;

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in FilterChip then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props Description:
// variant :- color type of filter chip mentioned in figma.
// label :- You want to display on chip.
// rightIcon :- Icon displayed on the right side of the label.
//  leftIcon :- Icon displayed on the left side of the label.
