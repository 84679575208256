import React, { useEffect, useState } from "react";
import classes from "./Deals.module.css";
import { MEETINGS_CRM } from "../../../utils/constants/assets";
import ContactDetailEmptyScreen from "../Contacts/components/ContactDetailEmptyScreen";
import { STRINGS } from "../strings";
import {
  crmEndpoints,
} from "../../../utils/constants/httpConstants";
import { getRequest } from "../../../Apis";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { BRAND } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Buttons";
import ListingMeetingCard from "./components/ListingMeetingCard";
import SkeletonLoaderCRMScreens from "../Contacts/components/SkeletonLoaderCRMScreens";
import CreateMeetingPopUp from "./components/CreateMeetingPopUp";
import CreateLogMeetingPopUp from "./components/CreateLogMeetingPopUp";
import { decompressString } from "../../../utils/helpers";

const ContactDetailMeetingScreen = ({ dealDetails, dealId, getMeetingLogList = () => { }, screenLoading = false, meetingListData = [], setMeetingListData = () => { }, totalMeetings = 0 }) => {
  const navigate = useNavigate();
  const [createFlag, setCreateFlag] = useState(false);
  const [createFlagLog, setCreateFlagLog] = useState(false);
  const [assigneeList, SetAssigneeList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const emptyScreenButtons = [
    {
      id: STRINGS.MEETINGS,
      label: STRINGS.CREATE_MEETINGS,
      variant: "primary",
      disabled: !Object.keys(dealDetails)?.length,

      onClick: () => {
        setCreateFlag(true);
      },
    },
    {
      id: STRINGS.MEETINGS,
      label: STRINGS.LOG_MEETING,
      variant: "primary",
      disabled: !Object.keys(dealDetails)?.length,

      onClick: () => {
        setCreateFlagLog(true);
      },
    },
  ];

  //Click for open the createNote PopUp

  const createTaskOpenHandler = () => {
    setCreateFlag(true);
  };

  const createLogMeetingOpenHandler = () => {
    setCreateFlagLog(true);
  };

  useEffect(() => {
    (async () => {
      let url2 = `${crmEndpoints.getEmailSuggestions}`;
      const response = await getRequest(navigate, url2);
      SetAssigneeList(response?.data?.data?.records);
    })();
  }, []);

  const onScrollApiHandler = () => {
    const scrollableDiv = document?.getElementsByClassName("allMeetingList")[0];
    const isAtBottom =
      scrollableDiv?.scrollTop >=
      scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;
    if (isAtBottom && totalMeetings > meetingListData?.length) {
      setPageCount(pageCount + 1);
      getMeetingLogList(true, pageCount + 1);
    }
  };

  useEffect(() => {
    // if (dealDetails?.email?.length) {
    getMeetingLogList();
    // }
  }, [dealDetails]);

  return (
    <div
      className={`${classes.noteContainer} allMeetingList`}
      id="comments_body"
      onScroll={onScrollApiHandler}
    >
      {screenLoading ? (
        <SkeletonLoaderCRMScreens
          loaderHeight="42px"
          accordionLengths={10}
          buttonsLength={2}
        />
      ) : (
        <>
          {" "}
          {/* Checking the length of the Meeting list */}
          {meetingListData?.length ? (
            <div className={classes.taskListContainer}>
              <div className={classes.HeadTaskContainerSubHead}>
                <TypographyInline
                  label={STRINGS.ALL_MEETINGS}
                  size={"md"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={BRAND.SECONDARY}
                />
                <div className={classes.WIdthButton}>
                  <Button
                    label={STRINGS.CREATE_MEETING}
                    variant={"primary"}
                    size={"sm36"}
                    onClick={createTaskOpenHandler}
                  />
                  <Button
                    label={STRINGS.LOG_MEETING}
                    variant={"primary"}
                    size={"sm36"}
                    onClick={createLogMeetingOpenHandler}
                  />
                </div>
              </div>
              {/* Sending Meeting list to the ListingMeetingCard*/}
              {meetingListData?.map((logData, index) => {
                return (
                  <div key={`log${index}`}>
                    <ListingMeetingCard
                      dealId={dealId}
                      id={`log${index}`}
                      logData={logData?.is_compressed ? { ...logData, event_description: decodeURIComponent(decompressString(logData.event_description)) } : logData}
                      meetingListData={meetingListData}
                      setMeetingListData={setMeetingListData}
                      assigneeList={assigneeList}
                      expanded={!index}
                      dealDetails={dealDetails}
                      getMeetingLogList={getMeetingLogList}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classes.TaskHead}>
              <ContactDetailEmptyScreen
                src={MEETINGS_CRM}
                label={STRINGS.MEETING_LABEL}
                buttonItems={emptyScreenButtons}
              />
            </div>
          )}
        </>
      )}

      {createFlag && (
        <CreateMeetingPopUp
          open={createFlag}
          close={setCreateFlag}
          deal_id={dealId}
          setMeetingListData={setMeetingListData}
          assigneeList={assigneeList}
          dealDetails={dealDetails}
          getMeetingLogList={getMeetingLogList}
          SetAssigneeList={SetAssigneeList}
        />
      )}
      {createFlagLog && (
        <CreateLogMeetingPopUp
          open={createFlagLog}
          close={setCreateFlagLog}
          deal_id={dealId}
          setMeetingListData={setMeetingListData}
          assigneeList={assigneeList}
          dealDetails={dealDetails}
          getMeetingLogList={getMeetingLogList}
          SetAssigneeList={SetAssigneeList}
        />
      )}
    </div>
  );
};

export default ContactDetailMeetingScreen;
