import React from 'react'
import { makeStyles } from "@mui/styles";
import { Box } from '@mui/material';
import { STRINGS } from "../strings";
import Image from '../../../components/Image/Image';
import { SEARCH_PROSPECTS_IMAGE } from '../../../utils/constants/assets';
import TypographyInline from '../../../components/Typography/TypographyInline';
import { GREY } from '../../../utils/constants/colors';
import { Button } from '../../../components/Buttons';
import { ICON_LABELS, SHADOW, fontWeight } from '../../../utils/constants/UI';

const styles = makeStyles({
    containSearchImage: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: "8px",
      height: "calc(100% - 160px)",
      background: "white",
    //   borderRadius: "4px",
      borderTop:"1px solid rgb(239, 239, 239)",
      margin: "0px 16px",
      borderBottomLeftRadius:"6px",
      borderBottomRightRadius:"6px",
      boxShadow: SHADOW.sm,
    },
    searchImage: {
      width: "19.141vw",
      objectFit: "contain",
      paddingBottom: "1rem",
    },
    searchPara: {
      fontSize: "clamp(12px, 0.8vw, 40px)",
      fontWeight: "500",
      color: "#626b7e",
    },
    title: {
      color: "#0F2F51",
      fontWeight: "800",
      fontSize: "clamp(1.4rem, 1.7vw, 4.2rem)",
      marginBottom: "1rem",
    },
    imageContainer: {
      display: "flex",
      flexDirection: "column",
    },
    contentPara: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "4px",
    },
    contentHeading: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  });
  

const EmptyDashboardScreen = ({openDashboard}) => {
const classes = styles();
  return (
    <Box className={classes.containSearchImage}>
    <div className={classes.imageContainer}>
      <Image
        src={SEARCH_PROSPECTS_IMAGE}
        alt=""
        className={classes.searchImage}
      />
    </div>
    <div className={classes.contentHeading}>
      <TypographyInline
        label={STRINGS.CREATE_DASHBOARD}
        size={"xl"}
        fontWeight={fontWeight.SEMIBOLD}
        color={GREY.PRIMARY}
      />
      <div className={classes.contentPara}>
        <TypographyInline
          label={STRINGS.GET_STARTED}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
        />
        <TypographyInline
          label={STRINGS.PERSONALIZED}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
        />
      </div>
    </div>
    <div>
      <Button
          label={STRINGS.CREATE_DASHBOARD}
          LeftIcon={ICON_LABELS.ADD}
          size={"md40"}
          variant={"primary"}
          onClick={()=>openDashboard()}
        />
    </div>
  </Box>
  )
}

export default EmptyDashboardScreen