import React, { useEffect, useState } from "react";
import { GREY, PRIMARY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import { STRINGS } from "./strings";
import CreatePropertyModal from "./components/CreatePropertyModal";
import EmptyPropertiesScreen from "./components/EmptyPropertiesScreen";
import EmptyGroupScreen from "./components/EmptyGroupScreen";
import { useLocation, useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import CreateGroupModal from "./components/CreateGroupModal";
import { useDispatch, useSelector } from "react-redux";
import {
  allPropertiesFilters,
  getGroupsList,
  getPropertiesList,
} from "../../redux/actions/propertiesAction";
import GroupListScreen from "./components/GroupListScreen";
import { postRequest } from "../../Apis";
import { crmEndpoints } from "../../utils/constants/httpConstants";
import PropertyListScreen from "./components/PropertyListScreen";
import classes from "./PropertiesScreen.module.css";
import TypographyInline from "../../components/Typography/TypographyInline";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  objectTypeDropdownValues,
} from "../../utils/constants";
import { deleteRequest, putRequest } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { paginationDataAction } from "../../redux/actions/customTableActions";
import { propertiesGrpNameValidation } from "../../utils/validations/inputValidations";
import EmptyScreen from "../../components/Screens/EmptyScreen";
import { PROPERTIES_EMPTY_SCREEN } from "../../utils/constants/assets";

const PropertiesScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const getGroupListSelector = useSelector((state) => state.getGroupList);
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );
  const allPropertiesFiltersSelector = useSelector(
    (state) => state.allPropertiesFilters
  );
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const [openCreatePropModal, setCreatePropModal] = useState(false);
  const [openGroupModal, setCreateGroupModal] = useState(false);
  const [createGrpBtnLoading, setCreateGrpBtnLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    grp_name: "",
    object_type: "",
  });
  const [tabId, setTabId] = useState(STRINGS.PROPERTIES);
  const [groupName, setGroupName] = useState("");
  const [objectTypeId, setObjectTypeId] = React.useState([]);
  const [objectTypeValue, setObjectTypeValue] = React.useState([]);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const [editBtnLoading, setEditBtnLoading] = useState(false);
  const [grpData, setGrpData] = useState({});
  const [dltBtnLoading, setDltBtnLoading] = useState(false);
  const [openDltGroupModal, setOpenDltGroupModal] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [openEditPropModal, setOpenEditPropModal] = useState(false);
  const [editRow, setEditRow] = useState({});
  const [selectedFieldType, setSelectedFieldType] = useState([]);
  const [selectedGroupName, setSelectedGroupName] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [allAppliedFilters, setAllAppliedFilters] = useState([]);

  // create property modal open handler
  const createPropertyHandler = async () => {
    setCreatePropModal(!openCreatePropModal);
    setSecondStep(false);
    setSelectedFieldType([]);
    setSelectedGroupName([]);
  };

  // create group modal open handler
  const createGroupModalHandler = () => {
    setCreateGroupModal(!openGroupModal);
    setGroupName("");
    setErrorMessage({
      grp_name: "",
      object_type: "",
    });
    setObjectTypeId([]);
    setObjectTypeValue([]);
  };

  // create group api call
  const createGroupHandler = async () => {
    let payload = { query: { grp_name: groupName, grp_type: objectTypeValue } };
    setCreateGrpBtnLoading(true);

    // validate required field
    const validatedObjectType = propertiesGrpNameValidation(objectTypeValue);
    const validatedGroupLabel = propertiesGrpNameValidation(groupName?.trim());

    if (validatedObjectType?.isValid && validatedGroupLabel?.isValid) {
      try {
        const response = await postRequest(
          navigate,
          crmEndpoints.createDynamic,
          payload
        );
        await dispatch(getGroupsList({}, navigate));
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setCreateGrpBtnLoading(false);
        setCreateGroupModal(false);
        setGroupName("");
        setErrorMessage({
          grp_name: "",
          object_type: "",
        });
        setObjectTypeId([]);
        setObjectTypeValue([]);
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        setCreateGrpBtnLoading(false);
      }
    } else {
      setErrorMessage({
        grp_name: validatedGroupLabel?.message,
        object_type: validatedObjectType?.message,
      });
      setCreateGrpBtnLoading(false);
    }
  };

  // edit group api call
  const editGroupHandler = async () => {
    let payload = {
      query: {
        id: grpData?._id,
        grp_name: groupName,
        grp_type: objectTypeValue,
      },
    };
    // validate required field
    const validatedObjectType = propertiesGrpNameValidation(objectTypeValue);
    const validatedGroupLabel = propertiesGrpNameValidation(groupName);
    // validate required field
    if (validatedObjectType?.isValid && validatedGroupLabel?.isValid) {
      try {
        setEditBtnLoading(true);
        if (groupName?.length) {
          const response = await putRequest(
            navigate,
            crmEndpoints.editGroup,
            payload
          );
          await dispatch(getGroupsList({}, navigate));
          setEditBtnLoading(false);
          setOpenEditGroupModal(false);
          setGroupName("");
          setErrorMessage({ grp_name: "", object_type: "" });
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        } else {
          setEditBtnLoading(false);
          setErrorMessage({
            grp_name: STRINGS.EMPTY_FIELD_ERROR,
            object_type: "",
          });
        }
      } catch (error) {
        setEditBtnLoading(false);
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    } else {
      setEditBtnLoading(false);
      setErrorMessage({
        grp_name: validatedGroupLabel?.message,
        object_type: validatedObjectType?.message,
      });
    }
  };

  // delete group handler
  const deleteGroupHandler = async () => {
    let payload = {
      query: {
        id: grpData?._id,
      },
    };
    try {
      setDltBtnLoading(true);
      const response = await deleteRequest(
        crmEndpoints.deleteGroup,
        payload,
        navigate
      );
      await dispatch(getGroupsList({}, navigate));
      setDltBtnLoading(false);
      setOpenDltGroupModal(!openDltGroupModal);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      setDltBtnLoading(false);
      setOpenDltGroupModal(!openDltGroupModal);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  // edit group modal handler
  const editGroupCloseHandler = () => {
    setOpenEditGroupModal(!openEditGroupModal);
    setGroupName("");
    setErrorMessage({ grp_name: "", object_type: "" });
  };

  // storing data on edit group
  const editGroupModalHandler = (row) => {
    let grpId = objectTypeDropdownValues?.filter(
      (item) => item?.label === row?.grp_type[0]
    )[0]?.id;
    setGrpData(row);
    setGroupName(row?.grp_name);
    setOpenEditGroupModal(true);
    setObjectTypeValue(row?.grp_type);
    setObjectTypeId([grpId]);
  };

  const editPropModalHandler = async (row) => {
    setEditRow(row);
    setOpenEditPropModal(!openEditPropModal);
    !openEditPropModal && (await dispatch(getGroupsList({}, navigate)));
  };

  // tab Data
  const tabData = [
    { label: STRINGS.PROPERTIES, id: STRINGS.PROPERTIES },
    { label: STRINGS.GROUPS, id: STRINGS.GROUPS },
  ];

  // used to switch tab and add query in url.
  const tabHandler = (tab) => {
    dispatch(paginationDataAction({}));
    setTabId(tab.id);
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ tab: tab.id }).toString(),
    });
  };

  // search function, calls api according to tab.
  const searchFieldHandler = async (e) => {
    dispatch(
      paginationDataAction({
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          page: 1,
        },
      })
    );
    if (tabId === STRINGS.GROUPS) {
      setSearchValue(e?.target?.value);
    } else {
      setSearchValue(e?.target?.value);
    }
  };

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ tab: STRINGS.PROPERTIES }).toString(),
    });
  }, [navigate]);

  useEffect(() => {
    (async () => {
      if (tabId === STRINGS.GROUPS) {
        await dispatch(
          getGroupsList(
            {
              query: {},
            },
            navigate
          )
        );
        await dispatch(
          allPropertiesFilters(
            {
              query: {},
            },
            navigate
          )
        );
      } else {
        await dispatch(
          getPropertiesList(
            {
              query: {},
            },
            navigate
          )
        );
        // group list is called because we need groups for creating property and also for group name filter.
        if (!allPropertiesFiltersSelector?.data?.query) {
          await dispatch(getGroupsList({}, navigate));
        }
      }
    })();
    setGroupName("");
    setAllAppliedFilters([]);
    setSelectedFieldType([]);
    setSelectedGroupName([]);
    setSearchValue("");
    setErrorMessage({ grp_name: "", object_type: "" });
  }, [tabId, dispatch]);

  return (
    <div className={classes.propertiesMainContainer}>
      <div>
        {/* Header Container */}
        <div className={classes.headerContainer}>
          <TypographyInline
            color={GREY.PRIMARY}
            fontWeight={fontWeight.SEMIBOLD}
            label={STRINGS.PROPERTIES}
            size={"lg"}
          />
          <TypographyInline
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.PROPERTIES_DESC}
            size={"sm"}
          />
        </div>

        {/* Body Container */}

        {/* Tabs */}
        <div className={classes.tabsContainer}>
          {tabData.map((tab, i) => {
            return (
              <div
                key={i}
                className={
                  location?.search?.includes(tabId) &&
                    location?.search?.includes(tab.label)
                    ? classes.tabSelected
                    : classes.tabPrimary
                }
                onClick={() => tabHandler(tab)}
              >
                <TypographyInline
                  size={"sm"}
                  color={
                    location?.search?.includes(tabId) &&
                      location?.search?.includes(tab.label)
                      ? PRIMARY.W_500
                      : GREY.SECONDARY
                  }
                  fontWeight={
                    location?.search?.includes(tabId) &&
                      location?.search?.includes(tab.label)
                      ? fontWeight.SEMIBOLD
                      : fontWeight.REGULAR
                  }
                  label={tab.label}
                />
              </div>
            );
          })}
        </div>
      </div>

      <>
        {location?.search?.includes(`tab=${STRINGS.PROPERTIES}`) ? (
          <>
            {!getPropertiesListSelector?.data?.is_empty ? (
              <PropertyListScreen
                createPropertyHandler={createPropertyHandler}
                handleDebounce={searchFieldHandler}
                editPropModalHandler={editPropModalHandler}
                selectedFieldType={selectedFieldType}
                setSelectedFieldType={setSelectedFieldType}
                selectedGroupName={selectedGroupName}
                setSelectedGroupName={setSelectedGroupName}
                searchValue={searchValue}
                allAppliedFilters={allAppliedFilters}
                setAllAppliedFilters={setAllAppliedFilters}
              />
            ) : (
              <EmptyScreen
                imageSrc={PROPERTIES_EMPTY_SCREEN}
                label={STRINGS.EMPTY_PROPERTY_SCREEN_HEADING}
                description={STRINGS.EMPTY_PROPERTY_SCREEN_DESC}
                buttonLabel={STRINGS.CREATE_PROPERTY}
                onClick={createPropertyHandler}
              />
            )}
          </>
        ) : (
          <>
            {!getGroupListSelector?.data?.is_empty ? (
              <GroupListScreen
                createGroupModalHandler={createGroupModalHandler}
                setGroupName={setGroupName}
                editGroupModalHandler={editGroupModalHandler}
                deleteGroupHandler={deleteGroupHandler}
                openDltGroupModal={openDltGroupModal}
                setOpenDltGroupModal={setOpenDltGroupModal}
                handleDebounce={searchFieldHandler}
                grpData={grpData}
                setGrpData={setGrpData}
                dltBtnLoading={dltBtnLoading}
                searchValue={searchValue}
              />
            ) : (
              <EmptyGroupScreen
                createGroupModalHandler={createGroupModalHandler}
              />
            )}
          </>
        )}
      </>

      {/* Create Property Modal */}
      <CreatePropertyModal
        open={openEditPropModal || openCreatePropModal}
        close={openEditPropModal ? editPropModalHandler : createPropertyHandler}
        secondStep={secondStep}
        setSecondStep={setSecondStep}
        data={editRow}
        isEdit={openEditPropModal}
      />

      {/* Create Group Modal */}
      <CreateGroupModal
        open={openEditGroupModal || openGroupModal}
        close={
          openEditGroupModal ? editGroupCloseHandler : createGroupModalHandler
        }
        createEditGroupHandler={
          openEditGroupModal ? editGroupHandler : createGroupHandler
        }
        btnLoading={openEditGroupModal ? editBtnLoading : createGrpBtnLoading}
        errorMessage={errorMessage}
        groupName={groupName}
        setGroupName={setGroupName}
        objectTypeId={objectTypeId}
        setObjectTypeId={setObjectTypeId}
        objectTypeValue={objectTypeValue}
        setObjectTypeValue={setObjectTypeValue}
        isEdit={openEditGroupModal}
      />
    </div>
  );
};

export default PropertiesScreen;
