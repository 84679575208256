import React, { useEffect, useState } from "react";
import { CALL_CRM, TASK_CRM } from "../../../utils/constants/assets";
import { STRINGS } from "../strings";
import classes from "./Deals.module.css";
import CreateTaskCrmPopUp from "./components/CreateTaskCrmPopUp";
import ContactDetailEmptyScreen from "./components/DetailEmptyScreen";
import { useLocation, useNavigate } from "react-router-dom";
import ListingTaskCard from "./components/ListingTaskCard";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { fontWeight } from "../../../utils/constants/UI";
import { BRAND } from "../../../utils/constants/colors";
import { Button } from "../../../components/Buttons";
import SkeletonLoaderCRMScreens from "../Contacts/components/SkeletonLoaderCRMScreens";
import { decompressString } from "../../../utils/helpers";

const ContactDetailTaskScreen = ({
  dealDetails,
  dealId,
  getTaskLogList = () => { },
  taskListData = [],
  setTaskListData = () => { },
  screenLoading = false,
  totalTasks = 0,
}) => {
  const location = useLocation();
  const [createFlag, setCreateFlag] = useState(false);
  const [type, setType] = useState("");

  const [pageCount, setPageCount] = useState(0);
  const locationTabs = location?.search?.split("?tabs=");
  const UpdatedLocation = locationTabs[1] == STRINGS.CALLS_SMALL;
  console.log({ UpdatedLocation });
  const emptyScreenButtons = [
    {
      id: UpdatedLocation ? STRINGS.CALLS_SMALL : STRINGS.TASKS_SMALL,
      label: UpdatedLocation ? STRINGS.CREATE_CALL : STRINGS.CREATE_TODO,
      variant: "primary",
      disabled: !Object.keys(dealDetails).length,
      onClick: () => {
        setCreateFlag(true);
      },
    },
  ];

  //Click for open (create Task pop up)
  const createTaskOpenHandler = () => {
    setCreateFlag(true);
  };

  const onScrollApiHandler = () => {
    const scrollableDiv = document?.getElementsByClassName("allTaskList")[0];
    const isAtBottom =
      scrollableDiv?.scrollTop >=
      scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;
    if (isAtBottom && totalTasks > taskListData?.length) {
      setPageCount(pageCount + 1);
      getTaskLogList(true, pageCount + 1);
    }
  };

  useEffect(() => {
    if (dealDetails?.deal_name?.length) {
      getTaskLogList();
    }
  }, [dealDetails]);

  return (
    <div
      className={`${classes.taskScreenMainContainer} allTaskList`}
      id="comments_body"
      onScroll={onScrollApiHandler}
    >
      {screenLoading ? (
        <SkeletonLoaderCRMScreens accordionLengths={5} buttonsLength={1} />
      ) : (
        <>
          {/* Checking the length of the Task list */}
          {taskListData?.length ? (
            <div className={classes.taskListContainer}>
              <div className={classes.HeadTaskContainerSubHead}>
                <TypographyInline
                  label={
                    UpdatedLocation ? STRINGS.ALL_CALLS : STRINGS.ALL_TODOS
                  }
                  size={"md"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={BRAND.SECONDARY}
                />
                <div className={classes.WIdthButton}>
                  <Button
                    label={
                      UpdatedLocation
                        ? STRINGS.CREATE_CALL
                        : STRINGS.CREATE_TODO
                    }
                    variant={"primary"}
                    size={"sm36"}
                    onClick={() => {
                      createTaskOpenHandler();
                      UpdatedLocation ? setType("call") : setType("tasks");
                    }}
                  />
                </div>
              </div>
              {/* Sending Task list to the ListingTaskCard*/}

              {taskListData?.map((logData, index) => {
                return (
                  <div key={`log${index}`}>
                    <ListingTaskCard
                      id={`log${index}`}
                      logData={
                        logData?.is_compressed
                          ? {
                            ...logData,
                            notes: decodeURIComponent(
                              decompressString(logData.notes)
                            ),
                          }
                          : logData
                      }
                      dealDetails={taskListData}
                      deal_id={dealId}
                      setTaskListData={setTaskListData}
                      expanded={!index}
                      getTaskLogList={getTaskLogList}
                      type={UpdatedLocation ? "call" : "tasks"}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classes.TaskHead}>
              <ContactDetailEmptyScreen
                src={UpdatedLocation ? CALL_CRM : TASK_CRM}
                label={
                  UpdatedLocation ? STRINGS.TASK_LABEL1 : STRINGS.TASK_LABEL
                }
                buttonItems={emptyScreenButtons}
              />
            </div>
          )}
        </>
      )}
      {createFlag && (
        <CreateTaskCrmPopUp
          type={UpdatedLocation ? STRINGS.CALL : STRINGS.TASKS_SMALL}
          open={createFlag}
          close={setCreateFlag}
          deal_id={dealId}
          dealDetails={dealDetails}
          getTaskLogList={getTaskLogList}
        />
      )}
    </div>
  );
};

export default ContactDetailTaskScreen;
