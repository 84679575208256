import {
  EDITOR_SUCCESS,
  MEETING_LINK_FAIL,
  MEETING_LINK_REQUEST,
  MEETING_LINK_SUCCESS,
} from "../constants/editorConstants";

export const editReducer = (state = {}, action) => {
  switch (action.type) {
    case EDITOR_SUCCESS:
      return { payload: action.payload, view: action.view };
    default:
      return state;
  }
};

export const getMeetingLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case MEETING_LINK_REQUEST:
      return { loading1: true };
    case MEETING_LINK_SUCCESS:
      return { loading1: false, data: action.payload, error: null };
    case MEETING_LINK_FAIL:
      return { loading1: false, error: action.payload };
    default:
      return state;
  }
};
