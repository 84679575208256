import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Anchor, FullScreenLoader, RightsTag } from "../../components";

import CompanyForm from "./components/CompanyForm";
import ProfileForm from "./components/ProfileForm";
import { styles } from "./style";
import Typography from "../../components/Typography/Typography";
import { GREY } from "../../utils/constants/colors";
import { PRIVACY_POLICY_LINK } from "../../utils/constants/httpConstants";
import { STRINGS } from "../Auth/strings";
import { fontWeight } from "../../utils/constants/UI";
import { TERMS_SERVICE_LINK } from "../../utils/constants";

const InitialForm = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isFullLoading, setIsFullLoading] = useState(false);
  const userDetailsSelector = useSelector((state) => state?.userDetails);

  const steps = [
    {
      label: "Account Settings",
      component: (
        <CompanyForm activeStep={activeStep} setActiveStep={setActiveStep} />
      ),
    },
    {
      label: "User Profile",
      component: (
        <ProfileForm activeStep={activeStep} setActiveStep={setActiveStep} />
      ),
    },
  ];

  useEffect(() => {
    setIsFullLoading(true);
    if (userDetailsSelector?.payload?.account_setting_setup) {
      setActiveStep(1);
    }
    setIsFullLoading(false);
  }, [userDetailsSelector, navigate]);

  return (
    <>
      {isFullLoading ? (
        <FullScreenLoader />
      ) : (
        <Box className={classes.initialFormMainContainer}>
          {/* Header */}

          {/* Steps Body */}
          <Box className={classes.stepsBodyMainContainer}>
            {steps[activeStep].component}
          </Box>
          <div className={classes.bottomContainer}>
            <div className={classes.containPolicy}>
              <RightsTag className={classes.termsPara} />
              {/* Privacy and Terms. */}
              <Anchor
                href={PRIVACY_POLICY_LINK}
                className={classes.privacyLink}
                target="_blank"
              >
                <div className={classes.hovering}>
                  {" "}
                  <Typography
                    label={STRINGS.PRIVACY}
                    color={GREY.QUATINARY}
                    size={"xs"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
              </Anchor>

              <div className={classes.TypoHead}>
                <Typography
                  label={STRINGS.AND}
                  color={GREY.TERTIARY}
                  size={"xs"}
                  fontWeight={fontWeight.REGULAR}
                />
              </div>
              <Anchor
                href={TERMS_SERVICE_LINK}
                className={classes.privacyLink}
                target="_blank"
              >
                <div className={classes.hovering}>
                  {" "}
                  <Typography
                    label={STRINGS.TERMS}
                    color={GREY.QUATINARY}
                    size={"xs"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
              </Anchor>
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default InitialForm;
