import React, { useEffect, useState } from "react";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import FilterTab from "../../../../components/FilterTab/FilterTab";
import { GREY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  SHADOW,
  fontWeight,
} from "../../../../utils/constants/UI";
import { STRINGS } from "../strings";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decompressString,
  filterDynamicQuery,
} from "../../../../utils/helpers";
import classes from "./DealsComp.module.css";
import CustomStyledMenu from "../../../../components/Menu/CustomStyledMenu";
import {
  allDynamicFilters,
  filterMultipleList,
} from "../../../../redux/actions/filterActions";
import AddFiltermodal from "./AddFilterModal";
import { HandleClearAllFilterFromStore } from "../../../../redux/store/storeHelper";
import { Button } from "../../../../components/Buttons";
import * as qs from "qs";
import FilterSlider from "../../../../components/FilterTypes/Dynamic/FilterSlider";
import FilterMultiple from "../../../../components/FilterTypes/Dynamic/FilterMultiple";
import InputField from "../../../../components/InputFields/InputField";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import { postRequest } from "../../../../Apis";
import { dealsEndpoints } from "../../../../utils/constants/httpConstants";
import { getDealsList } from "../../../../redux/actions/dealsActions";

const FilterStrip = ({ setSearchField = () => { }, setSortById = () => { }, setSortByLabel = () => { }, setOrderId = () => { }, setOrderLabel = () => { } }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryObject = qs.parse(window.location?.search?.slice(1));
  if (queryObject?.page === 1) {
    delete queryObject?.page;
  }
  if (queryObject?.limit === 25) {
    delete queryObject?.limit;
  }
  const queryObjectLength = Object.keys(queryObject || {})?.filter(
    (item) => !["page", "limit"].includes(item)
  )?.length;

  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );

  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  const propertiesPayload = localStorage.getItem("properties")
    ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))
      ?.properties_key_value
    : getPropertiesListSelector?.properties_key_value;

  const [filterTabAnchor, setFilterTabAnchor] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(-1);
  const [dealNameFilter, setDealNameFilter] = useState("");
  const [dealOwnerFilterId, setDealOwnerFilterId] = useState([]);
  const [dealOwnerFilterValue, setDealOwnerFilterValue] = useState([]);
  const [associatedContact, setAssociatedContact] = useState([]);

  // All Sales Filters Function

  // filter for deal name search
  const handleDealSearch = (data, id, payload) => {
    if (data?.[id]?.length) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(payload, propertiesPayload),
          deal_name: data?.deal_name,
        }).toString(),
      });
      return {
        ...payload,
        deal_name: data?.deal_name,
      };
    } else {
      let temp_payload = { ...payload };
      delete temp_payload?.deal_name;
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(temp_payload, propertiesPayload),
        }).toString(),
      });
      return { ...temp_payload };
    }
  };

  // filter for associated contact
  const handleAllFiltersQuery = (data, id, payload) => {
    // associated_contact
    if (id !== STRINGS.DEAL_NAME_SMALL) {
      // if any or not anyOf true and isApicall is also true.
      if (
        (filterMultipleListSelector?.[id]?.anyOf ||
          filterMultipleListSelector?.[id]?.notAnyOf) &&
        filterMultipleListSelector?.isApiCall
      ) {
        let temp_data = {
          ...data,
          exist_fields: [...(payload?.exist_fields || [])]?.filter(
            (e) => e !== id
          ),
          not_exist_fields: [...(payload?.not_exist_fields || [])].filter(
            (e) => e !== id
          ),
        };
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            ...filterDynamicQuery(temp_data, propertiesPayload),
            exist_fields: [...(payload?.exist_fields || [])]?.filter(
              (e) => e !== id
            ),
            not_exist_fields: [...(payload?.not_exist_fields || [])]?.filter(
              (e) => e !== id
            ),
          }).toString(),
        });
        return { ...data, ...temp_data };
      }
      // if isKnown is checked
      else if (
        filterMultipleListSelector?.[id]?.isKnown &&
        filterMultipleListSelector?.isApiCall
      ) {
        const queryPayload = {
          exist_fields: [...new Set([...(payload?.exist_fields || []), id])],
          not_exist_fields: [...(payload?.not_exist_fields || [])].filter(
            (e) => e !== id
          ),
          [id]: [],
          [`${id}-not`]: [],
        };

        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            ...queryPayload,
          }).toString(),
        });
        return { ...data, ...queryPayload };
      }
      // if isUnknown is checked
      else if (
        filterMultipleListSelector?.[id]?.isUnknown &&
        filterMultipleListSelector?.isApiCall
      ) {
        const queryPayload = {
          not_exist_fields: [
            ...new Set([...(payload?.not_exist_fields || []), id]),
          ],
          exist_fields: [...(payload?.exist_fields || [])].filter(
            (e) => e !== id
          ),
          [id]: [],
          [`${id}-not`]: [],
        };
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            ...queryPayload,
          }).toString(),
        });
        return { ...data, ...queryPayload };
      }
      // if isKnown is unchecked
      else if (
        (!filterMultipleListSelector?.[id]?.isKnown ||
          !filterMultipleListSelector?.[id]?.isUnknown) &&
        filterMultipleListSelector?.isApiCall &&
        id !== STRINGS.SEARCH_SMALL &&
        id !== STRINGS.NAME_SMALL &&
        id !== "page" &&
        id !== "limit"
      ) {
        const queryPayload = {
          exist_fields: [...(payload?.exist_fields || [])].filter(
            (e) => e !== id
          ),
          not_exist_fields: [...(payload?.not_exist_fields || [])].filter(
            (e) => e !== id
          ),
          [id]: [],
          [`${id}-not`]: [],
        };

        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(payload, propertiesPayload),
            ...queryPayload,
          }).toString(),
        });
        return { ...data, ...queryPayload };
      } else if (id === STRINGS.SEARCH_SMALL) {
        if (data?.search?.length) {
          navigate({
            pathname: location.pathname,
            search: createSearchParams({
              ...filterDynamicQuery(payload, propertiesPayload),
              search: data?.search,
            }).toString(),
          });
          return {
            ...payload,
            search: data?.search,
          };
        } else {
          let temp_payload = { ...payload };
          navigate({
            pathname: location.pathname,
            search: createSearchParams({
              ...filterDynamicQuery(temp_payload, propertiesPayload),
            }).toString(),
          });
          return { ...temp_payload };
        }
      } else {
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...filterDynamicQuery(data, propertiesPayload),
          }).toString(),
        });
        return { ...payload };
      }
    } else {
      return handleDealSearch(data, id, payload);
    }
  };

  // filter for date picker field type
  const handleDateFilterHandler = (data, id, payload) => {
    if (Object?.keys(data?.[id])?.length) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(payload, propertiesPayload),
          [`from-${id}`]: data?.[id]?.from,
          [`to-${id}`]: data?.[id]?.to,
        }).toString(),
      });
      return { ...payload, ...data };
    } else {
      return { ...payload };
    }
  };

  // filter for number field type
  const handleNumberFilterHandler = (data, id, payload) => {
    if (Object?.keys(data?.[id])?.length) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(payload, propertiesPayload),
          [`min-${id}`]: data?.[id]?.min,
          [`max-${id}`]: data?.[id]?.max,
        }).toString(),
      });
      return { ...payload, ...data };
    } else {
      let temp_payload = { ...payload };
      delete temp_payload?.[id];
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...filterDynamicQuery(temp_payload, propertiesPayload),
        }).toString(),
      });
      return { ...temp_payload };
    }
  };

  // filter for checkbox field type
  const handleCheckboxFilterHandler = (data, id, payload) => {
    let queryPayload = { [`cb-${id}`]: data?.[`cb-${id}`] };

    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...filterDynamicQuery(payload, propertiesPayload),
        ...queryPayload,
      }).toString(),
    });
    return { ...payload, ...data };
  };

  // Filters UI
  const ownerFilterUI = (
    <div>
      <div style={{ padding: "16px" }}>
        <AutoCompleteCustom
          size={"sm36"}
          dropdownId="deal_owner_filter"
          items={tenantUsersListSelector?.map((item) => {
            return {
              label: `${item?.first_name} ${item?.last_name}`,
              id: item?._id,
            };
          })}
          idState={dealOwnerFilterId}
          setIdState={setDealOwnerFilterId}
          valueState={dealOwnerFilterValue}
          setValueState={setDealOwnerFilterValue}
          onChange={(data) => {
            let arrData = [...data?.id];
            dispatch(
              filterMultipleList({
                ...filterMultipleListSelector,
                deal_owner: {
                  ...filterMultipleListSelector?.deal_owner,
                  anyOf: true,
                  anyOfList: [...arrData],
                },
                isApiCall: true,
              })
            );
          }}
          multiple
        />
      </div>
    </div>
  );

  const nameFilterUI = (
    <div className={classes.nameFilterContainer}>
      <InputField
        size={"sm36"}
        onKeyDown={(e) => e.stopPropagation()}
        placeholder={STRINGS.SEARCH}
        onChange={(e) => {
          setDealNameFilter(e?.target?.value);
        }}
        value={dealNameFilter}
      />
    </div>
  );

  const amountFilterUI = (
    <div>
      <FilterSlider filterName={STRINGS.DEAL_AMOUNT_SMALL} />
    </div>
  );

  const associatedContactFilterUI = (
    <div>
      <FilterMultiple
        filterName={STRINGS.ASSOCIATED_CONTACT_SMALL}
        anyOfItems={associatedContact}
        notAnyOfItems={associatedContact}
      />
    </div>
  );

  // Getting Count of filters applied to display on filter tab.
  const getAppliedFilterCount = (query, filterType) => {
    let values = filterDynamicQuery(query, propertiesPayload) || {};

    if (
      (values?.exist_fields || [])?.indexOf(filterType) !== -1 ||
      (values?.not_exist_fields || [])?.indexOf(filterType) !== -1
    ) {
      return 1;
    } else if (
      filterType === STRINGS.ASSOCIATED_CONTACT_SMALL ||
      filterType === STRINGS.DEAL_OWNER_SMALL
    ) {
      return (
        (values?.[filterType] || [])?.length +
        (values?.[`${filterType}-not`] || [])?.length
      );
    } else if (
      values?.[`max-${filterType}`]?.toString() &&
      values?.[`min-${filterType}`]?.toString()
    ) {
      return 1;
    } else if (filterType === STRINGS.DEAL_NAME_SMALL) {
      return values?.[filterType]?.length ? 1 : 0;
    } else if (
      `${filterType}` === STRINGS.EMPLOYEE_NUMBER_SMALL ||
      `${filterType}` === STRINGS.SENIORITY_SMALL
    ) {
      return values?.[`cb-${filterType}`]?.length
        ? values?.[`cb-${filterType}`]?.length
        : 0;
    } else {
      return (
        (values?.[filterType] || [])?.length +
        (values?.[`${filterType}-not`] || [])?.length
      );
    }
  };

  // clear all filters.
  const handleClearAllFilter = () => {
    // for company name filter
    HandleClearAllFilterFromStore(dispatch, location, navigate);
    dispatch(getDealsList({ query: {} }, navigate));
    setDealNameFilter("");
    setSearchField("");
    setOrderId([]);
    setOrderLabel([]);
    setSortById([]);
    setSortByLabel([]);

  };

  const filterTabOnClickHandler = (e, filterTab) => {
    e?.preventDefault();
    setFilterTabAnchor(e?.currentTarget);
    setSelectedFilter(filterTab?.id);
  };

  const filterTabValue = [
    {
      label: STRINGS.DEAL_OWNER,
      id: 0,
      filterUI: ownerFilterUI,
      filter_id: STRINGS.DEAL_OWNER_SMALL,
    },
    {
      label: STRINGS.DEAL_NAME,
      id: 1,
      filterUI: nameFilterUI,
      filter_id: STRINGS.DEAL_NAME_SMALL,
    },
    {
      label: STRINGS.DEAL_AMOUNT,
      id: 2,
      filterUI: amountFilterUI,
      filter_id: STRINGS.DEAL_AMOUNT_SMALL,
    },
    {
      label: STRINGS.ASSOCIATED_CONTACT,
      id: 3,
      filterUI: associatedContactFilterUI,
      filter_id: STRINGS.ASSOCIATED_CONTACT_SMALL,
    },
  ];

  useEffect(() => {
    if (
      Object.keys(filterMultipleListSelector || {})?.length &&
      Object.keys(propertiesPayload || {})?.length
    ) {
      let payload = { ...(allDynamicFiltersSelector || {}) };
      let api_call = false;
      for (const temp_id in filterMultipleListSelector) {
        let id = temp_id?.replace("cb-", "");
        switch (propertiesPayload?.[id]?.field_type[0]) {
          case STRINGS.NUMBER:
            api_call = true;
            payload = handleNumberFilterHandler(
              filterMultipleListSelector,
              id,
              payload
            );
            break;
          case STRINGS.DATE_PICKER:
            api_call = true;
            payload = handleDateFilterHandler(
              filterMultipleListSelector,
              id,
              payload
            );
            break;
          case STRINGS.MULTIPLE_CHECKBOX:
            api_call = true;
            payload = handleCheckboxFilterHandler(
              filterMultipleListSelector,
              id,
              payload
            );
            break;
          default:
            if (
              filterMultipleListSelector?.isApiCall ||
              id === STRINGS.DEAL_NAME_SMALL
            ) {
              api_call = true;
            }
            payload = handleAllFiltersQuery(
              filterMultipleListSelector,
              id,
              payload
            );
            break;
        }
      }
      if (api_call) {
        dispatch(allDynamicFilters({ ...payload }, false));
      }
    }

    // storing deal owner lists
    if (
      filterMultipleListSelector?.deal_owner?.anyOf &&
      filterMultipleListSelector?.deal_owner?.anyOfList?.length
    ) {
      let filtered_list = [
        ...filterMultipleListSelector?.deal_owner?.anyOfList,
      ];

      let all_users = tenantUsersListSelector?.map((item) => {
        return {
          label: `${item?.first_name} ${item?.last_name}`,
          id: item?._id,
        };
      });

      let arrLabelData = [];
      let arrIdData = [];
      all_users?.map((user) => {
        if (filtered_list?.includes(user?.id)) {
          arrLabelData.push(user.label);
          arrIdData.push(user.id);
        }
      });
      setDealOwnerFilterValue(arrLabelData);
      setDealOwnerFilterId(arrIdData);
    } else {
      setDealOwnerFilterValue([]);
      setDealOwnerFilterId([]);
    }
  }, [filterMultipleListSelector]);

  useEffect(() => {
    let getData;
    if (dealNameFilter?.length) {

      getData = setTimeout(() => {
        dispatch(
          filterMultipleList({
            ...filterMultipleListSelector,
            deal_name: dealNameFilter,
          })
        );
      }, 300);
      return () => clearTimeout(getData);
    } else {
      let payload = { ...filterMultipleListSelector, isApiCall: true };
      delete payload?.deal_name;
      getData = setTimeout(() => {
        dispatch(filterMultipleList({ ...payload }));
      }, 300);
    }
  }, [dealNameFilter]);

  useEffect(() => {
    if (
      selectedFilter === 3 ||
      filterMultipleListSelector?.associated_contact?.anyOfList?.length ||
      filterMultipleListSelector?.associated_contact?.notAnyOfList?.length
    ) {
      (async () => {
        try {
          const response = await postRequest(
            navigate,
            dealsEndpoints.getAssociatedContact,
            {
              record_ids: [
                ...(filterMultipleListSelector?.associated_contact?.anyOfList ||
                  []),
                ...(filterMultipleListSelector?.associated_contact
                  ?.notAnyOfList || []),
              ],
            }
          );
          let associated_contact = response?.data?.data?.records?.map(
            (element) => {
              return {
                id: element?._id,
                label: `${element?.first_name || ""} ${element?.last_name || ""}`,
              };
            }
          );
          setAssociatedContact(associated_contact);
        } catch (error) {
          console.log("Error----->>>>>>> ", error);
        }
      })();
    }
  }, [selectedFilter, filterMultipleListSelector]);

  return (
    <div className={classes.filterStripContainer}>
      {/* Filters of Table */}
      <div className={classes.filtersMainContainer}>
        <div className={classes.filtersContainer}>
          <div className={classes.filterLabel}>
            <TypographyInline
              color={GREY.SECONDARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={`${STRINGS.FILTER}:`}
              size={"sm"}
            />
          </div>
          {filterTabValue?.map((filterTab, index) => {
            return (
              <div key={filterTab?.id}>
                <FilterTab
                  isActive={selectedFilter === filterTab?.id}
                  label={filterTab?.label}
                  rightIcon={filterTab?.rightIcon || ICON_LABELS.ACTION_ARROW}
                  disabled={filterTab?.isDiabled}
                  id={filterTab?.id}
                  key={index}
                  onClick={(e) => filterTabOnClickHandler(e, filterTab)}
                  selectedValue={
                    parseInt(
                      getAppliedFilterCount(
                        allDynamicFiltersSelector,
                        filterTab.filter_id
                      )
                    )
                      ? parseInt(
                        getAppliedFilterCount(
                          allDynamicFiltersSelector,
                          filterTab.filter_id
                        )
                      )
                      : 0
                  }
                />
                <CustomStyledMenu
                  anchorEl={filterTabAnchor}
                  open={
                    selectedFilter === filterTab?.id && Boolean(filterTabAnchor)
                  }
                  arrow
                  onClose={() => {
                    setFilterTabAnchor(null);
                    setSelectedFilter(-1);
                  }}
                  noMargin={true}
                  isRightAlign={true}
                  shadow={SHADOW.md}
                  marginTop={"11px !important"}
                >
                  {filterTab?.filterUI}
                </CustomStyledMenu>
              </div>
            );
          })}

          {queryObjectLength > 0 ? (
            <div className={classes.addFilter2}>
              <Button
                size={"xs28"}
                LeftIcon={ICON_LABELS.CLOSE}
                label={STRINGS.CLEAR_ALL}
                variant={"text"}
                onClick={handleClearAllFilter}
              />
            </div>
          ) : null}
        </div>

        {/* Add Filter Modal */}
        <div className={classes.addFilterButton}>
          <AddFiltermodal
            dealNameFilter={dealNameFilter}
            setDealNameFilter={setDealNameFilter}
          />
        </div>
        {/* Add Filter Modal */}
      </div>
      {/* Filters of Table */}
    </div>
  );
};

export default FilterStrip;
