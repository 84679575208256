import {
    TEMPLATE_LIST_REQUEST,
    TEMPLATE_LIST_SUCCESS,
    TEMPLATE_LIST_FAIL,
    TEMPLATE_GET_REQUEST,
    TEMPLATE_GET_SUCCESS,
    TEMPLATE_GET_FAIL,
    GET_TEMPLATE_FILTERS_SUCCESS,
    GET_TEMPLATE_FILTERS_FAIL
} from "../constants/templateListConstants"
import { LOGIN_PATH } from "../../utils/constants/routes"
import { getRequest, postRequest } from "../../Apis"
import { templateEndpoints } from "../../utils/constants/httpConstants"

export const getTemplateList=(sentQuery, navigate)=> async(dispatch)=>{
    try{
         dispatch({type:TEMPLATE_LIST_REQUEST})
         const response=await postRequest(navigate,templateEndpoints.templateList,sentQuery)
         dispatch({
            type:TEMPLATE_LIST_SUCCESS,
            payload:{...response?.data,query:sentQuery}
         })
    }
    catch(err){
        if(err?.status === 401){
            navigate( LOGIN_PATH )
          }
        dispatch({
            type:TEMPLATE_LIST_FAIL,
            payload:err
        })
    }
}
export const getAllTemplateData=(navigate) => async(dispatch)=>{
    try{
       dispatch({type:TEMPLATE_GET_REQUEST})
       const response=await getRequest(navigate,templateEndpoints.getALLTemp)
       dispatch(
        {
            type:TEMPLATE_GET_SUCCESS,
            payload:{data:response?.data}
        }
       )
    }
    catch(error){
        if(error?.status === 401){
            navigate( LOGIN_PATH )
          }
        dispatch({
            type:TEMPLATE_GET_FAIL,
            payload:error
        })
    }
}

export const allTemplateFilters = (sentQuery) => async (dispatch) => {
    try {
      dispatch({
        type: GET_TEMPLATE_FILTERS_SUCCESS,
        payload: sentQuery,
      });
    } catch (err) {
      dispatch({ type: GET_TEMPLATE_FILTERS_FAIL, payload: err });
    }
  };