import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularLoader } from "..";
import { Box } from "@mui/material";
import { deleteRequest } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Logger } from "../../utils/helpers";
import Typography from "../Typography/Typography";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import CustomModal from "./CustomModal";
import { Button } from "../Buttons";
import { STRINGS } from "./strings";
import TypographyInline from "../Typography/TypographyInline";

const styles = makeStyles({
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "28vw",
    borderRadius: "12px",
  },
  headerModal: {
    height: "72px",
    background: "#D3DAE3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerData1: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginLeft: "18px",
  },
  headerData2: {
    color: "#626B7E",
    marginRight: "18px",
    fontWeight: "600",
    cursor: "pointer",
  },
  delContent: {
    height: "90px",
    display: "flex",
    paddingLeft: "18px",
    paddingTop: "32px",
  },
  delContentWithin: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#626B7E",
    lineHeight: "17px",
  },
  delFooter: {
    height: "66px",
    borderTop: "1px solid #D3DAE3",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cnclBtn: {
    marginRight: "10px",
    height: "37px",
    borderRadius: "6px",
    width: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    border: "1px solid #D3DAE3",
    color: "#626B7E",
    cursor: "pointer",
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },
  },
  crtBtn: {
    marginRight: "18px",
    height: "37px",
    color: "#FFFFFF",
    background: "#FF4848",
    borderRadius: "6px",
    width: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    cursor: "pointer",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  containBtns: {
    paddingTop: "1rem",
    justifyContent: "flex-end",
    display: "flex",
  },
  closeBtn: {
    background: "white",
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "13px",
    border: "none",
    padding: "0.7rem 1.5rem",
    borderRadius: "4px",
    cursor: "pointer",
    height: "40px",
    alignSelf: "center",
  },
  containIcon: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "1rem",
  },
  para: {
    color: "#616A7D",
    fontSize: "15px",
    fontWeight: "500",
  },
  rowDaata: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
  },
});

const DeleteConfirmationModal = ({
  open,
  setOpen,
  endPoint,
  message,
  action,
  reFetchEndpoint,
  deletePayload,
  name,
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    try {
      setDeleting(true);
      const response = await deleteRequest(endPoint, deletePayload);
      Logger(response?.data);
      EventEmitter.emit(
        EMITTER_SUCCESS,
        response?.data?.message || response?.data?.data?.message
      );
      await dispatch(action(reFetchEndpoint, navigate, true));
      setDeleting(false);
      setOpen(false);
    } catch (error) {
      setDeleting(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      setOpen(false);
    }
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: `${STRINGS.DELETE} ${name}`,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBodyContainer}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.REGULAR}
        label={message}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  const modalFooterRightData = (
    <div className={classes.rowDaata}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.DELETE}
        size={"sm36"}
        variant={"primary"}
        onClick={handleDelete}
        isLoading={deleting}
      />
    </div>
  );
  return (
    <CustomModal
      open={open}
      variant={"error"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
    // <Box className={classes.modal}>
    //   <Box className={classes.headerModal}>
    //     <Box className={classes.headerData1}>Delete{` ${name}`}</Box>
    //     <Box className={classes.headerData2}>
    //       <ClearIcon onClick={() => setOpen(false)} />
    //     </Box>
    //   </Box>
    //   <Box className={classes.delContent}>
    //     <span className={classes.delContentWithin}>
    //       {message}
    //     </span>
    //   </Box>
    //   <Box className={classes.delFooter}>
    //     <Box className={classes.cnclBtn} onClick={() => setOpen(false)}>
    //       Cancel
    //     </Box>
    //     {deleting ? (
    //       <Box className={classes.crtBtn}>
    //         <CircularLoader size={20} />
    //       </Box>
    //     ) : (
    //       <Box className={classes.crtBtn} onClick={handleDelete}>
    //         Delete
    //       </Box>
    //     )}
    //   </Box>
    // </Box>
  );
};

export default DeleteConfirmationModal;
