import React, { useEffect, useState } from "react";
import classes from "./Crm.module.css";
import { MEETINGS_CRM } from "../../../utils/constants/assets";
import ContactDetailEmptyScreen from "./components/AccountDetailEmptyScreen";
import { STRINGS } from "../strings";
import { crmEndpoints } from "../../../utils/constants/httpConstants";
import { getRequest } from "../../../Apis";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { BRAND } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Buttons";
import ListingMeetingCard from "./components/ListingMeetingCard";
import SkeletonLoaderCRMScreens from "./components/SkeletonLoaderCRMScreens";
import CreateMeetingPopUp from "./components/CreateMeetingPopUp";
import CreateLogMeetingPopUp from "./components/CreateLogMeetingPopUp";
import { decompressString } from "../../../utils/helpers";

const AccountDetailMeetingScreen = ({
  contactDetails,
  contactId,
  meetingListData = [],
  screenLoading = false,
  totalMeetings = 0,
  getMeetingLogList = () => {},
  setMeetingListData = () => {},
}) => {
  const navigate = useNavigate();
  const [createFlag, setCreateFlag] = useState(false);
  const [createFlagLog, setCreateFlagLog] = useState(false);
  const [assigneeList, SetAssigneeList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const emptyScreenButtons = [
    {
      id: STRINGS.MEETINGS,
      label: STRINGS.CREATE_MEETINGS,
      disabled: !Object.keys(contactDetails).length,
      variant: "primary",
      onClick: () => {
        setCreateFlag(true);
      },
    },
    {
      id: STRINGS.MEETINGS,
      disabled: !Object.keys(contactDetails).length,
      label: STRINGS.LOG_MEETING,
      variant: "primary",
      onClick: () => {
        setCreateFlagLog(true);
      },
    },
  ];

  //Click for open the createNote PopUp

  const createTaskOpenHandler = () => {
    setCreateFlag(true);
  };

  const createLogMeetingOpenHandler = () => {
    setCreateFlagLog(true);
  };

  const onScrollApiHandler = () => {
    const scrollableDiv = document?.getElementsByClassName(
      STRINGS.ALL_MEETING_LIST
    )[0];
    const isAtBottom =
      scrollableDiv?.scrollTop >=
      scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;
    if (isAtBottom && totalMeetings > meetingListData?.length) {
      setPageCount(pageCount + 1);
      getMeetingLogList(true, pageCount + 1);
    }
  };

  useEffect(() => {
    if (contactDetails) {
      getMeetingLogList();
    }
  }, [contactDetails]);

  useEffect(() => {
    (async () => {
      let url2 = `${crmEndpoints.getEmailSuggestions}`;
      const response = await getRequest(navigate, url2);
      SetAssigneeList(response?.data?.data?.records);
    })();
  }, []);
  return (
    <div
      className={`${classes.noteContainer} ${STRINGS.ALL_MEETING_LIST}`}
      id="comments_body"
      onScroll={onScrollApiHandler}
    >
      {screenLoading ? (
        <SkeletonLoaderCRMScreens
          loaderHeight="42px"
          accordionLengths={10}
          buttonsLength={2}
        />
      ) : (
        <>
          {" "}
          {/* Checking the length of the Meeting list */}
          {meetingListData?.length ? (
            <div className={classes.taskListContainer}>
              <div className={classes.HeadTaskContainerSubHead}>
                <TypographyInline
                  label={STRINGS.ALL_MEETINGS}
                  size={"md"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={BRAND.SECONDARY}
                />
                <div className={classes.WIdthButton}>
                  <Button
                    label={STRINGS.CREATE_MEETING}
                    variant={"primary"}
                    size={"sm36"}
                    onClick={createTaskOpenHandler}
                  />
                  <Button
                    label={STRINGS.LOG_MEETING}
                    variant={"primary"}
                    size={"sm36"}
                    onClick={createLogMeetingOpenHandler}
                  />
                </div>
              </div>
              {/* Sending Meeting list to the ListingMeetingCard*/}
              {meetingListData?.map((logData, index) => {
                return (
                  <div key={`log${index}`}>
                    <ListingMeetingCard
                      contactId={contactId}
                      id={`log${index}`}
                      logData={
                        logData?.is_compressed
                          ? {
                              ...logData,
                              event_description: decodeURIComponent(
                                decompressString(logData.event_description)
                              ),
                            }
                          : logData
                      }
                      meetingListData={meetingListData}
                      setMeetingListData={setMeetingListData}
                      assigneeList={assigneeList}
                      expanded={!index}
                      contactDetails={contactDetails}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classes.TaskHead}>
              <ContactDetailEmptyScreen
                src={MEETINGS_CRM}
                label={STRINGS.MEETING_LABEL}
                buttonItems={emptyScreenButtons}
              />
            </div>
          )}
        </>
      )}

      {createFlag && (
        <CreateMeetingPopUp
          open={createFlag}
          close={setCreateFlag}
          contact_id={contactId}
          setMeetingListData={setMeetingListData}
          assigneeList={assigneeList}
          contactDetails={contactDetails}
          SetAssigneeList={SetAssigneeList}
          getMeetings={getMeetingLogList}
        />
      )}
      {createFlagLog && (
        <CreateLogMeetingPopUp
          open={createFlagLog}
          close={setCreateFlagLog}
          contact_id={contactId}
          setMeetingListData={setMeetingListData}
          assigneeList={assigneeList}
          contactDetails={contactDetails}
          SetAssigneeList={SetAssigneeList}
        />
      )}
    </div>
  );
};

export default AccountDetailMeetingScreen;
