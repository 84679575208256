export const initialQueryPayload = {
  job_title: [],
  job_title_not: [],
  industry: [],
  industry_not: [],
  employee_number: [],
  seniority: [],
  company_keywords: [],
  company_keywords_not: [],
  company_technologies: [],
  company_technologies_not: [],
  company_technologies_all: [],
  seo_description: [],
  seo_description_not: [],
  seo_description_all: [],
  email_verification: [],
  exist_fields: [],
  not_exist_fields: [],
  company_ids: [],
  company_ids_not: [],
};

export const initialDynamicQueryPayload = {
  // deal_owner: [],
  // deal_name: [],
  // deal_amount: [],
  // associated_contact: [],
  // exist_fields: [],
  // not_exist_fields: [],
};

export const stringQueryList = [
  "min_revenue",
  "max_revenue",
  "name",
  "sort",
  "direction",
  "page",
  "limit",
  "e_verify_max_date",
  "e_verify_min_date",
  "new_contacts",
  "search",
  "file_path",
  "updated_min_date",
  "updated_max_date",
];

export const filterHeaderJobTitle = {
  payload: {
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

export const filterHeaderIndustry = {
  payload: {
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

export const initialFilterPayload = {
  payload: {
    allOf: false,
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

export const initialCompanyNameFilterPayload = {
  anyOf: false,
  notAnyOf: false,
  isKnown: false,
  isUnknown: false,
};

