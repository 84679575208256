import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { allFilters } from "../../redux/actions/filterActions";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fromToDateValidation } from "../../utils/validations/inputValidations";
import ErrorMessage from "../Messages/ErrorMessage";
import TypographyInline from "../Typography/TypographyInline";
import { STRINGS } from "./strings";
import { fontWeight } from "../../utils/constants/UI";
import { GREY } from "../../utils/constants/colors";
import { Button } from "../Buttons";
import CustomDatePicker from "../DatePicker/CustomDatePicker";

const FilterTypeLastUpdatedAt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allFiltersSelector = useSelector((state) => state.allFilters);
  const [tempFrom, setTempFrom] = useState(null);
  const [fromDate, setFromDate] = useState(
    allFiltersSelector?.payload?.updated_min_date || null
  );
  const [toDate, setToDate] = useState(
    allFiltersSelector?.payload?.updated_max_date || ""
  );
  const [validation, setValidation] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedFormDate = fromDate;
    const formattedToDate = toDate;

    if (formattedFormDate && formattedToDate) {
      const validatedDates = fromToDateValidation(formattedFormDate, formattedToDate);
      if (validatedDates.isValid) {
        setValidation("");
        dispatch(
          allFilters({
            updated_min_date: formattedFormDate,
            updated_max_date: formattedToDate,
          })
        );
        navigate({
          pathname: location.pathname,
          search: createSearchParams({
            ...allFiltersSelector?.payload,
            updated_min_date: formattedFormDate,
            updated_max_date: formattedToDate,
          }).toString(),
        });
      } else {
        setValidation(validatedDates.message);
      }
    }
  };

  if (tempFrom !== allFiltersSelector?.payload?.updated_min_date) {
    setTempFrom(allFiltersSelector?.payload?.updated_min_date);
    setFromDate(allFiltersSelector?.payload?.updated_min_date || "");
    setToDate(allFiltersSelector?.payload?.updated_max_date || "");
  }

  useEffect(() => {
    if (tempFrom === allFiltersSelector?.payload?.updated_min_date) {
      setFromDate(tempFrom);
      setToDate(allFiltersSelector?.payload?.updated_max_date);
      setTempFrom(allFiltersSelector?.payload?.name);
    }
    // setName(queryString?.name)
  }, [allFiltersSelector]);

  const handleClearFilter = () => {
    const query = JSON.parse(JSON.stringify(allFiltersSelector?.payload));
    delete query?.updated_min_date;
    delete query?.updated_max_date;

    dispatch(allFilters(query, true));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...query,
      }).toString(),
    });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterContainer}>
        <Box sx={styles.containBtnUpper}>
          <Box sx={styles.containDateInput}>
            <TypographyInline
              label={STRINGS.FROM}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.PRIMARY}
            />

            <CustomDatePicker
              value={fromDate || null}
              onChange={(dateValue) => {
                setFromDate(`${dateValue?.$y}-${dateValue?.$M+1}-${dateValue?.$D}`);
              }}
              size={"sm36"}
            />

          </Box>
          <Box sx={styles.containDateInput}>
            <TypographyInline
              label={STRINGS.TO}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.PRIMARY}
            />
            <CustomDatePicker
              value={toDate || null}
              onChange={(dateValue) => {
                setToDate(`${dateValue?.$y}-${dateValue?.$M+1}-${dateValue?.$D}`);
              }}
              size={"sm36"}
            />

          </Box>
        </Box>
        <Box>{validation ? <ErrorMessage message={validation} /> : null}</Box>
        <Box sx={styles.containBtn}>
          {allFiltersSelector?.payload?.updated_min_date &&
            allFiltersSelector?.payload?.updated_max_date ? (
            <Button
              label={STRINGS.CLEAR}
              onClick={handleClearFilter}
              size={"xs28"}
              variant={"ghost"}
            />
          ) : null}
          <Button
            label={STRINGS.SUBMIT}
            onClick={handleSubmit}
            size={"xs28"}
            variant={"primary"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterTypeLastUpdatedAt;
