import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classes from "./DealsComp.module.css";
import { ICON_LABELS, fontSize, fontWeight } from '../../../../utils/constants/UI';
import TypographyInline from '../../../../components/Typography/TypographyInline';
import { BASE, BORDER, GREY } from '../../../../utils/constants/colors';
import { Button, IconButton } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { STRINGS } from '../../strings';
import { filterDynamicQuery, formatCurrency, hextToRgbaWithOpacity, timeStampToDate, timeStampToDateTime } from '../../../../utils/helpers';
import AvatarName from '../../../../components/Avatar/AvatarName';
import { useNavigate } from 'react-router-dom';
import { postRequest, putRequest } from '../../../../Apis';
import { crmEndpoints, dealsEndpoints } from '../../../../utils/constants/httpConstants';
import EventEmitter from '../../../../utils/emitter/EventEmitter';
import { EMITTER_ERROR, dealPriorityVariant } from '../../../../utils/constants';
import { DealsBoardLoader } from "./index";
import { IconComponent } from "../../../../components/Icon/index";
import { getDealsList } from '../../../../redux/actions/dealsActions';
import StatusChip from '../../../../components/StatusChip/StatusChip';

const DealsBoardView = ({ cardStyle, displayCardFooter = false, setAddDealOpen = () => { }, setClickedStage = () => { }, pipelineId = "", setIsFromBoardView = () => { } }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dealsListSelector = useSelector((state) => state.getDealsList);
    const allDynamicFiltersSelector = useSelector((state) => state?.allDynamicFilters?.payload);

    const [columns, setColumns] = useState({});
    const [columnsOrder, setColumnsOrder] = useState([]);
    const [collapsedCell, setCollapsedCell] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [isInitial, setIsInitial] = useState(true);

    // drag end functions it is called when a card is dragged/dropped into any column.
    const onDragEnd = async (result, columns, setColumns) => {
        setIsDragging(false);
        setIsFromBoardView(true);
        if (!result.destination) return;
        const { source, destination, draggableId } = result;

        // when we are dropping card into another column.
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
        }
        // when we are dropping card into same column.
        else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }

        const payload = {
            stage_id: parseInt(destination.droppableId),
            _id: draggableId,
        };
        //Edit Stage Api
        try {
            await putRequest(navigate, crmEndpoints?.editDealStage, payload);
            dispatch(getDealsList({ query: { ...allDynamicFiltersSelector } }, navigate, { pipeline_id: pipelineId?.toString() }, false, true));
        } catch (error) {
            console.log("Error---------------->>>>>>>>>>>>>>>>>>> ", error);
        }
    };

    // list style returnig the background color when we are dragging which will help in display the draggable area.
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? BORDER.LIGHT : BASE.TRANSPARENT,
    });

    // list style returnig the background color when we are dragging which will help in display the draggable area.
    const getCardStyle = (isDraggingOver, provided) => ({
        background: isDraggingOver ? BASE.BACKGROUND_LIGHT : BASE.WHITE,
        ...provided.draggableProps.style
    });

    useEffect(() => {
        (async () => {
            if (Object.keys(dealsListSelector?.data || {})?.includes(STRINGS.STAGES) && pipelineId?.length) {

                let records = [...dealsListSelector?.data?.hits];
                let stages = [...dealsListSelector?.data?.stages?.stages];
                let format_obj = {};
                let column_order_obj = [];

                // creating the format_obj having name of stages in the id key. 
                stages?.forEach((stage) => {
                    format_obj = {
                        ...format_obj,
                        [`${stage?._id}` || ""]: {
                            name: stage?.name,
                            color: stage?.color,
                            items: [],
                            order: stage?.order
                        }
                    };
                    column_order_obj = [
                        ...column_order_obj,
                        stage?._id
                    ]
                });

                if (isInitial) {
                    setColumnsOrder(column_order_obj)
                    setColumns(format_obj);
                }


                // storing the deals listing into the format_obj in the items of key for each id.
                records?.forEach(record => {
                    format_obj = {
                        ...format_obj,
                        [record?.stage_id]: {
                            ...format_obj?.[record?.stage_id],
                            items: [...format_obj?.[record?.stage_id]?.items || [], record],
                        }
                    }
                });


                // storing insight total key value in our format_obj
                if (pipelineId?.toString()?.length) {
                    try {
                        let url = dealsEndpoints.getDealInsights;
                        let filters = filterDynamicQuery({ ...allDynamicFiltersSelector });
                        const response = await postRequest(navigate, url, { filters, pipeline_id: pipelineId?.toString() });
                        for (const key in response?.data?.data) {
                            format_obj = {
                                ...format_obj,
                                [key]: {
                                    ...format_obj?.[key],
                                    total: response?.data?.data?.[key]
                                }
                            }
                        }
                    } catch (error) {
                        console.log("Error----->>>>>>>>>> ", error);
                        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
                    }
                }
                setColumnsOrder(column_order_obj)
                setColumns(format_obj);
                setIsInitial(false);
            }
        })();
    }, [dealsListSelector, pipelineId]);


    return (
        <div id='modal_body' className={classes.boardViewMainContainer}>
            <DragDropContext
                onDragStart={() => setIsDragging(true)}
                onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
                {dealsListSelector?.final_loading ? <DealsBoardLoader /> :
                    <>
                        {columnsOrder.map((columnId) => {
                            let column = columns?.[columnId] || {};
                            return Object.keys(column)?.length && (
                                <>
                                    {
                                        // code for collapsed cell.
                                        collapsedCell?.includes(columnId) ? <div key={column?._id} className={classes.transformDivMainClass} >
                                            <div style={{ backgroundColor: hextToRgbaWithOpacity(column?.color, "0.1") }} className={`${classes.stageLabelMainContainer} ${classes.transformDivClass}`} >
                                                <div className={classes.stageLabelContainer} >
                                                    <IconComponent iconLabel={ICON_LABELS.FIBER_MANUAL_RECORD} fontSize={fontSize.MIN} color={column?.color} />
                                                    <TypographyInline color={GREY.PRIMARY} label={column?.name} size="sm" fontWeight={fontWeight.SEMIBOLD} isEllipses />
                                                    <div style={{ backgroundColor: column?.color }} className={classes.selectedValueCOntainer}>
                                                        <TypographyInline
                                                            label={column?.items?.length}
                                                            size="xs"
                                                            color={BASE.WHITE}
                                                            fontWeight={fontWeight.SEMIBOLD}
                                                            isNoWrap
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <IconButton LeftIcon={ICON_LABELS.SETTINGS_ETHERNET} size="xs28" variant="ghost" onClick={() => {
                                                        let collapsed = [...collapsedCell];
                                                        let filter_data = collapsed?.filter(item => item !== columnId);
                                                        setCollapsedCell([...filter_data])
                                                    }} />
                                                </div>
                                            </div>
                                        </div> :
                                            // code for open cell
                                            <div className={classes.columnContainer} key={columnId}>
                                                {/* Stage Name Container */}
                                                <div style={{ backgroundColor: hextToRgbaWithOpacity(column?.color, "0.1") }} className={classes.stageLabelMainContainer} >
                                                    <div className={classes.stageLabelContainer} >
                                                        <IconComponent iconLabel={ICON_LABELS.FIBER_MANUAL_RECORD} fontSize={fontSize.MIN} color={column?.color} />
                                                        <TypographyInline color={GREY.PRIMARY} label={column?.name} size="sm" fontWeight={fontWeight.SEMIBOLD} isEllipses />
                                                        <div style={{ backgroundColor: column?.color }} className={classes.selectedValueCOntainer}>
                                                            <TypographyInline
                                                                label={column?.items?.length}
                                                                size="xs"
                                                                color={BASE.WHITE}
                                                                fontWeight={fontWeight.SEMIBOLD}
                                                                isNoWrap
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <IconButton LeftIcon={ICON_LABELS.SETTINGS_ETHERNET} size="xs28" variant="ghost" onClick={() => setCollapsedCell([...collapsedCell || [], columnId])} />
                                                    </div>
                                                </div>
                                                {/* cards listing */}
                                                <div id='modal_body' className={classes.dealCardColumnContainer}>
                                                    <Button LeftIcon={ICON_LABELS.ADD} label={STRINGS.ADD_DEAL} size={"sm36"} variant={"ghost"} onClick={() => { setAddDealOpen(true); setClickedStage(columnId) }} />
                                                    <Droppable droppableId={columnId} key={columnId}>
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <div className={classes.cardListingContainer}
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot?.isDraggingOver)}
                                                                >
                                                                    {
                                                                        <>
                                                                            {column?.items.map((item, index) => {
                                                                                return (
                                                                                    <Draggable
                                                                                        key={item?._id}
                                                                                        draggableId={item?._id}
                                                                                        index={index}
                                                                                    >
                                                                                        {(provided, snapshot) => {
                                                                                            return (
                                                                                                <div className={classes.dealCardContainer}
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps} style={getCardStyle(snapshot?.isDraggingOver, provided)}
                                                                                                >
                                                                                                    {/* deal name and created date */}
                                                                                                    <div className={`${cardStyle === STRINGS.DEFAULT ? classes.dealDetailContainer : classes.dealDetailCompactContainer} ${classes.bottomBorderCard}`} >
                                                                                                        <div className={classes.dealsLinkLabel} onClick={() => navigate(`/user/deals/${item?._id}?tabs=timeline`)}><TypographyInline color={GREY.PRIMARY} label={item?.deal_name} size={"sm"} fontWeight={fontWeight.SEMIBOLD} /></div>
                                                                                                        {item?.created_at ? <TypographyInline color={GREY.SECONDARY} label={timeStampToDateTime(item?.created_at)} size={"xs"} fontWeight={fontWeight.REGULAR} /> : null}
                                                                                                    </div>
                                                                                                    {/* deal details like amount, close date, etc.. */}
                                                                                                    <div className={`${cardStyle === STRINGS.DEFAULT ? classes.dealDetailContainer : classes.dealDetailCompactMiddleContainer} ${displayCardFooter ? classes.bottomBorderCard : ""}`} >
                                                                                                        <div className={classes.labelValueContainer} >
                                                                                                            <IconComponent color={GREY.TERTIARY} fontSize={fontSize.LG} iconLabel={ICON_LABELS.ATTACH_MONEY} />
                                                                                                            {cardStyle === STRINGS.DEFAULT && <TypographyInline color={GREY.TERTIARY} label={STRINGS.AMOUNT_COLON} size={"sm"} fontWeight={fontWeight.REGULAR} />}
                                                                                                            <TypographyInline color={GREY.PRIMARY} label={formatCurrency(item?.deal_amount)} size={"sm"} fontWeight={fontWeight.REGULAR} />
                                                                                                        </div>
                                                                                                        <div className={classes.labelValueContainer} >
                                                                                                            <IconComponent color={GREY.TERTIARY} fontSize={fontSize.LG} iconLabel={ICON_LABELS.TODAY} />
                                                                                                            {cardStyle === STRINGS.DEFAULT && <TypographyInline color={GREY.TERTIARY} label={STRINGS.CLOSE_DATE_COLON} size={"sm"} fontWeight={fontWeight.REGULAR} />}
                                                                                                            <TypographyInline color={GREY.PRIMARY} label={timeStampToDate(item?.closed_date)} size={"sm"} fontWeight={fontWeight.REGULAR} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* deal prioority, etc.. */}
                                                                                                    {
                                                                                                        displayCardFooter && <div className={cardStyle === STRINGS.DEFAULT ? classes.dealCardFooterContainer : classes.dealCardFooterCompactContainer} >
                                                                                                            {/* Avatars for deal owner and associated contact */}
                                                                                                            <div className={classes.avatarContainer} >
                                                                                                                <AvatarName name={item?.deal_owner_name?.toString() || ""} size={"tiny"} />
                                                                                                                {item?.associated_contact_name?.length ? <AvatarName name={item?.associated_contact_name?.toString() || ""} size={"tiny"} /> : null}
                                                                                                            </div>

                                                                                                            <div> <StatusChip label={item?.deal_priority} variant={dealPriorityVariant?.[item?.deal_priority]} /> </div>
                                                                                                        </div>
                                                                                                    }

                                                                                                </div>
                                                                                            );
                                                                                        }}
                                                                                    </Draggable>
                                                                                );
                                                                            })}
                                                                        </>
                                                                    }
                                                                    {provided.placeholder}
                                                                </div>
                                                            );
                                                        }}
                                                    </Droppable>
                                                </div>

                                                {/* insights data */} <div style={{ borderTop: `1px solid ${column?.color}` }} className={classes.insightMainContainer}>
                                                    <TypographyInline color={column?.color} label={`${STRINGS.TOTAL_COLON} ${formatCurrency(column?.total || 0)}`} size={"sm"} fontWeight={fontWeight.BOLD} />
                                                </div>
                                            </div>
                                    }
                                </>
                            );
                        })}
                    </>
                }

            </DragDropContext>
        </div >
    )
}

export default DealsBoardView;