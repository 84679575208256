import {
  MEETING_GET_REQUEST,
  MEETING_GET_SUCCESS,
  MEETING_GET_FAIL,
  MEETING_BOOKED_GET_REQUEST,
  MEETING_BOOKED_GET_SUCCESS,
  MEETING_BOOKED_GET_FAIL,
  GET_MEETING_FILTERS_REQUEST,
  GET_MEETING_FILTERS_SUCCESS,
  GET_MEETING_FILTERS_FAIL,
} from "../constants/meetingConstants";

export const getMeetingListReducer = (state = {}, action) => {
  switch (action.type) {
    case MEETING_GET_REQUEST:
      return { loading: true };
    case MEETING_GET_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case MEETING_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBookedMeetingReducer = (state = {}, action) => {
  switch (action.type) {
    case MEETING_BOOKED_GET_REQUEST:
      return { loading: true };
    case MEETING_BOOKED_GET_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case MEETING_BOOKED_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allMeetingFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MEETING_FILTERS_REQUEST:
      return { loading: true };
    case GET_MEETING_FILTERS_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case GET_MEETING_FILTERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
