import {
  GET_TASK_SEARCH_FAIL,
  GET_TASK_SEARCH_REQUEST,
  GET_TASK_SEARCH_SUCCESS,
  GET_TASK_TABLE_FAIL,
  GET_TASK_TABLE_REQUEST,
  GET_TASK_TABLE_SUCCESS,
  UPDATE_TASK_TABLE_REQUEST,
  UPDATE_TASK_TABLE_SUCCESS,
  UPDATE_TASK_TABLE_FAIL,
} from "../constants/taskConstants";

export const taskReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASK_TABLE_REQUEST:
      return { loading: true };
    case GET_TASK_TABLE_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_TASK_TABLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const taskSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASK_SEARCH_REQUEST:
      return { loading: true };
    case GET_TASK_SEARCH_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_TASK_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const taskUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TASK_TABLE_REQUEST:
      return { loading: true };
    case UPDATE_TASK_TABLE_SUCCESS:
      return { loading: false, payload: action.payload };
    case UPDATE_TASK_TABLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
