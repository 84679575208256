import {
  LIST_TABLE_REQUEST,
  LIST_TABLE_SUCCESS,
  LIST_TABLE_FAIL,
  SELECTED_SEQUENCE_DETAILS_SUCCESS,
  SELECTED_SEQUENCE_DETAILS_FAIL
} from "../constants/listTableConstants";
import { getRequest, postRequest } from "../../Apis";
import { LOGIN_PATH } from "../../utils/constants/routes";
import { sequenceEndPoints } from "../../utils/constants/httpConstants";

export const getSequenceList = (sentQuery, navigate) => async (dispatch) => {
  try { 
    dispatch({type:LIST_TABLE_REQUEST});
    const response = await postRequest(navigate, sequenceEndPoints.listingSequence, sentQuery)
    const statId=response?.data?.data?.data?.map((item) => item?._id);
    const ownerVal=response?.data?.data?.data?.map((item) => item?.user_id);
    const statResponse = await postRequest(
      navigate,
      sequenceEndPoints.statsSteps,
      {sequence_ids:statId}
    );
    const ownerData= await postRequest(navigate, sequenceEndPoints.ownerStats, {
      user_ids: ownerVal,
    });
    
    const dataVal1=response?.data?.data?.data?.map((item) => {
      return {
        ...item,
        deliver:statResponse?.data?.records?.[item["_id"]]?.deliver?.value || 0,
        bounce:statResponse?.data?.records?.[item["_id"]]?.bounce?.value || 0,
        click:statResponse?.data?.records?.[item["_id"]]?.click?.value || 0,
        lead:statResponse?.data?.records?.[item["_id"]]?.lead?.value || 0,
        meeting_book:statResponse?.data?.records?.[item["_id"]]?.meeting_book?.value || 0,
        open:statResponse?.data?.records?.[item["_id"]]?.open?.value || 0,
        reply:statResponse?.data?.records?.[item["_id"]]?.reply?.value || 0,
        sent:statResponse?.data?.records?.[item["_id"]]?.sent?.value || 0,
        unsubscribe:statResponse?.data?.records?.[item["_id"]]?.unsubscribe?.value || 0,
      }
    })
    const finalVal=dataVal1.map((item)=>{
       return{
        ...item,
        owner:ownerData?.data?.data?.userdata?.[item["user_id"]]
      }
    })
    
    dispatch({
      type:LIST_TABLE_SUCCESS,
      payload: {finalVal, query:sentQuery},
    });
  } catch (err) {
    if(err?.status === 401){
      navigate( LOGIN_PATH )
    }

    dispatch({ type:LIST_TABLE_FAIL , payload: err });
  }
};

export const getSearchList = (sentQuery, navigate) => async (dispatch) => {
  try { 
    dispatch({type:LIST_TABLE_REQUEST});
    
    const response = await getRequest(navigate, sequenceEndPoints.searchSequence+`?name=${sentQuery}`);      
    dispatch({
      type:LIST_TABLE_SUCCESS,
      payload: {...response?.data, query:sentQuery},
    });
  } catch (err) {

    if(err?.status === 401){
      navigate( LOGIN_PATH )
    }

    dispatch({ type:LIST_TABLE_FAIL , payload: err });
  }
};
export const selectedSequenceDetails = (payload, navigate) => async (dispatch) => {
  try { 
    dispatch({
      type: SELECTED_SEQUENCE_DETAILS_SUCCESS,
      payload: payload,
    });
  } catch (err) {
    if(err?.status === 401){
      navigate( LOGIN_PATH )
    }

    dispatch({ type: SELECTED_SEQUENCE_DETAILS_FAIL , payload: err });
  }
};
