import { Styles } from "../Styles";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const SetDefaultDashboardPopUp = (props) => {
  const classes = Styles();
  const updateHandle = () => {
    let payload = {
      dashboard_id: props.dashBoard?._id,
      dashboard_name: props.dashBoard?.dashboard_name,
    };
    
    props.updateDashboard(payload, props.handleDefaultPopUp,props);
  };

  const cancelHandle = () => {
    props.handleDefaultPopUp();
  };

  const modalHeaderData = {
    heading: {
      id: "set_default_modal",
      label: STRINGS.SET_DEFAULT_HEADER_LABEL,
    },
  };

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.SET_DEFAULT}
        size={"sm36"}
        variant={"primary"}
        onClick={updateHandle}
        isLoading={props.loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
      width={"27.865vw"}
    >
      <TypographyInline
        label={STRINGS.SET_DEFAULT_BODY_LABEL}
        size={"sm"}
        fontWeight={fontWeight.REGULAR}
        color={GREY.SECONDARY}
      />
    </CustomModal>
  );
};
