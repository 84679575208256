import React, { useState } from "react";
import { Box } from "@mui/material";
import AddFiltermodal from "./AddFilterModal";
import {
  CustomAutoComplete,
  NoSuggestionsAutoComplete,
} from "../../../components/Autocomplete";
import { leadsEndpoints } from "../../../utils/constants/httpConstants";
import { useSelector, useDispatch } from "react-redux";
import {
  allFilters,
  filterHeaderCompanyCity,
  filterHeaderCompanyCountry,
  filterHeaderCompanyKeywords,
  filterHeaderCompanyName,
  filterHeaderCompanySeo,
  filterHeaderCompanyState,
  filterHeaderCompanyTechnologies,
  filterHeaderEmailVerification,
  filterHeaderPersonCity,
  filterHeaderPersonCountry,
  filterHeaderPersonState,
  filterHeaderSearchCompany,
  filterHeaderSearchIndustry,
  filterHeaderSearchJobTitle,
  filterHeaderSearchSeniority,
} from "../../../redux/actions/filterActions";
import { filterSelectedIndexes, getUniqueListBy } from "../../../utils/helpers";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  filterHeaderIndustry,
  initialFilterPayload,
  initialQueryPayload,
} from "../../../redux/store/storeConstants";
import * as qs from "qs";
import {
  DEFAULT_COMPANY_EMPLOYEES_COUNT,
  DEFAULT_EMAIL_VERIFICATION_TYPES,
  DEFAULT_SENIORIRT_TYPES,
  requiredKeysOfStorage,
} from "../../../utils/constants";
import { filterKeys } from "../../../utils/constants/filters";
import { companyDataFetch, tableDataFetch } from "../../../redux/actions/tableActions";
import FilterTab from "../../../components/FilterTab/FilterTab";
import { ICON_LABELS, SHADOW, fontWeight } from "../../../utils/constants/UI";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";
import classes from "./LeadsComponent.module.css";
import { HandleClearAllFilterFromStore } from "../../../redux/store/storeHelper";
import { paginationDataAction } from "../../../redux/actions/customTableActions";

//Component starts here.
const CompanyFilterStrip = ({
  isOpenFromOutside = false,
  setOpenFromOutside,
  clearSearch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const queryObjectLength = Object.keys(queryObject || {}).length;
  const companyHeaderSelector = useSelector(
    (state) => state?.filterHeaderCompany?.payload
  );
  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);
  const selectedIndustryFilter = useSelector(
    (state) => state.filterHeaderIndustry?.payload
  );
  const selectedTechnologyFilter = useSelector(
    (state) => state.filterHeaderCompanyTechnologies?.payload
  );
  const selectedKeywordsFilter = useSelector(
    (state) => state.filterHeaderCompanyKeywords?.payload
  );
  const companyTableEndpointChange = useSelector(
    (state) => state.companyTableEndpointChange
  );
  const [selectedFilter, setSelectedFilter] = useState(-1);
  const [filterTabEnchor, setFilterTabEnchor] = useState(null);
  const [selectedId, setSelectedId] = useState(false);

  const handleFilterSelection = (filter) => {
    filter.id === selectedFilter
      ? setSelectedFilter(-1)
      : setSelectedFilter(filter.id);
  };

  const handleClearAllFilter = async () => {
    // for company name filter
    clearSearch("");
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!requiredKeysOfStorage?.includes(key)) {
        localStorage.removeItem(key);
      }
    }

    HandleClearAllFilterFromStore(dispatch, location, navigate)
    dispatch(paginationDataAction({}));
    dispatch(tableDataFetch({}, navigate));
    if (companyTableEndpointChange?.payload === STRINGS.SAVED_COMPANIES) {
      dispatch(companyDataFetch({}, navigate));
    }
  };

  const handleExistFieldsIndustry = (
    existing_type,
    filter,
    queryInsertKey,
    notFilterKey
  ) => {
    let payload = {
      ...filterHeaderIndustry?.payload,
      [existing_type]: !selectedIndustryFilter[existing_type],
    };

    dispatch(filterHeaderSearchIndustry(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [queryInsertKey]: [...allFiltersSelector?.[queryInsertKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + STRINGS.NOT]: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [queryInsertKey]: [...allFiltersSelector?.[queryInsertKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  const handleExistFieldsKeywords = (
    existing_type,
    filter,
    filterKey,
    notFilterKey
  ) => {
    let payload = {
      ...initialFilterPayload?.payload,
      [existing_type]: !selectedKeywordsFilter[existing_type],
    };

    payload = JSON.parse(JSON.stringify(payload));
    dispatch(filterHeaderCompanyKeywords(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + STRINGS.NOT]: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  const handleIndustryChange = (item, filter) => {
    let payload = {
      ...selectedIndustryFilter,
      [item]: !selectedIndustryFilter[item],
      isKnown: false,
      isUnknown: false,
    };
    dispatch(
      allFilters({
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );

    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });

    dispatch(filterHeaderSearchIndustry(payload));
  };

  const handleCompanyKeywordsChange = (item, filter) => {
    let payload = {
      ...selectedKeywordsFilter,
      [item]: !selectedKeywordsFilter[item],
      isKnown: false,
      isUnknown: false,
    };
    dispatch(
      allFilters({
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });

    dispatch(filterHeaderCompanyKeywords(payload));
  };

  const handleExistFieldsTechnologies = (
    existing_type,
    filter,
    filterKey,
    notFilterKey
  ) => {
    // existing_type = isKnown/ isUnknown
    // filter = job_title/industry/ seniority..

    let payload = {
      ...initialFilterPayload?.payload,
      [existing_type]: !selectedTechnologyFilter[existing_type],
    };

    payload = JSON.parse(JSON.stringify(payload));
    dispatch(filterHeaderCompanyTechnologies(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + STRINGS.NOT]: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  const handleCompanyTechnologiesChange = (item, filter) => {
    let payload = {
      ...selectedTechnologyFilter,
      [item]: !selectedTechnologyFilter[item],
      isKnown: false,
      isUnknown: false,
    };
    dispatch(
      allFilters({
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });

    dispatch(filterHeaderCompanyTechnologies(payload));
  };

  const handleCompanyChange = (item) => {
    item.isChecked = !item.isChecked;
    const result = getUniqueListBy(
      [...companyHeaderSelector, item],
      STRINGS.INDEX
    );
    dispatch(filterHeaderSearchCompany(result));
    const new_employee_number = filterSelectedIndexes(result, STRINGS.INDEX);
    const resultant = [...new_employee_number];
    const payload = [...new Set(resultant)];
    const queryPayload = { employee_number: payload };
    dispatch(allFilters(queryPayload));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        employee_number: payload,
      }).toString(),
    });
  };

  const companyTooltip = (
    <div className={classes.companyContainer}>
      {companyHeaderSelector?.map((item) => {
        return (
          <div key={item.type} className={classes.containCompanyCheckbox}>
            <Checkbox
              checked={item.isChecked}
              onChange={() => handleCompanyChange(item)}
              label={item.type}
            />
          </div>
        );
      })}
    </div>
  );

  const industryTooltip = (
    <Box className={classes.filter}>
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedIndustryFilter?.anyOf}
          onChange={() =>
            handleIndustryChange(STRINGS.ANY_OF, filterKeys.industry)
          }
          padding="0.3rem"
          label={STRINGS.IS_ANY_OF}
        />
      </div>
      {selectedIndustryFilter.anyOf ? (
        <div className={classes.pad8}>
          <CustomAutoComplete
            endPoint={leadsEndpoints.getIndustryFilters}
            filter={filterKeys.industry}
            type=""
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedIndustryFilter?.notAnyOf}
          onChange={() =>
            handleIndustryChange(STRINGS.NOT_ANY_OF, filterKeys.industry)
          }
          label={STRINGS.IS_NOT_ANY_OF}
        />
      </div>
      {selectedIndustryFilter.notAnyOf ? (
        <div className={classes.pad8}>
          <CustomAutoComplete
            endPoint={leadsEndpoints.getIndustryFilters}
            filter={filterKeys.industry}
            type={STRINGS.NOT}
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedIndustryFilter?.isKnown}
          onChange={() =>
            handleExistFieldsIndustry(
              STRINGS.IS_KNOWN,
              filterKeys.industry,
              STRINGS.EXIST_FILEDS,
              STRINGS.NOT_EXIST_FIELDS
            )
          }
          label={STRINGS.IS_KNOWN_LABEL}
        />
      </div>

      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedIndustryFilter?.isUnknown}
          onClick={() =>
            handleExistFieldsIndustry(
              STRINGS.IS_UNKNOWN,
              filterKeys.industry,
              STRINGS.NOT_EXIST_FIELDS,
              STRINGS.EXIST_FILEDS
            )
          }
          label={STRINGS.IS_UNKNOWN_LABEL}
        />
      </div>
    </Box>
  );

  const companyKeywordsTooltip = (
    <Box className={classes.filter}>
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedKeywordsFilter?.anyOf}
          onChange={() =>
            handleCompanyKeywordsChange(
              STRINGS.ANY_OF,
              filterKeys.company_keywords
            )
          }
          label={STRINGS.IS_ANY_OF}
        />
      </div>
      {selectedKeywordsFilter?.anyOf ? (
        <div className={classes.pad8}>
          <NoSuggestionsAutoComplete
            endPoint={leadsEndpoints.getIndustryFilters}
            filter={filterKeys.company_keywords}
            type=""
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedKeywordsFilter?.notAnyOf}
          onChange={() =>
            handleCompanyKeywordsChange(
              STRINGS.NOT_ANY_OF,
              filterKeys.company_keywords
            )
          }
          label={STRINGS.IS_NOT_ANY_OF}
        />
      </div>
      {selectedKeywordsFilter?.notAnyOf ? (
        <div className={classes.pad8}>
          <NoSuggestionsAutoComplete
            endPoint={leadsEndpoints.getIndustryFilters}
            filter={filterKeys.company_keywords}
            type={STRINGS.NOT}
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedKeywordsFilter?.isKnown}
          onChange={() =>
            handleExistFieldsKeywords(
              STRINGS.IS_KNOWN,
              filterKeys.company_keywords,
              STRINGS.EXIST_FILEDS,
              STRINGS.NOT_EXIST_FIELDS
            )
          }
          label={STRINGS.IS_KNOWN_LABEL}
        />
      </div>

      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedKeywordsFilter?.isUnknown}
          onChange={() =>
            handleExistFieldsKeywords(
              STRINGS.IS_UNKNOWN,

              filterKeys.company_keywords,
              STRINGS.NOT_EXIST_FIELDS,
              STRINGS.EXIST_FILEDS
            )
          }
          label={STRINGS.IS_UNKNOWN_LABEL}
        />
      </div>
    </Box>
  );

  const technologyTooltip = (
    <Box className={classes.filter}>
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedTechnologyFilter?.anyOf}
          onChange={() =>
            handleCompanyTechnologiesChange(
              STRINGS.ANY_OF,
              filterKeys.company_technologies
            )
          }
          label={STRINGS.IS_ANY_OF}
        />
      </div>
      {selectedTechnologyFilter?.anyOf ? (
        <div className={classes.pad8}>
          <CustomAutoComplete
            endPoint={leadsEndpoints.getCompanyTechnologiesFilter}
            filter={filterKeys.company_technologies}
            type=""
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedTechnologyFilter?.notAnyOf}
          onChange={() =>
            handleCompanyTechnologiesChange(
              STRINGS.NOT_ANY_OF,
              filterKeys.company_technologies
            )
          }
          label={STRINGS.IS_NOT_ANY_OF}
        />
      </div>
      {selectedTechnologyFilter?.notAnyOf ? (
        <div className={classes.pad8}>
          <CustomAutoComplete
            endPoint={leadsEndpoints.getCompanyTechnologiesFilter}
            filter={filterKeys.company_technologies}
            type={STRINGS.NOT}
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedTechnologyFilter?.isKnown}
          onChange={() =>
            handleExistFieldsTechnologies(
              STRINGS.IS_KNOWN,
              filterKeys.company_technologies,
              STRINGS.EXIST_FILEDS,
              STRINGS.NOT_EXIST_FIELDS
            )
          }
          label={STRINGS.IS_KNOWN_LABEL}
        />
      </div>

      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={selectedTechnologyFilter?.isUnknown}
          onChange={() =>
            handleExistFieldsTechnologies(
              STRINGS.IS_UNKNOWN,

              filterKeys.company_technologies,
              STRINGS.NOT_EXIST_FIELDS,
              STRINGS.EXIST_FILEDS
            )
          }
          label={STRINGS.IS_UNKNOWN_LABEL}
        />
      </div>
    </Box>
  );

  const FILTERS_LIST = [
    {
      filterName: STRINGS.COMPANY_SIZE,
      id: 4,
      toolTip: companyTooltip,
      filter_id: STRINGS.EMPLOYEE_NUMBER,
    },
    {
      filterName: STRINGS.INDUSTRY_CAPS,
      id: 5,
      toolTip: industryTooltip,
      filter_id: STRINGS.INDUSTRY,
    },
    {
      filterName: STRINGS.KEYWORDS,
      id: 2,
      toolTip: companyKeywordsTooltip,
      filter_id: filterKeys.company_keywords,
    },
    {
      filterName: STRINGS.TECHNOLOGIES,
      id: 3,
      toolTip: technologyTooltip,
      filter_id: filterKeys.company_technologies,
    },
  ];

  const getAppliedFilterCount = (values, filterType) => {
    if (
      values?.exist_fields.indexOf(filterType) !== -1 ||
      values?.not_exist_fields.indexOf(filterType) !== -1
    ) {
      return 1;
    }

    if (
      filterType === STRINGS.JOB_TITLE_SMALL ||
      filterType === STRINGS.INDUSTRY_CAPS ||
      filterType === filterKeys.company_technologies ||
      filterType === filterKeys.company_keywords
    ) {
      return (
        values?.[filterType]?.length +
        values?.[filterType + STRINGS.NOT]?.length
      );
    }

    if (typeof values?.[filterType] === STRINGS.OBJECT) {
      return values?.[filterType]?.length;
    } else {
      return 1;
    }
  };

  return (
    <Box component={STRINGS.MAIN} className={classes.container1}>
      <Box className={classes.stripContainer}>
        <Box className={classes.filtersContainer}>
          <div className={classes.filterLabel}>
            <TypographyInline
              color={GREY.SECONDARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={`${STRINGS.FILTER}:`}
              size={"sm"}
            />
          </div>
          {FILTERS_LIST.map((filter) => {
            return (
              <div key={filter.id}>
                <FilterTab
                  isActive={filter.id === selectedId}
                  label={filter?.filterName}
                  rightIcon={ICON_LABELS.ACTION_ARROW}
                  id={filter?.id}
                  key={filter?.filter_id}
                  onClick={(e) => {
                    setFilterTabEnchor(e?.currentTarget);
                    handleFilterSelection(filter);
                    setSelectedId(filter.id);
                  }}
                  selectedValue={
                    parseInt(
                      getAppliedFilterCount(
                        allFiltersSelector,
                        filter.filter_id
                      )
                    )
                      ? getAppliedFilterCount(
                        allFiltersSelector,
                        filter.filter_id
                      )
                      : ""
                  }
                />
                <CustomStyledMenu
                  anchorEl={filterTabEnchor}
                  open={
                    selectedFilter === filter.id && Boolean(filterTabEnchor)
                  }
                  arrow
                  onClose={() => {
                    setFilterTabEnchor(null);
                    setSelectedFilter(-1);
                    setSelectedId(-1);
                  }}
                  noMargin={true}
                  isRightAlign={true}
                  shadow={SHADOW.md}
                  marginTop={"11px !important"}
                >
                  {filter.toolTip}
                </CustomStyledMenu>
              </div>
            );
          })}

          {queryObjectLength > 0 ? (
            <div className={classes.addFilter2}>
              <Button
                size={"xs28"}
                LeftIcon={ICON_LABELS.CLOSE}
                label={STRINGS.CLEAR_ALL}
                variant={"text"}
                onClick={handleClearAllFilter}
              />
            </div>
          ) : null}
        </Box>
        <AddFiltermodal
          leadType={STRINGS.COMPANY}
          isOpenFromOutside={isOpenFromOutside}
          setOpenFromOutside={setOpenFromOutside}
        />
      </Box>
    </Box>
  );
};

export default CompanyFilterStrip;
