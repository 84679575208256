export const IMAGE_BASE_URL =
  "https://images.reachiq.ai/assets";
//Icons

export const EDIT_COLUMNS_ICON = IMAGE_BASE_URL + "/assets/icons/Columns.svg";
export const CHECK_ICON = IMAGE_BASE_URL + "/assets/icons/Check.svg";
export const CHECK_ORANGE_ICON =
  IMAGE_BASE_URL + "/assets/icons/check_orange.svg";
export const SORT_BY_ICON = IMAGE_BASE_URL + "/assets/icons/SortByIcon.svg";
export const POLYGON_ICON = IMAGE_BASE_URL + "/assets/icons/Polygon.svg";
export const CROWN_ICON = IMAGE_BASE_URL + "/assets/icons/crown.svg";
export const EMAIL_ICON = IMAGE_BASE_URL + "/assets/icons/email.svg";
export const PHONE_ICON = IMAGE_BASE_URL + "/assets/icons/phone.svg";
export const TECHNOLOGY_ICON = IMAGE_BASE_URL + "/assets/icons/technology.svg";
export const RESET_ICON = IMAGE_BASE_URL + "/assets/icons/reset.svg";
export const USERS_ICON = IMAGE_BASE_URL + "/assets/icons/users.svg";
export const KEY_ICON = IMAGE_BASE_URL + "/assets/icons/key.svg";
export const LOGIN_CROSS_ICON =
  IMAGE_BASE_URL + "/assets/icons/login-cross.svg";
export const GOOGLE_ICON = IMAGE_BASE_URL + "/assets/icons/google.svg";
export const USER_ICON = IMAGE_BASE_URL + "/assets/icons/UserIcon.svg";
export const EDIT_ICON = IMAGE_BASE_URL + "/assets/icons/edit.svg";
export const THEME_ICON = IMAGE_BASE_URL + "/assets/icons/theme.svg";

export const SNACKBAR_CLOSE_ICON =
  IMAGE_BASE_URL + "/assets/icons/snackbar_close_icon.svg";

export const BOOKMARK_ICON = IMAGE_BASE_URL + "/assets/icons/bookmark_icon.svg";
export const VERIFIED_USER_ICON =
  IMAGE_BASE_URL + "/assets/icons/verified_user.svg";

//Images
export const SORRY_IMAGE = IMAGE_BASE_URL + "/assets/images/Sorry.gif";

export const USER_IMAGE = IMAGE_BASE_URL + "/assets/images/user.jpg";
export const DARK_THEME_IMAGE = IMAGE_BASE_URL + "/assets/images/darkTheme.png";
export const LIGHT_THEME_IMAGE =
  IMAGE_BASE_URL + "/assets/images/lightTheme.png";
export const AUTO_THEME_IMAGE = IMAGE_BASE_URL + "/assets/images/autoTheme.png";
export const LOGIN_ANALYTICS_IMAGE =
  IMAGE_BASE_URL + "/assets/images/login-analytics.png";

export const LOGIN_REACHIQ_LOGO =
  IMAGE_BASE_URL + "/assets/images/login_reachiq.svg";
export const NEW_LOGIN_REACHIQ_LOGO =
  IMAGE_BASE_URL + "/assets/images/new_logo_reachiq.svg";
export const LOCK_SVG = IMAGE_BASE_URL + "/assets/images/lock.svg";

export const USA_FLAG = IMAGE_BASE_URL + "/assets/images/usa-flag.png";
export const INDIA_FLAG = IMAGE_BASE_URL + "/assets/images/india-flag.jpeg";
export const DONE_IMAGE = IMAGE_BASE_URL + "/assets/images/done.png";
export const MAIL_IMAGE = IMAGE_BASE_URL + "/assets/images/mail.png";
export const SEARCH_IMAGE = IMAGE_BASE_URL + "/assets/images/search-icon.svg";

export const VIEWS_ACTION_IMAGE =
  IMAGE_BASE_URL + "/assets/images/viewsAction.svg";
export const NO_RECORD_IMAGE = IMAGE_BASE_URL + "/assets/images/noRecord.svg";
export const SEQUENCE_IMAGE =
  IMAGE_BASE_URL + "/assets/images/sequence_image.svg";
export const ADD_STEPS_IMAGE =
  IMAGE_BASE_URL + "/assets/images/steps_image.svg";
export const AUTOMATED_MAIL_IMAGE =
  IMAGE_BASE_URL + "/assets/images/automated_mail.svg";
export const AUTOMATED_CALL_IMAGE =
  IMAGE_BASE_URL + "/assets/images/automated_call.svg";
export const AUTOMATED_TODO_IMAGE =
  IMAGE_BASE_URL + "/assets/images/automated_todo.svg";
export const GREATER_THAN_ARROW_IMAGE =
  IMAGE_BASE_URL + "/images/GreaterThanArrow.png";

// gifs

export const DONE_GIF = IMAGE_BASE_URL + "/assets/gifs/done.gif";

export const DONENEW_GIF = IMAGE_BASE_URL + "/assets/images/Done.gif";
export const SEARCH_PROSPECTS_IMAGE =
  IMAGE_BASE_URL + "/assets/images/search_image.svg";

export const CALENDAR = IMAGE_BASE_URL + "/assets/images/CalendarPreview.svg";
export const LEFT_IMAGE = IMAGE_BASE_URL + "/assets/images/leftImage.svg";
export const RIGHT_IMAGE = IMAGE_BASE_URL + "/assets/images/rightImage.svg";

export const PIE_CHART_IMAGE = IMAGE_BASE_URL + "/assets/images/pieChart.svg";
export const LINE_CHART_IMAGE = IMAGE_BASE_URL + "/assets/images/lineChart.svg";
export const VBAR_CHART_IMAGE = IMAGE_BASE_URL + "/assets/images/vBarChart.svg";
export const HBAR_CHART_IMAGE = IMAGE_BASE_URL + "/assets/images/hBarChart.svg";
export const TABLE_CHART_IMAGE =
  IMAGE_BASE_URL + "/assets/images/tableChart.svg";
export const DUMMY_COMPANY_IMAGE =
  IMAGE_BASE_URL + "/assets/images/dummy_company.svg";
export const CAMPAIGN_IMAGE =
  IMAGE_BASE_URL + "/assets/images/campaign_image.svg";
export const PIN_ICON = IMAGE_BASE_URL + "/assets/icons/pin_icon.svg";
export const UNSUBSCRIBE_ICON =
  IMAGE_BASE_URL + "/assets/icons/message_remove.svg";
export const IMPORT_ICON = IMAGE_BASE_URL + "/assets/icons/import_icon.svg";
export const DRAG_FILE_ICON = IMAGE_BASE_URL + "/assets/icons/drag_file.svg";
export const CROSS_ICON = IMAGE_BASE_URL + "/assets/icons/cross_icon.svg";
export const GMAIL_ICON = IMAGE_BASE_URL + "/assets/icons/gmail_icon.svg";
export const OUTLOOK_ICON = IMAGE_BASE_URL + "/assets/icons/outlook_icon.svg";
export const SENDGRID_ICON = IMAGE_BASE_URL + "/assets/icons/sendgrid_icon.svg";
export const EMAIL_LOCK_ICON =
  IMAGE_BASE_URL + "/assets/icons/mail_lock_icon.svg";
export const SEQUENCE1_ICON = IMAGE_BASE_URL + "/assets/images/seqIcon.svg";
export const SALES_COMINGSOON = IMAGE_BASE_URL + "/assets/images/sale1.svg";
export const TOOLS_COMINGSOON = IMAGE_BASE_URL + "/assets/images/tool1.svg";
export const DASH_COMINGSOON = IMAGE_BASE_URL + "/assets/images/dash1.svg";
export const TEMPLATE_START = IMAGE_BASE_URL + "/assets/images/templateImg.svg";
export const SEQUENCE_FIRST = IMAGE_BASE_URL + "/assets/images/first.svg";
export const SEQUENCE_ICON = IMAGE_BASE_URL + "/assets/icons/sequence.svg";
export const RED_SEQUENCE_ICON =
  IMAGE_BASE_URL + "/assets/icons/red_sequence.svg";
export const REACHIQ_ICON = IMAGE_BASE_URL + "/assets/icons/REACHIQ_ICON.svg";
export const CREATEMEETING =
  IMAGE_BASE_URL + "/assets/images/createMeeting.svg";
export const TASKICON = IMAGE_BASE_URL + "/assets/images/taskIcon.svg";
export const LINKED = IMAGE_BASE_URL + "/assets/images/link.svg";
export const COPY_LINK = IMAGE_BASE_URL + "/assets/images/CopyLink.svg";
export const TICK_GREY = IMAGE_BASE_URL + "/assets/images/tickGrey.svg";
export const LEFT_CIRCLE = IMAGE_BASE_URL + "/assets/images/leftcircle.svg";
export const ORANGE_CIRCLE = IMAGE_BASE_URL + "/assets/images/orange.svg";
export const RIGHT_CIRCLE = IMAGE_BASE_URL + "/assets/images/right.svg";
export const EMAIL_OPENED = IMAGE_BASE_URL + "assestes/images/emailOpened.svg";
export const EMAIL_REPLIED = IMAGE_BASE_URL + "/assets/images/Emailreply.svg";
export const EMAIL_BOUNCE = IMAGE_BASE_URL + "/assets/images/EmailBounce.svg";
export const EMAIL_CLICKS = IMAGE_BASE_URL + "/assets/images/EmailClicks.svg";
export const EMAIL_DEL = IMAGE_BASE_URL + "/assets/images/EmailDel.svg";
export const EMAIL_UNSUBS = IMAGE_BASE_URL + "/assets/images/EmailUnsubs.svg";
export const EMAIL_SENT = IMAGE_BASE_URL + "/assets/images/EmailSent.svg";
export const PROFILE = IMAGE_BASE_URL + "/assets/images/profile.svg";
export const LOCATION = IMAGE_BASE_URL + "/assets/images/location.svg";

export const DESCRIPTION = IMAGE_BASE_URL + "/assets/images/Description.svg";

export const ELLIPSE = IMAGE_BASE_URL + "/assets/images/Ellipse 1.svg";
export const DATE = IMAGE_BASE_URL + "/assets/images/date.svg";
export const DURATIONS = IMAGE_BASE_URL + "/assets/images/Duration.svg";
export const TIME_ZONE = IMAGE_BASE_URL + "/assets/images/Timezone.svg";
export const TICK = IMAGE_BASE_URL + "/assets/images/tick.svg";
export const GREENTICK = IMAGE_BASE_URL + "/assets/images/greenCircle.svg";
export const RED = IMAGE_BASE_URL + "/assets/images/red.svg";
export const GREY_CIR = IMAGE_BASE_URL + "/assets/images/GREY.svg";

export const TIME = IMAGE_BASE_URL + "/assets/images/Time.svg";
export const Folder_LOGO = IMAGE_BASE_URL + "/assets/images/FolderIcon.svg";
export const VERTICAL = IMAGE_BASE_URL + "/assets/images/foldVertical.svg";
export const Folder_INIT = IMAGE_BASE_URL + "/assets/images/LogoTemp.svg";
export const ACTIVE_IMG = IMAGE_BASE_URL + "/assets/images/Active.svg";
export const DONE_IMG = IMAGE_BASE_URL + "/assets/images/Done.svg";
export const UNSELECTED_IMG = IMAGE_BASE_URL + "/assets/images/Unselected.svg";
export const MOVEIMAGE = IMAGE_BASE_URL + "/assets/images/movFold.svg";
export const GMAIL_IMG = IMAGE_BASE_URL + "/assets/images/gLogo.svg";
export const SEQ_CAMP = IMAGE_BASE_URL + "/assets/images/seqImg.svg";
export const LIST_TEMP = IMAGE_BASE_URL + "/assets/images/templateImg.svg";
export const VIEWALL = IMAGE_BASE_URL + "/assets/images/ViewAll.svg";
export const CHROME1 = IMAGE_BASE_URL + "/assets/images/Chrome1.png";
export const CHROMEEXTENSION = IMAGE_BASE_URL + "/assets/images/extension1.svg";
export const CHROMEEXTENSION1 =
  IMAGE_BASE_URL + "/assets/images/extension2.svg";
export const CHROMEEXTENSION2 =
  IMAGE_BASE_URL + "/assets/images/extension3.svg";
export const CHROMEEXTENSION3 =
  IMAGE_BASE_URL + "/assets/images/extension4.svg";

export const CHROME2 = IMAGE_BASE_URL + "/assets/images/Chrome2.png";
export const CHROME3 = IMAGE_BASE_URL + "/assets/images/Chrome3.png";
export const CHROME4 = IMAGE_BASE_URL + "/assets/images/Chrome4.png";
export const SNACKBAR_SUCCESS_ICON =
  IMAGE_BASE_URL + "/assets/icons/snackbar_success_icon.svg";
export const SNACKBAR_ERROR_ICON =
  IMAGE_BASE_URL + "/assets/icons/snackbar_error_icon.svg";
export const CSV = IMAGE_BASE_URL + "/assets/icons/csv.svg";

export const DASHBOARD_BG_IMAGE =
  IMAGE_BASE_URL + "/assets/images/dashboard-bg.svg";
export const DASHBOARD_EMPTY_IMAGE =
  IMAGE_BASE_URL + "/assets/images/emptydashboard.svg";
export const WIDGET_CORNER = IMAGE_BASE_URL + "/assets/images/widgetCorner.svg";
export const INSERT_IMAGE = IMAGE_BASE_URL + "/assets/images/insert_image.png";
export const DOWNLOAD = IMAGE_BASE_URL + "/assets/icons/download.svg";

export const BOARDING_GIFT = IMAGE_BASE_URL + "/assets/images/boardinggift.svg";
export const CHROME_EXTENSION =
  IMAGE_BASE_URL + "/assets/images/chromeextension.svg";
export const COMPLETE_ICON = IMAGE_BASE_URL + "/assets/icons/check.svg";
export const LANDING_PAGE = IMAGE_BASE_URL + "/assets/images/LANDING_PAGE.svg";
export const SAD_FACE = IMAGE_BASE_URL + "/assets/images/SAD_FACE.svg";

export const MAKE_DEFAULT_ICON =
  IMAGE_BASE_URL + "/assets/icons/make_default.svg";
export const MAKE_DEFAULT_WHITE_ICON =
  IMAGE_BASE_URL + "/assets/icons/make_default.svg";
export const NEW_PIN_ICON = IMAGE_BASE_URL + "/assets/icons/pin.svg";

export const SELECTED_SELECTED_COLUMNS =
  IMAGE_BASE_URL + "/assets/images/selected_selected_column.svg";
export const ALL_COLUMNS =
  IMAGE_BASE_URL + "/assets/images/selected_all_column.svg";
export const SELECTED_COLUMNS =
  IMAGE_BASE_URL + "/assets/images/selected_column.svg";
export const SELECTED_ALL_COLUMNS =
  IMAGE_BASE_URL + "/assets/images/all_column.svg";

export const SENDGRID_ICON_UPDATED =
  IMAGE_BASE_URL + "/assets/images/sendgridimage.png";
export const MAIL_ICON_UPDATED = IMAGE_BASE_URL + "/assets/images/mailgun.png";
export const GOOGLE_IMAGE_UPDATED =
  IMAGE_BASE_URL + "/assets/images/gmailimage.png";
export const DOWNCARROT_ICON = IMAGE_BASE_URL + "/assets/icons/downcarot.svg";
export const LOGIN_BACKGROUND =
  IMAGE_BASE_URL + "/assets/images/revo-login-bg.svg";

export const TICKED = IMAGE_BASE_URL + "/assets/icons/ticked.svg";
export const TICKEDGREY = IMAGE_BASE_URL + "/assets/icons/tickedgrey.svg";
export const ERROR = IMAGE_BASE_URL + "/assets/icons/Frame.svg";
export const SUCCESSONBOARD = IMAGE_BASE_URL + "/assets/images/thumbsup.gif";

export const SELECTED_CHECKBOX =
  IMAGE_BASE_URL + "/assets/icons/selected_checkbox.svg";
export const PARTIAL_CHECKBOX =
  IMAGE_BASE_URL + "/assets/icons/partial_checkbox.svg";
export const PARTIAL_HOVERED_CHECKBOX =
  IMAGE_BASE_URL + "/assets/icons/partial_hovered_checkbox.svg";
export const SELECTED_DISABLED_CHECKBOX =
  IMAGE_BASE_URL + "/assets/icons/selected_disabled_checkbox.svg";
export const DEFAULT_CHECKBOX =
  IMAGE_BASE_URL + "/assets/icons/default_checkbox.svg";
export const DEFAULT_DISABLED_CHECKBOX =
  IMAGE_BASE_URL + "/assets/icons/default_disabled_checkbox.svg";
export const HOVERED_CHECKBOX =
  IMAGE_BASE_URL + "/assets/icons/hovered_checkbox.svg";
export const AUTOMATED_IMAGE = IMAGE_BASE_URL + "/assets/images/Auto.svg";
export const CALL_IMAGE = IMAGE_BASE_URL + "/assets/images/Call.svg";
export const TODO_IMAGE = IMAGE_BASE_URL + "/assets/images/ToDo.svg";

export const PEOPLE_IMAGE = IMAGE_BASE_URL + "/assets/images/PeopleImage.svg";
export const COMPANY_IMAGE = IMAGE_BASE_URL + "/assets/images/CompanyImage.svg";

export const SALES_EMPTY_SCREEN =
  IMAGE_BASE_URL + "/assets/images/sales_empty_screen.svg";
export const NO_VIEW_CREATED =
  IMAGE_BASE_URL + "/assets/images/no_view_created.svg";
export const NO_SORT = IMAGE_BASE_URL + "/assets/icons/no_sort.svg";
export const ASCENDING_SORT = IMAGE_BASE_URL + "/assets/icons/ascending.svg";
export const DESCENDING_SORT = IMAGE_BASE_URL + "/assets/icons/descending.svg";
export const PROPERTIES_EMPTY_SCREEN =
  IMAGE_BASE_URL + "/assets/images/properties_empty_screen.svg";
export const GOOGLE_LOGO = IMAGE_BASE_URL + "/assets/images/gLogo.svg";
export const CALENDAR_LOGO =
  IMAGE_BASE_URL + "/assets/images/Google_Calendar_icon.svg";
export const CONNECTED_BUTTON = IMAGE_BASE_URL + "/assets/icons/Vector.svg";

export const OOPS_IMAGE = IMAGE_BASE_URL + "/assets/images/oop.svg";
export const OUTLOOK_IMAGE = IMAGE_BASE_URL + "/assets/images/outlook.png";
export const ONBOARDING_DASHBOARD_EXTENSION =
  IMAGE_BASE_URL + "/assets/images/Onboarding_Dashboard_Extension.svg";
export const INVITE_COLLEAGUE =
  IMAGE_BASE_URL + "/assets/images/InviteColleague.svg";
export const AVATAR_DUMMY_IMAGE =
  IMAGE_BASE_URL + "/assets/images/AVATAR_DUMMY_IMAGE.svg";
export const NO_RECORD_FOUND_IMAGE =
  IMAGE_BASE_URL + "/assets/images/norecordfoundimage.svg";
export const TASK_ICON = IMAGE_BASE_URL + "/assets/images/task.svg";
export const NOTE_ICON = IMAGE_BASE_URL + "/assets/images/sticky_note_2.svg";
export const MEETING_ICON = IMAGE_BASE_URL + "/assets/images/meeting.svg";

export const OUTLOOK_CONNECT = IMAGE_BASE_URL + "/assets/images/outlook.svg";
export const MICROSOFT_ICON = IMAGE_BASE_URL + "/assets/icons/microsoft.svg";
export const UPLOAD_FILE_IMAGE =
  IMAGE_BASE_URL + "/assets/images/UPLOAD_FILE_IMAGE.svg";
export const GMAIL_FULL_IMAGE = IMAGE_BASE_URL + "/assets/images/gmailFull.svg";
export const LOCATION_ON = IMAGE_BASE_URL + "/assets/images/location_on.svg";
export const DESCRIPTION_MEETINGS =
  IMAGE_BASE_URL + "/assets/images/description_meetings.svg";
export const CONNECTED_TICK = IMAGE_BASE_URL + "/assets/images/tick.svg";
export const MICROSOFT_IMAGE =
  IMAGE_BASE_URL + "/assets/images/Microsoft_logo_image.svg";
export const FACEBOOK = IMAGE_BASE_URL + "/assets/images/facebook.svg";

export const TASK_CRM = IMAGE_BASE_URL + "/assets/images/TASKS_CREATE.svg";
export const EMAIL_ORANGE_BACKGROUND_IMAGE =
  IMAGE_BASE_URL + "/assets/images/email_outlined_orange_color.svg";
export const CALL_CRM = IMAGE_BASE_URL + "/assets/images/CALL_CRM.svg";
export const ACTION_CRM = IMAGE_BASE_URL + "/assets/images/Action_CRM.svg";
export const AI_ICON = IMAGE_BASE_URL + "/assets/images/aicon.svg";
export const WHITE_AICON = IMAGE_BASE_URL + "/assets/images/whiteAicon.svg";
export const AI_LOADING = IMAGE_BASE_URL + "/assets/images/aiLoad.svg";
export const AI_LOAD_GIF = IMAGE_BASE_URL + "/assets/images/aiLoadGif.gif";
export const OUTLOOK_FULL_IMAGE = IMAGE_BASE_URL + "/assets/images/otlook.svg";
export const GMAIL_IMAGE1 = IMAGE_BASE_URL + "/assets/images/gmail.svg";
export const OUTLOKK_IMAGE1 = IMAGE_BASE_URL + "/assets/images/outlook1.svg";
export const PDF_IMAGE = IMAGE_BASE_URL + "/assets/images/pdf_extension.svg";
export const DOC_IMAGE = IMAGE_BASE_URL + "/assets/images/docs_extension.svg";
export const CSV_IMAGE = IMAGE_BASE_URL + "/assets/images/csv_extension.svg";
export const TXT_IMAGE = IMAGE_BASE_URL + "/assets/images/txt_extension.svg";
export const ZIP_IMAGE = IMAGE_BASE_URL + "/assets/images/zip_extension.svg";
export const INSERT_DRIVE_FILE =
  IMAGE_BASE_URL + "/assets/images/insert_drive_file.svg";
export const MEETINGS_CRM = IMAGE_BASE_URL + "/assets/images/meetings_Crm.svg";
export const PUSH_PIN_ICON = IMAGE_BASE_URL + "/assets/images/push_pin.svg";
export const UNPIN_ICON = IMAGE_BASE_URL + "/assets/images/unpin.svg";
export const GMAIL_SETTINGS =
  IMAGE_BASE_URL + "/assets/images/gmail-settings.svg";
export const OUTLOOK_SETTINGS =
  IMAGE_BASE_URL + "/assets/images/outlook-settings.svg";
export const SENDGRID_SETTINGS =
  IMAGE_BASE_URL + "/assets/images/sendgrid-settings.svg";
export const MANDRILL_SETTINGS =
  IMAGE_BASE_URL + "/assets/images/mandrill-settings.svg";
export const SENDINBLUE_SETTINGS =
  IMAGE_BASE_URL + "/assets/images/sendinblue-settings.svg";
export const BREVO_SETTINGS = IMAGE_BASE_URL + "/assets/images/brevo.svg";
export const MAILGUN_SETTINGS =
  IMAGE_BASE_URL + "/assets/images/mailgun-settings.svg";
export const CONSTANT_SETTINGS =
  IMAGE_BASE_URL + "/assets/images/constant-settings.svg";
export const MESSAGE_BIRD = IMAGE_BASE_URL + "/assets/icons/message.svg";
export const SMART_TOY = IMAGE_BASE_URL + "/assets/images/smart_toy.svg";
export const ACCOUNTFLAG_BG =
  IMAGE_BASE_URL + "/assets/images/accountflagbg.png";
export const TIMELINE_CRM = IMAGE_BASE_URL + "/assets/images/Timeline.svg";
export const NOTE_CRM = IMAGE_BASE_URL + "/assets/images/NOTE_CRM.svg";
export const TOY_ICON = IMAGE_BASE_URL + "/assets/images/toyicon.svg";
export const BLUE_TOY = IMAGE_BASE_URL + "/assets/images/bluetoy.svg";
export const DEALS_EMPTY_SCREEN = IMAGE_BASE_URL + "/assets/images/DEALS_EMPTY_SCREEN.svg";
export const EMP_WIDGT_SCREEN = IMAGE_BASE_URL + "/assets/images/widempty.svg";
export const NO_RECORD_UPDATE = IMAGE_BASE_URL + "/assets/images/norecordupdate.svg";
export const TOGGLE = IMAGE_BASE_URL + "/assets/images/ToggleDisable.svg";
export const RED_TOGGLE = IMAGE_BASE_URL + "/assets/images/ToggleEnable.svg";
export const BAR_CHART = IMAGE_BASE_URL + "/assets/images/barChart.png";
export const CALENDAR_LOGO_PNG = IMAGE_BASE_URL + "/assets/images/Google_Calendar_icon.png";
export const PIPELINE_ICON = IMAGE_BASE_URL + "/assets/images/Pipeline_Icon.png";
export const EXPORT_SEL = IMAGE_BASE_URL + "/assets/images/expsel.svg";
export const DUMMY_COMPANY2 = IMAGE_BASE_URL + "/assets/images/DUMMY_COMPANY2.svg";
export const DUMMY_CONTACT2 = IMAGE_BASE_URL + "/assets/images/dummy_contact2.svg";
export const DUMMY_DEALS2 = IMAGE_BASE_URL + "/assets/images/dummy_deals2.svg";
export const SELECTD_EXP = IMAGE_BASE_URL + "/assets/images/selectedExp.svg";
export const ALL_EXP = IMAGE_BASE_URL + "/assets/images/allExp.svg";


// Pending Images
export const ASTERISK = "/assets/images/asterisk.svg";
export const DEFAULT_CARD_STYLE = "/assets/images/DEFAULT_CARD_STYLE.svg"
export const DEFAULT_CARD_SELECTED_STYLE = "/assets/images/DEFAULT_CARD_SELECTED_STYLE.svg";
export const COMPACT_CARD_STYLE = "/assets/images/COMPACT_CARD_STYLE.svg";
export const COMPACT_CARD_SELECTED_STYLE = "/assets/images/COMPACT_CARD_SELECTED_STYLE.svg";
export const ONE_TO_ONE = "/assets/images/onetoone.svg"
export const GROUP_MEET = "/assets/images/group.svg";
export const BOX_CHART_IMAGE = "/assets/images/boxChart.svg";
export const VERTICAL_CHART_INITIAL = "/assets/images/VERTICAL_CHART_INITIAL.svg";
export const LINE_CHART_INITIAL = "/assets/images/LINE_CHART_INITIAL.svg";
export const ACTIVITY_CHART_INITIAL = "/assets/images/ACTIVITY_CHART_INITIAL.svg";
export const BAR_CHART_INITIAL = "/assets/images/BAR_CHART_INITIAL.svg";
export const PIECHART_DISABLED = "/assets/images/PIE_CHART_INITIAL.svg";