import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { reducers } from "./reducers";
import { initialState } from "./store/initialState";

const devMiddleware = [thunk, logger];

const prodMiddleware = [thunk];

const middleware = process.env.NODE_ENV === "development" ? devMiddleware : prodMiddleware;

const store = createStore(
  reducers,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
