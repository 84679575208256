import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { MultiSelectTextField } from "../../../components/Autocomplete";
import {  useNavigate } from "react-router-dom";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  INVITED_USERS_LIST_HEADERS,
} from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { invitedUsersList } from "../../../redux/actions/authActions";
import { STRINGS } from "../strings";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import CustomTable from "../../../components/Table/CustomTable";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import { deleteRequest } from "../../../Apis/apiWrapper";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { paginateAndSort } from "../../../utils/helpers";
import CustomModal from "../../../components/Modals/CustomModal";
import { Button } from "../../../components/Buttons";
import { PROFILE_PATH } from "../../../utils/constants/routes";

const InviteUser = () => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invitedUsersListSelector = useSelector(
    (state) => state.invitedUsersList
  );
  const { loading, payload } = invitedUsersListSelector;
  const [ConfirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [loader, setLoader] = useState(false);

  const [dataValue, setDataValue] = useState("");
  useEffect(() => {
    dispatch(invitedUsersList(navigate));
    navigate(`${PROFILE_PATH}?tab=InviteUser`);
  }, [navigate, dispatch]);
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const page = paginationDataSelector?.data?.query?.page || 1;
  const pageSize = paginationDataSelector?.data?.query?.limit || 10;
  const sortBy = paginationDataSelector?.data?.query?.sort?.id || "";
  const sortOrder =
    paginationDataSelector?.data?.query?.sort?.direction || "asc";
  const data =
    paginateAndSort(payload?.data, page, pageSize, sortBy, sortOrder) || [];
  const UserData = {
    heads: INVITED_USERS_LIST_HEADERS,
    body: data.records || [],
    operations: [
      {
        id: "delete",
        label: "Delete",
        iconLabel: ICON_LABELS.DELETE,
        function: (row, head) => handleDelete(row, head),
        variant: "error",
      },
    ],
    actions: {},
    sortHandler: {},
    total_records: payload?.data?.length || 0,
    isLoading: loading,
    is_selection_not_allowed: true,
  };

  const handleDelete = (data, head) => {
    setDataValue(data);
    setConfirmDeletePopup(true);
  };
  const handleDeleteConfirm = async () => {
    setLoader(true);
    try {
      let payload = { invitation_id: dataValue?._id };
      const response = await deleteRequest(
        authEndpoints.deleteInvitation,
        payload,
        navigate
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setLoader(false);
      setConfirmDeletePopup(!ConfirmDeletePopup);
      dispatch(invitedUsersList(navigate));
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  const cancelHandler = () => {
    setConfirmDeletePopup(!ConfirmDeletePopup);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.INSERT_LINK,
      label: STRINGS.DELETE,
    },
  };
  const modalBody = (
    <div>
      <TypographyInline
        size={"md"}
        color={GREY.PRIMARY}
        fontWeight={fontWeight.MEDIUM}
        label={"Are you sure want to delete?"}
      />
    </div>
  );
  const modalFooterRightData = (
    <div>
      <div className={classes.paddingBtnnUser}>
        <Button
          label={"Cancel"}
          size={"sm36"}
          variant={"ghost"}
          onClick={cancelHandler}
        />{" "}
        <Button
          label={"Delete"}
          size={"sm36"}
          variant={"primary"}
          onClick={handleDeleteConfirm}
          isLoading={loader}
        />
      </div>
    </div>
  );

  const modalFooterLeftData = <div></div>;
  return (
    <Box className={classes.container}>
      <Box className={classes.componentContainer}>
        <Box className={classes.flexBetween}>
          <Box className={classes.padUs}>
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.INVITE_USER}
            />
          </Box>
        </Box>
        <Box className={classes.container2}>
          <MultiSelectTextField />
        </Box>

        <Box className={`${classes.GAP} ${classes.paddingLeftData}`}>
          <TypographyInline
            size={"md"}
            color={GREY.PRIMARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.INVITED_USERS}
          />
          <TypographyInline
            size={"md"}
            color={GREY.PRIMARY}
            fontWeight={fontWeight.MEDIUM}
            label={loading ? "..." : `(${payload?.data?.length})`}
          />
        </Box>

        <Box className={classes.InviteTable}>
          <CustomTable tableData={UserData} />
        </Box>
      </Box>

      <CustomModal
      variant={"error"}
        close={cancelHandler}
        open={ConfirmDeletePopup}
        header={modalHeaderData}
        children={modalBody}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
      />
    </Box>
  );
};

export default InviteUser;
