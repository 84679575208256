import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../../strings";
import { styles } from "./styles";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import InputField from "../../../../components/InputFields/InputField";
import { Button } from "../../../../components/Buttons";
import { emailValidation, nameValidation } from "../../../../utils/validations";
import { postRequest } from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import { authEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { limitValidation } from "../../../../utils/validations/inputValidations";
import RadioButton from "../../../../components/RadioButton/RadioButton";

const EditInboxModal = ({ open, setOpen, setDetails, setReload, reload }) => {
  console.log({ setDetails });
  const classes = styles();
  const navigate = useNavigate();
  const [apikey, setApikey] = useState(setDetails?.key);
  const [isLoad, setIsLoad] = useState(false);
  const [email, setEmail] = useState(setDetails?.email);
  const [domain,setDomain] = useState(setDetails?.base_domain)
  const [name,setName] = useState(setDetails?.name)
  const [hourly, setHourly] = useState(setDetails?.hourly_limit);
  const [daily, setDaily] = useState(setDetails?.daily_limit);
  const [validations, setValidations] = useState({});
  const [selectedValue,setSelectedValue] = useState(STRINGS.US)
  const [isTest, setIsTest] = useState(false);
  const [token, setToken] = useState("");

  const updateDetails = async () => {
    const apiValidation = nameValidation(apikey);
    const nameEspValidation = nameValidation(name)
    const emailDataValidation = emailValidation(email);
    const limitDataValidation = limitValidation(hourly, daily);
    const domainValidation = nameValidation(domain)
    if (setDetails.esp == 2 || setDetails.esp == 5) {
      if (emailDataValidation.isValid && limitDataValidation.isValid) {
        setValidations({ hourly: "", daily: "", email: "" });

        try {
          let payload = {
            esp: setDetails?.esp,
            // key: apikey,
            email: email,
            daily_limit: parseInt(daily),
            hourly_limit: parseInt(hourly),
          };
          setIsLoad(true);
          const response = await postRequest(
            navigate,
            authEndpoints.googleOutlookUpdate,
            payload
          );
          EventEmitter.emit(
            EMITTER_SUCCESS,
            response?.data?.message || "Server Message Misplaced."
          );
          setReload(reload + 1);
          setIsLoad(false);
        } catch (error) {
          console.log({ error });
          EventEmitter.emit(
            EMITTER_ERROR,
            error?.data?.data || "Something went wrong"
          );
        } finally {
          setIsLoad(false);
          setOpen(!open);
        }
      } else {
        setValidations({
          hourly:
            limitDataValidation.Hourlymessage || limitDataValidation?.message,
          daily:
            limitDataValidation.Dailymessage || limitDataValidation?.message,
          email: emailDataValidation.message,
        });
      }
    } else {
       if(setDetails.base_domain){
        if (
          apiValidation.isValid &&
          emailDataValidation.isValid &&
          limitDataValidation.isValid &&
          nameEspValidation.isValid &&
          domainValidation.isValid
        ) {
          setValidations({ apikey: "", email: "", hourly: "", daily: "",name:"",domain:"" });
          try {
            let payload = {
              esp: setDetails?.esp,
              key: apikey,
              email: email,
              name: name,
              base_domain: domain,
              daily_limit: parseInt(daily),
              hourly_limit: parseInt(hourly),
              token: token,
              region:selectedValue
            };
            setIsLoad(true);
            const response = await postRequest(
              navigate,
              authEndpoints.updateApiDetails,
              payload
            );
            EventEmitter.emit(
              EMITTER_SUCCESS,
              response?.data?.message || "Server Message Misplaced."
            );
            setReload(reload + 1);
            setIsLoad(false);
          } catch (error) {
            EventEmitter.emit(
              EMITTER_ERROR,
              error?.data?.message || "Something went wrong"
            );
          } finally {
            setIsLoad(false);
            setOpen(!open);
            setToken("")
          }
        } else {
          setValidations({
            apikey: apiValidation.message,
            email: emailDataValidation.message,
            hourly:
              limitDataValidation.Hourlymessage || limitDataValidation?.message,
            daily:
              limitDataValidation.Dailymessage || limitDataValidation?.message,
            name: nameEspValidation.message,
            domain:domainValidation.message
          });
        }
       }
       else{
        if (
          apiValidation.isValid &&
          emailDataValidation.isValid &&
          limitDataValidation.isValid &&
          nameEspValidation.isValid
        ) {
          setValidations({ apikey: "", email: "", hourly: "", daily: "",name:"" });
          try {
            let payload = {
              esp: setDetails?.esp,
              key: apikey,
              email: email,
              name: name,
              daily_limit: parseInt(daily),
              hourly_limit: parseInt(hourly),
              token: token,
            };
            setIsLoad(true);
            const response = await postRequest(
              navigate,
              authEndpoints.updateApiDetails,
              payload
            );
            EventEmitter.emit(
              EMITTER_SUCCESS,
              response?.data?.message || "Server Message Misplaced."
            );
            setReload(reload + 1);
            setIsLoad(false);
          } catch (error) {
            EventEmitter.emit(
              EMITTER_ERROR,
              error?.data?.message || "Something went wrong"
            );
          } finally {
            setIsLoad(false);
            setOpen(!open);
            setToken("")
          }
        } else {
          setValidations({
            apikey: apiValidation.message,
            email: emailDataValidation.message,
            hourly:
              limitDataValidation.Hourlymessage || limitDataValidation?.message,
            daily:
              limitDataValidation.Dailymessage || limitDataValidation?.message,
            name: nameEspValidation.message
          });
        }
       }
    }
  };

  const radioButtonData = [
    { id: STRINGS.US, label: STRINGS.US },
    { id: STRINGS.EU, label: STRINGS.EU },
  ];

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const sentMailTest = async () => {
      const apiValidation = nameValidation(apikey);
      const nameEspValidation = nameValidation(name);
      const domainValidation = nameValidation(domain);
      if(setDetails?.base_domain){
        if (apiValidation.isValid && nameEspValidation.isValid && domainValidation.isValid) {
          setValidations({ apikey: "",name:"",domain:"" });
          try {
            let payload = {
              esp: setDetails?.esp,
              key: apikey,
              email: email,
              name:name,
              base_domain: domain
            };
            setIsTest(true);
            const response = await postRequest(
              navigate,
              authEndpoints.sendMailTest,
              payload
            );
            if (response.status === 200) {
              setToken(response?.data?.data?.token);
              EventEmitter.emit(
                EMITTER_SUCCESS,
                response?.data?.message || "Server Message Misplaced."
              );
            } else {
              setToken("");
            }
            setIsTest(false);
            // showAllEsp();
          } catch (error) {
            EventEmitter.emit(
              EMITTER_ERROR,
              error?.data?.message || "Something went wrong"
            );
          } finally {
            setIsTest(false);
            // setIsLoad(false);
            // setAddAnchorEl(null);
          }
        } else {
          setValidations({
            apikey:apiValidation.message,
            name: nameEspValidation.message,
            domain: domainValidation.message,
          });
        }
      }
      else{
        if (apiValidation.isValid && nameEspValidation.isValid) {
          setValidations({ apikey: "",name:"" });
          try {
            let payload = {
              esp: setDetails?.esp,
              key: apikey,
              email: email,
              name:name
            };
            setIsTest(true);
            const response = await postRequest(
              navigate,
              authEndpoints.sendMailTest,
              payload
            );
            if (response.status === 200) {
              setToken(response?.data?.data?.token);
              EventEmitter.emit(
                EMITTER_SUCCESS,
                response?.data?.message || "Server Message Misplaced."
              );
            } else {
              setToken("");
            }
            setIsTest(false);
            // showAllEsp();
          } catch (error) {
            EventEmitter.emit(
              EMITTER_ERROR,
              error?.data?.message || "Something went wrong"
            );
          } finally {
            setIsTest(false);
            // setIsLoad(false);
            // setAddAnchorEl(null);
          }
        } else {
          setValidations({
            apikey:apiValidation.message,
            name: nameEspValidation.message
          });
        }
      }

  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.API_KEY,
      label: `${STRINGS.EDIT} `,
    },
  };
  const modalBodyData = (
    <div>
      <div className={classes.menuContainer}>
        {!setDetails?.connection_type && (
          <>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.API_KEY}
            />
            <div className={classes.padding4ts}>
              <InputField
                size={"sm36"}
                placeholder={STRINGS.ENTER_API}
                variant={validations.apikey ? "error" : STRINGS.DEFAULT}
                errormessage={validations.apikey}
                value={apikey}
                onChange={(e) => setApikey(e.target.value)}
              />
            </div>
          </>
        )}
        {!setDetails?.connection_type && (
          <div className={classes.padding8ts}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.NAME}
            />
            <div className={classes.padding4ts}>
              <InputField
                size={"sm36"}
                placeholder={STRINGS.ENTR_NAME}
                variant={validations.name ? "error" : STRINGS.DEFAULT}
                errormessage={validations.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        )}
        {(!setDetails?.connection_type && setDetails?.base_domain) && (
          <div className={classes.padding8ts}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DOMAIN}
            />
            <div className={classes.padding4ts}>
              <InputField
                size={"sm36"}
                placeholder={STRINGS.ENTER_DOMAIN}
                variant={validations.domain ? "error" : STRINGS.DEFAULT}
                errormessage={validations.domain}
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </div>
          </div>
        )}
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.EMAIL}
          />
        </div>
        <div className={classes.padding4ts}>
          <InputField
            size={"sm36"}
            variant={"disabled"}
            errormessage={validations.email}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {setDetails?.region && 
        (<div className={classes.padding8ts}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.REGION}
          />
        
        <div className={classes.padding4ts}>
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </div>
        </div>
        )
        }
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"xs"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.EMIALSENDINGLIMIT}
          />
        </div>
        <div className={`${classes.padding4ts} ${classes.dispFlx1}`}>
          <div
            className={`${classes.dspColflx1} ${
              validations.daily ? classes.marginBottomIfErr : ""
            }`}
          >
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.HOURLY}
            />
            <InputField
              variant={validations.hourly ? "error" : STRINGS.DEFAULT}
              errormessage={validations.hourly}
              value={hourly}
              size={"sm36"}
              onChange={(e) => setHourly(e.target.value)}
            />
          </div>
          <div
            className={`${classes.dspColflx1} ${
              validations.hourly ? classes.marginBottomIfErr : ""
            }`}
          >
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DAILY}
            />
            <InputField
              variant={validations.daily ? "error" : STRINGS.DEFAULT}
              errormessage={validations.daily}
              value={daily}
              size={"sm36"}
              onChange={(e) => setDaily(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
  const modalFooterLeftData = (
    <>
    {
    !(setDetails.esp == 2 || setDetails.esp == 5) ? 
    <div>
      <Button
        label={STRINGS.SEND_TEST_MAIL}
        size={"sm36"}
        variant={"primary"}
        onClick={sentMailTest}
        isLoading={isTest}
      />
    </div> : 
    <div></div>
    }
    </>
  );
  const modalFooterRightData = (
    <div className={classes.dFlexGap}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.UPDATE}
        size={"sm36"}
        variant={"primary"}
        disabled={!(setDetails.esp == 2 || setDetails.esp == 5) ? (!(token?.length > 0)) : false  }
        onClick={updateDetails}
        isLoading={isLoad}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      width={"600px"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      remove_padding
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default EditInboxModal;
