import React from "react";
import { Box } from "@mui/system";
import { CustomAutoComplete } from "../Autocomplete";
import { leadsEndpoints } from "../../utils/constants/httpConstants";
import { useSelector, useDispatch } from "react-redux";
import {
  allFilters,
  filterHeaderSearchJobTitle,
} from "../../redux/actions/filterActions";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { filterHeaderJobTitle } from "../../redux/store/storeConstants";
import { filterKeys } from "../../utils/constants/filters";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import { STRINGS } from "./strings";
import { styles } from "./styles";

const FilterTypeJobTitle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedJobFilter = useSelector(
    (state) => state?.filterHeaderJobTitle?.payload
  );

  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);

  const handleExistFields = (
    existing_type,
    filter,
    filterKey,
    notFilterKey
  ) => {
    // existing_type = isKnown/ isUnknown
    // filter = job_title/industry/ seniority..

    let payload = {
      ...filterHeaderJobTitle?.payload,
      [existing_type]: !selectedJobFilter[existing_type],
    };

    dispatch(filterHeaderSearchJobTitle(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + "_not"]: [],
      };

      dispatch(allFilters(queryPayload));

      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  const handleJobTitleChange = (item, filter) => {
    let payload = {
      ...selectedJobFilter,
      [item]: !selectedJobFilter[item],
      isKnown: false,
      isUnknown: false,
    };

    // clear data if column is empty.
    const unCheckPayload = {};
    if (!payload?.anyOf) {
      unCheckPayload[filter] = [];
    }
    if (!payload?.notAnyOf) {
      unCheckPayload[filter + "_not"] = [];
    }
    // clear data if column is empty.

    dispatch(
      allFilters({
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );

    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });

    dispatch(filterHeaderSearchJobTitle(payload));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterContainer}>
        <div style={styles.jobTitleHead}>
          <Checkbox
            checked={
              selectedJobFilter?.anyOf || selectedJobFilter?.payload?.anyOf
            }
            onClick={() => handleJobTitleChange("anyOf", filterKeys.job_title)}
            label={STRINGS.IS_ANY_OF}
          />
        </div>

        {/* input box here for searching */}

        {selectedJobFilter?.anyOf ? (
          <Box>
            <CustomAutoComplete
              filter={filterKeys.job_title}
              endPoint={leadsEndpoints.getJobFilters}
              type=""
            />
          </Box>
        ) : null}
        <div style={styles.jobTitleHead}>
          <Checkbox
            checked={
              selectedJobFilter?.notAnyOf || selectedJobFilter?.payload?.anyOf
            }
            onClick={() =>
              handleJobTitleChange("notAnyOf", filterKeys.job_title)
            }
            label={STRINGS.IS_NOT_ANY_OF}
          />
        </div>
        {/* input box here for searching */}

        {selectedJobFilter?.notAnyOf ? (
          <Box>
            <CustomAutoComplete
              filter={filterKeys.job_title}
              endPoint={leadsEndpoints.getJobFilters}
              type="_not"
            />
          </Box>
        ) : null}

        <div style={styles.jobTitleHead}>
          <Checkbox
            checked={
              selectedJobFilter?.isKnown || selectedJobFilter?.payload?.isKnown
            }
            onClick={() =>
              handleExistFields(
                "isKnown",
                filterKeys.job_title,
                "exist_fields",
                "not_exist_fields"
              )
            }
            label={STRINGS.IS_KNOWN}
          />
        </div>

        <div style={styles.jobTitleHead}>
          <Checkbox
            checked={
              selectedJobFilter?.isUnknown ||
              selectedJobFilter?.payload?.isUnknown
            }
            onClick={() =>
              handleExistFields(
                "isUnknown",
                filterKeys.job_title,
                "not_exist_fields",
                "exist_fields"
              )
            }
            label={STRINGS.IS_UNKNOWN}
          />
        </div>
      </Box>
    </Box>
  );
};

export default FilterTypeJobTitle;
