import { getRequest } from "../../Apis";
import { crmEndpoints } from "../../utils/constants/httpConstants";
import {
  GET_PIPELINE_LIST_FAIL,
  GET_PIPELINE_LIST_REQUEST,
  GET_PIPELINE_LIST_SUCCESS,
} from "../constants/getPipelineConstants";

export const getPipelineList =
  (sentQuery = {}, navigate) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_PIPELINE_LIST_REQUEST });
      const response = await getRequest(navigate, crmEndpoints.getPipleline);

      dispatch({
        type: GET_PIPELINE_LIST_SUCCESS,
        payload: { ...response?.data },
      });
    } catch (err) {
      dispatch({ type: GET_PIPELINE_LIST_FAIL, payload: err });
    }
  };
