export const RED_COLOR = "#ff4848"
export const TITLE_COLOR = "#102F51"
export const LIGHT_RED_BACKGROUND_COLOR = "#FFE9E9"
export const PARA_COLOR = "#616A7D"
export const BORDER_COLOR = "#D3DAE3"
export const BACKGROUND_COLOR = "#F7F9FB"
export const RED_BOX_SHADOW_COLOR = "#FF16543C"
export const HEADING_BACKGROUND_COLOR = "#F7F9FB"


