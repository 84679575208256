import React, { useEffect, useState } from "react";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { Box } from "@mui/system";
import { InputWrapper, Root, StyledTag, Listbox } from ".";
import { styles } from "./styles";
import { useSelector } from "react-redux";

const DynamicAutoCompleteNoSuggestion = ({
  defaultValues = () => { },
  onChange = () => { },
  filterName = "",
  filterType = "",
}) => {

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  const [inputPayload, setInputPayload] = useState("");

  const handleInputChange = function (event, newInputValue) {
    setInputPayload(newInputValue);
  };

  let { getRootProps, getInputProps, getTagProps, value, setAnchorEl } =
    useAutocomplete({
      id: "customized-hook",
      inputValue: inputPayload,
      multiple: true,
      onInputChange: handleInputChange,
      defaultValue: defaultValues,
      options: [{ name: "" }],
      getOptionLabel: (option) => option?.name,
    });

  let inputValue = getInputProps()?.value;

  const handleSubmit = (e, type) => {
    e.preventDefault();
    if (inputValue?.length) {
      value.push({ name: inputValue });
      // const params = value?.map((x) => x?.name || x?.label);
      handleInputChange(e, "");
      if (type === "enterKey") onChange([...value]);
    }
  };

  useEffect(() => {
    if (
      (filterMultipleListSelector?.[filterName]?.[filterType] || [])?.length !==
      value?.length
    ) {
      onChange([...value]);
    }
  }, [value])


  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl}>
          {value?.map((option, index) => (
            <StyledTag label={option.name} {...getTagProps({ index })} />
          ))}
          <Box
            component="form"
            onSubmit={(e) => handleSubmit(e, "enterKey")}
            sx={styles.inputContainer}
          >
            <input
              style={styles.input}
              placeholder="Search..."
              {...getInputProps()}
            />
          </Box>
        </InputWrapper>
      </div>
    </Root>
  );
};

export default React.memo(DynamicAutoCompleteNoSuggestion);
