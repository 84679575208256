import React, { useEffect, useState } from "react";
import Image from "../../../../components/Image/Image";
import classes from "./CrmComp.module.css";
import { AccordionDetails, AccordionSummary } from "../../../../components";
import { Accordion } from "@mui/material";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import IconComponent from "../../../../components/Icon/IconComponent";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import CustomMenu from "../../../../components/Menu/CustomMenu";
import { STRINGS } from "../../strings";
import { GREY } from "../../../../utils/constants/colors";
import IconColoredComponent from "../../../../components/Icon/IconColoredComponent";
import AttachmentDeleteModal from "./AttachmentDeleteModal";
import {
  convertByteToKb,
  convertFormat,
  filterTableValue,
} from "../../../../utils/helpers";
import CustomTooltip from "../../../../components/Tooltip/CustomTooltip";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import { getRequest } from "../../../../Apis";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import ImageViewModal from "./ImageViewModal";
const ListingAttachmentImageCard = ({
  attachmentList,
  contact_id,
  setAttachmentList,
  contactDetails,
}) => {
  const navigate = useNavigate();
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
  const [attachmentOpen, setAttachmentOpen] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalImageAttachment, setTotalImageAttachment] = useState(0);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);

  const handleModal = (item, event) => {
    setIsActionMenuVisible(true);
    setActionMenuAnchor(event.currentTarget);
    setData(item);
  };

  const handleDownload = () => {
    window.open(data?.attachment_url, "_blank");
  };
  const tableOperations = [
    {
      id: STRINGS.SMALL_DELETE,
      label: STRINGS.DOWNLOAD,
      iconLabel: ICON_LABELS.ARROW_DOWN,
      function: () => handleDownload(),
    },
    {
      id: STRINGS.SMALL_DELETE,
      label: STRINGS.DELETE,
      iconLabel: ICON_LABELS.DELETE,
      function: () => handleDelete(),
      variant: "error",
    },
  ];

  const handleDelete = () => {
    setOpenDelete(true);
  };
  const handleOpen = (url) => {
    setImageUrl(url);

    setOpenModal(true);
  };
  const getImageLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${
        crmEndpoints.getAttachments
      }/${contact_id}?file_extension=${"image"}&page=${count}`;
      // !isOnScroll && setScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalImageAttachment(response?.data?.data?.total_records);
      if (isOnScroll) {
        setAttachmentList([
          ...attachmentList,
          ...response?.data?.data?.records,
        ]);
      } else setAttachmentList(response?.data?.data?.records);
      // !isOnScroll && setScreenLoading(false);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };
  const onScrollApiHandler = () => {
    const scrollableDiv =
      document?.getElementsByClassName("allImageAttachment")[0];
    const isAtBottom =
      scrollableDiv?.scrollTop >=
      scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;
    if (isAtBottom && totalImageAttachment > attachmentList?.length) {
      setPageCount(pageCount + 1);
      getImageLogList(true, pageCount + 1);
    }
  };

  useEffect(() => {
    if (contactDetails?.email?.length) {
      getImageLogList();
    }
  }, []);
  return (
    <div>
      <div
        className={`${classes.HeadAttachmentContainer} allImageAttachment`}
        onScroll={onScrollApiHandler}
      >
        <Accordion
          className={classes.accrodionRoot}
          onChange={() => setAttachmentOpen(!attachmentOpen)}
          expanded={attachmentOpen}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {" "}
            <div className={classes.summaryContainerHeads}>
              <div className={classes.summaryContainerNote}>
                <div className={classes.summaryRightSection}>
                  <IconComponent
                    color={GREY.TERTIARY}
                    fontSize={fontSize.DXS}
                    iconLabel={
                      attachmentOpen
                        ? ICON_LABELS.ACTION_ARROW
                        : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                    }
                  />

                  <IconColoredComponent
                    variant={"blue"}
                    iconLabel={ICON_LABELS.STICKY_NOTE}
                    color={"blue"}
                    size="small"
                  />

                  <TypographyInline
                    label={STRINGS.IMAGES}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                    color={GREY.PRIMARY}
                  />
                </div>

                <div className={classes.dateTimeTaskContainer}>
                  <div
                    className={classes.moreOptionsIconContianer}
                    onClick={(e) => {
                      e?.stopPropagation();
                    }}
                  >
                    <CustomMenu
                      items={tableOperations}
                      anchor={actionMenuAnchor}
                      open={isActionMenuVisible}
                      onClose={() => {
                        setIsActionMenuVisible(false);
                        setActionMenuAnchor(null);
                      }}
                      paperMenuClass={classes.dropdownMenuListing}
                    />
                  </div>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              className={classes.AttachmentHeadScrollDetail}
              id="comments_body"
            >
              <div className={classes.AttachmentHeadCon}>
                {attachmentList?.map((item) => {
                  return (
                    <div key={item + 1} className={classes.AttachSubHead}>
                      <div onClick={() => handleOpen(item?.attachment_url)}>
                        <Image
                          src={item?.attachment_url}
                          alt=""
                          className={classes.AttachmentIcon}
                        />
                      </div>

                      <div className={classes.attachJustify}>
                        <CustomTooltip
                          title={
                            item?.file_name?.length >= 20 ? item?.file_name : ""
                          }
                        >
                          <div>
                            <TypographyInline
                              label={filterTableValue(item?.file_name)}
                              size={"sm"}
                              fontWeight={fontWeight.SEMIBOLD}
                              color={GREY.SECONDARY}
                            />
                            <div className={classes.paddingVerticalSize}>
                              {" "}
                              <TypographyInline
                                label={convertByteToKb(item?.content_length)}
                                size={"sm"}
                                fontWeight={fontWeight.REGULAR}
                                color={GREY.SECONDARY}
                              />
                              <div className={classes.verticalLineAttach} />
                              <TypographyInline
                                label={convertFormat(item?.created_at)}
                                size={"sm"}
                                fontWeight={fontWeight.REGULAR}
                                color={GREY.SECONDARY}
                              />
                            </div>
                          </div>
                        </CustomTooltip>
                        <IconComponent
                          color={GREY.TERTIARY}
                          fontSize={fontSize.MD}
                          iconLabel={ICON_LABELS.MORE_VERT}
                          onClick={(event) => {
                            handleModal(item, event);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                {openDelete && (
                  <AttachmentDeleteModal
                    id={contact_id}
                    attachmentList={data}
                    openDelete={openDelete}
                    setOpenDelete={setOpenDelete}
                    setAttachmentList={setAttachmentList}
                    setAttachmentOpen={setAttachmentOpen}
                    attachmentOpen={attachmentOpen}
                  />
                )}
              </div>

              {openModal && (
                <ImageViewModal
                  id={contact_id}
                  attachmentList={data}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  imageUrl={imageUrl}
                />
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default ListingAttachmentImageCard;
