import React, { useState, useEffect } from "react";
import { Box, Modal } from "@mui/material";
import {
  COMPANY_EMPLOYEES_COUNT,
  EMAIL_VERIFICATION_TYPES,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  SENIORIRT_TYPES,
  tempHeadCells,
} from "../../../utils/constants";
import CreateListModal from "./CreateListModal";
import { useDispatch, useSelector } from "react-redux";
import { getPeopleBasicDetails } from "../../../redux/actions/peopleActions";
import {
  allFilters,
  filterHeaderCompanyCity,
  filterHeaderCompanyCountry,
  filterHeaderCompanyKeywords,
  filterHeaderCompanyName,
  filterHeaderCompanySeo,
  filterHeaderCompanyState,
  filterHeaderCompanyTechnologies,
  filterHeaderEmailVerification,
  filterHeaderPersonCity,
  filterHeaderPersonCountry,
  filterHeaderPersonState,
  filterHeaderSearchCompany,
  filterHeaderSearchIndustry,
  filterHeaderSearchJobTitle,
  filterHeaderSearchSeniority,
} from "../../../redux/actions/filterActions";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { AddViewModal, AllViewModal, CreateContactModal } from ".";
import { getRequest, postRequest } from "../../../Apis";
import {
  leadsEndpoints,
  viewEndPoints,
} from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { filterQuery, getSelectedColumns } from "../../../utils/helpers";
import {
  filterHeaderIndustry,
  filterHeaderJobTitle,
  initialFilterPayload,
  stringQueryList,
} from "../../../redux/store/storeConstants";
import {
  checkExistFields,
  getCheckEmployeeCount,
  getSelectedSeniority,
  HandleClearAllFilterFromStore,
} from "../../../redux/store/storeHelper";
import { filterKeys } from "../../../utils/constants/filters";
import {
  applyUpdateColumn,
  updateColumnHeaders,
} from "../../../redux/actions/tableActions";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import ViewTab from "../../../components/ViewTab/ViewTab";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import IconComponent from "../../../components/Icon/IconComponent";
import { BRAND, GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";
import classes from "./LeadsComponent.module.css";
import { paginationDataAction } from "../../../redux/actions/customTableActions";
// import * as qs from "qs";

const PeopleHeader = ({ nextstep, liststep, header, setHeader }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.peopleBasicDetails);
  const selectedTableCells = useSelector(
    (state) => state?.columnUpdate?.columns
  );
  const allFiltersSelector = useSelector((state) => state?.allFilters);
  const { loading, payload } = selector;

  const [showAllViewsModal, setShowAllViewsModal] = useState(false);
  const [createListModal, setCreateListModal] = useState(false);
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [openAddView, setOpenAddView] = useState(false);
  const [showViewActions, setShowViewActions] = useState("");
  const [actionPerforming, setActionPerforming] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [actionValue, setActionValue] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [defaultQueryPushed, setDefaultQueryPushed] = useState(false);
  const showActions = "";

  const hoverIndex = -1;
  const [saveViewEL, setSaveViewEL] = useState(null);
  const [viewEL, setViewEL] = useState(null);
  const [viewType, setViewType] = useState("")
  const [spViewEL, setSpViewEL] = useState(null);
  const [viewClicked, setViewClicked] = useState({});

  const handleSaveView = async () => {
    const filteredQuery = filterQuery(allFiltersSelector?.payload);
    const SelectedTableIds = getSelectedColumns(selectedTableCells);
    const viewPayload = {
      query_string: filteredQuery,
      view_id: payload?.active_view?.view_id,
      table_columns: SelectedTableIds,
    };
    try {
      setActionPerforming(true);
      const response = await postRequest(
        navigate,
        viewEndPoints?.updateView,
        viewPayload
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setActionPerforming(false);
      }
    } catch (error) {
      setActionPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setSaveViewEL(false);
    }
  };

  const saveViewModal = (
    <div className={classes.sortingModal}>
      <div className={classes.containSaveText}>
        <TypographyInline
          label={STRINGS.SAVE_AS_CURRENT}
          size={"md"}
          fontWeight={fontWeight.MEDIUM}
          color={GREY.PRIMARY}
        />
      </div>
      <div className={classes.saveViewDisplayTxt}>
        <TypographyInline
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
          label={STRINGS.SAVE_TEXT}
        />
      </div>
      <div className={classes.containApplyBtn}>
        <div>
          <Button
            label={STRINGS.SAVE_AS_NEW}
            LeftIcon={ICON_LABELS.ADD}
            size={"sm36"}
            variant={"text"}
            onClick={() => {
              setOpenAddView(true);
              setSaveViewEL(null);
            }}
          />
        </div>
        <div className={classes.rightFooterButton}>
          <Button
            label={STRINGS.CANCEL}
            size={"sm36"}
            variant={"ghost"}
            onClick={() => setSaveViewEL(null)}
          />
          <Button
            label={STRINGS.SAVE}
            size={"sm36"}
            variant={"primary"}
            onClick={handleSaveView}
            disabled={!isDisable}
            isLoading={actionPerforming}
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const active_view_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);
    setIsDisable(payload?.active_view?.is_owner);

    if (!mounted) {
      setMounted(true);
      dispatch(
        getPeopleBasicDetails(
          navigate,
          active_view_id
            ? `?${STRINGS.VIEW_ID}=${active_view_id}`
            : payload?.default_view?.is_main_view
              ? `?${STRINGS.VIEW_ID}=${payload?.default_view?.view_id}`
              : payload?.default_view?.view_id
                ? `?${STRINGS.VIEW_ID}=${payload?.default_view?.view_id}`
                : ""
        )
      );
    }
    if (
      Object.keys(payload?.default_view || {}).length &&
      !defaultQueryPushed
    ) {
      const filters = filterQuery(allFiltersSelector?.payload);

      let query_string;

      if (active_view_id) {
        if (!payload?.default_view.is_main_view) {
          query_string = payload?.active_view?.query_string || {};
        } else if (active_view_id === payload?.default_view?.view_id) {
          query_string = filters;
        } else {
          query_string = payload?.active_view?.query_string || {};
        }
      } else {
        if (payload?.default_view?.is_main_view) {
          query_string = filters;
        } else {
          query_string = payload?.default_view?.query_string;
        }
      }

      const table_columns = payload?.default_view?.is_main_view
        ? payload?.active_view?.table_columns
        : payload?.active_view?.table_columns || [];
      if (!Object.keys(query_string).includes(STRINGS.CONTACT_LISTS)) {
        handleChangeView(query_string, table_columns);
      }
      setDefaultQueryPushed(true);
    }
    if (nextstep > 3 && nextstep <= 6) {
      setOpenAddView(true);
    } else {
      setOpenAddView(false);
    }
    if (nextstep > 7) {
      setCreateListModal(true);
    } else {
      setCreateListModal(false);
    }
  }, [
    allFiltersSelector,
    dispatch,
    navigate,
    payload,
    mounted,
    nextstep,
    liststep,
  ]);

  const handleChangeView = async (query, table_columns) => {
    await HandleClearAllFilterFromStore(dispatch, location, navigate);

    // stroing company details into localstorage for company name filter
    // const company_ids = window.company_ids;
    // if (company_ids?.length) {
    //   localStorage.setItem("company_ids", JSON.stringify(company_ids));
    //   window.company_ids = [];
    // }

    if (!table_columns.length) {
      table_columns = [
        ...tempHeadCells
          .filter((item) => item.isChecked)
          .map((item) => item.id),
      ];
    }

    const new_table_cells_payload = [];
    for (let i in tempHeadCells) {
      if (table_columns.includes(tempHeadCells[i].id)) {
        new_table_cells_payload.push({ ...tempHeadCells[i], isChecked: true });
      } else {
        new_table_cells_payload.push({ ...tempHeadCells[i], isChecked: false });
      }
    }
    await dispatch(applyUpdateColumn(new_table_cells_payload));
    await dispatch(updateColumnHeaders(new_table_cells_payload));
    const res = query;
    if (res) {
      for (let i in res) {
        if (
          typeof res[i] === STRINGS.STRING &&
          stringQueryList?.indexOf(i) === -1
        ) {
          res[i] = [res[i]];
        }
      }
    }
    const filterHeaderSeniority = {
      payload: res?.seniority
        ? getSelectedSeniority(SENIORIRT_TYPES, res?.seniority)
        : SENIORIRT_TYPES,
    };

    const filterHeaderCompany = {
      payload: res?.employee_number
        ? getCheckEmployeeCount(
          JSON.parse(JSON.stringify(COMPANY_EMPLOYEES_COUNT)),
          res.employee_number
        )
        : JSON.parse(JSON.stringify(COMPANY_EMPLOYEES_COUNT)),
    };

    const initalJobTitleSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.job_title?.length ||
        res?.job_title_not?.length
        ? checkExistFields(res, filterKeys.job_title)
        : JSON.parse(JSON.stringify(filterHeaderJobTitle));

    const initalIndustrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.industry?.length ||
        res?.industry_not?.length
        ? checkExistFields(res, filterKeys.industry)
        : JSON.parse(JSON.stringify(filterHeaderIndustry));

    const InitialEmailVerification = {
      payload: res?.email_verification
        ? getCheckEmployeeCount(
          EMAIL_VERIFICATION_TYPES,
          res?.email_verification
        )
        : EMAIL_VERIFICATION_TYPES,
    };

    // const initialCompanyNameSelect = res?.exist_fields?.length ||
    //   res?.not_exist_fields?.length ||
    //   res?.company_ids?.length ||
    //   res?.company_ids_not?.length
    //   ? checkExistFields(res, STRINGS.COMPANY_IDS)
    //   : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyKeywordsSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_keywords?.length ||
        res?.company_keywords_not?.length ||
        res?.company_keywords_all?.length
        ? checkExistFields(res, STRINGS.COMPANY_KEYWORDS)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyTechnologySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_technologies?.length ||
        res?.company_technologies_not?.length ||
        res?.company_technologies_all?.length
        ? checkExistFields(res, STRINGS.COMPANY_TECHNOLOGIES)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanySeoSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.seo_description?.length ||
        res?.seo_description_not?.length ||
        res?.seo_description_alll?.length
        ? checkExistFields(res, STRINGS.SEO_DESCRIPTION)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyCitySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_city?.length ||
        res?.company_city_not?.length
        ? checkExistFields(res, STRINGS.COMPANY_CITY)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyStateSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_state?.length ||
        res?.company_state_not?.length
        ? checkExistFields(res, STRINGS.COMPANY_STATE)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyCountrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_country?.length ||
        res?.company_country_not?.length
        ? checkExistFields(res, STRINGS.COMPANY_COUNTRY)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonCitySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_city?.length ||
        res?.person_city_not?.length
        ? checkExistFields(res, STRINGS.PERSON_CITY)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonStateSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_state?.length ||
        res?.person_state_not?.length
        ? checkExistFields(res, STRINGS.PERSON_STATE)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonCountrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_country?.length ||
        res?.person_country_not?.length
        ? checkExistFields(res, STRINGS.PERSON_COUNTRY)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    dispatch(
      filterHeaderSearchCompany(
        JSON.parse(JSON.stringify(filterHeaderCompany?.payload))
      )
    );
    dispatch(
      filterHeaderSearchSeniority(
        JSON.parse(JSON.stringify(filterHeaderSeniority?.payload))
      )
    );
    dispatch(
      filterHeaderEmailVerification(
        JSON.parse(JSON.stringify(InitialEmailVerification?.payload))
      )
    );
    dispatch(filterHeaderSearchJobTitle(initalJobTitleSelect?.payload));
    dispatch(filterHeaderSearchIndustry(initalIndustrySelect?.payload));
    dispatch(filterHeaderCompanyKeywords(initalCompanyKeywordsSelect?.payload));
    dispatch(
      filterHeaderCompanyTechnologies(initalCompanyTechnologySelect?.payload)
    );
    dispatch(filterHeaderCompanySeo(initalCompanySeoSelect?.payload));
    dispatch(filterHeaderPersonCity(initalPersonCitySelect?.payload));
    dispatch(filterHeaderPersonState(initalPersonStateSelect?.payload));
    dispatch(filterHeaderPersonCountry(initalPersonCountrySelect?.payload));
    dispatch(filterHeaderCompanyCity(initalCompanyCitySelect?.payload));
    dispatch(filterHeaderCompanyState(initalCompanyStateSelect?.payload));
    dispatch(filterHeaderCompanyCountry(initalCompanyCountrySelect?.payload));
    dispatch(filterHeaderCompanyName(initialFilterPayload));
    dispatch(allFilters(query));
    navigate({
      pathname: location.pathname,
      search: createSearchParams(query || {}).toString(),
    });
  };

  const handleUnpinView = async () => {
    try {
      setActionPerforming(true);
      setActionValue(STRINGS.UNPIN_THIS_VIEW);
      const response = await getRequest(
        navigate,
        viewEndPoints?.unpinView + `?${STRINGS.VIEW_ID}=` + showViewActions
      );
      const active_view_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);

      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        await dispatch(
          getPeopleBasicDetails(
            navigate,
            active_view_id
              ? `?${STRINGS.VIEW_ID}=${active_view_id}`
              : payload?.default_view?.is_main_view
                ? `?${STRINGS.VIEW_ID}=${payload?.default_view?.view_id}`
                : payload?.default_view?.view_id
                  ? `?${STRINGS.VIEW_ID}=${payload?.default_view?.view_id}`
                  : ""
          )
        );
        setActionPerforming(false);
      }
    } catch (error) {
      setActionPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleMakeDefaultView = async () => {
    try {
      setActionPerforming(true);
      setActionValue(STRINGS.MAKE_DEFAULT_VIEW);
      const response = await getRequest(
        navigate,
        viewEndPoints?.makeDefaultView +
        `?${STRINGS.VIEW_ID}=` +
        showViewActions
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        try {
          const response = await getRequest(
            navigate,
            leadsEndpoints.peopleBasic
          );
          await dispatch(getPeopleBasicDetails(navigate));
          handleChangeView(
            response?.data?.data?.default_view?.query_string || {},
            response?.data?.data?.default_view?.table_columns
          );
          setActionPerforming(false);
        } catch (err) {
          setActionPerforming(false);
        }
      }
    } catch (error) {
      setActionPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleChangeActiveView = async (view) => {
    dispatch(
      paginationDataAction({
        checkedRow: [],
      })
    );
    try {
      const response = await getRequest(
        navigate,
        leadsEndpoints.peopleBasic + `?${STRINGS.VIEW_ID}=${view.view_id}`
      );
      sessionStorage.setItem(STRINGS.ACTIVE_VIEW_ID, view?.view_id.toString());
      await dispatch(
        getPeopleBasicDetails(navigate, true, response?.data?.data)
      );
      handleChangeView(
        response?.data?.data?.active_view?.query_string || {},
        response?.data?.data?.active_view?.table_columns
      );
      dispatch(
        applyUpdateColumn(response?.data?.data?.active_view?.table_columns)
      );
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handlePinView = async () => {
    try {
      const View = payload?.active_view;
      setActionPerforming(true);
      setActionValue(STRINGS.PIN_THIS_VIEW);
      const response = await getRequest(
        navigate,
        viewEndPoints.pinView + `?${STRINGS.VIEW_ID}=` + View?.view_id
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      handleChangeActiveView(View);
      setActionPerforming(false);
      setSpViewEL(null);
    } catch (error) {
      setActionPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const viewsActionList = [
    {
      type: STRINGS.UNPIN_THIS_VIEW,
      action: handleUnpinView,
      icon: ICON_LABELS.PUSH_PIN,
      inPinnedList: true,
    },
    {
      type: STRINGS.MAKE_DEFAULT_VIEW,
      action: handleMakeDefaultView,
      icon: ICON_LABELS.BOOKMARK,
      inPinnedList: true,
    },
    {
      type: STRINGS.PIN_THIS_VIEW,
      action: handlePinView,
      icon: ICON_LABELS.PUSH_PIN,
      inPinnedList: false,
    },
  ];

  const handleViewActionChange = (id) => {
    setShowViewActions(id);
  };

  const handleDefaultViewClick = async () => {
    dispatch(
      paginationDataAction({
        checkedRow: [],
      })
    );
    try {
      const response = await getRequest(
        navigate,
        leadsEndpoints.peopleBasic +
        `?${STRINGS.VIEW_ID}=${payload?.default_view?.view_id}`
      );
      sessionStorage.setItem(
        STRINGS.ACTIVE_VIEW_ID,
        payload?.default_view?.view_id
      );

      await dispatch(
        getPeopleBasicDetails(navigate, true, response?.data?.data)
      );
      handleChangeView(
        response?.data?.data?.active_view?.query_string || {},
        response?.data?.data?.active_view?.table_columns
      );
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const viewTabIconHandler = (e, view) => {
    if (viewEL) {
      setViewEL(null);
      setViewClicked({});
    } else {
      setViewEL(e?.currentTarget);
      setViewClicked(view);
    }
    handleViewActionChange(view?.view_id);
  };

  const specialViewTabIconHandler = (e, view) => {
    if (spViewEL) {
      setSpViewEL(null);
    } else {
      setSpViewEL(e?.currentTarget);
    }
    handleViewActionChange(view?.view_id);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.viewContainer}>
        <div className={classes.peopleContainer}>
          {/* Default View  */}
          <div>
            <ViewTab
              tabLabel={
                loading ? STRINGS.LOADING : payload?.default_view?.view_name
              }
              isActive={
                payload?.default_view?.view_id === payload?.active_view?.view_id
              }
              isDefault
              RightIcon={ICON_LABELS.ACTION_ARROW}
              buttonClick={
                payload?.default_view?.view_id !== payload?.active_view?.view_id
                  ? () => handleDefaultViewClick()
                  : null
              }
            />
          </div>
          {/* Default View  */}
          {/* Pinned View */}
          {payload?.pinned_view?.map((view, index) => {
            return (
              <div>
                <ViewTab
                  RightIcon={ICON_LABELS.ACTION_ARROW}
                  tabLabel={view?.view_name}
                  buttonClick={!(view?.view_id === payload?.active_view?.view_id) ? () => handleChangeActiveView(view) : () => { }}
                  isPin
                  iconClick={(e) => {
                    viewTabIconHandler(e, view);
                  }}
                  isActive={view?.view_id === payload?.active_view?.view_id}
                />
              </div>
            );
          })}
          <CustomStyledMenu
            anchorEl={viewEL}
            open={Boolean(viewEL)}
            onClose={() => setViewEL(null)}
            marginTop={"2px !important"}
            width={"13vw !important"}
          // arrow

          //isLeftStart
          >
            <Box className={classes.pinnedViewActions}>
              {showViewActions === viewClicked?.view_id && (
                <Box onClick={(e) => viewTabIconHandler(e, viewClicked)}>
                  {viewsActionList?.map((action, index) => {
                    return viewClicked?.is_main_view &&
                      action.type === STRINGS.UNPIN_THIS_VIEW
                      ? null
                      : action.inPinnedList && (
                        <Box
                          key={action.type}
                          onClick={() => action.action()}
                          className={
                            viewsActionList?.length - 1 === index
                              ? classes.borderFlex1
                              : classes.borderFlex1
                          }
                        >
                          <IconComponent
                            color={
                              showActions + hoverIndex ===
                                viewClicked.view_id + index
                                ? BRAND.PRIMARY
                                : GREY.TERTIARY
                            }
                            fontSize={fontSize.MD}
                            iconLabel={action.icon}
                          />
                          <TypographyInline
                            label={
                              actionValue === action.type && actionPerforming
                                ? STRINGS.LOADING
                                : action.type
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                            color={
                              showActions + hoverIndex ===
                                viewClicked.view_id + index
                                ? BRAND.PRIMARY
                                : GREY.SECONDARY
                            }
                          />
                        </Box>
                      );
                  })}
                </Box>
              )}
            </Box>
          </CustomStyledMenu>
          {/* Pinned View */}

          {/* Special Case */}
          {!payload?.active_view?.is_main_view &&
            payload?.active_view?.temp_active_view &&
            payload?.default_view?.view_id !== payload?.active_view?.view_id &&
            !payload?.pinned_view?.filter(
              (view) => view?.view_id === payload?.active_view?.view_id
            ).length ? (
            <div>
              <ViewTab
                RightIcon={ICON_LABELS.ACTION_ARROW}
                tabLabel={payload?.active_view?.view_name}
                buttonClick={!payload?.active_view?.temp_active_view ? () => handleChangeActiveView(payload?.active_view) : null}
                iconClick={(e) => {
                  specialViewTabIconHandler(e, payload?.active_view);
                }}
                isActive
              />
            </div>
          ) : null}
          <CustomStyledMenu
            anchorEl={spViewEL}
            open={Boolean(spViewEL)}
            onClose={() => setSpViewEL(null)}
            marginTop={"2px !important"}
            width={"13vw !important"}
          // arrow

          //isLeftStart
          >
            <Box className={classes.pinnedViewActions}>
              {showViewActions === payload?.active_view?.view_id && (
                <Box
                  onClick={() =>
                    handleViewActionChange(payload?.active_view?.view_id)
                  }
                >
                  {viewsActionList?.map((action, index) => {
                    return (
                      !action.inPinnedList && (
                        <Box
                          key={action.type}
                          onClick={() => action.action()}
                          className={
                            viewsActionList?.length - 1 === index
                              ? classes.borderFlex1
                              : classes.borderFlex1
                          }
                        >
                          <IconComponent
                            color={
                              showActions + hoverIndex ===
                                payload?.active_view?.view_id + index
                                ? BRAND.PRIMARY
                                : GREY.TERTIARY
                            }
                            fontSize={fontSize.MD}
                            iconLabel={action.icon}
                          />
                          <TypographyInline
                            label={
                              actionValue === action.type && actionPerforming
                                ? STRINGS.LOADING
                                : action.type
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                            color={
                              showActions + hoverIndex ===
                                payload?.active_view?.view_id + index
                                ? BRAND.PRIMARY
                                : GREY.SECONDARY
                            }
                          />
                        </Box>
                      )
                    );
                  })}
                </Box>
              )}
            </Box>
          </CustomStyledMenu>
          {/* Special Case */}

          {/* Manage View Button */}
          <div className={classes.manage}>
            {" "}
            <Button
              LeftIcon={ICON_LABELS.CLEAR_ALL}
              label={STRINGS.MANAGE_VIEWS}
              size={"sm36"}
              variant={"text"}
              onClick={() => setShowAllViewsModal(!showAllViewsModal)}
            />
          </div>

          {/* Manage View Button */}
        </div>
        <Box className={classes.actionContainer}>
          <div className={classes.saveViewButton}>
            <Button
              LeftIcon={ICON_LABELS.SAVE}
              label={STRINGS.SAVE_VIEW}
              size={"sm36"}
              variant={"ghost"}
              onClick={(e) => setSaveViewEL(e.currentTarget)}
            />
          </div>
          {/* All Modals */}
          {/* Create Contact Modal */}

          <Modal
            onClose={() => setShowCreateContact(false)}
            open={showCreateContact}
          >
            <CreateContactModal
              open={showCreateContact}
              setOpen={setShowCreateContact}
            />
          </Modal>
          {/* Create Contact Modal */}

          {/* Create List Modal */}

          <CreateListModal
            open={createListModal}
            setOpen={setCreateListModal}
          />
          {/* Create List Modal */}
          {/* Add View Modal */}

          {openAddView && (
            <AddViewModal open={openAddView} setOpen={setOpenAddView} viewType={viewType} />
          )}
          {/* Add View Modal */}
          {/* All Views Modal */}
          <AllViewModal
            viewToggler={openAddView}
            open={showAllViewsModal}
            setOpen={setShowAllViewsModal}
            setOpenAddView={setOpenAddView}
            setViewType={setViewType}
            viewType={viewType}
          />
          {/* All Views Modal */}

          {/* All Modals */}

          {/* Save View Content Here(button with Menu) */}

          <CustomStyledMenu
            open={Boolean(saveViewEL)}
            anchorEl={saveViewEL}
            onClose={() => setSaveViewEL(null)}
            noMargin={true}
            width="21vw"
          >
            {saveViewModal}
          </CustomStyledMenu>
          {/* Save View Content Here(button with Menu) */}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(PeopleHeader);
