import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import classes from "./Loader.module.css";
import SkeletonLoader from './SkeletonLoader';
const items = [
    { type1: 'rounded', type2: "text" },
    { type1: 'rounded', type2: "text" },
    { type1: 'rounded', type2: "text" },
    { type1: 'rounded', type2: "text" },
    { type1: 'rounded', type2: "text" },
]

const FolderSkeletonLoader = () => {
    return <Fragment>
        {items.map((item, index) => {
            return <Box key={index} className={classes.gapFlex} >
                <Box className={classes.containSkeleton} >
                    <SkeletonLoader
                        type={item.type1 || "rounded"}
                        skeletonWidth={20}
                        skeletonHeight={20}
                    />
                </Box>
                <Box className={classes.containSkeleton} >
                    <SkeletonLoader type={item.type2 || "text"} skeletonWidth={140} />
                </Box>
            </Box>
        })}
    </Fragment>
}

export default FolderSkeletonLoader;