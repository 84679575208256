import React, { useEffect, useState } from "react";
import { BRAND, GREY } from "../../../utils/constants/colors";
import classes from "./PipelineScreen.module.css";
import { STRINGS } from "./strings";
import MultipleDealsValues from "./components/MultipleDealsValues";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../../Apis";
import { crmEndpoints } from "../../../utils/constants/httpConstants";
import { useLocation, useNavigate } from "react-router-dom";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../utils/constants";
import DeletePipeline from "./components/Deletepipeline";
import {
  multipleValuesValidation,
  stringLengthValidation,
} from "../../../utils/validations/inputValidations";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";
import { SALES_EMPTY_SCREEN } from "../../../utils/constants/assets";
import Image from "../../../components/Image/Image";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import { getPipelineList } from "../../../redux/actions/getPipelineAction";
import { useDispatch } from "react-redux";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { Button, CustomModal, InputField } from "../../../components";
import { IconComponent } from "../../../components/Icon";
import CustomMenu from "../../../components/Menu/CustomMenu";

const PipelineScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //state management
  const [disable, setDisable] = useState(true);
  const [anchors, setAnchors] = useState(null);
  const [opens, setOpens] = useState(false);
  const [pipelineName, setPipelineName] = useState("");
  const [getUsers, setGetUsers] = useState([]);
  const [clickedId, setClickedId] = useState("");
  const [clickedLabel, setClickedLabel] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [editACtion, setEditAction] = useState(false);
  const [pipelineById, setPipelineById] = useState("");
  const [loader, SetLoader] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadTable, setLoadTable] = useState(false);
  const [validation, setValidation] = useState({
    name: "",
    probability: "",
    pipelineName: "",
    multiple_values: "",
  });
  const [valueUsers, setValueUsers] = useState([]);
  const [idUsers, setIdUsers] = useState([]);
  const [deleteStageModalOpen, setDeleteStageModalOpen] = useState(false);
  const [deleteModalApiData, setDeleteModalApiData] = useState({});
  const [deleteStageLoading, setDeleteStageLoading] = useState(false);

  /* ---------------handler start from here--------------------*/
  const internalChangeHandler = (event, row, multiValues) => {
    const modifiedArray = multiValues.map((obj) => {
      if (row.id === obj.id) {
        return {
          ...obj, // Copy all existing properties from the original object
          internalValue: event?.target?.value, // Add a new property for the internal value
        };
      } else {
        return { ...obj };
      }
    });
    setMultiValues(modifiedArray);
  };

  const labelChangeHandler = (event, row, multiValues) => {
    const modifiedArray = multiValues?.map((obj) => {
      setDisable(false);

      if (row?.id === obj?.id) {
        return {
          ...obj, // Copy all existing properties from the original object
          name: event?.target?.value?.trimStart(), // Add a new property for the label value
          labelValue: event?.target?.value?.trimStart(), // Add a new property for the label value
          setError: obj.labelValue?.trim() === "" ? true : false,
          setError2: obj.probability === "" ? true : false,
        };
      } else {
        return { ...obj };
      }
    });

    const mostmodifiedArray = modifiedArray?.map((obj) => {
      return {
        ...obj, // Copy all existing properties from the original object

        setError: obj.labelValue?.trim() === "" ? true : false,
        setError2: obj.probability === "" ? true : false,
      };
    });
    setMultiValues(mostmodifiedArray);
  };

  const labelProbabilityHandler = (event, row, multiValues) => {
    const modifiedArray = multiValues.map((obj) => {
      setDisable(false);

      if (row?.id === obj?.id) {
        return {
          ...obj, // Copy all existing properties from the original object
          probability:
            event?.target?.value <= 100 && event?.target?.value >= 0
              ? event?.target?.value?.trimStart()
              : "", // Add a new property for the label value
          setError2: event?.target?.value === "" || !(event?.target?.value <= 100 && event?.target?.value >= 0) ? true : false,
        };
      } else {
        return { ...obj };
      }
    });
    setMultiValues(modifiedArray);
  };
  const deleteHandler = async (row, multiValues) => {
    setDeleteStageModalOpen(true);
    setDeleteModalApiData({ row, multiValues });
  };

  const deleteStageApiHandler = async () => {
    setDeleteStageLoading(true);
    setDisable(true);
    let row = deleteModalApiData?.row;
    let multiValues = deleteModalApiData?.multiValues;


    try {
      let payload = {
        pipeline_id: pipelineById?.[0]?._id,
        stage_id: row?._id || row?.id
      }
      await deleteRequest(crmEndpoints.pipelineStageDelete, { ...payload }, navigate);

      if (multiValues?.length > 1) {
        let newValues = multiValues?.filter((item) => item?.id !== row?.id);
        setMultiValues(newValues);
      }
      await handleGetApi();
      setDeleteStageLoading(false);
      setDeleteStageModalOpen(false);
    } catch (error) {
      setDeleteStageLoading(false);
      console.log("Error--->>>>>>>>>>>>> ", error);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message)
    }

  };

  const colorChangeHandler = (event, row, multiValues) => {
    const modifiedArray = multiValues?.map((obj) => {
      setDisable(false);

      if (row?.id === obj?.id) {
        return {
          ...obj, // Copy all existing properties from the original object
          color: event.target.value
        };
      } else {
        return { ...obj };
      }
    });

    setMultiValues(modifiedArray);
  }

  const addLabelRowHandler = () => {
    //add stage
    let allIds = [];
    for (let index = 0; index < multiValues.length; index++) {
      allIds[index] = multiValues[index]["id"];
    }
    let newId = Math.max(...allIds) + 1;
    let newObj = {
      id: newId,
      labelOnchange: labelChangeHandler,
      probabilityChange: labelProbabilityHandler,
      internalOnchange: internalChangeHandler,
      onDeleteClick: deleteHandler,
      colorChangeHandler: colorChangeHandler,
      probability: "10",
      color: "#000000",
      setError: false,
      setError2: false,
    };
    setMultiValues((oldArray) => [...oldArray, newObj]);
  };


  /* ---------------handler end--------------------*/
  const initialMultipleValues = [
    {
      id: 1,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      probabilityChange: labelProbabilityHandler,
      onDeleteClick: deleteHandler,
      colorChangeHandler: colorChangeHandler,
      labelValue: STRINGS.OPPORTUNITY_IDENTIFIED,
      name: "",
      probability: "10",
      setError: false,
      setError2: false,
      color: "#8F5687"
    },
    {
      id: 2,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      probabilityChange: labelProbabilityHandler,
      onDeleteClick: deleteHandler,
      colorChangeHandler: colorChangeHandler,
      labelValue: STRINGS.PROPOSAL_SHARED,
      name: "",
      probability: "10",
      setError: false,
      setError2: false,
      color: "#FF5A1F",
    },
    {
      id: 3,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      probabilityChange: labelProbabilityHandler,
      onDeleteClick: deleteHandler,
      colorChangeHandler: colorChangeHandler,
      labelValue: STRINGS.NEGOTIATING,
      name: "",
      probability: "20",
      setError: false,
      setError2: false,
      color: "#00A2BB"
    },
    {
      id: 4,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      probabilityChange: labelProbabilityHandler,
      onDeleteClick: deleteHandler,
      colorChangeHandler: colorChangeHandler,
      labelValue: STRINGS.DEAL_WON,
      name: "",
      probability: "40",
      setError: false,
      setError2: false,
      color: "#01A63E"
    },
    {
      id: 5,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      probabilityChange: labelProbabilityHandler,
      onDeleteClick: deleteHandler,
      colorChangeHandler: colorChangeHandler,
      labelValue: STRINGS.DEAL_LOST,
      name: "",
      probability: "20",
      setError: false,
      setError2: false,
      color: "#ff4848"
    },
  ];
  const [multiValues, setMultiValues] = useState(initialMultipleValues);

  //Get api Call
  const handleGetApi = async (
    value = false,
    deletetrue = false,
    createTrue = false
  ) => {
    setLoadTable(true);
    dispatch(getPipelineList({}, navigate))
    const response = await getRequest(navigate, `${crmEndpoints.getPipleline}`);
    setGetUsers(response?.data?.data);

    if (response?.data?.data?.length && !value && !deletetrue && !createTrue) {
      const setEdit = response?.data?.data?.filter(
        (item) => item.label === pipelineName
      );

      // getting values from window when navigating from pipeline.
      let pipeline_id = window.selected_pipeline?.id?.[0] || "";
      let pipeline_label = window.selected_pipeline?.label?.[0] || "";
      // clearing the values from window.
      window.selected_pipeline = {};

      setLoadTable(true);
      setClickedId(setEdit[0]?.id || response?.data?.data[0]?.id);
      setClickedLabel(setEdit[0]?.label || response?.data?.data[0]?.label);
      setIdUsers([pipeline_id || idUsers?.[0] || response?.data?.data[0]?.id]);
      setValueUsers([pipeline_label || valueUsers?.[0] || response?.data?.data[0]?.label]);
      setLoadTable(false);
    } else {
      setValueUsers([]);
      setIdUsers([]);
      setClickedId("");
      setClickedLabel("");
    }
    setLoadTable(false);

    //delete & create section
    if (deletetrue || createTrue) {
      if (response?.data?.data?.length) {
        setValueUsers([response?.data?.data[0]?.label]);
        setIdUsers([response?.data?.data[0]?.id]);
        setClickedId(response?.data?.data[0]?.id);
        setClickedLabel(response?.data?.data[0]?.label);
      } else {
        setValueUsers([]);
        setIdUsers([]);
        setClickedId("");
        setClickedLabel("");
      }
    }

    //edit section
    if (editACtion || value) {
      setLoadTable(true);
      setEditAction(false);
      setPipelineName("");

      const setEdit = response?.data?.data?.filter(
        (item) => item.id === clickedId
      );
      if (response?.data?.data?.length) {
        setClickedId(setEdit[0]?.id);
        setClickedLabel(setEdit[0]?.label);
        setValueUsers([setEdit[0]?.label]);
        setIdUsers([setEdit[0]?.id]);
        setLoadTable(false);
      } else {
        setValueUsers([]);
        setIdUsers([]);
        setClickedId("");
        setClickedLabel("");
      }
    }
  };

  const resetPipelinedata = () => {
    let pipeline_stages = [...pipelineById[0]?.stages];

    let data = pipeline_stages?.map((item) => {
      return {
        ...item,
        id: item?._id,
        labelOnchange: labelChangeHandler,
        internalOnchange: internalChangeHandler,
        probabilityChange: labelProbabilityHandler,
        onDeleteClick: deleteHandler,
        colorChangeHandler: colorChangeHandler,
        labelValue: item?.name,
        setError: false,
        setError2: false,
      }
    })
    setMultiValues(data);
  }

  const handleActionSelection = (e) => {
    setAnchors(e.currentTarget);

    setOpens(true);
  };

  const HandleCreateDeal = () => {
    setCreateOpen(true);
  };

  //handle Create and Edit pipeline
  const handleCreatePipeline = async () => {
    const validateValue = stringLengthValidation(pipelineName, STRINGS.PIPELINE_NAME);
    if (validateValue.isValid) {
      //create pipeline Condition is here
      try {
        if (!editACtion) {
          SetLoader(true);
          const payload = {
            query: {
              pipeline_name: pipelineName?.trim(),
            },
          };
          const response = await postRequest(
            navigate,
            crmEndpoints.createPipeline,
            payload
          );
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          SetLoader(false);
          handleGetApi(false, false, true);
          setPipelineName("");
          setCreateOpen(!createOpen);
          setValidation({
            pipelineName: "",
          });
        } else {
          //edit pipeine condition is here
          SetLoader(true);
          const payload = {
            query: {
              id: clickedId,
              pipeline_name: pipelineName?.trim(),
            },
          };
          const responseEdit = await putRequest(
            navigate,
            crmEndpoints.editPipeline,
            payload
          );
          setPipelineById(responseEdit?.data?.data);
          EventEmitter.emit(EMITTER_SUCCESS, responseEdit?.data?.message);
          SetLoader(false);
          setCreateOpen(!createOpen);
          handleGetApi();
          setValidation({
            pipelineName: "",
          });
        }
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        SetLoader(false);
      } finally {
        SetLoader(false);
      }
    }
    else {
      setValidation({
        pipelineName: validateValue.message,
      });
    }
  };

  /*Create or Edit Header,body,footers Start*/
  const modalHeaderData = {
    heading: {
      id: STRINGS.SMALL_HEAD,
      label: editACtion ? STRINGS.EDITPIPELINE : STRINGS.CREATEPIPELINE,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <TypographyInline
        color={GREY.PRIMARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.PIPELINE_NAME}
        size={"sm"}
      />

      <InputField
        size={"sm36"}
        rows={1}
        placeholder={STRINGS.ENTER_PIPELINE}
        value={pipelineName}
        onChange={(e) => setPipelineName(e?.target?.value)}
        errormessage={validation.pipelineName}
        variant={validation.pipelineName ? STRINGS.ERROR : STRINGS.DEFAULT}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => {
          setCreateOpen(false);
          setPipelineName("");
          setEditAction(false);
          setValidation({
            name: "",
            probability: "",
            pipelineName: "",
            multiple_values: "",
          })
        }}
      />
      <Button
        label={editACtion ? STRINGS.UPDATE : STRINGS.CREATE}
        variant={"primary"}
        size={"sm36"}
        onClick={handleCreatePipeline}
        isLoading={loader}
      />
    </div>
  );
  const modalFooterLeftData = <div> </div>;

  const handleUserClick = async (id, label) => {
    setClickedId(id);
    setClickedLabel(label);
    if (id) {
      const responseData = await getRequest(
        navigate,
        `${crmEndpoints.getPiplelineById}/${id || ""}`
      );
      setPipelineById(responseData?.data?.data);
    }
  };

  // update/edit pipeline stages details
  const handleEditPipeline = async () => {
    setLoadingEdit(true);

    const validateMultipleValues = multipleValuesValidation(multiValues);

    if (validateMultipleValues.isValid) {
      try {
        const updatedArray = multiValues?.map((item, index) => {
          const variableData = {
            name: item?.labelValue,
            probability: item?.probability,
            _id: item?.id,
            color: item?.color,
            order: item?.order
          };

          return variableData;
        });

        const payload = {
          query: {
            id: clickedId,
            stages: updatedArray,
            pipeline_name: clickedLabel?.trim(),
          },
        };

        const responseEdit = await putRequest(
          navigate,
          crmEndpoints.editPipeline,
          payload
        );
        setLoadingEdit(false);

        setPipelineById(responseEdit?.data?.data);
        EventEmitter.emit(EMITTER_SUCCESS, responseEdit?.data?.message);

        setValidation({
          multiple_values: validateMultipleValues.message,
        });
        handleGetApi(true);
        setDisable(true);
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);

        setLoadingEdit(false);
      }
    } else {
      setLoadingEdit(false);

      setValidation({
        multiple_values: validateMultipleValues.message,
      });
    }
  };

  const handleActionClick = (id, label) => {
    if (id === STRINGS.EDIT) {
      setEditAction(true);
      setCreateOpen(true);
      setAnchors(null);
      setOpens(!opens);
    } else {
      setDeleteOpen(true);
      setAnchors(null);
      setOpens(!opens);
    }
  };
  const updateParentState = (data) => {
    // Your logic to handle the data from the child component
    if (data) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  // pipeline actions array
  const PipelineActions = [
    {
      id: STRINGS.EDIT, label: STRINGS.EDIT_LABEL, iconLabel: ICON_LABELS.EDIT, function: () => handleActionClick(STRINGS.EDIT, STRINGS.EDIT_LABEL),
    },
    { id: STRINGS.DELETE_SMALL, label: STRINGS.DELETE, iconLabel: ICON_LABELS.DELETE, function: () => handleActionClick(STRINGS.DELETE_SMALL, STRINGS.DELETE), variant: "error" },
  ];

  // delete stage confirmation modal details
  const deleteStageModalHeader = {
    heading: {
      id: STRINGS.SMALL_HEAD,
      label: `Delete ${deleteModalApiData?.row?.name || ""}`,
    },
  };
  const deleteStageModalRightFooter = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setDeleteStageModalOpen(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={deleteStageApiHandler}
        isLoading={deleteStageLoading}
      />
    </div>
  );
  const deleteStageModalLeftFooter = <div></div>;

  // UseEffect for getUsers ,clicked users
  useEffect(() => {
    handleGetApi();
  }, []);

  useEffect(() => {
    if (editACtion) {
      setPipelineName(clickedLabel);
    }
    if (pipelineById[0]?.stages?.length) {
      const modifiedArray = pipelineById[0]?.stages?.map((obj, index) => {
        return {
          id: index + 1,
          labelOnchange: labelChangeHandler,
          internalOnchange: internalChangeHandler,
          probabilityChange: labelProbabilityHandler,
          onDeleteClick: deleteHandler,
          colorChangeHandler: colorChangeHandler,
          ...obj, // Copy all existing properties from the original object
          probability: obj?.probability, // Add a new property for the label value
          labelValue: obj?.name,
        };
      });

      setMultiValues(modifiedArray);
    } else {
      setMultiValues(initialMultipleValues);
    }
  }, [clickedId, pipelineById, editACtion]);

  useEffect(() => {
    const isLabelUndefined = multiValues.some(
      (item) => item.labelValue === undefined || "" || item.setError
    );

    const isProbabilityUndefined = multiValues.some(
      (item) => item.probability === undefined || "" || item.setError2
    );
    if (isLabelUndefined) {
      setDisable(true);
    }
    if (isProbabilityUndefined) {
      setDisable(true);
    }
  }, [multiValues]);

  // UseEffect for getUsers ,clicked users
  useEffect(() => {
    if (idUsers?.length && valueUsers?.length) {
      handleUserClick(idUsers?.toString(), valueUsers?.toString());
    }
  }, [idUsers, valueUsers]);

  return (
    <div>
      <div className={classes.propertiesMainContainer}>
        <div>
          {/* Header Container */}
          <div className={classes.headerContainer}>
            <div>
              {" "}
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.SEMIBOLD}
                label={STRINGS.PIPELINE}
                size={"lg"}
              />
              <TypographyInline
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.DEAL_DES}
                size={"sm"}
              />
            </div>

            <div>
              <Button
                RightIcon={ICON_LABELS.ADD}
                label={STRINGS.CREATEPIPELINE}
                size={"sm36"}
                onClick={HandleCreateDeal}
                variant={"primary"}
              />
            </div>
          </div>

          {/* Body Container */}

          {/* Tabs */}
          {loadTable ? (
            <div className={classes.tabsContainer}>
              <div className={classes.tabsContainerSub}>
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={STRINGS.SELECT_PIPELINE}
                  size={"sm"}
                />
                <div className={classes.WidthDrop}>
                  {Array.from({ length: 1 })?.map(() => (
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={160}
                      skeletonHeight={40}
                    />
                  ))}
                </div>{" "}
              </div>
              <div>
                {Array.from({ length: 1 })?.map(() => (
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={24}
                    skeletonHeight={40}
                  />
                ))}
              </div>
            </div>
          ) : getUsers?.length ? (
            <div className={classes.tabsContainer}>
              <div className={classes.tabsContainerSub}>
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={STRINGS.SELECT_PIPELINE}
                  size={"lg"}
                />
                <div>
                  {loadTable ? (
                    <div className={classes.WidthDrop}>
                      {Array.from({ length: 1 })?.map(() => (
                        <SkeletonLoader
                          type="rounded"
                          skeletonWidth={160}
                          skeletonHeight={36}
                        />
                      ))}
                    </div>
                  ) : (
                    <AutoCompleteCustom
                      id="pipeline"
                      items={getUsers}
                      LeftIcon=""
                      valueState={valueUsers}
                      setValueState={setValueUsers}
                      idState={idUsers}
                      setIdState={setIdUsers}
                      value={
                        valueUsers?.length ? valueUsers : getUsers[0]?.label
                      }
                      papermenuclass={classes.papermenuclassmeeting}
                      size={"sm36"}
                    />
                  )}
                </div>{" "}
              </div>
              <div>
                {getUsers?.length && clickedId ? (
                  <div className={classes.Pointer}>
                    <IconComponent
                      color={GREY.TERTIARY}
                      fontSize={fontSize.DXS}
                      iconLabel={ICON_LABELS.MORE_VERT}
                      onClick={handleActionSelection}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.mainContainer}>
                <Image src={SALES_EMPTY_SCREEN} />
                <div className={classes.descriptionContainer}>
                  <div className={classes.descriptionLabel}>
                    <TypographyInline
                      label={STRINGS.EMPTY_DESC}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.REGULAR}
                      size="sm"
                    />
                    <TypographyInline
                      label={STRINGS.EMPTY_DESC2}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.REGULAR}
                      size="sm"
                    />
                  </div>
                </div>
                <div>
                  <Button
                    LeftIcon={ICON_LABELS.ADD}
                    label={STRINGS.CREATEPIPELINE}
                    size={"md40"}
                    variant="primary"
                    onClick={HandleCreateDeal}
                  />
                </div>
              </div>
            </div>
          )}

          {/* pipeline operation menu */}
          <CustomMenu
            items={PipelineActions}
            anchor={anchors}
            open={opens}
            onClose={() => { setAnchors(null); setOpens(false); }}
            paperMenuClass={classes.dropdownMenuListing}
          />

          {/* tableBody */}
          {loadTable ? (
            <div id={"modal_body"} className={classes.HeightSUbContainer}>
              <div className={classes.StageProbability}>
                <div className={classes.StageNameWidth}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.STAGE_NAME}
                    size={"sm"}
                  />
                </div>
                <div className={classes.DealPropbabilityWidth}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.DEAL_PROBABILITY}
                    size={"sm"}
                  />
                </div>
                <div className={classes.StageColorWidth}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.STAGE_COLOR}
                    size={"sm"}
                  />
                </div>
                <div className={classes.actionCellWidth}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.ACTION}
                    size={"sm"}
                  />
                </div>
              </div>
              <div className={classes.MultiDealcss}>
                <MultipleDealsValues
                  propertiesVal={multiValues}
                  setpropertiesVal={setMultiValues}
                  getUsers={getUsers}
                  validation={validation}
                  loadTable={loadTable}
                  updateParentState={updateParentState}
                />
              </div>
            </div>
          ) : getUsers?.length && clickedId ? (
            <div id={"modal_body"} className={classes.HeightSUbContainer}>
              <div className={classes.StageProbability}>
                {" "}
                <div className={classes.StageNameWidth}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.STAGE_NAME}
                    size={"sm"}
                  />
                </div>
                <div className={classes.DealPropbabilityWidth}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.DEAL_PROBABILITY}
                    size={"sm"}
                  />
                </div>
                <div className={classes.StageColorWidth}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.STAGE_COLOR}
                    size={"sm"}
                  />
                </div>
                <div className={classes.actionCellWidth}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.ACTION}
                    size={"sm"}
                  />
                </div>
              </div>
              <div className={classes.MultiDealcss}>
                <MultipleDealsValues
                  propertiesVal={multiValues}
                  setpropertiesVal={setMultiValues}
                  getUsers={getUsers}
                  validation={validation}
                  loadTable={loadTable}
                  updateParentState={updateParentState}
                />
                {validation.multiple_values && <TypographyInline color={BRAND.PRIMARY} size={"xs"} label={validation.multiple_values} fontWeight={fontWeight.REGULAR} />}
              </div>
            </div>
          ) : null}

          {/* Add Stage ,Cancel and Save */}
          {getUsers?.length && clickedId ? (
            <div className={classes.modalFooterRightData2}>
              <div>
                <Button
                  LeftIcon={ICON_LABELS.ADD}
                  label={STRINGS.ADD_LABEL}
                  size={"sm36"}
                  variant={"ghost"}
                  onClick={addLabelRowHandler}
                />
              </div>
              <div className={classes.DisplayFlex}>
                <Button
                  label={STRINGS.CANCEL}
                  variant={"ghost"}
                  size={"sm36"}
                  onClick={() => {
                    resetPipelinedata();
                  }}
                />
                <div>
                  {" "}
                  <Button
                    label={STRINGS.UPDATE}
                    variant={"primary"}
                    size={"sm36"}
                    onClick={handleEditPipeline}
                    isLoading={loadingEdit}
                    disabled={disable}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* create or edit pipeline modal */}
      <CustomModal
        close={() => {
          setCreateOpen(false);
          setPipelineName("");
          setEditAction(false);
          setValidation({
            name: "",
            probability: "",
            pipelineName: "",
            multiple_values: "",
          })
        }}
        open={createOpen}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
      />

      {/* delete pipeline modal */}
      {deleteOpen && (
        <DeletePipeline
          deleteOpen={deleteOpen}
          setDeleteOpen={setDeleteOpen}
          clickedId={clickedId}
          setClickedId={setClickedId}
          handleGetApi={handleGetApi}
          PipelineName={pipelineName}
        />
      )}

      {/* delete stage modal */}
      {
        deleteStageModalOpen && <CustomModal
          variant={"error"}
          close={() => setDeleteStageModalOpen(false)}
          open={deleteStageModalOpen}
          header={deleteStageModalHeader}
          footer={{
            left: deleteStageModalLeftFooter,
            right: deleteStageModalRightFooter,
          }}
        >
          <div>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.ARE_YOU_SURE_DELETE}
            />
          </div>
        </CustomModal>
      }
    </div>
  );
};

export default PipelineScreen;
