import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { STRINGS } from "../strings";
import { getErrorMessage } from "../../../../utils/helpers";
import { contactListEndPoints } from "../../../../utils/constants/httpConstants";
import { postRequest, putRequest } from "../../../../Apis/apiWrapper";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import InputField from "../../../../components/InputFields/InputField";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import CustomModal from "../../../../components/Modals/CustomModal";
import { Button } from "../../../../components/Buttons";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../../utils/constants";
import { useDispatch } from "react-redux";
import { paginationDataAction } from "../../../../redux/actions/customTableActions";
import { getContactListData } from "../../../../redux/actions/contactListactions";
import { Box } from "@mui/material";
import {
  LIST_NAME_LENGTH_ERROR,
  LIST_NAME_LONG_LENGTH_ERROR,
  MESSAGE_REQUIRED_FIELD,
} from "../../../../utils/constants/messages";
import classes from "../markComponents.module.css";

const CreateListModal = ({
  open,
  setOpen,
  permission,
  listNameValue,
  parent,
  itemId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = React.useState(
    permission || STRINGS.PRIVATE_ID
  );
  const [listName, setListName] = useState(listNameValue ? listNameValue : "");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const radioButtonData = [
    {
      id: STRINGS.PRIVATE_ID,
      label: STRINGS.PRIVATE_LABEL,
      disabled: parent?.length ? true : false,
    },
    {
      id: STRINGS.TEAM_ID,
      label: STRINGS.TEAM_LABEL,
      disabled: parent?.length ? true : false,
    },
  ];

  const handleCreate = async (e) => {
    e?.preventDefault();
    try {
      if (listName?.trim()?.length === 0) {
        setErrMsg(MESSAGE_REQUIRED_FIELD);
        return;
      } else if (listName?.trim()?.length < 3) {
        setErrMsg(LIST_NAME_LENGTH_ERROR);
        return;
      } else if (listName?.trim()?.length > 50) {
        setErrMsg(LIST_NAME_LONG_LENGTH_ERROR);
        return;
      } else {
        setLoading(true);
        setErrMsg("");
        const URL = itemId?.length
          ? contactListEndPoints.editList
          : contactListEndPoints.createList;
        let data = {
          list_name: listName,
          permission: selectedValue,
          type: STRINGS.SMALL_FILE,
        };

        if (itemId?.length) {
          data.list_id = itemId;
        }

        if (parent?.length) {
          data.parent = parent;
        }
        const response = itemId?.length
          ? await putRequest(navigate, URL, data)
          : await postRequest(navigate, URL, data);
        dispatch(paginationDataAction({}));
        const selectorPayload = {};
        if (parent?.length) {
          selectorPayload.parent = parent;
        }
        dispatch(getContactListData(selectorPayload));
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setOpen(false);
      }
    } catch (error) {
      setErrMsg(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: itemId?.length ? STRINGS.UPDATE_LIST : STRINGS.CREATE_LISST,
    },
  };

  const modalBodyData = (
    <form className={classes.modalBodyContainer} onSubmit={handleCreate}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.LISTNAME}
        data-tour="9"
      />
      <InputField
        value={listName}
        placeholder={STRINGS.TYPELIST}
        size={"sm36"}
        onChange={(e) => setListName(e.target.value)}
        variant={errMsg ? "error" : "default"}
        errormessage={errMsg}
      />
      <div className={classes.modalPadTop}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.SHARED_WITH}
          data-tour="10"
        />
      </div>
      <div className={classes.modalRad}>
        <RadioButton
          value={selectedValue}
          onChange={handleChange}
          items={radioButtonData}
        />
      </div>
    </form>
  );

  const modalFooterLeftData = <div></div>;
  const modalFooterRightData = (
    <Box className={classes.rightFoot}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={itemId ? STRINGS.UPDATE : STRINGS.CREATE}
        size={"sm36"}
        variant={"primary"}
        onClick={handleCreate}
        isLoading={loading}
      />
    </Box>
  );

  return (
    <CustomModal
      open={open}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default CreateListModal;
