import React, { useState } from "react";
import { tempHeadCells } from "../../../utils/constants";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import {
  applyUpdateColumn,
  companyDataFetch,
  tableDataFetch,
  updateColumnHeaders,
} from "../../../redux/actions/tableActions";
import * as qs from "qs";
import { useSelector, useDispatch } from "react-redux";
import { filterQuery, removeObjFromArray } from "../../../utils/helpers";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import CustomModal from "../../../components/Modals/CustomModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconComponent from "../../../components/Icon/IconComponent";
import { GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontSize } from "../../../utils/constants/UI";
import { fontWeight } from "../../../utils/constants/UI/uiConstants";
import TypographyInline from "../../../components/Typography/TypographyInline";
import SearchField from "../../../components/InputFields/SearchField";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import classes from "./LeadsComponent.module.css";

export default function EditColumnModal({ leadType = STRINGS.PEOPLE }) {
  const selector = useSelector((state) => state.ApplyUpdateColumn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const querySelector = filterQuery(
    useSelector((state) => state?.allFilters?.payload)
  );
  const tableEndpointChangeSelector = useSelector(
    (state) => state.tableEndpointChange
  );
  const companyTableEndpointChangeSelector = useSelector(
    (state) => state.companyTableEndpointChange
  );
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [headers, setHeaders] = useState(
    selector.columns || JSON.parse(JSON.stringify(tempHeadCells))
  );
  const [search, setSearch] = useState("");
  const [list, setList] = React.useState(selector.columns || []);
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const queryObjectLength = Object.keys(queryObject).length;

  const buttonCheckerQuery = { ...(querySelector || {}) };
  delete buttonCheckerQuery.search;
  delete buttonCheckerQuery.page;
  delete buttonCheckerQuery.limit;
  let isEditable = Object.keys(buttonCheckerQuery).length ? true : false;
  if (tableEndpointChangeSelector.payload === STRINGS.SAVED_CONTACT) {
    isEditable = true;
  }

  if (companyTableEndpointChangeSelector.payload === STRINGS.SAVED_COMPANIES) {
    isEditable = true;
  }

  const handleModalChange = () => {
    setOpenConfirmation(!openConfirmation);
    setSearch("")
  };

  const handleApplyColumnChange = () => {
    dispatch(applyUpdateColumn(list));
    dispatch(updateColumnHeaders(list));
    leadType === STRINGS.PEOPLE
      ? dispatch(tableDataFetch(querySelector, navigate))
      : dispatch(companyDataFetch(querySelector, navigate));
    setOpen(!open);
    setSearch("");
  };

  const handleClose = () => { setSearch(""); setOpen(false) };
  const handleChange = () => setOpen(!open);

  const handleChangeInAllHeaders = (column) => {
    const index = headers.findIndex((e) => e.id === column.id);
    let settedHeaders = headers;
    settedHeaders[index] = { ...column, isChecked: !column.isChecked };
    setList(settedHeaders);
    dispatch(updateColumnHeaders(settedHeaders));
    dispatch(applyUpdateColumn(settedHeaders));
  };

  const handleRemoveColumn = async (column) => {
    try {
      let newArray = removeObjFromArray(headers, column);
      setHeaders([...newArray, { ...column, isChecked: false }]);
      setList([...newArray, { ...column, isChecked: false }]);
      dispatch(
        updateColumnHeaders([...newArray, { ...column, isChecked: false }])
      );
      dispatch(
        applyUpdateColumn([...newArray, { ...column, isChecked: false }])
      );
    } catch (error) { }
  };

  React.useEffect(() => {
    setList(selector?.columns || JSON.parse(JSON.stringify(tempHeadCells)));
    setHeaders(selector?.columns || JSON.parse(JSON.stringify(tempHeadCells)));
  }, [selector]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setList(items);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.CHOOSE,
    },
  };

  const staticColumn = [
    STRINGS.COMPANY,
    STRINGS.EMAIL,
    STRINGS.JOB_TITLE,
    STRINGS.EMPLOYEES,
    STRINGS.PERSON_STATE_CAPS,
    STRINGS.PERSON_COUNTRY_CAPS,
    STRINGS.ANNUAL_REVENUE,
    STRINGS.COMPANY_WEBSITE,
    STRINGS.COMPANY_CITY_CAPS,
    STRINGS.COMPANY_COUNTRY_CAPS,
    STRINGS.COMPANY_STATE_CAPS,
    STRINGS.INDUSTRY_CAPS
  ]

  const modalBodyData = (
    <div className={classes.modalBody}>
      <div className={classes.containHead}>
        <div>
          <div className={classes.containLeft}>
            <div className={classes.searchContainer}>
              <TypographyInline
                label={STRINGS.CONTACT}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
                color={GREY.SECONDARY}
              />
              <SearchField
                size={"sm36"}
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
                placeholder={STRINGS.SEARCH_PROPERTY}
                autocomplete="off"
              />
            </div>
            <div
              id={STRINGS.MODAL_BODY}
              className={classes.containCotactColumns}
            >
              {leadType === STRINGS.PEOPLE ? (
                <div className={classes.allColumnsContainer}>
                  <div className={classes.columnLabelContainer}>
                    <TypographyInline
                      size={"sm"}
                      fontWeight={fontWeight.MEDIUM}
                      color={GREY.PRIMARY}
                      label={STRINGS.PEOPLE}
                    />
                    <div className={classes.containColumnMainContainer}>
                      {selector?.columns?.map((column) => {
                        return column?.id !== STRINGS.FIRST_NAME &&
                          !column?.companyField &&
                          (column?.label
                            ?.toLowerCase()
                            ?.includes(search?.toLocaleLowerCase()) ||
                            search?.length === 0) ? (
                          <div
                            className={classes.containColumns}
                            key={column.label}
                          >
                            <div className={classes.iconLabelContainer}>
                              <Checkbox
                                onChange={() =>
                                  handleChangeInAllHeaders(column)
                                }
                                checked={column.isChecked}
                                disabled={
                                  column.label === STRINGS.EMAIL ||
                                  column.label === STRINGS.JOB_TITLE ||
                                  column.label === STRINGS.PERSON_STATE_CAPS ||
                                  column.label === STRINGS.PERSON_COUNTRY_CAPS
                                }
                                label={column.label}
                              />
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                  <div className={classes.columnLabelContainer}>
                    <TypographyInline
                      size={"sm"}
                      fontWeight={fontWeight.MEDIUM}
                      color={GREY.PRIMARY}
                      label={STRINGS.SINGLE_COMPANY}
                    />
                    <div className={classes.containColumnMainContainer}>
                      {selector?.columns?.map((column) => {
                        return column?.id !== STRINGS.FIRST_NAME &&
                          column?.companyField &&
                          (column?.label
                            ?.toLowerCase()
                            ?.includes(search?.toLocaleLowerCase()) ||
                            search?.length === 0) ? (
                          <div
                            className={classes.containColumns}
                            key={column.label}
                          >
                            <div className={classes.iconLabelContainer}>
                              <Checkbox
                                onChange={() =>
                                  handleChangeInAllHeaders(column)
                                }
                                checked={column.isChecked}
                                disabled={
                                  column.label === STRINGS.NAME ||
                                  column.label === STRINGS.COMPANY ||
                                  column.label === STRINGS.EMPLOYEES ||
                                  column.label === STRINGS.COMPANY_WEBSITE ||
                                  column.label === STRINGS.INDUSTRY_CAPS
                                }
                                label={column.label}
                              />
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.columnLabelContainer}>
                  <TypographyInline
                    size={"sm"}
                    fontWeight={fontWeight.MEDIUM}
                    color={GREY.PRIMARY}
                    label={STRINGS.SINGLE_COMPANY}
                  />
                  <div className={classes.containColumnMainContainer}>
                    {selector?.columns?.map((column) => {
                      return column.label !== STRINGS.COMPANY &&
                        column?.companyField &&
                        (column?.label?.toLowerCase()?.includes(search) ||
                          search?.length === 0) ? (
                        <div
                          className={classes.containColumns}
                          key={column.label}
                        >
                          <Checkbox
                            onChange={() => handleChangeInAllHeaders(column)}
                            checked={column.isChecked}
                            disabled={
                              column.label === STRINGS.NAME ||
                              column.label === STRINGS.COMPANY ||
                              column.label === STRINGS.EMPLOYEES ||
                              column.label === STRINGS.COMPANY_WEBSITE ||
                              column.label === STRINGS.INDUSTRY_CAPS ||
                              column.label === STRINGS.ANNUAL_REVENUE ||
                              column.label === STRINGS.COMPANY_CITY_CAPS ||
                              column.label === STRINGS.COMPANY_COUNTRY_CAPS ||
                              column.label === STRINGS.COMPANY_STATE_CAPS}
                            label={column.label}
                          />
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* DRag and Drop container */}
        <div className={classes.containRight}>
          <div id={STRINGS.MODAL_BODY} className={classes.containScroll}>
            <div className={classes.draggableDivLabel}>
              <TypographyInline
                label={`${leadType === STRINGS.PEOPLE ? "" : STRINGS.COMPANY_SINGLE} ${STRINGS.NAME}`}
                size="sm"
                fontWeight={fontWeight.REGULAR}
                color={GREY.SECONDARY}
              />
            </div>

            {leadType === STRINGS.PEOPLE ? (
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={STRINGS.COLUMNS_LIST}>
                  {(provided, snapshot) => (
                    <div
                      className={classes.draggableMainDiv}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {list.map((column, index) => {
                        console.log("LISTYTYT", list)
                        return (
                          column?.isChecked &&
                          column?.label !== STRINGS.NAME && (
                            <Draggable
                              key={column?.id}
                              draggableId={column?.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={classes.draggableDiv}>
                                    <div className={classes.dragLabelContainer}>
                                      <div className={classes.centered}>
                                        <IconComponent
                                          color={GREY.TERTIARY}
                                          fontSize={fontSize.MD}
                                          iconLabel={ICON_LABELS.DRAG_INDICATOR}
                                        />
                                      </div>
                                      <TypographyInline
                                        label={column?.label}
                                        size="sm"
                                        fontWeight={fontWeight.REGULAR}
                                        color={GREY.SECONDARY}
                                      />
                                    </div>
                                    {!staticColumn.includes(column.label) ? (
                                      <div
                                        className={classes.crossIcon}
                                        onClick={() =>
                                          handleRemoveColumn(column)
                                        }
                                      >
                                        <IconComponent
                                          color={GREY.TERTIARY}
                                          fontSize={fontSize.DXS}
                                          iconLabel={ICON_LABELS.CLOSE}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId={STRINGS.COLUMNS_LIST}>
                  {(provided, snapshot) => (
                    <div
                      className={classes.draggableMainDiv}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >

                      {list.map((column, index) => {
                        console.log("LISTYTYT", list)
                        return (
                          column?.isChecked &&
                          column?.companyField &&
                          column?.label !== STRINGS.COMPANY && (
                            <Draggable
                              key={column?.id}
                              draggableId={column?.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={classes.draggableDiv}>
                                    <div className={classes.dragLabelContainer}>
                                      <div>
                                        <IconComponent
                                          color={GREY.TERTIARY}
                                          fontSize={fontSize.MD}
                                          iconLabel={ICON_LABELS.DRAG_INDICATOR}
                                        />
                                      </div>
                                      <TypographyInline
                                        label={column?.label}
                                        size="sm"
                                        fontWeight={fontWeight.REGULAR}
                                        color={GREY.SECONDARY}
                                      />
                                    </div>

                                    {!staticColumn.includes(column.label) ? (
                                      <div
                                        className={classes.crossIcon}
                                        onClick={() =>
                                          handleRemoveColumn(column)
                                        }
                                      >
                                        <IconComponent
                                          color={GREY.TERTIARY}
                                          fontSize={fontSize.MD}
                                          iconLabel={ICON_LABELS.CLOSE}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        </div>
        {/* DRag and Drop container */}
      </div>
    </div>
  );

  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={() => { setOpen(!open); setSearch(""); }}
      />

      <Button
        label={STRINGS.APPLY}
        size={"sm36"}
        variant={"primary"}
        onClick={handleApplyColumnChange}
      />
    </div>
  );

  const modalFooterLeftData = (
    <div>
      <Button
        label={STRINGS.RESET_ALL_PROPERTIES}
        LeftIcon={ICON_LABELS.RESTART_ALT}
        size={"sm36"}
        variant={"text"}
        onClick={handleModalChange}
      />
    </div>
  );

  return (
    <div>
      <ConfirmationModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        setHeaders={setHeaders}
        setList={setList}
      />

      <Button
        label={STRINGS.EDIT_COLUMNS}
        LeftIcon={ICON_LABELS.VIEW_COLUMN}
        size={"sm36"}
        disabled={(!(queryObjectLength > 0) && (leadType === STRINGS.PEOPLE ? tableEndpointChangeSelector.payload !== STRINGS.SAVED_CONTACT : companyTableEndpointChangeSelector.payload !== STRINGS.SAVED_COMPANIES))}
        variant={"ghost"}
        onClick={() => {
          (isEditable || querySelector?.search?.length) && handleChange();
        }}
      />

      <CustomModal
        width={"41.667vw"}
        close={handleClose}
        open={open}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
        remove_padding
      />
    </div>
  );
}
