export const lightModePalette = {
  primary: {
    500: "#ff4848",
    
  },
    mode: "light",
    background: {
      default: "white",
    },
  };
 