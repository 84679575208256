import React, { Fragment, useEffect, useState } from "react";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import InputField from "../../../components/InputFields/InputField";
import { BRAND, GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import {
  FIELD_TYPE_LABELS,
  replaceKeyInArrayOfObj,
} from "../../../utils/helpers";
import { Button } from "../../../components/Buttons";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";
import { HtmlEditor } from "../../../components";
import CustomTableHeader from "./CustomTableHeader";
import MultiplePropertyValue from "./MultiplePropertyValue";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../../Apis";
import { useNavigate } from "react-router-dom";
import { crmEndpoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  objectTypeDropdownValues,
} from "../../../utils/constants";
import { nameValidation } from "../../../utils/validations";
import {
  getGroupsListByObjectType,
  getPropertiesList,
} from "../../../redux/actions/propertiesAction";
import { putRequest } from "../../../Apis/apiWrapper";
import classes from "./PropComponent.module.css";
import TextArea from "../../../components/InputFields/TextArea";
import TypographyInline from "../../../components/Typography/TypographyInline";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";
import {
  multipleValuesValidation,
  propertiesGrpNameValidation,
} from "../../../utils/validations/inputValidations";
import FilterMultipleCheckbox from "../../../components/FilterTypes/Dynamic/FilterMultipleCheckbox";

const CreatePropertyModal = ({
  open,
  close,
  data = {},
  isEdit,
  secondStep,
  setSecondStep,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getGroupListByObjecTypeSelector = useSelector(
    (state) => state.getGroupsListByObjectType
  );

  const internalChangeHandler = (event, row, multiValues) => {
    const modifiedArray = multiValues.map((obj) => {
      if (row.id === obj.id) {
        return {
          ...obj, // Copy all existing properties from the original object
          internalValue: event?.target?.value, // Add a new property for the internal value
        };
      } else {
        return { ...obj };
      }
    });
    setMultiValues(modifiedArray);
  };

  const labelChangeHandler = (event, row, multiValues) => {
    const modifiedArray = multiValues.map((obj) => {
      if (row.id === obj.id) {
        return {
          ...obj, // Copy all existing properties from the original object
          labelValue: event?.target?.value, // Add a new property for the label value
        };
      } else {
        return { ...obj };
      }
    });
    setMultiValues(modifiedArray);
  };

  const deleteHandler = (row, multiValues) => {
    if (multiValues?.length > 1) {
      let newValues = multiValues?.filter((item) => item?.id !== row?.id);
      setMultiValues(newValues);
    }
  };

  const initialMultipleValues = [
    {
      id: 1,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      onDeleteClick: deleteHandler,
    },
    {
      id: 2,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      onDeleteClick: deleteHandler,
    },
    {
      id: 3,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      onDeleteClick: deleteHandler,
    },
  ];

  //   States used in this screen.
  const [multiValues, setMultiValues] = useState(initialMultipleValues);
  const [typeValue, setTypeValue] = useState([]);
  const [typeId, setTypeId] = useState([]);
  const [grpIdState, setGrpIdState] = useState([]);
  const [grpValueState, setGrpValueState] = useState([]);
  const [objectTypeValueState, setObjectTypeValueState] = useState([]);
  const [objectTypeIdState, setObjectTypeIdState] = useState([]);
  const [propertyLabel, setPropertyLabel] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState({
    object_type: "",
    label_name: "",
    field_type: "",
    multiple_values: "",
  });
  const [previewSelectIdState, setPreviewSelectIdState] = useState([]);
  const [previewSelectValueState, setPreviewSelectValueState] = useState([]);
  const [inForm, setInForm] = useState(false);

  // create property header data
  const modalHeaderData = {
    heading: {
      id: STRINGS.CRT_PROP_SMALL,
      label: isEdit ? STRINGS.EDIT_PROPERTY : STRINGS.CREATE_PROPERTY,
    },
  };

  // submitting property modal or create property api call.
  const createPropertyHandler = async () => {
    // creatig payload object as per the field type.
    let payload = {};
    if (
      typeId[0] === STRINGS.SINGLE_SELECT ||
      typeId[0] === STRINGS.MULTIPLE_SELECT ||
      typeId[0] === STRINGS.MULTIPLE_CHECKBOX
    ) {
      payload = {
        query: {
          mappings: {
            object_type: objectTypeValueState,
            grp_id: grpIdState,
            name: propertyLabel,
            type: typeValue,
            value: multiValues,
            in_form: inForm,
          },
        },
      };
    } else {
      payload = {
        query: {
          mappings: {
            object_type: objectTypeValueState,
            grp_id: grpIdState,
            name: propertyLabel,
            type: typeValue,
            value:
              typeId[0] === STRINGS.RICH_TEXT_EDITOR
                ? document.getElementById("editor")?.innerHTML?.toString()
                : inputValue,
            in_form: inForm,
          },
        },
      };
    }
    // creatig payload object as per the field type.

    // checking required field.
    const validatedObjectType = nameValidation(objectTypeValueState);
    const validatedPropertyLabel = propertiesGrpNameValidation(propertyLabel);
    const validatedTypeId = nameValidation(typeId);
    const validateMultipleValues = multipleValuesValidation(multiValues);
    // checking required field.
    // api call for creating property
    if (
      validatedObjectType.isValid &&
      validatedPropertyLabel.isValid &&
      validatedTypeId.isValid &&
      (typeId[0] === STRINGS.SINGLE_SELECT ||
      typeId[0] === STRINGS.MULTIPLE_SELECT ||
      typeId[0] === STRINGS.MULTIPLE_CHECKBOX
        ? validateMultipleValues.isValid
        : true)
    ) {
      try {
        // when doing edit operation of property.
        if (isEdit) {
          let editPayload = {
            ...payload,
            query: {
              ...payload.query,
              id: data?._id,
            },
          };
          setIsLoading(true);
          const response = await putRequest(
            navigate,
            crmEndpoints.editProperty,
            editPayload
          );
          await dispatch(getPropertiesList({}, navigate));
          setIsLoading(false);
          modalCloseHandler();
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        }
        // when doing edit operation of property.

        // when creating property.
        else {
          setIsLoading(true);
          const response = await postRequest(
            navigate,
            crmEndpoints.createDynamic,
            payload
          );
          await dispatch(getPropertiesList({}, navigate));

          setIsLoading(false);
          // stored object type dropown.
          setObjectTypeValueState([]);
          setObjectTypeIdState([]);
          // stored object type dropown.

          // stored field type dropdown.
          setTypeValue([]);
          setTypeId([]);
          // stored field type dropdown.

          // stored group name dropdown
          setGrpIdState([]);
          setGrpValueState([]);
          // stored group name dropdown

          // stored property name
          setPropertyLabel("");
          // stored property name
          setMultiValues(initialMultipleValues);
          setInputValue("");
          modalCloseHandler();
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        }
        // when creating property.
      } catch (error) {
        setIsLoading(false);
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    } else {
      setErrorState({
        object_type: validatedObjectType.message,
        label_name: validatedPropertyLabel.message,
        field_type: validatedTypeId.message,
        multiple_values: validateMultipleValues.message,
      });
    }
    // api call for creating property
  };

  // step validation for label value and object type
  const stepValidation = () => {
    // checking required field.
    const validatedObjectType = nameValidation(objectTypeValueState);
    const validatedPropertyLabel = propertiesGrpNameValidation(
      propertyLabel?.trim()
    );

    if (validatedObjectType.isValid && validatedPropertyLabel.isValid) {
      setSecondStep(true);
      setErrorState({
        object_type: "",
        label_name: "",
      });
    } else {
      setErrorState({
        object_type: validatedObjectType.message,
        label_name: validatedPropertyLabel.message,
      });
    }
  };

  // cancelButton function and setting error to empty.
  const modalCloseHandler = () => {
    close();

    // stored object type dropown.
    setObjectTypeValueState([]);
    setObjectTypeIdState([]);
    // stored object type dropown.

    // stored field type dropdown.
    setTypeValue([]);
    setTypeId([]);
    // stored field type dropdown.

    // stored group name dropdown
    setGrpIdState([]);
    setGrpValueState([]);
    // stored group name dropdown

    // stored property name
    setPropertyLabel("");
    // stored property name

    // stored property visibility
    setInForm(false);
    // stored property visibility

    setErrorState({
      object_type: "",
      label_name: "",
      field_type: "",
    });
  };

  console.log({ propertyLabel });
  // modal body data step 1.
  const formDataStep1 = [
    {
      label: STRINGS.OBJECT_TYPE,
      placeholder: STRINGS.SELECT_PLACEHOLDER,
      isRequired: true,
      isDropdown: true,
      isMultiple: false,
      items: objectTypeDropdownValues,
      variant: isEdit ? "disabled" : "default",

      errorMessage: errorState?.object_type?.length
        ? errorState?.object_type
        : "",
      idState: objectTypeIdState,
      setIdState: setObjectTypeIdState,
      valueState: objectTypeValueState,
      setValueState: setObjectTypeValueState,
    },
    {
      label: STRINGS.SELECT_GROUP,
      placeholder: STRINGS.SELECT_PLACEHOLDER,
      isRequired: false,
      isDropdown: true,
      variant: errorState?.object_type?.length ? "error" : "default",

      items: replaceKeyInArrayOfObj(
        getGroupListByObjecTypeSelector?.data || [],
        "grp_name",
        "label"
      ),
      idState: grpIdState,
      setIdState: setGrpIdState,
      valueState: grpValueState,
      setValueState: setGrpValueState,
      isLoading: getGroupListByObjecTypeSelector?.loading,
    },
    {
      label: STRINGS.LABEL_NAME,
      placeholder: STRINGS.LABEL_NAME_PLACEHOLDER,
      isRequired: true,
      isDropdown: false,
      value: propertyLabel,
      onChange: (e) => {
        setPropertyLabel(e?.target?.value);
      },
      variant: errorState?.object_type?.length ? "error" : "default",
      errorMessage: errorState.label_name?.length ? errorState?.label_name : "",
    },
  ];

  // modal body data step 2.
  const formDataStep2 = [
    {
      label: STRINGS.FIELD_TYPE,
      placeholder: STRINGS.SELECT_PLACEHOLDER,
      isRequired: true,
      isDropdown: true,
      items: FIELD_TYPE_LABELS,
      variant: errorState.field_type?.length
        ? "error"
        : isEdit
        ? "disabled"
        : "default",
      errorMessage: errorState.field_type?.length ? errorState?.field_type : "",
      valueState: typeValue,
      setValueState: setTypeValue,
      idState: typeId,
      setIdState: setTypeId,
      onChange: () => {
        setInputValue("");
      },
    },
  ];

  // modal footer buttons
  const modalFooterRightData = (
    <div className={classes.createPropFooter}>
      {secondStep ? (
        <Button
          label={STRINGS.BACK}
          variant={"ghost"}
          size={"sm36"}
          onClick={() => setSecondStep(false)}
        />
      ) : null}
      <Button
        label={
          isEdit
            ? secondStep
              ? STRINGS.UPDATE
              : STRINGS.NEXT
            : secondStep
            ? STRINGS.SAVE
            : STRINGS.NEXT
        }
        isLoading={isLoading}
        variant={"primary"}
        size={"sm36"}
        onClick={secondStep ? createPropertyHandler : stepValidation}
      />
    </div>
  );

  const modalFooterLeftData = (
    <div>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={modalCloseHandler}
      />
    </div>
  );

  // static values for multiple select drag and drop single row.
  const multiValueLabel = [
    { id: STRINGS.DRAG_ICON_SMALL, label: "" },
    { id: STRINGS.LABEL_SMALL, label: STRINGS.ADD_VALUE },
    // { id: STRINGS.INTERNAL_VALUE_SMALL, label: STRINGS.INTERNAL_VALUE },
    // { id: "in_form", label: STRINGS.IN_FORM },
    { id: STRINGS.ACTION_SMALL, label: "" },
  ];

  // function will add one more row in drag and drop label container
  const addLabelRowHandler = () => {
    let allIds = [];
    for (let index = 0; index < multiValues.length; index++) {
      allIds[index] = multiValues[index]["id"];
    }
    let newId = Math.max(...allIds) + 1;
    let newObj = {
      id: newId,
      labelOnchange: labelChangeHandler,
      internalOnchange: internalChangeHandler,
      onDeleteClick: deleteHandler,
      // inFormHandler: inFormHandler,
    };
    setMultiValues((oldArray) => [...oldArray, newObj]);
  };

  // select or multiselect field type design of internal value.
  const DROPDOWN_LABEL_VALUES_DESIGN = (
    <div id="modal_body" className={classes.dropdownLabelContainer}>
      {/* Header Container */}
      <div className={classes.headerContainerInputVal}>
        {multiValueLabel?.map((item, index) => {
          return (
            <Fragment key={index}>
              {item?.label?.length ? (
                <div
                  key={index}
                  className={`${
                    item?.label === STRINGS.IN_FORM
                      ? classes.inFormHeaderContainer
                      : classes.labelHeaderContainer
                  }`}
                >
                  <CustomTableHeader
                    key={index}
                    index={index}
                    label={item?.label}
                  />
                </div>
              ) : (
                <div
                  className={`${
                    index === 0 && classes.iconContainerLeftRadius
                  } ${
                    index === multiValueLabel?.length - 1 &&
                    classes.iconContainerRightRadius
                  } ${classes.headerCntnrWthoutLabel} `}
                  key={index}
                >
                  <CustomTableHeader label={item?.label} index={index} />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
      {/* Header Container */}

      {/* Body Container */}
      <MultiplePropertyValue
        propertiesVal={multiValues}
        setpropertiesVal={setMultiValues}
      />
      {errorState?.multiple_values?.length && (
        <div className={classes.multipleValuesError}>
          <TypographyInline
            label={errorState?.multiple_values}
            color={BRAND.PRIMARY}
            fontWeight={fontWeight.REGULAR}
            size={"sm"}
          />
        </div>
      )}

      {/* Body Container */}
    </div>
  );

  // components object according to the type id selected in Field Type dropdwon.
  const TYPE_ID_COMPONENTS = {
    text_input: (
      <InputField
        size={"sm36"}
        placeholder={STRINGS.ENTER_INPUT_VALUE}
        onChange={(e) => setInputValue(e?.target?.value)}
        value={inputValue}
      />
    ),
    date_picker: (
      <CustomDatePicker
        size={"sm36"}
        value={inputValue || new Date()}
        onChange={(dateValue) => setInputValue(dateValue)}
      />
    ),
    number: (
      <InputField
        size={"sm36"}
        type={"number"}
        onChange={(e) => setInputValue(e?.target?.value)}
        value={inputValue}
        placeholder={STRINGS.NUMBER_PLACEHOLDER}
      />
    ),
    multi_text_input: (
      <TextArea
        rows={"5"}
        onChange={(e) => setInputValue(e?.target?.value)}
        value={inputValue}
      />
    ),
    rich_text_editor: (
      <div className={classes.htmlEditor}>
        <HtmlEditor
          isAddTagNotVisible
          isMeetingTagNotVisible
          editorHeight={"12vh"}
          previosData={inputValue || ""}
        />
      </div>
    ),
    single_select: (
      <AutoCompleteCustom
        dropdownId="preview_dropdown"
        items={replaceKeyInArrayOfObj(
          multiValues,
          "labelValue",
          "label"
        )?.filter((item) => item?.label)}
        size={"sm36"}
        idState={previewSelectIdState}
        setIdState={setPreviewSelectIdState}
        valueState={previewSelectValueState}
        setValueState={setPreviewSelectValueState}
        paperHeight={multiValues?.length < 10 ? "fit-content" : "30vh"}
      />
    ),
    multiple_select: (
      <AutoCompleteCustom
        dropdownId="preview_dropdown"
        items={replaceKeyInArrayOfObj(
          multiValues,
          "labelValue",
          "label"
        )?.filter((item) => item?.label)}
        size={"sm36"}
        idState={previewSelectIdState}
        setIdState={setPreviewSelectIdState}
        valueState={previewSelectValueState}
        setValueState={setPreviewSelectValueState}
        paperHeight={multiValues?.length < 10 ? "fit-content" : "30vh"}
        multiple
      />
    ),
    multiple_checkbox: (
      <FilterMultipleCheckbox
        dispatchAllowed={false}
        items={replaceKeyInArrayOfObj(
          multiValues,
          "labelValue",
          "label"
        )?.filter((item) => item?.label)}
      />
    ),
  };

  useEffect(() => {
    // setInputValue("");
    if (document.getElementById("editor")) {
      document.getElementById("editor").innerHTML = "";
    }
    // setMultiValues(initialMultipleValues);
  }, [typeId]);

  useEffect(() => {
    // debugger;
    if (Object.keys(data)?.length) {
      let object_type_id = objectTypeDropdownValues
        .filter((item) => item.label === data?.object_type[0])
        .map((item) => {
          return item.id;
        });

      let field_type_id = FIELD_TYPE_LABELS.filter(
        (item) => item.label === data?.field_type[0]
      ).map((item) => {
        return item.id;
      });

      // setting property modal to step 1.
      setSecondStep(false);
      // setting property modal to step 1.

      // stored object type dropown.
      setObjectTypeValueState(data?.object_type);
      setObjectTypeIdState(object_type_id);
      // stored object type dropown.

      // stored field type dropdown.
      setTypeValue(data?.field_type);
      setTypeId(field_type_id);
      // stored field type dropdown.

      // stored group name dropdown
      setGrpIdState([data?.grp_id]);
      setGrpValueState([data?.grp_name]);
      // stored group name dropdown

      // stored property name
      setPropertyLabel(data?.property_name);
      // stored property name

      // stored property visibility
      setInForm(data?.in_form);
      // stored property visibility

      if (
        field_type_id[0] === STRINGS.SINGLE_SELECT ||
        field_type_id[0] === STRINGS.MULTIPLE_SELECT ||
        field_type_id[0] === STRINGS.MULTIPLE_CHECKBOX
      ) {
        let newArr = data?.value?.map((obj, index) => {
          return {
            ...obj,
            labelOnchange: labelChangeHandler,
            internalOnchange: internalChangeHandler,
            onDeleteClick: deleteHandler,
            // inFormHandler: inFormHandler,
          };
        });
        // console.log("NEwARR___ ", newArr);
        setMultiValues([...newArr]);
      } else {
        setInputValue(data?.value);
      }
    }
  }, [data]);

  useEffect(() => {
    if (objectTypeIdState?.length) {
      dispatch(
        getGroupsListByObjectType({
          query: {
            object_type: objectTypeValueState,
          },
        })
      );
    }
  }, [objectTypeIdState]);

  return (
    <CustomModal
      open={open}
      close={modalCloseHandler}
      header={modalHeaderData}
      footer={{ left: modalFooterLeftData, right: modalFooterRightData }}
      width={"31.250vw"}
      remove_padding={secondStep ? true : false}
    >
      <form
        onSubmit={secondStep ? createPropertyHandler : stepValidation}
        className={classes.formContainer}
      >
        {/* Form Data */}
        {!secondStep ? (
          // First step asking to enter label name, field type and object type.
          <>
            {formDataStep1?.map((item, index) => {
              console.log({ item });
              return (
                <div key={index} className={classes.labelFieldContainer}>
                  <TypographyInline
                    color={GREY.QUATINARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={item?.label}
                    size={"sm"}
                    isRequired={item?.isRequired}
                  />
                  {item?.isDropdown ? (
                    <AutoCompleteCustom
                      size={"sm36"}
                      LeftIcon=""
                      items={item?.items}
                      valueState={item?.valueState}
                      setValueState={item?.setValueState}
                      idState={item?.idState}
                      setIdState={item?.setIdState}
                      papermenuclass={classes.papermenuclass}
                      variant={item?.variant}
                      errormessage={item?.errorMessage}
                      paperHeight={
                        item?.items?.length < 5 ? "fit-content" : "20vh"
                      }
                    />
                  ) : (
                    <InputField
                      size={"sm36"}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={item?.onChange}
                      variant={item?.variant}
                      errormessage={item?.errorMessage}
                    />
                  )}
                </div>
              );
            })}
          </>
        ) : (
          // Second step after entering label name, group type and object type.
          <>
            {formDataStep2?.map((item, index) => {
              return (
                <div key={index} className={classes.formContainerStep2}>
                  {/* Entered Label name display on second step. */}
                  <div className={classes.labelFieldContainerBottomBorder}>
                    <TypographyInline
                      color={GREY.QUATINARY}
                      fontWeight={fontWeight.REGULAR}
                      label={STRINGS.LABEL_NAME}
                      size={"sm"}
                    />
                    <TypographyInline
                      color={GREY.PRIMARY}
                      fontWeight={fontWeight.MEDIUM}
                      label={propertyLabel}
                      size={"sm"}
                    />
                  </div>

                  <div className={classes.labelFieldContainer}>
                    {" "}
                    <TypographyInline
                      color={GREY.QUATINARY}
                      fontWeight={fontWeight.MEDIUM}
                      label={item?.label}
                      size={"sm"}
                      isRequired={item?.isRequired}
                    />
                    {item?.isDropdown ? (
                      <AutoCompleteCustom
                        size={"sm36"}
                        LeftIcon=""
                        items={item?.items}
                        isLoading={item?.items?.length ? false : true}
                        valueState={item?.valueState}
                        setValueState={item?.setValueState}
                        idState={item?.idState}
                        setIdState={item?.setIdState}
                        onChange={item?.onChange}
                        papermenuclass={classes.papermenuclass}
                        variant={item?.variant}
                        errormessage={item?.errorMessage}
                        paperHeight={
                          item?.items?.length < 5 ? "fit-content" : "20vh"
                        }
                      />
                    ) : (
                      <InputField
                        size={"sm36"}
                        placeholder={item?.placeholder}
                        value={item?.value}
                        onChange={item?.onChange}
                        variant={item?.variant}
                        errormessage={item?.errorMessage}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </form>

      {/* Data according to field type */}
      {secondStep ? (
        typeValue?.length &&
        (typeId[0] === STRINGS.SINGLE_SELECT ||
          typeId[0] === STRINGS.MULTIPLE_SELECT ||
          typeId[0] === STRINGS.MULTIPLE_CHECKBOX) ? (
          <div className={classes.propertiesContainer}>
            <div className={classes.addLabelButtonPropLabel}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.PROPERTIES_INPUT_VALUES}
              />
              {(typeId[0] === STRINGS.SINGLE_SELECT ||
                typeId[0] === STRINGS.MULTIPLE_SELECT ||
                typeId[0] === STRINGS.MULTIPLE_CHECKBOX) && (
                <div>
                  <Button
                    LeftIcon={ICON_LABELS.ADD}
                    label={STRINGS.ADD_LABEL}
                    size={"sm36"}
                    variant={"text"}
                    onClick={addLabelRowHandler}
                  />
                </div>
              )}
            </div>

            {/* Component according to type id. */}
            {typeId[0] === STRINGS.SINGLE_SELECT ||
            typeId[0] === STRINGS.MULTIPLE_SELECT ||
            typeId[0] === STRINGS.MULTIPLE_CHECKBOX
              ? DROPDOWN_LABEL_VALUES_DESIGN
              : TYPE_ID_COMPONENTS[typeId[0]]}
          </div>
        ) : null
      ) : null}

      {/* Preview Section accroding to field type. */}
      <>
        {secondStep && typeId?.length ? (
          <div>
            <div className={classes.previewMainContainer}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.PREVIEW}
              />
              {/* Component according to type id. */}
              {TYPE_ID_COMPONENTS[typeId[0]]}
            </div>
            {/* Property Visibility Section */}
            <div className={classes.visiblity_container}>
              <TypographyInline
                color={GREY.QUATINARY}
                size={"sm"}
                label={STRINGS.PROPERTY_VISIBLITY}
                fontWeight={fontWeight.MEDIUM}
              />
              <Checkbox
                label={STRINGS.VISIBILITY_DESCRIPTION}
                checked={inForm}
                onChange={() => {
                  setInForm(!inForm);
                }}
              />
            </div>
          </div>
        ) : null}
      </>
    </CustomModal>
  );
};

export default CreatePropertyModal;
