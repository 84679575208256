import React, { useEffect } from "react";
import { Root, Listbox } from "../../../components/Autocomplete";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import { Styles } from "../Styles";
import { getTaskSearch } from "../../../redux/actions/taskActions";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { STRINGS } from "../../ProfileScreen/strings";


const CustomAutoCompleteSingle = ({
  endPoint,
  setCompanyNo=()=>{},
  setPhoneNo=()=>{},
  setCompany=()=>{},
  setContactId=()=>{},
  setJobtitle=()=>{}
}) => {
  const taskDetailsSelector = useSelector((state) => state.getTaskContactList);

  const classes = Styles();
  const navigate = useNavigate();
  const selector = useSelector((state) => state);
  const { error, loading } = selector.getTaskContactList;
  const dispatch = useDispatch();

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
  } = useAutocomplete({
    id: "customized-hook",

    options: taskDetailsSelector?.payload?.data || [],
    getOptionLabel: (option) => option?.["name"],
  });


  let inputValue = getInputProps()?.value;
  useEffect(() => {
    dispatch(getTaskSearch(endPoint, `?search=${inputValue}`, navigate));
  }, [inputValue, error, endPoint, value, navigate, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleClick = (temp) => {

setJobtitle(temp?.name)    
setCompany(temp?.company_name);
      setCompanyNo(temp?.company_phone_number);
      setPhoneNo(temp?.direct_phone_number);
      setContactId(temp?.contact_id)
     
  };

  useEffect(()=>{
    if (getInputProps()?.value?.length ===0) {
      setCompany("");
      setCompanyNo("");
      setPhoneNo("");
      setContactId("")
    }
  },[getInputProps()?.value])
  return (
    <Root>
      <div {...getRootProps()}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          className={classes.inputContainer}
        >
          <input
            className={classes.input}
            placeholder="Search"
            {...getInputProps()}
          />
        </Box>
      </div>

      {groupedOptions.length === 0 && loading ? (
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.LOADING}
        />
      ) : (
        <Listbox
          {...getListboxProps()}
          className={classes.listBox}
          style={{ position: "block !important" }}
        >
          {groupedOptions.map((option, index) => (
            <div
              key={index}
              onClick={() => handleClick(option)}
            >
              <li {...getOptionProps({ option, index })}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={option?.name}
                />
              </li>
            </div>
          ))}
        </Listbox>
      )}
    </Root>
  );
};

export default React.memo(CustomAutoCompleteSingle);
