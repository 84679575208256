import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { allFilters } from "../../redux/actions/filterActions";
import { styles } from "./styles";
import IconComponent from "../Icon/IconComponent";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import { BRAND, GREY } from "../../utils/constants/colors";
import TypographyInline from "../Typography/TypographyInline";
import { STRINGS } from "../strings";

const CompanyNameFilter = ({
  value,
  filter,
  type = "",
  filterKey,
  clearAction,
  defaultActionValue,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);
  const [mappedValues, setMappedValues] = useState([]);

  useEffect(() => {
    const parsedData = JSON.parse(localStorage.getItem(filterKey + type));
    setMappedValues(parsedData);
  }, [filterKey, type, value]);

  const handleRemoveFilter = () => {
    if (
      !isNaN(allFiltersSelector?.exist_fields?.indexOf("company_name")) &&
      allFiltersSelector?.exist_fields?.indexOf("company_name") !== -1
    ) {
      const newExistFields = allFiltersSelector?.exist_fields?.filter(
        (item) => item !== "company_name"
      );

      const query = { exist_fields: newExistFields };
      dispatch(allFilters(query));
      dispatch(clearAction(defaultActionValue));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...query,
        }).toString(),
      });
    } else if (
      !isNaN(allFiltersSelector?.not_exist_fields?.indexOf("company_name")) &&
      allFiltersSelector?.not_exist_fields?.indexOf("company_name") !== -1
    ) {
      const newExistFields = allFiltersSelector?.not_exist_fields?.filter(
        (item) => item !== "company_name"
      );
      const query = { not_exist_fields: newExistFields };
      dispatch(allFilters(query));
      dispatch(clearAction(defaultActionValue));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...query,
        }).toString(),
      });
    } else {
      const query = JSON.parse(JSON.stringify(allFiltersSelector));
      delete query?.[filterKey + type];
      localStorage.removeItem(filterKey + type);
      dispatch(clearAction(defaultActionValue));

      dispatch(allFilters(query, true));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...query,
        }).toString(),
      });
    }
  };


  return (
    <Box sx={styles.selectedFilterBox}>
      <div style={styles.cancelIcon} onClick={handleRemoveFilter}>
        <IconComponent
          iconLabel={ICON_LABELS.CANCEL}
          color={GREY.TERTIARY}
          fontSize={fontSize.MD}
        />
      </div>
      <div style={styles.filterValues}>
        <TypographyInline
          label={filter}
          size={"sm"}
          fontWeight={fontWeight.SEMIBOLD}
          color={GREY.PRIMARY}
          isNoWrap={true}
        />
        <TypographyInline
          label={
            type === "_not" ? STRINGS.IS_NOT_EQUAL_TO : STRINGS.IS_EQUAL_TO
          }
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
          isNoWrap={true}
        />
        {mappedValues?.length ? mappedValues?.map((item, index) => {
          return (
            <TypographyInline
              label={`${item?.name}${value?.length > index + 1 ? ", " : "."}`}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={BRAND.PRIMARY}
              isNoWrap={true}
            />
          );
        }) : value?.map((item, index) => {
          return (
            <TypographyInline
              label={`${item?.name}${value?.length > index + 1 ? ", " : "."}`}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={BRAND.PRIMARY}
              isNoWrap={true}
            />
          );
        })}
      </div>
    </Box>
  );
};

export default CompanyNameFilter;
