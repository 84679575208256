import { Box } from "@mui/system";
import React from "react";
import { SEARCH_PROSPECTS_IMAGE } from "../../../utils/constants/assets";
import {
  PEOPLE_CONTENT_SEARCH_PARA1,
  PEOPLE_CONTENT_SEARCH_PARA2,
  PEOPLE_CONTENT_SEARCH_PARA3,
  PEOPLE_CONTENT_SEARCH_PARA4,
} from "../../../utils/constants/static";
import Image from "../../../components/Image/Image";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import classes from "./LeadsComponent.module.css";

const TablePlaceholder = ({
  title = STRINGS.PEOPLE,
  setAddAnotherFilter = () => {},
}) => {
  return (
    <Box className={classes.containSearchImage}>
      <div className={classes.imageContainer}>
        <Image
          src={SEARCH_PROSPECTS_IMAGE}
          alt=""
          className={classes.searchImage}
        />
      </div>
      <div className={classes.contentHeading}>
        <TypographyInline
          label={`${STRINGS.SEARCH} ${title}`}
          size={"xl"}
          fontWeight={fontWeight.SEMIBOLD}
          color={GREY.PRIMARY}
        />
        <div className={classes.contentPara}>
          <TypographyInline
            label={PEOPLE_CONTENT_SEARCH_PARA1}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
          <TypographyInline
            label={PEOPLE_CONTENT_SEARCH_PARA2}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
          <TypographyInline
            label={PEOPLE_CONTENT_SEARCH_PARA3}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
          <TypographyInline
            label={PEOPLE_CONTENT_SEARCH_PARA4}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
        </div>
      </div>
      <div>
        <Button
          LeftIcon={ICON_LABELS.FILTER_ALT}
          RightIcon={ICON_LABELS.KEYBOARD_ARROW_RIGHT}
          label={STRINGS.ADD_FILTER}
          size={"md40"}
          variant={"primary"}
          onClick={() => {
            setAddAnotherFilter(true);
          }}
        />
      </div>
    </Box>
  );
};

export default TablePlaceholder;
