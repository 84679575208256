import React, { useState } from "react";
import { Box } from "@mui/system";
import { HtmlEditor } from "../../../../components";
import { EMITTER_ERROR, EMITTER_SUCCESS, STEPS_INTERVAL_FIELDS } from "../../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../../../../Apis";
import { sequenceEndPoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { useDispatch, useSelector } from "react-redux";
import { getAllSteps } from "../../../../redux/actions/stepsActions";
import { patchRequest } from "../../../../Apis/apiWrapper";
import { addTagStyleInHtml, compressString, decompressString, removeTagStyleFromHtml } from "../../../../utils/helpers";
import { STRINGS } from "../strings";
import { Button } from "../../../../components/Buttons";
import CustomModal from "../../../../components/Modals/CustomModal";
import { GREY, PINK } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import InputField from "../../../../components/InputFields/InputField";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import classes from "../markComponents.module.css";
import { SEQUENCE_PATH } from "../../../../utils/constants/routes";
import { MESSAGE_REQUIRED_FIELD } from "../../../../utils/constants/messages";

const CreateTodo = ({
  data,
  editType,
  stepNumber,
  stepLength,
  myId,
  number,
  open,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const sequenceId = params?.id;
  const fold = params.fold;
  const [errMsg, setErrMsg] = useState("");
  const [title, setTitle] = useState(data?.step_data?.title || "");
  const [currentFocus, setCurrentFocus] = useState("");
  const stepsSelector = useSelector((state) => state?.getAllSteps);
  const { payload } = stepsSelector;
  const [intervals, setIntervals] = useState([payload?.data[0]?.total_steps === 1 && editType === STRINGS.SMALL_CLONE ? "1"
    : (parseInt(data?.interval) > 0 && data.step_number !== 1) ? data?.interval : "1"]);

  const [performing, setPerforming] = useState(false);
  const navigate = useNavigate();


  const handleCreateTodo = async () => {
    let customEditorValue = removeTagStyleFromHtml(`<div>${document
      .getElementById(STRINGS.SMALL_EDITOR)
      ?.innerHTML?.toString()}</div>`);
    customEditorValue = compressString(encodeURIComponent(customEditorValue))
    // let newCustomEditorValue = customEditorValue;
    if (title?.trim()?.length === 0) {
      setErrMsg(MESSAGE_REQUIRED_FIELD);
      return;
    }
    if (editType === STRINGS.SMALL_EDIT) {

      try {
        const payload = {
          step_id: data?._id,
          title,
          notes: customEditorValue,
          interval: intervals?.[0],
          step_number: stepNumber,
        };
        setPerforming(true);
        const response = await patchRequest(
          navigate,
          sequenceEndPoints.updateTodoStep,
          payload
        );
        dispatch(
          getAllSteps(
            sequenceEndPoints.validateSequenceSteps + sequenceId,
            navigate
          )
        );
        setPerforming(false);
        setOpen(false)
        EventEmitter.emit(
          EMITTER_SUCCESS,
          response?.data?.data?.message || ""
        );
      } catch (error) {
        setPerforming(false);
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    } else {
      if (editType === STRINGS.SMALL_CLONE) {
        try {
          const payload = {
            sequence_id: data?.sequence_id,
            title,
            notes: customEditorValue,
            interval: intervals?.[0],
            step_number: stepLength + 1,
            is_clone: true
          };
          setPerforming(true);
          const response = await postRequest(
            navigate,
            sequenceEndPoints.createTodoStep,
            payload
          );
          dispatch(
            getAllSteps(
              sequenceEndPoints.validateSequenceSteps + sequenceId,
              navigate
            )
          );
          setPerforming(false);
          setOpen(false)
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
        } catch (error) {
          setPerforming(false);
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        }
      } else {

        try {
          const payload = {
            sequence_id: myId ? myId : params?.id,
            title,
            notes: customEditorValue,
            interval: stepNumber > 1 ? intervals?.[0] : "0",
            step_number: stepNumber,
          };
          setPerforming(true);
          const response = await postRequest(
            navigate,
            sequenceEndPoints.createTodoStep,
            payload
          );
          dispatch(
            getAllSteps(
              sequenceEndPoints.validateSequenceSteps + sequenceId,
              navigate
            )
          );
          if (fold) {
            navigate(`${SEQUENCE_PATH}/${fold}/${STRINGS.STEPS}/${myId}`)
          }
          else if (myId) {
            navigate(STRINGS.USER_SEQ_STEPS + myId);
          }
          setPerforming(false);
          setOpen(false)
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
        } catch (error) {
          setPerforming(false);
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        }
      }
    }
  };

  // edit send data in modal
  const getEditedData = (data) => {
    // (data?.step_data?.body ? decodeURI(data?.step_data?.body) : "")
    if (data?.is_compressed) {
      return addTagStyleInHtml(decodeURIComponent(decompressString(data?.notes))) || "";
    } else {
      return addTagStyleInHtml(data?.notes) || "";
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: STRINGS.TODO,
    },
    steps: {
      id: STRINGS.SMALL_STEP,
      label: number ? `${STRINGS.STEP} ${number}` : `${STRINGS.STEP} ${stepNumber}`,
      bgcolor: PINK.W_500
    },
  };
  const modalBodyData = (
    <div>
      <Box>
        {(editType === STRINGS.SMALL_EDIT && (stepNumber >= 2 || parseInt(data?.step_number) >= 2)) || editType === STRINGS.SMALL_CLONE || stepNumber >= 2 ? (
          <>
            <Box className={`${classes.mTop} ${classes.linePadding}`}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.INTERVALS}
              />

              <Box className={classes.mTop}>
                <AutoCompleteCustom
                  items={STEPS_INTERVAL_FIELDS}
                  LeftIcon=""
                  valueState={intervals}
                  setValueState={setIntervals}
                  size={"sm36"}
                />
              </Box>
            </Box>


          </>
        ) : (
          ""
        )}
        <Box className={classes.mTop}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.TASK_TITLE}
          />

          <Box className={classes.mTop}>
            <InputField
              value={title}
              size={"sm36"}
              variant={errMsg ? "error" : "default"}
              errormessage={errMsg}
              placeholder={STRINGS.TASK_TTL}
              onClick={() => setCurrentFocus("subject")}
              onChange={(e) => setTitle(e?.target?.value)}
              val={STRINGS.CALL_VAL}
            />
          </Box>
        </Box>
        <Box className={classes.mTop}>
          <Box className={classes.flexJustifyBetween}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.NOTES}
            />
          </Box>
        </Box>
        <Box className={classes.containEditor}>
          <HtmlEditor
            setCurrentFocus={setCurrentFocus}
            currentFocus={currentFocus}
            previosData={Object.keys(data?.step_data || {})?.length ? getEditedData(data?.step_data) : ""}
            isAITagNotVisible
            isAddTagNotVisible
          />
        </Box>
      </Box>
    </div>
  );
  const modalFooterLeftData = (
    <div>
      <Box className={classes.flexJustifyBetween}>
        <Box className={classes.btnFooter}>
          <Button
            label={
              editType === STRINGS.SMALL_EDIT ? STRINGS.UPDATE : (editType === STRINGS.SMALL_CLONE) ? STRINGS.CLONE : STRINGS.SAVE
            }
            size={"sm36"}
            variant={"primary"}
            onClick={() => handleCreateTodo()}
            isLoading={performing}
          />
          <Button
            label={STRINGS.CANCEL}
            variant={"ghost"}
            size={"sm36"}
            onClick={() => setOpen(false)}
          />


        </Box>
      </Box>
    </div>
  );

  return (
    <CustomModal
      open={open}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
      }}
      width={"840px"}
    />



  );
};

export default CreateTodo;
