import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { STATS_KEY_VALUES } from "../../../../utils/constants";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Styles } from "../../Styles";

export const D3PieChart = (props) => {
  const { index, dataSet, height, width } = props;
  const classes = Styles();
  const widgetCoordinatesSelector = useSelector(
    (state) => state.getWidgetCoordinates
  );
  const d3Chart = useRef(null);

  function pieChart(width, height) {
    var margin
    try {
      if (props.activeTooltip === "#widgetDetailsTooltip") {
        margin = { top: 35, right: 70, bottom: 70, left: 70 };
      } else {
        margin = { top: 70, right: 70, bottom: 70, left: 70 };
      }

      const w = width - margin.left - margin.right;
      const h = height - margin.top - margin.bottom;
      var radius = 0;
      if (w > h) {
        radius = h / 2;
      } else {
        radius = w / 2;
      }

      var valueArr = [];
      var colorArr = [];
      var idArr = [];

      dataSet.forEach((data, i) => {
        valueArr.push(data.value);
        colorArr.push(data.color);
        idArr.push(data.id);
      });

      const svg = d3
        .select(d3Chart.current)
        .attr("width", w / 2)
        .attr("height", h / 2)
        .style("overflow", "visible")
        .style("margin-top", margin.top + 20)
        .style("margin-left", margin.left)
        .style("margin-right", margin.right)
        .style("margin-bottom", margin.bottom + 20)
        .attr(
          "transform",
          "translate(" +
          (margin.left + w / 6) +
          "," +
          (margin.top + h / 6) +
          ")"
        );

      const formatData = d3.pie().value((d) => d.value)(dataSet);
      const arcGenerator = d3
        .arc()
        .innerRadius(radius / 2)
        .outerRadius(radius);
      const color = d3.scaleOrdinal().domain(idArr).range(colorArr);

      var tooltip = d3
        .select(props.activeTooltip)
        .append("div")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("width", "142px")
        .style("height", "auto")
        .style("border-radius", "6px")
        .style("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.08)")
        .style("border", "1px solid #D3DAE3")
        .style("background-color", "white")
        .style("visibility", "hidden");

      svg
        .selectAll()
        .data(formatData)
        .join("path")
        .attr("d", arcGenerator)
        .attr("fill", (d) => color(d))
        .on("mouseover", function () {
          return tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (event, d) {
          tooltip.html(
            `<div class=${classes.widgetToolTip} >` +
            `<div class=${classes.widgetToolTipTop} >` +
            `<div class=${classes.widgetToolTipCircle
            } style="background-color:${color(d)}">` +
            "</div>" +
            STATS_KEY_VALUES[d.data.id] +
            "</div>" +
            `<div class=${classes.widgetToolTipBottom} >` +
            "Total: " +
            d.data.value +
            "</div>" +
            "</div>"
          );

          var x, y;
          let maxHeight = (document.documentElement.clientHeight / 100) * 90;
          let maxWidth = (document.documentElement.clientWidth / 100) * 90;
          if (event.pageY > maxHeight) {
            y = 80;
          } else {
            y = 10;
          }

          if (event.pageX > maxWidth) {
            x = 150;
          } else {
            x = -10;
          }



          tooltip
            .style("top", event.pageY - y + "px")
            .style("left", event.pageX - x + "px");
        })
        .on("mouseout", function () {
          return tooltip.style("visibility", "hidden");
        });

    
    } catch (error) {
    }
  }

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;

    const resizeHeight = widgetCoordinatesSelector?.payload?.h;
    const resizeWidth = widgetCoordinatesSelector?.payload?.w;
    const resizeIndex = widgetCoordinatesSelector?.payload?.i;

    if (
      (resizeWidth < documentWidth || resizeHeight < documentHeight) &&
      resizeIndex == index
    ) {
      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        d3.select(d3Chart.current).selectAll("*").remove();
        pieChart(resizeWidth, resizeHeight * 0.7);
      }, 50);
    }
  }, [widgetCoordinatesSelector]);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;
    if (width < documentWidth && height < documentHeight) {
      d3.select(d3Chart.current).selectAll("*").remove();
      pieChart(width, height);
    }
  }, [dataSet]);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;
    if (width < documentWidth && height < documentHeight) {
      d3.select(d3Chart.current).selectAll("*").remove();
      pieChart(width, height);
    }
    return () => {
      d3.select(props.activeTooltip).selectAll("*").remove();
    };
  }, []);

  return <Box>{dataSet.length > 0 ? <svg ref={d3Chart}></svg> : null}</Box>;
};
