import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { SHADOW } from "../../utils/constants/UI";
import { BORDER } from "../../utils/constants/colors";

const CustomStyledMenu = styled(
  ({
    marginTop,
    marginLeft,
    isRightAlign = false,
    arrow = false,
    noMargin = false,
    isPeople = false,
    isLeftStart = false,
    ...props
  }) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isRightAlign ? "left" : "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: isRightAlign ? "left" : "right",
      }}
      onKeyDown={(e) => e.stopPropagation()}
      PaperProps={{
        sx: {
          width: props?.width,
          boxShadow:
            `${props?.shadow} !important` || `${SHADOW.tags} !important`,
          borderWidth: arrow === true ? "1px" : "",
          borderStyle: arrow === true ? "solid" : "",
          borderColor: arrow === true ? BORDER.DEFAULT : "",
          marginTop:
            marginTop ||
            (isPeople && "32px !important") ||
            (noMargin && "0px !important"),
          marginLeft:
            marginLeft ||
            (isPeople && "60px !important") ||
            (noMargin && "0px !important") ||
            (isLeftStart && "-9rem !important"),
          overflow: arrow === true ? "visible" : "inherit",
          "&:before": arrow === true && {
            content: '""',
            display: "block",
            position: "absolute",
            top: -1,
            left: 14,
            width: 16,
            height: 16,
            bgcolor: "#ffffff",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            borderWidth: "1px 0px 0px 1px",
            borderStyle: "solid",
            borderColor: BORDER.DEFAULT,
          },
        },
      }}
      {...props}
    />
  )
)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginLeft: "5rem",
    boxShadow: SHADOW.tags,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],

    "& .MuiMenu-list": {
      padding: "0px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default CustomStyledMenu;

// ------------- NOTE ----------------
// Props description :-
// 1. width: used for menu width and initially it is set to 18vw.
// 2. shadow: used for boxShadow of menu initially it is set to SHADOW.lg
// 3. arrow: used to display arrow on top of menu.
