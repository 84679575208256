import React, { useState } from "react";
import { STRINGS } from "../strings";
import { Button } from "../../../../components/Buttons";
import CustomModal from "../../../../components/Modals/CustomModal";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import classes from "../markComponents.module.css";
import { useNavigate } from "react-router-dom";
import { templateEndpoints } from "../../../../utils/constants/httpConstants";
import { postRequest } from "../../../../Apis";
import { useDispatch } from "react-redux";
import { checkLogin } from "../../../../redux/actions/authActions";
const GenerateModal = ({
  open,
  setOpen,
  data,
  challenges,
  offerings,
  solutions,
  idVal,
  websiteUrl,
  context,
  valMeet,
  setMessage,
  setSubject,
  setIsLoading,

}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoad, setIsLoad] = useState(false);
  const confirmData = async () => {
    let payload;
    if (data.idVal === 1) {
      payload = {
        prompt: `Act as a marketing assistant. Write a compelling and effective Email Template about ${data.offerings}.Also Include the following :
        * [Challenges faced by the customer] ${data.challenges}
        * [Solutions we can provide regarding the challenges] ${data.solutions}
        [Call to Action] -
        *Website URL for the reference so that customers can lookup for reference regarding the solutions provided <a href= http://${data.websiteUrl}> http://${data.websiteUrl} </a>
        [Context to Add] ${data.context}
        *Provide additional context to include for the solution`,
      };
    }
    else if (data.idVal === 2) {
    payload = {
      prompt: `Act as a marketing assistant. Write a compelling and effective Email Template about ${data.offerings}.Also Include the following :
      * [Challenges faced by the customer] ${data.challenges}
      * [Solutions we can provide regarding the challenges] ${data.solutions}
      [Call to Action] -
      *Book a Meeting with the customer <a href= http://www.${data.meeting} >http://www.${data.meeting}</a>
      [Context to Add] ${data.context}
      *Provide additional context to include for the solution`,
    }; 
  }
    else {
      payload = {
        prompt: `Act as a marketing assistant. Write a compelling and effective Email Template about ${data.offerings}.Also Include the following :
        * [Challenges faced by the customer] ${data.challenges}
        * [Solutions we can provide regarding the challenges] ${data.solutions}
        [Context to Add] ${data.context}
        *Provide additional context to include for the solution`,
      };
    }
    try {
    setSubject("")
    setIsLoad(true)
    setIsLoading(true)
      const response = await postRequest(
        navigate,
        templateEndpoints.chatGpt,
        payload
      );
      // Split the HTML string into lines
      var lines = response?.data?.data?.choices[0]?.text.split("\n");
      // Extract the subject (assuming it's the first non-empty line)
      var subject1 = lines.find((line) => line.trim() !== "");
      // Extract the message (assuming it's everything after the subject)
      var message1 = lines.slice(lines.indexOf(subject1) + 1).join("\n");
      const colonIndex = subject1.indexOf(":");

      if (colonIndex !== -1) {
        const newText = subject1.substring(colonIndex + 1).trim();
        setSubject(newText);
      }
      let arr = message1.split("\n");
      let updateA = arr.slice(0, -3);
      let finalData = updateA?.map((itm) => {
        return itm + "\n";
      });
      setMessage(finalData.join(" ").slice(1));
      if(response?.status === 200){
        dispatch(checkLogin(navigate))
       }
      
    } catch (e) {
    } finally {
        setIsLoad(false)
        setIsLoading(false)
    }

    setOpen(false);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: STRINGS.REGEN_RESP,
    },
  };

  const modalBodyData = (
    <div>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.REGULAR}
        label={STRINGS.CREDIT10}
      />
    </div>
  );

  const modalFooterLeftData = <div></div>;

  const modalFooterRightData = (
    <div className={classes.displayFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.REGENERATE}
        size={"sm36"}
        variant={"primary"}
        isLoading={isLoad}
        onClick={confirmData}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      variant={"error"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default GenerateModal;
