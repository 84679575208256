import {
  CONTACT_LIST_CREATE_FAIL,
  CONTACT_LIST_CREATE_REQUEST,
  CONTACT_LIST_CREATE_RESET,
  CONTACT_LIST_CREATE_SUCCESS,
  GET_CONTACT_LIST_FAIL,
  GET_CONTACT_LIST_REQUEST,
  GET_CONTACT_LIST_SUCCESS,
} from "../constants/listConstants";

export const createContactListReducer = (state = {}, action) => {

  switch (action.type) {
    case CONTACT_LIST_CREATE_REQUEST:
      return { loading: true };

    case CONTACT_LIST_CREATE_SUCCESS:
      return { loading: false, payload: action.payload , error: null };

    case CONTACT_LIST_CREATE_FAIL:
      return { loading: false, error: action.payload, payload: null };

      case CONTACT_LIST_CREATE_RESET:
      return {  };

    default:
      return state;
  }
};

export const getContactListReducer = (state = {}, action) => {

  switch (action.type) {
    case GET_CONTACT_LIST_REQUEST:
      return { loading: true };

    case GET_CONTACT_LIST_SUCCESS:
      return { loading: false, payload: action.payload , error: null };

    case GET_CONTACT_LIST_FAIL:
      return { loading: false, error: action.payload, payload: null };

      case CONTACT_LIST_CREATE_RESET:
      return {  };

    default:
      return state;
  }
};
