import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailLogList from "./components/EmailLogList";
import ListingTaskCard from "./components/ListingTaskCard";
import { STRINGS } from "../strings";
import classes from "./Deals.module.css";
import { TIMELINE_CRM } from "../../../utils/constants/assets";
import ContactDetailEmptyScreen from "../Contacts/components/ContactDetailEmptyScreen";
import SkeletonLoaderCRMScreens from "../Contacts/components/SkeletonLoaderCRMScreens";
import EmailSentList from "./components/EmailSentList";
import ListingNoteCard from "./components/ListingNoteCard";
import ListingMeetingCard from "./components/ListingMeetingCard";
import { decompressString } from "../../../utils/helpers";

const ContactDetailTimelineScreen = ({ dealId, dealDetails, getTimelineLogList = () => { }, dataList = [], screenLoading = false, totalTimeline = 0 }) => {
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);

  const type_id_components = (prop, index) => {
    switch (prop?.activityType) {
      case "log":
        return (
          <EmailLogList
            dealDetails={dealDetails}
            logData={prop}
            showOperation={false}
          />
        );
      case "mail":
        return (
          <EmailSentList
            dealDetails={dealDetails}
            emailData={prop}
            showOperation={false}
          />
        );
      case "call":
        return (
          <ListingTaskCard
            dealDetails={dealDetails}
            logData={prop?.is_compressed ? { ...prop, notes: decodeURIComponent(decompressString(prop.notes)) } : prop}
            deal_id={dealId}
            type={"call"}
            showOperation={false}
          />
        );
      case "todo":
        return (
          <ListingTaskCard
            dealDetails={dealDetails}
            logData={prop?.is_compressed ? { ...prop, notes: decodeURIComponent(decompressString(prop.notes)) } : prop}

            deal_id={dealId}
            type={"todo"}
            showOperation={false}
          />
        );
      case "notes":
        return (
          <ListingNoteCard
            dealDetails={dealDetails}
            logData={prop?.is_compressed ? { ...prop, notes_body: decodeURIComponent(decompressString(prop.notes_body)) } : prop}
            deal_id={dealId}
            showOperation={false}
          />
        );
      case "meetings":
        return (
          <ListingMeetingCard
            dealDetails={dealDetails}
            logData={prop?.is_compressed ? { ...prop, event_description: decodeURIComponent(decompressString(prop.event_description)) } : prop}

            dealId={dealId}
            showOperation={false}
          />
        );

      default:
        return;
    }
  };

  const onScrollApiHandler = () => {
    const scrollableDiv =
      document?.getElementsByClassName("allTimelineList")[0];
    const isAtBottom =
      scrollableDiv?.scrollTop >=
      scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;
    if (isAtBottom && totalTimeline > dataList?.length) {
      setPageCount(pageCount + 1);
      getTimelineLogList(true, pageCount + 1);
    }
  };

  useEffect(() => {
    if (Object.keys(dealDetails)?.length) {
      getTimelineLogList();
    }
  }, [dealDetails, navigate]);

  return (
    <div
      className={`${classes.taskScreenMainContainer} allTimelineList`}
      id="comments_body"
      onScroll={onScrollApiHandler}
    >
      {screenLoading ? (
        <SkeletonLoaderCRMScreens accordionLengths={5} buttonsLength={0} />
      ) : (
        <>
          {/* Checking the length of the timeline list */}
          {dataList?.length ? (
            <div className={classes.taskListContainer}>
              {dataList?.map((dealData, index) => {
                return (
                  <div key={`log${index}`}>
                    <div> {type_id_components(dealData, index)}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classes.TaskHead}>
              <ContactDetailEmptyScreen
                src={TIMELINE_CRM}
                label={STRINGS.TIMELINE_NOTE}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContactDetailTimelineScreen;
