import React from "react";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { filterSelectedIndexes, getUniqueListBy } from "../../utils/helpers";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  allFilters,
  filterHeaderSearchSeniority,
} from "../../redux/actions/filterActions";
import Checkbox from "../Buttons/Checkbox/Checkbox";

const FilterTypeSeniority = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const seniorityHeaderSelector = useSelector(
    (state) => state?.filterHeaderSeniority?.payload
  );
  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);

  const handleChange = (item) => {
    item.isChecked = !item.isChecked;

    const result = getUniqueListBy([...seniorityHeaderSelector, item], "type");
    dispatch(filterHeaderSearchSeniority(result));
    const seniority = filterSelectedIndexes(result, "type");
    const payload = [...new Set(seniority)];
    const queryPayload = { seniority: payload };
    dispatch(allFilters(queryPayload));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        seniority: payload,
      }).toString(),
    });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterContainer}>
        {seniorityHeaderSelector?.map((item) => {
          return (
            <Box key={item.type} sx={styles.flex}>
              <Checkbox
                onClick={() => handleChange(item)}
                checked={item.isChecked}
                label={item?.label}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default FilterTypeSeniority;
