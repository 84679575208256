import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkLogin } from "../../redux/actions/authActions";
import { CROSS_ICON, CSV } from "../../utils/constants/assets";
import SuspenseImage from "../Image/SuspenseImage";
import { styles } from "./styles";

const ProgressEmitter = ({
  name,
  uploadPercentage,
  refreshRequired = false,
  setOpen,
  open,
}) => {
  const classes = styles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (refreshRequired) {
      const interval = setInterval(async () => {
        // dispatch( checkLogin() )
      }, 1000 * 60 * 5);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, refreshRequired]);

  return (
    <Box className={classes.modal1}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          columnGap: "5px",
          padding: "10px",
          alignItems: "center",
          width: "603px",
          // height: "50px",
          border: "1px solid #4CAF50",
          background: "#E8F5E9",
          borderRadius: "12px",
          boxShadow:
            "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)",
        }}
      >
        {" "}
        <SuspenseImage src={CSV} />
        <p>
          {name} file uploading ({`${uploadPercentage}`}%){" "}
        </p>
      </Box>
    </Box>
  );
};

export default ProgressEmitter;
