import React, { useState } from "react";
import { Styles } from "../Styles";
import { STRINGS } from "../strings";
import CustomDialog from "../../../components/Dialog/CustomDialog";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { REPORT_LIST } from "../../../utils/constants";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const AddWidget = (props) => {
  const classes = Styles();
  const [list,setList] = useState(REPORT_LIST)

  const dialogHeader = {
    heading: {
      id: "add_widgets",
      label: STRINGS.ADD_WIDGETS,
    },
  };

  return (
    <>
      <CustomDialog
        open={props.in}
        close={props?.close}
        header={dialogHeader}
        width={"28.500vw"}
      >
        <div className={classes.widget}>
          <div className={classes.addWidgetBody}>
            <div>
              <div className={classes.addWidgetBodyContainer}>
                <TypographyInline
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={STRINGS.SELECT_REPORT}
                  size={"sm"}
                />
                <AutoCompleteCustom
                  size={"sm36"}
                  dropdownId={"report-dropdown"}
                  items={list}
                  paperHeight={"fit-content"}
                  valueState={props?.report}
                  setValueState={props?.setReport}
                  idState={props?.report}
                  setIdState={props?.setReport}
                />
              </div>
              <div className={classes.selectWidgetLabel}>
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={STRINGS.SELECT_WIDGET}
                  size={"sm"}
                />
              </div>
            </div>
            {props.children}
          </div>
        </div>
      </CustomDialog>
    </>
  );
};
