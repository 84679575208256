export const STRINGS = {
    DAYS: "Days",
    START_TIME: "Start Time",
    END_TIME: "End Time",
    START_TYPE: "startType",
    END_TYPE: "endType",
    RESET: "Reset",
    SAVE: "Save",
    


};