import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  campaignEndpoints,
  listEndPoints,
  sequenceEndPoints,
  templateEndpoints,
} from "../../../../utils/constants/httpConstants";
import { getTempFolderList } from "../../../../redux/actions/templateFolderActions";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import * as qs from "qs";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { deleteRequest, postRequest } from "../../../../Apis/apiWrapper";
import { getTemplateList } from "../../../../redux/actions/templateListActions";
import { getContactListFolder } from "../../../../redux/actions/contactListFolderActions";
import { getErrorMessage } from "../../../../utils/helpers";
import { getSequenceList } from "../../../../redux/actions/listTableActions";
import { getCampaignFolderList } from "../../../../redux/actions/campaignFolderActions";
import { campaignsListFetch } from "../../../../redux/actions/campaignAction";
import { getContactListData } from "../../../../redux/actions/contactListactions";
import { getFolderList } from "../../../../redux/actions/listFolderActions";
import { STRINGS } from "../strings";
import { Button } from "../../../../components/Buttons";
import CustomModal from "../../../../components/Modals/CustomModal";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import { paginationDataAction } from "../../../../redux/actions/customTableActions";
import { DEFAULT_ERROR_MESSAGE, MESSAGE_FOLDERS_DELETE, MESSAGE_FOLDER_DELETE } from "../../../../utils/constants/messages";
import classes from "../markComponents.module.css";
const BulkDeleteModal = ({
  open,
  setOpen,
  IDs,
  type,
  val,
  setSelectedIdsInParent,
  itemType
}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const [loading, setLoading] = useState(false);

  const deleteRecord = async () => {

    if (type === STRINGS.DEL) {
      try {
        setLoading(true);
        const payload = {
          list_ids: IDs,
          type: itemType || STRINGS.SMALL_FILE,
        };
        const response = await deleteRequest(
          listEndPoints.bulkDeleteList,
          payload,
          navigate
        );
        dispatch(
          getContactListData({
            name: "",
            parent: queryObject.folder_id,
            navigate,
          })
        );
        dispatch(paginationDataAction({checkedRow:[]}));
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        setOpen(!open);
        setLoading(false);
      } catch (err) {
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
        );
      } finally {
        setOpen(!open);
        setLoading(false);
      }
    } else if (type === STRINGS.FOLDEL) {
      try {
        setLoading(true);
        const payload = {
          record_ids: IDs,
          type: itemType || STRINGS.SMALL_FOLDER,
        };
        const response = await deleteRequest(
          templateEndpoints.deleteFoldTemp,
          payload,
          navigate
        );
        dispatch(
          getTempFolderList({ name: "", type:STRINGS.SMALL_FOLDER, limit: 100, navigate })
        );
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        setOpen(!open);
      } catch (err) {
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
        );
      } finally {
        setOpen(false);
        setLoading(false);
      }
    } else if (type === STRINGS.CMFOL) {
      try {
        const payload = {
          record_ids: IDs,
        };
        setLoading(true);
        const response = await postRequest(
          navigate,
          campaignEndpoints.deleteFolder,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, MESSAGE_FOLDER_DELETE);
        dispatch(
          getCampaignFolderList(
            { page: 0, size: 100, type: STRINGS.SMALL_FOLDER },
            navigate
          )
        );
        setOpen(!open);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        EventEmitter.emit(EMITTER_ERROR, error?.message);
      }finally{
        setOpen(false);
      }
    } else if (type === STRINGS.SQFOL) {
      try {
        const payload = {
          record_ids: IDs,
        };
        setLoading(true);
        const response = await postRequest(
          navigate,
          sequenceEndPoints.deleteSequece,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, MESSAGE_FOLDERS_DELETE);
        dispatch(
          getFolderList(
            { type:STRINGS.SMALL_FOLDER, size: 100 },
            sequenceEndPoints.listingSequence,
            navigate
          )
        );
       
        setOpen(!open);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      } finally {
        setOpen(false);
        setLoading(false);
      }
    } else if (type === STRINGS.BULK_DEL_TYPE) {
      try {
        setLoading(true);
        const payload = {
          record_ids: IDs,
          type: STRINGS.SMALL_FOLDER,
        };
        const response = await deleteRequest(
          listEndPoints.deleteFolderList,
          payload,
          navigate
        );
        dispatch(
          getContactListFolder({
            name: "",
            type: STRINGS.SMALL_FOLDER,
            limit: 100,
            navigate,
          })
        );
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        dispatch(paginationDataAction({checkedRow:[] ,query:{}}));
        setOpen(!open);
        setLoading(false);
      } catch (err) {
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
        );
      } finally {
        setLoading(false);
        setOpen(false)
      }
    } else if (type === STRINGS.DLSQ) {
      try {
        setLoading(true);
        const payload = {
          record_ids: IDs,
        };
        const response = await postRequest(
          navigate,
          sequenceEndPoints.deleteSequece,
          payload
        );
        dispatch(
          getSequenceList(
            { type: STRINGS.FILE, folder_id: queryObject?.folder_id },
            navigate
          )
        );
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        setOpen(!open);
        setLoading(false);
      } catch (err) {
        EventEmitter.emit(EMITTER_ERROR, DEFAULT_ERROR_MESSAGE);
      } finally {
        setOpen(false);
        setLoading(false);
      }
    } else if (type === STRINGS.CMBULKDEL) {
      try {
        setLoading(true);
        const payload = {
          record_ids: IDs,
        };
        const response = await postRequest(
          navigate,
          campaignEndpoints.campaignBulkDelete,
          payload
        );
        dispatch(campaignsListFetch({ type: STRINGS.SMALL_FILE }, navigate, true));
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        setOpen(!open);
        setLoading(false);
      } catch (err) {
        EventEmitter.emit(EMITTER_ERROR, err?.data?.message || DEFAULT_ERROR_MESSAGE);
      } finally {
        setOpen(false);
        setLoading(false);
        setSelectedIdsInParent([]);
      }
    } else {
      try {
        setLoading(true);
        const payload = {
          record_ids: IDs,
          type: itemType || STRINGS.SMALL_FILE,
        };
        const response = await deleteRequest(
          templateEndpoints.deleteTemplate,
          payload,
          navigate
        );
        dispatch(
          getTemplateList({
            parent: id,
            navigate,
          })
        );
        dispatch(paginationDataAction({checkedRow:[]}));
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        setOpen(!open);
        setLoading(false);
      } catch (err) {
        EventEmitter.emit(EMITTER_ERROR, err?.data?.message)
      } finally {
        setLoading(false);
        setOpen(false);
      }
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: `${STRINGS.DELETE_SELECTED} record`,
    },
  };

  const modalBodyData = (
    <div >
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.REGULAR}
        label={`${STRINGS.DELETE_BULK} records?`}
        data-tour={STRINGS.SMALL_FOLDER_NAME}
      />
    </div>
  );

  const modalFooterLeftData = <div></div>;

  const modalFooterRightData = (
    <div className={classes.displayFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.DELETE}
        size={"sm36"}
        variant={"primary"}
        onClick={deleteRecord}
        isLoading={loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      variant={"error"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default BulkDeleteModal;
