import { postRequest } from "../../Apis";
import {
  contactListEndPoints,
  listEndPoints,
} from "../../utils/constants/httpConstants";
import {
  LISTCONTACT_REQUEST,
  LISTCONTACT_SUCCESS,
  LISTCONTACT_FAIL,
} from "../constants/contactListConstants";

export const getContactListData = (sentQuery, navigate) => async (dispatch, getState) => {
  try {

    dispatch({ type: LISTCONTACT_REQUEST });
    const response1 = await postRequest(
      navigate,
      listEndPoints.showFolderList,
      sentQuery
    );

    if(!response1?.data?.total_records ){
      dispatch({
        type: LISTCONTACT_SUCCESS,
        payload: { ...response1?.data || {}, query: sentQuery },
      });
    }else{
      const ids = response1?.data?.data?.records?.map((item) => item?._id);
      const response = await postRequest(
        navigate,
        contactListEndPoints.getAllListCount,
        { list_ids: ids }
      );
      let idKeyValue = {};
      for (let i in response?.data?.data) {
        idKeyValue[response?.data?.data?.[i]?._id] =
          response?.data?.data?.[i]?.count;
      }
      const dataVal = response1?.data?.data?.records?.map((item) => {
        return {
          ...item,
          count: idKeyValue?.[item["_id"]] || 0,
        };
      });
      dispatch({
        type: LISTCONTACT_SUCCESS,
        payload: { ...response1?.data || {},data:{records:dataVal}, query: sentQuery },
      });   
    }
    
   
  } catch (err) {
    dispatch({ type: LISTCONTACT_FAIL, payload: err });
  }
};
