import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import UserDetailModal from "./UserDetailModal";
import { CircularLoader } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { filterQuery } from "../../../utils/helpers";
import { getRequest, postRequest } from "../../../Apis";
import { CampaignSettingsEndpoints, contactListEndPoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS, tenants_api_endpoints } from "../../../utils/constants";
import LinearLoader from "../../../components/Loaders/LinearLoader";
import { CreateListModal, ExportModal } from ".";
import * as qs from "qs";
import {
  getListIds,
  tableDataFetch,
} from "../../../redux/actions/tableActions";
import { checkLogin } from "../../../redux/actions/authActions";
import DeleteRecordModal from "./DeleteRecordModal";
import CustomTable from "../../../components/Table/CustomTable";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import { ICON_LABELS, fontWeight, fontSize } from "../../../utils/constants/UI";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { BRAND, GREY } from "../../../utils/constants/colors";
import FilterTab from "../../../components/FilterTab/FilterTab";
import { paginationDataAction } from "../../../redux/actions/customTableActions";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";
import CreditsForRefferalModal from "../../../components/Modals/CreditsForRefferalModal";
import classes from "./LeadsComponent.module.css";
import SearchField from "../../../components/InputFields/SearchField";
import IconComponent from "../../../components/Icon/IconComponent";
import AddCrmConfirmationModal from "./AddCrmConfirmationModal";

const PeopleTable = ({
  heads,
  records,
  savedContactsTrue = false,
  selectedBulkValue,
  setSelectedBulkValue,
  bulkSelected,
  setBulkSelected, setIsEditContact = () => { },
  addContactHandler = () => { }
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const allFiltersSelector = useSelector((state) => state.allFilters);
  const tableDataSelector = useSelector((state) => state.tableDataFetch);
  const tableEndpointChangeSelector = useSelector(
    (state) => state?.tableEndpointChange
  );

  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const getListIdsSelector = useSelector((state) => state?.getListIds);
  const [selected, setSelected] = React.useState([]);
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );
  const columns = heads;
  const [emailUnlocking, setEmailUnlocking] = useState("");
  const [data, setData] = useState(records || []);
  const [showMoveToList, setShowMoveToList] = useState(null);
  const [lists, setLists] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [listUpdating, setListUpdating] = useState("");
  const [showCreateList, setShowCreateList] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showCrmConfirmation, setShowCrmConfirmation] = useState(false);
  const [showRecordRemove, setShowRecordRemove] = useState(false);
  const [changedSearch, setChangedSearch] = useState("");
  const [changedName, setChangedName] = useState("");
  const [createListModal, setCreateListModal] = useState(false);
  const [credits, setcredits] = useState(false);
  const [userDetailOpen, setUserDetailOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [userDetailIndex, setUserDetailIndex] = useState("");
  const [removeFromListEL, setRemoveFromListEL] = useState(null);
  let ownerTest = sessionStorage.getItem(STRINGS.ITEM_KEY);
  let ownerName = sessionStorage.getItem(STRINGS.ITEM_NAME);
  let UserdetailsName = `${userDetailsSelector?.payload?.first_name} ${userDetailsSelector?.payload?.last_name}`;

  const bulkRecordCount =
    tableDataSelector?.totalRecords < 500
      ? tableDataSelector?.totalRecords?.toLocaleString()
      : 500?.toLocaleString();

  const handleSaveToMyList = async (action) => {
    const payload = bulkSelected
      ? {
        ...filterQuery(allFiltersSelector?.payload),
        action_type: STRINGS.SAVE_LIST,
        list_id: STRINGS.MY_LIST,
      }
      : {
        record_ids: selected,
        action_type: STRINGS.SAVE_LIST,
        list_id: STRINGS.MY_LIST,
      };

    const email_ids = tableDataSelector?.columns
      ?.filter((item) => selected.includes(item?._id) && item.email)
      ?.map((item) => item?.email);
    payload.email_ids = email_ids || [];

    const URL = bulkSelected
      ? contactListEndPoints.bulkSaveToList
      : contactListEndPoints.saveToList;
    try {
      const response = await postRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response.data?.message || "");
      dispatch(checkLogin(navigate));
      dispatch(
        paginationDataAction({
          ...paginationDataSelector?.data,
          checkedRow: [],
        })
      );
      dispatch(tableDataFetch(allFiltersSelector?.payload, navigate, true));
      setSelected([]);
    } catch (err) {
      if (err?.status === 404) {
        setcredits(true);
      } else {
        EventEmitter.emit(EMITTER_ERROR, err?.data?.message || STRINGS.SESSION);
      }
    }
  };

  const handleListSuggestionSearch = async (e) => {
    try {
      setFetching(true);
      const response = await getRequest(
        navigate,
        contactListEndPoints.getList +
        `?${STRINGS.SMALL_SEARCH}=${e.target.value}&${STRINGS.PAGE}=1`
      );
      setLists(response?.data?.data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const handleAddToList = async (id) => {
    const payload = bulkSelected
      ? {
        ...filterQuery(allFiltersSelector?.payload),
        action_type: STRINGS.SAVE_LIST,
        list_id: id,
      }
      : {
        record_ids: selected,
        action_type: STRINGS.SAVE_LIST,
        list_id: id,
      };
    const URL = bulkSelected
      ? tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
        ? contactListEndPoints.bulkAlreadySaveToList
        : contactListEndPoints.bulkSaveToList
      : tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
        ? contactListEndPoints.alreadySaveToList
        : contactListEndPoints.saveToList;

    try {
      const email_ids = tableDataSelector?.columns
        ?.filter((item) => selected.includes(item?._id) && item.email)
        ?.map((item) => item?.email);
      payload.email_ids = email_ids || [];

      setListUpdating(id);
      const response = await postRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response.data?.message || "");
      dispatch(
        paginationDataAction({
          ...paginationDataSelector?.data,
          checkedRow: [],
        })
      );
      dispatch(tableDataFetch(allFiltersSelector?.payload, navigate, true));
      setSelected([]);
      setShowMoveToList(null);
      setListUpdating("");
    } catch (err) {

      if (err?.status === 404) {
        setcredits(true);
      } else {
        EventEmitter.emit(EMITTER_ERROR, err?.data?.message || STRINGS.SESSION);
      }
      setSelected([]);
      setShowMoveToList(null);
      setListUpdating("");
    }
  };

  const handleGetAllList = async () => {
    try {
      setFetching(true);
      const response = await getRequest(
        navigate,
        contactListEndPoints.getList +
        `?${STRINGS.SMALL_SEARCH}=&${STRINGS.PAGE}=1`
      );
      setLists(response?.data?.data || []);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const addToListTooltip = (
    <div className={classes.toolTipContain}>
      <Box className={classes.addToList}>
        <TypographyInline
          label={STRINGS.ADD_TO_LIST}
          size={"md"}
          fontWeight={fontWeight.MEDIUM}
          color={GREY.PRIMARY}
        />
      </Box>
      <Box className={classes.containAll}>
        <Box component="form" className={classes.searchList}>
          <SearchField
            size={"sm36"}
            // value={listName}
            LeftIcon={ICON_LABELS.SEARCH}
            onChange={handleListSuggestionSearch}
            // onChange={(e) => {
            //   e.preventDefault();
            //   setListName(e?.target?.value);
            // }}
            onKeyDown={(e) => e.stopPropagation()}
            placeholder={STRINGS.SEARCH_LIST}
          />
        </Box>
        <Box className={classes.containAllLists}>
          {fetching ? (
            <div className={classes.FLEX}>
              <CircularLoader />
            </div>
          ) : (
            lists?.map((item) => {
              return (
                <Box key={item._id} className={classes.addEachList} onClick={() => handleAddToList(item?._id)}>
                  <IconComponent
                    iconLabel={ICON_LABELS.LIST_ALT}
                    color={GREY.TERTIARY}
                    fontSize={fontSize.SM}
                  />
                  {listUpdating === item?._id ? (
                    <LinearLoader />
                  ) : (
                    <TypographyInline
                      label={item?.list_name}
                      size={"sm"}
                      fontWeight={fontWeight.MEDIUM}
                      color={GREY.SECONDARY}
                    />
                  )}
                </Box>
              );
            })
          )}
        </Box>
      </Box>

      <Box className={classes.createNewList}>
        <div>
          <Button
            LeftIcon={ICON_LABELS.ADD}
            label={STRINGS.CREATE_NEW_LIST}
            variant={"text"}
            size={"sm36"}
            onClick={() => {
              setShowCreateList(!showCreateList);
              setShowMoveToList(null);
            }}
          />
        </div>
        <div>
          <Button
            label={STRINGS.CANCEL}
            variant={"ghost"}
            size={"sm36"}
            onClick={() => {
              setShowMoveToList(null);
            }}
          />
        </div>
      </Box>
    </div>
  );

  const handleAdvanceExport = async () => {
    const payload = { ...filterQuery(allFiltersSelector?.payload) };
    const URL = contactListEndPoints.advanceExport;
    try {
      const response = await postRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response.data?.message || "");
    } catch (err) {
      EventEmitter.emit(EMITTER_ERROR, err?.data?.message || STRINGS.SESSION);
    }
  };

  const handleGetAllListByIds = async () => {
    try {
      setFetching(true);
      const payload = {
        record_ids: selected,
      };
      const response = await postRequest(
        navigate,
        contactListEndPoints.getListRecordWise,
        payload
      );
      setLists(response?.data?.data || []);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const headerStrip = [
    !savedContactsTrue && (
      <FilterTab
        leftIcon={ICON_LABELS.FACT_CHECK}
        label={STRINGS.SAVE_CONTACTS}
        onClick={handleSaveToMyList}
      />
    ),
    <FilterTab
      leftIcon={ICON_LABELS.POST_ADD}
      label={STRINGS.ADD_TO_LIST}
      onClick={(e) => {
        setShowMoveToList(e?.currentTarget);
        handleGetAllList();
      }}
    />,
    <FilterTab
      leftIcon={ICON_LABELS.DOWNLOAD_FOR_ONLINE}
      label={STRINGS.EXPORT}
      onClick={() => {
        setShowExportModal(true);
      }}
    />,
    <FilterTab
      leftIcon={ICON_LABELS.ADD}
      label={STRINGS.ADD_TO_CRM}
      onClick={() => {
        setShowCrmConfirmation(true);
      }}
    />,
    userDetailsSelector?.payload?.advance_export && (
      <FilterTab
        leftIcon={ICON_LABELS.DOWNLOAD_FOR_ONLINE}
        label={STRINGS.ADV_EXPORT}
        onClick={handleAdvanceExport}
      />
    ),
    queryObject.contact_lists &&
    ((tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT &&
      !bulkSelected &&
      ownerTest === STRINGS.TRUE &&
      UserdetailsName === ownerName) ||
      (tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT &&
        !bulkSelected && (
          <FilterTab
            leftIcon={ICON_LABELS.DELETE_OUTLINE}
            label={STRINGS.REMOVE_FROM_CONTACT_LIST}
            onClick={(e) => {
              handleGetAllListByIds();
              setRemoveFromListEL(e.currentTarget);
            }}
          />
        ))),
    savedContactsTrue && paginationDataSelector?.data?.checkedRow?.length === 1 && (
      <FilterTab
        leftIcon={ICON_LABELS.EDIT}
        label={STRINGS.EDIT_CONTACTS}
        onClick={() => { setIsEditContact(true); addContactHandler() }}
      />
    )
  ];


  const handleUnlockEmail = async (row, head, index, rowIndex) => {
    try {
      setEmailUnlocking(row?._id);
      const response = await postRequest(
        navigate,
        contactListEndPoints.singleEmailUnlock,
        { record_id: row?._id }
      );
      const newData = JSON.parse(JSON.stringify(data));
      const updatedRecord = newData?.filter((eachRecord) => {
        return eachRecord?._id === row?._id;
      })[0];
      updatedRecord.email = response?.data?.data?.email;
      const result = newData?.map((eachRecord) => {
        return eachRecord?._id === row?._id ? updatedRecord : eachRecord;
      });
      setData(result);
      setEmailUnlocking("");
      dispatch(tableDataFetch({}, navigate, true, result));
      await dispatch(checkLogin(navigate, tenants_api_endpoints));
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (err) {
      setEmailUnlocking("");
      EventEmitter.emit(
        EMITTER_ERROR,
        err?.data?.message || STRINGS.SOMETHING_WENT_WRONG
      );
    }
  };

  const handleBulkSelection = async () => {
    setBulkSelected(!bulkSelected);
    const selectedIds = data.map((item) => item?._id);

    console.log(selectedIds, records, "RDDD");
    setSelected(selectedIds);
    if (selectedBulkValue?.length) {
      setSelectedBulkValue("");
    } else {
      setSelectedBulkValue(bulkRecordCount);
    }
  };

  useEffect(() => {
    setData(records);
  }, [records]);

  useEffect(() => {
    if (changedName !== queryObject?.name) {
      setChangedName(queryObject?.name);
    }
    if (changedSearch !== queryObject?.search) {
      setChangedSearch(queryObject?.search);
    }
  }, [queryObject, changedName, changedSearch]);

  useEffect(() => {
    if (paginationDataSelector?.data?.checkedRow?.length) {
      setSelected(paginationDataSelector?.data?.checkedRow);
    } else {
      setSelectedBulkValue("");
      setBulkSelected("");
      setSelected("");
    }
  }, [paginationDataSelector?.data?.checkedRow, bulkSelected]);

  const handleResetTable = () => {
    setSelected([]);
    dispatch(tableDataFetch(allFiltersSelector?.payload, navigate, true));
    dispatch(checkLogin(navigate));
  };


  const handleChangeInList = (item) => {
    if ((getListIdsSelector.payload || []).includes(item?._id)) {
      const newArray = (getListIdsSelector.payload || []).filter(
        (i) => i !== item?._id
      );
      dispatch(getListIds(newArray));
    } else {
      dispatch(getListIds([...(getListIdsSelector.payload || []), item._id]));
    }
  };

  const removeFromListTooltip = (
    <div className={classes.toolTipContain}>
      <Box className={classes.containAllLists2}>
        <Box className={classes.containHead}>
          <TypographyInline
            label={STRINGS.SELECT_LIST}
            size={"md"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.PRIMARY}
          />
        </Box>
        {fetching ? (
          <div className={classes.selectList}>
            <CircularLoader />
          </div>
        ) : (
          lists?.map((item) => {
            return (
              <Box
                key={item._id}
                className={classes.eachList}
              // onClick={() => handleChangeInList(item)}
              >
                <Checkbox
                  checked={(getListIdsSelector.payload || []).includes(
                    item._id
                  )}
                  onClick={() => handleChangeInList(item)}
                />
                {listUpdating === item?._id ? (
                  <LinearLoader />
                ) : (
                  <div
                    className={classes.cursorPointer}
                    onClick={() => handleChangeInList(item)}
                  >
                    <TypographyInline
                      label={item?.list_name}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                      color={GREY.SECONDARY}
                    />
                  </div>
                )}
              </Box>
            );
          })
        )}
      </Box>

      <Box className={classes.createNewList}>
        <div className={classes.btnContainer}>
          <Button
            label={STRINGS.CANCEL}
            variant={"ghost"}
            size={"sm36"}
            onClick={() => {
              setRemoveFromListEL(null);
            }}
          />
          <Button
            label={STRINGS.REMOVE_FROM_LIST}
            variant={"primary"}
            size={"sm36"}
            onClick={() => {
              setShowRecordRemove(true);
              setRemoveFromListEL(null);
            }}
            disabled={!getListIdsSelector?.payload?.length}
          />
        </div>
      </Box>
    </div>
  );

  const tableData = {
    heads: heads?.filter((item) => item?.isChecked),
    body: records,
    isSticky: true,
    total_records:
      tableDataSelector?.totalRecords > 1000
        ? 1000
        : tableDataSelector?.totalRecords,
    isLoading: tableDataSelector?.loading,
    actions: {
      email: {
        function: (row, head, index, rowIndex) =>
          handleUnlockEmail(row, head, index, rowIndex),
        loading: emailUnlocking,
      },
      user_info: {
        function: (row, head, index) => {
          setUserDetailOpen(true);
          setUserDetail(row);
          setUserDetailIndex(index + 1);
        },
      },
    },
    header_strip_selected_value: selectedBulkValue,
  };
  return (
    <Box className={classes.tableBox}>
      {/* List actions here */}
      <Modal open={showExportModal} onClose={() => setShowExportModal(false)}>
        <ExportModal
          open={showExportModal}
          setOpen={setShowExportModal}
          bulkSelected={bulkSelected}
          setBulkSelected={setBulkSelected}
          record_ids={selected}
          columns={columns}
          setSelectedIds={setSelected}
        />
      </Modal>
      {showCrmConfirmation && (
        <AddCrmConfirmationModal
          open={showCrmConfirmation}
          setOpen={setShowCrmConfirmation}
          bulkSelected={bulkSelected}
          setBulkSelected={setBulkSelected}
          record_ids={selected}
          columns={columns}
          setSelectedIds={setSelected}
          setcredits={setcredits}
        />
      )}

      <CreateListModal
        open={showCreateList}
        setOpen={setShowCreateList}
        recordIds={selected}
        bulkSelected={bulkSelected}
        onCreate={handleResetTable}
      />

      {/* Record remove from list modal */}
      <DeleteRecordModal
        open={showRecordRemove}
        setOpen={setShowRecordRemove}
        list_ids={getListIdsSelector.payload || []}
        record_ids={selected}
      />

      {/* List actions here */}
      {selected?.length >= 1 ? (
        <Box className={classes.containAllContacts}>
          {!bulkSelected &&
            <TypographyInline
              label={
                selected?.length === 1
                  ? `${STRINGS.ONE_PAGE_SLT}`
                  : `${STRINGS.ALL} ${selected.length} ${STRINGS.CNT_ON_PAGE}`
              }
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.SECONDARY}
            />
          }
          <div className={classes.cursorPointer} onClick={handleBulkSelection}>
            <TypographyInline
              label={!bulkSelected ? `${STRINGS.SELECT_ALL} ${bulkRecordCount} ${STRINGS.SMALL_CONTACTS}` : `${STRINGS.UNSELECT_ALL} ${bulkRecordCount} ${STRINGS.SMALL_CONTACTS}`}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={BRAND.PRIMARY}
            />
          </div>
        </Box>
      ) : null}

      <div
        className={
          selected?.length >= 1
            ? classes.containTableSmall
            : classes.containTable
        }
      >
        <div className={classes.tableContainer}>
          <CustomTable
            tableData={tableData}
            headerStrip={headerStrip?.filter((item) => item)}
          />
          <CustomStyledMenu
            noMargin={true}
            anchorEl={showMoveToList}
            open={Boolean(showMoveToList)}
            width={"fit-content"}
            onClose={() => setShowMoveToList(null)}
          >
            {addToListTooltip}
          </CustomStyledMenu>

          <CustomStyledMenu
            open={Boolean(removeFromListEL)}
            anchorEl={removeFromListEL}
            noMargin={true}
            onClose={() => {
              setRemoveFromListEL(null);
              dispatch(getListIds([]));
            }}
          >
            {removeFromListTooltip}
          </CustomStyledMenu>

          {userDetailOpen && (
            <UserDetailModal
              modalOpen={userDetailOpen}
              setUserDetailOpen={setUserDetailOpen}
              user={userDetail}
              index={userDetailIndex}
              tableData={data}
            />
          )}
        </div>
      </div>
      {/* All Contact Label */}

      {/* All Contact Label */}
      <CreateListModal open={createListModal} setOpen={setCreateListModal} />

      {credits ? (
        <CreditsForRefferalModal open={credits} close={setcredits} />
      ) : (
        ""
      )}
    </Box>
  );
};

export default React.memo(PeopleTable);
