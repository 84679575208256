import React, { useEffect, useState } from "react";
import classes from "./Deals.module.css";
import CustomHeader from "../../../components/Header/CustomHeader";
import { STRINGS } from "./strings";
import DealsHeader from "./components/DealsHeader";
import FilterStrip from "./components/FilterStrip";
import EmptyScreen from "../../../components/Screens/EmptyScreen";
import { COMPACT_CARD_SELECTED_STYLE, COMPACT_CARD_STYLE, DEALS_EMPTY_SCREEN, DEFAULT_CARD_SELECTED_STYLE, DEFAULT_CARD_STYLE } from "../../../utils/constants/assets";
import AddDealDialog from "./components/AddDealDialog";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DROPDOWN_PEOPLE_HEADER_SORTING, tempHeadDealsCells } from "../../../utils/constants";
import SearchField from "../../../components/InputFields/SearchField";
import {
  allDynamicFilters,
  filterMultipleList,
} from "../../../redux/actions/filterActions";
import { Button, IconButton } from "../../../components/Buttons";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import { getDealsList } from "../../../redux/actions/dealsActions";
import DealsTable from "./components/DealsTable";
import { decompressString, filterDynamicQuery } from "../../../utils/helpers";
import EditColumnModal from "./components/EditColumnModal";
import * as qs from "qs";
import { DEALS_BOARD_PATH, DEALS_PATH, PROFILE_PATH } from "../../../utils/constants/routes";
import DealsBoardView from "./components/DealsBoardView";
import CustomMenu from "../../../components/Menu/CustomMenu";
import { AutoCompleteCustom, CustomModal } from "../../../components";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { paginationDataAction } from "../../../redux/actions/customTableActions";
import CustomDialog from "../../../components/Dialog/CustomDialog";
import RadioButton from "../../../components/RadioButton/RadioButton";
import Image from "../../../components/Image/Image";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";

const DealsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );
  const dealsListSelector = useSelector((state) => state.getDealsList);

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  const dynamicUpdatedColumnSelector = useSelector(
    (state) => state.dynamicUpdatedColumn
  );
  const userDetailsSelector = useSelector((state) => state?.userDetails?.payload);
  // storing all properties into below variable.
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );

  const getPipelineListSelector = useSelector(
    (state) => state.getPipelineList?.data
  );

  const propertiesPayload = localStorage.getItem("properties") ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))?.hits : getPropertiesListSelector?.hits;
  const res = qs.parse(window.location?.search?.slice(1));
  const [addDealOpen, setAddDealOpen] = useState(false);
  const [searchField, setSearchField] = useState(res?.search || "");
  const [dealTableLoading, setDealTableLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [getPipeline, setGetPipeline] = useState([]);
  const [pipelineValue, setPipelineValue] = useState([]);
  const [pipelineId, setPipelineId] = useState([]);
  const [dealsViewType, setDealsViewType] = useState("");
  const [boardActionAnchor, setBoardActionAnchor] = useState(null);
  const [openEditCardDialog, setOpenEditCardDialog] = useState(false);
  const [openSortModal, setOpenSortModal] = useState(false);
  const [sortById, setSortById] = useState([]);
  const [sortByLabel, setSortByLabel] = useState([])
  const [orderId, setOrderId] = useState([]);
  const [orderLabel, setOrderLabel] = useState([]);
  const [cardStyle, setCardStyle] = useState(STRINGS.DEFAULT);
  const [displayCardFooter, setDisplayCardFooter] = useState(false);
  const [clickedStage, setClickedStage] = useState("");
  const [isMount, setIsMount] = useState(false);
  const [isFromBoardView, setIsFromBoardView] = useState(false);

  const headerItems = [{ id: STRINGS.DEALS_SMALL, label: STRINGS.DEALS }];
  const tableBody = dealsListSelector?.data?.hits || [];
  const totalCount = dealsListSelector?.data?.total_records?.toString();
  const queryLength =
    Object.keys(filterDynamicQuery(allDynamicFiltersSelector) || {})?.length ||
    false;


  //pipeline change function
  const handleChangedPipeline = async (id, value) => {
    setPipelineId([id]);
    setPipelineValue([value]);
  };

  const searchFieldHandler = async (value) => {
    if (value) {
      dispatch(
        filterMultipleList({
          ...filterMultipleListSelector,
          search: value,
          page: 1,
          limit: 25,
          isApiCall: true,
        })
      );
    } else {
      let data = { ...filterMultipleListSelector };
      delete data?.search;
      dispatch(
        filterMultipleList({
          ...data,
          page: 1,
          limit: 25,
          isApiCall: true,
        })
      );
    }
  };

  const handlePropertyTypeChange = (data) => {
    handleChangedPipeline(data?.id[0], data?.label[0]);
  };

  const handleNavigatePipeline = () => {
    navigate(`${PROFILE_PATH}?tab=Pipeline`);
  };

  // pipeline dropdown to be displayed into custom header.
  const headerPipelineDropdown = (
    <AutoCompleteCustom dropdownId="dealDropdown" items={[...getPipeline]} LeftIcon="" placeholder={"Select..."} valueState={pipelineValue} setValueState={setPipelineValue} idState={pipelineId} setIdState={setPipelineId} onChange={(data) => handlePropertyTypeChange(data)} size={"sm36"} />
  )

  // create pipeline button to be displayed into custom header.
  const headerPipelineButton = (
    <Button label={STRINGS.CREATE_PIPELINE} size="sm36" variant={"primary"} LeftIcon={ICON_LABELS.ADD} onClick={handleNavigatePipeline}
    />
  )

  // board actions list of board view
  const boardActionsList = [
    {
      id: "sort_deals",
      label: STRINGS.SORT,
      iconLabel: ICON_LABELS.SORT,
      rightIconLabel: ICON_LABELS.KEYBOARD_ARROW_RIGHT,
      function: () => setOpenSortModal(true),
    },
    {
      id: "esit_stages",
      label: STRINGS.EDIT_STAGES,
      iconLabel: ICON_LABELS.VIEW_AGENDA,
      rightIconLabel: ICON_LABELS.OPEN_IN_NEW,
      function: () => { navigate(PROFILE_PATH + "?tab=Pipeline"); window.selected_pipeline = { id: pipelineId, label: pipelineValue }; },
      isVisible: userDetailsSelector?.role === "admin-user",
    },
    {
      id: "edit_cards",
      label: STRINGS.EDIT_CARDS,
      iconLabel: ICON_LABELS.ARTICLE,
      function: () => setOpenEditCardDialog(true),
    },
  ];

  // sort api handler
  const sortHandler = async () => {
    try {
      let sortingPayload = {
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          sort: {
            id: sortById?.toString(),
            direction: orderId?.toString()
          },
        },
      };
      await dispatch(paginationDataAction({ ...sortingPayload }));
      sortResetHandler();
    } catch (error) {
      console.log("Error------------>>>>>>>>>> ", error);
    }
  }

  const sortResetHandler = () => {
    // storing the values of dropdown to empty.
    setOpenSortModal(false);
  }

  // sort modal header
  const sortModalHeader = {
    heading: {
      id: STRINGS.SORT_DETAILS_SMALL,
      label: STRINGS.SORT_DETAILS,
    },
  };

  // sort modal footer
  const sortModalRightFooter = (
    <div className={classes.sortModalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={sortResetHandler}
      />
      <Button
        label={STRINGS.SAVE}
        variant={"primary"}
        size={"sm36"}
        onClick={sortHandler}
        disabled={!(sortById?.length && orderId?.length)}
      />
    </div>
  );

  // property ids and label for sort dropdown.
  const SORT_IDS_VALUES = propertiesPayload?.filter(item => item?.object_type?.toString() === STRINGS.DEALS)?.map((item) => {
    return {
      label: item?.property_name,
      id: item?.id
    }
  })


  // edit card reset handler
  const editCardResetHandler = () => {
    setOpenEditCardDialog(false);
  }

  // edit cards dialog header
  const editCardsDialogHeader = {
    heading: {
      id: STRINGS.EDIT_BOARD_VIEW_ID,
      label: STRINGS.EDIT_BOARD_VIEW,
    },
  };

  // card style handler
  const cardStyleHandler = (event) => {
    setCardStyle(event?.target?.value)
  }

  //getPipelineList dispatch
  useEffect(() => {
    // storing view-type of deals screen.
    if (location?.pathname === DEALS_BOARD_PATH) {
      setDealsViewType(STRINGS.BOARD);
      localStorage.setItem("deal_view", STRINGS.BOARD);
    } else {
      setDealsViewType(STRINGS.LIST)
      localStorage.setItem("deal_view", STRINGS.LIST);
    }
  }, [navigate]);

  useEffect(() => {

    // storing pipelines into dropdown.
    (async () => {
      try {
        if (getPipelineListSelector?.length) {
          const newObj = { id: STRINGS.ALL, label: STRINGS.ALL_PIPELINES };
          let pipeline_id = pipelineId?.length ? pipelineId?.[0] : getPipelineListSelector?.[0]?.id;
          let pipeline_value = pipelineValue?.length ? pipelineValue?.[0] : getPipelineListSelector?.[0]?.label;

          if (localStorage.getItem("deal_view") === STRINGS.LIST) {
            setGetPipeline([newObj, ...getPipelineListSelector]);
            handleChangedPipeline(pipeline_id, pipeline_value);
          } else {

            let pipeline_id = pipelineId?.length ? pipelineId?.[0] : getPipelineListSelector?.[0]?.id;
            let pipeline_value = pipelineValue?.length ? pipelineValue?.[0] : getPipelineListSelector?.[0]?.label;
            setGetPipeline([...getPipelineListSelector]);
            handleChangedPipeline(pipeline_id, pipeline_value);
          }
        } else {
          setPipelineValue([]);
          setPipelineId([]);
          setLoadingTable(false);
          setGetPipeline([]);
        }
      } catch (error) { }
    })();
  }, [getPipelineListSelector, navigate]);

  // debounce search api call for search
  useEffect(() => {
    if (isMount) {
      let getData;
      getData = setTimeout(() => {
        searchFieldHandler(searchField);
      }, 300);
      return () => clearTimeout(getData);
    }
    setIsMount(true);
  }, [searchField]);

  useEffect(() => {
    let session_key = sessionStorage.getItem("active_view_id");
    // get deals list api 
    if (
      dynamicUpdatedColumnSelector?.columns
        ?.filter((item) => item?.isChecked)
        ?.map((item) => item?.id)
        ?.includes("deal_name") &&
      !dealTableLoading &&
      !dynamicUpdatedColumnSelector?.not_api_call &&
      Object.keys(getPropertiesListSelector || {})?.length && pipelineId?.length && (session_key ? Object.keys(allDynamicFiltersSelector)?.length : true) && !isFromBoardView
    ) {
      (async () => {
        try {
          setDealTableLoading(true);
          if (pipelineId?.[0] === STRINGS.ALL) {
            await dispatch(
              getDealsList(
                {
                  query: {
                    ...allDynamicFiltersSelector,
                  },
                },
                navigate,
                {},
                true,
                localStorage.getItem("deal_view") === STRINGS.LIST ? false : true
              )
            );
          } else {
            await dispatch(
              getDealsList(
                {
                  query: {
                    ...allDynamicFiltersSelector,
                  },
                },
                navigate,
                {
                  pipeline_id: pipelineId?.[0],
                },
                true,
                localStorage.getItem("deal_view") === STRINGS.LIST ? false : true
              )
            );
          }
        } catch (error) {
          console.log("Error --->>>>. ", error);
        } finally {
          setDealTableLoading(false);
          setLoadingTable(false);
        }
      })();
    }
  }, [
    navigate,
    dispatch,
    allDynamicFiltersSelector,
    queryLength,
    dynamicUpdatedColumnSelector,
    getPropertiesListSelector,
    pipelineId,
  ]);

  useEffect(() => {
    if (Object.keys(paginationDataSelector?.data?.query || {}).length) {
      let payloadQuery = {
        ...filterMultipleListSelector,
        ...paginationDataSelector?.data?.query,
      };

      if (paginationDataSelector?.data?.query?.sort?.id?.length) {
        payloadQuery = {
          ...payloadQuery,
          sort: paginationDataSelector?.data?.query?.sort?.id,
        };
      }
      if (paginationDataSelector?.data?.query?.sort?.direction) {
        payloadQuery = {
          ...payloadQuery,
          direction: paginationDataSelector?.data?.query?.sort?.direction,
        };
      }

      dispatch(
        allDynamicFilters(
          {
            ...payloadQuery,
          },
          navigate
        )
      );

      let navigateQuery = { ...payloadQuery };
      delete navigateQuery?.isApiCall;
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...navigateQuery,
        }).toString(),
      });
    }
  }, [
    paginationDataSelector?.data?.query?.page,
    paginationDataSelector?.data?.query?.limit,
    paginationDataSelector?.data?.query?.sort,
  ]);

  return (
    <div className={classes.contactScreenMainContainer}>
      {/* Header */}
      <CustomHeader
        items={headerItems}
        description={`${dealsListSelector?.loading || loadingTable
          ? "Loading..."
          : dealsListSelector?.data?.is_empty || !getPipelineListSelector?.length ? "" : totalCount > 1
            ? `${totalCount} ${STRINGS.DEALS}`
            : `${totalCount || "0"} ${STRINGS.DEAL}`
          }`}
        children={!getPipelineListSelector?.loading && !loadingTable && (getPipelineListSelector?.length ? headerPipelineDropdown : userDetailsSelector?.role === "admin-user" ? headerPipelineButton : null)}
      />
      {/* Header */}

      {/* View Tab Header */}
      <DealsHeader />
      {/* View Tab Header */}

      {/* Table Container */}
      {dealsListSelector?.data?.is_empty || !getPipelineListSelector?.length ? (
        <div className={classes.dealsEmptyScreenContainer}>
          <EmptyScreen
            imageSrc={DEALS_EMPTY_SCREEN}
            label={STRINGS.EMPTY_DEALS_SCREEN_HEADING}
            description={STRINGS.EMPTY_DEALS_SCREEN_DESC}
            buttonLabel={STRINGS.CREATE_DEAL}
            onClick={() => setAddDealOpen(true)}
            disabled={!getPipeline?.length}
          />
        </div>
      ) : (
        <>
          {/* Filter Strip */}
          <FilterStrip setSearchField={setSearchField} setSortById={setSortById} setSortByLabel={setSortByLabel} setOrderId={setOrderId} setOrderLabel={setOrderLabel} />
          {/* Filter Strip */}

          {/* Table Search Bar and Table Operation Buttons */}
          <div className={classes.tableUpperStripContainer}>
            <div className={classes.searchFieldWidth}>
              <SearchField
                size={"sm36"}
                placeholder={STRINGS.SEARCH}
                value={searchField}
                onChange={(e) => {
                  setSearchField(e?.target?.value);
                }}
              />
            </div>
            <div className={classes.tableUpperStripRightContainer}>
              {pipelineId?.toString() !== "All" &&
                <><div>
                  <IconButton LeftIcon={ICON_LABELS.TABLE_ROWS} size={"sm36"} variant={localStorage.getItem("deal_view") === STRINGS.LIST ? "active" : "ghost"} onClick={() => { setDealsViewType(STRINGS.BOARD); navigate(DEALS_PATH + location?.search); }} tooltipLabel={STRINGS.LIST_VIEW} />
                </div>

                  <div>
                    <IconButton size={"sm36"} variant={localStorage.getItem("deal_view") === STRINGS.BOARD ? "active" : "ghost"} LeftIcon={ICON_LABELS.VIEW_KANBAN} onClick={() => { setDealsViewType(STRINGS.LIST); navigate(DEALS_BOARD_PATH + location?.search); }} tooltipLabel={STRINGS.BOARD_VIEW} />
                  </div></>}

              {
                dealsViewType === STRINGS.LIST ? <EditColumnModal /> : <>
                  <Button RightIcon={ICON_LABELS.KEYBOARD_ARROW_DOWN} label={STRINGS.BOARD_ACTIONS} size={"sm36"} variant={"ghost"} onClick={(event) => setBoardActionAnchor(event?.currentTarget)} />
                  {/* Custom Menu of Board Actions */}
                  <CustomMenu onClose={() => setBoardActionAnchor(null)}
                    anchor={boardActionAnchor}
                    open={Boolean(boardActionAnchor)}
                    items={boardActionsList}
                    paperMenuClass={classes.paperMenuClass}
                  />
                </>
              }

              <Button
                LeftIcon={ICON_LABELS.ADD}
                label={STRINGS.ADD_DEAL}
                size={"sm36"}
                variant="primary"
                onClick={() => setAddDealOpen(true)}
              />
            </div>
          </div>
          {
            dealsViewType === STRINGS.LIST ? <>
              {/* Table Search Bar and Table Operation Buttons */}
              <DealsTable
                heads={(
                  dynamicUpdatedColumnSelector?.columns || tempHeadDealsCells
                )?.filter((item) => item.isChecked && item.is_deal)}
                records={tableBody}
                totalCount={totalCount}
                setSearchField={setSearchField}
                loading={loadingTable}
                pipeline_id={pipelineId?.[0]}
              /></> :
              <DealsBoardView
                cardStyle={cardStyle}
                displayCardFooter={displayCardFooter}
                setAddDealOpen={setAddDealOpen}
                setClickedStage={setClickedStage}
                pipelineId={pipelineId}
                setIsFromBoardView={setIsFromBoardView}
              />
          }
        </>
      )}
      {/* Table Container */}

      {/* Add Contact Dialog */}
      {addDealOpen ? (
        <AddDealDialog
          open={addDealOpen}
          setOpen={setAddDealOpen}
          getDealsListed={getDealsList}
          pipeline_id={pipelineId?.[0]}
          pipeline_value={pipelineValue?.[0]}
          clickedStage={clickedStage}
        />
      ) : null}

      {/* Sort Modal */}
      {openSortModal && <CustomModal
        close={sortResetHandler}
        open={openSortModal}
        header={sortModalHeader}
        footer={{
          right: sortModalRightFooter,
        }}
      >
        <div className={classes.sortModalBodyContainer} >
          <div className={classes.inputLabelContainer}>
            <TypographyInline color={GREY.PRIMARY} label={STRINGS.PRIORITY} size={"sm"} fontWeight={fontWeight.MEDIUM} />
            <AutoCompleteCustom dropdownId={STRINGS.PRIORITY_ID} items={DROPDOWN_PEOPLE_HEADER_SORTING} idState={orderId} setIdState={setOrderId} valueState={orderLabel} setValueState={setOrderLabel} />
          </div>
          <div className={classes.inputLabelContainer}>
            <TypographyInline color={GREY.PRIMARY} label={STRINGS.SORT_BY} size={"sm"} fontWeight={fontWeight.MEDIUM} />
            <AutoCompleteCustom dropdownId={STRINGS.SORT_BY_ID} items={SORT_IDS_VALUES} idState={sortById} setIdState={setSortById} valueState={sortByLabel} setValueState={setSortByLabel} />
          </div>
        </div>
      </CustomModal>}

      {/* card design edit detials */}
      {openEditCardDialog && <CustomDialog
        open={openEditCardDialog}
        onClose={editCardResetHandler}
        header={editCardsDialogHeader}
        width="26.042vw"
      >
        <div className={classes.editCardDialogBody} >
          <TypographyInline color={GREY.PRIMARY} label={STRINGS.CHOOSE_CARD_STYLE} size="md" fontWeight={fontWeight.SEMIBOLD} />

          <div className={classes.cardStyleMainContainer} >
            <div className={cardStyle === STRINGS.DEFAULT ? classes.cardStyleSelectedContainer : classes.cardStyleContainer} onChange={cardStyleHandler} >
              <RadioButton items={[{ id: STRINGS.DEFAULT, label: STRINGS.DEFAULT_CAPS }]} value={cardStyle} />
              <Image src={cardStyle === STRINGS.DEFAULT ? DEFAULT_CARD_SELECTED_STYLE : DEFAULT_CARD_STYLE} />
            </div>
            <div className={cardStyle === STRINGS.COMPACT_SMALL ? classes.cardStyleSelectedContainer : classes.cardStyleContainer} onChange={cardStyleHandler}>
              <RadioButton items={[{ id: STRINGS.COMPACT_SMALL, label: STRINGS.COMPACT }]} value={cardStyle} />
              <Image src={cardStyle === STRINGS.COMPACT_SMALL ? COMPACT_CARD_SELECTED_STYLE : COMPACT_CARD_STYLE} />
            </div>
          </div>

          <div className={classes.inputLabelContainer}>
            <TypographyInline color={GREY.PRIMARY} label={STRINGS.MORE_OPTIONS} size="md" fontWeight={fontWeight.SEMIBOLD} />
            <Checkbox label={STRINGS.DISPLAY_PIORITY_AND_RECORDS} value={displayCardFooter} onChange={() => setDisplayCardFooter(!displayCardFooter)} />
          </div>
        </div>
      </CustomDialog>}

    </div>
  );
};

export default DealsScreen;
