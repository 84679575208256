export const STRINGS = {
  MANAGE_VIEWS: "Manage Views",
  RESET_ALL_PROPERTIES_DESC: "Are you sure to reset all properties?",
  SAVE_VIEW: "Save View",
  ALL_CONTACTS: "All Contacts",
  RESET_COLUMNS: "Reset Columns",
  ENTER_TASK: "Enter Title",
  LOG: "Log",
  FILTER: "Filter",
  CREATED_DATE: "Created Date",
  CONTACT_OWNER: "Contact Owner",
  LEAD_STATUS: "Lead Status",
  OWNER_ASSIGNED_DATE: "Owner Assigned Date",
  ADD_FILTER: "Add More Filters",
  CREATE_LEAD: "Create Lead",
  SET_PRIORITY: "Set Priority",
  SET_REMINDER: "Set Reminder",
  EMPTY_SCREEN_DESCRIPTION:
    "Add filters to prospect from our database of 166,152 business contacts.",
  ADD_CONTACTS: "Add Contact",
  DEAL_AMOUNT_SMALL: "deal_amount",
  UPLOADED: "Successfully uploaded",
  CONTACTS: "Contacts",
  COMPANIES: "Companies",
  FIRST_NAME: "First Name",
  FIRST_NAME_SMALL: "first_name",
  LAST_NAME: "Last Name",
  EMAILS: "Emails",
  EMAILS_SMALL: "emails",
  LINKEDIN_PROFILE: "Linkedin Profile",
  DATA_SOURCE: "Data Source",
  WEBSITE_URL: "Website URL",
  CATEGORY: "Category",
  TECHNOLOGY_STACK: "Technology Stack",
  PHONE_NUMBER: "Phone Number",
  ENTER_FRIST_NAME: "Enter First Name",
  ENTER_LAST_NAME: "Enter Last Name",
  EMAIL_PLACEHOLDER: "abc@example.com",
  LINKEDIN_URL: "Linkedin URL",
  WEBSITE_URL_PLACEHOLDER: "www.example.com",
  DROPDOWN_PLACEHODLER: "Select",
  ENTER_PHONE_NUMBER: "Enter Phone Number",
  ENTER_CONTACT_OWNER: "Enter Contact Owner ",
  EXPORT: "Export",
  EDIT_COLUMNS: "Edit Columns",
  SEARCH: "Search...",
  ALL_CONTACT_SAVED_VIEWS: "All Contact Saved Views ",
  STANDARD: "Standard",
  OWNER: "Owner",
  NO_PRIVATE_VIEW_CREATED: "No Private View Created!",
  NO_TEAM_VIEW_CREATED: "No Team View Created!",
  EMPTY_VIEW_DESC: "Do you want to make a new view?",
  CREATE_VIEW: "Create View",
  PRIVATE_VIEW: "Private View",
  TEAM_VIEW: "Team View",
  ASSIGN: "Assign",
  EDIT: "Edit",
  DELETE: "Delete",
  CREATE_TODO: "Log Task Activity",
  CREATE_NEW_VIEW: "Create New View",
  VIEW_NAME: "View Name",
  WRITE_VIEW_NAME: "Write view name",
  SHARED_WITH: "Shared with",
  PRIVATE: "Private",
  MY_TEAM: "My Team",
  PIN_THIS_VIEW: "Pin this View",
  CANCEL: "Cancel",
  CREATE: "Create",
  APPLY: "Apply",
  NAME: "Name",
  NAME_SMALL: "name",
  SENIORITY: "Seniority",
  SENIORITY_SMALL: "seniority",
  JOB_TITLE: "Job Title",
  JOB_TITLE_SMALL: "job_title",
  COMPANY_SIZE: "Company Size",
  COMPANY_NAME: "Company Name",
  EMPLOYEE_NUMBER_SMALL: "number_of_employees",
  INDUSTRY: "Industry",
  INDUSTRY_SMALL: "industry",
  IS_ANY_OF: "Is any of",
  IS_NOT_ANY_OF: "Is not any of",
  IS_KNOWN: "Is known",
  IS_UNKNOWN: "Is unknown",
  EXIST_FILEDS: "exist_fields",
  NOT_EXIST_FIELDS: "not_exist_fields",
  INDEX: "index",
  PEOPLE: "Contact",
  PERSONAL_INFORMATION: "Personal Information",
  CHECK: "check",
  LOCATION: "Location",
  COMPANY_INFO: "Company Info",
  SALES: "Sales",
  SALES_SMALL: "sales",
  COPIED_LABEL: "Copied",
  ADD_FILTERS: "Add Filters",
  CONTACT_INFORMATION: "Contact information",
  MODAL_BODY: "modal_body",
  SEARCH_PROPERTY: "Search property",
  SINGLE_COMPANY: "Company",
  RESET_ALL_PROPERTIES: "Reset all Properties",
  CHOOSE: "Choose which column you see",
  EXPORT_ALL_COLUMNS: "Export All Columns",
  EXPORT_DATA: "export-data",
  EXPORT_SELECT: "Export Selected Columns",
  SAVED_CONTACT: "Saved Contacts",
  SAVED_COMPANIES: "Saved Companies",
  CONTACT_EXPORT: "Contact_export.csv",
  SESSION: "Session expired.",
  COMPANY_EXPORT: "Company_export.csv",
  EXPORT_MODAL_ID: "export_modal",
  COLUMNS_LIST: "columns_list",
  SAVE_AS_CURRENT: "Save Current View",
  SAVE_TEXT:
    "This is a standard view or created by someone else. Save as new view to keep your changes.",
  SAVE_AS_NEW: "Save as new",
  SAVE: "Save",
  ABOUT_CONTACT: "About Contact",
  ABOUT_DEAL: "About Deal",
  ABOUT_COMPANY: "About Company",
  LAST_CONTACTED: "Last Contacted",
  LINKEDIN: "Linkedin",
  TIMEZONE: "Timezone",
  CITY: "City",
  STATE: "State",
  COUNTRY: "Country",
  RESET: "Reset",
  NUMBER_PLACEHOLDER: "Enter phone number",
  ENTER_INPUT_VALUE: "Enter input value",
  CONTACT: "Contact",
  COMPANY: "Company",
  ACTIVE_VIEW_ID: "active_view_id",
  VIEW_ID: "view-id",
  CONTACT_LISTS: "contact_lists",
  STRING: "string",
  LOADING: "Loading...",
  UNPIN_THIS_VIEW: "Unpin This View",
  ALL_COMPANIES: "All Companies",
  ME: "Me",
  PRIVATE_ID: "private",
  ADMIN_USER: "admin-user",
  PIN_VIEW_TO_KEEP_OPEN: "Pin view to keep it open",
  EDIT_VIEW: "Edit this view",
  DELETE_THIS_VIEW: "Delete this view",
  MAKE_DEFAULT_VIEW: "Make Default View",
  TEAM_ID: "team",
  TEAM_LABEL: "My Team",
  PRIVATE_LABEL: "Private",
  REQUIRED: "This is a required field.",
  VIEW_CANT_25CHAR: "View Name can't be more than 50 charecters.",
  HEADER_ID: "head",
  ERROR: "error",
  DEFAULT: "default",
  SHARED: "Shared with",
  UPDATE: "Update",
  DELETE_VIEW: "View Deleted Successfully",
  PINNED_VIEW: "View Pinned Successfully",
  ALL_LEADS: "All Leads",
  NOT: "_not",
  OBJECT: "object",
  EMAIL_VERIFY_DATE: "Email Verification Date",
  LAST_UPDATED_AT: "Last Updated At",
  EMAIL_VERIFICATION: "Email Verification",
  EMAIL_VERIFICATION_SMALL: "email_verification",
  PERSON_CITY: "person_city",
  PERSON_CITY_CAPS: "Contact City",
  PERSON_STATE: "person_state",
  PERSON_COUNTRY: "person_country",
  PERSON_COUNTRY_CAPS: "Contact Country",
  COMPANY_CITY_NOT: "company_city_not",
  COMPANY_COUNTRY_NOT: "company_country_not",
  COMPANY_STATE_NOT: "company_state_not",
  PERSON_CITY_NOT: "person_city_not",
  COMPANY_KEYWORDS_NOT: "company_keywords_not",
  COMPANY_TECHNOLOGIES_NOT: "company_technologies_not",
  PERSON_STATE_CAPS: "Contact State",
  PERSON_STATE_NOT: "person_state_not",
  PERSON_COUNTRY_NOT: "person_country_not",
  COMPANY_STATE_CAPS: "Company State",
  COMPANY_CITY_CAPS: "Company City",
  COMPANY_COUNTRY_CAPS: "Company Country",
  COMPANY_KEYWORDS_CAPS: "Company keywords",
  COMPANY_KEYWORDS_ID: "company_keywords",
  COMPANY_TECHNOLOGIES_CAPS: "Company Technologies",
  COMPANY_DESCRIPTION: "Company Description",
  MIN_REVENUE: "Minimum Revenue",
  KNOWN: "Known",
  UNKNOWN: "UnKnown",
  CLEAR_ALL: "Clear All",
  IMPORT_SMALL: "import",
  IMPORT: "Import",
  TABLE_COLUMNS: "table_columns",
  ENTER_EMAIL: "Enter Email ",
  ENTER_LAST_CONTACTED: "Enter Last Contacted ",
  ENTER_LINKED_URL: "Enter Linkedin Url",
  ENTER_COMPANY_WEBSITE: "Enter Company Website",
  ENTER_STATE: "Enter State",
  ENTER_CITY: "Enter City",
  MY_LIST: "My List",
  ALL: "All",
  CNT_ON_PAGE: "contacts on this page are selected.",
  SELECT_ALL: "Select all",
  SMALL_CONTACTS: "contacts",
  IMPORT_CSV: "Import CSV",
  IMPORT_A_FILE: "Import a File",
  IMPORT_DESC: "You can upload CSV and XLS files to import your inventory",
  MAP_THE_COLUMN: " Map the column headers in your file to ReachIQ Property",
  IMPORT_HEADER_ID: "import_header",
  MATCHED: "Select",
  COLUMN_HEADER: "Column Header",
  PROPERTY_TYPE: "Property Type",
  REACHIQ_PROPERTY: "ReachIQ Property",
  UNMATCHED_COLUMN_WARNING: "Don't import data in unmatched columns",
  ADD_TO_CRM: "Add to CRM",
  SELECT_IMPORT_TYPE: "Select Import Type",
  EMPTY_VALUES: "Empty Values?",
  ARE_YOU_SURE_DELETE:
    "Are you sure you want to delete this? This action cannot be undone.",
  ARE_YOU_SURE_RESET:
    "Are you sure you want to Reset this? This action cannot be undone.",
  ASSIGN_TO: "Assign to",
  COMPANY_NAME_SMALL: "company_name",
  COMPANY_ID_SMALL: "company_id",
  ADD_COMPANY: "Add Company",
  IMPORT_CONTACTS: "Import Contacts",
  SEARCH_FILE_NAME: "Search File Name",
  SUBMIT: "Submit",
  SINGLE_SELECT: "Single Select",
  ADD: "Add",
  CUSTOM_FIELDS: "Custom Fields",
  EMPLOYEES: "Employees",
  FOUNDED: "Founded",
  REVENUE: "Revenue",
  EMPLOYEES_SMALL: "employees",
  FOUNDED_SMALL: "founded",
  REVENUE_SMALL: "revenue",
  LOCATION_SMALL: "location",
  ANNUAL: "Annual",
  TODO: "Todo",
  TODOS: "Todos",
  TODO_SMALL: "todo",
  MEETINGS: "Meetings",
  NOTE_SMALL: "note",
  NOTES: "Notes",
  SEQUENCES: "Sequences",
  SEQUENCES_SMALL: "sequences",
  ATTACHMENTS_SMALL: "attachments",
  CALLS: "Calls",
  CALLS_SMALL: "calls",
  TIMELINE_SMALL: "timeline",
  CALL: "call",
  TASK_LABEL:
    "Stay organized and streamline collaboration with our integrated Task feature.",
  TASK_LABEL1:
    "Log call activities to track discussions and notes for easy reference and streamlined communication.",
  TASK_TITLE: "Title*",
  LOG_TASK: "Log Task Activity",
  DUE_DATE: "Due Date",
  DUE_TIME: "Due Time",
  PRIORITY: "Priority*",
  PRIORITY_FCAPS: "Priority",
  SEND_REMINDER: " Reminder*",
  SEND_REMINDER_CAPS: "Reminder",
  TASKS: "Tasks",
  NOTE: "Note",
  ATTACHMENTS: "Attachments",
  TIMELINE: "Timeline",
  EMAIL_SCREEN_EMPTY_DESC:
    "Send emails to contacts directly or log emails in ReachIQ from your email client for streamlined communication and enhanced productivity.",
  LOG_EMAIL: "Log Email",
  LOG_EMAIL_SMALL: "log_email",
  SEND_EMAIL: "Send Email",
  SEND_EMAIL_SMALL: "send_email",
  CONTACTED: "Contacted",
  CONTACTED_DROPDOWN_ID: "vontacted_dropdown",
  DESCRIPTION: "Description",
  LOG_EMAIL_EDITOR_ID: "logEmailEditor",
  CREATE_CALL: "Log Call",
  ATTACHMENTS_LABEL:
    "Streamline your workflow and enhance collaboration by effortlessly managing attachments within our platform.",
  UPLOAD: "Upload",
  LOGGED_EMAIL: "Logged Email",
  BY: "by",
  TO: "to",
  TO_CAPITAL: "To",
  ALL_EMAILS: "All Emails",
  TIME: "Time",
  ALL_CALLS: "All Calls",
  ALL_TODOS: "All Tasks",
  DATE: "Date",
  PIN: "Pin",
  MARK_AS_COMPLETE: "Mark as complete",
  ADD_COMMENT: "Add Comment",
  COMMENT: "Comment",
  ADD_A_COMMENT: "Add a comment",
  YES: "Yes",
  NO: "No",
  DELETE_COMMENT_HEADER_TILE: "Delete Comment?",
  DELETE_EMAIL_HEADER_TILE: "Delete Email?",
  DELETE_EMAIL_LOG_HEADER_TILE: "Delete Email Log?",
  DELETE_COMMENT_DESC:
    "Are you sure you want to delete this comment? This action cannot be undone.",
  DELETE_MODAL_COMMENT: "delete_comment_modal",
  JUST_NOW: "Just now",
  SMALL_PIN: "pin",
  NOTE_DESC:
    "Stay organized and streamline collaboration with our integrated note-taking feature.",
  CREATE_NOTE: "Create Note",
  UNPIN: "Unpin",
  UNPIN_SMALL: "unpin",
  CREATE_MEETINGS: "Create Meeting",
  MEETINGS_SMALL: "meetings",

  ALL_MEETINGS: "All Meetings",
  MEETING_LABEL:
    "Log meeting activities to track important discussions and notes, ensuring streamlined organization and efficient communication.",
  CC: "Cc",
  BCC: "Bcc",
  FROM: "From",
  EMAIL_SMALL: "email",
  EMAIL: "Email",
  SUBJECT: "Subject",
  BODY: "Body",
  TYPE_SUBJECT: "Type Subject",
  TYPE_TO_SEARCH: "Type to Search",
  SEND_NOW_SMALL: "send_now",
  SEND_NOW: "Send Now",
  SECHEDULE_SEND_SMALL: "schedule_send",
  SCHEDULE_SEND: "Schedule Send",
  SCHEDULE: "Schedule",
  CC_SMALL: "cc",
  BCC_SMALL: "bcc",
  DELETE_SENT_EMAIL_SMALL: "delete_sent_email",
  REPLY_ALL_SMALL: "reply_all",
  REPLY_ALL: "Reply all",
  REPLY_SMALL: "reply",
  REPLY: "Reply",
  FORWARD_SMALL: "forward",
  FORWARD: "Forward",
  ALL_ATTACHMENTS: "All Attachments",
  REPLY_EMAIL: "Reply Email",
  REPLY_EMAIL_SMALL: "reply_email",
  SEND: "Send",
  ATTENDEE: "Attendee",
  ATTENDEE_TIMEZONE: "Attendee Timezone",
  DURATION: "Duration",
  LOG_MEETING: "Log Meeting",
  FIFTEEN_MIN: "15 Minutes",
  THIRTY_MIN: "30 Minutes",
  FORTYFIVE_MIN: "45 Minutes",
  ONE_HOUR: "1 Hour",
  ONE_5_HOUR: "1 Hour 30 Minutes",
  TWO_HOUR: "2 Hour",
  THREE_HOUR: "3 Hour",
  COMPANY_COUNTRY_SMALL: "company_country",
  ADD_NEW_COMPANY_SMALL: "add_new_company",
  CREATE_NEW: "Create New",
  ADD_EXISITING: "Add Exisiting",
  ADD_EXISITING_COMPANY_SMALL: "add_exisiting_company",
  SAVE_CONTACT: "Save Contact",
  SAVE_COMPANY: "Save Company",
  DATE_PICKER: "Date Picker",
  NUMBER: "Number",
  MULTI_LINE_TEXT_INPUT: "Multi-line Text Input",
  RICH_TEXT_EDITOR: "Rich Text Editor",
  MULTIPLE_CHECKBOX: "Multiple Checkbox",
  MULTIPLE_SELECT: "Multiple Select",
  SEARCH_COMPANIES: "Search Companies",
  SEARCH_CONTACTS: "Search Contacts",
  TIMELINE_NOTE: "There are no timeline activities created at the moment.",
  TIMELINE_ID: "timeline",
  WITH: "with",
  MEETING_CREATED: "Meeting Created",
  MEETING_LOGGED: "Meeting Logged ",
  CALL_LOGGED: "Call logged",
  TODO_CREATED: "Task created",
  NO_COMPANY_ADDED: "No Company Added",
  NO_DEAL_ADDED: "No Deal Added",
  NO_CONTACT_ADDED: "No Contact Added",
  DOWNLOAD: "Download",
  COMPOSE_EMAIL: "Compose Email",
  IMAGE_SMALL: "image",
  DOC_SMALL: "doc",
  CREATE_MEETING: "Create Meeting",
  TITLE: "Title",
  CONNECTED_WITH: "Connected with",
  CONNECT: "Connect Now",
  CONNECT_WITH_GOOGLE_CALENDAR: "Connect With Google",
  CONNECT_WITH_OUTLOOK_CALENDAR: "Connect With Outlook",

  CONNECT_GMAIL_ACC:
    "Connect your Gmail Account in order to start scheduling meetings",
  CONNECT_OUTLOOK_ACC:
    "Connect your Outlook Account in order to start scheduling meetings",
  ALL_RESULTS: "All Results",
  VIEW_THREAD: "View Thread",
  UPDATE_COMPANY: "Update Company",
  DELETE_COMPANY_HEADER_TITLE: "Delete Company",
  DELETE_COMPANY_MODAL_SMALL: "delete_company_modal",
  UPDATE_DETAILS: "Update Details",
  ACCOUNTS: "Accounts",
  WARNING: "Warning Message",
  DEALS: "Deals",
  DEALS_SMALL: "deals",
  OUTCOME: "Outcome",
  NEW_COMPANIES: "New companies",
  TIMELINE_COMPANY: "timelineCompany",
  TODO_COMPANY: "todoCompany",
  CALLS_COMPANY: "callsCompany",
  CRM: "CRM",
  ATTACHMENTS_COMPANY: "attachmentsCompany",
  TASKS_SMALL: "tasks",
  COLUMN_LIST: "columns_list",

  LOADINGS: "Loading...",
  DEAL_OWNER: "Deal Owner",
  DEAL_OWNER_SMALL: "deal_owner",
  COMPANY_CRM: "Company",
  ALL_DEALS: "All Deals",
  ASSOCIATED_CONTACT_SMALL: "associated_contact",
  ASSOCIATED_CONTACT: "Associated Contact",

  DEAL_NAME_SMALL: "deal_name",
  TEXT_INPUT: "Text Input",
  LEADS: "Leads",
  MARK_AS_LEAD: "Mark as lead",
  DEAL_NAME: "Deal Name",
  COLUMN_RESET: "Columns Reset successfully",
  RESET_THE_COLUMNS: "Reset the Columns",
  AMOUNT_COLON: "Amount:",
  CLOSE_DATE: "Close Date",
  CLOSE_DATE_COLON: "Close Date:",
  STAGE: "Stage",
  STAGES: "stages",
  VIEW_ALL_PROPERTIES: "View All Properties",
  ABOUT: "About",
  PROPERTIES_MODAL_DESC:
    "These properties will appear when you view information about a deal. These changes will only affect you.",
  ALL_PROPERTIES: "All Properties",
  MANAGE_PROPERTIES: "Manage Properties",
  RESET_ORDER: "Reset Order",
  PIPELINE_NAME: "Pipeline",
  UNDEFINED: "undefined",
  COMPANY_WEBSITE: "Company Website",
  TWITTER: "Twitter",
  FACEBOOK: "Facebook",
  MEETING_NOTES: "Meeting Notes",
  EXPECT_CLOSED_DATE: "Expected Closed Date:",
  PIPELINENAME: "Pipeline Name :",
  STAGE_NAME_COLON: "Stage Name :",
  DISABLED: "disabled",
  USER: "User",
  EMPTY_CONTACTS_SCREEN_HEADING: "Add Contact",
  EMPTY_CONTACTS_SCREEN_DESC:
    "Enhance your lead management capabilities with our CRM platform, equipped with powerful filtering options.",
  EMPTY_COMPANY_SCREEN_HEADING: "Add Company",
  EMPTY_COMPANY_SCREEN_DESC:
    "Enhance your lead management capabilities with our CRM platform, equipped with powerful filtering options.",
  CONTACT_LINKED: "Contact Linkedin Profile",
  MOBILE_NUM: "Mobile Number",
  PIPELINE_NAME_CAPS: "Pipeline Name",
  AMOUNT_CAPS: "Amount",
  ANNUAL_REVENUE: "Annual Revenue",
  MOBILE_NUM_SMALL: "mobile_number",
  DIRECT_PHON_NUM_SMALL: "direct_phone_number",
  ARE_YOU_STILL_CREATE: "Are you still want to Create this meeting?",
  FILE_SIZE_TOO_LARGE: "Max 25 mb mail size is allowed.",
  ALL_MEETING_LIST: "allMeetingList",
  AMOUNT: "Amount",
  STAGE_NAME: "Stage Name",
  PROPERTIES: "properties",
  LABEL_VALUE: "labelValue",
  LABEL: "label",
  EDITOR_ID: "editor",
  PAGE_SMALL: "page",
  SEARCH_SMALL: "search",
  SORT_SMALL: "sort",
  DIRECTION_SMALL: "direction",
  LIMIT_SMALL: "limit",
  CROSS_ICON_LABEL_MODAL: "Task 2/2",
  BULK_ASSIGN: "Bulk assign",
  GLOGO: "logo",
  ENTER_EVENT_TITLE: "Enter event title",
  MESSAGE: "Message",
  ALL_EMAIL_LIST: "allEmailList",
  ALL_NOTE_LIST: "allNoteList",
  ALL_NOTES: "All Notes",
  ALL_TASK_LIST: "allTaskList",
  ALL_TIME_LIST: "allTimelineList",
  CSV_SMALL: "csv",
  PDF_SMALL: "pdf",
  TEXT_SMALL: "txt",
  ZIP_SMALL: "zip",
  IMAGES: "Images",
  PEOPLE_SMALL: "people",
  BOARD: "board",
  DEAL_VIEW_SMALL: "deal_view",
  ADD_DEAL: "Add Deal",
  TOTAL_COLON: "Total:",
  YOU_CAN_UPLOAD: "You can upload CSV or XLS files to import your inventory",
  UPLOADOR: "Upload or Drop a file",
  ASSOCIATED_COMPANY_SMALL: "associated_company",
  EMAIL_SEND_MODAL_EDITOR_ID: "emailSendModalEditor",
  SCHEDULE_FOR: "Schedule for"
};
