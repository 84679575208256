import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NEW_LOGIN_REACHIQ_LOGO } from "../../utils/constants/assets";
import { styles } from "./styles";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import {
  HOME_PATH,
  LOGIN_PATH,
  PEOPLE_SCREEN_PATH,
} from "../../utils/constants/routes";
import {
  confirmPasswordvalidation,
  nameValidation,
  passwordValidation,
} from "../../utils/validations";
import { Anchor, FullScreenLoader, RightsTag } from "../../components";
import { authEndpoints } from "../../utils/constants/httpConstants";
import { useSelector } from "react-redux";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  PRIVACY_POLICY_LINK,
  TERMS_SERVICE_LINK,
} from "../../utils/constants";

import { decryptEmail, filterQuery } from "../../utils/helpers";
import { GET, POST } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import Typography from "../../components/Typography/Typography";
import { STRINGS } from "./strings";
import { GREY } from "../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import InputField from "../../components/InputFields/InputField";
import { Button } from "../../components/Buttons";
import Image from "../../components/Image/Image";
import { verifyDomain } from "../../redux/actions/authActions";

const RegisterScreen = () => {
  const params = useParams();
  const token = params?.id;
  const classes = styles();
  const navigate = useNavigate();
  const checkLoginSelector = useSelector((state) => state.checkLogin);
  const [loading, setLoading] = useState(false);
  let defaultEmail = params?.email;
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);
  const filteredQuery = filterQuery(allFiltersSelector);
  const [email, setEmail] = useState(defaultEmail || "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [validations, setValidations] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    confirm_Password: "",
  });
  const [passwordType, setPasswordType] = useState(STRINGS.password);
  const [confirmpasswordType, setConfirmPasswordType] = useState(
    STRINGS.password
  );
  const [isInvitationValid, setIsInvitationValid] = useState(null);
  const [checking] = useState(true);

  if (defaultEmail) {
    const decryptedEmail = decryptEmail(params?.email?.trim());
    defaultEmail = decryptedEmail;
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validatedfirstName = nameValidation(firstName);
    const validatedLastName = nameValidation(lastName);
    const validatedPassword = passwordValidation(password);
    const validatedConfirmPassword = confirmPasswordvalidation(
      password,
      confirmPassword
    );
    if (
      validatedPassword.isValid &&
      validatedfirstName.isValid &&
      validatedLastName.isValid &&
      validatedConfirmPassword.isValid
    ) {
      //Here trigger the event
      setValidations({
        password: "",
        first_name: "",
        last_name: "",
        confirm_Password: "",
      });
      setLoading(true);

      let formdata = {
        password: password,
        first_name: firstName,
        last_name: lastName,
        password_confirmation: password,
        verification_token: token,
      };

      try {
        const response = await POST(
          authEndpoints.invitedUserRegistration,
          formdata
        );
        if (response?.status === 200) {
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          navigate({
            pathname: HOME_PATH,
            search: createSearchParams({
              ...filteredQuery,
            }).toString(),
          });
        }
      } catch (err) {
        if (err?.data?.data?.errors) {
          setValidations(err?.data?.data?.errors);
        } else {
          EventEmitter.emit(
            EMITTER_ERROR,
            err?.data?.data?.message || err?.data?.message || STRINGS.SOMETHING_WENT_WRONG
          );
        }
        setLoading(false);
        // here error;
      }
    } else {
      setValidations({
        password: validatedPassword.message,
        first_name: validatedfirstName.message,
        last_name: validatedLastName.message,
        confirm_Password: validatedConfirmPassword.message,
      });
    }
  };

  if (checkLoginSelector?.payload) {
    // navigate to people page as user already login.
    navigate({
      pathname: PEOPLE_SCREEN_PATH,
      search: createSearchParams({
        ...filteredQuery,
      }).toString(),
    });
  }

  useEffect(() => {
    if (!isInvitationValid) {
      (async () => {
        try {
          const payload = `?verification_token=${token} `;
          const host = window.location.host;
          const subDomain = host?.trim()?.split(".")[0] + ".reachiq.ai";
          await verifyDomain(subDomain, navigate);
          const response = await GET(authEndpoints.verifyInvitedUser + payload);
          setIsInvitationValid(true);
          setEmail(response?.data?.data?.email);
        } catch (error) {
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
          navigate(LOGIN_PATH);
        }
      })();
    }
  }, [navigate]);


  const toggleConfirmPassword = () => {
    if (confirmpasswordType === STRINGS.password) {
      setConfirmPasswordType(STRINGS.TEXT);
      return;
    }

    setConfirmPasswordType(STRINGS.password);
  };
  const togglePassword = () => {
    if (passwordType === STRINGS.password) {
      setPasswordType(STRINGS.TEXT);
      return;
    }

    setPasswordType(STRINGS.password);
  };

  return !checking ? (
    <FullScreenLoader />
  ) : (
    <Box component="main" className={classes.container}>
      <Box className={classes.leftContainer}>
        <div className={classes.containLogo}>
          <Image src={NEW_LOGIN_REACHIQ_LOGO} className={classes.logo} />
        </div>
        {/* Main auth form here */}
        <Box component="form" autoComplete="chrome-off" onSubmit={handleSubmit}>
          <div className={classes.MainContainers}>
            <Typography
              label={STRINGS.LETS_GET_FAMILIAR}
              color={GREY.PRIMARY}
              size={"dxs"}
              fontWeight={fontWeight.SEMIBOLD}
            />
            <Typography
              label={STRINGS.TO_GET_STARTED}
              color={GREY.TERTIARY}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
            />
          </div>
          <Box className={classes.MainContainer}>
            <Box className={classes.WiDTH}>
              <div className={classes.FOUR}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.FIRST_NAME}
                />
              </div>
              <InputField
                variant={
                  validations.first_name ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations.first_name ? validations.first_name : ""
                }
                placeholder={STRINGS.ENTER_FIRST_NAME}
                size={"md40"}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setValidations({ ...validations, first_name: "" });
                }}
                type="text"
              />
            </Box>

            <Box className={classes.WiDTH}>
              <div className={classes.FOUR}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.LAST_NAME}
                />
              </div>
              <InputField
                variant={
                  validations.last_name ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations.last_name ? validations.last_name : ""
                }
                placeholder={STRINGS.ENTER_LAST_NAME}
                size={"md40"}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setValidations({ ...validations, last_name: "" });
                }}
              />
            </Box>
          </Box>
          <div className={classes.FOUR}>
            <Typography
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.EMAIL}
            />{" "}
          </div>

          <InputField
            errormessage={validations.email ? validations.email : ""}
            placeholder={STRINGS.ENTER_EMAIL}
            size={"md40"}
            variant={"disabled"}
            value={email}
            onChange={(e) => {
              handleEmailChange(e);
              setValidations({ ...validations, email: "" });
            }}
          />
          <Box className={classes.MainContainer}>
            <Box className={classes.WiDTH}>
              <div className={classes.FOUR}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.PASSWORD}
                />
              </div>
              <InputField
                variant={validations.password ? STRINGS.ERROR : STRINGS.DEFAULT}
                errormessage={validations.password ? validations.password : ""}
                placeholder={STRINGS.ENTER_PASSWORD}
                type={passwordType}
                size={"md40"}
                RightIcon={
                  passwordType === STRINGS.password
                    ? ICON_LABELS.VISIBILITY_OFF
                    : ICON_LABELS.VISIBILITY_ON
                }
                rightOnClick={() => togglePassword()}
                value={password}
                onChange={(e) => {
                  handlePasswordChange(e);
                  setValidations({ ...validations, password: "" });
                }}
              />
            </Box>
            <Box className={classes.WiDTH}>
              {" "}
              <div className={classes.FOUR}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.CONFIRM_PASSWORD}
                />
              </div>
              <InputField
                variant={
                  validations.confirm_Password ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations.confirm_Password
                    ? validations.confirm_Password
                    : ""
                }
                placeholder={STRINGS.ENTER_CONFIRM_PASS}
                type={confirmpasswordType}
                size={"md40"}
                RightIcon={
                  confirmpasswordType === STRINGS.password
                    ? ICON_LABELS.VISIBILITY_OFF
                    : ICON_LABELS.VISIBILITY_ON
                }
                rightOnClick={() => toggleConfirmPassword()}
                value={confirmPassword}
                onChange={(e) => {
                  handleConfirmPasswordChange(e);
                  setValidations({ ...validations, confirm_Password: "" });
                }}
              />
            </Box>
          </Box>
          <Box className={classes.containLoginBtn}>
            <Button
              size={"sm36"}
              variant={"primary"}
              label={STRINGS.SIGN_UP}
              isLoading={loading}
            />
          </Box>
          <Box className={classes.flex}>
            <Typography
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.ALREADY_HAVEAN_ACCOUNT}
            />
            <Anchor
              href={LOGIN_PATH}
              className={classes.authToggle}
              target="_blank"
            >
              {STRINGS.SIGNIN}
            </Anchor>
          </Box>
        </Box>
        {/* Main auth form here */}
        <div className={classes.bottomContainer}>
          <div className={classes.containPolicy}>
            <RightsTag className={classes.termsPara} />
            {/* Privacy and Terms. */}
            <Anchor
              href={PRIVACY_POLICY_LINK}
              className={classes.privacyLink}
              target="_blank"
            >
              <Typography
                label={STRINGS.PRIVACY}
                color={GREY.SECONDARY}
                size={"xs"}
                fontWeight={fontWeight.SEMIBOLD}
              />
            </Anchor>
            <div className={classes.TypoHead}>
              <Typography
                label={STRINGS.AND}
                color={GREY.TERTIARY}
                size={"xs"}
                fontWeight={fontWeight.REGULAR}
              />
            </div>
            <Anchor
              href={TERMS_SERVICE_LINK}
              className={classes.privacyLink}
              target="_blank"
            >
              <Typography
                label={STRINGS.TERMS}
                color={GREY.SECONDARY}
                size={"xs"}
                fontWeight={fontWeight.SEMIBOLD}
              />
            </Anchor>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default RegisterScreen;
