import { makeStyles } from "@mui/styles";

export const styles = makeStyles({
  success: {
    backgroundColor: "#EBF7EE !important",
    border: "1px solid #3DC061 !important",
    color: "#1E2635 !important",
    padding: "0 unset !important",
    minWidth: "300px !important",
    paddingLeft: "5px !important",
  },
  error: {
    backgroundColor: "##FCEDEA !important",
    border: "1px solid ##EA4E2C !important",
    color: "#1E2635 !important",
    padding: "0 unset !important",
    minWidth: "300px !important",
    paddingLeft: "5px !important",
  },
});
