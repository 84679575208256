import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { addTagStyleInHtml, decompressString } from "../../../../utils/helpers";
import { STRINGS } from "../strings";
import { fontWeight } from "../../../../utils/constants/UI";
import {  GREY } from "../../../../utils/constants/colors";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import classes from "../markComponents.module.css";

const TodoStep = ({ data }) => {
  const [bodyContent, setBodyContent] = useState("");
  const [isMoreButton, setIsMoreButton] = useState(false);
  const [isMore, setIsMore] = useState(true);
  useEffect(() => {
    let value = data?.is_compressed ? decodeURIComponent(decompressString(data?.notes)) : data?.notes;
    setBodyContent(value?.slice(0, 300));

    if (value?.length > 300) {
      setIsMoreButton(!isMoreButton);
    }
  }, []);
  const bodyContentHandler = () => {
    let value = data?.is_compressed ? decodeURIComponent(decompressString(data?.notes)) : data?.notes;
    isMore
      ? setBodyContent(value?.slice(0, value?.length))
      : setBodyContent(value?.slice(0, 300));
    setIsMore(!isMore);
  };
  return (
    <Box className={classes.stepBody}>
      <Box className={classes.eachStepDetail}>
      <div className={classes.eachStepDetailKey}>
        <TypographyInline 
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={  STRINGS.TITLE2 }
        />
        </div>
        <TypographyInline 
            size={"sm"}
            color={GREY.PRIMARY}
            fontWeight={fontWeight.MEDIUM}
            label={  data?.title }
        />
      </Box>
      <Box >
        <div className={classes.eachStepDetailKey}>
        <TypographyInline 
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={  STRINGS.NOTES2 }
        />
        </div>
        <div className={classes.notesText}>
          {parse(addTagStyleInHtml(bodyContent))}
        </div>
        {isMoreButton && (
          <Box
            className={classes.showMoreBtn}
            onClick={bodyContentHandler}
          >
            {isMore ? STRINGS.VIEW_MORE : STRINGS.VIEW_LESS}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TodoStep;
