import React from "react";

import {
  CampaignContactsScreen,
  CampaignOverviewScreen,
  ListsScreen,
  StepsScreen,
  CampaignSettingsScreen,
  DefaultValuesScreen,
  BlacklistImportScreen,
  ProfileScreen,
  ContactsImportScreen,
} from "../screens";
import CreateEmailTemplate from "../screens/Marketing/components/Template/CreateEmailTemplate";
import CampaignActivityScreen from "../screens/Marketing/CampaignActivityScreen";
import {
  BLACKLIST_IMPORT_PATH,
  COMPANY_SCREEN_PATH,
  CREATE_TEMPLATE_PATH,
  TASK_PATH,
  DASHBOARD_PATH,
  DEFAULT_VALUES_PATH,
  EMAIL_MARKETING_CAMPAIGN_ACTIVITY_PATH,
  EMAIL_MARKETING_CAMPAIGN_CONTACTS_PATH,
  EMAIL_MARKETING_CAMPAIGN_OVERVIEW_PATH,
  EMAIL_MARKETING_CAMPAIGN_PATH,
  EMAIL_MARKETING_CAMPAIGN_SETTINGS_PATH,
  EMAIL_MARKETING_STEPS_PATH,
  IMPORT_PATH,
  LIST_PATH,
  MEETINGSTEPS_PATH,
  MEETING_PATH,
  PEOPLE_SCREEN_PATH,
  PREVIEW_TEMPLATE_PATH,
  PROFILE_PATH,
  SEQUENCE_PATH,
  TEMPLATE_PATH,
  UPDATE_MEETING_PATH,
  UPDATE_TEMPLATE_PATH,
  CHROME_PATH,
  IMPORT_TABLE_CSV,
  CRM_PATH,
  PROPERTIES_PATH,
  FOLDER_LIST_PATH,
  CREATE_TEMPLATE_IN_FOLDER_PATH,
  SEQUENCE_PATH_WITH_PARENT,
  STEPS_WITH_FOLDER_PATH,
  EMAIL_MARKETING_CAMPAIGN_PATH_WITH_PARENT,
  UPDATE_TEMPLATE_IN_FOLDER_PATH,
  PROFILE_SECURITY_PATH,
  CONTACT_DETAIL_SCREEN,
  DASHBOARD_LIST_PATH,
  CONNECT_INBOX_PATH,
  ACCOUNT_PATH,
  DEALS_PATH,
  COMPANY_DETAIL_SCREEN,
  DASHBOARD_PATH_ID,
  DEALS_DETAIL_SCREEN,
  SNIPPET_PATH,
  SNIP_PATH,
  DEALS_BOARD_PATH,
  SNIPPET_FOLDER_PATH,
  MARKETING_PROSPECT_SCREEN_PATH,
} from "../utils/constants/routes";
import ImportScreen from "../screens/CsvEnrichmentScreen/ImportScreen";
import { FOLDER_TEMPLATE_PATH } from "../utils/constants/routes";
import Properties from "../screens/PropertiesScreen/PropertiesScreen";
import Security from "../screens/ProfileScreen/components/Settings/Security";
import DashboardListScreen from "../screens/DashobardScreen/DashboardListScreen";
import ConnectInboxScreen from "../screens/DefaultScreens/ConnectInboxScreen";
import ContactsScreen from "../screens/CRM/Contacts/ContactsScreen";
import ContactDetailScreen from "../screens/CRM/Contacts/ContactDetailScreen";
import CompanyDetailScreen from "../screens/CRM/Account/CompanyDetailScreen";
import DashboardScreen from "../screens/DashobardScreen/DashboardScreen";
import AccountScreen from "../screens/CRM/Account/AccountScreen";
import DealsScreen from "../screens/CRM/Deals/DealsScreen";
import DealsDetailScreen from "../screens/CRM/Deals/DealsDetailScreen";

const PeopleScreen = React.lazy(() => import("../screens/Leads/PeopleScreen"));
// const ImportPeopleScreen = React.lazy(() =>
//   import("../screens/Leads/ImportPeopleScreen")
// );
const CompanyScreen = React.lazy(() =>
  import("../screens/Leads/CompanyScreen")
);

// const DashboardScreen = React.lazy(() =>
//   import("../screens/DashobardScreen/DashboardScreen")
// );
const TaskScreen = React.lazy(() => import("../screens/TaskScreen/TaskScreen"));

const SequenceScreen = React.lazy(() =>
  import("../screens/Marketing/SequenceScreen")
);
const CampaignScreen = React.lazy(() =>
  import("../screens/Marketing/CampaignScreen")
);
const TemplateScreen = React.lazy(() =>
  import("../screens/Marketing/TemplateScreen")
);
const ChromeExtScreen = React.lazy(() =>
  import("../screens/ChromeScreen/ChromeExtension")
);
const MeetingScreen = React.lazy(() =>
  import("../screens/MeetingScreen/Meeting/MeetingScreen")
);
const MeetingCreateSteps = React.lazy(() =>
  import("../screens/MeetingScreen/Meeting/MeetingCreateSteps")
);

const SnippetScreen = React.lazy(() =>
  import("../screens/SnippetScreen/SnippetDetail")
)

export const userRoutes = [
  {
    path: PEOPLE_SCREEN_PATH,
    component: PeopleScreen,
  },
  {
    path: MARKETING_PROSPECT_SCREEN_PATH,
    component: PeopleScreen,
  },
  {
    path: IMPORT_PATH,
    component: ContactsImportScreen,
  },
  {
    path: BLACKLIST_IMPORT_PATH,
    component: BlacklistImportScreen,
  },
  {
    path: CONNECT_INBOX_PATH,
    component: ConnectInboxScreen,
  },

  {
    path: COMPANY_SCREEN_PATH,
    component: CompanyScreen,
  },
  {
    path: DASHBOARD_PATH,
    component: DashboardScreen,
  },
  {
    path: DASHBOARD_PATH_ID,
    component: DashboardScreen,
  },
  {
    path: TASK_PATH,
    component: TaskScreen,
  },
  {
    path: MEETING_PATH,
    component: MeetingScreen,
  },
  {
    path: MEETINGSTEPS_PATH,
    component: MeetingCreateSteps,
  },
  {
    path: UPDATE_MEETING_PATH,
    component: MeetingCreateSteps,
  },
  {
    path: SEQUENCE_PATH,
    component: SequenceScreen,
  },
  {
    path: SEQUENCE_PATH_WITH_PARENT,
    component: SequenceScreen,
  },
  {
    path: EMAIL_MARKETING_STEPS_PATH,
    component: StepsScreen,
  },
  {
    path: STEPS_WITH_FOLDER_PATH,
    component: StepsScreen,
  },
  {
    path: EMAIL_MARKETING_CAMPAIGN_PATH,
    component: CampaignScreen,
  },
  {
    path: EMAIL_MARKETING_CAMPAIGN_PATH_WITH_PARENT,
    component: CampaignScreen,
  },
  {
    path: EMAIL_MARKETING_CAMPAIGN_OVERVIEW_PATH,
    component: CampaignOverviewScreen,
  },
  {
    path: EMAIL_MARKETING_CAMPAIGN_CONTACTS_PATH,
    component: CampaignContactsScreen,
  },
  {
    path: EMAIL_MARKETING_CAMPAIGN_ACTIVITY_PATH,
    component: CampaignActivityScreen,
  },
  {
    path: EMAIL_MARKETING_CAMPAIGN_SETTINGS_PATH,
    component: CampaignSettingsScreen,
  },
  {
    path: LIST_PATH,
    component: ListsScreen,
  },
  {
    path: PROFILE_PATH,
    component: ProfileScreen,
  },
  {
    path: PROFILE_SECURITY_PATH,
    component: Security,
  },
  {
    path: TEMPLATE_PATH,
    component: TemplateScreen,
  },

  {
    path: CREATE_TEMPLATE_PATH,
    component: CreateEmailTemplate,
  },
  {
    path: CREATE_TEMPLATE_IN_FOLDER_PATH,
    component: CreateEmailTemplate,
  },
  {
    path: UPDATE_TEMPLATE_IN_FOLDER_PATH,
    component: CreateEmailTemplate,
  },
  {
    path: UPDATE_TEMPLATE_PATH,
    component: CreateEmailTemplate,
  },
  {
    path: PREVIEW_TEMPLATE_PATH,
    component: CreateEmailTemplate,
  },
  {
    path: DASHBOARD_LIST_PATH,
    component: DashboardListScreen,
  },
  {
    path: DEFAULT_VALUES_PATH,
    component: DefaultValuesScreen,
  },
  {
    path: FOLDER_TEMPLATE_PATH,
    component: TemplateScreen,
  },
  {
    path: FOLDER_LIST_PATH,
    component: ListsScreen,
  },

  {
    path: CHROME_PATH,
    component: ChromeExtScreen,
  },
  {
    path: IMPORT_TABLE_CSV,
    component: ImportScreen,
  },
  {
    path: CRM_PATH,
    component: ContactsScreen,
  },
  {
    path: DEALS_PATH,
    component: DealsScreen,
  },
  {
    path: DEALS_BOARD_PATH,
    component: DealsScreen,
  },
  {
    path: PROPERTIES_PATH,
    component: Properties,
  },
  {
    path: CONTACT_DETAIL_SCREEN,
    component: ContactDetailScreen,
  },
  {
    path: ACCOUNT_PATH,
    component: AccountScreen,
  },
  {
    path: COMPANY_DETAIL_SCREEN,
    component: CompanyDetailScreen,
  },
  {
    path: DEALS_DETAIL_SCREEN,
    component: DealsDetailScreen,
  },
  {
    path: SNIPPET_PATH,
    component: SnippetScreen
  },
  {
    path: SNIPPET_FOLDER_PATH,
    component: SnippetScreen
  },
];
