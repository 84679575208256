import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Styles } from "../../Styles";
import { STATS_KEY_VALUES } from "../../../../utils/constants";

export const D3LineChart = (props) => {
  const d3Chart = useRef(null);
  const classes = Styles();
  const { index, dataSet, height, width } = props;
  const widgetCoordinatesSelector = useSelector(
    (state) => state.getWidgetCoordinates
  );

  const totalWidth = (document.documentElement.clientWidth / 100) * 93.5;

  function finalUpperLimit(number) {
    const remainder = number % 100;
    if (remainder === 0) {
      return number;
    } else {
      const nearestMultiple = number + (100 - remainder);
      return nearestMultiple;
    }
  }

  function lineChart(width, height) {
    try {
      dataSet.forEach((d, i) => {
        d.arr.sort((a, b) => new Date(a.x) - new Date(b.x));
      });

      let margin = { top: 40, right: 40, bottom: 30, left: 40 };
      const w = width - margin.left - margin.right;
      const h = height - margin.top - margin.bottom;
      const svg = d3
        .select(d3Chart.current)
        .attr("width", w + margin.left + margin.right)
        .attr("height", h + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .style("backgroud", "#d3d3d3")
        .style("margin-top", "50")
        .style("overflow", "visible"); // for axis(outside) visible

      let maxIndex = 0;
      let maxLen = 0;
      let xArr = [];
      let yArr = [];

      dataSet.forEach((data, i) => {
        if (data.arr.length > maxLen) {
          maxLen = data.arr.length;
          maxIndex = i;
        }
        data.arr.forEach((el) => {
          yArr.push(el.y);
        });
      });

      dataSet[maxIndex].arr.forEach((d, i) => {
        xArr.push(i);
      });

      let yMaxValue = Math.max(...yArr);

      let upperlimit = yMaxValue + 10 - (yMaxValue % 10);

      let final_upper_limit = finalUpperLimit(upperlimit);

      const yScale = d3.scaleLinear().domain([0, final_upper_limit]).range([h, 0]);
      const xScaleL = d3
        .scaleLinear()
        .domain([0, maxLen - 1])
        .range([0, w]);

      const yAxisGrid = d3
        .axisLeft(yScale)
        .tickSize(-w)
        .tickFormat("")
        .ticks(7);
      // .ticks(parseInt(yMaxValue / 10 + 1));

      svg
        .append("g")
        .attr("class", "y axis-grid")
        .attr("stroke", "#D3DAE3")
        .attr("stroke-width", "1")
        .attr("opacity", ".2")
        .call(yAxisGrid);

      const generateLine = d3
        .line()
        .y(function (d) {
          return yScale(d.y);
        })
        .defined(function (d) {
          return d.y !== null;
        }) // Omit empty values.
        .x(function (d, i) {
          return xScaleL(i);
        });

      let xTick = [];
      dataSet[maxIndex].arr.forEach((temp) => {
        let month = new Date(Object.values(temp)[0]).getMonth() + 1;
        let date = new Date(Object.values(temp)[0]).getDate();
        let mainDate = date + "/" + month;
        xTick.push(mainDate);
      });

      let xTickLength = xTick.length;

      const xAxis = d3
        .axisBottom(xScaleL)
        .ticks(xTick.length)
        .tickFormat((d, i) => {
          if (xTickLength > 30) {
            if (width > totalWidth * 0.66) {
              if (i % 7 === 0) {
                return xTick[i];
              }
            } else if (
              width > totalWidth * 0.33 &&
              width <= totalWidth * 0.66
            ) {
              if (i % 3 === 0) {
                return xTick[i];
              }
            } else if (width > 0 && width <= totalWidth * 0.33) {
              if (i % 4 === 0) {
                return xTick[i];
              }
            }
          } else if (xTickLength < 30 && xTickLength > 16) {
            if (width > totalWidth * 0.66) {
              if (i % 2 === 0) {
                return xTick[i];
              }
            } else if (
              width > totalWidth * 0.33 &&
              width <= totalWidth * 0.66
            ) {
              if (i % 3 === 0) {
                return xTick[i];
              }
            } else if (width > 0 && width <= totalWidth * 0.33) {
              if (i % 4 === 0) {
                return xTick[i];
              }
            }
          } else if (xTickLength < 15 && xTickLength > 8) {
            if (width > totalWidth * 0.66) {
              if (i % 2 === 0) {
                return xTick[i];
              }
            } else if (
              width > totalWidth * 0.33 &&
              width <= totalWidth * 0.66
            ) {
              if (i % 3 === 0) {
                return xTick[i];
              }
            } else if (width > 0 && width <= totalWidth * 0.33) {
              if (i % 4 === 0) {
                return xTick[i];
              }
            }
          } else {
            return xTick[i];
          }
        })
        .tickSize(0)
        .tickPadding([10]);

      const yAxis = d3
        .axisLeft(yScale)
        .ticks(7)
        .tickSize(0)
        .tickPadding([6]);
      // .ticks(parseInt(yMaxValue / 10 + 1))

      let tooltip = d3
        .select(props.activeTooltip)
        .append("div")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("width", "142px")
        .style("height", "auto")
        .style("border-radius", "6px")
        .style("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.08)")
        .style("border", "1px solid #D3DAE3")
        .style("background-color", "white")
        .style("visibility", "hidden");

      const mainArr = [];

      dataSet.forEach((d, i) => {
        let id = d.id;
        let color = d.color;
        d.arr.forEach((e, j) => {
          let obj = { id, color, ...e };
          mainArr.push(obj);
        });
      });
      let grp = d3.groups(mainArr, (d) => d.x);

      dataSet.forEach((tempData, i) => {
        svg
          .selectAll(".line")
          .data([tempData.arr])
          .join("path")
          .attr("d", (d) => generateLine(d))
          .attr("fill", "none")
          .attr("stroke", tempData.color)
          .attr("stroke-width", 2);

        svg
          .append("g")
          .selectAll("dot")
          .data(tempData.arr)
          .enter()
          .append("circle")
          .attr("cx", function (d, i) {
            return xScaleL(i);
          })
          .attr("cy", function (d) {
            return yScale(d.y);
          })
          .attr("r", 8)
          .style("fill", "transparent")
          .on("mouseover", function () {
            tooltip.style("visibility", "visible");
          })
          .on("mousemove", function (event, d) {
            let idx;
            grp.forEach((temp, i) => {
              if (d.x === grp[i][0]) {
                idx = i;
              }
            });

            tooltip.html(
              `<div class=${classes.widgetToolTip} >` +
              `<div class=${classes.widgetToolTipTopVBar1} >` +
              props?.ownerName +
              "</div>" +
              `<div class=${classes.widgetToolTipTopVBar2} >` +
              dataFormat(grp[idx][0]) +
              "</div>" +
              `<div class=${classes.widgetToolTipBottomVBar} >` +
              toolTipMaker(grp[idx][1], tempData.id) +
              "</div>" +
              "</div>"
            );

            let x, y;
            let maxHeight = (document.documentElement.clientHeight / 100) * 90;
            let maxWidth = (document.documentElement.clientWidth / 100) * 90;
            if (event.pageY > maxHeight) {
              y = 80;
            } else {
              y = 10;
            }

            if (event.pageX > maxWidth) {
              x = 150;
            } else {
              x = -10;
            }

            tooltip
              .style("top", event.pageY - y + "px")
              .style("left", event.pageX - x + "px");
          })
          .on("mouseout", function () {
            tooltip.style("visibility", "hidden");
          });
      });

      svg.append("g").call(xAxis).attr("transform", `translate(0,${h})`);
      svg.append("g").call(yAxis);

      svg.selectAll(".domain").attr("stroke", "#D3DAE3");
    } catch (error) { }
  }

  const toolTipMaker = (data, tempId) => {
    let str = "";
    data.forEach((d, i) => {
      str += "<div>";
      str += `<div class=${classes.widgetToolTipContent}>`;
      if (d.id === tempId) {
        str += `<div class=${classes.widgetToolTipContent4}>`;
      } else {
        str += `<div class=${classes.widgetToolTipContent2}>`;
      }
      str += `<div class=${classes.widgetToolTipCircle} style="background-color:${d.color}"></div>`;
      str += `${STATS_KEY_VALUES[d.id]}`;
      str += `</div>`;
      str += `<div class=${classes.widgetToolTipContent3}>`;
      str += `${d.y}`;
      str += `</div>`;
      str += `</div >`;
      str += `</div >`;
    });
    return str;
  };

  const dataFormat = (date) => {
    const tempDate = new Date(date);
    const yyyy = tempDate.getFullYear();
    let mm = tempDate.getMonth() + 1;
    let dd = tempDate.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = dd + "/" + mm + "/" + yyyy;
    return formattedToday;
  };

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;

    const resizeHeight = widgetCoordinatesSelector?.payload?.h;
    const resizeWidth = widgetCoordinatesSelector?.payload?.w;
    const resizeIndex = widgetCoordinatesSelector?.payload?.i;

    if (
      (resizeWidth < documentWidth || resizeHeight < documentHeight) &&
      resizeIndex == index
    ) {
      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        d3.select(d3Chart.current).selectAll("*").remove();
        lineChart(resizeWidth * 0.93, resizeHeight * 0.651);
      }, 50);
    }
  }, [widgetCoordinatesSelector]);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;
    if (width < documentWidth && height < documentHeight) {
      d3.select(d3Chart.current).selectAll("*").remove();
      lineChart(width * 0.93, height * 0.93);
    }
  }, [dataSet]);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;
    if (width < documentWidth && height < documentHeight) {
      d3.select(d3Chart.current).selectAll("*").remove();
      lineChart(width * 0.93, height * 0.93);
    }
    return () => {
      d3.select(props.activeTooltip).selectAll("*").remove();
    };
  }, []);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {dataSet.length > 0 ? <svg ref={d3Chart}></svg> : null}
    </Box>
  );
};
