import {
  PAGINATION_FAIL,
  PAGINATION_SUCCESS,
} from "../constants/customTableContants";

export const paginationDataAction = (sentQuery) => async (dispatch) => {
  try {
    dispatch({
      type: PAGINATION_SUCCESS,
      payload: sentQuery,
    });
  } catch (err) {
    dispatch({ type: PAGINATION_FAIL, payload: err });
  }
};
