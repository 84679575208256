import { useEffect, useState } from "react";
import { Styles } from "../Styles";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import RadioButton from "../../../components/RadioButton/RadioButton";
import InputField from "../../../components/InputFields/InputField";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const CloneDashboardPopUp = (props) => {
  const classes = Styles();
  const [selectedValue, setSelectedValue] = useState();
  const [dashboardName, setDashboardName] = useState();

  useEffect(() => {
    setDashboardName("Copy of - " + props.dashBoard?.dashboard_name || "");
    setSelectedValue(props?.dashBoard?.dashboard_type === "team" ? "public-read" : props?.dashBoard?.dashboard_type || "private");
  }, [props?.dashBoard]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const cancelHandle = () => {
    props.handleClonePopUp();
  };

  const updateHandle = () => {
    let payload = {
      dashboard_name: dashboardName,
      dashboard_id: props.dashBoard._id,
      dashboard_type: selectedValue,
    };
    props.cloneDashboard(payload, props.handleClonePopUp);
  };

  const modalHeaderData = {
    heading: {
      id: "clone_dashboard",
      label: STRINGS.CLONE_DASHBOARD,
    },
  };

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.APPLY}
        size={"sm36"}
        variant={"primary"}
        onClick={updateHandle}
        isLoading={props.loading}
      />
    </div>
  );

  const radioButtonData = [
    {
      id: "private",
      label: STRINGS.PRIVATE_LABEL,
    },
    { id: "public-read", label: STRINGS.EVERYONE_LABEL },
  ];

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
    >
      <form onSubmit={updateHandle} className={classes.modalBodyContainer}>
        <div
          className={classes.modalFieldLabelContainer}
        >
          <TypographyInline
            label={STRINGS.DASHBOARD_NAME}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
            isRequired
          />
          <InputField
            size={"sm36"}
            value={dashboardName}
            errormessage={props.createErr.length !== 0 ? props.createErr : ""}
            variant={props.createErr.length ? "error" : "default"}
            onChange={(e) => {
              if (e?.target?.value?.length <= 50) {
                setDashboardName(e.target.value);
              }
            }}
            placeholder={STRINGS.PLACEHOLDER_CREATE_DASHBOARD}
          />
        </div>
        <div
          className={classes.permissionsContainer}
        >
          <TypographyInline
            label={`${STRINGS.PERMISSIONS_LABEL}*`}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
          />
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </div>
      </form>
    </CustomModal>
  );
};
