import React from "react";
import classes from "./CrmComp.module.css";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";

const DeleteCommentModal = ({
  clickedData,
  deleteButtonLoading = false,
  deleteAPIHandler = () => { },
  open = false,
  close = () => { },
}) => {
  const deleteModalHeader = {
    heading: {
      id: STRINGS.DELETE_MODAL_COMMENT,
      label: STRINGS.DELETE_COMMENT_HEADER_TILE,
    },
  };

  const deleteModalRightFooter = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={close}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={() => {
          deleteAPIHandler(clickedData);
        }}
        isLoading={deleteButtonLoading}
      />
    </div>
  );
  const deleteModalLeftFooter = <div></div>;

  return (
    <CustomModal
      variant={"error"}
      close={close}
      open={open}
      header={deleteModalHeader}
      footer={{
        left: deleteModalLeftFooter,
        right: deleteModalRightFooter,
      }}
    >
      <div className={classes.modalBody}>
        <TypographyInline
          size={"sm"}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.DELETE_COMMENT_DESC}
        />
      </div>
    </CustomModal>
  );
};

export default DeleteCommentModal;
