import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import AddFiltermodal from "./AddFilterModal";
import { CustomAutoComplete } from "../../../components/Autocomplete";
import { leadsEndpoints } from "../../../utils/constants/httpConstants";
import { useSelector, useDispatch } from "react-redux";
import {
  allFilters,
  filterHeaderSearchCompany,
  filterHeaderSearchIndustry,
  filterHeaderSearchJobTitle,
  filterHeaderSearchSeniority,
} from "../../../redux/actions/filterActions";
import { tableDataFetch } from "../../../redux/actions/tableActions";
import { filterSelectedIndexes, getUniqueListBy } from "../../../utils/helpers";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  filterHeaderIndustry,
  filterHeaderJobTitle,
} from "../../../redux/store/storeConstants";
import * as qs from "qs";

import { filterKeys } from "../../../utils/constants/filters";
import { HandleClearAllFilterFromStore } from "../../../redux/store/storeHelper";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { STRINGS } from "../strings";
import { GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, SHADOW, fontWeight } from "../../../utils/constants/UI";
import FilterTab from "../../../components/FilterTab/FilterTab";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import { Button } from "../../../components/Buttons";
import InputField from "../../../components/InputFields/InputField";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";
import classes from "./LeadsComponent.module.css";
import { paginationDataAction } from "../../../redux/actions/customTableActions";

//Component starts here.
const FilterStrip = ({
  isOpenFromOutside = false,
  setOpenFromOutside,
  clearSort,
  clearSearch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const queryObjectLength = Object.keys(queryObject || {}).length;
  const seniorityHeaderSelector = useSelector(
    (state) => state?.filterHeaderSeniority?.payload
  );
  const companyHeaderSelector = useSelector(
    (state) => state?.filterHeaderCompany?.payload
  );
  const selectedJobFilter = useSelector(
    (state) => state?.filterHeaderJobTitle?.payload
  );
  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);
  const selectedIndustryFilter = useSelector(
    (state) => state.filterHeaderIndustry?.payload
  );
  const tableEndpointChangeSelector = useSelector(
    (state) => state?.tableEndpointChange
  );

  // console.log("SLCT", selectedJobFilter);
  const [selectedFilter, setSelectedFilter] = useState(-1);
  const [name, setName] = useState("");
  const [selectedId, setSelectedId] = useState(false);
  const [filterTabEnchor, setFilterTabEnchor] = useState(null);

  const handleFilterSelection = (filter) => {
    setSelectedFilter(filter.id);
  };

  useEffect(() => {
    if (queryObject?.name) setName(queryObject.name || "");
  }, [queryObject.name]);

  const handleClearAllFilter = async () => {
    setName("");
    clearSort();
    clearSearch();
    // for company name filter
    HandleClearAllFilterFromStore(dispatch, location, navigate);
    dispatch(paginationDataAction({}));
    dispatch(tableDataFetch({}, navigate));
    if (tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT) {
      dispatch(tableDataFetch({}, navigate));
    }
  };

  const handleExistFieldsJobTitle = (
    existing_type,
    filter,
    filterKey,
    notFilterKey
  ) => {
    // existing_type = isKnown/ isUnknown
    // filter = job_title/industry/ seniority..
    let payload = {
      ...filterHeaderJobTitle?.payload,
      [existing_type]: !selectedJobFilter[existing_type],
    };
    dispatch(filterHeaderSearchJobTitle(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + STRINGS.NOT]: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  const handleExistFieldsIndustry = (
    existing_type,
    filter,
    queryInsertKey,
    notFilterKey
  ) => {
    // STRINGS.IS_KNOWN,
    // STRINGS.INDUSTRY,
    // STRINGS.EXIST_FILEDS,
    // STRINGS.NOT_EXIST_FIELDS
    // existing_type = isKnown/ isUnknown
    // filter = job_title/industry/ seniority..

    let payload = {
      ...filterHeaderIndustry?.payload,
      [existing_type]: !selectedIndustryFilter[existing_type],
    };

    dispatch(filterHeaderSearchIndustry(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [queryInsertKey]: [...allFiltersSelector?.[queryInsertKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + STRINGS.NOT]: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [queryInsertKey]: [...allFiltersSelector?.[queryInsertKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  const handleJobTitleChange = (item, filter) => {
    let payload = {
      ...selectedJobFilter,
      [item]: !selectedJobFilter[item],
      isKnown: false,
      isUnknown: false,
    };

    // clear data if column is empty.
    const unCheckPayload = {};
    if (!payload?.anyOf) {
      unCheckPayload[filter] = [];
    }
    if (!payload?.notAnyOf) {
      unCheckPayload[filter + STRINGS.NOT] = [];
    }
    // clear data if column is empty.

    dispatch(
      allFilters({
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });

    dispatch(filterHeaderSearchJobTitle(payload));
  };

  const handleIndustryChange = (item, filter) => {
    let payload = {
      ...selectedIndustryFilter,
      [item]: !selectedIndustryFilter[item],
      isKnown: false,
      isUnknown: false,
    };

    // clear data if column is empty.
    const unCheckPayload = {};
    if (!payload?.anyOf) {
      unCheckPayload[filter] = [];
    }
    if (!payload?.notAnyOf) {
      unCheckPayload[filter + STRINGS.NOT] = [];
    }
    // clear data if column is empty.

    dispatch(
      allFilters({
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );

    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });

    dispatch(filterHeaderSearchIndustry(payload));
  };

  const handleCompanyChange = (item) => {
    item.isChecked = !item.isChecked;
    const result = getUniqueListBy(
      [...companyHeaderSelector, item],
      STRINGS.INDEX
    );
    dispatch(filterHeaderSearchCompany(result));
    const new_employee_number = filterSelectedIndexes(result, STRINGS.INDEX);
    const resultant = [...new_employee_number];
    const payload = [...new Set(resultant)];
    const queryPayload = { employee_number: payload };
    dispatch(allFilters(queryPayload));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        employee_number: payload,
      }).toString(),
    });
  };

  const handleSeniorityChange = (item) => {
    item.isChecked = !item.isChecked;

    const result = getUniqueListBy(
      [...seniorityHeaderSelector, item],
      STRINGS.TYPE
    );
    dispatch(filterHeaderSearchSeniority(result));
    const seniority = filterSelectedIndexes(result, STRINGS.TYPE);
    const payload = [...new Set(seniority)];
    const queryPayload = { seniority: payload };
    dispatch(allFilters(queryPayload));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        seniority: payload,
      }).toString(),
    });
  };

  const handleNameSubmit = (e) => {
    e.preventDefault();
    dispatch(allFilters({ name: name || "" }));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        name: name,
      }).toString(),
    });
  };

  const SenorityTooltip = (
    <div className={classes.seniorityContainer}>
      {seniorityHeaderSelector?.map((item) => {
        return (
          <div
            key={item.type}
            className={classes.containCheckbox}
            onClick={() => handleSeniorityChange(item)}
          >
            <Checkbox
              onClick={() => handleSeniorityChange(item)}
              checked={item.isChecked}
              label={item.label}
            />
          </div>
        );
      })}
    </div>
  );

  const companyTooltip = (
    <div className={classes.companyContainer}>
      {companyHeaderSelector?.map((item) => {
        return (
          <div key={item.type} className={classes.containCompanyCheckbox}>
            <Checkbox
              checked={item.isChecked}
              onClick={() => handleCompanyChange(item)}
              label={item.type}
            />
          </div>
        );
      })}
    </div>
  );

  // Job title tooltip

  const jobTitleTooltip = (
    <Box className={classes.filter}>
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={
            selectedJobFilter?.anyOf == undefined
              ? selectedJobFilter?.payload?.anyOf
              : selectedJobFilter?.anyOf
          }
          onClick={() =>
            handleJobTitleChange(STRINGS.ANY_OF, STRINGS.JOB_TITLE_SMALL)
          }
          onKeyDown={(e) => e.stopPropagation()}
          label={STRINGS.IS_ANY_OF}
        />
      </div>

      {/* input box here for searching */}

      {selectedJobFilter?.anyOf ? (
        <div className={classes.pad8}>
          <CustomAutoComplete
            endPoint={leadsEndpoints.getJobFilters}
            filter={filterKeys.job_title}
            type=""
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={
            selectedJobFilter?.notAnyOf == undefined
              ? selectedJobFilter?.payload?.notAnyOf
              : selectedJobFilter?.notAnyOf
          }
          onClick={() =>
            handleJobTitleChange(STRINGS.NOT_ANY_OF, filterKeys.job_title)
          }
          label={STRINGS.IS_NOT_ANY_OF}
        />
      </div>
      {/* input box here for searching */}

      {selectedJobFilter?.notAnyOf ? (
        <div className={classes.pad8}>
          <CustomAutoComplete
            endPoint={leadsEndpoints.getJobFilters}
            filter={filterKeys.job_title}
            type={STRINGS.NOT}
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={
            selectedJobFilter?.isKnown == undefined
              ? selectedJobFilter?.payload?.isKnown
              : selectedJobFilter?.isKnown
          }
          onClick={() =>
            handleExistFieldsJobTitle(
              STRINGS.IS_KNOWN,
              filterKeys.job_title,
              STRINGS.EXIST_FILEDS,
              STRINGS.NOT_EXIST_FIELDS
            )
          }
          label={STRINGS.IS_KNOWN_LABEL}
        />
      </div>
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={
            selectedJobFilter?.isUnknown == undefined
              ? selectedJobFilter?.payload?.isUnknown
              : selectedJobFilter?.isUnknown
          }
          onClick={() =>
            handleExistFieldsJobTitle(
              STRINGS.IS_UNKNOWN,
              filterKeys.job_title,
              STRINGS.NOT_EXIST_FIELDS,
              STRINGS.EXIST_FILEDS
            )
          }
          label={STRINGS.IS_UNKNOWN_LABEL}
        />
      </div>
    </Box>
  );

  const industryTooltip = (
    <Box className={classes.filter}>
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={
            selectedIndustryFilter?.anyOf == undefined
              ? selectedIndustryFilter?.payload?.anyOf
              : selectedIndustryFilter?.anyOf
          }
          onClick={() =>
            handleIndustryChange(STRINGS.ANY_OF, filterKeys.industry)
          }
          label={STRINGS.IS_ANY_OF}
        />
      </div>
      {selectedIndustryFilter.anyOf ? (
        <div className={classes.pad8}>
          <CustomAutoComplete
            endPoint={leadsEndpoints.getIndustryFilters}
            filter={filterKeys.industry}
            type=""
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={
            selectedIndustryFilter?.notAnyOf == undefined
              ? selectedIndustryFilter?.payload?.notAnyOf
              : selectedIndustryFilter?.notAnyOf
          }
          onClick={() =>
            handleIndustryChange(STRINGS.NOT_ANY_OF, filterKeys.industry)
          }
          label={STRINGS.IS_NOT_ANY_OF}
        />
      </div>
      {selectedIndustryFilter.notAnyOf ? (
        <div className={classes.pad8}>
          <CustomAutoComplete
            endPoint={leadsEndpoints.getIndustryFilters}
            filter={filterKeys.industry}
            type={STRINGS.NOT}
          />
        </div>
      ) : null}
      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={
            selectedIndustryFilter?.isKnown == undefined
              ? selectedIndustryFilter?.payload?.isKnown
              : selectedIndustryFilter?.isKnown
          }
          onClick={() =>
            handleExistFieldsIndustry(
              STRINGS.IS_KNOWN,
              filterKeys.industry,
              STRINGS.EXIST_FILEDS,
              STRINGS.NOT_EXIST_FIELDS
            )
          }
          label={STRINGS.IS_KNOWN_LABEL}
        />
      </div>

      <div className={classes.jobTitleHead}>
        <Checkbox
          checked={
            selectedIndustryFilter?.isUnknown == undefined
              ? selectedIndustryFilter?.payload?.isUnknown
              : selectedIndustryFilter?.isUnknown
          }
          onClick={() =>
            handleExistFieldsIndustry(
              STRINGS.IS_UNKNOWN,
              filterKeys.industry,
              STRINGS.NOT_EXIST_FIELDS,
              STRINGS.EXIST_FILEDS
            )
          }
          label={STRINGS.IS_UNKNOWN_LABEL}
        />
      </div>
    </Box>
  );

  const nameTooltip = (
    <Box
      className={classes.nameToolTipContainer}
      component={STRINGS.FORM}
      onSubmit={handleNameSubmit}
    >
      <InputField
        size={"sm36"}
        type="text"
        onKeyDown={(e) => e.stopPropagation()}
        placeholder={STRINGS.ENTER_NAME}
        value={name}
        onChange={(e) => { setName(e.target.value); }}
      />
    </Box>
  );

  const FILTERS_LIST = [
    {
      filterName: STRINGS.NAME,
      id: 0,
      toolTip: nameTooltip,
      filter_id: STRINGS.NAME_SMALL,
    },
    {
      filterName: STRINGS.SENIORITY,
      id: 3,
      toolTip: SenorityTooltip,
      filter_id: STRINGS.SENIORITY_SMALL,
    },
    {
      filterName: STRINGS.JOB_TITLE,
      id: 2,
      toolTip: jobTitleTooltip,
      filter_id: STRINGS.JOB_TITLE_SMALL,
    },
    {
      filterName: STRINGS.COMPANY_SIZE,
      id: 4,
      toolTip: companyTooltip,
      filter_id: STRINGS.EMPLOYEE_NUMBER,
    },
    {
      filterName: STRINGS.INDUSTRY_CAPS,
      id: 5,
      toolTip: industryTooltip,
      filter_id: STRINGS.INDUSTRY,
    },
  ];

  const getAppliedFilterCount = (values, filterType) => {
    if (
      values?.exist_fields.indexOf(filterType) !== -1 ||
      values?.not_exist_fields.indexOf(filterType) !== -1
    ) {
      return 1;
    }

    if (
      filterType === STRINGS.JOB_TITLE_SMALL ||
      filterType === STRINGS.INDUSTRY
    ) {
      return (
        values?.[filterType]?.length +
        values?.[filterType + STRINGS.NOT]?.length
      );
    }

    if (typeof values?.[filterType] === STRINGS.OBJECT) {
      return values?.[filterType]?.length;
    }
    if (filterType === STRINGS.NAME_SMALL) {
      return values?.[filterType]?.length ? 1 : 0;
    } else {
      return 1;
    }
  };

  return (
    <Box component={STRINGS.MAIN} className={classes.container1}>
      <Box className={classes.stripContainer}>
        <Box className={classes.filtersContainer}>
          <div className={classes.filterLabel}>
            <TypographyInline
              color={GREY.SECONDARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={`${STRINGS.FILTER}:`}
              size={"sm"}
            />
          </div>
          {FILTERS_LIST?.map((filter) => {
            return (
              <div key={filter?.id}>
                <FilterTab
                  isActive={filter?.id?.toString() === selectedId}
                  label={filter?.filterName}
                  rightIcon={ICON_LABELS.ACTION_ARROW}
                  id={filter?.id}
                  key={filter?.filter_id}
                  onClick={(e) => {
                    setFilterTabEnchor(e?.currentTarget);
                    handleFilterSelection(filter);
                    setSelectedId(e?.currentTarget?.id);
                  }}
                  selectedValue={
                    parseInt(
                      getAppliedFilterCount(
                        allFiltersSelector,
                        filter.filter_id
                      )
                    )
                      ? getAppliedFilterCount(
                        allFiltersSelector,
                        filter.filter_id
                      )
                      : ""
                  }
                />
                <CustomStyledMenu
                  anchorEl={filterTabEnchor}
                  open={
                    selectedFilter === filter.id && Boolean(filterTabEnchor)
                  }
                  arrow
                  onClose={() => {
                    setFilterTabEnchor(null);
                    setSelectedFilter(-1);
                    setSelectedId(-1);
                  }}
                  isRightAlign={true}
                  noMargin={true}
                  shadow={SHADOW.md}
                  marginTop={"11px !important"}
                >
                  {filter.toolTip}
                </CustomStyledMenu>
              </div>
            );
          })}

          {queryObjectLength > 0 ? (
            <div className={classes.addFilter2}>
              <Button
                size={"xs28"}
                LeftIcon={ICON_LABELS.CLOSE}
                label={STRINGS.CLEAR_ALL}
                variant={"text"}
                onClick={handleClearAllFilter}
              />
            </div>
          ) : null}
        </Box>
        <AddFiltermodal
          isOpenFromOutside={isOpenFromOutside}
          setOpenFromOutside={setOpenFromOutside}
        />
      </Box>
    </Box>
  );
};

export default FilterStrip;
