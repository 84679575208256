import { CAMPAIGN_FOLDER_REQUEST,CAMPAIGN_FOLDER_SUCCESS,CAMPAIGN_FOLDER_FAIL } from "../constants/campaignFolderConstants";

export const getCampFolderReducer=(state={},action) => {
    switch(action.type){
        case CAMPAIGN_FOLDER_REQUEST:
            return { loading1: true };
          case CAMPAIGN_FOLDER_SUCCESS:
            return { loading1: false, data: action.payload, error: null };
          case CAMPAIGN_FOLDER_FAIL:
            return { loading1: false, error: action.payload };
          default:
            return state;
    }
}