import {
  SALES_CONTACTS_TABLE_FAIL,
  SALES_CONTACTS_TABLE_REQUEST,
  SALES_CONTACTS_TABLE_SUCCESS,
  SALES_FILTERS_FAIL,
  SALES_FILTERS_REQUEST,
  SALES_FILTERS_SUCCESS,
} from "../constants/salesTableConstants";

export const getSalesContactsListReducer = (state = {}, action) => {
  switch (action.type) {
    case SALES_CONTACTS_TABLE_REQUEST:
      return { loading: true };
    case SALES_CONTACTS_TABLE_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case SALES_CONTACTS_TABLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const allSalesFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case SALES_FILTERS_REQUEST:
      return { loading: true };
    case SALES_FILTERS_SUCCESS:
      return { loading: false, payload: action.payload};
    case SALES_FILTERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};