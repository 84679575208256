import { useState } from "react";
import { Styles } from "../Styles";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import InputField from "../../../components/InputFields/InputField";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const CreateDashboardPopUp = (props) => {
  const classes = Styles();
  const [selectedValue, setSelectedValue] = useState("private");
  const [dashboardName, setDashboardName] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const cancelHandle = () => {
    props.handleCreatePopUp();
  };

  const createHandle = (e) => {
    let payload = {
      dashboard_name: dashboardName,
      dashboard_type: selectedValue,
    };
    props.createDashboard(payload, props.handleCreatePopUp, e);
  };


  const modalHeaderData = {
    heading: {
      id: "head",
      label: STRINGS.CREATE_DASHBOARD,
    },
  };

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.CREATE}
        size={"sm36"}
        variant={"primary"}
        onClick={createHandle}
        data-tour="createdashbtn"
        isLoading={props.loading}
      />
    </div>
  );

  const radioButtonData = [
    {
      id: "private",
      label: STRINGS.PRIVATE_LABEL,
    },
    { id: "public-read", label: STRINGS.EVERYONE_LABEL },
  ];

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
    >
      <form onSubmit={createHandle} className={classes.modalBodyContainer}>
        <div data-tour="dashboardname">
          <TypographyInline
            label={STRINGS.DASHBOARD_NAME}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
            isRequired
          />
          <div className={classes.paddingTopper4}>
            <InputField
              size={"sm36"}
              errormessage={props.createErr.length !== 0 ? props.createErr : ""}
              variant={props.createErr.length !== 0 ? "error" : "default"}
              value={dashboardName}
              onChange={(e) => {
                if (e?.target?.value?.length <= 50) {
                  setDashboardName(e.target.value)
                }
              }}
              placeholder={STRINGS.PLACEHOLDER_CREATE_DASHBOARD}
            />
          </div>
        </div>
        <div
          className={classes.permissionsContainer}
          data-tour="sharedashboard"
        >
          <TypographyInline
            label={`${STRINGS.PERMISSIONS_LABEL}*`}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
          />
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </div>
      </form>
    </CustomModal>
  );
};
