import React from "react";
import classes from "./CrmComp.module.css";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";

const SkeletonLoaderCRMScreens = ({
  loaderHeight = "140px",
  buttonsLength = 1,
  accordionLengths = 5,
}) => {
  return (
    <div className={classes.taskLogLoaderContainer}>
      <div className={classes.LoaderContainer}>
        {Array.from({ length: 1 }).map((index) => {
          return (
            <div key={`taskLogLoader${index}`} className={classes.widthLoader}>
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"100%"}
                skeletonHeight={"24px"}
                stackWidth={"100%"}
              />
            </div>
          );
        })}
        <div className={classes.crmLoaderButtonsSkeleton}>
          {Array.from({ length: buttonsLength }).map((index) => {
            return (
              <div
                key={`taskLogLoader${index}`}
                className={classes.widthLoader}
              >
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"36px"}
                  stackWidth={"100%"}
                />
              </div>
            );
          })}
        </div>
      </div>

      {Array.from({ length: accordionLengths }).map((item, index) => {
        return (
          <div key={`emaiLogLoader${index}`}>
            <SkeletonLoader
              type="rounded"
              skeletonWidth={"100%"}
              skeletonHeight={loaderHeight}
              stackWidth={"100%"}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SkeletonLoaderCRMScreens;
