import { useEffect, useState } from "react";
import { Styles } from "../Styles";
import { useSelector } from "react-redux";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import RadioButton from "../../../components/RadioButton/RadioButton";
import InputField from "../../../components/InputFields/InputField";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const UpdateDashboardPopUp = (props) => {
  const classes = Styles();
  const currentDashboardSelector = useSelector(
    (state) => state.getCurrentDashboard
  );
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const [selectedValue, setSelectedValue] = useState("");
  const [dashboardName, setDashboardName] = useState();

  useEffect(() => {
    setSelectedValue(props?.dashBoard?.dashboard_type === "team" ? "public-read" : props?.dashBoard?.dashboard_type || "private");
    setDashboardName(props.dashBoard?.dashboard_name || "");
  }, [props.dashBoard]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const cancelHandle = () => {
    props.handleUpdatePopUp();
  };

  const updateHandle = (e) => {
    let payload = {
      _id: props.dashBoard?._id,
      dashboard_name: dashboardName,
      dashboard_type: selectedValue,
      is_default_dashboard: props.dashBoard?.is_default_dashboard,
    };
    props.updateDashboard(payload, props.handleUpdatePopUp, e);
  };

  const modalHeaderData = {
    heading: {
      id: "head",
      label: STRINGS.EDIT_DASHBOARD,
    },
  };

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.UPDATE}
        size={"sm36"}
        variant={"primary"}
        onClick={updateHandle}
        isLoading={props.loading}
      />
    </div>
  );

  const radioButtonData = [
    {
      id: "private",
      label: STRINGS.PRIVATE_LABEL,
    },
    { id: "public-read", label: STRINGS.EVERYONE_LABEL },
  ];

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
    >
      <form
        onSubmit={(e) => updateHandle(e)}
        className={classes.modalBodyContainer}
      >
        <div
          className={classes.modalFieldLabelContainer}
          data-tour="dashboardname"
        >
          <TypographyInline
            label={STRINGS.DASHBOARD_NAME}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
            isRequired
          />
          <InputField
            size={"sm36"}
            value={dashboardName}
            errormessage={props.updateErr.length !== 0 ? props.updateErr : ""}
            variant={props.updateErr.length ? "error" : "default"}
            onChange={(e) => {
              if (e?.target?.value?.length <= 50) {
                setDashboardName(e.target.value);
              }
            }}
            disabled={
              userDetailsSelector.payload?.email !==
              currentDashboardSelector.payload?.owner_email
            }
            placeholder={STRINGS.PLACEHOLDER_CREATE_DASHBOARD}
          />
        </div>
        <div
          className={classes.permissionsContainer}
          data-tour="sharedashboard"
        >
          <TypographyInline
            label={`${STRINGS.PERMISSIONS_LABEL}*`}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
          />
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </div>
      </form>
    </CustomModal>
  );
};
