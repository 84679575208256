import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { GREY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import EmptyViewCard from "./EmptyViewCard";
import ManageViewCard from "./ManageViewCard";
import classes from "./DealsComp.module.css";
import { dealsEndpoints } from "../../../../utils/constants/httpConstants";
import { getRequest } from "../../../../Apis";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
import { getCompanyBasicDetails } from "../../../../redux/actions/companyActions";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  tempHeadDealsCells,
} from "../../../../utils/constants";
import {
  dynamicColumnUpdateHeaders,
  dynamicUpdatedColumn,
} from "../../../../redux/actions/tableActions";
import {
  HandleClearAllFilterFromStore,
  checkDynamicExistFields,
} from "../../../../redux/store/storeHelper";
import { stringQueryList } from "../../../../redux/store/storeConstants";
import { filterMultipleList } from "../../../../redux/actions/filterActions";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import CustomStyledMenu from "../../../../components/Menu/CustomStyledMenu";
import IconComponent from "../../../../components/Icon/IconComponent";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import AddViewModal from "./AddViewModal";
import { deleteRequest } from "../../../../Apis/apiWrapper";
import { Mixpanel } from "../../../../Apis/Mixpanel";
import { Button } from "../../../../components/Buttons";
import { getPeopleDealDetails } from "../../../../redux/actions/dealsActions";

const modalHeaderData = {
  heading: {
    id: "head",
    label: STRINGS.ALL_CONTACT_SAVED_VIEWS,
    cross_icon_label: "Task 2/2",
  },
};

const AllViewModal = ({
  openAddViewModal,
  setOpenAddViewModal = () => { },
  leadType,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const session_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);
  const active_view = session_id
    ? session_id
    : leadType === STRINGS.PEOPLE
      ? STRINGS.ALL_CONTACTS
      : STRINGS.ALL_COMPANIES;

  const selector = useSelector((state) => state.peopleDealsDetails);
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const userDetail = userDetailsSelector?.payload?.role;

  const [publicViews, setPublicViews] = useState([]);
  const [privateViews, setPrivateViews] = useState([]);
  const [teamViews, setTeamViews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewDetail, setViewDetail] = useState({});
  const [viewOwners, setViewOwners] = useState([]);
  const [showActions, setShowActions] = useState("");
  const [viewCardMenu, setViewCardMenu] = useState(null);
  const [privateViewCardMenu, setPrivateViewCardMenu] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [renameViewPopup, setRenameViewPopup] = useState(false);
  const [renamePopupValue, setRenamePopupValue] = useState({});
  const [isPin, setIsPin] = useState(0);
  const [performing, setPerforming] = useState(false);
  const [viewType, setViewType] = useState("");
  const [isRename, setIsRename] = useState(false);
  const [type, setType] = useState(STRINGS.TEAM_ID);

  async function getViews() {
    setLoading(true);
    try {
      const URL = dealsEndpoints.getAllDealsView;
      const response = await getRequest(navigate, URL);
      setPublicViews(response?.data?.data?.public);
      setPrivateViews(response?.data?.data?.private);
      setTeamViews(response?.data?.data?.team);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  const handleChangeView = (query, table_columns = []) => {
    HandleClearAllFilterFromStore(dispatch, location, navigate, table_columns);
    const res = query;
    if (res) {
      for (let i in res) {
        if (
          typeof res[i] === STRINGS.STRING &&
          stringQueryList?.indexOf(i) === -1
        ) {
          res[i] = [res[i]];
        }
      }
    }

    const initalMultipleList = checkDynamicExistFields(res);

    dispatch(filterMultipleList(initalMultipleList?.payload));
  };

  const handleCreateTempActiveView = async (view) => {
    try {
      sessionStorage.setItem(STRINGS.ACTIVE_VIEW_ID, view?.view_id.toString());
      const URL = dealsEndpoints.getInitialViews;
      const response = await getRequest(
        navigate,
        URL + "?view-id=" + view?.view_id
      );
      let table_columns =
        response?.data?.data?.active_view?.table_columns || [];
      if (!table_columns.length) {
        table_columns = [
          ...tempHeadDealsCells
            .filter((item) => item.isChecked)
            .map((item) => item.id),
        ];
      }

      const new_table_cells_payload = [];
      for (let i in tempHeadDealsCells) {
        if (table_columns.includes(tempHeadDealsCells[i].id)) {
          new_table_cells_payload.push({
            ...tempHeadDealsCells[i],
            isChecked: true,
          });
        } else {
          new_table_cells_payload.push({
            ...tempHeadDealsCells[i],
            isChecked: false,
          });
        }
      }
      // dispatch(dynamicUpdatedColumn(new_table_cells_payload));
      // dispatch(dynamicColumnUpdateHeaders(new_table_cells_payload));

      await dispatch(getPeopleDealDetails(navigate, true, response?.data?.data));
      handleChangeView(response?.data?.data?.active_view?.query_string || {}, new_table_cells_payload);
      setOpenAddViewModal(false);
    } catch (error) { }
  };

  const handleMouseEnter = (
    eachView,
    index,
    event,
    owners,
    isPrivate = false,
    is_pin
  ) => {
    setIsPin(is_pin);
    setViewDetail(eachView);
    setViewOwners(owners);
    setShowActions(eachView?.view_id);
    if (isPrivate) {
      setType(STRINGS.PRIVATE_ID);
      setPrivateViewCardMenu(event?.currentTarget);
    } else {
      setType(STRINGS.TEAM_ID);
      setViewCardMenu(event?.currentTarget);
    }
  };

  const handlePin = async (viewDetail) => {
    try {
      setViewCardMenu(null);
      setPrivateViewCardMenu(null);
      const URL = dealsEndpoints.pinDealView;
      const response = await getRequest(
        navigate,
        URL + "?view-id=" + viewDetail?.view_id
      );

      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        Mixpanel.track(STRINGS.PINNED_VIEW);
        getViews();
        await dispatch(getPeopleDealDetails(navigate));
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleDeleteConf = () => {
    setOpenConfirmation(true);
    setViewCardMenu(null);

    setPrivateViewCardMenu(null);
  };

  const renameViewHandler = (eachView, type) => {
    setIsRename(true);
    setRenamePopupValue({ eachView: eachView, type: type });
    setViewCardMenu(null);
    setPrivateViewCardMenu(null);
    setRenameViewPopup(true);
  };

  const handleUnpinView = async (viewDetail) => {
    try {
      setViewCardMenu(null);
      setPrivateViewCardMenu(null);
      const response = await getRequest(
        navigate,
        dealsEndpoints?.unpinDealView +
        `?${STRINGS.VIEW_ID}=` +
        viewDetail?.view_id
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        await dispatch(getPeopleDealDetails(navigate));
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleDelete = async () => {
    // call delete action here.
    try {
      const URL = dealsEndpoints.deleteDealView;
      setPerforming(true);
      const response = await deleteRequest(
        URL + `?${STRINGS.VIEW_ID}=` + showActions,
        {},
        navigate
      );
      const active_view_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);
      if (showActions === active_view_id) {
        sessionStorage.removeItem(STRINGS.ACTIVE_VIEW_ID);
      }
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
        Mixpanel.track(STRINGS.DELETE_VIEW);

        getViews();
        setPerforming(false);
        setOpenConfirmation(false);
        dispatch(getPeopleDealDetails(navigate));
      }
    } catch (error) {
      setPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const viewDeleteModalBody = (
    <div>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.ARE_YOU_SURE_DELETE}
      />
    </div>
  );

  const viewDeleteModalHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.DELETE_THIS_VIEW,
    },
  };

  const viewDeleteModalRightFooter = (
    <div className={classes.viewDeleteModalRightFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpenConfirmation(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={handleDelete}
        isLoading={performing}
      />
    </div>
  );

  const actionList = [
    {
      type: STRINGS.PIN_VIEW_TO_KEEP_OPEN,
      action: handlePin,
      icon: ICON_LABELS.PUSH_PIN,
    },
    {
      type: STRINGS.EDIT_VIEW,
      action: renameViewHandler,
      icon: ICON_LABELS.EDIT,
    },
    {
      type: STRINGS.DELETE_THIS_VIEW,
      action: handleDeleteConf,
      icon: ICON_LABELS.DELETE,
    },
  ];

  const actionLists = [
    {
      type: STRINGS.PIN_VIEW_TO_KEEP_OPEN,
      action: handlePin,
      icon: ICON_LABELS.PUSH_PIN,
    },

    // {
    //   type: STRINGS.DELETE_THIS_VIEW,
    //   action: handleDeleteConf,
    //   icon: ICON_LABELS.DELETE,
    // },
  ];

  useEffect(() => {
    if (openAddViewModal) {
      getViews();
    }
  }, [openAddViewModal]);

  return (
    <>
      <CustomModal
        open={openAddViewModal}
        close={() => setOpenAddViewModal(!openAddViewModal)}
        header={modalHeaderData}
        width="60.469vw"
        remove_padding={true}
      >
        <div className={classes.addViewModalBody}>
          {/* Standard Views */}
          <div className={classes.addViewBodySubCntnr}>
            <div className={classes.viewLabel}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.STANDARD}
              />
            </div>
            {loading ? (
              <div className={classes.loaderContainer}>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"40px"}
                  stackWidth={"100%"}
                />
              </div>
            ) : (
              <>
                {publicViews?.map((eachView) => {
                  return (
                    <ManageViewCard
                      label={eachView?.view_name}
                      RightIcon={ICON_LABELS.PUSH_PIN}
                      onClick={() => handleCreateTempActiveView(eachView)}
                      isSelected={
                        active_view === STRINGS.ALL_CONTACTS ||
                        active_view === STRINGS.ALL_COMPANIES ||
                        active_view === eachView?.view_id
                      }
                    />
                  );
                })}
              </>
            )}
          </div>
          {/* Standard Views */}

          {/* Private Views */}
          <div className={classes.addViewBodySubCntnr}>
            <div className={classes.viewLabel}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.PRIVATE_VIEW}
              />
            </div>

            <div id="modal_body" className={classes.manageCardMainContainer}>
              {loading ? (
                Array.from({ length: 5 }).map((item, index) => (
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={"55px"}
                    stackWidth={"100%"}
                  />
                ))
              ) : (
                <>
                  {privateViews?.length ? (
                    <>
                      {privateViews?.map((eachView, index) => {
                        const pinnedViewIds = selector?.payload?.pinned_view
                          .filter((item) => item?.view_id)
                          .map((view) => {
                            return view.view_id;
                          });
                        const defaultViewIds = selector?.payload?.default_view?.view_id;

                        const owners =
                          eachView?.owner_name === STRINGS.ME ||
                            userDetail === STRINGS.ADMIN_USER
                            ? actionList
                            : actionLists;
                        return (
                          <>
                            <ManageViewCard
                              label={eachView?.view_name}
                              onwerName={eachView?.owner_name || ""}
                              onClick={() =>
                                handleCreateTempActiveView(eachView)
                              }
                              isSelected={active_view === eachView?.view_id}
                              actionOnClick={(e) =>
                                handleMouseEnter(
                                  eachView,
                                  index,
                                  e,
                                  owners,
                                  true,
                                  eachView?.is_pin
                                )
                              }
                              isPinned={pinnedViewIds?.includes(
                                eachView?.view_id
                              )}
                              isDefault={defaultViewIds?.includes(
                                eachView?.view_id
                              )}
                            />
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <EmptyViewCard
                      label={STRINGS.NO_PRIVATE_VIEW_CREATED}
                      onClick={() => {
                        setRenameViewPopup(true);
                        setIsRename(false);
                        setViewType("private");
                      }}
                    />
                  )}
                </>
              )}
              <CustomStyledMenu
                anchorEl={privateViewCardMenu}
                open={Boolean(privateViewCardMenu)}
                onClose={() => setPrivateViewCardMenu(null)}
                noMargin={true}
                isRightAlign={true}
                arrow
              >
                <div className={classes.toolTipContainer}>
                  {viewOwners?.map((action, index) => {
                    return !(
                      viewDetail?.view_id ===
                      selector?.payload?.default_view
                        ?.view_id && index === 0
                    ) ?
                      <div
                        key={action.type}
                        onClick={() => action.action(viewDetail, type)}
                        className={
                          actionList?.length - 1 === index
                            ? classes.borderFlex
                            : classes.noBorderFlex
                        }
                      >
                        <IconComponent
                          color={GREY.TERTIARY}
                          iconLabel={action.icon}
                          fontSize={fontSize.LG}
                        />
                        <TypographyInline
                          label={action.type}
                          size={"sm"}
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                        />
                      </div> : null
                  })}
                </div>
              </CustomStyledMenu>
            </div>
          </div>
          {/* Private Views */}

          {/* Team Views */}
          {/* <div className={classes.addViewBodySubCntnr}>
            <div className={classes.viewLabel}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.TEAM_VIEW}
              />
            </div>

            <div id="modal_body" className={classes.manageCardMainContainer}>
              {loading ? (
                Array.from({ length: 5 }).map((item, index) => (
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={"55px"}
                    stackWidth={"100%"}
                  />
                ))
              ) : (
                <>
                  {teamViews?.length ? (
                    <>
                      {teamViews?.map((eachView, index) => {
                        const owners =
                          eachView?.owner_name === STRINGS.ME ||
                            userDetail === STRINGS.ADMIN_USER
                            ? actionList
                            : actionLists;

                        const pinnedViewIds = selector?.payload?.pinned_view
                          .filter((item) => item?.view_id)
                          .map((view) => {
                            return view.view_id;
                          });

                        const defaultViewIds = selector?.payload?.default_view?.view_id
                        return (
                          <ManageViewCard
                            key={eachView?.view_id}
                            label={eachView?.view_name}
                            onwerName={eachView?.owner_name || ""}
                            onClick={() => handleCreateTempActiveView(eachView)}
                            isSelected={active_view === eachView?.view_id}
                            actionOnClick={(e) =>
                              handleMouseEnter(
                                eachView,
                                index,
                                e,
                                owners,
                                true,
                                eachView?.is_pin
                              )
                            }
                            isPinned={pinnedViewIds?.includes(
                              eachView?.view_id
                            )}
                            isDefault={defaultViewIds?.includes(
                              eachView?.view_id
                            )}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <EmptyViewCard
                      label={STRINGS.NO_TEAM_VIEW_CREATED}
                      onClick={() => {
                        setViewType("team");
                        setIsRename(false);
                        setRenameViewPopup(true);
                      }}
                    />
                  )}
                </>
              )}
              <CustomStyledMenu
                anchorEl={privateViewCardMenu}
                open={Boolean(privateViewCardMenu)}
                onClose={() => setPrivateViewCardMenu(null)}
                noMargin={true}
                isRightAlign={true}
                arrow
              >
                <div className={classes.toolTipContainer}>
                  {viewOwners?.map((action, index) => {
                    return !(
                      viewDetail?.view_id ===
                      selector?.payload?.default_view
                        ?.view_id && index === 0
                    ) ?
                      <div
                        key={action.type}
                        onClick={() =>
                          isPin === 1
                            ? index === 0
                              ? handleUnpinView(viewDetail, type)
                              : action.action(viewDetail, type)
                            : action.action(viewDetail, type)
                        }
                        className={
                          actionList?.length - 1 === index
                            ? classes.borderFlex
                            : classes.noBorderFlex
                        }
                      >
                        <IconComponent
                          color={GREY.TERTIARY}
                          iconLabel={action.icon}
                          fontSize={fontSize.LG}
                        />
                        <TypographyInline
                          label={
                            isPin === 1
                              ? index === 0
                                ? STRINGS.UNPIN_THIS_VIEW
                                : action.type
                              : action.type
                          }
                          size={"sm"}
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                        />
                      </div> : null
                  })}
                </div>
              </CustomStyledMenu>
            </div>
          </div> */}
          {/* Team Views */}
        </div>
      </CustomModal>

      {/* Add/Edit View Modal */}
      {openAddViewModal && (
        <AddViewModal
          setPublicViews={setPublicViews}
          setPrivateViews={setPrivateViews}
          setTeamViews={setTeamViews}
          getViews={getViews}
          isRename={isRename}
          leadType={leadType}
          open={renameViewPopup}
          setOpen={setRenameViewPopup}
          renamePopupValue={isRename ? renamePopupValue : {}}
          setRenamePopupValue={setRenamePopupValue}
          setViewCardMenu={setViewCardMenu}
          setPrivateViewCardMenu={setPrivateViewCardMenu}
          viewType={viewType}
        />
      )}

      <CustomModal
        variant={"error"}
        close={() => setOpenConfirmation(false)}
        open={openConfirmation}
        header={viewDeleteModalHeader}
        children={viewDeleteModalBody}
        footer={{
          right: viewDeleteModalRightFooter,
        }}
      />
    </>
  );
};

export default AllViewModal;
