import { postRequest } from "../../Apis";
import { templateEndpoints } from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";
import{
    TEMPLATE_FOLDER_REQUEST,
    TEMPLATE_FOLDER_SUCCESS,
    TEMPLATE_FOLDER_FAIL
} from "../constants/templateFolderConstants"

export const getTempFolderList=(sentQuery, navigate)=> async(dispatch)=>{
        try{
            dispatch({type:TEMPLATE_FOLDER_REQUEST});
            const response=await postRequest(navigate,templateEndpoints.folderTempList,sentQuery)
            dispatch({
                type:TEMPLATE_FOLDER_SUCCESS,
                payload:{...response?.data,query:sentQuery}
            })
        }
        catch(err){
            if(err?.status === 401){
                navigate( LOGIN_PATH )
              }
              dispatch({ type:TEMPLATE_FOLDER_FAIL , payload: err });
        }
}