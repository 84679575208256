import { postRequest } from "../../Apis";
import { campaignEndpoints } from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";
import {
  CAMPAIGN_LIST_GET_FAIL,
  CAMPAIGN_LIST_GET_REQUEST,
  CAMPAIGN_LIST_GET_SUCCESS,
  GET_CAMPAIGN_FOLDER_LIST_FAIL,
  GET_CAMPAIGN_FOLDER_LIST_REQUEST,
  GET_CAMPAIGN_FOLDER_LIST_SUCCESS,
  SELECTED_CAMPAIGN_DETAILS_FAIL,
  SELECTED_CAMPAIGN_DETAILS_SUCCESS,
} from "../constants/campaignConstants";

export const campaignsListFetch =
  (sentQuery, navigate, callFromGetState = false) =>
  async (dispatch) => {
    if (callFromGetState) {
      const response = await postRequest(
        navigate,
        campaignEndpoints.getCampaignList,
        sentQuery
      );
      dispatch({
        type: CAMPAIGN_LIST_GET_SUCCESS,
        payload: { ...response?.data, query: sentQuery },
      });
    } else {
      try {
        dispatch({ type: CAMPAIGN_LIST_GET_REQUEST });
        const response = await postRequest(
          navigate,
          campaignEndpoints.getCampaignList,
          sentQuery
        );
        dispatch({
          type: CAMPAIGN_LIST_GET_SUCCESS,
          payload: { ...response?.data, query: sentQuery },
        });
        
      } catch (err) {
        dispatch({ type: CAMPAIGN_LIST_GET_FAIL, payload: err });
      }
    }
  };

export const selectedCamapaignDetails =
  (payload, navigate) => async (dispatch) => {
    try {

      dispatch({
        type: SELECTED_CAMPAIGN_DETAILS_SUCCESS,
        payload: payload,
      });
    } catch (err) {
      if (err?.status === 401) {
        navigate(LOGIN_PATH);
      }

      dispatch({ type: SELECTED_CAMPAIGN_DETAILS_FAIL, payload: err });
    }
  };

export const getCampaginFolderList =
  (navigate, payload) => async (dispatch) => {
    try {
      dispatch({ type: GET_CAMPAIGN_FOLDER_LIST_REQUEST });
      const response = await postRequest(
        navigate,
        campaignEndpoints.folderList,
        payload
      );

      dispatch({
        type: GET_CAMPAIGN_FOLDER_LIST_SUCCESS,
        payload: { ...response?.data, query: payload },
      });
    } catch (err) {
      if (err?.status === 401) {
        // navigate from this action
        navigate(LOGIN_PATH);
      }
      dispatch({ type: GET_CAMPAIGN_FOLDER_LIST_FAIL });
    }
  };

export const searchCampaginFolderList =
  (navigate, payload, folderName) => async (dispatch) => {
    try {
      dispatch({ type: GET_CAMPAIGN_FOLDER_LIST_REQUEST });
      const response = await postRequest(
        navigate,
        campaignEndpoints.folderList,
        folderName,
        payload
      );

      dispatch({
        type: GET_CAMPAIGN_FOLDER_LIST_SUCCESS,
        payload: { ...response?.data, query: payload },
      });
    } catch (err) {
      if (err?.status === 401) {
        // navigate from this action
        navigate(LOGIN_PATH);
      }
      dispatch({ type: GET_CAMPAIGN_FOLDER_LIST_FAIL });
    }
  };
