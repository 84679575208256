import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/Table/CustomTable.js";
import CustomHeader from "../../components/Header/CustomHeader.js";
import SearchField from "../../components/InputFields/SearchField.js";
import { AutoCompleteCustom } from "../../components/Autocomplete/AutoCompleteCustom";
import { dashboardEndpoints } from "../../utils/constants/httpConstants.js";
import { debounceHelper } from "../../utils/helpers";
import Button from "../../components/Buttons/Button.js";
import { STRINGS } from "./strings";
import {
  DASHBOARD_LIST_TABLE_HEADERS,
  DEBOUNCE_DELAY,
} from "../../utils/constants";
import { getCurrentDashboard, getDashboardList } from "../../redux/actions/dashboardActions";
import { ICON_LABELS } from "../../utils/constants/UI";
import { CreateDashboardPopUp } from "./components/createDashboardPopUp.js";
import { UpdateDashboardPopUp } from "./components/updateDashboardPopUp.js";
import { CloneDashboardPopUp } from "./components/cloneDashboardPopUp.js";
import { SetDefaultDashboardPopUp } from "./components/setDefaultDashboardPopUp.js";
import { RemoveDashboardPopUp } from "./components/removeDashboardPopUp.js";
import { getRequest, putRequest, deleteRequest, postRequest } from "../../Apis";
import {
  DASHBOARD_NAME_LENGTH_ERROR,
  DASHBOARD_NAME_LONG_LENGTH_ERROR,
  EMPTY_DASHBOARD_FIELD_ERROR,
  DASHBOARD_DELETED_SUCCESSFULLY,
} from "../../utils/constants/messages.js";
import classes from "./DashboardList.module.css";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../utils/constants";
import { getErrorMessage } from "../../utils/helpers";
import ManageAccessPopup from "./components/manageAccessPopup.js";
import { DashboardDetailsPopup } from "./components/DashboardDetailsPopup.js";
import { ActivityLogsPopup } from "./components/ActivityLogsPopup.js";
import { BulkDeletePopup } from "./components/bulkDeletePopup.js";
import {
  DASHBOARD_PATH,
} from "../../utils/constants/routes.js";
import EmptyDashboardScreen from "./components/EmptyDashboardScreen.js";
import { paginationDataAction } from "../../redux/actions/customTableActions.js";

const LOGIN_PATH = "/signin";

const generateDateRange = (straw) => {
  let to = new Date();
  let from;

  if (straw === "today") {
    var currentDate = new Date();

    // Set time components to zero to get the start of the day
    currentDate.setHours(0, 0, 0, 0);

    // Get the time difference in milliseconds
    var timeDifference = currentDate.getTime() - new Date().getTime();
    from = new Date(to.getTime() + timeDifference)
  }
  if (straw === "yesterday") {
    from = new Date(to.getTime() - 86400000);
    to = from;
  }
  if (straw === "last_week") {
    from = new Date(to.getTime() - 7 * 86400000);
  }
  if (straw === "this_month") {

    from = new Date(`${to.getMonth() + 1}-01-${to.getFullYear()}`);
  }
  if (straw === "last_month") {
    from = new Date(`${to.getMonth()}-01-${to.getFullYear()}`);
    let nextMonth = new Date(to.getFullYear(), to.getMonth() + 1, 1);
    let lastDay = new Date(nextMonth - 1);
    let data = lastDay.getDate() - 1;
    to = new Date(`${to.getMonth()}-${data}-${to.getFullYear()}`);
  }
  // if(straw === "custom_range"){

  // }
  return {
    from: `${String(from.getFullYear())}/${String(from.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(from.getDate()).padStart(2, "0")}`,
    to: `${String(to.getFullYear())}/${String(to.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(to.getDate()).padStart(2, "0")}`,
  };
};

const defaultTablePayload = {
  page: 1,
  limit: 25,
};

const DashboardListScreen = () => {
  // the paginationDataSelector is an instance of a subscription to the pagination query state(in the store)
  // upon updation of the pagination query by the appropriate reducer, our component will be updated by react
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const navigate = useNavigate();
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const getAllOwners = async () => {
    const response = await getRequest(
      navigate,
      dashboardEndpoints.getDashboardOwners
    );
    const all_owners = response?.data?.data;
    setMatchOwner(all_owners);
    const mapped_owners = all_owners.map((item, index) => {
      return { id: item?._id, label: item?.owner_name };
    });
    mapped_owners.unshift({ id: "0", label: "All" });
    setOwners(mapped_owners);
    return;
  };

  const dispatch = useDispatch(); // this is a dispatch function object that can be used to dispatch an action
  // this is a navigate function object that redirects simply to the desired location
  // const location = useLocation(); // this is a location function object that can be used to access the location "state"

  const [createErr, setCreateErr] = useState("");
  const [updateErr, setUpdateErr] = useState("");

  const [createDashboardFlag, setCreateDashboardFlag] = useState(false);
  const [editDashboardFlag, setEditDashboardFlag] = useState(false);
  const [cloneDashboardFlag, setCloneDashboardFlag] = useState(false);
  const [deleteDashboardFlag, setDeleteDashboardFlag] = useState(false);
  const [setDefaultDashboardFlag, setSetDefaultDashboardFlag] = useState(false);
  const [manageAccessPopupFlag, setManageAccessPopupFlag] = useState(false);
  const [isOp, setIsOp] = useState(false);
  const [isOwner, setIsOwner] = useState([]);
  const [dashboardActivityPopupFlag, setDashboardActivityPopupFlag] =
    useState(false);
  const [viewDashboardDetailsFlag, setViewDashboardDetailsFlag] =
    useState(false);
  const [dashboardForOperations, setDashboardForOperations] = useState("");
  const [scheduleBulkOperation, setScheduleBulkOperation] = useState(false);
  const [bulkOperationsEnabled, setBulkOperationsEnabled] = useState(false);
  const [matchOwner, setMatchOwner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resolvedRecords, setResolvedRecords] = useState([]); // these are the actual data records to be sent to the table
  const [tablePayload, setTablePayload] = useState({
    page: 1,
    limit: 25,
  });
  const [updateOwners, setUpdateOwners] = useState(true);
  const [search, setSearch] = useState("");
  // this is the general table payload format
  // the useEffect following this comment is triggered upon the first render of the component, and updates of the pagination

  const [owners, setOwners] = useState([
    {
      id: "0",
      label: "All",
    },
  ]);

  const [assignees, setAssignees] = useState([
    {
      id: "0",
      label: "All",
    },
    {
      id: "private",
      label: "Private",
    },
    {
      id: "public-read",
      label: "Team",
    },
  ]);

  const [dateFilteringOptionsArray, setDateFilteringOptionsArray] = useState([
    {
      id: "0",
      label: "All",
    },
    {
      id: "today",
      label: "Today",
    },
    {
      id: "yesterday",
      label: "Yesterday",
    },
    {
      id: "last_week",
      label: "Last 7 days",
    },
    {
      id: "this_month",
      label: "This month",
    },
    {
      id: "last_month",
      label: "Last month",
    },
    // {
    //   id: "custom_range",
    //   label:"Custom Range",
    // },
    // add a provision for custom range selection
  ]);

  const [selectedOwner, setSelectedOwner] = useState(["All"]);
  const [selectedOwnerId, setSelectedOwnerId] = useState("0");
  const [selectedAssignee, setSelectedAssignee] = useState(["All"]);
  const [selectedAssigneeId, setSelectedAssigneeId] = useState("0");
  const [selectedDateFilter, setSelectedDateFilter] = useState(["All"]);
  const [dateFilterId, setDateFilterId] = useState("0");

  const handlesCreateDashboardPopup = () => {
    setCreateDashboardFlag((cur) => !cur);
    setCreateErr("");
  };

  const handlesEdit = (target) => {
    setDashboardForOperations(target);
    setEditDashboardFlag(true);
  };

  const handlesDelete = (target) => {
    setDashboardForOperations(target);
    setDeleteDashboardFlag(true);
  };

  const handlesSetAsDefault = (target) => {
    setDashboardForOperations(target);
    setSetDefaultDashboardFlag(true);
  };

  const handlesClone = (target) => {
    setDashboardForOperations(target);
    setCloneDashboardFlag(true);
  };

  const handlesManagingAccess = (target) => {
    setDashboardForOperations(target);
    setManageAccessPopupFlag(true);
  };

  const handlesPin = (target) => {
    const func = () => { };
    const payload = {
      _id: target?._id,
      dashboard_name: target.dashboard_name,
      dashboard_type:
        target.dashboard_type == "team" ? "public-read" : target.dashboard_type,
      is_default_dashboard: target?.is_default_dashboard,
      pin: target.pin == 0 || !target.pin ? 1 : 0,
    };
    updateDashboardApiCall(payload, func);
  };

  const handlesViewDashboardDetails = (target) => {
    setDashboardForOperations(target);
    setViewDashboardDetailsFlag(true);
  };

  const handlesGettingActivityLogs = (target) => {
    setDashboardForOperations(target);
    setDashboardActivityPopupFlag(true);
  };

  const handlesBulkDeletePopup = () => {
    setScheduleBulkOperation(true);
  };

  const DashboardOperations = [
    {
      id: "clone",
      label: STRINGS.COMMON_CLONE,
      function: handlesClone,
      iconLabel: ICON_LABELS.FILE_COPY,
    },
    {
      id: "edit",
      label: STRINGS.EDIT,
      function: handlesEdit,
      iconLabel: ICON_LABELS.EDIT,
      // isVisible: item.owner_email === userDetailsSelector?.payload?.email
    },

    {
      id: "set as default",
      label: STRINGS.SETAS_DEF,
      function: handlesSetAsDefault,
      iconLabel: ICON_LABELS.BOOKMARK,
    },
    {
      id: "pin",
      label: STRINGS.PIN,
      function: handlesPin,
      iconLabel: ICON_LABELS.PUSH_PIN,
    },
    // {
    //   if: "manage_access",
    //   label: STRINGS.MANAGE_ACCESS,
    //   function: handlesManagingAccess,
    //   iconLabel: ICON_LABELS.SHIELD,
    // },
    // {
    //   id: "dashboard_details",
    //   label: STRINGS.DASHBOARD_DETAILS,
    //   function: handlesViewDashboardDetails,
    //   iconLabel: ICON_LABELS.INFO,
    // },
    {
      id: "activity",
      label: STRINGS.ACTIVITY_LOGS,
      function: handlesGettingActivityLogs,
      iconLabel: ICON_LABELS.VIEW_TIMELINE,
    },
    {
      id: "delete",
      label: STRINGS.DELETE_DASHBOARD,
      function: handlesDelete,
      iconLabel: ICON_LABELS.DELETE,
    },
  ];

  const createDashboardApiCall = async (payload, func) => {
    try {
      const URL = dashboardEndpoints?.createDashboard;
      const response = await postRequest(navigate, URL, payload);
      if (response?.status === 201) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        func();
        setLoading(false);
        dispatch(getDashboardList(navigate, {}));
        localStorage.setItem("dashboardid", payload.dashboard_name);
        navigate(DASHBOARD_PATH);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const createDashboard = async (payload, func, e) => {
    e?.preventDefault();
    if (payload.dashboard_name?.trim().length > 0) {
      if (payload.dashboard_name?.trim().length < 3) {
        setCreateErr(DASHBOARD_NAME_LENGTH_ERROR);
      } else {
        if (payload.dashboard_name?.trim().length > 25) {
          setCreateErr(DASHBOARD_NAME_LONG_LENGTH_ERROR);
        } else {
          setLoading(true);
          createDashboardApiCall(payload, func);
        }
      }
    } else {
      setCreateErr(EMPTY_DASHBOARD_FIELD_ERROR);
    }
  };

  const cloneDashboardApiCall = async (payload, func) => {
    try {
      const URL = dashboardEndpoints?.cloneDashboard;
      const response = await postRequest(navigate, URL, payload);
      if (response?.status === 201) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        func();
        setLoading(false);
      }
      setTablePayload(defaultTablePayload);
    } catch (error) {
      // log user out here.
      if (error?.status === 401) {
        // navigate from this action
        navigate(LOGIN_PATH);
      }
      setLoading(false);
      setCreateErr(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const cloneDashboard = async (payload, func) => {
    if (payload.dashboard_name?.trim().length > 0) {
      if (payload.dashboard_name?.trim().length < 3) {
        setCreateErr(DASHBOARD_NAME_LENGTH_ERROR);
      } else {
        if (payload.dashboard_name?.trim().length > 25) {
          setCreateErr(DASHBOARD_NAME_LONG_LENGTH_ERROR);
        } else {
          setLoading(true);
          cloneDashboardApiCall(payload, func);
        }
      }
    } else {
      setCreateErr(EMPTY_DASHBOARD_FIELD_ERROR);
    }
  };

  const updateDashboardApiCall = async (payload, func) => {
    try {
      const URL = dashboardEndpoints?.updateDashboard;
      const response = await putRequest(navigate, URL, payload);
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        func();
        setLoading(false);
        setUpdateOwners(true);
      }
      setTablePayload({ page: 1, limit: 25 });
    } catch (error) {
      setLoading(false);
      setUpdateErr(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const updateDashboard = async (payload, func, e) => {
    e?.preventDefault();
    if (payload.dashboard_name?.trim().length > 0) {
      if (payload.dashboard_name?.trim().length < 3) {
        setUpdateErr(DASHBOARD_NAME_LENGTH_ERROR);
      } else {
        if (payload.dashboard_name?.trim().length > 25) {
          setUpdateErr(DASHBOARD_NAME_LONG_LENGTH_ERROR);
        } else {
          setLoading(true);
          updateDashboardApiCall(payload, func);
        }
      }
    } else {
      setUpdateErr(EMPTY_DASHBOARD_FIELD_ERROR);
    }
  };

  const updateDashboardAccess = async (payload, func, e) => {
    e.preventDefault();
    setLoading(true);
    updateDashboardApiCall(payload, func);
  };

  const deleteDashboard = async (payload, func) => {
    setLoading(true);
    try {
      const URL = dashboardEndpoints?.deleteDashboard;
      const response = await deleteRequest(URL, payload);
      if (response?.status === 200) {
        func();
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setLoading(false);
        setTablePayload({ page: 1, limit: 25 });
        dispatch(getCurrentDashboard());
        setUpdateOwners(true);
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message || error?.message);
      setLoading(false);
    }
  };

  const setDefaultDashboard = async (payload, func, data) => {
    setLoading(true);
    try {
      const URL = dashboardEndpoints?.setDefaultDashboard;
      const response = await putRequest(navigate, URL, payload);
      if (response?.status === 200) {
        func();
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setLoading(false);
      }
      dispatch(paginationDataAction({
        checkedRow: [], query: {
          page: 1, limit: 25
        }
      }))
      dispatch(getCurrentDashboard(data));
      setTablePayload(defaultTablePayload);
    } catch (error) {
      setLoading(false);
      setUpdateErr(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const handlesBulkDelete = async () => {
    const payload = {
      record_ids: paginationDataSelector?.data?.checkedRow || [],
      bulk_type: STRINGS.DELETE_SMALL,
      bulk: false,
    };

    setLoading(true);
    try {
      const URL = dashboardEndpoints?.bulkDelete;
      const response = await postRequest(navigate, URL, payload);
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setLoading(false);
        setTablePayload({ page: 1, limit: 25 });
        setUpdateOwners(true);
        setScheduleBulkOperation(false);
        setBulkOperationsEnabled(false);
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message || error?.message);
      setLoading(false);
    }
  };

  // the DashboardListSelector is a subscription to the getDashBoardList reducer, which returns an empty list by default

  const DashboardListSelector = useSelector(
    (state) => state.getDashboardDetails
  ); // this calls the reducer and therefore gets a state
  const records = DashboardListSelector?.payload?.total_records; // total datapoints, useful in places

  function handlesSettingRecords() {
    const receivedData = DashboardListSelector?.payload;
    if (receivedData?.records?.length && matchOwner?.length) {
      const resultArray = receivedData?.records?.map((item1) => {
        // Find the matching object in array2
        const matchingItem2 = matchOwner.find(
          (item2) => item2._id === item1.user_id
        );

        if (matchingItem2) {
          // Add the desired key from array2 to array1
          return {
            ...item1,
            owner_name: matchingItem2.owner_name,
          };
        } else {
          // If no matching object is found, simply return the original object
          return item1;
        }
      });
      setResolvedRecords(resultArray || []);
    } else {
      setResolvedRecords(receivedData?.records || []);
    }
    // setResolvedRecords(receivedData?.records || []);
    return;
  }

  const handleSearch = (e) => {
    // this code is referenced from the SequenceScreen
    e?.preventDefault(); // it would be very unusual for the event source to not have a preventDefault() method. Curious.
    try {
      setTablePayload((cur) => {
        const newTablePayload = { ...cur };
        newTablePayload["search"] = e.target.value;
        return newTablePayload;
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleClearAllFilter = () => {
    setSelectedDateFilter(["All"]);
    setSelectedAssignee(["All"]);
    setSelectedOwner(["All"]);
    setSearch("");
  };

  const DashboardListData = {
    heads: DASHBOARD_LIST_TABLE_HEADERS,
    body: resolvedRecords,
    operations: !isOp ? [...DashboardOperations] : [...isOwner],
    actions: {
      bold_text: {
        // navigate to the selected Dashboard
      },
      text: {},
      marketing_list: {
        function: (a, b) => {
          localStorage.setItem("dashboardid", a.dashboard_name);
          navigate(DASHBOARD_PATH);
        },
      },
      action: {
        function: (a, b) => {
          setIsOp(true);
          if (userDetailsSelector?.payload?.email === a.owner_email) {
            setIsOwner([
              {
                id: "clone",
                label: STRINGS.COMMON_CLONE,
                function: handlesClone,
                iconLabel: ICON_LABELS.FILE_COPY,
              },
              {
                id: "edit",
                label: STRINGS.EDIT,
                function: handlesEdit,
                iconLabel: ICON_LABELS.EDIT,
                // isVisible: item.owner_email === userDetailsSelector?.payload?.email
              },

              {
                id: "set as default",
                label: STRINGS.SETAS_DEF,
                function: handlesSetAsDefault,
                iconLabel: ICON_LABELS.BOOKMARK,
              },
              {
                id: "pin",
                label: STRINGS.PIN,
                function: handlesPin,
                iconLabel: ICON_LABELS.PUSH_PIN,
              },
              // {
              //   if: "manage_access",
              //   label: STRINGS.MANAGE_ACCESS,
              //   function: handlesManagingAccess,
              //   iconLabel: ICON_LABELS.SHIELD,
              // },
              // {
              //   id: "dashboard_details",
              //   label: STRINGS.DASHBOARD_DETAILS,
              //   function: handlesViewDashboardDetails,
              //   iconLabel: ICON_LABELS.INFO,
              // },
              {
                id: "activity",
                label: STRINGS.ACTIVITY_LOGS,
                function: handlesGettingActivityLogs,
                iconLabel: ICON_LABELS.VIEW_TIMELINE,
              },
              {
                id: "delete",
                label: STRINGS.DELETE_DASHBOARD,
                function: handlesDelete,
                iconLabel: ICON_LABELS.DELETE,
              },
            ]);
          }
          if (a.is_default_dashboard) {
            setIsOwner([
              {
                id: "clone",
                label: STRINGS.COMMON_CLONE,
                function: handlesClone,
                iconLabel: ICON_LABELS.FILE_COPY,
              },
              {
                id: "edit",
                label: STRINGS.EDIT,
                function: handlesEdit,
                iconLabel: ICON_LABELS.EDIT,
                // isVisible: item.owner_email === userDetailsSelector?.payload?.email
              },
              {
                id: "pin",
                label: STRINGS.PIN,
                function: handlesPin,
                iconLabel: ICON_LABELS.PUSH_PIN,
              },
              {
                id: "activity",
                label: STRINGS.ACTIVITY_LOGS,
                function: handlesGettingActivityLogs,
                iconLabel: ICON_LABELS.VIEW_TIMELINE,
              },
            ]);
          } else {
            setIsOwner([
              {
                id: "clone",
                label: STRINGS.COMMON_CLONE,
                function: handlesClone,
                iconLabel: ICON_LABELS.FILE_COPY,
              },
              {
                id: "edit",
                label: STRINGS.EDIT,
                function: handlesEdit,
                iconLabel: ICON_LABELS.EDIT,
                // isVisible: item.owner_email === userDetailsSelector?.payload?.email
              },
              {
                id: "set as default",
                label: STRINGS.SETAS_DEF,
                function: handlesSetAsDefault,
                iconLabel: ICON_LABELS.BOOKMARK,
              },
              {
                id: "pin",
                label: STRINGS.PIN,
                function: handlesPin,
                iconLabel: ICON_LABELS.PUSH_PIN,
              },
              {
                id: "activity",
                label: STRINGS.ACTIVITY_LOGS,
                function: handlesGettingActivityLogs,
                iconLabel: ICON_LABELS.VIEW_TIMELINE,
              },
              {
                id: "delete",
                label: STRINGS.DELETE_DASHBOARD,
                function: handlesDelete,
                iconLabel: ICON_LABELS.DELETE,
              },
            ]);
          }
        },
      },
    },
    total_records: DashboardListSelector?.payload?.total_records || 0,
    isLoading: DashboardListSelector?.loading || matchOwner?.length < 1,
    is_selection_not_allowed: false,
    disable_key: STRINGS.DISABLE_KEY,
  };

  const headerItems = [
    {
      id: STRINGS.DASHBOARD,
      label: STRINGS.DASHBOARD,
      path: DASHBOARD_PATH,
    },
    {
      id: STRINGS.DASHBOARD,
      label: STRINGS.ALL_DASHBOARD,
    },
  ];
  const debounceSearch = debounceHelper(handleSearch, DEBOUNCE_DELAY);

  const bulkOperations = (
    <div>
      <Button
        label={STRINGS.DELETE}
        LeftIcon={ICON_LABELS.DELETEICON}
        size={"sm36"}
        variant={"ghost"}
        onClick={handlesBulkDeletePopup}
      />
    </div>
  );

  const standardOperations = (
    <div className={classes.dashBoardTableOperationsAreHere}>
      <div className={classes.dashBoardTableOperationsLeft}>
        <div className={classes.containsSearchAndNothingElse}>
          <SearchField
            value={search}
            size={"sm36"}
            LeftIcon={ICON_LABELS.search}
            onChange={(e) => {
              setSearch(e?.target?.value);
              debounceSearch(e);
            }}
            placeholder={STRINGS.SEARCH}
          />
        </div>
        <div className={classes.containsAutocomplete}>
          <AutoCompleteCustom
            dropdownId="ikuhgfvc"
            // paperWidth="12vw"
            valueState={`${STRINGS.OWNER} ` + selectedOwner}
            setValueState={setSelectedOwner}
            size={"sm36"}
            items={owners}
            idState={[selectedOwnerId]}
            setIdState={setSelectedOwnerId}
            LeftIcon={""}
          />
        </div>
        <div className={classes.containsAutocomplete}>
          <AutoCompleteCustom
            valueState={`${STRINGS.PERMISSIONS} ` + selectedAssignee}
            setValueState={setSelectedAssignee}
            size={"sm36"}
            items={assignees}
            idState={[selectedAssigneeId]}
            setIdState={setSelectedAssigneeId}
            LeftIcon={""}
          />
        </div>
        <div className={classes.containsAutocomplete}>
          <AutoCompleteCustom
            valueState={`${STRINGS.DATE} ` + selectedDateFilter}
            setValueState={setSelectedDateFilter}
            size={"sm36"}
            items={dateFilteringOptionsArray}
            idState={[dateFilterId]}
            setIdState={setDateFilterId}
            LeftIcon={ICON_LABELS.DATE_RANGE}
          />
        </div>
        {bulkOperationsEnabled ? bulkOperations : <></>}
        {(selectedDateFilter[0] !== "All" ||
          selectedAssignee[0] !== "All" ||
          selectedOwner[0] !== "All") && (
            <div className={classes.clearAllStyle}>
              <Button
                size={"xs28"}
                LeftIcon={ICON_LABELS.CLOSE}
                label={STRINGS.CLEAR_ALL}
                variant={"text"}
                onClick={handleClearAllFilter}
              />
            </div>
          )}
      </div>
      <div>
        <Button
          label={STRINGS.CREATE_DASHBOARD}
          LeftIcon={ICON_LABELS.ADD}
          size={"sm36"}
          variant={"primary"}
          onClick={handlesCreateDashboardPopup}
        />
      </div>
    </div>
  );

  //////////////////////////////////////// useEffects /////////////////////////////////

  useEffect(() => {
    // generate a new dataset given the current filters
    const dataCopy = { ...tablePayload };
    dispatch(getDashboardList(navigate, dataCopy));
  }, [tablePayload]);

  useEffect(() => {
    const payload = {
      // sort: paginationDataSelector?.data?.query?.sort,
      limit: paginationDataSelector?.data?.query?.limit || 25,
      page: paginationDataSelector?.data?.query?.page || 1,
      sort_field: paginationDataSelector?.data?.query?.sort?.id,
      sort_direction:
        (paginationDataSelector?.data?.query?.sort?.direction === "asc") * 1,
      // I am the state that is approachingggg
    };

    setTablePayload((cur) => {
      const tempObj = { ...cur };
      tempObj["limit"] = payload.limit;
      tempObj["page"] = payload.page;
      tempObj["sort_field"] = payload.sort_field;
      if (payload?.sort_direction === 1)
        tempObj["sort_direction"] = payload.sort_direction;
      else tempObj["sort_direction"] = "-1";

      return tempObj;
    });
  }, [paginationDataSelector?.data?.query]);

  useEffect(() => {
    const payload = {
      limit: paginationDataSelector?.data?.query?.limit || 25,
      page: paginationDataSelector?.data?.query?.page || 1,
      search: search,
    };
    if (selectedOwner[0] !== "All") payload["owner"] = selectedOwnerId[0];
    if (selectedAssignee[0] !== "All")
      payload["dashboard_type"] =
        selectedAssignee[0] == "Team"
          ? "public-read"
          : selectedAssignee[0].toLowerCase();
    if (selectedDateFilter[0] !== "All") {
      const dateRange = generateDateRange(dateFilterId[0]);
      payload["from"] = dateRange.from;
      payload["to"] = dateRange.to;
    }
    setTablePayload(payload);
  }, [selectedOwner, selectedAssignee, selectedDateFilter]);

  useEffect(() => {
    handlesSettingRecords();
  }, [DashboardListSelector, owners, navigate]);

  useEffect(() => {
    if (updateOwners) {
      const dataCopy = { ...tablePayload };
      getAllOwners();
      setUpdateOwners(false);
      setTablePayload(dataCopy);
    }
  }, [updateOwners]);

  useEffect(() => {
    const dashboardList = [...resolvedRecords];
    const checkedData = [...paginationDataSelector?.data?.checkedRow];
    const defaultDashboard = dashboardList?.filter(item => item?.is_default_dashboard)?.[0] || {};
    const isDefaultSelected = checkedData?.includes(defaultDashboard?._id || "");
    if (checkedData?.length > 0 && !isDefaultSelected) {
      setBulkOperationsEnabled(true);
    } else setBulkOperationsEnabled(false);
  }, [paginationDataSelector?.data?.checkedRow]);

  return (
    <>
      <div className={classes.superContainer}>
        {/* Create Dashboard Modal */}
        <CreateDashboardPopUp
          loading={loading}
          open={createDashboardFlag}
          onClose={() => {
            setCreateDashboardFlag(false);
          }}
          handleCreatePopUp={() => {
            setCreateDashboardFlag(false);
          }}
          createDashboard={createDashboard}
          createErr={createErr}
        />

        {/* Delete Dashboard Modal */}
        <RemoveDashboardPopUp
          open={deleteDashboardFlag}
          onClose={() => {
            setDeleteDashboardFlag(false);
          }}
          loading={loading}
          dashBoard={dashboardForOperations}
          handleRemovePopUp={() => {
            setDeleteDashboardFlag(false);
          }}
          deleteDashboard={deleteDashboard}
        />
        {/* Delete Dashboard Modal */}
        <BulkDeletePopup
          open={scheduleBulkOperation}
          onClose={() => {
            setScheduleBulkOperation(false);
          }}
          loading={loading}
          handlesBulkDeletePopup={() => {
            setScheduleBulkOperation(false);
          }}
          deleteDashboards={handlesBulkDelete}
        />

        {/* Edit Dashboard Modal */}
        <UpdateDashboardPopUp
          loading={loading}
          open={editDashboardFlag}
          onClose={() => {
            setEditDashboardFlag(false);
          }}
          updateErr={updateErr}
          dashBoard={dashboardForOperations}
          updateDashboard={updateDashboard}
          handleUpdatePopUp={() => {
            setEditDashboardFlag(false);
          }}
        />
        {/* Manage Dashboard Access Modal */}
        <ManageAccessPopup
          loading={loading}
          open={manageAccessPopupFlag}
          onClose={() => {
            setManageAccessPopupFlag(false);
          }}
          updateErr={updateErr}
          dashBoard={dashboardForOperations}
          updateDashboard={updateDashboardAccess}
          handleUpdatePopUp={() => {
            setManageAccessPopupFlag(false);
          }}
        />
        {/* Set as default Dashboard Modal */}
        <SetDefaultDashboardPopUp
          open={setDefaultDashboardFlag}
          onClose={() => {
            setSetDefaultDashboardFlag(false);
          }}
          dashBoard={dashboardForOperations}
          loading={loading}
          updateDashboard={setDefaultDashboard}
          handleDefaultPopUp={() => {
            setSetDefaultDashboardFlag(false);
          }}
        />
        {/* Set as default Dashboard Modal */}

        {/* Clone Dashboard Modal */}
        <CloneDashboardPopUp
          open={cloneDashboardFlag}
          onClose={() => {
            setCloneDashboardFlag(false);
          }}
          dashBoard={dashboardForOperations}
          loading={loading}
          createErr={createErr}
          cloneDashboard={cloneDashboard}
          handleClonePopUp={() => {
            setCloneDashboardFlag(false);
          }}
        />

        {/* Dashboard Details Popup*/}

        <DashboardDetailsPopup
          open={viewDashboardDetailsFlag}
          onClose={() => {
            setViewDashboardDetailsFlag(false);
          }}
          dashBoard={dashboardForOperations}
          loading={loading}
          updateErr={updateErr}
          updateDashboard={updateDashboard}
          handlesDashboardDetailsPopup={() => {
            setViewDashboardDetailsFlag(false);
          }}
        />

        {/* Activity Logs Popup */}

        {dashboardActivityPopupFlag && (
          <ActivityLogsPopup
            open={dashboardActivityPopupFlag}
            onClose={() => {
              setDashboardActivityPopupFlag(false);
            }}
            dashBoard={dashboardForOperations}
          />
        )}
        <div className={classes.containsCustomHeader}>
          <CustomHeader
            items={headerItems}
            description={
              records > 0
                ? records > 1
                  ? (records || 0) + ` ${STRINGS.DASHBOARDS}`
                  : (records || 0) + ` ${STRINGS.DASHBOARD}`
                : ""
            }
          />
        </div>
        <div className={classes.tableBoxheder}>{standardOperations}</div>
        {DashboardListSelector?.payload?.is_empty ? (
          <EmptyDashboardScreen openDashboard={handlesCreateDashboardPopup} />
        ) : (
          <div className={classes.tableContainer}>
            <div className={classes.tableStyle}>
              <CustomTable tableData={DashboardListData} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardListScreen;
