import React, { useEffect, useState } from "react";
import { Styles } from "../Styles";
import { useSelector } from "react-redux";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";

const ManageAccessPopup = (props) => {
  const classes = Styles();
  const [selectedValue, setSelectedValue] = useState("");
  const [dashboardName, setDashboardName] = useState();

  useEffect(() => {
    setSelectedValue(props?.dashBoard?.dashboard_type === "team" ? "public-read" :  props?.dashBoard?.dashboard_type || "private");
    setDashboardName(props.dashBoard?.dashboard_name || "");
  }, [props.dashBoard]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const cancelHandle = () => {
    props.handleUpdatePopUp();
  };

  const updateHandle = (e) => {
    let payload = {
      _id: props.dashBoard?._id,
      dashboard_name: dashboardName,
      dashboard_type: selectedValue,
      is_default_dashboard: props.dashBoard?.is_default_dashboard,
    };
    props.updateDashboard(payload, props.handleUpdatePopUp, e);
  };

  const modalHeaderData = {
    heading: {
      id: "head",
      label: STRINGS.MANAGE_ACCESS,
    },
  };
  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.UPDATE}
        size={"sm36"}
        variant={"primary"}
        onClick={updateHandle}
        isLoading={props.loading}
      />
    </div>
  );

  const radioButtonData = [
    {
      id: "private",
      label: STRINGS.PRIVATE_LABEL,
    },
    { id: "public-read", label: STRINGS.EVERYONE_LABEL },
  ];

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
    >
      <form
        onSubmit={(e) => updateHandle(e)}
      >
        <div
          data-tour="dashboardname"
        >
          
        </div>
        <div
          className={classes.permissionsContainer}
          data-tour="sharedashboard"
        >
          <TypographyInline
            label={STRINGS.PERMISSIONS_LABEL}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
          />
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </div>
      </form>
    </CustomModal>
  );
};

export default ManageAccessPopup;