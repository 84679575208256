import React from 'react';
import { Box } from '@mui/system';
import { CircularLoader } from '..';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
    loaderContainer:{
    alignSelf: "center",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
    },
    box:{
      alignSelf: "center" 
    }
})

const FullScreenLoader = () => {

    const classes = styles();

    return <Box className={ classes.loaderContainer }  >
    <Box className={ classes.box } >
      <CircularLoader />
    </Box>
  </Box>
}

export default FullScreenLoader;