import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { CompanyFilterStrip, CompanyHeader, CompanyTable, ContactDropdownModalCompany, EditColumnModal } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { companyDataFetch } from "../../redux/actions/tableActions";
import { debounceHelper, filterQuery } from "../../utils/helpers";
import TablePlaceholder from "./components/TablePlaceholder";
import { DEBOUNCE_DELAY, DROPDOWN_PEOPLE_HEADER_SORTING, tempHeadCells, tempHeadCompanyCells } from "../../utils/constants";
import { HandleClearAllFilterFromStore } from "../../redux/store/storeHelper";
import { STRINGS } from "./strings";
import CustomHeader from "../../components/Header/CustomHeader";
import * as qs from "qs";
import { allFilters } from "../../redux/actions/filterActions";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import SearchField from "../../components/InputFields/SearchField";
import CustomStyledMenu from "../../components/Menu/CustomStyledMenu";
import { GREY } from "../../utils/constants/colors";
import { AutoCompleteCustom } from "../../components/Autocomplete/AutoCompleteCustom";
import TypographyInline from "../../components/Typography/TypographyInline";
import { Button } from "../../components/Buttons";
import { paginationDataAction } from "../../redux/actions/customTableActions";

const CompanyScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const columnsSelector = useSelector((state) => state.ApplyUpdateColumn?.columns);
  const countSelector = useSelector((state) => state.companyBasicDetails);
  const tableDataSelector = useSelector((state) => state.tableDataFetch);
  const querySelector = useSelector((state) => state?.allFilters?.payload);
  const queryLength = Object.keys(filterQuery(querySelector)).length;
  const [querySelectorState, setQuerySelectorState] = useState(null);
  const [sortingId, setSortingId] = useState([]);
  const [sortingDirection, setSortingDirection] = useState([]);
  const [sortByEL, setSortByEL] = useState(null);
  const [searchField, setSearchField] = useState("");
  const [sortingValue, setSortingValue] = useState([]);
  const [sortingDirectionId, setSortingDirectionId] = useState([]);
  const [activeViewId, setActiveViewId] = useState(0);

  const { loading, columns } = tableDataSelector;
  const [addAnotherFilter, setAddAnotherFilter] = useState(false);
  const companyTableEndpointChangeSelector = useSelector(
    (state) => state.companyTableEndpointChange
  );
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const allFiltersSelector = useSelector((state) => state.allFilters);

  const savedContactsTrue =
    companyTableEndpointChangeSelector?.payload === STRINGS.SAVED_COMPANIES
      ? true
      : false;

  useEffect(() => {
    setQuerySelectorState(querySelector);
    if (
      JSON.stringify(querySelector) !== JSON.stringify(querySelectorState) &&
      queryLength
    ) {
      // handlling only sort, page filter in URLS.
      const filteredQuery = filterQuery(querySelector);

      if (
        Object.keys(filteredQuery)?.length <= 3 &&
        filteredQuery.sort &&
        filteredQuery.direction &&
        filteredQuery.sort &&
        !savedContactsTrue
      ) {
        delete filteredQuery[STRINGS.SORT];
        delete filteredQuery.direction;
        delete filterQuery.sort;
        HandleClearAllFilterFromStore(dispatch, location, navigate);
      }

      if (
        Object.keys(filteredQuery)?.length <= 2 &&
        filteredQuery.sort &&
        filteredQuery.direction &&
        !savedContactsTrue
      ) {
        delete filteredQuery[STRINGS.SORT];
        delete filteredQuery.direction;
        HandleClearAllFilterFromStore(dispatch, location, navigate);
      }
      if (
        Object.keys(filteredQuery)?.length <= 1 &&
        filteredQuery.page &&
        !savedContactsTrue
      ) {
        delete filteredQuery.page;
        HandleClearAllFilterFromStore(dispatch, location, navigate);
      }

      // handlling only sort, page filter in URLS.

      setActiveViewId(0);
      dispatch(companyDataFetch(querySelector, navigate));
    }

    if (
      countSelector?.payload?.active_view?.view_id !==
      countSelector?.payload?.default_view?.view_id &&
      activeViewId !== 1
    ) {
      setActiveViewId(1);
    }
  }, [
    navigate,
    querySelectorState,
    querySelector,
    dispatch,
    queryLength,
    countSelector,
    activeViewId,
    location,
  ]);

  useEffect(() => {
    if (Object.keys(paginationDataSelector?.data?.query || {}).length) {
      dispatch(
        allFilters({
          ...paginationDataSelector?.data?.query,
          sort: paginationDataSelector?.data?.query?.sort?.id,
          direction: paginationDataSelector?.data?.query?.sort?.direction,
        })
      );
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...querySelector,
          ...paginationDataSelector?.data?.query,
          sort: paginationDataSelector?.data?.query?.sort?.id,
          direction: paginationDataSelector?.data?.query?.sort?.direction,
        }).toString(),
      });
    }
  }, [paginationDataSelector?.data?.query]);

  const companyTableEndpointChange = useSelector(
    (state) => state.companyTableEndpointChange
  );

  const dataFetchSelector = useSelector((state) => state.tableDataFetch);
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const queryObjectLength = Object.keys(queryObject).length;

  const headerItems = [
    { id: STRINGS.COMPANY_HEADER, label: STRINGS.SINGLE_COMPANY }
  ];
  const headerDescription = `${countSelector?.loading || dataFetchSelector?.loading
    ? STRINGS.LOADING
    : queryObjectLength
      ? countSelector?.payload?.active_view?.is_main_view
        ? dataFetchSelector?.totalRecords?.toLocaleString()
        : dataFetchSelector?.totalRecords?.toLocaleString() ||
        countSelector?.payload?.total_record?.toLocaleString()
      : companyTableEndpointChange.payload === STRINGS.SAVED_COMPANIES
        ? dataFetchSelector?.totalRecords?.toString()
        : ""
    }
    
  ${queryObjectLength ||
      companyTableEndpointChange?.payload === STRINGS.SAVED_COMPANIES
      ? STRINGS.COMPANIES
      : ""
    }`;
  useEffect(() => {
    if (paginationDataSelector?.data?.checkedRow) {
      const setSelected = paginationDataSelector?.data?.checkedRow
      console.log(setSelected)
    }
  }, [paginationDataSelector?.data?.checkedRow]);
  const handleApply = () => {
    if (
      (sortingId && sortingDirectionId && queryObjectLength) ||
      (sortingId &&
        sortingDirectionId &&
        companyTableEndpointChange.payload === STRINGS.SAVED_COMPANIES)
    ) {
      const paginationQuery = {
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          sort: {
            id: sortingId[0],
            direction: sortingDirectionId[0]
          },
        },
      }
      dispatch(paginationDataAction(paginationQuery));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector?.payload,
          sort: sortingId[0],
          direction: sortingDirectionId[0],
        }).toString(),
      });
    }
  };
  const sortingModal = (
    <div style={styles.sortingModal}>
      <div style={styles.sortingDropdownContainer}>
        <TypographyInline
          label={STRINGS.RELEVANCE}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
        />
        <AutoCompleteCustom
          size={"sm36"}
          valueState={sortingValue}
          LeftIcon={""}
          setValueState={setSortingValue}
          idState={sortingId}
          setIdState={setSortingId}
          items={[...tempHeadCells]?.filter((item) => item.companyField)}
          papermenuclass={styles.paperMenu2}

        />
      </div>
      <div style={styles.sortingDropdownContainer}>
        <TypographyInline
          label={STRINGS.ORDER}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
        />
        <AutoCompleteCustom
          size={"sm36"}
          LeftIcon={""}
          valueState={sortingDirection}
          setValueState={setSortingDirection}
          idState={sortingDirectionId}
          setIdState={setSortingDirectionId}
          items={DROPDOWN_PEOPLE_HEADER_SORTING}
          paperHeight={"fit-content"}
          papermenuclass={styles.paperMenu2}

        />
      </div>

      <div style={styles.containApplyBtn}>
        <Button
          label={STRINGS.APPLY}
          size={"sm36"}
          variant={"primary"}
          onClick={handleApply}
          disabled={!(sortingId.length && sortingDirection.length)}
        />
      </div>
    </div>
  );

  const sortFunction = () => {
    setSortingValue([])
    setSortingId([])
    setSortingDirection([])
    setSortingDirectionId([])
  }

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (e?.target?.value) {
      dispatch(allFilters({ search: e?.target?.value, page: 1, limit: 25 }));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector?.payload,
          search: e?.target?.value,
        }).toString(),
      });
    } else {
      dispatch(allFilters({ search: e?.target?.value }));
      const query = {
        ...allFiltersSelector?.payload,
        search: e?.target?.value,
      };
      delete query?.page;
      delete query?.limit;
      delete query?.search;
      navigate({
        pathname: location.pathname,
        search: createSearchParams(query).toString(),
      });
    }
  };
  const debounceSearch = debounceHelper(handleSearchSubmit, DEBOUNCE_DELAY);

  return (
    <Box sx={styles.container}>
      <CustomHeader items={headerItems} description={headerDescription} />
      <CompanyHeader />
      <Box sx={styles.justifyContentCenter}>
        <Box sx={styles.filterContain}>
          <ContactDropdownModalCompany
            clearSearch={setSearchField}
          />

          <CompanyFilterStrip
            isOpenFromOutside={addAnotherFilter}
            setOpenFromOutside={setAddAnotherFilter}
            clearSearch={setSearchField}
            clearSort={sortFunction}
          />
        </Box>
      </Box>

      <Box>  <div style={styles.talbeActionsContainer}>
        <form onSubmit={handleSearchSubmit} style={styles.commonWidth}>
          <SearchField
            size={"sm36"}
            LeftIcon={"search"}
            value={searchField}
            onChange={(e) => {
              debounceSearch(e);
              setSearchField(e?.target?.value)
            }}
            placeholder={STRINGS.SEARCH}
          />
        </form>
        <div style={styles.tableActionRightContainer}>
          <EditColumnModal leadType={STRINGS.SINGLE_COMPANY} />

          {/* Sort By Button */}
          <div>
            <Button
              LeftIcon={ICON_LABELS.SORT}
              label={STRINGS.SORT_BY}
              size={"sm36"}
              variant={"ghost"}
              disabled={(!(queryObjectLength > 0) && companyTableEndpointChangeSelector.payload !== STRINGS.SAVED_COMPANIES)}
              onClick={(e) => setSortByEL(e.currentTarget)}
            />
            <CustomStyledMenu
              noMargin={true}
              open={Boolean(sortByEL)}
              anchorEl={sortByEL}
              onClose={() => setSortByEL(null)}
            >
              {sortingModal}
            </CustomStyledMenu>
          </div>
        </div>
      </div>


      </Box>

      {loading || savedContactsTrue ? (
        <CompanyTable
          heads={columnsSelector || JSON.parse(JSON.stringify(tempHeadCompanyCells))}
          records={tableDataSelector?.columns || []}
          savedContactsTrue={savedContactsTrue}
        />
      ) : columns && queryLength > 0 ? (
        false ? (
          // <NoRecord setOpen={setAddAnotherFilter} />
          <></>
        ) : (
          <CompanyTable
            heads={columnsSelector || JSON.parse(JSON.stringify(tempHeadCompanyCells))}
            records={tableDataSelector?.columns || []}
          />
        )
      ) : (
        <TablePlaceholder
          records={countSelector?.payload?.total_record}
          title={STRINGS.COMPANIES}
          setAddAnotherFilter={setAddAnotherFilter}
        />
      )}
    </Box>
  );
};

export default CompanyScreen;
