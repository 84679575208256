import * as React from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import IconComponent from "../Icon/IconComponent";
import { ERROR, GREY } from "../../utils/constants/colors";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import TypographyInline from "../Typography/TypographyInline";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import classes from "./AutoComplete.module.css";

const Root = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
    };
  font-size: 14px;
  width: 100%;
`
);

const InputWrapper = styled("div")(
  ({ theme }) => `
  border: 1px solid #DBDBDB;
  background-color: #fff;
  border-radius: 6px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 4px;
  border: 1px solid #DBDBDB; 
  &:hover {
    border: 1px solid #a1a0a3;
  }
  &:disabled {
    border: 1px solid transparent;
  }
  &.focused {
    border: 1px solid #222124;
    box-shadow: 0px 0px 0px 3px #EBECF0;
  }
  
  &.error{
    border: 1px solid #E73F3F;
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: #5F5F61;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <TypographyInline
        color={GREY.SECONDARY}
        label={label}
        size={"sm"}
        fontWeight={fontWeight.REGULAR}
        isEllipses
      />
      <IconComponent
        color={GREY.SECONDARY}
        fontSize={fontSize.MD}
        iconLabel={ICON_LABELS.CLOSE}
        onClick={onDelete}
      />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 2px;
    background-color:#E8E8E8;
  border-radius: 6px;
  box-sizing: content-box;
  padding: 3px 10px;
  outline: 0;
  overflow: hidden;
  margin-right: 4px;

  & span {
    cursor:pointer;
  }
`
);

const Listbox = styled("ul")(
  ({ theme, width }) => `
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  width: ${width || "100%"};
  

  & li {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & .selectedList {
    font-weight: 600;
    box-shadow: inset 2px 0px 0px 0px #ff4848 !important;
  }

  & li.${autocompleteClasses.focused} {
    background-color: #FCEAEA;
    cursor: pointer;
    box-shadow: inset 2px 0px 0px 0px #ff4848;
  }
`
);

export default function CustomAutoCompleteChip({
  id = "autocomplete-chip",
  items = [],
  onChange = () => { },
  inputOnchange = () => { },
  placeholder = "Select...",
  errormessage = "",
  variant = "default",
  defaultValues = [],
  multiple = true,
}) {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: id,
    multiple: multiple,
    options: items,
    defaultValue: defaultValues,
    getOptionLabel: (option) =>
      option.label || option.email || option.name || "",
  });

  const [width, setWidth] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [isMount, setIsMount] = React.useState(false);

  let inputValue = getInputProps()?.value;

  React.useEffect(() => {
    inputOnchange(inputValue);
  }, [inputValue]);

  React.useEffect(() => {
    let wrapperWidth = `${document?.getElementById(id)?.clientWidth}px`;
    setWidth(wrapperWidth);
  }, []);

  React.useEffect(() => {
    if (multiple) {
      let selected = value?.map((item) => {
        return item?.email;
      });
      setSelectedValue(selected);
      setIsMount(true);
    } else {
      onChange(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (multiple && !value?.length) {
      let selected = defaultValues?.map((item) => {
        return item?.email;
      });
      setSelectedValue(selected);
    }

    // onChange(value);
  }, [defaultValues]);

  React.useEffect(() => {
    if (isMount) {
      onChange(value);
      setIsMount(false);
    }
  }, [selectedValue]);

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          id={id}
          ref={setAnchorEl}
          className={focused ? `focused ${variant}` : `${variant}`}
        >
          {multiple
            ? value.map((option, index) => (
              <StyledTag
                label={option?.label || option?.email}
                {...getTagProps({ index })}
              />
            ))
            : null}

          <input placeholder={placeholder} {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox
          id="modal_body"
          className={classes.scrollList}
          width={width}
          {...getListboxProps()}
        >
          {groupedOptions.map((option, index) => (
            <li
              className={
                selectedValue?.includes(option?.email) ? "selectedList" : ""
              }
              {...getOptionProps({ option, index })}
            >
              {multiple && (
                <Checkbox
                  checked={
                    selectedValue?.includes(option?.email) ||
                    inputValue === option.email
                  }
                />
              )}

              <span>{option?.label}</span>
            </li>
          ))}
        </Listbox>
      ) : null}
      {variant === "error" && errormessage && (
        <TypographyInline
          label={errormessage}
          color={ERROR.W_500}
          size={"xs"}
          fontWeight={fontWeight.REGULAR}
        />
      )}
    </Root>
  );
}
