import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS, tempHeadCells } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { filterDynamicQuery, getErrorMessage } from "../../../../utils/helpers";
import { getRequest, postRequest } from "../../../../Apis";
import { newLeadsEndPoints } from "../../../../utils/constants/httpConstants";
import { STRINGS } from "../../strings";
import { Button } from "../../../../components/Buttons";
import CustomModal from "../../../../components/Modals/CustomModal";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import InputField from "../../../../components/InputFields/InputField";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import Checkbox from "../../../../components/Buttons/Checkbox/Checkbox";
import classes from "./CrmComp.module.css";
import { getPeopleLeadDetails } from "../../../../redux/actions/peopleActions";
import TypographyInline from "../../../../components/Typography/TypographyInline";

const AddViewModal = ({
  open,
  setOpen,
  isRename,
  renamePopupValue,
  setPublicViews = () => { },
  setPrivateViews = () => { },
  setTeamViews = () => { },
  setViewCardMenu = () => { },
  setPrivateViewCardMenu = () => { },
  getViews = () => { },
  viewType = ""
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewName, setViewName] = useState(
    renamePopupValue?.eachView?.view_name || ""
  );
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const allDynamicFilterSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );
  const filtersQuery = filterDynamicQuery(allDynamicFilterSelector);

  const tableColumnsSelector = useSelector(
    (state) => state?.dynamicColumnUpdate?.columns
  );
  useEffect(() => {
    setViewName(renamePopupValue?.eachView?.view_name || "");
    setSelectedValue(renamePopupValue?.type || viewType || STRINGS.PRIVATE_ID);
    setIsPin(renamePopupValue?.eachView?.is_pin);
  }, [renamePopupValue]);

  const radioButtonData = [
    { id: STRINGS.PRIVATE_ID, label: STRINGS.PRIVATE_LABEL },
    { id: STRINGS.TEAM_ID, label: STRINGS.TEAM_LABEL },
  ];
  const tableColumns = tableColumnsSelector
    ?.filter((item) => item?.isChecked && !item?.is_deal)
    .map((item) => item?.id);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(!open);
    setErrMsg("");
    setViewName("");
  };

  const handleCreate = async () => {
    const update_view_id = renamePopupValue?.eachView?.view_id || "";
    if (viewName?.length === 0) {
      setErrMsg(STRINGS.REQUIRED);
      return;
    }
    if (viewName?.length >= 50) {
      setErrMsg(STRINGS.VIEW_CANT_25CHAR);
      return;
    }

    setErrMsg("");
    const payload = {
      view_name: viewName,
      view_type: "private" || selectedValue,
      query_string: filtersQuery,
      is_pin: isPin ? 1 : 0,
      table_columns: tableColumns || [
        ...tempHeadCells
          .map((item) => item.id)
          .filter((item) => item.isChecked),
      ],
    };
    setLoading(true);
    try {
      let URL = "";
      if (update_view_id?.length) {
        URL = newLeadsEndPoints.updateLeadViewproperty;
      } else {
        URL = newLeadsEndPoints.createLeadView;
      }

      if (update_view_id.length) {
        payload.view_id = update_view_id;
        delete payload?.query_string;
        delete payload?.table_columns;
      }

      const response = await postRequest(navigate, URL, payload);
      const URL1 = newLeadsEndPoints.getAllLeadView;
      const response1 = await getRequest(navigate, URL1);
      setPublicViews(response1?.data?.data?.public);
      setPrivateViews(response1?.data?.data?.private);
      setTeamViews(response1?.data?.data?.team);
      setViewCardMenu(null);
      setPrivateViewCardMenu(null);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setViewName("");
      getViews();
      setLoading(false);
      dispatch(getPeopleLeadDetails(navigate));
      setOpen(false);
    } catch (error) {
      console.log("errors______", error);
      setLoading(false);
      // setOpen(false);
      setErrMsg(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: isRename ? STRINGS.EDIT_VIEW : STRINGS.CREATE_NEW_VIEW,
    },
  };

  const modalBodyData = (
    <div className={classes.bodyEditViewContainerView}>
      <Box className={classes.containInput}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.VIEW_NAME}
        />

        <InputField
          placeholder={STRINGS.WRITE_VIEW_NAME}
          value={viewName}
          size={"sm36"}
          onChange={(e) => setViewName(e.target.value)}
          variant={errMsg?.length ? STRINGS.ERROR : STRINGS.DEFAULT}
          errormessage={errMsg}
        />
      </Box>

      {/* <Box className={classes.sharedWithBox}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.SHARED}
          isRequired
        />
        <Box className={classes.containRadioBtns}>
          <Box className={classes.eachRadioContainer}>
            <RadioButton
              value={selectedValue}
              onChange={handleChange}
              items={radioButtonData}
            />
          </Box>
        </Box>
      </Box> */}
    </div>
  );

  const modalFooterRightData = (
    <div className={classes.modalEditViewFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={handleClose}
      />

      <Button
        label={isRename ? STRINGS.UPDATE : STRINGS.SAVE}
        size={"sm36"}
        variant={"primary"}
        onClick={() => {
          handleCreate();
        }}
        isLoading={loading}
      />
    </div>
  );

  const modalFooterLeftData = (
    <div className={classes.modalFooterLeftContainer}>
      <Checkbox
        label={STRINGS.PIN_THIS_VIEW}
        checked={isPin}
        onChange={() => setIsPin(!isPin)}
      />
    </div>
  );

  return (
    <CustomModal
      close={handleClose}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default AddViewModal;
