export const STRINGS = {
  LOGOUT: "Logout",
  ENter_First: "Enter First Name",
  Enter_Last: "Enter Last Name",
  JOB_TITLE: "Job Title*",
  TIMEZONED: "Timezone",
  PHONE: "Phone Number*",
  ENter_phone: "Enter Phone Number",
  Enter_Postal: "Enter Postal/Zip",
  Enter_CITY: "Enter City",
  STATE: "State*",
  ADDRESS_LINE1: "Address Line 1*",
  Enter_ADd: " Enter Address Line 1",
  Enter_add1: "Enter Address Line 2",
  ADDRESS_LINE2: "Address Line 2",
  CITY: "City*",
  POSTAL: "Postal/Zip*",
  COUNTRY: "Country*",
  ENTER_JOB: "Enter Job Title",
  LINKED: "LinkedIn Profile URL",
  Enter_linked: "Enter LinkedIn Profile URL",
  LAST_NAME: "Last Name*",
  LIST_TEXT: "Contact Lists",
  LIST_CREATE: "List",
  LIST_REMT: "listRemove",
  TOUR_LIST: "createlist",
  TEMPLATE_TEXT: "Templates",
  TEMPLATE_CREATE: "Template",
  SUBJECT: "Subject",
  SUBJECT1: "Subject:",
  SEQUENCE_TEXT: "Sequences",
  SMALL_SEQUENCES: "sequences",
  SEQUENCE_CREATE: "Sequence",
  DEL_SEQ: "delSeq",
  REM_SEQ: "sequenceRemove",
  REM_CAM: "campaignRemove",
  CREATE_SEQ: "Create Sequence",
  SEQUENCE_NAME: "Sequence Name*",
  TYPE_SEQ: "sequence",
  TYPE_TEMP: "template",
  TYPE_CREATE: "create",
  TYPE_CAMP: "campaign",
  TOUR_SEQUENCE: "createsequance",
  CAMPAIGN_TEXT: "Campaigns",
  CAMPAIGN_CREATE: "Campaign",
  TOUR_CAMPAIGN: "createcampaigns",
  COMMON_CREATE: "Create",
  CREATE_TEMPATE: "Create Template",
  COMMON_CANCEL: "Cancel",
  COMMON_UPDATE: "Update",
  COMMON_EDIT: "Edit",
  COMMON_SAVE: "Save",
  FOLDER_DETAILS: "Folder Details",
  CREATE_FOLDER: "Create New Folder",
  ENTER_FOLDER: "Enter Folder Name*",
  CREATE_FOLD: "Create Folder",
  EDIT_FOLD: "Edit Folder",
  MOVE_TO: "Move to",
  DELETE: "Delete",
  SMALL_DELETE: "delete",
  EDIT: "edit",
  STEPS_CREATED: "steps created",
  STATISTICS: "STATISTICS",
  COPYOF: "Copy of",
  MOVEUP: "Move Up",
  MOVEDOWN: "Move Down",
  EDIT_SEQUENCE: "Edit Sequence",
  CLONE_SEQUENCE: "Clone Sequence",
  DELETE_CONFIRMATION: "Are you sure you want to Delete this",
  DELETE_SELECTED: "Delete selected",
  DELETE_BULK: "Are you sure you want to delete the selected",
  COMMON_FOLDER: "Folder",
  TEMP_UPDATE: "Update Template",
  SAVE_TEMP: "Save Template",
  MOVE: "Move",
  SENDMAIL: "Send mail to myself",
  BACK: "Back",
  START_CAMP: "Start Campaign Now",
  VIEW_STATUS: "View By Status",
  ADD_WIDGETS: "Add Widgets",
  ACTIONS: "Actions",
  SET_DEFAULT: "Set as Default",
  COMMON_CLONE: "Clone",
  CLONE: "clone",
  SUBJECT_REQUIRED: "Subject is required.",
  SAVE_DASHBOARD: "Save Dashboard Layout",
  CREATE_WIDGET: "Create Widget",
  SAVE_REPORT: "Save Report",
  SAVE_ADD: "Save & Add",
  SAVE_DETAILS: "Save Details",
  UPDATE_PASSWORD: "Update Password",
  FILTER: "Filter",
  RESET: "Reset",
  APPLY: "Apply",
  ADD_CONTACT: "Add Contact",
  ADD_FILE: "Add File",
  ADD_DOMAIN: "Add Domain",
  REQUIRED: "This is a required field.",
  VIEW_CANT_25CHAR: "View Name can't be more than 25 charecters.",
  LIST_SHOULDBE_THREE: "List name should be atleast 3 characters.",
  LIST_SHOULDBE_FIFTY: "List name should be less than or equal to 50",
  FOLDER_NAME_THREE: "Folder name should be atleast 3 characters.",
  FOLDER_NAME_EXCEED: "Folder name should be less than or equal to 50",
  COMMON_NAME: "Name*",
  TEAM_ID: "team",
  PRIVATE_ID: "private",
  TEAM_LABEL: "My Team",
  PRIVATE_LABEL: "Private",
  CREATE_LISST: "Create List",
  ADMIN_DETAILS:"Admin Details Updated Successfully ",
  USER_DETAIL:"User Detail Updated Successfully ",
  LISTID: "_name",
  LISTNAME: "Contact List Name*",
  TYPELIST: "Type Contact List name",
  TYPESEQUENCE: "Type Sequence name",
  SHARED_WITH: "Shared with*",
  DEL: "del",
  FILE: "file",
  SEARCH_FOLDER: "Search Folder",
  SEARCH_LIST: "Search List",
  TRUE: "true",
  FOLDER_ACCESS: "Folder Access",
  COMMON_VIEWALL: "View all",
  FOLDER_MNGMT: "Folder Management",
  DESELECT: "Deselect All",
  SELECT_ALL: "Select All",
  SMALL: "small",
  FIRST: "first",
  VERTICAL_ICON: "Vertical Icon",
  BULK_DEL_TYPE: "foldListDel",
  SMALL_FOLDER: "folder",
  RECORD_NOT_FOUND: "Records not found.",
  SELECT_ANY_FOLD: "Please select any  Folder",
  SELECT_ATLEAST: "Please select at least one  List",
  SELECT_ATLEAST_SEQUENCE: "Please select at least one  Sequence",
  SELECT_ANY_LIST: "Please select any one list.",
  MOVE_TO_FOLDER: "Move to folder",
  NOFOLDER_FOUND: "No folder found!",
  RM_FROM_FOLDER: "Remove from Folder",
  ADMIN_USER: "admin-user",
  ATLEAST_ONE_FOLDER: "Select atleast one folder",
  REQUIRED_FIELD: "This is a required field.",
  ATLEAST_THREE: "Sequence name length must be at least 3 characters long",
  EXCEED_CHAR: "Sequence Name cannot be more than 150 characters",
  TEMP_EXCEED_CHAR: "Template Name cannot be more than 150 characters",
  EDIT_TEMPLATE: "Edit Email Template",
  CREATE_EMAIL_TEMPLATE: "Create Email Template",
  SOMETHING_WENT_WRONG: "Something went wrong",
  CHOOSE_STEPS_TEXT:
    "Sequences are a series of automated or manual touchpoints and activities, designed to drive deeper engagement with your prospects.",
  CHOOSE_HEAD: "Choose Steps For ( Sequence Name )",
  COMMON_ADD: "Add",
  AUTOMATED_EMAIL: "Automated email",
  ENTER_SUBJECT: "Enter Subject",
  Enter_State: "Enter State",
  BODY: "Body",
  PREVIEW: "Preview",
  TEMPLATE_NAME: "Template name:",
  SAVE_NEW_TEMPLATE: "Save as a new Template",
  NEW_TEMPLATE: "New Template",
  ENTER_TEMPLATE_NAME: "Enter template name",
  CALL: "Call",
  TASK_TITLE: "Task Title*",
  NOTES: "Notes",
  CALL_VAL: "Call contact to follow up",
  TODO: "To Do",
  INTERVALS: "Interval (in days)*",
  DELETE_STEP_CONFIRMATION: "Are you sure you want to delete this step?",
  STEP: "step",
  TEMP_FOLD: "Template Folders",
  SEARCH_TEMPLATE: "Search Template",
  DEFAULT_NAME: "Lennister Ann",
  EMAIL_TEMPLATE: "Email Template",
  TEMPLATE_NAME1: "Template Name*",
  OWNER: "Owner",
  SEARCH_CAMPAIGN: "Search Campaign",
  CREATE_CAMPAIGN: "Create Campaign",
  CAMPAIGN_DESCRIPTION: "Follow 5 simple Steps to create your campaign",
  CAMP_BASIC_DETAILS: "Campaign Basic Details",
  SELECT_SEQ_LIST: "Select Sequence and List",
  SKIPPED_CONTACTS: "Skipped Contacts",
  TIMINGS: "Timings",
  REPLY: "reply",
  CONNECT_GOOGLE: "Connect with Google",
  CONNECT_YOUR_GMAIL:
    "Connect your Gmail inbox in order to start your campaigns",
  CONNECT: "Connect",
  SCHEDULE_CAMPAIGN: "Schedule Campaign",
  COMMON_NEXT: "Next",
  STEP15: "Step 1 / 5",
  STEP25: "Step 2 / 5",
  STEP35: "Step 3 / 5",
  STEP45: "Step 4 / 5",
  STEP55: "Step 5 / 5",
  CAMPAIGN_NAME: "Campaign Name*",
  ENTER_CAMP_NAME: "Enter Campaign Name",
  SENDVIA: "Send Via*",
  SEQ_TOBE_USED: "Sequence to be used*",
  LIST_TOBE_USED: "List to be used*",
  NO_CONTACT: "No Contacts Skipped from selected list.",
  SCHEDULE_DATE: "Schedule Date",
  TIMEZONE: "Time Zone",
  DAYS: "Days",
  FIRST_NAME: "First Name*",
  START_TIME: "Start Time",
  END_TIME: "End Time",
  STEP1: "Step 1:",
  CAM_NAME: "Campaign Name:",
  SENVIA: "Send Via:",
  STEP2: "Step 2:",
  SEQUEN: "Sequence:",
  LIS: "List:",
  STEP3: "Step 3:",
  SKPCON: "Skipped Contacts:",
  STEP4: "Step 4:",
  SCHDAT: "Schedule Date:",
  TMZN: "Time Zone:",
  DY: "Days:",
  CONTACTS: "Contacts",
  CONTACT: "Contact",
  TOTAL_ENROL: "total_enrolled",
  DEL_CONFIRM: "Are you sure you want to Delete this Template?",
  DEL_CONFIRM1: "Are you sure you want to Delete this Folder?",
  PREVIEW_TEMP:
    "This is a preview for an email template. Hit edit if you wish to update the template.",
  PLZ_SEL_FOL: "Please select any Folder",
  PLZ_SEL_TEMP: "Please select at least one  Template",
  TEMP_PRV: "Template Preview",
  TO: "To:",
  TEMP_REM: "templateRemove",
  REM_SELECTED: "Remove selected",
  REM_DATA: "Are you sure you want to remove",
  FROM_FOLD: "from Folder",
  FOLDEL: "foldDel",
  CMFOL: "campaignfolder",
  SQFOL: "sequencefolder",
  FOLSUC: "Folders deleted successfully",
  FOLDL: "Folders deleted",
  DLSQ: "delSeq",
  CMBULKDEL: "campaignBulkDelete",
  UNDEFINED: "undefined",
  PRMLDATA: "previewMailBodyData",
  ERROR: "error",
  DEFAULT: "default",
  COMPANY_NAME: "Company Name*",
  Enter_Comp: "Enter Company Name",
  COMPANY_WEB: "Company Website*",
  ENTER_WEB: "Enter Company Website",
  USER_PROFILE: "User Profile",
  USER_PROFILE_DESC: "This applies across any ReachIQ accounts you have.",
  ACCOUNT_DETAILS: "Account Details",
  ACC_DESC: "This applies across any ReachIQ accounts you have.",
  GENERAL:"General",
  DATE_FORMAT:"Date Format*",
  CURRENCY:"Currency*",
  THIS_APPLIES:"This applies across any ReachIQ accounts you have.",
  
  
};
