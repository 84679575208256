import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FullScreenLoader } from "../../components";
import {
  DASHBOARD_PATH,
  INITIAL_ROUTE,
} from "../../utils/constants/routes";
import { POST } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS, tenants_api_endpoints } from "../../utils/constants";
import { authEndpoints } from "../../utils/constants/httpConstants";
import { useDispatch } from "react-redux";
import { checkLogin, userDetails } from "../../redux/actions/authActions";

const Loading = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        await dispatch(checkLogin(navigate, tenants_api_endpoints));
      } catch (error) {
        console.log('eerror', error);
      }
    })();
  }, [navigate, dispatch]);

  return <FullScreenLoader />;
};

export default Loading;
