import React, { useState } from "react";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import { Button } from "../../../../components/Buttons";
import classes from "../PipelineScreen.module.css";
import { deleteRequest } from "../../../../Apis";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import { useNavigate } from "react-router-dom";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";

const DeletePipeline = ({
  deleteOpen,
  setDeleteOpen,
  clickedId,
  setClickedId,
  handleGetApi,
}) => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const handleDeletePipeline = async () => {
    try {
      setLoader(true);
      const payload = {
        query: {
          id: clickedId,
        },
      };
      const response = await deleteRequest(
        crmEndpoints.deletePipeline,
        payload,
        navigate
      );

      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

      setLoader(false);
      setDeleteOpen(!deleteOpen);
      setClickedId("");
      handleGetApi(false, true, false);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      setDeleteOpen(!deleteOpen);

      setLoader(false);
    } finally {
      setLoader(false);
    }
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.SMALL_HEAD,
      label: `Delete Pipeline`,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <TypographyInline
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={STRINGS.ARE_YOU_SURE}
        size={"sm"}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setDeleteOpen(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={handleDeletePipeline}
        isLoading={loader}
      />
    </div>
  );
  const modalFooterLeftData = <div> </div>;
  return (
    <CustomModal
      variant="error"
      close={() => setDeleteOpen(false)}
      open={deleteOpen}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default DeletePipeline;
