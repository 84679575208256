import {
  PEOPLE_BASIC_DETAILS_FAIL,
  PEOPLE_BASIC_DETAILS_REQUEST,
  PEOPLE_BASIC_DETAILS_SUCCESS,
  PEOPLE_USER_DETAILS_FAIL,
  PEOPLE_USER_DETAILS_REQUEST,
  PEOPLE_USER_DETAILS_SUCCESS,
  PEOPLE_BASIC_LEAD_DETAILS_REQUEST,
  PEOPLE_BASIC_LEAD_DETAILS_SUCCESS,
  PEOPLE_BASIC_LEAD_DETAILS_FAIL,
  PEOPLE_BASIC_ACCOUNT_DETAILS_SUCCESS,
  PEOPLE_BASIC_ACCOUNT_DETAILS_REQUEST,
} from "../constants/peopleConstants";
import {
  accountEndPoints,
  leadsEndpoints,
  newLeadsEndPoints,
} from "../../utils/constants/httpConstants";
import { getRequest, postRequest } from "../../Apis";
import { LOGIN_PATH } from "../../utils/constants/routes";

export const getPeopleBasicDetails =
  (navigate, query = "", data = {}) =>
    async (dispatch) => {
      try {
        if (query === true) {
          dispatch({
            type: PEOPLE_BASIC_DETAILS_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({ type: PEOPLE_BASIC_DETAILS_REQUEST });
          const response = await getRequest(
            navigate,
            leadsEndpoints.peopleBasic + (query?.length ? query : "")
          );

          dispatch({
            type: PEOPLE_BASIC_DETAILS_SUCCESS,
            payload: response?.data?.data,
          });
        }
      } catch (err) {
        if (err?.status === 401) {
          // navigate from this action
          navigate(LOGIN_PATH);
        }
        dispatch({ type: PEOPLE_BASIC_DETAILS_FAIL });
      }
    };

export const getPeopleLeadDetails = (navigate, query = "", data = {}) => async (dispatch) => {
  try {
    if (query === true) {
      dispatch({
        type: PEOPLE_BASIC_LEAD_DETAILS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({ type: PEOPLE_BASIC_LEAD_DETAILS_REQUEST });
      const response = await getRequest(
        navigate,
        newLeadsEndPoints.getInitialViews + (query?.length ? query : "")
      );

      dispatch({
        type: PEOPLE_BASIC_LEAD_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    }
  } catch (err) {
    if (err?.status === 401) {
      // navigate from this action
      navigate(LOGIN_PATH);
    }
    dispatch({ type: PEOPLE_BASIC_LEAD_DETAILS_FAIL });
  }
};

export const getPeopleAccountDetails = (navigate, query = "", data = {}) => async (dispatch) => {
  try {
    if (query === true) {
      dispatch({
        type: PEOPLE_BASIC_ACCOUNT_DETAILS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({ type: PEOPLE_BASIC_ACCOUNT_DETAILS_REQUEST });
      let response = await getRequest(
        navigate,
        accountEndPoints.companyInitialViews + (query?.length ? query : "")
      );
      if (!response.data.data.default_view_exists) {
        // 1. hit create default view api.
        const create_default_view_ack = await postRequest(navigate, accountEndPoints.createDefaultView, {});
        response = await getRequest(navigate, accountEndPoints.companyInitialViews + (query?.length ? query : ""));
      }

      dispatch({
        type: PEOPLE_BASIC_ACCOUNT_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    }
  } catch (err) {
    if (err?.status === 401) {
      // navigate from this action
      navigate(LOGIN_PATH);
    }
    dispatch({ type: PEOPLE_BASIC_LEAD_DETAILS_FAIL });
  }
};

export const getPeopleUserDetails =
  (id, navigate) => async (dispatch, getState) => {
    try {
      dispatch({ type: PEOPLE_USER_DETAILS_REQUEST });
      const URL =
        getState()?.tableEndpointChange?.payload === "Saved Prospects"
          ? `${leadsEndpoints.savedUserDetails}?user_id=${id}`
          : `${leadsEndpoints.userDetails}?user_id=${id}`;
      const response = await getRequest(navigate, URL);
      dispatch({
        type: PEOPLE_USER_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    } catch (err) {
      // log user out here.
      if (err?.status === 401) {
        // navigate from this action
        navigate(LOGIN_PATH);
      }

      dispatch({ type: PEOPLE_USER_DETAILS_FAIL });
    }
  };
