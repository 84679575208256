// Path constants
export const HOME_PATH = "/";

export const LOGIN_PATH = "/auth/login";
export const LOGIN_PATH_WITH_EMAIL = "/auth/login/:email";
export const REGISTER_PATH_WITH_INVITE = "/auth/register/:id";
export const REGISTER_PATH_WITH_GOOGLE = "/auth/google-signup";
export const SIGNUP_PATH = "/auth/signup";
export const FORGOT_PASSWORD_PATH = "/auth/forgot_password";
export const EMAIL_VERIFICATION_PATH = "/auth/email_verify/:id";
export const FORGOT_PASSWORD_MAIL_SEND_PATH = "/auth/reset_password/verify/:id";
export const UNSUBSCRIBE_SUCCESSFUL_PATH = "/auth/unsubscribe-successful";
export const CALENDAR_PATH = "/auth/meetings/:custom_id";
export const CALENDAR_PATH_CLONE = "/auth/meetings/:custom_id/:id";

// Leads routes
export const PEOPLE_SCREEN_PATH = "/user/people";
export const IMPORT_PATH = "/user/import";
export const BLACKLIST_IMPORT_PATH = "/user/import/balcklist";
export const DYNAMIC_IMPORT_PATH = "/user/import/custom-field";
export const COMPANY_SCREEN_PATH = "/user/company";
export const TASK_PATH = "/user/tasks";
export const SNIP_PATH = "/user/snip";
export const ACCOUNT_PATH = "/user/account";

// Meeting routes
export const MEETING_PATH = "/user/meeting";
export const CHROME_PATH = "/user/chrome";
export const MEETINGSTEPS_PATH = "/user/create-meeting-steps";
export const UPDATE_MEETING_PATH = "/user/create-meeting-steps/:meetingId";
export const CALENDAR_CONNECT = "/user/create-meeting-steps?calendar-connect";
export const SNIPPET_PATH = "/user/snippet";
export const SNIPPET_FOLDER_PATH = "/user/snippet/:id";

// Marketing routes
export const MARKETING_PROSPECT_SCREEN_PATH = "/user/maketing-contacts";
export const LIST_PATH = "/user/lists";
export const SEQUENCE_PATH = "/user/sequence";
export const SEQUENCE_PATH_WITH_PARENT = "/user/sequence/:fold";
export const DEFAULT_VALUES_PATH = "/user/default-values";
export const TEMPLATE_PATH = "/user/template";
export const CREATE_TEMPLATE_PATH = "/user/template/create";
export const CREATE_TEMPLATE_IN_FOLDER_PATH = "/user/template/:id/create";
export const UPDATE_TEMPLATE_IN_FOLDER_PATH =
  "/user/template/update/:tempId/:id";
export const FOLDER_TEMPLATE_PATH = "/user/template/:id";
export const FOLDER_LIST_PATH = "user/lists/:id";
export const UPDATE_TEMPLATE_PATH = "/user/template/update/:tempId";
export const PREVIEW_TEMPLATE_PATH = "/user/template/update";
export const EMAIL_MARKETING_STEPS_PATH_PREFIX = "/user/sequence/steps";
export const EMAIL_MARKETING_STEPS_PATH_POSTFIX = "/:id";

export const EMAIL_MARKETING_STEPS_PATH =
  EMAIL_MARKETING_STEPS_PATH_PREFIX + EMAIL_MARKETING_STEPS_PATH_POSTFIX;
export const STEPS_WITH_FOLDER_PATH = SEQUENCE_PATH + "/:folderId/steps/:id";
export const EMAIL_MARKETING_CAMPAIGN_PATH = "/user/campaigns";
export const EMAIL_MARKETING_CAMPAIGN_PATH_WITH_PARENT = "/user/campaigns/:id";
export const EMAIL_MARKETING_CAMPAIGN_OVERVIEW_PATH =
  "/user/campaigns/:campaignId/sequence";
export const EMAIL_MARKETING_CAMPAIGN_CONTACTS_PATH =
  "/user/campaigns/:campaignId/contacts";
export const EMAIL_MARKETING_CAMPAIGN_ACTIVITY_PATH =
  "/user/campaigns/:campaignId/activity";
export const EMAIL_MARKETING_CAMPAIGN_SETTINGS_PATH =
  "/user/campaigns/:campaignId/settings";

//Profile Routes
export const PROFILE_PATH = "/user/settings";
export const PROFILE_NOTIFICATION_PATH = "/user/profile/notification";
export const PROFILE_SECURITY_PATH = "/user/profile/security";
export const PROFILE_APIKEYS_PATH = "/user/profile/apikeys";
export const PROFILE_ACCOUNT_DEFAULTS_PATH = "/user/profile/account_defaults";
export const PROFILE_INVITE_USER_PATH = "/user/profile/invite_user";
export const PROFILE_USERS_PATH = "/user/profile/users";
export const PROFILE_TEAMS_PATH = "/user/profile/teams";
export const CAMPAIGN_SETTING_PATH = "/user/profile/campaignsetting";
export const INVALID_TENANT_ROUTE_PATH = "/auth/tenant-invalid";
export const BLOCKED_TENANT_ROUTE_PATH = "/auth/tenant-blocked";
export const BRANDING_PATH = "/user/branding";
//ONBAORDING PATH
export const USER_ONBOARDING_PATH = "/user/onboarding";
export const USER_ONBOARDING_DASHBOARD_PATH = "/user/onboardingdashboard";

//CSV Path
export const CSV_PATH = "/user/csv-enrichment";
export const IMPORT_TABLE_CSV = "/user/csv-table";

// Initial Routes
export const INITIAL_ROUTE = "/onboarding-form";

// default invalid route page
export const DEFAULT_PROTECTED_PATH = "/user/page-not-found";

//Error
export const ERROR_PAGE_404 = "/error-404";

// Components
export const COMPONENT = "/cp";

// Account Setting Screen Path
export const PROPERTIES_PATH = "/user/settings/properties";
export const GROUPS_PATH = "/user/settings/groups";

// CRM Routes
export const CRM_PATH = "/user/contacts";
export const DEALS_PATH = "/user/deals";
export const DEALS_BOARD_PATH = "/user/deals-board";
export const CONTACT_DETAIL_SCREEN = "/user/contacts/:id";
export const COMPANY_DETAIL_SCREEN = "/user/account/:id";
export const DEALS_DETAIL_SCREEN = "/user/deals/:id";

//Cancel Meeting
export const CANCEl_MEETING_PATH = "/cancel-meeting";

// dashboard routes
export const DASHBOARD_PATH = "/user/dashboard";
export const DASHBOARD_PATH_ID = "/user/dashboard/:id";
export const DASHBOARD_LIST_PATH = "/user/dashboard-list";

// connnect inbox route.
export const CONNECT_INBOX_PATH = "/user/connect-inbox/:payload";
