import { Box, LinearProgress } from '@mui/material';
import React from 'react';
import { styles } from './styles';


const LinearLoader = () => {

  const classes = styles();

    return<Box  className={ classes.LinearLoaderContainer } >
    <LinearProgress color='primary'  />
  </Box>
}

export default LinearLoader;