import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import {
  BREVO_SETTINGS,
  CONSTANT_SETTINGS,
  GMAIL_SETTINGS,
  MAILGUN_SETTINGS,
  MANDRILL_SETTINGS,
  MESSAGE_BIRD,
  OUTLOOK_SETTINGS,
  SENDGRID_SETTINGS,
  SENDINBLUE_SETTINGS,
} from "../../../../utils/constants/assets";
import Image from "../../../../components/Image/Image";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { STRINGS } from "../../strings";
import { GREY, SUCCESS } from "../../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import { Button } from "../../../../components/Buttons";
import {
  authEndpoints,
  campaignEndpoints,
} from "../../../../utils/constants/httpConstants";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../components/InputFields/InputField";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { nameValidation } from "../../../../utils/validations";
import {
  dailyValidation,
  emailValidation,
  hourlyValidation,
} from "../../../../utils/validations/inputValidations";
import {
  handleGoogleAuth,
  handleMicrosoftAuthInitialize,
} from "../../../../utils/helpers";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
import CustomStyledMenu from "../../../../components/Menu/CustomStyledMenu";
import RadioButton from "../../../../components/RadioButton/RadioButton";

const AllAppData = () => {
  const classes = styles();
  const navigate = useNavigate();
  const [apiKey, setApikey] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [hourly, setHourly] = useState(50);
  const [daily, setDaily] = useState(500);
  const [domain, setDomain] = useState("");
  const [list, setList] = useState([]);
  const [isTest, setIsTest] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [esp, setEsp] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [token, setToken] = useState("");
  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [domainAnchorEl, setDomainAnchorEl] = useState(null);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [validations, setValidations] = useState({});
  const [selectedValue, setSelectedValue] = useState(STRINGS.US)
  const addOpen = Boolean(addAnchorEl);
  const domainOpen = Boolean(domainAnchorEl);

  useEffect(() => {
    showAllEsp();
  }, []);
  const allAppList = [
    {
      id: 2,
      image: GMAIL_SETTINGS,
    },
    {
      id: 5,
      image: OUTLOOK_SETTINGS,
    },
    {
      id: 1,
      image: SENDGRID_SETTINGS,
    },
    {
      id: 4,
      image: MANDRILL_SETTINGS,
    },
    {
      id: 6,
      image: BREVO_SETTINGS,
    },
    {
      id: 3,
      image: MAILGUN_SETTINGS,
    },
    {
      id: 7,
      image: MESSAGE_BIRD,
    },
  ];
  const handleClose = () => {
    setAddAnchorEl(null);
    setDomainAnchorEl(null);
  };

  const radioButtonData = [
    { id: STRINGS.US, label: STRINGS.US },
    { id: STRINGS.EU, label: STRINGS.EU },
  ];
  const skeletonList = [1, 2, 3, 4, 5, 6];
  const showAllEsp = async () => {
    setIsSkeleton(true);
    try {
      //Here All List of Esps API is hitting
      const limit = await getRequest(navigate, authEndpoints?.getApiKeyDetails);
      const idsToRemove = new Set(
        limit?.data?.data?.other_api_details.map((item) => parseInt(item.esp))
      );
      const filteredArray = allAppList.filter(
        (item) => !idsToRemove.has(item.id)
      );
      console.log("FIL", filteredArray)
      setList(filteredArray);
    } catch (e) {
    } finally {
      setIsSkeleton(false);
    }
  };

  const openConnection = (event, id) => {
    setEsp(id);
    setApikey("");
    setSuccessMessage("");
    setEmail("");
    setName("");
    setHourly(50);
    setDaily(500);
    setValidations({});
    setAddAnchorEl(event?.currentTarget);
  };
  const openDomain = (event, id) => {
    setEsp(id);
    setApikey("");
    setSuccessMessage("");
    setEmail("");
    setName("");
    setHourly(50);
    setDaily(500);
    setDomain("");
    setValidations({});
    setDomainAnchorEl(event?.currentTarget);
  };
  const updateDomainDetails = async () => {
    const apiValidation = nameValidation(apiKey);
    const nameEspValidation = nameValidation(name);
    const emailDataValidation = emailValidation(email);
    const domainValidation = nameValidation(domain);
    if (
      apiValidation.isValid &&
      emailDataValidation.isValid &&
      domainValidation.isValid &&
      nameEspValidation.isValid
    ) {
      setValidations({ apikey: "", email: "", domain: "", name: "" });
      try {
        let payload = {
          esp: esp,
          key: apiKey,
          email: email,
          name: name,
          base_domain: domain,
          daily_limit: daily,
          hourly_limit: hourly,
          token: token,
          region: selectedValue
        };
        setIsLoad(true);
        const response = await postRequest(
          navigate,
          authEndpoints.updateApiDetails,
          payload
        );
        EventEmitter.emit(
          EMITTER_SUCCESS,
          response?.data?.message || "Server Message Misplaced."
        );
        setIsLoad(false);
        showAllEsp();
      } catch (error) {
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.message || "Something went wrong"
        );
      } finally {
        setToken("");
        setIsLoad(false);
        setDomainAnchorEl(null);
      }
    } else {
      setValidations({
        apikey: apiValidation.message,
        email: emailDataValidation.message,
        domain: domainValidation.message,
        name: nameEspValidation.message,
      });
    }
  };
  const sentMailTest = async (isDomain) => {
    if (isDomain) {
      const apiValidation = nameValidation(apiKey);
      const emailDataValidation = emailValidation(email);
      const domainValidation = nameValidation(domain);
      const nameEspValidation = nameValidation(name);
      const hourlyValidation = nameValidation(hourly);
      const dailyValidation = nameValidation(daily);
      if (
        apiValidation.isValid &&
        emailDataValidation.isValid &&
        domainValidation.isValid &&
        hourlyValidation.isValid &&
        dailyValidation.isValid &&
        nameEspValidation.isValid
      ) {
        setValidations({ apikey: "", email: "", domain: "", name: "" });
        try {
          let payload = {
            esp: esp,
            key: apiKey,
            email: email,
            name: name,
            base_domain: domain,
          };
          setIsTest(true);
          const response = await postRequest(
            navigate,
            authEndpoints.sendMailTest,
            payload
          );
          if (response.status === 200) {
            setToken(response?.data?.data?.token);
            setSuccessMessage(response?.data?.message);
          } else {
            setToken("");
            setSuccessMessage("");
          }
          setIsTest(false);
        } catch (error) {
          EventEmitter.emit(
            EMITTER_ERROR,
            error?.data?.message || "Something went wrong"
          );
          setAddAnchorEl(null);
        } finally {
          setIsTest(false);
        }
      }
    } else {
      const apiValidation = nameValidation(apiKey);
      const emailDataValidation = emailValidation(email);
      const nameEspValidation = nameValidation(name);
      const hourlyValidation = nameValidation(hourly);
      const dailyValidation = nameValidation(daily);
      if (
        apiValidation.isValid &&
        emailDataValidation.isValid &&
        nameEspValidation.isValid &&
        hourlyValidation.isValid &&
        dailyValidation.isValid
      ) {
        setValidations({ apikey: "", email: "", name: "" });
        try {
          let payload = {
            esp: esp,
            key: apiKey,
            email: email,
            name: name,
          };
          setIsTest(true);
          const response = await postRequest(
            navigate,
            authEndpoints.sendMailTest,
            payload
          );
          if (response.status === 200) {
            setToken(response?.data?.data?.token);
            setSuccessMessage(response?.data?.message);
          } else {
            setToken("");
            setSuccessMessage("");
          }
          setIsTest(false);
        } catch (error) {
          EventEmitter.emit(
            EMITTER_ERROR,
            error?.data?.message || "Something went wrong"
          );
          setAddAnchorEl(null)
        } finally {
          setIsTest(false);
        }
      } else {
        setValidations({
          apikey: apiValidation.message,
          email: emailDataValidation.message,
          name: nameEspValidation.message,
        });
      }
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const updateDetails = async () => {
    const apiValidation = nameValidation(apiKey);
    const emailDataValidation = emailValidation(email);
    const nameEspValidation = nameValidation(name);
    if (
      apiValidation.isValid &&
      emailDataValidation.isValid &&
      nameEspValidation.isValid
    ) {
      setValidations({ apikey: "", email: "", name: "" });
      try {
        let payload = {
          esp: esp,
          key: apiKey,
          email: email,
          name: name,
          daily_limit: daily,
          hourly_limit: hourly,
          token: token,
          region: selectedValue
        };
        esp !== 7 && delete payload["region"]
        setIsLoad(true);
        const response = await postRequest(
          navigate,
          authEndpoints.updateApiDetails,
          payload
        );
        EventEmitter.emit(
          EMITTER_SUCCESS,
          response?.data?.message || "Server Message Misplaced."
        );
        setIsLoad(false);
        showAllEsp();
      } catch (error) {
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.message || "Something went wrong"
        );
      } finally {
        setToken("");
        setIsLoad(false);
        setAddAnchorEl(null);
      }
    } else {
      setValidations({
        apikey: apiValidation.message,
        email: emailDataValidation.message,
        name: nameEspValidation.message,
      });
    }
  };
  const domainMenuContent = (
    <div>
      <div className={classes.menuContainer}>
        <div className={`${classes.padding4ts} ${classes.dispFlx}`}>
          <div className={classes.dspColflx}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.API_KEY}
            />
            <InputField
              value={apiKey}
              size={"sm36"}
              variant={validations.apikey ? "error" : STRINGS.DEFAULT}
              errormessage={validations.apikey}
              onChange={(e) => setApikey(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className={classes.dspColflx}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DOMAIN}
            />
            <InputField
              value={domain}
              size={"sm36"}
              variant={validations.domain ? "error" : STRINGS.DEFAULT}
              errormessage={validations.domain}
              onChange={(e) => setDomain(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.NAME}
          />
        </div>
        <div className={classes.padding4ts}>
          <InputField
            size={"sm36"}
            variant={validations.name ? "error" : STRINGS.DEFAULT}
            errormessage={validations.name}
            placeholder={STRINGS.ENTR_NAME}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.EMAIL}
          />
        </div>
        <div className={classes.padding4ts}>
          <InputField
            size={"sm36"}
            variant={validations.email ? "error" : STRINGS.DEFAULT}
            errormessage={validations.email}
            placeholder={STRINGS.ENTR_EML}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.REGION}
          />
        </div>
        <div className={classes.padding4ts}>
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </div>
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"xs"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.EMIALSENDINGLIMIT}
          />
        </div>
        <div className={`${classes.padding4ts} ${classes.dispFlx}`}>
          <div className={classes.dspColflx}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.HOURLY}
            />
            <InputField
              value={hourly}
              size={"sm36"}
              variant={STRINGS.DEFAULT}
              onChange={(e) => setHourly(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className={classes.dspColflx}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DAILY}
            />
            <InputField
              value={daily}
              size={"sm36"}
              variant={STRINGS.DEFAULT}
              onChange={(e) => setDaily(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        {successMessage?.length > 0 && (
          <div className={classes.padding8ts}>
            <TypographyInline
              size={"xs"}
              color={SUCCESS.W_500}
              fontWeight={fontWeight.MEDIUM}
              label={successMessage}
            />
          </div>
        )}
      </div>
      <div className={`${classes.padding4ts} ${classes.dspFooter}`}>
        <div>
          <Button
            label={STRINGS.SEND_TEST_MAIL}
            size={"sm36"}
            variant={"primary"}
            onClick={() => sentMailTest(true)}
            isLoading={isTest}
          />
        </div>
        <div className={classes.dspFooterContent}>
          <Button
            label={STRINGS.CANCEL}
            variant={"ghost"}
            size={"sm36"}
            onClick={handleClose}
          />
          <Button
            label={STRINGS.CONNECT}
            size={"sm36"}
            variant={"primary"}
            disabled={!(token?.length > 0)}
            onClick={updateDomainDetails}
            isLoading={isLoad}
          />
        </div>
      </div>
    </div>
  );
  const addMenuContent = (
    <div>
      <div className={classes.menuContainer}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.API_KEY}
        />
        <div className={classes.padding4ts}>
          <InputField
            size={"sm36"}
            placeholder={STRINGS.ENTER_API}
            variant={validations.apikey ? "error" : STRINGS.DEFAULT}
            errormessage={validations.apikey}
            value={apiKey}
            onChange={(e) => setApikey(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.NAME}
          />
        </div>
        <div className={classes.padding4ts}>
          <InputField
            size={"sm36"}
            variant={validations.name ? "error" : STRINGS.DEFAULT}
            errormessage={validations.name}
            placeholder={STRINGS.ENTR_NAME}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.EMAIL}
          />
        </div>
        <div className={classes.padding4ts}>
          <InputField
            size={"sm36"}
            variant={validations.email ? "error" : STRINGS.DEFAULT}
            errormessage={validations.email}
            placeholder={STRINGS.ENTR_EML}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        {esp === 7 &&
          <div className={classes.padding8ts}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.REGION}
            />
            <div className={classes.padding4ts}>
              <RadioButton
                value={selectedValue}
                onChange={handleChange}
                items={radioButtonData}
              />
            </div>
          </div>
        }
        <div className={classes.padding8ts}>
          <TypographyInline
            size={"xs"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.EMIALSENDINGLIMIT}
          />
        </div>
        <div className={`${classes.padding4ts} ${classes.dispFlx}`}>
          <div className={classes.dspColflx}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.HOURLY}
            />
            <InputField
              value={hourly}
              size={"sm36"}
              variant={STRINGS.DEFAULT}
              onChange={(e) => setHourly(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className={classes.dspColflx}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DAILY}
            />
            <InputField
              value={daily}
              size={"sm36"}
              variant={STRINGS.DEFAULT}
              onChange={(e) => setDaily(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        {successMessage?.length > 0 && (
          <div className={classes.padding8ts}>
            <TypographyInline
              size={"xs"}
              color={SUCCESS.W_500}
              fontWeight={fontWeight.MEDIUM}
              label={successMessage}
            />
          </div>
        )}
      </div>
      <div className={`${classes.padding4ts} ${classes.dspFooter}`}>
        <div>
          <Button
            label={STRINGS.SEND_TEST_MAIL}
            size={"sm36"}
            variant={"primary"}
            onClick={() => sentMailTest(false)}
            isLoading={isTest}
          />
        </div>
        <div className={classes.dspFooterContent}>
          <Button
            label={STRINGS.CANCEL}
            variant={"ghost"}
            size={"sm36"}
            onClick={handleClose}
          />
          <Button
            label={STRINGS.CONNECT}
            size={"sm36"}
            variant={"primary"}
            disabled={!(token?.length > 0)}
            onClick={updateDetails}
            isLoading={isLoad}
          />
        </div>
      </div>
    </div>
  );
  const handleOpen = (event, id) => {
    switch (id) {
      case 2:
        handleGoogleAuth();
        break;
      case 5:
        handleMicrosoftAuthInitialize();
        break;
      case 3:
        openDomain(event, id);
        break;
      default:
        openConnection(event, id);
    }
  };
  return (
    <>
      {isSkeleton ? (
        <div className={classes.bodyContainer1}>
          {skeletonList?.map((item) => {
            return (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"16vw"}
                skeletonHeight={"8vw"}
              />
            );
          })}
        </div>
      ) : (
        <div className={classes.bodyContainer}>
          {list?.map((item, ind) => {
            return (
              <div key={ind} className={classes.boxContent}>
                <Image
                  src={item.image}
                  alt={item?.id}
                  className={classes.imageHtWd}
                />
                <div className={classes.widthFix}>
                  <Button
                    label={
                      item?.id === 2 || item?.id === 5
                        ? STRINGS.CONNECT
                        : STRINGS.ADD
                    }
                    LeftIcon={
                      item?.id === 2 || item?.id === 5
                        ? ICON_LABELS.LINK
                        : ICON_LABELS.ADD
                    }
                    size={"sm36"}
                    variant={"ghost"}
                    onClick={(e) => handleOpen(e, item?.id)}
                  />
                </div>
              </div>
            );
          })}
          <CustomStyledMenu
            width="25vw"
            anchorEl={addAnchorEl}
            open={addOpen}
            onClose={handleClose}
          >
            {addMenuContent}
          </CustomStyledMenu>
          <CustomStyledMenu
            width="25vw"
            anchorEl={domainAnchorEl}
            open={domainOpen}
            onClose={handleClose}
          >
            {domainMenuContent}
          </CustomStyledMenu>
        </div>
      )}
    </>
  );
};

export default AllAppData;
