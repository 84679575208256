import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CreateCommonFolder from "./components/common/CreateCommonFolder";
import { STRINGS } from "./strings";
import { EMAIL_MARKETING_CAMPAIGN_PATH } from "../../utils/constants/routes";
import CustomHeader from "../../components/Header/CustomHeader";
import SearchField from "../../components/InputFields/SearchField";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import { Button } from "../../components/Buttons";
import CustomTable from "../../components/Table/CustomTable";
import {
  DEBOUNCE_DELAY,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  campaignHeadData,
} from "../../utils/constants";
import {
  Logger,
  debounceHelper,
  generateBreadCrumbDescription,
  getErrorMessage,
  processStatsKeyValuePairs,
} from "../../utils/helpers";
import { paginationDataAction } from "../../redux/actions/customTableActions";
import RecordDeleteModal from "./components/Sequence/RecordDeleteModal";
import { getRequest, postRequest } from "../../Apis";
import { campaignEndpoints } from "../../utils/constants/httpConstants";
import EventEmitter from "../../utils/emitter/EventEmitter";
import CustomStyledMenu from "../../components/Menu/CustomStyledMenu";
import TypographyInline from "../../components/Typography/TypographyInline";
import FolderSkeletonLoader from "./components/common/FolderSkeletonLoader";
import IconComponent from "../../components/Icon/IconComponent";
import { GREY, WARNING } from "../../utils/constants/colors";
import { campaignsListFetch } from "../../redux/actions/campaignAction";
import CreateCampaignModal from "./components/Campaign/CreateCampaignModal";
import { StatsDialog } from "./components/Campaign";
import classes from "./marketing.module.css";
import RemoveFolder from "./components/common/RemoveFolder";
import EmptyScreen from "./EmptyScreen";

const CampaignScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const getCampaignListSelector = useSelector(
    (state) => state?.campaignsListFetch
  );
  const paginationSelector = useSelector((state) => state.getPaginationQuery);
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sequenceModalOpen, setSequenceModalOpen] = useState(false);
  const [showFolderEdit, setShowFolderEdit] = useState(false);
  const [isOperationPermitted, setIsOperationPermitted] = useState(true);
  const [movedAnchorEl, setMovedAnchorEl] = useState(null);
  const [showRemoveFromFolder, setShowRemoveFromFolder] = useState(false);
  const [isMount, setIsMount] = useState(false);
  const [moving, setMoving] = useState(false);
  const [editFold, setEditFold] = useState({
    id: "",
    name: "",
  });
  const [resolvedRecords, setResolvedRecords] = useState([]);
  const [isActive, setIsActive] = useState("");
  const [operationList, setOperationList] = useState([
    {
      id: "pin",
      label: "add",
    },
    {
      id: "pin",
      label: "data",
    },
    {
      id: "pin",
      label: "list",
    },
  ]);
  const [moveFolderList, setMoveFolderList] = useState([]);
  const [foldersLoading, setFoldersLoading] = useState(false);
  const [showItemDelete, setShowItemDelete] = useState(false);
  // const [showFolderEdit, setShowFolderEdit] = useState(false);
  const [operationRecordPayload, setOperationRecordPayload] = useState(null);
  const [statsShowModalOpen, setStatsModalShowOpen] = useState(false);
  const [statsPayload, setStatsPayload] = useState({});
  const [campaignPayload, setCampaignPayload] = useState({});
  const [search, setSearch] = useState("");
  const [headerItems, setHeaderItems] = useState([
    {
      id: STRINGS.TYPE_CAMP,
      label: STRINGS.CAMPAIGNS_TEXT,
      path: EMAIL_MARKETING_CAMPAIGN_PATH,
    },
  ]);
  const movedOpen = Boolean(movedAnchorEl);

  const handleSearch = () => {
    try {
      dispatch(
        paginationDataAction({
          ...paginationSelector?.data,
          checkedRow: [],
          query: {
            ...paginationSelector?.data?.query,
            search: search,
            page: 1,
          },
        })
      );
    } catch (error) { }
  };

  const handleFolderOpen = () => {
    setMovedAnchorEl(null);
    setFolderModalOpen(true);
  };

  const handleCampaignOpen = () => {
    setSequenceModalOpen(true);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const handleRemoveFromFolder = () => {
    setShowRemoveFromFolder(true);
  };

  const handleMovetoMenuOpenAnchor = (event) => {
    setMovedAnchorEl(event?.currentTarget);
  };

  const handleMovetoMenuOpen = async (event) => {
    try {
      setFoldersLoading(true);
      let userIdArr = getCampaignListSelector?.payload?.data?.records
        ?.filter((item) =>
          paginationSelector?.data?.checkedRow?.includes(item?._id)
        )
        ?.map((item) => {
          return item?.user_id;
        });
      const payload = {
        owner_ids:
          userDetailsSelector?.payload?.role === STRINGS.ADMIN_USER
            ? userIdArr
            : [],
      };

      const response = await postRequest(
        navigate,
        campaignEndpoints.getMoveList,
        payload
      );
      setMoveFolderList(response?.data?.data?.data || []);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setFoldersLoading(false);
    }
  };

  const handleClose = () => {
    setMovedAnchorEl(null);
  };

  const backHandle = (id) => {
    setIsActive(id);
  };

  const handleFolderSearch = async (event) => {
    try {
      setFoldersLoading(true);
      let userIdArr = getCampaignListSelector?.payload?.data?.records
        ?.filter((item) =>
          paginationSelector?.data?.checkedRow?.includes(item?._id)
        )
        ?.map((item) => {
          return item?.user_id;
        });
      const payload = {
        owner_ids:
          userDetailsSelector?.payload?.role === STRINGS.ADMIN_USER
            ? userIdArr
            : [],
        search: event.target.value,
      };

      const response = await postRequest(
        navigate,
        campaignEndpoints.getMoveList,
        payload
      );
      setMoveFolderList(response?.data?.data?.data || []);
    } catch (error) {
    } finally {
      setFoldersLoading(false);
    }
  };

  const handleDebounceFolderSearch = debounceHelper(
    handleFolderSearch,
    DEBOUNCE_DELAY
  );

  const moveCampaign = async (id) => {
    try {
      const payload = {
        campaign_ids: paginationSelector?.data?.checkedRow,
        folder_id: id,
      };

      setMoving(true);
      const response = await postRequest(
        navigate,
        campaignEndpoints.moveCampaignToList,
        payload
      );
      navigate(`${EMAIL_MARKETING_CAMPAIGN_PATH}/${id}`);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setIsActive("");
      setMovedAnchorEl(null);
      // dispatch(paginationDataAction({ checkedRow: [], query: {} }));
    } catch (err) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
      );
    } finally {
      setMoving(false);
    }
  };

  const handlePinRecord = async (row) => {
    try {
      let type;
      row.type == "file" ? (type = "file") : (type = "folder");
      const new_pin = row?.pin === 0 ? 1 : 0;
      const payload = { pin_unpin: new_pin, record_id: row?._id, type };
      const response = await postRequest(
        navigate,
        campaignEndpoints.pinUnpinCampaign,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      dispatch(paginationDataAction({ checkedRow: [], query: {} }));
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const handleEditCampaign = async (row) => {
    setEditFold({
      id: row?._id,
      name: row?.name,
    });
    setShowFolderEdit(true);
  };

  const value = {
    create: STRINGS.CRT_CMPGS,
    content: STRINGS.CMPGS_CONT,
    body: [STRINGS.CMP_BD1, STRINGS.CMP_BD2, STRINGS.CMP_BD3],
    foot: STRINGS.CRT_CMPGS,
  };

  const handlePauseCampaign = async (row) => {
    try {
      const pause_response = await getRequest(
        navigate,
        campaignEndpoints.puaseCampaign + "/" + row?._id
      );
      EventEmitter.emit(EMITTER_SUCCESS, pause_response?.data?.message);
      dispatch(paginationDataAction({ query: {}, checkedRow: [] }));
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const handleResumeCampaign = async (row) => {
    try {
      const pause_response = await getRequest(
        navigate,
        campaignEndpoints.resumeCampaign + "/" + row?._id
      );
      EventEmitter.emit(EMITTER_SUCCESS, pause_response?.data?.message);
      dispatch(paginationDataAction({ query: {}, checkedRow: [] }));
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const handleShowItemDelete = (row) => {
    try {
      setShowItemDelete(true);
      setOperationRecordPayload(row);
    } catch (error) { }
  };


  const isBulkOperationAllowed = (type = STRINGS.FILE) => {
    try {
      if (paginationSelector?.data?.checkedRow?.length) {
        // check if only one type exists.
        const records = getCampaignListSelector?.payload?.data?.records || [];
        const newRecords = [
          ...new Set(
            records
              .filter((item) =>
                paginationSelector?.data?.checkedRow?.includes(
                  item._id || item?.id
                )
              )
              ?.map((item) => item?.type)
          ),
        ];
        if (newRecords?.length >= 2) {
          return false;
        }
        if (
          type === STRINGS.SMALL_FOLDER &&
          newRecords.includes(STRINGS.SMALL_FOLDER)
        ) {
          return false;
        }
        return true;
      }
      return false;
    } catch (error) {
      Logger(error);
      return false;
    }
  };

  const handleeViewStatModal = (row, head) => {
    try {
      if (row?.type === STRINGS.FILE) {
        setStatsModalShowOpen(true);
        setStatsPayload({ row: row, head: head });
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error.message);
    }
  };

  const handleCloneCampaign = (row) => {
    setCampaignPayload(row);
    setSequenceModalOpen(true);
  };

  const campaignEmailTableData = {
    heads: campaignHeadData,
    body: resolvedRecords,
    operations: operationList || [
      {
        id: STRINGS.PIN,
        label: STRINGS.COMMON_PIN,
        iconLabel: ICON_LABELS.PUSH_PIN,
        function: handlePinRecord,
      },
      {
        id: STRINGS.PAUSE,
        label: STRINGS.PAUSE,
        iconLabel: ICON_LABELS.PAUSE,
        function: handlePauseCampaign,
      },
      {
        id: STRINGS.COMMON_EDIT,
        label: STRINGS.COMMON_EDIT,
        iconLabel: ICON_LABELS.EDIT,
        function: handlePauseCampaign,
      },
      {
        id: STRINGS.RESUME,
        label: STRINGS.RESUME,
        iconLabel: ICON_LABELS.PLAY_ARROW,
        function: handleResumeCampaign,
      },
      {
        id: STRINGS.CLONE,
        label: STRINGS.CLONE,
        iconLabel: ICON_LABELS.CONTENT_COPY_1,
        function: handleCloneCampaign,
      },
      {
        id: STRINGS.SMALL_DELETE,
        label: STRINGS.DELETE,
        iconLabel: ICON_LABELS.DELETE,
        variant: "error",
        function: handleShowItemDelete,
      },
    ],

    // heads for loading in particular column.
    loading_heads: [],
    actions: {
      marketing_list: {
        function: (a) => {
          dispatch(paginationDataAction({}));

          if (a.type === STRINGS.FILE) {
            if (a.state !== STRINGS.INTERRUPTED) {
              const PATH =
                EMAIL_MARKETING_CAMPAIGN_PATH +
                "/" +
                a?._id +
                "&" +
                a?.cam_sequence_id +
                "&" +
                a?.cam_list_id +
                "&" +
                a?.user_id +
                "/" +
                STRINGS.CAMPAIGN_CONTACTS_ID;
              navigate(PATH);
            }
          } else {
            navigate(`${EMAIL_MARKETING_CAMPAIGN_PATH}/${a?._id}`);
          }
        },
        loading: true,
      },
      action: {
        function: (a) => {
          if (a.type === STRINGS.FILE) {
            setOperationList([
              {
                id: STRINGS.PIN,
                label: STRINGS.COMMON_PIN,
                iconLabel: ICON_LABELS.PUSH_PIN,
                function: handlePinRecord,
              },
              {
                id: STRINGS.PAUSE,
                label: STRINGS.PAUSE,
                iconLabel: ICON_LABELS.PAUSE,
                function: handlePauseCampaign,
              },
              {
                id: STRINGS.RESUME,
                label: STRINGS.RESUME,
                iconLabel: ICON_LABELS.PLAY_ARROW,
                function: handleResumeCampaign,
              },
              {
                id: STRINGS.CLONE,
                label: STRINGS.CLONE,
                iconLabel: ICON_LABELS.CONTENT_COPY_1,
                function: handleCloneCampaign,
              },
              {
                id: STRINGS.SMALL_DELETE,
                label: STRINGS.DELETE,
                iconLabel: ICON_LABELS.DELETE,
                variant: "error",
                function: handleShowItemDelete,
              },
            ]);
          } else {
            setOperationList([
              {
                id: STRINGS.PIN,
                label: STRINGS.COMMON_PIN,
                iconLabel: ICON_LABELS.PUSH_PIN,
                function: handlePinRecord,
              },
              {
                id: STRINGS.COMMON_EDIT,
                label: STRINGS.COMMON_EDIT,
                iconLabel: ICON_LABELS.EDIT,
                function: handleEditCampaign,
              },
              {
                id: STRINGS.SMALL_DELETE,
                label: STRINGS.DELETE,
                iconLabel: ICON_LABELS.DELETE,
                variant: "error",
                function: handleShowItemDelete,
              },
            ]);
          }
        },
      },
      stats_data: {
        function: handleeViewStatModal,
      },
    },
    sortHandler: {},
    total_records: getCampaignListSelector?.payload?.total_records,
    isLoading: getCampaignListSelector?.loading,
    disable_key: STRINGS.DISABLE_KEY,
  };

  const moveToMenuContent = (
    <Box className={classes.movingContainer}>
      <Box className={classes.movingHeader}>
        <Box className={classes.moveStyle}>
          <TypographyInline
            size={"md"}
            color={GREY.PRIMARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.MOVE_TO_FOLDER}
          />
        </Box>
      </Box>
      <Box className={classes.moveStyle1}>
        <SearchField
          autoComplete="off"
          size={"sm36"}
          placeholder={STRINGS.SEARCH_FOLDER}
          onChange={handleDebounceFolderSearch}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </Box>
      <Box className={classes.moveDataHeight}>
        {foldersLoading ? (
          <Box className={classes.containSkeleton}>
            <FolderSkeletonLoader />
          </Box>
        ) : (
          moveFolderList?.map((val, id) => {
            return (
              <Box
                className={
                  isActive === val._id
                    ? classes.activeMovingContent
                    : classes.movingContent
                }
                onClick={() => backHandle(val._id)}
              >
                <IconComponent
                  color={WARNING.W_500}
                  fontSize={fontSize.LG}
                  iconLabel={ICON_LABELS.FOLDER}
                />
                <Box
                  className={
                    isActive === val._id
                      ? classes.movDataCont1
                      : classes.movDataCont
                  }
                >
                  <TypographyInline
                    size={"sm"}
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    label={val.name}
                  />
                </Box>
              </Box>
            );
          })
        )}
      </Box>
      <Box className={classes.movFooter}>
        <Box>
          <Button
            label={STRINGS.CREATE_FOLDER}
            LeftIcon={ICON_LABELS.ADD}
            size={"sm36"}
            variant="text"
            onClick={handleFolderOpen}
          />
        </Box>
        <Box className={classes.movBtnGen}>
          <Button
            label={STRINGS.COMMON_CANCEL}
            variant={"ghost"}
            size={"sm36"}
            onClick={handleClose}
          />
          <Button
            label={STRINGS.MOVE}
            size={"sm36"}
            variant={"primary"}
            onClick={() => moveCampaign(isActive)}
            disabled={!isActive}
            isLoading={moving}
          />
        </Box>
      </Box>
    </Box>
  );

  const getTypeFromIds = () => {
    const type = getCampaignListSelector?.payload?.data?.records?.filter(
      (item) =>
        paginationSelector?.data?.checkedRow?.includes(item?._id || item?.id)
    )?.[0]?.type;
    return type;
  };

  useEffect(() => {
    if (!statsShowModalOpen) {
      (async () => {
        try {
          const payload = { ...paginationSelector?.data?.query };
          if (id?.length) {
            payload.folder_id = id;
          }
          await dispatch(campaignsListFetch(payload, navigate));
        } catch (err) { }
      })();
      // get folder details api.
      if (id) {
        (async () => {
          try {
            const get_folder_details_payload = await getRequest(
              navigate,
              `${campaignEndpoints.getInfo}/${id}`
            );
            // condition to not add new header item on every re-render.
            const route_length = window.location.pathname
              ?.split(EMAIL_MARKETING_CAMPAIGN_PATH)?.[1]
              ?.split("/");
            let new_header_items;
            headerItems?.length > 1 && headerItems.pop();
            new_header_items = [
              ...headerItems,
              {
                id: id,
                label: get_folder_details_payload?.data?.data?.name || "",
              },
            ];
            if (headerItems?.length !== route_length?.length + 1) {
              setHeaderItems(new_header_items);
            }
            setIsOperationPermitted(
              get_folder_details_payload?.data?.data?.operation
            );
          } catch (error) {
            EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
          }
        })();
      } else {
        setHeaderItems([
          {
            id: STRINGS.TYPE_CAMP,
            label: STRINGS.CAMPAIGN_TEXT,
            path: EMAIL_MARKETING_CAMPAIGN_PATH,
          },
        ]);
      }
    }
  }, [dispatch, navigate, paginationSelector?.data?.query]);

  // this useEffect is just to set records.
  useEffect(() => {
    setResolvedRecords(getCampaignListSelector?.payload?.data?.records || []);
    if (getCampaignListSelector?.payload?.data?.records?.length) {
      (async () => {
        try {
          const campaign_ids =
            getCampaignListSelector?.payload?.data?.records?.map(
              (item) => item?._id
            );
          const owner_ids =
            getCampaignListSelector?.payload?.data?.records?.map(
              (item) => item?.user_id
            );
          // call stats api and owner api here, do promise.allSettled here.
          const promises = [
            postRequest(navigate, campaignEndpoints.campStats, {
              campaign_ids: campaign_ids,
            }),
            postRequest(navigate, campaignEndpoints.campOwnerStats, {
              user_ids: owner_ids,
            }),
          ];
          const resolved_promises = await Promise.allSettled(promises);

          const stats_payload = resolved_promises[0]?.value?.data;
          const owner_payload = resolved_promises[1]?.value?.data;
          // owner has been set.
          const structured_records =
            getCampaignListSelector?.payload?.data?.records?.map((item) => {
              return (
                {
                  ...item,
                  owner: owner_payload?.data?.userdata?.[item?.user_id],
                  ...processStatsKeyValuePairs(
                    stats_payload?.records[item?._id] || {}
                  ),
                } || ""
              );
            });
          if (structured_records?.length) {
            setResolvedRecords(structured_records);
          }
        } catch (error) {
          Logger(error);
        }
      })();
    }
  }, [getCampaignListSelector?.payload]);


  // debounce search api call for search
  useEffect(() => {
    if (isMount) {
      let getData;
      getData = setTimeout(() => {
        handleSearch();
      }, 300);
      return () => clearTimeout(getData);
    } else {
      setIsMount(true);
    }
  }, [search]);

  useEffect(() => {
    setSearch("");
    setIsMount(false);
  }, [navigate])

  return (
    <Box className={classes.container}>
      <Box className={classes.container}>
        {/* Common Header */}
        <CustomHeader
          items={headerItems}
          description={
            getCampaignListSelector?.loading
              ? STRINGS.LOADING
              : generateBreadCrumbDescription(getCampaignListSelector?.payload)
          }
        />

        {/* Bulk Operations Header */}
        {getCampaignListSelector?.payload?.is_empty ? (
          <EmptyScreen
            data={value}
            handleOpen={handleCampaignOpen}
            handleFolder={handleFolderOpen}
          />
        ) : (
          <>
            <Box className={classes.buttonscContainer}>
              <Box className={classes.leftHeadContainer}>
                <Box className={classes.commonWidth}>
                  <SearchField
                    size={"sm36"}
                    autoComplete="off"
                    placeholder={STRINGS.SEARCH}
                    value={search}
                    onChange={(event) => {
                      setSearch(event?.target?.value);
                    }}
                  />
                </Box>
                {!isBulkOperationAllowed(STRINGS.SMALL_FOLDER) ? null : (
                  <Box onClick={handleMovetoMenuOpen}>
                    <Button
                      label={STRINGS.MOVE_TO}
                      LeftIcon={ICON_LABELS.DRIVE_FILE_MOVE}
                      variant={"ghost"}
                      size={"sm36"}
                      onClick={handleMovetoMenuOpenAnchor}
                      disabled={!isBulkOperationAllowed(STRINGS.SMALL_FOLDER)}
                    />
                  </Box>
                )}
                {isBulkOperationAllowed() && (
                  <Box>
                    <Button
                      label={STRINGS.DELETE}
                      LeftIcon={ICON_LABELS.DELETE}
                      variant={"ghost"}
                      disabled={!paginationSelector?.data?.checkedRow?.length}
                      size={"sm36"}
                      onClick={handleDeleteModalOpen}
                    />
                  </Box>
                )}
                {id?.length && isBulkOperationAllowed() && (
                  <Box>
                    <Button
                      label={STRINGS.RM_FROM_FOLDER}
                      variant={"ghost"}
                      disabled={!paginationSelector?.data?.checkedRow?.length}
                      size={"sm36"}
                      onClick={handleRemoveFromFolder}
                    />
                  </Box>
                )}
              </Box>
              <Box className={classes.rightHeadContainer}>
                {!id?.length && (
                  <Box>
                    <Button
                      label={STRINGS.CREATE_FOLDER}
                      LeftIcon={ICON_LABELS.CREATE_NEW_FOLDER}
                      variant={"ghost"}
                      size={"sm36"}
                      onClick={handleFolderOpen}
                    />
                  </Box>
                )}
                {/* {!id?.length && ( */}
                <Box>
                  <Button
                    disabled={id?.length && !isOperationPermitted}
                    label={STRINGS.CREATE_CAMPAIGN}
                    LeftIcon={ICON_LABELS.ADD}
                    variant={"primary"}
                    size={"sm36"}
                    onClick={handleCampaignOpen}
                  />
                </Box>
                {/* )} */}
              </Box>
            </Box>
            {/* Bulk Operations Header */}

            {/* Custom Table Rendeering */}
            <Box className={classes.searchAndTableContainer}>
              <div className={classes.tableStyle}>
                <CustomTable tableData={campaignEmailTableData} />
              </div>
            </Box>
          </>
        )}
        {/* Custom Table Rendeering */}

        {/* All Menu's */}

        <CustomStyledMenu
          width="20.208vw"
          anchorEl={movedAnchorEl}
          open={movedOpen}
          onClose={handleClose}
        >
          {moveToMenuContent}
        </CustomStyledMenu>

        {/* All Menu's */}

        {/* All Modals */}

        {/* Create Folder Modal */}
        {folderModalOpen && (
          <CreateCommonFolder
            open={folderModalOpen}
            setOpen={setFolderModalOpen}
            type={STRINGS.TYPE_CAMP}
            endFunction={handleMovetoMenuOpen}
          />
        )}

        {/* Delete Sequence Modal */}
        {deleteModalOpen && (
          <RecordDeleteModal
            open={true}
            setOpen={setDeleteModalOpen}
            IDs={paginationSelector?.data?.checkedRow || []}
            val={STRINGS.CAMPAIGN_TEXT}
            itemType={getTypeFromIds()}
            endPoint={campaignEndpoints.deleteMany}
          />
        )}

        {/* Delete from operation Modal */}
        {showItemDelete && (
          <RecordDeleteModal
            open={true}
            setOpen={setShowItemDelete}
            IDs={[operationRecordPayload?._id] || []}
            val={STRINGS.CAMPAIGN_TEXT}
            itemName={operationRecordPayload?.name}
            itemType={operationRecordPayload?.type}
            endPoint={campaignEndpoints.deleteMany}
          />
        )}
        {/* Delete from operation Modal */}

        {/* Create Sequence Modal */}
        {sequenceModalOpen && (
          <CreateCampaignModal
            open={sequenceModalOpen}
            setOpen={setSequenceModalOpen}
            payload={campaignPayload}
            setPayload={setCampaignPayload}
          />
        )}

        {/* Edit Folder Modal */}
        {showFolderEdit && (
          <CreateCommonFolder
            open={showFolderEdit}
            setOpen={setShowFolderEdit}
            type={STRINGS.TYPE_CAMP}
            recordId={editFold?.id}
            nameFold={editFold?.name}
          // view={operationRecordPayload?.permission}
          />
        )}

        {/* Edit Folder Modal */}

        {/* Stats Details Dialog */}
        {statsShowModalOpen && (
          <StatsDialog
            open={statsShowModalOpen}
            setOpen={setStatsModalShowOpen}
            statsPayload={statsPayload}
          />
        )}
        {showRemoveFromFolder && (
          <RemoveFolder
            open={showRemoveFromFolder}
            setOpen={setShowRemoveFromFolder}
            IDs={paginationSelector?.data?.checkedRow}
            type={"campaignRemove"}
            val={STRINGS.CAMPAIGNS_TEXT}
          />
        )}

        {/* Stats Details Dialog */}

        {/* All Modals */}
      </Box>
    </Box>
  );
};

export default CampaignScreen;
