import { SNIPPET_LIST_REQUEST, SNIPPET_LIST_SUCCESS, SNIPPET_LIST_FAIL } from "../constants/snippetConstants";

export const snippetsListReducer = (state = {}, action) => {
    switch (action.type) {
      case SNIPPET_LIST_REQUEST:
        return { loading: true };
  
      case SNIPPET_LIST_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case SNIPPET_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };  