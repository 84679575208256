import React from "react";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import Typography from "../../../components/Typography/Typography";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import classes from "./PropComponent.module.css";

const DeleteGroupModal = ({
  open,
  close,
  deleteGroupHandler,
  btnLoading,
  groupName,
}) => {
  const modalHeaderData = {
    heading: {
      id: STRINGS.CRT_PROP_SMALL,
      label: STRINGS.DELETE + ` "${groupName}"`,
    },
  };

  const modalFooterRightData = (
    <div className={classes.createPropFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={close}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        isLoading={btnLoading}
        onClick={() => {
          deleteGroupHandler();
        }}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      close={close}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
      variant={STRINGS.ERROR_SMALL}
      width={"27.865vw"}
    >
      <Typography
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={STRINGS.DELETE_DESC}
        size={"sm"}
      />
    </CustomModal>
  );
};

export default DeleteGroupModal;
