import React, { useEffect, useState } from "react";
import { InputWrapper, Root, StyledTag, Listbox } from ".";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import classes from "./AutoComplete.module.css";
import { styles } from "./styles";
import { dealsEndpoints } from "../../utils/constants/httpConstants";
import { postRequest } from "../../Apis";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const DynamicCustomAutoComplete = ({
  defaultValues = () => { },
  items = [],
  onChange = () => { },
  inputOnchange = () => { },
  filterName = "",
  filterType = "",
}) => {
  const navigate = useNavigate();

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );


  const [list, setList] = useState(items);
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook",
    multiple: true,
    defaultValue: defaultValues,
    options: list,
    getOptionLabel: (option) => option?.["name"] || option?.label,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  let inputValue = getInputProps()?.value;

  useEffect(() => {
    if (
      (filterMultipleListSelector?.[filterName]?.[filterType] || [])?.length !==
      value?.length
    ) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (filterName === "associated_contact") {
      let getItems;
      inputOnchange(inputValue);

      (async () => {
        if (inputValue.length >= 3) {
          getItems = setTimeout(async () => {
            const response = await postRequest(
              navigate,
              dealsEndpoints.getAssociatedContact,
              {
                search: inputValue,
              }
            );
            let associated_contact = response?.data?.data?.records?.map(
              (element) => {
                return {
                  id: element?._id,
                  label: `${element?.first_name} ${element?.last_name}`,
                };
              }
            );
            setList(associated_contact);
          }, 300);
        }
      })();

      return () => clearTimeout(getItems);
    }
  }, [inputValue]);

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl}>
          {value?.map((option, index) => (
            <StyledTag
              label={option?.name || option?.label || ""}
              key={index}
              {...getTagProps({ index })}
            />
          ))}
          <form onSubmit={handleSubmit} className={classes.inputContainer}>
            <input
              onKeyDown={(e) => e.stopPropagation()}
              className={classes.input}
              placeholder="Search..."
              {...getInputProps()}
            />
          </form>
        </InputWrapper>
      </div>

      {groupedOptions?.length > 0 ? (
        <Listbox {...getListboxProps()} sx={styles.listBox}>
          {groupedOptions.map((option, index) => (
            <div key={index}>
              <li {...getOptionProps({ option, index })}>
                <span>{option?.name || option?.label}</span>
              </li>
            </div>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
};

export default React.memo(DynamicCustomAutoComplete);
