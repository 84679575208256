export const STRINGS = {
  ALL_FILE_LIST: "All files list",
  CREATE: "Create",
  UPDATE: "Update",
  NEW_CONTACTS: "New Prospects",
  RESET: "Reset",
  DATE_RANGE: "Date Range",
  STATUS: "Status",
  APPLY: "Apply",
  BLOCK: "Block",
  SUBMIT: "Submit",
  SAVE: "Save",
  FILTERS: "Filters",
  CANCEL: "Cancel",
  ALL: "All",
  _ALL: "_all",
  FILE_NAME: "File Name",
  CSV_FILES: "CSV Files",
  NOT_A_NUM: "NaN-NaN-NaN",
  DEL_DESC: " Are you sure you want to delete this Domain?",
  DOMAIN_NAME: "Domain Name",
  DOMAIN: "Add Domain",
  ADD_FILE: "Add File",
  ADD_EMAIL: "  Add Emails",
  EMAILS: "emails",
  CREATE_NEW_VIEW: "Create New View",
  EDIT_LIST: "Edit List",
  CREATE_NEW_LIST: "Create New List",
  VIEW_NAME: "View Name*",
  SOURCE_TYPE: "Source Type",
  SHARED: "Shared with*",
  PRIVATE: "Private",
  MY_TEAM: "My Team",
  PLEASE_ADD_CSV: "Selected file type is invalid. Please add CSV files only.",
  ENTER_LIST: "Enter List Name*",
  TEAM_ID: "team",
  PRIVATE_ID: "private",
  TEAM_LABEL: "My Team",
  PRIVATE_LABEL: "Private",
  BLOCKED_EMAILS: "blocked_emails",
  BLACKLIST: "Blacklist",
  CONTACT_LISTS: "contact_lists",
  SAVE_TEXT:
    "This is a standard view or created by someone else. Save as new view to keep your changes.",
  SAVE_AS_NEW: "Save as new",
  SAVE_AS_CURRENT: "Save Current View",
  CREATE_CONTACT: "Create Contact",
  REQUIRED: "This is a required field.",
  VIEW_CANT_25CHAR: "View Name can't be more than 50 charecters.",
  LIST_SHOULDBE_THREE: "List name should be atleast 3 characters.",
  LIST_SHOULDBE_ONEFIFTY: "List name should be less than or equal to 150",
  SAVED_COMPANIES: "Saved Companies",
  INDEX: "index",
  TYPE: "type",
  SUCCESS_MSG_MISPLACED: "Success Message Misplaced",
  ERROR_MSG_MISPLACED: "Error Message Misplaced",
  CONTACT_EXPORT: "Contact_export.csv",
  COMPANY_EXPORT: "Company_export.csv",
  ANY_OF: "anyOf",
  NOT_ANY_OF: "notAnyOf",
  IS_KNOWN: "isKnown",
  IS_UNKNOWN: "isUnknown",
  ENTER_NAME: "Enter Name",
  EXIST_FILEDS: "exist_fields",
  NOT_EXIST_FIELDS: "not_exist_fields",
  CHOOSE: "Choose which column you see",
  EDIT_COLUMNS: "Edit Columns",
  CONTACT: "Contact information",
  FIRST_NAME: "first_name",
  FIRST_NAME_CAPS: "First Name",
  LAST_NAME_CAPS: "Last Name",
  RESET_ALL_PROPERTIES: "Reset all Properties",
  LAST_NAME: "last_name",
  NO_RECORD: "Oops! No records found.",
  SERACH_COMPANIES: " Please try searching for other companies",
  ASC: "asc",
  NAME: "Name",
  NOT: "_not",
  EMAIL_VERIFY_DATE: "Email Verification Date",
  EMPLOYEE_NUMBER: "employee_number",
  LAST_UPDATED_AT: "Last Updated At",
  EMAIL_VERIFICATION: "Email Verification",
  EMAIL_VERIFICATION_SMALL: "email_verification",
  INDUSTRY: "industry",
  INDUSTRY_CAPS: "Industry",
  EMAIL_ADDRESS: "Email Address",
  OBJECT: "object",
  SENIORITY: "Seniority",
  COMPANY_SIZE: "Company Size",
  SENIORITY_SMALL: "seniority",
  JOB_TITLE: "Job Title",
  ALL_CONTACTS: "All Contacts",
  ALL_COMPANIES: "All Companies",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  JOB_TITLE_SMALL: "job_title",
  PEOPLE: "People",
  ANNUAL_REVENUE: "annual_revenue",
  EDIT_VIEW: "Edit this view",
  PIN_VIEW_TO_KEEP_OPEN: "Pin view to keep it open",
  DELETE_THIS_VIEW: "Delete this view",
  ARE_YOU_SURE_DELETE: "Are you sure you want to Delete this View?",
  ACTIVE_VIEW_ID: "active_view_id",
  COMPANY: "Company Name",
  PERSON_STATE_CAPS: "Contact State",
  PERSON_STATE_NOT: "person_state_not",
  KEYWORDS: "Keywords",
  ERROR: "error",
  DEFAULT: "default",
  ITEM_KEY: "item_key",
  ITEM_NAME: "item_name",
  FILE: "file",
  PERFORM_SEARCH: "perform_search",
  DATABASE_VIEW: "databaseview",
  DATABASE_LIST: "databaselist",
  DATABSE: "database",
  COMPANY_KEYWORDS_CAPS: "Company keywords",
  SINGLE_COMPANY: "Companies",
  SAVED_CONTACT: "Saved Prospects",
  SAVED_PROSPECT: "Saved Prospects",
  PEOPLE_HEADER: "people_header",
  PERSON_COUNTRY_NOT: "person_country_not",
  SEO_DESC_NOT: "seo_description_not",
  SEO_DESC_ALL: "seo_description_all",
  NEW_COMPANIES: "New Companies",
  COMPANY_SINGLE: "Company",
  COMPANY_CITY_NOT: "company_city_not",
  COMPANY_COUNTRY_NOT: "company_country_not",
  COMPANY_STATE_NOT: "company_state_not",
  PERSON_CITY_NOT: "person_city_not",
  COMPANY_KEYWORDS_NOT: "company_keywords_not",
  COMPANY_TECHNOLOGIES_NOT: "company_technologies_not",
  COMPANY_TECHNOLOGIES_ALL: "company_technologies_all",
  EXPORT_ALL_COLUMNS: "Export All Columns",
  EXPORT: "Export",
  MIN_REVENUE: "Minimum Revenue",
  ADV_EXPORT: "Advance Export",
  PIN_THIS_VIEW: "Pin This View",
  UNPIN_THIS_VIEW: "Unpin This View",
  MAKE_DEFAULT_VIEW: "Make Default View",
  SAVE_LIST: "save-list",
  EXPORT_MODAL_ID: "export_modal",
  MY_LIST: "My List",
  EXPORT_DATA: "export-data",
  SESSION: "Session expired.",
  DELETE: "Delete",
  CHECK: "check",
  TABLE_COLUMNS: "table_columns",
  RESOURCIFI: "resourcifi",
  DEMO: "demo",
  AGENCY_FOR_AGENCIES: "agencyforagencies",
  EXPORT_SELECT: "Export Selected Columns",
  ALL_VIEWS: "All Views",
  ADD_VIEW: "Add View",
  CONTACTS: "Contacts",
  COMPANIES: "Companies",
  IMPORT: "Import",
  LOADING: "loading",
  CREATE_LIST: "Create List",
  SAVE_VIEW: "Save View",
  COMPANY_HEADER: "company_header",
  SORT_BY: "Sort by",
  SORT: "sort",
  ORDER: "Order",
  STRING: "string",
  COMPANY_KEYWORDS: "company_keywords",
  COMPANY_KEYWORDS_ALL: "company_keywords_all",
  COMPANY_TECHNOLOGIES: "company_technologies",
  COMPANY_TECHNOLOGIES_CAPS: "Company Technologies",
  COMPANY_DESCRIPTION: "Company Description",
  SEO_DESCRIPTION: "seo_description",
  COMPANY_CITY: "company_city",
  COMPANY_CITY_CAPS: "Company City",
  COMPANY_STATE: "company_state",
  COMPANY_STATE_CAPS: "Company State",

  COMPANY_NAME: "company_name",
  COMPANY_COUNTRY: "company_country",
  COMPANY_COUNTRY_CAPS: "Company Country",
  COMPANY_IDS: "company_ids",
  PERSON_CITY: "person_city",
  PERSON_CITY_CAPS: "Contact City",
  PERSON_STATE: "person_state",
  PERSON_COUNTRY: "person_country",
  PERSON_COUNTRY_CAPS: "Contact Country",
  MANAGE_VIEWS: "Manage Views",
  FILTER: "Filter",
  CLEAR_ALL: "Clear All",
  LINKEDIN_URL: "LinkedIn URL",
  WEBSITE: "Website",
  ADD_FILTER: "Add More Filters",
  PERSONAL_INFORMATION: "Personal Information",
  LOCATION: "Location",
  COMPANY_INFO: "Company Info",
  ADD_FILTERS: "Add Filters",
  IS_ANY_OF: "Is any of",
  IS_NOT_ANY_OF: "Is not any of",
  IS_KNOWN_LABEL: "Is known",
  KNOWN: "Known",
  NAME_SMALL: "name",
  HEADER_ID: "head",
  UNKNOWN: "UnKnown",
  IS_UNKNOWN_LABEL: "Is unknown",
  RELEVANCE: "Relevance",
  SAVE_CONTACTS: "Save Contacts",
  SAVE_COMPANIES: "Save Companies",
  ADD_TO_LIST: "Add to Marketing List",
  SEARCH_PROPERTY: "Search property",
  STANDARD: "Standard",
  ALL_CONTACT_SAVED_VIEWS: "Contact Saved Views",
  COMPANY_SAVED_VIEWS: "Company Saved Views",
  PRIVATE_VIEW: "Private View",
  SHARED_VIEW: "Shared View",
  WRITE_VIEW_NAME: "Write view name",
  SEARCH: "Search",
  SELECTED_COMPANY: "Companies on this page are selected.",
  VIEW_MORE: "View More",
  LINKEDIN: "Linkedin",
  UNLOCK_BASIC_INFORMATION: "Unlock Basic Information",
  UNLOCK_BASIC_INFORMATION_DESC:
    "to get Email Address, Mobile No. & Corporate No",
  EMAIL_SMALL: "email",
  FILE_NAME_SMALL: "file_name",

  DOMAIN_CAPS: "Domain",
  DOMAIN_NAME_SMALL: "domain_name",
  BLOCKED_DOMAINS: "blocked_domains",
  EMAILS_CAPS: "Emails",
  ADMIN_USER: "admin-user",
  EMAIL: "Email",
  IMPORTED_FILES: "imported_files",
  CONTACT_NUMBER: "Contact Number",
  MOBILE_NUMBER: "Mobile Number",
  COMPANY_WEBSITE: "company_website",
  COMPANY_PHONE_NUMBER: "company_phone_number",
  CORPORATE_NUMBER: "Corporate Number",
  COMPANY_INFORMATION: "Company Information",
  TECHNOLOGIES: "Technologies",
  SHOW_MORE: "Show more...",
  NOTAPPLICABLE: "N/A",
  DIRECT_PHONE_NUMBER: "direct_phone_number",
  SHOW_LESS: "Show less...",
  COPIED_LABEL: "Copied",
  REMOVE_FROM_CONTACT_LIST: "Remove from Marketing List",
  REMOVE_FROM_LIST: "Remove",
  SELECT_LIST: "Select List",
  DELETE_CONTACT_FROM_LIST_MODAL_HEADER: "Remove selected records from list",
  DELETE_LIST_MODAL: "delete_list_modal",
  REMOVE_CONTACT_FROM_LIST_MODAL_DESCRIPTION:
    " Are you sure you want to remove list items?",
  DELETE_VIEW: "View Deleted Successfully",
  PINNED_VIEW: "View Pinned Successfully",
  UPLOAD_FILE: "upload_file",
  MAPPING_IS_MANDATORY: " Mapping is mandatory.",
  FOO: "foo",
  //BLACKLIST
  MAP_COLUMN: " Map the column headers in your file to ReachIQ Property",
  BLACKLIST_EMAILS: "Blacklist Emails",
  ENTER_MULTIPLE_EMAILS: "Enter Multiple Emails",
  ENTER_MUTLTIPLE_DOMAINS: "Enter Multiple Domains",
  BLACKLIST_DOMAINS: "Blacklist Domain",
  DOMAIN_BLOCKED: "domain_blocked",
  //
  SAD_FACE: "sad_face",
  MAIN: "main",
  VIEW_ID: "view-id",
  LOADING1: "Loading...",
  COLUMNS_LIST: "columns_list",
  MODAL_BODY: "modal_body",
  FORM: "form",
  SMALL_SEARCH: "search",
  PAGE: "page",
  SEARCH_LIST: "Search List",
  TRUE: "true",
  CNT_ON_PAGE: "contacts on this page are selected.",
  SELECT_ALL: "Select all",
  UNSELECT_ALL: "Unselect all",
  SMALL_CONTACTS: "contacts",
  BLANK: "_blank",
  COPIED_TEXT_CNTR: "copiedTextContainer",
  UNLOCK: "Unlock",
  ME: "Me",
  VIEW_PROFILE: "View Profile",
  VIEW_ALL: "View All",
  CONTACT_LST: "Marketing Lists",
  ONE_PAGE_SLT: "1 contact on this page is selected",
  EMPLOYEES: "Employees",
  ORDER: "Order",
  BLACKLIST: "Blacklist",
  ADD_TO_CRM: "Add to CRM",
  MOVE_TO_CRM: "Move to CRM",
  CRM_CONFIRMATION: "Are you sure you want to add selected contacts to CRM?",
  CONTACT_LINKED: "Contact Linkedin Profile",
  ADD_CONTACTS: "Add Contact",
  EDIT_CONTACTS: "Edit Contact",
  YOU_CAN: "You can now easily blacklist specific contacts and domains in all your scheduled campaigns and lists,",
  PROVIDING: "providing enhanced control over your communication strategies.",
  CREATE_VIEW: "Create View",
  EMPTY_VIEW_DESC: "Do you want to make a new view?",
  NO_TEAM_VIEW_CREATED: "No Team View Created!",
  NO_PRIVATE_VIEW_CREATED: "No Private View Created!",
  PROSPECTS: "Prospects",
  MARKETING: "Marketing",
  DO_YOU_BLACKLIST: "Do you want to blacklist contact in bulk?",
  ANNUAL_REVENUE: "Annual Revenue",
  COMPANY_WEBSITE: "Company Website",
  NEW: "New",
  ADD: "Add",
  COMPANY_ID: "company_id"
};
