import { getRequest } from "../../Apis";
import { leadsEndpoints } from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";
import { COMPANY_BASIC_DETAILS_FAIL, COMPANY_BASIC_DETAILS_REQUEST, COMPANY_BASIC_DETAILS_SUCCESS } from "../constants/companyConstants";

export const getCompanyBasicDetails = (navigate, query="", data={}) => async (dispatch) => {
    try { 
      if(query===true){
        dispatch({
          type: COMPANY_BASIC_DETAILS_SUCCESS,
          payload: data,
        });
      }else{
        dispatch({ type: COMPANY_BASIC_DETAILS_REQUEST });
        const response = await getRequest(navigate, leadsEndpoints.companyBasic+(query?.length ? query : ""));
     
        dispatch({
          type: COMPANY_BASIC_DETAILS_SUCCESS,
          payload: response?.data?.data,
        });  
      }
    } catch (err) {
      if(err?.status === 401){
        // navigate from this action
        navigate( LOGIN_PATH )
      }
      dispatch({ type: COMPANY_BASIC_DETAILS_FAIL });
    }
  };
  