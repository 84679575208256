import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paginationDataAction } from "../../redux/actions/customTableActions";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import PaginationIconButton from "../Buttons/PaginationIconButton";
import PaginationNumberButton from "../Buttons/PaginationNumberButton";
import CustomPaginationDropdown from "./CustomPaginationDropdown";
import { STRINGS } from "./strings";
import TypographyInline from "../Typography/TypographyInline";

const CustomPagination = ({ total_records = 10, id= "" }) => {
  const classes = {
    paginationMainContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "14px 16px",
    },
    paginationIconNumberContainer: {
      display: "flex",
      gap: "8px",
      justifyContent: "center",
      alignItems: "center",
    },
    pageLimitContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "4px",
    },
  };
  const dispatch = useDispatch();
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );
  const allFiltersSelector = useSelector((state) => state.allFilters);
  const idSelect = paginationDataSelector?.data
  // page result label displayed after pagination limit dropdown.
  const [pageResultLabel, setPageResultLabel] = useState("");

  // calculating the number of pages as per the total records.
  let pageNumber;
  if(id){
    pageNumber = Math.ceil(
      total_records / (idSelect?.[id]?.query?.limit || 25)
    ) || 1;
  }
  else{
    pageNumber = Math.ceil(
      total_records / (idSelect?.query?.limit || 25)
    ) || 1;
  }
    

  //Set total number of pages
  const numberOfPages = [];
  for (let i = 1; i <= pageNumber; i++) {
    numberOfPages.push(i);
  }

  useEffect(() => {
    // set label of total count of page results
    setPageResultLabel(`of ${total_records} results`);

    let currBtn ;
    if(id){
      currBtn = allFiltersSelector?.payload?.page ||
      idSelect?.[id]?.query?.page ||
      1;
    }
    else{
     currBtn =  allFiltersSelector?.payload?.page ||
     idSelect?.query?.page ||
      1;
    }

    let tempNumberOfPages = [...arrOfCurrButtons];

    let dotsInitial = "...";
    let dotsLeft = "...";
    let dotsRight = " ...";

    if (numberOfPages.length < 6) {
      tempNumberOfPages = numberOfPages;
    } else if (currBtn >= 1 && currBtn <= 3) {
      tempNumberOfPages = [
        1,
        2,
        3,
        4,
        dotsInitial,
        numberOfPages.length - 1,
        numberOfPages.length,
      ];
    } else if (currBtn === 4) {
      const sliced = numberOfPages.slice(0, 5);
      tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
    } else if (currBtn > 4 && currBtn < numberOfPages.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numberOfPages.slice(currBtn - 2, currBtn); // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numberOfPages.slice(currBtn, currBtn + 1); // sliced1 (5, 5+1) -> [6]
      tempNumberOfPages = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numberOfPages.length,
      ]; // [1, '...', 4, 5, 6, '...', 10]
    } else if (currBtn > numberOfPages.length - 3) {
      // > 7
      const sliced = numberOfPages.slice(numberOfPages.length - 4); // slice(10-4)
      tempNumberOfPages = [1, 2, dotsLeft, ...sliced];
    }
    setArrOfCurrButtons(tempNumberOfPages);
  }, [paginationDataSelector, total_records, allFiltersSelector]);

  const pageNumberClickHandler = (event) => {
    if (event.currentTarget.value !== "...") {
      let nestedTable = {
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          
        },
      };
      if (id) {
        nestedTable[id] = {
          ...paginationDataSelector.data?.[id],
          checkedRow: [],
          query: {
            ...paginationDataSelector.data?.[id]?.query,
            page: parseInt(event.currentTarget.value),
          },
        }
      }
      else{
        nestedTable = {
          ...paginationDataSelector.data,
          checkedRow: [],
          query : {
            ...paginationDataSelector.data?.query,
          page: parseInt(event.currentTarget.value),
          }
        }
      }
        dispatch(paginationDataAction({
          ...nestedTable
        }));
      }
  };

  const backArrowHandler = () => {
    let nestedTable ={
      ...paginationDataSelector.data,
      checkedRow: [],
      query: {
      },
    }
    if(id){
      nestedTable[id] = {
        ...paginationDataSelector.data?.[id],
      checkedRow: [],
      query: {
        ...paginationDataSelector.data?.[id]?.query,
        page: paginationDataSelector.data?.[id]?.query?.page - 1,
      },
      }
    }
    else{
      nestedTable= {
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          page: paginationDataSelector.data?.query?.page - 1,
        },
    }
  }
    dispatch(
      paginationDataAction({
         ...nestedTable
      })
    );
  };

  const forwardArrowHandler = () => {
    let nestedTable = {
      ...paginationDataSelector.data,
      checkedRow: [],
      query: {
              },
    }
    if(id){
      nestedTable[id] = {
        ...paginationDataSelector.data?.[id],
      checkedRow: [],
      query: {
        ...paginationDataSelector.data?.[id]?.query,
        page: (paginationDataSelector.data?.[id]?.query?.page || 1) + 1,
      },
      }
    }
    else{
      nestedTable = {
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          page: (paginationDataSelector.data?.query?.page || 1) + 1,
        },
    }
  }
    dispatch(
      paginationDataAction({
        ...nestedTable
      })
    );
  };

  const pagesList = [
    { id: "10", label: 10 },
    { id: "25", label: 25 },
    { id: "50", label: 50 },
    { id: "100", label: 100 },
  ];

  return (
    <div style={classes.paginationMainContainer}>
      {/* Page Numbers with Left and Right Icon */}
      <div style={classes.paginationIconNumberContainer}>
        <PaginationIconButton
          iconLabel={"arrow_back"}
          onClick={
            (id ? idSelect?.[id]?.query?.page > 1 : idSelect?.query?.page > 1)
              ? backArrowHandler
              : undefined
          }
          disabled={(id ? idSelect?.[id]?.query?.page || 1 : idSelect?.query?.page || 1) === 1}
        />

        {/* Pages number to be displayed. */}
        {arrOfCurrButtons.map((index) => {
          return (
            <PaginationNumberButton
              key={index}
              item={index}
              onClick={index !== "..." ? pageNumberClickHandler : () => {}}
              readOnly={index === "..."}
              id={id}
            />
          );
        })}

        <PaginationIconButton
          iconLabel={"arrow_forward"}
          onClick={
            (id ? idSelect?.[id]?.query?.page || 1 : idSelect?.query?.page || 1) <
            numberOfPages.length
              ? forwardArrowHandler
              : undefined
          }
          disabled={
            (id ? (idSelect?.[id]?.query?.page ===
              numberOfPages.length || numberOfPages.length === 1): (idSelect?.query?.page ===
              numberOfPages.length || numberOfPages.length === 1))
          }
        />
      </div>
      {/* Page Numbers with Left and Right Icon */}

      {/* Page Limit */}
      <div style={classes.pageLimitContainer}>
        <TypographyInline
          label={STRINGS.PAGINATION_PAGE_LABEL}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.MEDIUM}
          size={"xs"}
        />

        <CustomPaginationDropdown items={pagesList} id={id}/>

        <TypographyInline
          label={pageResultLabel}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.MEDIUM}
          size={"xs"}
        />
      </div>
      {/* Page Limit */}
    </div>
  );
};

export default CustomPagination;
