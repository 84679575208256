import React from "react";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../Typography/TypographyInline";

const Checkbox = ({ shadow, partial, ...props }) => {
  return (
    <div
      className="check-box-group"
      style={{ overflow: "hidden", width: props?.label ? "100%" : "auto" }}
    >
      <label
        style={{
          margin: props.noMargin && 0,
          display: "flex",
          gap: "8px",
          overflow: "hidden",
        }}
        className={partial ? "partial-checkbox" : "custom-checkbox"}
        tab-index="0"
        aria-label="Checkbox Label"
      >
        <input
          type="checkbox"
          {...props}
          onChange={props?.onChange ? props?.onChange : () => { }}
        />
        <span
          className="checkmark"
          style={{
            boxShadow: shadow ? "0px 3px 6px #0000001A" : "unset",
            cursor: props.cursor ? props.cursor : "pointer",
          }}
        ></span>
        {props?.label && (
          <TypographyInline
            label={props?.label}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
            isEllipses
          />
        )}
      </label>
    </div>
  );
};

export default Checkbox;

// ------------- NOTE ----------------
// Props description :-
// onChange :- you have to always pass onChange function from parent.
// checked :- you have to pass checked state as true false from parent.
// partial :- this prop is used for showing partial selection.
// disabled :- This prop will disabled the checkbox.
