import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import * as Papaparse from "papaparse";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getImportCsv } from "../../redux/actions/csvActions";
import SearchField from "../../components/InputFields/SearchField";
import CustomHeader from "../../components/Header/CustomHeader";
import { STRINGS } from "./strings";
import CustomTable from "../../components/Table/CustomTable";
import ImportHeader from "./ImportHeader";
import { CSV_HEADERS, DEBOUNCE_DELAY, EACH_BATCH_SIZE, EMITTER_ERROR, IMPORT_COLUMN_PEOPLE_PROPERTY_COLUMNS } from "../../utils/constants";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import { importEndpoints } from "../../utils/constants/httpConstants";
import { postRequest } from "../../Apis";
import { debounceHelper, match_import_fields } from "../../utils/helpers";
import { paginationDataAction } from "../../redux/actions/customTableActions";
import { Button, CustomModal, EmptyScreen } from "../../components";
import CompanyMappingCsvFieldModal from "./CompanyMappingCsvFieldModal";
import MappingCsvFieldModal from "./MappingCsvFieldModal";
import { GREY } from "../../utils/constants/colors";
import TypographyInline from "../../components/Typography/TypographyInline";
import { COMPANY_IMAGE, PEOPLE_IMAGE, SALES_EMPTY_SCREEN } from "../../utils/constants/assets";
import Image from "../../components/Image/Image";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { allFilters } from "../../redux/actions/filterActions";
import { initialQueryPayload } from "../../redux/store/storeConstants";

const ImportScreen = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resolvedRecords, setResolvedRecords] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [file, setFile] = useState(null);
  const [allColumns, setAllColumns] = useState([]);
  const [opened, setOpened] = useState(false);
  const [uploadtitle, setUploadTitle] = useState("");

  const [showMappingModal, setShowMappingModal] = useState(false);
  const [showCompanyMappingModal, setShowCompanyMappingModal] = useState(false);

  const setHighlighted = useState(false);
  const dynamicFields = IMPORT_COLUMN_PEOPLE_PROPERTY_COLUMNS || [];

  const peopleDynamicFields = [];
  const companyDynamicFields = [];
  const peopleCustomFields = [];
  const companyCustomFields = [];

  const headerItems = [
    { id: STRINGS.csvEnrichment, label: STRINGS.CSV_ENRICHMENT },
  ];
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );
  useEffect(() => {
    (async () => {
      const payload = {
        sort: paginationDataSelector?.data?.query?.sort,
        limit: paginationDataSelector?.data?.query?.limit || 25,
        page: paginationDataSelector?.data?.query?.page,
        file_name: searchField,
      };

      await dispatch(getImportCsv(payload, navigate));
    })();
  }, [dispatch, navigate, paginationDataSelector?.data?.query]);
  const CSVListSelector = useSelector((state) => state.getImportCsv);
  const records = CSVListSelector?.payload?.data?.total;
  const classes = styles();

  console.log("CSV", CSVListSelector)

  async function readFileChunk(time, index, fil) {
    let reader = new FileReader();
    let blob = fil.slice(
      EACH_BATCH_SIZE * index,
      EACH_BATCH_SIZE * (index + 1)
    );
    reader.onload = async function (e) {
      const each_parsed_string = e.target.result;
      const ParsedRes = Papaparse.parse(each_parsed_string);
      const result = [];
      const tempData = ParsedRes.data[0];
      const property_values = [];
      for (let i = 0; i < tempData?.length; i++) {
        let eachObj = { column: tempData?.[i], property_type: STRINGS.PEOPLE };
        const each_property_value = match_import_fields(
          tempData?.[i],
          peopleDynamicFields,
          companyDynamicFields
        );
        eachObj.property_value = each_property_value;
        each_property_value && property_values.push(each_property_value);
        result.push(eachObj);
      }

      setAllColumns(result);
      // make file sending endpoint here.
      // return await getRequest(navigate, campaignEndpoints.contactlist );
    };
    reader.readAsText(blob);
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const handleFileUpload = async (e) => {
    // console.log(e.target.files,"test")
    // checking for 1gb here
    const fileSize = e?.target?.files?.[0]?.size / (1024 * 1024);
    const uploadedFile = e?.dataTransfer?.files?.[0] || e?.target?.files?.[0];
    // console.log({uploadedFile})

    if (fileSize >= 1024) {
      EventEmitter.emit(EMITTER_ERROR, STRINGS.FILE_SIZE);
      return;
    }
    if (uploadedFile?.type !== STRINGS.TEXT_CSV) {
      EventEmitter.emit(EMITTER_ERROR, STRINGS.INVALID_TYPE);
      e.target.value = "";
      return;
    }
    setFile(uploadedFile);
    if (uploadedFile && uploadtitle === STRINGS.ENRICH_PEOPLE) {
      setShowMappingModal(true);
      await readFileChunk(1000, 0, uploadedFile);
      e.target.value = "";
    } else if (uploadedFile && uploadtitle === STRINGS.ENRICH_COMPANY) {
      setShowCompanyMappingModal(true);
      await readFileChunk(1000, 0, uploadedFile);
      e.target.value = "";
    }
  };
   
  const handleUpload = (title) => {
    dispatch(allFilters({...initialQueryPayload},true));
    setUploadTitle(title);
    document.getElementById("file-upload").click();
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.HEAD,
      label: STRINGS.CSV_ENRICHMENT,
    },
  };
  const modalBodyData = (
    <div>
      <Box className={classes.modalBodyContainer}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.SELECT_CSV}
        />
      </Box>

      <Box className={classes.containAllSteps1}>
        <Box className={classes.stepTypeBox}>
          <Box className={classes.containImage}>
            <Image src={PEOPLE_IMAGE} className={classes.ImageCss} />
          </Box>

          <Box className={classes.descContain}>
            <Box className={classes.txtAlgn}>
              <TypographyInline
                size={"md"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.SEMIBOLD}
                label={STRINGS.ENRICH_PEOPLE}
              />
            </Box>
            <Box className={classes.txtAlgn}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.SEL_PPL}
              />
            </Box>
          </Box>

          <Box className={classes.justifyContentCenter}>
            <Button
              label={STRINGS.UPLOAD_CSV_ENRICHMENT}
              variant={"primary"}
              size={"sm36"}
              onClick={() => {
                handleUpload(STRINGS.ENRICH_PEOPLE);
              }}
            />
          </Box>
        </Box>
        <hr className={classes.hline} />

        <Box className={classes.stepTypeBox}>
          <Box className={classes.containImage}>
            <Image src={COMPANY_IMAGE} className={classes.ImageCss} />
          </Box>

          <Box className={classes.descContain}>
            <Box className={classes.txtAlgn}>
              <TypographyInline
                size={"md"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.SEMIBOLD}
                label={STRINGS.ENRICH_COMPANY}
              />
            </Box>
            <Box className={classes.txtAlgn}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.REGULAR}
                label={
                  STRINGS.SEL_CMP
                }
              />
            </Box>
          </Box>

          <Box className={classes.justifyContentCenter}>
            <Button
              label={STRINGS.UPLOAD_CSV_ENRICHMENT}
              variant={"primary"}
              size={"sm36"}
              onClick={() => {
                handleUpload(STRINGS.ENRICH_COMPANY);
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );

  const handleOpen = () => {
    setOpened(!opened);
  };

  const handleSearchSubmit = (e) => {
    setSearchField(e.target.value);

    if (e) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          search: true,
        }).toString(),
      });
      const payload = {
        // sort: paginationDataSelector?.data?.query?.sort,
        // limit: paginationDataSelector?.data?.query?.limit || 10,
        // page: paginationDataSelector?.data?.query?.page,
        file_name: e.target.value,
      };
      dispatch(getImportCsv(payload));
      dispatch(
        paginationDataAction({
          checkedRow: [],
          query: {
            ...paginationDataSelector.data?.query,
            page: 1
          },
        })
      );
    } else {
      navigate({
        pathname: location.pathname,
      });
    }
  };
  const debounceSearch = debounceHelper(handleSearchSubmit, DEBOUNCE_DELAY);

  useEffect(() => {
    setResolvedRecords(CSVListSelector?.payload?.data?.data || []);
    if (CSVListSelector?.payload?.data?.data?.length) {
      (async () => {
        try {
          const owner_ids = CSVListSelector?.payload?.data?.data?.map(
            (item) => item?.user_id
          );
          // call stats api and owner api here, do promise.allSettled here.
          const promises = [
            postRequest(navigate, importEndpoints.getUser, {
              user_ids: owner_ids,
            }),
          ];
          const resolved_promises = await Promise.allSettled(promises);

          const owner_payload = resolved_promises[0]?.value?.data;

          // owner has been set.
          const structured_records = CSVListSelector?.payload?.data?.data?.map(
            (item) => {
              return (
                {
                  ...item,
                  owner: owner_payload?.data?.users?.[item?.user_id],
                } || ""
              );
            }
          );
          if (structured_records?.length) {
            setResolvedRecords(structured_records);
          }
        } catch (error) {
          console.log("error", error);
        }
      })();
    }
  }, [CSVListSelector?.payload?.data?.data, navigate]);

  const CsvData = {
    heads: CSV_HEADERS,
    body: resolvedRecords,
    operations: [],
    actions: {
      button: {
        function: (a) => HandleButton(a),
      },
    },
    sortHandler: {},
    total_records: records || "",
    isLoading: CSVListSelector?.loading,
    is_selection_not_allowed: true,
    disable_key: "status",
  };

  const HandleButton = (row) => {
    let anchor = document.createElement("a");
    anchor.href = row?.export_csv_url;
    anchor.target = "_blank";
    anchor.click();
  };
  return (
    <>
      <Box>
        <CustomHeader
          items={headerItems}
          description={
            CSVListSelector?.loading
              ? "Loading"
              : records
                ? `${records} ${STRINGS.FILES} `
                : "0 File"
          }
        />
      </Box>
      {CSVListSelector?.payload?.is_empty ?
        <>
          <EmptyScreen
            imageSrc={SALES_EMPTY_SCREEN}
            label={STRINGS.IMPORT_A_FILE}
            description={STRINGS.YOU_CAN_UPLOAD}
            buttonLabel={STRINGS.IMPORT}
            onClick={() => {
              handleOpen();
            }}
          />
          <input
            id="file-upload"
            type="file"
            className={classes.inputFile}
            onChange={handleFileUpload}
            accept=".csv"
          />
        </>
        :
        <Box className={classes.HEIGHt}>
          <Box className={classes.folderRightSide}>
            <Box className={classes.displayFlex}>
              <Box className={classes.commonWidth} onSubmit={handleSearchSubmit}>
                <SearchField
                  variant={
                    CSVListSelector?.payload?.is_empty
                      ? STRINGS.DISABLED
                      : STRINGS.DEFAULT
                  }
                  size={"sm36"}
                  LeftIcon={ICON_LABELS.search}
                  onChange={debounceSearch}
                  placeholder={STRINGS.SEARCH}
                />
              </Box>
            </Box>
            <Box className={classes.displayFlex}>
              {" "}
              {/* <ImportHeader /> */}
              <Box>
                <div className="contain-drag-drop">
                  <div
                    onDragEnter={() => {
                      setHighlighted(true);
                    }}
                    onDragLeave={() => {
                      setHighlighted(false);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={handleFileUpload}
                  >
                    <div className="for-center">
                      <div className="for-center">
                        <label htmlFor="file-upload" className="custom-file-upload">
                          <Box>
                            <Box className={classes.padding}>
                              <Button
                                label={STRINGS.IMPORT}
                                LeftIcon={ICON_LABELS.UPLOAD_FILE}
                                size={"sm36"}
                                variant={"primary"}
                                onClick={() => {
                                  handleOpen();
                                }}
                              />
                            </Box>
                          </Box>
                        </label>
                        <input
                          id="file-upload"
                          type="file"
                          className={classes.inputFile}
                          onChange={handleFileUpload}
                          accept=".csv"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>

          <div className={classes.tableUi}>
            <CustomTable tableData={CsvData} />
          </div>
        </Box>
      }
      <CustomModal
        width={"35.990vw"}
        close={handleOpen}
        open={opened}
        header={modalHeaderData}
        children={modalBodyData}
        remove_padding={true}
      />
      {showMappingModal &&
      <MappingCsvFieldModal
        file={file}
        setFile={setFile}
        columns={allColumns}
        open={showMappingModal}
        setOpen={setShowMappingModal}
        dynamicFields={dynamicFields}
        peopleDynamicFields={peopleDynamicFields}
        companyDynamicFields={companyDynamicFields}
        predefinedPeopleCustomFields={peopleCustomFields}
        predefinedCompanyCustomFields={companyCustomFields}
        setOpened={setOpened}
        opened={opened}
      />
      }
      {showCompanyMappingModal &&
      <CompanyMappingCsvFieldModal
        file={file}
        setFile={setFile}
        columns={allColumns}
        open={showCompanyMappingModal}
        setOpen={setShowCompanyMappingModal}
        dynamicFields={dynamicFields}
        peopleDynamicFields={peopleDynamicFields}
        companyDynamicFields={companyDynamicFields}
        predefinedPeopleCustomFields={peopleCustomFields}
        predefinedCompanyCustomFields={companyCustomFields}
        setOpened={setOpened}
        opened={opened}
      />
      }
    </>
  );
};

export default ImportScreen;
