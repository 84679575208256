import { combineReducers } from "redux";
import {
  columnUpdateReducer,
  ApplyUpdateColumnReducer,
  tableDataFetchReducer,
  tableEndpointChangeReducer,
  companyTableEndpointChangeReducer,
  tableDataFetchCampaignReducer,
  getListIdsReducer,
  dynamicColumnUpdateReducer,
  dynamicUpdatedColumnReducer,
} from "./tableReducer";
import { modeChangeReducer } from "./modeReducer";
import {
  allFiltersReducer,
  filterHeaderAccountListReducer,
  filterHeaderCompanyCityReducer,
  filterHeaderCompanyCountryReducer,
  filterHeaderCompanyKeywordsReducer,
  filterHeaderCompanyNameReducer,
  filterHeaderCompanyReducer,
  filterHeaderCompanySeoReducer,
  filterHeaderCompanyStateReducer,
  filterHeaderCompanyTechnologiesReducer,
  filterHeaderEmailVerificationReducer,
  filterHeaderIndustryReducer,
  filterHeaderJobTitleReducer,
  filterHeaderPersonCityReducer,
  filterHeaderPersonCountryReducer,
  filterHeaderPersonStateReducer,
  filterHeaderSearcheReducer,
  filterHeaderSeniorityReducer,
  filterHeaderContactListReducer,
  allDynamicFiltersReducer,
  filterMultipleListReducer,
} from "./filterReducer";
import {
  checkLoginReducer,
  invitedUsersListReducer,
  loginReducer,
  logoutReducer,
  registerReducer,
  resetPasswordMailSendReducer,
  resetPasswordReducer,
  usersListReducer,
  verifyDomainReducer,
  userDetailsReducer,
  getSignatureReducer,
  tenantUsersListReducer,
} from "./authReducer";
import {
  peopleAccountDetailsReducer,
  peopleBasicDetailsReducer,
  peopleLeadsDetailsReducer,
  peopleUserDetailsReducer,
} from "./peopleReducer";
import { createContactListReducer, getContactListReducer } from "./listReducer";
import { companyBasicDetailsReducer } from "./companyReducer";
import { getAllStepsReducer } from "./stepsReducer";

import {
  getSequenceListReducer,
  selectedSequenceDetailsReducer,
} from "./listTableReducer";
import {
  campaignsListFetchReducer,
  getCampaginFolderListReducer,
  selectedCampaignDetailsReducer,
} from "./campaignReducer";
import { getFolderListReducer } from "./listFolderReducer";
import { getTempListReducer } from "./templateFolderReducer";
import { getCampFolderReducer } from "./campaignFolderReducer";
import {
  getTemplateTableListReducer,
  getAllTempReducer,
  allTemplateFiltersReducer,
} from "./templateListReducer";
import { getContactListFolderReducer } from "./contactListFolderReducer";
import { getImportCustomFieldsReducer } from "./importReducer";
import { editReducer, getMeetingLinkReducer } from "./editorReducer";
import {
  chartDataReducer,
  currentDashboardReducer,
  dashboardReducer,
  dashboardWidgetsReducer,
  widgetCoordinatesReducer,
} from "./dashboardReducer";
import {
  allMeetingFiltersReducer,
  getBookedMeetingReducer,
  getMeetingListReducer,
} from "./meetingReducer";
import { brandKitListReducer } from "./brandingKitReducer";
import {
  taskReducer,
  taskSearchReducer,
  taskUpdateReducer,
} from "./taskReducers";
import { brandingDetailsReducer } from "./brandingDetailsReducer";
import { getContactListDataReducer } from "./contactListReducer";
import { getImportCsvReducer } from "./csvReducer";
import { paginationDataReducer } from "./customTableReducer";
import {
  allSalesFiltersReducer,
  getSalesContactsListReducer,
} from "./salesTableReducer";
import {
  allPropertiesFiltersReducer,
  createGroupNameReducer,
  getGroupListByOBjectTypeReducer,
  getGroupListReducer,
  getPropertiesListReducer,
} from "./propertiesReducer";
import { sequenceListReducer } from "./sequenceReducer";
import { getDealsListReducer, peopleDealsDetailsReducer } from "./dealsReducer";
import { getPipelineListReducer } from "./getPipelineReducer";
import { snippetsListReducer } from "./snippetReducer";
// All combined reducers here.
export const reducers = combineReducers({
  userDetails: userDetailsReducer,
  tenantUsersList: tenantUsersListReducer,
  columnUpdate: columnUpdateReducer,
  dynamicColumnUpdate: dynamicColumnUpdateReducer,
  ApplyUpdateColumn: ApplyUpdateColumnReducer,
  dynamicUpdatedColumn: dynamicUpdatedColumnReducer,
  modeChange: modeChangeReducer,
  register: registerReducer,
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  logout: logoutReducer,
  checkLogin: checkLoginReducer,
  verifyDomain: verifyDomainReducer,
  resetPasswordMailSend: resetPasswordMailSendReducer,
  filterHeaderSearch: filterHeaderSearcheReducer,
  filterHeaderJobTitle: filterHeaderJobTitleReducer,
  filterHeaderIndustry: filterHeaderIndustryReducer,
  filterHeaderEmailVerification: filterHeaderEmailVerificationReducer,
  tableDataFetch: tableDataFetchReducer,
  getListIds: getListIdsReducer,
  tableDataCampaignFetch: tableDataFetchCampaignReducer,
  peopleBasicDetails: peopleBasicDetailsReducer,
  leadsBasicDetails: peopleLeadsDetailsReducer,

  companyBasicDetails: companyBasicDetailsReducer,
  allFilters: allFiltersReducer,
  filterHeaderCompany: filterHeaderCompanyReducer,
  filterHeaderSeniority: filterHeaderSeniorityReducer,
  filterHeaderCompanyName: filterHeaderCompanyNameReducer,
  filterHeaderCompanyKeywords: filterHeaderCompanyKeywordsReducer,
  filterHeaderCompanyTechnologies: filterHeaderCompanyTechnologiesReducer,
  filterHeaderCompanySeo: filterHeaderCompanySeoReducer,
  filterHeaderPersonCity: filterHeaderPersonCityReducer,
  filterHeaderPersonState: filterHeaderPersonStateReducer,
  filterHeaderPersonCountry: filterHeaderPersonCountryReducer,
  filterHeaderCompanyCity: filterHeaderCompanyCityReducer,
  filterHeaderCompanyState: filterHeaderCompanyStateReducer,
  filterHeaderCompanyCountry: filterHeaderCompanyCountryReducer,
  filterHeaderAccountList: filterHeaderAccountListReducer,
  filterHeaderContactList: filterHeaderContactListReducer,
  peopleUserDetails: peopleUserDetailsReducer,
  tableEndpointChange: tableEndpointChangeReducer,
  companyTableEndpointChange: companyTableEndpointChangeReducer,
  // List reducers.
  createContactList: createContactListReducer,
  getContactList: getContactListReducer,
  getContactDataList: getContactListDataReducer,
  // steps reducers
  getAllSteps: getAllStepsReducer,
  // Get List Reducer
  getSequenceList: getSequenceListReducer,
  selectedSequenceDetails: selectedSequenceDetailsReducer,
  // Campaigns reducers
  campaignsListFetch: campaignsListFetchReducer,
  selectedCampaignDetails: selectedCampaignDetailsReducer,

  // profile reducers
  invitedUsersList: invitedUsersListReducer,
  usersList: usersListReducer,
  // Views reducers
  getFolderList: getFolderListReducer,
  getCampaginFolderList: getCampaginFolderListReducer,
  //template folder
  getTempfolderList: getTempListReducer,
  //template List
  getTempList: getTemplateTableListReducer,
  getAllTemplate: getAllTempReducer,
  //editor Reducer
  editorData: editReducer,
  getMeetingLink: getMeetingLinkReducer,
  //contactListFolder
  getContactListFolderReducer: getContactListFolderReducer,

  // import
  getImportCustomFields: getImportCustomFieldsReducer,
  // csv import
  getImportCsv: getImportCsvReducer,
  //dashboard reducers
  getDashboardDetails: dashboardReducer,
  getDashboardWidgetDetails: dashboardWidgetsReducer,
  getChartData: chartDataReducer,
  getWidgetCoordinates: widgetCoordinatesReducer,
  getCurrentDashboard: currentDashboardReducer,

  //Meeting Reducer

  getMeetingList: getMeetingListReducer,
  getBookedMeetingList: getBookedMeetingReducer,
  //Campaign Folder reducer
  getCampaignFolder: getCampFolderReducer,
  //BrandingKit

  getBrandKitList: brandKitListReducer,

  //BrandDetails

  getBrandDetailsList: brandingDetailsReducer,

  //Task Reducers
  getTaskDetails: taskReducer,
  getTaskContactList: taskSearchReducer,
  updateTaskList: taskUpdateReducer,
  //Signature Reducer
  getSignature: getSignatureReducer,

  // Custom Table Query Data
  getPaginationQuery: paginationDataReducer,

  // sequence reducer
  sequenceList: sequenceListReducer,

  // CRM Reducer
  getSalesContactList: getSalesContactsListReducer,
  getGroupList: getGroupListReducer,
  getPropertiesList: getPropertiesListReducer,
  allSalesFilters: allSalesFiltersReducer,
  allPropertiesFilters: allPropertiesFiltersReducer,
  allTemplateFilter: allTemplateFiltersReducer,
  getGroupsListByObjectType: getGroupListByOBjectTypeReducer,
  getDealsList: getDealsListReducer,

  allDynamicFilters: allDynamicFiltersReducer,
  peopleAccountDetails: peopleAccountDetailsReducer,
  allMeetingFilters: allMeetingFiltersReducer,
  peopleDealsDetails: peopleDealsDetailsReducer,
  filterMultipleList: filterMultipleListReducer,
  getPipelineList: getPipelineListReducer,

  getSnippetsList: snippetsListReducer,
});
