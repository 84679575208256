import { Icon } from "@mui/material";
import React from "react";

const IconComponent = ({
  color,
  fontSize,
  iconLabel,
  transform = false,
  rounded=true,
  ...props
}) => {
  const styles = {
    iconComponent: {
      fontSize: fontSize,
      color: color,
      transform: transform && "rotate(90deg)"
    },
  };
  const classes = styles;

  return (
    <Icon baseClassName={rounded && "material-icons-round"} style={classes.iconComponent} {...props}>
     { iconLabel}
    </Icon>
  );
};

export default IconComponent;
