import { CAMPAIGN_LIST_GET_FAIL, CAMPAIGN_LIST_GET_REQUEST, CAMPAIGN_LIST_GET_RESET, CAMPAIGN_LIST_GET_SUCCESS, GET_CAMPAIGN_FOLDER_LIST_FAIL, GET_CAMPAIGN_FOLDER_LIST_REQUEST, GET_CAMPAIGN_FOLDER_LIST_SUCCESS, SELECTED_CAMPAIGN_DETAILS_FAIL, SELECTED_CAMPAIGN_DETAILS_REQUEST, SELECTED_CAMPAIGN_DETAILS_SUCCESS } from "../constants/campaignConstants";

export const campaignsListFetchReducer = (state = {}, action) => {
    switch (action.type) {
      case CAMPAIGN_LIST_GET_REQUEST:
        return { loading: true };
  
      case CAMPAIGN_LIST_GET_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case CAMPAIGN_LIST_GET_FAIL:
        return { loading: false, error: action.payload };

        case CAMPAIGN_LIST_GET_RESET:
          return { loading: false, payload: action.payload };
    
      default:
        return state;
    }
  };
  
  export const selectedCampaignDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case SELECTED_CAMPAIGN_DETAILS_REQUEST:
        return { loading: true };
  
      case SELECTED_CAMPAIGN_DETAILS_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case SELECTED_CAMPAIGN_DETAILS_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  

  export const getCampaginFolderListReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_CAMPAIGN_FOLDER_LIST_REQUEST:
        return { loading: true };
  
      case GET_CAMPAIGN_FOLDER_LIST_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case GET_CAMPAIGN_FOLDER_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  