import React from 'react'
import SkeletonLoader from '../../../../components/Loaders/SkeletonLoader';
import classes from "./DealsComp.module.css";

const DealsBoardLoader = () => {
    return (
        <div className={classes.boardViewLoaderMainContainer} >
            <div className={classes.boardViewLoaderTicketHeaderContainer} >
                <SkeletonLoader type="rounded" stackWidth="40%" stackHeight='52px' />
                <SkeletonLoader type="rounded" stackWidth="40%" stackHeight='52px' />
                <SkeletonLoader type="rounded" stackWidth="40%" stackHeight='52px' />
                <SkeletonLoader type="rounded" stackWidth="40%" stackHeight='52px' />
            </div>
            <div className={classes.boardViewLoaderTicketContainer} >
                <SkeletonLoader type="rounded" stackWidth="40%" />
                <SkeletonLoader type="rounded" stackWidth="40%" />
                <SkeletonLoader type="rounded" stackWidth="40%" />
                <SkeletonLoader type="rounded" stackWidth="40%" />
            </div>
        </div>
    )
}

export default DealsBoardLoader