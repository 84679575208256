import { InvalidRouteScreen } from "../screens";
import { DEFAULT_PROTECTED_PATH } from "../utils/constants/routes";


export const defaultRoutes = [
    {
        path: DEFAULT_PROTECTED_PATH,
        component: InvalidRouteScreen,
    },
];
