import { makeStyles } from "@mui/styles";
import { fontSize, fontWeight } from "../../utils/constants/UI";
import { BASE, ERROR } from "../../utils/constants/colors";
import { ACCOUNTFLAG_BG } from "../../utils/constants/assets";

export const styles = makeStyles({
  containUnsubscribeBox: {
    background: "#ffe9e9",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  MAinContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "8px",
    paddingBottom: "12px",
    textAlign: "center"
  },
  unsubscribeBox: {
    width: "40vw",
    height: "50vh",
    background: "white",
    boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "75vw",
      padding: "1rem"
    },
  },
  ImageBorder: {
    width: "153px",
    height: "153px",
    border: `1px solid ${ERROR.W_400}`,
    display: "flex",
    borderRadius: "90px",
    justifyContent: "center",
    alignItems: "center",
  },
  containImage: {
    background: "#F7F9FB",
    padding: "1.5rem",
    width: "fit-content",
    borderRadius: "50%",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "max-content",
  },
  button: {
    background: "#ff4848",
    color: "white",
    width: "8rem",
    padding: "0.8rem",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
  },

  //css

  Image: {
    height: "100px",
    width: "100px",
  },
  IMMAGE: {
    maxHeight: "20vh",
  },
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  // ENd EmailVerification Css
  container1: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  head: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
  Top: {
    width: "300px",
    paddingTop: "24px",
  },
  TypoHead: {
    marginLeft: "0.2rem",
  },

  SubHead: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  Head: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
  containLoader: {
    margin: " 5vh",
  },
  title: {
    color: "#102f51",
    fontWeight: "600",
    textAlign: "center",
    fontSize: "clamp(1rem, 1.5vw, 3rem)",
  },
  homeBtn: {
    background: "white",
    border: "1px solid #ff4848",
    padding: "0.5rem 2rem",
    color: "#ff4848",
    borderRadius: "4px",
    fontFamily: "inter_600",
    cursor: "pointer",
  },
  boldTitle: {
    color: "#102f51",
    fontWeight: "bolder",
    textAlign: "center",
    fontSize: "clamp(1rem, 2vw, 3rem)",
  },
  para: {
    margin: "1rem",
    color: "#626b7e",
    fontWeight: "bolder",
    textAlign: "center",
    fontSize: "clamp(0.8rem, 0.8vw, 3rem)",
  },
  flexJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  continueBtn: {
    background: "#ff4848",
    padding: "0.8rem 2rem",
    border: "none",
    color: "white",
    fontSize: "clamp(0.8rem, 0.8vw, 3rem)",
    fontWeight: "700",
    borderRadius: "4px",
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  tableBox: {
    margin: "2rem 25%",
  },
  url: {
    fontWeight: "600",
    color: "#102f51",
    fontSize: "16px",
  },
  heading: {
    fontWeight: "700",
    color: "#102f51",
    fontSize: "16px",
  },
  accountsTitle: {
    textAlign: "center",
    color: "#102f51",
    margin: "1rem",
    fontWeight: "600",
    fontSize: "clamp(1rem, 1.2vw, 3rem)",
  },
  containTexts: {
    paddingBottom: "1rem",
    borderBottom: "1px solid #d3dae3",
    paddingTop: "60px",
  },
  headers: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  containBtns: {
    display: "flex",
    gap: "8px",
    marginRight: "8px",
    // display: "grid",
    marginTop: "1rem",
    gridTemplateColumns: "1fr 2fr",
  },
  cancelBtn: {
    border: "1px solid #d3dae3",
    background: "white",
    marginRight: "1rem",
    borderRadius: "4px",
    cursor: "pointer",
  },
  eachTenantContainer: {
    display: "flex",
    border: "1px solid #d3dae3",
    margin: "1rem 0",
    padding: "0.6rem 1rem",
    borderRadius: "6px",
  },

  tenant_url: {
    color: "#102f51",
    alignSelf: "center",
    fontWeight: "600",
    fontSize: "clamp(0.8rem , 0.8vw, 3rem)",
    marginLeft: "1rem",
  },
  privacyLinkSeperatorText: {
    fontSize: "clamp(12px, 0.729vw, 30px)",
    fontWeight: "500",
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: "#626B7E",
  },
  privacyLink: {
    fontSize: fontSize.XS,
    fontWeight: fontWeight.REGULAR,
    lineHeight: "18px",
    // fontFamily: "inter_500",
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: "#626B7E",
    transition: "100ms",
    "&:hover": {
      color: "#ff4848",
    },
  },
  termsPara: {
    fontSize: fontSize.XS,
    color: "#626B7E",
    fontFamily: "inter_400",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  logo_container: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "100px",
  },
  containPolicy: {
    paddingTop: "14px",
    // paddingBottom: "38px",
    display: "flex",
    justifyContent: "center",
  },
  sorryTitle: {
    fontSize: "4rem",
    fontWeight: "bolder",
    margin: "1rem 0 2rem 0",
    lineHeight: 1,
  },
  sorryPara: {
    fontSize: "16px",
    color: "#626b7e",
    width: "30vw",
    lineHeight: "1.5",
    textAlign: "center",
  },
  link: {
    fontSize: "16px",
    color: "#626b7e",
    width: "30vw",
    lineHeight: "35px",
    cursor: "pointer",
    marginRight: "0.75rem",
    paddingRight: "0.75rem",
    borderRight: "1px solid #d3dae3",
    textDecoration: "none",
    "&:hover": {
      color: "#ff4848",
    },
  },
  contains: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    height: "50vh",
    padding: "1rem",
  },
  invalidContentContainer: {
    paddingTop: "60px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  reachiqLogo: {
    maxHeight: "100px",
    cursor: "pointer",
  },
  icon: {
    color: "#626b7e",
    transition: "100ms ease-in-out",
    marginRight: "8px",
    alignSelf: "center",
    cursor: "pointer",
    // "&:hover":{
    //     color:"#ff4848"
    // }
  },
  containIcons: {
    display: "flex",
    justifyContent: "space-around",
  },
  containFooter: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2rem 5vw",
    borderTop: "1px solid #d3dae3",
    marginTop: "1rem",
  },
  btn: {
    background: "#ff4848",
    padding: "8px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    color: "white",
    marginTop: "1rem",
  },
  accountflagged: {
    background: `url(${ACCOUNTFLAG_BG})`,

    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accountflaggedbox: {
    width: "375px",
    textAlign: "center"
  },
  btnsupport: {
    width: "200px",
    margin: "25px auto",
  },
  spacetop: {
    margin: "5px 0 15px"
  },
  iconflag: {
    background: BASE.WHITE,
    width: "130px",
    height: "130px",
    borderRadius: "24px",
    margin: "0 auto 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
});
