import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { max } from "d3";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Styles } from "../../Styles";
import { STATS_KEY_VALUES } from "../../../../utils/constants";

export const D3VMultipleBarChartNoDate = (props) => {
  const { index, dataSet, height, width } = props;
  const classes = Styles();
  const widgetCoordinatesSelector = useSelector(
    (state) => state.getWidgetCoordinates
  );

  const d3Chart = useRef(null);

  function vBarChart(width, height) {
    try {
      let margin = { top: 30, right: 45, bottom: 40, left: 40 };
      const w = width - margin.left - margin.right;
      const h = height - margin.top - margin.bottom;
      const svg = d3
        .select(d3Chart.current)
        .attr("width", w + margin.left + margin.right)
        .attr("height", h + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .style("backgroud", "#d3d3d3")
        .style("margin-top", "50")
        .style("overflow", "visible"); // for axis(outside) visible

      let maxIndex = 0;
      let maxLen = 0;
      dataSet.forEach((data, i) => {
        if (data.arr.length > maxLen) {
          maxLen = data.arr.length;
          maxIndex = i;
        }
      });

      let xTick = [];
      dataSet[maxIndex].arr.forEach((temp) => {
        xTick.push(temp.x);
      });

      let first = false;

      const mainArr = [];

      dataSet.forEach((d, i) => {
        let id = d.id;
        let color = d.color;
        d.arr.forEach((e, j) => {
          let obj = { id, color, ...e };
          mainArr.push(obj);
        });
      });
      let grp = d3.groups(mainArr, (d) => d.x);

      let valueArr = [];

      dataSet.forEach((data, i) => {
        if (i === 0) {
          first = true;
        } else {
          first = false;
        }

        if (data.id === "inComplete") {
          data.arr.forEach((d, i) => {
            valueArr.push(d.z);
          });
        }

        let xvalueArr = [];
        data.arr.forEach((d, i) => {
          xvalueArr.push(d.x);
        });

        let upperlimit =
          max(valueArr, (d) => d) + 10 - (max(valueArr, (d) => d) % 10);

        const xScale = d3
          .scaleBand()
          .domain(xvalueArr)
          .range([0, w])
          .padding(0.5);
        const yScale = d3.scaleLinear().domain([0, upperlimit]).range([h, 0]);

        const xAxis = d3
          .axisBottom(xScale)
          .ticks(xTick.length)
          .tickFormat((d, i) => STATS_KEY_VALUES[xTick[i]])
          .tickSize(0)
          .tickPadding([10]);

        const yAxis = d3.axisLeft(yScale).ticks(5).tickSize(0).tickPadding([6]);
        const yAxisGrid = d3
          .axisLeft(yScale)
          .tickSize(-w)
          .tickFormat("")
          .ticks(5);

        if (first) {
          svg
            .append("g")
            .attr("class", "y axis-grid")
            .attr("stroke", "#D3DAE3")
            .attr("stroke-width", "1")
            .attr("opacity", "0.2")
            .call(yAxisGrid);

          svg.append("g").call(yAxis);

          svg.append("g").call(xAxis).attr("transform", `translate(0,${h})`);

          svg.selectAll(".domain").attr("stroke", "#D3DAE3");
        }

        let tooltip = d3
          .select(props.activeTooltip)
          .append("div")
          .style("position", "absolute")
          .style("z-index", "10")
          .style("width", "142px")
          .style("height", "auto")
          .style("border-radius", "6px")
          .style("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.08)")
          .style("border", "1px solid #D3DAE3")
          .style("background-color", "white")
          .style("visibility", "hidden");

        svg
          .selectAll(".bar")
          .data(data.arr)
          .join("rect")
          .attr("id", data.id)
          .attr("x", (d, i) => xScale(d.x))
          .attr("y", (d, i) =>
            data.id === "complete" ? yScale(d.y) : yScale(d.z)
          )
          .attr("width", xScale.bandwidth())
          .attr("height", (d, i) =>
            data.id === "complete" ? h - yScale(d.y) : h - yScale(d.z)
          )
          .attr("fill", data.color)
          .on("mouseover", function () {
            return tooltip.style("visibility", "visible");
          })
          .on("mousemove", function (event, d) {
            let index;
            grp.forEach((g, i) => {
              if (g[0] === d.x) {
                index = i;
              }
            });
            tooltip.html(
              `<div class=${classes.widgetToolTip} >` +
                `<div class=${classes.widgetToolTipTopVBar1} >` +
                props?.ownerName +
                "</div>" +
                `<div class=${classes.widgetToolTipTopVBar2} >` +
                STATS_KEY_VALUES[grp[index][0]] +
                "</div>" +
                `<div class=${classes.widgetToolTipBottomVBar} >` +
                toolTipMaker(grp[index][1], data.id) +
                "</div>" +
                "</div>"
            );
            let x, y;
            let maxHeight = (document.documentElement.clientHeight / 100) * 90;
            let maxWidth = (document.documentElement.clientWidth / 100) * 90;
            if (event.pageY > maxHeight) {
              y = 80;
            } else {
              y = 10;
            }

            if (event.pageX > maxWidth) {
              x = 150;
            } else {
              x = -10;
            }

            tooltip
              .style("top", event.pageY - y + "px")
              .style("left", event.pageX - x + "px");
          })
          .on("mouseout", function () {
            return tooltip.style("visibility", "hidden");
          });
      });
      //for multiple bar chart define different x and y scales and append the bar
    } catch (error) {}
  }

  const toolTipMaker = (data, tempId) => {
    let str = "";
    data.forEach((d, i) => {
      str += "<div>";
      str += `<div class=${classes.widgetToolTipContent}>`;
      if (d.id === tempId) {
        str += `<div class=${classes.widgetToolTipContent4}>`;
      } else {
        str += `<div class=${classes.widgetToolTipContent2}>`;
      }
      str += `<div class=${classes.widgetToolTipCircle} style="background-color:${d.color}"></div>`;
      str += `${STATS_KEY_VALUES[d.id]}`;
      str += `</div>`;
      str += `<div class=${classes.widgetToolTipContent3}>`;
      str += `${d.y}`;
      str += `</div>`;
      str += `</div >`;
      str += `</div >`;
    });
    return str;
  };

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;

    const resizeHeight = widgetCoordinatesSelector?.payload?.h;
    const resizeWidth = widgetCoordinatesSelector?.payload?.w;
    const resizeIndex = widgetCoordinatesSelector?.payload?.i;

    if (
      (resizeWidth < documentWidth || resizeHeight < documentHeight) &&
      resizeIndex === index?.toString()
    ) {
      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        d3.select(d3Chart.current).selectAll("*").remove();
        vBarChart(resizeWidth, resizeHeight * 0.7);
      }, 50);
    }
  }, [widgetCoordinatesSelector]);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;
    if (width < documentWidth && height < documentHeight) {
      d3.select(d3Chart.current).selectAll("*").remove();
      vBarChart(width, height);
    }
  }, [dataSet]);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;
    if (width < documentWidth && height < documentHeight) {
      d3.select(d3Chart.current).selectAll("*").remove();
      vBarChart(width, height);
    }
    return () => {
      d3.select(props.activeTooltip).selectAll("*").remove();
    };
  }, []);

  return <Box>{dataSet.length > 0 ? <svg ref={d3Chart}></svg> : null}</Box>;
};
