import React, { useEffect, useState } from "react";
import TypographyInline from "../Typography/TypographyInline";
import { useNavigate } from "react-router-dom";
import classes from "./Tabs.module.css";
import { fontWeight } from "../../utils/constants/UI";
import { GREY, PRIMARY } from "../../utils/constants/colors";
const Tabs = ({ items, selected, selectedTabFunc }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    setSelectedTab(items[0]?.path);
  }, []);
  useEffect(() => {
    if (selected) {
      setSelectedTab(selected);
    }
  }, [selected]);

  return (
    <div className={classes.tabsContainer}>
      {items.map((item, index) => {
        return (
          <div
            className={
              selectedTab === item?.path
                ? classes.tabSelected
                : classes.tabPrimary
            }
            key={`tab${index}`}
            onClick={() => {
              navigate(item?.path);
              setSelectedTab(item?.path);
              selectedTabFunc();
            }}
          >
            <TypographyInline
              color={
                selectedTab === item?.path ? PRIMARY.W_500 : GREY.SECONDARY
              }
              fontWeight={
                selectedTab === item?.path
                  ? fontWeight.SEMIBOLD
                  : fontWeight.REGULAR
              }
              label={item?.label}
              size={"sm"}
            />
          </div>
        );
      })}
    </div>
  );
};
export default Tabs;
// ------------- NOTE ----------------
// Props Description:
// items :- It should be array of object and every object should have three keys - id, label and path.
// selected :- It should contain path otherwise component will set selcted tab to First Tab.
