import React from "react";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import { SHADOW } from "../../../utils/constants/UI";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";
import { FIELD_TYPE_LABELS } from "../../../utils/helpers";
import classes from "./PropComponent.module.css";
import { useSelector } from "react-redux";

const FieldTypeFilterMenu = ({
  open,
  anchorEl,
  setFieldTypeMenuAnchor,
  selectedFieldType,
  setSelectedFieldType,
  fieldTypeFilterHandler,
  setSelectedId,
}) => {
  const allPropertiesFiltersSelector = useSelector(
    (state) => state.allPropertiesFilters
  );

  const onChangeHandler = (e) => {
    if (
      allPropertiesFiltersSelector?.data?.query?.field_type?.includes(
        e?.target?.value
      )
    ) {
      let tempGrpName = [
        ...allPropertiesFiltersSelector?.data?.query?.field_type,
      ];
      let indexOfValue = tempGrpName.indexOf(e?.target?.value);
      tempGrpName?.splice(indexOfValue, 1);
      setSelectedFieldType(tempGrpName);
      fieldTypeFilterHandler(tempGrpName);
    } else {
      setSelectedFieldType((oldArray) => [...oldArray, e?.target?.value]);
      fieldTypeFilterHandler([...selectedFieldType, e?.target?.value]);
    }
  };

  return (
    <CustomStyledMenu
      open={open}
      onClose={() => {
        setFieldTypeMenuAnchor(null);
        setSelectedId("");
      }}
      anchorEl={anchorEl}
      arrow={true}
      width={"14.948vw"}
      shadow={SHADOW.xs}
      noMargin={true}
      isRightAlign={true}
      marginTop={"11px !important"}
    >
      <div className={classes.filterMenuContainer}>
        {FIELD_TYPE_LABELS?.map((item, index) => {
          return (
            <div key={index} className={classes.fieldTypeList}>
              <Checkbox
                value={item?.label}
                checked={allPropertiesFiltersSelector?.data?.query?.field_type?.includes(
                  item?.label
                )}
                onChange={onChangeHandler}
                label={item?.label}
              />
            </div>
          );
        })}
      </div>
    </CustomStyledMenu>
  );
};

export default FieldTypeFilterMenu;
