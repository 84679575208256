import React from "react";
import {  BORDER, GREY } from "../../utils/constants/colors";
import { fontSize } from "../../utils/constants/UI";
import { borderRadius } from "../../utils/constants/UI/uiConstants";
import IconComponent from "../Icon/IconComponent";
import classes from "./Button.module.css"


const PaginationIconButton = ({ iconLabel, ...props }) => {
  const styles = {
    iconContainer: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "6px",
      boxShadow: `inset -1px 0 0 ${BORDER.DEFAULT}, inset 0 -1px 0 ${BORDER.DEFAULT}, inset 1px 0 0 ${BORDER.DEFAULT}, inset 0 1px 0 ${BORDER.DEFAULT}`,
      borderRadius: borderRadius.primary,
      cursor: (!props?.disabled ? "pointer" : "not-allowed"),
      backgroundColor: "transparent",
      border: "none",
    },
  }
  return (
    <button id="iconContainer" style={styles.iconContainer} className={props?.disabled ? classes.iconContainerDisabled : classes.iconContainer} {...props}>
      <IconComponent
        id={classes.iconComponent}
        iconLabel={iconLabel}
        fontSize={fontSize.XL}
        color={GREY.SECONDARY}
      />
    </button>
  );
};

export default PaginationIconButton;
