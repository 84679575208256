import * as qs from "qs";
import {
  COMPANY_EMPLOYEES_COUNT,
  EMAIL_VERIFICATION_TYPES,
  SENIORIRT_TYPES,
  tempHeadCells,
  tempHeadCompanyCells,
  tempHeadDealsCells,
} from "../../utils/constants";
import { filterKeys } from "../../utils/constants/filters";
import {
  filterHeaderIndustry,
  filterHeaderJobTitle,
  initialFilterPayload,
  initialQueryPayload,
  stringQueryList,
} from "./storeConstants";
import {
  checkDynamicExistFields,
  checkExistFields,
  checkExistFieldsCompanyName,
  getCheckEmployeeCount,
  getSelectedSeniority,
} from "./storeHelper";
import { ACCOUNT_PATH, COMPANY_SCREEN_PATH, CRM_PATH, DEALS_PATH, PEOPLE_SCREEN_PATH } from "../../utils/constants/routes";

let current_location = window?.location?.pathname;

let columnUpdate = {
  columns: JSON.parse(JSON.stringify(tempHeadCells)),
};
if (current_location?.includes(COMPANY_SCREEN_PATH)) {
  columnUpdate = {
    columns: JSON.parse(JSON.stringify(tempHeadCompanyCells)),
  }
}

let dynamicColumnUpdate = {};
if (current_location?.includes(CRM_PATH)) {
  dynamicColumnUpdate = {
    columns: JSON.parse(JSON.stringify([...tempHeadCells])),
  }
} else if (current_location?.includes(ACCOUNT_PATH)) {
  dynamicColumnUpdate = {
    columns: JSON.parse(JSON.stringify([...tempHeadCompanyCells])),
  }
} else if (current_location?.includes(DEALS_PATH)) {
  dynamicColumnUpdate = {
    columns: JSON.parse(JSON.stringify([...tempHeadDealsCells])),
  }
}

const res = qs.parse(window.location?.search?.slice(1));
if (res) {
  for (let i in res) {
    if (typeof res[i] === "string" && stringQueryList?.indexOf(i) === -1) {
      res[i] = [res[i]];
    }
  }
}

const allFilters = {
  payload: { ...initialQueryPayload, ...res },
};

// initial values of checkboxes

const initalMultipleFilterList = checkDynamicExistFields(res);

const initalJobTitleSelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.job_title?.length ||
    res?.job_title_not?.length
    ? checkExistFields(res, filterKeys.job_title)
    : JSON.parse(JSON.stringify(filterHeaderJobTitle));

const initalIndustrySelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.industry?.length ||
    res?.industry_not?.length
    ? checkExistFields(res, filterKeys.industry)
    : JSON.parse(JSON.stringify(filterHeaderIndustry));

const initalCompanyKeywordsSelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.company_keywords?.length ||
    res?.company_keywords_not?.length ||
    res?.company_keywords_all?.length
    ? checkExistFields(res, "company_keywords")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalCompanyTechnologySelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.company_technologies?.length ||
    res?.company_technologies_not?.length ||
    res?.company_technologies_all?.length
    ? checkExistFields(res, "company_technologies")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalCompanyCitySelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.company_city?.length ||
    res?.company_city_not?.length
    ? checkExistFields(res, "company_city")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalCompanyStateSelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.company_state?.length ||
    res?.company_state_not?.length
    ? checkExistFields(res, "company_state")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalCompanyCountrySelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.company_country?.length ||
    res?.company_country_not?.length
    ? checkExistFields(res, "company_country")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalPersonCitySelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.person_city?.length ||
    res?.person_city_not?.length
    ? checkExistFields(res, "person_city")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalPersonStateSelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.person_state?.length ||
    res?.person_state_not?.length
    ? checkExistFields(res, "person_state")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalPersonCountrySelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.person_country?.length ||
    res?.person_country_not?.length
    ? checkExistFields(res, "person_country")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalCompanyNameSelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.company_ids?.length ||
    res?.company_ids_not?.length
    ? checkExistFieldsCompanyName(res, "company_name")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalCompanySeoSelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.seo_description?.length ||
    res?.seo_description_not?.length ||
    res?.seo_description_all?.length
    ? checkExistFields(res, "seo_description")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalContactListSelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.contact_lists?.length ||
    res?.contact_lists_not?.length
    ? checkExistFields(res, "contact_lists")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initalAccountListSelect =
  res?.exist_fields?.length ||
    res?.not_exist_fields?.length ||
    res?.account_lists?.length ||
    res?.account_lists_not?.length
    ? checkExistFields(res, "account_lists")
    : JSON.parse(JSON.stringify(initialFilterPayload));

const initialCompanySelect = res.employee_number
  ? getCheckEmployeeCount(
    JSON.parse(JSON.stringify(COMPANY_EMPLOYEES_COUNT)),
    res.employee_number
  )
  : JSON.parse(JSON.stringify(COMPANY_EMPLOYEES_COUNT));

const initialSenioritySelect = res?.seniority
  ? getSelectedSeniority(SENIORIRT_TYPES, res?.seniority)
  : SENIORIRT_TYPES;

const initialEmailVerificationSelect = res?.email_verification
  ? getCheckEmployeeCount(EMAIL_VERIFICATION_TYPES, res?.email_verification)
  : EMAIL_VERIFICATION_TYPES;

const filterHeaderCompany = {
  payload: initialCompanySelect,
};

const filterHeaderSeniority = {
  payload: initialSenioritySelect,
};

const filterHeaderEmailVerification = {
  payload: initialEmailVerificationSelect,
};

export const initialState = {
  columnUpdate,
  dynamicColumnUpdate,
  filterHeaderJobTitle: initalJobTitleSelect,
  filterHeaderIndustry: initalIndustrySelect,
  filterHeaderCompany,
  filterHeaderSeniority,
  filterHeaderEmailVerification,
  filterHeaderCompanyKeywords: initalCompanyKeywordsSelect?.payload,
  filterHeaderCompanyTechnologies: initalCompanyTechnologySelect?.payload,
  filterHeaderCompanySeo: initalCompanySeoSelect?.payload,
  filterHeaderPersonCity: initalPersonCitySelect,
  filterHeaderPersonState: initalPersonStateSelect,
  filterHeaderPersonCountry: initalPersonCountrySelect,
  filterHeaderCompanyCity: initalCompanyCitySelect,
  filterHeaderCompanyState: initalCompanyStateSelect,
  filterHeaderCompanyCountry: initalCompanyCountrySelect,
  filterHeaderCompanyName: initalCompanyNameSelect,
  filterHeaderContactList: initalContactListSelect,
  filterHeaderAccountList: initalAccountListSelect,
  filterMultipleList: initalMultipleFilterList,
  allFilters,
  // allDynamicFilters,
};
