import { Autocomplete, Box,TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  DOWNCARROT_ICON,
  USER_ICON,
} from "../../../utils/constants/assets";
import { styles } from "./styles";
import { useDispatch } from "react-redux";
import { changeMode } from "../../../redux/actions/modeActions";
import {
  CircularLoader,
  CustomDropDown,
  FullScreenLoader,
} from "../../../components";
import {
  COUNTRY_LIST,
  EMITTER_SUCCESS,
  TIMEZONE_LIST,
} from "../../../utils/constants";
import { getRequest, postRequest } from "../../../Apis";
import { useNavigate } from "react-router-dom";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { nameValidation } from "../../../utils/validations";
import {
  numberValidation,
  urlValidation,
} from "../../../utils/validations/inputValidations";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import Image from "../../../components/Image/Image";
import { STRINGS } from "./strings";

// image crop
// src is equal to imageUrl;

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = styles();
  const [imageUrl, setImageUrl] = useState(USER_ICON);
  const [name, setName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [timezone, setTimezone] = useState("");
  const [number, setNumber] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [fullScreenLoading, setFullScreenLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [validations, setValidations] = useState({
    name: "",
    jobTitle: "",
    linkedinUrl: "",
    timezone: "",
    number: "",
    country: "",
    state: "",
    city: "",
  });

  //Image crop
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);

  //Handle Image upload

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
  };

  const handleImageUpload = (e) => {
    if (e?.target?.files) {
      setUserImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSelectTheme = (theme) => {
    dispatch(changeMode(theme));
  };

  const handleUserDetails = async () => {
    const validatedName = nameValidation(name);
    const validatedJobTitle = nameValidation(jobTitle);
    const validatedUrl = urlValidation(linkedinUrl);
    const validatedTimezone = nameValidation(timezone);
    const validatedNumber = numberValidation(number);
    if (
      validatedName.isValid &&
      validatedJobTitle.isValid &&
      validatedUrl.isValid &&
      validatedTimezone.isValid &&
      validatedNumber.isValid
    ) {
      const payload = {
        name,
        timezone,
        job_title: jobTitle,
        phone_number: number,
        contact_linkedin_url: linkedinUrl,
        city,
        state,
        country,
      };

      try {
        setLoading(true);
        const response = await postRequest(
          navigate,
          authEndpoints.updateUserDetails,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setLoading(false);
      } catch (error) {
        const errors = error?.data?.data?.errors;
        setLoading(false);
        setValidations({
          name: errors?.name || "",
          jobTitle: errors?.job_title || "",
          linkedinUrl: errors?.contact_linkedin_url || "",
          timezone: errors.timezone || "",
          number: errors?.phone_number || "",
          country: errors?.country || "",
          state: errors?.state || "",
          city: errors?.city || "",
        });
      }
    } else {
      setValidations({
        name: validatedName.message,
        jobTitle: validatedJobTitle.message,
        linkedinUrl: validatedUrl.message,
        timezone: validatedTimezone.message,
        number: validatedNumber.message,
      });
    }
  };

  

  useEffect(() => {
    (async () => {
      try {
        const response = await getRequest(
          navigate,
          authEndpoints.getUserDetails
        );
        setName(response?.data?.data?.name);
        setJobTitle(response?.data?.data?.job_title);
        setLinkedinUrl(response?.data?.data?.contact_linkedin_url);
        setTimezone(response?.data?.data?.timezone);
        setNumber(response?.data?.data?.phone_number);
      } catch (error) {
      } finally {
        setFullScreenLoading(false);
      }
    })();
  }, [navigate]);

  return fullScreenLoading ? (
    <FullScreenLoader />
  ) : (
    <Box className={classes.container}>
      <Box className={classes.scrollingContainer}>
        <Box className={classes.componentContainer}>
          <Box className={classes.headerWrap}>
            <h3 className={classes.title}>{STRINGS.USER_PROFILE}</h3>
            <p
              className={classes.callingPara}
              style={{ padding: "6px 0 0", border: "0px", fontWeight: "400" }}
            >
              {STRINGS.THIS_APPLIES}
            </p>
          </Box>
          {imageUrl && <div></div>}
       
          <Box className={classes.containNameInputs}>
            <Box className={classes.containInput}>
              <h5 className={classes.inputTitle}>{STRINGS.FULL_NAME}</h5>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={classes.input}
                placeholder="Rodriguez"
              />
              <ErrorMessage message={validations.name} />
            </Box>

            <Box className={classes.containInput} data-tour="jobtitle">
              <h5 className={classes.inputTitle}>Job Title</h5>
              <input
                className={classes.input}
                value={jobTitle}
                placeholder="Enter Job Title"
                type="text"
                onChange={(e) => setJobTitle(e.target.value)}
              />
              <ErrorMessage message={validations.jobTitle} />
            </Box>
            <Box className={classes.containInput} data-tour="linkedIn">
              <h5 className={classes.inputTitle}>Contact LinkedIn URL</h5>

              <input
                className={classes.input}
                value={linkedinUrl}
                placeholder="Enter LinkedIn Profile URL"
                type="text"
                onChange={(e) => setLinkedinUrl(e.target.value)}
              />
              <ErrorMessage message={validations.linkedinUrl} />
            </Box>
            <Box className={classes.containInput} data-tour="timezone">
              <Box className={classes.flexSpaceBetween}>
                <h5 className={classes.inputTitle} type="text">
                  Timezone
                </h5>
               
              </Box>
              <Box className={classes.containDropdown}>
                <CustomDropDown
                  value={timezone}
                  onChange={(e) =>{
                    setTimezone(e.target.value)
                    }}
                  items={TIMEZONE_LIST}
                  borderRadius="4px"
                  fontSize="clamp(12px, 0.729vw, 16px)"
                  background={`transparent url('${DOWNCARROT_ICON}') 97% center no-repeat `}
                />
              </Box>
              <ErrorMessage message={validations.timezone} />
            </Box>
            <Box className={classes.containInput} data-tour="phonenumber">
              <h5 className={classes.inputTitle}>Phone Number</h5>

              <input
                type="text"
                className={classes.input}
                placeholder="Enter Phone Number"
                value={number}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) {
                    setNumber(e.target.value);
                  }
                }}
              />

              <ErrorMessage message={validations.number} />

          
            </Box>
            <Box className={classes.containInput} data-tour="country">
              <h5 className={classes.inputTitle}>Country</h5>
              <Box className={classes.containLanguageDropdown}>
                <Autocomplete
                  popupIcon={<Image src={DOWNCARROT_ICON} />}
                  options={COUNTRY_LIST.map((option) => option.title)}
                  onChange={(e) => setCountry(e?.target?.innerText)}
                  renderInput={(params) => (
                    <TextField
                      className={classes.textfield}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      {...params}
                      label="Country"
                    />
                  )}
                />
              </Box>
              <ErrorMessage message={validations.country} />
            </Box>
            <Box className={classes.containInput} data-tour="state">
              <h5 className={classes.inputTitle}>State</h5>

              <input
                className={classes.input}
                value={state}
                placeholder="Enter State"
                type="text"
                onChange={(e) => setState(e.target.value)}
              />
              <ErrorMessage message={validations.state} />
            </Box>
            <Box className={classes.containInput} data-tour="city">
              <h5 className={classes.inputTitle}>City</h5>

              <input
                className={classes.input}
                value={city}
                placeholder="Enter City"
                type="text"
                onChange={(e) => setCity(e.target.value)}
              />
              <ErrorMessage message={validations.city} />
            </Box>
          </Box>

          <Box sx={{ margin: "12px 0" }}>
            {loading ? (
              <CircularLoader />
            ) : (
              <button
                className={classes.btn}
                onClick={handleUserDetails}
                data-tour="savedetails"
              >
                Save Details
              </button>
            )}
          </Box>

         
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
