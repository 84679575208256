import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { allFilters } from "../../redux/actions/filterActions";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../InputFields/InputField";
import { STRINGS } from "./strings";

const FilterTypeName = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const allFiltersSelector = useSelector((state) => state.allFilters);
  const [tempName, setTempName] = useState(null);
  const [name, setName] = useState(allFiltersSelector?.payload?.name || "");

  if (tempName !== allFiltersSelector?.payload?.name) {
    setTempName(allFiltersSelector?.payload?.name);
    setName(tempName);
  }

  useEffect(() => {
    if (tempName === allFiltersSelector?.payload?.name) {
      setName(tempName);
      setTempName(allFiltersSelector?.payload?.name);
    }
  }, [allFiltersSelector]);

  const handleNameSubmit = (e) => {
    e?.preventDefault();
    dispatch(allFilters({ name: name || "" }));
    let payload = {
      ...allFiltersSelector?.payload,
      name: name,
    }
    if (!name?.length) delete payload?.name;
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ ...payload }).toString(),
    });
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={styles.inputContainer}
        component="form"
        onSubmit={handleNameSubmit}
      >
        <InputField
          size={"sm36"}
          value={name}
          placeholder={STRINGS.SEARCH_NAME}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default FilterTypeName;
