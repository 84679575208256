import React, { useEffect, useState } from "react";
import { ACTION_CRM } from "../../../utils/constants/assets";
import { STRINGS } from "../strings";
import classes from "./Crm.module.css";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  imageType,
} from "../../../utils/constants";
import { fontWeight } from "../../../utils/constants/UI";
import { BRAND } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";
import ProgressEmitter from "../../../components/Loaders/ProgressEmitter";
import { Uploader } from "../../../utils/helpers/Uploader";
import { useNavigate } from "react-router-dom";
import { crmEndpoints } from "../../../utils/constants/httpConstants";
import { getRequest } from "../../../Apis";
import { Button } from "../../../components/Buttons";
import { Modal } from "@mui/material";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { INVALID_FORMAT_IMAGE } from "../../../utils/constants/messages";
import ContactDetailEmptyScreen from "./components/ContactDetailEmptyScreen";
import SkeletonLoaderCRMScreens from "./components/SkeletonLoaderCRMScreens";
import ListingAttachmentDOcCard from "./components/ListingAttachmentDocCard";
import ListingAttachmentImageCard from "./components/ListingAttachmentImageCard";

const ContactAttachmentScreen = ({ contactDetails, contactId }) => {
  const navigate = useNavigate();
  const [attachmentList, setAttachmentList] = useState([]);
  const [attachmentDocList, setAttachmentDocList] = useState([]);
  const [screenLoading, setScreenLoading] = useState(true);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [file, setFile] = useState("");
  const setHighlighted = useState(false);

  const emptyScreenButtons = [
    {
      id: STRINGS.UPLOAD,
      label: STRINGS.UPLOAD,
      variant: "primary",
      disabled: !Object.keys(contactDetails).length,
      onClick: () => {
        document?.getElementById("file-uploaded")?.click();
      },
    },
  ];

  const handleFileUpload = async (e) => {
    const ImageTypeCheck = e?.target?.files?.[0]?.type?.split("/");
    const extension = e.target.files[0]?.type.split("/")[1];
    let ImageType = "";
    if (ImageTypeCheck[0] === STRINGS.IMAGE_SMALL && !imageType[extension]) {
      return EventEmitter.emit(EMITTER_ERROR, INVALID_FORMAT_IMAGE);
    } else {
      if (ImageTypeCheck[0] === STRINGS.IMAGE_SMALL) {
        ImageType = STRINGS.IMAGE_SMALL;
      } else {
        ImageType = STRINGS.DOC_SMALL;
      }
      const uploadedFile = e?.dataTransfer?.files?.[0] || e?.target?.files?.[0];
      setFile(uploadedFile);
      if (uploadedFile) {
        initiateUploadingProcess(uploadedFile, ImageType);
        e.target.value = "";
      }
    }
  };

  const initiateUploadingProcess = (file, ImageType) => {
    let percentage = undefined;
    const videoUploaderOptions = {
      fileName: file?.name || STRINGS.FOO,
      size: file.size || "0",
      file: file,
      contact_id: contactId,
      typeImage: ImageType,
      initialEndpoint: crmEndpoints.uploadCrmFileInitialEndpoint,
      multipartEndpoint: crmEndpoints.uploadCrmFileMultipartEndpoint,
      finalEndpoint: crmEndpoints.uploadCrmFilefinalEndpoint,
      navigate: navigate,
    };
    if (contactDetails?.company_id) {
      videoUploaderOptions.company_id = contactDetails?.company_id;
    }
    const uploader = new Uploader(videoUploaderOptions);

    uploader.start();

    uploader.onProgress(({ percentage: newPercentage }) => {
      if (newPercentage !== percentage) {
        percentage = newPercentage;
        percentage >= uploadPercentage && setUploadPercentage(percentage);
      }
    });

    uploader.onFinish(() => {
      setFile(null);
      EventEmitter.emit(EMITTER_SUCCESS, STRINGS.UPLOADED);

      (async () => {
        setScreenLoading(true);
        let url = `${crmEndpoints.getAttachments}/${contactId}?file_extension=${STRINGS.IMAGE_SMALL}`;
        const response = await getRequest(navigate, url);
        setScreenLoading(false);
        setAttachmentList(response?.data?.data?.records);
        setUploadPercentage(0);
      })();
      (async () => {
        setScreenLoading(true);
        let url = `${crmEndpoints.getAttachments}/${contactId}?file_extension=${STRINGS.DOC_SMALL}`;
        const response = await getRequest(navigate, url);
        setScreenLoading(false);
        setAttachmentDocList(response?.data?.data?.records);
      })();
    });

    uploader.onError((error) => {
      setFile("");
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log({ error });
    });
  };

  useEffect(() => {
    (async () => {
      setScreenLoading(true);
      let url = `${crmEndpoints.getAttachments}/${contactId}?file_extension=${STRINGS.IMAGE_SMALL}`;
      const response = await getRequest(navigate, url);
      setScreenLoading(false);
      setUploadPercentage(0);
      setAttachmentList(response?.data?.data?.records);
      setFile("");
    })();
    (async () => {
      setScreenLoading(true);
      let url = `${crmEndpoints.getAttachments}/${contactId}?file_extension=${STRINGS.DOC_SMALL}`;
      const response = await getRequest(navigate, url);
      setScreenLoading(false);
      setUploadPercentage(0);
      setFile("");

      setAttachmentDocList(response?.data?.data?.records);
    })();
  }, []);

  return (
    <div className={classes.taskScreenMainContainer} id="comments_body">
      {file && (
        <Modal open={file}>
          <ProgressEmitter
            name={file?.name}
            uploadPercentage={uploadPercentage}
            refreshRequired={true}
          />
        </Modal>
      )}
      {screenLoading ? (
        <SkeletonLoaderCRMScreens
          loaderHeight="42px"
          accordionLengths={2}
          buttonsLength={1}
        />
      ) : (
        <>
          {attachmentList?.length || attachmentDocList?.length ? (
            <div className={classes.taskListContainer}>
              <div className={classes.HeadTaskContainerSubHead}>
                <TypographyInline
                  label={STRINGS.ALL_ATTACHMENTS}
                  size={"md"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={BRAND.SECONDARY}
                />
                <div className={classes.WIdthButton}>
                  <Button
                    label={STRINGS.UPLOAD}
                    variant={"primary"}
                    size={"sm36"}
                    onClick={() => {
                      document?.getElementById("file-uploaded")?.click();
                    }}
                  />
                </div>
              </div>
              {/* Sending Attachment list to the ListingAttachmentImageCard*/}
              {attachmentList?.length ? (
                <div key={`attachmentlog`}>
                  <ListingAttachmentImageCard
                    attachmentList={attachmentList}
                    contact_id={contactId}
                    setAttachmentList={setAttachmentList}
                    contactDetails={contactDetails}
                    setScreenLoading={setScreenLoading}
                    screenLoading={screenLoading}
                  />
                </div>
              ) : null}
              {/* Sending Attachment list to the ListingAttachmentDocCard*/}
              {attachmentDocList?.length ? (
                <div key={`attachmentlog`}>
                  <ListingAttachmentDOcCard
                    id={`attachmentloglog`}
                    attachmentDocList={attachmentDocList}
                    contact_id={contactId}
                    setAttachmentDocList={setAttachmentDocList}
                    contactDetails={contactDetails}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <>
              <div className={classes.TaskHead}>
                <ContactDetailEmptyScreen
                  src={ACTION_CRM}
                  label={STRINGS.ATTACHMENTS_LABEL}
                  buttonItems={emptyScreenButtons}
                />
              </div>
            </>
          )}
        </>
      )}

      <div className="contain-drag-drop">
        <div
          onDragEnter={() => {
            setHighlighted(true);
          }}
          onDragLeave={() => {
            setHighlighted(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={handleFileUpload}
        >
          <div className="for-center">
            <div className="for-center">
              <input
                id="file-uploaded"
                type="file"
                className={classes.inputFile}
                onChange={handleFileUpload}
                accept=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAttachmentScreen;
