import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS, tempHeadCells } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import {
  decompressString,
  filterDynamicQuery,
  getErrorMessage,
} from "../../../../utils/helpers";
import { getRequest, postRequest } from "../../../../Apis";
import { dealsEndpoints } from "../../../../utils/constants/httpConstants";
import { STRINGS } from "../../strings";
import { Button } from "../../../../components/Buttons";
import CustomModal from "../../../../components/Modals/CustomModal";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import InputField from "../../../../components/InputFields/InputField";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import Checkbox from "../../../../components/Buttons/Checkbox/Checkbox";
import classes from "./DealsComp.module.css";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { getPeopleDealDetails } from "../../../../redux/actions/dealsActions";

const AddViewModal = ({
  open,
  setOpen,
  isRename,
  renamePopupValue,
  setPublicViews = () => { },
  setPrivateViews = () => { },
  setTeamViews = () => { },
  setViewCardMenu = () => { },
  setPrivateViewCardMenu = () => { },
  getViews = () => { },
  viewType = "",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewName, setViewName] = useState(
    renamePopupValue?.eachView?.view_name || ""
  );
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const allFilterDynamicFilterSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );
  const peopleDealsDetailsSelector = useSelector((state) => state.peopleDealsDetails);

  const tableColumnsSelector = useSelector(
    (state) => state?.dynamicUpdatedColumn?.columns
  );
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );

  let propertiesPayload = localStorage.getItem("properties")
    ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))
      ?.properties_key_value
    : getPropertiesListSelector?.properties_key_value;

  const radioButtonData = [
    { id: STRINGS.PRIVATE_ID, label: STRINGS.PRIVATE_LABEL },
    { id: STRINGS.TEAM_ID, label: STRINGS.TEAM_LABEL },
  ];
  const tableColumns = tableColumnsSelector
    ?.filter((item) => item.isChecked)
    .map((item) => item.id);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(!open);
    setErrMsg("");
    setViewName("");
  };

  const handleCreate = async () => {
    const filtersQuery = filterDynamicQuery(
      allFilterDynamicFilterSelector,
      propertiesPayload
    );
    const update_view_id = renamePopupValue?.eachView?.view_id || "";

    if (viewName?.length === 0) {
      setErrMsg(STRINGS.REQUIRED);
      return;
    }
    if (viewName?.length >= 50) {
      setErrMsg(STRINGS.VIEW_CANT_25CHAR);
      return;
    }

    setErrMsg("");
    const payload = {
      view_name: viewName,
      view_type: "private" || selectedValue,
      query_string: filtersQuery,
      is_pin: isPin ? 1 : 0,
      table_columns: tableColumns || [
        ...tempHeadCells
          .map((item) => item.id)
          .filter((item) => item.isChecked),
      ],
    };
    setLoading(true);
    try {
      let URL = "";
      if (update_view_id?.length) {
        URL = dealsEndpoints.updateDealViewproperty;
      } else {
        URL = dealsEndpoints.createDealView;
      }

      if (update_view_id.length) {
        payload.view_id = update_view_id;
        delete payload?.query_string;
        delete payload?.table_columns;
      }

      const response = await postRequest(navigate, URL, payload);
      if (isRename) {
        const URL = dealsEndpoints.getAllDealsView;
        const response = await getRequest(navigate, URL);
        setPublicViews(response?.data?.data?.public);
        setPrivateViews(response?.data?.data?.private);
        setTeamViews(response?.data?.data?.team);
        setViewCardMenu(null);
        setPrivateViewCardMenu(null);
      }
      setViewName("");

      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

      dispatch(getPeopleDealDetails(navigate));

      getViews();
      setOpen(false);
      setLoading(false);
    } catch (error) {
      console.log("Error ", error);
      setLoading(false);
      setErrMsg(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: isRename ? STRINGS.EDIT_VIEW : STRINGS.CREATE_NEW_VIEW,
    },
  };
  const modalBodyData = (
    <div className={classes.bodyEditViewContainerView}>
      <Box className={classes.containInput}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.VIEW_NAME}
        />

        <InputField
          placeholder={STRINGS.WRITE_VIEW_NAME}
          value={viewName}
          size={"sm36"}
          onChange={(e) => setViewName(e.target.value)}
          variant={errMsg?.length ? STRINGS.ERROR : STRINGS.DEFAULT}
          errormessage={errMsg}
        />
      </Box>

      {/* <Box className={classes.sharedWithBox}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.SHARED}
          isRequired
        />
        <Box className={classes.containRadioBtns}>
          <Box className={classes.eachRadioContainer}>
            <RadioButton
              value={selectedValue}
              onChange={handleChange}
              items={radioButtonData}
            />
          </Box>
        </Box>
      </Box> */}
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalEditViewFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={handleClose}
      />

      <Button
        label={isRename ? STRINGS.UPDATE : STRINGS.SAVE}
        size={"sm36"}
        variant={"primary"}
        onClick={() => {
          handleCreate();
        }}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = renamePopupValue?.eachView?.view_id === peopleDealsDetailsSelector?.payload?.default_view?.view_id ? <div></div> : (
    <div className={classes.modalFooterLeftContainer}>
      <Checkbox
        label={STRINGS.PIN_THIS_VIEW}
        checked={isPin}
        onChange={() => setIsPin(!isPin)}
      />
    </div>
  );

  useEffect(() => {
    // if (isRename) {
    setViewName(renamePopupValue?.eachView?.view_name || "");
    setSelectedValue(renamePopupValue?.type || STRINGS.PRIVATE_ID);
    setIsPin(renamePopupValue?.eachView?.is_pin);
    // } else {
    //   setSelectedValue(viewType);
    // }

    // if (viewType?.length) {
    //   setSelectedValue(viewType);
    // }
  }, [renamePopupValue]);

  return (
    <CustomModal
      close={handleClose}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default AddViewModal;
