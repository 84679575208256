export const GET_GROUPS_LIST_REQUEST = "GET_GROUPS_LIST_REQUEST";
export const GET_GROUPS_LIST_SUCCESS = "GET_GROUPS_LIST_SUCCESS";
export const GET_GROUPS_LIST_FAIL = "GET_GROUPS_LIST_FAIL";

export const GET_GROUPS_LIST_BY_OBJECT_TYPE_REQUEST = "GET_GROUPS_LIST_BY_OBJECT_TYPE_REQUEST";
export const GET_GROUPS_LIST_BY_OBJECT_TYPE_SUCCESS = "GET_GROUPS_LIST_BY_OBJECT_TYPE_SUCCESS";
export const GET_GROUPS_LIST_BY_OBJECT_TYPE_FAIL = "GET_GROUPS_LIST_BY_OBJECT_TYPE_FAIL";

export const GET_PROPERTIES_LIST_REQUEST = "GET_PROPERTIES_LIST_REQUEST";
export const GET_PROPERTIES_LIST_SUCCESS = "GET_PROPERTIES_LIST_SUCCESS";
export const GET_PROPERTIES_LIST_FAIL = "GET_PROPERTIES_LIST_FAIL";

export const GET_PROPERTIES_FILTERS_REQUEST = "GET_PROPERTIES_FILTERS_REQUEST";
export const GET_PROPERTIES_FILTERS_SUCCESS = "GET_PROPERTIES_FILTERS_SUCCESS";
export const GET_PROPERTIES_FILTERS_FAIL = "GET_PROPERTIES_FILTERS_FAIL";
