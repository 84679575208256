import {
  GET_PIPELINE_LIST_FAIL,
  GET_PIPELINE_LIST_REQUEST,
  GET_PIPELINE_LIST_SUCCESS,
} from "../constants/getPipelineConstants";

export const getPipelineListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PIPELINE_LIST_REQUEST:
      return { loading: true };
    case GET_PIPELINE_LIST_SUCCESS:
      return { loading: false, data: action.payload.data, error: null };
    case GET_PIPELINE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
