import React from "react";
import { Box } from "@mui/material";
import {
  NEW_LOGIN_REACHIQ_LOGO,
  SORRY_IMAGE,
} from "../../utils/constants/assets";
import { styles } from "./styles";

import { Anchor, RightsTag } from "../../components";

import Typography from "../../components/Typography/Typography";
import Button from "../../components/Buttons/Button";
import Image from "../../components/Image/Image";
import { fontWeight } from "../../utils/constants/UI";
import { GREY } from "../../utils/constants/colors";
import { STRINGS } from "./strings";
import {
  PRIVACY_POLICY_LINK,
  REACHIQ,
  TERMS_SERVICE_LINK,
} from "../../utils/constants/httpConstants";
import { INVALID_TENANT_ROUTE_PATH } from "../../utils/constants/routes";

const InvalidRouteScreen = () => {

  const classes = styles();
  const current_path = window.location.pathname;
  const handleNavigation = () => {
    window.location.href = REACHIQ;
  };

  return (
    <Box className={classes.Head}>
      <Box className={classes.logo_container}>
        <Image
          onClick={handleNavigation}
          src={NEW_LOGIN_REACHIQ_LOGO}
          className={classes.reachiqLogo}
        />
      </Box>
      <Box className={classes.invalidContentContainer}>
        <div className={classes.ImageBorder}>
          <Image className={classes.Image} src={SORRY_IMAGE} />
        </div>

        <div className={classes.sorryTitle}>
          {" "}
          <Typography
            size={"dsm"}
            color={GREY.PRIMARY}
            fontWeight={fontWeight.SEMIBOLD}
            label={STRINGS.SORRY}
          />
        </div>
        {current_path === INVALID_TENANT_ROUTE_PATH ? <div className={classes.SubHead}>
          <Typography
            size={"sm"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.SEMIBOLD}
            label={STRINGS.TENANT_INACTIVE}
          />
        </div> : <div className={classes.SubHead}>
          <Typography
            size={"sm"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.SEMIBOLD}
            label={STRINGS.LOOKS_LIKE}
          />
          <Typography
            size={"sm"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.SEMIBOLD}
            label={STRINGS.PLEASE_CLICK}
          />
          <Typography
            size={"sm"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.SEMIBOLD}
            label={STRINGS.FIELDS}
          />
        </div>}
        <p className={classes.sorryPara}></p>
        <Box className={classes.Top}>
          {/* { current_path === INVALID_TENANT_ROUTE_PATH ? null : <Button
            size={"md40"}
            variant={"primary"}
            label={STRINGS.GET_EARLY_ACCESS}
            onClick={handleNavigation}
          />} */}
          
        </Box>
      </Box>
      <Box className={classes.contains}>
        <div className={classes.containPolicy}>
          <RightsTag className={classes.termsPara} />
          {/* Privacy and Terms. */}
          <Anchor
            href={PRIVACY_POLICY_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            <Typography
              label={STRINGS.PRIVACY}
              color={GREY.QUATINARY}
              size={"xs"}
              fontWeight={fontWeight.SEMIBOLD}
            />
          </Anchor>
          <div className={classes.TypoHead}>
            <Typography
              label={STRINGS.AND}
              color={GREY.TERTIARY}
              size={"xs"}
              fontWeight={fontWeight.MEDIUM}
            />
          </div>
          <Anchor
            href={TERMS_SERVICE_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            <Typography
              label={STRINGS.TERMS}
              color={GREY.QUATINARY}
              size={"xs"}
              fontWeight={fontWeight.SEMIBOLD}
            />
          </Anchor>
        </div>
      </Box>
    </Box>
  );
};

export default InvalidRouteScreen;
