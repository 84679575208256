export const MEETING_GET_REQUEST = "MEETING_GET_REQUEST";
export const MEETING_GET_SUCCESS = "MEETING_GET_SUCCESS";
export const MEETING_GET_FAIL = "MEETING_GET_FAIL";
export const MEETING_BOOKED_GET_REQUEST = "MEETING_BOOKED_GET_REQUEST";
export const MEETING_BOOKED_GET_SUCCESS = "MEETING_BOOKED_GET_SUCCESS";
export const MEETING_BOOKED_GET_FAIL = "MEETING_BOOKED_GET_FAIL";
export const GET_MEETING_FILTERS_SUCCESS = "GET_MEETING_FILTERS_SUCCESS";

export const GET_MEETING_FILTERS_FAIL = "GET_MEETING_FILTERS_SUCCESS";
export const GET_MEETING_FILTERS_REQUEST = "GET_MEETING_FILTERS_REQUEST";
