import React, { useEffect, useState } from "react";
import { STRINGS } from "../../strings";
import { HtmlEditor } from "../../../../components";
import { ERROR, GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import CustomModal from "../../../../components/Modals/CustomModal";
import { Button } from "../../../../components/Buttons";
import classes from "./CrmComp.module.css";
import InputField from "../../../../components/InputFields/InputField";
import { Box } from "@mui/material";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import TimeSelector from "../../../../components/TimeSelector/TimeSelector";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  SEND_REMINDER,
  STEPS_PRIORITY_FIELDS,
  TIMEZONE_LIST,
} from "../../../../utils/constants";
import { taskEndpoints } from "../../../../utils/constants/httpConstants";
import { getRequest, postRequest } from "../../../../Apis";
import { useLocation, useNavigate } from "react-router-dom";
import {
  compressString,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import {
  dateTimeValidation,
  stringLengthValidation,
} from "../../../../utils/validations/inputValidations";
import EventEmitter from "../../../../utils/emitter/EventEmitter";

const CreateTaskCrmPopUp = ({
  contactDetails,
  open,
  close = () => { },
  contact_id,
  type,
  getTask = () => { },
}) => {
  console.log({ type });
  const navigate = useNavigate();
  const location = useLocation();
  const [currentFocus, setCurrentFocus] = useState("");
  const [dueTime, setDueTime] = useState("00:00");
  const [priorityType, setPriorityType] = useState([""] || []);
  const [priorityTypeId, setPriorityTypeId] = useState([""] || []);
  const [reminderType, setReminderType] = useState([""] || []);
  const [reminderTypeId, setReminderTypeId] = useState([""] || []);
  const [subject, setSubject] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [validations, setValidations] = useState({});
  const [description, setDiscription] = useState("");

  const [dueDate, setDueDate] = useState(new Date());
  let offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone_objChange = TIMEZONE_LIST?.filter(
    (item) => item?.id === offset
  );

  const cancelHandle = () => {
    close(!open);
  };

  const handleInput = (e) => {
    if (e?.target?.value <= 100) {
      setTaskTitle(e.target.value);
    } else {
      const value = e?.target?.value?.substring(0, 100);
      setTaskTitle(value)
    }
  };

  const createHandle = async () => {
    setLoading(true);
    const validatedTaskTitle = stringLengthValidation(taskTitle);
    const validatedPriority = stringLengthValidation(priorityTypeId[0]);
    // const validatedReminder = stringLengthValidation(reminderType[0]);
    const customEditorValue = description?.length
      ? `<div>${description}</div>`
      : "";

    let dateObj = dueDate;
    const NewDate = dueTime?.split(":").map(Number);
    let m = NewDate[1];
    let h = NewDate[0];
    let totalms = h * 60 * 60 * 1000 + m * 60 * 1000;
    let month = new Date(dateObj)?.getMonth() + 1;
    let day = new Date(dateObj)?.getDate();
    let year = new Date(dateObj)?.getFullYear();
    let newdate = year + "-" + month + "-" + day;

    let date;
    const newDate = new Date(newdate);
    if (dueTime) {
      date = newDate.setHours(0, 0, 0, 0) + totalms;
    } else {
      date = new Date(newdate).getTime();
    }

    const validatedDateTime = dateTimeValidation(date);
    const UpdatedLocation = location?.search?.split("?tabs=");

    const newEditorValue = compressString(
      encodeURIComponent(removeTagStyleFromHtml(customEditorValue))
    );

    if (
      validatedTaskTitle?.isValid &&
      validatedPriority?.isValid &&
      validatedDateTime.isValid
    ) {
      try {
        setValidations({});
        const payload = {
          contact_id: contact_id,
          title: taskTitle,
          step_type:
            UpdatedLocation[1] === STRINGS.TASKS_SMALL
              ? 2
              : type === STRINGS.TASKS_SMALL
                ? 2
                : 3,
          show_time_stamp: date !== null ? date : "",
          notes: newEditorValue,
          offset: timezone_objChange[0]?.offset,
          priority: priorityTypeId[0],
          send_reminder: reminderTypeId[0],
        };
        if (contactDetails?.company_id) {
          payload.company_id = contactDetails?.company_id;
        }
        const responseGet = await postRequest(
          navigate,
          taskEndpoints.createTask,
          payload
        );

        EventEmitter.emit(EMITTER_SUCCESS, responseGet?.data?.message);
        close(!open);
        getTask();
      } catch (error) {
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.data?.errors?.title || error?.data?.message
        );
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setValidations({
        priorityTypeId: validatedPriority?.message,
        taskTitle: validatedTaskTitle?.message,
        show_time_stamp: validatedDateTime.message,
      });
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LOG_TASK,
      label: `${STRINGS.LOG} ${type === "call" ? "Call" : "Task"} Activity`,
    },
  };

  const modalBodyData = (
    <Box className={classes.modalBody}>
      <div className={classes.flexHead}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.TASK_TITLE}
        />

        <InputField
          placeholder={STRINGS.ENTER_TASK}
          size={"md40"}
          onChange={handleInput}
          value={taskTitle}
          variant={
            validations?.taskTitle?.length > 1 ? STRINGS.ERROR : STRINGS.DEFAULT
          }
          errormessage={
            validations?.taskTitle?.length !== 0 ? validations?.taskTitle : ""
          }
        />
      </div>

      <Box className={classes.FLEXED}>
        <Box className={classes.halfwidth}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DUE_DATE}
            />

            <CustomDatePicker
              disablePast={true}
              value={dueDate}
              onChange={(newValue) => {
                setDueDate(newValue?.$d);
              }}
              size={"sm36"}
            />
          </div>
        </Box>
        <Box className={classes.halfwidth}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DUE_TIME}
            />
            <Box>
              <TimeSelector time={dueTime} setTime={setDueTime} />
            </Box>
          </div>
        </Box>
      </Box>
      {validations.show_time_stamp && (
        <div className={classes.containError}>
          {" "}
          <TypographyInline
            label={validations.show_time_stamp}
            color={ERROR.W_500}
            size={"xs"}
            fontWeight={fontWeight.REGULAR}
          />
        </div>
      )}
      <Box className={classes.FLEXED}>
        <Box className={classes.halfwidth}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.SEND_REMINDER}
            />

            <AutoCompleteCustom
              paperWidth="14.219vw"
              paperHeight="fit-content"
              items={SEND_REMINDER}
              LeftIcon=""
              placeholder={STRINGS.SET_REMINDER}
              valueState={reminderType}
              setValueState={setReminderType}
              idState={reminderTypeId}
              setIdState={setReminderTypeId}
              variant={
                validations?.reminderType?.length > 1
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
              }
              errormessage={
                validations?.reminderType?.length !== 0
                  ? validations?.reminderType
                  : ""
              }
              size={"sm36"}
            />
          </div>
        </Box>
        <Box className={classes.halfwidth}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.PRIORITY}
            />
            <Box>
              <AutoCompleteCustom
                paperWidth="14.219vw"
                paperHeight="fit-content"
                items={STEPS_PRIORITY_FIELDS}
                LeftIcon=""
                placeholder={STRINGS.SET_PRIORITY}
                valueState={priorityType}
                setValueState={setPriorityType}
                idState={priorityTypeId}
                setIdState={setPriorityTypeId}
                variant={
                  validations?.priorityTypeId?.length > 1
                    ? STRINGS.ERROR
                    : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.priorityTypeId?.length !== 0
                    ? validations?.priorityTypeId
                    : ""
                }
                size={"sm36"}
              />
            </Box>
          </div>
        </Box>
      </Box>
      <Box className={classes.warningRenameBoxes}>
        <div className={classes.descriptionPadding}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DESCRIPTION}
            />
            <Box className={classes.editorContent}>
              <HtmlEditor
                isAddTagNotVisible={true}
                isMeetingTagNotVisible={true}
                id="editor_task"
                setCurrentFocus={setCurrentFocus}
                currentFocus={currentFocus}
                subject={subject}
                setSubject={setSubject}
                isAITagNotVisible
                editorHeight="20vh"
                onInput={() => {
                  setDiscription(
                    document?.getElementById("editor_task")?.innerHTML
                  );
                }}
              />
            </Box>
          </div>
        </div>
      </Box>
    </Box>
  );

  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.CREATE}
        size={"sm36"}
        variant={"primary"}
        onClick={createHandle}
        isLoading={loading}
      />
    </div>
  );

  const modalFooterLeftData = <div></div>;
  useEffect(() => {
    setReminderType([SEND_REMINDER[0]?.label]);
    setReminderTypeId([SEND_REMINDER[0]?.id]);
    setPriorityType([STEPS_PRIORITY_FIELDS[0]?.label]);
    setPriorityTypeId([STEPS_PRIORITY_FIELDS[0]?.id]);

    let hoursTime = new Date().getHours();
    let minutesTime = new Date().getMinutes();
    if (minutesTime >= 45) hoursTime++;
    hoursTime = hoursTime % 24;
    minutesTime += 15;
    minutesTime = minutesTime % 60;
    if (hoursTime.toString().length === 1) {
      hoursTime = "0" + hoursTime;
    }
    if (minutesTime.toString().length === 1) {
      minutesTime = "0" + minutesTime;
    }

    const setTiming = hoursTime + ":" + minutesTime;

    setDueTime(setTiming);
  }, []);
  return (
    <CustomModal
      width={"31.250vw"}
      close={cancelHandle}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default CreateTaskCrmPopUp;
