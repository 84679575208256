import React, { useEffect, useState } from "react";
import CustomDialog from "../../../../components/Dialog/CustomDialog";
import { STRINGS } from "../../strings";
import { Button } from "../../../../components/Buttons";
import classes from "./CrmComp.module.css";
import { useSelector } from "react-redux";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { BRAND, GREY, PRIMARY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import InputField from "../../../../components/InputFields/InputField";
import TextArea from "../../../../components/InputFields/TextArea";
import { HtmlEditor } from "../../../../components";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import {
  debounceHelper,
  dropdownValuesFromId,
  replaceKeyInArrayOfObj,
} from "../../../../utils/helpers";
import { nameValidation, urlValidation } from "../../../../utils/validations/inputValidations";
import SearchField from "../../../../components/InputFields/SearchField";
import { getRequest, postRequest, putRequest } from "../../../../Apis";
import { useNavigate, useParams } from "react-router-dom";
import {
  crmEndpoints,
  formsEndpoints,
  leadsEndpoints,
} from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import {
  COUNTRY_LIST,
  DEBOUNCE_DELAY,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../../utils/constants";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";

const AddEditCompany = ({
  open = false,
  close = () => { },
  data = {},
  getContactDetails = () => { },
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );

  const [inputData, setInputData] = useState({});
  const [inputDataId, setInputDataId] = useState({});
  const [inputError, setInputError] = useState({});
  const [selectedTab, setSelectedTab] = useState(STRINGS.ADD_NEW_COMPANY_SMALL);
  const [addCompanyLoading, setAddCompanyLoading] = useState(false);
  const [savedCompanies, setSavedCompanies] = useState([]);
  const [clickedCompany, setClickedCompany] = useState({});
  const [countryList, setCountrylist] = useState(COUNTRY_LIST || []);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const [requiredOrder, setRequiredOrder] = useState({});
  const [loading, setLoading] = useState(false);

  const isUpdate = Object.keys(data)?.length;
  // add company api handler.
  const addCompanyHandler = async (e) => {
    e?.preventDefault();
    if (
      selectedTab === STRINGS.ADD_EXISITING_COMPANY_SMALL ||
      validationHandler(inputData)
    ) {
      try {
        let payload = {};
        if (selectedTab === STRINGS.ADD_NEW_COMPANY_SMALL) {
          payload = { query: { ...inputData, contact_id: params?.id } };
        } else {
          payload = {
            query: {
              contact_id: params?.id,
              company_id: clickedCompany?.id,
            },
          };
        }
        setAddCompanyLoading(true);
        let response = await postRequest(
          navigate,
          crmEndpoints.addCompany,
          payload
        );
        getContactDetails();
        setAddCompanyLoading(false);
        close();
        setInputData({});
        setClickedCompany({});
        setSavedCompanies([]);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } catch (error) {
        setAddCompanyLoading(false);
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    }
  };

  // update/edit company api handler.
  const updateCompanyHandler = async (e) => {
    e?.preventDefault();
    let payload = {};
    if (selectedTab === STRINGS.ADD_NEW_COMPANY_SMALL) {
      if (validationHandler(inputData)) {
        payload = { query: { ...inputData, contact_id: params?.id } };
        setAddCompanyLoading(true);
        const response = await putRequest(
          navigate,
          crmEndpoints.updateCompany,
          payload
        );
        getContactDetails();
        setAddCompanyLoading(false);
        close();
        setInputData({});
        setClickedCompany({});
        setSavedCompanies([]);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      }


    } else {
      try {
        payload = {
          query: { company_id: clickedCompany?.id, id: params?.id },
        };
        setAddCompanyLoading(true);
        const response = await putRequest(
          navigate,
          crmEndpoints.editContactPersonDetails,
          payload
        );
        getContactDetails();
        setAddCompanyLoading(false);
        close();
        setInputData({});
        setClickedCompany({});
        setSavedCompanies([]);

        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } catch (error) {
        setAddCompanyLoading(false);
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    }
  };

  // validate add company required fields.
  const validationHandler = (inputData) => {

    // getting all the required fields id and storing it into array.
    let required_fields = [];
    let error_arr = [];
    let input_error = { ...inputError };

    Object.keys(requiredOrder)?.forEach((element, index) => {
      if (requiredOrder?.[element]) {
        required_fields.push(element);
      }
    });

    // loop on required ids for checking validation according to field_type
    required_fields?.forEach((element, index) => {
      if (element !== "company_website") {
        let validate = nameValidation(inputData?.[element]);

        if (validate?.isValid) {
          error_arr?.filter(item => item !== element);
          input_error = ({ ...input_error, [element]: "" });
        } else {
          error_arr = [...error_arr, element];
          input_error = ({ ...input_error, [element]: validate?.message });
        }
      }
    })

    // storing the error into state for displaying error for company_website
    const validatedCompanyWebsite = urlValidation(inputData?.company_website);
    if (validatedCompanyWebsite?.isValid) {
      error_arr?.filter(item => item !== "company_website");
      input_error = ({ ...input_error, company_website: "" });
    } else {
      error_arr = [...error_arr, "company_website"];
      input_error = ({ ...input_error, company_website: validatedCompanyWebsite?.message });
    }
    setInputError({ ...input_error });

    // return value according to error key check in error_arr.
    if (error_arr?.length) {
      return false;
    } else {
      return true;
    }
  };

  // company name dropdown fields search handler.
  const searchHandler = (e, property) => {
    let countryValue = [...COUNTRY_LIST];

    let tempItems = [...getPropertiesListSelector?.hits];
    let values = dropdownValuesFromId(tempItems, property?.id);
    let data = [];
    if (property?.id === STRINGS.COMPANY_ID_SMALL) {
      data = values?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
    } else if (property?.id === STRINGS.COMPANY_COUNTRY_SMALL) {
      let data = countryValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setCountrylist(COUNTRY_LIST);
      } else {
        setCountrylist(data);
      }
    }
    // // Storing searched Data into main state.
    // let newData = [];
    // tempItems?.map((item) => {
    //   if (
    //     item?.id === property?.id &&
    //     item?.field_type?.toString() === STRINGS.SINGLE_SELECT
    //   ) {
    //     let obj = {
    //       ...item,
    //       value: data,
    //     };
    //     newData?.push(obj);
    //   } else {
    //     newData.push(item);
    //   }
    // });
    // setPropertiesData(newData);
  };

  // get saved companies list api handler according to search.
  const getCompaniesList = async (e) => {
    try {
      let url =
        leadsEndpoints.getSavedCompanyName + `?search=${e?.target?.value}`;
      const response = await getRequest(navigate, url);
      setSavedCompanies(response?.data?.data);
    } catch (error) {
      console.log("Error -->>>> ", error);
    }
  };

  const debounceSearchCompany = debounceHelper(
    getCompaniesList,
    DEBOUNCE_DELAY
  );

  const getValuesOfDropdown = (prop) => {
    if (prop?.id === STRINGS.COMPANY_COUNTRY_SMALL) {
      let country_list = countryList?.map((item) => {
        return {
          label: item?.label,
          id: item?.id,
        };
      });

      return country_list;
    } else {
      return replaceKeyInArrayOfObj(prop?.value, "labelValue", "label");
    }
  };

  // type id components for add company forms.
  const type_id_components = (prop) => {
    switch (prop?.field_type[0]) {
      case STRINGS.DATE_PICKER:
        return (
          <CustomDatePicker
            size={"sm36"}
            value={inputData[prop?.id] || new Date()}
            onChange={(dateValue) =>
              setInputData({ ...inputData, [prop?.id]: dateValue })
            }
          />
        );

      case STRINGS.NUMBER:
        return (
          <InputField
            size={"sm36"}
            type={"number"}
            onChange={(e) =>
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              })
            }
            value={inputData[prop?.id]}
            placeholder={`Enter a ${prop?.property_name}`}
          />
        );

      case STRINGS.MULTI_LINE_TEXT_INPUT:
        return (
          <TextArea
            rows={"5"}
            onChange={(e) =>
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              })
            }
            value={inputData[prop?.id]}
          />
        );

      case STRINGS.RICH_TEXT_EDITOR:
        return (
          <><div className={inputError[prop?.id] ? classes.htmlEditorError : classes.htmlEditor}>
            <HtmlEditor
              editorHeight={"12vh"}
              previosData={inputData[prop?.id] || ""}
              onInput={() => {
                setInputData({
                  ...inputData,
                  [prop?.id]: document?.getElementById(STRINGS.EDITOR_ID)
                    ?.innerHTML,
                });
              }}
              isAITagNotVisible
              isAddTagNotVisible
              isMeetingTagNotVisible
              isFocusNotRequired
            />

          </div> {inputError[prop?.id] ? <TypographyInline label={inputError[prop?.id] || ""} color={BRAND.PRIMARY} size={"sm"} fontWeight={fontWeight.REGULAR}
          /> : null}</>
        );

      case STRINGS.SINGLE_SELECT:
        return (
          <AutoCompleteCustom
            dropdownId="add_company_dropdown"
            items={getValuesOfDropdown(prop)}
            size={"sm36"}
            valueState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputDataId[prop?.id]]
                : [inputData[prop?.id]]
            }
            setValueState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                })
                : setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                });
            }}
            idState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputData[prop?.id]]
                : [inputDataId[prop?.id]]
            }
            setIdState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                })
                : setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                });
            }}
            isSearchEnable={
              prop?.property_name === STRINGS.COMPANY ||
              STRINGS.PERSON_COUNTRY_CAPS
            }
            handleSearch={(event) => {
              searchHandler(event, prop);
            }}
          />
        );

      case STRINGS.MULTIPLE_SELECT:
        return (
          <AutoCompleteCustom
            dropdownId="add_company_dropdown_multiple"
            items={replaceKeyInArrayOfObj(prop?.value, "labelValue", "label")}
            size={"sm36"}
            multiple
            valueState={inputData[prop?.id]}
            setValueState={(data) => {
              setInputData({
                ...inputData,
                [prop?.id]: data,
              });
            }}
            idState={inputDataId[prop?.id]}
            setIdState={(data) => {
              setInputDataId({
                ...inputDataId,
                [prop?.id]: data,
              });
            }}
          />
        );

      default:
        return (
          <InputField
            size={"sm36"}
            placeholder={`Enter a ${prop?.property_name}`}
            value={inputData[prop?.id]}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
              if (inputData[prop?.id]?.length) {
                validationHandler({
                  ...inputData,
                  [prop?.id]: e.target.value,
                });
              }
            }}
            variant={inputError[prop?.id]?.length ? "error" : "default"}
            errormessage={inputError[prop?.id]}
          />
        );
    }
  };

  // api handler for get fields order.
  const getFieldsOrder = async () => {
    try {
      setLoading(true)
      const response = await getRequest(
        navigate,
        formsEndpoints.getFormsField
      );
      let columns = [];
      let required_obj = {};
      response?.data?.data?.Company?.sort((obj1, obj2) => obj1.order - obj2.order)?.forEach(element => {
        columns.push(element?.field_id);
        required_obj = { ...required_obj, ...{ [element.field_id]: element?.required } };
      });
      setRequiredOrder(required_obj);
      setFieldsOrder(columns || []);
    } catch (error) {
      console.log("Error------->>>>>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const dialogHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: isUpdate ? STRINGS.UPDATE_COMPANY : STRINGS.ADD_COMPANY,
    },
  };

  const dialogFooter = (
    <div className={classes.addDialogFooter}>
      <div>
        <Button
          label={STRINGS.CANCEL}
          size="sm36"
          variant={"ghost"}
          onClick={close}
        />
      </div>
      <div>
        <Button
          label={isUpdate ? STRINGS.UPDATE_COMPANY : STRINGS.ADD_COMPANY}
          size="sm36"
          variant={"primary"}
          onClick={isUpdate ? updateCompanyHandler : addCompanyHandler}
          isLoading={addCompanyLoading}
        />
      </div>
    </div>
  );

  const tabData = [
    {
      label: isUpdate ? STRINGS.UPDATE_DETAILS : STRINGS.CREATE_NEW,
      id: STRINGS.ADD_NEW_COMPANY_SMALL,
    },
    {
      label: STRINGS.ADD_EXISITING,
      id: STRINGS.ADD_EXISITING_COMPANY_SMALL,
    },
  ];

  //useEffect
  useEffect(() => {
    if (open) {
      getFieldsOrder();
    }
  }, [open]);

  useEffect(() => {
    if (isUpdate) {
      setInputData({ ...data });
    }
  }, [data]);

  return (
    <CustomDialog
      open={open}
      close={close}
      header={dialogHeader}
      footer={dialogFooter}
      width={"26.042vw"}
      remove_padding
    >
      {/* tabs of company */}
      <div className={classes.tabsContainer}>
        {tabData?.map((item, index) => {
          return (
            <div
              className={
                selectedTab === item?.id
                  ? classes.tabSelected
                  : classes.tabPrimary
              }
              key={`addCompany_tab${index}`}
              onClick={() => {
                setSelectedTab(item?.id);
                setSavedCompanies([]);
                setClickedCompany({});
                if (!isUpdate) { setInputData({}); setInputError({}) };
              }}
            >
              <TypographyInline
                color={
                  selectedTab === item?.id ? PRIMARY.W_500 : GREY.SECONDARY
                }
                fontWeight={
                  selectedTab === item?.id
                    ? fontWeight.SEMIBOLD
                    : fontWeight.REGULAR
                }
                label={item?.label}
                size={"sm"}
              />
            </div>
          );
        })}
      </div>

      {/* add or update company form */}
      {selectedTab === STRINGS.ADD_NEW_COMPANY_SMALL ? (
        <>
          {loading ? (
            <div className={classes.addCompanyFormLoaderContainer}>
              {Array.from({ length: 20 }).map((index) => {
                return (
                  <div key={`addCompany${index}`}>
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"100%"}
                      skeletonHeight={"30px"}
                      stackWidth={"100%"}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <form
              className={classes.addCompanyFormContainer}
              onSubmit={addCompanyHandler}
            >
              {fieldsOrder?.map((field) => {
                let propertyKeyValue = { ...getPropertiesListSelector?.properties_key_value };
                return (
                  <div className={classes.inputLabelField}>
                    <div className={classes.labelButtonContainer}>
                      <TypographyInline
                        label={propertyKeyValue?.[field]?.property_name}
                        color={GREY.QUATINARY}
                        size={"sm"}
                        fontWeight={fontWeight.MEDIUM}
                        isRequired={requiredOrder?.[field]}
                      />
                    </div>
                    {type_id_components(propertyKeyValue?.[field])}
                  </div>
                );
              })}
            </form>
          )
          }
        </>
      ) : (
        // Add Exisiting Company Section.
        <div className={classes.addCompanyFormContainer}>
          <SearchField
            size={"sm36"}
            placeholder={STRINGS.SEARCH_COMPANIES}
            onChange={debounceSearchCompany}
          />
          {/* allResults container */}
          <div className={classes.allResultContainer}>
            {/* all result label with companies count */}
            <div className={classes.allCompaniesCountLabel}>
              <TypographyInline
                label={STRINGS.ALL_RESULTS}
                color={GREY.QUATINARY}
                size={"sm"}
                fontWeight={fontWeight.SEMIBOLD}
              />
              {savedCompanies?.length ? (
                <TypographyInline
                  label={`${savedCompanies?.length} results`}
                  color={GREY.SECONDARY}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                />
              ) : null}
            </div>
            {/* loop data of searched companies. */}
            {savedCompanies?.length ? (
              <div className={classes.companiesList}>
                {savedCompanies?.map((company, index) => {
                  return (
                    <div
                      className={
                        clickedCompany?.id === company?.id
                          ? classes.Highlight
                          : classes.notHighlighted
                      }
                    >
                      <TypographyInline
                        label={company?.name}
                        color={GREY.SECONDARY}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                        onClick={() => {
                          setClickedCompany(company);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </CustomDialog>
  );
};

export default AddEditCompany;
