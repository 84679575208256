export const STRINGS = {
  PIPELINE_NAME: "Pipeline Name*",
  EMPTY_DEALS_SCREEN_HEADING: "Generate Deal",
  EMPTY_DEALS_SCREEN_DESC:
    "Enhance your lead management capabilities with our CRM platform, equipped with powerful filtering options.",
  DEALS: "Deals",
  DEALS_SMALL: "deals",
  CREATE_DEAL: "Create Deal",
  ADD_DEAL: "Add Deal",
  CANCEL: "Cancel",
  DATE_PICKER: "Date Picker",
  NUMBER: "Number",
  MULTI_LINE_TEXT_INPUT: "Multi-line Text Input",
  RICH_TEXT_EDITOR: "Rich Text Editor",
  SINGLE_SELECT: "Single Select",
  MULTIPLE_CHECKBOX: "Multiple Checkbox",
  MULTIPLE_SELECT: "Multiple Select",
  NUMBER_PLACEHOLDER: "Enter phone number",
  ENTER_INPUT_VALUE: "Enter input value",
  SEARCH: "Search...",
  DEAL_OWNER_SMALL: "deal_owner",
  DEAL_OWNER: "Deal Owner",
  DEAL_NAME: "Deal Name",
  DEAL_NAME_SMALL: "deal_name",
  DEAL_AMOUNT: "Deal Amount",
  DEAL_AMOUNT_SMALL: "deal_amount",
  ASSOCIATED_CONTACT: "Associated Contact",
  ASSOCIATED_CONTACT_SMALL: "associated_contact",
  ASSOCIATED_COMPANY_SMALL: "associated_company",
  FILTER: "Filter",
  CLEAR_ALL: "Clear All",
  DELETE_MODAL_DEAL: "delete_deal_modal",
  ARE_YOU_SURE_DELETE:
    "Are you sure you want to delete this? This action cannot be undone.",
  DELETE_DEAL_HEADER_TILE: "Delete Deals?",
  DELETE: "Delete",
  ASSIGN: "Assign",
  ASSIGN_TO: "Assign to",
  ERROR: "error",
  DEFAULT: "default",
  DEFAULT_CAPS: "Default",
  DEAL: "Deal",
  NOT: "_not",
  CREATEPIPELINE: "Create a pipeline",
  PIPELINE_SMALL: "pipeline_name",
  STAGES: "Stages",
  SEARCH_SMALL: "search",
  NAME_SMALL: "name",
  CREATE_PIPELINE: "Create a pipeline",
  ADMIN_USER: "admin-user",
  ADD: "ADD",
  BOARD: "board",
  LIST: "list",
  BOARD_ACTIONS: "Board Actions",
  DEAL_VIEW_SMALL: "deal_view",
  SORT: "Sort",
  EDIT_STAGES: "Edit Stages",
  EDIT_CARDS: "Edit Cards",
  SORT_DETAILS_SMALL: "sort_details",
  SORT_DETAILS: "Sort Details",
  SAVE: "Save",
  SORT_BY: "Sort By",
  PRIORITY: "Priority",
  PRIORITY_ID: "sort_modal_priority",
  SORT_BY_ID: "sort_modal_sortBy",
  EDIT_BOARD_VIEW: "Edit Board View",
  EDIT_BOARD_VIEW_ID: "edit_board_view",
  CHOOSE_CARD_STYLE: "Choose card style",
  COMPACT_SMALL: "compact",
  COMPACT: "Compact",
  MORE_OPTIONS: "More Options",
  DISPLAY_PIORITY_AND_RECORDS: "Display associated records",
  LIST_VIEW: "List View",
  BOARD_VIEW: "Board View",
  ALL: "All",
  ALL_PIPELINES: "All Pipelines"
};
