import React, { useEffect, useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Anchor,
} from "../../../../components";
import classes from "./CrmComp.module.css";
import IconComponent from "../../../../components/Icon/IconComponent";
import { GREY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import IconColoredComponent from "../../../../components/Icon/IconColoredComponent";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { STRINGS } from "../../strings";
import {
  dateTimeStringEmailLog,
  decompressString,
  removeStylesFromHeadHtml,
} from "../../../../utils/helpers";
import { Accordion } from "@mui/material";
import { Button } from "../../../../components/Buttons";
import AddComment from "../../Components/AddComment";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import DeleteCommentModal from "../../Components/DeleteCommentModal";
import CustomModal from "../../../../components/Modals/CustomModal";
import {
  PIN_ICON,
  PUSH_PIN_ICON,
  UNPIN_ICON,
} from "../../../../utils/constants/assets";
import Image from "../../../../components/Image/Image";
import CustomTooltip from "../../../../components/Tooltip/CustomTooltip";
import parse from "html-react-parser";

const EmailSentList = ({
  emailData,
  id,
  getEmailLogList = () => { },
  expanded,
  setOpenSendEmailModal = () => { },
  setClickedEmail = () => { },
  setReplyType = () => { },
  showOperation = true,
}) => {
  const navigate = useNavigate();

  const [emailOpen, setEmailOpen] = useState(false);
  const [emailComments, setEmailComments] = useState([]);
  const [emailCommentsPage, setEmailCommentsPage] = useState(0);
  const [commentBody, setCommentBody] = useState("");
  const [createCommentLoading, setCreateCommentLoading] = useState(false);
  const [isDeleteCommentLoading, setIsDeleteCommentLoading] = useState(false);
  const [deleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [clickedCommentData, setClickedCommentData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [clickedViewThread, setClickedViewThread] = useState(false);
  const [viewThreadLoading, setViewThreadLoading] = useState(false);
  const [emailThreadData, setEmailThreadData] = useState({});

  const emailNamesDisplayFromArray = (data) => {
    let emailsLabel = "";
    data?.forEach((element, index) => {
      emailsLabel = `${emailsLabel} ${element}${data?.length - 1 === index ? "" : ","
        }`;
    });
    return emailsLabel;
  };

  // delete mail modal handler
  const deleteEmailHandler = () => {
    setDeleteModal(true);
  };

  // delte mail api handler
  const deleteMailApiHandler = async () => {
    try {
      let url = "";
      if (clickedViewThread && emailThreadData?.length) {
        url = `${crmEndpoints.deleteEmail}/${emailData?.message_id}`;
      } else {
        url = `${crmEndpoints.deleteThread}/${emailData?.thread_id}`;
      }
      setDeleteButtonLoading(true);
      const response = await deleteRequest(url, {}, navigate);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      getEmailLogList();
      setDeleteModal(false);
      setDeleteButtonLoading(false);
    } catch (error) {
      setDeleteModal(false);
      setDeleteButtonLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // reply to mail api handler
  const replyEmailHandler = (type) => {
    setReplyType(type);
    setClickedEmail(emailData);
    setOpenSendEmailModal(true);
  };

  // forward mail api handler
  const forwardEmailHandler = () => {
    setClickedEmail(emailData);
  };

  // pin email log api handler
  const emailPinHandler = async (data) => {
    try {
      // setLogEmailCommentsPage(0);
      let payload = {
        pin: emailData?.pin ? 0 : 1,
        _id: emailData?._id,
      };
      const response = await putRequest(
        navigate,
        crmEndpoints.pinEmail,
        payload
      );
      getEmailLogList();
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // api call for getting email log comments
  const getEmailComments = async (isOnScroll, initial) => {
    try {
      let url =
        crmEndpoints.getEmailLogComments +
        `/${emailData?._id}?size=10&page=${initial ? 0 : emailCommentsPage}`;
      const response = await getRequest(navigate, url);
      if (isOnScroll) {
        setEmailComments([...emailComments, ...response?.data?.data?.records]);
      } else {
        setEmailComments([...response?.data?.data?.records]);
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // creating email log comment api handler
  const createCommentHandler = async () => {
    try {
      setEmailCommentsPage(0);
      let payload = {
        comment: commentBody,
        email_id: emailData?._id,
      };
      setCreateCommentLoading(true);
      const response = await postRequest(
        navigate,
        crmEndpoints.createEmailLogComment,
        payload
      );
      getEmailComments(false, true);

      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setCreateCommentLoading(false);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  // edit email log comment api handler
  const updateEmailLogCommentHandler = async (comment, editValue) => {
    try {
      setEmailCommentsPage(0);
      let payload = {
        comment: editValue[comment?.id],
        id: comment?.id,
      };
      const response = await putRequest(
        navigate,
        crmEndpoints.updateEmailLogComment,
        payload
      );
      getEmailComments(false, true);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // delete email log comment api handler
  const deleteEmailLogCommentHandler = async (data) => {
    try {
      setEmailCommentsPage(0);
      setIsDeleteCommentLoading(true);
      let url = `${crmEndpoints?.deleteEmailLogComment}/${data?.id}`;
      const response = await deleteRequest(url, {}, navigate);
      getEmailComments(false, true);
      setIsDeleteCommentLoading(false);
      setDeleteCommentModalOpen(false);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // storing clicked data for delete and open delete modal.
  const deleteEmailLogCommentModalHandler = (data) => {
    setClickedCommentData(data);
    setDeleteCommentModalOpen(true);
  };

  // view all thread api handler
  const viewThreadApiHandler = async () => {
    try {
      setViewThreadLoading(true);
      let url = `${crmEndpoints.getEmailByThreads}/${emailData?.thread_id}`;
      const response = await getRequest(navigate, url);
      setEmailThreadData(response?.data?.data?.records);
      setViewThreadLoading(false);
      setClickedViewThread(true);
    } catch (error) {
      console.log("Error -->>>>>> ", error);
      setViewThreadLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const sentEmailOperations = [
    {
      id: STRINGS.DELETE_SENT_EMAIL_SMALL,
      label: STRINGS.DELETE,
      leftIcon: ICON_LABELS.DELETE,
      onClick: () => deleteEmailHandler(),
      isVisible: true,
    },
    {
      id: STRINGS.REPLY_ALL_SMALL,
      label: STRINGS.REPLY_ALL,
      leftIcon: ICON_LABELS.REPLY_ALL,
      onClick: () => replyEmailHandler("reply_all"),
      isVisible: true,
    },
    {
      id: STRINGS.REPLY_SMALL,
      label: STRINGS.REPLY,
      leftIcon: ICON_LABELS.REPLY,
      onClick: () => replyEmailHandler("reply"),
      isVisible: true,
    },
    {
      id: STRINGS.FORWARD_SMALL,
      label: STRINGS.FORWARD,
      leftIcon: ICON_LABELS.SHORTCUT,
      onClick: () => forwardEmailHandler(),
      isVisible: false,
    },
  ];

  const deleteModalHeader = {
    heading: {
      id: STRINGS.DELETE_MODAL_COMMENT,
      label: STRINGS.DELETE_EMAIL_HEADER_TILE,
    },
  };

  const deleteModalRightFooter = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setDeleteModal(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={() => {
          deleteMailApiHandler();
        }}
        isLoading={deleteButtonLoading}
      />
    </div>
  );
  const deleteModalLeftFooter = <div></div>;

  useEffect(() => {
    if (expanded) {
      setEmailOpen(expanded);
    }
  }, [emailData]);

  useEffect(() => {
    if (emailOpen) {
      getEmailComments(false, true);
    }
  }, [emailOpen]);

  const random_id = "log" + Date.now();

  return (
    <div key={id}>
      <Accordion
        className={classes.accrodionRoot}
        onChange={() => setEmailOpen(!emailOpen)}
        expanded={emailOpen}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {showOperation && emailData?.pin ? (
            <div className={classes.pinContainer}>
              <Image className={classes.pinIcon} src={PIN_ICON} />
            </div>
          ) : null}
          <div className={classes.summaryContainer}>
            {/* accordion summary left part having title and icons */}
            <div className={classes.emailLogAccordionSummaryLeftPart}>
              {/* Accordion Icon and Email Icon */}
              <div className={classes.accordionSummaryIconContainer}>
                <IconComponent
                  color={GREY.TERTIARY}
                  fontSize={fontSize.DXS}
                  iconLabel={
                    emailOpen
                      ? ICON_LABELS.ACTION_ARROW
                      : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                  }
                />

                <IconColoredComponent
                  variant={"orange"}
                  iconLabel={ICON_LABELS.EMAIL}
                  size={"small"}
                />
              </div>

              {/* Sent Email Title */}
              <div className={classes.loggedEmailTitleContainer}>
                <TypographyInline
                  color={GREY.PRIMARY}
                  label={`${emailData?.type === "sent" ? STRINGS.EMAIL + ": " : ""
                    } ${emailData?.subject}`}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  isNoWrap
                />
              </div>
            </div>

            {/* accordion summary right part having date time and action icon */}
            <div className={classes.emailLogAccordionSummaryRightPart}>
              <div className={classes.loggedEmailTimeLabel}>
                <IconComponent
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.EVENT}
                />

                <TypographyInline
                  color={GREY.SECONDARY}
                  label={dateTimeStringEmailLog(emailData?.created_at)}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  isEllipses
                />
              </div>
              {showOperation && (
                <>
                  <div className={classes.verticalLine} />
                  <div
                    className={classes.moreOptionsIconContianer}
                    onClick={(e) => {
                      e?.stopPropagation();
                    }}
                  >
                    <Image
                      className={classes.pinIcon}
                      src={emailData?.pin ? UNPIN_ICON : PUSH_PIN_ICON}
                      onClick={emailPinHandler}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.emailSentAccordionDetail}>
            {/* this will check the length of thread, i.e, this will display when we clicked View Thread Button. */}
            {emailData?.thread_id && emailThreadData?.length ? (
              <>
                {/* mapping all thread data. */}
                {emailThreadData?.map((emailData, index) => {
                  return (
                    <div
                      className={`${classes.eachThreadContainer} ${emailThreadData?.length - 1 !== index
                        ? classes.eachThreadBorder
                        : ""
                        }`}
                    >
                      <div className={classes.recipntsAndOperationsButton}>
                        {/* Accordion Detail to, cc, bcc part */}
                        <div className={classes.emailRecipentsNames}>
                          <TypographyInline
                            color={GREY.SECONDARY}
                            label={emailData?.from_email || "Vernika Suryan"}
                            size={"sm"}
                            fontWeight={fontWeight.SEMIBOLD}
                            isNoWrap
                          />
                          <TypographyInline
                            color={GREY.SECONDARY}
                            label={`${STRINGS.TO} ${emailNamesDisplayFromArray(
                              emailData?.to_emails
                            )}`}
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                            isNoWrap
                          />
                          {emailData?.cc_emails?.length ? (
                            <TypographyInline
                              color={GREY.SECONDARY}
                              label={`${STRINGS.CC_SMALL
                                } ${emailNamesDisplayFromArray(
                                  emailData?.cc_emails
                                )}`}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                              isNoWrap
                            />
                          ) : null}
                          {emailData?.bcc_emails?.length ? (
                            <TypographyInline
                              color={GREY.SECONDARY}
                              label={`${STRINGS.BCC_SMALL
                                } ${emailNamesDisplayFromArray(
                                  emailData?.bcc_emails
                                )}`}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                              isNoWrap
                            />
                          ) : null}
                        </div>
                        {/* Accordion Detail reply, reply all and forward button part only for index 0 menas on the first thread */}
                        {/* {!index ? ( */}
                        <div className={classes.sentEmailOperations}>
                          {sentEmailOperations?.map((item, index) => {
                            return (
                              item.isVisible && (
                                <div key={`sentEmailOeprationsBtn${index}`}>
                                  <Button
                                    LeftIcon={item?.leftIcon}
                                    label={item?.label}
                                    size={"xs28"}
                                    variant={"text"}
                                    isLoading={item?.isLoading}
                                    onClick={() => item?.onClick(emailData)}
                                  />
                                </div>
                              )
                            );
                          })}
                        </div>
                        {/* ) : null} */}
                      </div>

                      {/* Body Part. */}
                      <div id="modal_body" className={classes.sentEmailData}>
                        <div className="exclude" id={random_id}>
                          {emailData?.is_compressed ? parse(decodeURIComponent(decompressString(
                            removeStylesFromHeadHtml(emailData?.body, random_id)
                          ))) : parse(
                            removeStylesFromHeadHtml(emailData?.body, random_id)
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              // this will return at initial time whem view thread is not clicked.
              <>
                <div className={classes.recipntsAndOperationsButton}>
                  {/* Accordion Detail to, cc, bcc part */}
                  <div className={classes.emailRecipentsNames}>
                    <TypographyInline
                      color={GREY.SECONDARY}
                      label={emailData?.from_email || "Vernika Suryan"}
                      size={"sm"}
                      fontWeight={fontWeight.SEMIBOLD}
                      isNoWrap
                    />
                    <TypographyInline
                      color={GREY.SECONDARY}
                      label={`${STRINGS.TO} ${emailNamesDisplayFromArray(
                        emailData?.to_emails
                      )}`}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                      isNoWrap
                    />
                    {emailData?.cc_emails?.length ? (
                      <TypographyInline
                        color={GREY.SECONDARY}
                        label={`${STRINGS.CC_SMALL
                          } ${emailNamesDisplayFromArray(emailData?.cc_emails)}`}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                        isNoWrap
                      />
                    ) : null}
                    {emailData?.bcc_emails?.length ? (
                      <TypographyInline
                        color={GREY.SECONDARY}
                        label={`${STRINGS.BCC_SMALL
                          } ${emailNamesDisplayFromArray(emailData?.bcc_emails)}`}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                        isNoWrap
                      />
                    ) : null}
                  </div>
                  {/* Accordion Detail reply, reply all and forward button part */}
                  {showOperation && (
                    <div className={classes.sentEmailOperations}>
                      {sentEmailOperations?.map((item, index) => {
                        return (
                          item.isVisible && (
                            <div key={`sentEmailOeprationsBtn${index}`}>
                              <Button
                                LeftIcon={item?.leftIcon}
                                label={item?.label}
                                size={"xs28"}
                                variant={"text"}
                                isLoading={item?.isLoading}
                                onClick={() => item?.onClick(emailData)}
                              />
                            </div>
                          )
                        );
                      })}
                    </div>
                  )}
                </div>

                {/* Body Part. */}
                <div id="modal_body" className={classes.sentEmailData}>
                  {/* <div>{parse(emailData?.body)}</div> */}
                  <div className="exclude" id={random_id}>
                    {emailData?.is_compressed ? parse(decodeURIComponent(decompressString(
                      removeStylesFromHeadHtml(emailData?.body, random_id)
                    ))) : parse(
                      removeStylesFromHeadHtml(emailData?.body, random_id)
                    )}
                  </div>
                </div>
                {/* Attachments list */}
                <div className={classes.attachment}>
                  {emailData?.attachment_links &&
                    emailData?.attachment_links?.map((item) => {
                      return (
                        <CustomTooltip title={item?.name} key={item.url}>
                          <Anchor href={item.url} className={classes.Anchor}>
                            <div className={classes.attachmentContainer}>
                              <IconComponent
                                color={GREY.SECONDARY}
                                fontSize={fontSize.SM}
                                iconLabel={ICON_LABELS.FILE_OPEN}
                              />
                              <TypographyInline
                                color={GREY.SECONDARY}
                                label={
                                  item.name?.slice(0, 30) +
                                  (item?.name?.length > 30 ? "..." : "")
                                }
                                size={"xs"}
                                fontWeight={fontWeight.MEDIUM}
                                isNoWrap
                              />
                            </div>
                          </Anchor>
                        </CustomTooltip>
                      );
                    })}
                </div>
              </>
            )}

            {/* All thread button and email stats */}
            {(!clickedViewThread && emailData?.thread_id) || emailData?.status === "scheduled" ? <div className={classes.threadEmailStatsContainer}>
              {!clickedViewThread && emailData?.thread_id ? <div>
                <Button
                  size={"sm36"}
                  LeftIcon={ICON_LABELS.REORDER}
                  label={STRINGS.VIEW_THREAD}
                  variant={"text"}
                  onClick={viewThreadApiHandler}
                  isLoading={viewThreadLoading}
                />
              </div> : <div></div>}

              {emailData?.status === "scheduled" ? <div>
                <TypographyInline
                  color={GREY.SECONDARY}
                  label={`${STRINGS.SCHEDULE_FOR} ${dateTimeStringEmailLog(emailData?.processing_date_time)}`}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  isEllipses
                />
              </div> : null}
            </div> : null}
          </div>
        </AccordionDetails>

        {/* detail comment section */}
        {showOperation && (
          <div className={classes.emailLogListCommentContainer}>
            <AddComment
              createCommentHandler={createCommentHandler}
              value={commentBody}
              setValue={setCommentBody}
              isLoading={createCommentLoading}
              commentsList={emailComments}
              onScrollHandler={() =>
                setEmailCommentsPage(emailCommentsPage + 1)
              }
              updateCommentHandler={updateEmailLogCommentHandler}
              deleteModalHandler={deleteEmailLogCommentModalHandler}
            />

            <DeleteCommentModal
              open={deleteCommentModalOpen}
              close={() => {
                setDeleteCommentModalOpen(false);
              }}
              clickedData={clickedCommentData}
              deleteButtonLoading={isDeleteCommentLoading}
              deleteAPIHandler={deleteEmailLogCommentHandler}
            />
          </div>
        )}
      </Accordion>

      {/* Delete Email log list modal. */}
      <CustomModal
        variant={"error"}
        close={() => setDeleteModal(false)}
        open={deleteModal}
        header={deleteModalHeader}
        footer={{
          left: deleteModalLeftFooter,
          right: deleteModalRightFooter,
        }}
      >
        <div className={classes.modalBody}>
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.DELETE_COMMENT_DESC}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default EmailSentList;
