import React from "react";
import { fontSize, lineHeight } from "../../utils/constants/UI";

const TypographyInline = ({
  size,
  color,
  label,
  fontWeight = 400,
  isRequired = false,
  hoverColor = "",
  isCapitalise = false,
  isEllipses,
  isNoWrap,
  ...props
}) => {
  const classes = {
    micro: {
      fontSize: fontSize.MICRO,
      lineHeight: lineHeight.MICRO,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    xs: {
      fontSize: fontSize.XS,
      lineHeight: lineHeight.XS,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    sm: {
      fontSize: fontSize.SM,
      lineHeight: lineHeight.SM,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    md: {
      fontSize: fontSize.MD,
      lineHeight: lineHeight.MD,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    lg: {
      fontSize: fontSize.LG,
      lineHeight: lineHeight.LG,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    xl: {
      fontSize: fontSize.XL,
      lineHeight: lineHeight.XL,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    dxs: {
      fontSize: fontSize.DXS,
      lineHeight: lineHeight.DXS,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    dsm: {
      fontSize: fontSize.DSM,
      lineHeight: lineHeight.DSM,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    dlg: {
      fontSize: fontSize.DLG,
      lineHeight: lineHeight.DLG,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
    dxl: {
      fontSize: fontSize.DXL,
      lineHeight: lineHeight.DXL,
      fontWeight: fontWeight,
      color: color,
      whiteSpace: (isEllipses || isNoWrap) && "nowrap",
      overflow: isEllipses && "hidden",
      textOverflow: isEllipses && "ellipsis",
      textTransform: isCapitalise && "capitalize",
    },
  };

  return (
    <p style={classes[size]} {...props}>
      {`${label}${isRequired ? "*" : ""}`}
    </p>
  );
};

export default TypographyInline;
