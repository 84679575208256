import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { max } from "d3";
import { STATS_KEY_VALUES } from "../../../../utils/constants";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Styles } from "../../Styles";

export const D3HBarChart = (props) => {
  const { index, dataSet, height, width } = props;
  const classes = Styles();
  const widgetCoordinatesSelector = useSelector(
    (state) => state.getWidgetCoordinates
  );

  let valuesArr = [];
  let colorArr = [];
  let idArr = [];
  dataSet.forEach((temp, i) => {
    valuesArr.push(temp.value);
    colorArr.push(temp.color);
    idArr.push(temp.id);
  });
  const d3Chart = useRef(null);

  function hBarChart(width, height) {
    try {
      let margin = { top: 40, right: 40, bottom: 50, left: 100 };
      const w = width - margin.left - margin.right;
      const h = height - margin.top - margin.bottom;
      const svg = d3
        .select(d3Chart.current)
        .attr("width", w + margin.left + margin.right)
        .attr("height", h + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .style("backgroud", "#d3d3d3")
        .style("margin-top", "50")
        .style("overflow", "visible"); // for axis(outside) visible

      const xScale = d3
        .scaleLinear()
        .domain([0, max(dataSet, (d) => d.value)])
        .range([0, w]);
      const yScale = d3
        .scaleBand()
        .domain(dataSet.map((d) => STATS_KEY_VALUES[d.id]))
        .range([0, h])
        .padding(0.2);

      const xAxis = d3
        .axisBottom(xScale)
        .ticks(dataSet.length)
        .tickSize(0)
        .tickPadding([10]);
      const yAxis = d3.axisLeft(yScale).tickSize(0).tickPadding([6]);

      const xAxisGrid = d3
        .axisBottom(xScale)
        .tickSize(-h)
        .tickFormat("")
        .ticks(dataSet.length);

      let colors = d3.scaleOrdinal().domain(idArr).range(colorArr);
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + h + ")")
        .attr("stroke", "#D3DAE3")
        .attr("stroke-width", "1")
        .attr("opacity", ".2")
        .call(xAxisGrid);

      let tooltip = d3
        .select(props.activeTooltip)
        .append("div")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("width", "142px")
        .style("height", "auto")
        .style("border-radius", "6px")
        .style("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.08)")
        .style("border", "1px solid #D3DAE3")
        .style("background-color", "white")
        .style("visibility", "hidden");

      svg
        .selectAll(".bar")
        .data(dataSet)
        .join("rect")
        .attr("class", "bar")
        .attr("x", xScale)
        .attr("y", (d, i) => yScale(STATS_KEY_VALUES[d.id]))
        .attr("width", (d, i) => xScale(d.value))
        .attr("height", yScale.bandwidth())
        .attr("fill", function (d, i) {
          return colors(d);
        })
        .on("mouseover", function () {
          return tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (event, d) {
          tooltip.html(
            `<div class=${classes.widgetToolTip} >` +
              `<div class=${classes.widgetToolTipTop} >` +
              `<div class=${classes.widgetToolTipCircle} style="background-color:${d.color}">` +
              "</div>" +
              STATS_KEY_VALUES[d.id] +
              "</div>" +
              `<div class=${classes.widgetToolTipBottom} >` +
              "Total: " +
              d.value +
              "</div>" +
              "</div>"
          );

          let x, y;
          let maxHeight = (document.documentElement.clientHeight / 100) * 90;
          let maxWidth = (document.documentElement.clientWidth / 100) * 90;
          if (event.pageY > maxHeight) {
            y = 80;
          } else {
            y = 10;
          }

          if (event.pageX > maxWidth) {
            x = 150;
          } else {
            x = -10;
          }

          tooltip
            .style("top", event.pageY - y + "px")
            .style("left", event.pageX - x + "px");
        })
        .on("mouseout", function () {
          return tooltip.style("visibility", "hidden");
        });

      svg.append("g").call(xAxis).attr("transform", `translate(0,${h})`);
      svg.append("g").call(yAxis);

      svg.selectAll(".domain").attr("stroke", "#D3DAE3");
    } catch (error) {}
  }

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;

    const resizeHeight = widgetCoordinatesSelector?.payload?.h;
    const resizeWidth = widgetCoordinatesSelector?.payload?.w;
    const resizeIndex = widgetCoordinatesSelector?.payload?.i;
    if (
      (resizeWidth < documentWidth || resizeHeight < documentHeight) &&
      resizeIndex === index?.toString()
    ) {
      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        d3.select(d3Chart.current).selectAll("*").remove();
        hBarChart(resizeWidth, resizeHeight * 0.7);
      }, 50);
    }
  }, [widgetCoordinatesSelector]);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;
    if (width < documentWidth && height < documentHeight) {
      d3.select(d3Chart.current).selectAll("*").remove();
      hBarChart(width, height);
    }
  }, [dataSet]);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    const documentWidth = document.body.clientWidth;
    if (width < documentWidth && height < documentHeight) {
      d3.select(d3Chart.current).selectAll("*").remove();
      hBarChart(width, height);
    }
    return () => {
      d3.select(props.activeTooltip).selectAll("*").remove();
    };
  }, []);

  return (
    <Box id="tooltipbar">
      {dataSet.length > 0 ? <svg ref={d3Chart}></svg> : null}
    </Box>
  );
};
