import { Dialog, Slide } from "@mui/material";
import React, { useState } from "react";
import parse from "html-react-parser";
import IconComponent from "../../../components/Icon/IconComponent";
import Typography from "../../../components/Typography/Typography";
import {
  ACTIONS,
  BASE,
  BORDER,
  BRAND,
  GREY,
  HELP,
  SUCCESS,
  WARNING,
} from "../../../utils/constants/colors";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import { borderRadius } from "../../../utils/constants/UI/uiConstants";
import Image from "../../../components/Image/Image";
import { SMART_TOY, TOY_ICON } from "../../../utils/constants/assets";
import { STRINGS } from "./strings";
import classes from "../components/markComponents.module.css";
import { Button } from "../../../components/Buttons";
import TypographyInline from "../../../components/Typography/TypographyInline";
import InputField from "../../../components/InputFields/InputField";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  selectData,
} from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../Apis";
import {
  authEndpoints,
  meetingsEndpoint,
  templateEndpoints,
} from "../../../utils/constants/httpConstants";
import { nameValidation } from "../../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "../../../components/InputFields/TextArea";
import GenerateModal from "./Template/GenerateModal";
import { checkLogin } from "../../../redux/actions/authActions";
import {
  aiTextValidation,
  aiTextValidationData,
  aiWebsiteValidation,
} from "../../../utils/validations/inputValidations";
import EventEmitter from "../../../utils/emitter/EventEmitter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UiDialog = ({ open, setOpen, getData, type }) => {
  const styles = {
    paeprContainer: {
      height: "100%",
      width: "62.500vw",
      margin: "0px",
      maxHeight: "100vh",
      maxWidth: "100vw",
      marginLeft: "auto",
      borderRadius: "none",
      marginRight: "0px ",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 20px",
      height: "68px",
      background: HELP.W_900,
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "3px",
      borderRadius: borderRadius.primary,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: ACTIONS.DISABLED,
      },
    },
    bodyContainer: {
      padding: "16px",
      height: "calc(100% - 62px)",
    },
    footerContainer: {
      padding: "16px 20px",
      //   borderTop: `1px solid ${BORDER.DEFAULT}`,
    },
    rootDialog: {
      backgroundColor: "rgba(0, 0, 0, 0.5) !important", // Set your desired background color here
    },
    displayFlex: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    aicon: {
      width: "30px",
      height: "30px",
    },
    btnCredit: {
      border: "none",
      backgroundColor: WARNING.W_400,
      color: BASE.BLACK,
      padding: "8px 14px 8px 14px",
      borderRadius: "6px",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
    },
    arlFont: {
      fontFamily: "Arial",
      fontSize: "14px",
      lineHeight: "20px",
    },
    padSett: {
      paddingTop: "8px",
    },
    rightFoot: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      // padding: "16px",
      gap: "8px",
    },
    footerData: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
    },
  };

  const [offerings, setOfferings] = useState("");
  const [challenges, setChallenges] = useState("");
  const [solutions, setSolutions] = useState("");
  const [context, setContext] = useState("");
  const [loading, setLoading] = useState(false);
  const [validations, setValidations] = useState({});
  const [websiteUrlValidate, setWebsiteUrlValidate] = useState({});
  const [meetingUrlValidate, setMeetingUrlValidate] = useState({});
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [valueState, setValueState] = useState([]);
  const [idVal, setIdVal] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [meeting, setMeeting] = useState([]);
  const [idMeet, setIdMeet] = useState([]);
  const [valMeet, setValMeet] = useState([]);
  const [regenOpen, setRegenOpen] = useState(false);
  const [btnLOad, setBtnLoad] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingReq, setLoadingReq] = useState(false);
  const [regenData, setRegenData] = useState({});
  const userDet = useSelector((state) => state.userDetails);
  const [aiResponse, setAiResponse] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addToEditor = () => {
    setLoading(true);
    let response = document?.getElementById("unique_dialog")?.innerHTML;
    document.getElementById("editor").innerHTML = response;
    getData(subject);
    setOpen(false);
    setLoading(false);
    emptyState();
  };
  const emptyState = () => {
    setChallenges("");
    setValidations({ challenges: "", offerings: "", solutions: "" });
    setOfferings("");
    setAiResponse("");
    setIdMeet([]);
    setIdVal([]);
    setWebsiteUrl("");
    setMeeting([]);
    setSolutions("");
    setValMeet([]);
    setValueState([]);
    setSubject("");
    setMessage("");
    setContext("");
  };

  const apiCall = async (payload, isLoad) => {
    try {
      isLoad === 1 && setBtnLoad(true);
      setIsLoading(true);
      const response = await postRequest(
        navigate,
        templateEndpoints.chatGpt,
        payload
      );
      var removeText = response?.data?.data?.choices[0]?.text.slice(
        response?.data?.data?.choices[0]?.text.indexOf("\n")
      );
      // Split the HTML string into lines
      var lines = removeText.split("\n");
      // Extract the subject (assuming it's the first non-empty line)
      var subject1 = lines.find((line) => line.trim() !== "");
      // Extract the message (assuming it's everything after the subject)
      var message1 = lines.slice(lines.indexOf(subject1) + 1).join("\n");
      const colonIndex = subject1.indexOf(":");

      if (colonIndex !== -1) {
        const newText = subject1.substring(colonIndex + 1).trim();
        setSubject(newText);
      }
      let arr = message1.split("\n");
      let updateA = arr.slice(0, -3);
      let finalData = updateA?.map((itm) => {
        return itm + "\n";
      });
      setMessage(finalData.join(" ").slice(1));
      if (response?.status === 200) {
        dispatch(checkLogin(navigate));
      }
      setAiResponse(subject1 + message1);
      setIsLoading(false);
    } catch (e) {
    } finally {
      setIsLoading(false);
      setBtnLoad(false);
    }
  };
  const generateResult = (e, isLoad) => {
    e.preventDefault();
    setSubject("");
    const validateChallenge = aiTextValidation(challenges);
    const validateOffering = aiTextValidationData(offerings);
    // const validateSolution = aiTextValidation(solutions);
    const validateWebsite = aiWebsiteValidation(websiteUrl);
    const validateMeeting = aiTextValidation(valMeet[0]);
    setRegenData({
      offerings: offerings,
      challenges: challenges,
      // solutions:solutions,
      websiteUrl: websiteUrl,
      context: context,
      meeting: valMeet[0],
      idVal: idVal[0],
    });
    if (validateChallenge.isValid && validateOffering.isValid) {
      setValidations({ challenges: "", offerings: "", solutions: "" });
      let payload;
      if (idVal[0] === 1) {
        if (validateWebsite.isValid) {
          setWebsiteUrlValidate({ website: "" });
          payload = {
            prompt: `Act as a marketing assistant. Write a compelling and effective Email Template about ${offerings}.Also Include the following :
              * [Challenges faced by the customer] ${challenges}
              [Call to Action] -
              *Website URL for the reference so that customers can lookup for reference regarding the solutions provided <a href= http://${websiteUrl}> http://${websiteUrl} </a>
              [Context to Add] ${context}
              *Provide additional context to include for the solution`,
          };
          apiCall(payload, isLoad);
        } else {
          setWebsiteUrlValidate({ website: validateWebsite.message });
        }
      } else if (idVal[0] === 2) {
        if (validateMeeting.isValid) {
          setMeetingUrlValidate({ meeting: "" });
          payload = {
            prompt: `Act as a marketing assistant. Write a compelling and effective Email Template about ${offerings}.Also Include the following :
          * [Challenges faced by the customer] ${challenges}
          [Call to Action] -
          *Book a Meeting with the customer <a href= http://www.${valMeet[0]} >http://www.${valMeet[0]}</a>
          [Context to Add] ${context}
          *Provide additional context to include for the solution`,
          };
          apiCall(payload, isLoad);
        } else {
          setMeetingUrlValidate({ meeting: validateMeeting.message });
        }
      } else {
        payload = {
          prompt: `Act as a marketing assistant. Write a compelling and effective Email Template about ${offerings}.Also Include the following :
          * [Challenges faced by the customer] ${challenges}
          [Context to Add] ${context}
          *Provide additional context to include for the solution`,
        };
        apiCall(payload, isLoad);
      }
    } else {
      setValidations({
        challenges: validateChallenge.message,
        offerings: validateOffering.message,
        // solutions: validateSolution.message,
      });
    }
  };

  const handleFormat = (value) => {
    const emailLines = value.split("\n").map((line, index) => {
      return (
        <div key={index} style={styles.arlFont}>
          {parse(line)}
          <br />
        </div>
      );
    });
    return emailLines;
  };

  const handleMeeting = async (e) => {
    if (e.id[0] === 2) {
      setWebsiteUrl("");
      const response = await getRequest(
        navigate,
        meetingsEndpoint.allMeetingLink
      );
      const list = response?.data?.data?.map(function (item) {
        return { id: item._id, label: item.schedule_page_link };
      });
      setMeeting(list);
    } else if (e.id[0] === 3) {
      setWebsiteUrl("");
      setValMeet([]);
    } else {
      setValMeet([]);
    }
  };

  const handleClose = (event) => {
    if (event.target === event.currentTarget) {
      setOpen(false);
      emptyState();
    }
  };

  const openRegenConfirmation = () => {
    setRegenOpen(!regenOpen);
  };

  const copyTextToClipboard = () => {
    if (message) {
      navigator?.clipboard?.writeText(message);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  const handleRequestForCredits = async () => {
    try {
      setLoadingReq(true);
      const response = await postRequest(
        navigate,
        authEndpoints.RequestForCredits
      );
      // console.log("RSQ",response)
      if (response?.status === 201) {
        EventEmitter.emit(EMITTER_SUCCESS, STRINGS.SUCCESS_REQ);
      }
      setLoading(false);
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        error?.data?.message || STRINGS.ERR_MESSAGE
      );
      setLoadingReq(false);
    } finally {
      setLoadingReq(false);
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: styles.paeprContainer,
      }}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div style={styles.headerContainer}>
        <div style={styles.displayFlex}>
          <Image src={TOY_ICON} alt="" style={styles.aicon} />
          <div style={styles.padSett}>
            <Typography
              color={BASE.WHITE}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.AI_ASSISTANT}
              size="xl"
              id={"assist"}
            />
          </div>
        </div>
        <div style={styles.displayFlex}>
          <button style={styles.btnCredit}>
            {`${STRINGS.CREDITS_AVAILABLE} ${userDet?.payload?.available_credits}`}
          </button>
          <div style={styles.iconContainer}>
            <IconComponent
              color={BASE.WHITE}
              fontSize={fontSize.DXS}
              iconLabel={ICON_LABELS.CLOSE_BUTTON}
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
      <div className={classes.aiContainer}>
        <form className={classes.aiForm} onSubmit={(e) => generateResult(e, 1)}>
          <div id="modal_body" className={classes.aiLeftSection}>
            <div className={classes.aiGapInside}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={`${STRINGS.DEFINE_TARGET}*`}
              />
              <TextArea
                value={offerings}
                variant={validations.offerings ? "error" : "default"}
                errormessage={validations.offerings}
                onChange={(e) => setOfferings(e?.target?.value)}
                placeholder={STRINGS.IDENTIFY_YOUR}
                isResize={true}
              />
            </div>
            <div className={classes.aiGapInside}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={`${STRINGS.OUTLINE_SOLUTIONS}*`}
              />
              <TextArea
                value={challenges}
                onChange={(e) => setChallenges(e?.target?.value)}
                placeholder={STRINGS.ADDRESS_CHALLENGES}
                variant={validations.challenges ? "error" : "default"}
                errormessage={validations.challenges}
                isResize={true}
              />
            </div>
            <div className={classes.aiGapInside}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={`${STRINGS.CURRENT_PROMO}`}
              />
              <TextArea
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e?.target?.value)}
                placeholder={STRINGS.DO_YOU_HAVE}
                variant={validations.challenges ? "error" : "default"}
                errormessage={validations.challenges}
                isResize={true}
              />
            </div>
            <div className={classes.aiGapInside}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.ADD_INFO}
              />
              <TextArea
                // size={"md40"}
                value={context}
                onChange={(e) => setContext(e?.target?.value)}
                placeholder={STRINGS.FEEL_FREE}
                isResize={true}
              />
            </div>
            <div>
              <div>
                <Button
                  label={STRINGS.GENERATE_RESPONSE}
                  size={"lg44"}
                  variant={"primary"}
                  isLoading={btnLOad}
                  onClick={() => generateResult(1)}
                  disabled={parseInt(userDet?.payload?.available_credits) < 10}
                />
              </div>
              <div className={classes.aiTextDesign}>
                <div>
                  <IconComponent
                    iconLabel={ICON_LABELS.INFO}
                    color={SUCCESS.W_500}
                    fontSize={fontSize.XS}
                  />
                </div>
                <div>
                  <TypographyInline
                    size={"xs"}
                    color={SUCCESS.W_500}
                    fontWeight={fontWeight.REGULAR}
                    label={`10 ${STRINGS.CREDITS} / ${STRINGS.AI_RESPONSE}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className={classes.aiRightSection}>
          <div>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.PREVIEW}
            />
          </div>
          {subject && (
            <div className={classes.subjectInside} id="modal_body">
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={`${STRINGS.SUBJECT2}`}
              />
              <TypographyInline
                size={"md"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={`${subject}`}
              />
            </div>
          )}
          <div
            id="unique_dialog"
            className={
              subject ? classes.aiRightInside : classes.aiRightNoContent
            }
          >
            {!subject ? (
              <div className={classes.centerData}>
                {isLoading ? (
                  <div className={classes.dflx}>
                    {" "}
                    <TypographyInline
                      size={"dlg"}
                      color={BORDER.DARK}
                      fontWeight={fontWeight.BOLD}
                      label={STRINGS.GENERATING}
                    />
                    <div className={classes.dots7}></div>
                  </div>
                ) : (
                  <>
                    <Image src={SMART_TOY} alt="" />
                    <div className={classes.paddingTopAI}>
                      <TypographyInline
                        size={"xl"}
                        color={BRAND.SECONDARY}
                        fontWeight={fontWeight.BOLD}
                        label={STRINGS.AI_ASSISTANT}
                      />
                    </div>
                    <div className={classes.paddingTopAIData}>
                      <TypographyInline
                        size={"sm"}
                        color={GREY.SECONDARY}
                        fontWeight={fontWeight.REGULAR}
                        label={STRINGS.GET_THE_BEST}
                      />
                      <TypographyInline
                        size={"sm"}
                        color={GREY.SECONDARY}
                        fontWeight={fontWeight.REGULAR}
                        label={STRINGS.INPUT_LEFT}
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className={classes.padSett}>{handleFormat(message)}</div>
            )}
          </div>
          {subject && (
            <div className={classes.footerPreviewInside}>
              <div>
                <Button
                  label={STRINGS.COPY_ANSWER}
                  LeftIcon={ICON_LABELS.CONTENT_COPY}
                  variant={"text"}
                  size={"sm36"}
                  onClick={copyTextToClipboard}
                />
              </div>
              {isCopied && (
                <div className={classes.copyFlex}>
                  <IconComponent
                    fontSize={fontSize.SM}
                    color={SUCCESS.W_500}
                    iconLabel={ICON_LABELS.DONE}
                  />
                  <TypographyInline
                    size={"xs"}
                    color={SUCCESS.W_400}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.COPIED}
                  />
                </div>
              )}
              <div>
                <Button
                  label={STRINGS.REGENERATE}
                  LeftIcon={ICON_LABELS.CACHED}
                  variant={"text"}
                  size={"sm36"}
                  onClick={openRegenConfirmation}
                  disabled={parseInt(userDet?.payload?.available_credits) < 10}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={styles.footerData}>
        <div>
          {parseInt(userDet?.payload?.available_credits) < 10 ? (
            <div>
              <Button
                label={STRINGS.REQ_CRED}
                size={"sm36"}
                variant={"primary"}
                onClick={handleRequestForCredits}
                isLoading={loadingReq}
                // onClick={addToEditor}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div style={styles.rightFoot}>
          <div>
            <Button
              label={STRINGS.CANCEL}
              variant={"ghost"}
              size={"sm36"}
              onClick={() => {
                setOpen(false);
                emptyState();
              }}
            />
          </div>
          <div>
            <Button
              label={`${STRINGS.ADD_TO} ${type}`}
              size={"sm36"}
              variant={"primary"}
              isLoading={loading}
              disabled={!subject}
              onClick={addToEditor}
            />
          </div>
        </div>
      </div>
      {regenOpen && (
        <GenerateModal
          open={regenOpen}
          setOpen={setRegenOpen}
          challenges={challenges}
          offerings={offerings}
          solutions={solutions}
          idVal={idVal}
          data={regenData}
          websiteUrl={websiteUrl}
          context={context}
          valMeet={valMeet}
          setMessage={setMessage}
          setSubject={setSubject}
          setIsLoading={setIsLoading}
          // setCreditAvailable={setCreditAvailable}
          // creditAvailable={creditAvailable}
        />
      )}
    </Dialog>
  );
};

export default UiDialog;
