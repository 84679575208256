import React from "react";
import { Box } from "@mui/material";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { BASE, GREY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { STEP_TYPES } from "../../../../utils/constants";
import { STRINGS } from "../strings";
import IconComponent from "../../../../components/Icon/IconComponent";
import { Button } from "../../../../components/Buttons";
import classes from "../markComponents.module.css";

const EachStepLayout = ({
  operation = true,
  step,
  stepIndex,
  stepDetail = { stepNumber: 0, stepType: 1 },
  headerActions = [],
  body,
  type,
  data,
  footer = { left: "", right: <Box></Box> },
}) => {

  console.log("BDD",body)
  const HEADER_CLASSES = {
    1: "emailStepHeader",
    2: "callStepHeader",
    3: "todoStepHeader",
  }
  const COUNTER_CLASSES = {
    1:"emailStepCounter",
    2:"callStepCounter",
    3:"todoStepCounter",
  }

  return (
    <Box className={classes.eachStepContainer}>
      <Box className={classes[HEADER_CLASSES[stepDetail?.stepType]]}>
        <Box className={classes.stepLeft}>
          <Box className={classes.stepNumberChip}>
            <TypographyInline
              className={classes[COUNTER_CLASSES[stepDetail?.stepType]]}
              size={"xs"}
              color={BASE.WHITE}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.STEP + " " + stepDetail.stepNumber}
            />
          </Box>
          <Box className={classes.stepNumberChip}>
            <TypographyInline
              size={"sm"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STEP_TYPES[stepDetail.stepType]}
            />
          </Box>
        </Box>
        <Box className={classes.stepLeft}>
          {headerActions.map((item, index) => {
            return (
              <Box key={index} className={classes.stepBorder}>
                {operation &&
                <Button
                  size={"sm36"}
                  LeftIcon={item.icon}
                  variant="text"
                  onClick={() => item.action(step, stepIndex)}
                  label={item.name}
                  disabled={!operation}
                />
                }
                {(index < 2 && type !=="camp") &&
                ( operation &&
                  <div className={classes.vl}>
                </div>
                )
                }
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className={classes.bodyContainer}>{body}</Box>
      <Box className={data?.length > 1 ? classes.footerContainer : classes.footerContainerNoTop}>
        <Box className={classes.stepLeft}>
          <Box className={classes.stepNumberChip}>
            {footer.left && (
              <IconComponent
                color={GREY.TERTIARY}
                fontSize={fontSize.LG}
                iconLabel={ICON_LABELS.TIMER}
              />
            )}
          </Box>
          <Box className={classes.stepNumberChip}>
            <TypographyInline
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={footer.left}
            />
          </Box>
        </Box>
        <Box>{footer.right}</Box>
      </Box>
    </Box>
  );
};

export default EachStepLayout;
