import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../../strings";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { Button } from "../../../../components/Buttons";
import classes from "./CrmComp.module.css";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { useNavigate } from "react-router";
import { EMITTER_SUCCESS } from "../../../../utils/constants";
import { putRequest } from "../../../../Apis/apiWrapper";
import { nameValidation } from "../../../../utils/validations";
import { getSalesContactsList } from "../../../../redux/actions/salesTableAction";
import { filterQuery } from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";

const AssignModal = ({ open, setOpen, record_ids, setSearchField }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [valueState, setValueState] = useState([]);
  const [idVal, setIdVal] = useState([]);
  const [data, setData] = useState([]);
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const querySelector = filterQuery(
    useSelector((state) => state?.allFilters?.payload)
  );

  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  useEffect(() => {
    let user_list = tenantUsersListSelector?.map((item) => {
      return {
        label: `${item?.first_name} ${item?.last_name}`,
        id: item?._id,
      };
    });
    setData(user_list);
  }, [open]);

  const assignMethod = async () => {
    setLoading(true);
    const validatedAssigneeName = nameValidation(valueState);

    const payload = {
      query: {
        ids: record_ids,
        assignee_id: idVal[0],
      },
    };

    if (validatedAssigneeName?.isValid) {
      try {
        const URL = crmEndpoints?.assignBulk;
        const response = await putRequest(navigate, URL, payload);

        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setLoading(false);
        dispatch(getSalesContactsList(querySelector, navigate));
        setSearchField("");
        setOpen(false);
      } catch (error) {
        setLoading(false);
        setOpen(false);
      }
    } else {
      setLoading(false);

      setInputError({
        first_name: validatedAssigneeName?.message,
      });
    }
  };
  const CancelAssign = () => {
    setOpen(false);
    setInputError({});
    setIdVal([]);
    setValueState([]);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.ASSIGN,
      label: `${STRINGS.BULK_ASSIGN} ${record_ids?.length} ${STRINGS.SMALL_CONTACTS}`,
    },
  };
  const modalBodyData = (
    <div className={classes.assignModalBody}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.ASSIGN_TO}
      />

      <AutoCompleteCustom
        variant={inputError?.first_name ? STRINGS.ERROR : STRINGS.DEFAULT}
        errormessage={inputError?.first_name}
        LeftIcon=""
        valueState={[valueState]}
        setValueState={setValueState}
        idState={idVal}
        setIdState={setIdVal}
        items={data}
        papermenuclass={classes.paperMenu}
        size={"md40"}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => CancelAssign()}
      />
      <Button
        label={STRINGS.ASSIGN}
        variant={"primary"}
        size={"sm36"}
        onClick={() => assignMethod()}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      variant={"primary"}
      close={() => CancelAssign()}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default AssignModal;
