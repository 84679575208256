import React, { useEffect, useState } from "react";
import CustomDialog from "../../../../components/Dialog/CustomDialog";
import { STRINGS } from "../strings";
import { Button } from "../../../../components/Buttons";
import classes from "./DealsComp.module.css";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import InputField from "../../../../components/InputFields/InputField";
import TextArea from "../../../../components/InputFields/TextArea";
import { HtmlEditor } from "../../../../components";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import { replaceKeyInArrayOfObj } from "../../../../utils/helpers";
import { nameValidation } from "../../../../utils/validations";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { BRAND, ERROR, GREY } from "../../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
import { getRequest, postRequest } from "../../../../Apis";
import {
  crmEndpoints,
  dealsEndpoints,
  formsEndpoints,
  leadsEndpoints,
} from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS, dealPriorityList } from "../../../../utils/constants";
import { PROFILE_PATH } from "../../../../utils/constants/routes";
import { getDealsList } from "../../../../redux/actions/dealsActions";

const AddDealDialog = ({
  open = false,
  setOpen = () => { },
  getContactDetails = () => { },
  getDealsListed = () => { },
  company_id,
  contact_id,
  updateDeal = false,
  pipeline_id = "",
  pipeline_value = "",
  clickedStage = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );
  const getPipelineListSelector = useSelector(
    (state) => state.getPipelineList?.data
  );
  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );
  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );
  const getSalesContactListSelector = useSelector(
    (state) => state?.getSalesContactList
  );
  const userDetailsSelector = useSelector((state) => state?.userDetails);

  const [inputData, setInputData] = useState({});
  const [inputDataId, setInputDataId] = useState({});
  const [inputError, setInputError] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [addDealLoading, setAddDealLoading] = useState(false);
  const [getUsers, setGetUsers] = useState([]);
  const [usersId, setUsersId] = useState([]);
  const [usersValue, setUsersValue] = useState([]);
  const [associatedContact, setAssociatedContact] = useState([]);
  const [associatedCompany, setAssociatedCompany] = useState([]);
  const [usersStageId, setUsersStageId] = useState([]);
  const [usersStageValue, setUsersStageValue] = useState([]);
  const [updatedItemArr, setUpdatedItemsArr] = useState([]);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const [requiredOrder, setRequiredOrder] = useState({});
  const [labelDealOwner, setLableDealOwner] = useState("");
  const [priorityId, setPriorityId] = useState([dealPriorityList?.[1]?.id]);
  const [priorityLabel, setPriorityLabel] = useState([
    dealPriorityList?.[1]?.label,
  ]);

  const validationHandler = (inputData) => {
    // getting all the required fields id and storing it into array.
    let required_fields = [];
    let error_arr = [];
    let input_error = { ...inputError };
    let static_required = ["deal_name", "pipeline_name"];

    Object.keys(requiredOrder)?.forEach((element, index) => {
      if (requiredOrder?.[element]) {
        required_fields.push(element);
      }
    });

    // loop on required ids for checking validation according to field_type
    required_fields?.forEach((element, index) => {
      if (!static_required.includes(element)) {
        let validate = nameValidation(inputData?.[element]);

        if (validate?.isValid) {
          error_arr?.filter((item) => item !== element);
          input_error = { ...input_error, [element]: "" };
        } else {
          error_arr = [...error_arr, element];
          input_error = { ...input_error, [element]: validate?.message };
        }
      }
    });

    // storing the error into state for displaying error for deal name and pipeline name
    const validatedDealName = nameValidation(inputData?.deal_name);
    const ValidatedPipelineName = nameValidation(usersValue?.toString());
    // validate deal_name
    if (validatedDealName?.isValid) {
      error_arr?.filter((item) => item !== "deal_name");
      input_error = { ...input_error, deal_name: "" };
    } else {
      error_arr = [...error_arr, "deal_name"];
      input_error = { ...input_error, deal_name: validatedDealName?.message };
    }
    // validate pipeline name
    if (ValidatedPipelineName?.isValid) {
      error_arr?.filter((item) => item !== "pipeline_name");
      input_error = { ...input_error, pipeline_name: "" };
    } else {
      error_arr = [...error_arr, "pipeline_name"];
      input_error = {
        ...input_error,
        pipeline_name: ValidatedPipelineName?.message,
      };
    }
    setInputError({ ...input_error });

    // return value according to error key check in error_arr.
    if (error_arr?.length) {
      return false;
    } else {
      return true;
    }
  };

  const addDealHandler = async () => {
    if (validationHandler(inputData)) {
      try {
        let payload = {
          query: {
            ...inputData,
            deal_name: inputData?.deal_name?.trim(),
            deal_owner_name:
              inputDataId[STRINGS.DEAL_OWNER_SMALL] === undefined
                ? labelDealOwner[0]?.label
                : inputDataId[STRINGS.DEAL_OWNER_SMALL],
            pipeline_name: usersValue[0],
            pipeline_id: usersId[0],
            stage_name: usersStageValue[0],
            stage_id: usersStageId[0],
            deal_priority: priorityId?.toString(),
          },
        };
        if (company_id?.length) {
          payload.query.company_id = [company_id];
        }
        if (contact_id?.length) {
          payload.query.associated_contact = [contact_id];
        }
        setAddDealLoading(true);
        const response = await postRequest(
          navigate,
          dealsEndpoints.createDeals,
          payload
        );
        getContactDetails();
        if (updateDeal) {
          getDealsListed();
        } else if (pipeline_id === "All") {
          await dispatch(
            getDealsList(
              {
                query: {
                  ...allDynamicFiltersSelector,
                },
              },
              navigate
            )
          );
        } else {
          await dispatch(
            getDealsList(
              {
                query: {
                  ...allDynamicFiltersSelector,
                },
              },
              navigate,
              {
                pipeline_id: pipeline_id,
              }
            )
          );
        }
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

        setAddDealLoading(false);
        setOpen(false);
      } catch (error) {
        setAddDealLoading(false);
        setInputError({ ...error?.data?.data?.errors });
      }
    }
  };

  const getValuesOfDropdown = (prop) => {
    if (prop?.id === STRINGS.DEAL_OWNER_SMALL) {
      let users_list = tenantUsersListSelector?.map((item) => {
        return {
          label: `${item?.first_name} ${item?.last_name}`,
          id: item?._id,
        };
      });
      return users_list;
    } else if (prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL) {
      let contacts_list = associatedContact?.map((item) => {
        return {
          label:
            item?.first_name?.length || item?.last_name?.length
              ? `${item?.first_name || ""} ${item?.last_name || ""}`
              : item?.email?.length
                ? `${item?.email}`
                : null,
          id: item?._id,
        };
      });

      return contacts_list;
    } else if (prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL) {
      let company_list = associatedCompany?.map((item) => {
        return {
          label: item?.company_name,
          id: item?._id,
        };
      });

      return company_list;
    } else {
      return replaceKeyInArrayOfObj(prop?.value, "labelValue", "label");
    }
  };

  // associated contact api handler
  const searchHandler = async (event) => {
    const payload = {
      search: event?.target?.value || "",
    };

    if (updateDeal && contact_id?.length) {
      payload.record_ids = [contact_id];
    } else {
      payload.record_ids = [];
    }
    const response = await postRequest(
      navigate,
      crmEndpoints.getAssociateContact,
      payload
    );

    if (updateDeal) {
      const associatedFilter = response?.data?.data?.records?.filter(
        (item) => item?._id === contact_id
      );
      // storing deal owner
      const dealOwner = tenantUsersListSelector?.filter(
        (item) => item?._id === userDetailsSelector?.payload?._id
      );
      let users_list = dealOwner?.map((item) => {
        return {
          label: `${item?.first_name} ${item?.last_name}`,
          id: item?._id,
        };
      });

      setInputData({
        ...inputData,
        [STRINGS.ASSOCIATED_CONTACT_SMALL]: associatedFilter?.[0]?._id,
        [STRINGS.DEAL_OWNER_SMALL]: users_list?.[0]?.id,
      });
      setInputDataId({
        ...inputDataId,
        [STRINGS.ASSOCIATED_CONTACT_SMALL]: `${associatedFilter?.[0]?.first_name || ""
          } ${associatedFilter?.[0]?.last_name || ""}`,
        [STRINGS.DEAL_OWNER_SMALL]: users_list?.[0]?.label,
      });
    }
    setAssociatedContact(response?.data?.data?.records);
  };

  // associated company api handler
  const associatedCompanyApiHandler = async (event) => {
    const payload = {
      search: event?.target?.value || "",
    };

    if (updateDeal && contact_id?.length) {
      payload.record_ids = [contact_id];
    } else {
      payload.record_ids = [];
    }

    const response = await postRequest(
      navigate,
      dealsEndpoints.getAssociatedCompany,
      payload
    );

    if (updateDeal) {
      const associatedFilter = response?.data?.data?.records?.filter(
        (item) => item?._id === contact_id
      );

      setInputData({
        ...inputData,
        [STRINGS.ASSOCIATED_COMPANY_SMALL]: associatedFilter?.[0]?._id || "",
      });
      setInputDataId({
        ...inputDataId,
        [STRINGS.ASSOCIATED_COMPANY_SMALL]: `${associatedFilter?.[0]?.company_name || ""}`,
      });
    }
    setAssociatedCompany(response?.data?.data?.records);
  };

  const dialogHeader = {
    heading: {
      id: "head",
      label: STRINGS.ADD_DEAL,
    },
  };

  const dialogFooter = (
    <div className={classes.addDialogFooter}>
      <div>
        <Button
          label={STRINGS.CANCEL}
          size="sm36"
          variant={"ghost"}
          onClick={() => setOpen(!open)}
        />
      </div>
      <div>
        <Button
          label={STRINGS.ADD_DEAL}
          size="sm36"
          variant={"primary"}
          onClick={addDealHandler}
          isLoading={addDealLoading}
        />
      </div>
    </div>
  );

  const type_id_components = (prop, index) => {
    switch (prop?.field_type[0]) {
      case STRINGS.DATE_PICKER:
        return (
          <CustomDatePicker
            size={"sm36"}
            value={inputData[prop?.id] || new Date()}
            onChange={(dateValue) =>
              setInputData({ ...inputData, [prop?.id]: dateValue })
            }
          />
        );

      case STRINGS.NUMBER:
        return (
          <InputField
            size={"sm36"}
            type={"number"}
            LeftIcon={
              prop?.id === STRINGS.DEAL_AMOUNT_SMALL
                ? ICON_LABELS.ATTACH_MONEY
                : ""
            }
            onChange={(e) =>
              prop?.id === STRINGS.DEAL_AMOUNT_SMALL
                ? setInputData({
                  ...inputData,
                  [prop?.id]: e.target.value >= 1 ? e.target.value : "",
                })
                : setInputData({
                  ...inputData,
                  [prop?.id]: e.target.value,
                })
            }
            value={inputData[prop?.id]}
            placeholder={`Enter a ${prop?.property_name}`}
            variant={
              inputError[prop?.id]?.length ? STRINGS.ERROR : STRINGS.DEFAULT
            }
            errormessage={inputError[prop?.id]}
          />
        );

      case STRINGS.MULTI_LINE_TEXT_INPUT:
        return (
          <div>
            <TextArea
              rows={"5"}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  [prop?.id]: e.target.value,
                })
              }
              value={inputData[prop?.id]}
              variant={inputError[prop?.id] ? STRINGS.ERROR : STRINGS.DEFAULT}
              errormessage={inputError[prop?.id] || ""}
            />
          </div>
        );

      case STRINGS.RICH_TEXT_EDITOR:
        return (
          <>
            <div
              className={
                inputError[prop?.id]
                  ? classes.htmlEditorError
                  : classes.htmlEditor
              }
            >
              <HtmlEditor
                editorHeight={"12vh"}
                onInput={() => {
                  setInputData({
                    ...inputData,
                    [prop?.id]: document?.getElementById(prop?.id)?.innerHTML,
                  });
                }}
                id={prop?.id}
                isAITagNotVisible
                isAddTagNotVisible
                isMeetingTagNotVisible
                isFocusNotRequired
              />
            </div>
            {inputError[prop?.id] ? (
              <TypographyInline
                label={inputError[prop?.id] || ""}
                color={ERROR.W_500}
                size={"xs"}
                fontWeight={fontWeight.REGULAR}
              />
            ) : null}
          </>
        );

      case STRINGS.SINGLE_SELECT:
        return (
          <>
            <AutoCompleteCustom
              dropdownId={`${index}preview_dropdown`}
              items={getValuesOfDropdown(prop)}
              size={"sm36"}
              variant={
                prop?.id === STRINGS.DEAL_OWNER_SMALL &&
                  userDetailsSelector?.payload?.role === "simple-user"
                  ? "disabled"
                  : inputError[prop?.id]
                    ? STRINGS.ERROR
                    : STRINGS.DEFAULT
              }
              valueState={
                prop?.id === STRINGS.DEAL_OWNER_SMALL ||
                  prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                  prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
                  ? [inputDataId[prop?.id] || ""]
                  : [inputData[prop?.id] || ""]
              }
              setValueState={(data) => {
                prop?.id === STRINGS.DEAL_OWNER_SMALL
                  ? setInputDataId({
                    ...inputDataId,
                    [prop?.id]: data[0],
                  })
                  : prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                    prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
                    ? setInputDataId({
                      ...inputDataId,
                      [prop?.id]: data,
                    })
                    : setInputData({
                      ...inputData,
                      [prop?.id]: data[0],
                    });
              }}
              idState={
                prop?.id === STRINGS.DEAL_OWNER_SMALL ||
                  prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                  prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
                  ? [inputData[prop?.id] || ""]
                  : [inputDataId[prop?.id] || ""]
              }
              setIdState={(data) => {
                prop?.id === STRINGS.DEAL_OWNER_SMALL
                  ? setInputData({
                    ...inputData,
                    [prop?.id]: data[0],
                  })
                  : prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                    prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
                    ? setInputData({
                      ...inputData,
                      [prop?.id]: data,
                    })
                    : setInputDataId({
                      ...inputDataId,
                      [prop?.id]: data[0],
                    });
              }}
              isSearchEnable={
                prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL ||
                prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL
              }
              handleSearch={(event) => {
                if (prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL)
                  searchHandler(event, prop);
                else if (prop?.id === STRINGS.ASSOCIATED_COMPANY_SMALL)
                  associatedCompanyApiHandler(event, prop);
              }}
              isLoading={
                prop?.id === STRINGS.ASSOCIATED_CONTACT_SMALL
                  ? getSalesContactListSelector?.loading
                  : false
              }
            />
            {inputError[prop?.id] ? (
              <TypographyInline
                label={inputError[prop?.id] || ""}
                color={ERROR.W_500}
                size={"xs"}
                fontWeight={fontWeight.REGULAR}
              />
            ) : null}
          </>
        );

      case STRINGS.MULTIPLE_SELECT:
        return (
          <>
            <AutoCompleteCustom
              dropdownId="preview_dropdown"
              items={getValuesOfDropdown(prop)}
              size={"sm36"}
              multiple
              valueState={inputData[prop?.id]}
              setValueState={(data) => {
                setInputData({
                  ...inputData,
                  [prop?.id]: data,
                });
              }}
              idState={inputDataId[prop?.id]}
              setIdState={(data) => {
                setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data,
                });
              }}
              variant={inputError[prop?.id] ? STRINGS.ERROR : STRINGS.DEFAULT}
            />
            {inputError[prop?.id] ? (
              <TypographyInline
                label={inputError[prop?.id] || ""}
                color={ERROR.W_500}
                size={"xs"}
                fontWeight={fontWeight.REGULAR}
              />
            ) : null}
          </>
        );

      default:
        return (
          <InputField
            size={"sm36"}
            placeholder={`Enter a ${prop?.property_name}`}
            LeftIcon={
              prop?.id === STRINGS.DEAL_AMOUNT_SMALL
                ? ICON_LABELS.ATTACH_MONEY
                : ""
            }
            value={inputData[prop?.id]}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            variant={
              inputError[prop?.id]?.length ? STRINGS.ERROR : STRINGS.DEFAULT
            }
            errormessage={inputError[prop?.id]}
          />
        );
    }
  };

  const handleNavigatePipeline = () => {
    navigate(`${PROFILE_PATH}?tab=Pipeline`);
  };

  const handleFUnc = async (id) => {
    const responseData = await getRequest(
      navigate,
      `${crmEndpoints.getPiplelineById}/${id}`
    );

    const updatedItems = replaceKeyInArrayOfObj(
      responseData?.data?.data[0]?.stages,
      "name",
      "label"
    );

    const resolvedItems = replaceKeyInArrayOfObj(updatedItems, "_id", "id");
    setUsersStageId([
      responseData?.data?.data[0]?.stages[parseInt(clickedStage) - 1 || 0]?._id,
    ]);
    setUsersStageValue([
      responseData?.data?.data[0]?.stages[parseInt(clickedStage) - 1 || 0]
        ?.name,
    ]);
    setUpdatedItemsArr(resolvedItems);
  };

  // api handler for get fields order.
  const getFieldsOrder = async () => {
    try {
      const response = await getRequest(navigate, formsEndpoints.getFormsField);
      let columns = [];
      let required_obj = {};
      response?.data?.data?.Deals?.sort(
        (obj1, obj2) => obj1.order - obj2.order
      )?.forEach((element) => {
        columns.push(element?.field_id);
        required_obj = {
          ...required_obj,
          ...{ [element.field_id]: element?.required },
        };
      });
      setRequiredOrder(required_obj);
      setFieldsOrder(columns || []);
    } catch (error) {
      console.log("Error------->>>>>> ", error);
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setFormLoading(true);

        // get form fields order api call
        await getFieldsOrder();

        setGetUsers(getPipelineListSelector);
        if (getPipelineListSelector?.length) {
          if (pipeline_id !== STRINGS.ALL) {
            setUsersValue([pipeline_value]);
            setUsersId([pipeline_id]);
          } else {
            setUsersValue([getPipelineListSelector[0]?.label]);
            setUsersId([getPipelineListSelector[0]?.id]);
          }
        } else {
          setUsersValue([]);
          setUsersId([]);
        }
        setFormLoading(false);
      } catch (error) {
        setFormLoading(false);
      }
    })();

    // storing deal owner
    const dealOwner = tenantUsersListSelector?.filter(
      (item) => item?._id === userDetailsSelector?.payload?._id
    );
    let users_list = dealOwner?.map((item) => {
      return {
        label: `${item?.first_name} ${item?.last_name}`,
        id: item?._id,
      };
    });
    setInputData({
      ...inputData,
      [STRINGS.DEAL_OWNER_SMALL]: users_list?.[0]?.id,
    });
    setInputDataId({
      ...inputDataId,
      [STRINGS.DEAL_OWNER_SMALL]: users_list?.[0]?.label,
    });

    setLableDealOwner(users_list);

    searchHandler();
    associatedCompanyApiHandler();
  }, []);

  useEffect(() => {
    if (usersId?.length) {
      handleFUnc(usersId);
    }
  }, [usersId]);

  return (
    <div>
      <CustomDialog
        open={open}
        close={() => setOpen(false)}
        header={dialogHeader}
        footer={dialogFooter}
        width="23.958vw"
      >
        {formLoading ? (
          <div className={classes.addContactFormLoaderContainer}>
            {Array.from({ length: 20 }).map((index) => {
              return (
                <div key={`addContact${index}`}>
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={"30px"}
                    stackWidth={"100%"}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <form className={classes.inputForm}>
            <div className={classes.labelButtonContainer}>
              <TypographyInline
                label={STRINGS.PIPELINE_NAME}
                color={GREY.QUATINARY}
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
              />
              {!getUsers?.length &&
                userDetailsSelector?.payload?.role === "admin-user" ? (
                <Button
                  label={STRINGS.CREATEPIPELINE}
                  size="sm36"
                  variant={"primary"}
                  LeftIcon={ICON_LABELS.ADD}
                  onClick={handleNavigatePipeline}
                />
              ) : (
                ""
              )}
            </div>
            {/* pipeline name dropdown */}
            <AutoCompleteCustom
              dropdownId="preview_dropdown1"
              items={getUsers}
              size={"sm36"}
              setIdState={setUsersId}
              setValueState={setUsersValue}
              valueState={usersValue}
              idState={usersId}
              variant={
                inputError[STRINGS.PIPELINE_SMALL]?.length
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
              }
              errormessage={inputError[STRINGS.PIPELINE_SMALL]}
            />
            {/* stages dropdown */}
            <TypographyInline
              label={STRINGS.STAGES}
              color={GREY.QUATINARY}
              size={"sm"}
              fontWeight={fontWeight.MEDIUM}
            />
            <AutoCompleteCustom
              dropdownId="preview_dropdown2"
              items={updatedItemArr}
              size={"sm36"}
              setIdState={setUsersStageId}
              setValueState={setUsersStageValue}
              valueState={usersStageValue}
              idState={usersStageId}
              variant={
                inputError[STRINGS.PIPELINE_SMALL]?.length
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
              }
              errormessage={inputError[STRINGS.PIPELINE_SMALL]}
            />

            {/* deal priority */}
            <TypographyInline
              label={STRINGS.PRIORITY}
              color={GREY.QUATINARY}
              size={"sm"}
              fontWeight={fontWeight.MEDIUM}
            />
            <AutoCompleteCustom
              dropdownId="priority_dropdown"
              items={dealPriorityList}
              size={"sm36"}
              idState={priorityId}
              setIdState={setPriorityId}
              valueState={priorityLabel}
              setValueState={setPriorityLabel}
            />

            {fieldsOrder?.map((field, index) => {
              let propertyKeyValue = {
                ...getPropertiesListSelector?.data?.properties_key_value,
              };
              return (
                <div
                  key={`add_deal${index}`}
                  className={classes.inputLabelField}
                >
                  <div className={classes.labelButtonContainer}>
                    <TypographyInline
                      label={propertyKeyValue?.[field]?.property_name}
                      color={GREY.QUATINARY}
                      size={"sm"}
                      fontWeight={fontWeight.MEDIUM}
                      isRequired={requiredOrder?.[field]}
                    />
                  </div>
                  {type_id_components(propertyKeyValue?.[field], index)}
                </div>
              );
            })}
          </form>
        )}
      </CustomDialog>
    </div>
  );
};

export default AddDealDialog;
