import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { D3PieChart } from "./d3/D3PieChart";
import { D3LineChart } from "./d3/D3LineChart";
import { D3HBarChart } from "./d3/D3HBarChart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getChartData } from "../../../redux/actions/dashboardActions";
import {
  STATS_KEY_VALUES,
  WIDGET_TABLE_LEADS_LIST,
  WIDGET_TABLE_UNSUBSCRIBE_LIST,
} from "../../../utils/constants";
import { BoxWidget } from "./boxWidget";
import { CommonBlacklistTable } from "../../Leads/components";
import { ImageIcon } from "../../../components";
import { Styles } from "../Styles";
import { ACTIVITY_CHART_INITIAL, BAR_CHART_INITIAL, LINE_CHART_INITIAL, PIECHART_DISABLED, VERTICAL_CHART_INITIAL } from "../../../utils/constants/assets";
import { D3VBarChart } from "./d3/D3VBarChart";
import { D3VMultipleBarChartNoDate } from "./d3/D3VMultipleBarChartNoDate";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import { ACTIONS, GREY } from "../../../utils/constants/colors";
import IconComponent from "../../../components/Icon/IconComponent";
import CustomMenu from "../../../components/Menu/CustomMenu";
import { STRINGS } from "../strings";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const Widget = (props) => {
  const classes = Styles();
  const [headerName, setHeaderName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActivityBlank, setIsActivityBlank] = useState(false);

  const currentDashboardSelector = useSelector(
    (state) => state.getCurrentDashboard
  );
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const chartDataSelector = useSelector((state) => state.getChartData);

  const dashboardWidgetListSelector = useSelector(
    (state) => state.getDashboardWidgetDetails
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    if (dashboardWidgetListSelector?.payload?.records?.length > 0) {
      let payload = {
        widget_ids: [
          {
            id: props.widgetDetails._id,
          },
        ],
      };
      props?.setNewDashName("");
      await dispatch(
        getChartData(navigate, payload, true, true, chartDataSelector?.payload)
      );
      setIsLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (props?.widgetDetails.widget_name) {
      setHeaderName(props?.widgetDetails.widget_name);
      props.setHeaderWidgetName(props?.widgetDetails.widget_name);
    } else {
      if (props?.widgetDetails.widget_type === "line") {
        setHeaderName(STRINGS.RECENT_CAMPAIGNS);
        props.setHeaderWidgetName(STRINGS.RECENT_CAMPAIGNS);
      } else if (props?.widgetDetails.widget_type === "pie") {
        setHeaderName(STRINGS.RECENT_SEQUENCES);
        props.setHeaderWidgetName(STRINGS.RECENT_SEQUENCES);
      } else if (props?.widgetDetails.widget_type === "bar-horizontal") {
        setHeaderName(STRINGS.DAILY_TASK_REPORT);
        props.setHeaderWidgetName(STRINGS.DAILY_TASK_REPORT);
      } else if (props?.widgetDetails.widget_type === "bar-vertical") {
        setHeaderName(STRINGS.DAILY_TASK_REPORT);
        props.setHeaderWidgetName(STRINGS.DAILY_TASK_REPORT);
      }
    }
  }, [props?.widgetDetails]);

  useEffect(() => {
    if (props?.widgetDetails?._id) {
      setChartData(chartDataSelector?.payload?.[props.widgetDetails._id]);
      props?.setWidgetChartData(
        chartDataSelector?.payload?.[props.widgetDetails._id]
      );
    }

    // stroing true/false for blank activity report graph
    if (props.widgetDetails.data_type === "activity" && props.widgetDetails.widget_type === "bar-vertical") {
      const chartData = chartDataSelector?.payload?.[props?.widgetDetails?._id];
      const inCompleteCallCount = chartData?.[0]?.arr?.[0]?.y || 0 + chartData?.[0]?.arr?.[0]?.z || 0;
      const inCompleteTodoCount = chartData?.[1]?.arr?.[1]?.y || 0 + chartData?.[1]?.arr?.[1]?.z || 0;
      const completeCallCount = chartData?.[0]?.arr?.[0]?.y || 0 + chartData?.[0]?.arr?.[0]?.z || 0;
      const completeTodoCount = chartData?.[1]?.arr?.[1]?.y || 0 + chartData?.[1]?.arr?.[1]?.z || 0;
      const totalCount = inCompleteCallCount + inCompleteTodoCount + completeCallCount + completeTodoCount;
      setIsActivityBlank(Boolean(totalCount));
    }

  }, [chartDataSelector, props?.widgetDetails]);

  const topBox = (id, arr, color, value) => {
    let tempYValues = [];
    arr?.forEach((temp, i) => {
      tempYValues.push(Object.values(temp)[1]);
    });
    let tempYsum = tempYValues.reduce((a, b) => a + b, 0);
    return (
      <Box className={classes.topBoxMainContainer}>
        <Box className={classes.toolBarNames}>
          <IconComponent
            color={color}
            fontSize={fontSize.SM}
            iconLabel={ICON_LABELS.FIBER_MANUAL_RECORD}
          />
          <TypographyInline
            label={STATS_KEY_VALUES[id]}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
        </Box>
        <div className={classes.statsValue}>
          <TypographyInline
            label={value ? value : tempYsum}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.PRIMARY}
          />
        </div>
      </Box>
    );
  };

  const widgetActionsList = [
    // {
    //   id: "refresh_widget",
    //   label: isLoading ? STRINGS.REFRESHING : STRINGS.REFRESH,
    //   iconLabel: ICON_LABELS.REFRESH,
    //   function: handleRefresh,
    //   isVisible: true,
    // },
    {
      id: "clone_widget",
      label: STRINGS.CLONE_WIDGET,
      iconLabel: ICON_LABELS.CONTENT_COPY,
      function: props.handleClone,
      isVisible:
        userDetailsSelector.payload?.email ===
        currentDashboardSelector.payload?.owner_email,
    },
    {
      id: "delete_widget",
      label: STRINGS.REMOVE_WIDGET,
      iconLabel: ICON_LABELS.REMOVE_CIRCLE,
      function: props.handleRemove,
      isVisible:
        userDetailsSelector.payload?.email ===
        currentDashboardSelector.payload?.owner_email,
      variant: "error",
    },
  ];

  const handleDotClick = (e) => {
    handleClick(e);
    props?.setClickedWidgetDetail(props?.widgetDetails);
    props?.setWidgetIndex(props?.index);
  };

  return (
    <Box component={"div"} id="WidgetBox" className={classes.WidgetBox}>
      <div
        className={`grid-item ${props?.widgetDetails?.widget_type === "box"
          ? classes.gridItemHeight
          : ""
          }`}
      >
        <Box className={classes.widgetToolBar}>
          <TypographyInline
            label={headerName}
            size={"md"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.PRIMARY}
          />
          <Box>
            <Box className={classes.rightToolbar}>
              <Box
                aria-haspopup="true"
                disabled={
                  userDetailsSelector.payload?.email !==
                  currentDashboardSelector.payload?.owner_email
                }
                className={
                  userDetailsSelector.payload?.email !==
                    currentDashboardSelector.payload?.owner_email
                    ? classes.toolBarDisabledIcon
                    : classes.toolbarIcon
                }
                onClick={() => {
                  if (
                    !(
                      userDetailsSelector.payload?.email !==
                      currentDashboardSelector.payload?.owner_email
                    )
                  ) {
                    props?.handleDetail();
                    props?.setClickedWidgetDetail(props?.widgetDetails);
                  }
                }}
              >
                <IconComponent
                  color={
                    userDetailsSelector.payload?.email !==
                      currentDashboardSelector.payload?.owner_email
                      ? ACTIONS.DISABLED
                      : GREY.TERTIARY
                  }
                  fontSize={fontSize.DXS}
                  iconLabel={ICON_LABELS.TUNE}
                  disabled={
                    userDetailsSelector.payload?.email !==
                    currentDashboardSelector.payload?.owner_email
                  }
                />
              </Box>

              <Box
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={
                  !(
                    userDetailsSelector.payload?.email !==
                    currentDashboardSelector.payload?.owner_email
                  )
                    ? handleDotClick
                    : null
                }
                className={
                  userDetailsSelector.payload?.email !==
                    currentDashboardSelector.payload?.owner_email
                    ? classes.toolBarDisabledIcon
                    : classes.toolbarIcon
                }
              >
                <IconComponent
                  color={
                    userDetailsSelector.payload?.email !==
                      currentDashboardSelector.payload?.owner_email
                      ? ACTIONS.DISABLED
                      : GREY.TERTIARY
                  }
                  fontSize={fontSize.DXS}
                  iconLabel={ICON_LABELS.MORE_VERT}
                  disabled={
                    userDetailsSelector.payload?.email !==
                    currentDashboardSelector.payload?.owner_email
                  }
                />
              </Box>
              <CustomMenu
                onClose={handleClose}
                anchor={anchorEl}
                open={Boolean(anchorEl)}
                items={widgetActionsList}
                paperMenuClass={classes.paperMenuClass}
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={
            props?.widgetDetails?.widget_type === "box" && classes.widgetTypeBox
          }
        >
          {props?.widgetDetails?.widget_type !== "box" &&
            props?.widgetDetails?.widget_type !== "table" ? (
            <Box>
              {chartDataSelector.loading === false ? (
                <Box className={classes.topBox}>
                  {chartDataSelector?.payload?.[props?.widgetDetails?._id]?.map(
                    (data, i) => {
                      return (
                        <Box key={i}>
                          {topBox(data.id, data.arr, data.color, data?.value)}
                        </Box>
                      );
                    }
                  )}
                </Box>
              ) : null}
              <Box className={classes.bottomBox}>
                <Box className={classes.svgContainer}>
                  {(props.widgetDetails.widget_type === "line") &
                    (chartData?.length > 0) ? (
                    chartData.filter((item) => item?.arr?.length)
                      .length ? (
                      <D3LineChart
                        activeTooltip="#widgetTooltip"
                        ownerName={props.dashBoard?.dashboard_name}
                        index={props.index}
                        dataSet={chartData}
                        height={
                          document.getElementById(props.index)?.clientHeight * 0.7
                        }
                        width={document.getElementById(props.index)?.clientWidth}
                      />
                    ) : (
                      <div className={classes.paddingTopper16}>
                        <ImageIcon
                          className={classes.pieChartImage}
                          image={LINE_CHART_INITIAL}
                          height={
                            document.getElementById(props.index)?.clientHeight *
                            0.6
                          }
                          width={
                            document.getElementById(props.index)?.clientWidth
                          }
                        />
                      </div>
                    )

                  ) : null}
                  {(props.widgetDetails.widget_type === "pie") &
                    (chartData?.length > 0) ? (
                    chartData.filter((item) => parseInt(item.value) !== 0)
                      .length ? (
                      <D3PieChart
                        activeTooltip="#widgetTooltip"
                        index={props.index}
                        dataSet={chartData}
                        height={
                          document.getElementById(props.index)?.clientHeight *
                          0.7
                        }
                        width={
                          document.getElementById(props.index)?.clientWidth
                        }
                      />
                    ) : (
                      <div className={classes.paddingTopper16}>
                        <ImageIcon
                          className={classes.pieChartImage}
                          image={PIECHART_DISABLED}
                          height={
                            document.getElementById(props.index)?.clientHeight *
                            0.6
                          }
                          width={
                            document.getElementById(props.index)?.clientWidth
                          }
                        />
                      </div>
                    )
                  ) : null}
                  {(props.widgetDetails.widget_type === "bar-horizontal") &
                    (chartData?.length > 0) ? (
                    chartData.filter((item) => parseInt(item.value) !== 0)
                      .length ? (
                      <D3HBarChart
                        activeTooltip="#widgetTooltip"
                        index={props.index}
                        dataSet={chartData}
                        height={
                          document.getElementById(props.index)?.clientHeight * 0.7
                        }
                        width={document.getElementById(props.index)?.clientWidth}
                      />
                    ) : (
                      <div className={classes.paddingTopper16}>
                        <ImageIcon
                          className={classes.pieChartImage}
                          image={BAR_CHART_INITIAL}
                          height={
                            document.getElementById(props.index)?.clientHeight *
                            0.6
                          }
                          width={
                            document.getElementById(props.index)?.clientWidth
                          }
                        />
                      </div>
                    )
                  ) : null}
                  {(props.widgetDetails.data_type === "activity" &&
                    props.widgetDetails.widget_type === "bar-vertical") &
                    (chartData?.length > 0) ? (
                    isActivityBlank ? (
                      <D3VMultipleBarChartNoDate
                        activeTooltip="#widgetTooltip"
                        ownerName={props.dashBoard?.dashboard_name}
                        index={props.index}
                        dataSet={chartData}
                        height={
                          document.getElementById(props.index)?.clientHeight * 0.7
                        }
                        width={document.getElementById(props.index)?.clientWidth}
                      />
                    ) : (
                      <div className={classes.paddingTopper16}>
                        <ImageIcon
                          className={classes.pieChartImage}
                          image={ACTIVITY_CHART_INITIAL}
                          height={
                            document.getElementById(props.index)?.clientHeight *
                            0.6
                          }
                          width={
                            document.getElementById(props.index)?.clientWidth
                          }
                        />
                      </div>
                    )
                  ) : null}
                  {(props.widgetDetails.data_type !== "activity" &&
                    props.widgetDetails.widget_type === "bar-vertical") &
                    (chartData?.length > 0) ? (
                    chartData.filter((item) => parseInt(item.value) !== 0)
                      .length ?
                      <D3VBarChart
                        activeTooltip="#widgetTooltip"
                        ownerName={props.dashBoard?.dashboard_name}
                        index={props.index}
                        dataSet={chartData}
                        height={
                          document.getElementById(props.index)?.clientHeight * 0.7
                        }
                        width={document.getElementById(props.index)?.clientWidth}
                      /> : <div className={classes.paddingTopper16}>
                        <ImageIcon
                          className={classes.pieChartImage}
                          image={VERTICAL_CHART_INITIAL}
                          height={
                            document.getElementById(props.index)?.clientHeight *
                            0.6
                          }
                          width={
                            document.getElementById(props.index)?.clientWidth
                          }
                        />
                      </div>
                  ) : null}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              className={
                props?.widgetDetails?.widget_type === "box" &&
                classes.gridItemHeight
              }

            >
              {(props.widgetDetails.widget_type === "box") &
                (chartData?.length > 0) ? (
                <BoxWidget dataSet={chartData} />
              ) : null}
              {(props.widgetDetails.widget_type === "table") &
                (chartData?.length >= 0) ? (
                <Box className={classes.bottomTableBox}>
                  <CommonBlacklistTable
                    widgetHeight={
                      document.getElementById(props.index)?.clientHeight - 98
                    }
                    widgetWidth={
                      document.getElementById(props.index)?.clientWidth - 50
                    }
                    heads={
                      props?.widgetDetails?.data_type === "unsubscribe"
                        ? WIDGET_TABLE_UNSUBSCRIBE_LIST
                        : WIDGET_TABLE_LEADS_LIST
                    }
                    data={chartData}
                    isDashboardTable={true}
                  />
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      </div>
    </Box>
  );
};
