import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import { deleteRequest } from "../../../../Apis";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../../utils/constants";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../../strings";
import classes from "./CrmComp.module.css";
import { GREY } from "../../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import { useNavigate } from "react-router-dom";

const ResetPropertyActionModal = ({ reset, setReset, id, close, open, action = () => { } }) => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const ResetMethod = async () => {
    setLoading(true);
    try {
      const URL = `${crmEndpoints?.resetColumns}/${id}`;
      const response = await deleteRequest(URL, {}, navigate);
      setLoading(false);
      action();
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setReset(false);
      close(!open)
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const modalHeaderData = {
    heading: {
      id: "head",
      label: STRINGS.RESET_COLUMNS,
    },
    alertIcon: {
      id: "restore",
      label: ICON_LABELS.RESTORE,
    },
  };

  const modalBodyData = (
    <div className={classes.reset_body}>
      <TypographyInline
        size={"md"}
        color={GREY.PRIMARY}
        fontWeight={fontWeight.SEMIBOLD}
        label={STRINGS.RESET_ALL_PROPERTIES}
      />
      <TypographyInline
        size={"xs"}
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={STRINGS.RESET_ALL_PROPERTIES_DESC}
      />
    </div>
  );



  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setReset(false)}
      />
      <Button
        label={STRINGS.RESET}
        variant={"primary"}
        size={"sm36"}
        onClick={() => ResetMethod()}
        isLoading={loading}
      />
    </div>
  );

  const modalFooterLeftData = <div></div>;

  return (
    <div>
      <CustomModal
        variant={"alert"}
        close={() => setReset(false)}
        open={reset}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
        remove_padding
      />

    </div>
  );
};
export default ResetPropertyActionModal;
