import React, { useEffect, useState } from 'react'
import CustomDialog from '../../../../components/Dialog/CustomDialog'
import { STRINGS } from '../strings';
import { getRequest } from '../../../../Apis';
import { campaignEndpoints } from '../../../../utils/constants/httpConstants';
import EventEmitter from '../../../../utils/emitter/EventEmitter';
import { EMITTER_ERROR, USER_MODAL_COMPANY_DETAIL } from '../../../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import classes from "../markComponents.module.css"
import { Anchor, Button, FullScreenLoader } from '../../../../components';
import AvatarName from '../../../../components/Avatar/AvatarName';
import AvatarOutlined from '../../../../components/Avatar/AvatarOutlined';
import Image from '../../../../components/Image/Image';
import { DUMMY_COMPANY_IMAGE, DUMMY_CONTACT2, EMAIL_ICON, PHONE_ICON } from '../../../../utils/constants/assets';
import TypographyInline from '../../../../components/Typography/TypographyInline';
import { ICON_LABELS, fontSize, fontWeight } from '../../../../utils/constants/UI';
import { BRAND, GREY, SUCCESS } from '../../../../utils/constants/colors';
import { FacebookIcon, LinkedInIcon, TwitterIcon } from '../../../../components/Icon/MuiIcons';
import { Box } from '@mui/material';
import { IconComponent } from '../../../../components/Icon';
import { convertToInternationalCurrencySystem, createCompanyURL, filterUserName } from '../../../../utils/helpers';
import CustomTooltip from '../../../../components/Tooltip/CustomTooltip';

const UserProfileDialog = ({ open, setOpen, email }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const params = useParams();
    const campaign_id = params?.campaignId?.split("&")?.[0];
    const navigate = useNavigate();
    const [technologies, setTechnologies] = useState([]);
    const [clipboardValue, setClipBoardValue] = useState({});

    const USER_MODAL_COMPANY_INFO = [
        {
            icon: <FacebookIcon className={classes.socialIcon} />,
            component: data?.company_facebook_url,
        },
        {
            icon: <TwitterIcon className={classes.socialIcon} />,
            component: data?.company_twitter_url,
        },
        {
            icon: <LinkedInIcon className={classes.socialIcon} />,
            component: data?.company_linkedin_url,
        },
    ];

    const handleCopyText = (value, id) => {
        try {
            setClipBoardValue({ id: id, value: value });
            navigator?.clipboard?.writeText(value);

            setTimeout(() => {
                setClipBoardValue({});
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    const handleShowMore = () => {
        setTechnologies(data?.company_technologies?.split(","));
    };
    const showAll = () => {
        setTechnologies(data?.company_technologies?.split(" "));
    };

    const handleShowLess = () => {
        setTechnologies(data?.company_technologies?.split(",")?.slice(0, 5));
    };

    const filterValue = (key, item) => {
        if (key === STRINGS.ANNUAL_REVENUE) {
            return convertToInternationalCurrencySystem(item)?.toString();
        }
        return item;
    };
    const dialogHeader = {
        heading: {
            id: STRINGS.VIEW_PROFILE,
            label: STRINGS.VIEW_PROFILE,
        },
    };

    const profileBody = (
        loading ? (
            <FullScreenLoader />
        ) : (
            <div className={classes.containUserModal}>
                <div className={classes.containPersonalDetails}>
                    {data?.first_name || data?.last_name ? (
                        <AvatarName
                            name={data?.first_name + " " + (data?.last_name || "")}
                            size="XXL"
                        />
                    ) : (
                        <AvatarOutlined size={"XXL"}>
                            <Image
                                src={DUMMY_CONTACT2}
                                alt=""
                                style={{
                                    height: "78px",
                                    width: "78px",
                                }}
                            />
                        </AvatarOutlined>
                    )}
                    <div className={classes.headingContainer}>
                        <TypographyInline
                            label={(data?.first_name || "") + " " + (data?.last_name || "")}
                            size={"md"}
                            fontWeight={fontWeight.SEMIBOLD}
                            color={GREY.PRIMARY}
                        />
                        <TypographyInline
                            label={data?.job_title || ""}
                            size={"xs"}
                            fontWeight={fontWeight.REGULAR}
                            color={BRAND.SECONDARY}
                        />
                    </div>
                </div>

                <div className={classes.socialIconsContainer1}>
                    {data?.contact_linkedin_url ? (
                        <div className={classes.containLinkedin}>
                            <Anchor
                                href={data?.contact_linkedin_url}
                                target="_blank"
                            >
                                <LinkedInIcon className={classes.socialIcon} />
                            </Anchor>
                        </div>
                    ) : null}
                </div>

                <Box className={classes.containBasicInfo}>
                    <Box
                        className={
                            data?.email
                                ? classes.basicInfoUnset
                                : classes.basicInfoBlur
                        }
                    >
                        <div className={classes.containCrownInfo}>
                            <TypographyInline
                                label={STRINGS.PERSONAL_INFORMATION}
                                size={"sm"}
                                fontWeight={fontWeight.MEDIUM}
                                color={GREY.PRIMARY}
                            />
                        </div>
                        <div className={classes.containEachInfo}>
                            <div className={classes.basicInfoLeft}>
                                <AvatarOutlined size={"small"}>
                                    <Image src={EMAIL_ICON} />
                                </AvatarOutlined>
                                <div>
                                    <TypographyInline
                                        label={STRINGS.EMAIL}
                                        size={"sm"}
                                        fontWeight={fontWeight.SEMIBOLD}
                                        color={GREY.SECONDARY}
                                    />
                                    <div className={classes.labelIconContainer}>
                                        <TypographyInline
                                            label={
                                                data?.email ||
                                                STRINGS.NOTAPPLICABLE
                                            }
                                            size={"sm"}
                                            fontWeight={fontWeight.REGULAR}
                                            color={GREY.SECONDARY}
                                        />
                                        <div
                                            onClick={() =>
                                                handleCopyText(
                                                    data?.email ||
                                                    STRINGS.NOTAPPLICABLE,
                                                    STRINGS.EMAIL_SMALL
                                                )
                                            }
                                            className={classes.copyIcon}
                                        >
                                            {clipboardValue?.value === data?.email &&
                                                clipboardValue?.id === STRINGS.EMAIL_SMALL ? (
                                                <div
                                                    id={STRINGS.COPIED_TEXT_CNTR}
                                                    className={classes.copiedTextContainer}
                                                >
                                                    <IconComponent
                                                        fontSize={fontSize.SM}
                                                        color={SUCCESS.W_500}
                                                        iconLabel={ICON_LABELS.DONE}
                                                    />
                                                    <TypographyInline
                                                        isEllipses
                                                        color={SUCCESS.W_500}
                                                        fontWeight={fontWeight.MEDIUM}
                                                        label={STRINGS.COPIED}
                                                        size={"xs"}
                                                    />
                                                </div>
                                            ) : (
                                                <IconComponent
                                                    color={GREY.SECONDARY}
                                                    fontSize={fontSize.MD}
                                                    iconLabel={ICON_LABELS.CONTENT_COPY}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {data?.direct_phone_number ? (
                            <div className={classes.containEachInfo}>
                                <div className={classes.basicInfoLeft}>
                                    <AvatarOutlined size={"small"}>
                                        <Image src={PHONE_ICON} />
                                    </AvatarOutlined>
                                    <div>
                                        <TypographyInline
                                            label={STRINGS.MOBILE_NUMBER}
                                            size={"sm"}
                                            fontWeight={fontWeight.SEMIBOLD}
                                            color={GREY.SECONDARY}
                                        />
                                        <div className={classes.labelIconContainer}>
                                            <TypographyInline
                                                label={
                                                    data?.direct_phone_number ||
                                                    STRINGS.NOTAPPLICABLE
                                                }
                                                size={"sm"}
                                                fontWeight={fontWeight.REGULAR}
                                                color={GREY.SECONDARY}
                                            />
                                            <div
                                                onClick={() =>
                                                    handleCopyText(
                                                        data?.direct_phone_number ||
                                                        STRINGS.NOTAPPLICABLE,
                                                        STRINGS.DIRECT_PHONE_NUMBER
                                                    )
                                                }
                                                className={classes.copyIcon}
                                            >
                                                {clipboardValue?.value ===
                                                    data?.direct_phone_number &&
                                                    clipboardValue?.id ===
                                                    STRINGS.DIRECT_PHONE_NUMBER ? (
                                                    <div
                                                        id={STRINGS.COPIED_TEXT_CNTR}
                                                        className={classes.copiedTextContainer}
                                                    >
                                                        <IconComponent
                                                            fontSize={fontSize.SM}
                                                            color={SUCCESS.W_500}
                                                            iconLabel={ICON_LABELS.DONE}
                                                        />
                                                        <TypographyInline
                                                            isEllipses
                                                            color={SUCCESS.W_500}
                                                            fontWeight={fontWeight.MEDIUM}
                                                            label={STRINGS.COPIED}
                                                            size={"xs"}
                                                        />
                                                    </div>
                                                ) : (
                                                    <IconComponent
                                                        color={GREY.SECONDARY}
                                                        fontSize={fontSize.MD}
                                                        iconLabel={ICON_LABELS.CONTENT_COPY}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : data?.email ? null : (
                            <div className={classes.containEachInfo}>
                                <div className={classes.basicInfoLeft}>
                                    <AvatarOutlined size={"small"}>
                                        <Image src={PHONE_ICON} />
                                    </AvatarOutlined>
                                    <div>
                                        <TypographyInline
                                            label={STRINGS.MOBILE_NUMBER}
                                            size={"sm"}
                                            fontWeight={fontWeight.SEMIBOLD}
                                            color={GREY.SECONDARY}
                                        />
                                        <div className={classes.labelIconContainer}>
                                            <TypographyInline
                                                label={
                                                    data?.direct_phone_number ||
                                                    STRINGS.NOTAPPLICABLE
                                                }
                                                size={"sm"}
                                                fontWeight={fontWeight.REGULAR}
                                                color={GREY.SECONDARY}
                                            />
                                            <div
                                                onClick={() =>
                                                    handleCopyText(
                                                        data?.direct_phone_number ||
                                                        STRINGS.NOTAPPLICABLE,
                                                        STRINGS.DIRECT_PHONE_NUMBER
                                                    )
                                                }
                                                className={classes.copyIcon}
                                            >
                                                {clipboardValue?.value ===
                                                    data?.direct_phone_number &&
                                                    clipboardValue?.id ===
                                                    STRINGS.DIRECT_PHONE_NUMBER ? (
                                                    <div
                                                        id={STRINGS.COPIED_TEXT_CNTR}
                                                        className={classes.copiedTextContainer}
                                                    >
                                                        <IconComponent
                                                            fontSize={fontSize.SM}
                                                            color={SUCCESS.W_500}
                                                            iconLabel={ICON_LABELS.DONE}
                                                        />
                                                        <TypographyInline
                                                            isEllipses
                                                            color={SUCCESS.W_500}
                                                            fontWeight={fontWeight.MEDIUM}
                                                            label={STRINGS.COPIED}
                                                            size={"xs"}
                                                        />
                                                    </div>
                                                ) : (
                                                    <IconComponent
                                                        color={GREY.SECONDARY}
                                                        fontSize={fontSize.MD}
                                                        iconLabel={ICON_LABELS.CONTENT_COPY}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {data?.company_phone_number ? (
                            <div className={classes.containEachInfo}>
                                <div className={classes.basicInfoLeft}>
                                    <AvatarOutlined size={"small"}>
                                        <Image src={PHONE_ICON} />
                                    </AvatarOutlined>
                                    <div>
                                        <TypographyInline
                                            label={STRINGS.CORPORATE_NUMBER}
                                            size={"sm"}
                                            fontWeight={fontWeight.SEMIBOLD}
                                            color={GREY.SECONDARY}
                                        />
                                        <div className={classes.labelIconContainer}>
                                            <TypographyInline
                                                label={
                                                    data?.company_phone_number ||
                                                    STRINGS.NOTAPPLICABLE
                                                }
                                                size={"sm"}
                                                fontWeight={fontWeight.REGULAR}
                                                color={GREY.SECONDARY}
                                            />
                                            <div
                                                onClick={() =>
                                                    handleCopyText(
                                                        data?.company_phone_number ||
                                                        STRINGS.NOTAPPLICABLE,
                                                        STRINGS.COMPANY_PHONE_NUMBER
                                                    )
                                                }
                                                className={classes.copyIcon}
                                            >
                                                {clipboardValue?.value ===
                                                    data?.company_phone_number &&
                                                    clipboardValue?.id ===
                                                    STRINGS.COMPANY_PHONE_NUMBER ? (
                                                    <div
                                                        id={STRINGS.COPIED_TEXT_CNTR}
                                                        className={classes.copiedTextContainer}
                                                    >
                                                        <IconComponent
                                                            fontSize={fontSize.SM}
                                                            color={SUCCESS.W_500}
                                                            iconLabel={ICON_LABELS.DONE}
                                                        />
                                                        <TypographyInline
                                                            isEllipses
                                                            color={SUCCESS.W_500}
                                                            fontWeight={fontWeight.MEDIUM}
                                                            label={STRINGS.COPIED}
                                                            size={"xs"}
                                                        />
                                                    </div>
                                                ) : (
                                                    <IconComponent
                                                        color={GREY.SECONDARY}
                                                        fontSize={fontSize.MD}
                                                        iconLabel={ICON_LABELS.CONTENT_COPY}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : data?.email ? null : (
                            <div
                                className={
                                    classes.containEachInfo}
                            >
                                <div className={classes.basicInfoLeft}>
                                    <AvatarOutlined size={"small"}>
                                        <Image src={PHONE_ICON} />
                                    </AvatarOutlined>
                                    <div>
                                        <TypographyInline
                                            label={STRINGS.CORPORATE_NUMBER}
                                            size={"sm"}
                                            fontWeight={fontWeight.SEMIBOLD}
                                            color={GREY.SECONDARY}
                                        />
                                        <div className={classes.labelIconContainer}>
                                            <TypographyInline
                                                label={
                                                    data?.company_phone_number ||
                                                    STRINGS.NOTAPPLICABLE
                                                }
                                                size={"sm"}
                                                fontWeight={fontWeight.REGULAR}
                                                color={GREY.SECONDARY}
                                            />
                                            <div
                                                onClick={() =>
                                                    handleCopyText(
                                                        data?.company_phone_number ||
                                                        STRINGS.NOTAPPLICABLE,
                                                        STRINGS.COMPANY_PHONE_NUMBER
                                                    )
                                                }
                                                className={classes.copyIcon}
                                            >
                                                {clipboardValue?.value ===
                                                    data?.company_phone_number &&
                                                    clipboardValue?.id ===
                                                    STRINGS.COMPANY_PHONE_NUMBER ? (
                                                    <div
                                                        id={STRINGS.COPIED_TEXT_CNTR}
                                                        className={classes.copiedTextContainer}
                                                    >
                                                        <IconComponent
                                                            fontSize={fontSize.SM}
                                                            color={SUCCESS.W_500}
                                                            iconLabel={ICON_LABELS.DONE}
                                                        />
                                                        <TypographyInline
                                                            isEllipses
                                                            color={SUCCESS.W_500}
                                                            fontWeight={fontWeight.MEDIUM}
                                                            label={STRINGS.COPIED}
                                                            size={"xs"}
                                                        />
                                                    </div>
                                                ) : (
                                                    <IconComponent
                                                        color={GREY.SECONDARY}
                                                        fontSize={fontSize.MD}
                                                        iconLabel={ICON_LABELS.CONTENT_COPY}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Box>
                </Box>

                {/* Account Information */}
                <Box className={classes.containAccountsInfo}>
                    <Box className={classes.containAccountsTitle}>
                        <TypographyInline
                            label={STRINGS.COMPANY_INFORMATION}
                            size={"sm"}
                            fontWeight={fontWeight.MEDIUM}
                            color={GREY.PRIMARY}
                        />
                    </Box>
                    <Box className={classes.flexJustifyBetween1}>
                        <Box className={classes.flexDis}>
                            <AvatarOutlined size={"large"}>
                                <Image
                                    onError={(e) => (e.target.src = DUMMY_COMPANY_IMAGE)}
                                    src={
                                        createCompanyURL(data?.company_website) ||
                                        DUMMY_COMPANY_IMAGE
                                    }
                                    className={classes.comapnyImage}
                                    alt=""
                                />
                            </AvatarOutlined>
                            <div className={classes.flexDis}>
                                <CustomTooltip
                                    title={
                                        data?.company_name?.length >= 15
                                            ? data?.company_name
                                            : ""
                                    }
                                >
                                    <TypographyInline
                                        label={filterUserName(data?.company_name) || ""}
                                        size={"md"}
                                        fontWeight={fontWeight.SEMIBOLD}
                                        color={GREY.PRIMARY}
                                    />
                                </CustomTooltip>
                            </div>
                        </Box>
                        <Box className={classes.socialIconsContainer}>
                            {USER_MODAL_COMPANY_INFO?.map((item) => {
                                return (
                                    item?.component && (
                                        <div className={classes.containFacebook}>
                                            <Anchor href={item?.component} target="_blank">
                                                {item.icon}
                                            </Anchor>
                                        </div>
                                    )
                                );
                            })}
                        </Box>
                    </Box>
                    <Box className={classes.userDetailContain}>
                        {USER_MODAL_COMPANY_DETAIL.map((item) => {
                            return data?.[item?.id] ? (
                                <Box
                                    className={classes.userEachDetailContain}
                                    key={item.id}
                                >
                                    {
                                        <IconComponent
                                            color={GREY.TERTIARY}
                                            fontSize={fontSize.MD}
                                            iconLabel={item.icon}
                                        />
                                    }
                                    {item?.id === STRINGS.COMPANY_WEBSITE ? (
                                        <Anchor
                                            href={data?.[item?.id]}
                                            className={classes.link}
                                            target="_blank"
                                        >
                                            {data?.[item?.id] || STRINGS.NOTAPPLICABLE}
                                        </Anchor>
                                    ) : (
                                        <TypographyInline
                                            label={`${filterValue(item?.id, data?.[item?.id]) ||
                                                STRINGS.NOTAPPLICABLE
                                                } ${filterValue(item?.id, data?.[item?.id]) &&
                                                item?.text
                                                } `}
                                            size={"sm"}
                                            fontWeight={fontWeight.REGULAR}
                                            color={GREY.SECONDARY}
                                        />
                                    )}
                                </Box>
                            ) : null;
                        })}
                    </Box>
                </Box>

                {technologies?.length ? (
                    <Box className={classes.containAccountsInfo}>
                        <Box className={classes.containAccountsTitle}>
                            <TypographyInline
                                label={STRINGS.TECHNOLOGIES}
                                size={"sm"}
                                fontWeight={fontWeight.MEDIUM}
                                color={GREY.PRIMARY}
                            />
                        </Box>
                        {technologies?.map((technology, index) => {
                            return (
                                <Box key={index} className={classes.flexDisplay}>
                                    <AvatarName name={technology} size={"small"} />
                                    <Box className={classes.containTechPara}>
                                        <TypographyInline
                                            label={technology}
                                            size={"sm"}
                                            fontWeight={fontWeight.REGULAR}
                                            color={GREY.SECONDARY}
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                        {technologies?.length !==
                            data?.company_technologies?.split(",")?.length &&
                            data?.company_technologies?.split(",")?.length > 5 ? (
                            <Button
                                size={"xs28"}
                                label={STRINGS.SHOW_MORE}
                                variant={"text"}
                                onClick={handleShowMore}
                            />
                        ) : data?.company_technologies?.split(",")?.length > 5 ? (
                            <Button
                                size={"xs28"}
                                label={STRINGS.SHOW_LESS}
                                variant={"text"}
                                onClick={handleShowLess}
                            />
                        ) : null}
                    </Box>
                ) : null}
            </div>
        )
    )

    useEffect(() => {
        // fetch steps details;
        (async () => {
            try {
                setLoading(true)
                const response = await getRequest(
                    navigate,
                    `${campaignEndpoints.showUserProf}?user_email=${email}&campaign_id=${campaign_id}`
                );
                setData(response?.data?.data)
                setLoading(false);
            } catch (error) {
                EventEmitter.emit(EMITTER_ERROR, error.data.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (data) {
            setTechnologies(data?.company_technologies?.split(",")?.slice(0, 5));
        }
    }, [data]);

    return (
        <CustomDialog
            open={open}
            keepMounted={true}
            onClose={() => setOpen(!open)}
            width={"500px"}
            header={dialogHeader}
        >
            {profileBody}
        </CustomDialog>
    )
}

export default UserProfileDialog