import React, { useEffect, useState } from "react";
import EmailLogList from "./components/EmailLogList";
import ListingTaskCard from "./components/ListingTaskCard";
import { STRINGS } from "../strings";
import classes from "./Crm.module.css";
import { TIMELINE_CRM } from "../../../utils/constants/assets";
import ContactDetailEmptyScreen from "./components/AccountDetailEmptyScreen";
import SkeletonLoaderCRMScreens from "./components/SkeletonLoaderCRMScreens";
import EmailSentList from "./components/EmailSentList";
import ListingNoteCard from "./components/ListingNoteCard";
import ListingMeetingCard from "./components/ListingMeetingCard";
import { decompressString } from "../../../utils/helpers";

const ContactDetailTimelineScreen = ({
  contactId,
  contactDetails,
  getTimelineLogList = () => {},
  dataList = [],
  screenLoading = false,
  totalTimeline = 0,
}) => {
  const [pageCount, setPageCount] = useState(0);

  const type_id_components = (prop) => {
    switch (prop?.activityType) {
      case "log":
        return (
          <EmailLogList
            contactDetails={contactDetails}
            logData={prop}
            showOperation={false}
          />
        );
      case "mail":
        return (
          <EmailSentList
            contactDetails={contactDetails}
            emailData={prop}
            showOperation={false}
          />
        );
      case "call":
        return (
          <ListingTaskCard
            contactDetails={contactDetails}
            logData={
              prop?.is_compressed
                ? {
                    ...prop,
                    notes: decodeURIComponent(decompressString(prop.notes)),
                  }
                : prop
            }
            contact_id={contactId}
            type={"call"}
            showOperation={false}
          />
        );
      case "todo":
        return (
          <ListingTaskCard
            contactDetails={contactDetails}
            logData={
              prop?.is_compressed
                ? {
                    ...prop,
                    notes: decodeURIComponent(decompressString(prop.notes)),
                  }
                : prop
            }
            contact_id={contactId}
            type={"todo"}
            showOperation={false}
          />
        );
      case "notes":
        return (
          <ListingNoteCard
            contactDetails={contactDetails}
            logData={
              prop?.is_compressed
                ? {
                    ...prop,
                    notes_body: decodeURIComponent(
                      decompressString(prop.notes_body)
                    ),
                  }
                : prop
            }
            contact_id={contactId}
            showOperation={false}
          />
        );
      case "meetings":
        return (
          <ListingMeetingCard
            contactDetails={contactDetails}
            logData={
              prop?.is_compressed
                ? {
                    ...prop,
                    event_description: decodeURIComponent(
                      decompressString(prop.event_description)
                    ),
                  }
                : prop
            }
            contactId={contactId}
            showOperation={false}
          />
        );

      default:
        return;
    }
  };

  const onScrollApiHandler = () => {
    const scrollableDiv = document?.getElementsByClassName(
      STRINGS.ALL_TIME_LIST
    )[0];
    const isAtBottom =
      scrollableDiv?.scrollTop >=
      scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;
    if (isAtBottom && totalTimeline > dataList?.length) {
      setPageCount(pageCount + 1);
      getTimelineLogList(true, pageCount + 1);
    }
  };

  useEffect(() => {
    if (Object.keys(contactDetails)?.length) {
      getTimelineLogList();
    }
  }, [contactDetails]);

  return (
    <div
      className={`${classes.taskScreenMainContainer} ${STRINGS.ALL_TIME_LIST}`}
      id="comments_body"
      onScroll={onScrollApiHandler}
    >
      {screenLoading ? (
        <SkeletonLoaderCRMScreens accordionLengths={5} buttonsLength={0} />
      ) : (
        <>
          {/* Checking the length of the timeline list */}
          {dataList?.length ? (
            <div className={classes.taskListContainer}>
              {dataList?.map((logData, index) => {
                return (
                  <div key={`log${index}`}>
                    <div> {type_id_components(logData)}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classes.TaskHead}>
              <ContactDetailEmptyScreen
                src={TIMELINE_CRM}
                label={STRINGS.TIMELINE_NOTE}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContactDetailTimelineScreen;
