import { useEffect, useState } from "react";
import { Styles } from "../Styles";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import InputField from "../../../components/InputFields/InputField";
import RadioButton from "../../../components/RadioButton/RadioButton";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const ClonePopUp = (props) => {
  const classes = Styles();
  const [cloneWidgetName, setCloneWidgetName] = useState();
  const [selectedValue, setSelectedValue] = useState("existing");
  const [selectedValue1, setSelectedValue1] = useState("private");
  const [dashboardName, setDashboardName] = useState("");

  useEffect(() => {
    setCloneWidgetName(props.headerName || "");
  }, [props?.headerName]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChange1 = (event) => {
    setSelectedValue1(event.target.value);
  };

  const handleInput = (e) => {
    setCloneWidgetName(e.target.value);
    //cahnge the name of cloned report
  };

  const saveHandle = async () => {
    await props.cloneWidget(
      cloneWidgetName,
      selectedValue,
      selectedValue1,
      dashboardName,
      props?.wholeWidget?.filters
      // props?.wholeWidget?.filters?.fromDate ,
      // props?.wholeWidget?.filters?.toDate 
    );
  };

  const cancelHandle = () => {
    props.onClose();
  };

  const modalHeaderData = {
    heading: {
      id: "clone_widget",
      label: STRINGS.CLONE_WIDGET,
    },
  };

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.APPLY}
        size={"sm36"}
        variant={"primary"}
        onClick={saveHandle}
        isLoading={props.loading}
      />
    </div>
  );

  const radioButtonData = [
    {
      id: "existing",
      label: STRINGS.ADD_TO_EXISTING_DASHBOARD,
    },
    { id: "new", label: STRINGS.ADD_TO_NEW_DASHBOARD },
  ];

  const radioButtonDashboardData = [
    {
      id: "private",
      label: STRINGS.PRIVATE_LABEL,
    },
    { id: "public-read", label: STRINGS.EVERYONE_LABEL },
  ];

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
      width={"28.865vw"}
    >
      <div className={classes.modalBodyContainer}>
        <TypographyInline
          label={STRINGS.CLONE_WIDGET_WARNING_LABEL}
          size={"sm"}
          fontWeight={fontWeight.MEDIUM}
          color={GREY.SECONDARY}
        />
        <div
          className={classes.modalFieldLabelContainer}
          data-tour="dashboardname"
        >
          <TypographyInline
            label={STRINGS.REPORT_NAME}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
            isRequired
          />
          <InputField
            size={"sm36"}
            errormessage={props.cloneErr.length !== 0 ? props.cloneErr : ""}
            variant={"disabled"}
            placeholder={STRINGS.PLACEHOLDER_WIDGET}
            // value={cloneWidgetName}
            value={props?.type}
            onChange={handleInput}
            iscapitalize
          />
        </div>

        <div
          className={classes.permissionsContainer}
          data-tour="sharedashboard"
        >
          <TypographyInline
            label={STRINGS.WIDGET_CLONE_DASHBOARD_PERMISSION}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
          />
          <RadioButton
            value={selectedValue}
            onChange={handleChange}
            items={radioButtonData}
          />
        </div>

        {selectedValue === "new" && (
          <>
            <div data-tour="dashboardname">
              <TypographyInline
                label={STRINGS.DASHBOARD_NAME}
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
                color={GREY.QUATINARY}
                isRequired
              />
              <div className={classes.paddingTopper4}>
                <InputField
                  size={"sm36"}
                  errormessage={
                    props.dashboardErr.length !== 0 ? props.dashboardErr : ""
                  }
                  variant={props.dashboardErr.length ? "error" : "default"}
                  onChange={(e) => setDashboardName(e.target.value)}
                  placeholder={STRINGS.PLACEHOLDER_CREATE_DASHBOARD}
                />
              </div>
            </div>
            <div
              className={classes.permissionsContainer}
              data-tour="sharedashboard"
            >
              <TypographyInline
                label={`${STRINGS.PERMISSIONS_LABEL}*`}
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
                color={GREY.QUATINARY}
              />
              <RadioButton
                value={selectedValue1}
                onChange={handleChange1}
                items={radioButtonDashboardData}
              />
            </div>
          </>
        )}
      </div>
    </CustomModal>
  );
};
