import {
  LIST_TABLE_REQUEST,
  LIST_TABLE_SUCCESS,
  LIST_TABLE_FAIL,
  SELECTED_SEQUENCE_DETAILS_REQUEST,
  SELECTED_SEQUENCE_DETAILS_SUCCESS,
  SELECTED_SEQUENCE_DETAILS_FAIL
} from "../constants/listTableConstants";
export const getSequenceListReducer = (state = {}, action) => {
  switch (action.type) {
    case LIST_TABLE_REQUEST:
      return { loading: true };
    case LIST_TABLE_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case LIST_TABLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const selectedSequenceDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_SEQUENCE_DETAILS_REQUEST:
      return { loading: true };

    case SELECTED_SEQUENCE_DETAILS_SUCCESS:
      return { loading: false, payload: action.payload };

    case SELECTED_SEQUENCE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
