import React, { Fragment, useState } from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { HandleClearAllFilterFromStore } from "../../redux/store/storeHelper";
import { useDispatch } from "react-redux";
import {
  companyTableEndpointChange,
  tableEndpointChange,
} from "../../redux/actions/tableActions";
import CustomStyledMenu from "../Menu/CustomStyledMenu";
import IconComponent from "../Icon/IconComponent";
import { fontSize, fontWeight } from "../../utils/constants/UI";
import { BORDER, BRAND, GREY, PRIMARY } from "../../utils/constants/colors";
import Typography from "../Typography/Typography";
import TypographyInline from "../Typography/TypographyInline";

const styles = {
  coloredContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "13px 8px",
    backgroundColor: "#ffe9e9",
    borderBottom: "2px solid #ff4848",
    cursor: "pointer",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "13px 8px",
    borderBottom: "2px solid white",
    cursor: "pointer",
  },
  itemText: {
    color: "#626b7f",
    fontSize: "12px",
    fontWeight: "500",
    whiteSpace: "nowrap",
  },
  itemIcon: {
    color: "#626b7f",
  },
  coloredIcon: {
    color: "#ff4848",
  },
  coloredText: {
    color: "#ff4848",
    fontSize: "12px",
    fontWeight: "500",
  },
  toolTipPara: {
    fontWeight: "400",
    fontSize: "clamp(12px ,0.72vw, 24px)",
    color: "#626B7E",
    alignSelf: "center",
    margin: "0 5px",
    cursor: "pointer",
  },
  redTooltipPara: {
    fontWeight: "400",
    fontSize: "clamp(12px ,0.72vw, 24px)",
    color: "#ff4848",
    alignSelf: "center",
    margin: "0 5px",
    cursor: "pointer",
  },
  icon: {
    color: "#626B7E",
  },
  flex: {
    display: "flex",
    borderBottom: "1px solid #D3DAE3",
    padding: "0.6rem 1rem",
  },
};

const SidebarItem = ({ item, selected }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleHover = () => {
    setHovered(true);
  };
  const handleHoverLeave = () => {
    setHovered(false);
  };

  const handleClickTooltipElements = async (each) => {
    setHovered(false);
    setIsOpen(false);
    setAnchor(null);
    HandleClearAllFilterFromStore(dispatch, location, navigate);
    // set contacts state to new contacts.
    dispatch(tableEndpointChange("New Prospects"));
    dispatch(companyTableEndpointChange("New Companies"));

    //clearing session.
    sessionStorage.clear();
    if (each?.toolTipPath) {
      navigate(each?.toolTipPath);
    }
  };

  const handleMenuClose = () => {
    setAnchor(null);
    setIsOpen(false);
  };

  const handleMenuClick = (e) => {
    if (item?.toolTipElements?.length) {
      setAnchor(e.currentTarget);
      setIsOpen(true);
    }
  };

  const toolTipTitle = (
    <div>
      {item?.toolTipElements?.map((each, index) => {
        return !each?.notVisible ? (
          <div
            onMouseEnter={() => handleHover()}
            onMouseLeave={handleHoverLeave}
            onClick={() => handleClickTooltipElements(each)}
            key={each.title}
            style={{
              ...styles.flex,
              borderBottom:
                item?.toolTipElements?.length === index + 1
                  ? "none"
                  : `1px solid${BORDER.DEFAULT}`,
            }}
          >
            <IconComponent
              iconLabel={each.icon}
              fontSize={fontSize.DXS}
              color={hovered === each.title || location.pathname.includes(each.toolTipPath) ? BRAND.PRIMARY : GREY.SECONDARY}
            />
            <p
              style={
                hovered === each.title || location.pathname.includes(each.toolTipPath)
                  ? styles.redTooltipPara
                  : styles.toolTipPara
              }
            >
              {each.title}
            </p>
          </div>
        ) : null;
      })}
    </div>
  );

  return (
    <Fragment>
      <Box
        onClick={handleMenuClick}
        sx={selected ? styles.coloredContainer : styles.itemContainer}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
      >
        <IconComponent
          iconLabel={item.icon}
          fontSize={fontSize.DXS}
          color={hovered || selected ? PRIMARY.W_500 : GREY.SECONDARY}
        />
        <TypographyInline
          color={hovered || selected ? PRIMARY.W_500 : GREY.SECONDARY}
          fontWeight={fontWeight.MEDIUM}
          label={item.text}
          size="xs"
          isNoWrap={true}
        />
      </Box>
      <CustomStyledMenu
        open={isOpen}
        anchorEl={anchor}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "top",
        }}
        marginTop="0px !important"
        marginLeft="4.2rem !important"
      >
        {toolTipTitle}
      </CustomStyledMenu>
    </Fragment>
  );
};

export default SidebarItem;
