export const ICON_LABELS = {
  GOOGLE: "google",
  MICROSOFT: "microsoft",
  CONTENT_COPY_1: "content_copy",
  TODAY: "today",
  HOUR_GLASS_BOTTOM: "hourglass_bottom",
  SCHEDULE: "schedule",
  PUBLIC: "public",
  TIMER: "timer",
  CONTENT_COPY: "content_copy",
  DONE_ALL: "done_all",
  ADD: "add",
  FOLDER: "folder",
  MOVE: "drive_file_move",
  DELETEICON: "delete_outline",
  VIEW_ICON: "sort",
  ADD_WIDGET: "dashboard_customize",
  ACTION_ARROW: "keyboard_arrow_down",
  PERSON: "person",
  EVENT_NOTE: "event_note",
  SKIP_NEXT: "skip_next",
  CHECK_CIRCLE: "check_circle",
  ARROW_BACK: "arrow_back",
  VIEW_WEEK: "view_week",
  CHECK: "check",
  CLOUD_DOWNLOAD: "cloud_download",
  MAIL_LOCK: "mail_lock",
  LINK: "link",
  PUSH_PIN: "push_pin",
  DELETE: "delete",
  PAUSE: "pause",
  PLAY_ARROW: "play_arrow",
  DELETE_OUTLINE: "delete_outline",
  INSERT_DRIVE_FILE: "insert_drive_file",
  ALL: "article",
  CALL: "call",
  EMAIL: "email",
  INSERT_LINK: "insert_link",
  RADIO_BUTTON_UNCHECKED: "radio_button_unchecked",
  NAVIGATE_NEXT: "navigate_next",
  HELP_OUTLINE: "help_outline",
  NOTIFICATIONS_NONE: "notifications_none",
  SETTINGS: "settings",
  CURRENCY_EXCHANGE: "currency_exchange",
  LOGOUT: "logout",
  LOGOUTS: "logout",
  ACCESS_TIME: "access_time",
  VISIBILITY_OFF: "visibility_off",
  VISIBILITY_ON: "visibility_on",
  BOOKMARK: "bookmark",
  SAVE: "save",
  CONTACT_PAGE: "contact_page",
  FILTER_ALT: "filter_alt",
  CLOSE_BUTTON: "close_button",
  DOWNLOAD: "download",
  ADD_CIRCLE_OUTLINE: "add_circle_outline",
  DRIVE_FILE_MOVE: "drive_file_move",
  SORT: "sort",
  DASHBOARD_CUSTOMIZE: "dashboard_customize",
  KEYBOARD_ARROW_DOWN: "keyboard_arrow_down",
  KEYBOARD_ARROW_UP: "keyboard_arrow_up",
  KEYBOARD_ARROW_RIGHT: "keyboard_arrow_right",
  EDIT: "edit",
  FILE_UPLOAD: "file_upload",
  FACT_CHECK: "fact_check",
  TASK: "task",
  GRID_VIEW: "grid_view",
  COMPLETED: "check_circle",
  DESCRIPTION: "description",
  DOUBLE_ARROW: "double_arrow",
  LIST_ALT: "list_alt",
  APARTMENT: "apartment",
  REFRESH: "refresh",
  MANAGE_SEARCH: "manage_search",
  CAMPAIGN: "campaign",
  TRENDING_UP: "trending_up",
  UPLOAD_FILE: "upload_file",
  VIDEO_CHAT_ROUDED: "video_chat",
  EXTENSION: "extension",
  TUNE: "tune",
  MORE_VERT: "more_vert",
  REMOVE_CIRCLE: "remove_circle",
  FIBER_MANUAL_RECORD: "fiber_manual_record",
  CREATE_NEW_FOLDER: "create_new_folder",
  CLEAR_ALL: "clear_all",
  BUG_REPORT: "bug_report",
  CLOSE: "close",
  BUILD_CIRCLE: "build_circle",
  VIEW_COLUMN: "view_column",
  PLAYLIST_ADD: "playlist_add",
  POST_ADD: "post_add",
  DOWNLOAD_FOR_ONLINE: "download_for_offline",
  RESTART_ALT: "restart_alt",
  ARROW_UP: "arrow_upward",
  ARROW_DOWN: "arrow_downward",
  CANCEL: "cancel",
  DONE: "done",
  STICKY_NOTE: "sticky_note_2",
  REPLY: "reply",
  LINKEDIN: "linkedin",
  LOCK_PERSON: "lock_person",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INFO: "info_outlined",
  DISABLEDBYDEFAULT: "disabled_by_default",
  GROUPS: "groups",
  WORK: "work",
  KEY: "key",
  MONETIZATION_ON: "monetization_on",
  LOCATION_ON: "location_on",
  LANGUAGE: "language",
  DNS: "dns",
  DRAG_INDICATOR: "drag_indicator",
  ARROW_BACK_IOS: "arrow_back_ios",
  ARROW_FORWARD: "arrow_forward",
  ERROR: "report_gmailerrorred",
  SHIELD: "shield",
  FILE_COPY: "file_copy",
  FILE_OPEN: "file_open",
  VIEW_TIMELINE: "view_timeline",
  DATE_RANGE: "date_range",
  RESTORE: "restore",
  OPEN_IN_NEW: "open_in_new",
  EVENT: "event",
  COMMENT: "comment",
  SEND: "send",
  SEND_AND_ARCHIVE: "send_and_archive",
  REPLY_ALL: "reply_all",
  SHORTCUT: "shortcut",
  SHARE: "share",
  CACHED: "cached",
  VIDEO_CALL: "video_call",
  LINKOFF: "link_off",
  EXPAND_MORE: "expand_more",
  EXPAND_LESS: "expand_less",
  FLAG_ICON: "flag",
  REORDER: "reorder",
  HANDSHAKE: "handshake",
  ATTACH_MONEY: "attach_money",
  INSERT_INVITATION: "insert_invitation",
  CALL_TO_ACTION: "call_to_action",
  VIEW_AGENDA: "view_agenda",
  HORIZONTAL_SPLIT: "horizontal_split",
  PREVIEW: "preview",
  TEXTSNIP: "text_snippet",
  TABLE_ROWS: "table_rows",
  VIEW_KANBAN: "view_kanban",
  SETTINGS_ETHERNET: "settings_ethernet",
  ARTICLE: "article",
  TAG: "tag",
  FORMAT_BOLD: "format_bold",
  FORMAT_ITALIC: "format_italic",
  FORMAT_UNDERLINED: "format_underlined",
  STRIKE_THROUGH_ICON: "strikethrough_s",
  UNDO: "undo",
  REDO: "redo",
  FORMAT_COLOR_TEXT: "format_color_text",
  FORMAT_ALIGN_LEFT: "format_align_left",
  FORMAT_ALIGN_CENTER: "format_align_center",
  FORMAT_ALIGN_RIGHT: "format_align_right",
  FORMAT_LIST_NUMBERED: "format_list_numbered",
  FORMAT_LIST_BULLETED: "format_list_bulleted",
  CODE: "code",
  CODE_OFF: "code_off",
  DATA_OBJECT: "data_object",
  ATTACH_FILE: "attach_file",
  VIDEOCAM: "videocam",
  UNSUBSCRIBE: "unsubscribe",
  EMOJI_EMOTIONS: "emoji_emotions"
};
