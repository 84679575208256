import React, { Fragment } from 'react';
import SkeletonLoader from '../../../../components/Loaders/SkeletonLoader';
import { Box } from '@mui/material';
import classes from "../markComponents.module.css";
const items = [
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
    { type1: 'text', type2: "text" },
]

const StepsSkeleton = () => {


    return <Fragment>
        <Box className={ classes.loaderContainer } >
            {items.map((item, index) => {
                return <Box key={index} className={classes.flexContainerStats} >
                    <Box className={classes.containSkeletonStat} >
                        <SkeletonLoader
                            type={"rectangular"}
                            skeletonWidth={"52vw"}
                            skeletonHeight={"240px"}
                        />
                    </Box>
                </Box>
            })} 
        </Box>
    </Fragment>

}

export default StepsSkeleton;