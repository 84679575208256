import { makeStyles } from "@mui/styles";
import {
  ACTIONS,
  BASE,
  BORDER,
  BRAND,
  GREY,
  NAVY,
  SUCCESS,
} from "../../utils/constants/colors";
import {
  borderRadius,
  SCROLL_BAR,
  SHADOW,
} from "../../utils/constants/UI/uiConstants";
import { innerBorder } from "../../utils/helpers";

export const styles = {
  table: {
    position: "relative",
    borderCollapse: "collapse",
    height: "calc(100% - 60px)",
  },
  tableHeadContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderWidth: "1px 0px 1px 0px",
    borderStyle: "solid",
    borderColor: ACTIONS.DEFAULT,
    // boxShadow: `inset -1px 0 0 ${ACTIONS.DEFAULT}, inset 0 -1px 0 ${ACTIONS.DEFAULT}, inset 1px 0 0 ${ACTIONS.DEFAULT}, inset 0 1px 0 ${ACTIONS.DEFAULT}`,
    position: "sticky",
    top: "0",
    zIndex: "10",
    height: "46px",
    // width: "-webkit-fill-available",
    borderRight: `1px solid ${BORDER.DEFAULT}`,
    borderLeft: `1px solid ${BORDER.DEFAULT}`,
  },
  tableMainContainer: {
    borderRadius: "0px 0px 6px 6px",
    height: "100%",
  },
  tableHeadValue: {
    borderRight: `1px solid ${BORDER.DEFAULT}`,
    borderTop: `1px solid ${BORDER.DEFAULT}`,
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    backgroundColor: ACTIONS.HOVER,
    padding: "11px",
    "&:hover": {
      backgroundColor: ACTIONS.ALTERNATE,
    },
  },
  initialTableHeadValue: {
    display: "inline-flex",
    alignItems: "center",
    columnGap: "12px",
    backgroundColor: ACTIONS.HOVER,
    borderRight: `1px solid ${BORDER.DEFAULT}`,
    borderTop: `1px solid ${BORDER.DEFAULT}`,
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    "&:hover": {
      backgroundColor: ACTIONS.ALTERNATE,
    },
  },
  stickInitialHead: {
    position: "sticky",
    zIndex: "10",
    left: "0px",
    borderRight: "none",
    boxShadow: SHADOW.tableCells,
  },
  tableHeadCheckbox: {
    padding: "15px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `1px solid ${BORDER.DEFAULT}`,
  },
  tableHeadAllChecked: {
    padding: "8px 12px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "16px",
    boxShadow: SHADOW.md,
    position: "sticky",
    top: "0",
    left: "0",
    zIndex: "10",
    backgroundColor: BASE.WHITE,
  },
  tableBody: {
    height: "calc(100% - 50px)",
  },
  tableBodyRow: {
    display: "inline-flex",
    alignItems: "center",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    height: "56px",
    // width: "-webkit-fill-available"
  },
  textCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px 18px 12px",
    // textTransform: "capitalize",
  },
  amountCell: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "4px",
    padding: "18px 12px 18px 12px",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
  },
  boldTextCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "24px",
    padding: "18px 12px 18px 12px",
    background: BASE.WHITE,
    height: "55px",
    cursor: "pointer"
  },
  userInfoCell: {
    display: "flex",
    alignItems: "center",
    background: BASE.WHITE,
    overflow: "hidden",
    height: "55px",
    // borderBottom: `1px solid ${BORDER.DEFAULT}`,
    "& #copiedTextContainer": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
      "& #copiedTextContainer": {
        display: "flex",
      },
    },
  },
  userInfoCellWoChkbox: {
    display: "flex",
    alignItems: "center",
    background: BASE.WHITE,
    overflow: "hidden",
    padding: "0px 11px",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  stickyUserInfoCell: {
    position: "sticky",
    left: "0",
    boxShadow: SHADOW.tableCells,
    zIndex: 9,
  },
  checkboxAvatarContainer: {
    display: "inline-flex",
    alignItems: "center",
    columnGap: "12px",
  },
  checkboxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
  },
  stickyRowCheckbox: {
    position: "sticky",
    left: "0px",
  },
  userName: {
    display: "flex",
    gap: "4px",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "17.5px 12px 17.5px 12px",
    overflow: "hidden",
  },
  companyURLCell: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  companyURLCellPadding: {
    backgroundColor: BASE.WHITE,
    padding: "8.5px 12px 8.5px 0px",
  },
  companyIcon: {
    height: "18px",
    width: "18px",
  },
  companyNameLink: {
    whiteSpace: "nowrap",
    textDecoration: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer",
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  meetingLinkIcon: {
    whiteSpace: "nowrap",
    textDecoration: "none",
    cursor: "pointer",
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  actionCellContainer: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "18px 12px",
    "& :hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
    "& :active": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  actionIconCellPaddingContainer: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px 12px",
    gap: "10px",
    "& :hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
    "& :active": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  cursPointr: {
    cursor: "pointer",
  },
  sortIconStyle: {},
  headerLabelSortContainer: {
    display: "inline-flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    gap: "4px",
  },
  userInfoHeaderCellTotalPadding: {
    padding: "11px",
    width: "100%",
  },
  userInfoHeaderCellPadding: {
    padding: "11px 11px 11px 0px",
    width: "100%",
  },
  emailCell: {
    padding: "12px",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  emailTextCell: {
    padding: "17.5px 12px",
    "& #copiedTextContainer": {
      display: "none",
    },
    "&:hover": {
      "& #copiedTextContainer": {
        display: "flex",
      },
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  emailCopiedTextCellContainer: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    width: "100%",
  },
  copiedTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 4px",
    gap: "2px",
    borderRadius: borderRadius.tertiary,
    backgroundColor: SUCCESS.W_50,
  },
  marketingListCell: {
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  marketingListName: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "18px 12px 18px 12px",
    overflow: "hidden",
  },
  marketingListNameNotAllowed: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
    width: "100%",
    cursor: "not-allowed",
    whiteSpace: "nowrap",
    padding: "18px 12px 18px 12px",
    overflow: "hidden",
  },
  statsDataCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px 18px 12px",
    cursor: "pointer",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  textButtonCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "10px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  buttonCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "10px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  statusCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "14px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  textURLCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  durationTextCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  taskListCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  userDetailCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  companyDetailCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  taskTypeCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  dueTimeStampCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  stepsButtonContainer: {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  companyNameLabel: {
    textTransform: "capitalize",
  },
  taskListNameWithStatus: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "8px 12px ",
    overflow: "hidden",
  },
  taskListName: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "18px 12px ",
    overflow: "hidden",
  },
  leadStatusCell: {
    textTransform: "capitalize",
  },
  headerStripButton: {
    display: "flex",
    alignItems: "center",
    position: "sticky",
    left: "132px",
  },
  selectedAllLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    position: "sticky",
    left: "12px",
  },
  tabLineContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    paddingRight: "10px",
  },
  horizontalLine: {
    width: "0px",
    height: "20px",
  },
  sortImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: borderRadius.primary,
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  actionIconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    cursor: "pointer",
    boxShadow: innerBorder(BORDER.DARK),
    borderRadius: borderRadius.primary,
  },
  actionIconsDisabledContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    background: BORDER.DISABLED,
    boxShadow: innerBorder(BORDER.DISABLED),
    borderRadius: borderRadius.primary,
    cursor: "not-allowed",
  },
  emptyRecordContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    height: "100%",
  },
  actionIconsDisabled: {
    cursor: "not-allowed",
  },
  comapnyCellPadding: {
    padding: "0px 12px",
  },

  pinContainer: {
    position: "absolute",
    // padding:"2px 0"
  },
  pinIcon: {
    width: "18px",
    height: "18px",
  },
  dashboardDefault: {
    background: NAVY.W_50,
    borderRadius: "4px",
    padding: "2px 8px",
  },
  textCellAttendee: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px 18px 12px",
    textTransform: "capitalize",
    cursor: "pointer",
  },
};
