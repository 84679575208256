import React, { useState } from "react";
import { Box } from "@mui/system";
import {
  GOOGLE_ICON,
  MICROSOFT_IMAGE,
  NEW_LOGIN_REACHIQ_LOGO,
} from "../../utils/constants/assets";
import { styles } from "./styles";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  DASHBOARD_PATH,
  FORGOT_PASSWORD_PATH,
  INITIAL_ROUTE,
} from "../../utils/constants/routes";
import {
  emailValidation,
  loginPasswordValidation,
} from "../../utils/validations";
import { Anchor, RightsTag } from "../../components";
import {
  authEndpoints,
  REGISTRATION_LINK,
} from "../../utils/constants/httpConstants";
import { useSelector, useDispatch } from "react-redux";
import {
  tenantUsersDetail,
  userDetails,
} from "../../redux/actions/authActions";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  PRIVACY_POLICY_LINK,
  TERMS_SERVICE_LINK,
} from "../../utils/constants";
import {
  decryptEmail,
  filterQuery,
  handleGoogleAuth,
  handleGoogleAuthentication,
  handleMicrosoftAuthInitialize,
  handleMicrosoftAuthentication,
} from "../../utils/helpers";
import { POST } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { Button } from "../../components/Buttons";
import { STRINGS } from "./strings";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import InputField from "../../components/InputFields/InputField";
import { BRAND, GREY } from "../../utils/constants/colors";
import Typography from "../../components/Typography/Typography";
import Image from "../../components/Image/Image";
import { HandleClearAllFilterFromStore } from "../../redux/store/storeHelper";
import { Mixpanel } from "../../Apis/Mixpanel";

const LoginScreen = () => {
  const params = useParams();
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  let defaultEmail = params?.email;
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);
  const filteredQuery = filterQuery(allFiltersSelector);

  if (defaultEmail) {
    const decryptedEmail = decryptEmail(params?.email?.trim());
    defaultEmail = decryptedEmail;
  }
  const [email, setEmail] = useState(defaultEmail ? defaultEmail : "");
  const [password, setPassword] = useState("");
  const [validations, setValidations] = useState({ email: "", password: "" });
  const [passwordType, setPasswordType] = useState(STRINGS.password);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validatedEmail = emailValidation(email);
    const validatedPassword = loginPasswordValidation(password);
    HandleClearAllFilterFromStore(dispatch, location, navigate);

    if (validatedEmail.isValid && validatedPassword.isValid) {
      //Here trigger the event
      setValidations({
        email: "",
        password: "",
      });
      setLoading(true);

      let formdata = {
        email: email,
        password: password,
      };
      try {
        const response = await POST(authEndpoints.login, formdata, navigate);

        Mixpanel.track(STRINGS.SUCCESSFUL_LOGIN);
        Mixpanel.identify(response?.data?.data?.email);

        Mixpanel.people.set({
          $first_name: response?.data?.data?.first_name,
          $last_name: response?.data?.data?.last_name,
          $email: response?.data?.data?.email,
        });
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        dispatch(userDetails(response?.data?.data));
        dispatch(tenantUsersDetail());
        // to remove view id key stored in session storage.
        sessionStorage.clear();

        let onboardingInitialRoute = response?.data?.data;
        navigate({
          pathname: onboardingInitialRoute?.general_setting_setup
            ? DASHBOARD_PATH
            : INITIAL_ROUTE,
          search: createSearchParams({
            ...filteredQuery,
          }).toString(),
        });
      } catch (err) {
        console.log("err_____", err);
        EventEmitter.emit(
          EMITTER_ERROR,
          err?.data?.message || STRINGS.SOMETHING_WENT_WRONG
        );
        setLoading(false);
        // here error;
      }
    } else {
      Mixpanel.track(STRINGS.UNSUCCESSFULL_LOGIN);
      setValidations({
        email: validatedEmail.message,
        password: validatedPassword.message,
      });
    }
  };

  const togglePassword = () => {
    if (passwordType === STRINGS.password) {
      setPasswordType(STRINGS.TEXT);
      return;
    }

    setPasswordType(STRINGS.password);
  };
  return (
    <Box component="main" className={classes.container}>
      <Box className={classes.leftContainer}>
        <div>
          <div className={classes.containLogo}>
            <Image src={NEW_LOGIN_REACHIQ_LOGO} className={classes.logo} />
          </div>
          {/* Main auth form here */}
          <Box className={classes.Top}>
            <div className={classes.containLogo}>
              <Typography
                size={"dxs"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.SEMIBOLD}
                label={STRINGS.WELCOME_BACK}
              />
            </div>
            <div className={classes.containLogos}>
              <Typography
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.PLEASE_SIGNIN_ACCESS_ACCOUNT}
              />
            </div>
            <div className={classes.displayHead}>
              <button
                className={classes.socialAuthBtn}
                onClick={handleGoogleAuthentication}
              >
                <Image src={GOOGLE_ICON} className={classes.socialAuthIcon} />
                {STRINGS.SIGN_IN_GOOGLE}
              </button>{" "}
              <button
                className={classes.socialAuthBtn}
                onClick={handleMicrosoftAuthentication}
              >
                <Image
                  src={MICROSOFT_IMAGE}
                  className={classes.socialAuthIcon}
                />
                {STRINGS.SIGN_IN_WITH_MICROSOFT}{" "}
              </button>
            </div>

            <Box
              component="form"
              autoComplete="chrome-off"
              onSubmit={handleSubmit}
              noValidate
            >
              <div className={classes.Gap}>
                <div className={classes.Border} />
                <p className={classes.emailSignIn}>
                  <Typography
                    size={"sm"}
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.OR_CONTINUE_WITH}
                  />
                </p>
                <div className={classes.Border} />
              </div>
              <Box className={classes.email}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.EMAIL}
                />
                <InputField
                  variant={
                    validations.email.length > 1
                      ? STRINGS.ERROR
                      : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations.email.length > 1 ? validations.email : ""
                  }
                  placeholder={STRINGS.ENTER_EMAIL}
                  size={"md40"}
                  value={email}
                  type="email"
                  onChange={(e) => {
                    handleEmailChange(e);
                    setValidations({ ...validations, email: "" });
                  }}
                />
              </Box>

              <Box className={classes.pass}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.PASSWORD}
                />{" "}
                <InputField
                  variant={
                    validations.password.length
                      ? STRINGS.ERROR
                      : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations.password.length ? validations.password : ""
                  }
                  placeholder={STRINGS.ENTER_PASSWORD}
                  type={passwordType}
                  size={"md40"}
                  RightIcon={
                    passwordType === STRINGS.password
                      ? ICON_LABELS.VISIBILITY_OFF
                      : ICON_LABELS.VISIBILITY_ON
                  }
                  rightOnClick={() => togglePassword()}
                  value={password}
                  onChange={(e) => {
                    handlePasswordChange(e);
                    setValidations({ ...validations, password: "" });
                  }}
                />
              </Box>

              <Box className={classes.containForgotPassword}>
                <div
                  className={classes.forgotPassword}
                  onClick={() => handleNavigate(FORGOT_PASSWORD_PATH)}
                >
                  <Typography
                    size={"sm"}
                    color={BRAND.PRIMARY}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.FORGOT_PASSWORD}
                  />{" "}
                </div>
              </Box>
              <Box className={classes.containLoginBtn}>
                <Button
                  label={STRINGS.SIGNIN}
                  size={"md40"}
                  variant={"primary"}
                  isLoading={loading}
                />
              </Box>
              <Box className={classes.flex}>
                <Typography
                  size={"sm"}
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={STRINGS.DO_YOU_HAVE}
                />{" "}
                <Anchor
                  target="_blank"
                  href={REGISTRATION_LINK}
                  className={classes.authToggle}
                >
                  <div className={classes.hover}>
                    <Typography
                      size={"sm"}
                      color={BRAND.PRIMARY}
                      fontWeight={fontWeight.MEDIUM}
                      label={STRINGS.REGISTER}
                    />{" "}
                  </div>
                </Anchor>
              </Box>
            </Box>
          </Box>
          {/* Main auth form here */}
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.containPolicy}>
            <RightsTag className={classes.termsPara} />
            {/* Privacy and Terms. */}
            <Anchor
              href={PRIVACY_POLICY_LINK}
              className={classes.privacyLink}
              target="_blank"
            >
              <div className={classes.hovering}>
                {" "}
                <Typography
                  label={STRINGS.PRIVACY}
                  color={GREY.QUATINARY}
                  size={"xs"}
                  fontWeight={fontWeight.SEMIBOLD}
                />
              </div>
            </Anchor>

            <div className={classes.TypoHead}>
              <Typography
                label={STRINGS.AND}
                color={GREY.TERTIARY}
                size={"xs"}
                fontWeight={fontWeight.REGULAR}
              />
            </div>
            <Anchor
              href={TERMS_SERVICE_LINK}
              className={classes.privacyLink}
              target="_blank"
            >
              <div className={classes.hovering}>
                {" "}
                <Typography
                  label={STRINGS.TERMS}
                  color={GREY.QUATINARY}
                  size={"xs"}
                  fontWeight={fontWeight.SEMIBOLD}
                />
              </div>
            </Anchor>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default LoginScreen;
