import { Accordion } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  COMPANY_EMPLOYEES_COUNT,
  COMPANY_INFO_FILTER_LIST,
  DEFAULT_COMPANY_EMPLOYEES_COUNT,
  DEFAULT_EMAIL_VERIFICATION_TYPES,
  DEFAULT_SENIORIRT_TYPES,
  LOCATION_FILTER_LIST,
  PERSONAL_INFO_FILTER_LIST,
  staticTenantsHostNames,
} from "../../../utils/constants";
import { Box } from "@mui/system";
import { ADD_FILTER_Accordion } from "../../../utils/constants/styles";
import { AccordionSummary, AccordionDetails } from "../../../components";
import { useSelector } from "react-redux";
import { filterQuery } from "../../../utils/helpers";
import {
  CheckBoxValueFilter,
  CompanyNameFilter,
  CompanySizeValueFilter,
  EmailVerificationDateFilter,
  LastUpdatedAtFilter,
  MultiValueFilter,
  RevenueFilter,
  SingleValueFilter,
} from "../../../components/AppliedFilters";
import {
  filterHeaderCompanyCity,
  filterHeaderCompanyCountry,
  filterHeaderCompanyKeywords,
  filterHeaderCompanyName,
  filterHeaderCompanySeo,
  filterHeaderCompanyState,
  filterHeaderCompanyTechnologies,
  filterHeaderEmailVerification,
  filterHeaderPersonCity,
  filterHeaderPersonCountry,
  filterHeaderPersonState,
  filterHeaderSearchCompany,
  filterHeaderSearchJobTitle,
  filterHeaderSearchSeniority,
} from "../../../redux/actions/filterActions";
import { initialFilterPayload } from "../../../redux/store/storeConstants";
import { Button } from "../../../components/Buttons";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import { STRINGS } from "../strings";
import CustomDialog from "../../../components/Dialog/CustomDialog";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import IconComponent from "../../../components/Icon/IconComponent";
import { COMPANY_SCREEN_PATH } from "../../../utils/constants/routes";
import { useLocation } from "react-router-dom";
import classes from "./LeadsComponent.module.css";

const AddFiltermodal = ({
  leadType = STRINGS.PEOPLE,
  isOpenFromOutside = false,
}) => {
  const location = useLocation();

  const allFilters = useSelector((state) => state?.allFilters?.payload);

  const tableEndpointChangeSelector = useSelector(
    (state) => state.tableEndpointChange
  );
  const isSavedContactSelected =
    tableEndpointChangeSelector.payload === STRINGS.SAVED_CONTACT
      ? true
      : false;
  const filteredFilters = filterQuery(allFilters);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState("");

  const handleFilterChange = (filter) => {
    if (expanded === filter) setExpanded("");
    else setExpanded(filter);
  };

  const handleClose = () => {
    setOpen(!open);
    setExpanded("");
  };

  const checkMappedFilterVisibility = (filter, type = "") => {
    if (filteredFilters?.[filter + type]?.length) {
      return { status: true, value: filteredFilters?.[filter + type] };
    }
    if (
      !isNaN(filteredFilters?.exist_fields?.indexOf(filter)) &&
      filteredFilters?.exist_fields?.indexOf(filter) !== -1
    ) {
      return { status: true, value: [STRINGS.KNOWN] };
    }
    if (
      !isNaN(filteredFilters?.not_exist_fields?.indexOf(filter)) &&
      filteredFilters?.not_exist_fields?.indexOf(filter) !== -1
    ) {
      return { status: true, value: [STRINGS.UNKNOWN] };
    } else {
      return { status: false, value: [] };
    }
  };

  const checkMappedCompanyNameFilterVisibility = (filter, type = "") => {
    if (filteredFilters?.[filter + type]?.length) {
      return { status: true, value: filteredFilters?.[filter + type] };
    }
    if (
      !isNaN(filteredFilters?.exist_fields?.indexOf(STRINGS.COMPANY_NAME)) &&
      filteredFilters?.exist_fields?.indexOf(STRINGS.COMPANY_NAME) !== -1
    ) {
      return { status: true, value: [{ name: STRINGS.KNOWN }] };
    }
    if (
      !isNaN(
        filteredFilters?.not_exist_fields?.indexOf(STRINGS.COMPANY_NAME)
      ) &&
      filteredFilters?.not_exist_fields?.indexOf(STRINGS.COMPANY_NAME) !== -1
    ) {
      return { status: true, value: [{ name: STRINGS.UNKNOWN }] };
    } else {
      return { status: false, value: [] };
    }
  };

  const dialogHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.ADD_FILTERS,
    },
  };

  const APPLIED_FILTER_LABEL = [
    {
      component: SingleValueFilter,
      payload: {
        filterKey: STRINGS.NAME_SMALL,
        value: filteredFilters?.name,
        filter: STRINGS.NAME,
      },

      isVisible: checkMappedFilterVisibility(STRINGS.NAME_SMALL).status,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.JOB_TITLE,
        clearAction: filterHeaderSearchJobTitle,

        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: checkMappedFilterVisibility(STRINGS.JOB_TITLE_SMALL).value,
        filterKey: STRINGS.JOB_TITLE_SMALL,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.JOB_TITLE_SMALL).status,
    },
    {
      component: MultiValueFilter,

      payload: {
        filter: STRINGS.JOB_TITLE,
        clearAction: filterHeaderSearchJobTitle,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: filteredFilters?.job_title_not,
        filterKey: STRINGS.JOB_TITLE_SMALL,
        type: STRINGS.NOT,
      },

      isVisible: filteredFilters?.job_title_not?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.INDUSTRY,
        value: checkMappedFilterVisibility(STRINGS.INDUSTRY).value,
        filterKey: STRINGS.INDUSTRY,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
      },
      isVisible: checkMappedFilterVisibility(STRINGS.INDUSTRY).status,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.INDUSTRY,
        value: filteredFilters?.industry_not,
        filterKey: STRINGS.INDUSTRY,
        type: STRINGS.NOT,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
      },
      isVisible: filteredFilters?.industry_not?.length,
    },
    {
      component: CheckBoxValueFilter,
      payload: {
        filter: STRINGS.SENIORITY,
        filterKey: STRINGS.SENIORITY_SMALL,
        clearAction: filterHeaderSearchSeniority,
        array: DEFAULT_SENIORIRT_TYPES,
        filterType: "type",
        defaultActionValue: JSON.parse(JSON.stringify(DEFAULT_SENIORIRT_TYPES)),
        value: filteredFilters?.seniority,
      },

      isVisible: filteredFilters?.seniority?.length,
    },
    {
      component: CompanySizeValueFilter,
      payload: {
        filter: STRINGS.COMPANY_SIZE,
        filterKey: STRINGS.EMPLOYEE_NUMBER,
        clearAction: filterHeaderSearchCompany,
        filterType: STRINGS.INDEX,
        defaultActionValue: JSON.parse(
          JSON.stringify(DEFAULT_COMPANY_EMPLOYEES_COUNT)
        ),
        array: COMPANY_EMPLOYEES_COUNT,
        value: filteredFilters?.employee_number,
      },
      isVisible: filteredFilters?.employee_number?.length,
    },

    {
      component: EmailVerificationDateFilter,
      payload: {
        filter: STRINGS.EMAIL_VERIFY_DATE,
        clearAction: filterHeaderEmailVerification,
        defaultActionValue: JSON.parse(
          JSON.stringify(DEFAULT_EMAIL_VERIFICATION_TYPES)
        ),
        minDate: filteredFilters?.e_verify_min_date,
        maxDate: filteredFilters?.e_verify_max_date,
      },
      isVisible:
        filteredFilters?.e_verify_min_date?.length &&
        filteredFilters?.e_verify_max_date?.length,
    },
    {
      component: LastUpdatedAtFilter,
      payload: {
        filter: STRINGS.LAST_UPDATED_AT,
        clearAction: filterHeaderEmailVerification,
        defaultActionValue: JSON.parse(
          JSON.stringify(DEFAULT_EMAIL_VERIFICATION_TYPES)
        ),
        minDate: filteredFilters?.updated_min_date,
        maxDate: filteredFilters?.updated_max_date,
      },
      isVisible:
        filteredFilters?.updated_min_date?.length &&
        filteredFilters?.updated_max_date?.length,
    },

    {
      component: CheckBoxValueFilter,
      payload: {
        filter: STRINGS.EMAIL_VERIFICATION,
        clearAction: filterHeaderEmailVerification,
        defaultActionValue: JSON.parse(
          JSON.stringify(DEFAULT_EMAIL_VERIFICATION_TYPES)
        ),
        filterKey: STRINGS.EMAIL_VERIFICATION_SMALL,
        filterType: STRINGS.INDEX,
        array: DEFAULT_EMAIL_VERIFICATION_TYPES,
        value: filteredFilters?.email_verification,
      },
      isVisible: filteredFilters?.email_verification?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.PERSON_CITY,
        clearAction: filterHeaderPersonCity,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.PERSON_CITY_CAPS,
        value: checkMappedFilterVisibility(STRINGS.PERSON_CITY).value,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.PERSON_CITY).status,
    },

    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.PERSON_CITY_NOT,
        clearAction: filterHeaderPersonCity,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.PERSON_CITY_CAPS,
        value: filteredFilters?.person_city_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.person_city_not?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.PERSON_STATE,
        clearAction: filterHeaderPersonState,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.PERSON_STATE_CAPS,
        value: checkMappedFilterVisibility(STRINGS.PERSON_STATE).value,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.PERSON_STATE).status,
    },

    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.PERSON_STATE_NOT,
        clearAction: filterHeaderPersonState,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.PERSON_STATE_CAPS,
        value: filteredFilters?.person_state_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.person_state_not?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.PERSON_COUNTRY,
        clearAction: filterHeaderPersonCountry,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.PERSON_COUNTRY_CAPS,
        value: checkMappedFilterVisibility(STRINGS.PERSON_COUNTRY).value,
      },

      isVisible: checkMappedFilterVisibility(STRINGS.PERSON_COUNTRY).status,
    },

    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.PERSON_COUNTRY_NOT,
        clearAction: filterHeaderPersonCountry,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.PERSON_COUNTRY_CAPS,
        value: filteredFilters?.person_country_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.person_country_not?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.COMPANY_CITY,
        clearAction: filterHeaderCompanyCity,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.COMPANY_CITY_CAPS,
        value: checkMappedFilterVisibility(STRINGS.COMPANY_CITY).value,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.COMPANY_CITY).status,
    },

    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.COMPANY_CITY_NOT,
        clearAction: filterHeaderCompanyCity,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.COMPANY_CITY_CAPS,
        value: filteredFilters?.company_city_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.company_city_not?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.COMPANY_STATE,
        clearAction: filterHeaderCompanyState,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.COMPANY_STATE_CAPS,
        value: checkMappedFilterVisibility(STRINGS.COMPANY_STATE).value,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.COMPANY_STATE).status,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.COMPANY_STATE_NOT,
        clearAction: filterHeaderCompanyState,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.COMPANY_STATE_CAPS,
        value: filteredFilters?.company_state_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.company_state_not?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.COMPANY_COUNTRY,
        clearAction: filterHeaderCompanyCountry,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.COMPANY_COUNTRY_CAPS,
        value: checkMappedFilterVisibility(STRINGS.COMPANY_COUNTRY).value,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.COMPANY_COUNTRY).status,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.COMPANY_COUNTRY_NOT,
        clearAction: filterHeaderCompanyCountry,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.COMPANY_COUNTRY_CAPS,
        value: filteredFilters?.company_country_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.company_country_not?.length,
    },
    {
      component: CompanyNameFilter,
      payload: {
        filter: STRINGS.COMPANY,
        filterKey: STRINGS.COMPANY_IDS,
        clearAction: filterHeaderCompanyName,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: checkMappedCompanyNameFilterVisibility(STRINGS.COMPANY_IDS)
          .value,
      },
      isVisible: checkMappedCompanyNameFilterVisibility(STRINGS.COMPANY_IDS)
        .status,
    },
    {
      component: CompanyNameFilter,
      payload: {
        filter: STRINGS.COMPANY,
        filterKey: STRINGS.COMPANY_IDS,
        clearAction: filterHeaderCompanyName,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        type: STRINGS.NOT,
        value: filteredFilters?.company_ids_not,
      },
      isVisible: filteredFilters?.company_ids_not?.length,
    },

    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.COMPANY_KEYWORDS_CAPS,
        filterKey: STRINGS.COMPANY_KEYWORDS_ALL,
        clearAction: filterHeaderCompanyKeywords,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: filteredFilters?.company_keywords_all,
        type: STRINGS._ALL,
      },
      isVisible: filteredFilters?.company_keywords_all?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.COMPANY_KEYWORDS_CAPS,
        filterKey: STRINGS.COMPANY_KEYWORDS,
        clearAction: filterHeaderCompanyKeywords,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: checkMappedFilterVisibility(STRINGS.COMPANY_KEYWORDS).value,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.COMPANY_KEYWORDS).status,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.COMPANY_KEYWORDS_NOT,
        clearAction: filterHeaderCompanyKeywords,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.COMPANY_KEYWORDS_CAPS,
        value: filteredFilters?.company_keywords_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.company_keywords_not?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.COMPANY_TECHNOLOGIES_CAPS,
        filterKey: STRINGS.COMPANY_TECHNOLOGIES_ALL,
        clearAction: filterHeaderCompanyTechnologies,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: filteredFilters?.company_technologies_all,
        type: STRINGS._ALL,
      },
      isVisible: filteredFilters?.company_technologies_all?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filterKey: STRINGS.COMPANY_TECHNOLOGIES,
        clearAction: filterHeaderCompanyTechnologies,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        filter: STRINGS.COMPANY_TECHNOLOGIES_CAPS,
        value: checkMappedFilterVisibility(STRINGS.COMPANY_TECHNOLOGIES).value,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.COMPANY_TECHNOLOGIES)
        .status,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.COMPANY_TECHNOLOGIES_CAPS,
        filterKey: STRINGS.COMPANY_TECHNOLOGIES_NOT,
        clearAction: filterHeaderCompanyTechnologies,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: filteredFilters?.company_technologies_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.company_technologies_not?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.COMPANY_DESCRIPTION,
        filterKey: STRINGS.SEO_DESC_ALL,
        clearAction: filterHeaderCompanySeo,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: filteredFilters?.seo_description_all,
        type: STRINGS._ALL,
      },
      isVisible: filteredFilters?.seo_description_all?.length,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.COMPANY_DESCRIPTION,
        filterKey: STRINGS.SEO_DESCRIPTION,
        clearAction: filterHeaderCompanySeo,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: checkMappedFilterVisibility(STRINGS.SEO_DESCRIPTION).value,
      },
      isVisible: checkMappedFilterVisibility(STRINGS.SEO_DESCRIPTION).status,
    },
    {
      component: MultiValueFilter,
      payload: {
        filter: STRINGS.COMPANY_DESCRIPTION,
        filterKey: STRINGS.SEO_DESC_NOT,
        clearAction: filterHeaderCompanySeo,
        defaultActionValue: JSON.parse(JSON.stringify(initialFilterPayload)),
        value: filteredFilters?.seo_description_not,
        type: STRINGS.NOT,
      },
      isVisible: filteredFilters?.seo_description_not?.length,
    },
    {
      component: RevenueFilter,
      payload: {
        minRevenue: filteredFilters?.min_revenue,
        maxRevenue: filteredFilters?.max_revenue,
        filter: STRINGS.MIN_REVENUE,
      },
      isVisible: filteredFilters?.min_revenue?.toString(),
    },
  ];

  useEffect(() => {
    setOpen(isOpenFromOutside);
  }, [isOpenFromOutside]);

  return (
    <>
      <div className={classes.addFilter}>
        <Button
          LeftIcon={ICON_LABELS.FILTER_ALT}
          size={"sm36"}
          label={STRINGS.ADD_FILTER}
          variant={"text"}
          onClick={handleClose}
        />
      </div>

      <CustomDialog
        header={dialogHeader}
        open={open}
        remove_padding
        onClose={handleClose}
        width={"500px"}
      >
        {/* Filter Applied Label on the top of Dialog */}
        <div className={classes.containAllFilters}>
          {APPLIED_FILTER_LABEL?.map((item) => {
            return item?.isVisible && <item.component {...item.payload} />;
          })}

          <div
            className={
              location.pathname !== COMPANY_SCREEN_PATH
                ? classes.normalFiltersContainer
                : classes.oppositeFiltersContainer
            }
          >
            {/* Filter Applied Label on the top of Dialog */}
            {/* Filters of Personal Information */}
            <div>
              {location.pathname !== COMPANY_SCREEN_PATH ? (
                <div className={classes.filterCategoryLabel}>
                  <TypographyInline
                    label={STRINGS.PERSONAL_INFORMATION}
                    size={"sm"}
                    fontWeight={fontWeight.MEDIUM}
                    color={GREY.PRIMARY}
                  />
                </div>
              ) : null}

              {leadType === STRINGS.COMPANY
                ? PERSONAL_INFO_FILTER_LIST.map((filter) => {
                  return (
                    filter.checkStatic === STRINGS.CHECK &&
                    (window.location.host.includes(STRINGS.RESOURCIFI) ||
                      window.location.host.includes(
                        STRINGS.AGENCY_FOR_AGENCIES
                      ) ||
                      window.location.host.includes(STRINGS.DEMO)) &&
                    filter.companyFilter && (
                      <Box key={filter.filterName}>
                        <Accordion
                          disableGutters
                          key={filter.filterName}
                          expanded={expanded === filter.filterName}
                          onChange={() =>
                            handleFilterChange(filter.filterName)
                          }
                          sx={ADD_FILTER_Accordion}
                        >
                          <AccordionSummary
                            className={classes.accordionSummary}
                          >
                            <Box
                              className={
                                expanded === filter.filterName
                                  ? classes.containEachOpenFilter
                                  : classes.containEachFilter
                              }
                            >
                              <TypographyInline
                                label={filter.filterName}
                                size={"sm"}
                                fontWeight={fontWeight.MEDIUM}
                                color={GREY.SECONDARY}
                              />
                              {expanded === filter.filterName ? (
                                <IconComponent
                                  color={GREY.TERTIARY}
                                  fontSize={fontSize.DXS}
                                  iconLabel={ICON_LABELS.CLOSE}
                                />
                              ) : (
                                <IconComponent
                                  color={GREY.TERTIARY}
                                  fontSize={fontSize.DXS}
                                  iconLabel={ICON_LABELS.ADD}
                                />
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            {<filter.component />}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                  );
                })
                : PERSONAL_INFO_FILTER_LIST.map((filter) => {
                  let host = window.location.host?.split(".")?.[0];
                  return filter.checkStatic === STRINGS.CHECK &&
                    !(staticTenantsHostNames?.includes(host))
                    ? null
                    : (!filter.onlySavedContactFilter ||
                      isSavedContactSelected) && (
                      <Box key={filter.filterName}>
                        <Accordion
                          disableGutters
                          key={filter.filterName}
                          expanded={expanded === filter.filterName}
                          onChange={() =>
                            handleFilterChange(filter.filterName)
                          }
                          sx={ADD_FILTER_Accordion}
                        >
                          <AccordionSummary
                            className={classes.accordionSummary}
                          >
                            <Box
                              className={
                                expanded === filter.filterName
                                  ? classes.containEachOpenFilter
                                  : classes.containEachFilter
                              }
                            >
                              <TypographyInline
                                label={filter.filterName}
                                size={"sm"}
                                fontWeight={fontWeight.MEDIUM}
                                color={GREY.SECONDARY}
                              />
                              {expanded === filter.filterName ? (
                                <IconComponent
                                  color={GREY.TERTIARY}
                                  fontSize={fontSize.DXS}
                                  iconLabel={ICON_LABELS.CLOSE}
                                />
                              ) : (
                                <IconComponent
                                  color={GREY.TERTIARY}
                                  fontSize={fontSize.DXS}
                                  iconLabel={ICON_LABELS.ADD}
                                />
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            {<filter.component />}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    );
                })}
            </div>
            {/* Filters of Personal Information */}

            {/* Filters of Location */}
            <div>
              <div className={classes.filterCategoryLabel}>
                <TypographyInline
                  label={STRINGS.LOCATION}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.PRIMARY}
                />
              </div>
              {LOCATION_FILTER_LIST.map((filter) => {
                return (
                  <Box key={filter.filterName}>
                    <Accordion
                      disableGutters
                      key={filter.filterName}
                      expanded={expanded === filter.filterName}
                      onChange={() => handleFilterChange(filter.filterName)}
                      sx={ADD_FILTER_Accordion}
                    >
                      <AccordionSummary className={classes.accordionSummary}>
                        <Box
                          className={
                            expanded === filter.filterName
                              ? classes.containEachOpenFilter
                              : classes.containEachFilter
                          }
                        >
                          <TypographyInline
                            label={filter.filterName}
                            size={"sm"}
                            fontWeight={fontWeight.MEDIUM}
                            color={GREY.SECONDARY}
                          />
                          {expanded === filter.filterName ? (
                            <IconComponent
                              color={GREY.TERTIARY}
                              fontSize={fontSize.DXS}
                              iconLabel={ICON_LABELS.CLOSE}
                            />
                          ) : (
                            <IconComponent
                              color={GREY.TERTIARY}
                              fontSize={fontSize.DXS}
                              iconLabel={ICON_LABELS.ADD}
                            />
                          )}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <filter.component type={leadType} />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              })}
            </div>
            {/* Filters of Location */}

            {/* Filters of Company Info */}
            <div>
              <div className={classes.filterCategoryLabel}>
                <TypographyInline
                  label={STRINGS.COMPANY_INFO}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.PRIMARY}
                />
              </div>
              {COMPANY_INFO_FILTER_LIST.map((filter) => {
                return (
                  <Box key={filter.filterName}>
                    <Accordion
                      disableGutters
                      key={filter.filterName}
                      expanded={expanded === filter.filterName}
                      onChange={() => handleFilterChange(filter.filterName)}
                      sx={ADD_FILTER_Accordion}
                    >
                      <AccordionSummary className={classes.accordionSummary}>
                        <Box
                          className={
                            expanded === filter.filterName
                              ? classes.containEachOpenFilter
                              : classes.containEachFilter
                          }
                        >
                          <TypographyInline
                            label={filter.filterName}
                            size={"sm"}
                            fontWeight={fontWeight.MEDIUM}
                            color={GREY.SECONDARY}
                          />
                          {expanded === filter.filterName ? (
                            <IconComponent
                              color={GREY.TERTIARY}
                              fontSize={fontSize.DXS}
                              iconLabel={ICON_LABELS.CLOSE}
                            />
                          ) : (
                            <IconComponent
                              color={GREY.TERTIARY}
                              fontSize={fontSize.DXS}
                              iconLabel={ICON_LABELS.ADD}
                            />
                          )}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <filter.component />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              })}
            </div>
          </div>
        </div>
        {/* Filters of Company Info */}
      </CustomDialog>
    </>
  );
};

export default AddFiltermodal;
