import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classes from "../PipelineScreen.module.css";
import { STRINGS } from "../strings";
import { IconComponent } from "../../../../components/Icon";
import { ICON_LABELS, fontSize } from "../../../../utils/constants/UI";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
import { IconButton, InputField } from "../../../../components";
import { BASE, GREY } from "../../../../utils/constants/colors";

const MultipleDealsValues = ({
  propertiesVal,
  setpropertiesVal,
  getUsers,
  loadTable,
  updateParentState,
}) => {

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(propertiesVal);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const item = items.map((element, index) => {
      return { ...element, order: parseInt(index) }
    });
    setpropertiesVal(item);
    updateParentState(true);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? BASE.BACKGROUND_LIGHT : BASE.WHITE,
    height: "100%",
  });
  console.log("propertiesVal ", propertiesVal, propertiesVal?.length);
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="inputValues">
        {(provided, snapshot) => (
          <div
            className={classes.multiValueMainContainer}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {propertiesVal?.map((val, index) => {
              return (
                <Draggable
                  key={val?.id}
                  draggableId={val?.id?.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={`${classes.multiValueSubContainer} ${index === propertiesVal?.length - 1 &&
                        classes.borderRaidiusValueSubContainer
                        }`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {/* drag icon and stage name input field */}
                      <div className={classes.iconFieldContainer}>
                        {/* drag indictor icon */}
                        <div className={classes.iconContainer}>
                          <IconComponent
                            color={GREY.TERTIARY}
                            fontSize={fontSize.DXS}
                            iconLabel={ICON_LABELS.DRAG_INDICATOR}
                          />
                        </div>

                        {/* stage name input field */}
                        <div
                          className={`${classes.inputFieldContainer} ${val?.setError2 && !val?.setError ? classes.marginBottomIfErr : ""
                            }`}
                        >

                          <div
                            className={
                              propertiesVal?.length > 1
                                ? classes.deleteIconContainer
                                : classes.deleteDisableIconContainer
                            }
                          >
                            {loadTable ? (
                              Array.from({ length: 1 })?.map(() => (
                                <SkeletonLoader
                                  type="rounded"
                                  skeletonWidth={150}
                                  skeletonHeight={30}
                                />
                              ))
                            ) : (
                              <InputField
                                size={"sm36"}
                                onChange={(event) => {
                                  val?.labelOnchange(event, val, propertiesVal);
                                }}
                                value={val?.labelValue || val?.name}
                                errormessage={
                                  val?.setError ? STRINGS.REQUIRED : ""
                                }
                                variant={val.setError ? "error" : "default"}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {/* deal probability input field */}
                      <div
                        className={`${classes.middleInputContainer} ${val?.setError && !val?.setError2 ? classes.marginBottomIfErr : ""
                          }`}
                      >
                        <div
                          className={
                            propertiesVal?.length > 1
                              ? classes.deleteIconContainer
                              : classes.deleteDisableIconContainer
                          }
                        >
                          {loadTable ? (
                            Array.from({ length: 1 })?.map(() => (
                              <SkeletonLoader
                                type="rounded"
                                skeletonWidth={150}
                                skeletonHeight={30}
                              />
                            ))
                          ) : (
                            <InputField
                              type={"number"}
                              size={"sm36"}
                              onChange={(event) => {
                                val?.probabilityChange(
                                  event,
                                  val,
                                  propertiesVal
                                );
                              }}
                              value={
                                Array.isArray(val?.probability)
                                  ? val?.probability[0]
                                  : val?.probability
                              }
                              errormessage={
                                val?.setError2 ? STRINGS.REQUIRED : ""
                              }
                              variant={val.setError2 ? "error" : "default"}
                            />
                          )}
                        </div>
                      </div>

                      {/* color picker and delete icon */}
                      <div className={classes.colorPickerContainer}>
                        {/* color picker */}
                        <div
                          className={`${classes.colorPickerFieldContainer} ${val?.setError || val?.setError2 ? classes.marginBottomIfErr : ""
                            }`}
                        >
                          <div
                            className={
                              propertiesVal?.length > 1
                                ? classes.deleteIconContainer
                                : classes.deleteDisableIconContainer
                            }
                          >
                            {loadTable ? (
                              Array.from({ length: 1 })?.map(() => (
                                <SkeletonLoader
                                  type="rounded"
                                  skeletonWidth={150}
                                  skeletonHeight={30}
                                />
                              ))
                            ) : (
                              <InputField
                                type="color"
                                size="xs34"
                                onChange={(event) => {
                                  val?.colorChangeHandler(event, val, propertiesVal)
                                }}
                                value={val.color}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {/* action delete icon */}
                      <div
                        className={`${classes.deleteIconMainContainer} ${val?.setError || val?.setError2 ? classes.marginBottomIfErr : ""
                          }`}
                      >
                        <div><IconButton LeftIcon={ICON_LABELS.DELETE} size="sm36" variant="ghost" onClick={() => val?.onDeleteClick(val, propertiesVal)} disabled={propertiesVal?.length < 2} /></div>
                        {/* <IconComponent
                          disabled={getUsers?.length === 0}
                          color={
                            propertiesVal?.length > 1
                              ? GREY.TERTIARY
                              : GREY.DISABLED
                          }
                          fontSize={fontSize.DXS}
                          iconLabel={ICON_LABELS.DELETE_OUTLINE}
                          onClick={() => val?.onDeleteClick(val, propertiesVal)}
                        /> */}
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default MultipleDealsValues;
