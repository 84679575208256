import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { DRAWER_CONTENT_EXCEPTION } from "../../utils/constants";
import { REACHIQ_ICON } from "../../utils/constants/assets";
import { DASHBOARD_PATH, PEOPLE_SCREEN_PATH } from "../../utils/constants/routes";
import SidebarItem from "./SidebarItem";
import { useLocation, useNavigate } from "react-router-dom";
import ImageIcon from "../Icon/ImageIcon";
import ContactFormModal from "../Modals/ContactFormModal";
import IconComponent from "../Icon/IconComponent";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import { BASE, BORDER, GREY } from "../../utils/constants/colors";
import TypographyInline from "../Typography/TypographyInline";

const styles = {
  sidebarContainer: {
    display: "flex",
  },
  bugReportContainer: {
    paddingTop: "5vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  drawer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1vh 0 2.5vh 0",
    width: "5rem",
    borderRight: `1px solid ${BORDER.LIGHT}`,
    height: "100vh",
  },

  containIcon: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    bgcolor: "background.default",
    cursor: "pointer",
  },
  containBugIcon: {
    background: "rgba(211, 218, 227,0.5)",
    padding: "6px",
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
  },
  childrenContainer: {
    width: "calc(100% - 5rem)",
    background: BASE.BACKGROUND_LIGHT,
    maxHeight: "100vh",
    overflow: "hidden",
  },

  flex: {
    display: "flex",
    borderBottom: "1px solid #D3DAE3",
    padding: "0.6rem 1rem",
    cursor: "pointer",
  },
  navContainer: {
    overflow: "auto",
    background: "white",
  },
  navItemContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "60px",
  },
};

export default function DashboardLayout({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleModalChange = () => {
    setOpenConfirmation(!openConfirmation);
  };

  const handleSelection = (item) => {
    if (!item?.toolTipElements?.length) {
      item?.path && navigate(item.path);
    }
  };

  const checkSelected = (items) => {
    if (items?.length) {
      const paths = items?.map((item) => item?.toolTipPath);
      return paths?.some(item => location.pathname.includes(item));;
    }
    return false;
  };

  return (
    <Box sx={styles.sidebarContainer}>
      <Box sx={styles.drawer}>
        <Box sx={styles.navItemContainer}>
          <Box
            sx={styles.containIcon}
            onClick={() => navigate(DASHBOARD_PATH)}
          >
            <ImageIcon image={REACHIQ_ICON} width="40px" height="40px" />
          </Box>

          <Box sx={styles.navContainer}>
            <List>

              {DRAWER_CONTENT_EXCEPTION.map((item, index) => {
                return <div key={index} onClick={() => handleSelection(item)}>
                  <SidebarItem
                    itemIndex={index}
                    item={item}
                    selected={
                      location.pathname.includes(item.path) ||
                      checkSelected(item?.toolTipElements)
                    }
                  />
                </div>
              })}

            </List>
          </Box>
        </Box>
        <Box onClick={handleModalChange} sx={styles.bugReportContainer}>
          <Box sx={styles.containBugIcon}>
            <IconComponent
              iconLabel={ICON_LABELS.BUG_REPORT}
              color={GREY.SECONDARY}
            />
          </Box>
          <TypographyInline
            color={GREY.SECONDARY}
            fontWeight={fontWeight.MEDIUM}
            label={"Report a bug"}
            size="xs"
          />
        </Box>
      </Box>
      <Box component="main" sx={styles.childrenContainer}>
        {children}
      </Box>

      {/* Bug Report Modal */}
      <ContactFormModal open={openConfirmation} setOpen={setOpenConfirmation} />
      {/* Bug Report Modal */}
    </Box>
  );
}
