export const LOGIN_CONTENT_SUBTITLE =
  "Access a precise database of verified emails, B2B direct dials, and contact details from 2M companies worldwide with ReachIQ.";

export const LOGIN_CONTENT_TITLE1 = "Unlock Exponential Revenue Growth ";
export const LOGIN_CONTENT_TITLE2 = "With ReachIQ. ";

// export const LOGIN_CONTENT_POINT1 = "Download ReachIQ Extension"
// export const LOGIN_CONTENT_POINT2 = "Download ReachIQ Extension"

export const NO_RECORD_FOUND =
  "Sorry, We couldn't find anything. You can try another filter. ";
export const PEOPLE_CONTENT_SEARCH_PARA1 =
  "Refine your prospecting efforts with advanced filters, leveraging our extensive ";
export const PEOPLE_CONTENT_SEARCH_PARA2 =
  "database of unique business contacts. Easily narrow down your search based";
export const PEOPLE_CONTENT_SEARCH_PARA3 =
  "on industry, location, company size, and more, to target the most relevant leads";
export const PEOPLE_CONTENT_SEARCH_PARA4 = "for your business.";
