import { postRequest } from "../../Apis";
import { importEndpoints } from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";

import {
  IMPORT_CSVGET_FAIL,
  IMPORT_CSVGET_REQUEST,
  IMPORT_CSVGET_SUCCESS,
} from "../constants/csvContstants";

export const getImportCsv = (sentQuery, navigate) => async (dispatch) => {
  try {
    dispatch({ type: IMPORT_CSVGET_REQUEST });
    const response = await postRequest(
      navigate,
      importEndpoints.getImport,
      sentQuery
    );

    dispatch({
      type: IMPORT_CSVGET_SUCCESS,

      payload: { ...response?.data, query: sentQuery },
    });
  } catch (err) {
    if (err?.status === 401) {
      navigate(LOGIN_PATH);
    }
    dispatch({ type: IMPORT_CSVGET_FAIL, payload: err });
  }
};
