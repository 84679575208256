import { postRequest } from "../../Apis";
import { listEndPoints } from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";
import{
    LISTCONTACT_FOLDER_REQUEST,
    LISTCONTACT_FOLDER_SUCCESS,
    LISTCONTACT_FOLDER_FAIL
} from "../constants/contactListFolderConstants"

export const getContactListFolder=(sentQuery, navigate)=> async(dispatch)=>{
        try{
            dispatch({type:LISTCONTACT_FOLDER_REQUEST});
            const response=await postRequest(navigate,listEndPoints.showFolderList,sentQuery)
            dispatch({
                type:LISTCONTACT_FOLDER_SUCCESS,
                payload:{...response?.data,query:sentQuery}
            })
        }
        catch(err){
            if(err?.status === 401){
                navigate( LOGIN_PATH )
              }
              dispatch({ type:LISTCONTACT_FOLDER_FAIL , payload: err });
        }
}