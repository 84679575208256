import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styles } from "./styles";
import { postRequest } from "../../../../Apis";
import { authEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { passwordValidation } from "../../../../utils/validations";
import {
  confPasswordValidation,
  newPasswordvalidation,
} from "../../../../utils/validations/inputValidations";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Buttons";
import Typography from "../../../../components/Typography/Typography";
import { GREY } from "../../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import InputField from "../../../../components/InputFields/InputField";
import { Mixpanel } from "../../../../Apis/Mixpanel";

const Security = () => {
  const classes = styles();
  const navigate = useNavigate();
  const location = useLocation()
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [performing, setPerforming] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordType, setOldPasswordType] = useState(STRINGS.password);
  const [newPasswordType, setNewPasswordType] = useState(STRINGS.password);

  const [confirmpasswordType, setConfirmPasswordType] = useState(
    STRINGS.password
  );

  const [validations, setValidations] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChangePassword = async (e) => {
    e?.preventDefault();
    const validatedOldPassword = passwordValidation(oldPassword);
    const validatedConfirmPassword = confPasswordValidation(
      newPassword,
      confirmPassword
    );

    const validatedNewPassword = newPasswordvalidation(
      oldPassword,
      newPassword
    );

    if (
      validatedOldPassword.isValid &&
      validatedNewPassword.isValid &&
      validatedConfirmPassword.isValid
    ) {
      try {
        setValidations({
          newPassword: "",
          confirmPassword: "",
          oldPassword: "",
        });
        setPerforming(true);
        const payload = { old_password: oldPassword, password: newPassword };
        const response = await postRequest(
          navigate,
          authEndpoints.changePassword,
          payload
        );
        Mixpanel.track(" Password Changed Successfully ");

        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setPerforming(false);
      } catch (err) {
        // check errors object here.
        setPerforming(false);
        const errors = err?.data?.data?.errors;
        const error_message =
          errors?.old_password ||
          errors?.password ||
          err?.data?.message ||
          STRINGS.SOMETHING_WENT_WRONG;
        EventEmitter.emit(EMITTER_ERROR, error_message);
      }
    } else {
      setValidations({
        oldPassword: validatedOldPassword.message,
        newPassword: validatedNewPassword.message,
        confirmPassword: validatedConfirmPassword.message,
      });
    }
  };
  const togglePassword = () => {
    if (oldPasswordType === STRINGS.password) {
      setOldPasswordType(STRINGS.TEXT);
      return;
    }

    setOldPasswordType(STRINGS.password);
  };
  const togglePassword2 = () => {
    if (newPasswordType === STRINGS.password) {
      setNewPasswordType(STRINGS.TEXT);
      return;
    }

    setNewPasswordType(STRINGS.password);
  };
  const toggleConfirmPassword = () => {
    if (confirmpasswordType === STRINGS.password) {
      setConfirmPasswordType(STRINGS.TEXT);
      return;
    }

    setConfirmPasswordType(STRINGS.password);
  };


  return (
    <Box className={classes.container}>
      <Box className={classes.security}>
        <Box className={classes.SubHeader}>
          <Typography
            size={"md"}
            color={GREY.PRIMARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.SECURITY}
          />
        </Box>
        <Box
          className={classes.containSecurityBoxesWidth}
          component="form"
          onSubmit={handleChangePassword}
        >
          <div className={classes.dFlexSecurity}>
            <Typography
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.OLD_PASSWORD}
            />

            <InputField
              variant={
                validations?.oldPassword?.length > 1
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
              }
              errormessage={
                validations?.oldPassword ? validations?.oldPassword : ""
              }
              placeholder={STRINGS.OLD}
              type={oldPasswordType}
              RightIcon={
                oldPasswordType === STRINGS.password
                  ? ICON_LABELS.VISIBILITY_OFF
                  : ICON_LABELS.VISIBILITY_ON
              }
              rightOnClick={() => togglePassword()}
              size={"md40"}
              value={oldPassword}
              onChange={(e) => setOldPassword(e?.target?.value)}
            />
          </div>
          <div className={classes.dFlexSecurity}>
            <Typography
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.NEW_PASSWORD}
            />
            <InputField
              size={"md40"}
              type={newPasswordType}
              RightIcon={
                newPasswordType === STRINGS.password
                  ? ICON_LABELS.VISIBILITY_OFF
                  : ICON_LABELS.VISIBILITY_ON
              }
              rightOnClick={() => togglePassword2()}
              value={newPassword}
              onChange={(e) => setNewPassword(e?.target?.value)}
              placeholder={STRINGS.NEW}
              variant={
                validations?.newPassword?.length > 1
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
              }
              errormessage={
                validations?.newPassword ? validations?.newPassword : ""
              }
            />
          </div>
          <div className={classes.dFlexSecurity}>
            <Typography
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.CONFIRM_PASSWORD}
            />
            <InputField
              size={"md40"}
              type={confirmpasswordType}
              RightIcon={
                confirmpasswordType === STRINGS.password
                  ? ICON_LABELS.VISIBILITY_OFF
                  : ICON_LABELS.VISIBILITY_ON
              }
              rightOnClick={() => toggleConfirmPassword()}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e?.target?.value)}
              placeholder={STRINGS.CONFIRM}
              variant={
                validations?.confirmPassword?.length > 1
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
              }
              errormessage={
                validations?.confirmPassword
                  ? validations?.confirmPassword
                  : ""
              }
            />
          </div>

          <div className={classes.mxWd}>
            <Button
              label={STRINGS.UPDATE_PASSWORD}
              size={"sm36"}
              variant={"primary"}
              isLoading={performing}
              onClick={handleChangePassword}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};
export default Security;
