import React, { useEffect, useState } from "react";
import CustomDialog from "../../../../components/Dialog/CustomDialog";
import { STRINGS } from "../../strings";
import { Button } from "../../../../components/Buttons";
import classes from "./CrmComp.module.css";
import { useSelector } from "react-redux";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontSize, fontWeight } from "../../../../utils/constants/UI";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import InputField from "../../../../components/InputFields/InputField";
import TextArea from "../../../../components/InputFields/TextArea";
import { HtmlEditor } from "../../../../components";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import {
  dropdownValuesFromId,
  replaceKeyInArrayOfObj,
} from "../../../../utils/helpers";
import {
  emailValidation,
  nameValidation,
} from "../../../../utils/validations/inputValidations";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate, useParams } from "react-router-dom";
import {
  accountEndPoints,
  crmEndpoints,
  formsEndpoints,
} from "../../../../utils/constants/httpConstants";
import Checkbox from "../../../../components/Buttons/Checkbox/Checkbox";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import {
  COUNTRY_LIST,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../../utils/constants";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";

const CreateContactDialog = ({
  open = false,
  close = () => { },
  data = {},
  getContactDetails = () => { },
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );

  const [inputData, setInputData] = useState({ is_lead: true });
  const [inputDataId, setInputDataId] = useState({});
  const [inputError, setInputError] = useState({});
  const [addCompanyLoading, setAddCompanyLoading] = useState(false);
  const [countryList, setCountrylist] = useState(COUNTRY_LIST || []);
  const [companyList, setCompanyList] = useState([]);
  const [companyList2, setCompanyList2] = useState(companyList || []);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [requiredOrder, setRequiredOrder] = useState({});

  const isUpdate = Object.keys(data)?.length;

  // add contact into company handler.
  const saveContactHandler = async (e) => {
    e?.preventDefault();
    // validating all the required field.
    if (validationHandler(inputData)) {
      try {
        setAddCompanyLoading(true);
        const response = await postRequest(navigate, crmEndpoints.addContact, {
          query: {
            ...inputData,
            company_id: params.id,
            company_name: params.id,
          },
        });
        getContactDetails();
        close();
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      } finally {
        setAddCompanyLoading(false);
      }
    }
  };

  // validate add company required fields.
  const validationHandler = (inputData) => {

    // getting all the required fields id and storing it into array.
    let required_fields = [];
    let error_arr = [];
    let input_error = { ...inputError };

    Object.keys(requiredOrder)?.forEach((element, index) => {
      if (requiredOrder?.[element]) {
        required_fields.push(element);
      }
    });

    // loop on required ids for checking validation according to field_type
    required_fields?.forEach((element, index) => {
      if (element !== "email") {
        let validate = nameValidation(inputData?.[element]);

        if (validate?.isValid) {
          error_arr?.filter(item => item !== element);
          input_error = ({ ...input_error, [element]: "" });
        } else {
          error_arr = [...error_arr, element];
          input_error = ({ ...input_error, [element]: validate?.message });
        }
      }
    })

    // storing the error into state for displaying error for email
    const validatedEmail = emailValidation(inputData?.email);
    if (validatedEmail?.isValid) {
      error_arr?.filter(item => item !== "email");
      input_error = ({ ...input_error, email: "" });
    } else {
      error_arr = [...error_arr, "email"];
      input_error = ({ ...input_error, email: validatedEmail?.message });
    }
    setInputError({ ...input_error });

    // return value according to error key check in error_arr.
    if (error_arr?.length) {
      return false;
    } else {
      return true;
    }
  };

  // company name dropdown fields search handler.
  const searchHandler = (e, property) => {
    let countryValue = COUNTRY_LIST;
    // let tempItems = [...(getPropertiesListSelector?.data?.hits || [])];
    let data = [];
    if (property?.id === STRINGS.COMPANY_ID_SMALL) {
      data = companyList?.filter(
        (item) =>
          item?.company_name
            ?.toLowerCase()
            ?.includes(e?.target?.value?.toLowerCase()) ||
          item?.company_website
            ?.toLowerCase()
            ?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value !== "" || undefined) {
        setCompanyList2(data);
      } else {
        setCompanyList2(companyList);
      }
    } else if (property?.id === STRINGS.PERSON_COUNTRY) {
      data = countryValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setCountrylist(COUNTRY_LIST);
      } else {
        setCountrylist(data);
      }
    }
    // // Storing searched Data into main state.
    // let newData = [];
    // tempItems?.map((item) => {
    //   if (
    //     item?.id === property?.id &&
    //     item?.field_type?.toString() === STRINGS.SINGLE_SELECT
    //   ) {
    //     let obj = {
    //       ...item,
    //       value: data,
    //     };
    //     newData?.push(obj);
    //   } else {
    //     newData.push(item);
    //   }
    // });
    // setPropertiesData(newData);
  };

  const getValuesOfDropdown = (prop) => {
    if (prop?.id === STRINGS.PERSON_COUNTRY) {
      let country_list = countryList?.map((item) => {
        return {
          label: item?.label,
          id: item?.id,
        };
      });

      return country_list;
    } else if (prop?.id === STRINGS.COMPANY_ID_SMALL) {
      let country_list = companyList2?.map((item) => {
        return {
          label: item?.company_name || item?.company_website,
          id: item?._id,
        };
      });

      return country_list;
    } else {
      return replaceKeyInArrayOfObj(prop?.value, "labelValue", "label");
    }
  };

  // api handler for get fields order.
  const getFieldsOrder = async () => {
    try {
      setLoading(true)
      const response = await getRequest(
        navigate,
        formsEndpoints.getFormsField
      );
      let columns = [];
      let required_obj = {};
      response?.data?.data?.Contact?.sort((obj1, obj2) => obj1.order - obj2.order)?.forEach(element => {
        columns.push(element?.field_id);
        required_obj = { ...required_obj, ...{ [element.field_id]: element?.required } };
      });
      setRequiredOrder(required_obj);
      setFieldsOrder(columns || []);
    } catch (error) {
      console.log("Error------->>>>>> ", error);
    } finally {
      setLoading(false);
    }
  };


  // type id components for add company forms.
  const type_id_components = (prop) => {
    switch (prop?.field_type[0]) {
      case STRINGS.DATE_PICKER:
        return (
          <CustomDatePicker
            size={"sm36"}
            value={inputData[prop?.id] || new Date()}
            onChange={(dateValue) =>
              setInputData({ ...inputData, [prop?.id]: dateValue })
            }
          />
        );

      case STRINGS.NUMBER:
        return (
          <InputField
            size={"sm36"}
            type={"number"}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            value={inputData[prop?.id]}
            placeholder={`Enter a ${prop?.property_name}`}
          />
        );

      case STRINGS.MULTI_LINE_TEXT_INPUT:
        return (
          <TextArea
            rows={"5"}
            onChange={(e) =>
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              })
            }
            value={inputData[prop?.id]}
          />
        );

      case STRINGS.RICH_TEXT_EDITOR:
        return (
          <div className={classes.htmlEditor}>
            <HtmlEditor
              editorHeight={"12vh"}
              previosData={inputData[prop?.id] || ""}
              onInput={() => {
                setInputData({
                  ...inputData,
                  [prop?.id]: document?.getElementById("editor")?.innerHTML,
                });
              }}
              isAITagNotVisible
              isAddTagNotVisible
              isMeetingTagNotVisible
              isFocusNotRequired
            />
          </div>
        );

      case STRINGS.SINGLE_SELECT:
        return (
          <AutoCompleteCustom
            dropdownId="add_company_dropdown"
            items={getValuesOfDropdown(prop)}
            size={"sm36"}
            valueState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputDataId[prop?.id]]
                : [inputData[prop?.id]]
            }
            setValueState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                })
                : setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                });
            }}
            idState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputData[prop?.id]]
                : [inputDataId[prop?.id]]
            }
            setIdState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                })
                : setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                });
            }}
            isSearchEnable={
              prop?.property_name === STRINGS.COMPANY ||
              STRINGS.PERSON_COUNTRY_CAPS ||
              STRINGS.COMPANY_ID_SMALL
            }
            handleSearch={(event) => {
              searchHandler(event, prop);
            }}
          />
        );

      case STRINGS.MULTIPLE_SELECT:
        return (
          <AutoCompleteCustom
            dropdownId="add_company_dropdown_multiple"
            items={replaceKeyInArrayOfObj(prop?.value, "labelValue", "label")}
            size={"sm36"}
            multiple
            valueState={inputData[prop?.id]}
            setValueState={(data) => {
              setInputData({
                ...inputData,
                [prop?.id]: data,
              });
            }}
            idState={inputDataId[prop?.id]}
            setIdState={(data) => {
              setInputDataId({
                ...inputDataId,
                [prop?.id]: data,
              });
            }}
          />
        );

      default:
        return (
          <InputField
            size={"sm36"}
            placeholder={`Enter a ${prop?.property_name}`}
            value={inputData[prop?.id]}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            variant={
              inputError[prop?.id]?.length ? STRINGS.ERROR : STRINGS.DEFAULT
            }
            errormessage={inputError[prop?.id]}
          />
        );
    }
  };

  const dialogHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.ADD_CONTACTS,
    },
  };

  const dialogFooter = (
    <div className={classes.addDialogFooter}>
      <div>
        <Button
          label={STRINGS.CANCEL}
          size="sm36"
          variant={"ghost"}
          onClick={close}
        />
      </div>
      <div>
        <Button
          label={STRINGS.ADD_CONTACTS}
          size="sm36"
          variant={"primary"}
          onClick={saveContactHandler}
          isLoading={addCompanyLoading}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (isUpdate) {
      setInputData({ ...data });
    }
  }, [data]);

  useEffect(() => {
    searchHandler();
  }, [countryList]);

  useEffect(async () => {
    getFieldsOrder();

    const table_columns = ["company_name", "email", "company_website"];

    const query = { ...table_columns };
    const response = await postRequest(
      navigate,
      accountEndPoints.accountSearch,
      query
    );

    setCompanyList(response?.data?.data?.records);
    setCompanyList2(response?.data?.data?.records);
  }, []);

  return (
    <CustomDialog
      open={open}
      close={close}
      header={dialogHeader}
      footer={dialogFooter}
      width={"26.042vw"}
      remove_padding
    >

      {loading ? (
        <div className={classes.addContactFormLoaderContainer}>
          {Array.from({ length: 20 }).map((index) => {
            return (
              <div key={`addContact${index}`}>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"30px"}
                  stackWidth={"100%"}
                />
              </div>
            );
          })}
        </div>
      ) : (
        // {/* add or update company form */ }
        < div className={classes.summaryContainerHeads}>
          <form className={classes.inputForm} onSubmit={saveContactHandler}>
            {fieldsOrder?.map((field) => {
              let propertyKeyValue = { ...getPropertiesListSelector?.data?.properties_key_value };
              return (
                <div className={classes.inputLabelField}>
                  <div className={classes.labelButtonContainer}>
                    <TypographyInline
                      label={propertyKeyValue?.[field]?.property_name}
                      color={GREY.QUATINARY}
                      size={"sm"}
                      fontWeight={fontWeight.MEDIUM}
                      isRequired={requiredOrder?.[field]}
                    />
                  </div>
                  {type_id_components(propertyKeyValue?.[field])}
                </div>
              );
            })}
            <div>
              <Checkbox
                size={fontSize.SM}
                checked={inputData.is_lead}
                onChange={(e) => {
                  e?.stopPropagation();
                  setInputData({ ...inputData, is_lead: !inputData.is_lead });
                }}
                label={STRINGS.MARK_AS_LEAD}
              />
            </div>
          </form>
        </div>
      )
      }
    </CustomDialog >
  );
};

export default CreateContactDialog;
