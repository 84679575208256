import {
    LISTCONTACT_REQUEST,
    LISTCONTACT_SUCCESS,
    LISTCONTACT_FAIL
} from "../constants/contactListConstants"

export const getContactListDataReducer=(state={},action) => {
    switch(action.type){
        case LISTCONTACT_REQUEST:
            return { loading: true };
          case LISTCONTACT_SUCCESS:
            return { loading: false, data: action.payload, error: null };
          case LISTCONTACT_FAIL:
            return { loading: false, error: action.payload };
          default:
            return state;
    }
}