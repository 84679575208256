import {
  ALL_DYNAMIC_FILTERS_FAIL,
  ALL_DYNAMIC_FILTERS_REQUEST,
  ALL_DYNAMIC_FILTERS_SUCCESS,
  ALL_FILTERS_FAIL,
  ALL_FILTERS_REQUEST,
  ALL_FILTERS_SUCCESS,
  FILTER_HEADER_ACCOUNT_LIST_FAIL,
  FILTER_HEADER_ACCOUNT_LIST_REQUEST,
  FILTER_HEADER_ACCOUNT_LIST_SUCCESS,
  FILTER_HEADER_COMPANY_CITY_FAIL,
  FILTER_HEADER_COMPANY_CITY_REQUEST,
  FILTER_HEADER_COMPANY_CITY_SUCCESS,
  FILTER_HEADER_COMPANY_COUNTRY_FAIL,
  FILTER_HEADER_COMPANY_COUNTRY_REQUEST,
  FILTER_HEADER_COMPANY_COUNTRY_SUCCESS,
  FILTER_HEADER_COMPANY_FAIL,
  FILTER_HEADER_COMPANY_KEYWORDS_FAIL,
  FILTER_HEADER_COMPANY_KEYWORDS_REQUEST,
  FILTER_HEADER_COMPANY_KEYWORDS_SUCCESS,
  FILTER_HEADER_COMPANY_NAME_FAIL,
  FILTER_HEADER_COMPANY_NAME_REQUEST,
  FILTER_HEADER_COMPANY_NAME_SUCCESS,
  FILTER_HEADER_COMPANY_REQUEST,
  FILTER_HEADER_COMPANY_SEO_FAIL,
  FILTER_HEADER_COMPANY_SEO_REQUEST,
  FILTER_HEADER_COMPANY_SEO_SUCCESS,
  FILTER_HEADER_COMPANY_STATE_FAIL,
  FILTER_HEADER_COMPANY_STATE_REQUEST,
  FILTER_HEADER_COMPANY_STATE_SUCCESS,
  FILTER_HEADER_COMPANY_SUCCESS,
  FILTER_HEADER_COMPANY_TECHNOLOGIES_FAIL,
  FILTER_HEADER_COMPANY_TECHNOLOGIES_REQUEST,
  FILTER_HEADER_COMPANY_TECHNOLOGIES_SUCCESS,
  FILTER_HEADER_CONTACT_LIST_FAIL,
  FILTER_HEADER_CONTACT_LIST_REQUEST,
  FILTER_HEADER_CONTACT_LIST_SUCCESS,
  FILTER_HEADER_EMAIL_VERIFICATION_FAIL,
  FILTER_HEADER_EMAIL_VERIFICATION_REQUEST,
  FILTER_HEADER_EMAIL_VERIFICATION_SUCCESS,
  FILTER_HEADER_PERSON_CITY_FAIL,
  FILTER_HEADER_PERSON_CITY_REQUEST,
  FILTER_HEADER_PERSON_CITY_SUCCESS,
  FILTER_HEADER_PERSON_COUNTRY_FAIL,
  FILTER_HEADER_PERSON_COUNTRY_REQUEST,
  FILTER_HEADER_PERSON_COUNTRY_SUCCESS,
  FILTER_HEADER_PERSON_STATE_FAIL,
  FILTER_HEADER_PERSON_STATE_REQUEST,
  FILTER_HEADER_PERSON_STATE_SUCCESS,
  FILTER_HEADER_SEARCH_FAIL,
  FILTER_HEADER_SEARCH_INDUSTRY_FAIL,
  FILTER_HEADER_SEARCH_INDUSTRY_REQUEST,
  FILTER_HEADER_SEARCH_INDUSTRY_SUCCESS,
  FILTER_HEADER_SEARCH_JOBTITLE_FAIL,
  FILTER_HEADER_SEARCH_JOBTITLE_REQUEST,
  FILTER_HEADER_SEARCH_JOBTITLE_SUCCESS,
  FILTER_HEADER_SEARCH_REQUEST,
  FILTER_HEADER_SEARCH_SUCCESS,
  FILTER_HEADER_SENIORITY_FAIL,
  FILTER_HEADER_SENIORITY_REQUEST,
  FILTER_HEADER_SENIORITY_SUCCESS,
  FILTER_MULTIPLE_FAIL,
  FILTER_MULTIPLE_REQUEST,
  FILTER_MULTIPLE_SUCCESS,
} from "../constants/filterConstants";

export const filterHeaderSearcheReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_SEARCH_REQUEST:
      return { loading: true };

    case FILTER_HEADER_SEARCH_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_SEARCH_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderJobTitleReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_SEARCH_JOBTITLE_REQUEST:
      return { loading: true };

    case FILTER_HEADER_SEARCH_JOBTITLE_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_SEARCH_JOBTITLE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderCompanyReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_COMPANY_REQUEST:
      return { loading: true };

    case FILTER_HEADER_COMPANY_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_COMPANY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderSeniorityReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_SENIORITY_REQUEST:
      return { loading: true };

    case FILTER_HEADER_SENIORITY_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_SENIORITY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderIndustryReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_SEARCH_INDUSTRY_REQUEST:
      return { loading: true };

    case FILTER_HEADER_SEARCH_INDUSTRY_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_SEARCH_INDUSTRY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderEmailVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_EMAIL_VERIFICATION_REQUEST:
      return { loading: true };

    case FILTER_HEADER_EMAIL_VERIFICATION_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_EMAIL_VERIFICATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderCompanyNameReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_COMPANY_NAME_REQUEST:
      return { loading: true };

    case FILTER_HEADER_COMPANY_NAME_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_COMPANY_NAME_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderCompanyKeywordsReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_COMPANY_KEYWORDS_REQUEST:
      return { loading: true };

    case FILTER_HEADER_COMPANY_KEYWORDS_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_COMPANY_KEYWORDS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderCompanyTechnologiesReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_COMPANY_TECHNOLOGIES_REQUEST:
      return { loading: true };

    case FILTER_HEADER_COMPANY_TECHNOLOGIES_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_COMPANY_TECHNOLOGIES_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderCompanySeoReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_COMPANY_SEO_REQUEST:
      return { loading: true };

    case FILTER_HEADER_COMPANY_SEO_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_COMPANY_SEO_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderPersonCityReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_PERSON_CITY_REQUEST:
      return { loading: true };

    case FILTER_HEADER_PERSON_CITY_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_PERSON_CITY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderPersonStateReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_PERSON_STATE_REQUEST:
      return { loading: true };

    case FILTER_HEADER_PERSON_STATE_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_PERSON_STATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderPersonCountryReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_PERSON_COUNTRY_REQUEST:
      return { loading: true };

    case FILTER_HEADER_PERSON_COUNTRY_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_PERSON_COUNTRY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderCompanyCityReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_COMPANY_CITY_REQUEST:
      return { loading: true };

    case FILTER_HEADER_COMPANY_CITY_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_COMPANY_CITY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderCompanyStateReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_COMPANY_STATE_REQUEST:
      return { loading: true };

    case FILTER_HEADER_COMPANY_STATE_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_COMPANY_STATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderCompanyCountryReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_COMPANY_COUNTRY_REQUEST:
      return { loading: true };

    case FILTER_HEADER_COMPANY_COUNTRY_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_COMPANY_COUNTRY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderContactListReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_CONTACT_LIST_REQUEST:
      return { loading: true };

    case FILTER_HEADER_CONTACT_LIST_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_CONTACT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterHeaderAccountListReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_HEADER_ACCOUNT_LIST_REQUEST:
      return { loading: true };

    case FILTER_HEADER_ACCOUNT_LIST_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_HEADER_ACCOUNT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// All filters reducer

export const allFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_FILTERS_REQUEST:
      return { loading: true };

    case ALL_FILTERS_SUCCESS:
      return { loading: false, payload: action.payload };

    case ALL_FILTERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// All dynamic filters reducer

export const allDynamicFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_DYNAMIC_FILTERS_REQUEST:
      return { loading: true };

    case ALL_DYNAMIC_FILTERS_SUCCESS:
      return { loading: false, payload: action.payload };

    case ALL_DYNAMIC_FILTERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const filterMultipleListReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_MULTIPLE_REQUEST:
      return { loading: true };

    case FILTER_MULTIPLE_SUCCESS:
      return { loading: false, payload: action.payload };

    case FILTER_MULTIPLE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
