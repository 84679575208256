import React, { useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  csvDownload,
  filterQuery,
  getSelectedColumns,
  getSelectedColumnsCompany,
  processContactNumberFormat,
  reorderArrayByKeys,
} from "../../../../utils/helpers";
import { contactListEndPoints } from "../../../../utils/constants/httpConstants";
import { postRequest } from "../../../../Apis";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  STATIC_COLUMN_ORDER,
} from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { COMPANY_SCREEN_PATH } from "../../../../utils/constants/routes";
import {
  ALL_COLUMNS,
  SELECTED_ALL_COLUMNS,
  SELECTED_COLUMNS,
  SELECTED_SELECTED_COLUMNS,
} from "../../../../utils/constants/assets";
import { checkLogin } from "../../../../redux/actions/authActions";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../../strings";
import { Button } from "../../../../components/Buttons";
import CreditsForRefferalModal from "../../../../components/Modals/CreditsForRefferalModal";
import classes from "./CrmComp.module.css";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import Image from "../../../../components/Image/Image";

const ExportList = [
  {
    field: STRINGS.EXPORT_ALL_COLUMNS,
    selected_icon: SELECTED_ALL_COLUMNS,
    normal_icon: ALL_COLUMNS,
  },
  {
    field: STRINGS.EXPORT_SELECT,
    selected_icon: SELECTED_SELECTED_COLUMNS,
    normal_icon: SELECTED_COLUMNS,
  },
];

const ExportModal = ({
  open,
  setOpen,
  leadType = STRINGS.PEOPLE,
  bulkSelected = false,
  record_ids = [],
  columns,
  setSelectedIds,
  setBulkSelected,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(STRINGS.EXPORT_ALL_COLUMNS);
  const [exporting, setExporting] = useState(false);
  const [credits, setcredits] = useState(false);

  const allFiltersSelector = useSelector((state) => state.allFilters);

  const handleSelection = (item) => {
    setSelected(item.field);
  };

  const handleExport = async () => {
    const payload = bulkSelected
      ? {
        ...filterQuery(allFiltersSelector?.payload),
        action_type: STRINGS.EXPORT_DATA,
        list_id: STRINGS.MY_LIST,
      }
      : {
        record_ids: record_ids,
        action_type: STRINGS.EXPORT_DATA,
        list_id: STRINGS.MY_LIST,
      };

    if (selected === STRINGS.EXPORT_SELECT) {
      payload[STRINGS.TABLE_COLUMNS] = getSelectedColumns(columns);
    } else {
      delete payload.table_columns;
    }

    if (
      window.location.pathname === COMPANY_SCREEN_PATH &&
      selected === STRINGS.EXPORT_SELECT
    ) {
      payload[STRINGS.TABLE_COLUMNS] = getSelectedColumnsCompany(columns);
    }
    const peopleURL = bulkSelected
      ? contactListEndPoints.bulkAlreadySaveToList
      : contactListEndPoints.alreadySaveToList;

    const companyURL = bulkSelected
      ? contactListEndPoints.companyBulkExport
      : contactListEndPoints.companyExportList;

    const URL = leadType === STRINGS.PEOPLE ? peopleURL : companyURL;
    try {
      setExporting(true);
      const response = await postRequest(navigate, URL, payload);

      EventEmitter.emit(EMITTER_SUCCESS, response.data?.message || "");
      setExporting(false);
      setBulkSelected(false);
      setOpen(false);

      if (!bulkSelected) {
        // condition here if table_columns are present, pass table columns.
        let data = response?.data?.data;
        let columns_order = payload?.table_columns || STATIC_COLUMN_ORDER;
        data = reorderArrayByKeys(response?.data?.data, columns_order);

        // sanitize contact numbers based on countries.
        const formatted_data = [];
        for (let i in data) {
          let each_data = data?.[i] || {};
          if (each_data?.direct_phone_number) {
            const direct_phone_number = processContactNumberFormat(
              each_data?.person_country,
              each_data?.direct_phone_number
            );
            each_data.direct_phone_number = direct_phone_number;
          }
          if (each_data?.mobile_number) {
            const mobile_number = processContactNumberFormat(
              each_data?.person_country,
              each_data?.mobile_number
            );
            each_data.mobile_number = mobile_number;
          }
          if (each_data?.company_phone_number) {
            const company_phone_number = processContactNumberFormat(
              each_data?.company_country,
              each_data?.company_phone_number
            );
            each_data.company_phone_number = company_phone_number;
          }
          formatted_data.push(each_data);
        }

        // download csv.
        csvDownload(
          formatted_data,
          leadType === STRINGS.PEOPLE
            ? STRINGS.CONTACT_EXPORT
            : STRINGS.COMPANY_EXPORT
        );
      }
    } catch (err) {
      setExporting(false);
      if (err?.status === 422) {
        setcredits(true);
      } else {
        EventEmitter.emit(EMITTER_ERROR, err?.data?.message || STRINGS.SESSION);
      }
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.EXPORT_MODAL_ID,
      label: STRINGS.EXPORT,
    },
  };

  const modalFooterRightData = (
    <div className={classes.exportModalFooter}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={() => setOpen(!open)}
      />

      <Button
        label={STRINGS.EXPORT}
        size={"sm36"}
        variant={"primary"}
        onClick={handleExport}
        isLoading={exporting}
      />
    </div>
  );

  return (
    <Box>
      <CustomModal
        header={modalHeaderData}
        footer={{ right: modalFooterRightData }}
        open={open}
        close={() => setOpen(!open)}
      >
        <Box className={classes.flex1}>
          {ExportList?.map((item) => {
            return (
              <Box
                className={classes.containEachAction}
                key={item.field}
                onClick={() => handleSelection(item)}
              >
                <Image
                  src={
                    selected === item.field
                      ? item.selected_icon
                      : item.normal_icon
                  }
                  fontSize="20px"
                  name={item.icon}
                  alt=""
                />
                <TypographyInline
                  label={item.field}
                  size={"md"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                />
              </Box>
            );
          })}
        </Box>
      </CustomModal>

      {credits ? (
        <CreditsForRefferalModal open={credits} close={setcredits} />
      ) : (
        ""
      )}
    </Box>
  );
};

export default ExportModal;
