import React, { Suspense, useEffect, useRef } from "react";
import { Container } from "./App/container";
import store from "./App/redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { FullScreenLoader } from "./App/components";
import { preloadImages } from "./App/utils/helpers";
import * as assets from "./App/utils/constants/assets";

// scroll hide and display helper.
// const useScrollDetection = (onScroll, onMouseOut) => {
//   const scrollContainerRef = useRef(null);


//   useEffect(() => {
//     const handleScroll = () => {


//       var scrollableDiv = document.getElementById(scrollContainerRef.current.id);

//       // Check if the scroll position is at the bottom
//       var isAtBottom = scrollableDiv.scrollHeight - scrollableDiv.scrollTop - 5 === scrollableDiv.clientHeight - 5;
//       console.log("isAtBottom ", isAtBottom);
//       // Call the onScroll callback function when a scroll occurs
//       if (onScroll && typeof onScroll === 'function' && !isAtBottom) {
//         onScroll(scrollContainerRef.current.scrollTop);
//       }
//     };

//     const handleMouseOut = () => {
//       // Call the onMouseOut callback function when the mouse leaves the container
//       if (onMouseOut && typeof onMouseOut === 'function') {
//         onMouseOut();
//       }
//     };

//     // Add event listeners for scroll and mouseout on the specified scroll container
//     const scrollContainer = scrollContainerRef.current;
//     if (scrollContainer) {
//       scrollContainer.addEventListener('scroll', handleScroll);
//       scrollContainer.addEventListener('mouseout', handleMouseOut);

//       // Clean up the event listeners when the component unmounts
//       return () => {
//         scrollContainer.removeEventListener('scroll', handleScroll);
//         scrollContainer.removeEventListener('mouseout', handleMouseOut);
//       };
//     }
//   }, [onScroll, onMouseOut]);

//   return scrollContainerRef;
// };

function App() {

  React.useEffect(() => {

    if (process.env.NODE_ENV === 'production') {
      window.console.log = () => { };
      window.alert = () => { };
      window.refresh_token_calling = false;
    }

    // preload images for caching.
    const images_preloaded = localStorage.getItem('images_preloaded');
    if (!images_preloaded) {
      const images = Object.values(assets);
      preloadImages(images, 10, 1000);
      localStorage.setItem('images_preloaded', true);
    }

  }, []);

  return (
    <Provider className="App" store={store}>
      <Suspense fallback={<FullScreenLoader />}>
        <BrowserRouter>
          <Container />
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}
// export { useScrollDetection };
export default App;
