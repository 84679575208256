import React from "react";
import classes from "./CrmComp.module.css";
import Image from "../../../../components/Image/Image";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import { Button } from "../../../../components/Buttons";

const AccountDetailEmptyScreen = ({ src, label, buttonItems }) => {
  return (
    <div className={classes.detailEmptyScreenMainContainer}>
      <div className={classes.emptyScreenImageMainContainer}>
        <Image src={src} alt="" className={classes.emptyScreenImage} />
      </div>
      <div className={classes.emptyScreenLabelContainer}>
        <TypographyInline
          color={GREY.SECONDARY}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          label={label}
        />
      </div>
      <div className={classes.detailEmptyScreenButtonContainer}>
        {buttonItems?.map((item, index) => {
          return (
            <div
              className={classes.detailEmptyScreenButtons}
              key={`button${index}`}
            >
              <Button
                disabled={item.disabled}
                LeftIcon={item?.LeftIcon}
                RightIcon={item?.RightIcon}
                label={item?.label}
                size={"sm36"}
                variant={item?.variant}
                {...item}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountDetailEmptyScreen;

// ------------- NOTE ----------------
// Props description :-
// label : Label you need to display on Button.
// buttonItems :- This prop is an array of object having all the properties of button in the same keys which are passed in button component.
