import { Box, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../Apis";
import { useNavigate, useParams } from "react-router-dom";
import { campaignEndpoints } from "../../utils/constants/httpConstants";
import { CircularLoader } from "../../components";
import {
  Logger,
  capitalizeFirstLetter,
  debounceHelper,
  generateNameFromEmail,
  processStatsKeyValuePairs,
  sortArrayWithValue,
} from "../../utils/helpers";
import {
  CONTACTS_STATS_TYPE,
  DEBOUNCE_DELAY,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../utils/constants";
import {
  BRAND,
  GREY,
  INTER_DIMENSIONAL_BLUE,
} from "../../utils/constants/colors";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import CustomHeader from "../../components/Header/CustomHeader";
import { STRINGS } from "./strings";
import { EMAIL_MARKETING_CAMPAIGN_PATH } from "../../utils/constants/routes";
import TypographyInline from "../../components/Typography/TypographyInline";
import EventEmitter from "../../utils/emitter/EventEmitter";
import AvatarName from "../../components/Avatar/AvatarName";
import SkeletonLoader from "../../components/Loaders/SkeletonLoader";
import { HELP } from "../../utils/constants/colors";
import { ERROR } from "../../utils/constants/colors";
import { NAVY } from "../../utils/constants/colors";
import StatusChip from "./components/common/StatusChip";
import IconComponent from "../../components/Icon/IconComponent";
import { Button } from "../../components/Buttons";
import classes from "./marketing.module.css";
import CustomModal from "../../components/Modals/CustomModal";
import SearchField from "../../components/InputFields/SearchField";
import { StatsDialog } from "./components/Campaign";
import UserProfileDialog from "./components/Campaign/UserProfileDialog";
import { NO_RECORD_UPDATE } from "../../utils/constants/assets";
import Image from "../../components/Image/Image";
const CampaignContactsScreen = () => {
  const CURRENT_TAB_ID = STRINGS.CAMPAIGN_CONTACTS_ID;
  const navigate = useNavigate();
  const params = useParams();
  const ids = params?.campaignId?.split("&");
  const campaignId = ids[0];
  const sequenceId = ids[1];

  const [loading, setLoading] = useState(true);
  const stepsCount = 1;
  const [start, setStart] = useState(0);
  const [search, setSearch] = useState("");
  const [allRecordsFetched, setAllRecordsFetched] = useState(false);
  const [statsShowModalOpen, setStatsModalShowOpen] = useState(false);
  const [name, setName] = useState("");
  const [records, setRecords] = useState({});
  const [statsPayload, setStatsPayload] = useState({});
  const [totalCount, setTotalCount] = useState(-1);
  const [campaignStats, setCampaignStats] = useState({});
  const [statsLoading, setStatsLoading] = useState(true);
  const [totalEnroled, setTotalEnroled] = useState("");
  const [camListId, setCamListId] = useState("");
  const [campaignStatus, setCampaignStatus] = useState("");
  const [showUnerollModal, setShowEnrollModal] = useState(false);
  const [unenrolling, setUnenrolling] = useState(false);
  const [email, setEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [headerItems, setHeaderItems] = useState([
    {
      id: STRINGS.TYPE_CAMP,
      label: STRINGS.CAMPAIGN_TEXT,
      path: EMAIL_MARKETING_CAMPAIGN_PATH,
    },
  ]);

  const EMAIL_MARKETING_TABS = [
    {
      label: STRINGS.CAMPAIGN_CONTACTS_LABEL,
      id: STRINGS.CAMPAIGN_CONTACTS_ID,
    },
    {
      label: STRINGS.CAMPAIGN_OVERVIEW_LABEL,
      id: STRINGS.CAMPAIGN_OVERVIEW_ID,
    },
    {
      label: STRINGS.CAMPAIGN_ACTIVITY_LABEL,
      id: STRINGS.CAMPAIGN_ACTIVITY_ID,
    },
    {
      label: STRINGS.CAMPAIGN_SETTINGS_LABEL,
      id: STRINGS.CAMPAIGN_SETTINGS_ID,
    },
  ];

  const generateName = (val) => {
    const data = val.includes("undefined") ? " " : val;
    return data;
  };

  const handleNavigate = (item) => {
    try {
      navigate(
        `${EMAIL_MARKETING_CAMPAIGN_PATH}/${params?.campaignId}/${item?.id}`
      );
    } catch (error) {
      Logger(error);
    }
  };

  const handleContactUnenroll = (email) => {
    setShowEnrollModal(!showUnerollModal);
    setEmail(email);
  };

  const handleScroll = async (e) => {
    if (
      e?.currentTarget.offsetHeight + e?.currentTarget.scrollTop >=
      e?.currentTarget.scrollHeight - 10
    ) {
      if (!loading && !allRecordsFetched) {
        try {
          setLoading(true);
          const payload = {
            campaign_id: campaignId,
            size: 5 * stepsCount,
            page: start + 1,
            cam_list_id: camListId,
          };
          const response = await postRequest(
            navigate,
            campaignEndpoints.campaignContactList,
            payload
          );
          setStart(start + 1);
          setRecords({ ...records, ...response?.data?.data });
          if (
            Object.keys({ ...records, ...response?.data?.data })?.length *
            stepsCount >=
            totalCount
          ) {
            setAllRecordsFetched(true);
          }

          if (!Object.keys(response?.data?.data || {}).length) {
            setAllRecordsFetched(true);
          }
        } catch (error) {
          setAllRecordsFetched(true);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const unenrollContact = async (e) => {
    try {
      setUnenrolling(true);
      const response = await postRequest(navigate, campaignEndpoints.UNENROLL, {
        email: email,
        campaign_id: campaignId,
      });
      const new_records = { ...records };
      delete new_records[email];
      setRecords(new_records);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setUnenrolling(false);
      setShowEnrollModal(false);
    }
  };

  const STATUS_LIST = [
    { label: STRINGS.SENT, color: HELP.W_500 },
    { label: STRINGS.DELIVER, color: INTER_DIMENSIONAL_BLUE.W_500 },
    { label: STRINGS.BOUNCED, color: ERROR.W_500 },
    { label: STRINGS.UNSUBSCRIBED, color: NAVY.W_500 },
  ];

  const STATUS_WISE_BORDERS_BG = {
    Sent: "eachContactStepSent",
    Bounced: "eachContactStepBounce",
    Lead: "eachContactStepLead",
    Delivered: "eachContactStepDeliver",
    Active: "eachContactStepActive",
    Unsubscribed: "eachContactStepUnsubscribe",
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.UNENROLL,
      label: STRINGS.UNENROLL,
    },
  };

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Box>
        <Button
          label={STRINGS.CANCEL}
          variant={"ghost"}
          size={"sm36"}
          onClick={handleContactUnenroll}
        />
      </Box>
      <Box>
        <Button
          label={STRINGS.UNENROLL}
          size={"sm36"}
          variant={"primary"}
          onClick={unenrollContact}
          isLoading={unenrolling}
          disabled={campaignStatus === "completed"}
        />
      </Box>
    </div>
  );

  const opnStatsDialog = (key) => {
    setStatsModalShowOpen(true);
    setStatsPayload({
      head: { id: key },
      row: {
        _id: campaignId,
        cam_sequence_id: sequenceId,
        name: name,
      },
    });
  };

  const showProfileHandle = (email) => {
    setUserEmail(email);
    setOpenProfileDialog(true);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setSearch(e.target.value);
      const payload = {
        campaign_id: campaignId,
        size: 5,
        page: 0,
        cam_list_id: camListId,
        name: e.target.value,
      };
      const steps_response = await postRequest(
        navigate,
        campaignEndpoints.campaignContactList,
        payload
      );
      setTotalCount(steps_response.data.count);
      setRecords(steps_response?.data?.data);
    } catch (e) { }
  };
  const debounceSearch = debounceHelper(handleSearch, DEBOUNCE_DELAY);
  useEffect(() => {
    // This is for getting details of campaign.
    (async () => {
      try {
        const campaign_details = await getRequest(
          navigate,
          campaignEndpoints.eachCampaignDetail + "/" + campaignId
        );
        const cam_list_id = campaign_details?.data?.data?.cam_list_id;
        setCamListId(cam_list_id);
        setName(campaign_details?.data?.data?.name);
        setCampaignStatus(campaign_details?.data?.data?.state)
        setHeaderItems([
          {
            id: STRINGS.TYPE_CAMP,
            label: STRINGS.CAMPAIGN_TEXT,
            path: EMAIL_MARKETING_CAMPAIGN_PATH,
          },
          {
            id: campaign_details?.data?.data?._id,
            label: campaign_details?.data?.data?.name,
          },
        ]);
        setTotalEnroled(campaign_details?.data?.data?.total_enrolled);

        const payload = {
          campaign_id: campaignId,
          size: 5,
          page: 0,
          cam_list_id: cam_list_id,
        };
        const steps_response = await postRequest(
          navigate,
          campaignEndpoints.campaignContactList,
          payload
        );
        setTotalCount(steps_response.data.count);
        setRecords(steps_response?.data?.data);
        setLoading(false);
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    })();

    // This is for gettings stats of the campaign
    (async () => {
      try {
        setStatsLoading(true);
        const campaign_stats = await postRequest(
          navigate,
          campaignEndpoints.campStats,
          { campaign_ids: [campaignId] }
        );
        setCampaignStats(
          processStatsKeyValuePairs(
            campaign_stats?.data?.records?.[campaignId] || {}
          )
        );
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      } finally {
        setStatsLoading(false);
      }
    })();
  }, [navigate, campaignId, sequenceId]);
  // this useEffect is for gettings campaign details, stats, and overview steps.


  return (
    <Box className={classes.container1}>
      <CustomHeader items={headerItems} />
      {/* Tabs for selection */}
      <Box className={classes.tabContainer}>
        {EMAIL_MARKETING_TABS?.map((item) => {
          return (
            <Box
              keye={item.id}
              className={
                CURRENT_TAB_ID === item.id ? classes.selectedTab : classes.tab
              }
              onClick={() => handleNavigate(item)}
            >
              <TypographyInline
                size={"sm"}
                color={
                  CURRENT_TAB_ID === item.id ? BRAND.PRIMARY : GREY.SECONDARY
                }
                fontWeight={
                  CURRENT_TAB_ID === item.id
                    ? fontWeight.MEDIUM
                    : fontWeight.REGULAR
                }
                label={item.label}
              />
            </Box>
          );
        })}
      </Box>
      {/* Tabs for selection */}

      <Box className={classes.containAllStats}>
        {CONTACTS_STATS_TYPE.map((eachStat, index) => {
          return (
            <Box
              key={eachStat.key}
              className={classes.eachContactStat}
              onClick={() => index !== 0 && opnStatsDialog(eachStat.key)}
            >
              {statsLoading ? (
                <Box>
                  {" "}
                  <SkeletonLoader
                    variant="text"
                    skeletonWidth="20px"
                    skeletonHeight="30px"
                  />{" "}
                </Box>
              ) : eachStat?.key === STRINGS.TOTAL_ENROL ? (
                <TypographyInline
                  size={"xl"}
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={totalEnroled}
                />
              ) : (
                <TypographyInline
                  size={"xl"}
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={campaignStats?.[eachStat?.key]}
                />
              )}
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={`${STRINGS.TOTAL} ${eachStat.label}`}
              />
            </Box>
          );
        })}
      </Box>

      <Box onScroll={handleScroll} id="modal_body" className={classes.contactsRootContainer}>
        <Box className={classes.searchAndStatusContainer}>
          <Box className={classes.commonWidthContact}>
            <SearchField
              autoComplete="off"
              size={"sm36"}
              placeholder={STRINGS.SEARCH_CONTACTS}
              onChange={debounceSearch}
            />
          </Box>
          <div></div>
          <Box className={classes.statusContainer}>
            {STATUS_LIST?.map((item) => {
              return <StatusChip color={item.color} label={item.label} />;
            })}
          </Box>
        </Box>
        <Box className={classes.contactsContainer}>
          {
            Object?.keys(records)?.length ? <>
              {Object.keys(records).map((recordKey, index) => {
                return (
                  <Box className={classes.containEachContact} key={index}>
                    <Box className={classes.eachContact}>
                      <Box className={classes.eachContactHeader}>
                        <Box className={classes.flexSpaceBetween}>
                          <Box
                            className={classes.pureFlex}
                            onClick={() =>
                              showProfileHandle(records[recordKey].email)
                            }
                          >
                            <Box className={classes.avatarContainer}>
                              <AvatarName
                                size={"small"}
                                name={
                                  records[recordKey].full_name ||
                                  generateNameFromEmail(records[recordKey].email)
                                }
                              />
                            </Box>
                            <Tooltip title={records[recordKey].email}>
                              <Box className={classes.alignSelfCenter}>
                                <TypographyInline
                                  size={"sm"}
                                  color={GREY.SECONDARY}
                                  fontWeight={fontWeight.SEMIBOLD}
                                  label={generateName(records[recordKey].full_name)}
                                />
                                <TypographyInline
                                  size={"sm"}
                                  color={GREY.SECONDARY}
                                  fontWeight={fontWeight.REGULAR}
                                  label={
                                    !records[recordKey].company_name
                                      ? `${STRINGS.EMAIL}: ${records[recordKey].email}`
                                      : `${STRINGS.COMPANY}: ${records[recordKey].company_name}`
                                  }
                                />
                              </Box>
                            </Tooltip>
                          </Box>

                          <Box>
                            <Button
                              label={STRINGS.UNENROLL}
                              size={"sm36"}
                              variant={"ghost"}
                              onClick={() =>
                                handleContactUnenroll(records[recordKey].email)
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.containStepList}>
                      {sortArrayWithValue(
                        records?.[recordKey]?.steps,
                        STRINGS.PROCESS_DATE_TIME
                      )?.map((eachRecord, index1) => {
                        const dateTimeString = eachRecord?.processing_date_time;
                        const dateTime = new Date(dateTimeString).toLocaleString();
                        return (
                          <Tooltip key={index1} title={capitalizeFirstLetter(eachRecord?.status)}>
                            <Box
                              className={
                                classes[
                                STATUS_WISE_BORDERS_BG[eachRecord?.status] ||
                                "eachContactStepActive"
                                ]
                              }
                              key={index1}
                            >
                              <Box>
                                <Box className={classes.justifyBetween}>
                                  <TypographyInline
                                    size={"sm"}
                                    color={GREY.PRIMARY}
                                    fontWeight={fontWeight.SEMIBOLD}
                                    label={`${STRINGS.COMMON_STEP}: ${index1 + 1}`}
                                  />
                                  {eachRecord?.status === STRINGS.SENT && (
                                    <IconComponent
                                      iconLabel={ICON_LABELS.DONE}
                                      color={GREY.PRIMARY}
                                      fontSize={fontSize.LG}
                                    />
                                  )}
                                  {eachRecord?.status === STRINGS.DELIVER && (
                                    <IconComponent
                                      iconLabel={ICON_LABELS.DONE_ALL}
                                      color={GREY.PRIMARY}
                                      fontSize={fontSize.LG}
                                    />
                                  )}
                                </Box>

                                <div className={classes.dateTimeText}>
                                  <TypographyInline
                                    size={"xs"}
                                    color={GREY.SECONDARY}
                                    fontWeight={fontWeight.REGULAR}
                                    label={eachRecord?.show_date_time || dateTime}
                                  />
                                </div>
                              </Box>
                            </Box>
                          </Tooltip>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </> : search?.length ? <>
              <div className={classes.noRecordFoundContainer} >
                <div>
                  <Image
                    className={classes.noRecordFoundImage}
                    src={NO_RECORD_UPDATE}
                  />
                </div>
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={STRINGS.NO_RECORD_FOUND}
                  size={"xl"}
                />
              </div>
            </> : null
          }
          {loading ? (
            <Box className={classes.loaderContainer}>
              <CircularLoader />
            </Box>
          ) : null}
        </Box>

        {/* Modals */}
        {showUnerollModal && (
          <CustomModal
            variant={"error"}
            open={true}
            close={handleContactUnenroll}
            header={modalHeaderData}
            footer={{ right: modalFooterRightData }}
          >
            <TypographyInline
              label={STRINGS.UNENROLL_CONFIRMATION}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.SECONDARY}
            />
          </CustomModal>
        )}
      </Box>
      {statsShowModalOpen && (
        <StatsDialog
          open={statsShowModalOpen}
          setOpen={setStatsModalShowOpen}
          statsPayload={statsPayload}
        />
      )}
      {openProfileDialog && (
        <UserProfileDialog
          open={openProfileDialog}
          setOpen={setOpenProfileDialog}
          email={userEmail}
        />
      )}
    </Box>
  );
};

export default CampaignContactsScreen;
