export const COLUMNS_UPDATE_REQUEST = "COLUMNS_UPDATE_REQUEST";
export const COLUMNS_UPDATE_SUCCESS = "COLUMNS_UPDATE_SUCCESS";
export const COLUMNS_UPDATE_FAIL = "COLUMNS_UPDATE_FAIL";

export const APPLY_COLUMN_UPDATE_REQUEST = "APPLY_COLUMN_UPDATE_REQUEST";
export const APPLY_COLUMN_UPDATE_SUCCESS = "APPLY_COLUMN_UPDATE_SUCCESS";
export const APPLY_COLUMN_UPDATE_FAIL = "APPLY_COLUMN_UPDATE_FAIL";

export const TABLE_DATA_FETCH_REQUEST = "TABLE_DATA_FETCH_REQUEST";
export const TABLE_DATA_FETCH_SUCCESS = "TABLE_DATA_FETCH_SUCCESS";
export const TABLE_DATA_FETCH_FAIL = "TABLE_DATA_FETCH_FAIL";

export const TABLE_ENDPOINT_CHANGE_REQUEST = "TABLE_ENDPOINT_CHANGE_REQUEST";
export const TABLE_ENDPOINT_CHANGE_SUCCESS = "TABLE_ENDPOINT_CHANGE_SUCCESS";
export const TABLE_ENDPOINT_CHANGE_FAIL = "TABLE_ENDPOINT_CHANGE_FAIL";

export const COMPANY_TABLE_ENDPOINT_CHANGE_REQUEST = "COMPANY_TABLE_ENDPOINT_CHANGE_REQUEST";
export const COMPANY_TABLE_ENDPOINT_CHANGE_SUCCESS = "COMPANY_TABLE_ENDPOINT_CHANGE_SUCCESS";
export const COMPANY_TABLE_ENDPOINT_CHANGE_FAIL = "COMPANY_TABLE_ENDPOINT_CHANGE_FAIL";

export const TABLE_DATA_FETCH_CAMPAIGN_REQUEST = "TABLE_DATA_FETCH_CAMPAIGN_REQUEST";
export const TABLE_DATA_FETCH_CAMPAIGN_SUCCESS = "TABLE_DATA_FETCH_CAMPAIGN_SUCCESS";
export const TABLE_DATA_FETCH_CAMPAIGN_FAIL = "TABLE_DATA_FETCH_CAMPAIGN_FAIL";

export const GET_LIST_IDS_REQUEST = "GET_LIST_IDS_REQUEST";
export const GET_LIST_IDS_SUCCESS = "GET_LIST_IDS_SUCCESS";
export const GET_LIST_IDS_FAIL = "GET_LIST_IDS_FAIL";

export const DYNAMIC_COLUMNS_UPDATE_REQUEST = "DYNAMIC_COLUMNS_UPDATE_REQUEST";
export const DYNAMIC_COLUMNS_UPDATE_SUCCESS = "DYNAMIC_COLUMNS_UPDATE_SUCCESS";
export const DYNAMIC_COLUMNS_UPDATE_FAIL = "DYNAMIC_COLUMNS_UPDATE_FAIL";

export const DYNAMIC_UPDATED_COLUMNS_REQUEST = "DYNAMIC_UPDATED_COLUMNS_REQUEST";
export const DYNAMIC_UPDATED_COLUMNS_SUCCESS = "DYNAMIC_UPDATED_COLUMNS_SUCCESS";
export const DYNAMIC_UPDATED_COLUMNS_FAIL = "DYNAMIC_UPDATED_COLUMNS_FAIL";
