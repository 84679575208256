import {
  CANT_EXCEED_100WORDS,
  CANT_EXCEED_50WORDS,
  CONFIRM_PASSWORD_LENGTH,
  DAILY_DAILY_LIMIT,
  DUPLICATE_STAGE_NAME,
  DYNAMIC_FIELD_FORMAT,
  INVALID_DATE_TIME,
  INVALID_URL,
  LABEL_MAX_LENGTH,
  MESSAGE_CONFIRMED_INVALID_PASSWORD_LENGTH,
  MESSAGE_DAILY_LIMIT_EXCEED,
  MESSAGE_HOURLY_LIMIT_EXCEED,
  MESSAGE_INVALID_DOMAIN,
  MESSAGE_INVALID_EMAIL_LENGTH,
  MESSAGE_INVALID_EMAIL_PATTERN,
  MESSAGE_INVALID_LENGTH,
  MESSAGE_INVALID_LENGTH_SUFFIX,
  MESSAGE_INVALID_NAME_LENGTH,
  MESSAGE_INVALID_NAME_LENGTH_SUFFIX,
  MESSAGE_INVALID_NEW_PASSWORD_LENGTH,
  MESSAGE_INVALID_NUMBER,
  MESSAGE_INVALID_PASSWORD,
  MESSAGE_INVALID_PASSWORD_LENGTH,
  MESSAGE_INVALID_URL,
  MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH,
  MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH,
  MESSAGE_PAST_DATE_NOT_ALLOWED,
  MESSAGE_REQUIRED_FIELD,
  MESSAGE_SCHEDULE,
  MESSAGE_ZIPCODE_ERROR_MESSAGE,
  NEW_PASSWORD_LENGTH,
  PHONE_NUMBER_INVALID,
  SENDER_ADDRESS,
  SENDER_CITY,
  SENDER_COMPANYNAME,
  SENDER_COUNTRY,
  SENDER_FIRSTNAME_LENGTH,
  SENDER_JOBTITLE,
  SENDER_LASTNAME_LENGTH,
  SENDER_PHONENUMBER,
  SENDER_POSTAL,
  SENDER_STATE,
  SPECIAL_CHARATER_CHECK,
} from "../constants/messages";
import { isValidUrl } from "../helpers";

export const emailValidation = (email = "") => {
  if (!email.length) {
    return { isValid: false, message: MESSAGE_INVALID_EMAIL_LENGTH };
  }
  let filter =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  return filter.test(email)
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_EMAIL_PATTERN };
};

export const domainValidationForBlacklist = (domain) => {
  if (!domain.length) {
    return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
  }

  const domain_split = domain?.split(".");
  if (domain_split?.length) {
  }

  return domain_split?.length >= 2
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_DOMAIN };
};

export const passwordValidation = (password) => {
  if (!password.length) {
    return { isValid: false, message: MESSAGE_INVALID_PASSWORD_LENGTH };
  }

  if (password?.length >= 8) {
    return { isValid: true, message: "" };
  } else {
    return { isValid: false, message: MESSAGE_INVALID_PASSWORD };
  }
};

//Login SCreen password Validation

export const loginPasswordValidation = (password) => {
  if (!password.length) {
    return { isValid: false, message: MESSAGE_INVALID_PASSWORD_LENGTH };
  } else {
    return { isValid: true, message: "" };
  }
};

export const confPasswordValidation = (pass, confPass) => {
  if (!confPass.length) {
    return { isValid: false, message: CONFIRM_PASSWORD_LENGTH };
  }
  if (pass === confPass) {
    return {
      isValid: true,
      message: "",
    };
  } else {
    return {
      isValid: false,
      message: MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH,
    };
  }
};
export const confirmPasswordvalidation = (pass, confPass) => {
  if (!confPass.length) {
    return {
      isValid: false,
      message: MESSAGE_CONFIRMED_INVALID_PASSWORD_LENGTH,
    };
  }

  if (confPass === pass) {
    if (confPass?.length >= 8) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: "" };
    }
  } else {
    return {
      isValid: false,
      message: MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH,
    };
  }
};

export const newPasswordvalidation = (pass, confPass) => {
  if (!confPass.length) {
    return { isValid: false, message: NEW_PASSWORD_LENGTH };
  }
  if (confPass !== pass) {
    if (confPass?.length >= 8) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: MESSAGE_INVALID_NEW_PASSWORD_LENGTH };
    }
  } else {
    return {
      isValid: false,
      message: MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH,
    };
  }
};

export const nameValidation = (name) => {
  return name?.toString()?.trim()?.length
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

export const aiTextValidation = (name) => {
  if (name?.length) {
    const count = name.split(" ");
    if (count?.length > 100) {
      return {
        isValid: false,
        message: CANT_EXCEED_100WORDS,
      };
    } else {
      return { isValid: true, message: "" };
    }
  } else {
    return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
  }
};

export const aiWebsiteValidation = (name) => {
  if (name?.length) {
    const result = isValidUrl(name);
    if (result) {
      return {
        isValid: true,
        message: "",
      };
    } else {
      return { isValid: false, message: INVALID_URL };
    }
  } else {
    return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
  }
};
export const aiTextValidationData = (name) => {
  if (name?.length) {
    const count = name.split(" ");
    if (count?.length > 50) {
      return {
        isValid: false,
        message: CANT_EXCEED_50WORDS,
      };
    } else {
      return { isValid: true, message: "" };
    }
  } else {
    return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
  }
};

export const propertiesGrpNameValidation = (name) => {
  const regexPattern = /^[a-zA-Z0-9 ]+$/;
  return name?.length
    ? !regexPattern?.test(name)
      ? { isValid: false, message: SPECIAL_CHARATER_CHECK }
      : name?.length > 50
        ? { isValid: false, message: LABEL_MAX_LENGTH }
        : { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

export const organiserValidation = (name) => {
  return name?.length
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};
export const dynamicFieldNameValidation = (name) => {
  const regex = /^[a-zA-Z0-9!^\)\( _-]+$/g;
  if (name?.length) {
    if (regex.test(name)) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: DYNAMIC_FIELD_FORMAT };
    }
  }
  return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

export const numberValidation = (number) => {
  if (number?.length === 0) {
    return { isValid: false, message: MESSAGE_REQUIRED_FIELD };
  } else {
    if (number?.length >= 3) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: MESSAGE_INVALID_NUMBER };
    }
  }
};
export const numberValidation2 = (type, number) => {
  if (type === "zip") {
    if (number?.length === 0) {
      return { isValid: false, message: SENDER_POSTAL };
    } else {
      if (number?.length >= 3) {
        return { isValid: true, message: "" };
      } else {
        return { isValid: false, message: MESSAGE_INVALID_NUMBER };
      }
    }
  } else {
    if (number?.length === 0) {
      return { isValid: false, message: SENDER_PHONENUMBER };
    } else {
      if (number?.length >= 3) {
        return { isValid: true, message: "" };
      } else {
        return { isValid: false, message: MESSAGE_INVALID_NUMBER };
      }
    }
  }
};

export const hourlyValidation = (number) => {
  if (number?.length === 0 || number === undefined) {
    return { isValid: false, message: MESSAGE_REQUIRED_FIELD };
  } else if (number > 100) {
    return { isValid: false, message: MESSAGE_HOURLY_LIMIT_EXCEED };
  } else {
    return { isValid: true, message: "" };
  }
};
export const dailyValidation = (number) => {
  if (number?.length === 0 || number === undefined) {
    return { isValid: false, message: MESSAGE_REQUIRED_FIELD };
  } else if (number > 1000) {
    return { isValid: false, message: MESSAGE_DAILY_LIMIT_EXCEED };
  } else {
    return { isValid: true, message: "" };
  }
};
export const campaignValidation = (type, name) => {
  if (type === "first_name") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_FIRSTNAME_LENGTH };
  } else if (type === "last_name") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_LASTNAME_LENGTH };
  } else if (type === "job") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_JOBTITLE };
  } else if (type === "company") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_COMPANYNAME };
  } else if (type === "address") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_ADDRESS };
  } else if (type === "city") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_CITY };
  } else if (type === "state") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_STATE };
  } else if (type === "country") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_COUNTRY };
  } else if (type === "zip") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: SENDER_POSTAL };
  }
};
export const zipcodeValidation = (zipcode) => {
  return zipcode?.length === 0
    ? { isValid: false, message: MESSAGE_REQUIRED_FIELD }
    : zipcode?.length >= 4
      ? { isValid: true, message: "" }
      : { isValid: false, message: MESSAGE_ZIPCODE_ERROR_MESSAGE };
};

export const dateValidation = (date) => {
  if (!date?.length) {
    return { isValid: false, message: MESSAGE_SCHEDULE };
  }
  if (
    new Date(date).setHours(0, 0, 0, 0) + 1 <=
    new Date().setHours(0, 0, 0, 0)
  ) {
    return { isValid: false, message: MESSAGE_PAST_DATE_NOT_ALLOWED };
  }
  return { isValid: true, message: "" };
};

export const urlValidation = (url) => {
  if (url?.length) {
    let urlRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return urlRegex.test(url)
      ? { isValid: true, message: "" }
      : { isValid: false, message: MESSAGE_INVALID_URL };
  } else {
    return { isValid: false, message: MESSAGE_REQUIRED_FIELD };
  }
};

export const linkedInURLValidation = (url) => {
  if (url?.length) {
    let urlRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return urlRegex.test(url)
      ? { isValid: true, message: "" }
      : { isValid: false, message: MESSAGE_INVALID_URL };
  } else return { isValid: true, message: "" };
};

export const domainValidation = (domain) => {
  // subdomain regex
  let filter = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;

  if (!domain.length) {
    return { isValid: false, message: MESSAGE_INVALID_DOMAIN };
  }

  return filter.test(domain)
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_DOMAIN };
};

export const fromToDateValidation = (fromDate, toDate) => {
  if (Date.parse(fromDate) > Date.parse(toDate)) {
    return { isValid: false, message: "Invalid Date Range" };
  }
  return { isValid: true, message: "" };
};

export const internalNameValidation = (name) => {
  if (!name.length) {
    return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
  }
};

export const EventTitleValidation = (name) => {
  if (!name.length) {
    return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
  }
};

export const stringLengthValidation = (name = "", key = "") => {
  return name?.trim()?.length
    ? name?.trim()?.length >= 3
      ? { isValid: true, message: "" }
      : {
        isValid: false,
        message: key
          ? `${key} ${MESSAGE_INVALID_LENGTH_SUFFIX}`
          : MESSAGE_INVALID_LENGTH,
      }
    : {
      isValid: false,
      message: key
        ? `${key} ${MESSAGE_INVALID_NAME_LENGTH_SUFFIX}`
        : MESSAGE_INVALID_NAME_LENGTH,
    };
};

export const prioritTypeValidation = (name) => {
  return name?.length
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

export const taskTypeValidation = (name) => {
  return name?.length
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

// validation for multiple values validation in drag drop context also checking the duplicate values.
export const multipleValuesValidation = (values) => {
  let tempValue = values?.filter((item) => item?.labelValue?.trim()?.length);
  let allLabels = values?.map((item) => item.labelValue?.toLowerCase()?.trim());
  let newAllLabels = [...new Set([...allLabels])];
  let duplicateValues = allLabels?.length !== newAllLabels?.length;

  return tempValue?.length
    ? duplicateValues
      ? { isValid: false, message: DUPLICATE_STAGE_NAME }
      : { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

export const meetingTitleValidation = (name) => {
  return name?.length
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

export const calendarValidation = (name) => {
  return name?.length
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

export const CalendarRequiredValidation = (name, nameCondition) => {
  if (nameCondition === "true") {
    return name?.length
      ? { isValid: true, message: "" }
      : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
  } else {
    return { isValid: true, message: "" };
  }
};

export const numberCrmValidation = (number) => {
  if (number?.length === 0) {
    return { isValid: false, message: MESSAGE_REQUIRED_FIELD };
  } else {
    return { isValid: true, message: "" };
  }
};

export const limitValidation = (hourly, daily) => {
  const parseHourly = parseInt(hourly);
  const parseDaily = parseInt(daily);

  if (hourly === "") {
    return { isValid: false, Hourlymessage: MESSAGE_REQUIRED_FIELD };
  }
  if (daily === "") {
    return { isValid: false, Dailymessage: MESSAGE_REQUIRED_FIELD };
  }
  if (parseHourly > 50) {
    return {
      isValid: false,
      Hourlymessage: MESSAGE_HOURLY_LIMIT_EXCEED,
    };
  }
  if (parseDaily > 500) {
    return {
      isValid: false,
      Dailymessage: MESSAGE_DAILY_LIMIT_EXCEED,
    };
  }
  if (hourly < daily) {
    return { isValid: true, message: "" };
  }
  if (parseHourly > parseDaily) {
    return { isValid: false, Dailymessage: DAILY_DAILY_LIMIT };
  } else {
    return { isValid: true, message: "" };
  }
};

export const dateTimeValidation = (timestamp) => {
  const d = new Date(timestamp);
  if (d.getTime() > Date.now()) {
    return { isValid: true, message: "" };
  }
  return { isValid: false, message: INVALID_DATE_TIME };
};

export const phoneValidation = (value) => {
  const regex = /^[\s0-9+\-().]*$/;
  if (regex?.test(value)) {
    return { isValid: true, message: "" };
  } else {
    return { isValid: false, message: PHONE_NUMBER_INVALID };
  }
};

export const stringMinMaxLengthValidation = (name = "", max = 0, key = "") => {
  return name?.trim()?.length
    ? (max ? name?.trim()?.length < max : true) ? { isValid: true, message: "" } : { isValid: false, message: `${key} must be less than ${max} characters.` }
    : { isValid: false, message: `${key} ${MESSAGE_INVALID_NAME_LENGTH_SUFFIX}` }
};