import React from "react";
import { filterItemsForSorting, findSortedValue } from "../../utils/helpers";
import { IMAGE_BASE_URL } from "../../utils/constants/assets";
import classes from "./Input.module.css";

const CustomDropDown = ({
  value,
  items,
  backgroundColor,
  fontWeight,
  iconPercent,
  height,
  margin,
  minHeight,
  minWidth,
  ...props
}) => {
  const resultedValue = findSortedValue(value, items);
  const resultedItems = filterItemsForSorting(items, value);

  return (
    <select
      {...props}
      className={classes.dropdown}
      style={{
        fontWeight: fontWeight && fontWeight,
        background: props.background
          ? props.background
          : iconPercent
            ? `transparent url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat ${iconPercent}`
            : null,
        backgroundColor: backgroundColor ? backgroundColor : null,
        height: height || "100%",
        margin: margin || "unset",
        minHeight: minHeight || "5vh",
        border: props.border ? props.border : "1px solid #D3DAE3",
        width: props.width ? props.width : "100%",
        fontSize: props.fontSize ? props.fontSize : "13px",
        padding: props.padding || "0 1rem",
      }}
    >
      {value ? (
        <option value={resultedValue?.id}>{resultedValue?.label}</option>
      ) : null}
      {resultedItems?.length
        ? resultedItems?.map((item) => {
          return (
            <option key={item.id} value={item.id}>
              {item?.label}
            </option>
          );
        })
        : null}
    </select>
  );
};

export default CustomDropDown;
