import {
  APPLY_COLUMN_UPDATE_FAIL,
  APPLY_COLUMN_UPDATE_REQUEST,
  APPLY_COLUMN_UPDATE_SUCCESS,
  COLUMNS_UPDATE_FAIL,
  COLUMNS_UPDATE_REQUEST,
  COLUMNS_UPDATE_SUCCESS,
  COMPANY_TABLE_ENDPOINT_CHANGE_FAIL,
  COMPANY_TABLE_ENDPOINT_CHANGE_REQUEST,
  COMPANY_TABLE_ENDPOINT_CHANGE_SUCCESS,
  DYNAMIC_COLUMNS_UPDATE_FAIL,
  DYNAMIC_COLUMNS_UPDATE_REQUEST,
  DYNAMIC_COLUMNS_UPDATE_SUCCESS,
  DYNAMIC_UPDATED_COLUMNS_FAIL,
  DYNAMIC_UPDATED_COLUMNS_REQUEST,
  DYNAMIC_UPDATED_COLUMNS_SUCCESS,
  GET_LIST_IDS_FAIL,
  GET_LIST_IDS_REQUEST,
  GET_LIST_IDS_SUCCESS,
  TABLE_DATA_FETCH_CAMPAIGN_FAIL,
  TABLE_DATA_FETCH_CAMPAIGN_REQUEST,
  TABLE_DATA_FETCH_CAMPAIGN_SUCCESS,
  TABLE_DATA_FETCH_FAIL,
  TABLE_DATA_FETCH_REQUEST,
  TABLE_DATA_FETCH_SUCCESS,
  TABLE_ENDPOINT_CHANGE_FAIL,
  TABLE_ENDPOINT_CHANGE_REQUEST,
  TABLE_ENDPOINT_CHANGE_SUCCESS,
} from "../constants/tableConstants";
import { accountEndPoints, leadsEndpoints } from "../../utils/constants/httpConstants";
import {
  dataReferenceMapping,
  decompressString,
  filterColumnQueryKeys,
  filterDynamicQuery,
  filterQuery,
  getSelectedColumns,
} from "../../utils/helpers";
import { postRequest } from "../../Apis";
import { LOGIN_PATH } from "../../utils/constants/routes";
import { paginationDataAction } from "./customTableActions";
import { tempHeadCompanyCells } from "../../utils/constants";

export const updateColumnHeaders = (updatedHeadersList) => async (dispatch) => {
  try {
    dispatch({ type: COLUMNS_UPDATE_REQUEST });
    dispatch({ type: COLUMNS_UPDATE_SUCCESS, payload: updatedHeadersList });
  } catch (err) {
    dispatch({ type: COLUMNS_UPDATE_FAIL });
  }
};

export const dynamicColumnUpdateHeaders =
  (updatedHeadersList, not_api_call = false) => async (dispatch) => {
    try {
      dispatch({ type: DYNAMIC_COLUMNS_UPDATE_REQUEST });
      dispatch({
        type: DYNAMIC_COLUMNS_UPDATE_SUCCESS,
        payload: updatedHeadersList,
        not_api_call: not_api_call
      });
    } catch (err) {
      dispatch({ type: DYNAMIC_COLUMNS_UPDATE_FAIL });
    }
  };

export const applyUpdateColumn = (updatedHeadersList) => async (dispatch) => {
  try {
    dispatch({ type: APPLY_COLUMN_UPDATE_REQUEST });
    dispatch({
      type: APPLY_COLUMN_UPDATE_SUCCESS,
      payload: updatedHeadersList,
    });
  } catch (err) {
    dispatch({ type: APPLY_COLUMN_UPDATE_FAIL });
  }
};

export const dynamicUpdatedColumn =
  (updatedHeadersList, not_api_call = false) => async (dispatch) => {
    try {
      dispatch({ type: DYNAMIC_UPDATED_COLUMNS_REQUEST });
      dispatch({
        type: DYNAMIC_UPDATED_COLUMNS_SUCCESS,
        payload: updatedHeadersList,
        not_api_call: not_api_call
      });
    } catch (err) {
      dispatch({ type: DYNAMIC_UPDATED_COLUMNS_FAIL });
    }
  };

export const tableDataFetch =
  (sentQuery, navigate, noRefresh = false, updateData = []) =>
    async (dispatch, getState) => {
      try {
        const table_columns = getSelectedColumns(
          getState().columnUpdate?.columns || []
        );

        console.log("TABBB", table_columns)
        // got the table columns here, will send them from here.

        // updating custom table query and checked row.
        dispatch(
          paginationDataAction({
            checkedRow: [],
            query: {
              ...getState()?.getPaginationQuery.data?.query,
            },
          })
        );

        const currentFilter = getState()?.allFilters?.payload;
        const URL =
          getState().tableEndpointChange?.payload?.trim() === "Saved Prospects"
            ? leadsEndpoints.savedContactSearch
            : leadsEndpoints.search;
        const filteredQuery = filterQuery(sentQuery);

        // Add columns with respective filter.
        const filteredQueryKeys = filterColumnQueryKeys(filteredQuery);
        for (let i in filteredQueryKeys) {
          if (!table_columns.includes(filteredQueryKeys[i])) {
            table_columns.push(filteredQueryKeys[i]);
          }
        }
        // dispatch columns
        const columns_clone = [...getState().columnUpdate?.columns];
        for (let i in columns_clone) {
          if (table_columns.includes(columns_clone?.[i]?.["id"])) {
            columns_clone[i].isChecked = true;
          }
        }
        dispatch(applyUpdateColumn(columns_clone));

        // const filter_key_values =

        if (noRefresh) {
          let query = {
            table_columns,
            ...currentFilter,
          };
          if (updateData?.length) {
            dispatch({
              type: TABLE_DATA_FETCH_SUCCESS,
              payload: updateData,
              totalRecords: getState().tableDataFetch?.totalRecords,
            });
          } else {
            const response = await postRequest(navigate, URL, query);

            dispatch({
              type: TABLE_DATA_FETCH_SUCCESS,
              payload: response?.data?.data?.records,
              totalRecords: response?.data?.data?.total_records,
            });
          }
        } else {
          try {
            // Add columns with respective filter.
            let query = {
              table_columns,
              ...filteredQuery,
            };
            dispatch({ type: TABLE_DATA_FETCH_REQUEST });
            const response = await postRequest(navigate, URL, query);

            dispatch({
              type: TABLE_DATA_FETCH_SUCCESS,
              payload: response?.data?.data?.records,
              totalRecords: response?.data?.data?.total_records,
            });
          } catch (err) {
            if (err?.status === 401) {
              // navigate from this action

              navigate(LOGIN_PATH);
            }
            dispatch({ type: TABLE_DATA_FETCH_FAIL, payload: err });
          }
        }
      } catch (error) {
        console.log("error_______", error);
      }
    };

export const tableDataCampaignFetch =
  (sentQuery, navigate, noRefresh = false) =>
    async (dispatch, getState) => {
      const table_columns = getSelectedColumns(getState().columnUpdate?.columns);
      // got the table columns here, will send them from here.

      const currentFilter = getState()?.allFilters?.payload;
      const URL = leadsEndpoints.savedContactCampaign;
      const filteredQuery = filterQuery(sentQuery);
      // Add columns with respective filter.
      const filteredQueryKeys = filterColumnQueryKeys(filteredQuery);
      for (let i in filteredQueryKeys) {
        if (!table_columns.includes(filteredQueryKeys[i])) {
          table_columns.push(filteredQueryKeys[i]);
        }
      }
      table_columns.push("campaign_name");
      table_columns.push("lead_date_time");
      table_columns.push("sequence_id");

      // dispatch columns
      const columns_clone = [...getState().columnUpdate?.columns];
      for (let i in columns_clone) {
        if (table_columns.includes(columns_clone?.[i]?.["id"])) {
          columns_clone[i].isChecked = true;
        }
      }
      dispatch(applyUpdateColumn(columns_clone));

      // const filter_key_values =

      if (noRefresh) {
        let query = {
          table_columns,
          ...currentFilter,
        };
        const response = await postRequest(navigate, URL, query);
        dispatch({
          type: TABLE_DATA_FETCH_CAMPAIGN_SUCCESS,
          payload: response?.data?.data?.records,
          totalRecords: response?.data?.data?.total_records,
        });
      } else {
        try {
          // Add columns with respective filter.
          let query = {
            table_columns,
            ...filteredQuery,
          };
          dispatch({ type: TABLE_DATA_FETCH_CAMPAIGN_REQUEST });
          const response = await postRequest(navigate, URL, query);

          dispatch({
            type: TABLE_DATA_FETCH_CAMPAIGN_SUCCESS,
            payload: response?.data?.records,
            totalRecords: response?.data?.total_records,
          });
        } catch (err) {
          if (err?.status === 401) {
            // navigate from this action

            navigate(LOGIN_PATH);
          }
          dispatch({ type: TABLE_DATA_FETCH_CAMPAIGN_FAIL, payload: err });
        }
      }
    };

export const companyDataFetch =
  (sentQuery, navigate, isSavedCompany = false) =>
    async (dispatch, getState) => {
      const table_columns = getSelectedColumns(getState().columnUpdate?.columns);
      const filteredQuery = filterQuery(sentQuery);

      // got the table columns here, will send them from here.
      const filteredQueryKeys = filterColumnQueryKeys(filteredQuery);
      for (let i in filteredQueryKeys) {
        if (!table_columns.includes(filteredQueryKeys[i])) {
          table_columns.push(filteredQueryKeys[i]);
        }
      }

      // dispatch columns
      const columns_clone = [...getState().columnUpdate?.columns];

      for (let i in columns_clone) {
        if (table_columns.includes(columns_clone?.[i]?.["id"])) {
          columns_clone[i].isChecked = true;
        }
      }
      dispatch(applyUpdateColumn(columns_clone));
      const URL =
        (getState().companyTableEndpointChange?.payload?.trim() ===
          "Saved Companies") || isSavedCompany
          ? leadsEndpoints.companySavedSearch
          : leadsEndpoints.companySearch;

      try {
        let query = {
          table_columns,
          ...filteredQuery,
        };
        dispatch({ type: TABLE_DATA_FETCH_REQUEST });
        const response = await postRequest(navigate, URL, query);

        dispatch({
          type: TABLE_DATA_FETCH_SUCCESS,
          payload: response?.data?.data?.records,
          totalRecords: response?.data?.data?.total_records,
        });
      } catch (err) {
        if (err?.status === 401) {
          // navigate from this action

          navigate(LOGIN_PATH);
        }

        dispatch({ type: TABLE_DATA_FETCH_FAIL, payload: err });
      }
    };

export const crmAccountDataFetch =
  (sentQuery, navigate) => async (dispatch, getState) => {
    try {
      const table_columns = getSelectedColumns(
        getState()?.dynamicColumnUpdate?.columns?.filter(item => !item.is_deal) || [...tempHeadCompanyCells]
      );
      // console.log("Dynami______ ", table_columns, getState().dynamicColumnUpdate?.columns);
      // got the table columns here, will send them from here.

      // storing all properties into below variable.
      const getPropertiesListSelector = getState()?.getPropertiesList?.data;
      let propertiesPayload = localStorage.getItem("properties") ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))?.properties_key_value : getPropertiesListSelector?.properties_key_value;

      // updating custom table query and checked row.
      dispatch(
        paginationDataAction({
          checkedRow: [],
          query: {
            ...getState()?.getPaginationQuery.data?.query,
          },
        })
      );
      const filteredQuery = filterDynamicQuery(
        sentQuery?.query,
        propertiesPayload
      );

      // Add columns with respective filter.
      const filteredQueryKeys = filterColumnQueryKeys(filteredQuery);
      for (let i in filteredQueryKeys) {
        if (!table_columns.includes(filteredQueryKeys[i])) {
          table_columns.push(filteredQueryKeys[i]);
        }
      }

      // dispatch columns
      const columns_clone = [...getState()?.dynamicColumnUpdate?.columns || [...tempHeadCompanyCells]];
      for (let i in columns_clone) {
        if (table_columns.includes(columns_clone?.[i]?.["id"])) {
          columns_clone[i].isChecked = true;
        }
      }
      dispatch(applyUpdateColumn(columns_clone));

      let query = {
        table_columns,
        ...filteredQuery,
      };

      try {
        dispatch({ type: TABLE_DATA_FETCH_REQUEST });
        const response = await postRequest(
          navigate,
          accountEndPoints.accountSearch,
          query
        );

        const properties_reference_key_value = getState()?.getPropertiesList?.data?.reference_key_value;
        const items = response?.data?.data?.records || [];

        const records = dataReferenceMapping(items, properties_reference_key_value);

        dispatch({
          type: TABLE_DATA_FETCH_SUCCESS,
          payload: { ...response?.data?.data, records, query: filteredQuery },
        });
      } catch (err) {
        dispatch({ type: TABLE_DATA_FETCH_FAIL, payload: err });
      }
    } catch (error) {
      console.log("error------------------> ", error);
    }
  };

export const tableEndpointChange = (payload) => async (dispatch) => {
  console.log("PAYLOADTable", payload)
  try {
    dispatch({ type: TABLE_ENDPOINT_CHANGE_REQUEST });
    dispatch({
      type: TABLE_ENDPOINT_CHANGE_SUCCESS,
      payload: payload,
    });
  } catch (err) {
    dispatch({ type: TABLE_ENDPOINT_CHANGE_FAIL });
  }
};

export const companyTableEndpointChange = (payload) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_TABLE_ENDPOINT_CHANGE_REQUEST });
    dispatch({
      type: COMPANY_TABLE_ENDPOINT_CHANGE_SUCCESS,
      payload: payload,
    });
  } catch (err) {
    dispatch({ type: COMPANY_TABLE_ENDPOINT_CHANGE_FAIL });
  }
};

// getListIds

export const getListIds = (payload) => async (dispatch) => {
  try {
    dispatch({ type: GET_LIST_IDS_REQUEST });
    dispatch({
      type: GET_LIST_IDS_SUCCESS,
      payload: payload,
    });
  } catch (err) {
    dispatch({ type: GET_LIST_IDS_FAIL });
  }
};
