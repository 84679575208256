import React from "react";
import { CONNECTOME_URL, CURRENT_YEAR } from "../../utils/constants";
import { BRAND, GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import Anchor from "../Anchor/Anchor";
import TypographyInline from "../Typography/TypographyInline";
import classes from "./Statics.module.css"

const RightsTag = () => {
  const styles = {
    hovering: {
      textDecoration: "none",
      "& :hover": {
        color: BRAND.PRIMARY,
      },
    },
    Padding: {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  };
  return (
    <>
      <TypographyInline
        size={"xs"}
        color={GREY.TERTIARY}
        fontWeight={fontWeight.REGULAR}
        label={` © ${CURRENT_YEAR}`}
      />

      <Anchor
        href={CONNECTOME_URL}
        target="_blank"
        style={styles.hovering}
        id={classes.hovering}
        className={classes.hovering}
      >
        <div style={styles.Padding} >
          <TypographyInline
            id={classes.companyLabel}
            size={"xs"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.REGULAR}
            label={" Connectome Inc."}
          />
        </div>
      </Anchor>
      <TypographyInline
        size={"xs"}
        color={GREY.TERTIARY}
        fontWeight={fontWeight.REGULAR}
        label={" All Rights Reserved."}
      />
    </>
  );
};

export default RightsTag;
