import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { deleteRequest } from "../../../../Apis/apiWrapper";
import {
  contactListEndPoints,
  listEndPoints,
} from "../../../../utils/constants/httpConstants";
import { getContactListData } from "../../../../redux/actions/contactListactions";
import { getErrorMessage } from "../../../../utils/helpers";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../strings";
import CustomModal from "../../../../components/Modals/CustomModal";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import { paginationDataAction } from "../../../../redux/actions/customTableActions";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import classes from "../markComponents.module.css";

const ListDeleteFolderModal = ({ open, setOpen, id1, name, recordId, val }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  
  const deleteRecord = async () => {
    if (id1) {
      try {
        setLoading(true);
        const payload = {
          list_ids: [id1],
        };
        const response = await deleteRequest(
          contactListEndPoints.deleteList,
          payload,
          navigate
        );
        dispatch(
          getContactListData(
            { name: "", parent: id },
            navigate
          )
        );
        dispatch(paginationDataAction({checkedRow:[]}));
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        setOpen(!open);
        setLoading(false);
      } catch (err) {
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(err?.data?.data?.errors || {}, err?.message)
        );
      } finally {
        setLoading(false);
        setOpen(false)

      }
    } else {
      try {
        setLoading(true);
        const payload = {
          record_ids: [recordId],
        };
        const response = await deleteRequest(
          listEndPoints.deleteFolderList,
          payload,
          navigate
        );
        dispatch(
          getContactListData(
            { name: "" },
            navigate({
              pathname: location.pathname,
            })
          )
        );
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        setOpen(!open);
        setLoading(false);
      } catch (err) {
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
        );
      } finally {
        setLoading(false);
        setOpen(false)
      }
    }
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: `${STRINGS.DELETE} ${name}`,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBodyContainer}>
      <TypographyInline
        size={"sm"}
        isEllipses={true}
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={`${STRINGS.DELETE_CONFIRMATION} ${val}?`}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  const modalFooterRightData = (
    <div className={classes.rightFoot}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.DELETE}
        size={"sm36"}
        variant={"primary"}
        onClick={deleteRecord}
        isLoading={loading}
      />
    </div>
  );
  return (
   
    <CustomModal
      open={open}
      variant={"error"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default ListDeleteFolderModal;
