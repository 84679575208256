import {
  IMPORT_CSVGET_FAIL,
  IMPORT_CSVGET_REQUEST,
  IMPORT_CSVGET_SUCCESS,
} from "../constants/csvContstants";
export const getImportCsvReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_CSVGET_REQUEST:
      return { loading: true };

    case IMPORT_CSVGET_SUCCESS:
      return { loading: false, payload: action.payload, error: null };

    case IMPORT_CSVGET_FAIL:
      return { loading: false, error: action.payload, payload: null };

    default:
      return state;
  }
};
