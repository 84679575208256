import React, { useState } from "react";
import { Box } from "@mui/system";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { contactListEndPoints } from "../../../utils/constants/httpConstants";
import { getErrorMessage } from "../../../utils/helpers";
import { postRequest } from "../../../Apis";
import { tableDataFetch } from "../../../redux/actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { Button } from "../../../components/Buttons";
import classes from "./LeadsComponent.module.css";


const DeleteRecordModal = ({
  open,
  setOpen,
  openEdit,
  setOpenEdit,
  listId,
  record_ids,
  list_ids,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const allFiltersSelector = useSelector((state) => state.allFilters);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const payload = {
        record_ids,
        list_ids,
      };
      const response = await postRequest(
        navigate,
        contactListEndPoints.removeRecordfromList,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      await dispatch(
        tableDataFetch(allFiltersSelector?.payload, navigate, true)
      );
      setOpen(false);
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.DELETE_LIST_MODAL,
      label: STRINGS.DELETE_CONTACT_FROM_LIST_MODAL_HEADER,
    },
  };

  const modalFooterRightData = (
    <Box className={classes.footerData}>
      <Button variant={"ghost"} size={"sm36"} onClick={handleClose} label={STRINGS.CANCEL} />
      <Button variant={"primary"} size={"sm36"} isLoading={loading} label={STRINGS.REMOVE_FROM_LIST} onClick={handleDelete} />
    </Box>
  )

  return (
    <CustomModal variant={"error"} open={open} close={listId ? () => setOpenEdit(!openEdit) : () => setOpen(!open)} header={modalHeaderData} footer={{
      right: modalFooterRightData,
    }} >
      <Box >
        <TypographyInline label={STRINGS.REMOVE_CONTACT_FROM_LIST_MODAL_DESCRIPTION} color={GREY.SECONDARY} size={"sm"} />
      </Box>
    </CustomModal>
  );
};

export default DeleteRecordModal;
