
export const commonStyles = {
  largeTitle: {
    color: "#212833",
    fontWeight: "700",
    fontSize: "clamp(20px,1.563vw,36px)",
    textAlign: "center",
    letterSpacing: "0",
    margin: "0rem 1rem",
    marginTop: 0,
  },

  largePara: {
    textAlign: "center",
    fontWeight: "300",
    color: "#212833",
    alignSelf: "center",
    fontSize: "clamp(12px,1.042vw,24px)",
    width: "90%",
    paddingTop: "1rem",
  },

  redButtonWithShadow: {
    background: "#ff4848",
    width: "100%",
    padding: "clamp(1rem,1vh,3rem)",
    // boxShadow: `0px 9px 20px ${RED_BOX_SHADOW_COLOR}`,
    border: "none",
    borderRadius: "8px",
    fontWeight: "700",
    color: "white",
    fontSize: "clamp(1rem,1vw,3rem)",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: " #0F2F51",
    },
  },
  flex: {
    display: "flex",
  },
  flexWithJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexWithJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },

  centerModal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    background: "white",
  },
};
