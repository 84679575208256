import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import IconComponent from "../../../components/Icon/IconComponent";
import { BASE, GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontSize } from "../../../utils/constants/UI";
import InputField from "../../../components/InputFields/InputField";
import classes from "./PropComponent.module.css";
import { STRINGS } from "../strings";

const MultiplePropertyValue = ({ propertiesVal, setpropertiesVal, values }) => {
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(propertiesVal);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setpropertiesVal(items);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? BASE.BACKGROUND_LIGHT : BASE.WHITE,
    height: "100%",
  });

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="inputValues">
        {(provided, snapshot) => (
          <div
            className={classes.multiValueMainContainer}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {propertiesVal?.map((val, index) => {
              return (
                <Draggable
                  key={val?.id}
                  draggableId={val?.id?.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={`${classes.multiValueSubContainer} ${
                        index === propertiesVal?.length - 1 &&
                        classes.borderRaidiusValueSubContainer
                      }`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className={classes.iconContainer}>
                        <IconComponent
                          color={GREY.TERTIARY}
                          fontSize={fontSize.DXS}
                          iconLabel={ICON_LABELS.DRAG_INDICATOR}
                        />
                      </div>
                      <div className={classes.inputFieldContainer}>
                        <InputField
                          placeholder={STRINGS.ENTER_LABEL_NAME}
                          size={"xs34"}
                          onChange={(event) => {
                            val?.labelOnchange(event, val, propertiesVal);
                          }}
                          value={val?.labelValue}
                        />
                      </div>
                      {/* <div className={classes.inputFieldContainer}>
                        <InputField
                          placeholder={STRINGS.ENTER_INTERNAL_VALUE}
                          size={"xs34"}
                          onChange={(event) => {
                            val?.internalOnchange(event, val, propertiesVal);
                          }}
                          value={val?.internalValue}
                        />
                      </div> */}
                      <div className={classes.deleteIconMainContainer}>
                        <div
                          className={
                            propertiesVal?.length > 1
                              ? classes.deleteIconContainer
                              : classes.deleteDisableIconContainer
                          }
                        >
                          <IconComponent
                            color={
                              propertiesVal?.length > 1
                                ? GREY.TERTIARY
                                : GREY.DISABLED
                            }
                            fontSize={fontSize.DXS}
                            iconLabel={ICON_LABELS.DELETE_OUTLINE}
                            onClick={() =>
                              val?.onDeleteClick(val, propertiesVal)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default MultiplePropertyValue;
