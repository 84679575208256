import React from "react";
import classes from "./Input.module.css";
import { ERROR } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import TypographyInline from "../Typography/TypographyInline";

const TextArea = ({ isResize, variant = "default", errormessage = "", ...props }) => {
  return (
    <>
      <textarea id={classes.textarea} className={`${isResize ? classes.textarea1 : classes.textarea} ${variant === "error" ? classes.textAreaError : ""}`} {...props} />
      {
        errormessage?.length ?
          <TypographyInline
            label={errormessage}
            color={ERROR.W_500}
            size={"xs"}
            fontWeight={fontWeight.REGULAR}
          />
          : null
      }
    </>
  );
};

export default TextArea;
