import { makeStyles } from "@mui/styles";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import { BASE, BORDER } from "../../utils/constants/colors";
import { SHADOW } from "../../utils/constants/UI";
import { SCROLL_BAR } from "../../utils/constants/UI/uiConstants";

export const styles = makeStyles({
  ImageCss:{
width:"120px",
height:"120px"
  },
  HEIGHt:{
height:"calc(100vh - 80px)",
  },
  MARGIN: {
    marginTop: "16px",
  },
  modalBodyContainer:{
padding:"16px"
  },
  containAllSteps1: {
    padding:"64px",
    display: "flex",
    justifyContent: "center",
    alignItems:"center"
  },
  txtAlgn: {
    textAlign: "center",
  },
  descContain: {
    padding: "1rem 0",
  },
  stepTypeBox: {
    display: "flex",
    // justifyContent: "flex-end",
    // padding: "1rem",
    // margin: "1rem 1rem",
    // borderRadius: "4px",
    flexDirection: "column",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  icon: {
    color: "#626B7E",
    fontSize: "20px",
    cursor: "pointer",
  },
  content: {
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "clamp(0.8rem, 0.8vw, 2rem)",
    padding: "0 1rem",
    marginTop: "clamp(1rem,1.5vw,2rem)",
  },
  containImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background: "rgba(255, 243, 226, 1)",
    borderRadius: "50%",
    // width: "10vw",
    // height: "10vw",
    alignSelf: "center",
    "@media (max-width: 1280px)": {
      // width: "13vw",
      // height: "13vw",
    },
  },
  subTitle: {
    fontSize: "clamp(0.8rem, 0.9vw, 2rem)",
    fontWeight: "500",
    color: "#102f51",
    textAlign: "center",
  },
  sort: {
    marginLeft: "6px",
    fontSize: "14px",
    color: "#D3DAE3",
    cursor: "pointer",
  },
  tableUi: {
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    gap: "16px",
    margin: "0px 16px 16px 16px",
    background: BASE.WHITE,
    boxShadow: SHADOW.xs,
    height: "calc(100% - 84px)",
    ...SCROLL_BAR,
  },
  exportCSv: {
    color: "#626B7E",
    textDecoration: "none",
  },
  mrginLeft: {
    marginLeft: "12px",
  },
  container: {
    paddingTop: "2rem",
    // backgroundColor: "#f9fafb",
    padding: 0,
    height: "100vh",
  },
  paperContainer: {
    width: "100%",
    mb: 2,
  },
  boxShadow: {
    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.07)",
  },
  circle: {
    height: "calc(100% - 57px)",
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
  },
  tableSize: { overflowX: "auto" },
  load: {
    height: "calc(100% - 57px)",
    display: "flex",
    // background: "#f9fafb",
    justifyContent: "center",
    alignItems: "center",
  },
  containerset: {
    paddingTop: "2rem",
    // backgroundColor: "#f9fafb",
    padding: "1rem",
    height: "100vh",
  },
  NOWRAP: {
    whiteSpace: "nowrap",
  },
  DISPLAY: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  displayed: {
    display: "flex",
    alignItems: "center",
    padding: "12px 16px",
  },
  red: {
    background: "rgba(255, 233, 233, 0.25)",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerContent: {
    // color: "#0F2F51",
    marginLeft: "20px",
  },
  folderRightSide: {
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    display: "flex",
    padding: "0rem 0rem 1rem 1rem",
    background: BASE.WHITE,
    marginTop: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    justifyContent: "space-between",
    "@media (max-width:1700px)": {
      // marginTop: "10px",
    },
  },

  displayFlex: {
    display: "flex",
    marginTop: "1rem",
    justifyContent: "space-between",
    // marginRight: "25px",
  },
  Test: {
    "& .MuiOutlinedInput-input": {
      color: "#626b7e !important",
    },
    fontWeight: "400 !important",
    fontFamily: "Inter !important",
    fontSize: "clamp(12px, 0.729vw, 20px) !important",
    color: "#626B7E !important",
  },
  searchIcon: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "350px",
    color: "#626B7E",
    paddingLeft: "12px",
    backgroundColor: "#fff",
    border: "1px solid #D3DAE3",
    borderRadius: "5px",
    "@media (max-width:1700px)": {
      height: "35px",
      width: "320px",
    },
  },
  headerButton: {
    backgroundColor: "#FF4848",
    color: "white",
    border: "none",
    padding: "12px 30px",
    // fontWeight:'500',
    borderRadius: "6px",
    marginRight: "20px",
    fontSize: "clamp(12px,0.729vw,16px)",

    "&:hover": {
      background: "#0F2F51",
    },
    // height: "5vh",
    cursor: "pointer",
    // marginTop: "10px",
    // marginBottom: "6px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    height: "64px",

    boxShadow: " 0px 2px 6px rgba(0, 0, 0, 0.07)",
    // boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.02)",
    "@media (max-width:1470px)": {
      height: "56px",
    },
  },

  filterContain: {
    display: "flex",
    margin: "0 1rem",
    width: "100%",
    maxHeight: "5rem",
    minHeight: "5rem",
    overflow: "auto",
  },
  filterText: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "14px",
    width: "100px",
    alignSelf: "center",
    textAlign: "center",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "25vh",
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center",
    margin:"auto"
  },
  contactsOverlay: {
    display: "flex",
    height: "3rem",
    background: "white",
    boxShadow: "0px 2px 4px #00000008",
    margin: "1rem 1rem 0 0",
    borderRadius: "6px 6px 0 0",
    borderBottom: "2px solid #ff4848",
    padding: "1rem",
    cursor: "pointer",
  },
  contactsText: {
    fontSize: "13px",
    color: "#626B7E",
    fontWeight: "400",
    whiteSpace: "nowrap",
    alignSelf: "center",
  },

  cleanBox: {
    padding: 0,
    margin: 0,
  },
  redText: {
    color: "#626b73",
    fontSize: "clamp(0.8rem, 0.8vw, 2rem)",
    fontWeight: "500",
    cursor: "pointer",
    transition: "100ms ease-in-out",
    "&:hover": {
      color: "#ff4848",
    },
  },
  padding1rem: {
    padding: "1rem",
  },
  flexRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  // title: {
  //   textAlign: "center",
  //   fontSize: "clamp(1rem, 1vw, 3rem)",
  //   color: "#092f51",
  // },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    // height: "calc(100vh - 15rem)",

    height: "calc(100vh - 0rem)",
  },
  // commonSpanTableNopointBlack: {
  //   display: "flex",
  //   fontSize: "14px",
  //   color: "#212833 ",
  //   fontWeight: "600",
  //   alignItems: "center",
  //   textTransform: "capitalize",
  // },
  commonSpan: {
    textAlign: "center",
    fontWeight: "400",
    /* font-size: clamp(12px, 0.729vw, 30px); */
    whitespace: "nowrap",
    color: "#FFC107",
    // cursor: "pointer",
    fontSize: "12px",
    background: "#FFF8E1",
    padding: "4px 12px",
    borderRadius: "6px",
    textTransform: "capitalize",
    /* width: 125px; */
  },
  commonSpaninterupt: {
    // marginLeft: "12px",
    textAlign: "center",
    fontWeight: "400",
    fontSize: "12px",
    /* font-size: clamp(12px, 0.729vw, 30px); */
    whitespace: "nowrap",
    color: "#FF3B3B",
    // cursor: "pointer",
    background: "#FFEDED",
    padding: "4px 12px",
    borderRadius: "6px",
    textTransform: "capitalize",
    /* width: 125px; */
  },

  commonSpanProcess: {
    marginLeft: "12px",
    textAlign: "center",
    fontWeight: "400",
    fontSize: "12px",
    //
    /* font-size: clamp(12px, 0.729vw, 30px); */
    whitespace: "nowrap",
    color: "#626B7E ",
    cursor: "pointer",
    background: "#FFFFFF",
    padding: "4px 12px",
    border: "1px solid #D3DAE3 ",
    borderRadius: "6px",
    textTransform: "capitalize",
    /* width: 125px; */
  },
  commonSpanGreen: {
    textAlign: "center",
    fontWeight: "400",
    fontSize: "12px",

    /* font-size: clamp(12px, 0.729vw, 30px); */
    whitespace: "nowrap",
    // cursor: "pointer",

    background: "#E8F5E9",
    color: "#4CAF50",

    padding: "4px 12px",
    borderRadius: "6px",
    textTransform: "capitalize",
    /* width: 125px; */
  },
  commonSpanTableNopointRed: {
    display: "flex",
    fontSize: "14px",

    fontWeight: "600",
    alignItems: "center",
    textTransform: "capitalize",

    color: "#FF4848",
  },
  containUploadBtn: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    height: "calc(100vh - 10rem)",
  },
  fileInputWrapper: {
    fontSize: "100px",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
  },
  uploadBtn: {
    background: "#ff4848",
    padding: "1rem 4rem",
    border: "none",
    color: "white",
    borderRadius: "6px",
    cursor: "pointer",
  },
  uploadBtnWrapper: {
    alignSelf: "center",
    position: "relative",
  },
  eachColumn: {
    display: "flex",
    paddingBottom: "1.5rem",
    justifyContent: "space-between",
    margin: "0 20vw",
    "@media (max-width: 768px)": {
      margin: "0 10vw",
    },
  },
  importModalColumnsContainer: {
    height: "calc(100vh - 6rem)",
    overflow: "auto",
    background: "white",
    padding: "1rem",
    margin: "1rem",
    ...TABLE_SCROLL_BAR,
  },
  containEachColumnName: {
    width: "15rem",
    display: "flex",
    alignItems: "center",
  },
  containCustomFields: {
    padding: "3vh 15vw",
  },
  tableContainer: {
    margin: "2rem 0",
  },
  importTitle: {
    fontSize: "clamp(1.3rem, 1.3vw, 4rem )",
    color: "#102f51",
    fontWeight: "500",
    textAlign: "center",
  },
  importPara: {
    color: "#102f51",
    fontSize: "clamp(1rem , 1vw, 2rem)",
    fontWeight: "600",
    textAlign: "center",
    padding: "2vw 0",
  },
  importPara1: {
    color: "#626B7E",
    fontSize: "clamp(0.8rem , 0.8vw, 2rem)",
    fontWeight: "500",
    textAlign: "center",
    padding: "1vw 0",
  },
  padding: {
    marginRight: "1rem",
  },
  inputFile: {
    display: "none",
  },
  commonWidth: {
    width: "21.615vw",
  },
  flexJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  containDropBox: {
    border: "1px dashed #d3dae3",
    padding: "2rem 1rem 0 1rem",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "100ms ease-in-out",
    "&:hover": {
      border: "1px solid #ff4848",
    },
  },

  containDropBox2: {
    marginRight: "1rem",
    padding: "12px 30px",
    cursor: "pointer",

    "&:hover": {
      background: "#0F2F51",
    },

    backgroundColor: "#FF4848",
    color: "white",
    border: "none",

    borderRadius: "6px",
    fontSize: "clamp(12px,0.729vw,16px)",
  },
  blackListText: {
    color: "#102f51",
    fontSize: "clamp(1rem, 1vw, 3rem)",
    fontWeight: "500",
  },
  containBlacklist: {
    margin: "1.5rem",
    background: "white",
    width: "calc(100vw - 29.5rem)",
  },
  eachOp: {
    border: "1px solid #d3dae3",
    padding: "1rem",
    borderRadius: "6px",
    margin: "1rem 0.5rem",
    cursor: "pointer",
  },
  containHeader: {
    padding: "1.5rem",
    background: "white",
  },
  divideinto2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  blackListBtn: {
    color: "#FF4848",
    background: "#ffe9e9",
    border: "none",
    borderRadius: "6px",
    padding: "0.5rem 1.5rem",
    fontSize: "clamp(0.8rem, 0.8vw, 3rem)",
    cursor: "pointer",
    maxWidth: "10vw",
  },
  ApplyBtn: {
    background: "#FF4848",
    color: "white",
    border: "none",
    borderRadius: "6px",
    padding: "0.5rem 1.5rem",
    fontSize: "clamp(0.8rem, 0.8vw, 3rem)",
    cursor: "pointer",
    maxWidth: "10vw",
  },

  eachBlacklistBlock: {
    display: "flex",
    justifyContent: "center",
    padding: "2rem",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #d3dae3",
    margin: "1rem",
    borderRadius: "6px",
  },

  header: {
    color: "#102F51",
    fontSize: "clamp(0.8rem, 0.8vw, 3rem)",
    fontWeight: "500",
    paddingRight: "1rem",
    cursor: "pointer",
  },
  selectedHeader: {
    color: "#ff4848",
    fontSize: "clamp(0.8rem, 0.8vw, 3rem)",
    fontWeight: "500",
    paddingRight: "1rem",
    cursor: "pointer",
  },
  containHeads: {
    display: "flex",
    padding: "1rem 1.5rem",
    borderBottom: "1px solid #d3dae3",
    paddingTop: "0",
  },
  containTable: {
    padding: "1rem",
    // width:'calc(100% - 10rem)',
  },
  flexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  containTableHeads: {
    display: "grid",
    background: " #F3F5F7",
    padding: "1rem",
    borderRadius: "4px 4px 0 0",
    width: "fit-content",
  },

  tableHead: {
    color: "#0B1534",
    fontSize: "clamp(0.75rem, 0.75vw, 2rem)",
    fontWeight: "500",
  },
  eachTableRow: {
    display: "grid",
    padding: "1rem",
    background: "white",
  },
  containCommonTable: {
    width: "calc(100vw - 32rem)",
    overflow: "auto",
    border: "1px solid #d3dae3",
    borderRadius: "6px",
    maxHeight: "calc(100vh - 18rem)",
    ...TABLE_SCROLL_BAR,
  },
  eachTableRecord: {
    color: "#626B7E",
    fontSize: "clamp(0.7rem, 0.7vw, 2rem)",
    whiteSpace: "nowrap",
    fontWeight: "400",
    // width:'10rem',
  },
  deleteBtn: {
    background: "#FFE9E9",
    color: "#ff4848",
    padding: "8px 1.5rem",
    border: "none",
    borderRadius: "6px",
    fontWeight: "400",
    fontSize: "clamp(0.75rem, 0.75vw, 2rem )",
  },
  eachBlock: {
    display: "flex",
    alignItems: "center",
  },
  cotnainEachHead: {
    display: "flex",
    // justifyContent:'center'
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "50vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },

  bigModal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "75vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },
  // flex:{
  //     display:'flex'
  // },
  containCrossIcon: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    alignItems: "center",
    cursor: "pointer",
    height: "2rem",
  },
  title1: {
    color: "#102f51",
    fontSize: "clamp(1.1rem, 1.1vw, 3rem)",
    fontWeight: "400",
    alignSelf: "center",
  },
  inputContainer: {
    width: "100%",
  },
  input: {
    width: "100% !important",
    padding: "1rem !important",
  },
  input1: {
    width: "30vw !important",
    padding: "1.5rem 1rem !important",
  },
  listBox: {
    width: "83%",
  },
  marginTop: {
    marginTop: "1rem",
  },
  inviteBtn: {
    background: "#ff4848",
    border: "none",
    color: "white",
    width: "10vw",
    padding: "1rem 2.5rem",
    marginTop: "1rem",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
  },
  redBackgroundBtn: {
    background: "#ff4848",
    color: "white",
    fontSize: "clamp(0.9rem, 0.85vw. 3rem)",
    padding: "1rem 2rem",
    borderRadius: "6px",
    border: "none",
    marginTop: "1rem",
    cursor: "pointer",
  },
  AlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  commonSpanTableNopoint: {
    display: "flex",
    fontSize: "14px",
    color: "#626B7E",
    fontWeight: "500",
  },
  commonSpanTableNopoints: {
    fontSize: "14px",
    color: "#626B7E",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    width: "200px",
  },
  commonSpanTableNopointwidth: {
    display: "flex",
    fontSize: "14px",
    color: "#626B7E",
    fontWeight: "500",
    width: "125px",
  },
  commonSpanTableNopointBlack: {
    display: "flex",
    fontSize: "14px",
    color: "#212833 ",
    fontWeight: "600",
    alignItems: "center",
    textTransform: "capitalize",
    // "&:hover":{
    //   color:"#FF4848"
    // }
  },
  containSearchImage: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "calc(100% - 13rem)",
    margin: "1rem 1rem",
    borderRadius: "4px",
    marginTop: "0",
    // boxShadow: "0px 1px 6px rgb(105 111 118 / 20%)",
    // background: "#f9fafb",
  },
  title: {
    color: "#0F2F51",
    fontWeight: "800",
    fontSize: "clamp(1.4rem, 1.7vw, 4.2rem)",
    marginBottom: "1rem",
  },
  searchImage: {
    width: "clamp(3rem, 6vw, 6rem)",
    objectFit: "contain",
    paddingBottom: "1rem",
  },
  rowHeight: {
    height: "70px",
  },
  Import: {
    cursor: "pointer",
    marginLeft: "20px",
    padding: "0.5rem 1rem",
    background: "white",
    color: "#626B7E",
    fontSize: "12px",
    border: "1.5px solid #D3DAE3",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  auto: {
    overflow: "auto",
    height: "calc(100vh - 200px)",
    ...TABLE_SCROLL_BAR,
    "@media (min-width: 1400px)": {
      height: "calc(100vh - 220px)",
    },
  },
  tableHeadings: {
    color: "#0F2F51",
    fontWeight: "500",
    fontSize: "clamp(14px,0.833vw,16px)",
    background: "white",
    alignSelf: "center",
    whiteSpace: "nowrap",
    textTransform: "capitalize",

    // padding: "0px 2px !important",
    // border:"2px solid red"
  },
  containBlacklistModalItems: {
    padding: "1rem 1.5rem",
  },
  containEachInput: {
    padding: "1rem 0",
  },
  dialogModal: {
    paddingTop: "1rem",
    width: "100%",
  },
  searchContainer: {
    border: "1px solid #d3dae3",
    padding: "0.5rem 1rem",
    display: "flex",
    paddingLeft: "4px",
    borderRadius: "4px",
    width: "40%",
  },
  searchBox: {
    border: "none",
    width: "100%",
    paddingLeft: "4px",
    fontSize: "clamp(0.75rem, 0.75vw, 3rem)",
  },
  filtersBtn: {
    background: "white",
    border: "1px solid #d3dae3",
    padding: "8px",
    borderRadius: "4px",
    color: "#626B7E",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: "#d3dae3",
    },
  },
  filterLabel: {
    color: "#626B7E",
    fontSize: "clamp(0.75rem, 0.75vw, 2.5rem)",
    fontWeight: "500",
    display: "block",
  },
  filterinput: {
    padding: "8px 12px",
    fontSize: "clamp(0.75rem, 0.75vw, 2.5rem)",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    marginTop: "8px",
    width: "100%",
  },
  containAllFilter: {
    padding: "1rem",
    paddingTop: "0",
  },
  containEachFilter: {
    paddingTop: "1rem",
  },
  hline: {
    border: `1px solid ${BORDER.DEFAULT}`,
    height: "8vw",
    margin:"40px 40px",

  },
  FilterJobTitle:{
    display:"flex",
    gap:"16px",
    paddingTop:"9px"
  }
});
