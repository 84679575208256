import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptEmail } from '../../utils/helpers';
import { postRequest } from '../../Apis';
import { authEndpoints } from '../../utils/constants/httpConstants';
import EventEmitter from '../../utils/emitter/EventEmitter';
import { EMITTER_ERROR, EMITTER_SUCCESS } from '../../utils/constants';
import { FullScreenLoader } from '../../components';
import * as qs from "qs";

const ConnectInboxScreen = () => {

    const payload = useParams();
    const navigate = useNavigate();
    const parsedObject = qs.parse(window.location.search?.slice(1));

    useEffect(() => {
        console.log('parsedObject__', parsedObject);
        (async () => {
            try {

                const decryptedPayload = decryptEmail(payload.payload);
                const response = await postRequest(navigate, authEndpoints.connectInboxVerify, JSON.parse(decryptedPayload));
                EventEmitter.emit(EMITTER_SUCCESS, response.data.message);

            } catch (error) {
                EventEmitter.emit(EMITTER_ERROR, error.data.message);
            } finally {
                if (parsedObject.tenant_path) {
                    navigate(parsedObject.tenant_path)
                }
            }
        })()
    }, []);

    return <FullScreenLoader />

}

export default ConnectInboxScreen;