import React, { useState } from "react";
import { Box } from "@mui/material";
import { SEQUENCE_STEP_TYPES } from "../../../../utils/constants";
import { STRINGS } from "../strings";
import CustomModal from "../../../../components/Modals/CustomModal";
import { fontWeight } from "../../../../utils/constants/UI";
import { GREY } from "../../../../utils/constants/colors";
import { Button } from "../../../../components/Buttons";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import Image from "../../../../components/Image/Image";
import classes from "../markComponents.module.css";
const ChooseStepModal = ({ open, setOpen, stepNumber, reSubject, seqId, nextstep,foldId }) => {

  const [stepSelected, setStepSelected] = useState({});
  const [open1,setOpen1] = useState(false);
  
  const handleEditorOpen = (step) => {
    setOpen1(true);
    setStepSelected(step);
  };

  const handleClose = () => {
    setOpen(false);
    setStepSelected("");
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: STRINGS.CHOOSE_HEAD,
    },
  };

  const modalBodyData = (
    <div className={classes.modalBodyContainer}>
      <Box>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.CHOOSE_STEPS_TEXT}
        />
      </Box>
      
      <Box className={classes.displayFlex}>
        {SEQUENCE_STEP_TYPES.map((step) => {
          return (
            <Box key={step.title} className={classes.stepTypeBox}>
              <Box className={classes.containImage}>
                <Image src={step.iconComponent}/>
              </Box>
              <Box className={classes.descContain}>
                
                <Box className={classes.txtAlgn}>
                  <TypographyInline
                    size={"md"}
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.SEMIBOLD}
                    label={step.title}
                  />
                </Box>
                <Box className={classes.txtAlgn}>
                  <TypographyInline
                    size={"sm"}
                    color={GREY.QUATINARY}
                    fontWeight={fontWeight.REGULAR}
                    label={step.description}
                  />
                </Box>
               
              </Box>
              <Box className={classes.justifyContentCenter}>
                <Button
                  label={`${STRINGS.ADD} ${step.title}`}
                  variant={"ghost"}
                  size={"sm36"}
                  onClick={() => handleEditorOpen(step)}
                />
                
              </Box>
            </Box>
          );
        })}
      </Box>
    </div>
  );

  return (
    <>
      <CustomModal
        open={open}
        close={() => setOpen(false)}
        header={modalHeaderData}
        children={modalBodyData}
        width={"840px"}
      />
      {
        open1 && (
        <stepSelected.component
          handleClose={handleClose}
          stepNumber={stepNumber}
          reSubject={reSubject}
          myId={seqId}
          foldId={foldId}
          open={open1}
          setOpen={setOpen1}
        />
      )}
    </>
    
  );
};

export default ChooseStepModal;
