import React, { useEffect, useState } from "react";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY, PRIMARY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import classes from "./DealsComp.module.css";
import { Accordion } from "@mui/material";
import {
  AccordionDetails,
  AccordionSummary,
  HtmlEditor,
} from "../../../../components";

import IconComponent from "../../../../components/Icon/IconComponent";
import IconColoredComponent from "../../../../components/Icon/IconColoredComponent";
import { useNavigate } from "react-router-dom";
import { STRINGS } from "../../strings";
import {
  compressString,
  dateTimeStringEmailLog,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import { AutoCompleteWoBorder } from "../../../../components/Autocomplete/AutoCompleteWoBorder";
import CustomMenu from "../../../../components/Menu/CustomMenu";
import { Button } from "../../../../components/Buttons";
import CustomDatePickerWoBorder from "../../../../components/DatePicker/CustomDatePickerWoBorder";
import TimeSelector from "../../../../components/TimeSelector/TimeSelector";
import { meetingsEndpoint } from "../../../../utils/constants/httpConstants";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../Apis/apiWrapper";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  OUTCOME_LIST,
  TIMEZONE_LIST,
} from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";

import AddComment from "../../Components/AddComment";
import parse from "html-react-parser";
import MeetingDeleteModal from "./MeetingDeleteModal";
import { useSelector } from "react-redux";
import WarningModalMeetingCrm from "./WarningModalMeetingCrm";
import DeleteCommentModal from "../../Components/DeleteCommentModal";
import TextArea from "../../../../components/InputFields/TextArea";
import InputFieldWoBorder from "../../../../components/InputFields/InputFieldWoBorder";

const ListingMeetingCard = ({
  id,
  logData,
  dealId,
  setMeetingListData,

  assigneeList,
  expanded,
  dealDetails,
  getMeetingLogList,
  showOperation = true,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dueTime, setDueTime] = useState("00:00");
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [meetingLogComments, setMeetingLogComments] = useState([]);
  const [commentBody, setCommentBody] = useState("");
  const [loader, setLoader] = useState(false);
  const [createCommentLoading, setCreateCommentLoading] = useState(false);
  const [taskLogCommentsPage, setLogTaskCommentsPage] = useState(0);
  const [isDeleteCommentLoading, setIsDeleteCommentLoading] = useState(false);
  const [deleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [clickedCommentData, setClickedCommentData] = useState({});
  const [attendeeType, setAttendeeType] = useState([""] || []);
  const [attendeeTypeId, setAttendeeTypeId] = useState([""] || []);
  const [durationType, setDurationType] = useState([""] || []);
  const [durationId, setDurationId] = useState([""] || []);
  const [timezoneType, setTimezoneType] = useState([""] || []);
  const [timezoneTypeId, setTimezoneTypeId] = useState([""] || []);
  const [notesClicked, setNotesClicked] = useState(false);
  const [outcomeType, setOutcomeType] = useState([""] || []);
  const [outcomeTypeId, setOutcomeTypeId] = useState([""] || []);
  const [editorView, setEditorView] = useState(false);
  const [notesView, setNotesView] = useState(false);
  const [meetingNotes, setMeetingNotes] = useState("");

  const [forceCreate, setForceCreate] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  let offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone_objChange = TIMEZONE_LIST?.filter(
    (item) => item?.id === offset
  );
  const userDet = useSelector((state) => state.userDetails);

  const tableOperations = [
    {
      id: STRINGS.SMALL_PIN,
      label: logData?.is_pin === 1 ? STRINGS.UNPIN : STRINGS.PIN,
      iconLabel: ICON_LABELS.PUSH_PIN,
      function: () => handlePin(),
    },

    {
      id: STRINGS.SMALL_DELETE,
      label: STRINGS.DELETE,
      iconLabel: ICON_LABELS.DELETE,
      function: () => handleTaskRecordDelete(),
      variant: "error",
    },
  ];
  const handleModal = (event) => {
    setIsActionMenuVisible(true);
    setActionMenuAnchor(event.currentTarget);
  };
  //Handling pin Action here
  const handlePin = async () => {
    let payload = {
      query: {
        pin: logData?.is_pin === 1 ? 0 : 1,

        id: logData?._id,
      },
      deal_id: dealId,
    };
    try {
      const URL = meetingsEndpoint?.pinMeetingCrm;
      const response = await postRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      getMeetingLogList();
    } catch (error) {}
  };

  //handling task Delete Record
  const handleTaskRecordDelete = async () => {
    setOpenTask(true);
  };

  //previous Duetime set here -setDueTime
  useEffect(() => {
    if (logData?.event_startDateTime_timestamp) {
      let hours = new Date(
        parseInt(logData?.event_startDateTime_timestamp)
      ).getHours();
      let minutes = new Date(
        parseInt(logData?.event_startDateTime_timestamp)
      ).getMinutes();

      if (hours.toString().length === 1) {
        hours = "0" + hours;
      }
      if (minutes.toString().length === 1) {
        minutes = "0" + minutes;
      }
      const set = hours + ":" + minutes;
      setDueTime(set);
    } else {
      let hoursTime = new Date().getHours();
      let minutesTime = new Date().getMinutes();
      if (minutesTime >= 45) hoursTime++;
      hoursTime = hoursTime % 24;
      minutesTime += 15;
      minutesTime = minutesTime % 60;
      if (hoursTime.toString().length === 1) {
        hoursTime = "0" + hoursTime;
      }
      if (minutesTime.toString().length === 1) {
        minutesTime = "0" + minutesTime;
      }

      const setTiming = hoursTime + ":" + minutesTime;

      setDueTime(setTiming);
    }
    //set the previous data
    setDescription(logData?.event_description);

    setAttendeeType([logData?.attendee_name]);

    setAttendeeTypeId([logData?.attendee_email]);
    setDurationId([logData?.duration_options]);
    setDurationType([logData?.duration_options]);
    setDueDate(new Date(logData?.event_startDateTime));
    setTimezoneTypeId([logData?.attendee_timezone?.id]);
    setTimezoneType([logData?.attendee_timezone?.label]);
    setOutcomeType([logData?.outcome]);
    setOutcomeTypeId([logData?.outcome]);
    setMeetingNotes(logData?.notes);
    if (expanded) {
      setOpen(expanded);
    }
  }, [logData]);

  //Handling Edit Api
  const handleEditApi = async () => {
    setLoader(true);

    try {
      let dateObj = dueDate;
      const NewDate = dueTime?.split(":").map(Number);
      let m = NewDate[1];
      let h = NewDate[0];
      let totalms = h * 60 * 60 * 1000 + m * 60 * 1000;
      let month = new Date(dateObj)?.getUTCMonth() + 1;
      let day = new Date(dateObj)?.getUTCDate();
      let year = new Date(dateObj)?.getUTCFullYear();
      let newdate = year + "-" + month + "-" + day;

      let date;
      if (dueTime) {
        date = new Date(newdate).getTime() + totalms;
      } else {
        date = new Date(newdate).getTime();
      }
      const customEditorValue = description?.length
        ? `<div>${description}</div>`
        : "";
      const timezone_attendee = TIMEZONE_LIST?.filter(
        (item) => item?.id === timezoneTypeId[0]
      );

      const UpdateDate = new Date(date);
      const formattedDate = UpdateDate.toISOString().slice(0, -5);
      if (logData?.type === STRINGS.CRM) {
        const payload = {
          event_title: logData?.event_title,

          duration_options: durationId[0],
          attendee_name: attendeeType[0],
          attendee_timezone: timezone_attendee[0],
          complete_time: date !== null ? formattedDate : "",
          description: compressString(
            decodeURIComponent(removeTagStyleFromHtml(customEditorValue))
          ),
          organizer_timezone: timezone_objChange[0],
          attendee_email: attendeeTypeId[0],
          from_email: userDet?.payload?.email,
          eventId: logData?.eventId,
          deal_id: dealId,
          force_edit: forceCreate,
          notes: meetingNotes,
        };
        const URL = meetingsEndpoint?.editCrmMeeting;
        const response = await postRequest(navigate, URL, payload);
        setLoader(false);
        setEditorView(false);
        setNotesView(false);

        setVisible(false);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } else {
        const payload = {
          duration_options: durationId[0],
          attendee_name: attendeeType[0],
          attendee_timezone: timezone_attendee[0],
          outcome: outcomeType[0],
          _id: logData?._id,
          complete_time: date !== null ? formattedDate : "",
          description: compressString(
            decodeURIComponent(removeTagStyleFromHtml(customEditorValue))
          ),
          organizer_timezone: timezone_objChange[0],
          attendee_email: attendeeTypeId[0],
          from_email: userDet?.payload?.email,
          eventId: logData?.eventId,
          deal_id: dealId,
          notes: meetingNotes,
        };
        const URL =
          logData?.type === STRINGS.CRM
            ? meetingsEndpoint?.editCrmMeeting
            : meetingsEndpoint?.editLogCrmMeeting;
        const response = await postRequest(navigate, URL, payload);
        setLoader(false);
        setEditorView(false);
        setVisible(false);
        setNotesView(false);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      }
    } catch (error) {
      if (error?.status === 412) {
        setOpenWarningModal(true);
        setErrMessage(error?.data?.message);
      } else {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
      setLoader(false);
      // EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  useEffect(() => {
    if (open) {
      getTaskLogComments(false, true);
    }
  }, [open]);
  useEffect(() => {
    if (taskLogCommentsPage) {
      getTaskLogComments(true, false);
    }
  }, [taskLogCommentsPage]);

  useEffect(() => {
    if (forceCreate) {
      handleEditApi();
    }
  }, [forceCreate]);
  // creating task log comment api handler
  const createCommentHandler = async () => {
    try {
      setLogTaskCommentsPage(0);
      let payload = {
        query: {
          comments: commentBody,
          eventId: logData?.eventId,
        },
        deal_id: dealId,
      };
      let payload2 = {
        query: {
          comments: commentBody,
          meeting_id: logData?._id,
        },
        deal_id: dealId,
      };
      setCreateCommentLoading(true);
      const Url =
        logData?.type === STRINGS.CRM
          ? meetingsEndpoint.createCommentMeetingCrm
          : meetingsEndpoint.addCommentLogCrmMeeting;
      const response = await postRequest(
        navigate,
        Url,
        logData?.type === STRINGS.CRM ? payload : payload2
      );
      getTaskLogComments(false, true);
      setCreateCommentLoading(false);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>>> ", error);
    }
  };

  // edit task log comment api handler
  const updateTaskLogCommentHandler = async (comment, editValue) => {
    try {
      setLogTaskCommentsPage(0);
      let payload = {
        deal_id: dealId,
        query: {
          comments: editValue[comment?.id],
          id: comment?.id,
        },
      };
      const URL = meetingsEndpoint.editCommentMeetingCrm;
      const response = await postRequest(navigate, URL, payload);
      getTaskLogComments(false, true);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  // delete task log comment api handler
  const deleteTaskLogCommentHandler = async (data) => {
    try {
      setLogTaskCommentsPage(0);
      setIsDeleteCommentLoading(true);
      let payload = {
        query: {
          comments: data?.comments,
          id: data?.id,
        },
        deal_id: dealId,
      };
      let url = `${meetingsEndpoint?.deleteCommentMeetingCrm}`;
      const response = await deleteRequest(url, payload, navigate);
      getTaskLogComments(false, true);
      setIsDeleteCommentLoading(false);
      setDeleteCommentModalOpen(false);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  const deleteTaskLogCommentModalHandler = (data) => {
    setClickedCommentData(data);
    setDeleteCommentModalOpen(true);
  };

  // api call for getting task log comments
  const getTaskLogComments = async (isOnScroll, initial) => {
    try {
      let url =
        meetingsEndpoint.getCommentMeetingCrm +
        `/${logData?.eventId}?size=10&page=${
          initial ? 0 : taskLogCommentsPage
        }`;
      let url2 =
        meetingsEndpoint.getCommentLogCrmMeeting +
        `/${logData?._id}?size=10&page=${initial ? 0 : taskLogCommentsPage}`;
      const response = await getRequest(
        navigate,
        logData?.type === "CRM" ? url : url2
      );
      if (isOnScroll) {
        setMeetingLogComments([
          ...meetingLogComments,
          ...response?.data?.data?.records,
        ]);
      } else {
        setMeetingLogComments([...response?.data?.data?.records]);
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };
  const handleResetContDetails = () => {
    setVisible(false);
    setEditorView(false);
    if (logData?.event_startDateTime_timestamp) {
      let hours = new Date(
        parseInt(logData?.event_startDateTime_timestamp)
      ).getHours();
      let minutes = new Date(
        parseInt(logData?.event_startDateTime_timestamp)
      ).getMinutes();

      if (hours.toString().length === 1) {
        hours = "0" + hours;
      }
      if (minutes.toString().length === 1) {
        minutes = "0" + minutes;
      }
      const set = hours + ":" + minutes;
      setDueTime(set);
    } else {
      let hoursTime = new Date().getHours();
      let minutesTime = new Date().getMinutes();
      if (minutesTime >= 45) hoursTime++;
      hoursTime = hoursTime % 24;
      minutesTime += 15;
      minutesTime = minutesTime % 60;
      if (hoursTime.toString().length === 1) {
        hoursTime = "0" + hoursTime;
      }
      if (minutesTime.toString().length === 1) {
        minutesTime = "0" + minutesTime;
      }

      const setTiming = hoursTime + ":" + minutesTime;

      setDueTime(setTiming);
    }
    setDescription(logData?.event_description);
    setDueDate(new Date(logData?.event_startDateTime));
    // setDueTime(timeStamp);
    if (document.getElementById(id)) {
      document.getElementById(id).innerHTML = logData?.event_description;
    }

    setAttendeeType([logData?.attendee_name]);
    setDurationId([logData?.duration_options]);
    setDurationType([logData?.duration_options]);
    setTimezoneTypeId([logData?.attendee_timezone?.id]);
    setTimezoneType([logData?.attendee_timezone?.label]);
  };
  const options = [
    { id: 15, label: STRINGS.FIFTEEN_MIN },
    { id: 30, label: STRINGS.THIRTY_MIN },
    { id: 45, label: STRINGS.FORTYFIVE_MIN },
    { id: 60, label: STRINGS.ONE_HOUR },
    { id: 90, label: STRINGS.ONE_5_HOUR },
    { id: 120, label: STRINGS.TWO_HOUR },
  ];
  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );
  const updatedRecord = tenantUsersListSelector?.filter((eachRecord) => {
    return eachRecord?._id === logData?.userId;
  })[0];

  console.log({ visible });
  return (
    <div className={classes.HeadTaskContainer}>
      <Accordion
        className={classes.accrodionRoot}
        onChange={() => setOpen(!open)}
        expanded={open}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {" "}
          <div className={classes.summaryContainerHeads}>
            <div className={classes.summaryContainerMeeting}>
              {logData?.is_pin ? (
                <div className={logData?.is_pin ? classes.defaultIcon : ""}>
                  <IconComponent
                    iconLabel={logData?.is_pin ? ICON_LABELS.PUSH_PIN : ""}
                    color={logData?.is_pin ? PRIMARY.W_400 : ""}
                    fontSize={fontSize.XS}
                  />
                </div>
              ) : (
                ""
              )}
              <div className={classes.summaryRightSection}>
                <IconComponent
                  color={GREY.TERTIARY}
                  fontSize={fontSize.DXS}
                  iconLabel={
                    open
                      ? ICON_LABELS.ACTION_ARROW
                      : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                  }
                />

                <IconColoredComponent
                  variant={"purple"}
                  iconLabel={ICON_LABELS.EVENT_NOTE}
                  color={"purple"}
                  size="small"
                />

                <TypographyInline
                  label={
                    logData?.type === "CRM"
                      ? STRINGS.MEETING_CREATED
                      : STRINGS.MEETING_LOGGED
                  }
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={GREY.PRIMARY}
                />
                <TypographyInline
                  color={GREY.SECONDARY}
                  label={
                    logData?.attendee_name
                      ? `${STRINGS.BY} ${updatedRecord?.first_name} ${updatedRecord?.last_name}  ${STRINGS.WITH}`
                      : `${STRINGS.BY} ${updatedRecord?.first_name || ""} ${
                          updatedRecord?.last_name || ""
                        } `
                  }
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  isNoWrap
                />
                {logData?.attendee_name == " " ? (
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={`${logData?.attendee_email}`}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                    isEllipses
                  />
                ) : (
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={`${logData?.attendee_name || ""}`}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                    isEllipses
                  />
                )}
              </div>

              <div className={classes.dateTimeTaskContainer}>
                <IconComponent
                  iconLabel={ICON_LABELS.ACCESS_TIME}
                  fontSize={fontSize.MD}
                  color={GREY.SECONDARY}
                />
                <TypographyInline
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"sm"}
                  label={dateTimeStringEmailLog(logData?.updated_at)}
                />

                {showOperation && (
                  <>
                    <div className={classes.verticalLine} />
                    <div
                      className={
                        updatedRecord
                          ? classes.moreOptionsIconContianer
                          : classes.moreOptionsIconContianerban
                      }
                      onClick={(e) => {
                        e?.stopPropagation();
                      }}
                    >
                      <IconComponent
                        color={GREY.TERTIARY}
                        fontSize={fontSize.MD}
                        iconLabel={ICON_LABELS.MORE_VERT}
                        onClick={updatedRecord ? handleModal : ""}
                      />

                      <CustomMenu
                        items={tableOperations}
                        anchor={actionMenuAnchor}
                        open={isActionMenuVisible}
                        onClose={() => {
                          setIsActionMenuVisible(false);
                          setActionMenuAnchor(null);
                        }}
                        paperMenuClass={classes.dropdownMenuListing}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.meetingDescriptionLabel}>
            <div className={classes.companyDetailMainContainer}>
              <div className={classes.taskDetailsContainer}>
                <div>
                  {" "}
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.ATTENDEE}
                    isEllipses
                  />
                  {logData?.attendee_name == " " ? (
                    <InputFieldWoBorder
                      size={"sm36"}
                      value={logData?.attendee_email}
                      variant={"disabled"}
                    />
                  ) : (
                    <AutoCompleteWoBorder
                      size={"sm36"}
                      setValueState={setAttendeeType}
                      onChange={() => {
                        setVisible(true);
                      }}
                      variant={"disabled"}
                      items={assigneeList}
                      LeftIcon=""
                      valueState={attendeeType}
                      idState={attendeeTypeId}
                      setIdState={setAttendeeTypeId}
                    />
                  )}
                </div>
                <div className={classes.verticalLine} />
                <div>
                  {" "}
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.DATE}
                    isEllipses
                  />
                  <CustomDatePickerWoBorder
                    disablePast={true}
                    value={dueDate}
                    onChange={(newValue) => {
                      setDueDate(newValue?.$d);
                      setVisible(true);
                    }}
                    size={"sm36"}
                  />
                </div>
                <div className={classes.verticalLine} />
                <div>
                  {" "}
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.TIME}
                    isEllipses
                  />
                  <TimeSelector
                    time={dueTime}
                    setTime={(time) => {
                      setDueTime(time);
                      setVisible(true);
                    }}
                    removeBorder
                  />
                </div>{" "}
                <div className={classes.verticalLine} />
                <div>
                  {" "}
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.DURATION}
                    isEllipses
                  />
                  <AutoCompleteWoBorder
                    size={"sm36"}
                    setValueState={setDurationType}
                    onChange={() => {
                      setVisible(true);
                    }}
                    items={options}
                    LeftIcon=""
                    valueState={durationType}
                    idState={durationId}
                    setIdState={setDurationId}
                  />
                </div>
                <div className={classes.verticalLine} />
                {logData?.type === "CRM" ? (
                  <div>
                    {" "}
                    <TypographyInline
                      color={GREY.SECONDARY}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                      label={STRINGS.ATTENDEE_TIMEZONE}
                      isEllipses
                    />
                    <AutoCompleteWoBorder
                      onChange={() => {
                        setVisible(true);
                      }}
                      items={TIMEZONE_LIST}
                      LeftIcon=""
                      valueState={timezoneType}
                      setValueState={setTimezoneType}
                      idState={timezoneTypeId}
                      setIdState={setTimezoneTypeId}
                      size={"sm36"}
                    />
                  </div>
                ) : (
                  <div>
                    {" "}
                    <TypographyInline
                      color={GREY.SECONDARY}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                      label={STRINGS.OUTCOME}
                      isEllipses
                    />
                    <AutoCompleteWoBorder
                      paperWidth="40vw"
                      items={OUTCOME_LIST}
                      onChange={() => {
                        setVisible(true);
                      }}
                      LeftIcon=""
                      valueState={outcomeType}
                      setValueState={setOutcomeType}
                      idState={outcomeTypeId}
                      setIdState={setOutcomeTypeId}
                      size={"sm36"}
                    />
                  </div>
                )}
              </div>
              {logData?.event_title ? (
                <div className={classes.TitleClass}>
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.TITLE}
                    isEllipses
                  />
                  <TypographyInline
                    color={GREY.PRIMARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={logData?.event_title || ""}
                    isEllipses
                  />
                </div>
              ) : (
                ""
              )}
              {logData?.event_description ? (
                <>
                  <div
                    className={`${classes.logEmailListSummaryLowerContainer} ${
                      notesClicked ? classes.notesBackground : ""
                    }`}
                    onClick={() => {
                      setNotesClicked(true);
                    }}
                    onBlur={() => {
                      setNotesClicked(false);
                    }}
                  >
                    <div>
                      <TypographyInline
                        color={GREY.PRIMARY}
                        label={STRINGS.DESCRIPTION}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                      />
                    </div>
                    {editorView ? (
                      <div className={classes.htmlEditor}>
                        <HtmlEditor
                          isAddTagNotVisible={true}
                          isMeetingTagNotVisible={true}
                          isAITagNotVisible
                          isFocusNotRequired
                          id={id}
                          previosData={logData?.event_description}
                          onInput={(event) => {
                            setDescription(event?.target?.innerHTML);
                            setVisible(true);
                          }}
                          editorHeight={"8.625vw"}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => setEditorView(true)}
                        className={classes.parsedEmailLogData}
                      >
                        {parse(logData?.event_description)}
                      </div>
                    )}
                  </div>
                  {logData?.notes?.length ? (
                    <div
                      // className={classes.notesMeetings}
                      className={`${
                        classes.logEmailListSummaryLowerContainer
                      } ${classes.notesMeetings}${
                        notesClicked ? classes.notesBackground : ""
                      }`}
                    >
                      <TypographyInline
                        color={GREY.PRIMARY}
                        label={STRINGS.MEETING_NOTES}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                      />
                      {notesView ? (
                        <div
                          onClick={() => {
                            setNotesClicked(true);
                          }}
                          onBlur={() => {
                            setNotesClicked(false);
                          }}
                        >
                          <div></div>{" "}
                          <TextArea
                            variant={"default"}
                            isResize={true}
                            placeholder={STRINGS.ADD_REASON}
                            value={meetingNotes}
                            fullWidth
                            name="Message"
                            maxLength={500}
                            onChange={(event) => {
                              setMeetingNotes(event?.target?.value);
                              setVisible(true);
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => setNotesView(true)}
                          // className={classes.notesMeetings}
                        >
                          {logData?.notes || ""}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {visible ? (
                    <div className={classes.aboutFooter}>
                      <div>
                        <Button
                          label={STRINGS.CANCEL}
                          size={"sm36"}
                          variant={"ghost"}
                          onClick={() => {
                            handleResetContDetails();
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          label={STRINGS.SAVE}
                          size={"sm36"}
                          variant={"primary"}
                          onClick={() => {
                            handleEditApi();
                          }}
                          isLoading={loader}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : visible ? (
                <div className={classes.aboutFootervisible}>
                  <div>
                    <Button
                      label={STRINGS.CANCEL}
                      size={"sm36"}
                      variant={"ghost"}
                      onClick={() => {
                        handleResetContDetails();
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      label={STRINGS.SAVE}
                      size={"sm36"}
                      variant={"primary"}
                      onClick={() => {
                        handleEditApi();
                      }}
                      isLoading={loader}
                      disabled={showOperation ? false : true}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* detail comment section */}
          <div className={classes.taskLogListCommentContainer}>
            {showOperation && (
              <AddComment
                createCommentHandler={createCommentHandler}
                value={commentBody}
                setValue={setCommentBody}
                isLoading={createCommentLoading}
                commentsList={meetingLogComments}
                onScrollHandler={() =>
                  setLogTaskCommentsPage(taskLogCommentsPage + 1)
                }
                updateCommentHandler={updateTaskLogCommentHandler}
                deleteModalHandler={deleteTaskLogCommentModalHandler}
              />
            )}

            <DeleteCommentModal
              open={deleteCommentModalOpen}
              close={() => {
                setDeleteCommentModalOpen(false);
              }}
              clickedData={clickedCommentData}
              deleteButtonLoading={isDeleteCommentLoading}
              deleteAPIHandler={deleteTaskLogCommentHandler}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {openTask && (
        <MeetingDeleteModal
          logData={logData}
          id={logData?.eventId}
          openTask={openTask}
          setOpenTask={setOpenTask}
          dealId={dealId}
          setMeetingListData={setMeetingListData}
          dealDetails={dealDetails}
          getMeetingLogList={getMeetingLogList}
        />
      )}

      {openWarningModal && (
        <WarningModalMeetingCrm
          open={openWarningModal}
          close={setOpenWarningModal}
          setForceCreate={setForceCreate}
          errMessage={errMessage}
        />
      )}
    </div>
  );
};

export default ListingMeetingCard;
