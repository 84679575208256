import {
    LISTCONTACT_FOLDER_REQUEST,
    LISTCONTACT_FOLDER_SUCCESS,
    LISTCONTACT_FOLDER_FAIL
} from "../constants/contactListFolderConstants"

export const getContactListFolderReducer=(state={},action) => {
    switch(action.type){
        case LISTCONTACT_FOLDER_REQUEST:
            return { loading: true };
          case LISTCONTACT_FOLDER_SUCCESS:
            return { loading: false, data: action.payload, error: null };
          case LISTCONTACT_FOLDER_FAIL:
            return { loading: false, error: action.payload };
          default:
            return state;
    }
}