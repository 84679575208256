export const STRINGS = {
  SELECT_LOGO: "Select Logo",
  BRAND_KIT: "Brand Kit",
  LOGO_URL: "Logo Url",
  APP_HEAD: "This applies across any reachiq accounts you have.",
  BRAND_DELETE: " Are you sure you want to Delete this Branding Detail?",
  SENDGRID: "sendgrid",
  GMAIL: "gmail",
  password: "password",
  MAILGUN: "mailgun",
  LOADING: "loading...",
  HOURLY: "Hourly",
  OLD: "Old Password",
  NEW: "New Password",
  CONFIRM: "Confirm New Password",
  HOURLY_LIMIT_EXCEED: "Hourly limit cannot exceed more than 100",
  DAILY_LIMIT_EXCEED: "Daily limit cannot exceed more than 1000",
  HOUR_LIMIT_DAILY: "Hour limit cannot more than Daily limit",
  BACK_COLOR: "Background Color",
  BRANDING_CREATE_SUCCESS: "Branding Created Successfully ",
  BRANDING_UPDATE_SUCCESS: "Branding Created Successfully ",
  BRANDING_DELETE_SUCCESS: "Branding Deleted Successfully ",
  DAILY: "Daily",
  SUCCESS_DELETE: "Successfully Deleted",
  SERVER_MESSAGE: "Server Message Misplaced.",
  PRIORITY_NAME: "Priority*",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  MAX_SEC: "You have reached your limit to create branding kits  ",
  CANCEL: "Cancel",
  PRIMARY: "Primary",
  ERR_MESSAGE:
    "The image you are trying to upload exceeds the maximum allowed size of 200 KB.   ",
  UPDATE: "Update",
  SAVE: "Save",
  DRAG: "or drag and drop PNG,",
  JPG_IMAGE: "JPG (max. 200kb)",
  CLICK_UPLOAD: "Click to upload",
  REQUIRED: "This field is required",
  WRITE_BRAND: "Write Your Brand Kit Name",
  SECONDARY: "Secondary",
  BRANDING: "Branding",
  MY_BRAND: "My Brand Kits",
  BRAND_NAME: "Brand Kit Name*",
  COLORS: "Colors",
  BRANDING_DESC:
    "Set your logo and colors for tools where you can show your branding. These settings will help you quickly choose brand options when creating public facing content.",
  DELETE: "Delete",
  CREATE_BRAND: " Create a new brand kit",
  DIS_GMAIL: "Disconnect Gmail",
  ARE_YOU_SURE_DISCONNECT: "Are you sure you want to Disconnect?",
  ENTER_API: "Enter API Key",
  GMAIL_LIMIT: "Gmail Email Limit",
  ENTER_DOMAIN: "Enter Domain",
  SENDGRID_EMAIL_LIMIT: "SendGrid Email Sending Limit",
  GOOGLE_SIGN: "Sign In with Google",
  GMAIL_EMAIL_SENDING: "Gmail Email Sending Limit",
  DISCONNECT: "Disconnect",
  MAILGUN_LIMIT: "Mailgun Email Sending Limit",

  COMMON_SAVE: "Save",
  CONNECT_WITH_APPS: "Connect with Apps",
  IS_CONNECT: "No Google tokens found",
  DATA_MANAGEMENT: "Data Management",
  SECURITY: "Security",
  SECURITY_DESC: "This applies across any ReachIQ accounts you have.",
  UPDATE_PASSWORD: "Update Password",
  PASSWORD: "Change Password",
  ERROR: "error",
  DEFAULT: "default",
  SETTING: "Settings",
  PREFERENCE: "Your Preference",
  ACC_SETUP: "Account Setup",
  INVITE_USER: "Invite Users",
  INVITED_USERS: "Pending Invitation",
  INVITE_DESC:
    "  Create new users, customize user permissions, and remove users from your account.",
  USERS_DESC:
    " Create new users, customize user permissions, and remove users from your account.",
  USERS: "Users",
  SAVED_CONTACTS: "Saved Contacts",
  CONNECTED: "Connected",
  ALLAPPS: "All Apps",
  ALLAPPSID: "all_apps",
  HEADING: "Connect with Apps",
  SUBHEADING: "This applies across any ReachIQ accounts you have",
  BUTTONSTRING: "connect",
  EMIALSENDINGLIMIT: "Email Sending Limits",
  EDIT: "Edit",
  HOURLY: "Hourly",
  DAILY: "Daily",
  HOURLYNO: "100",
  DAILYNO: "250",
  SET_EMAIL_LIMIT: "Set Email Limit",
  CANCEL: "Cancel",
  SAVE: "Save",
  CONNECT_GMAIL: "Connect your Gmail inbox",
  API_KEY: "API Key",
  PERMISSION: "Permissions",
  CONNECT: "Connect",
  DISABLED: "disabled",
  CONNECT_OUTLOOK: "Connect your Outlook",
  INBOX_ADDED: "Inbox Added",
  NOT_CONNECTED: "Not connected",
  ADD: "Add",
  API_KEY: "API Key*",
  EMAIL: "Email*",
  ENTR_EML: "Enter email",
  DOMAIN: "Domain*",
  MAKE_IT_PRIMARY: "Make it Primary",
  NO_RECORD_FOUND: "No Record Found",
  INTEGRATIONS: "Integrations",
  GENERAL: "General",
  OLD_PASSWORD: "Old Password*",
  NEW_PASSWORD: "New Password*",
  CONFIRM_PASSWORD: "Confirm New Password*",
  SEND_TEST_MAIL: "Send test mail",
  NAME: "Name*",
  ENTR_NAME: "Enter name",
  US: "US",
  EU: "EU",
  REGION: "Region*",
  PULL: "Pull signature",
  EMAIL_SIGNATURE: "Email signature",
  EDIT_SIGNATURE: "Edit signature",
  PULL_SIGN: "Extract from Gmail",
  SAVE_CHG: "Save Changes",
  FORMS: "Forms",
  FORMS_DESC: "Modify and Update fields on different forms within ReachIQ.",
  SELECT_FORM: "Select a form:",
  CONTACTS_SMALL: "contacts",
  ACCOUNTS_SMALL: "accounts",
  DEALS_SMALL: "deals",
  ADD_PROPERTIES_SMALL: "add_properties",
  ADD_PROPERTIES: "Add Properties",
  ADD_ASSOCIATIONS_SMALL: "add_associations",
  ADD_ASSOCIATIONS: "Add Assocations",
  OTHER: "Other",
  CONTACT: "Contact",
  PREVIEW: "Preview",
  PREVIEW_SMALL: "preview",
  SEARCH: "Search...",
  DATE_PICKER: "Date Picker",
  NUMBER: "Number",
  MULTI_LINE_TEXT_INPUT: "Multi-line Text Input",
  RICH_TEXT_EDITOR: "Rich Text Editor",
  MULTIPLE_SELECT: "Multiple Select",
  SINGLE_SELECT: "Single Select",
  COMPANY: "Company",
  DEALS: "Deals",
  ASSOCIATED_CONTACT_SMALL: "associated_contact",
  ASSOCIATED_COMPANY_SMALL: "associated_company",
  INSTRUCTIONS: "Instructions",
  DESC_INST2: "2. Upload the image in both PNG and JPG (Max 200KB).",
  DESC_INST1: "1. Image width must be precisely 350 pixels.",
  SELECT_ALL: "Select All"
};
