import { Styles } from "../Styles";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { capitalizeFirstLetter } from "../../../utils/helpers";

export const RemovePopUp = (props) => {
  console.log("PROPS", props)
  const classes = Styles();
  const removeHandle = async () => {
    await props.removeWidget();
  };

  const cancelHandle = () => {
    props.onClose();
  };

  const modalHeaderData = {
    heading: {
      id: "delete-dashboard",
      label: `${STRINGS.REMOVE} "${capitalizeFirstLetter(props?.type)}"`,
    },
  };

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.REMOVE}
        size={"sm36"}
        variant={"primary"}
        onClick={removeHandle}
        isLoading={props.loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      variant={"error"}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
    >
      <TypographyInline
        label={STRINGS.REMOVE_WIDGET_BODY_LABEL}
        size={"sm"}
        fontWeight={fontWeight.REGULAR}
        color={GREY.SECONDARY}
      />
    </CustomModal>
  );
};
