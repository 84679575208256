import React from "react";
import { MultiSelectTextField } from ".";
import { importEndpoints } from "../../../utils/constants/httpConstants";
import { domainValidationForBlacklist } from "../../../utils/validations/inputValidations";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";

const BlacklistDoaminModal = ({ open, setOpen, setData = () => { } }) => {

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.BLACKLIST_DOMAINS,
    },
  };
  const modalBodyData = (
    <MultiSelectTextField
      setOpen={setOpen}
      validationFunction={domainValidationForBlacklist}
      endPoint={importEndpoints.blackListDomain}
      payloadKey={STRINGS.DOMAIN_BLOCKED}
      placeholder={STRINGS.ENTER_MUTLTIPLE_DOMAINS}
      setRecords={setData}
      getEndpoint={importEndpoints.getBlackListDomains}
      getPayloadKey={STRINGS.BLOCKED_DOMAINS}

    />
  );


  return (
    <CustomModal
      width={"50vw"}
      close={() => setOpen(false)}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
    />
  );
};

export default BlacklistDoaminModal;
