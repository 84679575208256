import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  EACH_BATCH_SIZE,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  IMPORT_COLUMN_PROPERTY_TYPES,
  IMPORT_CREATE_TYPE,
  IMPORT_EMPTY_FIELD_OPTIONS,
} from "../../../../utils/constants";
import * as Papaparse from "papaparse";
import {
  getErrorMessage,
  match_import_fields,
} from "../../../../utils/helpers";
import { Uploader } from "../../../../utils/helpers/Uploader";
import { ErrorMessage } from "../../../../components";
import { TABLE_SCROLL_BAR } from "../../../../utils/constants/styles";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { postRequest } from "../../../../Apis";
import { importEndpoints } from "../../../../utils/constants/httpConstants";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../../strings";
import ProgressEmitter from "../../../../components/Loaders/ProgressEmitter";
import classes from "./CrmComp.module.css";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import Checkbox from "../../../../components/Buttons/Checkbox/Checkbox";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import { Button } from "../../../../components/Buttons";
import {
  ATLEAS_ONE_FIELD,
  IMPORT_COMPANY_WEBSITE_EMAIL_MANDATORY,
  IMPORT_COMPANY_WEBSITE_MANDATORY,
  IMPORT_FILE_EMAIL,
  IMPORT_FILE_UPLOAD_SUCCESS,
} from "../../../../utils/constants/messages";

const styles = {
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "75vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },
  flex: {
    display: "flex",
    paddingTop: "1rem",
    justifyContent: "space-between",
  },
  containCrossIcon: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    alignItems: "center",
    cursor: "pointer",
    height: "2rem",
  },
  icon: {
    color: "#7c7d7e",
    fontSize: "20px",
  },
  title: {
    color: "#102f51",
    fontSize: "clamp(1.1rem, 1.1vw, 3rem)",
    fontWeight: "400",
    alignSelf: "center",
  },
  importModalColumnsContainer: {
    ...TABLE_SCROLL_BAR,
  },
  eachColumn: {
    display: "grid",
    paddingBottom: "1rem",
    gridTemplateColumns: "1fr 1fr 1fr 1fr ",
    padding: "0 1rem",
    paddingTop: "1rem",
  },
  column_title: {
    color: "#616A7D",
    fontWeight: "400",
    fontSize: "clamp(0.75rem, 0.75vw, 2rem)",
    alignSelf: "center",
    background: "white",
    padding: "0.6rem 1rem",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    paddingRight: "0",
    border: "1px solid #d3dae3",
    width: "100%",
    borderRadius: "4px",
  },
  containBtns: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "2.5rem",
  },
  column_label: {
    alignSelf: "center",
  },
  iconContainer: {
    width: "2rem",
  },
  headerTitle: {
    color: "#102f51",
    fontSize: "clamp(0.85rem, 0.8vw, 2rem)",
  },
  flexJustifyCenter: {
    paddingLeft: "1rem",
  },
  dropdown: {
    padding: "0.5rem 0",
  },
  containDropdowns: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    paddingTop: "1rem",
  },
};

const MappingFieldModal = ({
  open,
  setOpen,
  file,
  columns,
  setFile,
  peopleDynamicFields,
  companyDynamicFields,
  predefinedPeopleCustomFields,
  predefinedCompanyCustomFields,
  getImportedFilesList,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetailsSelector = useSelector(
    (state) => state?.userDetails?.payload
  );

  const [allColumns, setAllColumns] = useState(columns || []);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [fillEmptyId, setFillEmptyId] = useState([
    IMPORT_EMPTY_FIELD_OPTIONS[0].id,
  ]);
  const [fillEmptyValue, setFillEmptyValue] = useState([
    IMPORT_EMPTY_FIELD_OPTIONS[0].label,
  ]);
  const [uploadTypeValue, setUploadTypeValue] = useState([
    IMPORT_CREATE_TYPE[2].label,
  ]);
  const [uploadTypeId, setUploadTypeId] = useState([IMPORT_CREATE_TYPE[2].id]);
  const [selectMatchColumnsOnly, setSelectMatchColumnsOnly] = useState(false);
  const [addToCrm, setAddToCrm] = useState(false);
  const [peopleCustomFields, setPeopleCustomFields] = useState(
    peopleDynamicFields || []
  );
  const [companyCustomFields, setCompanyCustomFields] = useState(
    companyDynamicFields || []
  );
  const [predefinedPeopleFields, setPredefinedPeopleFields] = useState(
    predefinedPeopleCustomFields || []
  );
  const [predefinedCompanyFields, setPredefinedCompanyFields] = useState(
    predefinedCompanyCustomFields || []
  );
  const [errorMessage, setErrorMessage] = useState({ id: "", message: "" });
  const [columnsUploading, setColumnsUploading] = useState(false);
  const [importCreateType, setImportCreateType] = useState([
    ...IMPORT_CREATE_TYPE,
  ]);

  // send only property_value in items.
  const checkSelectedFields = (items) => {
    const all_company_fields = [
      ...companyDynamicFields,
      ...predefinedCompanyCustomFields,
    ].map((item) => item.id);
    const all_people_fields = [
      ...peopleDynamicFields,
      ...predefinedPeopleCustomFields,
    ].map((item) => item.id);
    const is_people_field_available = all_people_fields.some((item) =>
      items.includes(item)
    );
    const is_company_field_available = all_company_fields.some((item) =>
      items.includes(item)
    );
    return { is_people_field_available, is_company_field_available };
  };

  async function readFileChunk(time, index, fil) {
    let reader = new FileReader();
    let blob = fil.slice(
      EACH_BATCH_SIZE * index,
      EACH_BATCH_SIZE * (index + 1)
    );
    reader.onload = async function (e) {
      const each_parsed_string = e.target.result;
      const ParsedRes = Papaparse.parse(each_parsed_string);
      const result = [];
      const tempData = ParsedRes.data[0];
      const property_values = [];
      for (let i = 0; i < tempData?.length; i++) {
        if (tempData?.[i]) {
          let eachObj = { column: tempData?.[i], selected: true };
          const each_property_value = match_import_fields(
            tempData?.[i],
            peopleCustomFields,
            companyCustomFields,
            predefinedPeopleCustomFields,
            predefinedCompanyCustomFields
          );

          eachObj.property_value = each_property_value.property_value;
          eachObj.property_type = each_property_value.property_type;
          eachObj.property_type_label = each_property_value.property_type_label;
          let each_property_label = "";
          const custom_fields =
            each_property_value.property_type === STRINGS.PEOPLE_SMALL
              ? peopleCustomFields
              : companyCustomFields;
          const each_property_find = custom_fields?.filter(
            (item) => item?.id === each_property_value?.property_value
          );
          if (each_property_find?.length) {
            each_property_label = each_property_find?.[0]?.label;
          }
          eachObj.property_label = each_property_label;

          each_property_value?.property_value &&
            property_values.push(each_property_value?.property_value);
          result.push(eachObj);
        }
      }

      // comapny and people changes here.
      const newPeopleDynamicFields = peopleCustomFields?.filter((item) => {
        return !property_values?.includes(item?.id);
      });
      const newCompanyDynamicFields = companyCustomFields?.filter((item) => {
        return !property_values?.includes(item?.id);
      });

      const newPredefinedPeopleFields = predefinedPeopleFields?.filter(
        (item) => {
          return !property_values?.includes(item?.id);
        }
      );
      const newPredefinedCompanyFields = predefinedCompanyFields?.filter(
        (item) => {
          return !property_values?.includes(item?.id);
        }
      );

      // adding isCustomField Key here.
      const newPeopleDynamicFields1 = newPeopleDynamicFields?.map((item) => {
        return { ...item, isCustomField: false };
      });
      const newPredefinedPeopleFields1 = newPredefinedPeopleFields?.map(
        (item) => {
          return { ...item, isCustomField: true };
        }
      );

      const newCompanyDynamicFields1 = newCompanyDynamicFields?.map((item) => {
        return { ...item, isCustomField: false };
      });
      const newPredefinedCompanyFields1 = newPredefinedCompanyFields?.map(
        (item) => {
          return { ...item, isCustomField: true };
        }
      );

      setPeopleCustomFields([
        ...newPeopleDynamicFields1,
        ...newPredefinedPeopleFields1,
      ]);
      setCompanyCustomFields([
        ...newCompanyDynamicFields1,
        ...newPredefinedCompanyFields1,
      ]);
      setPredefinedPeopleFields(newPredefinedPeopleFields);
      setPredefinedCompanyFields(newPredefinedCompanyFields);

      const selected_proprty_values = result
        .filter((item) => item?.selected)
        .map((item) => item.property_value);
      const field_selection = checkSelectedFields(selected_proprty_values);
      if (
        field_selection.is_company_field_available &&
        field_selection.is_people_field_available
      ) {
        // update import Type here.
        setImportCreateType(
          importCreateType.filter((item) => item.id !== "update_only")
        );
      }
      setAllColumns(result);
      // make file sending endpoint here.
    };
    reader.readAsText(blob);
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const initiateUploadingProcess = () => {
    let percentage = undefined;
    const videoUploaderOptions = {
      fileName: file?.name || "foo",
      user_email: userDetailsSelector?.email,
      file: file,
      initialEndpoint: importEndpoints.uploadFileInitialEndpoint,
      multipartEndpoint: importEndpoints.uploadFileMultipartEndpoint,
      finalEndpoint: importEndpoints.uploadFilefinalEndpoint,
      navigate: navigate,
    };
    const uploader = new Uploader(videoUploaderOptions);

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          percentage >= uploadPercentage && setUploadPercentage(percentage);
        }
      })
      .onError((error) => {
        console.error(error);
      });

    uploader.start();
  };

  useEffect(() => {
    initiateUploadingProcess();
    (async () => {
      await readFileChunk(1000, 0, file);
    })();
  }, [file]);

  useEffect(() => {
    if (uploadPercentage === 100) {
      EventEmitter.emit(EMITTER_SUCCESS, IMPORT_FILE_UPLOAD_SUCCESS);
    }
  }, [uploadPercentage, dispatch]);

  const handlePropertyTypeChange = (data, index, column) => {
    const currentValue = data?.id[0];
    const currentPropertyValue = data?.label[0];
    const itemsClone = [...allColumns];
    const prevArray = itemsClone.slice(0, index);
    const nextArray = itemsClone.slice(index + 1);
    if (currentValue === "company") {
      let allFields = [...peopleDynamicFields];
      let changed_field = allFields?.filter((item) => {
        return item?.label === column?.property_label;
      });
      setPeopleCustomFields([...peopleCustomFields, ...changed_field]);
    } else {
      let allFields = [...companyDynamicFields];
      let changed_field = allFields?.filter((item) => {
        return item?.label === column?.property_label;
      });
      setCompanyCustomFields([...companyCustomFields, ...changed_field]);
    }
    const currentElement = itemsClone[index];
    currentElement.property_type = currentValue;
    currentElement.property_type_label = currentPropertyValue;
    currentElement.property_value = "";
    currentElement.property_label = "";
    setAllColumns([...prevArray, currentElement, ...nextArray]);
  };

  const handlePropertyValueChange = (e, index, previousValue, type) => {
    const currentValue = e?.id[0];
    const itemsClone = [...allColumns];
    const prevArray = itemsClone.slice(0, index);
    const nextArray = itemsClone.slice(index + 1);
    const currentElement = itemsClone[index];
    const current_custom_fields =
      type === STRINGS.PEOPLE_SMALL ? peopleCustomFields : companyCustomFields;
    currentElement.property_value = currentValue;
    currentElement.property_label = current_custom_fields?.filter(
      (item) => item?.id === currentValue
    )?.[0]?.label;

    setAllColumns([...prevArray, currentElement, ...nextArray]);

    if (type === STRINGS.PEOPLE_SMALL) {
      const newCustomFields = [
        ...peopleCustomFields?.filter((item) => item?.id !== currentValue),
      ];
      if (previousValue) {
        let prevValue;
        prevValue = peopleDynamicFields?.filter(
          (item) => item?.id === previousValue
        )?.[0];
        if (prevValue) {
          prevValue.isCustomField = false;
        } else {
          prevValue = predefinedPeopleCustomFields?.filter(
            (item) => item?.id === previousValue
          )?.[0];
          prevValue.isCustomField = true;
        }
        prevValue && newCustomFields.push(prevValue);
      }
      setPeopleCustomFields(newCustomFields);
    } else {
      const newCustomFields = [
        ...companyCustomFields?.filter((item) => item?.id !== currentValue),
      ];
      if (previousValue) {
        let prevValue;
        prevValue = companyDynamicFields?.filter(
          (item) => item?.id === previousValue
        )?.[0];
        if (prevValue) {
          prevValue.isCustomField = false;
        } else {
          prevValue = predefinedCompanyCustomFields?.filter(
            (item) => item?.id === previousValue
          )?.[0];
          prevValue.isCustomField = true;
        }
        newCustomFields.push(prevValue);
      }
      setCompanyCustomFields(newCustomFields);
    }

    settingImportTypeValues(allColumns);
  };

  const handleClose = () => {
    setFile("");
    setOpen(!open);
  };

  const handleCheckBoxClick = (column) => {
    setSelectMatchColumnsOnly(false);
    const newColumn = { ...column };
    newColumn.selected = column?.selected ? false : true;
    const all_columns_new = [];
    for (let i in allColumns) {
      if (allColumns?.[i]?.column !== newColumn?.column) {
        all_columns_new.push(allColumns[i]);
      } else {
        all_columns_new.push(newColumn);
      }
    }
    setAllColumns(all_columns_new);

    settingImportTypeValues(all_columns_new);
  };

  const settingImportTypeValues = (all_columns_new) => {
    const selected_proprty_values = all_columns_new
      .filter((item) => item?.selected && item?.property_value)
      .map((item) => item.property_value);
    const field_selection = checkSelectedFields(selected_proprty_values);
    if (
      field_selection.is_company_field_available &&
      field_selection.is_people_field_available
    ) {
      // update import Type here.
      setImportCreateType(
        importCreateType.filter((item) => item.id !== "update_only")
      );
      if (uploadTypeId[0] === "update_only") {
        setUploadTypeId([IMPORT_CREATE_TYPE[2].id]);
        setUploadTypeValue([IMPORT_CREATE_TYPE[2].label]);
      }
    } else {
      setImportCreateType([...IMPORT_CREATE_TYPE]);
    }
  };

  const handleUnmatchedClick = () => {
    setSelectMatchColumnsOnly(!selectMatchColumnsOnly);
    let tempColumns = [...allColumns];
    let all_new_columns = [];
    tempColumns.forEach((element) => {
      let obj = { ...element };
      if (obj?.property_value) {
        obj.selected = true;
        all_new_columns.push(obj);
      } else {
        obj.selected = false;
        all_new_columns.push(obj);
      }
    });
    setAllColumns(all_new_columns);

    settingImportTypeValues(all_new_columns);
  };

  const handleSubmit = async () => {
    // now manage all validations using checkSelectedFields function.
    // debugger;
    const selected_proprty_values = allColumns
      .filter((item) => item.property_value?.length && item?.selected)
      .map((item) => item.property_value);
    const field_selection = checkSelectedFields(selected_proprty_values);
    if (
      field_selection.is_people_field_available &&
      field_selection.is_company_field_available
    ) {
      // if both company and people data is selected, check for company_website and email field.
      if (
        !(
          selected_proprty_values.includes("company_website") &&
          selected_proprty_values.includes("email")
        )
      ) {
        // here if both are not selected, it will come here and return from here.
        setErrorMessage({
          id: selected_proprty_values.includes("company_website")
            ? "company_website"
            : "email",
          message: IMPORT_COMPANY_WEBSITE_EMAIL_MANDATORY,
        });
        return;
      }
    } else if (field_selection.is_company_field_available) {
      // if only company data is selected, then check for company_website.
      if (!selected_proprty_values.includes("company_website")) {
        // here if company_website is not selected and company data is selected.
        setErrorMessage({
          id: "company_website",
          message: IMPORT_COMPANY_WEBSITE_MANDATORY,
        });
        return;
      }
    } else if (field_selection.is_people_field_available) {
      // if only people data is selected, then check for email.
      if (!selected_proprty_values.includes("email")) {
        // here if company_website is not selected and company data is selected.
        setErrorMessage({
          id: "email",
          message: IMPORT_FILE_EMAIL,
        });
        return;
      }
    } else {
      setErrorMessage({ id: "email", message: ATLEAS_ONE_FIELD });
      return;
    }
    // make api call here.

    const file_path = localStorage.getItem("upload_file");
    const fill_empty_value = JSON.parse(fillEmptyId[0]);
    const upload_type = uploadTypeId[0];

    const people_fields = [];
    const company_fields = [];

    for (let i in allColumns) {
      if (allColumns?.[i]?.property_type === STRINGS.PEOPLE_SMALL) {
        allColumns?.[i]?.property_value &&
          allColumns?.[i]?.selected &&
          people_fields.push({
            field_name: allColumns?.[i]?.property_value,
            column_index: Number(i),
          });
      } else {
        allColumns?.[i]?.property_value &&
          allColumns?.[i]?.selected &&
          company_fields.push({
            field_name: allColumns?.[i]?.property_value,
            column_index: Number(i),
          });
      }
    }
    try {
      setColumnsUploading(true);
      const payload = {
        file_path,
        file_name: file?.name,
        fill_empty_value,
        upload_type,
        people_fields,
        company_fields,
        user_email: userDetailsSelector?.email,
        add_to_crm: addToCrm,
      };
      localStorage.removeItem("upload_file");
      const response = await postRequest(
        navigate,
        importEndpoints.updateFieldMapping,
        payload
      );
      getImportedFilesList();
      handleClose();
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    } finally {
      setColumnsUploading(false);
    }
  };

  const importModal = {
    heading: {
      id: STRINGS.IMPORT_HEADER_ID,
      label: STRINGS.MAP_THE_COLUMN,
    },
  };

  const importModalRightFooter = (
    <div className={classes.importModalRightFooter}>
      <Button
        variant={"ghost"}
        size={"sm36"}
        label={STRINGS.CANCEL}
        onClick={handleClose}
      />
      <Button
        variant={"primary"}
        size={"sm36"}
        label={STRINGS.SUBMIT}
        onClick={handleSubmit}
        isLoading={columnsUploading}
      />
    </div>
  );

  const importModalLeftFooter = <div></div>;

  const csvMatchTableHeadersLabel = [
    {
      id: STRINGS.MATCHED,
      label: STRINGS.MATCHED,
    },
    {
      id: STRINGS.COLUMN_HEADER,
      label: STRINGS.COLUMN_HEADER,
    },
    {
      id: STRINGS.PROPERTY_TYPE,
      label: STRINGS.PROPERTY_TYPE,
    },
    {
      id: STRINGS.REACHIQ_PROPERTY,
      label: STRINGS.REACHIQ_PROPERTY,
    },
  ];

  return (
    <Box>
      {/* Upload Progress bar modal */}
      <Modal open={uploadPercentage !== 100 && open}>
        <ProgressEmitter
          name={file?.name}
          uploadPercentage={uploadPercentage}
          refreshRequired={true}
        />
      </Modal>
      {/* Upload Progress bar modal */}

      {/* CSV Mapping Modal */}
      <CustomModal
        open={uploadPercentage === 100 && open}
        close={handleClose}
        header={importModal}
        width={"70.521vw"}
        footer={{ left: importModalLeftFooter, right: importModalRightFooter }}
      >
        <div className={classes.importModalColumnsContainer}>
          <div className={classes.csvMatchingTableHeaderContainer}>
            {csvMatchTableHeadersLabel?.map((header) => {
              return (
                <div
                  key={header?.id}
                  className={classes.csvMatchingTableHeader}
                >
                  <TypographyInline
                    size={"sm"}
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={header?.label}
                  />
                </div>
              );
            })}
          </div>
          {allColumns?.map((eachColumn, i) => {
            return eachColumn?.column?.length ? (
              <Box key={eachColumn?.column}>
                <Box className={classes.eachCloumn}>
                  <div className={classes.checkboxContainer}>
                    <Checkbox
                      checked={
                        // selectMatchColumnsOnly
                        //   ? eachColumn.property_value
                        //   :
                        eachColumn?.selected
                      }
                      onChange={() => handleCheckBoxClick(eachColumn)}
                    />
                  </div>
                  <div className={classes.containEachColumnName}>
                    <TypographyInline
                      size={"sm"}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.REGULAR}
                      label={eachColumn?.column}
                      isEllipses
                    />
                  </div>
                  {/* Property dropdown */}
                  <Box className={classes.dropdownContainer}>
                    <AutoCompleteCustom
                      items={IMPORT_COLUMN_PROPERTY_TYPES}
                      size={"sm36"}
                      idState={[eachColumn?.property_type]}
                      valueState={[eachColumn?.property_type_label]}
                      onChange={(data) =>
                        handlePropertyTypeChange(data, i, eachColumn)
                      }
                    />
                  </Box>
                  {/* Columns Dropdown */}
                  <Box className={classes.dropdownContainer}>
                    <AutoCompleteCustom
                      items={
                        eachColumn?.property_type === STRINGS.PEOPLE_SMALL
                          ? peopleCustomFields?.filter(
                              (item) => !item?.isCustomField
                            )
                          : companyCustomFields?.filter(
                              (item) => !item?.isCustomField
                            )
                      }
                      subItems={
                        eachColumn?.property_type === STRINGS.PEOPLE_SMALL
                          ? peopleCustomFields?.filter(
                              (item) => item?.isCustomField
                            )
                          : companyCustomFields?.filter(
                              (item) => item?.isCustomField
                            )
                      }
                      subItemsLabel={STRINGS.CUSTOM_FIELDS}
                      size={"sm36"}
                      idState={[eachColumn?.property_type]}
                      valueState={[eachColumn?.property_label]}
                      onChange={(data) =>
                        handlePropertyValueChange(
                          data,
                          i,
                          eachColumn?.property_value,
                          eachColumn?.property_type
                        )
                      }
                    />
                  </Box>
                </Box>
              </Box>
            ) : null;
          })}

          <Box>
            {errorMessage?.id ? (
              <ErrorMessage message={errorMessage.message} />
            ) : null}
          </Box>
          <Box sx={styles.flex}>
            <Checkbox
              checked={selectMatchColumnsOnly}
              onClick={handleUnmatchedClick}
              label={STRINGS.UNMATCHED_COLUMN_WARNING}
            />
            <Checkbox
              checked={addToCrm}
              onClick={() => setAddToCrm(!addToCrm)}
              label={STRINGS.ADD_TO_CRM}
            />
          </Box>

          <Box sx={styles.containDropdowns}>
            <Box sx={{ paddingRight: "1rem" }}>
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.SELECT_IMPORT_TYPE}
              />
              <Box sx={styles.dropdown}>
                <AutoCompleteCustom
                  size={"sm36"}
                  items={importCreateType}
                  idState={uploadTypeId}
                  setIdState={setUploadTypeId}
                  valueState={uploadTypeValue}
                  setValueState={setUploadTypeValue}
                  paperHeight={"fit-content"}
                  dropdownId="import_dropdown"
                />
              </Box>
            </Box>
            <Box>
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.EMPTY_VALUES}
              />
              <Box sx={styles.dropdown}>
                <AutoCompleteCustom
                  size={"sm36"}
                  items={IMPORT_EMPTY_FIELD_OPTIONS}
                  idState={fillEmptyId}
                  setIdState={setFillEmptyId}
                  valueState={fillEmptyValue}
                  setValueState={setFillEmptyValue}
                  paperHeight={"fit-content"}
                  dropdownId="import_dropdown_empty"
                />
              </Box>
            </Box>
          </Box>
        </div>
      </CustomModal>
    </Box>
  );
};

export default MappingFieldModal;
