import React, { useEffect, useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  HtmlEditor,
} from "../../../../components";
import { Accordion } from "@mui/material";
import classes from "./DealsComp.module.css";
import IconComponent from "../../../../components/Icon/IconComponent";
import { GREY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { STRINGS } from "../../strings";
import {
  dateTimeStringEmailLog,
  decompressString,
} from "../../../../utils/helpers";
import IconColoredComponent from "../../../../components/Icon/IconColoredComponent";
import CustomDatePickerWoBorder from "../../../../components/DatePicker/CustomDatePickerWoBorder";
import TimeSelector from "../../../../components/TimeSelector/TimeSelector";
import { Button } from "../../../../components/Buttons";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../../Apis";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import { useNavigate } from "react-router";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import AddComment from "../../Components/AddComment";
import DeleteCommentModal from "../../Components/DeleteCommentModal";
import CustomMenu from "../../../../components/Menu/CustomMenu";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import Image from "../../../../components/Image/Image";
import { PIN_ICON } from "../../../../utils/constants/assets";
import CustomModal from "../../../../components/Modals/CustomModal";
import InputFieldWoBorder from "../../../../components/InputFields/InputFieldWoBorder";

const EmailLogList = ({
  logData,
  dealDetails,
  id,
  getEmailLogList,
  expanded,
  showOperation = true,
}) => {
  const navigate = useNavigate();

  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  const [logOpen, setLogOpen] = useState(false);
  const [logEmailDate, setLogEmailDate] = useState(new Date());
  const [logEmailTime, setLogEmailTime] = useState("00:00");
  const [onChangeDetected, setOnchangeDetected] = useState(false);
  const [notesClicked, setNotesClicked] = useState(false);
  const [description, setDescription] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [commentBody, setCommentBody] = useState("");
  const [createCommentLoading, setCreateCommentLoading] = useState(false);
  const [emailLogComments, setEmailLogComments] = useState([]);
  const [emailLogCommentsPage, setLogEmailCommentsPage] = useState(0);
  const [isDeleteCommentLoading, setIsDeleteCommentLoading] = useState(false);
  const [deleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [clickedCommentData, setClickedCommentData] = useState({});
  const [logOperationTarget, setLogOperationTarget] = useState(null);
  const [logOperationVisible, setLogOperationVisible] = useState(false);
  const [isLogEditable, setIsLogEditable] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

  // api call for updating email log.
  const updateLogHandler = async (e) => {
    try {
      e?.preventDefault();
      let processingDateTime = `${logEmailDate?.toDateString()} ${logEmailTime}`;
      let processingDateTimeMs = new Date(processingDateTime).getTime();

      let payload = {
        body: encodeURIComponent(document.getElementById(id)?.innerHTML),
        id: logData?._id,
        processing_date_time: processingDateTimeMs,
      };
      setSaveLoading(true);
      const response = await putRequest(
        navigate,
        crmEndpoints.updateEmailLog,
        payload
      );
      setSaveLoading(false);
      setOnchangeDetected(false);
      setIsLogEditable(false);
      getEmailLogList();
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      setSaveLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("error ->>>>>>> ", error);
    }
  };

  // resetting all the values on the click of cancel
  const resetLogHandler = (e) => {
    e?.preventDefault();
    let timeStamp =
      new Date(logData.processing_date_time).toTimeString().split(":")[0] +
      ":" +
      new Date(logData.processing_date_time).toTimeString().split(":")[1];

    setDescription(logData?.body);
    setLogEmailDate(new Date(logData?.processing_date_time));
    setLogEmailTime(timeStamp);
    if (document.getElementById(id)) {
      document.getElementById(id).innerHTML = logData?.body;
    }
    setOnchangeDetected(false);
    setIsLogEditable(false);
  };

  // pin email log api handler
  const logPinHandler = async (data) => {
    try {
      setLogEmailCommentsPage(0);
      let payload = {
        pin: data?.pin ? 0 : 1,
        _id: data?._id,
      };
      const response = await putRequest(
        navigate,
        crmEndpoints.pinEmail,
        payload
      );
      getEmailLogList();
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // delete email log api handler
  const logDeleteHandler = async (data) => {
    try {
      setLogEmailCommentsPage(0);
      setDeleteButtonLoading(true);
      let url = `${crmEndpoints.deleteEmailLog}/${data?._id}`;
      const response = await deleteRequest(url, {}, navigate);
      getEmailLogList();
      setDeleteButtonLoading(false);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      setDeleteButtonLoading(false);
      setDeleteModal(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // api call for getting email log comments
  const getEmailLogComments = async (isOnScroll, initial) => {
    try {
      let url =
        crmEndpoints.getEmailLogComments +
        `/${logData?._id}?size=10&page=${initial ? 0 : emailLogCommentsPage}`;
      const response = await getRequest(navigate, url);
      if (isOnScroll) {
        setEmailLogComments([
          ...emailLogComments,
          ...response?.data?.data?.records,
        ]);
      } else {
        setEmailLogComments([...response?.data?.data?.records]);
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // creating email log comment api handler
  const createCommentHandler = async () => {
    try {
      setLogEmailCommentsPage(0);
      let payload = {
        comment: commentBody,
        email_id: logData?._id,
      };
      setCreateCommentLoading(true);
      const response = await postRequest(
        navigate,
        crmEndpoints.createEmailLogComment,
        payload
      );
      getEmailLogComments(false, true);

      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setCreateCommentLoading(false);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  // edit email log comment api handler
  const updateEmailLogCommentHandler = async (comment, editValue) => {
    try {
      setLogEmailCommentsPage(0);
      let payload = {
        comment: editValue[comment?.id],
        id: comment?.id,
      };
      const response = await putRequest(
        navigate,
        crmEndpoints.updateEmailLogComment,
        payload
      );
      getEmailLogComments(false, true);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // delete email log comment api handler
  const deleteEmailLogCommentHandler = async (data) => {
    try {
      setLogEmailCommentsPage(0);
      setIsDeleteCommentLoading(true);
      let url = `${crmEndpoints?.deleteEmailLogComment}/${data?.id}`;
      const response = await deleteRequest(url, {}, navigate);
      getEmailLogComments(false, true);
      setIsDeleteCommentLoading(false);
      setDeleteCommentModalOpen(false);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  // storing clicked data for delete and open delete modal.
  const deleteEmailLogCommentModalHandler = (data) => {
    setClickedCommentData(data);
    setDeleteCommentModalOpen(true);
  };

  // get logged email user name from usre list api data.
  const getUserName = (data) => {
    let name = "";
    tenantUsersListSelector?.forEach((element) => {
      if (element?._id === data?.user_id) {
        name = `${element?.first_name || "-"} ${element?.last_name || "-"} `;
      }
    });
    return name;
  };

  const emailLogOperation = [
    {
      id: logData?.pin ? STRINGS.UNPIN_SMALL : STRINGS.SMALL_PIN,
      label: logData?.pin ? STRINGS.UNPIN : STRINGS.PIN,
      iconLabel: ICON_LABELS.PUSH_PIN,
      function: (data) => logPinHandler(data),
    },
    {
      id: STRINGS.SMALL_DELETE,
      label: STRINGS.DELETE,
      iconLabel: ICON_LABELS.DELETE,
      function: () => setDeleteModal(true),
      variant: "error",
    },
  ];

  const deleteModalHeader = {
    heading: {
      id: STRINGS.DELETE_MODAL_COMMENT,
      label: STRINGS.DELETE_EMAIL_LOG_HEADER_TILE,
    },
  };

  const deleteModalRightFooter = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setDeleteModal(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={() => {
          logDeleteHandler(logData);
        }}
        isLoading={deleteButtonLoading}
      />
    </div>
  );

  const deleteModalLeftFooter = <div></div>;

  useEffect(() => {
    let timeStamp =
      new Date(logData?.processing_date_time).toTimeString().split(":")[0] +
      ":" +
      new Date(logData?.processing_date_time).toTimeString().split(":")[1];

    setDescription(logData?.body);
    setLogEmailDate(new Date(logData?.processing_date_time));
    setLogEmailTime(timeStamp);

    if (expanded) {
      setLogOpen(expanded);
    }
  }, [logData]);

  useEffect(() => {
    if (logOpen) {
      getEmailLogComments(false, true);
    }
  }, [logOpen]);

  useEffect(() => {
    if (emailLogCommentsPage) {
      getEmailLogComments(true, false);
    }
  }, [emailLogCommentsPage]);

  return (
    <Accordion
      className={classes.accrodionRoot}
      onChange={() => setLogOpen(!logOpen)}
      expanded={logOpen}
    >
      <AccordionSummary className={classes.accordionSummary}>
        {showOperation && logData?.pin ? (
          <div className={classes.pinContainer}>
            <Image className={classes.pinIcon} src={PIN_ICON} />
          </div>
        ) : null}

        <div className={classes.summaryContainer}>
          {/* accordion summary left part having title and icons */}
          <div className={classes.emailLogAccordionSummaryLeftPart}>
            {/* Accordion Icon and Email Icon */}
            <div className={classes.accordionSummaryIconContainer}>
              <IconComponent
                color={GREY.TERTIARY}
                fontSize={fontSize.DXS}
                iconLabel={
                  logOpen
                    ? ICON_LABELS.ACTION_ARROW
                    : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                }
              />

              <IconColoredComponent
                variant={"orange"}
                iconLabel={ICON_LABELS.EMAIL}
                size={"small"}
              />
            </div>

            {/* Logged Email Title */}
            <div className={classes.loggedEmailTitleContainer}>
              <TypographyInline
                color={GREY.PRIMARY}
                label={STRINGS.LOGGED_EMAIL}
                size={"sm"}
                fontWeight={fontWeight.SEMIBOLD}
                isNoWrap
              />
              <TypographyInline
                color={GREY.SECONDARY}
                label={`${STRINGS.BY} ${getUserName(logData)}`}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
                isNoWrap
              />
            </div>
          </div>

          {/* accordion summary right part having date time and action icon */}
          <div className={classes.emailLogAccordionSummaryRightPart}>
            <div className={classes.loggedEmailTimeLabel}>
              <IconComponent
                color={GREY.TERTIARY}
                fontSize={fontSize.MD}
                iconLabel={ICON_LABELS.EVENT}
              />

              <TypographyInline
                color={GREY.SECONDARY}
                label={dateTimeStringEmailLog(logData?.created_at)}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
                isEllipses
              />
            </div>
            {showOperation && (
              <>
                {" "}
                <div className={classes.verticalLine} />
                <div
                  className={classes.moreOptionsIconContianer}
                  onClick={(e) => {
                    e?.stopPropagation();
                  }}
                >
                  <IconComponent
                    color={GREY.TERTIARY}
                    fontSize={fontSize.MD}
                    iconLabel={ICON_LABELS.MORE_VERT}
                    onClick={(e) => {
                      setLogOperationTarget(e?.currentTarget);
                      setLogOperationVisible(true);
                    }}
                  />

                  <CustomMenu
                    items={emailLogOperation}
                    anchor={logOperationTarget}
                    open={logOperationVisible}
                    onClose={() => {
                      setLogOperationTarget(null);
                      setLogOperationVisible(false);
                    }}
                    paperMenuClass={classes.dropdownMenuListing}
                    rowData={logData}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {/* detail container */}
        <div className={classes.logEmailListSummaryUpperMainContainer}>
          {/* detail Upper container  */}
          <div className={classes.logEmailListSummaryUpperContainer}>
            <div className={classes.inputLabelField}>
              <TypographyInline
                color={GREY.SECONDARY}
                label={STRINGS.CONTACTED}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
              />
              <InputFieldWoBorder
                size={"sm36"}
                variant={"disabled"}
                value={`${logData?.to_emails?.toString() || ""}`}
              />
            </div>

            <div className={classes.verticalLine} />
            <div className={classes.inputLabelField}>
              <TypographyInline
                color={GREY.SECONDARY}
                label={STRINGS.DUE_DATE}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
              />
              <CustomDatePickerWoBorder
                size={"sm36"}
                value={logEmailDate}
                onChange={(dateValue) => {
                  setLogEmailDate(new Date(dateValue));
                  setOnchangeDetected(true);
                }}
              />
            </div>

            <div className={classes.verticalLine} />
            <div className={classes.inputLabelField}>
              <TypographyInline
                color={GREY.SECONDARY}
                label={STRINGS.TIME}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
              />
              <TimeSelector
                time={logEmailTime}
                setTime={(time) => {
                  setLogEmailTime(time);
                  setOnchangeDetected(true);
                }}
                removeBorder
              />
            </div>
          </div>
          {/* detail notes section */}
          <div
            className={`${classes.logEmailListSummaryLowerContainer} ${notesClicked ? classes.notesBackground : ""
              }`}
            onClick={() => {
              setNotesClicked(true);
            }}
            onBlur={() => {
              setNotesClicked(false);
            }}
          >
            <div>
              <TypographyInline
                color={GREY.PRIMARY}
                label={STRINGS.NOTES}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
              />
            </div>
            {isLogEditable ? (
              <div className={classes.htmlEditor}>
                <HtmlEditor
                  previosData={decodeURIComponent(
                    decompressString(logData?.body) || logData?.body
                  )}
                  isFocusNotRequired
                  isAITagNotVisible
                  id={id}
                  editorHeight={"10.4vh"}
                  onInput={(event) => {
                    setOnchangeDetected(true);
                    setDescription(event?.target?.innerHTML);
                  }}
                  isMeetingTagNotVisible
                  isAddTagNotVisible
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  setIsLogEditable(true);
                }}
                className={classes.parsedEmailLogData}
              >
                {logData?.is_compressed
                  ? parse(
                    decompressString(logData?.body || "") ||
                    decodeURIComponent(logData?.body) ||
                    ""
                  )
                  : parse(logData?.body || "")}
              </div>
            )}

            {/* Cancel Save Button for Update */}
            {onChangeDetected && (
              <div className={classes.logUpdateButtons}>
                <div>
                  <Button
                    label={STRINGS.CANCEL}
                    size={"sm36"}
                    variant={"ghost"}
                    onClick={resetLogHandler}
                  />
                </div>
                <div>
                  <Button
                    label={STRINGS.SAVE}
                    size={"sm36"}
                    variant={"primary"}
                    isLoading={saveLoading}
                    onClick={updateLogHandler}
                    disabled={showOperation ? false : true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* detail comment section */}

        {showOperation && (
          <div className={classes.emailLogListCommentContainer}>
            <AddComment
              createCommentHandler={createCommentHandler}
              value={commentBody}
              setValue={setCommentBody}
              isLoading={createCommentLoading}
              commentsList={emailLogComments}
              onScrollHandler={() =>
                setLogEmailCommentsPage(emailLogCommentsPage + 1)
              }
              updateCommentHandler={updateEmailLogCommentHandler}
              deleteModalHandler={deleteEmailLogCommentModalHandler}
            />

            <DeleteCommentModal
              open={deleteCommentModalOpen}
              close={() => {
                setDeleteCommentModalOpen(false);
              }}
              clickedData={clickedCommentData}
              deleteButtonLoading={isDeleteCommentLoading}
              deleteAPIHandler={deleteEmailLogCommentHandler}
            />
          </div>
        )}
      </AccordionDetails>
      <CustomModal
        variant={"error"}
        close={() => setDeleteModal(false)}
        open={deleteModal}
        header={deleteModalHeader}
        footer={{
          left: deleteModalLeftFooter,
          right: deleteModalRightFooter,
        }}
      >
        <div className={classes.modalBody}>
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.DELETE_COMMENT_DESC}
          />
        </div>
      </CustomModal>
    </Accordion>
  );
};

export default EmailLogList;
