import {
  CAMPAIGN_FOLDER_REQUEST,
  CAMPAIGN_FOLDER_SUCCESS,
  CAMPAIGN_FOLDER_FAIL,
} from "../constants/campaignFolderConstants";
import { postRequest } from "../../Apis";
import { LOGIN_PATH } from "../../utils/constants/routes";
import { campaignEndpoints } from "../../utils/constants/httpConstants";
export const getCampaignFolderList =
  (sentQuery, navigate) => async (dispatch) => {
    try {
      dispatch({ type: CAMPAIGN_FOLDER_REQUEST });
      const response = await postRequest(
        navigate,
        campaignEndpoints.folderList,
        sentQuery
      );
      dispatch({
        type: CAMPAIGN_FOLDER_SUCCESS,
        payload: { ...response?.data, query: sentQuery },
      });
    } catch (err) {
      if (err?.status === 401) {
        navigate(LOGIN_PATH);
      }
      dispatch({
        type: CAMPAIGN_FOLDER_FAIL,
        payload: err,
      });
    }
  };
