import React, { useEffect, useState } from "react";
import classes from "./CrmComp.module.css";
import CustomTable from "../../../../components/Table/CustomTable";
import FilterTab from "../../../../components/FilterTab/FilterTab";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ExportModal from "./ExportModal";
import { BRAND, GREY } from "../../../../utils/constants/colors";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import DeleteModal from "./DeleteModal";
import AssignModal from "./AssignModal";
import { tempHeadCells } from "../../../../utils/constants";

const ContactTable = ({
  heads,
  records,
  totalCount,
  setSearchField,
  loading = true,
}) => {
  const navigate = useNavigate();

  const salesContactListSelector = useSelector(
    (state) => state.getSalesContactList
  );
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const bulkRecordCount =
    salesContactListSelector?.data?.total_records?.value < 500
      ? salesContactListSelector?.data?.total_records?.value?.toLocaleString()
      : 500?.toLocaleString();

  const [showExportModal, setShowExportModal] = useState(false);
  const [deleteBulkModal, setDeleteBulkModal] = useState(false);
  const [assignBulkModal, setAssignBulkModal] = useState(false);
  const [bulkSelected, setBulkSelected] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [selectedBulkValue, setSelectedBulkValue] = useState("");

  const contactTableData = {
    heads: heads?.length
      ? heads
      : tempHeadCells.filter((item) => item.isChecked),
    body: records,
    actions: {
      user_info: {
        function: (row) => {
          navigate(`/user/contacts/${row?._id}?tabs=timeline`);
        },
      },
    },
    total_records: totalCount,
    isSticky: true,
    isLoading: salesContactListSelector?.loading || loading,
    header_strip_selected_value: selectedBulkValue,
  };

  const headerStrip = [
    <FilterTab
      leftIcon={ICON_LABELS.FACT_CHECK}
      label={STRINGS.ASSIGN}
      onClick={() => {
        setAssignBulkModal(true);
      }}
    />,
    <FilterTab
      leftIcon={ICON_LABELS.DELETE_OUTLINE}
      label={STRINGS.DELETE}
      onClick={() => {
        setDeleteBulkModal(true);
      }}
    />,
    <FilterTab
      leftIcon={ICON_LABELS.DOWNLOAD_FOR_ONLINE}
      label={STRINGS.EXPORT}
      onClick={() => {
        setShowExportModal(true);
      }}
    />,
  ];
  const headerStripWODelete = [
    <FilterTab
      leftIcon={ICON_LABELS.FACT_CHECK}
      label={STRINGS.ASSIGN}
      onClick={() => {
        setAssignBulkModal(true);
      }}
    />,
    // <FilterTab
    //   leftIcon={ICON_LABELS.DOWNLOAD_FOR_ONLINE}
    //   label={STRINGS.EXPORT}
    //   onClick={() => {
    //     setShowExportModal(true);
    //   }}
    // />,
  ];

  const handleBulkSelection = async () => {
    setBulkSelected(!bulkSelected);
    const selectedIds = records.map((item) => item?._id);

    setSelected(selectedIds);
    if (selectedBulkValue?.length) {
      setSelectedBulkValue("");
    } else {
      setSelectedBulkValue(bulkRecordCount);
    }
  };

  useEffect(() => {
    if (paginationDataSelector?.data?.checkedRow) {
      setSelected(paginationDataSelector?.data?.checkedRow);
    }
  }, [paginationDataSelector?.data?.checkedRow]);

  return (
    <div className={classes.HeadTable}>
      {/* List actions here */}
      {selected?.length >= 1 ? (
        <div className={classes.containAllContacts}>
          <TypographyInline
            label={`${STRINGS.ALL} ${selected?.length} ${STRINGS.CNT_ON_PAGE}`}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
          <div className={classes.cursorPointer} onClick={handleBulkSelection}>
            <TypographyInline
              label={`${STRINGS.SELECT_ALL} ${bulkRecordCount} ${STRINGS.SMALL_CONTACTS}`}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={BRAND.PRIMARY}
            />
          </div>
        </div>
      ) : null}

      <div
        id="modal_body"
        className={
          selected?.length
            ? classes.tableSelectedMainContainer
            : classes.tableMainContainer
        }
      >
        <CustomTable
          tableData={contactTableData}
          headerStrip={!bulkSelected ? headerStrip : headerStripWODelete}
        />
      </div>

      <ExportModal
        open={showExportModal}
        setOpen={setShowExportModal}
        bulkSelected={bulkSelected}
        setBulkSelected={setBulkSelected}
        columns={heads}
        record_ids={selected}
        setSelectedIds={setSelected}
      />
      <DeleteModal
        open={deleteBulkModal}
        setOpen={setDeleteBulkModal}
        record_ids={selected}
        setSelectedIds={setSelected}
        setSearchField={setSearchField}
      />

      <AssignModal
        open={assignBulkModal}
        setOpen={setAssignBulkModal}
        record_ids={selected}
        setSelectedIds={setSelected}
        setSearchField={setSearchField}
      />
    </div>
  );
};

export default ContactTable;
