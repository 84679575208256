export const STRINGS = {
  EMAIL_LINK: " Email link is on it's way",
  EMAIL_SENT: "An email has been sent to",
  RESEND: "Resend",
  GO_TO_HOME: "Go to home",
  PRIVACY: "Privacy",
  EMITTER: "Email id verified successfully, redirecting to login page.",
  UNSUBSCRIBE: "You’ve been unsubscribed.",
  MORE_EMAIL: "We won’t send you any more emails.",
  SORRY: "Sorry !",
  LOOKS_LIKE: "Looks like you are on the wrong page.To get early access on  ",
  TENANT_INACTIVE: "We have received your request. It is under process. We will contact you soon.",
  PLEASE_CLICK:
    "ReachIQ,please click go to our website and fill up the required",
  FIELDS: " fields.Our support team will contact you soon.",
  GET_EARLY_ACCESS: " Get Early Access",
  AND: "and",
  GO_HOME: "Go Home",
  BACK_LOGIN: "Go back to Login",
  REACH_OUT: " It is now under process. We will reach out to you soon.",
  EMAIL_VERIFIED: "Email id verified successfully, redirecting to login page.",
  SOMETHING_WENT_WRONG: "Something went wrong.",
  Exist_TENANT: "exist-tenant=true",
  INVITATION_TOKEN: "invitation_token",
  host: "host",
  REQ_RECIEVED: "We have received your request.",
  ERROR: "Error",
  HI_THERE: "Hi There !",
  CANCEL: "Cancel",
  YOUR_ACCOUNTS: "Your Accounts",
  CONTINUE_WITH_ACC: " Continue with this account",
  CHOOSE_ONE_TENANT: "    Choose one tenant for Login",
  TERMS: "Terms .",
};
