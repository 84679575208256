import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { Button } from "../../../../components/Buttons";
import classes from "./DealsComp.module.css";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import { dealsEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { useNavigate } from "react-router";
import { EMITTER_SUCCESS } from "../../../../utils/constants";
import { putRequest } from "../../../../Apis/apiWrapper";
import { nameValidation } from "../../../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { getDealsList } from "../../../../redux/actions/dealsActions";

const DealAssignModal = ({
  open,
  setOpen,
  record_ids,
  setSearchField,
  pipeline_id,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [valueState, setValueState] = useState([]);
  const [idVal, setIdVal] = useState([]);
  const [data, setData] = useState([]);
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);

  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );

  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  useEffect(() => {
    let user_list = tenantUsersListSelector?.map((item) => {
      return {
        label: `${item?.first_name} ${item?.last_name}`,
        id: item?._id,
      };
    });
    setData(user_list);
  }, [open]);

  const assignMethod = async () => {
    setLoading(true);
    const validatedAssigneeName = nameValidation(valueState);

    if (validatedAssigneeName?.isValid) {
      try {
        const payload = {
          query: {
            ids: record_ids,
            assignee_id: idVal[0],
          },
        };

        const response = await putRequest(
          navigate,
          dealsEndpoints?.assignBulkDeals,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        if (pipeline_id === "All") {
          dispatch(getDealsList(allDynamicFiltersSelector, navigate));
        } else {
          dispatch(
            getDealsList(allDynamicFiltersSelector, navigate, {
              pipeline_id: pipeline_id,
            })
          );
        }

        setSearchField("");
        setLoading(false);
        setOpen(false);
      } catch (error) {
        setLoading(false);
        setOpen(false);
        console.log("Error --->>>>>>> ", error);
      }
    } else {
      setLoading(false);
      setInputError({
        first_name: validatedAssigneeName?.message,
      });
    }
  };

  const CancelAssign = () => {
    setOpen(false);
    setInputError({});
    setIdVal([]);
    setValueState([]);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.ASSIGN,
      label: `Bulk assign ${record_ids?.length} contacts`,
    },
  };

  const modalBodyData = (
    <div className={classes.assignModalBody}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.ASSIGN_TO}
      />

      <AutoCompleteCustom
        variant={inputError?.first_name ? STRINGS.ERROR : STRINGS.DEFAULT}
        errormessage={inputError?.first_name}
        LeftIcon=""
        valueState={[valueState]}
        setValueState={setValueState}
        idState={idVal}
        setIdState={setIdVal}
        items={data}
        papermenuclass={classes.paperMenu}
        size={"md40"}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => CancelAssign()}
      />
      <Button
        label={STRINGS.ASSIGN}
        variant={"primary"}
        size={"sm36"}
        onClick={() => assignMethod()}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      variant={"primary"}
      close={() => CancelAssign()}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default DealAssignModal;
