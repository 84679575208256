import React from "react";
import { AddList } from "./AddList";

export const TopBar = ({
  items,
  onRemoveItem,
  onAddItem,
  originalItems,
  close,
  report,
  campaignList,
  sequenceList,
  widgetType,
  setWidgetType,
  dashboard,
  dashboardstep,
  closeTour,
  complteTour,
}) => {
  return (
    <AddList
      items={items}
      onRemoveItem={onRemoveItem}
      onAddItem={onAddItem}
      originalItems={originalItems}
      close={close}
      dashboard={dashboard}
      report={report}
      campaignList={campaignList}
      sequenceList={sequenceList}
      widgetType={widgetType}
      setWidgetType={setWidgetType}
      dashboardstep={dashboardstep}
      closeTour={closeTour}
      complteTour={complteTour}
    />
  );
};
