import React, { useState } from "react";
import { tempHeadCompanyCells } from "../../../../utils/constants";
import {
  crmAccountDataFetch,
  dynamicColumnUpdateHeaders,
  dynamicUpdatedColumn,
} from "../../../../redux/actions/tableActions";
import { useSelector, useDispatch } from "react-redux";
import {
  decompressString,
  removeObjFromArray,
} from "../../../../utils/helpers";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../../strings";
import CustomModal from "../../../../components/Modals/CustomModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconComponent from "../../../../components/Icon/IconComponent";
import { GREY } from "../../../../utils/constants/colors";
import { ICON_LABELS, fontSize } from "../../../../utils/constants/UI";
import { fontWeight } from "../../../../utils/constants/UI/uiConstants";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import SearchField from "../../../../components/InputFields/SearchField";
import Checkbox from "../../../../components/Buttons/Checkbox/Checkbox";
import classes from "../../Contacts/components/CrmComp.module.css";
import ResetDynamicColumns from "../../Components/ResetDynamicColumns";

export default function EditColumnModal() {
  const selector = useSelector((state) => state.dynamicUpdatedColumn);
  const dispatch = useDispatch();

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );

  let propertiesPayload = JSON.parse(
    decompressString(localStorage.getItem("properties") || "") || {}
  )?.properties_key_value;

  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [headers, setHeaders] = useState(
    selector.columns?.filter((item) => item.companyField) ||
    JSON.parse(
      JSON.stringify(
        tempHeadCompanyCells?.filter((item) => item?.companyField)
      )
    )
  );
  const [search, setSearch] = useState("");
  const [list, setList] = React.useState(selector.columns || []);

  const handleModalChange = () => {
    setOpenConfirmation(!openConfirmation);
  };

  const handleClose = () => setOpen(false);
  const handleChange = () => setOpen(!open);

  // helper for adding header into drag section.
  const handleChangeInAllHeaders = (column) => {
    const index = headers.findIndex((e) => e.id === column.id);
    let settedHeaders = headers;
    let not_api_call = true;
    settedHeaders[index] = { ...column, isChecked: !column.isChecked };
    setList(settedHeaders);
    dispatch(dynamicColumnUpdateHeaders(settedHeaders, not_api_call));
    dispatch(dynamicUpdatedColumn(settedHeaders, not_api_call));
  };

  // helper for removing header from table.
  const handleRemoveColumn = async (column) => {
    try {
      let newArray = removeObjFromArray(headers, column);
      setHeaders([...newArray, { ...column, isChecked: false }]);
      setList([...newArray, { ...column, isChecked: false }]);
      dispatch(
        dynamicColumnUpdateHeaders([
          ...newArray,
          { ...column, isChecked: false },
        ])
      );
      dispatch(
        dynamicUpdatedColumn([...newArray, { ...column, isChecked: false }])
      );
    } catch (error) { }
  };

  // helper for apply format of header.
  const handleApplyColumnChange = () => {
    dispatch(dynamicUpdatedColumn(list));
    dispatch(dynamicColumnUpdateHeaders(list));
    setOpen(!open);
  };

  const getColumnWidthUiID = (data) => {
    let objectType = propertiesPayload?.[data?.id]?.object_type?.toString();

    switch (objectType) {
      case STRINGS.MULTI_LINE_TEXT_INPUT || STRINGS.RICH_TEXT_EDITOR:
        return {
          ui_id: "text",
          width: "13.542vw",
        };

      case STRINGS.NUMBER:
        return {
          ui_id: "stats_data",
          width: "13.542vw",
        };

      default:
        return {
          ui_id: "text",
          width: "13.542vw",
        };
    }
  };

  const staticColumn = [
    STRINGS.COMPANY_NAME,
    STRINGS.INDUSTRY,
    STRINGS.COMPANY_WEBSITE,
    STRINGS.EMPLOYEES,
    STRINGS.ANNUAL_REVENUE,
    STRINGS.COMPANY_CITY_CAPS,
    STRINGS.COMPANY_COUNTRY_CAPS,
    STRINGS.COMPANY_STATE_CAPS,
  ];
  React.useEffect(() => {
    if (selector?.columns) {
      let final_columns = [...selector?.columns]?.filter(
        (item) => !item?.is_deal
      );
      let arr = [...final_columns?.map((item) => item?.id)];

      getPropertiesListSelector?.hits?.forEach((column) => {
        if (
          column?.object_type?.toString() === STRINGS.COMPANY
        ) {
          let new_obj = {
            id: column?.id,
            label: column?.property_name,
            isChecked: false,
            sort: true,
            copy: true,
            ...getColumnWidthUiID(column),
          };
          if (!arr?.includes(column?.id)) {
            final_columns.push(new_obj);
          }
        }
      });
      setList([...final_columns]);
      setHeaders([...final_columns]);
    } else {
      setList(
        JSON.parse(
          JSON.stringify(
            tempHeadCompanyCells?.filter((item) => item.companyField)
          )
        )
      );
      setHeaders(JSON.parse(JSON.stringify(tempHeadCompanyCells)));
    }
  }, [selector, getPropertiesListSelector]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setList(items);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.CHOOSE,
    },
  };

  const modalBodyData = (
    <div className={classes.modalBody}>
      <div className={classes.containHead}>
        <div>
          {/* left part of edit colum modal */}
          <div className={classes.containLeft}>
            {/* search field container */}
            <div className={classes.searchContainer}>
              <TypographyInline
                label={STRINGS.COMPANY_INFO}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
                color={GREY.SECONDARY}
              />
              <SearchField
                size={"sm36"}
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
                placeholder={STRINGS.SEARCH_PROPERTY}
                autocomplete="off"
              />
            </div>
            {/* Columns listing with checkbox. */}
            <div
              id={STRINGS.MODAL_BODY}
              className={classes.containCotactColumns}
            >
              <div className={classes.allColumnsContainer}>
                <div className={classes.columnLabelContainer}>
                  <TypographyInline
                    size={"sm"}
                    fontWeight={fontWeight.MEDIUM}
                    color={GREY.PRIMARY}
                    label={STRINGS.COMPANIES}
                  />
                  <div className={classes.containColumnMainContainer}>
                    {list?.map((column, index) => {
                      return column?.id !== STRINGS.COMPANY_NAME &&
                        column.companyField &&
                        (column?.label
                          ?.toLowerCase()
                          ?.includes(search?.toLocaleLowerCase()) ||
                          search?.length === 0) ? (
                        <div
                          className={classes.containColumns}
                          key={column.label + index}
                        >
                          <div className={classes.iconLabelContainer}>
                            <Checkbox
                              onChange={() => handleChangeInAllHeaders(column)}
                              checked={column.isChecked}
                              disabled={
                                column.label === STRINGS.NAME ||
                                column.label === STRINGS.COMPANY_NAME ||
                                column.label === STRINGS.INDUSTRY ||
                                column.label === STRINGS.COMPANY_WEBSITE ||
                                column.label === STRINGS.EMPLOYEES ||
                                column.label === STRINGS.ANNUAL_REVENUE ||
                                column.label === STRINGS.COMPANY_CITY_CAPS ||
                                column.label === STRINGS.COMPANY_COUNTRY_CAPS ||
                                column.label === STRINGS.COMPANY_STATE_CAPS
                              }
                              label={column.label}
                            />
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* DRag and Drop container */}
        <div className={classes.containRight}>
          <div id={STRINGS.MODAL_BODY} className={classes.containScroll}>
            <div className={classes.draggableDivLabel}>
              <TypographyInline
                label={STRINGS.COMPANY_NAME}
                size="sm"
                fontWeight={fontWeight.REGULAR}
                color={GREY.SECONDARY}
              />
            </div>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId={STRINGS.COLUMNS_LIST}>
                {(provided, snapshot) => (
                  <div
                    className={classes.draggableMainDiv}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {list.map((column, index) => {
                      return (
                        column?.isChecked &&
                        column.companyField &&
                        column?.label !== STRINGS.NAME && (
                          <Draggable
                            key={column?.id}
                            draggableId={column?.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className={classes.draggableDiv}>
                                  <div className={classes.dragLabelContainer}>
                                    <div className={classes.centered}>
                                      <IconComponent
                                        color={GREY.SECONDARY}
                                        fontSize={fontSize.MD}
                                        iconLabel={ICON_LABELS.DRAG_INDICATOR}
                                      />
                                    </div>
                                    <TypographyInline
                                      label={column?.label}
                                      size="sm"
                                      fontWeight={fontWeight.REGULAR}
                                      color={GREY.SECONDARY}
                                    />
                                  </div>
                                  {!staticColumn?.includes(column.label) ? (
                                    <div
                                      className={classes.crossIcon}
                                      onClick={() => handleRemoveColumn(column)}
                                    >
                                      <IconComponent
                                        color={GREY.TERTIARY}
                                        fontSize={fontSize.DXS}
                                        iconLabel={ICON_LABELS.CLOSE}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        {/* DRag and Drop container */}
      </div>
    </div>
  );

  const modalFooterRightData = (
    <div className={classes.editColumnModalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={() => setOpen(!open)}
      />

      <Button
        label={STRINGS.APPLY}
        size={"sm36"}
        variant={"primary"}
        onClick={handleApplyColumnChange}
      />
    </div>
  );

  const modalFooterLeftData = (
    <div>
      <Button
        label={STRINGS.RESET_ALL_PROPERTIES}
        LeftIcon={ICON_LABELS.RESTART_ALT}
        size={"sm36"}
        variant={"text"}
        onClick={handleModalChange}
      />
    </div>
  );

  return (
    <div>
      <ResetDynamicColumns
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        setHeaders={setHeaders}
        setList={setList}
        action={crmAccountDataFetch}
      />

      <Button
        label={STRINGS.EDIT_COLUMNS}
        LeftIcon={ICON_LABELS.VIEW_COLUMN}
        size={"sm36"}
        variant={"ghost"}
        onClick={() => {
          handleChange();
        }}
      />

      <CustomModal
        width={"41.667vw"}
        close={handleClose}
        open={open}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
        remove_padding
      />
    </div>
  );
}
