import React from "react";

import classes from "./LeadsComponent.module.css"
import Image from "../../../components/Image/Image";
import { NO_VIEW_CREATED } from "../../../utils/constants/assets";
import { GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import Typography from "../../../components/Typography/Typography";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";

const EmptyViewCard = ({ label, ...props }) => {
  return (
    <div className={classes.emptyViewCard}>
      <Image src={NO_VIEW_CREATED} />
      <div className={classes.emptyViewCardBody}>
        <Typography
          color={GREY.PRIMARY}
          fontWeight={fontWeight.SEMIBOLD}
          label={label}
          size="md"
        />
        <Typography
          color={GREY.SECONDARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.EMPTY_VIEW_DESC}
          size="sm"
        />
        <Button
          LeftIcon={ICON_LABELS.ADD_CIRCLE_OUTLINE}
          label={STRINGS.CREATE_VIEW}
          size={"sm36"}
          variant="text"
          {...props}
        />
      </div>
    </div>
  );
};

export default EmptyViewCard;
