import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";

import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  initialTimings,
  TIMEZONE_LIST,
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../Apis";
import { campaignEndpoints } from "../../utils/constants/httpConstants";
import { selectedCamapaignDetails } from "../../redux/actions/campaignAction";
import {
  Logger,
  createTimingsForPayload,
  createTimingsFromPayload,
} from "../../utils/helpers";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { nameValidation } from "../../utils/validations";
import { dateValidation } from "../../utils/validations/inputValidations";
import ErrorMessage from "../../components/Messages/ErrorMessage";
import { Button } from "../../components/Buttons";
import { STRINGS } from "./strings";
import CustomHeader from "../../components/Header/CustomHeader";
import { EMAIL_MARKETING_CAMPAIGN_PATH } from "../../utils/constants/routes";
import TypographyInline from "../../components/Typography/TypographyInline";
import { BRAND, GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import InputField from "../../components/InputFields/InputField";
import CustomDatePicker from "../../components/DatePicker/CustomDatePicker";
import { AutoCompleteCustom } from "../../components/Autocomplete/AutoCompleteCustom";
import classes from "./marketing.module.css";
import TimeScheduler from "../../components/TimeScheduler/TimeScheduler";

const formatSet = (val) => {
  const timestamp = parseInt(val);

  // Create a new Date object using the timestamp
  const date = new Date(timestamp);

  // Get the individual components of the date (year, month, day)
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Note: Month is zero-based, so we add 1
  const day = date.getDate();

  // Create a string in the desired format: "DD-MM-YYYY"
  return `${day}-${month}-${year}`;
};

const millisecondsToDateString = (date) => {
  const schedule_date = new Date(Number(date));
  const result = `${schedule_date.getFullYear()}-${schedule_date.getMonth() <= 9 ? "0" : ""
    }${schedule_date.getMonth() + 1}-${schedule_date.getDate() <= 9 ? "0" : ""
    }${schedule_date.getDate()}`;
  console.log("RESSS", result);
  return result;
};

const CampaignSettingsScreen = () => {
  const CURRENT_TAB_ID = STRINGS.CAMPAIGN_SETTINGS_ID;
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ids = params?.campaignId?.split("&");
  const campaignId = ids[0];
  const sequenceId = ids[1];
  const userId = ids[3];
  const selectedCampaignDetailsSelector = useSelector(
    (state) => state?.selectedCampaignDetails
  );
  const [stats, setStats] = useState(
    selectedCampaignDetailsSelector?.payload || null
  );
  const [getTimings, setGetTimings] = useState(
    createTimingsFromPayload(stats?.timings) || [...initialTimings]
  );
  const [ownerData, setOwnerData] = useState("");
  const [name, setName] = useState(stats?.name || "");
  const [isTrue, setIsTrue] = useState(false);
  const [preScheduleDate, setPrescheduleDate] = useState();
  const [campaignStatus, setCampaignStatus] = useState("");
  const [timezone, setTimezone] = useState(stats?.timezone || "");
  const [scheduledDate, setScheduleDate] = useState(
    stats?.scheduled_date || ""
  );
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [validations, setValidations] = useState({ name: "", date: "" });
  const [headerItems, setHeaderItems] = useState([
    {
      id: STRINGS.TYPE_CAMP,
      label: STRINGS.CAMPAIGN_TEXT,
      path: EMAIL_MARKETING_CAMPAIGN_PATH,
    },
  ]);
  const [error, setError] = useState(false);
  const EMAIL_MARKETING_TABS = [
    {
      label: STRINGS.CAMPAIGN_CONTACTS_LABEL,
      id: STRINGS.CAMPAIGN_CONTACTS_ID,
    },
    {
      label: STRINGS.CAMPAIGN_OVERVIEW_LABEL,
      id: STRINGS.CAMPAIGN_OVERVIEW_ID,
    },

    {
      label: STRINGS.CAMPAIGN_ACTIVITY_LABEL,
      id: STRINGS.CAMPAIGN_ACTIVITY_ID,
    },
    {
      label: STRINGS.CAMPAIGN_SETTINGS_LABEL,
      id: STRINGS.CAMPAIGN_SETTINGS_ID,
    },
  ];
  useEffect(() => {
    (async () => {
      try {
        const response = await getRequest(
          navigate,
          campaignEndpoints.eachCampaignDetail + "/" + campaignId
        );
        setStats(response?.data?.data || {});
        setName(response?.data?.data?.name);
        setTimezone(response?.data?.data?.timezone);
        setEmail(response?.data?.data?.from_email);
        setScheduleDate(
          millisecondsToDateString(response?.data?.data?.scheduled_date)
        );
        setPrescheduleDate(formatSet(response?.data?.data?.scheduled_date));
        setEmail(response?.data?.data?.from_email);
        setGetTimings(createTimingsFromPayload(response?.data?.data?.timings));
        setCampaignStatus(response?.data?.data?.state);
        dispatch(selectedCamapaignDetails(response?.data?.data));
      } catch (error) { }
      const res = await postRequest(
        navigate,
        campaignEndpoints.campOwnerStats,
        { user_ids: [userId] }
      );
      let data = res?.data?.data?.userdata;
      data = data[userId];
      setOwnerData(data);
    })();
  }, []);

  useEffect(() => {
    // This is for getting details of campaign.
    (async () => {
      try {
        const campaign_details = await getRequest(
          navigate,
          campaignEndpoints.eachCampaignDetail + "/" + campaignId
        );
        setHeaderItems([
          {
            id: STRINGS.TYPE_CAMP,
            label: STRINGS.CAMPAIGN_TEXT,
            path: EMAIL_MARKETING_CAMPAIGN_PATH,
          },
          {
            id: campaign_details?.data?.data?._id,
            label: campaign_details?.data?.data?.name,
          },
        ]);
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    })();
  }, [navigate, campaignId, sequenceId]);
  // this useEffect is for gettings campaign details, stats, and overview steps.

  const handleGetData = (newTimings) => {
    setGetTimings(newTimings);
    const messageLen = newTimings?.filter((item) => item?.message?.length > 0);
    if (messageLen?.length > 0) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const handleUpdateCampaign = async () => {
    try {
      const validatedName = nameValidation(name);
      const validatedDate = dateValidation(scheduledDate?.toString());

      if (validatedName.isValid && validatedDate.isValid) {
        setValidations({ name: "", date: "" });
        try {
          const offset = TIMEZONE_LIST.filter(
            (item) => item.id === timezone
          )?.[0]?.offset;
          let dateArray;
          let newScheduleDate;
          if (isTrue) {
            dateArray = millisecondsToDateString(scheduledDate)?.split("-");
            newScheduleDate = `${parseInt(dateArray[2])}-${parseInt(
              dateArray[1]
            )}-${parseInt(dateArray[0])}`;
          } else {
            newScheduleDate = preScheduleDate;
          }

          setLoading(true);
          const payload = {
            campaign_id: campaignId,
            name: name,
            scheduled_date: newScheduleDate,
            offset,
            timezone,
            timings: createTimingsForPayload(getTimings),
            from_email: email,
          };
          const response = await postRequest(
            navigate,
            campaignEndpoints.updateCampaign,
            payload
          );
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        } catch (error) {
          Logger(error);
          EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
        } finally {
          setLoading(false);
        }
      } else {
        setValidations({
          name: validatedName.message,
          date: validatedDate.message,
        });
      }
    } catch (error) {
      Logger(error);
    }
  };

  const handleNavigate = (item) => {
    try {
      navigate(
        `${EMAIL_MARKETING_CAMPAIGN_PATH}/${params?.campaignId}/${item?.id}`
      );
    } catch (error) {
      Logger(error);
    }
  };

  return (
    <Box className={classes.container1}>
      <CustomHeader items={headerItems} />

      {/* Tabs for selection */}
      <Box className={classes.tabContainer}>
        {EMAIL_MARKETING_TABS?.map((item) => {
          return (
            <Box
              keye={item.id}
              className={
                CURRENT_TAB_ID === item.id ? classes.selectedTab : classes.tab
              }
              onClick={() => handleNavigate(item)}
            >
              <TypographyInline
                size={"sm"}
                color={
                  CURRENT_TAB_ID === item.id ? BRAND.PRIMARY : GREY.SECONDARY
                }
                fontWeight={
                  CURRENT_TAB_ID === item.id
                    ? fontWeight.MEDIUM
                    : fontWeight.REGULAR
                }
                label={item.label}
              />
            </Box>
          );
        })}
      </Box>
      {/* Tabs for selection */}
      <Box className={classes.settingsBodyContainer}>
        <Box className={classes.infoContainer}>
          <Box className={classes.borderBox}>
            <TypographyInline
              label={STRINGS.CAMPAIGN_INFO}
              size={"md"}
              fontWeight={fontWeight.MEDIUM}
              color={GREY.PRIMARY}
            />
          </Box>
          <Box className={classes.eachInputContainer1}>
            <Box className={classes.containInput}>
              <TypographyInline
                label={STRINGS.CAMPAIGN_NAME}
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
                color={GREY.QUATINARY}
              />
              <InputField
                variant={validations.name ? STRINGS.ERROR : STRINGS.DEFAULT}
                size={"sm36"}
                value={name}
                errormessage={validations.name}
                placeholder={STRINGS.UPDATE_CAMPAIGN_NAME}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Box className={classes.containInput}>
              <TypographyInline
                label={STRINGS.OWNER_NAME}
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
                color={GREY.QUATINARY}
              />
              <InputField
                variant={"disabled"}
                size={"sm36"}
                value={ownerData || ""}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.scheduleContainer}>
          <Box className={classes.borderBox}>
            <TypographyInline
              label={STRINGS.EDIT_SCHEDULE}
              size={"md"}
              fontWeight={fontWeight.MEDIUM}
              color={GREY.PRIMARY}
            />
          </Box>
          <Box className={classes.containInputs}>
            <Box className={classes.selectDateTimeZoneContainer}>
              <Box className={classes.containInput}>
                <TypographyInline
                  label={STRINGS.SELECT_DATE}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.QUATINARY}
                />

                <CustomDatePicker
                  minDate={new Date()}
                  value={scheduledDate}
                  onChange={(e) => {
                    setIsTrue(true);
                    setScheduleDate(e?.$d);
                  }}
                  size={"sm36"}
                />
                {validations.date && (
                  <ErrorMessage message={validations.date} />
                )}
              </Box>
              <Box className={classes.containInput}>
                <TypographyInline
                  label={STRINGS.TIMEZONE}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.QUATINARY}
                />

                <AutoCompleteCustom
                  items={TIMEZONE_LIST}
                  valueState={[timezone]}
                  idState={[timezone]}
                  setIdState={setTimezone}
                  papermenuclass={classes.paperMenu}
                  size={"sm36"}
                />
              </Box>
            </Box>
            <TimeScheduler
              getData={(e) => handleGetData(e)}
              payload={getTimings}
            />
          </Box>
          <Box className={classes.settingUpdateBtn}>
            <Button
              label={STRINGS.COMMON_UPDATE}
              size={"sm36"}
              variant={"primary"}
              disabled={error || campaignStatus === "completed"}
              onClick={handleUpdateCampaign}
              isLoading={loading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignSettingsScreen;
