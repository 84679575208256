import React from "react";
import { STRINGS } from "../../strings";
import CustomModal from "../../../../components/Modals/CustomModal";
import { Button } from "../../../../components/Buttons";
import classes from "./CrmComp.module.css";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import TypographyInline from "../../../../components/Typography/TypographyInline";

const WarningModalMeetingCrm = ({
  open,
  close,
  setForceCreate,
  errMessage,
}) => {
  const cancelHandle = () => {
    close(!open);
  };

  const createHandle = () => {
    close(!open);
    setForceCreate(true);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LOG_MEETING,
      label: STRINGS.WARNING,
    },
  };

  const modalBodyData = (
    <div>
      <TypographyInline
        size={"md"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={errMessage}
      />
      <TypographyInline
        size={"md"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.ARE_YOU_STILL_CREATE}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        onClick={cancelHandle}
        variant={"ghost"}
        size={"sm36"}
      />
      <Button
        label={STRINGS.CREATE}
        size={"sm36"}
        variant={"primary"}
        onClick={createHandle}
      />
    </div>
  );

  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      width={"41.667vw"}
      close={cancelHandle}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
      variant={"error"}
    />
  );
};

export default WarningModalMeetingCrm;
