import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {  sequenceEndPoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { postRequest } from "../../../../Apis/apiWrapper";
import { STRINGS } from "../strings";
import { Button } from "../../../../components/Buttons";
import CustomModal from "../../../../components/Modals/CustomModal";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import { paginationDataAction } from "../../../../redux/actions/customTableActions";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import classes from "../markComponents.module.css";
const RecordDeleteModal = ({ open, setOpen, IDs, val, itemType, itemName , endPoint = sequenceEndPoints.deleteSequece}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const deleteRecord = async () => {
    try {
      setLoading(true);
      const payload = {
        record_ids: IDs,
        type: itemType || STRINGS.SMALL_FILE,
      };
      const response = await postRequest(navigate, endPoint , payload);
      dispatch(paginationDataAction({ checkedRow: [], query:{} }));
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      // setOpen(!open);
    } catch (err) {
      EventEmitter.emit(EMITTER_ERROR, err?.data?.message)
    } finally {
      setOpen(!open);
      setLoading(false);

    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: itemName ? `${STRINGS.DELETE} ${itemName}` : `${STRINGS.DELETE_SELECTED} record`,
    },
  };

  const modalBodyData = (
    <div >
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.REGULAR}
        label={itemName? `${STRINGS.DELETE_BULK} ${val}?` : `${STRINGS.DELETE_BULK} records?`}
      />
    </div>
  );

  const modalFooterLeftData = <div></div>;

  const modalFooterRightData = (
    <div className={classes.rightFoot}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.DELETE}
        size={"sm36"}
        variant={"primary"}
        onClick={deleteRecord}
        isLoading={loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      variant={"error"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default RecordDeleteModal;
