import {
  DEALS_TABLE_FAIL,
  DEALS_TABLE_REQUEST,
  DEALS_TABLE_SUCCESS,
  PEOPLE_BASIC_DEAL_DETAILS_FAIL,
  PEOPLE_BASIC_DEAL_DETAILS_REQUEST,
  PEOPLE_BASIC_DEAL_DETAILS_SUCCESS,
} from "../constants/dealsConstants";

export const getDealsListReducer = (state = {}, action) => {
  switch (action.type) {
    case DEALS_TABLE_REQUEST:
      return { loading: true, final_loading: true };
    case DEALS_TABLE_SUCCESS:
      return { loading: false, data: action.payload, error: null, final_loading: action.final_loading };
    case DEALS_TABLE_FAIL:
      return { loading: false, error: action.payload, final_loading: false };
    default:
      return state;
  }
};

export const peopleDealsDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PEOPLE_BASIC_DEAL_DETAILS_REQUEST:
      return { loading: true };

    case PEOPLE_BASIC_DEAL_DETAILS_SUCCESS:
      return { loading: false, payload: action.payload };

    case PEOPLE_BASIC_DEAL_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
