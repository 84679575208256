export const CAMPAIGN_LIST_GET_REQUEST = "CAMPAIGN_LIST_GET_REQUEST"
export const CAMPAIGN_LIST_GET_SUCCESS = "CAMPAIGN_LIST_GET_SUCCESS"
export const CAMPAIGN_LIST_GET_FAIL = "CAMPAIGN_LIST_GET_FAIL"
export const CAMPAIGN_LIST_GET_RESET = "CAMPAIGN_LIST_GET_RESET"

export const SELECTED_CAMPAIGN_DETAILS_REQUEST = "SELECTED_CAMPAIGN_DETAILS_REQUEST"
export const SELECTED_CAMPAIGN_DETAILS_SUCCESS = "SELECTED_CAMPAIGN_DETAILS_SUCCESS"
export const SELECTED_CAMPAIGN_DETAILS_FAIL = "SELECTED_CAMPAIGN_DETAILS_FAIL"

export const GET_CAMPAIGN_FOLDER_LIST_REQUEST = "GET_CAMPAIGN_FOLDER_LIST_REQUEST"
export const GET_CAMPAIGN_FOLDER_LIST_SUCCESS = "GET_CAMPAIGN_FOLDER_LIST_SUCCESS"
export const GET_CAMPAIGN_FOLDER_LIST_FAIL = "GET_CAMPAIGN_FOLDER_LIST_FAIL"

