import React from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  EMITTER_SUCCESS,
  tempHeadCells,
  tempHeadLeadCells,
} from "../../utils/constants";
import {
  applyUpdateColumn,
  tableDataCampaignFetch,
  tableDataFetch,
  updateColumnHeaders,
} from "../../redux/actions/tableActions";
import { useNavigate } from "react-router-dom";
import { Button } from "../Buttons";
import { STRINGS } from "./strings";
import CustomModal from "./CustomModal";
import { GREY } from "../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import { getSalesContactsList } from "../../redux/actions/salesTableAction";
import TypographyInline from "../Typography/TypographyInline";
import EventEmitter from "../../utils/emitter/EventEmitter";

const styles = makeStyles({
  modalFooterRightData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
  },
  modalBody: {
    padding: "4px 16px 16px 16px",
  },
});

const ConfirmationModal = ({
  open,
  setOpen,
  setHeaders,
  setList,
  resetCells = tempHeadCells,
  isLead = false,
  isSales = false,
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const querySelector = useSelector((state) => state?.allFilters?.payload);

  const handleResetColumn = async () => {
    await dispatch(
      updateColumnHeaders(
        JSON.parse(JSON.stringify(isLead ? tempHeadLeadCells : resetCells))
      )
    );
    await dispatch(
      applyUpdateColumn(
        JSON.parse(JSON.stringify(isLead ? tempHeadLeadCells : resetCells))
      )
    );
    setHeaders(
      JSON.parse(JSON.stringify(isLead ? tempHeadLeadCells : resetCells))
    );
    setList(
      JSON.parse(JSON.stringify(isLead ? tempHeadLeadCells : resetCells))
    );
    if (isSales) {
      dispatch(getSalesContactsList(querySelector, navigate));
    } else {
      isLead
        ? dispatch(tableDataCampaignFetch(querySelector, navigate))
        : dispatch(tableDataFetch(querySelector, navigate));
    }
    setOpen(!open);
    EventEmitter.emit(EMITTER_SUCCESS, STRINGS.COLUMN_RESET);
  };

  const handleChange = async () => {
    setOpen(!open);
  };

  const modalHeaderData = {
    heading: {
      id: "head",
      label: STRINGS.RESET_COLUMNS,
    },
    alertIcon: {
      id: "restore",
      label: ICON_LABELS.RESTORE,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <TypographyInline
        size={"md"}
        color={GREY.PRIMARY}
        fontWeight={fontWeight.SEMIBOLD}
        label={STRINGS.RESET_ALL_PROPERTIES}
      />
      <TypographyInline
        size={"xs"}
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={STRINGS.RESET_ALL_PROPERTIES_DESC}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={handleChange}
      />

      <Button
        label={STRINGS.RESET}
        size={"sm36"}
        variant={"primary"}
        onClick={handleResetColumn}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      close={handleChange}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
      variant={"alert"}
      remove_padding
    />
  );
};

export default ConfirmationModal;
