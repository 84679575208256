import React, { useEffect, useState } from "react";
import AddComment from "../../Components/AddComment";
import { Accordion } from "@mui/material";
import {
  AccordionDetails,
  AccordionSummary,
  HtmlEditor,
} from "../../../../components";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import IconColoredComponent from "../../../../components/Icon/IconColoredComponent";
import IconComponent from "../../../../components/Icon/IconComponent";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { GREY, PRIMARY } from "../../../../utils/constants/colors";
import classes from "./DealsComp.module.css";
import { STRINGS } from "../../strings";
import { Button } from "../../../../components/Buttons";
import CustomMenu from "../../../../components/Menu/CustomMenu";
import { compressString, dateTimeStringEmailLog } from "../../../../utils/helpers";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import DeleteCommentModal from "../../Components/DeleteCommentModal";

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../../../Apis";
import NoteDeleteModal from "./NoteDeleteModal";
import { useSelector } from "react-redux";

const ListingNoteCard = ({
  dealId,
  id,
  logData,
  dealDetails,
  setNoteListData,
  expanded,
  showOperation = true,
  getNoteLogList,
}) => {

  const navigate = useNavigate();
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [noteOpen, setNoteOpen] = useState(false);
  const [noteLogComments, setNoteLogComments] = useState([]);
  const [openNote, setOpenNote] = useState(false);
  const [deleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [createCommentLoading, setCreateCommentLoading] = useState(false);
  const [noteLogCommentsPage, setLogNoteCommentsPage] = useState(0);
  const [isDeleteCommentLoading, setIsDeleteCommentLoading] = useState(false);
  const [commentBody, setCommentBody] = useState("");
  const [clickedCommentData, setClickedCommentData] = useState({});
  const [editorView, setEditorView] = useState(false);
  const [notesClicked, setNotesClicked] = useState(false);

  // creating note log comment api handler
  const createCommentHandler = async () => {
    try {
      setLogNoteCommentsPage(0);
      let payload = {
        query: {
          comments: commentBody,
          note_id: logData?.id,
          deal_id: dealId,
        },
      };
      setCreateCommentLoading(true);
      const response = await postRequest(
        navigate,
        crmEndpoints.createNoteCommentCrm,
        payload
      );
      getNoteLogComments(false, true);
      EventEmitter.emit(EMITTER_SUCCESS, response.data.message);
      setCreateCommentLoading(false);
    } catch (error) {
      console.log("Error ->>>>> ", error);
    }
  };
  // edit note log comment api handler
  const updateNoteLogCommentHandler = async (comment, editValue) => {
    try {
      setLogNoteCommentsPage(0);
      let payload = {
        query: {
          comments: editValue[comment?.id],
          id: comment?.id,
          deal_id: dealId,
        },
      };
      const response = await putRequest(
        navigate,
        crmEndpoints.updateNoteCommentCrm,
        payload
      );
      getNoteLogComments(false, true);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };
  // api call for getting note log comments
  const getNoteLogComments = async (isOnScroll = false, initial) => {
    try {
      let url =
        crmEndpoints.getNotesComment +
        `/${logData?.id}?size=10&page=${initial ? 0 : noteLogCommentsPage}`;
      const response = await getRequest(navigate, url);
      if (isOnScroll) {
        setNoteLogComments([
          ...noteLogComments,
          ...response?.data?.data?.records,
        ]);
      } else {
        setNoteLogComments([...response?.data?.data?.records]);
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleModal = (event) => {
    setIsActionMenuVisible(true);
    setActionMenuAnchor(event.currentTarget);
  };

  useEffect(() => {
    if (noteLogCommentsPage) {
      getNoteLogComments(false, true);
    }
  }, [noteLogCommentsPage]);

  useEffect(() => {
    if (noteOpen) {
      getNoteLogComments(false, false);
    }
  }, [noteOpen]);

  useEffect(() => {
    //set the previous data
    setDescription(logData?.notes_body);
  }, [logData]);

  const tableOperations = [
    {
      id: STRINGS.SMALL_PIN,
      iconLabel: ICON_LABELS.PUSH_PIN,

      label: logData?.is_pin === 1 ? STRINGS.UNPIN : STRINGS.PIN,
      function: () => handlePin(),
    },
    {
      id: STRINGS.DELETE,
      label: STRINGS.DELETE,
      iconLabel: ICON_LABELS.DELETE,
      function: () => handleDelete(),
      variant: "error",
    },
  ];

  //Handling pin Action here
  const handlePin = async () => {
    let payload = {
      query: {
        pin: logData?.is_pin === 1 ? 0 : 1,
        id: logData?.id,
        deal_id: dealId,
      },
    };
    try {
      const URL = crmEndpoints?.pinNotesCrm;
      const response = await putRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      getNoteLogList();
    } catch (error) { }
  };

  const handleDelete = () => {
    setOpenNote(true);
  };

  //handling Note Edit
  const handleNoteEditApi = async () => {
    setLoading(true);
    try {
      let payload = {
        query: {
          id: logData?.id,
          notes_body: compressString(encodeURIComponent(description)),
          deal_id: dealId,
        },
      };
      const URL = crmEndpoints?.editNotes;
      const response = await putRequest(navigate, URL, payload);
      setLoading(false);
      setEditorView(false);
      setVisible(false);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      getNoteLogList();
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  // delete note log comment api handler
  const deleteNoteLogCommentHandler = async (data) => {
    try {
      setLogNoteCommentsPage(0);
      setIsDeleteCommentLoading(true);
      let url = `${crmEndpoints?.deleteNotesComment}`;
      let payload = {
        query: {
          id: data?.id,
          note_id: logData?.id,
          deal_id: dealId,
        },
      };
      const response = await deleteRequest(url, payload, navigate);
      getNoteLogComments(false, true);
      setIsDeleteCommentLoading(false);
      setDeleteCommentModalOpen(false);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      setIsDeleteCommentLoading(false);

      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const deleteNoteLogCommentModalHandler = (data) => {
    setClickedCommentData(data);
    setDeleteCommentModalOpen(true);
  };

  const handleResetContDetails = () => {
    setVisible(false);
    setEditorView(false);
    setDescription(logData?.notes_body);
    if (document.getElementById(id)) {
      document.getElementById(id).innerHTML = logData?.notes_body;
    }
  };

  //previous Duetime set here -setDueTime
  useEffect(() => {
    //set the previous data
    setDescription(logData?.notes);

    if (expanded) {
      setNoteOpen(expanded);
    }
  }, [logData]);

  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  const updatedRecord = tenantUsersListSelector?.filter((eachRecord) => {
    return eachRecord?._id === logData?.user_id;
  })[0];

  return (
    <div>
      <div className={classes.HeadTaskContainer}>
        <Accordion
          className={classes.accrodionRoot}
          onChange={() => setNoteOpen(!noteOpen)}
          expanded={noteOpen}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {" "}
            <div className={classes.summaryContainerHeads}>
              <div className={classes.summaryContainerNote}>
                {showOperation && parseInt(logData?.is_pin) === 1 ? (
                  <div
                    className={
                      parseInt(logData?.is_pin) === 1 ? classes.defaultIcon : ""
                    }
                  >
                    <IconComponent
                      iconLabel={
                        parseInt(logData?.is_pin) === 1
                          ? ICON_LABELS.PUSH_PIN
                          : ""
                      }
                      color={
                        parseInt(logData?.is_pin) === 1 ? PRIMARY.W_400 : ""
                      }
                      fontSize={fontSize.XS}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className={classes.summaryRightSection}>
                  <IconComponent
                    color={GREY.TERTIARY}
                    fontSize={fontSize.DXS}
                    iconLabel={
                      noteOpen
                        ? ICON_LABELS.ACTION_ARROW
                        : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                    }
                  />

                  <IconColoredComponent
                    variant={"blue"}
                    iconLabel={ICON_LABELS.STICKY_NOTE}
                    color={"blue"}
                    size="small"
                  />

                  <TypographyInline
                    label={"Note"}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                    color={GREY.PRIMARY}
                  />

                  <TypographyInline
                    color={GREY.SECONDARY}
                    label={`${STRINGS.BY} ${updatedRecord?.first_name} ${updatedRecord?.last_name}`}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    isNoWrap
                  />
                  {dealDetails?.contactData?.first_name && (
                    <>
                      <TypographyInline
                        color={GREY.SECONDARY}
                        label={`${STRINGS.WITH}`}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                        isNoWrap
                      />
                      <TypographyInline
                        color={GREY.PRIMARY}
                        label={` ${dealDetails?.contactData?.first_name || ""} ${dealDetails?.contactData?.last_name || ""
                          }`}
                        size={"sm"}
                        fontWeight={fontWeight.SEMIBOLD}
                        isEllipses
                      />
                    </>
                  )}
                </div>

                <div className={classes.dateTimeTaskContainer}>
                  <IconComponent
                    iconLabel={ICON_LABELS.ACCESS_TIME}
                    fontSize={fontSize.MD}
                    color={GREY.SECONDARY}
                  />
                  <TypographyInline
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    size={"sm"}
                    label={dateTimeStringEmailLog(logData?.created_at)}
                  />

                  {showOperation && (
                    <>
                      <div className={classes.verticalLine} />
                      <div
                        className={classes.moreOptionsIconContianer}
                        onClick={(e) => {
                          e?.stopPropagation();
                        }}
                      >
                        <IconComponent
                          color={GREY.TERTIARY}
                          fontSize={fontSize.MD}
                          iconLabel={ICON_LABELS.MORE_VERT}
                          onClick={handleModal}
                        />

                        <CustomMenu
                          items={tableOperations}
                          anchor={actionMenuAnchor}
                          open={isActionMenuVisible}
                          onClose={() => {
                            setIsActionMenuVisible(false);
                            setActionMenuAnchor(null);
                          }}
                          paperMenuClass={classes.dropdownMenuListing}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {logData?.notes_body ? (
                <div
                  className={`${classes.logEmailListSummaryLowerContainer} ${notesClicked ? classes.notesBackground : ""
                    }`}
                  onClick={() => {
                    setNotesClicked(true);
                  }}
                  onBlur={() => {
                    setNotesClicked(false);
                  }}
                >
                  <div>
                    <TypographyInline
                      color={GREY.PRIMARY}
                      label={STRINGS.NOTES}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                    />
                  </div>
                  {editorView ? (
                    <div className={classes.htmlEditor}>
                      <HtmlEditor
                        isAddTagNotVisible={true}
                        isMeetingTagNotVisible={true}
                        isAITagNotVisible
                        isFocusNotRequired
                        id={id}
                        previosData={logData?.notes_body}
                        onInput={(event) => {
                          setDescription(event?.target?.innerHTML);
                          setVisible(true);
                        }}
                        editorHeight={"8.625vw"}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => setEditorView(true)}
                      className={classes.parsedEmailLogData}
                    >
                      {parse(logData?.notes_body)}
                    </div>
                  )}
                  {visible ? (
                    <div className={classes.aboutFooterNote}>
                      <div>
                        <Button
                          label={STRINGS.CANCEL}
                          size={"sm36"}
                          variant={"ghost"}
                          onClick={() => {
                            handleResetContDetails();
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          label={STRINGS.SAVE}
                          size={"sm36"}
                          variant={"primary"}
                          onClick={() => {
                            handleNoteEditApi();
                          }}
                          isLoading={loading}
                          disabled={showOperation ? false : true}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : visible ? (
                <div className={classes.aboutFooterNote}>
                  <div>
                    <Button
                      label={STRINGS.CANCEL}
                      size={"sm36"}
                      variant={"ghost"}
                      onClick={() => {
                        handleResetContDetails();
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      label={STRINGS.SAVE}
                      size={"sm36"}
                      variant={"primary"}
                      onClick={() => {
                        handleNoteEditApi();
                      }}
                      isLoading={loading}
                      disabled={showOperation ? false : true}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* detail comment section */}
            {showOperation && (
              <div className={classes.noteLogListCommentContainer}>
                <AddComment
                  createCommentHandler={createCommentHandler}
                  value={commentBody}
                  setValue={setCommentBody}
                  isLoading={createCommentLoading}
                  commentsList={noteLogComments}
                  onScrollHandler={() =>
                    setLogNoteCommentsPage(noteLogCommentsPage + 1)
                  }
                  updateCommentHandler={updateNoteLogCommentHandler}
                  deleteModalHandler={deleteNoteLogCommentModalHandler}
                />

                <DeleteCommentModal
                  open={deleteCommentModalOpen}
                  close={() => {
                    setDeleteCommentModalOpen(false);
                  }}
                  clickedData={clickedCommentData}
                  deleteButtonLoading={isDeleteCommentLoading}
                  deleteAPIHandler={deleteNoteLogCommentHandler}
                />
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        {openNote && (
          <NoteDeleteModal
            id={logData?.id}
            openNote={openNote}
            setOpenNote={setOpenNote}
            dealId={dealId}
            getNoteLogList={getNoteLogList}
          />
        )}
      </div>
    </div>
  );
};

export default ListingNoteCard;
