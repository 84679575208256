import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BORDER, BRAND, GREY } from "../../utils/constants/colors";
import { SHADOW, fontSize } from "../../utils/constants/UI";

const SIZE_MAPPING = {
  xs34: {
    padding: "5.5px 14px !important",
    fontSize: fontSize.XS,
  },
  sm36: {
    padding: "6.5px 14px !important",
    fontSize: fontSize.SM,
  },
  md40: {
    padding: "8.5px 14px !important",
    fontSize: fontSize.MD,
  },
  lg44: {
    padding: "10.5px 14px !important",
    fontSize: fontSize.LG,
  },
};

const styles = makeStyles({
  datePickerMainContainer: {
    width: "100%",
  },
  dateInput: {
    padding: (props) => SIZE_MAPPING[props?.size]?.padding,
    fontSize: (props) => `${SIZE_MAPPING[props?.size]?.fontSize} !important`,
    color: "#5f5f61 !important",
  },
  paeprRoot: {
    marginTop: "2px !important",
    marginLeft: "68px !important",
    width: (props) => props?.paperWidth || "100% !important",
    "& .MuiPickersDay-root": {
      "&.Mui-selected": {
        backgroundColor: `${BRAND.PRIMARY} !important`,
      },
    },
  },
  inputField: {},
});

export default function CustomDatePicker({ size, paperWidth, ...props }) {
  const classes = styles({ size, paperWidth, ...props });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={props?.disabled}
        inputFormat="DD/MM/YYYY"
        className={classes.datePickerMainContainer}
        InputProps={{
          classes: { input: classes.dateInput },
        }}
        // disablePast
        // props
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            id="custom-datepicker"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: BORDER.HOVER,
                },
                "&:hover fieldset": {
                  borderColor: GREY.TERTIARY,
                },
                "&.Mui-focused fieldset": {
                  borderColor: GREY.PRIMARY,
                  boxShadow: SHADOW.input,
                },
              },
            }}
            {...params}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}

//disable key for disable custom datepicker
