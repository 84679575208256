import React, { useEffect, useState } from "react";
import classes from "./DealsComp.module.css";
import CustomTable from "../../../../components/Table/CustomTable";
import FilterTab from "../../../../components/FilterTab/FilterTab";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BRAND, GREY } from "../../../../utils/constants/colors";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import DealDeleteModal from "./DealDeleteModal";
import { deleteRequest, putRequest } from "../../../../Apis";
import {
  crmEndpoints,
  dealsEndpoints,
} from "../../../../utils/constants/httpConstants";
import DealAssignModal from "./DealAssignModal";
import { getDealsList } from "../../../../redux/actions/dealsActions";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  tempHeadDealsCells,
} from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";

const DealsTable = ({
  heads,
  records,
  totalCount,
  setSearchField,
  loading = true,
  pipeline_id,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dealsListSelector = useSelector((state) => state.getDealsList);

  const userDetailsSelector = useSelector((state) => state?.userDetails?.payload);
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );
  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );
  const bulkRecordCount =
    dealsListSelector?.data?.total_records?.value < 500
      ? dealsListSelector?.data?.total_records?.value?.toLocaleString()
      : 500?.toLocaleString();

  const [deleteBulkModal, setDeleteBulkModal] = useState(false);
  const [assignBulkModal, setAssignBulkModal] = useState(false);
  const [bulkSelected, setBulkSelected] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [selectedBulkValue, setSelectedBulkValue] = useState("");
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

  const [rowStages, setRowStages] = useState([]);

  //function for row data while click on a stage name
  const handleStatusShow = (row) => {
    const data = row?.stages?.map((value, i) => {
      return {
        _id: value?._id,
        label: value?.name,
        visible: true,
        function: (row, head, item) => handleStageDataShow(row, head, item),
      };
    });
    setRowStages(data);
  };

  //function for edit stage
  const handleStageDataShow = async (row, head, item) => {
    const payload = {
      stage_id: item?._id,
      _id: row?._id,
    };
    //Edit Stage Api
    try {
      const URL = crmEndpoints?.editDealStage;
      const response = await putRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }

    //dispatch get Deals with pipeline id

    if (pipeline_id === "All") {
      dispatch(getDealsList(allDynamicFiltersSelector, navigate));
    } else {
      dispatch(
        getDealsList(allDynamicFiltersSelector, navigate, {
          pipeline_id: pipeline_id,
        })
      );
    }
  };

  const dealsTableData = {
    heads: heads?.length
      ? heads
      : tempHeadDealsCells.filter((item) => item.isChecked),
    body: records,
    status_list: rowStages,
    actions: {
      bold_text: {
        function: (row) => {
          navigate(`/user/deals/${row?._id}?tabs=timeline`);
        },
      },
      lead_status: {
        function: (row, head, item) => handleStatusShow(row, head, item),
      },
    },
    total_records: totalCount,
    isSticky: true,
    isLoading: dealsListSelector?.loading || loading,
    header_strip_selected_value: selectedBulkValue,
  };

  const headerStrip = [
    <FilterTab
      leftIcon={ICON_LABELS.FACT_CHECK}
      label={STRINGS.ASSIGN}
      onClick={() => {
        setAssignBulkModal(true);
      }}
    />,
    <FilterTab
      leftIcon={ICON_LABELS.DELETE_OUTLINE}
      label={STRINGS.DELETE}
      onClick={() => {
        setDeleteBulkModal(true);
      }}
    />,
    //temparory hiding export button
    // <FilterTab
    //   leftIcon={ICON_LABELS.DOWNLOAD_FOR_ONLINE}
    //   label={STRINGS.EXPORT}
    //   onClick={() => {
    //     setShowExportModal(true);
    //   }}
    // />,
  ];

  const headerStripWoAssign = [
    <FilterTab
      leftIcon={ICON_LABELS.DELETE_OUTLINE}
      label={STRINGS.DELETE}
      onClick={() => {
        setDeleteBulkModal(true);
      }}
    />,
    //temparory hiding export button
    // <FilterTab
    //   leftIcon={ICON_LABELS.DOWNLOAD_FOR_ONLINE}
    //   label={STRINGS.EXPORT}
    //   onClick={() => {
    //     setShowExportModal(true);
    //   }}
    // />,
  ];
  const headerStripWoDelete = [
    <FilterTab
      leftIcon={ICON_LABELS.FACT_CHECK}
      label={STRINGS.ASSIGN}
      onClick={() => {
        setAssignBulkModal(true);
      }}
    />,
  ];

  const handleBulkSelection = async () => {
    setBulkSelected(!bulkSelected);
    const selectedIds = records.map((item) => item?._id);

    setSelected(selectedIds);
    if (selectedBulkValue?.length) {
      setSelectedBulkValue("");
    } else {
      setSelectedBulkValue(bulkRecordCount);
    }
  };

  const deleteDealHandler = async () => {
    try {
      let payload = {
        query: {
          ids: paginationDataSelector?.data?.checkedRow,
        },
      };

      setDeleteButtonLoading(true);
      const response = await deleteRequest(dealsEndpoints.bulkDeleteDeals, payload, navigate);
      if (pipeline_id === "All") {
        dispatch(getDealsList(allDynamicFiltersSelector, navigate));
      } else {
        dispatch(
          getDealsList(allDynamicFiltersSelector, navigate, {
            pipeline_id: pipeline_id,
          })
        );
      }

      setDeleteButtonLoading(false);
      setDeleteBulkModal(false);
      EventEmitter.emit(EMITTER_SUCCESS, response.data?.message);
    } catch (error) {
      setDeleteButtonLoading(false);
      console.log("Error --->>>>>>... ", error);
    }
  };

  useEffect(() => {
    if (paginationDataSelector?.data?.checkedRow) {
      setSelected(paginationDataSelector?.data?.checkedRow);
    }
  }, [paginationDataSelector?.data?.checkedRow]);

  return (
    <div className={classes.HeadTable}>
      {/* List actions here */}
      {selected?.length >= 1 ? (
        <div className={classes.containAllContacts}>
          <TypographyInline
            label={`${STRINGS.ALL} ${selected?.length} ${STRINGS.CNT_ON_PAGE}`}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
          <div className={classes.cursorPointer} onClick={handleBulkSelection}>
            <TypographyInline
              label={`${STRINGS.SELECT_ALL} ${bulkRecordCount} ${STRINGS.SMALL_CONTACTS}`}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={BRAND.PRIMARY}
            />
          </div>
        </div>
      ) : null}

      <div
        id="modal_body"
        className={
          selected?.length
            ? classes.tableSelectedMainContainer
            : classes.tableMainContainer
        }
      >
        <CustomTable
          tableData={dealsTableData}
          headerStrip={!bulkSelected ? userDetailsSelector?.role === "admin-user" ? headerStrip : headerStripWoAssign : headerStripWoDelete}
        />
      </div>

      {/* <ExportModal
        open={showExportModal}
        setOpen={setShowExportModal}
        bulkSelected={bulkSelected}
        setBulkSelected={setBulkSelected}
        columns={heads}
        record_ids={selected}
        setSelectedIds={setSelected}
      /> */}
      <DealDeleteModal
        open={deleteBulkModal}
        close={() => setDeleteBulkModal(false)}
        deleteButtonLoading={deleteButtonLoading}
        deleteAPIHandler={deleteDealHandler}
      />

      <DealAssignModal
        open={assignBulkModal}
        setOpen={setAssignBulkModal}
        record_ids={selected}
        setSelectedIds={setSelected}
        setSearchField={setSearchField}
        pipeline_id={pipeline_id}
      />
    </div>
  );
};

export default DealsTable;
