import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { EACH_BATCH_SIZE, EMITTER_SUCCESS } from "../../../utils/constants";
import * as Papaparse from "papaparse";
import { Uploader } from "../../../utils/helpers/Uploader";
import { ErrorMessage } from "../../../components";
import ProgressEmitter from "../../../components/Loaders/ProgressEmitter";
import { useDispatch } from "react-redux";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { importEndpoints } from "../../../utils/constants/httpConstants";
import { postRequest } from "../../../Apis";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "../../../utils/helpers";
import { Modal } from "@mui/material";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import classes from "./LeadsComponent.module.css";
import TypographyInline from "../../../components/Typography/TypographyInline";

const BlacklistMappingFieldModal = ({
  open,
  setOpen,
  file,
  columns = [],
  setFile,
  selectedBlacklistLabel,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allColumns, setAllColumns] = useState(columns || []);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [dropdownValue, setDropdownValue] = useState([] || "");
  const [dropdownValueId, setDropdownValueId] = useState([] || "");
  const [errorMessage, setErrorMessage] = useState("");
  const [columnsUploading, setColumnsUploading] = useState(false);

  async function readFileChunk(time, index, fil) {
    let reader = new FileReader();
    let blob = fil.slice(
      EACH_BATCH_SIZE * index,
      EACH_BATCH_SIZE * (index + 1)
    );
    reader.onload = async function (e) {
      const each_parsed_string = e.target.result;
      const ParsedRes = Papaparse.parse(each_parsed_string);
      const result = [];
      const tempData = ParsedRes.data[0];
      for (let i = 0; i < tempData?.length; i++) {
        tempData?.[i]?.length &&
          result.push({ id: i.toString(), label: tempData?.[i] });
      }

      console.log({ result });
      setAllColumns(result);
    };
    reader.readAsText(blob);
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const initiateUploadingProcess = () => {
    let percentage = undefined;
    const videoUploaderOptions = {
      fileName: file?.name || STRINGS.FOO,
      file: file,
      initialEndpoint: importEndpoints.uploadFileInitialEndpoint,
      multipartEndpoint: importEndpoints.uploadFileMultipartEndpoint,
      finalEndpoint: importEndpoints.uploadFilefinalEndpoint,
    };
    const uploader = new Uploader(videoUploaderOptions);
    uploader
      .onProgress(({ percentage: newPercentage }) => {
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          percentage >= uploadPercentage && setUploadPercentage(percentage);
        }
      })
      .onError((error) => {
        console.error(error);
      });
    uploader.start();
  };

  useEffect(() => {
    initiateUploadingProcess();
    (async () => {
      await readFileChunk(1000, 0, file);
    })();
  }, [file]);

  useEffect(() => {
    if (uploadPercentage === 100) {
    }
  }, [uploadPercentage, dispatch]);

  const handleClose = () => {
    setFile(null);
    setOpen(!open);
  };

  const filterCsvColumn = (text) => {
    return text?.length >= 20 ? text?.slice(0, 20) + "..." : text;
  };

  const handleSubmit = async () => {
    if (dropdownValue?.length === 0) {
      setErrorMessage(STRINGS.MAPPING_IS_MANDATORY);
      return;
    }
    setErrorMessage("");
    setColumnsUploading(true);
    try {
      const file_path = localStorage.getItem(STRINGS.UPLOAD_FILE);
      const payload = {
        file_path,
        file_name: file?.name,
        column_header: Number(dropdownValueId[0]),
      };
      localStorage.removeItem(STRINGS.UPLOAD_FILE);
      const response = await postRequest(
        navigate,
        selectedBlacklistLabel == "Emails"
          ? importEndpoints.blackListFileFieldMapping
          : importEndpoints.domainFileFieldMapping,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      handleClose();
    } catch (error) {
      EventEmitter.emit(
        EMITTER_SUCCESS,
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    } finally {
      setColumnsUploading(false);
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.MAP_COLUMN,
    },
  };
  const modalBodyData = (
    <div>
      <Box className={classes.importModalColumnsContainer}>
        <Box className={classes.eachColumn}>
          <p className={classes.column_title}>
            {filterCsvColumn(selectedBlacklistLabel)}
          </p>
          <Box className={classes.flexJustifyCenter}>
            <AutoCompleteCustom
              width={"96px"}
              items={allColumns}
              LeftIcon=""
              valueState={dropdownValue}
              setValueState={setDropdownValue}
              idState={dropdownValueId}
              setIdState={setDropdownValueId}
              size={"md40"}
            />
          </Box>
        </Box>
        <Box>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        </Box>
      </Box>
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData1}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={handleClose}
      />
      <Button
        label={STRINGS.SUBMIT}
        variant={"primary"}
        size={"sm36"}
        onClick={handleSubmit}
        isLoading={columnsUploading}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <Box>
      {/* Upload Progress bar modal */}
      <Modal open={uploadPercentage !== 100}>
        <ProgressEmitter
          name={file?.name}
          uploadPercentage={uploadPercentage}
          refreshRequired={true}
        />
      </Modal>
      {/* Upload Progress bar modal */}

      <CustomModal
        width={"40vw"}
        close={() => setOpen(false)}
        open={uploadPercentage === 100}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
      />
    </Box>
  );
};

export default BlacklistMappingFieldModal;
