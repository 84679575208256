import React, { useEffect, useState } from "react";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import {
  BORDER,
  GREY,
  PRIMARY,
  SUCCESS,
} from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import classes from "./CrmComp.module.css";

import { Accordion } from "@mui/material";
import {
  AccordionDetails,
  AccordionSummary,
  HtmlEditor,
} from "../../../../components";

import IconComponent from "../../../../components/Icon/IconComponent";
import IconColoredComponent from "../../../../components/Icon/IconColoredComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { STRINGS } from "../../strings";
import {
  compressString,
  dateTimeStringEmailLog,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import { AutoCompleteWoBorder } from "../../../../components/Autocomplete/AutoCompleteWoBorder";
import CustomMenu from "../../../../components/Menu/CustomMenu";
import { Button } from "../../../../components/Buttons";
import CustomDatePickerWoBorder from "../../../../components/DatePicker/CustomDatePickerWoBorder";
import TimeSelector from "../../../../components/TimeSelector/TimeSelector";
import {
  sequenceEndPoints,
  taskEndpoints,
} from "../../../../utils/constants/httpConstants";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "../../../../Apis/apiWrapper";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  SEND_REMINDER,
  STEPS_PRIORITY_FIELDS,
  TIMEZONE_LIST,
} from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import InputField from "../../../../components/InputFields/InputField";
import TaskDeleteModal from "./TaskDeleteModal";
import AddComment from "../../Components/AddComment";
import DeleteCommentModal from "../../Components/DeleteCommentModal";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

const ListingTaskCard = ({
  id,
  logData,
  contact_id,
  setTaskListData,
  expanded,
  type,
  showOperation = true,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [taskOpen, setTaskOpen] = useState(false);
  const [dueTime, setDueTime] = useState("00:00");
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
  const [priorityType, setPriorityType] = useState([""] || []);
  const [priorityTypeId, setPriorityTypeId] = useState([""] || []);
  const [reminderType, setReminderType] = useState([""] || []);
  const [reminderTypeId, setReminderTypeId] = useState([""] || []);
  const [openTask, setOpenTask] = useState(false);
  const [ChangeField, setChangeField] = useState(false);
  const [changeIcon, setChangeIcon] = useState(false);
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [taskTitle, setTaskTitle] = useState("");
  const [commentBody, setCommentBody] = useState("");
  const [loader, setLoader] = useState(false);
  const [createCommentLoading, setCreateCommentLoading] = useState(false);
  const [taskLogCommentsPage, setLogTaskCommentsPage] = useState(0);
  const [isDeleteCommentLoading, setIsDeleteCommentLoading] = useState(false);
  const [deleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [clickedCommentData, setClickedCommentData] = useState({});
  const [taskLogComments, setTaskLogComments] = useState([]);
  const [editorView, setEditorView] = useState(false);
  const [notesClicked, setNotesClicked] = useState(false);
  console.log({ type });
  const UpdatedLocation = type === STRINGS.CALL;

  let offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone_objChange = TIMEZONE_LIST?.filter(
    (item) => item?.id === offset
  );

  const handleHover = () => {
    setChangeField(true);
  };

  const handleHoverLeave = () => {
    setChangeField(false);
  };

  const tableOperations = [
    {
      id: STRINGS.SMALL_PIN,
      label: logData?.pin === true ? STRINGS.UNPIN : STRINGS.PIN,
      iconLabel: ICON_LABELS.PUSH_PIN,
      function: () => handlePin(),
    },

    {
      id: STRINGS.SMALL_COMPLETE,
      label: STRINGS.MARK_AS_COMPLETE,
      iconLabel: ICON_LABELS.CHECK_CIRCLE,
      function: () => handleConfirmStatus(),
      isVisible: logData?.step_type === 3 ? false : logData?.status ? false : true,
    },
    {
      id: STRINGS.SMALL_DELETE,
      label: STRINGS.DELETE,
      iconLabel: ICON_LABELS.DELETE,
      function: () => handleTaskRecordDelete(),
      variant: "error",
    },
  ];

  const handleModal = (event) => {
    setIsActionMenuVisible(true);
    setActionMenuAnchor(event.currentTarget);
  };

  //Handling pin Action here
  const handlePin = async () => {
    let payload = {
      pin: !logData?.pin,
      _id: logData?._id,
    };
    try {
      const URL = sequenceEndPoints?.pinTaskCrm;
      const response = await putRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      let url2 = `${taskEndpoints.getTaskListCrm}/${contact_id}?step_type=${UpdatedLocation ? 3 : 2
        }`;
      const responseGet = await getRequest(navigate, url2);
      setTaskListData(responseGet?.data?.data?.records);
    } catch (error) { }
  };

  //Mark as complete Handler
  const handleConfirmStatus = async () => {
    setChangeIcon(!changeIcon);

    let payload = {
      status: !logData?.status,
      id: logData?._id,
      offset: timezone_objChange[0]?.offset,
    };
    try {
      const URL = taskEndpoints?.updateTask;
      const response = await patchRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      let url2 = `${taskEndpoints.getTaskListCrm}/ ${contact_id}?step_type=${UpdatedLocation ? 3 : 2
        }`;
      const responseGet = await getRequest(navigate, url2);

      setTaskListData(responseGet?.data?.data?.records);
    } catch (error) { }
  };

  //handling task Delete Record popup open state
  const handleTaskRecordDelete = async () => {
    setOpenTask(true);
  };

  //previous Duetime set here -setDueTime
  useEffect(() => {
    if (logData?.show_time_stamp) {
      let hours = new Date(parseInt(logData?.show_time_stamp)).getHours();
      let minutes = new Date(parseInt(logData?.show_time_stamp)).getMinutes();

      if (hours.toString().length === 1) {
        hours = "0" + hours;
      }
      if (minutes.toString().length === 1) {
        minutes = "0" + minutes;
      }
      const set = hours + ":" + minutes;

      setDueTime(set);
    } else {
      let hoursTime = new Date().getHours();
      let minutesTime = new Date().getMinutes();
      if (minutesTime >= 45) hoursTime++;
      hoursTime = hoursTime % 24;
      minutesTime += 15;
      minutesTime = minutesTime % 60;
      if (hoursTime.toString().length === 1) {
        hoursTime = "0" + hoursTime;
      }
      if (minutesTime.toString().length === 1) {
        minutesTime = "0" + minutesTime;
      }

      const setTiming = hoursTime + ":" + minutesTime;

      setDueTime(setTiming);
    }
    //set the previous data

    setDescription(logData?.notes);
    setPriorityTypeId([logData?.priority]);
    setPriorityType([logData?.priority]);
    setReminderTypeId([logData?.send_reminder]);
    setReminderType(
      logData?.send_reminder === 1 ? [STRINGS.YES] : [STRINGS.NO]
    );

    setDueDate(new Date(logData?.show_time_stamp));
    setTaskTitle(logData?.title);

    if (expanded) {
      setTaskOpen(expanded);
    }
  }, [logData]);

  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  const updatedRecord = tenantUsersListSelector?.filter((eachRecord) => {
    return eachRecord?._id === logData?.user_id;
  })[0];

  //Handling Edit Task Api
  const handleTaskEditApi = async () => {
    setLoader(true);

    try {
      let dateObj = dueDate;
      const NewDate = dueTime?.split(":").map(Number);
      let m = NewDate[1];
      let h = NewDate[0];
      let totalms = h * 60 * 60 * 1000 + m * 60 * 1000;
      let month = new Date(dateObj)?.getMonth() + 1;
      let day = new Date(dateObj)?.getDate();
      let year = new Date(dateObj)?.getFullYear();
      let newdate = year + "-" + month + "-" + day;

      let date;
      if (dueTime) {
        date = new Date(newdate).getTime() + totalms;
      } else {
        date = new Date(newdate).getTime();
      }
      const customEditorValue = description?.length
        ? `<div>${description}</div>`
        : "";
      const payload = {
        status: logData?.status,
        id: logData?._id,
        contact_id: contact_id,
        title: taskTitle,
        step_type: UpdatedLocation ? 3 : 2,
        show_time_stamp: date !== null ? date : "",
        notes: compressString(
          encodeURIComponent(removeTagStyleFromHtml(customEditorValue))
        ),
        offset: timezone_objChange[0]?.offset,
        priority: priorityTypeId[0],
        send_reminder: parseInt(reminderTypeId[0]),
      };
      const URL = taskEndpoints?.updateTask;
      const response = await patchRequest(navigate, URL, payload);
      let url2 = `${taskEndpoints.getTaskListCrm}/ ${contact_id}?step_type=${UpdatedLocation ? 3 : 2
        }`;
      const responseGet = await getRequest(navigate, url2);
      setTaskListData(responseGet?.data?.data?.records);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

      setLoader(false);
      setEditorView(false);
      setVisible(false);
    } catch (error) {
      // console.log({ error });
      setLoader(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  useEffect(() => {
    if (taskOpen) {
      getTaskLogComments(false, true);
    }
  }, [taskOpen]);

  useEffect(() => {
    if (taskLogCommentsPage) {
      getTaskLogComments(true, false);
    }
  }, [taskLogCommentsPage]);

  // creating task log comment api handler
  const createCommentHandler = async () => {
    try {
      setLogTaskCommentsPage(0);
      let payload = {
        comment: commentBody,
        task_id: logData?._id,
      };
      setCreateCommentLoading(true);
      const response = await postRequest(
        navigate,
        sequenceEndPoints.createTaskCommentCrm,
        payload
      );
      getTaskLogComments(false, true);
      setCreateCommentLoading(false);
      EventEmitter.emit(EMITTER_SUCCESS, response.data.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);

      // console.log("Error ->>>>> ", error);
    }
  };

  // edit task log comment api handler
  const updateTaskLogCommentHandler = async (comment, editValue) => {
    try {
      setLogTaskCommentsPage(0);
      let payload = {
        comment: editValue[comment?.id],
        id: comment?.id,
      };
      const response = await putRequest(
        navigate,
        sequenceEndPoints.updateTaskCommentCrm,
        payload
      );
      getTaskLogComments(false, true);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      // console.log("Error ->>>> ", error);
    }
  };

  // delete task log comment api handler
  const deleteTaskLogCommentHandler = async (data) => {
    try {
      setLogTaskCommentsPage(0);
      setIsDeleteCommentLoading(true);
      let url = `${sequenceEndPoints?.deleteTaskCommentCrm}/${data?.id}`;
      const response = await deleteRequest(url, {}, navigate);
      getTaskLogComments(false, true);
      setIsDeleteCommentLoading(false);
      setDeleteCommentModalOpen(false);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      // console.log("Error ->>>> ", error);
    }
  };

  const deleteTaskLogCommentModalHandler = (data) => {
    setClickedCommentData(data);
    setDeleteCommentModalOpen(true);
  };

  // api call for getting task log comments
  const getTaskLogComments = async (isOnScroll, initial) => {
    try {
      let url =
        sequenceEndPoints.getTaskCommentCrm +
        `/${logData?._id}?size=10&page=${initial ? 0 : taskLogCommentsPage}`;
      const response = await getRequest(navigate, url);
      if (isOnScroll) {
        setTaskLogComments([
          ...taskLogComments,
          ...response?.data?.data?.records,
        ]);
      } else {
        setTaskLogComments([...response?.data?.data?.records]);
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleResetContDetails = () => {
    setVisible(false);

    let timeStamp =
      new Date(logData?.show_time_stamp)?.toTimeString().split(":")[0] +
      ":" +
      new Date(logData?.show_time_stamp)?.toTimeString().split(":")[1];
    setDescription(logData?.notes);
    setDueDate(new Date(logData?.show_time_stamp));
    setDueTime(timeStamp);
    setTaskTitle(logData?.title);
    setPriorityTypeId([logData?.priority]);
    setPriorityType([logData?.priority]);
    setReminderTypeId([logData?.send_reminder]);
    setReminderType(
      logData?.send_reminder === 1 ? [STRINGS.YES] : [STRINGS.NO]
    );
    document.getElementById(id).innerHTML = logData?.notes;
  };

  return (
    <div className={classes.HeadTaskContainer}>
      <Accordion
        className={classes.accrodionRoot}
        onChange={() => setTaskOpen(!taskOpen)}
        expanded={taskOpen}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {" "}
          <div
            className={
              taskOpen
                ? classes.summaryContainerHeadsOpen
                : classes.summaryContainerHeads
            }
          >
            <div className={classes.summaryContainerTask}>
              {showOperation && logData?.pin ? (
                <div className={logData?.pin ? classes.defaultIcon : ""}>
                  <IconComponent
                    iconLabel={logData?.pin ? ICON_LABELS.PUSH_PIN : ""}
                    color={logData?.pin ? PRIMARY.W_400 : ""}
                    fontSize={fontSize.XS}
                  />
                </div>
              ) : (
                ""
              )}
              <div className={classes.summaryRightSection}>
                <IconComponent
                  color={GREY.TERTIARY}
                  fontSize={fontSize.DXS}
                  iconLabel={
                    taskOpen
                      ? ICON_LABELS.ACTION_ARROW
                      : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                  }
                />

                <IconColoredComponent
                  variant={logData?.step_type === 3 ? "green" : "yellow"}
                  iconLabel={
                    logData?.step_type === 3
                      ? ICON_LABELS.CALL
                      : ICON_LABELS.TASK
                  }
                  color={logData?.step_type === 3 ? "green" : "yellow"}
                  size="small"
                />

                <TypographyInline
                  label={
                    logData?.step_type === 3
                      ? STRINGS.CALL_LOGGED
                      : STRINGS.TODO_CREATED
                  }
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={GREY.PRIMARY}
                />
                <TypographyInline
                  color={GREY.SECONDARY}
                  label={`${STRINGS.BY} ${updatedRecord?.first_name || ""} ${updatedRecord?.last_name || ""
                    }${logData?.first_name ? ` ${STRINGS.WITH}` : ""}`}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  isNoWrap
                />
                {logData?.first_name && (
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={
                      `${logData?.first_name || ""} ${logData?.last_name || ""
                      }` || `${logData?.email}`
                    }
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                    isEllipses
                  />
                )}
              </div>

              <div className={classes.dateTimeTaskContainer}>
                <IconComponent
                  iconLabel={ICON_LABELS.ACCESS_TIME}
                  fontSize={fontSize.MD}
                  color={GREY.SECONDARY}
                />
                <TypographyInline
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"sm"}
                  label={dateTimeStringEmailLog(logData?.created_at)}
                />

                {showOperation && (
                  <>
                    <div className={classes.verticalLine} />
                    <div
                      className={classes.moreOptionsIconContianer}
                      onClick={(e) => {
                        e?.stopPropagation();
                      }}
                    >
                      <IconComponent
                        color={GREY.TERTIARY}
                        fontSize={fontSize.MD}
                        iconLabel={ICON_LABELS.MORE_VERT}
                        onClick={handleModal}
                      />

                      <CustomMenu
                        items={tableOperations}
                        anchor={actionMenuAnchor}
                        open={isActionMenuVisible}
                        onClose={() => {
                          setIsActionMenuVisible(false);
                          setActionMenuAnchor(null);
                        }}
                        paperMenuClass={classes.dropdownMenuListing}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              onMouseEnter={taskOpen ? () => handleHover() : () => { }}
              onMouseLeave={taskOpen ? handleHoverLeave : () => { }}
              className={
                ChangeField && taskOpen
                  ? classes.CallTodesignBorderchangeOpen
                  : taskOpen
                    ? classes.CallTodesignBorderOpen
                    : classes.CallTodesignBorder
              }
            >
              {logData?.step_type === 3 ? (
                ""
              ) : (
                <IconComponent
                  color={
                    changeIcon || logData?.status == true
                      ? SUCCESS.W_400
                      : BORDER.DARK
                  }
                  fontSize={fontSize.DXS}
                  iconLabel={
                    changeIcon || logData?.status == true
                      ? ICON_LABELS?.CHECK_CIRCLE
                      : ICON_LABELS.RADIO_BUTTON_UNCHECKED
                  }
                  onClick={(e) => {
                    e?.stopPropagation();

                    handleConfirmStatus();
                  }}
                />
              )}
              <div
                className={classes.WIDTh}
                onClick={(e) => {
                  setChangeField(true);
                }}
              >
                <div
                  onClick={(e) => {
                    e?.stopPropagation();
                  }}
                >
                  {" "}
                  {ChangeField && taskOpen ? (
                    <InputField
                      size={"sm36"}
                      value={taskTitle}
                      onChange={(e) => {
                        e?.stopPropagation();
                        setTaskTitle(e?.target?.value);
                        setVisible(true);
                      }}
                    />
                  ) : (
                    <TypographyInline
                      label={`${taskTitle}` || `${logData?.title}`}
                      size={"sm"}
                      fontWeight={fontWeight.SEMIBOLD}
                      color={GREY.PRIMARY}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.taskDescriptionLabel}>
            <div className={classes.companyDetailMainContainer}>
              <div className={classes.taskDetailsContainer}>
                <div>
                  {" "}
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.PRIORITY_FCAPS}
                    isEllipses
                  />
                  <AutoCompleteWoBorder
                    size={"sm36"}
                    setValueState={setPriorityType}
                    onChange={() => {
                      setVisible(true);
                    }}
                    id="priority"
                    items={STEPS_PRIORITY_FIELDS}
                    LeftIcon=""
                    placeholder={STRINGS.SET_PRIORITY}
                    valueState={priorityType}
                    idState={priorityTypeId}
                    setIdState={setPriorityTypeId}
                  />
                </div>
                <div className={classes.verticalLine} />
                <div>
                  {" "}
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.DUE_DATE}
                    isEllipses
                  />
                  <CustomDatePickerWoBorder
                    disablePast={true}
                    value={dueDate}
                    onChange={(newValue) => {
                      setDueDate(newValue?.$d);
                      setVisible(true);
                    }}
                    size={"sm36"}
                  />
                </div>
                <div className={classes.verticalLine} />
                <div>
                  {" "}
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.TIME}
                    isEllipses
                  />
                  <TimeSelector
                    time={dueTime}
                    setTime={(time) => {
                      setDueTime(time);
                      setVisible(true);
                    }}
                    removeBorder
                  />
                </div>{" "}
                <div className={classes.verticalLine} />
                <div>
                  {" "}
                  <TypographyInline
                    color={GREY.SECONDARY}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.SEND_REMINDER_CAPS}
                    isEllipses
                  />
                  <AutoCompleteWoBorder
                    size={"sm36"}
                    setValueState={setReminderType}
                    onChange={() => {
                      setVisible(true);
                    }}
                    items={SEND_REMINDER}
                    LeftIcon=""
                    placeholder={STRINGS.SET_REMINDER}
                    valueState={reminderType}
                    idState={reminderTypeId}
                    setIdState={setReminderTypeId}
                  />
                </div>
              </div>

              {logData?.notes ? (
                <div
                  className={`${classes.logEmailListSummaryLowerContainer} ${notesClicked ? classes.notesBackground : ""
                    }`}
                  onClick={() => {
                    setNotesClicked(true);
                  }}
                  onBlur={() => {
                    setNotesClicked(false);
                  }}
                >
                  <div>
                    <TypographyInline
                      color={GREY.PRIMARY}
                      label={STRINGS.DESCRIPTION}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                    />
                  </div>
                  {editorView ? (
                    <div className={classes.htmlEditor}>
                      <HtmlEditor
                        isAddTagNotVisible={true}
                        isMeetingTagNotVisible={true}
                        isAITagNotVisible
                        isFocusNotRequired
                        id={id}
                        previosData={logData?.notes}
                        onInput={(event) => {
                          setDescription(event?.target?.innerHTML);
                          setVisible(true);
                        }}
                        editorHeight={"8.625vw"}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => setEditorView(true)}
                      className={classes.parsedEmailLogData}
                    >
                      {parse(logData?.notes)}
                      <br />
                    </div>
                  )}
                  {visible ? (
                    <div className={classes.aboutFooter}>
                      <div>
                        <Button
                          label={STRINGS.CANCEL}
                          size={"sm36"}
                          variant={"ghost"}
                          onClick={() => {
                            handleResetContDetails();
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          label={STRINGS.SAVE}
                          size={"sm36"}
                          variant={"primary"}
                          onClick={() => {
                            handleTaskEditApi();
                          }}
                          isLoading={loader}
                          disabled={showOperation ? false : true}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : visible ? (
                <div className={classes.aboutFootervisible}>
                  <div>
                    <Button
                      label={STRINGS.CANCEL}
                      size={"sm36"}
                      variant={"ghost"}
                      onClick={() => {
                        handleResetContDetails();
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      label={STRINGS.SAVE}
                      size={"sm36"}
                      variant={"primary"}
                      onClick={() => {
                        handleTaskEditApi();
                      }}
                      isLoading={loader}
                      disabled={showOperation ? false : true}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* detail comment section */}
          </div>
          {showOperation && (
            <div
              className={
                logData?.notes
                  ? classes.taskLogListCommentContainer
                  : classes.taskLogListCommentContainer
              }
            >
              <AddComment
                createCommentHandler={createCommentHandler}
                value={commentBody}
                setValue={setCommentBody}
                isLoading={createCommentLoading}
                commentsList={taskLogComments}
                onScrollHandler={() =>
                  setLogTaskCommentsPage(taskLogCommentsPage + 1)
                }
                updateCommentHandler={updateTaskLogCommentHandler}
                deleteModalHandler={deleteTaskLogCommentModalHandler}
              />

              <DeleteCommentModal
                open={deleteCommentModalOpen}
                close={() => {
                  setDeleteCommentModalOpen(false);
                }}
                clickedData={clickedCommentData}
                deleteButtonLoading={isDeleteCommentLoading}
                deleteAPIHandler={deleteTaskLogCommentHandler}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      {openTask && (
        <TaskDeleteModal
          id={logData?._id}
          openTask={openTask}
          setOpenTask={setOpenTask}
          contactId={contact_id}
          setTaskListData={setTaskListData}
        />
      )}
    </div>
  );
};

export default ListingTaskCard;
