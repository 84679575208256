import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { addTagStyleInHtml, decompressString } from "../../../../utils/helpers";
import classes from "../markComponents.module.css";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { fontWeight } from "../../../../utils/constants/UI";
import { STRINGS } from "../strings";
import { BRAND, GREY } from "../../../../utils/constants/colors";

const CallStep = ({ data }) => {
  const [bodyContent, setBodyContent] = useState("");
  const [isMoreButton, setIsMoreButton] = useState(false);
  const [isMore, setIsMore] = useState(true);
  useEffect(() => {
    let value = data?.is_compressed ? decodeURIComponent(decompressString(data?.notes)) : data?.notes
    setBodyContent(value?.slice(0, 300));

    if (value?.length > 300) {
      setIsMoreButton(!isMoreButton);
    }
  }, []);
  const bodyContentHandler = () => {
    let value = data?.is_compressed ? decodeURIComponent(decompressString(data?.notes)) : data?.notes
    isMore
      ? setBodyContent(value?.slice(0, value?.length))
      : setBodyContent(value?.slice(0, 300));
    setIsMore(!isMore);
  };
  return (
    <Box className={classes.stepBody}>
      <Box className={classes.eachStepDetail}>
        <div className={classes.eachStepDetailKey}>
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.TITLE2}
          />
        </div>
        <TypographyInline
          size={"sm"}
          color={GREY.PRIMARY}
          fontWeight={fontWeight.MEDIUM}
          label={data?.title}
        />
      </Box>
      <Box>
        <div className={classes.eachStepDetailKey}>
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.NOTES2}
          />
        </div>
        <div className={classes.notesText}>
          {parse(addTagStyleInHtml(bodyContent))}
        </div>
        {isMoreButton && (
          <TypographyInline
            className={classes.showMoreBtn}
            onClick={bodyContentHandler}
            label={isMore ? STRINGS.VIEW_MORE : STRINGS.VIEW_LESS}
            color={BRAND.PRIMARY}
            size={"sm"}
          />

        )}
      </Box>

    </Box>
  );
};

export default CallStep;
