import React, { useEffect, useState } from "react";
import { EMAIL_ORANGE_BACKGROUND_IMAGE } from "../../../utils/constants/assets";
import { STRINGS } from "../strings";
import classes from "./Deals.module.css";
import { BRAND } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { Button } from "../../../components/Buttons";
import TypographyInline from "../../../components/Typography/TypographyInline";
import ContactDetailEmptyScreen from "../Contacts/components/ContactDetailEmptyScreen";
import EmailLogList from "./components/EmailLogList";
import EmailSentList from "./components/EmailSentList";
import LogEmailModal from "./components/LogEmailModal";
import EmailSendModal from "./components/EmailSendModal";
import SkeletonLoaderCRMScreens from "../Contacts/components/SkeletonLoaderCRMScreens";

const EmailScreen = ({ dealDetails, dealId, getEmailLogList = () => { }, screenLoading = false, totalEmails = 0, emailLogListData = [] }) => {
  const [openLogEmailModal, setOpenLogEmailModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [clickedEmail, setClickedEmail] = useState({});
  const [replyType, setReplyType] = useState("");

  const emptyScreenButtons = [
    {
      id: STRINGS.LOG_EMAIL_SMALL,
      label: STRINGS.LOG_EMAIL,
      variant: "ghost",
      disabled: !Object.keys(dealDetails)?.length,
      onClick: () => {
        setOpenLogEmailModal(true);
      },
    },
    {
      id: STRINGS.SEND_EMAIL_SMALL,
      label: STRINGS.COMPOSE_EMAIL,
      variant: "primary",
      disabled: !Object.keys(dealDetails)?.length,

      onClick: () => {
        setOpenSendEmailModal(true);
      },
    },
  ];

  const closeLogEmailModal = () => {
    setOpenLogEmailModal(false);
  };

  const closeSendEmailModal = () => {
    setOpenSendEmailModal(false);
    setClickedEmail({});
  };

  const onScrolllEmailListHandler = () => {
    const scrollableDiv = document.getElementsByClassName("allEmailList")[0];
    const isAtBottom =
      scrollableDiv.scrollTop >=
      scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
    if (isAtBottom && totalEmails > emailLogListData?.length) {
      setPageCount(pageCount + 1);
      getEmailLogList(true, pageCount + 1);
    }
  };

  useEffect(() => {
    if (dealDetails?.deal_name?.length) {
      getEmailLogList();
    }
  }, [dealDetails]);

  return (
    <div
      id="modal_body"
      className={`${classes.emailScreenMainContainer} allEmailList`}
      onScroll={onScrolllEmailListHandler}
    >
      {/* Loader of Email Screen */}
      {screenLoading ? (
        <SkeletonLoaderCRMScreens
          loaderHeight="42px"
          accordionLengths={10}
          buttonsLength={2}
        />
      ) : (
        <>
          {/* Email Log Data Length Check */}
          {emailLogListData?.length ? (
            <div className={classes.emailLogListContainer}>
              <div className={classes.labelButtonContainer}>
                <div>
                  <TypographyInline
                    color={BRAND.SECONDARY}
                    label={STRINGS.ALL_EMAILS}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
                <div className={classes.emailLogButtonContainer}>
                  <Button
                    label={STRINGS.LOG_EMAIL}
                    size={"sm36"}
                    variant={"ghost"}
                    onClick={() => setOpenLogEmailModal(true)}
                  />
                  <Button
                    label={STRINGS.COMPOSE_EMAIL}
                    size={"sm36"}
                    variant={"primary"}
                    onClick={() => {
                      setOpenSendEmailModal(true);
                    }}
                  />
                </div>
              </div>
              {emailLogListData?.map((logData, index) => {
                return logData?.type === "log" ? (
                  <div key={`log${index}`}>
                    <EmailLogList
                      id={`log${index}`}
                      logData={logData}
                      dealDetails={dealDetails}
                      getEmailLogList={getEmailLogList}
                      expanded={!index}
                    />
                  </div>
                ) : (
                  <EmailSentList
                    id={`log${index}`}
                    emailData={logData}
                    dealDetails={dealDetails}
                    getEmailLogList={getEmailLogList}
                    expanded={!index}
                    openSendEmailModal={openSendEmailModal}
                    setOpenSendEmailModal={setOpenSendEmailModal}
                    setClickedEmail={setClickedEmail}
                    setReplyType={setReplyType}
                  />
                );
              })}
            </div>
          ) : (
            // Email Log Empty Screen.
            <div className={classes.emptyEmailDetailMainContainer}>
              <ContactDetailEmptyScreen
                src={EMAIL_ORANGE_BACKGROUND_IMAGE}
                label={STRINGS.EMAIL_SCREEN_EMPTY_DESC}
                buttonItems={emptyScreenButtons}
              />
            </div>
          )}
        </>
      )}

      {/* Create Log Email Modal */}
      <LogEmailModal
        open={openLogEmailModal}
        close={closeLogEmailModal}
        dealDetails={dealDetails}
        dealId={dealId}
        getEmailLogList={getEmailLogList}
      />

      {/* Create/Send Email Modal */}
      {openSendEmailModal && (
        <EmailSendModal
          open={openSendEmailModal}
          close={closeSendEmailModal}
          dealDetails={dealDetails}
          dealId={dealId}
          getEmails={getEmailLogList}
          data={clickedEmail}
          setPageCount={setPageCount}
          replyType={replyType}
        />
      )}
    </div>
  );
};

export default EmailScreen;
