import { Box } from "@mui/system";
import { WIDGET_BOX_KEY_VALUES } from "../../../utils/constants";
import { makeStyles } from "@mui/styles";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
const styles = makeStyles({
  boxwidget: {
    display: "grid",
    gridTemplateColumns:"1fr 1fr 1fr",
    // justifyContent: "left",
    // alignItems: "left",
    // flexWrap: "wrap",
    rowGap: "50px",
    borderLeft: "1px solid #D3DAE3",
    padding: "24px",
  },
  mainBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    // width: "145px",
   
  },
  subBox1: {
    display: "flex",
    justifyContent: "space-around",
  },
  subBox2: {
    display: "flex",
    justifyContent: "space-around",
  },
  boxWidgetMainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0px 24px",
  },
  totalEnrolledContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    marginRight: "24px",
  },
  totalEnrolledValue: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "36px",
    color: "#212833",
  },
  totalEnrolledId: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#626B7E",
  },
  dataValue: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29px",
  },
});
export const BoxWidget = (props) => {
  const classes = styles();
  return (
    <Box className={classes.boxWidgetMainContainer}>
      <Box className={classes.totalEnrolledContainer}>
        <Box className={classes.totalEnrolledValue}>
          {props?.dataSet[0]?.value}
        </Box>
        <Box className={classes.totalEnrolledId}>
          <TypographyInline
            label={"Total Enrolled"}
            size={"xl"}
            fontWeight={fontWeight.SEMIBOLD}
            color={GREY.PRIMARY}
          />

        </Box>
      </Box>
      <Box className={classes.boxwidget}>
        {props?.dataSet?.map((data, i) => {
          return (
            <>
              {data.id !== "total_enrolled" && (
                <Box key={i} className={classes.mainBox}>
                  <Box className={classes.subBox1} sx={{ color: data.color,}}>
                    <Box className={classes.dataValue}>{data.value || 0}</Box>
                  </Box>
                  <Box className={classes.subBox2}>
                    {WIDGET_BOX_KEY_VALUES[data.id]}
                  </Box>
                </Box>
              )}
            </>
          );
        })}
      </Box>
    </Box>
  );
};
