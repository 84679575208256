import React, { useEffect, useState } from "react";
import classes from "./TimeScheduler.module.css";
import { Box } from "@mui/material";
import TypographyInline from "../Typography/TypographyInline";
import { GREY, ERROR } from "../../utils/constants/colors";
import { STRINGS } from "./strings";
import { fontWeight , ICON_LABELS , fontSize} from "../../utils/constants/UI";
import {
    initialTimings,
    MINUTES_LIST,
    TIME_TYPE,
    CAMPAIGN_CREATE_DAYS_LIST,
    TIME_LIST,
    WEEK_DAYS_ID
} from "../../utils/constants";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import { createTimingsForPayload , validationErrorMessage } from "../../utils/helpers";
import { AutoCompleteCustom } from "../Autocomplete/AutoCompleteCustom";
import IconComponent from "../Icon/IconComponent";
import CustomTooltip from "../Tooltip/CustomTooltip";
const TimeScheduler = ({

    payload,
    getData = () => { },
    
}) => {

    const [timings, setTimings] = useState(
        payload ||
        [...initialTimings]
    );

    useEffect(() => {
        getData(timings);
    }, [timings]);

    useEffect(() => {       
            setTimings((payload) ||  [...initialTimings]);
            
        },

     [payload])

     
    const handleDaysSelection = (index) => {
        const selectedTiming = timings?.filter((timing) => timing?.day === index);
        if (selectedTiming?.length) {
            const newObj = JSON.parse(JSON.stringify(selectedTiming[0]));
            newObj.checked = !newObj.checked;
            const newTimings = [
                ...timings?.filter((timing) => timing?.day !== index),
                newObj,
            ];
            setTimings(newTimings);

        }
    };

    const handleStartTimeChange = (e, index) => {
        const newTimings = JSON.parse(JSON.stringify(timings));
        const objIndex = newTimings?.findIndex((obj) => obj.day === index);
        newTimings[objIndex].startTime = parseInt(e?.label);
        const newTime = updateTimingsWithMessages(newTimings);
        setTimings(newTime);


    };
    const handleStartMinuteChange = (e, index) => {
        const newTimings = JSON.parse(JSON.stringify(timings));
        const objIndex = newTimings?.findIndex((obj) => obj.day === index);
        newTimings[objIndex].startMinute = parseInt(e?.label);
        const newTime = updateTimingsWithMessages(newTimings);
        setTimings(newTime);

    };

    const handleEndMinuteChange = (e, index) => {
        const newTimings = JSON.parse(JSON.stringify(timings));
        const objIndex = newTimings?.findIndex((obj) => obj.day === index);
        newTimings[objIndex].endMinute = parseInt(e?.label);
        const newTime = updateTimingsWithMessages(newTimings);
        setTimings(newTime);

    };

    const handleEndTimeChange = (e, index) => {
        const newTimings = JSON.parse(JSON.stringify(timings));
        const objIndex = newTimings?.findIndex((obj) => obj.day === index);
        newTimings[objIndex].endTime = parseInt(e?.label);
        const newTime = updateTimingsWithMessages(newTimings);
        setTimings(newTime);

    };

    const handleTimingTypeChange = (e, type, index) => {
        const newTimings = JSON.parse(JSON.stringify(timings));
        const objIndex = newTimings?.findIndex((obj) => obj.day === index);
        newTimings[objIndex][type] = e?.label?.[0];
        const newTime = updateTimingsWithMessages(newTimings);
        setTimings(newTime);

    };

    const updateTimingsWithMessages = (timings) => {
        const changedTimings = createTimingsForPayload(timings);
        const validTimings = validationErrorMessage(changedTimings); 
        const addMessage = {};
        validTimings?.forEach((item) => {
          addMessage[WEEK_DAYS_ID[item.day]] = item.message;
        });
        
        timings?.forEach((item) => {
          if (timings.hasOwnProperty(item.day)) {
            item.message = addMessage[item.day];
          }
        });
        return timings;
      };
      
    return (
        <Box >
            <Box className={classes.mainContainer} >
                <Box className={classes.header}>
                    <TypographyInline
                        size={"xs"}
                        isEllipses={true}
                        color={GREY.PRIMARY}
                        fontWeight={fontWeight.MEDIUM}
                        label={STRINGS.DAYS}
                    />
                    <Box className={classes.headerPadding}>

                        <TypographyInline
                            isEllipses={true}
                            size={"xs"}
                            color={GREY.PRIMARY}
                            fontWeight={fontWeight.MEDIUM}
                            label={STRINGS.START_TIME}
                        />
                    </Box>
                    <Box className={classes.headerPadding}>

                        <TypographyInline
                            size={"xs"}
                            isEllipses={true}
                            color={GREY.PRIMARY}
                            fontWeight={fontWeight.MEDIUM}
                            label={STRINGS.END_TIME}
                        />
                    </Box>
                </Box>
                <Box className={classes.WeekDays}>
                    {CAMPAIGN_CREATE_DAYS_LIST?.map((eachDay, index) => {
                        return (
                            <Box
                                key={eachDay.id}
                                className={classes.containDates2}
                            >
                                <Box className={classes.containEach}>
                                    <Checkbox
                                        noMargin={true}
                                        checked={timings?.filter
                                            ((timing) => timing.day === index)?.[0]?.checked}
                                        onClick={() => handleDaysSelection(index)}
                                    />
                                    <TypographyInline
                                        size={"sm"}
                                        color={GREY.SECONDARY}
                                        fontWeight={fontWeight.SEMIBOLD}
                                        isCapitalise={true}
                                        label={eachDay.day}
                                    />
                                </Box>

                                <Box className={classes.containEach2}>


                                    <AutoCompleteCustom

                                        size={"sm36"}
                                        LeftIcon=""
                                        items={TIME_LIST}
                                        valueState={timings?.[
                                            timings?.findIndex((obj) => obj.day === index)
                                        ]?.startTime?.toString()}
                                        onChange={(e) => handleStartTimeChange(e, index)}
                                        variant={
                                            (!timings?.filter(
                                                (timing) => timing.day === index
                                            )?.[0].checked
                                                ? "disabled"
                                                :  timings?.filter(
                                                    (timing) => timing.day === index
                                                )?.[0].message?.length > 0 
                                                    ? "error"
                                                    : "default")
                                        }
                                        dropdownId="asdf"
                                    />

                                    <AutoCompleteCustom
                                        size={"sm36"}
                                        LeftIcon=""
                                        items={MINUTES_LIST}
                                        valueState={timings?.[
                                            timings?.findIndex((obj) => obj.day === index)
                                        ]?.startMinute?.toString()}
                                        onChange={(e) => handleStartMinuteChange(e, index)}
                                        variant={
                                            (!timings?.filter(
                                                (timing) => timing.day === index
                                            )?.[0].checked
                                                ? "disabled"
                                                :  timings?.filter(
                                                    (timing) => timing.day === index
                                                )?.[0].message?.length > 0 
                                                    ? "error"
                                                    : "default")
                                        }
                                        dropdownId="asdf"
                                    />
                                    <AutoCompleteCustom
                                        size={"sm36"}
                                        LeftIcon=""
                                        items={TIME_TYPE}
                                        valueState={timings?.[
                                            timings?.findIndex((obj) => obj.day === index)
                                        ]?.startType}
                                        onChange={(e) =>
                                            handleTimingTypeChange(
                                                e,
                                                STRINGS.START_TYPE,
                                                index
                                            )}
                                        paperHeight={"fit-content"}
                                        variant={
                                            (!timings?.filter(
                                                (timing) => timing.day === index
                                            )?.[0].checked
                                                ? "disabled"
                                                :  timings?.filter(
                                                    (timing) => timing.day === index
                                                )?.[0].message?.length > 0 
                                                    ? "error"
                                                    : "default")
                                        }
                                        dropdownId="asdf"
                                    />







                                </Box>

                                <Box className={classes.containEach2}>
                                    <AutoCompleteCustom
                                        size={"sm36"}
                                        LeftIcon=""
                                        items={TIME_LIST}
                                        valueState={timings?.[
                                            timings?.findIndex((obj) => obj.day === index)
                                        ]?.endTime?.toString()}
                                        onChange={(e) => handleEndTimeChange(e, index)}
                                        variant={
                                            (!timings?.filter(
                                                (timing) => timing.day === index
                                            )?.[0].checked
                                                ? "disabled"
                                                :  timings?.filter(
                                                    (timing) => timing.day === index
                                                )?.[0].message?.length > 0 
                                                    ? "error"
                                                    : "default")
                                        }
                                        dropdownId="asdf"
                                    />

                                    <AutoCompleteCustom
                                        size={"sm36"}
                                        LeftIcon=""
                                        items={MINUTES_LIST}
                                        valueState={timings?.[
                                            timings?.findIndex((obj) => obj.day === index)
                                        ]?.endMinute?.toString()}
                                        onChange={(e) => handleEndMinuteChange(e, index)}
                                        variant={
                                            (!timings?.filter(
                                                (timing) => timing.day === index
                                            )?.[0].checked
                                                ? "disabled"
                                                :  timings?.filter(
                                                    (timing) => timing.day === index
                                                )?.[0].message?.length > 0 
                                                    ? "error"
                                                    : "default")
                                        }
                                        dropdownId="asdf"
                                    />
                                    <AutoCompleteCustom
                                        size={"sm36"}
                                        LeftIcon=""
                                        items={TIME_TYPE}
                                        valueState={timings?.[
                                            timings?.findIndex((obj) => obj.day === index)
                                        ]?.endType}
                                        onChange={(e) =>
                                            handleTimingTypeChange(
                                                e,
                                                STRINGS.END_TYPE,
                                                index
                                            )}
                                        paperHeight={"fit-content"}

                                        variant={
                                            (!timings?.filter(
                                                (timing) => timing.day === index
                                            )?.[0].checked
                                                ? "disabled"
                                                :  timings?.filter(
                                                    (timing) => timing.day === index
                                                )?.[0].message?.length > 0 
                                                    ? "error"
                                                    : "default")
                                        }
                                        dropdownId="asdf"
                                    />

                                    
                                </Box>
                                <Box className={classes.infoBtn}>
                                        {
                                            timings?.filter(
                                                (timing) => timing.day === index
                                            )?.[0].message?.length > 0 ?
                                            <CustomTooltip
                                            title={
                                                timings?.[
                                                    timings?.findIndex((obj) => obj.day === index)
                                                ]?.message
                                              }

                                            >
                                            <Box><IconComponent
                                            iconLabel={ICON_LABELS.ERROR}
                                            color={ERROR.W_500}
                                            fontSize={fontSize.DSM}/></Box>


                                        </CustomTooltip>
                                               
                                               
                                                : ""
                                        }
                                    </Box>
                            </Box>



                        );
                    })}
                </Box>

            </Box>
        </Box>
    );
};

export default TimeScheduler;