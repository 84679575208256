import { getRequest } from "../../Apis";
import { meetingsEndpoint } from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";
import {
  EDITOR_SUCCESS,
  MEETING_LINK_FAIL,
  MEETING_LINK_REQUEST,
  MEETING_LINK_SUCCESS,
} from "../constants/editorConstants";

export const editAction = (value, view = "text") => async (dispatch) => {
  dispatch({
    type: EDITOR_SUCCESS,
    payload: value,
    view: view
  });
};

export const getMeetingLinkAction = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: MEETING_LINK_REQUEST });
    const response = await getRequest(
      navigate,
      meetingsEndpoint.allMeetingLink
    );
    dispatch({ type: MEETING_LINK_SUCCESS, payload: response?.data?.data });
  } catch (error) {
    if (error?.status === 401) {
      navigate(LOGIN_PATH);
    }
    dispatch({ type: MEETING_LINK_FAIL, payload: error });
  }
};
