import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../../strings";
import classes from "./CrmComp.module.css";
import { GREY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS } from "../../../../utils/constants/styles";
import { getRequest, patchRequest } from "../../../../Apis/apiWrapper";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconComponent from "../../../../components/Icon/IconComponent";
import { useSelector } from "react-redux";
import InputFieldWoBorder from "../../../../components/InputFields/InputFieldWoBorder";
import { replaceKeyInArrayOfObj } from "../../../../utils/helpers";
import { AutoCompleteWoBorder } from "../../../../components/Autocomplete/AutoCompleteWoBorder";
import { HtmlEditor } from "../../../../components";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import TextArea from "../../../../components/InputFields/TextArea";
import {
  DEFAULT_CONTACT_FIELD_ORDER,
  EMITTER_SUCCESS,
} from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import ResetPropertyActionModal from "./ResetPropertyActionModal";
import { PROFILE_PATH } from "../../../../utils/constants/routes";

const PropertyActionModal = ({
  open,
  close,
  firstName,
  lastName,
  contactId,
  getFieldsOrder = () => {},
}) => {
  const [loading, setLoading] = useState(true);
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );
  const [inputData, setInputData] = useState({});
  const [inputDataId, setInputDataId] = useState({});
  const [currentFocus, setCurrentFocus] = useState("");
  const [subject, setSubject] = useState("");
  const [loader, setLoader] = useState(false);
  const params = useParams();

  // const [list, setList] = useState(getPropertiesListSelector?.data?.hits || []);
  const [list, setList] = useState([]);
  const listed = getPropertiesListSelector?.data?.hits || [];

  const [resetList, setResetList] = useState([]);
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();

  const getContactDetails = async () => {
    try {
      let id = params?.id;
      const response = await getRequest(
        navigate,
        `${crmEndpoints.getContactDetails}/${id}`
      );

      setInputData(response?.data?.data);
      setInputDataId(response?.data?.data);
    } catch (error) {
      console.log("Error ----------------> ", error);
    }
  };

  const createOrderedListByOrder = (items_key_pair, order) => {
    const ordered_list = [];
    for (let i in order) {
      ordered_list.push(items_key_pair[order[i]]);
    }
    return ordered_list;
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getRequest(
          navigate,
          `${crmEndpoints.getColumns}?type=contact`
        );
        setResetList(response?.data?.data);
        setList(
          createOrderedListByOrder(
            getPropertiesListSelector?.data?.properties_key_value,
            response?.data?.data?.table_columns
          )
        );
      } catch (error) {
        setList(
          createOrderedListByOrder(
            getPropertiesListSelector?.data?.properties_key_value,
            DEFAULT_CONTACT_FIELD_ORDER
          )
        );
      } finally {
        setLoading(false);
      }
    })();

    (async () => {
      await getContactDetails();
    })();
  }, [navigate]);

  const handleSaveMethod = async () => {
    const payload = {
      id: contactId,
      table_columns: (list?.map((item) => item?.id) || []).filter(
        (item) => item
      ),
      type: "contact",
    };

    // return
    setLoader(true);

    const response = await patchRequest(
      navigate,
      crmEndpoints.editPropertyAction,
      payload
    );
    getFieldsOrder();
    setLoader(false);
    EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    close(!open);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    display: "flex",
    ...draggableStyle,
  });

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(list);

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setList(items);
  };

  const handleManageProperties = () => {
    navigate(`${PROFILE_PATH}?tab=Properties`);
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.SMALL_HEAD,
      label: `Manage properties for ${firstName || ""} ${lastName || ""}`,
    },
  };

  const type_id_components = (prop) => {
    switch (prop?.field_type[0]) {
      case "Date Picker":
        return (
          <CustomDatePicker
            size={"sm36"}
            value={inputData[prop?.id] || new Date()}
            onChange={(dateValue) => {
              setInputData({ ...inputData, [prop?.id]: dateValue });
            }}
          />
        );

      case "Number":
        return (
          <InputFieldWoBorder
            size={"sm36"}
            type={"number"}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            value={inputData[prop?.id] || ""}
            placeholder={STRINGS.NUMBER_PLACEHOLDER}
          />
        );

      case "Multi-line Text Input":
        return (
          <TextArea
            rows={"5"}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            value={inputData[prop?.id] || ""}
          />
        );

      case "Rich Text Editor":
        return (
          <div className={classes.htmlEditor}>
            <HtmlEditor
              setCurrentFocus={setCurrentFocus}
              currentFocus={currentFocus}
              subject={subject}
              setSubject={setSubject}
              isAddTagNotVisible
              isMeetingTagNotVisible
              editorHeight={"12vh"}
              previosData={inputData[prop?.id] || ""}
              onInput={() => {
                setInputData({
                  ...inputData,
                  [prop?.id]: document?.getElementById("editor")?.innerHTML,
                });
              }}
              isAITagNotVisible
              isFocusNotRequired
            />
          </div>
        );

      case "Single Select":
        return (
          <AutoCompleteWoBorder
            dropdownId="preview_dropdown"
            items={replaceKeyInArrayOfObj(prop?.value, "labelValue", "label")}
            size={"sm36"}
            valueState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputDataId[prop?.id]]
                : [inputData[prop?.id]]
            }
            setValueState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputDataId({
                    ...inputDataId,
                    [prop?.id]: data[0],
                  })
                : setInputData({
                    ...inputData,
                    [prop?.id]: data[0],
                  });
            }}
            idState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputData[prop?.id]]
                : [inputDataId[prop?.id]]
            }
            setIdState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputData({
                    ...inputData,
                    [prop?.id]: data[0],
                  })
                : setInputDataId({
                    ...inputDataId,
                    [prop?.id]: data[0],
                  });
            }}
          />
        );

      case "Multiple Checkbox":
        return (
          <AutoCompleteWoBorder
            dropdownId="preview_dropdown"
            items={replaceKeyInArrayOfObj(prop?.value, "labelValue", "label")}
            size={"sm36"}
            multiple
            valueState={inputData[prop?.id]}
            setValueState={(data) => {
              setInputData({
                ...inputData,
                [prop?.id]: data,
              });
            }}
            idState={inputDataId[prop?.id]}
            setIdState={(data) => {
              setInputDataId({
                ...inputDataId,
                [prop?.id]: data,
              });
            }}
          />
        );

      default:
        return (
          <InputFieldWoBorder
            size={"sm36"}
            placeholder={STRINGS.ENTER_INPUT_VALUE}
            value={inputData[prop?.id] || ""}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            variant={prop.id === "email" ? "disabled" : "default"}
          />
        );
    }
  };

  const handleRemoveColumn = async (index) => {
    const updates = list?.filter((each, idx) => idx !== index);
    setList(updates);
  };

  const handleChangeInAll = (column) => {
    !list?.map((item) => item?.id)?.includes(column.id) &&
      setList([...list, column]);
  };

  const ResetMethod = () => {
    setReset(true);
  };

  const modalBodyData = (
    <div>
      <div className={classes.detailLayoutContainerProprty}>
        <div
          id="modal_body"
          className={classes.contactDetailLeftContainerProperty}
        >
          <TypographyInline
            label={"About"}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.PRIMARY}
          />
          <div className={classes.paddingaction}>
            <TypographyInline
              label={
                "These properties will appear when you view information about a deal. These changes will only affect you."
              }
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.SECONDARY}
            />
          </div>
          <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId={STRINGS.COLUMN_LIST}>
                {(provided, snapshot) => (
                  <div
                    className={classes.draggableMainDiv}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {list?.map((column, index) => {
                      return (
                        <Draggable
                          key={column?.id || column}
                          draggableId={column?.id || column}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              style={
                                (getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                ),
                                { display: "flex" })
                              }
                              className={classes.draggableDiv}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className={classes.dragLabelContainer}>
                                <div className={classes.dragClass}>
                                  <IconComponent
                                    color={GREY.SECONDARY}
                                    fontSize={fontSize.MD}
                                    iconLabel={ICON_LABELS.DRAG_INDICATOR}
                                  />
                                </div>
                                <TypographyInline
                                  label={column?.property_name || column}
                                  size="sm"
                                  fontWeight={fontWeight.REGULAR}
                                  color={GREY.SECONDARY}
                                />
                              </div>
                              <div
                                className={
                                  column?.id !== STRINGS.EMAIL.toLowerCase()
                                    ? classes.crossIcon
                                    : classes.crossIconDisable
                                }
                                onClick={() =>
                                  column?.id !== STRINGS.EMAIL.toLowerCase() &&
                                  handleRemoveColumn(index)
                                }
                              >
                                <IconComponent
                                  color={GREY.SECONDARY}
                                  fontSize={fontSize.MD}
                                  iconLabel={ICON_LABELS.CLOSE}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <div
          id="modal_body"
          className={classes.contactDetailContainerHeadProperty}
        >
          {" "}
          <div className={classes.contactDetailContainerSubHead}>
            <TypographyInline
              label={"All Properties"}
              size={"LG"}
              fontWeight={fontWeight.BOLD}
              color={COLORS.BLACK}
            />
            <div>
              <Button
                onClick={handleManageProperties}
                RightIcon={ICON_LABELS.KEYBOARD_ARROW_RIGHT}
                label={"Manage Properties"}
                variant={"ghost"}
                size={"sm36"}
              />
            </div>
          </div>
          <div className={classes.propertyACtion}>
            {listed
              ?.sort(
                (obj1, obj2) => Number(obj1.operation) - Number(obj2.operation)
              )
              ?.map((prop) => {
                return (
                  prop?.object_type[0] === STRINGS.CONTACT && (
                    <div className={classes.inputLabelField}>
                      <div className={classes.labelButtonContainerAction}>
                        <TypographyInline
                          label={prop?.property_name}
                          color={GREY.QUATINARY}
                          size={"sm"}
                          fontWeight={fontWeight.MEDIUM}
                          isRequired={prop?.is_required}
                        />
                        <div
                          onClick={() => {
                            handleChangeInAll(prop);
                          }}
                        >
                          <IconComponent
                            color={GREY.TERTIARY}
                            fontSize={fontSize.MD}
                            iconLabel={
                              list?.map((item) => item?.id)?.includes(prop?.id)
                                ? ICON_LABELS.DONE
                                : ICON_LABELS.ADD
                            }
                          />
                        </div>
                      </div>
                      {type_id_components(prop)}
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );

  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => close(!open)}
      />
      <Button
        label={STRINGS.SAVE}
        variant={"primary"}
        size={"sm36"}
        onClick={() => handleSaveMethod()}
        isLoading={loader}
      />
    </div>
  );

  const modalFooterLeftData = (
    <div>
      {resetList?._id && (
        <Button
          LeftIcon={ICON_LABELS.RESTORE}
          label={"Reset to account defaults"}
          variant={"text"}
          size={"sm36"}
          onClick={() => ResetMethod()}
          isLoading={loading}
        />
      )}
    </div>
  );

  return (
    <div>
      {" "}
      <CustomModal
        remove_padding={true}
        width={"59.167vw"}
        close={() => close(!open)}
        open={open}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
      />
      {reset && (
        <ResetPropertyActionModal
          reset={reset}
          setReset={setReset}
          id={resetList?._id}
          close={close}
          open={open}
          action={getFieldsOrder}
        />
      )}
    </div>
  );
};
export default PropertyActionModal;
