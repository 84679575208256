import React, { useState } from "react";
import { Box } from "@mui/system";
import { CustomAutoComplete } from "../Autocomplete";
import { styles } from "./styles";
import { leadsEndpoints } from "../../utils/constants/httpConstants";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  allFilters,
  filterHeaderCompanyCity,
  filterHeaderPersonCity,
} from "../../redux/actions/filterActions";
import { initialFilterPayload } from "../../redux/store/storeConstants";
import TypographyInline from "../Typography/TypographyInline";
import { STRINGS } from "./strings";
import { fontWeight } from "../../utils/constants/UI";
import { BRAND, GREY } from "../../utils/constants/colors";
import Checkbox from "../Buttons/Checkbox/Checkbox";

const FilterTypeLocationCity = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedJobFilter = useSelector(
    (state) => state?.filterHeaderPersonCity?.payload
  );
  const selectedCompanyFilter = useSelector(
    (state) => state?.filterHeaderCompanyCity?.payload
  );
  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);
  const [selectedListFilter, setSelectedListFilter] = useState(
    type === "Company Name" ? "company" : "contact"
  );
  
  const handleListToolTipSelection = (type) => {
    setSelectedListFilter(type);
  };

  const handlePersonCityChange = (item, filter, selector, action) => {
    let payload = {
      ...selector,
      [item]: !selector[item],
      isKnown: false,
      isUnknown: false,
    };

    // clear data if column is empty.
    const unCheckPayload = {};
    if (!payload?.anyOf) {
      unCheckPayload[filter] = [];
    }
    if (!payload?.notAnyOf) {
      unCheckPayload[filter + "_not"] = [];
    }
    // clear data if column is empty.

    dispatch(
      allFilters({
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });

    dispatch(action(payload));
  };

  const handleExistFields = (
    existing_type,
    filter,
    filterKey,
    notFilterKey,
    selector,
    action
  ) => {
    // existing_type = isKnown/ isUnknown
    // filter = job_title/industry/ seniority..

    let payload = {
      ...initialFilterPayload?.payload,
      [existing_type]: !selector[existing_type],
    };

    dispatch(action(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + "_not"]: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };
  
  return (
    <Box sx={styles.container}>

      {type === "Company Name" ? null : (
        <Box sx={styles.containTooltipTitles}>
          <div
            style={
              selectedListFilter === "contact"
                ? styles.selectedTooltipTitle
                : styles.tooltipTitle
            }
            onClick={() => handleListToolTipSelection("contact")}
          >
            <TypographyInline
              label={STRINGS.CONTACT}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={
                selectedListFilter === "contact"
                  ? BRAND.PRIMARY
                  : GREY.SECONDARY
              }
            />
          </div>
          <div
            style={
              selectedListFilter === "company"
                ? styles.selectedTooltipTitle
                : styles.tooltipTitle
            }
            onClick={() => handleListToolTipSelection("company")}
          >
            <TypographyInline
              label={STRINGS.COMPANY}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={
                selectedListFilter === "company"
                  ? BRAND.PRIMARY
                  : GREY.SECONDARY
              }
            />
          </div>
        </Box>
      )}
      {selectedListFilter === "contact" ? (
        <Box sx={styles.containAllFilter}>
          <Box sx={styles.jobTitleHead}>
            <Checkbox
              checked={selectedJobFilter?.anyOf}
              onClick={() =>
                handlePersonCityChange(
                  "anyOf",
                  "person_city",
                  selectedJobFilter,
                  filterHeaderPersonCity
                )
              }
              label={STRINGS.IS_ANY_OF}
            />
          </Box>
          {selectedJobFilter?.anyOf ? (
            <Box component="form">
              <CustomAutoComplete
                endPoint={leadsEndpoints.getLocationCityFilter}
                filter="person_city"
                type=""
              />
            </Box>
          ) : null}
          <Box sx={styles.jobTitleHead}>
            <Checkbox
              checked={selectedJobFilter?.notAnyOf}
              onClick={() =>
                handlePersonCityChange(
                  "notAnyOf",
                  "person_city",
                  selectedJobFilter,
                  filterHeaderPersonCity
                )
              }
              label={STRINGS.IS_NOT_ANY_OF}
            />
          </Box>
          {selectedJobFilter?.notAnyOf ? (
            <Box>
              <CustomAutoComplete
                endPoint={leadsEndpoints.getLocationCityFilter}
                filter="person_city"
                type="_not"
              />
            </Box>
          ) : null}
          <Box sx={styles.jobTitleHead}>
            <Checkbox
              checked={selectedJobFilter?.isKnown}
              onClick={() =>
                handleExistFields(
                  "isKnown",
                  "person_city",
                  "exist_fields",
                  "not_exist_fields",
                  selectedJobFilter,
                  filterHeaderPersonCity
                )
              }
              label={STRINGS.IS_KNOWN}
            />
          </Box>
          <Box sx={styles.jobTitleHead}>
            <Checkbox
              checked={selectedJobFilter?.isUnknown}
              onClick={() =>
                handleExistFields(
                  "isUnknown",
                  "person_city",
                  "not_exist_fields",
                  "exist_fields",
                  selectedJobFilter,
                  filterHeaderPersonCity
                )
              }
              label={STRINGS.IS_UNKNOWN}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={styles.containAllFilter}>
          <Box sx={styles.jobTitleHead}>
            <Checkbox
              checked={selectedCompanyFilter?.anyOf}
              onClick={() =>
                handlePersonCityChange(
                  "anyOf",
                  "company_city",
                  selectedCompanyFilter,
                  filterHeaderCompanyCity
                )
              }
              label={STRINGS.IS_ANY_OF}
            />
          </Box>

          {selectedCompanyFilter?.anyOf ? (
            <Box>
              {" "}
              <CustomAutoComplete
                endPoint={leadsEndpoints.getLocationCityFilter}
                filter="company_city"
                type=""
              />
            </Box>
          ) : null}

          <Box sx={styles.jobTitleHead}>
            <Checkbox
              checked={selectedCompanyFilter?.notAnyOf}
              onClick={() =>
                handlePersonCityChange(
                  "notAnyOf",
                  "company_city",
                  selectedCompanyFilter,
                  filterHeaderCompanyCity
                )
              }
              label={STRINGS.IS_NOT_ANY_OF}
            />
          </Box>

          {selectedCompanyFilter.notAnyOf ? (
            <Box>
              {" "}
              <CustomAutoComplete
                endPoint={leadsEndpoints.getLocationCityFilter}
                filter="company_city"
                type="_not"
              />
            </Box>
          ) : null}

          <Box sx={styles.jobTitleHead}>
            <Checkbox
              checked={selectedCompanyFilter?.isKnown}
              onClick={() =>
                handleExistFields(
                  "isKnown",
                  "company_city",
                  "exist_fields",
                  "not_exist_fields",
                  selectedCompanyFilter,
                  filterHeaderCompanyCity
                )
              }
              label={STRINGS.IS_KNOWN}
            />
          </Box>
          <Box sx={styles.jobTitleHead}>
            <Checkbox
              checked={selectedCompanyFilter?.isUnknown}
              onClick={() =>
                handleExistFields(
                  "isUnknown",
                  "company_city",
                  "not_exist_fields",
                  "exist_fields",
                  selectedCompanyFilter,
                  filterHeaderCompanyCity
                )
              }
              label={STRINGS.IS_UNKNOWN}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FilterTypeLocationCity;
