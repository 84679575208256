import { Box } from "@mui/system";
import React from "react";
import { STRINGS } from "../strings";
import classes from "../markComponents.module.css";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import {  fontWeight } from "../../../../utils/constants/UI";
import {  GREY } from "../../../../utils/constants/colors";

const SkippedContact = ({ data, heads }) => {
  return (
    <Box className={classes.containCommonTable} >
     

      {/* Table Common Header */}
      <Box className={classes.bgColSkip}>
        <Box className={classes.wAuto}>
          <Box
            className={classes.containTableHeads}
            sx={{ gridTemplateColumns: `${STRINGS.SMALL_REPEAT}(${heads?.length},${STRINGS.ONE_FR})` }}
          >
            {heads?.map((head, index) => {
              return (
                head?.isChecked && (
                  <Box
                    key={index}
                    sx={{ minWidth: head?.minWidth,
                          borderRight:"1px solid #E6E6E6",
                          borderBottom:"1px solid #E6E6E6",
                          padding:"1rem" }}
                    className={`${classes.displayFlex}`}
                  >
                    <TypographyInline
                    size={"sm"}
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={head?.label}
                  />
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      </Box>
      {/* Table Common Header */}

      {/*  Table Body as per the matched key  */}
      <Box className={classes.tableBodyContainer}>
        {data?.map((item, index) => {
          return (
            <Box className={classes.bgColTab} key={index}>
              <Box>
                <Box
                  className={classes.eachTableRow}
                  sx={{ gridTemplateColumns: `${STRINGS.SMALL_REPEAT}(${heads?.length},${STRINGS.ONE_FR})` }}
                >
                  <Box className={classes.eachTableRecord}>{item?.email}</Box>
                  <Box className={classes.eachTableRecord1}>{item?.status}</Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {/*  Table Body as per the matched key  */}
    </Box>
  );
};
export default SkippedContact;
