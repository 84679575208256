import React, { useEffect, useState } from 'react'
import { ICON_LABELS, SHADOW, fontSize, fontWeight, lineHeight } from '../../utils/constants/UI';
import { BASE, BORDER, BRAND, ERROR, GREY, PRIMARY } from '../../utils/constants/colors';
import { debounceHelper } from '../../utils/helpers';
import { DEBOUNCE_DELAY } from '../../utils/constants';
import { IconComponent } from '../Icon';
import CustomTooltip from '../Tooltip/CustomTooltip';
import Image from '../Image/Image';
import TypographyInline from '../Typography/TypographyInline';
import { Menu, MenuItem } from '@mui/material';
import SearchField from '../InputFields/SearchField';
import Checkbox from '../Buttons/Checkbox/Checkbox';
import { STRINGS } from '../strings';
import classes from "./Dropdown.module.css";

const SIZE_MAPPING = {
    xs34: {
        padding: "8px 10px",
        fontSize: fontSize.XS,
        fontWeight: 400,
        lineHeight: lineHeight.XS,
        iconSize: fontSize.MD,
    },
    xs36: {
        padding: "8px 10px ",
        fontSize: fontSize.XS,
        fontWeight: 400,
        lineHeight: lineHeight.XS,
        iconSize: fontSize.MD,
    },
    sm36: {
        padding: "8px 10px",
        fontSize: fontSize.SM,
        fontWeight: 400,
        lineHeight: lineHeight.SM,
        iconSize: fontSize.MD,
    },
    md40: {
        padding: "10px 10px",
        fontSize: fontSize.SM,
        fontWeight: 400,
        lineHeight: lineHeight.SM,
        iconSize: fontSize.MD,
    },
    lg44: {
        padding: "10px 10px",
        fontSize: fontSize.MD,
        fontWeight: 400,
        lineHeight: lineHeight.MD,
        iconSize: fontSize.XL,
    },
    default: {
        backgroundColor: BASE.WHITE,
        border: `1px solid ${BORDER.HOVER}`,
    },
    focused: {
        backgroundColor: BASE.WHITE,
        border: `1px solid ${GREY.PRIMARY}`,
        boxShadow: SHADOW.input,
    },
    filled: {
        boxShadow: SHADOW.md,
        backgroundColor: BASE.WHITE,
        border: `1px solid ${BORDER.DARK}`,
    },
    error: {
        boxShadow: "",
        backgroundColor: BASE.WHITE,
        border: `1px solid ${ERROR.W_500}`,
    },
    disabled: {
        backgroundColor: BORDER.DISABLED,
        border: `1px solid ${BASE.TRANSPARENT}`,
    },
};


const Dropdown = ({
    items = [],
    subItems = [],
    subItemsLabel = "Sub Label",
    multiple = false,
    isLoading = false,
    size = "sm36",
    handleClick = () => { },
    paperWidth = "",
    dropdownId = "",
    onChange,
    isColor = false,
    isSearchEnable = false,
    handleSearch = () => { },
    LeftImage = "",
    RightImage = "",
    variant = "default",
    disabled = false,
    LeftIcon = "",
    RightIcon = ICON_LABELS.ACTION_ARROW,
    errormessage = "",
    initialValue = {},
    idState, setIdState = () => { },
    valueState, setValueState = () => { },
    ...props
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [inputState, setInputState] = useState(variant || "default");

    const styles = {
        dropdownMainContainer: {
            width: "100%",
            cursor: "pointer",
        },
        mainContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            width: "100%",
        },
        paperMenu: {
            maxHeight: props?.paperHeight || "30vh",
            width:
                paperWidth ||
                document?.getElementById(dropdownId || "custom-dropdown")?.clientWidth,
            marginTop: "3px",
            boxShadow:
                "0px 12px 8px 0px rgba(24, 24, 28, 0.02), 0px 0px 16px 0px rgba(24, 24, 28, 0.12)",
        },
        selectedItem: {
            padding: multiple ? "0px" : SIZE_MAPPING[size]?.padding,
            backgroundColor: PRIMARY.W_50,
            gap: "4px",
            boxShadow: `inset 2px 0px 0px 0px ${BRAND.PRIMARY}`,
        },
        defaultItem: {
            padding: multiple ? "0px" : "8px 10px",
            gap: "4px",
        },
        checkboxContainer: {
            padding: multiple && "8px 0px 10px 8px",
        },
        labelContainer: {
            padding: multiple && "8px 0px 10px 8px",
            width: "100%",
        },
        iconStyle: {
            fontSize: `${SIZE_MAPPING[size]?.iconSize}`,
            color: disabled ? GREY.DISABLED : GREY.SECONDARY,
            marginTop: "1px",
        },
        iconStyle1: {
            fontSize: `${SIZE_MAPPING[size]?.iconSize}`,
            color: disabled ? GREY.DISABLED : isColor ? BRAND.PRIMARY : GREY.TERTIARY,
            marginTop: "1px",
        },
        xs34: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
            borderRadius: "6px",
            padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
            boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
            border: SIZE_MAPPING[inputState]?.border,
            cursor: inputState === "disabled" ? "not-allowed" : "pointer",
            backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
        },
        xs36: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
            borderRadius: "6px",
            padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
            boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
            cursor: inputState === "disabled" ? "not-allowed" : "pointer",
            border: SIZE_MAPPING[inputState]?.border,
            backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
        },
        sm36: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
            borderRadius: "6px",
            padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
            boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
            cursor: inputState === "disabled" ? "not-allowed" : "pointer",
            border: SIZE_MAPPING[inputState]?.border,
            backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
        },
        md40: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
            borderRadius: "6px",
            padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
            boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
            border: SIZE_MAPPING[inputState]?.border,
            cursor: inputState === "disabled" ? "not-allowed" : "pointer",
            backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
        },
        lg44: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
            borderRadius: "6px",
            padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
            boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
            border: SIZE_MAPPING[inputState]?.border,
            cursor: inputState === "disabled" ? "not-allowed" : "pointer",
            backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
        },
        inputField: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            width: "100%",
            border: "none",
            height: "100%",
            color: inputState === "disabled" ? `${GREY.DISABLED}` : `${GREY.SECONDARY}`,
            borderRadius: "6px",
            fontStyle: "normal",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: SIZE_MAPPING[size]?.fontWeight,
            fontSize: SIZE_MAPPING[size]?.fontSize,
            lineHeight: SIZE_MAPPING[size]?.lineHeight,
            padding: SIZE_MAPPING[size]?.padding,
            cursor: inputState === "disabled" && "not-allowed",
            backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
            textAlign: "left",
            cursor: "pointer"
        },
    };

    const onClickHandler = (label, id) => {
        if (multiple) {
            if (valueState?.includes(label)) {
                let newSelectedValue = valueState?.filter((val) => val !== label);
                let newSelectedId = idState?.filter((val) => val !== id);
                setValueState(newSelectedValue);
                setIdState(newSelectedId);
                if (onChange) {
                    onChange({ id: newSelectedId, label: newSelectedValue });
                }
            } else {
                setValueState([...valueState, label]);
                setIdState([...idState, id]);
                if (onChange) {
                    onChange({
                        id: [...idState, id],
                        label: [...valueState, label],
                    });
                }
            }
        } else {
            setValueState([label]);
            setIdState([id]);
            setIsVisible(false);
            if (onChange) {
                onChange({ id: [id], label: [label] });
            }
        }
    };

    const searchHandler = (variantVal, inputValue) => {
        if (variantVal === "blurred") {
            if (inputValue) {
                setInputState("filled");
            } else {
                setInputState(variant || "default");
            }
        } else {
            if (variant !== "error") {
                setInputState(variantVal);
            }
        }
    };

    const debounceSearch = debounceHelper(handleSearch, DEBOUNCE_DELAY);
    let input = document.getElementById("inputField");

    return (
        <div
            style={styles.dropdownMainContainer}
            className={classes.dropdownMainContainer}
            id={dropdownId || "custom-dropdown"}
        >
            <div style={styles.mainContainer}>
                <div
                    style={styles[size]}
                    className={classes[inputState]}
                    onClick={(e) => {
                        if (props?.variant !== "disabled") {
                            handleClick();
                            setIsVisible(true);
                            setAnchor(e?.currentTarget);
                        }
                    }}
                >
                    <CustomTooltip
                        title={input?.clientWidth < input?.scrollWidth ? props?.value : ""}
                    >
                        <button
                            id="inputField"
                            autoComplete="off"
                            type={"text"}
                            style={styles.inputField}
                            onFocus={() => {
                                searchHandler("focused");
                            }}
                            onBlur={(event) => {
                                searchHandler("blurred", event.target.value);
                            }}
                            disabled={variant === "disabled"}
                            {...props}
                        >
                            {LeftIcon && (
                                <IconComponent iconLabel={LeftIcon} style={styles.iconStyle1} />
                            )}
                            {LeftImage && <Image src={LeftImage} />}
                            {valueState?.length ? valueState : STRINGS.SEARCH}
                            {RightIcon && (
                                <IconComponent
                                    iconLabel={RightIcon}
                                    style={styles.iconStyle}
                                />
                            )}
                            {RightImage && <Image src={RightImage} />}
                        </button>
                    </CustomTooltip>


                </div>
                {variant === "error" && errormessage && (
                    <TypographyInline
                        label={errormessage}
                        color={ERROR.W_500}
                        size={"xs"}
                        fontWeight={fontWeight.REGULAR}
                    />
                )}
            </div>

            {isVisible && (
                <Menu
                    onClose={() => setIsVisible(false)}
                    anchorEl={anchor}
                    open={isVisible}
                    PaperProps={{ style: styles.paperMenu, id: "modal_body" }}
                >
                    {isSearchEnable && (
                        <div className={classes.searchFieldContainer}>
                            <SearchField
                                size={"sm36"}
                                onChange={(event) => {
                                    debounceSearch(event);
                                }}
                                onKeyDown={(event) => event.stopPropagation()}
                                placeholder={STRINGS.SEARCH}
                            />
                        </div>
                    )}
                    {isLoading ? (
                        <div style={styles.defaultItem}>
                            <TypographyInline
                                label={"Loading..."}
                                color={GREY.SECONDARY}
                                fontWeight={fontWeight.REGULAR}
                            />
                        </div>
                    ) : (
                        <div>
                            {items?.length ? (
                                <div>
                                    {items?.map((item, index) => {
                                        return item?.label?.toString()?.trim()?.length ? (
                                            <MenuItem
                                                style={
                                                    idState?.filter(
                                                        (val) => val === (item._id || item?.id)
                                                    )?.length
                                                        ? styles.selectedItem
                                                        : styles.defaultItem
                                                }
                                                className={
                                                    idState?.filter(
                                                        (val) => val === (item._id || item?.id)
                                                    )?.length
                                                        ? classes.selectedItem
                                                        : classes.defaultItem
                                                }
                                                key={index}
                                            // onClick={() =>
                                            //   onClickHandler(item?.label, item?.id || item?._id)
                                            // }
                                            >
                                                {multiple && (
                                                    <div style={styles.checkboxContainer}>
                                                        <Checkbox
                                                            size={fontSize.SM}
                                                            checked={idState?.includes(item?.id || item?._id)}
                                                            onChange={(e) => {
                                                                e?.stopPropagation();
                                                                onClickHandler(
                                                                    item?.label,
                                                                    item?.id || item?._id
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {item?.iconLabel && (
                                                    <IconComponent
                                                        fontSize={fontSize.MD}
                                                        color={item?.iconColor || GREY.TERTIARY}
                                                        iconLabel={item?.iconLabel}
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            onClickHandler(
                                                                item?.label,
                                                                item?.id || item?._id
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {item?.iconSrc && (
                                                    <Image
                                                        src={item?.iconSrc}
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            onClickHandler(
                                                                item?.label,
                                                                item?.id || item?._id
                                                            );
                                                        }}
                                                    />
                                                )}
                                                <div style={styles.labelContainer}>
                                                    <TypographyInline
                                                        label={item?.label?.toString() || ""}
                                                        size={size.slice(0, 2)}
                                                        fontWeight={fontWeight.REGULAR}
                                                        color={GREY.SECONDARY}
                                                        onClick={() =>
                                                            onClickHandler(item?.label, item?.id || item?._id)
                                                        }
                                                    />
                                                </div>
                                            </MenuItem>
                                        ) : null;
                                    })}
                                </div>
                            ) : (
                                <div style={styles.defaultItem}>
                                    <TypographyInline
                                        color={GREY.PRIMARY}
                                        size={"sm"}
                                        label={STRINGS.NO_RECORD_FOUND}
                                        fontWeight={fontWeight.REGULAR}
                                    />
                                </div>
                            )}

                            {/* Looping when subItems are available. */}
                            {subItems?.length ? (
                                <>
                                    <div className={classes.subItemLabel}>
                                        <TypographyInline
                                            color={GREY.PRIMARY}
                                            label={subItemsLabel}
                                            size={"sm"}
                                            fontWeight={fontWeight.SEMIBOLD}
                                            isEllipses
                                        />
                                    </div>
                                    {subItems?.map((item, index) => {
                                        return item?.label?.trim()?.length ? (
                                            <>
                                                <MenuItem
                                                    style={
                                                        idState?.filter(
                                                            (val) => val === (item._id || item?.id)
                                                        )?.length
                                                            ? styles.selectedItem
                                                            : styles.defaultItem
                                                    }
                                                    className={
                                                        idState?.filter(
                                                            (val) => val === (item._id || item?.id)
                                                        )?.length
                                                            ? classes.selectedItem
                                                            : classes.defaultItem
                                                    }
                                                    key={index}
                                                    onClick={() =>
                                                        onClickHandler(item?.label, item?.id || item?._id)
                                                    }
                                                >
                                                    {multiple && (
                                                        <div style={styles.checkboxContainer}>
                                                            <Checkbox
                                                                size={fontSize.SM}
                                                                checked={valueState?.includes(item?.label)}
                                                                onChange={(e) => {
                                                                    e?.stopPropagation();
                                                                    onClickHandler(
                                                                        item?.label,
                                                                        item?.id || item?._id
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {item?.iconLabel && (
                                                        <IconComponent
                                                            fontSize={fontSize.MD}
                                                            color={item?.iconColor || GREY.TERTIARY}
                                                            iconLabel={item?.iconLabel}
                                                            onClick={(e) => {
                                                                e?.stopPropagation();
                                                                onClickHandler(
                                                                    item?.label,
                                                                    item?.id || item?._id
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    <div style={styles.labelContainer}>
                                                        <TypographyInline
                                                            label={item?.label || ""}
                                                            size={size.slice(0, 2)}
                                                            fontWeight={fontWeight.REGULAR}
                                                            color={GREY.SECONDARY}
                                                        // onClick={() =>
                                                        //   onClickHandler(
                                                        //     item?.label,
                                                        //     item?.id || item?._id
                                                        //   )
                                                        // }
                                                        />
                                                    </div>
                                                </MenuItem>
                                            </>
                                        ) : null;
                                    })}
                                </>
                            ) : null}
                        </div>
                    )}
                </Menu>
            )}
        </div>
    );
}

export default Dropdown;


// ------------- NOTE ----------------
// If there is need to add color or any other attribute in button then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props description :-
// items :- This should contain values for dropdown to display in the form of array of object have id, label and iconLabel(pass this if you want icon on the left side of dropdown value) as key of objects.
// subItems :- This should contain values for dropdown to display as a items of sublabel in the form of array of object have id and labes as key of objects.
// subItemsLabel :- This is label which will be displayed as a sublabel for subitems.
// multiple :- Will make dropdown as multiple.
// isLoading :- Loading state of dropdown.
// size :- size of dropdown mentioned in figma.
// paperWidth: "This prop is used for menu paper width."
// handleClick :- This prop is onClick function on search field.
// dropdownId :- This prop is used for defining unique id for dropdown.
// onChange :- It is on change function for dropdown.
// isSearchEnable :- This prop will enable search for searching values in dropdown.
// handleSearch :- This prop should be pass from parent component having searching logic for dropdown items.
// variant :- This will mainly used for only two states of dropdown. And this two states are error and disabled state.
