import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DONENEW_GIF,
  LEFT_IMAGE,
  NEW_LOGIN_REACHIQ_LOGO,
  RIGHT_IMAGE,
} from "../../utils/constants/assets";
import { RED_COLOR } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { HOME_PATH } from "../../utils/constants/routes";
import { Anchor, RightsTag } from "../../components";
import { PRIVACY_POLICY_LINK, TERMS_SERVICE_LINK } from "../../utils/constants";
import Typography from "../../components/Typography/Typography";
import { BRAND, GREY } from "../../utils/constants/colors";
import { fontSize, fontWeight } from "../../utils/constants/UI";
import { Button } from "../../components/Buttons";
import { STRINGS } from "./strings";
import Image from "../../components/Image/Image";

const styles = makeStyles({
  TypoHead: {
    marginLeft: "0.2rem",
  },
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: `url(${LEFT_IMAGE}) right top no-repeat ,url(${RIGHT_IMAGE}) left bottom no-repeat`,
  },
  Margin: {
    marginLeft: "5px",
  },
  containLogo: {
    display: "flex",
    justifyContent: "center",
    // margin: "0 20vw",
    marginTop: "100px",
  },
  hovering: {
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  logo: {
    width: "94px",
    height: "95px",
    objectFit: "contain",
    alignSelf: "center",
  },
  borderLogo: {
    borderRadius: "80px",
    border: " 1px solid #5EC37F",
    height: "154px",
    width: "154px",
    display: "flex",
    justifyContent: "center",
  },
  containPolicy: {
    display: "flex",
    justifyContent: "center",

    paddingTop: "1rem",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "32px",
    // paddingBottom: "15vh",
  },
  title: {
    color: "#102F51",
    fontWeight: "300",
    fontSize: "clamp(1.4rem, 2.5vw, 4.5rem)",
    letterSpacing: "1x",
    marginBottom: "2vh",
    textAlign: "center",
  },
  para: {
    display: "flex",
    color: "#626B7E",
    fontWeight: "400",
    paddingTop: "4px",
    paddingBottom: "24px",
    fontSize: "clamp(0.9rem, 1vw, 2.5rem)",
    // marginBottom: "4vh",
    textAlign: "center",
  },
  red: {
    color: "#1E2939",
  },
  button: {
    backgroundColor: RED_COLOR,
    color: "white",
    fontSize: "clamp(0.9rem, 1vw, 2.5rem)",
    padding: "1rem 4rem",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    cursor: "pointer",
    // boxShadow: "0px 9px 20px #ff16543c",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "#0f2f51",
    },
  },
  containFooter: {
    display: "flex",
    // justifyContent: "space-around",
    // padding: "4vh 0 0 0",
    // height: "40vh",
    height: "61vh",

    justifyContent: "flex-end",
    padding: "1rem",
    flexDirection: "column",
    alignItems: "center",
    // borderTop: "1px solid #d3dae3",
  },
  policyPara: {
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "clamp(0.8rem, 0.9vw, 2.5rem)",
    marginBottom: "4vh",
    textAlign: "center",
  },
  termsPara: {
    fontSize: "clamp(12px,0.8vw,36px)",
    color: "#626B7E",
    fontWeight: fontWeight.MEDIUM,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  privacyLink: {
    fontSize: fontSize.XS,
    color: "#626B7E",
    fontWeight: fontWeight.MEDIUM,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    transition: "100ms",
    "&:hover": {
      color: "#ff4848",
    },
  },
  privacynotLink: {
    fontSize: fontSize.XS,
    color: "#626B7E",
    fontWeight: fontWeight.MEDIUM,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
  },
  padTop: {
    paddingTop: "60px",
  },
});

const ThankYouScreen = ({ email, resend }) => {
  const classes = styles();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(HOME_PATH);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.containLogo}>
        <Image src={NEW_LOGIN_REACHIQ_LOGO} />
      </Box>
      <Box className={classes.padTop}>
        <div className={classes.borderLogo}>
          <Image src={DONENEW_GIF} className={classes.logo} />
        </div>
      </Box>

      <Box className={classes.content}>
        <Typography
          size={"dxs"}
          color={GREY.PRIMARY}
          fontWeight={fontWeight.SEMIBOLD}
          label={STRINGS.EMAIL_LINK}
        />

        <div className={classes.para}>
          <Typography
            size={"sm"}
            color={GREY.TERTIARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.EMAIL_SENT}
          />
          <div className={classes.Margin}>
            <Typography
              size={"sm"}
              color={BRAND.SECONDARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={email}
            />
          </div>
          {/* <span className={classes.red}></span> */}
        </div>
        {/* <button className={classes.button} onClick={handleNavigate}>
          {resend ? "Resend" : "Go Home"}
        </button> */}

        <Button
          label={STRINGS.BACK_LOGIN}
          size={"md40"}
          variant={"primary"}
          onClick={handleNavigate}
          // isLoading={loading}
        />
      </Box>
      <Box className={classes.containFooter}>
        <div className={classes.containPolicy}>
          <RightsTag className={classes.termsPara} />
          {/* Privacy and Terms. */}
          <Anchor
            href={PRIVACY_POLICY_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            <div className={classes.hovering}>
              {" "}
              <Typography
                label={STRINGS.PRIVACY}
                color={GREY.QUATINARY}
                size={"xs"}
                fontWeight={fontWeight.SEMIBOLD}
              />
            </div>
          </Anchor>
          <div className={classes.TypoHead}>
            <Typography
              label={STRINGS.AND}
              color={GREY.TERTIARY}
              size={"xs"}
              fontWeight={fontWeight.MEDIUM}
            />
          </div>
          <Anchor
            href={TERMS_SERVICE_LINK}
            className={classes.privacynotLink}
            target="_blank"
          >
            {" "}
            <div className={classes.hovering}>
              {" "}
              <Typography
                label={STRINGS.TERMS}
                color={GREY.QUATINARY}
                size={"xs"}
                fontWeight={fontWeight.SEMIBOLD}
              />
            </div>
            {/* Terms. */}
          </Anchor>
        </div>
      </Box>
    </Box>
  );
};

export default ThankYouScreen;
