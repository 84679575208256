import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  SETTINGS_ACCOUNTDEFAULT_TABS,
  SETTINGS_ACCOUNT_SETUP_LIST,
  SETTINGS_ACCOUNT_TABS,
  SETTINGS_DATA_MANGEMENT_LIST,
  SETTINGS_GENERAL_TABS,
  SETTINGS_PREFERENCES_LIST,
} from "../../utils/constants";
import { styles } from "./styles";
import General from "./components/Settings/General";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PROFILE_PATH } from "../../utils/constants/routes";
import { BRAND, GREY } from "../../utils/constants/colors";
import { fontSize, fontWeight } from "../../utils/constants/UI";
import { STRINGS } from "./strings";
import CustomHeader from "../../components/Header/CustomHeader";
import TypographyInline from "../../components/Typography/TypographyInline";
import IconComponent from "../../components/Icon/IconComponent";
import { getSignatureAction } from "../../redux/actions/authActions";
import Image from "../../components/Image/Image";
import * as qs from "qs";

const ProfileScreen = () => {
  const classes = styles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const parsedObject = qs.parse(window.location.search?.slice(1));

  const [renderedComponent, setRenderedComponent] = useState({
    component: General,
  });
  const [selectedPreference, setSelectedPreference] = useState(parsedObject?.tab);
  const selectedGeneralTab = SETTINGS_GENERAL_TABS[0];

  const selectedAccountTab = SETTINGS_ACCOUNT_TABS[0];

  const selectedBrandTab = SETTINGS_ACCOUNTDEFAULT_TABS[0];

  const userDetailsSelector = useSelector(
    (state) => state?.userDetails?.payload
  );
  useEffect(() => {
    (async () => {
      dispatch(getSignatureAction(navigate));
    })();
  }, [dispatch]);
  useEffect(() => {
    if (location?.pathname?.length) {
      const UpdatedLocation = parsedObject?.tab;
      let tabLabel = "";
      let tabComponent = "";
      let tabDetails = "";
      let query = qs.parse(window.location.search)
      SETTINGS_PREFERENCES_LIST.forEach((element) => {
        if (element?.id === UpdatedLocation) {
          tabComponent = element?.component;
          tabLabel = element?.label;
          tabDetails = element;
        }
      });

      if (!tabLabel?.length) {
        SETTINGS_ACCOUNT_SETUP_LIST?.forEach((element) => {
          if (element?.id === UpdatedLocation) {
            tabComponent = element?.component;
            tabLabel = element?.label;
            tabDetails = element;
          }
        });
      }

      if (!tabLabel?.length) {
        SETTINGS_DATA_MANGEMENT_LIST?.forEach((element) => {
          if (element?.id === UpdatedLocation) {
            tabComponent = element?.component;
            tabLabel = element?.label;
            tabDetails = element;
          }
        });
      }

      handlePreferenceSelection(tabLabel, tabComponent, window.location.search);
    }
  }, [location.pathname]);

  const handlePreferenceSelection = (preference, component, details) => {

    navigate(`${PROFILE_PATH}${details?.id ? `?tab=${details?.id}` : details}`);

    setRenderedComponent({ component: component });
    setSelectedPreference(preference);
  };

  const headerItems = [{ id: "meeting", label: "Profile Settings" }];

  return (
    <Box className={classes.container}>
      <CustomHeader items={headerItems} />
      <Box className={classes.mainContainer}>
        <Box id="modal_body" className={classes.settingContainer}>
          <Box className={classes.setting}>
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.SETTING}
            />
          </Box>
          <div className={classes.settingsHeading}>
            {" "}
            <TypographyInline
              size={"sm"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.PREFERENCE}
            />
          </div>
          <Box className={classes.containPreferences}>
            {SETTINGS_PREFERENCES_LIST.map((item, index) => {
              return (
                <Box
                  key={item.label}
                  className={
                    selectedPreference === item.label
                      ? classes.selectedEachPreference
                      : classes.eachPreference
                  }
                  sx={{ borderRadius: (index === 0 ? "6px 6px 0px 0px" : (index === (SETTINGS_PREFERENCES_LIST.length - 1) ? "0px 0px 6px 6px" : "0px")) }}
                  onClick={() => {
                    handlePreferenceSelection(item.label, item.component, item);
                  }}
                >
                  <item.icon
                    className={
                      selectedPreference === item.label
                        ? classes.selectedIcon
                        : classes.icon
                    }
                  />
                  <TypographyInline
                    label={item.label}
                    size={"sm"}
                    fontWeight={fontWeight.MEDIUM}
                    color={selectedPreference === item.label ? BRAND.PRIMARY : GREY.SECONDARY}
                  />
                </Box>
              );
            })}
          </Box>
          {userDetailsSelector?.role === "admin-user" ? (
            <Box>
              <Box>
                <div className={classes.settingsHeading}>
                  <TypographyInline
                    size={"sm"}
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.ACC_SETUP}
                  />
                </div>
                <Box className={classes.containPreferences}>
                  {SETTINGS_ACCOUNT_SETUP_LIST?.map((item, index) => {
                    return (
                      <Box
                        key={item.label}
                        className={
                          selectedPreference === item.label
                            ? classes.selectedEachPreference
                            : classes.eachPreference
                        }
                        sx={{ borderRadius: (index === 0 ? "6px 6px 0px 0px" : (index === (SETTINGS_ACCOUNT_SETUP_LIST.length - 1) ? "0px 0px 6px 6px" : "0px")) }}
                        onClick={() =>
                          handlePreferenceSelection(
                            item?.label,
                            item?.component,
                            item
                          )
                        }
                      >
                        <item.icon
                          className={
                            selectedPreference === item.label
                              ? classes.selectedIcon
                              : classes.icon
                          }
                        />
                        <TypographyInline
                          label={item.label}
                          size={"sm"}
                          fontWeight={fontWeight.MEDIUM}
                          color={selectedPreference === item.label ? BRAND.PRIMARY : GREY.SECONDARY}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box className={classes.dataManagementLabel}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.DATA_MANAGEMENT}
                />
              </Box>

              <Box className={classes.containPreferences}>
                {SETTINGS_DATA_MANGEMENT_LIST?.map((item, index) => {
                  return (
                    <Box
                      key={item?.label}
                      className={
                        selectedPreference === item.label
                          ? classes.selectedEachPreference
                          : classes.eachPreference
                      }
                      sx={{ borderRadius: (index === 0 ? "6px 6px 0px 0px" : (index === (SETTINGS_DATA_MANGEMENT_LIST.length - 1) ? "0px 0px 6px 6px" : "0px")) }}
                      onClick={() => {
                        handlePreferenceSelection(
                          item?.label,
                          item?.component,
                          item
                        );
                      }}
                    >
                      {
                        item?.image ? <Image className={styles.sidebarImage} src={item?.image} alt="" /> : <IconComponent
                          color={
                            selectedPreference === item?.label
                              ? BRAND.PRIMARY
                              : GREY.SECONDARY
                          }
                          fontSize={fontSize.XL}
                          iconLabel={item?.icon}
                        />
                      }
                      <TypographyInline
                        size={"sm"}
                        color={
                          selectedPreference === item?.label
                            ? BRAND.PRIMARY
                            : GREY.SECONDARY
                        }
                        fontWeight={fontWeight.MEDIUM}
                        label={item.label}
                      />
                    </Box>
                  );
                })}
              </Box>
              {/* </>
              } */}
              {/* Dynamic Field Tab */}
            </Box>
          ) : null}
        </Box>
        <Box className={classes.contentContainer}>
          {selectedPreference === "General" ? (
            <selectedGeneralTab.component isProfileSetting />
          ) : selectedPreference === "Users" ? (
            <selectedAccountTab.component />
          ) : selectedPreference === "Account Details" ? (
            <selectedBrandTab.component isProfileSetting />
          ) : (
            <renderedComponent.component />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileScreen;
