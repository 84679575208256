export const STRINGS = {
  LOGIN: "Log In",
  TO_GET_STARTED:
    "To get started, simply fill out the registration form below.",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  EMAIL: "Email",
  SUB_DOMAIN: "Sub-domain",
  CONFIRM_PASSWORD: "Confirm Password",
  URL: "URL",
  ALREADY_HAVEAN_ACCOUNT: "Already have an account?",
  SIGN_IN: "Sign In",
  SIGNIN: "Sign In",
  SUCCESSFUL_LOGIN:"Successful login",
  UNSUCCESSFULL_LOGIN:"Unsuccessful login",
  LOGIN_NOW: " Login Now",
  SIGN_UP: "Sign up",
  GOOGLE_REGISTER:
    "  Sorry, the page you are looking doesn’t exist or has been moved.",
  ENTER_CONFIRM_PASS: "Enter Confirm Password",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  SIGN_IN_GOOGLE: "Continue with Google",
  SIGN_IN_EMAIL: "Sign In with Email",
  FORGOT_PASSWORD: "Forgot password?",
  DO_YOU_HAVE: "Don’t have an account?",
  REGISTER: " Create an account",
  PRIVACY: "Privacy",
  TERMS: "Terms.  ",
  ENTER_LAST_NAME: "Enter Last Name",
  ENTER_EMAIL: "Enter Email",
  PASSWORD: "Password",
  password: "password",
  AND: "and",
  ENTER_FIRST_NAME: "Enter First Name",
  TEXT: "text",
  LETS_GET_FAMILIAR: "Let’s get familiar ",
  ENTER_PASSWORD: "Enter Password",
  WELCOME_BACK: " Welcome Back!",
  GOOGLE_ICON: "google_icon",
  PLEASE_SIGNIN_ACCESS_ACCOUNT: "Please sign in to access your account.",
  OR_CONTINUE_WITH: "or continue with email",
  ERROR: "error",
  DEFAULT: "default",
  SIGN_IN_WITH_MICROSOFT:"Continue with Microsoft",
  DISABLED:"disabled"
};
