import { Box } from '@mui/material';
import React from 'react';
import SkeletonLoader from '../../../../components/Loaders/SkeletonLoader';
import classes from "../markComponents.module.css";
const EspSkeleton = () => {

    const skeleton_boxes = [
        {type:"rectangular"},
        {type:"rectangular"},
        {type:"rectangular"},
    ]


    return <Box className={ classes.gapFlex } >
        { skeleton_boxes.map((item, index)=>{
            return <Box className={ classes.gapFlex } key={ index } >
                <SkeletonLoader variant={item.type} skeletonWidth='120px' skeletonHeight='115px' />
            </Box>
        }) }
    </Box>
}

export default EspSkeleton;