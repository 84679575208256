import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HtmlEditor } from "../../../../components";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import {
  DYNAMIC_TAGS,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../../utils/constants";
import { campaignEndpoints, templateEndpoints } from "../../../../utils/constants/httpConstants";
import { getRequest, postRequest } from "../../../../Apis";
import {
  addTagStyleInHtml,
  getErrorMessage,
  removeTagStyleFromHtml,
  generateSignatureHTML,
  compressString,
  decompressString,
} from "../../../../utils/helpers";
import parse from "html-react-parser";
import { putRequest } from "../../../../Apis/apiWrapper";
import { editAction } from "../../../../redux/actions/editorAction";
import { getSignatureAction } from "../../../../redux/actions/authActions";
import { STRINGS } from "../strings";
import { Button } from "../../../../components/Buttons";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { BASE, GREY, PRIMARY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import CustomHeader from "../../../../components/Header/CustomHeader";
import { TEMPLATE_PATH } from "../../../../utils/constants/routes";
import InputField from "../../../../components/InputFields/InputField";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import { nameValidation } from "../../../../utils/validations";
import classes from "../markComponents.module.css";
import Checkbox from "../../../../components/Buttons/Checkbox/Checkbox";

const CreateEmailTemplate = () => {
  const location = useLocation();
  const { tempId, prId, id } = useParams();
  const [tempName, setTempName] = useState("");
  const userDet = useSelector((state) => state.userDetails);
  const owner = userDet?.payload;
  const [owner1, setOwner1] = useState("");
  const [viewType, setViewType] = useState(
    location?.state?.permit || STRINGS.PRIVATE_ID
  );
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [dataList, setDataList] = useState([]);
  // const [parseSubject,setParsedSubject] = useState("");
  const [currentFocus, setCurrentFocus] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getEditorSelector = useSelector((state) => state.editorData);
  const signatureSelector = useSelector((state) => state.getSignature);
  const data = signatureSelector?.payload?.data?.signature;
  const [isSignShow, setIsSignShow] = useState(false)
  const string = `<div>${getEditorSelector?.payload?.innerHTML?.toString()}</div>`;
  const finalString = parse(string)?.props?.children;
  const [valueState, setValueState] = useState([]);
  const [idVal, setIdVal] = useState([]);
  const [checked, setChecked] = useState(false);
  const [validations, setValidations] = useState({ name: "", subject: "" });
  const [mailSending, setMailSending] = useState(false);
  const [insertSignData, setInsertSignData] = useState("");
  const [caretPosition, setCaretPosition] = useState(0);
  const [finalDynamicFields, setFinalDynamicFields] = useState([]);
  const [radioButtonData, setRadioButtonData] = useState([
    { id: STRINGS.PRIVATE_ID, label: STRINGS.PRIVATE_LABEL },
    { id: STRINGS.TEAM_ID, label: STRINGS.TEAM_LABEL },
  ]);
  const [headerItems, setHeaderItems] = useState([
    {
      id: STRINGS.TYPE_TEMP,
      label: STRINGS.TEMPLATES,
      path: TEMPLATE_PATH,
    },
  ]);

  const handleChange = (event) => {
    setViewType(event.target.value);
  };

  useEffect(async () => {
    (async () => {
      await dispatch(getSignatureAction(navigate));
      const response = await getRequest(
        navigate,
        templateEndpoints.getFolderList
      );
      let foldN = response?.data?.data;
      setDataList(response?.data?.data);
      if (tempId) {
        try {
          const response = await getRequest(
            navigate,
            `${templateEndpoints.getTemplate}?${STRINGS.ID}=${tempId}`
          );
          if (response?.data?.data?.is_compressed) {
            setBody(decodeURIComponent(decompressString(response?.data?.data?.body)));
          }
          else {
            setBody(decodeURI(response?.data?.data?.body));
          }
          setTempName(response?.data?.data?.name);
          setSubject(response?.data?.data?.subject);
          setViewType(response?.data?.data?.permission);
          // setBody(decodeURI(response?.data?.data?.body));
          setOwner1(response?.data?.data?.owner_name);
          setChecked(
            response?.data?.data?.isGmail
              ? response?.data?.data?.isGmail
              : false
          );
          if (response?.data?.data?.isGmail) gmailSignatureHandle();
          setIdVal([response?.data?.data?.parent]);
          let val = foldN.filter(
            (item) => item?._id === response?.data?.data?.parent
          );
          setValueState([val[0]?.name]);
          setIsSignShow(true)
        } catch (error) { }
      } else if (prId) {
        try {
          const response = await getRequest(
            navigate,
            `${templateEndpoints.getTemplate}?${STRINGS.ID}=${prId}`
          );
          if (response?.data?.data?.is_compressed) {
            setBody(decodeURIComponent(decompressString(response?.data?.data?.body)));
          }
          else {
            setBody(decodeURIComponent(response?.data?.data?.body));
          }
          setTempName(response?.data?.data?.name);
          setSubject(response?.data?.data?.subject);
          setViewType(response?.data?.data?.permission);
          // setBody(decodeURI(response?.data?.data?.body));
          setOwner1(response?.data?.data?.owner_name);
          setChecked(
            response?.data?.data?.isGmail
              ? response?.data?.data?.isGmail
              : false
          );
          if (response?.data?.data?.isGmail) gmailSignatureHandle();
          setIsSignShow(true)
        } catch (error) { }
      } else {
      }
    })();
    (async () => {
      const newHeaderItems = [];
      try {
        if (id) {
          const get_folder_details_payload = await getRequest(
            navigate,
            templateEndpoints.getTemplate + `?${STRINGS.ID}=${id}`
          );
          newHeaderItems.push({
            id: id,
            label: get_folder_details_payload?.data?.data?.name || "",
            path: `${TEMPLATE_PATH}/${id}`,
          });
          if (get_folder_details_payload?.data?.data?._id) {
            const updated_radio_buttons = radioButtonData.map((item) => {
              return { ...item, disabled: true };
            });
            setRadioButtonData(updated_radio_buttons);
            setViewType(get_folder_details_payload?.data?.data?.permission);
            setIdVal([get_folder_details_payload?.data?.data?._id]);
            setValueState([get_folder_details_payload?.data?.data?.name]);
            setOwner1(get_folder_details_payload?.data?.data?.owner_name);
          }
        }
      } catch (error) {
      } finally {
        newHeaderItems.push({
          id: STRINGS.SMALL_CREATE,
          label: tempId ? STRINGS.EDIT_TEMPLATE : STRINGS.CREATE_EMAIL_TEMPLATE,
        });
        setHeaderItems([...headerItems, ...newHeaderItems]);
      }
    })();

    try {
      let final_fields = [];
      const response = await getRequest(
        navigate,
        campaignEndpoints.getDefaultValues
      );
      let defaultValues = response?.data?.data?.[0];
      DYNAMIC_TAGS?.forEach(ele => {
        if (ele?.id === "company_name") {
          final_fields = [...final_fields, { ...ele, dummyValue: owner?.company_name || defaultValues?.[ele?.id] || ele?.dummyValue }];
        }
        else if (defaultValues?.[ele?.id]) {
          final_fields = [...final_fields, { ...ele, dummyValue: defaultValues?.[ele?.id] }];
        } else {
          final_fields = [...final_fields, ele]
        }
      });

      setFinalDynamicFields(final_fields);
    } catch (error) {
      console.log("Error_______ ", error);
    }
  }, [id, dispatch, navigate]);

  const handleCancel = () => {
    const PATH = TEMPLATE_PATH + (id?.length ? `/${id}` : "");
    navigate(PATH);
  };

  const handleFocus = (current, e) => {
    setCurrentFocus(current);
    getCaretPosition(e);
  };

  const arr = dataList.map((item, key) => {
    return {
      label: item?.name,
      id: item?._id,
    };
  });

  const submitData = async () => {
    const validatedName = nameValidation(tempName);
    const validatedSubject = nameValidation(subject);

    if (validatedName.isValid && validatedSubject.isValid) {
      setValidations({ name: "", subject: "" });
      let customEditorValue = removeTagStyleFromHtml(`<div>${document
        .getElementById(STRINGS.SMALL_EDITOR)
        ?.innerHTML?.toString()}</div>`);
      customEditorValue = compressString(encodeURIComponent(customEditorValue))

      const payload = {
        name: tempName,
        subject,
        isGmail: checked,
        body: customEditorValue,
        permission: viewType,
        parent: idVal[0] || "",
      };
      setLoading(true);
      if (tempId) {
        const payload2 = {
          _id: tempId,
          name: tempName,
          subject,
          isGmail: checked,
          type: STRINGS.SMALL_FILE,
          body: customEditorValue,
          permission: viewType,
          parent: idVal?.[0] || "",
        };
        try {
          const response = await putRequest(
            navigate,
            templateEndpoints.updateTemplate,
            payload2
          );
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          const PATH = TEMPLATE_PATH + (id?.length ? `/${id}` : "");
          navigate(PATH);
          setLoading(false);
          dispatch(editAction(""));
        } catch (err) {
          EventEmitter.emit(
            EMITTER_ERROR,
            getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
          );
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const response = await postRequest(
            navigate,
            templateEndpoints.createTemplate,
            payload
          );
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          const PATH = TEMPLATE_PATH + (id?.length ? `/${id}` : "");
          navigate(PATH);

          setLoading(false);
          dispatch(editAction(""));
        } catch (err) {
          setLoading(false);
          EventEmitter.emit(
            EMITTER_ERROR,
            getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
          );
        } finally {
          setLoading(false);
        }
      }
    } else {
      setValidations({
        name: validatedName.message,
        subject: validatedSubject.message,
      });
    }
  };

  const getDummyValueHandlerSub = (parsingRequired = true) => {
    let temp_subject = subject || ""
    for (let i in finalDynamicFields) {
      temp_subject = temp_subject.replaceAll(
        `{{${finalDynamicFields[i]?.id}}}`,
        finalDynamicFields[i]?.dummyValue
      );
    }
    return temp_subject

  };
  const getDummyValueHandler = (parsingRequired = true) => {
    // let temp_body = getEditorSelector?.view === "text" ? document.getElementById(STRINGS.SMALL_EDITOR)?.innerText?.toString() : document.getElementById(STRINGS.SMALL_EDITOR)?.innerHTML?.toString();
    let temp_body = document.getElementById(STRINGS.SMALL_EDITOR)?.innerHTML?.toString();
    for (let i in finalDynamicFields) {
      temp_body = temp_body.replaceAll(
        `{{${finalDynamicFields[i]?.id}}}`,
        finalDynamicFields[i]?.dummyValue
      );
    }

    // this is for removing tags colors.
    temp_body = removeTagStyleFromHtml(temp_body);
    // this is for removing tags colors.
    return parsingRequired ? parse(temp_body) : temp_body;

  };

  const signature_payload = (
    (tempId || prId) ? isSignShow &&
      <div id={STRINGS.SIGNATURE_DETAILS}>
        {parse(generateSignatureHTML(data))}
      </div> :
      <div id={STRINGS.SIGNATURE_DETAILS}>
        {parse(generateSignatureHTML(data))}
      </div>
  );

  const getSubjectHandler = () => {
    let temp_subject = subject || "";
    for (let i in finalDynamicFields) {
      temp_subject = temp_subject.replaceAll(
        `{{${finalDynamicFields[i]?.id}}}`,
        finalDynamicFields[i]?.dummyValue
      );
    }
    return temp_subject
  }

  const handleSendTestMail = async () => {
    try {
      setMailSending(true);
      let bodyVal = compressString(encodeURIComponent(`<div>${getDummyValueHandler(false)} ${insertSignData} </div>`))
      let payload;

      if (checked) {
        payload = {
          emailbody: bodyVal?.length
            ? bodyVal
            : "",
          subject: getSubjectHandler(),
          toemail: owner?.email,
        };
      } else {
        const signature = document.getElementById(
          STRINGS.SIGNATURE_DETAILS
        ).innerHTML;
        payload = {
          emailbody: bodyVal?.length
            ? bodyVal
            : "",
          subject: getSubjectHandler(),
          toemail: owner?.email,
        };
      }
      const response = await postRequest(
        navigate,
        templateEndpoints.sentTest,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || error?.data?.message)
      );
    } finally {
      setMailSending(false);
    }
  };

  const gmailSignatureHandle = async () => {
    setChecked(!checked);
    if (!checked) {
      const response = await postRequest(
        navigate,
        templateEndpoints.getSignature,
        {}
      );
      setInsertSignData(response?.data?.data);
    } else {
      setInsertSignData("");
      await dispatch(getSignatureAction(navigate));
    }
  };

  const getData = (subject) => {
    setSubject(subject);
    document.getElementById("editor").focus();
  };

  function getCaretPosition(e) {
    setCaretPosition(e?.target?.selectionStart);
  }

  return (
    <Box className={classes.tempCon}>
      <CustomHeader items={headerItems} />
      <Box className={classes.crtTemplateBodyContainer}>
        <Box className={classes.templateCreationPage} id="modal_body">
          <Box className={classes.headerCreateTemplate}>
            <Box className={classes.headerTempContent2}>
              <TypographyInline
                size={"md"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.EMAIL_TEMPLATE}
              />
              {/* Email Template */}
            </Box>
          </Box>
          <Box>
            <Box className={classes.commonMargin}>
              <Box className={classes.commonLabel}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.TEMPLATE_NAME1}
                />
                {/* Template Name* */}
              </Box>
            </Box>
            <Box className={classes.templateTextStyle}>
              <InputField
                autoComplete="off"
                value={tempName}
                placeholder={STRINGS.TYPETEMPLATE}
                variant={validations.name ? "error" : "default"}
                errormessage={validations.name}
                size={"sm36"}
                disabled={prId}
                onChange={(e) => setTempName(e.target.value)}
              />
            </Box>
            <Box className={classes.ownerTextStyle}>
              <Box className={classes.widthCommon}>
                <Box>
                  <TypographyInline
                    size={"sm"}
                    color={GREY.QUATINARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.OWNER}
                  />
                  {/* Owner */}
                </Box>
                <Box className={classes.commonTextBoxes1}>
                  <InputField
                    autoComplete="off"
                    variant={"disabled"}
                    value={owner1 || `${owner?.first_name} ${owner?.last_name}`}
                    size={"sm36"}
                    disabled
                  />
                </Box>
              </Box>
              <Box className={classes.widthCommon}>
                <Box className={classes.commonLabel}>
                  <TypographyInline
                    size={"sm"}
                    color={GREY.QUATINARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.FOLDER}
                  />
                </Box>
                <Box className={classes.commonTextBoxes1}>
                  <AutoCompleteCustom
                    items={arr}
                    LeftIcon=""
                    valueState={valueState}
                    setValueState={setValueState || []}
                    idState={idVal || []}
                    setIdState={setIdVal}
                    variant={id?.length ? "disabled" : "default"}
                    papermenuclass={classes.paperMenu1}
                    size={"sm36"}
                  />
                </Box>
              </Box>
            </Box>
            <div className={classes.modalPadding}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.SHARED_WITH}
              />
            </div>
            <div className={classes.modalRadio1}>
              <RadioButton
                value={viewType}
                onChange={handleChange}
                items={radioButtonData}
              />
            </div>
            <Box className={classes.modalRadio}>
              <Box className={classes.paddingT}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.SUBJECT1}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box className={classes.templateTextStyle}>
              <InputField
                autoComplete="off"
                value={subject}
                size={"sm36"}
                disabled={prId}
                placeholder={STRINGS.TYPESUBJECT}
                variant={validations.subject ? "error" : "default"}
                errormessage={validations.subject}
                onFocus={(e) => handleFocus(STRINGS.SMALL_SUBJECT, e)}
                // onBlur={() => setCurrentFocus("")}
                onChange={(e) => { setSubject(e.target.value); getCaretPosition(e) }}
                onKeyUp={(e) => getCaretPosition(e)}
                onKeyDown={(e) => {
                  getCaretPosition(e);
                }}
                onMouseUp={(e) => getCaretPosition(e)}
                onClick={(e) => getCaretPosition(e)}
              />

            </Box>
            <Box className={classes.commonMargin}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.BODY}
              />
            </Box>
            <Box className={classes.commonTextBoxes3}>
              <HtmlEditor
                setCurrentFocus={setCurrentFocus}
                currentFocus={currentFocus}
                subject={subject}
                setSubject={setSubject}
                previosData={addTagStyleInHtml(body)}
                editorHeight="200px"
                getData={getData}
                aiTypeValue="Template"
                caretPosition={caretPosition}
              />
            </Box>
            <Box
              className={`${classes.flexAlignItemsCenter} ${classes.padding16}`}
            >
              <Checkbox checked={checked} onClick={gmailSignatureHandle} />
              <Box className={classes.footerRightData} onClick={gmailSignatureHandle}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={STRINGS.INCLUDE_GMAIL_SIGN}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.mainPrevCont}>
          <Box className={classes.previewTemplateContainer} id="modal_body">
            <Box>
              <Box className={classes.prevHead}>
                <TypographyInline
                  size={"md"}
                  color={BASE.WHITE}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={STRINGS.PREVIEW}
                />
              </Box>
              <Box className={classes.commonDataPrev}>
                <Box className={classes.commonPreviews}>
                  {/* To: */}
                  <TypographyInline
                    size={"sm"}
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.TO}
                  />
                  <TypographyInline
                    size={"sm"}
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.MEDIUM}
                    label={owner?.email}
                  />
                </Box>
              </Box>
              <Box className={classes.commonDataPrev}>
                <Box className={classes.commonPreviews}>
                  <TypographyInline
                    size={"sm"}
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.SUBJECT2}
                  />
                  <Box className={classes.wordBreak}>
                    <TypographyInline
                      isEllipses={true}
                      size={"sm"}
                      color={GREY.PRIMARY}
                      fontWeight={fontWeight.MEDIUM}
                      label={getDummyValueHandlerSub()}
                    />
                  </Box>
                </Box>
              </Box>
              <Box id={STRINGS.PRMLDATA} className={classes.bodyData}>
                <Box>
                  {finalString === `${STRINGS.SMALL_UNDEFINED}`
                    ? " "
                    : getDummyValueHandler()}
                </Box>

                {checked ? (
                  <Box className={classes.previewMailBodyData}>
                    {parse(insertSignData)}
                  </Box>
                ) : signatureSelector?.loading ? (
                  ""
                ) : (
                  <Box className={classes.previewMailBodyData}>
                    {signature_payload}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.sendMailStyPrev}>
            <div>
              <Button
                label={STRINGS.SEND_TEST_MAIL}
                size="sm36"
                variant={"ghost"}
                onClick={handleSendTestMail}
                disabled={!subject?.length}
                isLoading={mailSending}
              />
            </div>
            <div className={classes.flexStylePrev}>
              <TypographyInline
                size={"xs"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.TEST_EMAIL}
              />
              <TypographyInline
                size={"xs"}
                color={PRIMARY.W_500}
                fontWeight={fontWeight.REGULAR}
                label={`${owner?.email}`}
              />
            </div>
          </Box>
        </Box>
      </Box>
      <Box className={classes.footerCreateTemplate}>
        <div>
          <Button
            label={STRINGS.CANCEL}
            variant={"ghost"}
            size={"sm36"}
            onClick={handleCancel}
          />
        </div>
        <div className={classes.padRight}>
          <Button
            label={tempId ? STRINGS.TEMP_UPDATE : STRINGS.SAVE_TEMP}
            size={"sm36"}
            variant={"primary"}
            onClick={submitData}
            disabled={prId}
            isLoading={loading}
          />
        </div>
      </Box>
    </Box>
  );
};

export default CreateEmailTemplate;