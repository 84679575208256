export const GET_TASK_TABLE_REQUEST = "GET_TASK_TABLE_REQUEST";
export const GET_TASK_TABLE_SUCCESS = "GET_TASK_TABLE_SUCCESS";
export const GET_TASK_TABLE_FAIL = "GET_TASK_TABLE_FAIL";

export const GET_TASK_SEARCH_REQUEST = "GET_TASK_SEARCH_REQUEST";
export const GET_TASK_SEARCH_SUCCESS = "GET_TASK_SEARCH_SUCCESS";
export const GET_TASK_SEARCH_FAIL = "GET_TASK_SEARCH_FAIL";

export const UPDATE_TASK_TABLE_REQUEST = "UPDATE_TASK_TABLE_REQUEST";
export const UPDATE_TASK_TABLE_SUCCESS = "UPDATE_TASK_TABLE_SUCCESS";
export const UPDATE_TASK_TABLE_FAIL = "UPDATE_TASK_TABLE_FAIL";
