import React, { useEffect, useState } from "react";
import ViewTab from "../../../../components/ViewTab/ViewTab";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import CustomStyledMenu from "../../../../components/Menu/CustomStyledMenu";
import { BRAND, GREY } from "../../../../utils/constants/colors";
import classes from "./CrmComp.module.css";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import {
  decompressString,
  filterDynamicQuery,
  getSelectedColumns,
} from "../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getRequest, postRequest } from "../../../../Apis";
import { accountEndPoints } from "../../../../utils/constants/httpConstants";
import { useLocation, useNavigate } from "react-router";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  tempHeadCompanyCells,
} from "../../../../utils/constants";
import { Button } from "../../../../components/Buttons";
import AllViewModal from "./AllViewModal";
import { getPeopleAccountDetails } from "../../../../redux/actions/peopleActions";
import {
  HandleClearAllFilterFromStore,
  checkDynamicExistFields,
} from "../../../../redux/store/storeHelper";
import {
  dynamicColumnUpdateHeaders,
  dynamicUpdatedColumn,
} from "../../../../redux/actions/tableActions";
import { filterMultipleList } from "../../../../redux/actions/filterActions";
import { stringQueryList } from "../../../../redux/store/storeConstants";
import IconComponent from "../../../../components/Icon/IconComponent";
import AddViewModal from "./AddViewModal";

const ContactHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const selector = useSelector((state) => state.peopleAccountDetails);
  const selectedTableCells = useSelector(
    (state) => state?.columnUpdate?.columns
  );
  const allDynamicFiltersSelector = useSelector(
    (state) => state.allDynamicFilters?.payload
  );
  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );
  const { loading, payload } = selector;

  let propertiesPayload = localStorage.getItem(STRINGS.PROPERTIES)
    ? JSON.parse(
        decompressString(localStorage.getItem(STRINGS.PROPERTIES) || "")
      )?.properties_key_value
    : getPropertiesListSelector?.properties_key_value;

  const [openAddView, setOpenAddView] = useState(false);
  const [actionPerforming, setActionPerforming] = useState(false);
  const [openAllViewModal, setOpenAllViewModal] = useState(false);
  const [saveViewEL, setSaveViewEL] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [defaultQueryPushed, setDefaultQueryPushed] = useState(false);
  const [viewEL, setViewEL] = useState(null);
  const [viewClicked, setViewClicked] = useState({});
  const [showViewActions, setShowViewActions] = useState("");
  const [actionValue, setActionValue] = useState("");
  const [spViewEL, setSpViewEL] = useState(null);
  const hoverIndex = -1;
  const showActions = "";
  const handleUnpinView = async () => {
    try {
      setActionPerforming(true);
      setActionValue(STRINGS.UNPIN_THIS_VIEW);
      const response = await getRequest(
        navigate,
        accountEndPoints?.unpinLeadView + "?view-id=" + showViewActions
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        await dispatch(getPeopleAccountDetails(navigate));
        setActionPerforming(false);
      }
    } catch (error) {
      setActionPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleMakeDefaultView = async () => {
    try {
      setActionPerforming(true);
      setActionValue(STRINGS.MAKE_DEFAULT_VIEW);
      const response = await getRequest(
        navigate,
        accountEndPoints?.makeDefaultLeadView + "?view-id=" + showViewActions
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        try {
          const response = await getRequest(
            navigate,
            accountEndPoints.getInitialViews
          );
          await dispatch(getPeopleAccountDetails(navigate));
          handleChangeView(
            response?.data?.data?.default_view?.query_string || {},
            response?.data?.data?.default_view?.table_columns
          );
          setActionPerforming(false);
        } catch (err) {
          setActionPerforming(false);
        }
      }
    } catch (error) {
      setActionPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handlePinView = async () => {
    try {
      const View = payload?.active_view;
      setActionPerforming(true);
      setActionValue(STRINGS.PIN_THIS_VIEW);
      const response = await getRequest(
        navigate,
        accountEndPoints.pinLeadView + "?view-id=" + View?.view_id
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      handleChangeActiveView(View);
      setActionPerforming(false);
    } catch (error) {
      setActionPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  const viewsActionList = [
    {
      type: STRINGS.UNPIN_THIS_VIEW,
      action: handleUnpinView,
      icon: ICON_LABELS.PUSH_PIN,
      inPinnedList: true,
    },
    {
      type: STRINGS.MAKE_DEFAULT_VIEW,
      action: handleMakeDefaultView,
      icon: ICON_LABELS.BOOKMARK,
      inPinnedList: true,
    },
    {
      type: STRINGS.PIN_THIS_VIEW,
      action: handlePinView,
      icon: ICON_LABELS.PUSH_PIN,
      inPinnedList: false,
    },
  ];
  const addViewHandler = () => {
    setOpenAllViewModal(true);
  };

  const handleSaveView = async () => {
    const filteredQuery = filterDynamicQuery(
      allDynamicFiltersSelector,
      propertiesPayload
    );
    const SelectedTableIds = getSelectedColumns(selectedTableCells);
    const viewPayload = {
      query_string: filteredQuery,
      view_id: payload?.active_view?.view_id,
      table_columns: SelectedTableIds,
    };
    try {
      setActionPerforming(true);
      const response = await postRequest(
        navigate,
        accountEndPoints?.updateLeadView,
        viewPayload
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setActionPerforming(false);
      }
    } catch (error) {
      setActionPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setSaveViewEL(false);
    }
  };

  const handleChangeView = async (query, table_columns) => {
    await HandleClearAllFilterFromStore(dispatch, location, navigate);
    if (!table_columns.length) {
      table_columns = [
        ...tempHeadCompanyCells
          .filter((item) => item.isChecked)
          .map((item) => item.id),
      ];
    }

    const new_table_cells_payload = [];
    for (let i in tempHeadCompanyCells) {
      if (table_columns.includes(tempHeadCompanyCells[i].id)) {
        new_table_cells_payload.push({
          ...tempHeadCompanyCells[i],
          isChecked: true,
        });
      } else {
        new_table_cells_payload.push({
          ...tempHeadCompanyCells[i],
          isChecked: false,
        });
      }
    }
    await dispatch(dynamicUpdatedColumn(new_table_cells_payload));
    await dispatch(dynamicColumnUpdateHeaders(new_table_cells_payload));
    const res = query;
    if (res) {
      for (let i in res) {
        if (
          typeof res[i] === STRINGS.STRING &&
          stringQueryList?.indexOf(i) === -1
        ) {
          res[i] = [res[i]];
        }
      }
    }
    const initalMultipleFilterList = checkDynamicExistFields(res);
    dispatch(filterMultipleList(initalMultipleFilterList?.payload));
  };

  const handleChangeActiveView = async (view) => {
    try {
      const response = await getRequest(
        navigate,
        accountEndPoints.getInitialViews + `?view-id=${view.view_id}`
      );
      sessionStorage.setItem(STRINGS.ACTIVE_VIEW_ID, view?.view_id.toString());
      await dispatch(
        getPeopleAccountDetails(navigate, true, response?.data?.data)
      );
      handleChangeView(
        response?.data?.data?.active_view?.query_string || {},
        response?.data?.data?.active_view?.table_columns
      );
    } catch (error) {
      console.log(error);
    }
  };

  const viewTabIconHandler = (e, view) => {
    if (viewEL) {
      setViewEL(null);
      setViewClicked({});
    } else {
      setViewEL(e?.currentTarget);
      setViewClicked(view);
    }
    handleViewActionChange(view?.view_id);
  };

  const handleViewActionChange = (id) => {
    setShowViewActions(id);
  };

  const specialViewTabIconHandler = (e, view) => {
    if (spViewEL) {
      setSpViewEL(null);
    } else {
      setSpViewEL(e?.currentTarget);
    }
    handleViewActionChange(view?.view_id);
  };

  const handleDefaultViewClick = async () => {
    try {
      const response = await getRequest(
        navigate,
        accountEndPoints.getInitialViews +
          `?${STRINGS.VIEW_ID}=${payload?.default_view?.view_id}`
      );
      sessionStorage.setItem(
        STRINGS.ACTIVE_VIEW_ID,
        payload?.default_view?.view_id
      );

      await dispatch(
        getPeopleAccountDetails(navigate, true, response?.data?.data)
      );
      handleChangeView(
        response?.data?.data?.active_view?.query_string || {},
        response?.data?.data?.active_view?.table_columns
      );
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  const saveViewModal = (
    <div className={classes.sortingModal}>
      <div className={classes.containSaveText}>
        <TypographyInline
          label={STRINGS.SAVE_AS_CURRENT}
          size={"md"}
          fontWeight={fontWeight.MEDIUM}
          color={GREY.PRIMARY}
        />
      </div>
      <div className={classes.saveViewDisplayTxt}>
        <TypographyInline
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
          label={STRINGS.SAVE_TEXT}
        />
      </div>
      <div className={classes.containApplyBtn}>
        <div>
          <Button
            label={STRINGS.SAVE_AS_NEW}
            LeftIcon={ICON_LABELS.ADD}
            size={"sm36"}
            variant={"text"}
            onClick={() => {
              setOpenAddView(true);
              setSaveViewEL(null);
            }}
          />
        </div>
        <div className={classes.rightFooterSaveViewMenuButton}>
          <Button
            label={STRINGS.CANCEL}
            size={"sm36"}
            variant={"ghost"}
            onClick={() => setSaveViewEL(null)}
          />
          <Button
            label={STRINGS.SAVE}
            size={"sm36"}
            variant={"primary"}
            onClick={handleSaveView}
            disabled={payload?.active_view?.view_name === STRINGS.ALL_LEADS}
            isLoading={actionPerforming}
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const active_view_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);

    if (!mounted) {
      setMounted(true);
      dispatch(
        getPeopleAccountDetails(
          navigate,
          active_view_id
            ? `?${STRINGS.VIEW_ID}=${active_view_id}`
            : payload?.default_view?.is_main_view
            ? `?${STRINGS.VIEW_ID}=${payload?.default_view?.view_id}`
            : payload?.default_view?.view_id
            ? `?${STRINGS.VIEW_ID}=${payload?.default_view?.view_id}`
            : ""
        )
      );
    }
    if (
      Object.keys(payload?.default_view || {}).length &&
      !defaultQueryPushed &&
      Object.keys(propertiesPayload || {})?.length
    ) {
      const filters = filterDynamicQuery(
        filterMultipleListSelector,
        propertiesPayload
      );
      let query_string = [];

      if (active_view_id) {
        if (!payload?.default_view.is_main_view) {
          query_string = payload?.active_view?.query_string || {};
        } else if (active_view_id === payload?.default_view?.view_id) {
          query_string = filters;
        } else {
          query_string = payload?.active_view?.query_string || {};
        }
      } else {
        if (payload?.default_view?.is_main_view) {
          query_string = filters;
        } else {
          query_string = payload?.default_view?.query_string;
        }
      }

      const table_columns = payload?.default_view?.is_main_view
        ? payload?.active_view?.table_columns?.length
          ? payload?.active_view?.table_columns
          : tempHeadCompanyCells
              .filter((item) => item.companyField && item.isChecked)
              .map((item) => item.id)
        : payload?.active_view?.table_columns || [];
      handleChangeView(query_string, table_columns);
      setDefaultQueryPushed(true);
    }
  }, [allDynamicFiltersSelector, dispatch, navigate, payload, mounted]);

  return (
    <div className={classes.tabHeaderContainer}>
      <div className={classes.tabHeaderLeftContainer}>
        {/* Default View  */}
        <ViewTab
          tabLabel={
            loading ? STRINGS.LOADING : payload?.default_view?.view_name
          }
          isActive={
            payload?.default_view?.view_id === payload?.active_view?.view_id
          }
          isDefault
          RightIcon={ICON_LABELS.ACTION_ARROW}
          buttonClick={
            payload?.default_view?.view_id !== payload?.active_view?.view_id
              ? () => handleDefaultViewClick()
              : null
          }
        />

        {/* Pinned View */}
        {payload?.pinned_view?.map((view, index) => {
          return (
            <div>
              <ViewTab
                RightIcon={ICON_LABELS.ACTION_ARROW}
                tabLabel={view?.view_name}
                buttonClick={() => handleChangeActiveView(view)}
                isPin
                iconClick={(e) => {
                  viewTabIconHandler(e, view);
                }}
                isActive={view?.view_id === payload?.active_view?.view_id}
              />
            </div>
          );
        })}
        <CustomStyledMenu
          anchorEl={viewEL}
          open={Boolean(viewEL)}
          onClose={() => setViewEL(null)}
          width={"13vw"}
        >
          <div className={classes.pinnedViewActions}>
            {showViewActions === viewClicked?.view_id && (
              <div onClick={(e) => viewTabIconHandler(e, viewClicked)}>
                {viewsActionList?.map((action, index) => {
                  return viewClicked?.is_main_view &&
                    action.type === STRINGS.UNPIN_THIS_VIEW
                    ? null
                    : action.inPinnedList && (
                        <div
                          key={action.type}
                          onClick={() => action.action()}
                          className={
                            viewsActionList?.length - 1 === index
                              ? classes.borderFlex1
                              : classes.borderFlex1
                          }
                        >
                          <IconComponent
                            color={
                              showActions + hoverIndex ===
                              viewClicked.view_id + index
                                ? BRAND.PRIMARY
                                : GREY.TERTIARY
                            }
                            fontSize={fontSize.MD}
                            iconLabel={action.icon}
                          />
                          <TypographyInline
                            label={
                              actionValue === action.type && actionPerforming
                                ? STRINGS.LOADING
                                : action.type
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                            color={
                              showActions + hoverIndex ===
                              viewClicked.view_id + index
                                ? BRAND.PRIMARY
                                : GREY.SECONDARY
                            }
                          />
                        </div>
                      );
                })}
              </div>
            )}
          </div>
        </CustomStyledMenu>
        {/* Pinned View */}

        {/* Special Case */}
        {!payload?.active_view?.is_main_view &&
        payload?.active_view?.temp_active_view &&
        payload?.default_view?.view_id !== payload?.active_view?.view_id &&
        !payload?.pinned_view?.filter(
          (view) => view?.view_id === payload?.active_view?.view_id
        ).length ? (
          <div>
            <ViewTab
              RightIcon={ICON_LABELS.ACTION_ARROW}
              tabLabel={payload?.active_view?.view_name}
              buttonClick={() => handleChangeActiveView(payload?.active_view)}
              iconClick={(e) => {
                specialViewTabIconHandler(e, payload?.active_view);
              }}
              isActive
            />
          </div>
        ) : null}
        <CustomStyledMenu
          anchorEl={spViewEL}
          open={Boolean(spViewEL)}
          onClose={() => setSpViewEL(null)}
          noMargin={true}
          width={"15vw"}
          // arrow
        >
          <div className={classes.pinnedViewActions}>
            {showViewActions === payload?.active_view?.view_id && (
              <div
                onClick={() =>
                  handleViewActionChange(payload?.active_view?.view_id)
                }
              >
                {viewsActionList?.map((action, index) => {
                  return (
                    !action.inPinnedList && (
                      <div
                        key={action.type}
                        onClick={() => action.action()}
                        className={
                          viewsActionList?.length - 1 === index
                            ? classes.noBorderFlex
                            : classes.borderFlex
                        }
                      >
                        <IconComponent
                          color={
                            showActions + hoverIndex ===
                            payload?.active_view?.view_id + index
                              ? BRAND.PRIMARY
                              : GREY.TERTIARY
                          }
                          fontSize={fontSize.MD}
                          iconLabel={action.icon}
                        />
                        <TypographyInline
                          label={
                            actionValue === action.type && actionPerforming
                              ? STRINGS.LOADING
                              : action.type
                          }
                          size={"sm"}
                          fontWeight={fontWeight.REGULAR}
                          color={
                            showActions + hoverIndex ===
                            payload?.active_view?.view_id + index
                              ? BRAND.PRIMARY
                              : GREY.SECONDARY
                          }
                        />
                      </div>
                    )
                  );
                })}
              </div>
            )}
          </div>
        </CustomStyledMenu>
        {/* Special Case */}

        <Button
          LeftIcon={ICON_LABELS.CLEAR_ALL}
          variant={"text"}
          size={"sm36"}
          label={STRINGS.MANAGE_VIEWS}
          onClick={addViewHandler}
        />
      </div>
      <div className={classes.tabHeaderRightContainer}>
        <Button
          LeftIcon={ICON_LABELS.SAVE}
          RightIcon={ICON_LABELS.ACTION_ARROW}
          variant={"ghost"}
          size={"sm36"}
          onClick={(e) => setSaveViewEL(e.currentTarget)}
          label={STRINGS.SAVE_VIEW}
        />

        {/* Save View Content Here(button with Menu) */}

        <CustomStyledMenu
          open={Boolean(saveViewEL)}
          anchorEl={saveViewEL}
          onClose={() => setSaveViewEL(null)}
          noMargin={true}
          width="19.792vw"
        >
          {saveViewModal}
        </CustomStyledMenu>
        {/* Save View Content Here(button with Menu) */}

        {/* Manage View Modal */}
        <AllViewModal
          openAddViewModal={openAllViewModal}
          setOpenAddViewModal={setOpenAllViewModal}
          leadType={STRINGS.PEOPLE}
        />
        {/* Manage View Modal */}

        {/* Add View Modal */}
        {openAddView && (
          <AddViewModal open={openAddView} setOpen={setOpenAddView} />
        )}
        {/* Add View Modal */}

        {/* Create View Modal */}

        {/* Create View Modal */}
      </div>
    </div>
  );
};

export default ContactHeader;
