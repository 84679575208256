import { Box, Grid, Modal } from "@mui/material";

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { INVISIBLE_SCROLL_BAR } from "../../../utils/constants/styles";
import { Tab, Tabs } from "@mui/material";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import { postRequest } from "../../../Apis";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  DEFAULT_VALUES_BRANDING,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../utils/constants";
import { CloseIcon } from "../../../components/Icon/MuiIcons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { CircularLoader, ErrorMessage } from "../../../components";

import { patchRequest } from "../../../Apis/apiWrapper";
import BrandingDelete from "./BrandingDelete";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { getBrandDetailsList } from "../../../redux/actions/brandKitDetailsActions";
import { STRINGS } from "../strings";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { BASE, BORDER, BRAND, GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import { Button } from "../../../components/Buttons";
import {
  SCROLL_BAR,
  borderRadius,
  fontSize,
} from "../../../utils/constants/UI/uiConstants";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import AvatarName from "../../../components/Avatar/AvatarName";
import CustomDialog from "../../../components/Dialog/CustomDialog";
import IconComponent from "../../../components/Icon/IconComponent";
import { convertBase64, innerBorder } from "../../../utils/helpers";
import InputField from "../../../components/InputFields/InputField";
import { Mixpanel } from "../../../Apis/Mixpanel";

export const styles = makeStyles({
  IMAGEPREVIEW: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
  },
  setLoad: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    width: "80%",
    position: "relative",
  },
  actionIconsDisabledContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    cursor: "pointer",
    background: BORDER.DISABLED,
    boxShadow: innerBorder(BORDER.DISABLED),
    borderRadius: borderRadius.primary,
    cursor: "not-allowed",
  },
  widthVw: {
    width: "5vw",
  },
  actionIconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    cursor: "pointer",
    boxShadow: innerBorder(BORDER.DARK),
    borderRadius: borderRadius.primary,
  },
  accountDefaultInput: {
    background: "#F7F9FB",
    border: "1px solid #D3DAE3",
    padding: "1rem",
    borderRadius: "4px",
    width: "100%",
    margin: "1rem 0",
    fontWeight: "400",
    fontSize: "14px",
    color: "#0F2F51",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff4848",
      borderWidth: "0.1rem",
    },
  },
  importPara: {
    padding: "2rem",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  icon2: {
    color: "#626b7e",
  },
  typo: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
  },
  paperMenu: {
    height: "11vh",
    ...SCROLL_BAR,
    width: "28vw",
  },
  GAPS: {
    paddingTop: "8px",
    paddingBottom: "8px",
    display: "flex",
    gap: "12px",
  },
  Avtar: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    cursor: "pointer",
    width: "100%"
  },
  btnfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
  },
  accountDefaultInputt: {
    background: "#FFFFFF",
    border: "1px solid #D3DAE3",
    padding: "1rem",
    borderRadius: "4px",
    width: "100%",
    margin: "1rem 0",
    fontWeight: "400",
    fontSize: "14px",
    color: "#0F2F51",
    display: "flex",
    justifyContent: "space-between",
  },
  Border: {
    border: "1px solid #D3DAE3",
    padding: "1rem",
    borderRadius: "4px",
  },
  commonLabells: {
    fontSize: `${fontSize.SM}  !important`,
    color: `${BRAND.PRIMARY} !important`,
    fontWeight: `${fontWeight.SEMIBOLD} !important`,
  },
  commonLabell: {
    fontSize: `${fontSize.SM} !important`,
    color: `${GREY.QUATINARY} !important`,
    fontWeight: `${fontWeight.SEMIBOLD} !important`,
  },
  Colorcode: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#626B7E",
    margin: "1.5rem 1rem",
  },
  displayFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  MaxCont: {
    display: "flex",
    width: "max-content",
    paddingTop: "16px",
  },
  imagescss: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
  },
  imageattach: {
    color: "#626b7e",
    fontSize: fontSize.SM,
  },
  FLEXED: { display: "flex", justifyContent: "center", alignItems: "center" },
  Green: {
    background: "#13CA7A",
    width: "121px",
    height: "35px",
    margin: "1rem",
  },
  Purple: {
    background: "#8414F3",
    width: "121px",
    height: "35px",
    margin: "1rem",
  },
  Blue: {
    background: "#1CD6E3",
    width: "121px",
    height: "35px",
    margin: "1rem",
  },
  Orange: {
    background: "#FFA033",
    width: "121px",
    height: "35px",
    margin: "1rem",
  },
  InputColors: {
    border: "none",
    background: "transparent",
    width: "121px",
    height: "41px",
    margin: "0.8rem",
    cursor: "pointer",
  },
  CLICK: {
    color: BRAND.PRIMARY,
    marginRight: "4px",
    cursor: "pointer",
    fontSize: fontSize.SM,
    fontWeight: fontWeight.MEDIUM,
  },

  textBox1: {
    width: "100%",
    border: "1px solid #D3DAE3",

    "&  .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      background: "transparent !important",
    },
    "& .MuiSelect-select": {
      paddingLeft: "10px",
    },

    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  PaddingBox: {
    overflow: "auto",
    ...SCROLL_BAR,
    paddingTop: "0rem",
    paddingLeft: "0rem",
    paddingRight: "0rem",
  },
  MoreVertIcon: {
    cursor: "pointer",
    color: "grey",
    display: "flex",
    justifyContent: "flex-end",
    margin: "1rem",
  },
  accountInput: {
    height: "45px",
    background: "#F7F9FB",
    border: "1px solid #D3DAE3",
    padding: "1rem",
    borderRadius: "4px",
    width: "90%",
    marginLeft: "15px",
    fontWeight: "400",
    fontSize: "14px",
    color: "#0F2F51",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff4848",
      borderWidth: "0.1rem",
    },
  },

  dialog: {
    position: "fixed",
    top: 0,
    right: 0,
    marginLeft: "70vw",
  },
  dropdownLabel: {
    padding: "10px 18px 13px 10px !important",
    "&:focus": {
      backgroundColor: "red",
    },
  },
  dropDownIcon: {
    position: "absolute",
    right: "5px",
    pointerEvents: "none",
    color: "#626B7E",
  },
  accountDefaultContainer: {
    background: "white",
    padding: "1rem",
    borderRadius: "4px",
    height: "calc(100vh - 6rem)",
    overflow: "scroll",
    "@media (max-width: 768px)": {
      background: "unset",
    },
    ...INVISIBLE_SCROLL_BAR,
  },
  container: {
    margin: "16px 0px 0px 16px",
    borderRadius: "6px",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  commonMarginDisplay: {
    borderBottom: " 0.708129px solid #D3DAE3",
  },
  accountDefaultFlex: {
    display: "flex",
    justifyContent: "space-between",
    maxHeight: "calc(100vh - 7rem)",
    overflow: "auto",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainTitle: {
    fontWeight: "600",
    color: "#102F51",
    fontSize: "20px",
    alignSelf: "center",
  },

  callingPara: {
    color: "#616A7D",
    fontWeight: "400",
    fontSize: "12px",
    padding: "0 0 1rem 0",
    lineHeight: "1.6",
    paddingTop: "6px",
  },
  BrandingBtn: {
    background: "#ff4848",
    padding: "0.7rem 1.5rem",
    border: "1px solid #ff4848",
    color: "white",
    borderRadius: "6px",
    cursor: "pointer",

    "&:hover": {
      transition: "300ms",
      background: "#0F2F51",
      color: "white",
      border: "1px solid #0F2F51",
    },
  },
  Save: {
    background: "#ff4848",
    margin: "1rem",
    marginLeft: "0rem",
    padding: "0.7rem 1.5rem",
    border: "1px solid #ff4848",
    color: "white",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      transition: "300ms",
      background: "#0F2F51",
      color: "white",
      border: "1px solid #0F2F51",
    },
  },
  DisableSave: {
    background: "lightGrey",
    margin: "1rem",
    marginLeft: "0rem",
    padding: "0.7rem 1.5rem",
    border: "1px solid lightGrey",
    color: "Black",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      transition: "300ms",
      background: "lightGrey",
      color: "Black",
    },
  },
  filterHeader: {
    display: "flex",
    position: "fixed",
    width: "100%",
    zIndex: "1",
  },
  containIcon: {
    backgroundColor: GREY.QUATINARY,
    padding: "clamp(1rem,2vh,3rem)",
    display: "flex",
    color: BASE.WHITE,
    cursor: "pointer",
  },
  icon: {
    color: BASE.WHITE,
  },
  containText: {
    color: BASE.WHITE,
    fontSize: fontSize.MD,
  },
  containFilterText: {
    backgroundColor: GREY.QUATINARY,
    color: BASE.WHITE,
    width: "100%",
    display: "flex",
    padding: "0 1rem",
    alignItems: "center",
    fontSize: fontSize.MD,
  },
  Brand: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#212833",
  },
  LOGO: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    fontSize: "20px",
    fontWeight: "600",
    color: "#212833",
  },
  input: {
    width: "100%",
    margin: "8px 0 0.5rem 0rem ",
  },
  KIT: {
    width: "100%",

    background: " #F7F9FB",

    border: "1px solid #D3DAE3",
    borderRadius: "6px 6px 6px 6px",
  },
  KIT2: {
    width: "100%",
    height: "300px",

    marginBottom: "1rem",
    background: " #F7F9FB",

    border: "1px solid #D3DAE3",
    borderRadius: "6px 6px 6px 6px",
  },
  Resource: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#212833",
    padding: "1rem",
    borderBottom: "1px solid #D3DAE3",
  },
  Logo_name: {
    color: "#212833",
    fontSize: "14px",
    fontWeight: "500",
    padding: "1rem",
  },
  Logo_nameWidth: {
    width: "55%",
    color: "#212833",
    fontSize: "14px",
    fontWeight: "500",
    padding: "1rem",
  },
  PaddingTp: {
    paddingTop: "8px",
  },
  BoxColor: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #D3DAE3",
    marginTop: "1rem",
    borderRadius: "4px",
    boxShadow: "0px 2px 3px rgba(98, 107, 126, 0.11)",
  },
  IMAGEBS: {
    paddingBottom: "5px",
    paddingLeft: "1rem",
    width: "120px",
    objectFit: "contain",
    // height: "100px",
  },
  BUTTON: { display: "flex", width: "max-content", paddingTop: "16px" },

  Hovering: {
    cursor: "pointer",
  },
});
const Branding = () => {
  const [attach, setAttach] = useState("");

  const [imagebs, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState("#ff4848");
  const [openModal, setOpenModal] = useState(false);
  const [record, setRecord] = useState("");
  const [values, setValues] = useState(0);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState("");
  const size = "";
  const [brandKit, setBrandKit] = useState("");

  const [brandKitId, setBrandKitId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [priority, setPriority] = useState(
    [DEFAULT_VALUES_BRANDING[1]?.label] || []
  );

  const [validations, setValidations] = useState({ brandKit: "" });
  const [disable, setDisable] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const brandingDetailsSelector = useSelector(
    (state) => state?.getBrandDetailsList
  );
  const handleClose = () => {
    setValues(0);
    setUpdate("");
    setAttach("");
    setBrandKitId("");
    setBrandKit("");
    setValidations({
      brandKit: "",
    });
    setPriority([DEFAULT_VALUES_BRANDING[1]?.label]);
    setImage("");
    setOpen(!open);
  };

  const count = brandingDetailsSelector?.payload?.data?.filter(
    (val) => val?.priority === STRINGS.SECONDARY
  );
  const handleChanges = (event, newValues) => {
    setValues(newValues);
  };
  const opened = Boolean(anchorEl);

  const classes = styles();
  const handleSave = async () => {
    try {
      if (brandKit) {
        setValidations({
          brandKit: "",
        });
        if (count.length >= 5) {
          EventEmitter.emit(EMITTER_ERROR, STRINGS.MAX_SEC);
        } else {
          const payload = {
            brand_kit_name: brandKit,
            priority: priority[0],
            logo: {
              logo_url: imagebs,
            },
            file_name: attach,
            background_color: color,
          };
          setLoading(true);
          const response = await postRequest(
            navigate,
            authEndpoints.createAccountBranding,
            payload
          );
          Mixpanel.track(STRINGS.BRANDING_CREATE_SUCCESS);

          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          (async () => {
            dispatch(getBrandDetailsList());
          })();
          setLoading(false);
          setOpen(false);
          setAttach("");
          setImage("");
        }
      } else {
        setValidations({
          brandKit: STRINGS.REQUIRED,
        });
      }
    } catch (error) {
      console.log(error?.data?.message, { error });
      EventEmitter?.emit(EMITTER_ERROR, error?.data?.message);

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      dispatch(getBrandDetailsList());
    })();
  }, [dispatch]);

  const handleUpdateBrandKit = (val) => {
    setUpdate("Update");
    setBrandKitId(val?._id);
    setBrandKit(val?.brand_kit_name);

    setPriority([val?.priority]);

    setImage(val?.logo?.logo_url);
    setAttach(val?.file_name);

    setColor(val?.background_color);
    setOpen(!open);
  };

  const handleUpdate = () => {
    try {
      setLoading(true);
      (async () => {
        if (count.length >= 5 && priority === STRINGS.SECONDARY) {
          EventEmitter.emit(EMITTER_ERROR, STRINGS.MAX_SEC);
        } else {
          const payload = {
            brand_id: brandKitId,
            brand_kit_name: brandKit,
            priority: priority[0],
            logo: {
              logo_url: imagebs,
            },
            file_name: attach,
            background_color: color,
          };

          const response = await patchRequest(
            navigate,
            authEndpoints.brandKitUpdate,
            payload
          );
          Mixpanel.track(STRINGS.BRANDING_UPDATE_SUCCESS);

          setLoading(false);
          setOpen(!open);
          setAttach("");
          setImage("");
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

          (async () => {
            dispatch(getBrandDetailsList());
          })();
        }
      })();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const deleteMethod = (name, id) => {
    setBrandKit(name);
    setRecord(id);
    setAnchorEl(null);
    setOpenModal(true);
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];

    setAttach(file?.name);
    const base64 = await convertBase64(file);
    setImage(base64);
    let base64String = base64;
    let stringLength = base64String.length - "data:image/png;base64,".length;
    let sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    let sizeInKb = sizeInBytes / 1000;

    setDisable(sizeInKb);
  };

  const dialogHeader = {
    heading: {
      id: STRINGS.MY_BRAND,
      label: STRINGS.MY_BRAND,
    },
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.accountDefaultContainer}>
        <Box className={classes.flexBetween}>
          <Box>
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.BRANDING}
            />
            <TypographyInline
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.BRANDING_DESC}
            />
            <div className={classes.BUTTON}>
              <Button
                label={STRINGS.CREATE_BRAND}
                size={"sm36"}
                variant={"primary"}
                onClick={handleClose}
              />
            </div>
          </Box>
          <CustomDialog
            header={dialogHeader}
            open={open}
            onClose={handleClose}
            width={"30vw"}
          >
            <Box className={classes.PaddingBox}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.BRAND_NAME}
              />

              <Box className={classes.input}>
                {" "}
                <InputField
                  size={"sm36"}
                  variant={
                    validations?.brandKit?.length > 1
                      ? STRINGS.ERROR
                      : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations?.brandKit ? validations?.brandKit : ""
                  }
                  type="text"
                  placeholder="Write Your BrandKit Name. "
                  value={brandKit}
                  onChange={(e) => setBrandKit(e.target.value)}
                />
              </Box>

              <Box>
                <TypographyInline
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.PRIORITY_NAME}
                />
                <Box className={classes.PaddingTp}>
                  <AutoCompleteCustom
                    width={"96px"}
                    items={DEFAULT_VALUES_BRANDING}
                    LeftIcon=""
                    valueState={priority}
                    setValueState={setPriority}
                    idState={priority}
                    setIdState={setPriority}
                    papermenuclass={classes.paperMenu}
                    disabled={priority === STRINGS.PRIMARY ? true : false}
                    size={"md40"}
                  />
                </Box>
              </Box>
              <Box className={classes.commonMarginDisplay}>
                <Tabs
                  value={values}
                  onChange={handleChanges}
                  textColor="red"
                  indicatorColor="inherit"
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    sx: {
                      background: "red",
                      fontSize: "14px !important",
                      textTransform: "lowercase",
                      color: "red !important",
                    },
                  }}
                >
                  <Tab
                    className={
                      values == "0"
                        ? classes.commonLabells
                        : classes.commonLabell
                    }
                    values="0"
                    label="Logo"
                  />
                  <Tab
                    className={
                      values == "1"
                        ? classes.commonLabells
                        : classes.commonLabell
                    }
                    label="Colors"
                    values="1"
                  />
                </Tabs>
              </Box>

              {values === 0 ? (
                <>
                  <Box className={classes.typo}>
                    {" "}
                    <TypographyInline
                      size={"md"}
                      color={GREY.PRIMARY}
                      fontWeight={fontWeight.MEDIUM}
                      label={STRINGS.SELECT_LOGO}
                    />
                  </Box>
                  <Box className={classes.KIT}>
                    <div className={classes.Resource}>
                      {" "}
                      <TypographyInline
                        size={"sm"}
                        color={GREY.QUATINARY}
                        fontWeight={fontWeight.REGULAR}
                        label={STRINGS.BRAND_KIT}
                      />{" "}
                    </div>

                    <Grid
                      item
                      xs={12}
                      sx={{ position: "relative", marginLeft: "1rem" }}
                    >
                      <input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        fullWidth
                        style={{
                          mb: 1,
                          opacity: "0",
                          position: "absolute",
                          width: "80%",
                          height: "20%",
                          cursor: "pointer",
                        }}
                        name="attach"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <Box className={classes.containDropBox}>
                        <div className={classes.importPara}>
                          {" "}
                          <div style={{ display: "flex", gap: "4px" }}>
                            <TypographyInline
                              size={"sm"}
                              color={BRAND.PRIMARY}
                              fontWeight={fontWeight.REGULAR}
                              label={STRINGS.CLICK_UPLOAD}
                            />{" "}
                            <TypographyInline
                              size={"sm"}
                              color={GREY.PRIMARY}
                              fontWeight={fontWeight.REGULAR}
                              label={STRINGS.DRAG}
                            />{" "}
                          </div>
                          <div>
                            <TypographyInline
                              size={"sm"}
                              color={GREY.PRIMARY}
                              fontWeight={fontWeight.REGULAR}
                              label={STRINGS.JPG_IMAGE}
                            />{" "}
                          </div>
                          <input
                            type="file"
                            accept="image/x-png,image/gif,image/jpeg"
                            fullWidth
                            style={{
                              mb: 1,
                              opacity: "0",
                              position: "absolute",
                              width: "80%",
                              height: "20%",
                              cursor: "pointer",
                            }}
                            name="attach"
                            onChange={(e) => {
                              uploadImage(e);
                            }}
                          />
                        </div>
                      </Box>{" "}
                      {size && <ErrorMessage message={size} />}
                    </Grid>
                    {imagebs ? (
                      <div className={classes.IMAGEPREVIEW}>
                        <img className={classes.IMAGEBS} src={imagebs} />
                      </div>
                    ) : (
                      ""
                    )}
                    {imagebs ? (
                      <Box className={classes.imagebs}>
                        <Box className={classes.GAPS}>
                          {" "}
                          <Box className={classes.imagescss}>
                            <Box className={classes.imageattach}>
                              {" "}
                              {attach ? attach : ""}
                            </Box>{" "}
                          </Box>
                          <Box className={classes.FLEXED}>
                            {imagebs && attach ? (
                              <CloseIcon
                                fontSize="small"
                                className={classes.icon2}
                                onClick={() => {
                                  setAttach("");
                                  setImage("");
                                }}
                              />
                            ) : (
                              ""
                            )}{" "}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                  {disable > 200 && (
                    <ErrorMessage message={STRINGS.ERR_MESSAGE} />
                  )}
                  <Box>
                    {" "}
                    <div className={classes.GAPS}>
                      <TypographyInline
                        size={"md"}
                        color={GREY.PRIMARY}
                        fontWeight={fontWeight.SEMIBOLD}
                        label={STRINGS.INSTRUCTIONS}
                      />{" "}
                    </div>
                    <TypographyInline
                      size={"sm"}
                      color={GREY.QUATINARY}
                      fontWeight={fontWeight.REGULAR}
                      label={STRINGS.DESC_INST1}
                    />
                    <TypographyInline
                      size={"sm"}
                      color={GREY.QUATINARY}
                      fontWeight={fontWeight.REGULAR}
                      label={STRINGS.DESC_INST2}
                    />
                  </Box>
                  <Box>
                    {" "}
                    <Box className={classes.MaxCont}>
                      <Button
                        label={
                          update === STRINGS.UPDATE
                            ? STRINGS.UPDATE
                            : STRINGS.SAVE
                        }
                        onClick={() =>
                          update === STRINGS.UPDATE
                            ? handleUpdate()
                            : handleSave()
                        }
                        disabled={disable > 200}
                        size={"sm36"}
                        variant={"primary"}
                        isLoading={loading}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <Box>
                  {" "}
                  <div className={classes.GAPS}>
                    <TypographyInline
                      size={"md"}
                      color={GREY.PRIMARY}
                      fontWeight={fontWeight.REGULAR}
                      label={STRINGS.COLORS}
                    />{" "}
                  </div>
                  <TypographyInline
                    size={"sm"}
                    color={GREY.QUATINARY}
                    fontWeight={fontWeight.REGULAR}
                    label={STRINGS.BACK_COLOR}
                  />
                  <Box className={classes.BoxColor}>
                    <Box className={classes.displayFlex}>
                      <div>
                        <input
                          type="color"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                          className={classes.InputColors}
                        />
                      </div>
                      <div className={classes.widthVw}>
                        <InputField
                          size={"sm36"}
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        />
                      </div>
                    </Box>
                  </Box>
                  <Box className={classes.MaxCont}>
                    <Button
                      label={
                        update === STRINGS.UPDATE
                          ? STRINGS.UPDATE
                          : STRINGS.SAVE
                      }
                      onClick={() =>
                        update === STRINGS.UPDATE
                          ? handleUpdate()
                          : handleSave()
                      }
                      disabled={disable > 200}
                      size={"sm36"}
                      variant={"primary"}
                      isLoading={loading}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </CustomDialog>
        </Box>

        {brandingDetailsSelector?.loading ? (
          <div className={classes.setLoad}>
            <CircularLoader />
          </div>
        ) : (
          brandingDetailsSelector?.payload?.data?.map((val, i) => (
            <Box className={classes.accountDefaultInputt}>
              <Box className={classes.Avtar} onClick={() => handleUpdateBrandKit(val)}>
                <Box>
                  <AvatarName name={val?.brand_kit_name} size={"small"} />
                </Box>
                <Box className={classes.Hovering}>
                  <TypographyInline
                    size={"sm"}
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.SEMIBOLD}
                    label={val?.brand_kit_name}
                  // onClick={() => handleUpdateBrandKit(val)}
                  />
                  <TypographyInline
                    size={"sm"}
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    label={val?.priority}
                  />
                </Box>
              </Box>

              <div
                className={
                  val?.operation == true
                    ? classes.actionIconsDisabledContainer
                    : classes.actionIconsContainer
                }
              >
                <IconComponent
                  disabled={val?.operation == true ? true : false}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.DELETE}
                  onClick={() =>
                    val?.operation == true
                      ? ""
                      : deleteMethod(val?.brand_kit_name, val?._id)
                  }
                />
              </div>
            </Box>
          ))
        )}
      </Box>

      <Modal onClose={() => setOpenModal(false)} open={openModal}>
        <BrandingDelete
          openMeet={openModal}
          setOpenMeet={setOpenModal}
          name={brandKit}
          id={record}
        />
      </Modal>
    </Box>
  );
};

export default Branding;
