import { Styles } from "../Styles";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";

export const RemoveDashboardPopUp = (props) => {
  const classes = Styles();

  const cancelHandle = () => {
    props.handleRemovePopUp();
  };

  const removeHandle = () => {
    let payload = {
      id: props.dashBoard?._id,
    };
    props.deleteDashboard(payload, props.handleRemovePopUp);
  };

  const modalHeaderData = {
    heading: {
      id: "delete-dashboard",
      label: `${STRINGS.DELETE} "${props.dashBoard?.dashboard_name}"`,
    },
  };

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.DELETE}
        size={"sm36"}
        variant={"primary"}
        onClick={removeHandle}
        isLoading={props.loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={props?.open}
      close={cancelHandle}
      variant={"error"}
      footer={{ right: modalFooterRightData }}
      header={modalHeaderData}
      width={"27.865vw"}
    >
      <TypographyInline
        label={STRINGS.DELETE_WARNING_TEXT}
        size={"sm"}
        fontWeight={fontWeight.REGULAR}
        color={GREY.SECONDARY}
      />
    </CustomModal>
  );
};
