import { makeStyles } from "@mui/styles";
import { LEFT_IMAGE, RIGHT_IMAGE } from "../../utils/constants/assets";
import {
  SCROLL_BAR,
  fontSize,
  fontWeight,
} from "../../utils/constants/UI/uiConstants";
import { BRAND, GREY, PRIMARY } from "../../utils/constants/colors";

export const styles = makeStyles({
  privacyLink: {
    fontSize: fontSize.XS,
    fontWeight: fontWeight.REGULAR,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: GREY.SECONDARY,
    transition: "100ms",
    "&:hover": {
      color: BRAND.PRIMARY,
    },
  },
  TypoHead: {
    marginLeft: "0.2rem",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  mypass: {
    paddingLeft: "4px",
    cursor: "pointer",
    "& :hover": {
      color: PRIMARY.W_600,
    },
  },
  hovering: {
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  container: {
    flexDirection: "column",
    justifyContent: "space-around",
    display: "flex",
    height: "100vh",
    ...SCROLL_BAR,
    background: `url(${LEFT_IMAGE}) right top no-repeat ,url(${RIGHT_IMAGE}) left bottom no-repeat`,
  },
  topContainer: {
    height: "50vh",
    backgroundSize: "cover",
    backgroundBlendMode: "multiply",
    // background: `url(${LOGIN_BACKGROUND}) 0 0 no-repeat rgba(211, 218, 227, 0.2)`,
    position: "relative",
  },
  bottomContainer: {
    height: "50vh",
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem",
    flexDirection: "column",
  },
  reachiq_logo: {
    width: "clamp(80px,10vw,200px)",
    height: "clamp(80px,10vh,200px)",
    objectFit: "contain",
  },
  containLogo: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "100px",
  },
  modal: {
    position: "absolute",
    width: "420px",
    // top: "50%",
    left: "50%",
    transform: "translate(-50%, 0)",
    background: "white",
    paddingTop: "60px",
    // boxShadow: "0px 3px 10px rgb(0 0 0 / 8%)",
    borderRadius: "8px",
    // "@media (max-width: 768px)": {
    //   width: "90%",
    // },
    // "@media (min-width: 1024px)": {
    //   width: "40%",
    // },
  },
  policyPara: {
    textAlign: "center",
    fontWeight: "500",
    color: "#626B7E",
    fontSize: "14px",
  },
  resetTitle: {
    textAlign: "center",
    // fontSize: "clamp(1.2rem, 1.8vw,3rem)",
    // fontWeight: "400",
    // color: "#212833",
  },
  containInstructions: {
    paddingTop: "4px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  containInstruct: {
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    rowGap: "4px",
    textAlign: "center",
  },
  containInput: {
    display: "flex",
    flexDirection: "column",
    background: "#fafbfc",
    padding: "0.8rem clamp(1rem, 2vw, 3rem)",
    border: "1px solid #dee2e6",
    borderRadius: "4px",
  },
  input: {
    background: "unset",
    border: "none",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "5px",
    color: "#1d2029",
  },
  inputLable: {
    fontWeight: "500",
    fontSize: "12px",
  },
  submitButton: {
    background: "#ff4848",
    border: "none",
    color: "white",
    // boxShadow: "0px 9px 20px #ff16543c",
    fontWeight: "600",
    width: "100%",
    padding: "1.2rem",
    marginTop: "clamp(1.5rem,4vh, 4rem)",
    borderRadius: "8px",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0f2f51",
    },
  },
  email: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    paddingTop: "32px",
  },
  btnContainer: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "center",
  },
  termsPara: {
    fontSize: "clamp(12px, 0.729vw, 30px)",
    color: "#626B7E",
    fontWeight: "400",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  containPolicy: {
    display: "flex",
    justifyContent: "center",
  },

  mypassword: {
    display: "flex",
    alignItems: "center",
    fontSize: "clamp(12px, 0.729vw, 30px)",
    fontWeight: "500",
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",

    color: "#ff4848",
  },
  privacyLinkSeperatorText: {
    fontSize: fontSize.SM,
    fontWeight: fontWeight.MEDIUM,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: "#626B7E",
  },
  inputLabelText: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontSize: "clamp(12px, 0.729vw, 24px)",
    fontWeight: "500",
    marginBottom: "4px",
    color: "#212833",
  },
});
