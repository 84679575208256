import { makeStyles } from "@mui/styles";
import { BACKGROUND_COLOR } from "../../../theme/colors";
import { INVISIBLE_SCROLL_BAR } from "../../../utils/constants/styles";
import { BORDER } from "../../../utils/constants/colors";

export const styles = makeStyles({
  textfield: {
    background: "white",

    margin: "0.4rem 0 0 0 !important",
    height: "10px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D3DAE3",
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-root": {
      height: "45px",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D3DAE3",
    },
    "& .MuiOutlinedInput-input": {
      color: "#626b7e",
      padding: "5px !important",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#626b7e",
    },
    // imput value
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#1D2029",
    },
    "& .MuiInputLabel-outlined": {
      color: "#626b7e",
      fontSize: "14px",
      marginTop: "-4px",
    },
    //onselect label
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#626b7e",
    },
    // on select border
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D3DAE3",
      borderWidth: "0.1rem",
    },
  },
  flex: {
    display: "flex",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  userTableContainer: {
    margin: "1rem",
    height: "calc(100% - 80px)",
    width: "calc(100% - 32px)",
    border: `1px solid ${BORDER.DEFAULT}`,
  },
  padUs:{
    padding:"1rem 1rem 0 1rem"
  },
  multiSelectContain: {
    display: "flex",
    padding: "0 1rem",
    flexDirection: "column",
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "1rem",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
  },
  dropdownContain: {
    marginTop: "5px",
  },
  dropdownAllContain: {
    height: "5vh",
    alignSelf: "center",
    margin: "0 0 10px 10px",
  },
  marginVertical: {
    margin: "1rem 0",
  },
  marginHorizontal: {
    margin: "0 1rem",
  },

  containNationalDropdown: {
    display: "flex",
    marginTop: "0.4rem",
  },
  container: {
    margin: "16px 0 0 16px",
    borderRadius: "6px",
    height: "100%",
    "@media (max-width: 768px)": {
      background: "unset",
      width: "100%",
    },
  },
  GAP: {
    display: "flex",
    gap: "4px",
  },
  paddingLeftData:{
   paddingLeft:"16px"
  },
  InviteTable: {
    width: "calc(100% - 32px)",
    height: "calc(100% - 240px)",
    border: "1px solid #E6E6E6",
    margin: "1rem",
  },
  container2: {
    margin: "1rem",
    borderRadius: "6px",

    "@media (max-width: 768px)": {
      background: "unset",
      width: "100%",
    },
  },
  componentContainer: {
    background: "white",
    // padding: "1rem",
    margin: "0 0 1rem 0",
    borderRadius: "6px",
    height: "calc(100vh - 6rem)",

    "@media (max-width: 768px)": {
      background: "unset",
      paddingRight: "1rem",
      paddingLeft: "0.5rem",
    },
  },
  mainTitle: {
    fontWeight: "600",
    color: "#102F51",
    fontSize: "20px",
    alignSelf: "center",
  },
  title: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "20px",
  },
  callingPara: {
    color: "#616A7D",
    fontWeight: "400",
    fontSize: "12px",
    padding: "0 0 1rem 0",
    lineHeight: "1.6",
    paddingTop: "6px",
  },
  redPara: {
    color: "#ff4848",
    fontWeight: "400",
    fontSize: "12px",
    cursor: "pointer",
  },
  pointsPara: {
    color: "#616A7D",
    fontWeight: "400",
    fontSize: "14px",
  },
  greyText: {
    color: "#616A7D",
    fontWeight: "400",
    fontSize: "12px",
    padding: "1rem 0",
    lineHeight: "1.6",
  },
  btn: {
    background: "#ff4848",
    padding: "10px 20px",
    border: "1px solid #ff4848",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "400",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      transition: "300ms",
      background: "white",
      color: "#ff4848",
    },
  },
  containPoints: {
    display: "flex",
    padding: "0.6rem 0",
  },
  containAllPoints: {
    padding: "1rem 0",
  },
  containEmailPoints: {
    padding: "1rem 0 0 0",
  },
  containCalenderSync: {
    background: "#F9FAFB",
    padding: "1.5rem 1rem",
    borderRadius: "5px",
  },
  selectTitle: {
    color: "#102F51",
    fontWeight: "300",
    fontSize: "14px",
    marginBottom: "0.8rem",
  },
  eachDropdown: {
    width: "15vw",
    border: "1px solid #D3DAE3",
    background: "#F9FAFB",
    padding: "1rem",
    color: "#626B7E",
    fontWeight: "300",
    borderRadius: "4px",
    margin: "0  0.4rem",
  },
  containAllSelect: {
    display: "flex",
    flexWrap: "wrap",
  },
  containEachSelect: {
    width: "45%",
    marginRight: "1rem",
    marginTop: "2rem",
  },
  taskBottom: {
    marginTop: "2rem",
  },
  checkBoxText: {
    color: "#102F51",
    fontSize: "14px",
    paddingLeft: "4px",
    fontWeight: "600",
    alignSelf: "center",
  },
  defaultsContainer: {
    display: "flex",
    paddingTop: "1rem",
  },
  scrollingContainer: {
    borderRadius: "6px",
    background: "white",
    height: "calc(100vh - 7rem)",
    overflow: "scroll",
    boxShadow: "0px 2px 3px rgba(98, 107, 126, 0.11)",
    ...INVISIBLE_SCROLL_BAR,
  },
  emailFilters: {
    display: "flex",
    width: "50%",
  },
  notificationInput: {
    border: "none",
    background: BACKGROUND_COLOR,
    width: "100%",
    paddingLeft: "0.5rem",
    fontWeight: "300",
  },
  hiddenInput: {
    display: "none",
  },
  containNotificationInput: {
    background: BACKGROUND_COLOR,
    width: "25vw",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    padding: "0.5rem",
    display: "flex",
    justifyContent: "flex-start",
    "@media (max-width: 768px)": {
      width: "35vw",
    },
  },
  allDropdown: {
    width: "7vw",
    border: "1px solid #D3DAE3",
    background: "#F9FAFB",
    padding: "1rem",
    color: "#626B7E",
    fontWeight: "300",
    borderRadius: "4px",
    margin: "0  0.4rem",
  },
  userImageContainer: {
    width: "80px",
    height: "80px",
  },
  containFileUpload: {
    position: "relative",
    overflow: "inherit",
  },
  fileInputIconContainer: {
    position: "absolute",
    left: 50,
    bottom: -15,
  },
  inputTitle: {
    fontWeight: "500",
    fontSize: "14px",
    color: "#212833",
  },
  emailTableTitle: {
    color: "#0B1534",
    fontSize: "12px",
    fontWeight: "400",
    letterSpacing: "0.5px",
  },
  inputSubTitle: {
    fontWeight: "400",
    fontSize: "10px",
    color: "#616A7D",
    alignSelf: "center",
  },
  input: {
    fontSize: "clamp(12px, 0.729vw, 16px)",
    fontWeight: "400",
    border: "1px solid #D3DAE3",
    padding: "13px 17px",
    borderRadius: "4px",
    width: "100%",
    marginTop: "12px ",
    color: "rgba(98, 107, 126, 1)!important",
    "&::placeholder": {
      color: "rgba(98, 107, 126, 0.5)",
      fontSize: "clamp(12px, 0.729vw, 16px)",
    },
  },
  containLanguageDropdown: {
    margin: "0.4rem 0",
    height: "50px",
    "& .MuiAutocomplete-popupIndicator": {
      right: "7px",
      "@media (max-width: 1400px)": {
        right: "2px",
      },
    },
  },
  containDropdown: {
    marginTop: "0.75rem",
    height: "45px",
  },
  numberInput: {
    fontSize: "13px",
    background: "#F9FAFB",
    fontWeight: "400",
    border: "1px solid #D3DAE3",
    padding: "0 1rem",
    borderRadius: "0 4px 4px 0",
    width: "100%",
  },
  containNameInputs: {
    display: "flex",
    flexWrap: "wrap",
  },
  containInput: {
    width: "45%",
    margin: "0 1rem",
    marginLeft: "0",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  themeTitle: {
    color: "#102F51",
    fontSize: "14px",
    fontWeight: "600",
    alignSelf: "center",
  },
  eachTheme: {
    margin: "1rem 1rem 0 0",
    cursor: "pointer",
  },
  themePara: {
    fontWeight: "600",
    fontSize: "12px",
    textAlign: "center",
    color: "#102F51",
    margin: "0.2rem 0",
  },
  dropdown: {
    alignSelf: "center",
    border: "1px solid #D3DAE3",
    color: "#626B7E",
    fontWeight: "400",
    marginLeft: "10px",
    padding: "0.6rem 0.8rem",
    borderRadius: "4px",
  },
  emailTableHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 1.5rem",
    background: "#F3F5F7",
    borderRadius: "5px 5px 0 0",
    borderBottom: "1px solid #d3dae3",
  },
  eachDomainRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 1.5rem 0 1.5rem",
  },
  containEmailTable: {
    border: "1px solid #D3DAE3",
    margin: "2rem 0 1rem 0",
    borderRadius: "6px",
    paddingBottom: "1rem",
  },
  domain: {
    color: "#626b7e",
    fontSize: "13px",
    fontWeight: "400",
    alignSelf: "center",
  },
  domainBtn: {
    color: "#ff4848",
    border: "1px solid #ff4848",
    background: "#FFE9E9",
    padding: "0.4rem 1.2rem",
    borderRadius: "5px",
    opacity: "0.7",
    fontWeight: "400",
    fontSize: "13px",
  },
  selectedTheme: {
    border: "2px solid #ff4848",
    borderRadius: "8px",
    height: "62px !important",
  },
  containAllDropdown: {
    width: "7vw",
    margin: "0 10px",
    height: "40px",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  containAllDropdown1: {
    width: "14vw",
    margin: "0 ",
    height: "40px",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  ///****UI changes */
  headerWrap: {
    borderBottom: "1px solid #D3DAE3",
    padding: "0 30px 24px",
    margin: "0 -30px 24px",
  },
  paddingBtnnUser:{
    display:"flex",
    gap:"8px"
  }
});
