export const PEOPLE_BASIC_DETAILS_REQUEST = "PEOPLE_BASIC_DETAILS_REQUEST";
export const PEOPLE_BASIC_DETAILS_SUCCESS = "PEOPLE_BASIC_DETAILS_SUCCESS";
export const PEOPLE_BASIC_DETAILS_FAIL = "PEOPLE_BASIC_DETAILS_FAIL";

export const PEOPLE_USER_DETAILS_REQUEST = "PEOPLE_USER_DETAILS_REQUEST";
export const PEOPLE_USER_DETAILS_SUCCESS = "PEOPLE_USER_DETAILS_SUCCESS";
export const PEOPLE_USER_DETAILS_FAIL = "PEOPLE_USER_DETAILS_FAIL";

export const PEOPLE_BASIC_LEAD_DETAILS_REQUEST ="PEOPLE_BASIC_LEAD_DETAILS_REQUEST";
export const PEOPLE_BASIC_LEAD_DETAILS_SUCCESS ="PEOPLE_BASIC_LEAD_DETAILS_SUCCESS";
export const PEOPLE_BASIC_LEAD_DETAILS_FAIL = "PEOPLE_BASIC_LEAD_DETAILS_FAIL";

export const PEOPLE_BASIC_ACCOUNT_DETAILS_REQUEST ="PEOPLE_BASIC_ACCOUNT_DETAILS_REQUEST";
export const PEOPLE_BASIC_ACCOUNT_DETAILS_SUCCESS ="PEOPLE_BASIC_ACCOUNT_DETAILS_SUCCESS";
export const PEOPLE_BASIC_ACCOUNT_DETAILS_FAIL = "PEOPLE_BASIC_ACCOUNT_DETAILS_FAIL";
