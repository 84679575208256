import React, { useState } from 'react'
import Checkbox from '../../Buttons/Checkbox/Checkbox'
import classes from "../FilterTypes.module.css"
import { useDispatch, useSelector } from 'react-redux'
import { filterMultipleList } from '../../../redux/actions/filterActions'
import { STRINGS } from '../strings'

const FilterMultipleCheckbox = ({ items = [], dispatchAllowed = true, filterName = "" }) => {
    const dispatch = useDispatch();

    const filterMultipleListSelector = useSelector(
        (state) => state?.filterMultipleList?.payload
    );


    const handleChange = (item) => {

        let selected_items = [];
        if (filterName === STRINGS.EMPLOYEE_NUMBER_SMALL) {
            selected_items = [...new Set([...filterMultipleListSelector?.[`cb-${filterName}`] || [], item?.id])]
        } else if (filterName === STRINGS.SENIORITY_SMALL) {
            selected_items = [...new Set([...filterMultipleListSelector?.[`cb-${filterName}`] || [], item?.id])]
        }
        else {
            selected_items = [...new Set([...filterMultipleListSelector?.[`cb-${filterName}`] || [], item?.label])];
        }

        // remove if already selected

        if (filterName === STRINGS.EMPLOYEE_NUMBER_SMALL || filterName === STRINGS.SENIORITY_SMALL) {
            if ((filterMultipleListSelector?.[`cb-${filterName}`]?.includes(item?.id))) {
                selected_items = selected_items?.filter((ele) => ele !== item?.id);
            }
        } else {
            if ((filterMultipleListSelector?.[`cb-${filterName}`]?.includes(item?.label))) {
                selected_items = selected_items?.filter((ele) => ele !== item?.label);
            }
        }


        if (dispatchAllowed) {
            dispatch(filterMultipleList({
                ...filterMultipleListSelector,
                [`cb-${filterName}`]: selected_items
            }))
        }
    }


    return (
        <div className={classes.multipleCheckboxMainContainer} >
            {
                items?.map((item, index) => {
                    return <div
                        key={index}
                        className={classes.containCheckbox}
                    >
                        <Checkbox
                            onClick={() => handleChange(item)}
                            checked={(filterMultipleListSelector?.[`cb-${filterName}`] || [])?.includes(filterName === STRINGS.EMPLOYEE_NUMBER_SMALL || filterName === STRINGS.SENIORITY_SMALL ? item?.id : item?.label)}
                            label={item?.label}
                        />
                    </div>
                })
            }
        </div>
    )
}

export default FilterMultipleCheckbox