import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import { useDispatch } from "react-redux";

import { authEndpoints } from "../../../utils/constants/httpConstants";
import { deleteRequest } from "../../../Apis/apiWrapper";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { getBrandDetailsList } from "../../../redux/actions/brandKitDetailsActions";
import { STRINGS } from "../strings";
import CustomModal from "../../../components/Modals/CustomModal";
import { Button } from "../../../components/Buttons";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { Mixpanel } from "../../../Apis/Mixpanel";
import { useNavigate } from "react-router-dom";
const styles = makeStyles({
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "40vw",
    borderRadius: "12px",
  },
  containBtns: {
    paddingTop: "1rem",
    justifyContent: "flex-end",
    display: "flex",
    marginTop: "10px",
  },
  closeBtn: {
    background: "white",
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "13px",
    border: "none",
    padding: "0.7rem 1.5rem",
    borderRadius: "4px",
    cursor: "pointer",
    height: "40px",
    alignSelf: "center",
  },
  containIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerModal: {
    height: "72px",
    background: "#D3DAE3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  para: {
    color: "#616A7D",
    fontSize: "15px",
    fontWeight: "500",
  },
  deleteBtn: {
    backgroundColor: "#FFFFFF",
    color: "#626B7E",
    border: "1px solid #D3DAE3",
    height: "30px",
    width: "80px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  cancelBtn: {
    backgroundColor: "#FF4848",
    color: "#FFFFFF",
    fontSize: "11px",
    height: "30px",
    width: "80px",
    borderRadius: "3px",
    border: "none",
    marginLeft: "10px",
    cursor: "pointer",
  },
  iconBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#626B7E",
    border: "1px solid #D3DAE3",
  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  headerData1: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginLeft: "18px",
  },
  headerData2: {
    color: "#626B7E",
    marginRight: "18px",
    fontWeight: "600",
    cursor: "pointer",
  },
  delContent: {
    height: "75px",
    display: "flex",
    paddingLeft: "18px",
    paddingTop: "32px",
  },
  delContentWithin: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#626B7E",
    lineHeight: "17px",
  },
  delFooter: {
    height: "66px",
    borderTop: "1px solid #D3DAE3",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cnclBtn: {
    marginRight: "10px",
    height: "37px",
    borderRadius: "6px",
    width: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    border: "1px solid #D3DAE3",
    color: "#626B7E",
    cursor: "pointer",
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },
  },
  crtBtn: {
    marginRight: "18px",
    height: "37px",
    color: "#FFFFFF",
    background: "#FF4848",
    borderRadius: "6px",
    width: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    cursor: "pointer",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  modalFooterRightData: {
    display: "flex",
    gap: "4px",
  },
});
const BrandingDelete = ({ id, openMeet, setOpenMeet, name }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const deleteMethod = async () => {
    try {
      setLoading(true);
      const response = await deleteRequest(authEndpoints.deleteBrandkit + id,{},navigate);
      Mixpanel.track(STRINGS.BRANDING_DELETE_SUCCESS);

      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS,STRINGS.SUCCESS_DELETE);

        dispatch(getBrandDetailsList());
      } else {
        EventEmitter.emit(EMITTER_ERROR, STRINGS.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        error?.data?.message || "Something went wrong"
      );
      return error;
    } finally {
      setLoading(false);
      setOpenMeet(false);
    }
  };
  const modalHeaderData = {
    heading: {
      id: "head",
      label: `Delete the "${name}"`,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.BRAND_DELETE}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpenMeet(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={deleteMethod}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      variant={"error"}
      close={() => setOpenMeet(false)}
      open={openMeet}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
    // <Box className={classes.modal}>
    //   <Box className={classes.headerModal}>
    //     <Box className={classes.headerData1}>Delete the {`"${name}"`}</Box>
    //     <Box className={classes.headerData2}>
    //       <ClearIcon onClick={() => setOpenMeet(false)} />
    //     </Box>
    //   </Box>
    //   <Box className={classes.delContent}>
    //     <span className={classes.delContentWithin}>
    //       Are you sure you want to Delete this Branding Detail?
    //     </span>
    //   </Box>
    //   <Box className={classes.delFooter}>
    //     <Box className={classes.cnclBtn} onClick={() => setOpenMeet(false)}>
    //       Cancel
    //     </Box>
    //     {loading ? (
    //       <div className={classes.crtBtn}>
    //         {" "}
    //         <CircularLoader size={15} />
    //       </div>
    //     ) : (
    //       <Box className={classes.crtBtn} onClick={deleteMethod}>
    //         Delete
    //       </Box>
    //     )}
    //   </Box>
    // </Box>
  );
};

export default BrandingDelete;
