import {  TextareaAutosize } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { SCROLL_BAR } from "../../utils/constants/UI/uiConstants";

const styles = makeStyles({
  textArea:{
    height:"136px !important",
    overflow:"auto !important",
    ...SCROLL_BAR,
    resize:"none",
    width:'100%',
    border:'1px solid #d3dae3',
    borderRadius:'4px',
    padding:'1rem',
    "&:hover":{
      border:"1px solid #a1a0a3 !important",
      overflow:"auto !important",
    }
  }
})

const CustomTextArea = ({ ...props }) => {

  const classes = styles();

  return <TextareaAutosize 
  
  { ...props }  className={ classes.textArea } />
};

export default CustomTextArea;
