import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/Buttons";
import CustomHeader from "../../../components/Header/CustomHeader";
import SearchField from "../../../components/InputFields/SearchField";
import { ICON_LABELS } from "../../../utils/constants/UI";
import AddContactDialog from "./components/AddContactDialog";
import { STRINGS } from "../strings";
import { getSalesContactsList } from "../../../redux/actions/salesTableAction";
import { tempHeadCells } from "../../../utils/constants";
import FilterStrip from "./components/FilterStrip";
import classes from "./Crm.module.css";
import { filterQuery } from "../../../utils/helpers";
import {
  allDynamicFilters,
  filterMultipleList,
} from "../../../redux/actions/filterActions";
import EditColumnModal from "./components/EditColumnModal";
import ContactHeader from "./components/ContactHeader";
import ContactTable from "./components/ContactTable";
import EmptyScreen from "../../../components/Screens/EmptyScreen";
import { DEALS_EMPTY_SCREEN } from "../../../utils/constants/assets";
import * as qs from "qs";

const ContactsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );
  const salesContactListSelector = useSelector(
    (state) => state.getSalesContactList
  );
  const columnsSelector =
    useSelector((state) => state.dynamicUpdatedColumn) || [];

  const allDynamicFiltersSelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );
  const tableBody = salesContactListSelector?.data?.records || [];
  const totalCount = salesContactListSelector?.data?.total_records?.toString();
  const res = qs.parse(window.location?.search?.slice(1));
  const queryLength = Object.keys(filterQuery(allFiltersSelector)).length;
  // added leads as label only for pushing code to master.
  const headerItems = [{ id: STRINGS.SALES_SMALL, label: STRINGS.CONTACTS }];

  //State Handling
  const [addContactOpen, setAddContactOpen] = useState(false);
  const [searchField, setSearchField] = useState(res?.search || "");
  const [contactTableLoading, setContactTableLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);

  const searchFieldHandler = async (value) => {
    if (value) {
      dispatch(
        filterMultipleList({
          ...filterMultipleListSelector,
          search: value,
          page: 1,
          limit: 25,
          isApiCall: true,
        })
      );
    } else {
      let data = { ...filterMultipleListSelector };
      delete data?.search;
      dispatch(
        filterMultipleList({
          ...data,
          page: 1,
          limit: 25,
          isApiCall: true,
        })
      );
    }
  };

  const addContactHandler = async () => {
    setAddContactOpen(!addContactOpen);
  };

  // clear all if needed required fields.
  const handleClearAll = () => {
    setSearchField("");
  };

  //useEffect Handling
  useEffect(() => {
    // const res = qs.parse(window.location?.search?.slice(1));
    let session_key = sessionStorage.getItem("active_view_id");

    if (
      columnsSelector?.columns
        ?.filter((item) => item?.isChecked)
        ?.map((item) => item?.id)
        ?.includes("first_name") &&
      !contactTableLoading &&
      !columnsSelector?.not_api_call &&
      Object.keys(getPropertiesListSelector || {})?.length && (session_key ? Object.keys(allDynamicFiltersSelector)?.length : true)
    ) {
      (async () => {
        try {
          setContactTableLoading(true);
          await dispatch(
            getSalesContactsList(
              {
                query: {
                  ...allDynamicFiltersSelector,
                },
              },
              navigate
            )
          );
        } catch (error) {
          console.log("Error --->>>>. ", error);
        } finally {
          setContactTableLoading(false);
          setLoadingTable(false);
        }
      })();
    }
  }, [
    navigate,
    dispatch,
    allDynamicFiltersSelector,
    queryLength,
    columnsSelector,
    getPropertiesListSelector,
  ]);

  useEffect(() => {
    if (Object.keys(paginationDataSelector?.data?.query || {}).length) {
      let payloadQuery = {
        ...filterMultipleListSelector,
        ...paginationDataSelector?.data?.query,
      };

      if (paginationDataSelector?.data?.query?.sort?.id?.length) {
        payloadQuery = {
          ...payloadQuery,
          sort: paginationDataSelector?.data?.query?.sort?.id,
        };
      }
      if (paginationDataSelector?.data?.query?.sort?.direction) {
        payloadQuery = {
          ...payloadQuery,
          direction: paginationDataSelector?.data?.query?.sort?.direction,
        };
      }

      dispatch(
        allDynamicFilters(
          {
            ...payloadQuery,
          },
          navigate
        )
      );
      let navigateQuery = { ...payloadQuery };
      delete navigateQuery?.isApiCall;
      if (navigateQuery?.page === 1) delete navigateQuery?.page;
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...navigateQuery,
        }).toString(),
      });
    }
  }, [
    paginationDataSelector?.data?.query?.page,
    paginationDataSelector?.data?.query?.limit,
    paginationDataSelector?.data?.query?.sort,
  ]);

  // debounce search api call for search
  useEffect(() => {
    let getData;
    getData = setTimeout(() => {
      searchFieldHandler(searchField);
    }, 300);
    return () => clearTimeout(getData);
  }, [searchField]);

  return (
    <div className={classes.contactScreenMainContainer}>
      {/* Header */}
      <CustomHeader
        items={headerItems}
        description={
          `${salesContactListSelector?.loading || loadingTable
            ? "loading"
            : totalCount
          } ${STRINGS.CONTACTS}` || "NA"
        }
      />
      {/* Header */}

      {/* View Tab Header */}
      <ContactHeader />
      {/* View Tab Header */}

      {tableBody?.data?.is_empty ? (
        <div className={classes.dealsEmptyScreenContainer}>
          <EmptyScreen
            imageSrc={DEALS_EMPTY_SCREEN}
            label={STRINGS.EMPTY_CONTACTS_SCREEN_HEADING}
            description={STRINGS.EMPTY_CONTACTS_SCREEN_DESC}
            buttonLabel={STRINGS.ADD_CONTACTS}
            onClick={() => setAddContactOpen(true)}
          />
        </div>
      ) : (
        <>
          {/* Filter Strip */}
          <FilterStrip clearAllParentHandler={handleClearAll} />
          {/* Filter Strip */}

          {/* Table Search Bar and Table Operation Buttons */}
          <div className={classes.tableUpperStripContainer}>
            <div className={classes.searchFieldWidth}>
              <SearchField
                size={"sm36"}
                placeholder={STRINGS.SEARCH}
                value={searchField}
                onChange={(e) => {
                  setSearchField(e?.target?.value);
                }}
              />
            </div>
            <div className={classes.tableUpperStripRightContainer}>
              <EditColumnModal />

              <Button
                LeftIcon={ICON_LABELS.ADD}
                label={STRINGS.ADD_CONTACTS}
                size={"sm36"}
                variant="primary"
                onClick={addContactHandler}
              />
            </div>
          </div>
          {/* Table Search Bar and Table Operation Buttons */}

          {/* Table Container */}
          <ContactTable
            heads={(
              columnsSelector?.columns?.filter((item) => !item.is_deal) ||
              tempHeadCells
            )?.filter((item) => item.isChecked)}
            records={tableBody}
            totalCount={totalCount}
            setSearchField={setSearchField}
            loading={loadingTable}
          />
          {/* Table Container */}
        </>
      )}

      {/* Add Contact Dialog */}
      {addContactOpen && (
        <AddContactDialog open={addContactOpen} setOpen={setAddContactOpen} />
      )}

      {/* Add Contact Dialog */}
    </div>
  );
};

export default ContactsScreen;
