import { Accordion } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DEBOUNCE_DELAY } from "../../../../utils/constants";
import { Box } from "@mui/system";
import { ADD_FILTER_Accordion } from "../../../../utils/constants/styles";
import { AccordionSummary, AccordionDetails } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  debounceHelper,
  decompressString,
  replaceKeyInArrayOfObj,
} from "../../../../utils/helpers";
import { filterMultipleList } from "../../../../redux/actions/filterActions";
import { Button } from "../../../../components/Buttons";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import CustomDialog from "../../../../components/Dialog/CustomDialog";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import IconComponent from "../../../../components/Icon/IconComponent";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import classes from "./CrmComp.module.css";
import FilterSlider from "../../../../components/FilterTypes/Dynamic/FilterSlider";
import FilterMultiple from "../../../../components/FilterTypes/Dynamic/FilterMultiple";
import { postRequest } from "../../../../Apis";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import FilterMultipleNoSuggestions from "../../../../components/FilterTypes/Dynamic/FilterMultipleNoSuggestions";
import FilterDateRange from "../../../../components/FilterTypes/Dynamic/FilterDateRange";
import InputField from "../../../../components/InputFields/InputField";
import { filterDynamicQuery } from "../../../../utils/helpers";
import DynamicValueFilter from "../../../../components/AppliedFilters/DynamicValueFilter";
import FilterMultipleCheckbox from "../../../../components/FilterTypes/Dynamic/FilterMultipleCheckbox";

const AddFiltermodal = ({
  isOpenFromOutside = false,
  dealNameFilter = "",
  setDealNameFilter = () => { },
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );
  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );
  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  const [expanded, setExpanded] = useState("");
  const [open, setOpen] = useState(false);
  const [associatedContact, setAssociatedContact] = useState([]);
  const handleFilterChange = (filter) => {
    expanded === filter ? setExpanded("") : setExpanded(filter);
  };

  const handleClose = () => {
    setOpen(!open);
    setExpanded("");
  };

  const dialogHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.ADD_FILTERS,
    },
  };

  // variable having the list of tenant users in id label format.
  let tenant_user_list = tenantUsersListSelector?.map((item) => {
    return {
      id: item?._id,
      label: `${item?.first_name} ${item?.last_name}`,
    };
  });

  // return the items list for filter multiple component (anyOfItems and notAnyOfItems)
  const getItemsList = (filter) => {
    if (filter?.id === STRINGS.DEAL_OWNER_SMALL) {
      return tenant_user_list;
    } else if (filter?.id === STRINGS.ASSOCIATED_CONTACT_SMALL) {
      let associated_contact = associatedContact?.forEach((element) => {
        return {
          id: element?._id,
          label: `${element?.first_name} ${element?.last_name}`,
        };
      });
      return associated_contact;
    } else {
      return (
        replaceKeyInArrayOfObj(
          filter?.value,
          STRINGS.LABEL_VALUE,
          STRINGS.LABEL
        ) || []
      );
    }
  };

  // filter for deal name search
  const handleDealSearch = (e) => {
    dispatch(
      filterMultipleList({
        ...(filterMultipleListSelector || {}),
      })
    );

    let propertiesPayload = localStorage.getItem(STRINGS.PROPERTIES)
      ? JSON.parse(
        decompressString(localStorage.getItem(STRINGS.PROPERTIES) || "")
      )?.properties_key_value
      : getPropertiesListSelector?.properties_key_value;
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...filterDynamicQuery(filterMultipleListSelector, propertiesPayload),
      }).toString(),
    });
  };

  // search debounce
  const debounceSearch = debounceHelper(handleDealSearch, DEBOUNCE_DELAY);

  const typeIdComponents = (filter) => {
    let temp_filter =
      filter?.id === STRINGS.DEAL_NAME_SMALL
        ? STRINGS.DEAL_NAME_SMALL
        : filter?.field_type[0];
    switch (temp_filter) {
      case STRINGS.NUMBER:
        return <FilterSlider filterName={filter?.id} />;
      case STRINGS.DATE_PICKER:
        return <FilterDateRange filterName={filter?.id} />;
      case STRINGS.SINGLE_SELECT:
        return (
          <FilterMultiple
            anyOfItems={getItemsList(filter)}
            notAnyOfItems={getItemsList(filter)}
            filterName={filter?.id}
          />
        );
      case STRINGS.MULTIPLE_SELECT:
        return (
          <FilterMultiple
            anyOfItems={getItemsList(filter)}
            notAnyOfItems={getItemsList(filter)}
            filterName={filter?.id}
          />
        );
      case STRINGS.MULTIPLE_CHECKBOX:
        return (
          <FilterMultipleCheckbox
            items={getItemsList(filter)}
            filterName={filter?.id}
          />
        );
      case STRINGS.DEAL_NAME_SMALL:
        return (
          <div className={classes.fieldFilterContainer}>
            <InputField
              size={"sm36"}
              onKeyDown={(e) => e.stopPropagation()}
              placeholder={STRINGS.SEARCH}
              onChange={(e) => {
                setDealNameFilter(e?.target?.value);
                debounceSearch(e);
              }}
              value={dealNameFilter}
            />
          </div>
        );

      default:
        return <FilterMultipleNoSuggestions filterName={filter?.id} />;
    }
  };

  // useEffect for api call of associated contact.
  useEffect(() => {
    (async () => {
      if (
        filterMultipleListSelector?.associated_contact?.anyOfInputVal?.length ||
        filterMultipleListSelector?.associated_contact?.notAnyOfInputVal?.length
      ) {
        const response = await postRequest(navigate, crmEndpoints.getContact, {
          search:
            filterMultipleListSelector?.associated_contact?.anyOfInputVal ||
            filterMultipleListSelector?.associated_contact?.notAnyOfInputVal,
        });
        setAssociatedContact(response?.data?.data?.records);
      }
    })();
  }, [
    filterMultipleListSelector?.associated_contact?.anyOfInputVal,
    filterMultipleListSelector?.associated_contact?.notAnyOfInputVal,
  ]);

  // useEffect for storing deal name input field value.
  useEffect(() => {
    if (filterMultipleListSelector?.deal_name?.length) {
      setDealNameFilter(filterMultipleListSelector?.deal_name);
    }
  }, []);

  // useEffect for managing open state of add filter modal dialog.
  useEffect(() => {
    setOpen(isOpenFromOutside);
  }, [isOpenFromOutside]);

  return (
    <>
      <div className={classes.addFilter}>
        <Button
          LeftIcon={ICON_LABELS.FILTER_ALT}
          size={"sm36"}
          label={STRINGS.ADD_FILTER}
          variant={"text"}
          onClick={handleClose}
        />
      </div>

      <CustomDialog
        header={dialogHeader}
        open={open}
        onClose={handleClose}
        width={"30vw"}
        remove_padding
      >
        {/* Filter Applied Label on the top of Dialog */}
        <div id="modal_body">
          {Object.keys(filterMultipleListSelector || {})?.map((id, index) => {
            return id !== STRINGS.PAGE_SMALL &&
              id !== STRINGS.SEARCH_SMALL &&
              id !== STRINGS.SORT_SMALL &&
              id !== STRINGS.DIRECTION_SMALL &&
              id !== STRINGS.LIMIT_SMALL &&
              (filterMultipleListSelector?.[id]?.length ||
                Object.keys(filterMultipleListSelector?.[id])?.length) ? (
              <DynamicValueFilter key={index} id={id} />
            ) : null;
          })}

          <div className={classes.normalFiltersContainer}>
            {/* Filters of Personal Information */}
            <div>
              {/* only for company name filter having id company_id */}
              {getPropertiesListSelector?.hits?.sort((a, b) => {
                var aValue = a.operation ? 1 : 0;
                var bValue = b.operation ? 1 : 0;
                return aValue - bValue;
              })?.map((filter) => {
                return (
                  filter?.object_type[0] === STRINGS.CONTACT &&
                  filter?.id === STRINGS.COMPANY_ID_SMALL && (
                    <Box key={filter.property_name}>
                      <Accordion
                        disableGutters
                        key={filter.property_name}
                        expanded={expanded === filter.property_name}
                        onChange={() =>
                          handleFilterChange(filter.property_name)
                        }
                        sx={ADD_FILTER_Accordion}
                      >
                        <AccordionSummary
                          className={classes.accordionSummary}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Box
                            className={
                              expanded === filter.property_name
                                ? classes.containEachOpenFilter
                                : classes.containEachFilter
                            }
                          >
                            <TypographyInline
                              label={filter.property_name}
                              size={"sm"}
                              fontWeight={fontWeight.MEDIUM}
                              color={GREY.SECONDARY}
                            />
                            {expanded === filter.property_name ? (
                              <IconComponent
                                color={GREY.TERTIARY}
                                fontSize={fontSize.DXS}
                                iconLabel={ICON_LABELS.CLOSE}
                              />
                            ) : (
                              <IconComponent
                                color={GREY.TERTIARY}
                                fontSize={fontSize.DXS}
                                iconLabel={ICON_LABELS.ADD}
                              />
                            )}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={classes.accordionDetailContainer}>
                            {typeIdComponents(filter)}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )
                );
              })}

              {getPropertiesListSelector?.hits?.map((filter) => {
                return (
                  filter?.object_type[0] === STRINGS.COMPANY &&
                  filter?.id !== STRINGS.COMPANY_NAME_SMALL && (
                    <Box key={filter.property_name}>
                      <Accordion
                        disableGutters
                        key={filter.property_name}
                        expanded={expanded === filter.property_name}
                        onChange={() =>
                          handleFilterChange(filter.property_name)
                        }
                        sx={ADD_FILTER_Accordion}
                      >
                        <AccordionSummary
                          className={classes.accordionSummary}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Box
                            className={
                              expanded === filter.property_name
                                ? classes.containEachOpenFilter
                                : classes.containEachFilter
                            }
                          >
                            <TypographyInline
                              label={filter.property_name}
                              size={"sm"}
                              fontWeight={fontWeight.MEDIUM}
                              color={GREY.SECONDARY}
                            />
                            {expanded === filter.property_name ? (
                              <IconComponent
                                color={GREY.TERTIARY}
                                fontSize={fontSize.DXS}
                                iconLabel={ICON_LABELS.CLOSE}
                              />
                            ) : (
                              <IconComponent
                                color={GREY.TERTIARY}
                                fontSize={fontSize.DXS}
                                iconLabel={ICON_LABELS.ADD}
                              />
                            )}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={classes.accordionDetailContainer}>
                            {typeIdComponents(filter)}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )
                );
              })}
            </div>
          </div>
        </div>
        {/* Filters of Company Info */}
      </CustomDialog>
    </>
  );
};

export default AddFiltermodal;
