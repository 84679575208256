import React from "react";
import { Box } from "@mui/system";
import { styles } from "./styles";
import NoSuggestionsAutoComplete from "../Autocomplete/NoSuggestionsAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { initialFilterPayload } from "../../redux/store/storeConstants";
import {
  allFilters,
  filterHeaderCompanySeo,
} from "../../redux/actions/filterActions";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import { STRINGS } from "./strings";

const FilterTypeCompanySeo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedJobFilter = useSelector(
    (state) => state?.filterHeaderCompanySeo?.payload
  );

  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);

  const handleExistFields = (
    existing_type,
    filter,
    filterKey,
    notFilterKey
  ) => {
    // existing_type = isKnown/ isUnknown
    // filter = job_title/industry/ seniority..

    let payload = {
      ...initialFilterPayload?.payload,
      [existing_type]: !selectedJobFilter[existing_type],
    };

    payload = JSON.parse(JSON.stringify(payload));
    dispatch(filterHeaderCompanySeo(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + "_not"]: [],
        [filter + "_all"]: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [filterKey]: [...allFiltersSelector?.[filterKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  const handleCompanySeoChange = (item, filter) => {
    let payload = {
      ...selectedJobFilter,
      [item]: !selectedJobFilter[item],
      isKnown: false,
      isUnknown: false,
    };

    // clear data if column is empty.
    const unCheckPayload = {};
    if (!payload?.anyOf) {
      unCheckPayload[filter] = [];
    }
    if (!payload?.notAnyOf) {
      unCheckPayload[filter + "_not"] = [];
    }
    if (!payload?.notAnyOf) {
      unCheckPayload[filter + "_all"] = [];
    }
    // clear data if column is empty.

    dispatch(
      allFilters({
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });
    dispatch(filterHeaderCompanySeo(payload));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containAllFilter}>

        {/* <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.allOf}
            onClick={() => handleCompanySeoChange("allOf", "seo_description")}
            label={STRINGS.IS_ALL_OF}
          />
        </Box>

        {selectedJobFilter?.allOf ? (
          <Box>
            <NoSuggestionsAutoComplete filter="seo_description" type="_all" />{" "}
          </Box>
        ) : null} */}

        <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.anyOf}
            onClick={() => handleCompanySeoChange("anyOf", "seo_description")}
            label={STRINGS.IS_ANY_OF}
          />
        </Box>

        {selectedJobFilter?.anyOf ? (
          <Box>
            {" "}
            <NoSuggestionsAutoComplete filter="seo_description" type="" />{" "}
          </Box>
        ) : null}

        <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.notAnyOf}
            onClick={() =>
              handleCompanySeoChange("notAnyOf", "seo_description")
            }
            label={STRINGS.IS_NOT_ANY_OF}
          />
        </Box>

        {selectedJobFilter?.notAnyOf ? (
          <Box>
            <NoSuggestionsAutoComplete filter="seo_description" type="_not" />
          </Box>
        ) : null}

        <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.isKnown}
            onClick={() =>
              handleExistFields(
                "isKnown",
                "seo_description",
                "exist_fields",
                "not_exist_fields"
              )
            }
            label={STRINGS.IS_KNOWN}
          />
        </Box>
        <Box sx={styles.jobTitleHead}>
          <Checkbox
            checked={selectedJobFilter?.isUnknown}
            onClick={() =>
              handleExistFields(
                "isUnknown",
                "seo_description",
                "not_exist_fields",
                "exist_fields"
              )
            }
            label={STRINGS.IS_UNKNOWN}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterTypeCompanySeo;
