import React from 'react'
import { makeStyles } from "@mui/styles";
import { Box } from '@mui/material';
import Image from '../../components/Image/Image';
import { SEARCH_PROSPECTS_IMAGE } from '../../utils/constants/assets';
import { STRINGS } from './strings';
import { GREY } from '../../utils/constants/colors';
import { ICON_LABELS, SHADOW, fontWeight } from '../../utils/constants/UI';
import { Button } from '../../components/Buttons';
import TypographyInline from '../../components/Typography/TypographyInline';
import { useParams } from 'react-router';

const styles = makeStyles({
  containSearchImage: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    height: "calc(100% - 100px)",
    background: "white",
    //   borderRadius: "4px",
    borderTop: "1px solid rgb(239, 239, 239)",
    margin: "16px",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    boxShadow: SHADOW.sm,
  },
  searchImage: {
    width: "19.141vw",
    objectFit: "contain",
    paddingBottom: "1rem",
  },
  searchPara: {
    fontSize: "clamp(12px, 0.8vw, 40px)",
    fontWeight: "500",
    color: "#626b7e",
  },
  title: {
    color: "#0F2F51",
    fontWeight: "800",
    fontSize: "clamp(1.4rem, 1.7vw, 4.2rem)",
    marginBottom: "1rem",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  contentPara: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
  },
  contentHeading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  displayFlexFoot: {
    display: "flex",
    gap: "8px",
    alignItems: "center"
  }
});


const EmptyScreen = ({ data, handleOpen, handleFolder }) => {
  const params = useParams();
  const classes = styles();

  console.log("Params______ ", params);

  return (
    <Box className={classes.containSearchImage}>
      <div className={classes.imageContainer}>
        <Image
          src={SEARCH_PROSPECTS_IMAGE}
          alt=""
          className={classes.searchImage}
        />
      </div>
      <div className={classes.contentHeading}>
        <TypographyInline
          label={data.create}
          size={"xl"}
          fontWeight={fontWeight.SEMIBOLD}
          color={GREY.PRIMARY}
        />
        <TypographyInline
          label={data.content}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
        />
        <div className={classes.contentPara}>
          {data?.body?.map((item) => {
            return (
              <TypographyInline
                label={item}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
                color={GREY.SECONDARY}
              />
            )
          }
          )
          }
        </div>
      </div>
      <div className={classes.displayFlexFoot}>
        <div>
          <Button
            label={data?.foot}
            LeftIcon={ICON_LABELS.ADD}
            size={"md40"}
            variant={"primary"}
            onClick={() => handleOpen()}
          />
        </div>
        {
          !params?.id?.length && <div>
            <Button
              label={STRINGS.CREATE_FOLDER}
              LeftIcon={ICON_LABELS.ADD}
              size={"md40"}
              variant={"primary"}
              onClick={() => handleFolder()}
            />
          </div>
        }
      </div>
    </Box>
  )
}

export default EmptyScreen