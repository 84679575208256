import React from "react";

const ImageIcon = ({
  alignSelf,
  image,
  margin,
  width,
  borderColor,
  height,
  borderRadius,
  ...props
}) => {
  return (
    <img
      {...props}
      src={image}
      alt="icon"
      // loading="lazy"
      style={{
        alignSelf: alignSelf ? alignSelf : "center",
        margin: margin ? margin : "0 0.2rem",
        borderColor: borderColor ? borderColor : "",
        width: width && width,
        height: height && height,
        objectFit: "contain",
        borderRadius: borderRadius ? borderRadius : null,
      }}
    />
  );
};

export default ImageIcon;
