import React, { useEffect, useState } from "react";
import { InputWrapper, Root, StyledTag } from ".";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { useDispatch, useSelector } from "react-redux";
import { allFilters } from "../../redux/actions/filterActions";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { UNAUTHORIZED_USER_MESSAGE } from "../../utils/constants/messages";
import { Box } from "@mui/system";
import { styles } from "./styles";

const getDefaultValues = (data) => {
  let result = [];
  for (let i = 0; i < data?.length; i++) {
    result.push({ name: data[i] });
  }
  return result;
};

const NoSuggestionsAutoComplete = ({ type, filter }) => {
  const allFiltersSelector = useSelector((state) => state.allFilters);
  const navigate = useNavigate();
  const location = useLocation();
  const selector = useSelector((state) => state);
  const { error } = selector.filterHeaderSearch;
  const dispatch = useDispatch();
  const [inputPayload, setInputPayload] = useState("");

  const defaultValues = getDefaultValues(
    allFiltersSelector?.payload[filter + type]
  );

  const handleInputChange = function (event, newInputValue) {
    setInputPayload(newInputValue);
  };

  let { getRootProps, getInputProps, getTagProps, value, setAnchorEl } =
    useAutocomplete({
      id: "customized-hook",
      inputValue: inputPayload,
      multiple: true,
      onInputChange: handleInputChange,
      defaultValue: defaultValues,
      options: [{ name: "" }],
      getOptionLabel: (option) => option.name,
    });

  let inputValue = getInputProps()?.value;

  useEffect(() => {
    if (value?.length >= 0) {
      const params = value?.map((x) => x?.name);
      dispatch(allFilters({ [filter + type]: params }));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector?.payload,
          [filter + type]: params,
        }).toString(),
      });
    }

    if (error?.message === UNAUTHORIZED_USER_MESSAGE) {
    }
  }, [inputValue, error, value, dispatch, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    value.push({ name: inputValue });
    const params = value?.map((x) => x?.name);
    handleInputChange(e, "");

    dispatch(allFilters({ [filter + type]: [...params, inputValue] }));
  };

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl}>
          {value?.map((option, index) => (
            <StyledTag label={option.name} {...getTagProps({ index })} />
          ))}
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={styles.inputContainer}
          >
            <input
              style={styles.input}
              placeholder="Search..."
              {...getInputProps()}
            />
          </Box>
        </InputWrapper>
      </div>
    </Root>
  );
};

export default React.memo(NoSuggestionsAutoComplete);
