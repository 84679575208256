import React, { useEffect, useState } from "react";
import { STRINGS } from "../strings";
import classes from "../FilterTypes.module.css";
import DynamicCustomAutoComplete from "../../Autocomplete/DynamicCustomAutoComplete";
import Checkbox from "../../Buttons/Checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { filterMultipleList } from "../../../redux/actions/filterActions";
import { useNavigate } from "react-router-dom";

const FilterMultiple = ({
  anyOfItems = [],
  notAnyOfItems = [],
  filterName = "",
}) => {
  const dispatch = useDispatch();

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );
  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  const [selectedFilter, setSelectedFilter] = useState({
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  });

  const filterDispatchHandler = (data, isApiCall) => {
    dispatch(
      filterMultipleList({
        ...filterMultipleListSelector,
        [filterName]: {
          ...filterMultipleListSelector?.[filterName],
          ...data,
        },
        isApiCall
      })
    );
  };

  const anyOfOnChange = (data) => {
    let arrData = data?.map((item) => {
      if (
        filterName === STRINGS.DEAL_OWNER_SMALL ||
        filterName === STRINGS.ASSOCIATED_CONTACT_SMALL
      ) {
        return item?.id;
      } else {
        return item?.label || item?.name;
      }
    });
    dispatch(
      filterMultipleList({
        ...filterMultipleListSelector,
        [filterName]: {
          ...filterMultipleListSelector?.[filterName],
          anyOfList: [...arrData],

        }, isApiCall: true,
      })
    );
  };

  const anyOfInputChange = (data) => {
    if (data?.length) {
      dispatch(
        filterMultipleList({
          ...filterMultipleListSelector,
          [filterName]: {
            ...filterMultipleListSelector?.[filterName],
            anyOfInputVal: data,

          }, isApiCall: false,
        })
      );
    }
  };

  const notAnyOfOnChange = (data) => {
    let arrData = data?.map((item) => {
      if (
        filterName === STRINGS.DEAL_OWNER_SMALL ||
        filterName === STRINGS.ASSOCIATED_CONTACT_SMALL
      ) {
        return item?.id;
      } else {
        return item?.label || item?.name;
      }
    });
    dispatch(
      filterMultipleList({
        ...filterMultipleListSelector,
        [filterName]: {
          ...filterMultipleListSelector?.[filterName],
          notAnyOfList: [...arrData],

        }, isApiCall: true,
      })
    );
  };

  const notAnyOfInputChange = (data) => {
    if (data?.length) {
      dispatch(
        filterMultipleList({
          ...filterMultipleListSelector,
          [filterName]: {
            ...filterMultipleListSelector?.[filterName],
            notAnyOfInputVal: data,

          }, isApiCall: false,
        })
      );
    }
  };


  const getDefaultValues = (type) => {
    let default_value = [];
    // default values for deal owner
    if (filterName === "deal_owner") {
      let data = [];

      if (type === STRINGS.IS_ANY_OF) {
        data = [...(filterMultipleListSelector?.[filterName]?.anyOfList || [])];
      } else if (type === STRINGS.IS_NOT_ANY_OF) {
        data = [
          ...(filterMultipleListSelector?.[filterName]?.notAnyOfList || []),
        ];
      }

      // checking the selected item into tenant users list.
      tenantUsersListSelector?.map((item) => {
        if (data?.includes(item?._id)) {
          let temp_item = {
            id: item?._id,
            label: `${item?.first_name} ${item?.last_name}`,
          };
          default_value.push(temp_item);
        }
      });
    }
    // default values for associated contact
    else if (filterName === "associated_contact") {
      let record_ids = [];
      let records = [];

      if (type === STRINGS.IS_ANY_OF) {
        record_ids = [...filterMultipleListSelector?.associated_contact?.anyOfList || []];
        records = [...anyOfItems];
      } else if (type === STRINGS.IS_NOT_ANY_OF) {
        record_ids = [...filterMultipleListSelector?.associated_contact?.notAnyOfList || []];
        records = [...notAnyOfItems]
      }
      // restructuring the selected item into associate contact list.
      records?.map((item) => {
        if (record_ids?.includes(item?.id)) {
          default_value.push(item);
        }
      });
    }
    // default values for all filters instead of deal_owner and associated_contact.
    else {
      let data = [];
      let items = [...anyOfItems];

      if (type === STRINGS.IS_ANY_OF) {
        data = [...(filterMultipleListSelector?.[filterName]?.anyOfList || [])];
      } else if (type === STRINGS.IS_NOT_ANY_OF) {
        data = [
          ...(filterMultipleListSelector?.[filterName]?.notAnyOfList || []),
        ];
      }

      items?.forEach((item) => {
        if (data?.includes(item?.label)) {
          default_value.push({ ...item });
        }
      });
    }

    return default_value;
  };

  useEffect(() => {
    if (Object?.keys(filterMultipleListSelector || {})?.length) {
      setSelectedFilter({
        anyOf: filterMultipleListSelector?.[filterName]?.anyOf,
        notAnyOf: filterMultipleListSelector?.[filterName]?.notAnyOf,
        isKnown: filterMultipleListSelector?.[filterName]?.isKnown,
        isUnknown: filterMultipleListSelector?.[filterName]?.isUnknown,
      });
    }
  }, [filterMultipleListSelector]);

  return (
    <div className={classes.filter}>
      <div className={classes.title}>
        <Checkbox
          checked={selectedFilter?.anyOf}
          onChange={() => {
            setSelectedFilter({
              ...selectedFilter,
              anyOf: !selectedFilter?.anyOf,
              isKnown: false,
              isUnknown: false,
            });
            filterDispatchHandler({
              ...selectedFilter,
              anyOf: !selectedFilter?.anyOf,
              anyOfList: [],
              isKnown: false,
              isUnknown: false,
              // isApiCall: false,
            }, false);
          }}
          onKeyDown={(e) => e.stopPropagation()}
          label={STRINGS.IS_ANY_OF}
        />
      </div>

      {selectedFilter?.anyOf ? (
        <DynamicCustomAutoComplete
          onChange={anyOfOnChange}
          inputOnchange={anyOfInputChange}
          items={anyOfItems}
          filterName={filterName}
          filterType={STRINGS.ANY_OF_LIST_SMALL}
          defaultValues={() => getDefaultValues(STRINGS.IS_ANY_OF)}
        />
      ) : null}
      <div className={classes.title}>
        <Checkbox
          checked={selectedFilter?.notAnyOf}
          onClick={() => {
            setSelectedFilter({
              ...selectedFilter,
              notAnyOf: !selectedFilter?.notAnyOf,
              isKnown: false,
              isUnknown: false,
            });
            filterDispatchHandler({
              ...selectedFilter,
              notAnyOf: !selectedFilter?.notAnyOf,
              notAnyOfList: [],
              isKnown: false,
              isUnknown: false,
              // isApiCall: false,
            }, false);
          }}
          onKeyDown={(e) => e.stopPropagation()}
          label={STRINGS.IS_NOT_ANY_OF}
        />
      </div>

      {selectedFilter?.notAnyOf ? (
        <DynamicCustomAutoComplete
          onChange={notAnyOfOnChange}
          inputOnchange={notAnyOfInputChange}
          items={notAnyOfItems}
          filterName={filterName}
          filterType={STRINGS.NOT_ANY_OF_LIST_SMALL}
          defaultValues={() => getDefaultValues(STRINGS.IS_NOT_ANY_OF)}
        />
      ) : null}
      <div className={classes.title}>
        <Checkbox
          checked={selectedFilter?.isKnown}
          onClick={() => {
            setSelectedFilter({
              isKnown: !selectedFilter?.isKnown,
              anyOf: false,
              isUnknown: false,
              notAnyOf: false,
            });
            filterDispatchHandler({
              isKnown: !selectedFilter?.isKnown,
              anyOf: false,
              isUnknown: false,
              notAnyOf: false,
              // isApiCall: true,
            }, true);
          }}
          label={STRINGS.IS_KNOWN}
        />
      </div>
      <div className={classes.title}>
        <Checkbox
          checked={selectedFilter?.isUnknown}
          onClick={() => {
            setSelectedFilter({
              isKnown: false,
              anyOf: false,
              isUnknown: !selectedFilter?.isUnknown,
              notAnyOf: false,
            });
            filterDispatchHandler({
              isKnown: false,
              anyOf: false,
              isUnknown: !selectedFilter?.isUnknown,
              notAnyOf: false,
              // isApiCall: true,
            }, true);
          }}
          label={STRINGS.IS_UNKNOWN}
        />
      </div>
    </div>
  );
};

export default FilterMultiple;

// Props Description :-
// 1. anyOfOnChange = selected values on any of filter,
// 2. anyOfInputChange = input change values of any of filter,
// 3. notAnyOfOnChange = selected values on not any of filter,
// 4. notAnyOfInputChange = input change values of not any of filter,
// 5. anyOfItems = items of any of,
// 6. notAnyOfItems = items of not any of,
