import React from 'react';
import classes from "./FormsComp.module.css"
import SkeletonLoader from '../../../../components/Loaders/SkeletonLoader';

const FormsDragLoading = () => {
    return (
        <div id='modal_body' className={classes.formsFieldsLoadingMainContainer} >
            {Array.from({ length: 10 }).map((index) => {
                return (
                    <div key={`formsField${index}`} className={classes.eachLoaderWidth}>
                        <SkeletonLoader
                            type="rounded"
                            skeletonWidth={"100%"}
                            skeletonHeight={"40px"}
                            stackWidth={"100%"}
                        />
                    </div>
                );
            })}
        </div>
    )
}

export default FormsDragLoading;