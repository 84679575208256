import React from "react";
import IconComponent from "../../../../components/Icon/IconComponent";
import { GREY } from "../../../../utils/constants/colors";
import {
  fontSize,
  fontWeight,
  ICON_LABELS,
} from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import classes from "./DealsComp.module.css";
import TypographyInline from "../../../../components/Typography/TypographyInline";

const ManageViewCard = ({
  label,
  onwerName,
  color = GREY.PRIMARY,
  RightIcon,
  actionOnClick,
  onClick,
  isSelected,
  isPinned,
  isDefault,
  ...props
}) => {
  return (
    <div
      className={
        isSelected
          ? classes.selectedManageCardContainer
          : classes.manageCardContainer
      }
    >
      {/* Label Container */}
      <div className={classes.labelContainer} onClick={onClick}>
        <TypographyInline
          label={label}
          color={color}
          fontWeight={fontWeight.MEDIUM}
          size={"sm"}
        />
        {onwerName && (
          <TypographyInline
            label={`${STRINGS.OWNER}: ${onwerName}`}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            size={"xs"}
          />
        )}
      </div>
      {/* Label Container */}
      {/* Actions Container */}
      <div className={classes.iconContainer}>
        {(isDefault) ?
          <div>
            <IconComponent
              color={GREY.DISABLED}
              fontSize={fontSize.XS}
              iconLabel={ICON_LABELS.BOOKMARK}
            />
          </div> :
          (isPinned || RightIcon) && (
            <div>
              <IconComponent
                color={GREY.DISABLED}
                fontSize={fontSize.XS}
                iconLabel={RightIcon || ICON_LABELS.PUSH_PIN}
              />
            </div>
          )}
        {actionOnClick ? (
          <div className={classes.icon} onClick={actionOnClick}>
            <IconComponent
              color={GREY.TERTIARY}
              fontSize={fontSize.DXS}
              iconLabel={ICON_LABELS.MORE_VERT}
              transform
            />
          </div>
        ) : null}
      </div>
      {/* Actions Container */}
    </div>
  );
};

export default ManageViewCard;
