import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { allFilters } from "../../redux/actions/filterActions";
import { styles } from "./styles";
import IconComponent from "../Icon/IconComponent";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import { BRAND, GREY } from "../../utils/constants/colors";
import { STRINGS } from "../strings";
import TypographyInline from "../Typography/TypographyInline";

const RevenueFilter = ({ minRevenue, maxRevenue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);

  const handleRemoveFilter = () => {
    const query = JSON.parse(JSON.stringify(allFiltersSelector));
    delete query?.min_revenue;
    delete query?.max_revenue;

    dispatch(allFilters(query, true));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...query,
      }).toString(),
    });
  };

  return (
    <Box sx={styles.selectedFilterBox}>
      <div style={styles.cancelIcon} onClick={handleRemoveFilter}>
        <IconComponent
          iconLabel={ICON_LABELS.CANCEL}
          color={GREY.TERTIARY}
          fontSize={fontSize.MD}
        />
      </div>
      <div style={styles.filterValues}>
        <TypographyInline
          label={STRINGS.REVENUE}
          size={"sm"}
          fontWeight={fontWeight.SEMIBOLD}
          color={GREY.PRIMARY}
          isNoWrap={true}
        />
        <TypographyInline
          label={STRINGS.IS_BETWEEN}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
          isNoWrap={true}
        />
        <TypographyInline
          label={`${minRevenue}M and ${maxRevenue}M USD`}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={BRAND.PRIMARY}
          isNoWrap={true}
        />
      </div>
    </Box>
  );
};

export default RevenueFilter;
