export const STRINGS = {
  CANCEL: "Cancel",
  INSERT: "Insert",
  INSERT_LINK: "Insert Link",
  TEXT: "Text to display",
  USE_AI: "Use AI Assistant",
  ADD_MEET: "Add Meeting Link",
  UNSUBS_TAG: "Unsubscribe Tag",
  UNSUBS_TAG_SMALL: "unsubscribe_tag",
  SUBJECT_SMALL: "subject",
  INSERT_HTML_CAMEL_CASE: "insertHTML",
  IMAGE_SMALL: "image",
  ENTER_TEXT_TO_DISPLAY: "Enter text to display",
  MEETING_LINK: "Meeting Link",
  WEB_ADDRESS: "Web Address",
  MEETING_LINK_PLACEHOLDER: "To what URL should this link go?",
  NO_LINKS_FOUND: "No Links Found",
  PEOPLE: "People",
  COMPANY: "Company",
  SEARCH: "Search",
  TAGS: "Tags",
  SAVE: "Save",
  EDIT: "Edit",
  ADD_VALUE: "Add Value",
  FORE_COLOR_CAMEL_CASE: "foreColor",
  HILITE_COLOR_CAMEL_CASE: "hiliteColor",
  TEXT_COLOR: "Text Color",
  BACKGROUND_COLOR: "Background Color",
  BUTTON_SMALL: "button",
  TAG_SMALL: "tag",
  DROPDOWN_SMALL: "dropdown",
  BOLD: "Bold",
  BOLD_SMALL: "bold",
  ITALIC: "Italic",
  ITALIC_SMALL: "italic",
  UNDERLINE: "Underline",
  UNDERLINE_SMALL: "underline",
  STRIKE_THROUGH: "Strike Through",
  STRIKE_THROUGH_CAMEL_CASE: "strikeThrough",
  UNDO: "Undo",
  UNDO_SMALL: "undo",
  REDO: "Redo",
  REDO_SMALL: "redo",
  FONT_COLOR: "Font Color",
  FONT_COLOR_CAMEL_CASE: "fontColor",
  ALIGN_LEFT: "Align Left",
  JUSTIFY_LEFT_CAMEL_CASE: "justifyLeft",
  ALIGN_CENTER: "Align Center",
  JUSTIFY_CENTER_CAMEL_CASE: "justifyCenter",
  ALIGN_RIGHT: "Align Right",
  JUSTIFY_RIGHT_CAMEL_CASE: "justifyRight",
  INSERT_IMAGE: "Insert Image",
  ATTACHMENT: "Attachment",
  LINK_SMALL: "link",
  NUMBERED_LIST: "Numbered List",
  INSERT_ORDERED_LIST_CAMEL_CASE: "insertOrderedList",
  BULLETED_LIST: "Bulleted List",
  INSERT_UNORDERED_LIST_CAMEL_CASE: "insertUnorderedList",
  INSERT_HTML: "Insert HTML",
  INSERT_HTML_SMALL: "insert_html",
  ADD_UNSUBSCRIBE_TAG: "Add Unsubscribe Tag",
  ADD_TAG: "Personalize",
  DYNAMIC_TAG_SMALL: "dynamic_tag",
  ADD_MEETING_LINK: "Add Meeting Link",
  MEETING_LINK_SMALL: "meeting_link",
  FONT_SIZE: "Font Size",
  FONT_SIZE_CAMEL_CASE: "fontSize",
  FONT_FAMILY: "Font Family",
  FONT_NAME_CAMEL_CASE: "fontName",
  USE_AI_ASSISTANT: "Use AI Assistant",
  AI_ASSISTANT_SMALL: "ai_assistant",
  ADD_EMOJIS: "Add Emojis",
  ADD_EMOJIS_SMALL: "add_emojis"
};
