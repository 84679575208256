import React from 'react'
import { Button } from '../../../../components/Buttons'
import { ICON_LABELS } from '../../../../utils/constants/UI'
import { STRINGS } from '../../strings';
import classes from "./FormsComp.module.css"

const Footer = ({ previewHandler = () => { }, cancleHandler = () => { }, saveHandler = () => { }, saveLoading = false, disableOperation = true }) => {
    return (
        <div className={classes.formsFooter} >
            <div>
                <Button LeftIcon={ICON_LABELS.PREVIEW} label={STRINGS.PREVIEW} size={"sm36"} variant={"ghost"} onClick={previewHandler} />
            </div>
            <div className={classes.formsFooterRight} >
                <Button label={STRINGS.CANCEL} size={"sm36"} variant={"ghost"} onClick={cancleHandler} disabled={disableOperation} />
                <Button label={STRINGS.SAVE} size={"sm36"} variant={"primary"} onClick={saveHandler} isLoading={saveLoading} disabled={disableOperation} />
            </div>
        </div>
    )
}

export default Footer