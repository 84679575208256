import React from "react";

const Anchor = ({ ...props }) => {
  return (
    <a rel="noreferrer" {...props} target="_blank">
      {props.children}
    </a>
  );
};

export default Anchor;
