import { createData } from "../helpers";
import {
  ManageAccountsIcon,
  SecurityIcon,
  GroupIcon,
  CurrencyExchangeIcon,
  LogoutIcon,
  PersonAddAltIcon,
  FiberManualRecordIcon,
  CancelScheduleSendIcon,
  SettingsIcon,
  SettingsApplicationsIcon,
  AppsRoundedIcon,
  PaletteIcon,
} from "../../components/Icon/MuiIcons";

import Users from "../../screens/ProfileScreen/components/Users";
import UserTeams from "../../screens/ProfileScreen/components/Settings/UserTeams";
import Security from "../../screens/ProfileScreen/components/Settings/Security";

import {
  FilterTypeName,
  FilterTypeJobTitle,
  FilterTypeIndustry,
  FilterTypeSeniority,
  FilterTypeCompanySize,
  FilterTypeCompanyRevenue,
  FilterTypeLocationCity,
  FilterTypeLocationState,
  FilterTypeLocationCountry,
  FilterTypeCompanyName,
  FilterTypeCompanyKeywords,
  FilterTypeCompanyTechnologies,
  FilterTypeCompanySeo,
  FilterTypeEmailVerification,
  FilterTypeEmailVerificationDate,
  FilterTypeLastUpdatedAt,
  FilterTypeCreatedDate,
} from "../../components/FilterTypes";
import {
  BLACKLIST_IMPORT_PATH,
  COMPANY_SCREEN_PATH,
  DASHBOARD_PATH,
  EMAIL_MARKETING_CAMPAIGN_PATH,
  LIST_PATH,
  PEOPLE_SCREEN_PATH,
  PROFILE_APIKEYS_PATH,
  PROFILE_PATH,
  PROFILE_SECURITY_PATH,
  SEQUENCE_PATH,
  TEMPLATE_PATH,
  MEETING_PATH,
  TASK_PATH,
  CHROME_PATH,
  CAMPAIGN_SETTING_PATH,
  IMPORT_TABLE_CSV,
  CRM_PATH,
  IMPORT_PATH,
  ACCOUNT_PATH,
  DEALS_PATH,
  SNIPPET_PATH,
  MARKETING_PROSPECT_SCREEN_PATH,
} from "./routes";
import InviteUser from "../../screens/ProfileScreen/components/InviteUser";
import {
  AUTOMATED_IMAGE,
  CALL_IMAGE,
  COMPANY_IMAGE,
  PEOPLE_IMAGE,
  TODO_IMAGE,
} from "./assets";
import CreateAutomatedEmail from "../../screens/Marketing/components/Steps/CreateAutomatedEmail";
import CreateCall from "../../screens/Marketing/components/Steps/CreateCall";
import CreateTodo from "../../screens/Marketing/components/Steps/CreateTodo";
import {
  AutomatedEmailStep,
  CallStep,
  TodoStep,
} from "../../screens/Marketing/components/Steps";
import ApiKeys from "../../screens/ProfileScreen/components/Settings/ApiKeys";
import BlacklistImportScreen from "../../screens/Leads/BlacklistImportScreen";
import Branding from "../../screens/ProfileScreen/components/Branding";
import { CampaignSetting } from "../../screens/ProfileScreen/components/Settings/CampaignSetting";
import ProfileForm from "../../screens/InitialForm/components/ProfileForm";
import CompanyForm from "../../screens/InitialForm/components/CompanyForm";
import { ICON_LABELS } from "./UI";
import PropertiesScreen from "../../screens/PropertiesScreen/PropertiesScreen";
import { ERROR, ORANGE, WARNING } from "./colors";
import { authEndpoints } from "./httpConstants";
import { getPropertiesList } from "../../redux/actions/propertiesAction";
import Forms from "../../screens/ProfileScreen/Forms/Forms";
import { getPipelineList } from "../../redux/actions/getPipelineAction";
import PipelineScreen from "../../screens/ProfileScreen/PipelineScreen/PipelineScreen";
import Profile from "../../screens/ProfileScreen/components/Profile";

// Snackbar constants
export const AUTO_HIDE_DURATION = 5000;
export const EMITTER_SUCCESS = "alert_success";
export const EMITTER_WARNING = "alert_warning";
export const EMITTER_ERROR = "alert_error";
export const EMITTER_INFO = "alert_info";

export const SNACKBAR_CONFIG = {
  snackBarPlacement: {
    vertical: "top",
    horizontal: "center",
  },
};

// Laptop Size for media queries

export const CHROME_EXTENSION_LINK =
  "https://chrome.google.com/webstore/detail/reachiq-verified-b2b-cont/ceflmhjepdngdfhboedephbaohnpglde?gclid=Cj0KCQjwi7GnBhDXARIsAFLvH4nbKIwPjDvQEaXKQLaTRBunfRlyIRWD7OaKr5pWSHSCVZ6PNBKEZ5oaAgyfEALw_wcB";
export const MEDIA_QUERY_SIZE = "768";

export const CURRENT_YEAR = new Date().getFullYear();

export const EMAIL_ENCODING_SALT = "rnf@tech";

export const MAX_FILE_UPLOAD_LIMIT = 26214400;

// Sidebar contants
export const DRAWER_WIDTH = 100;
export const DRAWER_CONTENT = [
  {
    text: "Dashboard",
    icon: ICON_LABELS.GRID_VIEW,
    path: DASHBOARD_PATH,
  },

  {
    text: "Prospects",
    icon: ICON_LABELS.MANAGE_SEARCH,
    path: PEOPLE_SCREEN_PATH,
    toolTipElements: [
      {
        title: "People",
        icon: ICON_LABELS.MANAGE_SEARCH,
        toolTipPath: PEOPLE_SCREEN_PATH,
      },
      {
        title: "Companies",
        icon: ICON_LABELS.APARTMENT,
        toolTipPath: COMPANY_SCREEN_PATH,
      },
    ],
  },
  {
    text: "Marketing",
    icon: ICON_LABELS.CAMPAIGN,
    toolTipElements: [
      {
        title: "Marketing Lists",
        icon: ICON_LABELS.LIST_ALT,
        toolTipPath: LIST_PATH,
      },
      {
        title: "Sequence",
        icon: ICON_LABELS.DOUBLE_ARROW,
        toolTipPath: SEQUENCE_PATH,
      },
      {
        title: "Campaigns",
        icon: ICON_LABELS.CAMPAIGN,
        toolTipPath: EMAIL_MARKETING_CAMPAIGN_PATH,
      },
      {
        title: "Templates",
        icon: ICON_LABELS.DESCRIPTION,
        toolTipPath: TEMPLATE_PATH,
      },
    ],
  },
  // {
  //   text: "Leads",
  //   icon: ICON_LABELS.TRENDING_UP,
  //   toolTipElements: [],
  //   path: CRM_PATH,
  // },

  {
    text: "Sales",
    icon: ICON_LABELS.TRENDING_UP,
    toolTipElements: [
      {
        title: " Contacts",
        icon: ICON_LABELS.GROUPS,
        toolTipPath: CRM_PATH,
      },
      {
        title: "Deals",
        icon: ICON_LABELS.HANDSHAKE,
        toolTipPath: DEALS_PATH,
      },
      {
        title: "Import",
        icon: ICON_LABELS.FILE_UPLOAD,
        toolTipPath: IMPORT_PATH,
      },
      ,
      {
        title: "Accounts",
        icon: ICON_LABELS.VIDEO_CHAT_ROUDED,
        toolTipPath: ACCOUNT_PATH,
      },
    ],
    path: CRM_PATH,
  },
  {
    text: "Tools",
    icon: ICON_LABELS.BUILD_CIRCLE,
    toolTipElements: [
      {
        title: " CSV Enrichment",
        icon: ICON_LABELS.UPLOAD_FILE,
        toolTipPath: IMPORT_TABLE_CSV,
      },
      {
        title: "Meetings",
        icon: ICON_LABELS.VIDEO_CHAT_ROUDED,
        toolTipPath: MEETING_PATH,
      },
      {
        title: "Tasks",
        icon: ICON_LABELS.TASK,
        toolTipPath: TASK_PATH,
      },
      {
        title: "Chrome Extension",
        icon: ICON_LABELS.EXTENSION,
        toolTipPath: CHROME_PATH,
      },
      {
        title: "Snippet",
        icon: ICON_LABELS.ADD,
        toolTipPath: SNIPPET_PATH,
      },
    ],
  },
];

// routes for staging and resouricifi.
export const DRAWER_CONTENT_EXCEPTION = [
  {
    text: "Dashboard",
    icon: ICON_LABELS.GRID_VIEW,
    path: DASHBOARD_PATH,
  },

  {
    text: "Prospects",
    icon: ICON_LABELS.MANAGE_SEARCH,
    toolTipElements: [
      {
        title: "People",
        icon: ICON_LABELS.MANAGE_SEARCH,
        toolTipPath: PEOPLE_SCREEN_PATH,
      },
      {
        title: "Companies",
        icon: ICON_LABELS.APARTMENT,
        toolTipPath: COMPANY_SCREEN_PATH,
      },
    ],
  },
  {
    text: "Marketing",
    icon: ICON_LABELS.CAMPAIGN,
    toolTipElements: [
      {
        title: "Marketing Lists",
        icon: ICON_LABELS.LIST_ALT,
        toolTipPath: LIST_PATH,
      },
      {
        title: "Sequence",
        icon: ICON_LABELS.DOUBLE_ARROW,
        toolTipPath: SEQUENCE_PATH,
      },
      {
        title: "Campaigns",
        icon: ICON_LABELS.CAMPAIGN,
        toolTipPath: EMAIL_MARKETING_CAMPAIGN_PATH,
      },
      {
        title: "Templates",
        icon: ICON_LABELS.DESCRIPTION,
        toolTipPath: TEMPLATE_PATH,
      },
      {
        title: "Templates",
        icon: ICON_LABELS.DESCRIPTION,
        toolTipPath: MARKETING_PROSPECT_SCREEN_PATH,
        notVisible: true
      },

    ],
  },
  {
    text: "Sales",
    icon: ICON_LABELS.TRENDING_UP,
    toolTipElements: [
      {
        title: "Deals",
        icon: ICON_LABELS.MONETIZATION_ON,
        toolTipPath: DEALS_PATH,
      },
      {
        title: " Contacts",
        icon: ICON_LABELS.GROUPS,
        toolTipPath: CRM_PATH,
      },

      {
        title: "Accounts",
        icon: ICON_LABELS.APARTMENT,
        toolTipPath: ACCOUNT_PATH,
      },
    ],
  },
  {
    text: "Tools",
    icon: ICON_LABELS.BUILD_CIRCLE,
    toolTipElements: [
      {
        title: " CSV Enrichment",
        icon: ICON_LABELS.UPLOAD_FILE,
        toolTipPath: IMPORT_TABLE_CSV,
      },
      {
        title: "Meetings",
        icon: ICON_LABELS.VIDEO_CHAT_ROUDED,
        toolTipPath: MEETING_PATH,
      },
      {
        title: "Tasks",
        icon: ICON_LABELS.TASK,
        toolTipPath: TASK_PATH,
      },
      {
        title: "Import",
        icon: ICON_LABELS.FILE_UPLOAD,
        toolTipPath: IMPORT_PATH,
      },
      {
        title: "Snippets",
        icon: ICON_LABELS.TAG,
        toolTipPath: SNIPPET_PATH,
      },
      {
        title: "Chrome Extension",
        icon: ICON_LABELS.EXTENSION,
        toolTipPath: CHROME_PATH,
      },
    ],
  },
];

// Debounce delay.

export const DEBOUNCE_DELAY = 300;

export const PROFILE_HOVER_CONTENT = [
  { title: "Settings", id: "name", icon: SettingsIcon },
  {
    title: "Your Credits",
    id: "available_credits",
    icon: CurrencyExchangeIcon,
  },
  { title: "Logout", id: "logout", icon: LogoutIcon },
];

export const PROFILE_MENU_CONTENT = [
  { title: "Settings", id: "name", icon: ICON_LABELS.SETTINGS },
  {
    title: "Your Credits",
    id: "available_credits",
    icon: ICON_LABELS.CURRENCY_EXCHANGE,
  },
  { title: "Logout", id: "logout", icon: ICON_LABELS.LOGOUT },
];

//Temperory constants

export const PRIVACY_POLICY_LINK = "http://reachiq.ai/privacy-policy";
export const TERMS_SERVICE_LINK = "http://reachiq.ai/term-conditions";
export const DO_NOT_SELL_LINK = "https://reachiq.ai/do-not-sell-my-information";

//Connectome URL

export const CONNECTOME_URL = "https://connectomeinc.com/";
//Settings Constants minor change for remote origin change

export const SETTINGS_GENERAL_TABS = [
  { tab: "Profile", component: ProfileForm },
];

export const SETTINGS_ACCOUNT_TABS = [{ tab: "Users", component: Users }];

//Acccount Default , Branding

export const SETTINGS_ACCOUNTDEFAULT_TABS = [
  { tab: "Account Details", component: CompanyForm },
];
export const SETTINGS_PREFERENCES_LIST = [
  {
    id: "General",
    icon: ManageAccountsIcon,
    label: "General",
    component: Profile,
    path: PROFILE_PATH,
  },

  {
    icon: SecurityIcon,
    label: "Security",
    id: "Security",

    component: Security,
    path: PROFILE_SECURITY_PATH,
  },
  {
    icon: AppsRoundedIcon,
    label: "Integrations",
    id: "Integrations",

    component: ApiKeys,
    path: PROFILE_APIKEYS_PATH,
  },
  {
    id: "Blacklist",
    icon: CancelScheduleSendIcon,
    label: "Blacklist",
    component: BlacklistImportScreen,
    path: BLACKLIST_IMPORT_PATH,
  },
  {
    id: "CampaignDefaults",

    icon: SettingsApplicationsIcon,
    label: "Campaign Defaults",
    component: CampaignSetting,
    path: CAMPAIGN_SETTING_PATH,
  },
];

export const SETTINGS_ACCOUNT_SETUP_LIST = [
  {
    id: "AccountDetails",

    icon: ManageAccountsIcon,
    label: "Account Details",
    component: CompanyForm,
  },

  { id: "Branding", icon: PaletteIcon, label: "Branding", component: Branding },

  { id: "Users", icon: GroupIcon, label: "Users", component: UserTeams },
  {
    id: "InviteUser",
    icon: PersonAddAltIcon,
    label: "Invite User",
    component: InviteUser,
  },
];

export const SETTINGS_DATA_MANGEMENT_LIST = [
  {
    id: "Properties",
    icon: ICON_LABELS.TUNE,
    label: "Properties",
    component: PropertiesScreen,
  },
  {
    id: "Pipeline",
    icon: ICON_LABELS.VIEW_AGENDA,
    label: "Pipelines",
    component: PipelineScreen,
  },
  {
    id: "forms",
    icon: ICON_LABELS.HORIZONTAL_SPLIT,
    label: "Forms",
    component: Forms,
  },
];

export const SETTINGS_NOTIFICATIONS_TOPICS = [
  {
    title: "Acadamy",
    description:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of content. ",
  },
  {
    title: "Contacts & Campaigns",
    description:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of content. ",
  },
  {
    title: "Dashboard",
    description:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of content. ",
  },
  {
    title: "Deals",
    description:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of content. ",
  },
];

export const USER_MODAL_COMPANY_DETAIL = [
  { icon: ICON_LABELS.GROUPS, text: "Employees", id: "number_of_employees" },
  { icon: ICON_LABELS.WORK, text: "", id: "industry" },
  {
    icon: ICON_LABELS.LIST_ALT,
    text: "",
    id: "company_keywords",
  },
  {
    icon: ICON_LABELS.MONETIZATION_ON,
    text: "Annual Revenue",
    id: "annual_revenue",
  },
  {
    icon: ICON_LABELS.LOCATION_ON,
    text: "",
    id: "company_address",
  },
  {
    icon: ICON_LABELS.LANGUAGE,
    text: "",
    id: "company_website",
  },
];

export const TEMP_COMPANY_URL =
  "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1397188087/d49ae32b3869e61076d79695dc94096f.png";

export const EMAIL_VERIFICATION_TYPES = [
  { type: "Valid", isChecked: false, index: 1 },
  { type: "Invalid", isChecked: false, index: 2 },
  { type: "Accept-All", isChecked: false, index: 3 },
  { type: "Spam Trap", isChecked: false, index: 4 },
  { type: "Unknown", isChecked: false, index: 5 },
  { type: "Blank", isChecked: false, index: 6 },
];

export const DEFAULT_EMAIL_VERIFICATION_TYPES = [
  { type: "Valid", isChecked: false, index: 1 },
  { type: "Invalid", isChecked: false, index: 2 },
  { type: "Accept-All", isChecked: false, index: 3 },
  { type: "Spam Trap", isChecked: false, index: 4 },
  { type: "Unknown", isChecked: false, index: 5 },
  { type: "Blank", isChecked: false, index: 6 },
];

export const COMPANY_EMPLOYEES_COUNT = [
  { type: "0-10 employees", index: 1, isChecked: false },
  { type: "11-20 employees", index: 2, isChecked: false },
  { type: "21-50 employees", index: 3, isChecked: false },
  { type: "51-100 employees", index: 4, isChecked: false },
  { type: "101-200 employees", index: 5, isChecked: false },
  { type: "201-500 employees", index: 6, isChecked: false },
  { type: "501-1,000 employees", index: 7, isChecked: false },
  { type: "1,001-2,000 employees", index: 8, isChecked: false },
  { type: "2,001-5,000 employees", index: 9, isChecked: false },
  { type: "5,001-10,000 employees", index: 10, isChecked: false },
  { type: "10,000+ employees", index: 11, isChecked: false },
];

export const DEFAULT_COMPANY_EMPLOYEES_COUNT = [
  { type: "0-10 employees", index: 1, isChecked: false },
  { type: "11-20 employees", index: 2, isChecked: false },
  { type: "21-50 employees", index: 3, isChecked: false },
  { type: "51-100 employees", index: 4, isChecked: false },
  { type: "101-200 employees", index: 5, isChecked: false },
  { type: "201-500 employees", index: 6, isChecked: false },
  { type: "501-1,000 employees", index: 7, isChecked: false },
  { type: "1,001-2,000 employees", index: 8, isChecked: false },
  { type: "2,001-5,000 employees", index: 9, isChecked: false },
  { type: "5,001-10,000 employees", index: 10, isChecked: false },
  { type: "10,000+ employees", index: 11, isChecked: false },
];

export const SENIORIRT_TYPES = [
  { type: "owner", label: "Owner", isChecked: false },
  { type: "founder", label: "Founder", isChecked: false },
  { type: "cxo", label: "CXO", isChecked: false },
  { type: "partner", label: "Partner", isChecked: false },
  { type: "vp", label: "VP", isChecked: false },
  { type: "director", label: "Director", isChecked: false },
  { type: "head", label: "Head", isChecked: false },
  { type: "manager", label: "Manager", isChecked: false },
  { type: "entry_level", label: "Entry Level", isChecked: false },
];

export const DEFAULT_SENIORIRT_TYPES = [
  { type: "owner", label: "Owner", isChecked: false },
  { type: "founder", label: "Founder", isChecked: false },
  { type: "cxo", label: "CXO", isChecked: false },
  { type: "partner", label: "Partner", isChecked: false },
  { type: "vp", label: "VP", isChecked: false },
  { type: "director", label: "Director", isChecked: false },
  { type: "head", label: "Head", isChecked: false },
  { type: "manager", label: "Manager", isChecked: false },
  { type: "entry_level", label: "Entry Level", isChecked: false },
];

export const PERSONAL_INFO_FILTER_LIST = [
  {
    filterName: "Name",
    component: FilterTypeName,
    companyFilter: false,
    onlySavedContactFilter: false,
  },
  {
    filterName: "Seniority",
    component: FilterTypeSeniority,
    companyFilter: false,
    onlySavedContactFilter: false,
  },
  {
    filterName: "Job Title",
    component: FilterTypeJobTitle,
    companyFilter: false,
    onlySavedContactFilter: false,
  },

  {
    filterName: "Email Verification",
    component: FilterTypeEmailVerification,
    companyFilter: false,
    onlySavedContactFilter: false,
    checkStatic: "check",
  },
  {
    filterName: "Email Verification Date",
    component: FilterTypeEmailVerificationDate,
    companyFilter: false,
    checkStatic: "check",
    onlySavedContactFilter: false,
  },
  {
    filterName: "Last Updated Date",
    component: FilterTypeLastUpdatedAt,
    companyFilter: true,
    checkStatic: "check",
  },
  {
    filterName: "Created Date",
    component: FilterTypeCreatedDate,
    companyFilter: true,
    checkStatic: "check",
  },
];
//leads
export const PERSONAL_LEADS_INFO_FILTER_LIST = [
  {
    filterName: "Name",
    component: FilterTypeName,
    companyFilter: false,
    onlySavedContactFilter: false,
  },
  {
    filterName: "Seniority",
    component: FilterTypeSeniority,
    companyFilter: false,
    onlySavedContactFilter: false,
  },
  {
    filterName: "Job Title",
    component: FilterTypeJobTitle,
    companyFilter: false,
    onlySavedContactFilter: false,
  },
  {
    filterName: "Company Size",
    component: FilterTypeCompanySize,
    companyFilter: true,
    onlySavedContactFilter: false,
  },
];
export const LOCATION_FILTER_LIST = [
  {
    filterName: "City",
    component: FilterTypeLocationCity,
    companyFilter: true,
  },
  {
    filterName: "State",
    component: FilterTypeLocationState,
    companyFilter: true,
  },
  {
    filterName: "Country",
    component: FilterTypeLocationCountry,
    companyFilter: true,
  },
];

export const COMPANY_INFO_FILTER_LIST = [
  {
    filterName: "Company Name",
    component: FilterTypeCompanyName,
    companyFilter: true,
  },
  {
    filterName: "Company Size",
    component: FilterTypeCompanySize,
    companyFilter: true,
    onlySavedContactFilter: false,
  },
  {
    filterName: "Industry",
    component: FilterTypeIndustry,
    companyFilter: true,
    onlySavedContactFilter: false,
  },
  {
    filterName: "Keywords",
    component: FilterTypeCompanyKeywords,
    companyFilter: true,
  },
  {
    filterName: "Technologies",
    component: FilterTypeCompanyTechnologies,
    companyFilter: true,
  },
  {
    filterName: "Company Description",
    component: FilterTypeCompanySeo,
    companyFilter: true,
  },
  {
    filterName: "Annual Revenue",
    component: FilterTypeCompanyRevenue,
    companyFilter: true,
  },
];

export const COLUMN_CONTACT_LIST = [
  { label: "Agency focus", isChecked: false },
  { label: "Alternate email address", isChecked: false },
  { label: "Annual revenue", isChecked: false },
  { label: "Associated company", isChecked: false },
  { label: "Associated deals", isChecked: false },
  { label: "BDE", isChecked: false },
  { label: "Became a lead date", isChecked: false },
  { label: "Became a marketing qualified lead date", isChecked: false },
  { label: "Became a sales qualified lead date", isChecked: false },
  { label: "Create Date", isChecked: true },
  { label: "Email", isChecked: true },
  { label: "Phone number", isChecked: true },
  { label: "Lead status", isChecked: true },
  { label: "School", isChecked: true },
  { label: "Agency", isChecked: true },
  { label: "Become an Evangalist date", isChecked: true },
];

export const SETTINGS_USERS_TABLE_HEADERS = [
  {
    id: "name",
    label: "Name",
    sort: true,
    ui_id: "user_detail",
    width: "25.052vw",
  },
  {
    id: "role",
    label: "Role",
    sort: true,
    ui_id: "stats_data",
    width: "18.672vw",
  },
  {
    id: "user_available_credits",
    label: "Available Credits",
    sort: true,
    ui_id: "stats_data",
    width: "17.672vw",
  },
  {
    id: "account_status",
    label: "Account Status",
    sort: true,
    ui_id: "lead_status",
    width: "14.250vw",
  },
];

export const SETTINGS_USERS_DATA = [
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
];

export const SETTINGS_TEAM_TABLE_HEADERS = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "Users",
    label: "Users",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "AdditionalUsers",
    label: "Additional Users",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "Action",
    label: "Action",
    isChecked: true,
    minWidth: "130px",
  },
];

export const INVITED_USERS_LIST_HEADERS = [
  {
    id: "email",
    label: "Email",
    sort: true,
    minWidth: "130px",
    ui_id: "bold_text",
    width: "30.890vw",
  },
  {
    id: "created_at",
    label: "Invitation Sent at",
    sort: true,
    minWidth: "130px",
    ui_id: "time_stamp",
    width: "30.690vw",
  },
  {
    id: "action",
    label: "Action",
    isChecked: true,
    ui_id: "action",
    width: "15.1vw",
  },
];

export const SETTINGS_TEAMS_DATA = [
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
];

export const COLUMNS_KEYS_VALUES = {
  first_name: "Name",
  job_title: "Job Title",
  company_name: "Company Name",
  number_of_employees: "Employees",
  email: "Email",
  industry: "Industry",
  company_keywords: "Keywords",
  company_address: "Company Address",
  annual_revenue: "Annual Revenue",
  company_city: "Company city",
  company_country: "Company Country",
  company_website: "Company Website",
  company_facebook_url: "Company Facebook Page",
  company_linkedin_url: "Company Linkedin URL",
  company_phone_number: "Company Phone Number",
  company_technologies: "Company Technologies",
  company_twitter_url: "Company Twitter Page",
  corporate_phone_number: "Corporate Phone Number",
  created_at: "Created Date",
  direct_phone_number: "Direct Phone Number",
  email_verification_updated_at: "Email Verified At",
  person_city: "Contact City",
  person_state: "Contact State",
  seo_description: "Seo Description",
  last_name: "Last Name",
  person_country: "Contact Location",
  updated_at: "Last Updated Date",
};

export const BLOCKED_CONTACTS_LIST = [
  { id: "email", label: "Email", isChecked: true, ui_id: "number_text" },
  { id: "status", label: "Status", isChecked: true, ui_id: "number_text" },
];

export const WIDGET_TABLE_UNSUBSCRIBE_LIST = [
  {
    id: "name",
    label: "Campaigns",
    isChecked: true,
    width: "14vw",
  },
  {
    id: "total_enrolled",
    label: "Total Enrolled",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "unsubscribe",
    label: "Unsubscribe",
    isChecked: true,
    width: "12vw",
  },
];

export const WIDGET_TABLE_LEADS_LIST = [
  {
    id: "name",
    label: "Campaigns",
    isChecked: true,
    width: "14vw",
  },
  {
    id: "total_enrolled",
    label: "Total Enrolled",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "lead",
    label: "Leads",
    isChecked: true,
    width: "12vw",
  },
];

export const TASK_TABLE_LIST = [
  { id: "title", label: "Title", isChecked: true, width: "4.7vw" },
  {
    id: "Name",
    label: "Associated Contact",
    isChecked: true,
    width: "4.7vw",
  },
  {
    id: "company_name",
    label: "Associated Company",
    isChecked: true,
    width: "4.7vw",
  },
  { id: "created_at", label: "Created at", isChecked: true, width: "4.7vw" },
  { id: "step_type", label: "Task Type", isChecked: true, width: "4.7vw" },
  {
    id: "processing_date_time",
    label: "Due Date",
    isChecked: true,
    width: "4.7vw",
  },
  { id: "action", label: "Action", isChecked: true, width: "4.7vw" },
];

export const IMPORT_BLACKLIST_FILES_HEADS = [
  { id: "file_name", label: "File Name", isChecked: true, width: "12vw" },
  {
    id: "error_count",
    label: "Invalid Records",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "total_records",
    label: "Total Records",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "total_valid_record",
    label: "Total Valid",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "insert_count",
    label: "Total Inserted",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "created_at",
    label: "Uploaded at",
    isChecked: true,
    width: "12vw",
    type: "date",
  },
  {
    id: "updated_at",
    label: "Last updated at",
    isChecked: true,
    width: "12vw",
    type: "date",
  },
  { id: "status", label: "Status", isChecked: true, width: "11rem" },
  {
    id: "processing_count",
    label: "Process Count",
    isChecked: true,
    width: "9rem",
  },

  { id: "data_for", label: "Source Type", isChecked: true, width: "11rem" },

  // { id: "action", label: "Action", isChecked: true, width: "9rem" },
];

export const tempHeadCells = [
  {
    id: "first_name",
    label: "Name",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "user_info",
    width: "15.625vw",
    copy: true,
  },

  {
    id: "job_title",
    label: "Job Title",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_name",
    label: "Company Name",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "company_info",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },

  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "email",
    width: "15.625vw",
    copy: true,
  },
  {
    id: "company_website",
    label: "Company Website",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "number_of_employees",
    label: "Employees",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "stats_data",
    width: "9.375vw",
    copy: false,
  },

  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_address",
    label: "Company Address",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "amount",
    width: "13.542vw",
  },
  {
    id: "company_city",
    label: "Company city",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_country",
    label: "Company Country",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_facebook_url",
    label: "Company Facebook Page",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "company_linkedin_url",
    label: "Company LinkedIn Page",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "company_phone_number",
    label: "Company Phone Number",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_state",
    label: "Company State",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_technologies",
    label: "Company Technologies",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_twitter_url",
    label: "Company Twitter Page",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },

  {
    id: "contact_linkedin_url",
    label: "Contact Linkedin Profile",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "mobile_number",
    label: "Mobile Number",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },

  {
    id: "direct_phone_number",
    label: "Phone Number",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },

  {
    id: "person_city",
    label: "Contact City",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "person_state",
    label: "Contact State",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "seo_description",
    label: "Company Description",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "person_country",
    label: "Contact Country",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
];

export const tempHeadCompanyCells = [
  {
    id: "first_name",
    label: "Name",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "user_info",
    width: "15.625vw",
    copy: true,
  },

  {
    id: "job_title",
    label: "Job Title",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_name",
    label: "Company Name",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "company_info",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },

  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "email",
    width: "15.625vw",
    copy: true,
  },
  {
    id: "company_website",
    label: "Company Website",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "number_of_employees",
    label: "Employees",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "stats_data",
    width: "9.375vw",
    copy: false,
  },

  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_address",
    label: "Company Address",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "amount",
    width: "13.542vw",
  },
  {
    id: "company_city",
    label: "Company city",
    companyField: true,
    isChecked: true,

    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_country",
    label: "Company Country",
    companyField: true,
    isChecked: true,

    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_facebook_url",
    label: "Company Facebook Page",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "company_linkedin_url",
    label: "Company LinkedIn Page",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "company_phone_number",
    label: "Company Phone Number",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_state",
    label: "Company State",
    companyField: true,
    isChecked: true,

    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_technologies",
    label: "Company Technologies",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_twitter_url",
    label: "Company Twitter Page",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },

  {
    id: "contact_linkedin_url",
    label: "Contact Linkedin Profile",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text_url",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "mobile_number",
    label: "Mobile Number",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },

  {
    id: "direct_phone_number",
    label: "Phone Number",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },

  {
    id: "person_city",
    label: "Contact City",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "person_state",
    label: "Contact State",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "seo_description",
    label: "Company Description",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "person_country",
    label: "Contact Country",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
];

export const tempHeadDealsCells = [
  {
    id: "deal_name",
    label: "Deal Name",
    sort: true,
    ui_id: "bold_text",
    width: "15.813vw",
    copy: true,
    isChecked: true,
    is_deal: true,
  },
  {
    id: "deal_owner_name",
    label: "Deal Owner",
    sort: true,
    ui_id: "user_detail",
    width: "14.813vw",
    copy: true,
    isChecked: true,
    is_deal: true,
  },
  {
    id: "associated_contact_name",
    label: "Associated Contact",
    sort: true,
    ui_id: "text",
    width: "13.813vw",
    copy: true,
    isChecked: true,
    is_deal: true,
  },
  {
    id: "pipeline_name",
    label: "Pipeline Name",
    sort: true,
    ui_id: "text",
    width: "14.813vw",
    copy: true,
    isChecked: true,
    is_deal: true,
  },
  {
    id: "closed_date",
    label: "Close Date",
    sort: true,
    ui_id: "time_stamp",
    width: "18.2vw",
    copy: true,
    isChecked: true,
    is_deal: true,
    isDateLabel: true,
  },
  {
    id: "deal_amount",
    label: "Amount",
    sort: true,
    ui_id: "amount",
    width: "12vw",
    copy: true,
    isChecked: true,
    is_deal: true,
  },
  {
    id: "created_at",
    label: "Created At",
    sort: true,
    ui_id: "time_stamp",
    width: "18.813vw",
    copy: true,
    isChecked: true,
    is_deal: true,
  },
  {
    id: "stage_id_name",
    label: "Stage Name",
    sort: false,
    ui_id: "lead_status",
    width: "12.250vw",
    copy: true,
    isChecked: true,
    is_deal: true,
  },
];

export const tempHeadLeadCells = [
  {
    id: "first_name",
    label: "Name",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "user_info",
    width: "15.625vw",
    copy: true,
  },
  {
    id: "lead_date_time",
    label: "Lead Generated",
    companyField: false,
    isChecked: true,
    ui_id: "time_stamp",
    width: "12.726vw",
  },

  {
    id: "campaign_name",
    label: "Campaign Name",
    companyField: false,
    isChecked: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "sequence_id",
    label: "Sequence Used",
    companyField: false,
    isChecked: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },

  {
    id: "job_title",
    label: "Job Title",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: false,
  },
  {
    id: "company_name",
    label: "Company Name",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "company_info",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "number_of_employees",
    label: "Employees",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "stats_data",
    width: "12.726vw",
    copy: false,
  },
  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "email",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: false,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
    sort: true,
  },
  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_address",
    label: "Company Address",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "amount",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_city",
    label: "Company city",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_country",
    label: "Company Country",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_facebook_url",
    label: "Company Facebook Page",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "url",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_linkedin_url",
    label: "Company LinkedIn URL",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "url",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_phone_number",
    label: "Company Phone Number",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_state",
    label: "Company State",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_technologies",
    label: "Company Technologies",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_twitter_url",
    label: "Company Twitter Page",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "url",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "company_website",
    label: "Company Website",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "url",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "contact_linkedin_url",
    label: "Contact Linkedin Profile",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "url",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "corporate_phone_number",
    label: "Corporate Phone Number",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "created_at",
    label: "Created Date",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "time_stamp",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "direct_phone_number",
    label: "Direct Phone Number",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "email_verification_updated_at",
    label: "Email Verified At",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "time_stamp",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "person_city",
    label: "Contact City",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "person_state",
    label: "Contact State",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "seo_description",
    label: "Company Description",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "last_name",
    label: "Last Name",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
  {
    id: "person_country",
    label: "Contact Country",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "12.726vw",
    copy: true,
  },
];

export const DYNAMIC_TAGS = [
  {
    id: "first_name",
    label: "First Name",
    companyField: false,
    isChecked: true,
    dummyValue: "John",
  },
  {
    id: "last_name",
    label: "Last Name",
    companyField: false,
    isChecked: false,
    dummyValue: "Doe",
  },
  {
    id: "job_title",
    label: "Job Title",
    companyField: false,
    isChecked: true,
    dummyValue: "Manager",
  },
  {
    id: "company_name",
    label: "Company Name",
    companyField: true,
    isChecked: true,
    dummyValue: "ABC Technologies",
  },
  {
    id: "number_of_employees",
    label: "Number of Employees",
    companyField: true,
    isChecked: true,
    dummyValue: "100",
  },
  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    dummyValue: "john.doe@abc.com",
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: true,
    dummyValue: "IT",
  },
  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: true,
    dummyValue: "SEO, IT",
  },
  {
    id: "company_address",
    label: "Company Address",
    companyField: true,
    isChecked: false,
    dummyValue: "200 Southland Drive",
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: false,
    dummyValue: "$1000",
  },
  {
    id: "company_city",
    label: "Company city",
    companyField: true,
    isChecked: false,
    dummyValue: "Lexington",
  },
  {
    id: "company_country",
    label: "Company Country",
    companyField: true,
    isChecked: true,
    dummyValue: "Kentucky ",
  },
  {
    id: "company_facebook_url",
    label: "Company Facebook Page",
    companyField: true,
    isChecked: false,
    dummyValue: "www.facebook.com",
  },
  {
    id: "company_linkedin_url",
    label: "Company LinkedIn URL",
    companyField: true,
    isChecked: false,
    dummyValue: "www.linkedin.com",
  },
  {
    id: "company_phone_number",
    label: "Company Phone Number",
    companyField: true,
    isChecked: false,
    dummyValue: "9999999999",
  },
  {
    id: "company_state",
    label: "Company State",
    companyField: true,
    isChecked: true,
    dummyValue: "Kentucky ",
  },
  {
    id: "company_technologies",
    label: "Company Technologies",
    companyField: true,
    isChecked: true,
    dummyValue: "React, NODE",
  },
  {
    id: "company_twitter_url",
    label: "Company Twitter Page",
    companyField: true,
    isChecked: false,
    dummyValue: "www.twitter.com",
  },
  {
    id: "company_website",
    label: "Company Website",
    companyField: true,
    isChecked: false,
    dummyValue: "www.abc.com",
  },
  {
    id: "contact_linkedin_url",
    label: "Contact Linkedin Profile",
    companyField: false,
    isChecked: false,
    dummyValue: "www.linkedin.com",
  },
  // {
  //   id: "corporate_phone_number",
  //   label: "Corporate Phone Number",
  //   companyField: false,
  //   isChecked: false,
  //   dummyValue: "9999999999",
  // },

  {
    id: "direct_phone_number",
    label: "Direct Phone Number",
    companyField: false,
    isChecked: false,
    dummyValue: "9999999999",
  },

  {
    id: "person_city",
    label: "Contact City",
    companyField: false,
    isChecked: true,
    dummyValue: "Lexington",
  },
  {
    id: "person_state",
    label: "Contact State",
    companyField: false,
    isChecked: false,
    dummyValue: "Kentucky",
  },
  {
    id: "seo_description",
    label: "Company Description",
    companyField: true,
    isChecked: false,
    dummyValue: "SEO, IT",
  },
  {
    id: "person_country",
    label: "Contact Country",
    companyField: false,
    isChecked: true,
    dummyValue: "India",
  },
];

export const LEADS_TABLE_LINKS_IDS = [
  "company_name",
  "company_facebook_url",
  "company_linkedin_url",
  "company_twitter_url",
  "company_website",
  "contact_linkedin_url",
];

export const tempHeadCells2 = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "300px",
  },
  {
    id: "jobTitle",
    label: "Job title",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "company",
    label: "Company",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "actions",
    label: "Actions",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "contactLocation",
    label: "Contact Country",
    isChecked: true,
    minWidth: "160px",
  },
  {
    id: "employees",
    label: "Employees",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "industry",
    label: "Industrys",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "keywords",
    label: "Keywords",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "Agency focus",
    label: "Agency focus",
    isChecked: false,
    minWidth: "150px",
  },
  {
    id: "Alternate email address",
    label: "Alternate email address",
    isChecked: false,
    minWidth: "210px",
  },
  {
    id: "Annual revenue",
    label: "Annual revenue",
    isChecked: false,
    minWidth: "155px",
  },
  {
    id: "associatedComapny",
    label: "Associated company",
    isChecked: false,
    minWidth: "190px",
  },
  {
    id: "viewactivity",
    label: "View Activity",
    isChecked: true,
    minWidth: "130px",
  },
];

export const defaultHeadCells = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "jobTitle",
    label: "Job title",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "company",
    label: "Company",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "actions",
    label: "Actions",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "contactLocation",
    label: "Contact Country",
    isChecked: true,
    minWidth: "160px",
  },
  {
    id: "employees",
    label: "Employees",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "industry",
    label: "Industrys",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "keywords",
    label: "Keywords",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "Agency focus",
    label: "Agency focus",
    isChecked: false,
    minWidth: "150px",
  },
  {
    id: "Alternate email address",
    label: "Alternate email address",
    isChecked: false,
    minWidth: "210px",
  },
  {
    id: "Annual revenue",
    label: "Annual revenue",
    isChecked: false,
    minWidth: "155px",
  },
  {
    id: "associatedComapny",
    label: "Associated company",
    isChecked: false,
    minWidth: "190px",
  },
];

export const defaultHeadCells1 = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "jobTitle",
    label: "Job title",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "company",
    label: "Company",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "actions",
    label: "Actions",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "contactLocation",
    label: "Contact Country",
    isChecked: true,
    minWidth: "160px",
  },
  {
    id: "employees",
    label: "Employees",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "industry",
    label: "Industrys",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "keywords",
    label: "Keywords",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "Agency focus",
    label: "Agency focus",
    isChecked: false,
    minWidth: "150px",
  },
  {
    id: "Alternate email address",
    label: "Alternate email address",
    isChecked: false,
    minWidth: "210px",
  },
  {
    id: "Annual revenue",
    label: "Annual revenue",
    isChecked: false,
    minWidth: "155px",
  },
  {
    id: "associatedComapny",
    label: "Associated company",
    isChecked: false,
    minWidth: "190px",
  },
];

export const rows = [
  createData(
    "Targeraen Gin",
    "CEO && Founder",
    "Cnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "King Slayer",
    "BEO && Founder",
    "Bnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Bargeraen Gmb",
    "CEO && Founder",
    "Cnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Ding Slayer",
    "DEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Chadnler bing",
    "EEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Fling Slayer",
    "FEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Loadings King",
    "GEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Starks Arya",
    "HEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Zing Slayer",
    "CEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "jargeraen and",
    "CEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
];

export const DOMAINS_EMAILS_ADDRESS = [
  "*@agilistechlabs.com",
  "*@bistoneseo.com",
  "*@ezmarketingtech.co.uk",
  "*@ezmarketingtech.com",
  "*@integratedmarketingone.com",
  "*@prismevents.co",
];

export const EDITOR_IMAGE_LIMIT = 1498 * 500;

export const DROPDOWN_EMAIL_VIEWS = ["All", "Everyone", "Any"];

//Dropdowns

export const IMPORT_DOMAINS_KEYS = [
  { id: "domain_name", label: "Domain Name", isChecked: true, width: "18vw" },
  {
    id: "created_at",
    label: "Created Time",
    isChecked: true,
    type: "date",
    width: "18vw",
  },
  {
    id: "updated_at",
    label: "Updated Time",
    isChecked: true,
    type: "date",
    width: "18vw",
  },

  { id: "delete", label: "Actions", isChecked: true, width: "15rem" },
];

export const IMPORT_CONTACTS_KEYS = [
  { id: "email", label: "Email", isChecked: true, width: "16vw" },
  { id: "source", label: "Source", isChecked: true, width: "16vw" },

  {
    id: "updated_at",
    label: "Updated Date and Time",
    type: "date",
    isChecked: true,
    width: "16vw",
  },
  { id: "status", label: "Status", isChecked: true, width: "16vw" },
];

export const DROPDOWN_PROFILE_EMAIL_DOMAIN = [
  "Domain and Emails",
  "Domain",
  "Emails",
];

export const DROPDOWN_PEOPLE_HEADER_RELEVENCE = ["Relevance", "Irrevelant"];

export const DROPDOWN_PEOPLE_HEADER_SORTING = [
  { id: "asc", label: "Ascending" },
  { id: "desc", label: "Descending" },
];

export const DROPDOWN_PROFILE_TASK_DUEDATE = [
  "In 3 business days",
  "In 7 business days",
  "In 10 business days",
  "In a month",
];

export const DROPDOWN_PROFILE_TASK_DUETIME = ["8 AM", "9 AM", "10 AM", "11 AM"];

export const DROPDOWN_PROFILE_TASK_REMINDER = ["No Reminder", "Set Reminder"];

export const STEPS_HEADER_ACTIONS = [{ field: "Overview", path: "" }];

export const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: [],
};

export const STEP_THREADS = [
  { type: "Reply to previous thread", key: 1 },
  { type: "New thread", key: 0 },
];

// Steps Constants---------------

export const SEQUENCE_STEP_TYPES = [
  {
    title: "Automated Email",
    description: "Send an email automatically",
    bgColor: "#FFF3E2",
    iconComponent: AUTOMATED_IMAGE,
    component: CreateAutomatedEmail,
  },
  {
    title: "Call",
    description: "Create a task to call account",
    bgColor: "#E4FDFF",
    iconComponent: CALL_IMAGE,
    component: CreateCall,
  },
  {
    title: "To-do",
    description: "Create a task for an action item",
    bgColor: "#F7E9FF",
    iconComponent: TODO_IMAGE,
    component: CreateTodo,
  },
];

export const STEP_TYPE_BG = {
  1: "#EBF1FD",
  2: "#FCF1F7",
  3: "#FFF5E6",
};

export const ICON_BY_STEP_TYPE = {
  1: ICON_LABELS.EMAIL,
  2: ICON_LABELS.STICKY_NOTE,
  3: ICON_LABELS.CALL,
};

export const STEP_TYPE_CHIP_BG = {
  1: "#2080F6",
  2: "#E64593",
  3: "#F4A118",
};

export const STEP_TYPES = {
  1: "Automated Mail",
  2: "To-Do",
  3: "Call",
};

export const STEP_TYPE_COLORS = {
  1: "#FFF3E2",
  2: "#e7d2f9",
  3: "#ddf2f3",
};
export const SIDE_STEP_COLORS = {
  1: "#FFA033",
  2: "#8414F3",
  3: "#1CD6E3",
};

export const STEP_TYPE_COMPONENTS = {
  Email: AutomatedEmailStep,
  Call: CallStep,
  Todo: TodoStep,
};

export const OVERVIEW_TEMPLATE_COLORS = [
  { headerBackground: "#FFF3E2", stepBackground: "#FFA033" },
  { headerBackground: "#F7E9FF", stepBackground: "#8414F3 " },
  { headerBackground: "#E4FDFF", stepBackground: "#1CD6E3" },
];

export const STEPS_PRIORITY_FIELDS = [
  {
    id: "High",
    label: "High",
    iconLabel: ICON_LABELS.FIBER_MANUAL_RECORD,
    iconColor: ERROR.W_500,
  },
  {
    id: "Medium",
    label: "Medium",
    iconLabel: ICON_LABELS.FIBER_MANUAL_RECORD,
    iconColor: ORANGE.W_400,
  },
  {
    id: "Low",
    label: "Low",
    iconLabel: ICON_LABELS.FIBER_MANUAL_RECORD,
    iconColor: WARNING.W_400,
  },
];

export const STEPS_THREAD_FIELDS = [
  { id: "1", label: "New Thread" },
  { id: "2", label: "Reply to previous thread" },
];

export const STEPS_INTERVAL_FIELDS = [
  { id: "1", label: "1" },
  { id: "2", label: "2" },
  { id: "3", label: "3" },
  { id: "4", label: "4" },
  { id: "5", label: "5" },
  { id: "6", label: "6" },
  { id: "7", label: "7" },
  { id: "8", label: "8" },
  { id: "9", label: "9" },
  { id: "10", label: "10" },
  { id: "11", label: "11" },
  { id: "12", label: "12" },
  { id: "13", label: "13" },
  { id: "14", label: "14" },
  { id: "15", label: "15" },
  { id: "16", label: "16" },
  { id: "17", label: "17" },
  { id: "18", label: "18" },
  { id: "19", label: "19" },
  { id: "20", label: "20" },
  { id: "21", label: "21" },
  { id: "22", label: "22" },
  { id: "23", label: "23" },
  { id: "24", label: "24" },
  { id: "25", label: "25" },
  { id: "26", label: "26" },
  { id: "27", label: "27" },
  { id: "28", label: "28" },
  { id: "29", label: "29" },
  { id: "30", label: "30" },
];

export const TASKTYPES = [
  { id: "2", label: "To Do", iconLabel: ICON_LABELS.INSERT_DRIVE_FILE },
  { id: "3", label: "Call", iconLabel: ICON_LABELS.CALL },
  { id: "", label: "All", iconLabel: ICON_LABELS.ALL },
];
export const TASKTYPESFILTER = [
  { id: "2", label: "Completed", iconLabel: ICON_LABELS.CHECK_CIRCLE },
  { id: "3", label: "Overdue", iconLabel: ICON_LABELS.TIMER },
  { id: "", label: "All", iconLabel: ICON_LABELS.ALL },
];
export const CREATETASKTYPES = [
  { id: "2", label: "To Do", iconLabel: ICON_LABELS.INSERT_DRIVE_FILE },
  { id: "3", label: "Call", iconLabel: ICON_LABELS.CALL },
];

export const CREATE_PRIORITY = [
  { id: "1", label: "High" },
  { id: "2", label: "Medium" },
  { id: "3", label: "Low" },
];
export const TIME_LIST = [
  { id: "1", label: "1" },
  { id: "2", label: "2" },
  { id: "3", label: "3" },
  { id: "4", label: "4" },
  { id: "5", label: "5" },
  { id: "6", label: "6" },
  { id: "7", label: "7" },
  { id: "8", label: "8" },
  { id: "9", label: "9" },
  { id: "10", label: "10" },
  { id: "11", label: "11" },
  { id: "12", label: "12" },
];
export const TIME_LIST1 = [
  { id: "1  AM", label: "1  AM" },
  { id: "2  AM", label: "2  AM" },
  { id: "3  AM", label: "3  AM" },
  { id: "4  AM", label: "4  AM" },
  { id: "5 AM", label: "5  AM" },
  { id: "6 AM", label: "6 AM" },
  { id: "7 AM", label: "7 AM" },
  { id: "8 AM", label: "8 AM" },
  { id: "9 AM", label: "9 AM" },
  { id: "10 AM", label: "10 AM" },
  { id: "11 AM", label: "11 AM" },
  { id: "12 AM", label: "12 AM" },
  { id: "1 PM", label: "1 PM" },
  { id: "2 PM", label: "2 PM" },
  { id: "3 PM", label: "3 PM" },
  { id: "4 PM", label: "4 PM" },
  { id: "5 PM", label: "5 PM" },
  { id: "6 PM", label: "6 PM" },
  { id: "7 PM ", label: "7 PM" },
  { id: "8 PM", label: "8 PM" },
  { id: "9 PM", label: "9 PM" },
  { id: "10 PM", label: "10 PM" },
  { id: "11 PM", label: "11 PM" },
  { id: "12 PM", label: "12 PM" },
];

export const MINUTES_LIST = [
  { id: "0", label: 0 },
  { id: "5", label: 5 },
  { id: "10", label: 10 },
  { id: "15", label: 15 },
  { id: "20", label: 20 },
  { id: "25", label: 25 },
  { id: "30", label: 30 },
  { id: "35", label: 35 },
  { id: "40", label: 40 },
  { id: "45", label: 45 },
  { id: "50", label: 50 },
  { id: "55", label: 55 },
];

export const TIME_TYPE = [
  { id: "AM", label: "AM" },
  { id: "PM", label: "PM" },
];

export const CAMPAIGN_CREATE_DAYS_LIST = [
  { day: "sunday", id: 0 },
  { day: "monday", id: 1 },
  { day: "tuesday", id: 2 },
  { day: "wednesday", id: 3 },
  { day: "thursday", id: 4 },
  { day: "friday", id: 5 },
  { day: "saturday", id: 6 },
];

export const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const WEEK_DAYS_ID = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const CAMPAIGNS_STATUS_LIST = [
  { label: "All", icon: FiberManualRecordIcon, color: "#FF4848" },
  { label: "Active", icon: FiberManualRecordIcon, color: "#80F2BA" },
  { label: "Scheduled", icon: FiberManualRecordIcon, color: "#FFC87F" },
  { label: "Paused", icon: FiberManualRecordIcon, color: "#E0A9FF" },
  { label: "Completed", icon: FiberManualRecordIcon, color: "#95F5FC" },
];

export const MONTH_INDEX_PAIR = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};
export const MONTH_INDEX = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const CAMPAIGN_TABLE_HEADS = [
  { id: "name", label: "Name", isChecked: true, minWidth: "220px" },
  { id: "total_steps", label: "Steps", isChecked: true, minWidth: "120px" },
  { id: "owner", label: "Owner", isChecked: true, minWidth: "120px" },
  {
    id: "total_enrolled",
    label: "Total Enrolled",
    isChecked: true,
    minWidth: "120px",
  },
  { id: "lead", label: "Leads", isChecked: true, minWidth: "120px" },
  { id: "sent", label: "Sent", isChecked: true, minWidth: "120px" },
  { id: "deliver", label: "Delivered", isChecked: true, minWidth: "120px" },
  { id: "bounce", label: "Bounces", isChecked: true, minWidth: "120px" },
  { id: "open", label: "Opens", isChecked: true, minWidth: "120px" },
  { id: "click", label: "Clicks", isChecked: true, minWidth: "120px" },
  { id: "reply", label: "Replies", isChecked: true, minWidth: "120px" },
  {
    id: "unsubscribe",
    label: "Unsubscribes",
    isChecked: true,
    minWidth: "120px",
  },
  { id: "state", label: "Status", isChecked: true, minWidth: "120px" },
  { id: "actions", label: "Actions", isChecked: true, minWidth: "120px" },
];
export const CAMPAIGN_TABLE_HEADS1 = [
  { id: "name", label: "Name", isChecked: true, minWidth: "220px" },
  { id: "total_steps", label: "Steps", isChecked: true, minWidth: "120px" },
  {
    id: "total_enrolled",
    label: "Total Enrolled",
    isChecked: true,
    minWidth: "120px",
  },
  { id: "lead", label: "Leads", isChecked: true, minWidth: "120px" },
  { id: "sent", label: "Sent", isChecked: true, minWidth: "120px" },
  { id: "deliver", label: "Delivered", isChecked: true, minWidth: "120px" },
  { id: "bounce", label: "Bounces", isChecked: true, minWidth: "120px" },
  { id: "open", label: "Opens", isChecked: true, minWidth: "120px" },
  { id: "click", label: "Clicks", isChecked: true, minWidth: "120px" },
  { id: "reply", label: "Replies", isChecked: true, minWidth: "120px" },
  {
    id: "unsubscribe",
    label: "Unsubscribes",
    isChecked: true,
    minWidth: "120px",
  },
  { id: "state", label: "Status", isChecked: true, minWidth: "120px" },
  { id: "actions", label: "Actions", isChecked: true, minWidth: "120px" },
];

export const EDITOR_FONT_SIZE_OPTIONS = [
  { id: "2", label: "Normal" },
  { id: "1", label: "Small" },
  { id: "3", label: "Medium" },
  { id: "4", label: "Large" },
  { id: "5", label: "X-Large" },
  { id: "6", label: "Huge" },
];

export const EDITOR_FONT_FAMILY_OPTIONS = [
  { id: "Inter", label: "Inter" },
  { id: "Sans Serif", label: "Sans Serif" },
  { id: "Arial", label: "Arial" },
  { id: "Serif", label: "Serif" },
  { id: "Fixed Width", label: "Fixed Width" },
  { id: "Wide", label: "Wide" },
  { id: "Narrow", label: "Narrow" },
  { id: "Comic Sans MS", label: "Comic Sans MS" },
  { id: "Garamond", label: "Garamond" },
  { id: "Georgia", label: "Georgia" },
  { id: "Tahoma", label: "Tahoma" },
  { id: "Trebuchet MS", label: "Trebuchet MS" },
  { id: "Verdana", label: "Verdana" },
];

export const LIST_TABLE_HEADS = [
  { id: "list_name", label: "List Name", isChecked: true, minWidth: "250px" },
  { id: "owner_name", label: "Owner", isChecked: true, minWidth: "250px" },
  {
    id: "number_of_contacts",
    label: "Number of Contacts",
    isChecked: true,
    minWidth: "260px",
  },
  { id: "created_at", label: "Created at", isChecked: true, minWidth: "250px" },
  { id: "updated_at", label: "Updated at", isChecked: true, minWidth: "250px" },
  { id: "actions", label: "Actions", isChecked: true, minWidth: "250px" },
];

export const CUSTOM_FIELDS_HEADS = [
  { id: "field_name", label: "Field Name", isChecked: true },
  { id: "field_type", label: "Field Type", isChecked: true },
  { id: "action", label: "Actions", isChecked: true },
];

export const IMPORT_COLUMN_PROPERTY_TYPES = [
  { id: "people", label: "People" },
  { id: "company", label: "Company" },
];

export const colorsArray = [
  "#000000",
  "#444444",
  "#666666",
  "#999999",
  "#cccccc",
  "#eeeeee",
  "#f3f3f3",
  "#ffffff",
  "#ff0000",
  "#ff9900",
  "#ffff00",
  "#00ff00",
  "#00ffff",
  "#0000ff",
  "#9900ff",
  "#ff00ff",
  "#f4cccc",
  "#fce5cd",
  "#fff2cc",
  "#d9ead3",
  "#d0e0e3",
  "#cfe2f3",
  "#d9d2e9",
  "#ead1dc",
  "#ea9999",
  "#f9cb9c",
  "#ffe599",
  "#b6d7a8",
  "#a2c4c9",
  "#9fc5e8",
  "#b4a7d6",
  "#d5a6bd",

  "#e06666",
  "#f6b26b",
  "#ffd966",
  "#93c47d",
  "#76a5af",
  "#6fa8dc",
  "#8e7cc3",
  "#c27ba0",

  "#cc0000",
  "#e69138",
  "#f1c232",
  "#6aa84f",
  "#45818e",
  "#3d85c6",
  "#674ea7",
  "#a64d79",

  "#990000",
  "#b45f06",
  "#bf9000",
  "#38761d",
  "#134f5c",
  "#0b5394",
  "#351c75",
  "#741b47",

  "#660000",
  "#783f04",
  "#7f6000",
  "#274e13",
  "#0c343d",
  "#073763",
  "#20124d",
  "#4c1130",
];

export const IMPORT_COLUMN_PEOPLE_PROPERTY_COLUMNS = [
  { id: "first_name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "job_title", label: "Job Title" },
  { id: "company_name", label: "Company Name" },
  { id: "company_city", label: "Company City" },
  { id: "company_state", label: "Company State" },
  { id: "company_country", label: "Company Country" },
  { id: "person_city1", label: "Person City1" },
  { id: "person_stat1e", label: "person Stat1e" },
  { id: "person_coun1try", label: "person Coun1try" },
];

export const EACH_BATCH_SIZE = 512 * 10 * 10 * 5;

export const CAMPAIGN_FINAL_STATES = [
  "interrupted",
  "active",
  "scheduled",
  "completed",
  "paused",
];

export const CAMPAIGN_FINISHED_STAGES = ["interrupted", "completed"];

export const POSSIBLE_STATES_OF_CAMPAIGN = [
  { label: "active", textColor: "#2196F3", bgColor: "#E3F2FD" },
  { label: "completed", textColor: "#4CAF50", bgColor: "#E8F5E9" },
  { label: "scheduled", textColor: "#FF9800", bgColor: "#FFF3E0" },

  { label: "entry", textColor: "#FFC87F", bgColor: "#ffc87f1a" },
  { label: "scheduling", textColor: "#FFC87F", bgColor: "#ffc87f1a" },
  { label: "setting up", textColor: "#FFC87F", bgColor: "#ffc87f1a" },

  { label: "interrupted", textColor: "#FF3B3B", bgColor: "#FFEDED" },
  { label: "paused", textColor: "#FFC107", bgColor: "#FFF8E1" },
];

export const CAMPAIGN_HEADERS_NAVIGATION_LIST = [
  { label: "Sequence", path: "", tour: "campaignoverviews", isSelected: true },
  {
    label: "Contacts",
    path: "/contacts",
    tour: "campaigncontact",
    isSelected: false,
  },
  {
    label: "Activity Log",
    path: "/activity",
    tour: "campaignactivity",
    isSelected: false,
  },
  {
    label: "Settings",
    path: "/settings",
    tour: "campaignsetting",
    isSelected: false,
  },
];

export const CONTACTS_STATS_TYPE = [
  { key: "total_enrolled", label: "Enrolled" },
  { key: "sent", label: "Sent" },
  { key: "deliver", label: "Delivered" },
  { key: "lead", label: "Leads" },
  { key: "bounce", label: "Bounces" },
  { key: "open", label: "Opens" },
  { key: "meeting_book", label: "Booked Meetings" },
  { key: "click", label: "Clicks" },
  { key: "reply", label: "Replies" },
  { key: "unsubscribe", label: "Unsubscribes" },
];

export const SEQUENCE_STATS_DATA = [
  { key: "sent", label: "Sent" },
  { key: "deliver", label: "Delivered" },
  { key: "lead", label: "Leads" },
  { key: "bounce", label: "Bounces" },
  { key: "open", label: "Opens" },
  { key: "meeting_book", label: "Booked Meetings" },
  { key: "click", label: "Clicks" },
  { key: "reply", label: "Replies" },
  { key: "unsubscribe", label: "Unsubscribes" },
];

export const EACH_CAMPAIGN_STATS_TYPE = [
  { key: "sent", label: "Sent" },
  { key: "deliver", label: "Delivered" },
  { key: "lead", label: "Leads" },
  { key: "bounce", label: "Bounces" },
  { key: "open", label: "Opens" },
  { key: "meeting_book", label: "Booked Meetings" },
  { key: "click", label: "Clicks" },
  { key: "reply", label: "Replies" },
  { key: "unsubscribe", label: "Unsubscribes" },
];

export const STATS_KEY_VALUES = {
  total_enrolled: "Total Enrolled",
  deliver: "Delivered",
  open: "Opens",
  sent: "Emails Sent",
  click: "Clicks",
  bounce: "Bounces",
  reply: "Replies",
  email: "Emails",
  call: "Calls",
  todo: "To Do",
  meeting_book: "Booked Meetings",
  complete: "Complete",
  inComplete: "Incomplete",
};

export const WIDGET_BOX_KEY_VALUES = {
  total_enrolled: "Total Enrolled",
  deliver: "Delivered",
  open: "Opens",
  sent: "Emails Sent",
  click: "Clicks",
  bounce: "Bounces",
  reply: "Replies",
  meeting_book: "Booked Meetings",
};


export const IMPORT_CREATE_TYPE = [
  { id: "create_only", label: "Create Only" },
  { id: "update_only", label: "Update Only" },
  { id: "create_and_update", label: "Create and Update both" },
];

export const IMPORT_EMPTY_FIELD_OPTIONS = [
  { id: "false", label: "Leave Empty" },
];

export const IMPORT_BLACKLIST_OPTIONS = [{ id: "email", label: "Email" }];

export const initialTimings = [
  {
    day: 0,
    startTime: 9,
    endTime: 6,
    startMinute: 0,
    endMinute: 0,
    startType: "AM",
    endType: "PM",
    checked: false,
  },
  {
    day: 1,
    startTime: 9,
    endTime: 6,
    startMinute: 0,
    endMinute: 0,
    startType: "AM",
    endType: "PM",
    checked: true,
  },
  {
    day: 2,
    startTime: 9,
    endTime: 6,
    startMinute: 0,
    endMinute: 0,
    startType: "AM",
    endType: "PM",
    checked: true,
  },
  {
    day: 3,
    startTime: 9,
    endTime: 6,
    startMinute: 0,
    endMinute: 0,
    startType: "AM",
    endType: "PM",
    checked: true,
  },
  {
    day: 4,
    startTime: 9,
    endTime: 6,
    startMinute: 0,
    endMinute: 0,
    startType: "AM",
    endType: "PM",
    checked: true,
  },
  {
    day: 5,
    startTime: 9,
    endTime: 6,
    startMinute: 0,
    endMinute: 0,
    startType: "AM",
    endType: "PM",
    checked: true,
  },
  {
    day: 6,
    startTime: 9,
    endTime: 6,
    startMinute: 0,
    endMinute: 0,
    startType: "AM",
    endType: "PM",
    checked: false,
  },
];

export const COUNTRY_LIST = [
  { id: "Afghanistan", label: "Afghanistan" },
  { id: "Åland Islands", label: "Åland Islands" },
  { id: "Albania", label: "Albania" },
  { id: "Algeria", label: "Algeria" },
  { id: "American Samoa", label: "American Samoa" },
  { id: "AndorrA", label: "AndorrA" },
  { id: "Angola", label: "Angola" },
  { id: "Anguilla", label: "Anguilla" },
  { id: "Antarctica", label: "Antarctica" },
  { id: "Antigua and Barbuda ", label: "Antigua and Barbuda" },
  { id: "Argentina", label: "Argentina" },
  { id: "Armenia", label: "Armenia" },
  { id: "Aruba", label: "Aruba" },
  { id: "Australia", label: "Australia" },
  { id: "Austria", label: "Austria" },
  { id: "Azerbaijan", label: "Azerbaijan" },
  { id: "Bahamas", label: "Bahamas" },
  { id: "Bahrain", label: "Bahrain" },
  { id: "Bangladesh", label: "Bangladesh" },
  { id: "Barbados", label: "Barbados" },
  { id: "Belarus", label: "Belarus" },
  { id: "Belgium", label: "Belgium" },
  { id: "Belize", label: "Belize" },
  { id: "Benin", label: "Benin" },
  { id: "Bermuda", label: "Bermuda" },
  { id: "Bhutan", label: "Bhutan" },
  { id: "Bolivia", label: "Bolivia" },
  { id: "Bosnia nd Herzegovina", label: "Bosnia and Herzegovina" },
  { id: "Botswana", label: "Botswana" },
  { id: "Bouvet Island", label: "Bouvet Island" },
  { id: "Brazil", label: "Brazil" },
  {
    id: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  { id: "Brunei Darussalam", label: "Brunei Darussalam" },
  { id: "Bulgaria", label: "Bulgaria" },
  { id: "Burkina Faso", label: "Burkina Faso" },
  { id: "Burundi", label: "Burundi" },
  { id: "Cambodia", label: "Cambodia" },
  { id: "Cameroon", label: "Cameroon" },
  { id: "Canada", label: "Canada" },
  { id: "Cape Verde", label: "Cape Verde" },
  { id: "Cayman Islands", label: "Cayman Islands" },
  { id: "Central African Republic", label: "Central African Republic" },
  { id: "Chad", label: "Chad" },
  { id: "Chile", label: "Chile" },
  { id: "China", label: "China" },
  { id: "Christma", label: "Christmas Island" },
  { id: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
  { id: "Colombia ", label: "Colombia" },
  { id: "Comoros", label: "Comoros" },
  { id: "Congo", label: "Congo" },
  {
    id: "Congo, The Democratic Republic of the",
    label: "Congo, The Democratic Republic of the",
  },
  { id: "Cook Islands", label: "Cook Islands" },
  { id: "Costa Rica", label: "Costa Rica" },
  { id: "Cote D'Ivoire", label: "Cote D'Ivoire" },
  { id: "Croatia", label: "Croatia" },
  { id: "Cuba", label: "Cuba" },
  { id: "Cyprus", label: "Cyprus" },
  { id: "Czech Republic", label: "Czech Republic" },
  { id: "Denmark", label: "Denmark" },
  { id: "Djibouti", label: "Djibouti" },
  { id: "Dominica", label: "Dominica" },
  { id: "Dominican Republic", label: "Dominican Republic" },
  { id: "Ecuador", label: "Ecuador" },
  { id: "Egypt", label: "Egypt" },
  { id: "El Salvador", label: "El Salvador" },
  { id: "Equatori Guinea", label: "Equatorial Guinea" },
  { id: "Eritrea", label: "Eritrea" },
  { id: "Estonia", label: "Estonia" },
  { id: "Ethiopia", label: "Ethiopia" },
  { id: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
  { id: "Faroe Islands", label: "Faroe Islands" },
  { id: "Fiji", label: "Fiji" },
  { id: "Finland", label: "Finland" },
  { id: "France", label: "France" },
  { id: "French Guiana", label: "French Guiana" },
  { id: "French Polynesia", label: "French Polynesia" },
  { id: "French Southern Territories", label: "French Southern Territories" },
  { id: "Gabon", label: "Gabon" },
  { id: "Gambia", label: "Gambia" },
  { id: "Georgia", label: "Georgia" },
  { id: "Germany", label: "Germany" },
  { id: "Ghana", label: "Ghana" },
  { id: "Gibraltar", label: "Gibraltar" },
  { id: "Greece", label: "Greece" },
  { id: "Greenland", label: "Greenland" },
  { id: "Grenada", label: "Grenada" },
  { id: "Guadeloupe", label: "Guadeloupe" },
  { id: "Guam", label: "Guam" },
  { id: "Guatemala", label: "Guatemala" },
  { id: "Guernsey", label: "Guernsey" },
  { id: "Guinea", label: "Guinea" },
  { id: "Guinea-Bissau", label: "Guinea-Bissau" },
  { id: "Guyana", label: "Guyana" },
  { id: "Haiti", label: "Haiti" },
  {
    id: "Heard Island and Mcdonald Islands",
    label: "Heard Island and Mcdonald Islands",
  },
  {
    id: "Holy See (Vatican City State)",
    label: "Holy See (Vatican City State)",
  },
  { id: "Honduras", label: "Honduras" },
  { id: "Hong Kong", label: "Hong Kong" },
  { id: "Hungary", label: "Hungary" },
  { id: "Iceland", label: "Iceland" },
  { id: "India", label: "India" },
  { id: "Indonesia", label: "Indonesia" },
  { id: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
  { id: "Iraq", label: "Iraq" },
  { id: "Ireland", label: "Ireland" },
  { id: "Isle of Man", label: "Isle of Man" },
  { id: "Israel", label: "Israel" },
  { id: "Italy", label: "Italy" },
  { id: "Jamaica", label: "Jamaica" },
  { id: "Japan", label: "Japan" },
  { id: "Jersey", label: "Jersey" },
  { id: "Jordan", label: "Jordan" },
  { id: "Kazakhstan", label: "Kazakhstan" },
  { id: "Kenya", label: "Kenya" },
  { id: "Kiribati", label: "Kiribati" },
  {
    id: "Korea,  Democratic People'S Republic of",
    label: "Korea, Democratic People'S Republic of",
  },
  { id: "Korea, Republic of", label: "Korea, Republic of" },
  { id: "Kuwait", label: "Kuwait" },
  { id: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    id: "Lao People'S Democratic Republic",
    label: "Lao People'S Democratic Republic",
  },
  { id: "Latvia", label: "Latvia" },
  { id: "Lebanon", label: "Lebanon" },
  { id: "Lesotho", label: "Lesotho" },
  { id: "Liberia", label: "Liberia" },
  { id: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
  { id: "Liechtenstein", label: "Liechtenstein" },
  { id: "Lithuania", label: "Lithuania" },
  { id: "Luxembourg", label: "Luxembourg" },
  { id: "Macao", label: "Macao" },
  {
    id: "Macedoni, The Former Yugoslav Republic of",
    label: "Macedonia, The Former Yugoslav Republic of",
  },
  { id: "Madagascar", label: "Madagascar" },
  { id: "Malawi", label: "Malawi" },
  { id: "Malaysia", label: "Malaysia" },
  { id: "Maldives", label: "Maldives" },
  { id: "Mali", label: "Mali" },
  { id: "Malta", label: "Malta" },
  { id: "Marshall Islands", label: "Marshall Islands" },
  { id: "Martinique", label: "Martinique" },
  { id: "Mauritania", label: "Mauritania" },
  { id: "Mauritius", label: "Mauritius" },
  { id: "Mayotte", label: "Mayotte" },
  { id: "Mexico", label: "Mexico" },
  {
    id: "Micrones, Federated States of",
    label: "Micronesia, Federated States of",
  },
  { id: "Moldova, Republic of", label: "Moldova, Republic of" },
  { id: "Monaco", label: "Monaco" },
  { id: "Mongolia", label: "Mongolia" },
  { id: "Montserrat", label: "Montserrat" },
  { id: "Morocco", label: "Morocco" },
  { id: "Mozambique", label: "Mozambique" },
  { id: "Myanmar", label: "Myanmar" },
  { id: "Namibia", label: "Namibia" },
  { id: "Nauru", label: "Nauru" },
  { id: "Nepal", label: "Nepal" },
  { id: "Netherlands", label: "Netherlands" },
  { id: "Netherlands Antilles", label: "Netherlands Antilles" },
  { id: "New Caledonia", label: "New Caledonia" },
  { id: "New Zealand", label: "New Zealand" },
  { id: "Nicaragua", label: "Nicaragua" },
  { id: "Niger", label: "Niger" },
  { id: "Nigeria", label: "Nigeria" },
  { id: "Niue", label: "Niue" },
  { id: "Norfolk Island", label: "Norfolk Island" },
  { id: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { id: "Norway", label: "Norway" },
  { id: "Oman", label: "Oman" },
  { id: "Pakistan", label: "Pakistan" },
  { id: "Palau", label: "Palau" },
  {
    id: "Palestinan Territory, Occupied",
    label: "Palestinian Territory, Occupied",
  },
  { id: "Panama", label: "Panama" },
  { id: "Papua New Guinea", label: "Papua New Guinea" },
  { id: "Paraguay", label: "Paraguay" },
  { id: "Peru", label: "Peru" },
  { id: "Philippines", label: "Philippines" },
  { id: "Pitcairn", label: "Pitcairn" },
  { id: "Poland", label: "Poland" },
  { id: "Portugal", label: "Portugal" },
  { id: "Puerto Rico", label: "Puerto Rico" },
  { id: "Qatar", label: "Qatar" },
  { id: "Reunion", label: "Reunion" },
  { id: "Romania", label: "Romania" },
  { id: "Russian Federation", label: "Russian Federation" },
  { id: "RWANDA", label: "RWANDA" },
  { id: "Saint Helena", label: "Saint Helena" },
  { id: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { id: "Saint Lucia", label: "Saint Lucia" },
  { id: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  {
    id: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { id: "Samoa", label: "Samoa" },
  { id: "San Mari ", label: "San Marino" },
  { id: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { id: "Saudi Arabia", label: "Saudi Arabia" },
  { id: "Senegal", label: "Senegal" },
  { id: "Serbia and Montenegro", label: "Serbia and Montenegro" },
  { id: "Seychelles", label: "Seychelles" },
  { id: "Sierra Leone", label: "Sierra Leone" },
  { id: "Singapore", label: "Singapore" },
  { id: "Slovakia", label: "Slovakia" },
  { id: "Slovenia", label: "Slovenia" },
  { id: "Solomon Islands", label: "Solomon Islands" },
  { id: "Somalia", label: "Somalia" },
  { id: "South Africa", label: "South Africa" },
  {
    id: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  { id: "Spain", label: "Spain" },
  { id: "Sri Lanka", label: "Sri Lanka" },
  { id: "Sudan", label: "Sudan" },
  { id: "Suriname", label: "Suriname" },
  { id: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { id: "Swaziland", label: "Swaziland" },
  { id: "Sweden", label: "Sweden" },
  { id: "Switzerland", label: "Switzerland" },
  { id: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { id: "Taiwan, Province of China", label: "Taiwan, Province of China" },
  { id: "Tajikistan", label: "Tajikistan" },
  {
    id: "Tanzania , United Republic of",
    label: "Tanzania, United Republic of",
  },
  { id: "Thailand", label: "Thailand" },
  { id: "Timor-Leste", label: "Timor-Leste" },
  { id: "Togo", label: "Togo" },
  { id: "Tokelau", label: "Tokelau" },
  { id: "Tonga", label: "Tonga" },
  { id: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { id: "Tunisia", label: "Tunisia" },
  { id: "Turkey", label: "Turkey" },
  { id: "Turkmenistan", label: "Turkmenistan" },
  { id: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { id: "Tuvalu", label: "Tuvalu" },
  { id: "Uganda", label: "Uganda" },
  { id: "Ukraine", label: "Ukraine" },
  { id: "United Arab Emirates", label: "United Arab Emirates" },
  { id: "United Kingdom", label: "United Kingdom" },
  { id: "United States", label: "United States" },
  {
    id: "United  States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
  },
  { id: "Uruguay", label: "Uruguay" },
  { id: "Uzbekist ", label: "Uzbekistan" },
  { id: "Vanuatu", label: "Vanuatu" },
  { id: "Venezuel", label: "Venezuela" },
  { id: "Viet Nam", label: "Viet Nam" },
  { id: "Virgin Islands, British", label: "Virgin Islands, British" },
  { id: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
  { id: "Wallis and Futuna", label: "Wallis and Futuna" },
  { id: "Western", label: "Western Sahara" },
  { id: "Yemen", label: "Yemen" },
  { id: "Zambia", label: "Zambia" },
  { id: "Zimbabwe ", label: "Zimbabwe" },
];

export const YEAR_LIST = [
  { label: "january", id: "january" },
  { label: "february", id: "february" },
  { label: "march", id: "march" },
  { label: "april", id: "april" },
  { label: "may", id: "may" },
  { label: "june", id: "june" },
  { label: "july", id: "july" },
  { label: "august", id: "august" },
  { label: "september", id: "september" },
  { label: "october", id: "october" },
  { label: "november", id: "november" },
  { label: "december", id: "december" },
];
export const TIMEZONE_LIST = [
  { label: "Asia/Calcutta(UTC+05:30)", offset: 330, id: "Asia/Calcutta" },
  { label: "Etc/GMT+12(UTC-12:00)", offset: -720, id: "Etc/GMT+12" },
  { label: "Etc/GMT+11(UTC-11:00)", offset: -660, id: "Etc/GMT+11" },
  { label: "Pacific/Midway(UTC-11:00)", offset: -660, id: "Pacific/Midway" },
  { label: "Pacific/Niue(UTC-11:00)", offset: -660, id: "Pacific/Niue" },
  {
    label: "Pacific/Pago_Pago(UTC-11:00)",
    offset: -660,
    id: "Pacific/Pago_Pago",
  },
  { label: "Etc/GMT+10(UTC-10:00)", offset: -600, id: "Etc/GMT+10" },
  {
    label: "Pacific/Honolulu(UTC-10:00)",
    offset: -600,
    id: "Pacific/Honolulu",
  },
  {
    label: "Pacific/Johnston(UTC-10:00)",
    offset: -600,
    id: "Pacific/Johnston",
  },
  {
    label: "Pacific/Rarotonga(UTC-10:00)",
    offset: -600,
    id: "Pacific/Rarotonga",
  },
  { label: "Pacific/Tahiti(UTC-10:00)", offset: -600, id: "Pacific/Tahiti" },
  {
    label: "America/Anchorage(UTC-09:00)",
    offset: -480,
    id: "America/Anchorage",
  },
  { label: "America/Juneau(UTC-09:00)", offset: -480, id: "America/Juneau" },
  { label: "America/Nome(UTC-09:00)", offset: -480, id: "America/Nome" },
  { label: "America/Sitka(UTC-09:00)", offset: -480, id: "America/Sitka" },
  { label: "America/Yakutat(UTC-09:00)", offset: -480, id: "America/Yakutat" },
  {
    label: "America/Santa_Isabel(UTC-08:00)",
    offset: -420,
    id: "America/Santa_Isabel",
  },
  {
    label: "America/Los_Angeles(UTC-07:00)",
    offset: -420,
    id: "America/Los_Angeles",
  },
  { label: "America/Tijuana(UTC-07:00)", offset: -420, id: "America/Tijuana" },
  {
    label: "America/Vancouver(UTC-07:00)",
    offset: -420,
    id: "America/Vancouver",
  },
  { label: "PST8PDT(UTC-08:00)", offset: -480, id: "PST8PDT" },
  { label: "America/Creston(UTC-07:00)", offset: -420, id: "America/Creston" },
  { label: "America/Dawson(UTC-07:00)", offset: -420, id: "America/Dawson" },
  {
    label: "America/Dawson_Creek(UTC-07:00)",
    offset: -420,
    id: "America/Dawson_Creek",
  },
  {
    label: "America/Hermosillo(UTC-07:00)",
    offset: -420,
    id: "America/Hermosillo",
  },
  { label: "America/Phoenix(UTC-07:00)", offset: -420, id: "America/Phoenix" },
  {
    label: "America/Whitehorse(UTC-07:00)",
    offset: -420,
    id: "America/Whitehorse",
  },
  { label: "Etc/GMT+7(UTC-07:00)", offset: -420, id: "Etc/GMT+7" },
  {
    label: "America/Chihuahua(UTC-07:00)",
    offset: -360,
    id: "America/Chihuahua",
  },
  {
    label: "America/Mazatlan(UTC-07:00)",
    offset: -360,
    id: "America/Mazatlan",
  },
  { label: "America/Boise(UTC-07:00)", offset: -360, id: "America/Boise" },
  {
    label: "America/Cambridge_Bay(UTC-07:00)",
    offset: -360,
    id: "America/Cambridge_Bay",
  },
  { label: "America/Denver(UTC-07:00)", offset: -360, id: "America/Denver" },
  {
    label: "America/Edmonton(UTC-07:00)",
    offset: -360,
    id: "America/Edmonton",
  },
  { label: "America/Inuvik(UTC-07:00)", offset: -360, id: "America/Inuvik" },
  { label: "America/Ojinaga(UTC-07:00)", offset: -360, id: "America/Ojinaga" },
  {
    label: "America/Yellowknife(UTC-07:00)",
    offset: -360,
    id: "America/Yellowknife",
  },
  { label: "MST7MDT(UTC-07:00)", offset: -360, id: "MST7MDT" },
  { label: "America/Belize(UTC-06:00)", offset: -360, id: "America/Belize" },
  {
    label: "America/Costa_Rica(UTC-06:00)",
    offset: -360,
    id: "America/Costa_Rica",
  },
  {
    label: "America/El_Salvador(UTC-06:00)",
    offset: -360,
    id: "America/El_Salvador",
  },
  {
    label: "America/Guatemala(UTC-06:00)",
    offset: -360,
    id: "America/Guatemala",
  },
  { label: "America/Managua(UTC-06:00)", offset: -360, id: "America/Managua" },
  {
    label: "America/Tegucigalpa(UTC-06:00)",
    offset: -360,
    id: "America/Tegucigalpa",
  },
  { label: "Etc/GMT+6(UTC-06:00)", offset: -360, id: "Etc/GMT+6" },
  {
    label: "Pacific/Galapagos(UTC-06:00)",
    offset: -360,
    id: "Pacific/Galapagos",
  },
  { label: "America/Chicago(UTC-06:00)", offset: -300, id: "America/Chicago" },
  {
    label: "America/Indiana/Knox(UTC-06:00)",
    offset: -300,
    id: "America/Indiana/Knox",
  },
  {
    label: "America/Indiana/Tell_City(UTC-06:00)",
    offset: -300,
    id: "America/Indiana/Tell_City",
  },
  {
    label: "America/Matamoros(UTC-06:00)",
    offset: -300,
    id: "America/Matamoros",
  },
  {
    label: "America/Menominee(UTC-06:00)",
    offset: -300,
    id: "America/Menominee",
  },
  {
    label: "America/North_Dakota/Beulah(UTC-06:00)",
    offset: -300,
    id: "America/North_Dakota/Beulah",
  },
  {
    label: "America/North_Dakota/Center(UTC-06:00)",
    offset: -300,
    id: "America/North_Dakota/Center",
  },
  {
    label: "America/North_Dakota/New_Salem(UTC-06:00)",
    offset: -300,
    id: "America/North_Dakota/New_Salem",
  },
  {
    label: "America/Rainy_River(UTC-06:00)",
    offset: -300,
    id: "America/Rainy_River",
  },
  {
    label: "America/Rankin_Inlet(UTC-06:00)",
    offset: -300,
    id: "America/Rankin_Inlet",
  },
  {
    label: "America/Resolute(UTC-06:00)",
    offset: -300,
    id: "America/Resolute",
  },
  {
    label: "America/Winnipeg(UTC-06:00)",
    offset: -300,
    id: "America/Winnipeg",
  },
  { label: "CST6CDT(UTC-06:00)", offset: -300, id: "CST6CDT" },
  {
    label: "America/Bahia_Banderas(UTC-06:00)",
    offset: -300,
    id: "America/Bahia_Banderas",
  },
  { label: "America/Cancun(UTC-06:00)", offset: -300, id: "America/Cancun" },
  { label: "America/Merida(UTC-06:00)", offset: -300, id: "America/Merida" },
  {
    label: "America/Mexico_City(UTC-06:00)",
    offset: -300,
    id: "America/Mexico_City",
  },
  {
    label: "America/Monterrey(UTC-06:00)",
    offset: -300,
    id: "America/Monterrey",
  },
  { label: "America/Regina(UTC-06:00)", offset: -360, id: "America/Regina" },
  {
    label: "America/Swift_Current(UTC-06:00)",
    offset: -360,
    id: "America/Swift_Current",
  },
  { label: "America/Bogota(UTC-05:00)", offset: -300, id: "America/Bogota" },
  { label: "America/Cayman(UTC-05:00)", offset: -300, id: "America/Cayman" },
  {
    label: "America/Coral_Harbour(UTC-05:00)",
    offset: -300,
    id: "America/Coral_Harbour",
  },
  {
    label: "America/Eirunepe(UTC-05:00)",
    offset: -300,
    id: "America/Eirunepe",
  },
  {
    label: "America/Guayaquil(UTC-05:00)",
    offset: -300,
    id: "America/Guayaquil",
  },
  { label: "America/Jamaica(UTC-05:00)", offset: -300, id: "America/Jamaica" },
  { label: "America/Lima(UTC-05:00)", offset: -300, id: "America/Lima" },
  { label: "America/Panama(UTC-05:00)", offset: -300, id: "America/Panama" },
  {
    label: "America/Rio_Branco(UTC-05:00)",
    offset: -300,
    id: "America/Rio_Branco",
  },
  { label: "Etc/GMT+5(UTC-05:00)", offset: -300, id: "Etc/GMT+5" },
  { label: "America/Detroit(UTC-05:00)", offset: -300, id: "America/Detroit" },
  { label: "America/Havana(UTC-05:00)", offset: -300, id: "America/Havana" },
  {
    label: "America/Indiana/Petersburg(UTC-05:00)",
    offset: -300,
    id: "America/Indiana/Petersburg",
  },
  {
    label: "America/Indiana/Vincennes(UTC-05:00)",
    offset: -300,
    id: "America/Indiana/Vincennes",
  },
  {
    label: "America/Indiana/Winamac(UTC-05:00)",
    offset: -300,
    id: "America/Indiana/Winamac",
  },
  { label: "America/Iqaluit(UTC-05:00)", offset: -300, id: "America/Iqaluit" },
  {
    label: "America/Kentucky/Monticello(UTC-05:00)",
    offset: -300,
    id: "America/Kentucky/Monticello",
  },
  {
    label: "America/Louisville(UTC-05:00)",
    offset: -300,
    id: "America/Louisville",
  },
  {
    label: "America/Montreal(UTC-05:00)",
    offset: -300,
    id: "America/Montreal",
  },
  { label: "America/Nassau(UTC-05:00)", offset: -300, id: "America/Nassau" },
  {
    label: "America/New_York(UTC-05:00)",
    offset: -300,
    id: "America/New_York",
  },
  { label: "America/Nipigon(UTC-05:00)", offset: -300, id: "America/Nipigon" },
  {
    label: "America/Pangnirtung(UTC-05:00)",
    offset: -300,
    id: "America/Pangnirtung",
  },
  {
    label: "America/Port-au-Prince(UTC-05:00)",
    offset: -300,
    id: "America/Port-au-Prince",
  },
  {
    label: "America/Thunder_Bay(UTC-05:00)",
    offset: -300,
    id: "America/Thunder_Bay",
  },
  { label: "America/Toronto(UTC-05:00)", offset: -300, id: "America/Toronto" },
  {
    label: "America/Indiana/Marengo(UTC-05:00)",
    offset: -300,
    id: "America/Indiana/Marengo",
  },
  {
    label: "America/Indiana/Vevay(UTC-05:00)",
    offset: -300,
    id: "America/Indiana/Vevay",
  },
  {
    label: "America/Indianapolis(UTC-05:00)",
    offset: -300,
    id: "America/Indianapolis",
  },
  { label: "America/Caracas(UTC-04:30)", offset: -270, id: "America/Caracas" },
  {
    label: "America/Asuncion(UTC-04:00)",
    offset: -240,
    id: "America/Asuncion",
  },
  {
    label: "America/Glace_Bay(UTC-04:00)",
    offset: -180,
    id: "America/Glace_Bay",
  },
  {
    label: "America/Goose_Bay(UTC-04:00)",
    offset: -180,
    id: "America/Goose_Bay",
  },
  { label: "America/Halifax(UTC-04:00)", offset: -180, id: "America/Halifax" },
  { label: "America/Moncton(UTC-04:00)", offset: -180, id: "America/Moncton" },
  { label: "America/Thule(UTC-04:00)", offset: -180, id: "America/Thule" },
  {
    label: "Atlantic/Bermuda(UTC-04:00)",
    offset: -180,
    id: "Atlantic/Bermuda",
  },
  {
    label: "America/Campo_Grande(UTC-04:00)",
    offset: -240,
    id: "America/Campo_Grande",
  },
  { label: "America/Cuiaba(UTC-04:00)", offset: -240, id: "America/Cuiaba" },
  {
    label: "America/Anguilla(UTC-04:00)",
    offset: -240,
    id: "America/Anguilla",
  },
  { label: "America/Antigua(UTC-04:00)", offset: -240, id: "America/Antigua" },
  { label: "America/Aruba(UTC-04:00)", offset: -240, id: "America/Aruba" },
  {
    label: "America/Barbados(UTC-04:00)",
    offset: -240,
    id: "America/Barbados",
  },
  {
    label: "America/Blanc-Sablon(UTC-04:00)",
    offset: -240,
    id: "America/Blanc-Sablon",
  },
  {
    label: "America/Boa_Vista(UTC-04:00)",
    offset: -240,
    id: "America/Boa_Vista",
  },
  { label: "America/Curacao(UTC-04:00)", offset: -240, id: "America/Curacao" },
  {
    label: "America/Dominica(UTC-04:00)",
    offset: -240,
    id: "America/Dominica",
  },
  {
    label: "America/Grand_Turk(UTC-04:00)",
    offset: -240,
    id: "America/Grand_Turk",
  },
  { label: "America/Grenada(UTC-04:00)", offset: -240, id: "America/Grenada" },
  {
    label: "America/Guadeloupe(UTC-04:00)",
    offset: -240,
    id: "America/Guadeloupe",
  },
  { label: "America/Guyana(UTC-04:00)", offset: -240, id: "America/Guyana" },
  {
    label: "America/Kralendijk(UTC-04:00)",
    offset: -240,
    id: "America/Kralendijk",
  },
  { label: "America/La_Paz(UTC-04:00)", offset: -240, id: "America/La_Paz" },
  {
    label: "America/Lower_Princes(UTC-04:00)",
    offset: -240,
    id: "America/Lower_Princes",
  },
  { label: "America/Manaus(UTC-04:00)", offset: -240, id: "America/Manaus" },
  { label: "America/Marigot(UTC-04:00)", offset: -240, id: "America/Marigot" },
  {
    label: "America/Martinique(UTC-04:00)",
    offset: -240,
    id: "America/Martinique",
  },
  {
    label: "America/Montserrat(UTC-04:00)",
    offset: -240,
    id: "America/Montserrat",
  },
  {
    label: "America/Port_of_Spain(UTC-04:00)",
    offset: -240,
    id: "America/Port_of_Spain",
  },
  {
    label: "America/Porto_Velho(UTC-04:00)",
    offset: -240,
    id: "America/Porto_Velho",
  },
  {
    label: "America/Puerto_Rico(UTC-04:00)",
    offset: -240,
    id: "America/Puerto_Rico",
  },
  {
    label: "America/Santo_Domingo(UTC-04:00)",
    offset: -240,
    id: "America/Santo_Domingo",
  },
  {
    label: "America/St_Barthelemy(UTC-04:00)",
    offset: -240,
    id: "America/St_Barthelemy",
  },
  {
    label: "America/St_Kitts(UTC-04:00)",
    offset: -240,
    id: "America/St_Kitts",
  },
  {
    label: "America/St_Lucia(UTC-04:00)",
    offset: -240,
    id: "America/St_Lucia",
  },
  {
    label: "America/St_Thomas(UTC-04:00)",
    offset: -240,
    id: "America/St_Thomas",
  },
  {
    label: "America/St_Vincent(UTC-04:00)",
    offset: -240,
    id: "America/St_Vincent",
  },
  { label: "America/Tortola(UTC-04:00)", offset: -240, id: "America/Tortola" },
  { label: "Etc/GMT+4(UTC-04:00)", offset: -240, id: "Etc/GMT+4" },
  {
    label: "America/Santiago(UTC-04:00)",
    offset: -240,
    id: "America/Santiago",
  },
  {
    label: "Antarctica/Palmer(UTC-04:00)",
    offset: -240,
    id: "Antarctica/Palmer",
  },
  {
    label: "America/St_Johns(UTC-03:30)",
    offset: -150,
    id: "America/St_Johns",
  },
  {
    label: "America/Sao_Paulo(UTC-03:00)",
    offset: -180,
    id: "America/Sao_Paulo",
  },
  {
    label: "America/Argentina/La_Rioja(UTC-03:00)",
    offset: -180,
    id: "America/Argentina/La_Rioja",
  },
  {
    label: "America/Argentina/Rio_Gallegos(UTC-03:00)",
    offset: -180,
    id: "America/Argentina/Rio_Gallegos",
  },
  {
    label: "America/Argentina/Salta(UTC-03:00)",
    offset: -180,
    id: "America/Argentina/Salta",
  },
  {
    label: "America/Argentina/San_Juan(UTC-03:00)",
    offset: -180,
    id: "America/Argentina/San_Juan",
  },
  {
    label: "America/Argentina/San_Luis(UTC-03:00)",
    offset: -180,
    id: "America/Argentina/San_Luis",
  },
  {
    label: "America/Argentina/Tucuman(UTC-03:00)",
    offset: -180,
    id: "America/Argentina/Tucuman",
  },
  {
    label: "America/Argentina/Ushuaia(UTC-03:00)",
    offset: -180,
    id: "America/Argentina/Ushuaia",
  },
  {
    label: "America/Buenos_Aires(UTC-03:00)",
    offset: -180,
    id: "America/Buenos_Aires",
  },
  {
    label: "America/Catamarca(UTC-03:00)",
    offset: -180,
    id: "America/Catamarca",
  },
  { label: "America/Cordoba(UTC-03:00)", offset: -180, id: "America/Cordoba" },
  { label: "America/Jujuy(UTC-03:00)", offset: -180, id: "America/Jujuy" },
  { label: "America/Mendoza(UTC-03:00)", offset: -180, id: "America/Mendoza" },
  {
    label: "America/Araguaina(UTC-03:00)",
    offset: -180,
    id: "America/Araguaina",
  },
  { label: "America/Belem(UTC-03:00)", offset: -180, id: "America/Belem" },
  { label: "America/Cayenne(UTC-03:00)", offset: -180, id: "America/Cayenne" },
  {
    label: "America/Fortaleza(UTC-03:00)",
    offset: -180,
    id: "America/Fortaleza",
  },
  { label: "America/Maceio(UTC-03:00)", offset: -180, id: "America/Maceio" },
  {
    label: "America/Paramaribo(UTC-03:00)",
    offset: -180,
    id: "America/Paramaribo",
  },
  { label: "America/Recife(UTC-03:00)", offset: -180, id: "America/Recife" },
  {
    label: "America/Santarem(UTC-03:00)",
    offset: -180,
    id: "America/Santarem",
  },
  {
    label: "Antarctica/Rothera(UTC-03:00)",
    offset: -180,
    id: "Antarctica/Rothera",
  },
  {
    label: "Atlantic/Stanley(UTC-03:00)",
    offset: -180,
    id: "Atlantic/Stanley",
  },
  { label: "Etc/GMT+3(UTC-03:00)", offset: -180, id: "Etc/GMT+3" },
  { label: "America/Godthab(UTC-03:00)", offset: -180, id: "America/Godthab" },
  {
    label: "America/Montevideo(UTC-03:00)",
    offset: -180,
    id: "America/Montevideo",
  },
  { label: "America/Bahia(UTC-03:00)", offset: -180, id: "America/Bahia" },
  { label: "America/Noronha(UTC-02:00)", offset: -120, id: "America/Noronha" },
  {
    label: "Atlantic/South_Georgia(UTC-02:00)",
    offset: -120,
    id: "Atlantic/South_Georgia",
  },
  { label: "Etc/GMT+2(UTC-02:00)", offset: -120, id: "Etc/GMT+2" },
  {
    label: "America/Scoresbysund(UTC-01:00)",
    offset: 0,
    id: "America/Scoresbysund",
  },
  { label: "Atlantic/Azores(UTC-01:00)", offset: 0, id: "Atlantic/Azores" },
  {
    label: "Atlantic/Cape_Verde(UTC-01:00)",
    offset: -60,
    id: "Atlantic/Cape_Verde",
  },
  { label: "Etc/GMT+1(UTC-01:00)", offset: -60, id: "Etc/GMT+1" },
  {
    label: "Africa/Casablanca(UTC+01:00)",
    offset: 60,
    id: "Africa/Casablanca",
  },
  { label: "Africa/El_Aaiun(UTC+01:00)", offset: 60, id: "Africa/El_Aaiun" },
  {
    label: "America/Danmarkshavn(UTC+00:00)",
    offset: 0,
    id: "America/Danmarkshavn",
  },
  { label: "Etc/GMT(UTC+00:00)", offset: 0, id: "Etc/GMT" },
  {
    label: "Europe/Isle_of_Man(UTC+00:00)",
    offset: 0,
    id: "Europe/Isle_of_Man",
  },
  { label: "Europe/Guernsey(UTC+00:00)", offset: 0, id: "Europe/Guernsey" },
  { label: "Europe/Jersey(UTC+00:00)", offset: 0, id: "Europe/Jersey" },
  { label: "Europe/London(UTC+00:00)", offset: 0, id: "Europe/London" },
  { label: "Atlantic/Canary(UTC+01:00)", offset: 60, id: "Atlantic/Canary" },
  { label: "Atlantic/Faeroe(UTC+01:00)", offset: 60, id: "Atlantic/Faeroe" },
  { label: "Atlantic/Madeira(UTC+01:00)", offset: 60, id: "Atlantic/Madeira" },
  { label: "Europe/Dublin(UTC+01:00)", offset: 60, id: "Europe/Dublin" },
  { label: "Europe/Lisbon(UTC+01:00)", offset: 60, id: "Europe/Lisbon" },
  { label: "Africa/Abidjan(UTC+00:00)", offset: 0, id: "Africa/Abidjan" },
  { label: "Africa/Accra(UTC+00:00)", offset: 0, id: "Africa/Accra" },
  { label: "Africa/Bamako(UTC+00:00)", offset: 0, id: "Africa/Bamako" },
  { label: "Africa/Banjul(UTC+00:00)", offset: 0, id: "Africa/Banjul" },
  { label: "Africa/Bissau(UTC+00:00)", offset: 0, id: "Africa/Bissau" },
  { label: "Africa/Conakry(UTC+00:00)", offset: 0, id: "Africa/Conakry" },
  { label: "Africa/Dakar(UTC+00:00)", offset: 0, id: "Africa/Dakar" },
  { label: "Africa/Freetown(UTC+00:00)", offset: 0, id: "Africa/Freetown" },
  { label: "Africa/Lome(UTC+00:00)", offset: 0, id: "Africa/Lome" },
  { label: "Africa/Monrovia(UTC+00:00)", offset: 0, id: "Africa/Monrovia" },
  { label: "Africa/Nouakchott(UTC+00:00)", offset: 0, id: "Africa/Nouakchott" },
  {
    label: "Africa/Ouagadougou(UTC+00:00)",
    offset: 0,
    id: "Africa/Ouagadougou",
  },
  { label: "Africa/Sao_Tome(UTC+00:00)", offset: 0, id: "Africa/Sao_Tome" },
  {
    label: "Atlantic/Reykjavik(UTC+00:00)",
    offset: 0,
    id: "Atlantic/Reykjavik",
  },
  {
    label: "Atlantic/St_Helena(UTC+00:00)",
    offset: 0,
    id: "Atlantic/St_Helena",
  },
  {
    label: "Arctic/Longyearbyen(UTC+01:00)",
    offset: 120,
    id: "Arctic/Longyearbyen",
  },
  { label: "Europe/Amsterdam(UTC+01:00)", offset: 120, id: "Europe/Amsterdam" },
  { label: "Europe/Andorra(UTC+01:00)", offset: 120, id: "Europe/Andorra" },
  { label: "Europe/Berlin(UTC+01:00)", offset: 120, id: "Europe/Berlin" },
  { label: "Europe/Busingen(UTC+01:00)", offset: 120, id: "Europe/Busingen" },
  { label: "Europe/Gibraltar(UTC+01:00)", offset: 120, id: "Europe/Gibraltar" },
  {
    label: "Europe/Luxembourg(UTC+01:00)",
    offset: 120,
    id: "Europe/Luxembourg",
  },
  { label: "Europe/Malta(UTC+01:00)", offset: 120, id: "Europe/Malta" },
  { label: "Europe/Monaco(UTC+01:00)", offset: 120, id: "Europe/Monaco" },
  { label: "Europe/Oslo(UTC+01:00)", offset: 120, id: "Europe/Oslo" },
  { label: "Europe/Rome(UTC+01:00)", offset: 120, id: "Europe/Rome" },
  {
    label: "Europe/San_Marino(UTC+01:00)",
    offset: 120,
    id: "Europe/San_Marino",
  },
  { label: "Europe/Stockholm(UTC+01:00)", offset: 120, id: "Europe/Stockholm" },
  { label: "Europe/Vaduz(UTC+01:00)", offset: 120, id: "Europe/Vaduz" },
  { label: "Europe/Vatican(UTC+01:00)", offset: 120, id: "Europe/Vatican" },
  { label: "Europe/Vienna(UTC+01:00)", offset: 120, id: "Europe/Vienna" },
  { label: "Europe/Zurich(UTC+01:00)", offset: 120, id: "Europe/Zurich" },
  { label: "Europe/Belgrade(UTC+01:00)", offset: 120, id: "Europe/Belgrade" },
  {
    label: "Europe/Bratislava(UTC+01:00)",
    offset: 120,
    id: "Europe/Bratislava",
  },
  { label: "Europe/Budapest(UTC+01:00)", offset: 120, id: "Europe/Budapest" },
  { label: "Europe/Ljubljana(UTC+01:00)", offset: 120, id: "Europe/Ljubljana" },
  { label: "Europe/Podgorica(UTC+01:00)", offset: 120, id: "Europe/Podgorica" },
  { label: "Europe/Prague(UTC+01:00)", offset: 120, id: "Europe/Prague" },
  { label: "Europe/Tirane(UTC+01:00)", offset: 120, id: "Europe/Tirane" },
  { label: "Africa/Ceuta(UTC+01:00)", offset: 120, id: "Africa/Ceuta" },
  { label: "Europe/Brussels(UTC+01:00)", offset: 120, id: "Europe/Brussels" },
  {
    label: "Europe/Copenhagen(UTC+01:00)",
    offset: 120,
    id: "Europe/Copenhagen",
  },
  { label: "Europe/Madrid(UTC+01:00)", offset: 120, id: "Europe/Madrid" },
  { label: "Europe/Paris(UTC+01:00)", offset: 120, id: "Europe/Paris" },
  { label: "Europe/Sarajevo(UTC+01:00)", offset: 120, id: "Europe/Sarajevo" },
  { label: "Europe/Skopje(UTC+01:00)", offset: 120, id: "Europe/Skopje" },
  { label: "Europe/Warsaw(UTC+01:00)", offset: 120, id: "Europe/Warsaw" },
  { label: "Europe/Zagreb(UTC+01:00)", offset: 120, id: "Europe/Zagreb" },
  { label: "Africa/Algiers(UTC+01:00)", offset: 60, id: "Africa/Algiers" },
  { label: "Africa/Bangui(UTC+01:00)", offset: 60, id: "Africa/Bangui" },
  {
    label: "Africa/Brazzaville(UTC+01:00)",
    offset: 60,
    id: "Africa/Brazzaville",
  },
  { label: "Africa/Douala(UTC+01:00)", offset: 60, id: "Africa/Douala" },
  { label: "Africa/Kinshasa(UTC+01:00)", offset: 60, id: "Africa/Kinshasa" },
  { label: "Africa/Lagos(UTC+01:00)", offset: 60, id: "Africa/Lagos" },
  {
    label: "Africa/Libreville(UTC+01:00)",
    offset: 60,
    id: "Africa/Libreville",
  },
  { label: "Africa/Luanda(UTC+01:00)", offset: 60, id: "Africa/Luanda" },
  { label: "Africa/Malabo(UTC+01:00)", offset: 60, id: "Africa/Malabo" },
  { label: "Africa/Ndjamena(UTC+01:00)", offset: 60, id: "Africa/Ndjamena" },
  { label: "Africa/Niamey(UTC+01:00)", offset: 60, id: "Africa/Niamey" },
  {
    label: "Africa/Porto-Novo(UTC+01:00)",
    offset: 60,
    id: "Africa/Porto-Novo",
  },
  { label: "Africa/Tunis(UTC+01:00)", offset: 60, id: "Africa/Tunis" },
  { label: "Etc/GMT-1(UTC+01:00)", offset: 60, id: "Etc/GMT-1" },
  { label: "Africa/Windhoek(UTC+01:00)", offset: 60, id: "Africa/Windhoek" },
  { label: "Asia/Nicosia(UTC+02:00)", offset: 180, id: "Asia/Nicosia" },
  { label: "Europe/Athens(UTC+02:00)", offset: 180, id: "Europe/Athens" },
  { label: "Europe/Bucharest(UTC+02:00)", offset: 180, id: "Europe/Bucharest" },
  { label: "Europe/Chisinau(UTC+02:00)", offset: 180, id: "Europe/Chisinau" },
  { label: "Asia/Beirut(UTC+02:00)", offset: 180, id: "Asia/Beirut" },
  { label: "Africa/Cairo(UTC+02:00)", offset: 120, id: "Africa/Cairo" },
  { label: "Asia/Damascus(UTC+02:00)", offset: 180, id: "Asia/Damascus" },
  { label: "Europe/Helsinki(UTC+02:00)", offset: 180, id: "Europe/Helsinki" },
  { label: "Europe/Kiev(UTC+02:00)", offset: 180, id: "Europe/Kiev" },
  { label: "Europe/Mariehamn(UTC+02:00)", offset: 180, id: "Europe/Mariehamn" },
  { label: "Europe/Nicosia(UTC+02:00)", offset: 180, id: "Europe/Nicosia" },
  { label: "Europe/Riga(UTC+02:00)", offset: 180, id: "Europe/Riga" },
  { label: "Europe/Sofia(UTC+02:00)", offset: 180, id: "Europe/Sofia" },
  { label: "Europe/Tallinn(UTC+02:00)", offset: 180, id: "Europe/Tallinn" },
  { label: "Europe/Uzhgorod(UTC+02:00)", offset: 180, id: "Europe/Uzhgorod" },
  { label: "Africa/Blantyre(UTC+02:00)", offset: 120, id: "Africa/Blantyre" },
  { label: "Africa/Bujumbura(UTC+02:00)", offset: 120, id: "Africa/Bujumbura" },
  { label: "Africa/Gaborone(UTC+02:00)", offset: 120, id: "Africa/Gaborone" },
  { label: "Africa/Harare(UTC+02:00)", offset: 120, id: "Africa/Harare" },
  {
    label: "Africa/Johannesburg(UTC+02:00)",
    offset: 120,
    id: "Africa/Johannesburg",
  },
  { label: "Africa/Kigali(UTC+02:00)", offset: 120, id: "Africa/Kigali" },
  {
    label: "Africa/Lubumbashi(UTC+02:00)",
    offset: 120,
    id: "Africa/Lubumbashi",
  },
  { label: "Africa/Lusaka(UTC+02:00)", offset: 120, id: "Africa/Lusaka" },
  { label: "Africa/Maputo(UTC+02:00)", offset: 120, id: "Africa/Maputo" },
  { label: "Africa/Maseru(UTC+02:00)", offset: 120, id: "Africa/Maseru" },
  { label: "Africa/Mbabane(UTC+02:00)", offset: 120, id: "Africa/Mbabane" },
  { label: "Etc/GMT-2(UTC+02:00)", offset: 120, id: "Etc/GMT-2" },
  { label: "Europe/Vilnius(UTC+02:00)", offset: 180, id: "Europe/Vilnius" },
  {
    label: "Europe/Zaporozhye(UTC+02:00)",
    offset: 180,
    id: "Europe/Zaporozhye",
  },
  { label: "Europe/Istanbul(UTC+03:00)", offset: 180, id: "Europe/Istanbul" },
  { label: "Asia/Jerusalem(UTC+02:00)", offset: 180, id: "Asia/Jerusalem" },
  { label: "Africa/Tripoli(UTC+02:00)", offset: 120, id: "Africa/Tripoli" },
  { label: "Asia/Amman(UTC+03:00)", offset: 180, id: "Asia/Amman" },
  { label: "Asia/Baghdad(UTC+03:00)", offset: 180, id: "Asia/Baghdad" },
  {
    label: "Europe/Kaliningrad(UTC+02:00)",
    offset: 180,
    id: "Europe/Kaliningrad",
  },
  { label: "Asia/Aden(UTC+03:00)", offset: 180, id: "Asia/Aden" },
  { label: "Asia/Bahrain(UTC+03:00)", offset: 180, id: "Asia/Bahrain" },
  { label: "Asia/Kuwait(UTC+03:00)", offset: 180, id: "Asia/Kuwait" },
  { label: "Asia/Qatar(UTC+03:00)", offset: 180, id: "Asia/Qatar" },
  { label: "Asia/Riyadh(UTC+03:00)", offset: 180, id: "Asia/Riyadh" },
  {
    label: "Africa/Addis_Ababa(UTC+03:00)",
    offset: 180,
    id: "Africa/Addis_Ababa",
  },
  { label: "Africa/Asmera(UTC+03:00)", offset: 180, id: "Africa/Asmera" },
  {
    label: "Africa/Dar_es_Salaam(UTC+03:00)",
    offset: 180,
    id: "Africa/Dar_es_Salaam",
  },
  { label: "Africa/Djibouti(UTC+03:00)", offset: 180, id: "Africa/Djibouti" },
  { label: "Africa/Juba(UTC+03:00)", offset: 180, id: "Africa/Juba" },
  { label: "Africa/Kampala(UTC+03:00)", offset: 180, id: "Africa/Kampala" },
  { label: "Africa/Khartoum(UTC+03:00)", offset: 180, id: "Africa/Khartoum" },
  { label: "Africa/Mogadishu(UTC+03:00)", offset: 180, id: "Africa/Mogadishu" },
  { label: "Africa/Nairobi(UTC+03:00)", offset: 180, id: "Africa/Nairobi" },
  { label: "Antarctica/Syowa(UTC+03:00)", offset: 180, id: "Antarctica/Syowa" },
  { label: "Etc/GMT-3(UTC+03:00)", offset: 180, id: "Etc/GMT-3" },
  {
    label: "Indian/Antananarivo(UTC+03:00)",
    offset: 180,
    id: "Indian/Antananarivo",
  },
  { label: "Indian/Comoro(UTC+03:00)", offset: 180, id: "Indian/Comoro" },
  { label: "Indian/Mayotte(UTC+03:00)", offset: 180, id: "Indian/Mayotte" },
  { label: "Europe/Kirov(UTC+03:00)", offset: 180, id: "Europe/Kirov" },
  { label: "Europe/Moscow(UTC+03:00)", offset: 180, id: "Europe/Moscow" },
  {
    label: "Europe/Simferopol(UTC+03:00)",
    offset: 180,
    id: "Europe/Simferopol",
  },
  { label: "Europe/Volgograd(UTC+03:00)", offset: 180, id: "Europe/Volgograd" },
  { label: "Europe/Minsk(UTC+03:00)", offset: 180, id: "Europe/Minsk" },
  { label: "Europe/Astrakhan(UTC+04:00)", offset: 240, id: "Europe/Astrakhan" },
  { label: "Europe/Samara(UTC+04:00)", offset: 240, id: "Europe/Samara" },
  { label: "Europe/Ulyanovsk(UTC+04:00)", offset: 240, id: "Europe/Ulyanovsk" },
  { label: "Asia/Tehran(UTC+03:30)", offset: 270, id: "Asia/Tehran" },
  { label: "Asia/Dubai(UTC+04:00)", offset: 240, id: "Asia/Dubai" },
  { label: "Asia/Muscat(UTC+04:00)", offset: 240, id: "Asia/Muscat" },
  { label: "Etc/GMT-4(UTC+04:00)", offset: 240, id: "Etc/GMT-4" },
  { label: "Asia/Baku(UTC+04:00)", offset: 300, id: "Asia/Baku" },
  { label: "Indian/Mahe(UTC+04:00)", offset: 240, id: "Indian/Mahe" },
  { label: "Indian/Mauritius(UTC+04:00)", offset: 240, id: "Indian/Mauritius" },
  { label: "Indian/Reunion(UTC+04:00)", offset: 240, id: "Indian/Reunion" },
  { label: "Asia/Tbilisi(UTC+04:00)", offset: 240, id: "Asia/Tbilisi" },
  { label: "Asia/Yerevan(UTC+04:00)", offset: 240, id: "Asia/Yerevan" },
  { label: "Asia/Kabul(UTC+04:30)", offset: 270, id: "Asia/Kabul" },
  {
    label: "Antarctica/Mawson(UTC+05:00)",
    offset: 300,
    id: "Antarctica/Mawson",
  },
  { label: "Asia/Aqtau(UTC+05:00)", offset: 300, id: "Asia/Aqtau" },
  { label: "Asia/Aqtobe(UTC+05:00)", offset: 300, id: "Asia/Aqtobe" },
  { label: "Asia/Ashgabat(UTC+05:00)", offset: 300, id: "Asia/Ashgabat" },
  { label: "Asia/Dushanbe(UTC+05:00)", offset: 300, id: "Asia/Dushanbe" },
  { label: "Asia/Oral(UTC+05:00)", offset: 300, id: "Asia/Oral" },
  { label: "Asia/Samarkand(UTC+05:00)", offset: 300, id: "Asia/Samarkand" },
  { label: "Asia/Tashkent(UTC+05:00)", offset: 300, id: "Asia/Tashkent" },
  { label: "Etc/GMT-5(UTC+05:00)", offset: 300, id: "Etc/GMT-5" },
  { label: "Indian/Kerguelen(UTC+05:00)", offset: 300, id: "Indian/Kerguelen" },
  { label: "Indian/Maldives(UTC+05:00)", offset: 300, id: "Indian/Maldives" },
  {
    label: "Asia/Yekaterinburg(UTC+05:00)",
    offset: 300,
    id: "Asia/Yekaterinburg",
  },
  { label: "Asia/Karachi(UTC+05:00)", offset: 300, id: "Asia/Karachi" },
  { label: "Asia/Calcutta(UTC+05:30)", offset: 330, id: "Asia/Calcutta" },
  { label: "Asia/Colombo(UTC+05:30)", offset: 330, id: "Asia/Colombo" },
  { label: "Asia/Kathmandu(UTC+05:45)", offset: 345, id: "Asia/Kathmandu" },
  {
    label: "Antarctica/Vostok(UTC+06:00)",
    offset: 360,
    id: "Antarctica/Vostok",
  },
  { label: "Asia/Almaty(UTC+06:00)", offset: 360, id: "Asia/Almaty" },
  { label: "Asia/Bishkek(UTC+06:00)", offset: 360, id: "Asia/Bishkek" },
  { label: "Asia/Qyzylorda(UTC+06:00)", offset: 360, id: "Asia/Qyzylorda" },
  { label: "Asia/Urumqi(UTC+06:00)", offset: 360, id: "Asia/Urumqi" },
  { label: "Etc/GMT-6(UTC+06:00)", offset: 360, id: "Etc/GMT-6" },
  { label: "Indian/Chagos(UTC+06:00)", offset: 360, id: "Indian/Chagos" },
  { label: "Asia/Dhaka(UTC+06:00)", offset: 360, id: "Asia/Dhaka" },
  { label: "Asia/Thimphu(UTC+06:00)", offset: 360, id: "Asia/Thimphu" },
  { label: "Asia/Rangoon(UTC+06:30)", offset: 390, id: "Asia/Rangoon" },
  { label: "Indian/Cocos(UTC+06:30)", offset: 390, id: "Indian/Cocos" },
  { label: "Antarctica/Davis(UTC+07:00)", offset: 420, id: "Antarctica/Davis" },
  { label: "Asia/Bangkok(UTC+07:00)", offset: 420, id: "Asia/Bangkok" },
  { label: "Asia/Hovd(UTC+07:00)", offset: 420, id: "Asia/Hovd" },
  { label: "Asia/Jakarta(UTC+07:00)", offset: 420, id: "Asia/Jakarta" },
  { label: "Asia/Phnom_Penh(UTC+07:00)", offset: 420, id: "Asia/Phnom_Penh" },
  { label: "Asia/Pontianak(UTC+07:00)", offset: 420, id: "Asia/Pontianak" },
  { label: "Asia/Saigon(UTC+07:00)", offset: 420, id: "Asia/Saigon" },
  { label: "Asia/Vientiane(UTC+07:00)", offset: 420, id: "Asia/Vientiane" },
  { label: "Etc/GMT-7(UTC+07:00)", offset: 420, id: "Etc/GMT-7" },
  { label: "Indian/Christmas(UTC+07:00)", offset: 420, id: "Indian/Christmas" },
  {
    label: "Asia/Novokuznetsk(UTC+07:00)",
    offset: 420,
    id: "Asia/Novokuznetsk",
  },
  { label: "Asia/Novosibirsk(UTC+07:00)", offset: 420, id: "Asia/Novosibirsk" },
  { label: "Asia/Omsk(UTC+07:00)", offset: 420, id: "Asia/Omsk" },
  { label: "Asia/Hong_Kong(UTC+08:00)", offset: 480, id: "Asia/Hong_Kong" },
  { label: "Asia/Macau(UTC+08:00)", offset: 480, id: "Asia/Macau" },
  { label: "Asia/Shanghai(UTC+08:00)", offset: 480, id: "Asia/Shanghai" },
  { label: "Asia/Krasnoyarsk(UTC+08:00)", offset: 480, id: "Asia/Krasnoyarsk" },
  { label: "Asia/Brunei(UTC+08:00)", offset: 480, id: "Asia/Brunei" },
  {
    label: "Asia/Kuala_Lumpur(UTC+08:00)",
    offset: 480,
    id: "Asia/Kuala_Lumpur",
  },
  { label: "Asia/Kuching(UTC+08:00)", offset: 480, id: "Asia/Kuching" },
  { label: "Asia/Makassar(UTC+08:00)", offset: 480, id: "Asia/Makassar" },
  { label: "Asia/Manila(UTC+08:00)", offset: 480, id: "Asia/Manila" },
  { label: "Asia/Singapore(UTC+08:00)", offset: 480, id: "Asia/Singapore" },
  { label: "Etc/GMT-8(UTC+08:00)", offset: 480, id: "Etc/GMT-8" },
  { label: "Antarctica/Casey(UTC+08:00)", offset: 480, id: "Antarctica/Casey" },
  { label: "Australia/Perth(UTC+08:00)", offset: 480, id: "Australia/Perth" },
  { label: "Asia/Taipei(UTC+08:00)", offset: 480, id: "Asia/Taipei" },
  { label: "Asia/Choibalsan(UTC+08:00)", offset: 480, id: "Asia/Choibalsan" },
  { label: "Asia/Ulaanbaatar(UTC+08:00)", offset: 480, id: "Asia/Ulaanbaatar" },
  { label: "Asia/Irkutsk(UTC+08:00)", offset: 480, id: "Asia/Irkutsk" },
  { label: "Asia/Dili(UTC+09:00)", offset: 540, id: "Asia/Dili" },
  { label: "Asia/Jayapura(UTC+09:00)", offset: 540, id: "Asia/Jayapura" },
  { label: "Asia/Tokyo(UTC+09:00)", offset: 540, id: "Asia/Tokyo" },
  { label: "Etc/GMT-9(UTC+09:00)", offset: 540, id: "Etc/GMT-9" },
  { label: "Pacific/Palau(UTC+09:00)", offset: 540, id: "Pacific/Palau" },
  { label: "Asia/Pyongyang(UTC+09:00)", offset: 540, id: "Asia/Pyongyang" },
  { label: "Asia/Seoul(UTC+09:00)", offset: 540, id: "Asia/Seoul" },
  {
    label: "Australia/Adelaide(UTC+09:30)",
    offset: 570,
    id: "Australia/Adelaide",
  },
  {
    label: "Australia/Broken_Hill(UTC+09:30)",
    offset: 570,
    id: "Australia/Broken_Hill",
  },
  { label: "Australia/Darwin(UTC+09:30)", offset: 570, id: "Australia/Darwin" },
  {
    label: "Australia/Brisbane(UTC+10:00)",
    offset: 600,
    id: "Australia/Brisbane",
  },
  {
    label: "Australia/Lindeman(UTC+10:00)",
    offset: 600,
    id: "Australia/Lindeman",
  },
  {
    label: "Australia/Melbourne(UTC+10:00)",
    offset: 600,
    id: "Australia/Melbourne",
  },
  { label: "Australia/Sydney(UTC+10:00)", offset: 600, id: "Australia/Sydney" },
  {
    label: "Antarctica/DumontDUrville(UTC+10:00)",
    offset: 600,
    id: "Antarctica/DumontDUrville",
  },
  { label: "Etc/GMT-10(UTC+10:00)", offset: 600, id: "Etc/GMT-10" },
  { label: "Pacific/Guam(UTC+10:00)", offset: 600, id: "Pacific/Guam" },
  {
    label: "Pacific/Port_Moresby(UTC+10:00)",
    offset: 600,
    id: "Pacific/Port_Moresby",
  },
  { label: "Pacific/Saipan(UTC+10:00)", offset: 600, id: "Pacific/Saipan" },
  { label: "Pacific/Truk(UTC+10:00)", offset: 600, id: "Pacific/Truk" },
  { label: "Australia/Currie(UTC+10:00)", offset: 600, id: "Australia/Currie" },
  { label: "Australia/Hobart(UTC+10:00)", offset: 600, id: "Australia/Hobart" },
  { label: "Asia/Chita(UTC+09:00)", offset: 540, id: "Asia/Chita" },
  { label: "Asia/Khandyga(UTC+09:00)", offset: 540, id: "Asia/Khandyga" },
  { label: "Asia/Yakutsk(UTC+09:00)", offset: 540, id: "Asia/Yakutsk" },
  {
    label: "Antarctica/Macquarie(UTC+11:00)",
    offset: 660,
    id: "Antarctica/Macquarie",
  },
  { label: "Etc/GMT-11(UTC+11:00)", offset: 660, id: "Etc/GMT-11" },
  { label: "Pacific/Efate(UTC+11:00)", offset: 660, id: "Pacific/Efate" },
  {
    label: "Pacific/Guadalcanal(UTC+11:00)",
    offset: 660,
    id: "Pacific/Guadalcanal",
  },
  { label: "Pacific/Kosrae(UTC+11:00)", offset: 660, id: "Pacific/Kosrae" },
  { label: "Pacific/Noumea(UTC+11:00)", offset: 660, id: "Pacific/Noumea" },
  { label: "Pacific/Ponape(UTC+11:00)", offset: 660, id: "Pacific/Ponape" },
  { label: "Asia/Sakhalin(UTC+11:00)", offset: 660, id: "Asia/Sakhalin" },
  { label: "Asia/Ust-Nera(UTC+11:00)", offset: 660, id: "Asia/Ust-Nera" },
  { label: "Asia/Vladivostok(UTC+11:00)", offset: 660, id: "Asia/Vladivostok" },
  {
    label: "Antarctica/McMurdo(UTC+12:00)",
    offset: 720,
    id: "Antarctica/McMurdo",
  },
  { label: "Pacific/Auckland(UTC+12:00)", offset: 720, id: "Pacific/Auckland" },
  { label: "Etc/GMT-12(UTC+12:00)", offset: 720, id: "Etc/GMT-12" },
  { label: "Pacific/Funafuti(UTC+12:00)", offset: 720, id: "Pacific/Funafuti" },
  {
    label: "Pacific/Kwajalein(UTC+12:00)",
    offset: 720,
    id: "Pacific/Kwajalein",
  },
  { label: "Pacific/Majuro(UTC+12:00)", offset: 720, id: "Pacific/Majuro" },
  { label: "Pacific/Nauru(UTC+12:00)", offset: 720, id: "Pacific/Nauru" },
  { label: "Pacific/Tarawa(UTC+12:00)", offset: 720, id: "Pacific/Tarawa" },
  { label: "Pacific/Wake(UTC+12:00)", offset: 720, id: "Pacific/Wake" },
  { label: "Pacific/Wallis(UTC+12:00)", offset: 720, id: "Pacific/Wallis" },
  { label: "Pacific/Fiji(UTC+12:00)", offset: 720, id: "Pacific/Fiji" },
  { label: "Asia/Anadyr(UTC+12:00)", offset: 720, id: "Asia/Anadyr" },
  { label: "Asia/Kamchatka(UTC+12:00)", offset: 720, id: "Asia/Kamchatka" },
  { label: "Asia/Magadan(UTC+12:00)", offset: 720, id: "Asia/Magadan" },
  {
    label: "Asia/Srednekolymsk(UTC+12:00)",
    offset: 720,
    id: "Asia/Srednekolymsk",
  },
  { label: "Etc/GMT-13(UTC+13:00)", offset: 780, id: "Etc/GMT-13" },
  {
    label: "Pacific/Enderbury(UTC+13:00)",
    offset: 780,
    id: "Pacific/Enderbury",
  },
  { label: "Pacific/Fakaofo(UTC+13:00)", offset: 780, id: "Pacific/Fakaofo" },
  {
    label: "Pacific/Tongatapu(UTC+13:00)",
    offset: 780,
    id: "Pacific/Tongatapu",
  },
  { label: "Pacific/Apia(UTC+13:00)", offset: 780, id: "Pacific/Apia" },
];

export const DATE_FORMAT_LIST = [
  { id: "MM/DD/YYYY", label: "MM/DD/YYYY (e.g., 12/31/2023)" },
  { id: "MM-DD-YYYY", label: "MM-DD-YYYY (e.g., 12-31-2023)" },
  { id: "MM.DD.YYYY", label: "MM.DD.YYYY (e.g., 12.31.2023)" },
  { id: "DD/MM/YYYY", label: "DD/MM/YYYY (e.g., 31/12/2023)" },
  { id: "DD-MM-YYYY", label: "DD-MM-YYYY (e.g., 31-12-2023)" },
  { id: "DD.MM.YYYY", label: "DD.MM.YYYY (e.g., 31.12.2023)" },
  { id: "YYYY.MM.DD", label: "YYYY.MM.DD (e.g., 2023.12.31)" },
  { id: "YYYY-MM-DD", label: "YYYY-MM-DD (e.g., 2023-12-31)" },
  { id: "YYYY/MM/DD", label: "YYYY/MM/DD (e.g., 2023/12/31)" },
  { id: "YYYY-Mon-DD", label: "YYYY-Mon-DD (e.g., 2023-Dec-31)" },
  { id: "YYYY-DD-Mon", label: "YYYY-DD-Mon (e.g., 2023-31-Dec)" },
  { id: "DD-Mon-YYYY", label: "DD-Mon-YYYY (e.g., 31-Dec-2023)" },
  { id: "DD Mon, YYYY", label: "DD Mon, YYYY (e.g., 31 Dec,2023)" },
  { id: "DD Month, YYYY", label: "DD Month, YYYY (e.g., 31 December, 2023)" },
  // {id:"YY/MM/DD",label:"YY/MM/DD (e.g., 23/12/31)"},
  // {id:"MM/DD/YY",label:"MM/DD/YY (e.g., 12/31/23)"},
  // {id:"DD/MM/YY",label:"DD/MM/YY (e.g., 31/12/23)"},
  // {id:"Month DD, YYYY",label:"Month DD, YYYY (e.g., December 31, 2023)"},
  // {id:"Month DDth, YYYY",label:"Month DDth, YYYY (e.g., December 31st, 2023)"},
  // {id:"Day, Month DD, YYYY",label:"Day, Month DD, YYYY (e.g., Monday, December 31, 2023)"},
  // {id:"MM_DD_YYYY",label:"MM_DD_YYYY (e.g., 12_31_2023)"},
];

export const CURRENCY_LIST = [
  { id: "$", label: "$" },
  { id: "€", label: "€" },
  { id: "£", label: "£" },
  { id: "¥", label: "¥" },
  { id: "₣", label: "₣" },
  { id: "₹", label: "₹" },
  // {id:"د.ك",label:"د.ك"},{id:"د.إ",label:"د.إ"},{id:"﷼",label:"﷼"},{id:"₻",label:"₻"},{id:"₽",label:"₽"},{id:"₾",label:"₾"},
  // {id:"₺",label:"₺"},{id:"₼",label:"₼"},{id:"₸",label:"₸"},{id:"₴",label:"₴"},{id:"₷",label:"₷"},{id:"฿",label:"฿"},
  // {id:"원",label:"원"},{id:"₫",label:"₫"},{id:"₮",label:"₮"},{id:"₯",label:"₯"},{id:"₱",label:"₱"},{id:"₳",label:"₳"},
  // {id:"₵",label:"₵"},{id:"₲",label:"₲"},{id:"₪",label:"₪"},{id:"₰",label:"₰"}
];

export const LANGUAGES = [
  { id: "english", label: "English" },
  { id: "hindi", label: "Hindi" },
  { id: "spanish", label: "Spanish" },
  { id: "french", label: "French" },
];

//Meetings

export const MEETING_CREATE_DAYS_LIST = [
  { id: "everyday", label: "Everyday" },
  { id: "weekdays", label: "Monday-Friday" },
  { id: "weekend", label: "Saturday-Sunday" },
  { id: "sunday", label: "Sunday" },
  { id: "monday", label: "Monday" },
  { id: "tuesday", label: "Tuesday" },
  { id: "wednesday", label: "Wednesday" },
  { id: "thursday", label: "Thursday" },
  { id: "friday", label: "Friday" },
  { id: "saturday", label: "Saturday" },
];

export const DURATION_LIST = [
  { id: "10min", label: "10min" },
  { id: "15min", label: "15min" },
  { id: "30min", label: "30min" },
  { id: "45min", label: "45min" },
  { id: "60min", label: "60min" },
];

export const ADDCONTACT = [
  { id: "Phone number", label: "Phone number" },
  { id: "Fax number", label: "Fax number" },
  { id: "Mobile number", label: "Mobile number" },
  { id: "Street address", label: "Street address" },
];

export const LIST = [
  {
    day: "sunday",
    capitalDay: "Sunday",
    id: "0",
    from: "9 AM",
    to: "9 PM",
  },
  {
    day: "monday",
    capitalDay: "Monday",
    id: "1",
    from: "9 AM",
    to: "9 PM",
  },
  {
    day: "tuesday",
    capitalDay: "Tuesday",
    id: "2",
    from: "9 AM",
    to: "9 PM",
  },
  {
    day: "wednesday",
    capitalDay: "Wednesday",
    id: "3",
    from: "9 AM",
    to: "9 PM",
  },
  {
    day: "thursday",
    capitalDay: "Thursday",
    id: "4",
    from: "9 AM",
    to: "9 PM",
  },
  {
    day: "friday",
    capitalDay: "Friday",
    id: "5",
    from: "9 AM",
    to: "9 PM",
  },
  {
    day: "saturday",
    capitalDay: "Saturday",
    id: "6",
    from: "9 AM",
    to: "9 PM",
  },
];

//Account Setting
export const DEFAULT_VALUES_BRANDING = [
  { id: "Secondary", label: "Secondary" },
  { id: "Primary", label: "Primary" },
];

// Heads for Campaign stats table
export const STATS_HEADERS = [
  {
    id: "name",
    ui_id: "user_detail",
    label: "Name",
    width: "18.2vw",
  },
  {
    id: "email",
    ui_id: "stats_data",
    label: "Email",
    width: "18.2vw",
  },
  {
    id: "date",
    ui_id: "stats_data",
    label: "Date",
    width: "18.2vw",
  },
  {
    id: "action",
    ui_id: "action",
    label: "Action",
    width: "10.063vw",
  }
];

//CSV table
export const CSV_HEADERS = [
  {
    id: "file_name",
    ui_id: "text",
    sort: true,
    label: "File Name",
    width: "11.771vw",
  },

  {
    id: "owner",
    label: "Uploaded by",
    ui_id: "text",
    sort: false,
    width: "11.771vw",
  },
  {
    id: "job_filter",
    label: "Job Title",
    ui_id: "text",
    sort: false,
    width: "18vw",
  },
  {
    id: "data_for_type",
    label: "Type",
    ui_id: "text",
    sort: false,
    width: "11.771vw",
  },
  {
    id: "total_records",
    ui_id: "text",
    sort: false,

    label: "Total Records",
    width: "11.771vw",
  },
  {
    id: "company_count",
    ui_id: "text",
    sort: false,

    label: "Company Count",
    width: "11.771vw",
  },

  {
    id: "total_matched",
    ui_id: "number_text",
    sort: false,
    label: "Total Matched",
    width: "11.771vw",
  },

  {
    id: "created_at",
    ui_id: "time_stamp",
    sort: true,
    label: "Uploaded At",
    width: "11.771vw",
  },

  {
    id: "status",
    ui_id: "status",
    sort: true,
    label: "Status",
    width: "11.771vw",
  },
  {
    id: "export",
    ui_id: "button",
    sort: false,
    label: "Action",
    width: "11.771vw",
  },
];

//CSV table

export const IMPORT_CONTACT_HEADERS = [
  {
    id: "file_name",
    ui_id: "marketing_list",
    sort: true,
    label: "File Name",
    width: "11.771vw",
  },

  {
    id: "uploaded_by",
    label: "Uploaded by",
    ui_id: "text",
    sort: false,
    width: "11.771vw",
  },
  // {
  //   id: "job_filter",
  //   label: "Job Title",
  //   ui_id: "text",
  //   sort: false,
  //   width: "11.771vw",
  // },
  {
    id: "data_type",
    label: "Type",
    ui_id: "text",
    sort: false,
    width: "11.771vw",
  },
  {
    id: "total_records",
    ui_id: "text",
    sort: false,

    label: "Total Records",
    width: "11.771vw",
  },

  {
    id: "company_insert_count",
    ui_id: "number_text",
    sort: false,
    label: "Company Insert Count",
    width: "11.771vw",
  },

  {
    id: "company_update_count",
    ui_id: "number_text",
    sort: false,
    label: "Company Update Count",
    width: "11.771vw",
  },

  {
    id: "contact_insert_count",
    ui_id: "number_text",
    sort: false,
    label: "Contact Insert Count",
    width: "11.771vw",
  },

  {
    id: "contact_update_count",
    ui_id: "number_text",
    sort: false,
    label: "Contact Update Count",
    width: "11.771vw",
  },

  {
    id: "created_at",
    ui_id: "time_stamp",
    sort: true,
    label: "Uploaded At",
    width: "11.771vw",
  },

  {
    id: "status",
    ui_id: "status",
    sort: true,
    label: "Status",
    width: "11.771vw",
  },
  {
    id: "export",
    ui_id: "button",
    sort: false,
    label: "Action",
    width: "11.771vw",
  },
];

//Meeting Header
export const MEETING_HEADERS = [
  {
    id: "event_title",
    sort: true,
    label: "Meeting Name",
    ui_id: "marketing_list",
    width: "21.185vw",
    isClickAllowed: true
  },
  {
    id: "created_by",
    sort: true,
    label: "Created By",
    ui_id: "text_url",
    width: "12vw",
  },
  {
    id: "organizer_name",
    sort: true,
    label: "Organizer",
    ui_id: "text",
    width: "12vw",
  },
  {
    id: "connected_inbox_mail",
    sort: false,
    label: "Connected Inbox",
    ui_id: "email",
    width: "16.185vw",
  },
  {
    id: "status",
    sort: true,
    label: "Status",
    ui_id: "status",
    width: "8vw",
  },
  {
    id: "meeting_booked_count",
    sort: false,
    label: "Booked Meeting Stats",
    ui_id: "stats_data",
    disable_key: "stats_operation",
    width: "11.5vw",
  },
  {
    id: "duration_options",
    sort: false,
    label: "Duration",
    ui_id: "duration_text",
    width: "8vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "6vw",
  },
];

//Booked meeting Headers
export const BOOKED_MEETING_HEADERS = [
  {
    id: "event_summary",
    sort: false,
    label: "Event Title",
    ui_id: "marketing_list",
    width: "18.854vw",
  },
  {
    id: "event_organizer_name",
    sort: false,
    label: "Organizer",
    ui_id: "text",
    width: "13.615vw",
  },
  {
    id: "organizer_email",
    sort: false,
    label: "Connected Inbox",
    ui_id: "email",
    width: "13.615vw",
  },
  {
    id: "attendee_name",
    sort: true,
    label: "Attendee",
    ui_id: "cursor_text",
    width: "13.615vw",
  },

  {
    id: "event_startDateTime",
    sort: true,
    label: "Date",
    ui_id: "time_stamp",
    width: "9.5vw",
  },
  {
    id: "event_startDateTime",
    label: "Time",
    ui_id: "time_stamp",
    width: "9.5vw",
  },
  {
    id: "booked_duration",
    label: "Duration",
    ui_id: "duration_text",
    width: "9.5vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "7.240vw",
  },
];
export const GROUPED_BOOKED_MEETING_HEADERS = [
  {
    id: "event_summary",
    sort: false,
    label: "Event Title",
    ui_id: "marketing_list",
    width: "18.854vw",
  },
  {
    id: "event_organizer_name",
    sort: false,
    label: "Organizer",
    ui_id: "text",
    width: "13.615vw",
  },
  {
    id: "membersLength",
    sort: false,
    label: "No. of Members",
    ui_id: "text",
    width: "13.615vw",
    tooltip: "meeting_tool",
  },
  {
    id: "organizer_email",
    sort: false,
    label: "Connected Inbox",
    ui_id: "email",
    width: "13.615vw",
  },
  {
    id: "attendee_name",
    sort: true,
    label: "Attendee",
    ui_id: "cursor_text",
    width: "13.615vw",
  },

  {
    id: "event_startDateTime",
    sort: true,
    label: "Date",
    ui_id: "time_stamp",
    width: "9.5vw",
  },
  {
    id: "event_startDateTime",
    label: "Time",
    ui_id: "time_stamp",
    width: "9.5vw",
  },
  {
    id: "booked_duration",
    label: "Duration",
    ui_id: "duration_text",
    width: "9.5vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "7.240vw",
  },
];
//COLUMN EXPORT IN CSV

export const ALL_COLUMNS = [
  {
    column: "Linkedin Profile ",
    columnLable: "Linkedin Profile",
    label: "contact_linkedin_url",
    property_type: "people",
    property_value: "",
    value: "",
    selected: true,
  },

  {
    column: "Email",
    columnLable: "Email",
    label: "email",
    property_value: "",
    property_type: "people",
    value: "",
    selected: true,
  },
];

export const ALL_COLUMNS_COMPANY_CSV = [
  {
    column: "Company Linkedin Url ",
    columnLable: "Company Linkedin Url",
    label: "company_linkedin_url",
    property_type: "company",
    property_value: "",
    value: "",
    selected: true,
  },

  {
    column: "Company Website",
    columnLable: "Company Website",
    label: "company_website",
    property_value: "",
    property_type: "company",
    value: "",
    selected: true,
  },
];
//TASK_HEADERS TABLE

export const TASK_HEADERS = [
  {
    id: "title",
    label: "Title",
    sort: true,
    ui_id: "task_list",
    width: "19vw",
    role: "simple-user",
  },
  {
    id: "associated_contact",
    label: "Associated Contact",
    sort: false,
    ui_id: "user_detail",
    width: "15.469vw",
    role: "simple-user",
  },
  {
    id: "company_name",
    label: "Associated Company",
    sort: false,
    ui_id: "company_detail",
    width: "15.469vw",
    role: "simple-user",
  },
  {
    id: "assignee",
    label: "Assignee",
    sort: false,
    ui_id: "text",
    width: "15.469vw",
    role: "admin-user",
  },
  {
    id: "created_at",
    label: "Created At",
    sort: true,
    ui_id: "time_stamp",
    width: "15.469vw",
    role: "simple-user",
  },
  {
    id: "step_type",
    label: "Task Type",
    sort: false,
    ui_id: "task_type",
    width: "8.333vw",
    role: "simple-user",
  },
  {
    id: "show_time_stamp",
    label: "Due Date",
    sort: true,
    ui_id: "due_time_stamp",
    width: "14.323vw",
    role: "simple-user",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "6.250vw",
    role: "simple-user",
  },
];

export const DASHBOARD_LIST_TABLE_HEADERS = [
  {
    id: "dashboard_name",
    label: "Name",
    ui_id: "marketing_list",
    sort: true,
    width: "16.948vw",
  },
  {
    id: "owner_name",
    label: "Owner",
    ui_id: "text",
    sort: false,
    width: "16.948vw",
  },
  {
    id: "dashboard_type",
    label: "Permissions",
    ui_id: "text",
    sort: true,
    width: "16.948vw",
    isCapitalize: true,
  },
  {
    id: "created_at",
    label: "Created At",
    ui_id: "time_stamp",
    sort: true,
    width: "16.948vw",
  },
  {
    id: "updated_at",
    label: "Updated At",
    ui_id: "time_stamp",
    sort: true,
    width: "16.948vw",
  },
  {
    id: "action",
    label: "Actions",
    ui_id: "action",
    sort: false,
    width: "9.5vw",
  },
];

export const DASHBOARD_ACTIVITY_LOGS_TABLE_HEADERS = [
  {
    id: "message",
    label: "Action Details",
    isChecked: true,
    // ui_id: "bold_text",
    width: "36.510vw",
  },
  {
    id: "owner_name",
    label: "Performed By",
    isChecked: true,
    // ui_id: "text",
    width: "12.318vw",
  },
  {
    id: "updated_at",
    label: "Date ",
    isChecked: true,
    // ui_id: "time_stamp",
    width: "12.318vw",
  },
];

export const objectTypeDropdownValues = [
  { id: "contact", label: "Contact" },
  { id: "company", label: "Company" },
  { id: "deals", label: "Deals" },
];

export const templateHeadData = [
  {
    id: "name",
    sort: true,
    label: "Templates",
    ui_id: "marketing_list",
    width: "17.604vw",
  },

  {
    id: "owner_name",
    sort: false,
    label: "Owner",
    ui_id: "text",
    width: "12vw",
  },
  {
    id: "subject",
    sort: true,
    label: "Subject",
    ui_id: "text",
    width: "17.604vw",
  },
  {
    id: "permission",
    sort: true,
    label: "Permission",
    ui_id: "text",
    width: "10vw",
    isCapitalize: true,
  },
  {
    id: "created_at",
    sort: true,
    label: "Created At",
    ui_id: "time_stamp",
    width: "14.604vw",
  },
  {
    id: "updated_at",
    sort: true,
    label: "Updated At",
    ui_id: "time_stamp",
    width: "14.604vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "9vw",
  },
];

export const snipHeadData = [
  {
    id: "name",
    sort: true,
    label: "Name",
    ui_id: "marketing_list",
    width: "21vw",
  },
  {
    id: "created_by",
    sort: true,
    label: "Created By",
    ui_id: "time_stamp",
    width: "21vw",
  },
  {
    id: "shortcut",
    sort: false,
    label: "Shortcut",
    ui_id: "text",
    width: "21vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created At",
    ui_id: "time_stamp",
    width: "19vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "12vw",
  },
];
export const contactListHeadData = [
  {
    id: "list_name",
    sort: true,
    label: "Marketing Lists",
    ui_id: "marketing_list",
    copy: false,
    width: "17.146vw",
  },
  {
    id: "owner_name",
    sort: false,
    label: "Owner",
    ui_id: "text",
    copy: false,
    width: "17.146vw",
  },
  {
    id: "count",
    sort: false,
    label: "No of Contacts",
    ui_id: "stats_data",
    copy: false,
    width: "11.146vw",
  },
  {
    id: "permission",
    sort: true,
    label: "Permission",
    ui_id: "text",
    copy: false,
    width: "17.146vw",
    isCapitalize: true,
  },
  {
    id: "created_at",
    sort: true,
    label: "Created At",
    ui_id: "time_stamp",
    copy: false,
    width: "17.146vw",
  },
  {
    id: "updated_at",
    sort: true,
    label: "Updated At",
    ui_id: "time_stamp",
    copy: false,
    width: "17.146vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "6.25vw",
  },
];
export const sequenceHeadData = [
  {
    id: "name",
    sort: true,
    copy: false,
    label: "Sequence Name",
    ui_id: "marketing_list",
    width: "13.542vw",
  },
  { id: "owner", sort: false, label: "Owner", ui_id: "text", width: "7.604vw" },
  {
    id: "permission",
    sort: false,
    label: "Permission",
    ui_id: "text",
    width: "7.604vw",
    isCapitalize: true,
  },

  {
    id: "total_steps",
    sort: true,
    label: "Steps",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "sent",
    sort: false,
    label: "Sent",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "deliver",
    sort: false,
    label: "Delivered",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "lead",
    sort: false,
    label: "Leads",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "bounce",
    sort: false,
    label: "Bounces",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "open",
    sort: false,
    label: "Opens",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "meeting_book",
    sort: false,
    label: "Booked Meetings",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "click",
    sort: false,
    label: "Clicks",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "reply",
    sort: false,
    label: "Replies",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "unsubscribe",
    sort: false,
    label: "Unsubscribes",
    ui_id: "stats_data",
    width: "7.604vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created At",
    ui_id: "time_stamp",
    width: "9.427vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "3.958vw",
  },
];

export const campaignHeadData = [
  {
    id: "name",
    sort: true,
    copy: false,
    label: "Campaign Name",
    ui_id: "marketing_list",
    width: "13.542vw",
  },
  {
    id: "owner",
    sort: false,
    label: "Owner",
    ui_id: "text",
    width: "13.542vw",
  },
  {
    id: "from_email",
    sort: false,
    label: "Connected Inbox",
    ui_id: "email",
    width: "13.542vw",
  },
  {
    id: "total_enrolled",
    sort: false,
    label: "Total Enrolled",
    ui_id: "text",
    copy: false,
    width: "7.135vw",
  },
  {
    id: "total_steps",
    sort: true,
    label: "Steps",
    ui_id: "text",
    copy: false,
    width: "5.469vw",
  },
  {
    id: "sent",
    sort: false,
    label: "Sent",
    ui_id: "stats_data",
    is_pointer: true,
    width: "5.469vw",
  },

  {
    id: "deliver",
    sort: false,
    label: "Delivered",
    ui_id: "stats_data",
    is_pointer: true,
    width: "5.469vw",
  },
  {
    id: "lead",
    sort: false,
    label: "Leads",
    ui_id: "stats_data",
    is_pointer: true,
    width: "5.469vw",
  },
  {
    id: "bounce",
    sort: false,
    label: "Bounces",
    ui_id: "stats_data",
    is_pointer: true,
    width: "5.469vw",
  },
  {
    id: "open",
    sort: false,
    label: "Opens",
    ui_id: "stats_data",
    is_pointer: true,
    width: "5.469vw",
  },
  {
    id: "meeting_book",
    sort: false,
    label: "Booked Meetings",
    ui_id: "stats_data",
    is_pointer: true,
    width: "5.469vw",
  },
  {
    id: "click",
    sort: false,
    label: "Clicks",
    ui_id: "stats_data",
    is_pointer: true,
    width: "5.469vw",
  },
  {
    id: "reply",
    sort: false,
    label: "Replies",
    ui_id: "stats_data",
    is_pointer: true,
    width: "5.469vw",
  },
  {
    id: "unsubscribe",
    sort: false,
    label: "Unsubscribes",
    ui_id: "stats_data",
    is_pointer: true,
    width: "7.135vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created At",
    ui_id: "time_stamp",
    width: "9.531vw",
  },
  {
    id: "state",
    sort: true,
    label: "Status",
    ui_id: "status",
    width: "7.344vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "4.063vw",
  },
];

export const propertyListTableHeads = [
  {
    id: "property_name",
    sort: true,
    label: "Properties Name",
    ui_id: "bold_text",
    width: "18.555vw",
  },
  {
    id: "field_type",
    sort: true,
    label: "Field Types",
    ui_id: "stats_data",
    width: "16.263vw",
  },
  {
    id: "object_type",
    sort: true,
    label: "Property for",
    ui_id: "stats_data",
    width: "16.263vw",
  },
  {
    id: "grp_name",
    sort: true,
    label: "Group",
    ui_id: "stats_data",
    width: "16.263vw",
  },
  {
    id: "created_by",
    sort: false,
    label: "Created by",
    ui_id: "stats_data",
    width: "16.263vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "9.844vw",
  },
];

export const groupListTableHeads = [
  {
    id: "grp_name",
    sort: true,
    label: "Group Name",
    ui_id: "bold_text",
    width: "31.419vw",
  },
  {
    id: "count",
    sort: true,
    label: "Properties",
    ui_id: "text",
    width: "27vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "13vw",
  },
];

export const REPORT_LIST = [
  {
    id: "Campaign Report",
    key: "Campaign Report",
    label: "Campaign Report",
  },
  {
    id: "Sequence Report",
    key: "Sequence Report",
    label: "Sequence Report",
  },
  {
    id: "Unsubscribe Report",
    key: "Unsubscribe Report",
    label: "Unsubscribe Report",
  },
  { id: "Leads Report", key: "Leads Report", label: "Leads Report" },
  {
    id: "Activity Report",
    key: "Activity Report",
    label: "Activity Report",
  },
];

// domain,Email,CSV

export const STATUS_TYPES = [
  { id: "", label: "All" },
  { id: "completed", label: "Completed" },
  { id: "processing", label: "Processing" },
  { id: "Interrupted", label: "Interrupted" },
];

export const SOURCES_TYPES = [
  { id: "", label: "All" },
  { id: "blacklist_emails", label: "Blacklist Email" },
  { id: "blacklist_domains", label: "Blacklist Domain" },
];
export const EMAIL_TYPES = [
  { id: "", label: "All" },
  { id: "Unsubscribed", label: "Unsubscribed" },
  { id: "Bounced", label: "Bounced" },
];

export const STATIC_EXPORT_ORDRE_PAIR = {
  first_name: "first_name",
  last_name: "last_name",
  job_title: "job_title",
  email: "email",
  mobile_number: "mobile_phone_number",
  direct_phone_number: "contact_phone_number",
  company_phone_number: "company_phone_number",
  person_city: "contact_city",
  person_state: "contact_state",
  person_country: "contact_country",
  contact_linkedin_url: "contact_linkedin_profile",
  company_linkedin_url: "company_linkedin_page",
  company_facebook_url: "company_facebook_page",
  company_twitter_url: "company_twitter_page",
  company_name: "company_name",
  company_website: "company_website",
  industry: "industry",
  number_of_employees: "number_of_employees",
  annual_revenue: "annual_revenue",
  company_city: "company_city",
  company_state: "company_state",
  company_country: "company_country",
  company_address: "company_address",
  company_technologies: "company_technologies",
  company_keywords: "company_keywords",
  seo_description: "company_description",
};
export const STATIC_EXPORT_ORDRE_PAIR1 = {
  first_name: "First Name",
  last_name: "Last Name",
  job_title: "Job Title",
  email: "Email",
  mobile_number: "Mobile Phone Number",
  direct_phone_number: "Contact Phone Number",
  company_phone_number: "Company Phone Number",
  person_city: "Contact City",
  person_state: "Contact State",
  person_country: "Contact Country",
  contact_linkedin_url: "Contact Linkedin Profile",
  company_linkedin_url: "Company Linkedin Page",
  company_facebook_url: "Company Facebook Page",
  company_twitter_url: "Company Twitter Page",
  company_name: "Company Name",
  company_website: "Company Website",
  industry: "Industry",
  number_of_employees: "Number of Employees",
  annual_revenue: "Annual Revenue",
  company_city: "Company City",
  company_state: "Company State",
  company_country: "Company Country",
  company_address: "Company Address",
  company_technologies: "Company Technologies",
  company_keywords: "Company Keywords",
  seo_description: "Company Description",
};

export const STATIC_COLUMN_ORDER = [
  "first_name",
  "last_name",
  "job_title",
  "email",
  "mobile_number",
  "direct_phone_number",
  "company_phone_number",
  "person_city",
  "person_state",
  "person_country",
  "contact_linkedin_url",
  "company_linkedin_url",
  "company_facebook_url",
  "company_twitter_url",
  "company_name",
  "company_website",
  "industry",
  "number_of_employees",
  "annual_revenue",
  "company_city",
  "company_state",
  "company_country",
  "company_address",
  "company_technologies",
  "company_keywords",
  "seo_description",
];

export const REQUIRED_ITEM = [
  { id: "true", label: "Required" },
  { id: "false", label: "Not Required" },
];

export const propertiesFilterKeys = [
  "property_name",
  "field_type",
  "grp_name",
  "created_by",
];
export const templateFilterKeys = ["name", "owner_name"];
export const dashboardFilterKeys = ["dashboard_name"];
export const groupsFilterKeys = ["grp_name", "grp_type"];

export const CSV_STEP_TYPES = [
  {
    title: "Enrich People",
    description: "Select a CSV of people and enrich their information.",
    bgColor: "#FFF3E2",
    iconComponent: PEOPLE_IMAGE,
  },
  {
    title: "Enrich Companies",
    description: "Select a CSV of companies and enrich their information.",
    bgColor: "#E4FDFF",
    iconComponent: COMPANY_IMAGE,
  },
];

//TAbs Items

// const tabItems = [
//   { id: "timeline", label: "Timeline", path: "/timeline" },
//   { id: "calls", label: "Calls", path: "/calls" },
//   { id: "emails", label: "Emails", path: "/emails" },
//   { id: "meetings", label: "Emails", path: "/meetings" },
//   { id: "notes", label: "Emails", path: "/notes" },
//   { id: "tasks", label: "Emails", path: "/tasks" },
//   { id: "notes", label: "Emails", path: "/notes" },
//   { id: "notes", label: "Emails", path: "/notes" },
// ];

export const selectData = [
  {
    id: 3,
    label: "Select",
    // iconLabel: ICON_LABELS.LANGUAGE,
  },
  {
    id: 1,
    label: "Website URL",
    iconLabel: ICON_LABELS.LANGUAGE,
  },
  {
    id: 2,
    label: "Meeting URL",
    iconLabel: ICON_LABELS.VIDEO_CALL,
  },
];

export const ESP_IMAGES_BY_INDEX = {
  1: "https://reachiq-email-template-images-new.s3.us-west-1.amazonaws.com/assets/assets/icons/sendgridUpdated.svg",
  2: "https://reachiq-email-template-images-new.s3.us-west-1.amazonaws.com/assets/assets/icons/gmail.svg",
  3: "https://reachiq-email-template-images-new.s3.us-west-1.amazonaws.com/assets/assets/icons/mailgun.svg",
  4: "https://reachiq-email-template-images-new.s3.us-west-1.amazonaws.com/assets/assets/icons/mandrill.svg",
  5: "https://reachiq-email-template-images-new.s3.us-west-1.amazonaws.com/assets/assets/icons/outlook.svg",
  6: "https://reachiq-email-template-images-new.s3.us-west-1.amazonaws.com/assets/assets/icons/brevo.svg",
  7: "https://reachiq-email-template-images-new.s3.us-west-1.amazonaws.com/assets/assets/icons/message.svg",
  "-1": "Invalid ESP",
};

// Meetings Constants

export const options = [
  { id: 15, label: "15 Minutes", email: "15 Minutes" },
  { id: 30, label: "30 Minutes", email: "30 Minutes" },
  { id: 45, label: "45 Minutes", email: "45 Minutes" },
  { id: 60, label: "1 Hour", email: "1 Hour" },
  { id: 90, label: "1 Hour 30 Minutes", email: "1 Hour 30 Minutes" },
  { id: 120, label: "2 Hour", email: "2 Hour" },
];

export const customWeek = [
  { id: 1, label: "This Week" },
  { id: 2, label: "This Week and Next Week" },
  { id: 3, label: "3 Weeks" },
  { id: 4, label: "4 Weeks" },
  { id: 5, label: "5 Weeks" },
  { id: 6, label: "6 Weeks" },
];

export const bufferTime = [
  { id: 0, label: "No Buffer Time" },
  { id: 15, label: "15 Minutes" },
  { id: 30, label: "30 Minutes" },
  { id: 45, label: "45 Minutes" },
  { id: 60, label: "1 Hour" },
];

export const minNoticePeriod = [
  { id: 15, label: "15 Minutes" },
  { id: 30, label: "30 Minutes" },
  { id: 45, label: "45 Minutes" },
  { id: 60, label: "1 Hour" },
  { id: 120, label: "2 Hour" },
  { id: 180, label: "3 Hour" },
];
export const radioButtonData = [
  {
    id: 0,
    label: "Over a period of rolling weeks",
  },
  { id: 1, label: "Custom Date Range" },
];

export const organizerAvatar = [
  { id: "0", label: "Organizer Avatar" },
  { id: "1", label: "Company Avatar" },
  { id: "2", label: "Custom Image" },
];

export const imageType = {
  jpg: "jpg",
  jpeg: "jpeg",
  png: "png",
};
export const SEND_REMINDER = [
  {
    id: "1",
    label: "Yes",
  },
  {
    id: "0",
    label: "No",
  },
];

export const OUTCOME_LIST = [
  { id: "Scheduled", label: "Scheduled" },
  { id: "Completed", label: "Completed" },
  { id: "Rescheduled", label: "Rescheduled" },
  { id: "No Show", label: "No Show" },
  { id: "Canceled", label: "Canceled" },
];
export const tenants_api_endpoints = [
  { id: "get_property_list", action: getPropertiesList, type: "post" },
  { id: "check_inbox_status", endpoint: authEndpoints.checkInboxStatus },
  { id: "tenant_user_list", endpoint: authEndpoints.tenantUsersList },
  { id: "get_pipelines_list", action: getPipelineList, type: "get" },
];

export function getFileExtension(fileName) {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return null; // No dot found, no extension
  }
  const extension = fileName.substring(lastDotIndex + 1);
  return extension.toLowerCase();
}

export const DEFAULT_CONTACT_FIELD_ORDER = [
  "first_name",
  "last_name",
  "email",
  "direct_phone_number",
  "corporate_phone_number",
  "contact_linkedin_url",
  "job_title",
  "person_city",
  "person_state",
];

export const DEFAULT_DEALS_FIELD_ORDER = [
  "deal_name",
  "deal_owner",
  "deal_amount",
  "associated_contact",
];
export const DEFAULT_COMPANY_FIELD_ORDER = [
  "company_name",
  "industry",
  "company_keywords",
  "company_address",
  "annual_revenue",
  "company_city",
  "company_state",
  "company_country",
  "company_phone_number",
];

export const DealsOptions = [
  { id: "10%", label: "10%" },
  { id: "20%", label: "20%" },
  { id: "30%", label: "30%" },
  { id: "40%", label: "40%" },
  { id: "50%", label: "50%" },
  { id: "60%", label: "60%" },
  { id: "70%", label: "70%" },
  { id: "80%", label: "80%" },
  { id: "90%", label: "90%" },
  { id: "100%", label: "100%" },
];

export const COMPANY_EMPLOYEES_COUNT_DYNAMIC = [
  { label: "0-10 employees", id: 1 },
  { label: "11-20 employees", id: 2 },
  { label: "21-50 employees", id: 3 },
  { label: "51-100 employees", id: 4 },
  { label: "101-200 employees", id: 5 },
  { label: "201-500 employees", id: 6 },
  { label: "501-1,000 employees", id: 7 },
  { label: "1,001-2,000 employees", id: 8 },
  { label: "2,001-5,000 employees", id: 9 },
  { label: "5,001-10,000 employees", id: 10 },
  { label: "10,000+ employees", id: 11 },
];

export const SENIORIRT_TYPES_DYNAMIC = [
  { id: "owner", label: "Owner" },
  { id: "founder", label: "Founder" },
  { id: "cxo", label: "CXO" },
  { id: "partner", label: "Partner" },
  { id: "vp", label: "VP" },
  { id: "director", label: "Director" },
  { id: "head", label: "Head" },
  { id: "manager", label: "Manager" },
  { id: "entry_level", label: "Entry Level" },
];

export const INITIALS_STAGES = [
  { name: "Opportunity Identified", probability: "20", _id: 1 },

  { name: "Opportunity Identified", probability: "20", _id: 2 },
  { name: "Proposal Shared", probability: "10", _id: 3 },
  { name: "Negotiating ", probability: "20", _id: 4 },
  { name: "Deal Won", probability: "40", _id: 5 },

  { name: "Deal Lost", probability: "50", _id: 6 },
];

export const requiredKeysOfStorage = [
  "properties",
  "pipelines",
  "images_preloaded",
  "deal_view",
  "pipeline",
];

export const FORMS_TYPE_LIST = [
  { id: "contacts", label: "Contacts" },
  { id: "accounts", label: "Accounts" },
  { id: "deals", label: "Deals" },
];

export const formSectionList = [
  {
    id: "add_properties",
    label: "Add Properties",
    companyField: true,
  },
  {
    id: "add_associations",
    label: "Add Associations",
    companyField: false,
  },
];

// static fields of contact form.
export const CONTACT_FORM_STATIC_FIELDS = ["first_name", "last_name", "email"];

// static fields of company form.
export const COMPANY_FORM_STATIC_FIELDS = ["company_name", "company_website"];

// static fields of company form.
export const DEALS_FORM_STATIC_FIELDS = ["deal_name"];

// associations fields list of form module.
export const ADD_ASSOCIATION_LIST_DEALS = [
  {
    id: "associated_contact",
    label: "Associated Contact",
    field_type: "Multiple Select",
  },
  {
    id: "associated_company",
    label: "Associated Company",
    field_type: "Single Select",
  },
];

// associations fields list of form module.
export const ADD_ASSOCIATION_LIST_CONTACT = [
  {
    id: "company_id",
    label: "Company Name",
    field_type: "Single Select",
    is_deal: true,
    is_contact: true,
  },
];

export const SnippetListHeadData = [
  {
    id: "name",
    sort: true,
    label: "Name",
    ui_id: "marketing_list",
    copy: false,
    width: "21vw",
  },
  {
    id: "created_by",
    sort: false,
    label: "Created By",
    ui_id: "text",
    copy: false,
    width: "21vw",
  },
  {
    id: "shortcut",
    sort: false,
    label: "Shortcut",
    ui_id: "text",
    copy: true,
    width: "21vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created At",
    ui_id: "time_stamp",
    copy: false,
    width: "21vw",
  },
  {
    id: "action",
    sort: false,
    label: "Actions",
    ui_id: "action",
    width: "12vw",
  },
];

export const dealPriorityList = [
  { id: "low", label: "Low" },
  { id: "medium", label: "Medium" },
  { id: "high", label: "High" },
];

export const dealPriorityVariant = {
  low: "green",
  medium: "yellow",
  high: "red",
};

export const PREIVEW_FORMS_DROPDOWN_VALUES = [
  { id: "1", label: "Value 1" },
  { id: "2", label: "Value 2" },
  { id: "3", label: "Value 3" },
  { id: "4", label: "Value 4" },
];

// date range picker months constant.
export const MONTHS = [
  {
    id: "0",
    label: "January",
  },
  {
    id: "1",
    label: "February",
  },
  {
    id: "2",
    label: "March",
  },
  {
    id: "3",
    label: "April",
  },
  {
    id: "4",
    label: "May",
  },
  {
    id: "5",
    label: "June",
  },
  {
    id: "6",
    label: "July",
  },
  {
    id: "7",
    label: "August",
  },
  {
    id: "8",
    label: "September",
  },
  {
    id: "9",
    label: "October",
  },
  {
    id: "10",
    label: "November",
  },
  {
    id: "11",
    label: "December",
  },
];

// date range picker days constants
export const DAYNAMES = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

// date range labels index
export const DATE_RANGE_LABEL_INDEX = {
  today: 0,
  yesterday: 1,
  last_7_days: 2,
  last_30_days: 3,
  custom_range: 4,
};

// date range index labels
export const DATE_RANGE_INDEX_LABELS = {
  0: "today",
  1: "yesterday",
  2: "last_7_days",
  3: "last_30_days",
  4: "custom_range",
};

export const staticTenantsHostNames = [
  "demo",
  "resourcifi",
  "agencyforagencies",
  "indigocoders",
  "indigocoders-co",
  "codecircuit",
  "revocytenet",
  "revocytenet-com",
  "testdomain",
];

export const EMOJIS_LIST = [
  {
    id: "blush",
    label: "😊"
  },
  {
    id: "smiley",
    label: "😃"
  },
  {
    id: "satisfied",
    label: "😆"
  },
  {
    id: "grin",
    label: "😁"
  },
  {
    id: "stuck_out_tongue_closed_eyes",
    label: "😝"
  },
  {
    id: "sweat_smile",
    label: "😅"
  },
  {
    id: "sweat_smile",
    label: "😅"
  },
  {
    id: "disappointed_relieved",
    label: "😥"
  },
]