export const FILTER_HEADER_SEARCH_REQUEST = "FILTER_HEADER_SEARCH_REQUEST";
export const FILTER_HEADER_SEARCH_SUCCESS = "FILTER_HEADER_SEARCH_SUCCESS";
export const FILTER_HEADER_SEARCH_FAIL = "FILTER_HEADER_SEARCH_FAIL";

export const FILTER_HEADER_SEARCH_JOBTITLE_REQUEST =
  "FILTER_HEADER_SEARCH_JOBTITLE_REQUEST";
export const FILTER_HEADER_SEARCH_JOBTITLE_SUCCESS =
  "FILTER_HEADER_SEARCH_JOBTITLE_SUCCESS";
export const FILTER_HEADER_SEARCH_JOBTITLE_FAIL =
  "FILTER_HEADER_SEARCH_JOBTITLE_FAIL";

export const FILTER_HEADER_SEARCH_INDUSTRY_REQUEST =
  "FILTER_HEADER_SEARCH_INDUSTRY_REQUEST";
export const FILTER_HEADER_SEARCH_INDUSTRY_SUCCESS =
  "FILTER_HEADER_SEARCH_INDUSTRY_SUCCESS";
export const FILTER_HEADER_SEARCH_INDUSTRY_FAIL =
  "FILTER_HEADER_SEARCH_INDUSTRY_FAIL";

export const FILTER_HEADER_COMPANY_NAME_REQUEST =
  "FILTER_HEADER_COMPANY_NAME_REQUEST";
export const FILTER_HEADER_COMPANY_NAME_SUCCESS =
  "FILTER_HEADER_COMPANY_NAME_SUCCESS";
export const FILTER_HEADER_COMPANY_NAME_FAIL =
  "FILTER_HEADER_COMPANY_NAME_FAIL";

export const FILTER_HEADER_COMPANY_KEYWORDS_REQUEST =
  "FILTER_HEADER_COMPANY_KEYWORDS_REQUEST";
export const FILTER_HEADER_COMPANY_KEYWORDS_SUCCESS =
  "FILTER_HEADER_COMPANY_KEYWORDS_SUCCESS";
export const FILTER_HEADER_COMPANY_KEYWORDS_FAIL =
  "FILTER_HEADER_COMPANY_KEYWORDS_FAIL";

export const FILTER_HEADER_COMPANY_TECHNOLOGIES_REQUEST =
  "FILTER_HEADER_COMPANY_TECHNOLOGIES_REQUEST";
export const FILTER_HEADER_COMPANY_TECHNOLOGIES_SUCCESS =
  "FILTER_HEADER_COMPANY_TECHNOLOGIES_SUCCESS";
export const FILTER_HEADER_COMPANY_TECHNOLOGIES_FAIL =
  "FILTER_HEADER_COMPANY_TECHNOLOGIES_FAIL";

export const FILTER_HEADER_COMPANY_SEO_REQUEST =
  "FILTER_HEADER_COMPANY_SEO_REQUEST";
export const FILTER_HEADER_COMPANY_SEO_SUCCESS =
  "FILTER_HEADER_COMPANY_SEO_SUCCESS";
export const FILTER_HEADER_COMPANY_SEO_FAIL = "FILTER_HEADER_COMPANY_SEO_FAIL";

export const FILTER_HEADER_PERSON_CITY_REQUEST =
  "FILTER_HEADER_PERSON_CITY_REQUEST";
export const FILTER_HEADER_PERSON_CITY_SUCCESS =
  "FILTER_HEADER_PERSON_CITY_SUCCESS";
export const FILTER_HEADER_PERSON_CITY_FAIL = "FILTER_HEADER_PERSON_CITY_FAIL";

export const FILTER_HEADER_PERSON_STATE_REQUEST =
  "FILTER_HEADER_PERSON_STATE_REQUEST";
export const FILTER_HEADER_PERSON_STATE_SUCCESS =
  "FILTER_HEADER_PERSON_STATE_SUCCESS";
export const FILTER_HEADER_PERSON_STATE_FAIL =
  "FILTER_HEADER_PERSON_STATE_FAIL";

export const FILTER_HEADER_PERSON_COUNTRY_REQUEST =
  "FILTER_HEADER_PERSON_COUNTRY_REQUEST";
export const FILTER_HEADER_PERSON_COUNTRY_SUCCESS =
  "FILTER_HEADER_PERSON_COUNTRY_SUCCESS";
export const FILTER_HEADER_PERSON_COUNTRY_FAIL =
  "FILTER_HEADER_PERSON_COUNTRY_FAIL";

export const FILTER_HEADER_COMPANY_CITY_REQUEST =
  "FILTER_HEADER_COMPANY_CITY_REQUEST";
export const FILTER_HEADER_COMPANY_CITY_SUCCESS =
  "FILTER_HEADER_COMPANY_CITY_SUCCESS";
export const FILTER_HEADER_COMPANY_CITY_FAIL =
  "FILTER_HEADER_COMPANY_CITY_FAIL";

export const FILTER_HEADER_COMPANY_STATE_REQUEST =
  "FILTER_HEADER_COMPANY_STATE_REQUEST";
export const FILTER_HEADER_COMPANY_STATE_SUCCESS =
  "FILTER_HEADER_COMPANY_STATE_SUCCESS";
export const FILTER_HEADER_COMPANY_STATE_FAIL =
  "FILTER_HEADER_COMPANY_STATE_FAIL";

export const FILTER_HEADER_COMPANY_COUNTRY_REQUEST =
  "FILTER_HEADER_COMPANY_COUNTRY_REQUEST";
export const FILTER_HEADER_COMPANY_COUNTRY_SUCCESS =
  "FILTER_HEADER_COMPANY_COUNTRY_SUCCESS";
export const FILTER_HEADER_COMPANY_COUNTRY_FAIL =
  "FILTER_HEADER_COMPANY_COUNTRY_FAIL";

export const FILTER_HEADER_COMPANY_REQUEST = "FILTER_HEADER_COMPANY_REQUEST";
export const FILTER_HEADER_COMPANY_SUCCESS = "FILTER_HEADER_COMPANY_SUCCESS";
export const FILTER_HEADER_COMPANY_FAIL = "FILTER_HEADER_COMPANY_FAIL";

export const FILTER_HEADER_SENIORITY_REQUEST =
  "FILTER_HEADER_SENIORITY_REQUEST";
export const FILTER_HEADER_SENIORITY_SUCCESS =
  "FILTER_HEADER_SENIORITY_SUCCESS";
export const FILTER_HEADER_SENIORITY_FAIL = "FILTER_HEADER_SENIORITY_FAIL";

export const FILTER_HEADER_EMAIL_VERIFICATION_REQUEST =
  "FILTER_HEADER_EMAIL_VERIFICATION_REQUEST";
export const FILTER_HEADER_EMAIL_VERIFICATION_SUCCESS =
  "FILTER_HEADER_EMAIL_VERIFICATION_SUCCESS";
export const FILTER_HEADER_EMAIL_VERIFICATION_FAIL =
  "FILTER_HEADER_EMAIL_VERIFICATION_FAIL";

export const FILTER_HEADER_CONTACT_LIST_REQUEST =
  "FILTER_HEADER_CONTACT_LIST_REQUEST";
export const FILTER_HEADER_CONTACT_LIST_SUCCESS =
  "FILTER_HEADER_CONTACT_LIST_SUCCESS";
export const FILTER_HEADER_CONTACT_LIST_FAIL =
  "FILTER_HEADER_CONTACT_LIST_FAIL";

export const FILTER_HEADER_ACCOUNT_LIST_REQUEST =
  "FILTER_HEADER_ACCOUNT_LIST_REQUEST";
export const FILTER_HEADER_ACCOUNT_LIST_SUCCESS =
  "FILTER_HEADER_ACCOUNT_LIST_SUCCESS";
export const FILTER_HEADER_ACCOUNT_LIST_FAIL =
  "FILTER_HEADER_ACCOUNT_LIST_FAIL";

export const ALL_FILTERS_REQUEST = "ALL_FILTERS_REQUEST";
export const ALL_FILTERS_SUCCESS = "ALL_FILTERS_SUCCESS";
export const ALL_FILTERS_FAIL = "ALL_FILTERS_FAIL";

export const ALL_DYNAMIC_FILTERS_REQUEST = "ALL_DYNAMIC_FILTERS_REQUEST";
export const ALL_DYNAMIC_FILTERS_SUCCESS = "ALL_DYNAMIC_FILTERS_SUCCESS";
export const ALL_DYNAMIC_FILTERS_FAIL = "ALL_DYNAMIC_FILTERS_FAIL";

export const FILTER_MULTIPLE_REQUEST = "FILTER_MULTIPLE_REQUEST";
export const FILTER_MULTIPLE_SUCCESS = "FILTER_MULTIPLE_SUCCESS";
export const FILTER_MULTIPLE_FAIL = "FILTER_MULTIPLE_FAIL";
