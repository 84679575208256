import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UNSUBSCRIBE_ICON } from "../../utils/constants/assets";
import { HOME_PATH } from "../../utils/constants/routes";
import { styles } from "./styles";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import { STRINGS } from "./strings";
import Image from "../../components/Image/Image";
import { Button } from "../../components/Buttons";
import TypographyInline from "../../components/Typography/TypographyInline";

const UnsubscribeScreen = () => {
  const classes = styles();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(HOME_PATH);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.containUnsubscribeBox}>
        <Box className={classes.unsubscribeBox}>
          <Box className={classes.flex}>
            <Box className={classes.containImage}>
              <Image src={UNSUBSCRIBE_ICON} />
            </Box>
          </Box>
          <Box className={classes.MAinContainer}>
            <TypographyInline
              size={"dxs"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.UNSUBSCRIBE}
            />{" "}
            <TypographyInline
              size={"sm"}
              color={GREY.TERTIARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.MORE_EMAIL}
            />{" "}
          </Box>
          <Box className={classes.flex}>
            <Button
              label={STRINGS.GO_TO_HOME}
              size={"md40"}
              variant={"primary"}
              onClick={handleNavigate}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UnsubscribeScreen;
