import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../Apis";
import { campaignEndpoints } from "../../utils/constants/httpConstants";
import { EMITTER_ERROR, MONTH_INDEX_PAIR } from "../../utils/constants";
import { Logger, textEllipsesHandler } from "../../utils/helpers";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import { BRAND, GREY, HELP } from "../../utils/constants/colors";
import { STRINGS } from "./strings";
import { EMAIL_MARKETING_CAMPAIGN_PATH } from "../../utils/constants/routes";
import CustomHeader from "../../components/Header/CustomHeader";
import TypographyInline from "../../components/Typography/TypographyInline";
import EventEmitter from "../../utils/emitter/EventEmitter";
import IconComponent from "../../components/Icon/IconComponent";
import SkeletonLoader from "../../components/Loaders/SkeletonLoader";
import classes from "./marketing.module.css";
import { useSelector } from "react-redux";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
const CampaignActivityScreen = () => {
  const navigate = useNavigate();
  const params = useParams();

  const tenantUsersListSelector = useSelector((state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data);

  const ids = params?.campaignId?.split("&");
  const CURRENT_TAB_ID = STRINGS.CAMPAIGN_ACTIVITY_ID;
  const campaignId = ids[0];
  const sequenceId = ids[1];
  const [activities, setActivities] = useState({});
  const [loading, setLoading] = useState(true);
  const [headerItems, setHeaderItems] = useState([
    {
      id: STRINGS.TYPE_CAMP,
      label: STRINGS.CAMPAIGN_TEXT,
      path: EMAIL_MARKETING_CAMPAIGN_PATH,
    },
  ]);

  const EMAIL_MARKETING_TABS = [
    {
      label: STRINGS.CAMPAIGN_CONTACTS_LABEL,
      id: STRINGS.CAMPAIGN_CONTACTS_ID,
    },
    {
      label: STRINGS.CAMPAIGN_OVERVIEW_LABEL,
      id: STRINGS.CAMPAIGN_OVERVIEW_ID,
    },

    {
      label: STRINGS.CAMPAIGN_ACTIVITY_LABEL,
      id: STRINGS.CAMPAIGN_ACTIVITY_ID,
    },
    {
      label: STRINGS.CAMPAIGN_SETTINGS_LABEL,
      id: STRINGS.CAMPAIGN_SETTINGS_ID,
    },
  ];

  const skeletonActivities = [{}, {}, {}, {}, {}];

  const replaceUserIdWithName = (activity) => {
    const activiy_user_id = activity?.user_id;
    const activity_message = activity?.message;
    const activity_user_details = tenantUsersListSelector?.filter((item) => item?._id === activiy_user_id)?.[0];
    const activity_user_name = `${activity_user_details?.first_name || ""} ${activity_user_details?.last_name || ""}`;
    const activity_final_message = activity_message?.replace(STRINGS.USER, activity_user_name);
    return activity_final_message;
  }

  useEffect(() => {
    // This is for getting details of campaign.
    (async () => {
      try {
        const campaign_details = await getRequest(
          navigate,
          campaignEndpoints.eachCampaignDetail + "/" + campaignId
        );
        setHeaderItems([
          {
            id: STRINGS.TYPE_CAMP,
            label: STRINGS.CAMPAIGN_TEXT,
            path: EMAIL_MARKETING_CAMPAIGN_PATH,
          },
          {
            id: campaign_details?.data?.data?._id,
            label: campaign_details?.data?.data?.name,
          },
        ]);
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    })();

    // This is for getting contacts steps.
    (async () => {
      try {
        let payload = { campaign_id: campaignId, page: 0, start: 0 };
        const activity_response = await postRequest(
          navigate,
          campaignEndpoints.activityLogs,
          payload
        );
        let server_payload = activity_response?.data?.data?.logs || [];

        let responseObj = {};
        for (let i = 0; i < server_payload?.length; i++) {
          const date = new Date(server_payload[i].updated_at);
          const year = date?.getFullYear();
          const month = date?.getMonth();
          const key = `${month}_${year}`;
          responseObj[key] = [...(responseObj[key] || []), server_payload[i]];
        }
        setActivities(responseObj);
      } catch (error) {
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [navigate, campaignId, sequenceId]);
  // this useEffect is for gettings campaign details, stats, and overview steps.

  const handleNavigate = (item) => {
    try {
      navigate(
        `${EMAIL_MARKETING_CAMPAIGN_PATH}/${params?.campaignId}/${item?.id}`
      );
    } catch (error) {
      Logger(error);
    }
  };

  return (
    <Box className={classes.container1}>
      <CustomHeader items={headerItems} />

      {/* Tabs for selection */}
      <Box className={classes.tabContainer}>
        {EMAIL_MARKETING_TABS?.map((item) => {
          return (
            <Box
              keye={item.id}
              className={
                CURRENT_TAB_ID === item.id ? classes.selectedTab : classes.tab
              }
              onClick={() => handleNavigate(item)}
            >
              <TypographyInline
                size={"sm"}
                color={
                  CURRENT_TAB_ID === item.id ? BRAND.PRIMARY : GREY.SECONDARY
                }
                fontWeight={
                  CURRENT_TAB_ID === item.id
                    ? fontWeight.MEDIUM
                    : fontWeight.REGULAR
                }
                label={item.label}
              />
            </Box>
          );
        })}
      </Box>
      {/* Tabs for selection */}
      <Box className={classes.activityContainer}>
        <Box className={classes.activities}>
          {loading && (
            <Box className={classes.eachActivity1}>
              <SkeletonLoader
                type={"rounded"}
                skeletonWidth={100}
                skeletonHeight={16}
              />
            </Box>
          )}
          {loading
            ? skeletonActivities.map((item) => {
              return (
                <Box id="modal_body" className={classes.eachActivity}>
                  <Box className={classes.flex}>
                    <Box className={classes.containIcon}>
                      <SkeletonLoader
                        type={"circular"}
                        skeletonWidth={40}
                        skeletonHeight={40}
                      />
                    </Box>
                    <Box className={classes.alignSelfCenter}>
                      <SkeletonLoader
                        type={"rounded"}
                        skeletonWidth={140}
                        skeletonHeight={20}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.pureFlex}>
                    <Box className={classes.activityLogDateContainer}>
                      <SkeletonLoader
                        type={"rounded"}
                        skeletonWidth={16}
                        skeletonHeight={16}
                      />
                      <SkeletonLoader
                        type={"rounded"}
                        skeletonWidth={80}
                        skeletonHeight={16}
                      />
                    </Box>
                    <Box className={classes.activityLogTimeContainer}>
                      <SkeletonLoader
                        type={"rounded"}
                        skeletonWidth={16}
                        skeletonHeight={16}
                      />
                      <SkeletonLoader
                        type={"rounded"}
                        skeletonWidth={60}
                        skeletonHeight={16}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })
            : Object?.keys(activities)?.map((eachActivityMonth, index) => {
              let eachMonth = eachActivityMonth?.split("_")?.[0];
              let eachActivityYear = eachActivityMonth?.split("_")?.[1];
              return (
                <Box
                  key={eachActivityMonth}
                  className={classes.activitiesContainer}
                >
                  <Box className={classes.eachActivity1}>
                    <TypographyInline
                      size={"sm"}
                      color={GREY.PRIMARY}
                      fontWeight={fontWeight.MEDIUM}
                      label={`${MONTH_INDEX_PAIR[eachMonth]} ${eachActivityYear}`}
                    />
                  </Box>
                  {activities[eachActivityMonth]?.map((eachActivity) => {
                    return (
                      <Box id="modal_body" className={classes.eachActivity}>
                        <Box className={classes.flex}>
                          <Box className={classes.containIcon}>
                            <IconComponent
                              iconLabel={ICON_LABELS.EMAIL}
                              color={HELP.W_500}
                              fontSize={fontSize.MD}
                            />
                          </Box>
                          <CustomTooltip title={replaceUserIdWithName(eachActivity)?.length >= 50 ? replaceUserIdWithName(eachActivity) : ""} >
                            <Box className={classes.alignSelfCenter}>
                              <TypographyInline
                                size={"sm"}
                                color={GREY.SECONDARY}
                                fontWeight={fontWeight.REGULAR}
                                isEllipses={true}
                                label={textEllipsesHandler(
                                  replaceUserIdWithName(eachActivity),
                                  50
                                )}
                              />
                            </Box>
                          </CustomTooltip>
                        </Box>
                        <Box className={classes.pureFlex}>
                          <Box className={classes.activityLogDateContainer}>
                            <IconComponent
                              iconLabel={ICON_LABELS.TODAY}
                              color={GREY.TERTIARY}
                              fontSize={fontSize.SM}
                            />
                            <TypographyInline
                              size={"xs"}
                              isEllipses={true}
                              color={GREY.SECONDARY}
                              fontWeight={fontWeight.REGULAR}
                              label={new Date(
                                eachActivity?.updated_at
                              ).toDateString()}
                            />
                          </Box>
                          <Box className={classes.activityLogTimeContainer}>
                            <IconComponent
                              iconLabel={ICON_LABELS.SCHEDULE}
                              color={GREY.TERTIARY}
                              fontSize={fontSize.SM}
                            />
                            <TypographyInline
                              size={"xs"}
                              isEllipses={true}
                              color={GREY.SECONDARY}
                              fontWeight={fontWeight.REGULAR}
                              label={new Date(
                                eachActivity?.updated_at
                              ).toLocaleTimeString(`en-US`, { hour12: true })}
                            />
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignActivityScreen;
