import {
  APPLY_COLUMN_UPDATE_FAIL,
  APPLY_COLUMN_UPDATE_REQUEST,
  APPLY_COLUMN_UPDATE_SUCCESS,
  COLUMNS_UPDATE_FAIL,
  COLUMNS_UPDATE_REQUEST,
  COLUMNS_UPDATE_SUCCESS,
  COMPANY_TABLE_ENDPOINT_CHANGE_FAIL,
  COMPANY_TABLE_ENDPOINT_CHANGE_REQUEST,
  COMPANY_TABLE_ENDPOINT_CHANGE_SUCCESS,
  TABLE_DATA_FETCH_FAIL,
  TABLE_DATA_FETCH_REQUEST,
  TABLE_DATA_FETCH_SUCCESS,
  TABLE_ENDPOINT_CHANGE_FAIL,
  TABLE_ENDPOINT_CHANGE_REQUEST,
  TABLE_ENDPOINT_CHANGE_SUCCESS,
  TABLE_DATA_FETCH_CAMPAIGN_FAIL,
  TABLE_DATA_FETCH_CAMPAIGN_REQUEST,
  TABLE_DATA_FETCH_CAMPAIGN_SUCCESS,
  GET_LIST_IDS_REQUEST,
  GET_LIST_IDS_SUCCESS,
  GET_LIST_IDS_FAIL,
  DYNAMIC_COLUMNS_UPDATE_REQUEST,
  DYNAMIC_COLUMNS_UPDATE_SUCCESS,
  DYNAMIC_COLUMNS_UPDATE_FAIL,
  DYNAMIC_UPDATED_COLUMNS_SUCCESS,
  DYNAMIC_UPDATED_COLUMNS_REQUEST,
  DYNAMIC_UPDATED_COLUMNS_FAIL,
} from "../constants/tableConstants";

export const columnUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COLUMNS_UPDATE_REQUEST:
      return { loading: true };

    case COLUMNS_UPDATE_SUCCESS:
      return { loading: false, columns: action.payload };

    case COLUMNS_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const dynamicColumnUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DYNAMIC_COLUMNS_UPDATE_REQUEST:
      return { loading: true };

    case DYNAMIC_COLUMNS_UPDATE_SUCCESS:
      return { loading: false, columns: action.payload, not_api_call: action.not_api_call };

    case DYNAMIC_COLUMNS_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const ApplyUpdateColumnReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLY_COLUMN_UPDATE_REQUEST:
      return { loading: true };

    case APPLY_COLUMN_UPDATE_SUCCESS:
      return { loading: false, columns: action.payload };

    case APPLY_COLUMN_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const dynamicUpdatedColumnReducer = (state = {}, action) => {
  switch (action.type) {
    case DYNAMIC_UPDATED_COLUMNS_REQUEST:
      return { loading: true };

    case DYNAMIC_UPDATED_COLUMNS_SUCCESS:
      return { loading: false, columns: action.payload, not_api_call: action.not_api_call };

    case DYNAMIC_UPDATED_COLUMNS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const tableDataFetchReducer = (state = {}, action) => {
  switch (action.type) {
    case TABLE_DATA_FETCH_REQUEST:
      return { loading: true };

    case TABLE_DATA_FETCH_SUCCESS:
      return {
        loading: false,
        columns: action.payload,
        totalRecords: action.totalRecords,
      };

    case TABLE_DATA_FETCH_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const tableDataFetchCampaignReducer = (state = {}, action) => {
  switch (action.type) {
    case TABLE_DATA_FETCH_CAMPAIGN_REQUEST:
      return { loading: true };

    case TABLE_DATA_FETCH_CAMPAIGN_SUCCESS:
      return {
        loading: false,
        columns: action.payload,
        totalRecords: action.totalRecords,
      };

    case TABLE_DATA_FETCH_CAMPAIGN_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const tableEndpointChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case TABLE_ENDPOINT_CHANGE_REQUEST:
      return { loading: true };

    case TABLE_ENDPOINT_CHANGE_SUCCESS:
      return { loading: false, payload: action.payload };

    case TABLE_ENDPOINT_CHANGE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const companyTableEndpointChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_TABLE_ENDPOINT_CHANGE_REQUEST:
      return { loading: true };

    case COMPANY_TABLE_ENDPOINT_CHANGE_SUCCESS:
      return { loading: false, payload: action.payload };

    case COMPANY_TABLE_ENDPOINT_CHANGE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getListIdsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LIST_IDS_REQUEST:
      return { loading: true };

    case GET_LIST_IDS_SUCCESS:
      return { loading: false, payload: action.payload };

    case GET_LIST_IDS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
