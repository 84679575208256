export const STRINGS = {
  PROPERTIES: "Properties",
  PROPERTIES_DESC:
    "These are used to collect and store information about your records in ReachIQ. For example, a contact might have properties like First Name or Lead Status.",
  CREATE_GROUP: "Create Group",
  PROPERTY_MODAL_DESC: "Follow 5 simple Steps to create your campaign",
  OBJECT_TYPE_PLACEHOLDER: "Contact",
  OBJECT_TYPE: "Property for",
  SELECT_GROUP: "Select Group",
  LABEL_NAME: "Label Name",
  LABEL_NAME_PLACEHOLDER: "Enter your label name",
  FIELD_TYPE: "Field Type",
  SELECT_PLACEHOLDER: "Select",
  CANCEL: "Cancel",
  SAVE: "Save",
  CREATE: "Create",
  EDIT: "Edit",
  UPDATE: "Update",
  PROPERTIES_INPUT_VALUES: "Property Input Values",
  ENTER_INPUT_VALUE: "Enter input value",
  NUMBER_TYPE_INPUT_PLACEHOLDER: "123456",
  ADD_LABEL: "Add Label",
  LABEL: "Label",
  INTERNAL_VALUE: "Internal Value",
  IN_FORM: "In Form",
  GROUPS: "Groups",
  ENTER_LABEL_NAME: "Enter Value",
  ENTER_INTERNAL_VALUE: "Enter Internal Value",
  EMPTY_GROUP_SCREEN_HEADING: "Groups",
  EMPTY_GROUP_SCREEN_DESC:
    "Create your own groups to collect and store information about your records in ReachIQ.",
  INPUT_PLACEHOLDER: "Enter Group Name",
  GROUP_NAME: "Group Name",
  EDIT_GROUP: "Edit Group",
  DELETE: "Delete",
  DELETE_DESC_PROPERTY: "Deleting this property will also delete all associated data created using it. Are you sure you want to proceed with the deletion?",
  DELETE_DESC:
    "Are you sure you want to delete this? This action cannot be undone.",
  EMPTY_FIELD_ERROR: "This is a required field",
  SEARCH: "Search",
  EDIT_PROPERTY: "Edit Property",
  FILTER: "Filter:",
  NUMBER_PLACEHOLDER: "123456",
  ADD_VALUE: "Add Value",
  NEXT: "Next",
  BACK: "Back",
  PREVIEW: "Preview",
  PROPERTY_VISIBLITY: "Property Visibility",
  VISIBILITY_DESCRIPTION: "Show in form",
  LOADING: "Loading",
  GROUP_NAME_PLACEHOLDER: "Search Groups",
  OPERATION: "operation",
  EDIT_SMALL: "edit",
  DELETE_SMALL: "delete",
  ERROR_SMALL: "error",
  FIELD_TYPE_SMALL: "field_type",
  GRP_NAME_SMALL: "grp_name",
  GROUP_NAME_SMALL: "group_name",
  CRT_PROP_SMALL: "crtProp",
  SINGLE_SELECT: "single_select",
  MULTIPLE_CHECKBOX: "multiple_checkbox",
  MULTIPLE_SELECT: "multiple_select",
  RICH_TEXT_EDITOR: "rich_text_editor",
  DRAG_ICON_SMALL: "drag_icon",
  LABEL_SMALL: "label",
  INTERNAL_VALUE_SMALL: "internal_value",
  ACTION_SMALL: "action",
  EMPTY_PROPERTY_SCREEN_HEADING: "Dynamic Fields",
  EMPTY_PROPERTY_SCREEN_DESC:
    "Create your own dynamic properties to collect and store information about your records in ReachIQ.",
  CREATE_PROPERTY: "Create Property",
};
