import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NEW_LOGIN_REACHIQ_LOGO } from "../../utils/constants/assets";
import { styles } from "./styles";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import {
  HOME_PATH,
  LOGIN_PATH,
  PEOPLE_SCREEN_PATH,
} from "../../utils/constants/routes";
import {
  confirmPasswordvalidation,
  passwordValidation,
} from "../../utils/validations";
import { Anchor, RightsTag } from "../../components";
import { authEndpoints } from "../../utils/constants/httpConstants";
import { useSelector } from "react-redux";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  PRIVACY_POLICY_LINK,
  TERMS_SERVICE_LINK,
} from "../../utils/constants";

import { decryptEmail, filterQuery } from "../../utils/helpers";
import { POST } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import * as qs from "qs";
import Typography from "../../components/Typography/Typography";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import { STRINGS } from "./strings";
import InputField from "../../components/InputFields/InputField";
import { Button } from "../../components/Buttons";
import Image from "../../components/Image/Image";

const GoogleRegisterScreen = () => {
  const params = useParams();
  const payload = qs.parse(window.location.search?.slice(1));
  const classes = styles();
  const navigate = useNavigate();
  const checkLoginSelector = useSelector((state) => state.checkLogin);
  const [loading, setLoading] = useState(false);
  let defaultEmail = params?.email;
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);
  const filteredQuery = filterQuery(allFiltersSelector);
  const [email, setEmail] = useState(defaultEmail || "");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [token, setToken] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validations, setValidations] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    confirm_Password: "",
  });

  if (defaultEmail) {
    const decryptedEmail = decryptEmail(params?.email?.trim());
    defaultEmail = decryptedEmail;
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validatedPassword = passwordValidation(password);
    const validatedConfirmPassword = confirmPasswordvalidation(
      password,
      confirmPassword
    );
    if (validatedPassword.isValid && validatedConfirmPassword.isValid) {
      //Here trigger the event
      setValidations({
        password: "",
        confirm_Password: "",
      });
      setLoading(true);

      const formdata = { token, password };

      try {
        const response = await POST(authEndpoints.registerGoogleUser, formdata);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        navigate(HOME_PATH);
        setLoading(false);
        if (response?.status === 200) {
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          navigate(HOME_PATH);
        }
      } catch (err) {
        const errorMessage =
          err?.data?.data?.errors?.owner_email ||
          err?.data?.data?.errors?.owner_name ||
          err?.data?.data?.errors?.password ||
          err?.data?.data?.errors?.password_confirmation ||
          err?.data?.data?.errors?.sub_domain;
        EventEmitter.emit(
          EMITTER_ERROR,
          errorMessage || STRINGS.SOMETHING_WENT_WRONG
        );
        setLoading(false);
        // here error;
      }
    } else {
      setValidations({
        password: validatedPassword.message,
        confirm_Password: validatedConfirmPassword.message,
      });
    }
  };

  if (checkLoginSelector?.payload) {
    // navigate to people page as user already login.
    // EventEmitter.emit(EMITTER_INFO, "Logged in.");
    navigate({
      pathname: PEOPLE_SCREEN_PATH,
      search: createSearchParams({
        ...filteredQuery,
      }).toString(),
    });
  }

  useEffect(() => {
    const decryptedPayload = JSON.parse(decryptEmail(payload?.token));
    setFirstName(decryptedPayload?.first_name);
    setLastName(decryptedPayload?.last_name);
    setEmail(decryptedPayload?.email);
    setToken(decryptedPayload?.v_token);
  }, [payload]);

  return (
    <Box component="main" className={classes.container}>
      <Box className={classes.leftContainer}>
        <div className={classes.containLogo}>
          <Image src={NEW_LOGIN_REACHIQ_LOGO} className={classes.logo} />
        </div>
        {/* Main auth form here */}
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <div className={classes.MainContainers}>
            <Typography
              label={STRINGS.LETS_GET_FAMILIAR}
              color={GREY.PRIMARY}
              size={"dxs"}
              fontWeight={fontWeight.SEMIBOLD}
            />
            <Typography
              label={STRINGS.TO_GET_STARTED}
              color={GREY.TERTIARY}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
            />
          </div>

          <Box className={classes.MainContainer}>
            <Box className={classes.WiDTH}>
              <div className={classes.FOUR}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.FIRST_NAME}
                />
              </div>
              <InputField
                variant={
                  validations.firstName ? STRINGS.ERROR : STRINGS.DISABLED
                }
                errormessage={
                  validations.firstName ? validations.firstName : ""
                }
                placeholder={STRINGS.ENTER_FIRST_NAME}
                size={"md40"}
                value={firstName}
              />
            </Box>

            <div className={classes.WiDTH}>
              <div className={classes.FOUR}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.LAST_NAME}
                />{" "}
              </div>
              <InputField
                variant={
                  validations.lastName ? STRINGS.ERROR : STRINGS.DISABLED
                }
                errormessage={validations.lastName ? validations.lastName : ""}
                placeholder={STRINGS.LAST_NAME}
                size={"md40"}
                value={lastName}
                disabled={true}
              />
            </div>
          </Box>
          <div className={classes.FOUR}>
            <Typography
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.EMAIL}
            />{" "}
          </div>
          <InputField
            variant={validations.email ? STRINGS.ERROR : STRINGS.DISABLED}
            errormessage={validations.email ? validations.email : ""}
            placeholder={STRINGS.ENTER_EMAIL}
            size={"md40"}
            disabled={true}
            value={email}
          />

          <Box className={classes.MainContainer}>
            <Box className={classes.WiDTH}>
              <div className={classes.FOUR}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.PASSWORD}
                />
              </div>
              <InputField
                type="password"
                variant={validations.password ? STRINGS.ERROR : STRINGS.DEFAULT}
                errormessage={validations.password ? validations.password : ""}
                placeholder={STRINGS.PASSWORD}
                size={"md40"}
                // disabled={true}
                value={password}
                onChange={(e) => {
                  handlePasswordChange(e);
                  setValidations({ ...validations, password: "" });
                }}
              />
            </Box>

            <Box className={classes.WiDTH}>
              <div className={classes.FOUR}>
                <Typography
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.CONFIRM_PASSWORD}
                />
              </div>
              <InputField
                type="password"
                variant={
                  validations.confirm_Password ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations.confirm_Password
                    ? validations.confirm_Password
                    : ""
                }
                placeholder={STRINGS.ENTER_CONFIRM_PASS}
                size={"md40"}
                // disabled={true}
                value={confirmPassword}
                onChange={(e) => {
                  handleConfirmPasswordChange(e);
                  setValidations({ ...validations, confirm_Password: "" });
                }}
              />
            </Box>
          </Box>
          <Box className={classes.containLoginBtn}>
            <Button
              size={"sm36"}
              variant={"primary"}
              label={STRINGS.SIGN_UP}
              isLoading={loading}
            />
          </Box>
          <Box className={classes.flex}>
            <Typography
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.ALREADY_HAVEAN_ACCOUNT}
            />
            <Anchor
              href={LOGIN_PATH}
              className={classes.authToggle}
              target="_blank"
            >
              {STRINGS.SIGNIN}
            </Anchor>
          </Box>
        </Box>
        {/* Main auth form here */}
        <div className={classes.containPolicy}>
          <RightsTag className={classes.termsPara} />
          {/* Privacy and Terms. */}
          <Anchor
            href={PRIVACY_POLICY_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            <Typography
              size={"xs"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.PRIVACY}
            />
          </Anchor>
          <div className={classes.TypoHead}>
            <Typography
              size={"xs"}
              color={GREY.TERTIARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.AND}
            />
          </div>
          <Anchor
            href={TERMS_SERVICE_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            <Typography
              size={"xs"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.TERMS}
            />
          </Anchor>
        </div>
      </Box>
    </Box>
  );
};

export default GoogleRegisterScreen;
