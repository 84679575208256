import React, { useEffect, useState } from "react";
import { BASE, BORDER, BRAND, ERROR, GREY } from "../../utils/constants/colors";
import {
  fontSize,
  fontWeight,
  lineHeight,
  SHADOW,
} from "../../utils/constants/UI";
import IconComponent from "../Icon/IconComponent";
import CustomTooltip from "../Tooltip/CustomTooltip";
import Typography from "../Typography/Typography";
import classes from "./Input.module.css";
import Image from "../Image/Image";

const SIZE_MAPPING = {
  xs34: {
    padding: "8px 10px 8px 0px",
    fontSize: fontSize.XS,
    fontWeight: 400,
    lineHeight: lineHeight.XS,
    iconSize: fontSize.MD,
  },
  xs36: {
    padding: "8px 10px 8px 0px",
    fontSize: fontSize.XS,
    fontWeight: 400,
    lineHeight: lineHeight.XS,
    iconSize: fontSize.MD,
  },
  sm36: {
    padding: "8px 10px 8px 0px",
    fontSize: fontSize.SM,
    fontWeight: 400,
    lineHeight: lineHeight.SM,
    iconSize: fontSize.MD,
  },
  md40: {
    padding: "10px 10px 10px 0px",
    fontSize: fontSize.SM,
    fontWeight: 400,
    lineHeight: lineHeight.SM,
    iconSize: fontSize.MD,
  },
  lg44: {
    padding: "10px 10px 10px 0px",
    fontSize: fontSize.MD,
    fontWeight: 400,
    lineHeight: lineHeight.MD,
    iconSize: fontSize.XL,
  },
  default: {
    backgroundColor: BASE.WHITE,
    border: `1px solid ${BORDER.HOVER}`,
  },
  focused: {
    backgroundColor: BASE.WHITE,
    border: `1px solid ${GREY.PRIMARY}`,
    boxShadow: SHADOW.input,
  },
  filled: {
    boxShadow: SHADOW.md,
    backgroundColor: BASE.WHITE,
    border: `1px solid ${BORDER.DARK}`,
  },
  error: {
    boxShadow: "",
    backgroundColor: BASE.WHITE,
    border: `1px solid ${ERROR.W_500}`,
  },
  disabled: {
    backgroundColor: BORDER.DISABLED,
    border: `1px solid ${BASE.TRANSPARENT}`,
  },
};

const SearchField = ({
  size,
  LeftIcon = "search",
  RightIcon,
  variant,
  onClick,
  isColor,
  errormessage,
  disabled = false,
  LeftImage,
  RightImage,
  ...props
}) => {
  const [inputState, setInputState] = useState(variant || "default");

  const styles = {
    inputField: {
      width: "100%",
      border: "none",
      height: "100%",
      color: inputState === "disabled" ? `${GREY.DISABLED}`:`${GREY.SECONDARY}`,
      borderRadius: "6px",
      fontStyle: "normal",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontWeight: SIZE_MAPPING[size]?.fontWeight,
      fontSize: SIZE_MAPPING[size]?.fontSize,
      lineHeight: SIZE_MAPPING[size]?.lineHeight,
      padding: SIZE_MAPPING[size]?.padding,
      cursor: inputState === "disabled" && "not-allowed",
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      width: "100%",
    },
    iconStyle: {
      fontSize: `${SIZE_MAPPING[size]?.iconSize}`,
      color: disabled ? GREY.DISABLED : GREY.SECONDARY,
      marginTop: "1px",
    },
    iconStyle1: {
      fontSize: `${SIZE_MAPPING[size]?.iconSize}`,
      color: disabled ? GREY.DISABLED : isColor ? BRAND.PRIMARY : GREY.TERTIARY,
      marginTop: "1px",
    },
    xs34: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: "6px",
      padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
      boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
      border: SIZE_MAPPING[inputState]?.border,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
    },
    xs36: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: "6px",
      padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
      boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      border: SIZE_MAPPING[inputState]?.border,
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
    },
    sm36: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: "6px",
      padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
      boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      border: SIZE_MAPPING[inputState]?.border,
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
    },
    md40: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: "6px",
      padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
      boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
      border: SIZE_MAPPING[inputState]?.border,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
    },
    lg44: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: "6px",
      padding: RightIcon ? "0px 10px" : "0px 0px 0px 10px",
      boxShadow: inputState === "focused" && SIZE_MAPPING[inputState].boxShadow,
      border: SIZE_MAPPING[inputState]?.border,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
    },
  };

  useEffect(() => {
    setInputState(variant || "default");
  }, [variant]);

  const searchHandler = (variantVal, inputValue) => {
    if (variantVal === "blurred") {
      if (inputValue) {
        setInputState("filled");
      } else {
        setInputState(variant || "default");
      }
    } else {
      if (variant !== "error") {
        setInputState(variantVal);
      }
    }
  };

  let input = document.getElementById("inputField");

  return (
    <div style={styles.mainContainer}>
      <div
        style={styles[size]}
        className={classes[inputState]}
        onClick={onClick}
      >
        {LeftIcon && (
          <IconComponent iconLabel={LeftIcon} style={styles.iconStyle1} />
        )}
        {LeftImage && <Image src={LeftImage} />}
        <CustomTooltip
          title={input?.clientWidth < input?.scrollWidth ? props?.value : ""}
        >
          <input
            id="inputField"
            autoComplete="off"
            type={"text"}
            style={styles.inputField}
            onFocus={() => {
              searchHandler("focused");
            }}
            onBlur={(event) => {
              searchHandler("blurred", event.target.value);
            }}
            disabled={variant === "disabled"}
            {...props}
          />
        </CustomTooltip>
        {RightIcon && (
          <IconComponent
            iconLabel={RightIcon}
            style={styles.iconStyle}
            //   onClick={props?.onRightClick}
          />
        )}
        {RightImage && <Image src={RightImage} />}
      </div>
      {variant === "error" && errormessage && (
        <Typography
          label={errormessage}
          color={ERROR.W_500}
          size={"xs"}
          fontWeight={fontWeight.REGULAR}
        />
      )}
    </div>
  );
};

export default SearchField;

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in SearchField then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props Description:
// size :- This is used for size of input mentioned in Figma.
// LeftIcon :- Icon displayed on the left of input field.
// RightIcon :- Icon displayed on the right of input field.
// variant :- This will mainly used for only two states of input. And this two states are error and disabled state.
