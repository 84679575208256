import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { getRequest, postRequest } from "../../../Apis";
import {
  COUNTRY_LIST,
  CURRENCY_LIST,
  EMITTER_SUCCESS,
  TIMEZONE_LIST,
} from "../../../utils/constants";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import {
  nameValidation,
  urlValidation,
  zipcodeValidation,
} from "../../../utils/validations/inputValidations";
import { styles } from "../style";
import SuspenseImage from "../../../components/Image/SuspenseImage";
import {
  GREY_CIR,
  NEW_LOGIN_REACHIQ_LOGO,
  RED,
} from "../../../utils/constants/assets";
import { LOGIN_PATH } from "../../../utils/constants/routes";
import { logoutUser } from "../../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import ProfileForm from "./ProfileForm";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import Typography from "../../../components/Typography/Typography";
import { BRAND, GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import InputField from "../../../components/InputFields/InputField";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import { Mixpanel } from "../../../Apis/Mixpanel";

const CompanyForm = ({ activeStep, setActiveStep, isProfileSetting }) => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [idVal, setIdVal] = useState([]);
  let offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone_objChange = TIMEZONE_LIST?.filter(
    (item) => item?.id === offset
  );
  const [valueState, setValueState] = useState([
    timezone_objChange?.[0]?.label,
  ]);
  const [companyName, setCompanyName] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validations, setValidations] = useState({
    companyName: "",
    companyDomain: "",
    valueState: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });
  const [timezoneList, setTimezoneList] = useState(TIMEZONE_LIST);
  const [countryList, setCountrylist] = useState(COUNTRY_LIST || []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getRequest(
          navigate,
          authEndpoints.accountGetDetails
        );
        setIsLoading(false);
        if (Object.keys(response?.data?.data || {})?.length) {
          setCompanyName(response?.data?.data?.company_name);
          setCompanyDomain(response?.data?.data?.company_website);
          setValueState([response?.data?.data?.timezone]);
          setIdVal([response?.data?.data?.timezone]);
          setAddressLine1(response?.data?.data?.address_line1);
          setAddressLine2(response?.data?.data?.address_line2);
          setCity(response?.data?.data?.city);
          setCountry([response?.data?.data?.country]);
          setCurrency([response?.data?.data?.default_currency]);
          setState(response?.data?.data?.state);
          setZipcode(response?.data?.data?.zipcode);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [navigate]);

  console.log({ city });
  const domainValueHandler = (url) => {
    return (
      "http://www." +
      url.replace("https://", "").replace("www.", "").replace("http://", "")
    );
  };

  const saveDetailsHandler = async () => {
    let domain = domainValueHandler(companyDomain);
    const companyNameValidation = nameValidation(companyName);
    const companyDomainValidation = urlValidation(domain);
    const timeZoneValidation = nameValidation(valueState);
    const addressLine1Validation = nameValidation(addressLine1);
    const cityValidation = nameValidation(city);
    const stateValidation = nameValidation(state);
    const zipcodeValidate = zipcodeValidation(zipcode);
    const countryValidation = nameValidation(country);
    const currencyValidation = nameValidation(currency);

    if (
      companyNameValidation.isValid &&
      companyDomainValidation.isValid &&
      timeZoneValidation.isValid &&
      addressLine1Validation.isValid &&
      cityValidation.isValid &&
      stateValidation.isValid &&
      zipcodeValidate.isValid &&
      countryValidation.isValid &&
      currencyValidation.isValid
    ) {
      const payload = {
        company_name: companyName,
        company_website: domain,
        timezone: idVal[0],
        address_line1: addressLine1,
        address_line2: addressLine2,
        city: city,
        state: state,
        zipcode: zipcode,
        country: country[0],
        default_currency: encodeURIComponent(currency[0]),
      };
      try {
        setIsLoading(true);
        const response = await postRequest(
          navigate,
          authEndpoints.createAccountSetting,
          payload
        );
        Mixpanel.track(STRINGS.ADMIN_DETAILS);

        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setIsLoading(false);
        setActiveStep(1);
      } catch (error) {
        const errors = error?.data?.data?.errors;
        setIsLoading(false);
        setValidations({
          companyName: errors?.company_name,
          companyDomain: errors?.company_website,
          timezone: errors?.valueState,
          addressLine1: errors?.address_line1,
          addressLine2: errors?.address_line2,
          city: errors?.city,
          state: errors?.state,
          zipcode: errors?.zipcode,
          country: errors?.country,
          currency: errors?.currency,
        });
      }
    } else {
      setValidations({
        companyName: companyNameValidation.message,
        companyDomain: companyDomainValidation.message,
        valueState: timeZoneValidation.message,
        addressLine1: addressLine1Validation.message,
        city: cityValidation.message,
        state: stateValidation.message,
        zipcode: zipcodeValidate.message,
        country: countryValidation.message,
        currency: currencyValidation.message,
      });
    }
  };
  const logoutHandler = async () => {
    dispatch(logoutUser());
    navigate(LOGIN_PATH);
  };
  const steps = [
    {
      label: "Account Settings",
      component: (
        <CompanyForm activeStep={activeStep} setActiveStep={setActiveStep} />
      ),
    },
    {
      label: "User Profile",
      component: (
        <ProfileForm activeStep={activeStep} setActiveStep={setActiveStep} />
      ),
    },
  ];
  const searchHandler = (e, property) => {
    let countryValue = countryList;
    let TimeoneValue = timezoneList;

    if (property === "countrylist") {
      let data = countryValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setCountrylist(COUNTRY_LIST);
      } else {
        setCountrylist(data);
      }
    } else if (property === "timezoneList") {
      let data = TimeoneValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setTimezoneList(TIMEZONE_LIST);
      } else {
        setTimezoneList(data);
      }
    }
  };
  return (
    <Box
      className={
        isProfileSetting
          ? classes.isProfileSettingFormMainContainer
          : classes.profileFormMainContainer
      }
    >
      <Box
        className={
          isProfileSetting
            ? classes.isProfileSettingFormContainer
            : classes.profileFormContainer
        }
      >
        {location.pathname === "/onboarding-form" ? (
          <Box className={classes.intitalFormHeader}>
            <SuspenseImage
              className={classes.logoIcon}
              src={NEW_LOGIN_REACHIQ_LOGO}
            />
            <Box className={classes.initialFormSteps}>
              {/* Account setting option */}
              <Box
                className={
                  activeStep === 1
                    ? classes.stepLabelCompletedContainer
                    : classes.stepLabelActiveContainer
                }
              >
                {activeStep === 0 && (
                  <SuspenseImage src={RED} className={classes.tickIcon} />
                )}

                <Typography
                  size={"md"}
                  color={activeStep === 0 ? BRAND.PRIMARY : GREY.SECONDARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={steps[0].label}
                />
              </Box>
              <hr className={classes.horizontalLine} />
              {/* User Profile option */}
              <Box
                className={
                  activeStep === 1
                    ? classes.stepLabelActiveContainer
                    : classes.stepLabelNotActiveContainer
                }
              >
                {activeStep === 0 && (
                  <SuspenseImage
                    src={GREY_CIR}
                    className={classes.tickIconred}
                  />
                )}

                <Typography
                  size={"md"}
                  color={activeStep === 0 ? GREY.TERTIARY : GREY.TERTIARY}
                  fontWeight={fontWeight.REGULAR}
                  label={steps[1].label}
                />
              </Box>
            </Box>
            <Box onClick={logoutHandler} className={classes.logoutContainer}>
              <Button
                LeftIcon={ICON_LABELS.LOGOUTS}
                label={STRINGS.LOGOUT}
                size={"sm36"}
                variant={"ghost"}
                onClick={logoutHandler}
              />
            </Box>
          </Box>
        ) : (
          ""
        )}
        {!location.pathname === "/onboarding-form" ? (
          ""
        ) : (
          <Box className={classes.SubHeader}>
            <Typography
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.ACCOUNT_DETAILS}
            />
          </Box>
        )}
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COMPANY_NAME}
              />
              <InputField
                placeholder={STRINGS.Enter_Comp}
                size={"md40"}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  setValidations({
                    ...validations,
                    companyName: "",
                  });
                }}
                variant={
                  validations?.companyName ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.companyName?.length !== 0
                    ? validations?.companyName
                    : ""
                }
              />
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COMPANY_WEB}
              />
              <InputField
                placeholder={STRINGS.ENTER_WEB}
                size={"md40"}
                value={companyDomain}
                onChange={(e) => {
                  setCompanyDomain(e?.target?.value);
                  setValidations({
                    ...validations,
                    companyDomain: "",
                  });
                }}
                variant={
                  validations?.companyDomain ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.companyDomain?.length !== 0
                    ? validations?.companyDomain
                    : ""
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.TIMEZONED}
              />
              <Box>
                <AutoCompleteCustom
                  size={"md40"}
                  LeftIcon=""
                  papermenuclass={classes.papermenuclass}
                  items={timezoneList}
                  valueState={valueState}
                  setValueState={setValueState}
                  idState={idVal}
                  setIdState={setIdVal}
                  variant={
                    validations?.valueState ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations?.valueState?.length !== 0
                      ? validations?.valueState
                      : ""
                  }
                  isSearchEnable={true}
                  handleSearch={(event) => {
                    searchHandler(event, "timezoneList");
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.ADDRESS_LINE1}
              />
              <InputField
                placeholder={STRINGS.Enter_ADd}
                size={"md40"}
                value={addressLine1}
                onChange={(e) => {
                  setAddressLine1(e?.target?.value);
                  setValidations({
                    ...validations,
                    addressLine1: "",
                  });
                }}
                variant={
                  validations?.addressLine1 ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.addressLine1?.length !== 0
                    ? validations?.addressLine1
                    : ""
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.ADDRESS_LINE2}
              />
              <InputField
                placeholder={STRINGS.Enter_add1}
                size={"md40"}
                value={addressLine2}
                onChange={(e) => {
                  setAddressLine2(e?.target?.value);
                  setValidations({
                    ...validations,
                    addressLine2: "",
                  });
                }}
              />
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.CITY}
              />
              <InputField
                placeholder={STRINGS.Enter_CITY}
                size={"md40"}
                value={city}
                onChange={(e) => {
                  setCity(e?.target?.value);
                  setValidations({
                    ...validations,
                    city: "",
                  });
                }}
                variant={validations.city ? STRINGS.ERROR : STRINGS.DEFAULT}
                errormessage={
                  validations?.city?.length !== 0 ? validations?.city : ""
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.STATE}
              />
              <InputField
                placeholder={STRINGS.Enter_State}
                size={"md40"}
                value={state}
                onChange={(e) => {
                  setState(e?.target?.value);
                  setValidations({
                    ...validations,
                    state: "",
                  });
                }}
                variant={validations?.state ? STRINGS.ERROR : STRINGS.DEFAULT}
                errormessage={
                  validations?.state?.length !== 0 ? validations?.state : ""
                }
              />
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.POSTAL}
              />
              <InputField
                placeholder={STRINGS.Enter_Postal}
                size={"md40"}
                type="number"
                value={zipcode}
                onChange={(e) => {
                  setZipcode(e?.target?.value);
                  setValidations({
                    ...validations,
                    zipcode: "",
                  });
                }}
                variant={validations?.zipcode ? STRINGS.ERROR : STRINGS.DEFAULT}
                errormessage={
                  validations?.zipcode?.length !== 0 ? validations?.zipcode : ""
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COUNTRY}
              />
              <Box>
                <AutoCompleteCustom
                  size={"md40"}
                  LeftIcon=""
                  items={countryList}
                  valueState={country}
                  setValueState={setCountry}
                  papermenuclass={classes.papermenuclass}
                  idState={country}
                  setIdState={setCountry}
                  variant={
                    validations?.country ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations?.country?.length !== 0
                      ? validations?.country
                      : ""
                  }
                  isSearchEnable={true}
                  handleSearch={(event) => {
                    searchHandler(event, "countrylist");
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.CURRENCY}
              />
              <Box>
                <AutoCompleteCustom
                  size={"md40"}
                  LeftIcon=""
                  items={CURRENCY_LIST}
                  valueState={currency}
                  setValueState={setCurrency}
                  papermenuclass={classes.papermenuclass}
                  idState={currency}
                  setIdState={setCurrency}
                  variant={
                    validations?.currency ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations?.currency?.length !== 0
                      ? validations?.currency
                      : ""
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.MarginTOp}>
          {" "}
          <Button
            label={STRINGS.SAVE_DETAILS}
            size={"sm36"}
            variant={"primary"}
            onClick={saveDetailsHandler}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyForm;
