import React, { useState } from 'react';
import AvatarName from '../Avatar/AvatarName';
import AvatarOutlined from '../Avatar/AvatarOutlined';
import { createCompanyURL, getContactHeading } from '../../utils/helpers';
import Image from '../Image/Image';
import { DUMMY_COMPANY_IMAGE, DUMMY_CONTACT2 } from '../../utils/constants/assets';

const AvatarNameWithLink = ({ row }) => {

    const [error, setError] = useState(false);

    return error ? <>
        {row?.first_name || row?.last_name ? <AvatarName
            name={getContactHeading(row)}
            size={"small"}
        /> : <AvatarOutlined size={"small"}>
            <Image src={DUMMY_CONTACT2} alt="" style={{
                height: "18px",
                width: "18px"
            }} />
        </AvatarOutlined>}
    </>
        :
        <AvatarOutlined>
            <Image
                src={createCompanyURL(row["company_website"] || DUMMY_COMPANY_IMAGE)}
                style={{
                    height: "18px",
                    width: "18px"
                }}
                onError={() => setError(true)}
                alt=""
            />
        </AvatarOutlined>

}

export default AvatarNameWithLink;