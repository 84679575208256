import React, { useState } from "react";
import { STRINGS } from "./strings";
import { Box } from "@mui/material";
import CustomModal from "./CustomModal";
import { fontWeight } from "../../utils/constants/UI";
import { ACTIONS, BORDER, GREY } from "../../utils/constants/colors";
import TypographyInline from "../Typography/TypographyInline";
import Image from "../Image/Image";
import { OOPS_IMAGE } from "../../utils/constants/assets";
import { Button } from "../Buttons";
import { authEndpoints } from "../../utils/constants/httpConstants";
import { postRequest } from "../../Apis";
import { useNavigate } from "react-router-dom";
import { EMITTER_ERROR } from "../../utils/constants";
import EventEmitter from "../../utils/emitter/EventEmitter";

const styles = {

    bodyContainerView: {
        gap: "8px",
        display: "flex",
        justifyContent: " center",
        alignItems: "center",
        flexDirection: "column",
        borderBottom: `1px solid ${ACTIONS.DEFAULT}`

    },
    BodyCredits: {
        paddingBottom: "1rem"
    },
    BUTTON: {
        display: "block",
        margin: "auto",
        width: "fit-content",
        paddingTop: "1rem"
    }
}
const CreditsForRefferalModal = ({ open, close }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleChange = () => {
        close(!open)
    }

    const handleRequestForCredits = async () => {

        try {
            setLoading(true)
            await postRequest(navigate, authEndpoints.RequestForCredits);
            setLoading(false)
        } catch (error) {
            EventEmitter.emit(
                EMITTER_ERROR,
                error?.data?.message || STRINGS.ERR_MESSAGE
            );
            setLoading(false)
            close(!open)

        } finally {

            close(!open)
        }



    }
    const modalHeaderData = {
        heading: {
            id: STRINGS.HEAD,
            label: STRINGS.GET_MORE_CREDITS,
        },
    };
    const modalBodyData = (
        <Box>
            <Box style={styles.bodyContainerView} >

                <Image src={OOPS_IMAGE} />

                <TypographyInline
                    size={"lg"}
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.BOLD}
                    label={STRINGS.CREDIT_CONTENT}
                />
                <Box style={styles.BodyCredits}>
                    <TypographyInline
                        size={"sm"}
                        color={GREY.SECONDARY}
                        fontWeight={fontWeight.REGULAR}
                        label={STRINGS.REACH_FOR_CREDITS}
                    /></Box></Box>

            <Box style={styles.BUTTON}><Button
                label={STRINGS.REQUEST_FOR_MORE_CREDITS}
                size={"sm36"}
                variant={"primary"}
                onClick={handleRequestForCredits}
                isLoading={loading}
            /></Box>
        </Box>
    );




    return (
        <>
            <CustomModal
                width={"29.583vw"}
                close={handleChange}
                open={open}
                header={modalHeaderData}
                children={modalBodyData}

            />

        </>
    )
}


export default CreditsForRefferalModal