import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  ChooseStepModal,
  CreateAutomatedEmail,
  CreateCall,
  CreateTodo,
} from "./components/Steps";
import { useNavigate, useParams } from "react-router-dom";
import { sequenceEndPoints } from "../../utils/constants/httpConstants";
import {
  EMITTER_SUCCESS,
  STEP_TYPE_COMPONENTS,
  SEQUENCE_STATS_DATA,
  EMITTER_ERROR,
} from "../../utils/constants";
import { DeleteConfirmationModal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getAllSteps } from "../../redux/actions/stepsActions";
import { getRequest, postRequest } from "../../Apis";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { getTemplateList } from "../../redux/actions/templateListActions";
import { STRINGS } from "./strings";
import { SEQUENCE_PATH } from "../../utils/constants/routes";
import CustomHeader from "../../components/Header/CustomHeader";
import TypographyInline from "../../components/Typography/TypographyInline";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import { GREY } from "../../utils/constants/colors";
import { processStatsKeyValuePairs } from "../../utils/helpers";
import StatsSkeleton from "./components/common/StatsSkeleton";
import StepsSkeleton from "./components/common/StepsSkeleton";
import EachStepLayout from "./components/common/EachStepLayout";
import { Button } from "../../components/Buttons";
import classes from "./marketing.module.css";

const StepsScreen = () => {
  const params = useParams();
  const sequenceId = params.id;
  const folderId = params.folderId;
  const URL =
    sequenceEndPoints.deleteStep + "?" + STRINGS.SEQUENCE_ID + "=" + params?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepsSelector = useSelector((state) => state?.getAllSteps);
  const { payload } = stepsSelector;
  const [autoModal, setAutoModal] = useState(null);
  const [callModal, setCallModal] = useState(null);
  const [todoModal, setTodoModal] = useState(null);
  const [opened, setOpened] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteEndpoint, setDeleteEndpoint] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const [automatedOpen, setAutomatedOpen] = useState(false);
  const [callOpen, setCallOpen] = useState(false);
  const [todoOpen, setTodoOpen] = useState(false);
  const [reSubject, setReSubject] = useState("");
  const [modStyl, setModStyl] = useState(false);
  const [delName, setDelName] = useState("");
  const [type, setType] = useState("");
  const [seqLoading, seteSeqLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const [sequenceStats, setSequenceStats] = useState({});
  const [isFolderFetched, setIsFolderFetched] = useState(false);
  const [headerItems, setHeaderItems] = useState([
    { id: STRINGS.TYPE_SEQ, label: STRINGS.TYPE_SEQ, path: SEQUENCE_PATH },
  ]);
  // this useEffect is for stats api call.
  useEffect(() => {
    (async () => {
      try {
        setStatsLoading(true);
        const response = await postRequest(
          navigate,
          sequenceEndPoints.statsSteps,
          { sequence_ids: [sequenceId] }
        );
        setSequenceStats(
          processStatsKeyValuePairs(response?.data?.records?.[sequenceId] || {})
        );
      } catch (error) {
      } finally {
        setStatsLoading(false);
        setOpened(false)
      }
    })();
  }, [navigate, sequenceId]);

  // this useEffect is for stepsList api call and folder name header breadCrumb with condition.
  useEffect(() => {
    (async () => {
      try {
        seteSeqLoading(true);
        if (folderId && !isFolderFetched) {
          const get_folder_details_payload = await getRequest(
            navigate,
            `${sequenceEndPoints.getFolderDetails}/${folderId}`
          );
          const new_header_items = [
            ...headerItems,
            {
              id: folderId,
              label: get_folder_details_payload?.data?.record?.name || "",
              path: `${SEQUENCE_PATH}/${folderId}`,
            },
          ];

          setHeaderItems(new_header_items);
          // condition to not add new header item on every re-render.

          await dispatch(
            getAllSteps(
              sequenceEndPoints.validateSequenceSteps + sequenceId,
              navigate
            )
          );
          setIsFolderFetched(true);
        } else {
          await dispatch(
            getAllSteps(
              sequenceEndPoints.validateSequenceSteps + sequenceId,
              navigate
            )
          );
        }
      } catch (error) {
      } finally {
        seteSeqLoading(false);
      }
    })();
  }, [dispatch, navigate]);

  // This useEffect is for setting sequence name header .
  useEffect(() => {
    if (folderId && headerItems?.length <= 2 && stepsSelector?.payload?.data) {
      setHeaderItems([
        ...headerItems,
        { id: sequenceId, label: stepsSelector?.payload?.data?.name },
      ]);
    } else if (headerItems?.length && stepsSelector?.payload?.data) {
      setHeaderItems([
        { id: STRINGS.TYPE_SEQ, label: STRINGS.TYPE_SEQ, path: SEQUENCE_PATH },
        { id: sequenceId, label: stepsSelector?.payload?.data?.name },
      ]);
    }
    setOpened(false)
  }, [stepsSelector, folderId, sequenceId]);


  const handleClone = (step, ind) => {
    if (step?.step_type === 1) {
      dispatch(getTemplateList({ name: "", type: STRINGS.FILE }, navigate));
      setAutoModal(step);
      setAutomatedOpen(true);
      setType(STRINGS.CLONE);
      setDelName(ind + 1);
      setReSubject(step?.step_data?.subject);
    }
    if (step?.step_type === 2) {
      setTodoModal(step);
      setTodoOpen(true);
      setType(STRINGS.CLONE);
      setDelName(ind + 1);
    }
    if (step?.step_type === 3) {
      setCallModal(step);
      setCallOpen(true);
      setType(STRINGS.CLONE);
      setDelName(ind + 1);
    }
  };

  const handleDelete = (step, ind) => {
    setShowDeleteModal(step);
    setDelName(`${STRINGS.STEP} ${ind + 1}`);
    setDeleteEndpoint(URL + `&${STRINGS.STEP_ID}=${step?._id}`);
  };

  const handleEdit = (step, ind) => {
    if (step?.step_type === 1) {
      dispatch(getTemplateList({ name: "", type: STRINGS.FILE }, navigate));
      setAutoModal(step);
      setAutomatedOpen(true);
      setType(STRINGS.EDIT);
      setDelName(ind + 1);
      setReSubject(step?.step_data?.subject);
    }
    if (step?.step_type === 2) {
      setTodoModal(step);
      setTodoOpen(true);
      setType(STRINGS.EDIT);
      setDelName(ind + 1);
    }
    if (step?.step_type === 3) {
      setCallModal(step);
      setCallOpen(true);
      setType(STRINGS.EDIT);
      setDelName(ind + 1);
    }
  };

  const swapSteps = async (ind) => {
    let secondaryData;
    if (payload?.data?.seq_steps[ind + 1]?._id) {
      secondaryData = payload?.data?.seq_steps[ind + 1]?._id;
    } else {
      secondaryData = payload?.data?.seq_steps[ind]?._id;
    }
    try {
      const payload1 = {
        primary: payload?.data?.seq_steps[ind]?._id,
        secondary: secondaryData,
      };
      const response = await postRequest(
        navigate,
        sequenceEndPoints.moveStepDown,
        payload1
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      await dispatch(
        getAllSteps(
          sequenceEndPoints.validateSequenceSteps + sequenceId,
          navigate
        )
      );
    } catch (e) {
      EventEmitter.emit(EMITTER_ERROR, e?.data?.message);
    }
  };

  const swapStepsUp = async (ind) => {
    let secondaryData;
    if (ind === 0) {
      secondaryData = payload?.data?.seq_steps[ind]?._id;
    } else {
      secondaryData = payload?.data?.seq_steps[ind - 1]?._id;
    }
    try {
      const payload1 = {
        primary: payload?.data?.seq_steps[ind]?._id,
        secondary: secondaryData,
      };
      const response = await postRequest(
        navigate,
        sequenceEndPoints.moveStepDown,
        payload1
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      await dispatch(
        getAllSteps(
          sequenceEndPoints.validateSequenceSteps + sequenceId,
          navigate
        )
      );
    } catch (e) { }
  };

  const handleClose = () => {
    setAutoModal(false);
    setCallModal(false);
    setTodoModal(false);
  };

  const STEP_ACTIONS = [
    {
      name: STRINGS.COMMON_CLONE,
      icon: ICON_LABELS.CONTENT_COPY_1,

      action: handleClone,
    },
    {
      name: STRINGS.COMMON_EDIT,
      icon: ICON_LABELS.EDIT,
      action: handleEdit,
    },
    {
      name: STRINGS.DELETE,
      icon: ICON_LABELS.DELETE,
      action: handleDelete,
    },
  ];

  const handleAddStep = (index, step) => {
    setModStyl(true);
    setOpened(true);
    setStepNumber(index + 1 + 1);
    setReSubject(step?.step_data?.subject);
  };

  return (
    <Box className={classes.stepsContainer}>
      {/* Steps Screen Header */}
      <CustomHeader items={headerItems} />
      {/* Steps Screen Header */}

      {/* Choosing steps type modal */}
      {opened &&
        <ChooseStepModal
          open={opened}
          setOpen={setOpened}
          reSubject={reSubject}
          stepNumber={stepNumber || 1}
          custStyle={modStyl}
        />
      }

      {automatedOpen && (
        <CreateAutomatedEmail
          handleClose={handleClose}
          data={autoModal}
          open={automatedOpen}
          setOpen={setAutomatedOpen}
          editType={type}
          number={delName}
          stepLength={payload?.data?.seq_steps?.length}
          reSubject={reSubject}
        />
      )}
      {callOpen && (
        <CreateCall
          handleClose={handleClose}
          data={callModal}
          open={callOpen}
          setOpen={setCallOpen}
          editType={type}
          number={delName}
          stepLength={payload?.data?.seq_steps?.length}
        />
      )}

      {todoOpen && (
        <CreateTodo
          handleClose={handleClose}
          data={todoModal}
          open={todoOpen}
          setOpen={setTodoOpen}
          editType={type}
          number={delName}
          stepLength={payload?.data?.seq_steps?.length}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          message={STRINGS.DELETE_STEP_CONFIRMATION}
          name={delName}
          val={STRINGS.STEP}
          // make endpoint here and pass as prop
          action={getAllSteps}
          endPoint={deleteEndpoint}
          reFetchEndpoint={sequenceEndPoints.validateSequenceSteps + sequenceId}
        />
      )}

      {/* Stats Container */}

      <Box className={classes.statsParentContainer}>
        {
          <Box className={classes.statsContainer} id="modal_body">
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.STATISTICS}
              className={classes.statsTitle}
            />
            <Box className={classes.pureFlex2}>
              {statsLoading ? (
                <StatsSkeleton />
              ) : (
                SEQUENCE_STATS_DATA?.map((eachStat, index) => {
                  return (
                    <Box
                      className={
                        index === SEQUENCE_STATS_DATA.length - 1
                          ? classes.lastStat
                          : classes.eachStat
                      }
                      key={eachStat.key}
                    >
                      <TypographyInline
                        size={"sm"}
                        color={GREY.PRIMARY}
                        fontWeight={fontWeight.MEDIUM}
                        label={sequenceStats?.[eachStat.key] || 0}
                      />
                      <TypographyInline
                        size={"sm"}
                        color={GREY.PRIMARY}
                        fontWeight={fontWeight.REGULAR}
                        label={eachStat.label || ""}
                      />
                    </Box>
                  );
                })
              )}
            </Box>
          </Box>
        }
      </Box>

      {/* Stats Container */}

      {/* Steps Container */}

      {seqLoading ? (
        <StepsSkeleton />
      ) : (
        <Box className={classes.stepsScrollContainer} id="modal_body">
          <Box className={classes.containAllSteps}>
            <Box className={classes.containOverviewSteps}>
              {payload?.data?.seq_steps?.map((step, index) => {
                return (
                  <Box>
                    <EachStepLayout
                      operation={payload?.data?.operation}
                      step={step}
                      data={payload?.data?.seq_steps}
                      stepIndex={index}
                      headerActions={STEP_ACTIONS}
                      stepDetail={{
                        stepNumber: step?.step_number,
                        stepType: step?.step_type,
                      }}
                      body={
                        step?.step_type === 1 ? (
                          <STEP_TYPE_COMPONENTS.Email
                            data={step?.step_data}
                            reSubject={reSubject}
                            stepNumber={stepNumber || 0}
                          />
                        ) : step?.step_type === 2 ? (
                          <STEP_TYPE_COMPONENTS.Todo data={step?.step_data} />
                        ) : step?.step_type === 3 ? (
                          <STEP_TYPE_COMPONENTS.Call data={step?.step_data} />
                        ) : null
                      }
                      footer={{
                        left:
                          index === 0
                            ? ""
                            : step?.interval > 1 ? `${step?.interval} ${STRINGS.DAYS_AFTER_STEP} ${index}` : `${step?.interval} ${STRINGS.DAY_AFTER_STEP} ${index}`,
                        right: (
                          <Box className={classes.eachStepDetail}>
                            {index !== 0 ? (
                              payload?.data?.operation ? (
                                <Button
                                  size={"sm36"}
                                  LeftIcon={ICON_LABELS.ARROW_UP}
                                  variant="text"
                                  onClick={() => swapStepsUp(index)}
                                  label={STRINGS.MOVEUP}
                                  disabled={!payload?.data?.operation}
                                />
                              ) : (
                                <Button
                                  size={"sm36"}
                                  LeftIcon={ICON_LABELS.ARROW_UP}
                                  variant="text"
                                  onClick={() => swapStepsUp(index)}
                                  label={STRINGS.MOVEUP}
                                  disabled={!payload?.data?.operation}
                                />
                              )
                            ) : (
                              ""
                            )}
                            {index !== 0 &&
                              payload?.data?.seq_steps[index + 1]?._id ? (
                              <span className={classes.commonSlash1}>|</span>
                            ) : (
                              ""
                            )}
                            {payload?.data?.seq_steps[index + 1]?._id ? (
                              payload?.data?.operation ? (
                                <Button
                                  size={"sm36"}
                                  LeftIcon={ICON_LABELS.ARROW_DOWN}
                                  variant="text"
                                  onClick={() => swapSteps(index)}
                                  label={STRINGS.MOVEDOWN}
                                  disabled={!payload?.data?.operation}
                                />
                              ) : (
                                <Button
                                  size={"sm36"}
                                  LeftIcon={ICON_LABELS.ARROW_DOWN}
                                  variant="text"
                                  onClick={() => swapSteps(index)}
                                  label={STRINGS.MOVEDOWN}
                                  disabled={!payload?.data?.operation}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        ),
                      }}
                    />
                    <Box className={classes.horizontalBorder}></Box>
                    {payload?.data?.operation ? (
                      <Box className={classes.addStepBtnContainer}>
                        <Button
                          onClick={() => handleAddStep(index, step)}
                          variant="ghost"
                          size="sm36"
                          label={STRINGS.ADD_STEP}
                        />
                      </Box>
                    ) : (
                      <Box className={classes.addStepBtnContainer}>
                        <Button
                          disabled={true}
                          variant="ghost"
                          size="sm36"
                          label={STRINGS.ADD_STEP}
                        />
                      </Box>
                    )}
                    <Box className={classes.horizontalBorder}></Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}

      {/* Steps Container */}
    </Box>
  );
};

export default StepsScreen;
