import {
  REGISTER_REQUEST,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CHECK_LOGIN_REQUEST,
  CHECK_LOGIN_SUCCESS,
  CHECK_LOGIN_FAIL,
  VERIFY_DOMAIN_REQUEST,
  VERIFY_DOMAIN_SUCCESS,
  VERIFY_DOMAIN_FAIL,
  RESET_PASSWORD_MAIL_SEND_REQUEST,
  RESET_PASSWORD_MAIL_SEND_SUCCESS,
  RESET_PASSWORD_MAIL_SEND_FAIL,
  INVITED_USERS_LIST_REQUEST,
  INVITED_USERS_LIST_SUCCESS,
  INVITED_USERS_LIST_FAIL,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  SIGNATURE_REQUEST,
  SIGNATURE_SUCCESS,
  SIGNATURE_FAIL,
  TENANT_USERS_LIST_REQUEST,
  TENANT_USERS_LIST_SUCCESS,
  TENANT_USERS_LIST_FAIL,
} from "../constants/authConstants";

export const registerReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return { loading: true };

    case REGISTER_SUCCESS:
      return { loading: false, payload: action.payload };

    case REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };

    case USER_DETAILS_SUCCESS:
      return { loading: false, payload: action.payload };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const getSignatureReducer = (state = {}, action) => {
  switch (action.type) {
    case SIGNATURE_REQUEST:
      return { loading: true };
    case SIGNATURE_SUCCESS:
      return { loading: false, payload: action.payload };
    case SIGNATURE_FAIL:
      return { laoding: false, error: action.payload };
    default:
      return state;
  }
};

export const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { loading: true };

    case LOGIN_SUCCESS:
      return { loading: false, payload: action.payload };

    case LOGIN_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const logoutReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
      return { loading: true };

    case LOGOUT_SUCCESS:
      return { loading: false, payload: action.payload };

    case LOGOUT_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const verifyDomainReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_DOMAIN_REQUEST:
      return { loading: true };

    case VERIFY_DOMAIN_SUCCESS:
      return { loading: false, payload: action.payload };

    case VERIFY_DOMAIN_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const checkLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_LOGIN_REQUEST:
      return { loading: true };

    case CHECK_LOGIN_SUCCESS:
      return { loading: false, payload: action.payload };

    case CHECK_LOGIN_FAIL:
      return { loading: false, error: action.payload, payload: null };

    default:
      return state;
  }
};

// Forgot password reducer

export const resetPasswordMailSendReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_MAIL_SEND_REQUEST:
      return { loading: true };

    case RESET_PASSWORD_MAIL_SEND_SUCCESS:
      return { loading: false, payload: action.payload };

    case RESET_PASSWORD_MAIL_SEND_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { loading: true };

    case RESET_PASSWORD_SUCCESS:
      return { loading: false, payload: action.payload };

    case RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
// Users invitation and users routes
export const invitedUsersListReducer = (state = {}, action) => {
  switch (action.type) {
    case INVITED_USERS_LIST_REQUEST:
      return { loading: true };

    case INVITED_USERS_LIST_SUCCESS:
      return { loading: false, payload: action.payload };

    case INVITED_USERS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const usersListReducer = (state = {}, action) => {
  switch (action.type) {
    case USERS_LIST_REQUEST:
      return { loading: true };

    case USERS_LIST_SUCCESS:
      return { loading: false, payload: action.payload };

    case USERS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const tenantUsersListReducer = (state = {}, action) => {
  switch (action.type) {
    case TENANT_USERS_LIST_REQUEST:
      return { loading: true };

    case TENANT_USERS_LIST_SUCCESS:
      return { loading: false, payload: action.payload };

    case TENANT_USERS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
