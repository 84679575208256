import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import * as qs from "qs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { filterQuery } from "../../../utils/helpers";
import { postRequest } from "../../../Apis";
import { contactListEndPoints } from "../../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../../utils/constants/routes";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import { ExportModal } from ".";
import CompanyEmployeesModal from "./CompanyEmployeesModal";
import CustomTable from "../../../components/Table/CustomTable";
import { STRINGS } from "../strings";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import FilterTab from "../../../components/FilterTab/FilterTab";
import CustomDialog from "../../../components/Dialog/CustomDialog";
import classes from "./LeadsComponent.module.css";

const CompanyTable = ({ heads, records, savedContactsTrue = false }) => {
  const navigate = useNavigate();

  const allFiltersSelector = useSelector((state) => state.allFilters);
  const tableDataSelector = useSelector((state) => state.tableDataFetch);

  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );
  const selector = useSelector((state) => state.ApplyUpdateColumn);
  const [selected, setSelected] = React.useState([]);
  const [showExportModal, setShowExportModal] = useState(false);
  const [columns] = useState(selector?.columns || heads);
  const [data, setData] = useState([]);
  const bulkSelected = false;
  const [employeesModal, setEmployeesModal] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [showUserDetailModal, setShowUserDetailModal] = useState(false);
  const [changedSearch, setChangedSearch] = useState("");

  const [selectedBulkValue, setSelectedBulkValue] = useState("");

  const queryObject = qs.parse(window.location?.search?.slice(1));

  const handleSaveToMyList = async (action) => {
    const payload = bulkSelected
      ? {
        ...filterQuery(allFiltersSelector?.payload),
        action_type: STRINGS.SAVE_LIST,
        list_id: STRINGS.MY_LIST,
      }
      : {
        record_ids: selected,
        action_type: STRINGS.SAVE_LIST,
        list_id: STRINGS.MY_LIST,
      };

    const URL = bulkSelected
      ? contactListEndPoints.companybulkSaveToList
      : contactListEndPoints.companySaveToList;
    try {
      const response = await postRequest(navigate, URL, payload);
      EventEmitter.emit(EMITTER_SUCCESS, response.data?.message || "");
    } catch (err) {
      EventEmitter.emit(EMITTER_ERROR, err?.data?.message || STRINGS.SESSION);
    }
  };

  useEffect(() => {
    setData(records);
  }, [records]);

  useEffect(() => {
    if (changedSearch !== queryObject?.search) {
      setChangedSearch(queryObject?.search);
    }
  }, [queryObject, changedSearch]);

  useEffect(() => {
    if (paginationDataSelector?.data?.checkedRow) {
      setSelected(paginationDataSelector?.data?.checkedRow);
      setSelectedBulkValue("");
    } else {
      setSelected([]);
    }
  }, [paginationDataSelector?.data?.checkedRow]);

  const handleUnlockEmail = async (row) => {
    try {
      const response = await postRequest(
        navigate,
        contactListEndPoints.singleEmailUnlock,
        { record_id: row?._id }
      );
      const newData = JSON.parse(JSON.stringify(data));
      const updatedRecord = newData?.filter((eachRecord) => {
        return eachRecord?._id === row?._id;
      })[0];
      updatedRecord.email = response?.data?.data?.email;
      const result = newData?.map((eachRecord) => {
        return eachRecord?._id === row?._id ? updatedRecord : eachRecord;
      });
      setData(result);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNavigateBack = (record) => {
    setCompanyName(record.company_name);
    setCompanyId(record._id);
    setEmployeesModal(true);
  };

  const tableData = {
    heads: heads?.filter((item) => item?.isChecked && item?.companyField),
    body: records,
    isSticky: true,
    total_records:
      tableDataSelector?.totalRecords > 1000
        ? 1000
        : tableDataSelector?.totalRecords,
    isLoading: tableDataSelector?.loading,
    header_strip_selected_value: selectedBulkValue,
    actions: {
      email: {
        function: (row) => handleUnlockEmail(row),
      },
      company_info: {
        function: (row, head, index) => {
          handleNavigateBack(row);
        },
      },
    },
  };

  const headerStrip = [
    !savedContactsTrue && (
      <FilterTab
        leftIcon={ICON_LABELS.FACT_CHECK}
        label={STRINGS.SAVE_COMPANIES}
        onClick={handleSaveToMyList}
      />
    ),
    <FilterTab
      leftIcon={ICON_LABELS.DOWNLOAD_FOR_ONLINE}
      label={STRINGS.EXPORT}
      onClick={() => {
        setShowExportModal(true);
      }}
    />,
  ];

  const dialogHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: companyName,
    },
  };

  return (
    <Box className={classes.tableBox}>
      <ExportModal
        open={showExportModal}
        setOpen={setShowExportModal}
        bulkSelected={bulkSelected}
        record_ids={selected}
        columns={selector?.columns ? selector?.columns : columns}
        leadType={STRINGS.COMPANY_SINGLE}
      />
      {/* All Contact Label */}
      {selected?.length >= 1 ? (
        <Box className={classes.containAllContacts}>
          <TypographyInline
            label={`${selected.length} ${STRINGS.SELECTED_COMPANY}`}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={GREY.SECONDARY}
          />
        </Box>
      ) : null}
      {/* All Contact Label */}

      <div
        className={
          selected?.length >= 1
            ? classes.containTableSmall
            : classes.containTable
        }
      >
        <div className={classes.compTableContainer}>
          <CustomTable tableData={tableData} headerStrip={headerStrip} />
          <CustomDialog
            open={employeesModal}
            onClose={() => {
              setEmployeesModal(false);
              setShowUserDetailModal(false);
            }}
            header={dialogHeader}
            width={"30vw"}
            remove_padding
          >
            <CompanyEmployeesModal
              showUserDetailModal={showUserDetailModal}
              setShowUserDetailModal={setShowUserDetailModal}
              companyId={companyId}
              companyName={companyName}
              setEmployeesModal={setEmployeesModal}
              employeesModal={employeesModal}
              savedContactsTrue={savedContactsTrue}
            />
          </CustomDialog>
        </div>
      </div>
    </Box>
  );
};

export default React.memo(CompanyTable);
