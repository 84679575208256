export const STRINGS = {
  PIPELINE: "Pipelines",
  DELETE: "Delete",
  DEAL_DES:
    "Use deal pipelines to manage the way you track potential revenue over time  ",
  SELECT_PIPELINE: "Select a pipeline:",
  CANCEL: "Cancel",
  SELECT: "Select",
  ADD_LABEL: "Add Stage",
  EDITPIPELINE: "Edit a Pipeline",
  CREATEPIPELINE: "Create a Pipeline",
  ARE_YOU_SURE: "Are you sure want to delete this pipeline?",
  REQUIRED: "This is a required field",
  ENTER_PIPELINE: "Enter your pipeline name",
  STAGE_NAME: "Stage Name",
  DEAL_PROBABILITY: "Deal Probability(%)",
  UPDATE: "Update",
  CREATE: "Create",
  EMPTY_DESC: "Utilize deal pipelines to effectively monitor the progression ",
  EMPTY_DESC2: "of potential revenue throughout its lifecycle.  ",
  PIPELINE_NAME: "Pipeline name",
  ERROR: "error",
  DEFAULT: "default",
  EDIT: "edit",
  EDIT_LABEL: "Edit",
  DELETE_SMALL: "delete",
  OPPORTUNITY_IDENTIFIED: "Opportunity Identified",
  PROPOSAL_SHARED: "Proposal Shared",
  NEGOTIATING: "Negotiating",
  DEAL_WON: "Deal Won",
  DEAL_LOST: "Deal Lost",
  STAGE_COLOR: "Stage Color",
  ACTION: "Action",
  ARE_YOU_SURE_DELETE:
    "Are you sure you want to delete this? This action cannot be undone.",
};
