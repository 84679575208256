import { Box } from '@mui/material';
import React from 'react';
import TypographyInline from '../../../../components/Typography/TypographyInline';
import { fontWeight } from '../../../../utils/constants/UI';
import classes from "../markComponents.module.css";
const StatusChip = ({color, label}) => {


    return <Box className={ classes.chip } >
        <Box sx={{background:color}} className={ classes.dot } ></Box>
        <TypographyInline
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
            color={color}
            label={ label }
        />
    </Box>
}

export default StatusChip;