import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import { SCROLL_BAR, borderRadius } from "../../utils/constants/UI/uiConstants";
import { BASE, BORDER } from "../../utils/constants/colors";
import { innerBorder } from "../../utils/helpers";

export const styles = {
  commonWidth: {
    width: "21vw",

  },

  container: {
    backgroundColor: "#f9fafb",
    padding: 0,
    height: "100vh",
  },
  sortingDropdownContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px"
  },
  containAllContacts: {
    background: "#FFE9E9",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    gap: "2px",
  },
  talbeActionsContainer: {
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 16px",
    backgroundColor: BASE.WHITE,
    borderTopLeftRadius: borderRadius.primary,
    borderTopRightRadius: borderRadius.primary,
    borderBottom: `1px solid ${BORDER.LIGHT}`
  },
  actionIconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    cursor: "pointer",
    boxShadow: innerBorder(BORDER.DARK),
    borderRadius: borderRadius.primary,
  },
  sortingModal: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    gap: "8px",
  },
  actionIconsDisabledContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    background: BORDER.DISABLED,
    boxShadow: innerBorder(BORDER.DISABLED),
    borderRadius: borderRadius.primary,
    cursor: "not-allowed",
  },
  GAP: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "100%"
  },
  searchImage: {
    width: "clamp(3rem, 6vw, 6rem)",
    objectFit: "contain",
    paddingBottom: "1rem",
  },
  containCommonTables: {
    // width: "calc(100vw - 32rem)",
    overflow: "auto",
    // border: "1px solid #d3dae3",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    height: "calc(100vh - 18rem)",
    ...SCROLL_BAR,
    flexDirection: "column",
    alignItems: "center",
  },
  containDashboardCommonTable: {
    height: "calc(100% - 47px)",
    overflow: "auto",
    // border: "1px solid #d3dae3",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    ...SCROLL_BAR,
    flexDirection: "column",
    alignItems: "center",
  },
  titled: {
    color: "#0F2F51",
    // fontWeight: "800",
    fontSize: "clamp(1.4rem, 1.7vw, 4.2rem)",
    // marginBottom: "1rem",
  },
  commonSpanProcess: {
    marginLeft: "8px",
    textAlign: "center",
    fontWeight: "400",
    fontSize: "clamp(0.7rem, 0.7vw, 2rem)",
    //
    /* font-size: clamp(12px, 0.729vw, 30px); */
    whitespace: "nowrap",
    color: "#626B7E ",
    cursor: "pointer",
    background: "#FFFFFF",
    padding: "4px 5px",
    border: "1px solid #D3DAE3 ",
    borderRadius: "6px",
    textTransform: "capitalize",
    /* width: 125px; */
  },
  flexx: {
    display: "flex",
    gap: "8px",
    // justifyContent: "space-between",
    // width: "100%",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  filterContain: {
    display: "flex",
    padding: "14px",
    width: "100%",
    gap: "8px",
    // boxShadow:SHADOW.sm,
    overflow: "auto",
  },
  filterText: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "14px",
    width: "100px",
    alignSelf: "center",
    textAlign: "center",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100% - 115px - 80px)",
    alignItems: "center",
    // paddingTop: "25vh",
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  contactsOverlay: {
    display: "flex",
    height: "3rem",
    background: "white",
    boxShadow: "0px 2px 4px #00000008",
    margin: "1rem 1rem 0 0",
    borderRadius: "6px 6px 0 0",
    borderBottom: "2px solid #ff4848",
    padding: "1rem",
    cursor: "pointer",
  },
  contactsText: {
    fontSize: "13px",
    color: "#626B7E",
    fontWeight: "400",
    whiteSpace: "nowrap",
    alignSelf: "center",
  },
  cleanBox: {
    padding: 0,
    margin: 0,
  },

  padding1rem: {
    padding: "1rem",
  },
  flexRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    textAlign: "center",
    fontSize: "clamp(1rem, 1vw, 3rem)",
    color: "#092f51",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    height: "calc(100vh - 15rem)",
  },
  containUploadBtn: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    height: "calc(100vh - 10rem)",
  },
  tableActionRightContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  fileInputWrapper: {
    fontSize: "100px",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
  },
  uploadBtn: {
    background: "#ff4848",
    padding: "1rem 4rem",
    border: "none",
    color: "white",
    borderRadius: "6px",
    cursor: "pointer",
  },
  uploadBtnWrapper: {
    alignSelf: "center",
    position: "relative",
  },
  eachColumn: {
    display: "flex",
    paddingBottom: "1.5rem",
    justifyContent: "space-between",
    margin: "0 20vw",

  },
  importModalColumnsContainer: {
    height: "calc(100vh - 6rem)",
    overflow: "auto",
    background: "white",
    padding: "1rem",
    margin: "1rem",
    ...TABLE_SCROLL_BAR,
  },
  containEachColumnName: {
    width: "15rem",
    display: "flex",
    alignItems: "center",
  },
  containCustomFields: {
    padding: "3vh 15vw",
  },
  tableContainer: {
    margin: "2rem 0",
  },
  importTitle: {
    fontSize: "clamp(1.3rem, 1.3vw, 4rem )",
    color: "#102f51",
    fontWeight: "500",
    textAlign: "center",
  },
  importPara: {
    color: "#102f51",
    fontSize: "clamp(1rem , 1vw, 2rem)",
    fontWeight: "600",
    textAlign: "center",
    padding: "2vw 0",
  },
  importPara1: {
    color: "#626B7E",
    fontSize: "clamp(0.8rem , 0.8vw, 2rem)",
    fontWeight: "500",
    textAlign: "center",
    padding: "1vw 0",
  },
  flexJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  containDropBox: {
    border: "1px dashed #d3dae3",
    padding: "2rem 1rem 0 1rem",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "100ms ease-in-out",
    // "&:hover": {
    //   border: "1px solid #ff4848",
    // },
  },
  blackListText: {
    color: "#102f51",
    fontSize: "clamp(1rem, 1vw, 3rem)",
    fontWeight: "500",
  },
  containBlacklist: {
    margin: "16px 0px 16px 16px",
    background: "white",
    borderRadius: "6px",
    height: "calc(100% - 32px)"
    // width: "calc(100vw - 33.5rem)",
  },
  eachOp: {
    border: "1px solid #d3dae3",
    padding: "1rem",
    borderRadius: "6px",
    margin: "1rem 0.5rem",
    cursor: "pointer",
  },
  containHeader: {
    padding: "1.5rem",
    background: "white",
  },
  divideinto2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  blackListBtn: {
    color: "#FF4848",
    background: "#ffe9e9",
    border: "none",
    borderRadius: "6px",
    padding: "0.5rem 1.5rem",
    fontSize: "clamp(0.8rem, 0.8vw, 3rem)",
    cursor: "pointer",
    maxWidth: "10vw",
  },
  ApplyBtn: {
    background: "#FF4848",
    color: "white",
    border: "none",
    borderRadius: "6px",
    padding: "0.5rem 1.5rem",
    fontSize: "clamp(0.8rem, 0.8vw, 3rem)",
    cursor: "pointer",
    maxWidth: "10vw",
  },

  eachBlacklistBlock: {
    display: "flex",
    justifyContent: "center",
    padding: "2rem",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #d3dae3",
    margin: "1rem",
    borderRadius: "6px",
  },
  icon: {
    color: "#626B7E",
  },
  header: {
    color: "#102F51",
    fontSize: "14px",
    fontWeight: "500",
    padding: "0px 16px 12px 16px",
    cursor: "pointer",
  },
  selectedHeader: {
    color: "#ff4848",
    padding: "0px 16px 12px 16px",
    borderBottom: "3px solid #ff4848",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
  },
  containHeads: {
    display: "flex",
    gap: "10px",
    borderBottom: "1px solid #d3dae3",
  },
  containTable: {
    padding: "1rem",
    height: "100%"
  },
  emptyContainTable: {
    height: "100%"
  },
  flexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  containTableHeads: {
    display: "grid",
    background: " #F3F5F7",
    padding: "1rem",
    borderRadius: "4px 4px 0 0",
    width: "fit-content",
  },

  containTableDashboardHeads: {
    display: "flex",
    justifyContent: "space-between",
    background: " #F3F5F7",
    padding: "1rem",
    borderRadius: "4px 4px 0 0",
    width: "100%",
    position: "sticky",
    top: "0px",
  },
  containTableHead: {
    background: "#F3F5F7",
  },
  tableHead: {
    color: "#0B1534",
    fontSize: "clamp(0.75rem, 0.75vw, 2rem)",
    fontWeight: "500",
  },
  eachTableRow: {
    display: "grid",
    padding: "1rem",
    background: "white",
  },
  eachTableRowDashboard: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    background: "white",
  },
  paperMenu: {
    height: "20vh",
    ...SCROLL_BAR,
    width: "13vw",
  },
  paperMenu2: {
    // height: "20vh",
    ...SCROLL_BAR,
    width: "15vw",
  },
  containCommonTable: {
    width: "calc(100vw - 35rem)",
    overflow: "auto",
    border: "1px solid #d3dae3",
    borderRadius: "6px",
    maxHeight: "calc(100vh - 18rem)",
    ...SCROLL_BAR,
  },
  eachTableRecord: {
    color: "#626B7E",
    fontSize: "clamp(0.7rem, 0.7vw, 2rem)",
    whiteSpace: "nowrap",
    fontWeight: "400",
    // width:'10rem',
  },
  deleteBtn: {
    background: "#FFE9E9",
    color: "#ff4848",
    padding: "8px 1.5rem",
    border: "none",
    borderRadius: "6px",
    fontWeight: "400",
    fontSize: "clamp(0.75rem, 0.75vw, 2rem )",
  },
  eachBlock: {
    display: "flex",
    alignItems: "center",
  },
  dashboardEachBlock: {
    textAlign: "left",
  },
  cotnainEachHead: {
    display: "flex",
    // justifyContent:'center'
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "50vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },

  bigModal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "75vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },
  // flex:{
  //     display:'flex'
  // },
  containCrossIcon: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    alignItems: "center",
    cursor: "pointer",
    height: "2rem",
  },
  title1: {
    color: "#102f51",
    fontSize: "clamp(1.1rem, 1.1vw, 3rem)",
    fontWeight: "400",
    alignSelf: "center",
  },
  inputContainer: {
    width: "100%",
  },
  input: {
    width: "100% !important",
    padding: "1rem !important",
  },
  input1: {
    width: "30vw !important",
    padding: "1.5rem 1rem !important",
  },
  listBox: {
    width: "83%",
  },
  marginTop: {
    marginTop: "1rem",
  },
  inviteBtn: {
    background: "#ff4848",
    border: "none",
    color: "white",
    width: "10vw",
    padding: "1rem 2.5rem",
    marginTop: "1rem",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
  },
  redBackgroundBtn: {
    background: "#ff4848",
    color: "white",
    fontSize: "clamp(0.9rem, 0.85vw. 3rem)",
    padding: "1rem 2rem",
    borderRadius: "6px",
    border: "none",
    marginTop: "1rem",
    cursor: "pointer",
  },
  containBlacklistModalItems: {
    padding: "1rem 1.5rem",
  },
  containEachInput: {
    padding: "1rem 0",
  },
  dialogModal: {
    paddingTop: "1rem",
    width: "100%",
  },
  searchContainer: {
    border: "1px solid #d3dae3",
    padding: "0.5rem 1rem",
    display: "flex",
    paddingLeft: "4px",
    borderRadius: "4px",
    width: "40%",
    alignItems: "flex-end",
  },
  searchBox: {
    border: "none",
    width: "100%",
    paddingLeft: "4px",
    fontSize: "clamp(0.75rem, 0.75vw, 3rem)",
  },

  filterLabel: {
    color: "#626B7E",
    fontSize: "clamp(0.75rem, 0.75vw, 2.5rem)",
    fontWeight: "500",
    display: "block",
  },
  filterinput: {
    padding: "8px 12px",
    fontSize: "clamp(0.75rem, 0.75vw, 2.5rem)",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    marginTop: "8px",
    width: "100%",
  },
  filterinputt: {
    fontSize: "clamp(0.75rem, 0.75vw, 2.5rem)",
    borderRadius: "4px",
    marginTop: "8px",
    width: "80%",
  },
  containAllFilter: {
    padding: "1rem",
    paddingTop: "0",
  },
  containEachFilter: {
    paddingTop: "1rem",
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  dashboardTableBody: {
    height: "calc(100% - 50px)",
    display: "flex",
    justifyContent: "center",
  },
  dashboardTableBody1: {
    height: "calc(100% - 50px)",
  },
  loaderContainer: {
    display: "flex", justifyContent: "center", minHeight: "60px", maxHeight: "60px", alignItems: "center"
  }
};
