import React from "react";
import { BORDER, GREY } from "../../utils/constants/colors";
import { fontSize, lineHeight } from "../../utils/constants/UI";
import IconComponent from "./IconComponent";
import classes from "./iconOutlineHover.module.css";
import CustomTooltip from "../Tooltip/CustomTooltip";

const SIZE_MAPPING = {
  XXL: {
    width: "80px",
    height: "80px",
    fontSize: fontSize.DSM,
    lineHeight: lineHeight.DSM,
  },
  extraLarge: {
    width: "64px",
    height: "64px",
    fontSize: fontSize.DXS,
    lineHeight: lineHeight.DXS,
  },
  huge: {
    width: "56px",
    height: "56px",
    fontSize: fontSize.XL,
    lineHeight: lineHeight.XL,
  },
  large: {
    width: "48px",
    height: "48px",
    fontSize: fontSize.XL,
    lineHeight: lineHeight.XL,
  },
  medium: {
    width: "40px",
    height: "40px",
    fontSize: fontSize.MD,
    lineHeight: lineHeight.MD,
  },
  small: {
    width: "32px",
    height: "32px",
    fontSize: fontSize.SM,
    lineHeight: lineHeight.SM,
  },
  tiny: {
    width: "24px",
    height: "24px",
    fontSize: fontSize.XS,
    lineHeight: lineHeight.XS,
  },
};
const IconOutlinedHover = ({
  iconLabel,
  color,
  size,
  iconName,
  transform = false,
  rounded = true,
  ...props
}) => {
  const styles = {
    orange: {
      color: GREY.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "9px",
      gap: "2px",
      backgroundColor: "transparent",
      borderRadius: "50%",
      cursor: "pointer",
      width: "100%",
      textTransform: "capitalize",

      border: `1px solid ${BORDER.LIGHT}`,

      transition: "0.200s ease-in-out",
    },

    purple: {
      color: GREY.SECONDARY,

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "9px",
      gap: "2px",
      backgroundColor: "transparent",
      borderRadius: "50%",
      cursor: "pointer",
      width: "100%",
      textTransform: "capitalize",

      border: `1px solid ${BORDER.LIGHT}`,

      transition: "0.200s ease-in-out",
    },
    yellow: {
      color: GREY.SECONDARY,

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "9px",

      gap: "2px",
      backgroundColor: "transparent",
      borderRadius: "50%",
      cursor: "pointer",
      width: "100%",
      textTransform: "capitalize",

      border: `1px solid ${BORDER.LIGHT}`,

      transition: "0.200s ease-in-out",
    },
    green: {
      color: GREY.SECONDARY,

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "9px",

      gap: "2px",
      backgroundColor: "transparent",
      borderRadius: "50%",
      cursor: "pointer",
      width: "100%",
      textTransform: "capitalize",

      border: `1px solid ${BORDER.LIGHT}`,

      transition: "0.200s ease-in-out",
    },
    blue: {
      color: GREY.SECONDARY,

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "9px",

      gap: "2px",
      backgroundColor: "transparent",
      borderRadius: "50%",
      cursor: "pointer",
      width: "100%",
      textTransform: "capitalize",

      border: `1px solid ${BORDER.LIGHT}`,

      transition: "0.200s ease-in-out",
    },
  };
  return (
      <CustomTooltip title={iconName}  variant='bottom' theme="dark">
      <div
      style={styles[props?.variant]}
      {...props}
      className={classes[props?.variant]}
    >
        <IconComponent
          iconLabel={iconLabel}
          color={props?.color}
          id="icon"
          fontSize={SIZE_MAPPING[size]?.fontSize}
        />
    </div>
    </CustomTooltip>
  );
};

export default IconOutlinedHover;

//Icon Colored component main keys are:-

// 1. Icon labels
// 2.variant - there are 5 types of variants are :- yellow,purple,blue,orange,green
// 3.color - there are 5 types of Iconcolors are :- yellow,purple,blue,orange,green
// 4.size - you can set the size of  Icon according to your requirement (XXL,extraLarge,huge,large,medium,small,tiny)
