import { Grid, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";

import {
  CircularLoader,
  CustomTextArea,
  ErrorMessage,
} from "..";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../Icon/MuiIcons";
import { Box } from "@mui/system";
import { postRequest } from "../../Apis";
import { useDispatch, useSelector } from "react-redux";
import { feedbackForm } from "../../utils/constants/httpConstants";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../utils/constants";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import ImageUploadedPreview from "./ImageUploadPreview";
import CustomModal from "./CustomModal";
import { STRINGS } from "./strings";
import { Button } from "../Buttons";

const styles = makeStyles({
  importPara: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "40vw",
    borderRadius: "12px",

    "&:focus-visible": {
      outline: "0",
    },
    "& .MuiInputBase-input": {
      padding: "0.859vw 0.729vw",
    },
    "& textArea": {
      height: "8.208vw  !important",
      resize: "none",
      overflowY: "auto",
      ...TABLE_SCROLL_BAR,
    },
  },
  icon: {
    color: "#626B7E",
    cursor: "pointer",
  },
  containCross: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  texth4: {
    color: "#212833",
    fontSize: "16px",
    fontWeight: "600",
  },
  button: {
    backgroundColor: "white",
    color: "#626B7E",
    fontSize: "14px",
    padding: "0.5rem 1.2rem",
    border: "1px solid #D3DAE3",
    borderRadius: "8px",
    // marginLeft: "0.6rem",
    cursor: "pointer",
    // boxShadow:"0px 9px 20px #ff16543c",
    fontWeight: "400",
    "&:hover": {
      border: "1px solid #FF4848",
      color: "#FF4848",
    },
  },
  imagebs: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#F7F9FB",
    border: "1px solid #D3DAE3",
    padding: "0.6rem",

    marginBottom: "10px",
  },
  para: {
    fontSize: "12px",
    fontWeight: "300",
    letterSpacing: "0.6px",
  },
  containText: {
    padding: "1rem",
  },
  containBtns: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem 0",
    paddingBottom: 0,
  },
  confirmTitle: {
    fontWeight: "400",
    letterSpacing: "0.6px",
    color: "#212833",
    paddingBottom: "0.4rem",
  },
  span: {
    alignSelf: "center",
    cursor: "pointer",
    fontSize: "14px",
    color: "#626B7E",
  },
  containModalHead: {
    display: "flex",
    justifyContent: "space-between",
    padding: " 1rem",
    background: "#D3DAE3",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
  createListTitle: {
    color: "#212833",
    fontSize: "20px",
    fontWeight: "600",
  },
  labelclass: {
    color: "#212833",
    fontSize: "14px",
    fontWeight: "500",
    marginBottom: "4px",
    display: "block",
  },
  btnfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
  },
  icon2: {
    color: "#f00",
    fontSize: "16px!important",
    marginLeft: "5px",
    cursor: "pointer",
  },
  cursor: {
    cursor: "pointer",
  },
  Border: {
    border: "1px solid #D3DAE3",
    padding: "1rem",
    borderRadius: "4px",
  },
});

const initialValues = {
  Device: "",
  Message: "",
};

const ContactFormModal = ({ open, setOpen }) => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const captchaRef = useRef(null);
  const [highLighted, setHighlighted] = useState(false);

  const [values, setValues] = useState(initialValues);
  const [attach, setAttach] = useState("");
  const [loader, setLoader] = useState(false);
  const [os, setOs] = useState("");
  const [device, setDevice] = useState("");
  const [imagebs, setImage] = useState("");

  const checkLoginSelector = useSelector((state) => state.checkLogin);

  const [click, setClick] = useState(false);

  const [error, setError] = useState({});
  const [size, setSize] = useState("");
  const [validations, setValidations] = useState({
    Message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];

    const imageType = {
      jpg: "jpg",
      jpeg: "jpeg",
      png: "png",
    };
    const extension = e.target.files[0]?.type.split("/")[1];

    console.log(!imageType[extension], "!imageType[extension]");
    if (!imageType[extension]) {
      return setSize("Please choose the image format to upload in JPG or PNG");
    }
    if (file?.size >= 500000) {
      setSize(
        "The file you are trying to upload exceeds the maximum allowed size of 500 KB."
      );
    } else {
      setSize("");

      setAttach(file?.name);
      const base64 = await convertBase64(file);
      setImage(base64);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = () => {
        reject(error);
      };
    });
  };

  const handleChange = async () => {
    setOpen(!open);
    setValues(initialValues);
    setAttach("");
    setImage("");
    setSize("");

    setValidations({
      Message: "",
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoader(true);
    const payload = {
      image: imagebs,
      device: device,
      message: values.Message,
    };

    if (values.Message !== "") {
      setValidations({
        Message: "",
      });

      try {
        const res = await postRequest(navigate, feedbackForm.feedback, payload);

        setLoader(false);
        setValues({
          Message: "",
        });
        setOpen(false);
        EventEmitter.emit(EMITTER_SUCCESS, res?.data?.message);

        setAttach("");
        setImage("");
        setSize("");
      } catch (error) {
        setError(error?.response?.data?.data?.errors);
        setLoader(false);
      }
    } else {
      setValidations({
        Message: "This is a required field",
      });
      setLoader(false);
    }
  };

  function osfunction() {
    let os = navigator.userAgent;
    let finalOs = "";
    if (os.search("Windows") !== -1) {
      finalOs = "Windows";
    } else if (os.search("Mac") !== -1) {
      finalOs = "MacOS";
    } else if (os.search("X11") !== -1 && !(os.search("Linux") !== -1)) {
      finalOs = "UNIX";
    } else if (os.search("Linux") !== -1 && os.search("X11") !== -1) {
      finalOs = "Linux";
    }

    setOs(finalOs);
  }
  function getdevice() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      setDevice("Mobile");
    } else {
      // false for not mobile device
      setDevice("Desktop");
    }
  }

  useEffect(() => {
    // dispatch(checkLogin())
    osfunction();
    getdevice();
  }, [dispatch]);

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEAD,
      label: STRINGS.REPORT,
    },
  };
  const modalBodyData = (
    <Box component="form" onSubmit={handleSubmit}>
      <div className={classes.containText}>
        <div container style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div item xs={12}>
            <label className={classes.labelclass}>Your Message*</label>
            <CustomTextArea
              placeholder="Write your message here..."
              value={values.Message}
              fullWidth
              sx={{ mb: 1 }}
              name="Message"
              onChange={handleInputChange}
            />

            {validations.Message && (
              <ErrorMessage message={validations.Message} />
            )}
          </div>
          <div item xs={12} style={{ position: "relative" }}>
            <label className={classes.labelclass}>Upload File</label>
            {imagebs ? (
              <Box className={classes.imagebs}>
                <Box sx={{ display: "flex", gap: "12px" }}>
                  {" "}
                  {imagebs ? (
                    <img
                      src={imagebs}
                      alt=""
                      width="30px"
                      height="30px"
                      className={classes.cursor}
                      onClick={() => setClick(true)}
                    />
                  ) : (
                    ""
                  )}{" "}
                  <Box
                    style={{
                      color: "#626b7e",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    {attach ? attach : ""}
                  </Box>{" "}
                </Box>
                <Box>
                  {imagebs ? (
                    <CloseIcon
                      fontSize="medium"
                      className={classes.icon2}
                      onClick={() => {
                        setAttach("");
                        setImage("");
                      }}
                    />
                  ) : (
                    ""
                  )}{" "}
                </Box>
              </Box>
            ) : (
              ""
            )}
            <div className={classes.Border}>
              <Box className={classes.btnfile}>
                <div className="contain-drag-drop">
                  <div
                    onDragEnter={() => {
                      setHighlighted(true);
                    }}
                    onDragLeave={() => {
                      setHighlighted(false);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={uploadImage}
                  >
                    {/* <span>Upload</span> */}
                    <div className="for-center">
                      <input
                        type="file"
                        accept="image/x-png,image/jpeg,image/jpg"
                        fullWidth
                        style={{
                          mb: 1,
                          opacity: "0",
                          position: "absolute",
                          zIndex: "999",
                          width: "18%",
                          height: "4%",

                          cursor: "pointer",
                          fontSize: "0px",
                        }}
                        name="attach"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <label
                        htmlFor="file-upload"
                        className="custom-file-upload"
                      >
                        <Box className={classes.containDropBox}>
                          <div className={classes.importPara}>
                            {" "}
                            <div style={{ display: "flex" }}>
                              <span
                                style={{
                                  color: "#FF4848",
                                  marginRight: "4px",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                Click to upload {""}
                              </span>{" "}
                              <p
                                style={{
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                or drag and drop PNG,{" "}
                              </p>
                            </div>
                            <div>
                              <p
                                style={{
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                JPG (max. 500kb)
                              </p>{" "}
                            </div>
                          </div>
                        </Box>
                      </label>
                      <input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        fullWidth
                        style={{
                          mb: 1,
                          opacity: "0",
                          position: "absolute",
                          zIndex: "999",
                          width: "20%",
                          height: "5%",
                          cursor: "pointer",
                          fontSize: "0px",
                        }}
                        name="attach"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Box>{" "}
            </div>
            {size && <ErrorMessage message={size} />}
          </div>
          {/* <Grid item xs={6}>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={onChangeCapcha}
        />
        {validations.captcha && (
          <ErrorMessage message={validations.captcha} />
        )}
      </Grid> */}
        </div>
      </div>
    </Box>
  );
  const modalFooterRightData = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={handleChange}
      />

      <Button
        isLoading={loader}
        label={STRINGS.SAVE}
        size={"sm36"}
        variant={"primary"}
        onClick={handleSubmit}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <Box>
      <CustomModal
        width={"35vw"}
        close={handleChange}
        open={open}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
        remove_padding
      />

      <Modal open={click}>
        <ImageUploadedPreview
          imagebs={imagebs}
          setClick={setClick}
          click={click}
        />
      </Modal>
    </Box>
  );
};

export default ContactFormModal;
