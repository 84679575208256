import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { GREY } from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import EmptyViewCard from "./EmptyViewCard";
import ManageViewCard from "./ManageViewCard";
import classes from "./CrmComp.module.css";
import { newLeadsEndPoints } from "../../../../utils/constants/httpConstants";
import { getRequest } from "../../../../Apis";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
import { getPeopleLeadDetails } from "../../../../redux/actions/peopleActions";
import { getCompanyBasicDetails } from "../../../../redux/actions/companyActions";
import {
  COMPANY_EMPLOYEES_COUNT,
  EMAIL_VERIFICATION_TYPES,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  SENIORIRT_TYPES,
  tempHeadCells,
} from "../../../../utils/constants";
import {
  applyUpdateColumn,
  updateColumnHeaders,
} from "../../../../redux/actions/tableActions";
import {
  HandleClearAllFilterFromStore,
  checkExistFields,
  checkExistFieldsCompanyName,
  getCheckEmployeeCount,
  getSelectedSeniority,
} from "../../../../redux/store/storeHelper";
import {
  filterHeaderIndustry,
  filterHeaderJobTitle,
  initialFilterPayload,
  stringQueryList,
} from "../../../../redux/store/storeConstants";
import { filterKeys } from "../../../../utils/constants/filters";
import {
  allFilters,
  filterHeaderCompanyCity,
  filterHeaderCompanyCountry,
  filterHeaderCompanyKeywords,
  filterHeaderCompanySeo,
  filterHeaderCompanyState,
  filterHeaderCompanyTechnologies,
  filterHeaderEmailVerification,
  filterHeaderPersonCity,
  filterHeaderPersonCountry,
  filterHeaderPersonState,
  filterHeaderSearchCompany,
  filterHeaderSearchIndustry,
  filterHeaderSearchJobTitle,
  filterHeaderSearchSeniority,
} from "../../../../redux/actions/filterActions";
import { getSalesContactsList } from "../../../../redux/actions/salesTableAction";
import { createSearchParams } from "react-router-dom";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import CustomStyledMenu from "../../../../components/Menu/CustomStyledMenu";
import IconComponent from "../../../../components/Icon/IconComponent";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import AddViewModal from "./AddViewModal";
import { deleteRequest } from "../../../../Apis/apiWrapper";
import { Mixpanel } from "../../../../Apis/Mixpanel";
import { Button } from "../../../../components/Buttons";

const modalHeaderData = {
  heading: {
    id: STRINGS.HEADER_ID,
    label: STRINGS.ALL_CONTACT_SAVED_VIEWS,
    cross_icon_label: STRINGS.CROSS_ICON_LABEL_MODAL,
  },
};

const AllViewModal = ({
  openAddViewModal,
  setOpenAddViewModal = () => { },
  setCreateViewOpen,
  setViewType,
  leadType,
  viewType = ""
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const session_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);
  const active_view = session_id
    ? session_id
    : leadType === STRINGS.PEOPLE
      ? STRINGS.ALL_CONTACTS
      : STRINGS.ALL_COMPANIES;

  const selector = useSelector((state) => state.leadsBasicDetails);
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const userDetail = userDetailsSelector?.payload?.role;

  const [publicViews, setPublicViews] = useState([]);
  const [privateViews, setPrivateViews] = useState([]);
  const [teamViews, setTeamViews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewDetail, setViewDetail] = useState({});
  const [type, setType] = useState(STRINGS.TEAM_ID);
  const [viewOwners, setViewOwners] = useState([]);
  const [showActions, setShowActions] = useState("");
  const [viewCardMenu, setViewCardMenu] = useState(null);
  const [privateViewCardMenu, setPrivateViewCardMenu] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [renameViewPopup, setRenameViewPopup] = useState(false);
  const [renamePopupValue, setRenamePopupValue] = useState({});
  const [isPin, setIsPin] = useState(0);
  const [performing, setPerforming] = useState(false);
  const [isRename, setIsRename] = useState(false);

  async function getViews() {
    setLoading(true);
    try {
      const URL = newLeadsEndPoints.getAllLeadView;
      const response = await getRequest(navigate, URL);
      setPublicViews(response?.data?.data?.public);
      setPrivateViews(response?.data?.data?.private);
      setTeamViews(response?.data?.data?.team);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  const handleChangeView = (query) => {
    HandleClearAllFilterFromStore(dispatch, location, navigate);
    const res = query;
    if (res) {
      for (let i in res) {
        if (
          typeof res[i] === STRINGS.STRING &&
          stringQueryList?.indexOf(i) === -1
        ) {
          res[i] = [res[i]];
        }
      }
    }

    const filterHeaderSeniority = {
      payload: res?.seniority
        ? getSelectedSeniority(SENIORIRT_TYPES, res?.seniority)
        : SENIORIRT_TYPES,
    };

    const filterHeaderCompany = {
      payload: res.employee_number
        ? getCheckEmployeeCount(
          JSON.parse(JSON.stringify(COMPANY_EMPLOYEES_COUNT)),
          res.employee_number
        )
        : JSON.parse(JSON.stringify(COMPANY_EMPLOYEES_COUNT)),
    };

    const initalJobTitleSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.job_title?.length ||
        res?.job_title_not?.length
        ? checkExistFields(res, filterKeys.job_title)
        : JSON.parse(JSON.stringify(filterHeaderJobTitle));

    const initalIndustrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.industry?.length ||
        res?.industry_not?.length
        ? checkExistFields(res, filterKeys.industry)
        : JSON.parse(JSON.stringify(filterHeaderIndustry));

    const InitialEmailVerification = {
      payload: res?.email_verification
        ? getCheckEmployeeCount(
          EMAIL_VERIFICATION_TYPES,
          res?.email_verification
        )
        : EMAIL_VERIFICATION_TYPES,
    };

    const initalCompanyKeywordsSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_keywords?.length ||
        res?.company_keywords_not?.length
        ? checkExistFields(res, filterKeys.company_keywords)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyTechnologySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_technologies?.length ||
        res?.company_technologies_not?.length
        ? checkExistFields(res, filterKeys.company_technologies)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanySeoSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.seo_description?.length ||
        res?.seo_description_not?.length
        ? checkExistFields(res, filterKeys.seo_description)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyCitySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_city?.length ||
        res?.company_city_not?.length
        ? checkExistFields(res, filterKeys.company_city)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyStateSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_state?.length ||
        res?.company_state_not?.length
        ? checkExistFields(res, filterKeys.company_state)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyNameSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_ids?.length ||
        res?.company_ids_not?.length
        ? checkExistFieldsCompanyName(res, filterKeys.company_name, true)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalCompanyCountrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.company_country?.length ||
        res?.company_country_not?.length
        ? checkExistFields(res, filterKeys.company_country)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonCitySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_city?.length ||
        res?.person_city_not?.length
        ? checkExistFields(res, filterKeys.person_city)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonStateSelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_state?.length ||
        res?.person_state_not?.length
        ? checkExistFields(res, filterKeys.person_state)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    const initalPersonCountrySelect =
      res?.exist_fields?.length ||
        res?.not_exist_fields?.length ||
        res?.person_country?.length ||
        res?.person_country_not?.length
        ? checkExistFields(res, filterKeys.person_country)
        : JSON.parse(JSON.stringify(initialFilterPayload));

    dispatch(
      filterHeaderSearchCompany(
        JSON.parse(JSON.stringify(filterHeaderCompany?.payload))
      )
    );
    dispatch(
      filterHeaderSearchSeniority(
        JSON.parse(JSON.stringify(filterHeaderSeniority?.payload))
      )
    );
    dispatch(
      filterHeaderEmailVerification(
        JSON.parse(JSON.stringify(InitialEmailVerification?.payload))
      )
    );
    dispatch(filterHeaderSearchJobTitle(initalJobTitleSelect?.payload));
    dispatch(filterHeaderSearchIndustry(initalIndustrySelect?.payload));
    dispatch(filterHeaderCompanyKeywords(initalCompanyKeywordsSelect?.payload));
    dispatch(
      filterHeaderCompanyTechnologies(initalCompanyTechnologySelect?.payload)
    );
    dispatch(filterHeaderCompanySeo(initalCompanySeoSelect?.payload));
    dispatch(filterHeaderPersonCity(initalPersonCitySelect?.payload));
    dispatch(filterHeaderPersonState(initalPersonStateSelect?.payload));
    dispatch(filterHeaderPersonCountry(initalPersonCountrySelect?.payload));
    dispatch(filterHeaderCompanyCity(initalCompanyCitySelect?.payload));
    dispatch(filterHeaderCompanyState(initalCompanyStateSelect?.payload));
    dispatch(filterHeaderCompanyCountry(initalCompanyCountrySelect?.payload));
    dispatch(allFilters(query));
    dispatch(getSalesContactsList(query, navigate));
    navigate({
      pathname: location.pathname,
      search: createSearchParams(query || {}).toString(),
    });
  };

  const handleCreateTempActiveView = async (view) => {
    try {
      sessionStorage.setItem(STRINGS.ACTIVE_VIEW_ID, view?.view_id.toString());
      const URL = newLeadsEndPoints.getInitialViews;
      const response = await getRequest(
        navigate,
        URL + "?view-id=" + view?.view_id
      );
      let table_columns =
        response?.data?.data?.active_view?.table_columns || [];
      if (!table_columns.length) {
        table_columns = [
          ...tempHeadCells
            .filter((item) => item.isChecked)
            .map((item) => item.id),
        ];
      }

      const new_table_cells_payload = [];
      for (let i in tempHeadCells) {
        if (table_columns.includes(tempHeadCells[i].id)) {
          new_table_cells_payload.push({
            ...tempHeadCells[i],
            isChecked: true,
          });
        } else {
          new_table_cells_payload.push({
            ...tempHeadCells[i],
            isChecked: false,
          });
        }
      }
      dispatch(applyUpdateColumn(new_table_cells_payload));
      dispatch(updateColumnHeaders(new_table_cells_payload));

      await dispatch(getPeopleLeadDetails(navigate, true, response?.data?.data));

      handleChangeView(response?.data?.data?.active_view?.query_string || {});
      setOpenAddViewModal(false);
    } catch (error) { }
  };

  const handleMouseEnter = (
    eachView,
    index,
    event,
    owners,
    isPrivate = false,
    is_pin
  ) => {
    setIsPin(is_pin);
    setViewDetail(eachView);
    setViewOwners(owners);
    setShowActions(eachView?.view_id);
    if (isPrivate) {
      setType(STRINGS.PRIVATE_ID);
      setPrivateViewCardMenu(event?.currentTarget);
    } else {
      setType(STRINGS.TEAM_ID);
      setViewCardMenu(event?.currentTarget);
    }
  };

  const handlePin = async (viewDetail) => {
    try {
      setViewCardMenu(null);
      setPrivateViewCardMenu(null);
      const URL = newLeadsEndPoints.pinLeadView;
      const response = await getRequest(
        navigate,
        URL + "?view-id=" + viewDetail?.view_id
      );

      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        Mixpanel.track(STRINGS.PINNED_VIEW);
        getViews();
        await dispatch(getPeopleLeadDetails(navigate));
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleDeleteConf = () => {
    setOpenConfirmation(true);
    setViewCardMenu(null);

    setPrivateViewCardMenu(null);
  };

  const renameViewHandler = (eachView, type) => {
    setIsRename(true);
    setRenamePopupValue({ eachView: eachView, type: type });
    setViewCardMenu(null);
    setPrivateViewCardMenu(null);
    setRenameViewPopup(true);
  };

  const handleUnpinView = async (viewDetail) => {
    try {
      setViewCardMenu(null);
      setPrivateViewCardMenu(null);
      const response = await getRequest(
        navigate,
        newLeadsEndPoints?.unpinLeadView +
        `?${STRINGS.VIEW_ID}=` +
        viewDetail?.view_id
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        await dispatch(getPeopleLeadDetails(navigate));
      }
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleDelete = async () => {
    // call delete action here.
    try {
      const URL = newLeadsEndPoints.deleteLeadView;
      setPerforming(true);
      const response = await deleteRequest(
        URL + `?${STRINGS.VIEW_ID}=` + showActions,
        {},
        navigate
      );
      const active_view_id = sessionStorage.getItem(STRINGS.ACTIVE_VIEW_ID);
      if (showActions === active_view_id) {
        sessionStorage.removeItem(STRINGS.ACTIVE_VIEW_ID);
      }
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
        Mixpanel.track(STRINGS.DELETE_VIEW);

        getViews();
        setPerforming(false);
        setOpenConfirmation(false);
        dispatch(getPeopleLeadDetails(navigate));
      }
    } catch (error) {
      setPerforming(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const viewDeleteModalBody = (
    <div>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.ARE_YOU_SURE_DELETE}
      />
    </div>
  );

  const viewDeleteModalHeader = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.DELETE_THIS_VIEW,
    },
  };

  const viewDeleteModalRightFooter = (
    <div className={classes.viewDeleteModalRightFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpenConfirmation(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={handleDelete}
        isLoading={performing}
      />
    </div>
  );

  const actionList = [
    {
      type: STRINGS.PIN_VIEW_TO_KEEP_OPEN,
      action: handlePin,
      icon: ICON_LABELS.PUSH_PIN,
    },
    {
      type: STRINGS.EDIT_VIEW,
      action: renameViewHandler,
      icon: ICON_LABELS.EDIT,
    },
    {
      type: STRINGS.DELETE_THIS_VIEW,
      action: handleDeleteConf,
      icon: ICON_LABELS.DELETE,
    },
  ];

  const actionLists = [
    {
      type: STRINGS.PIN_VIEW_TO_KEEP_OPEN,
      action: handlePin,
      icon: ICON_LABELS.PUSH_PIN,
    },
  ];

  useEffect(() => {
    if (openAddViewModal) {
      getViews();
    }
  }, [openAddViewModal]);
  console.log("viewCardMenu____________ ", viewCardMenu);
  return (
    <>
      <CustomModal
        open={openAddViewModal}
        close={() => setOpenAddViewModal(!openAddViewModal)}
        header={modalHeaderData}
        width="60.469vw"
        remove_padding={true}
      >
        <div className={classes.addViewModalBody}>
          {/* Standard Views */}
          <div className={classes.addViewBodySubCntnr}>
            <div className={classes.viewLabel}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.STANDARD}
              />
            </div>
            {loading ? (
              <div className={classes.loaderContainer}>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"40px"}
                  stackWidth={"100%"}
                />
              </div>
            ) : (
              <>
                {publicViews?.map((eachView) => {
                  return (
                    <ManageViewCard
                      label={eachView?.view_name}
                      RightIcon={ICON_LABELS.PUSH_PIN}
                      onClick={() => handleCreateTempActiveView(eachView)}
                      isSelected={
                        active_view === STRINGS.ALL_CONTACTS ||
                        active_view === STRINGS.ALL_COMPANIES ||
                        active_view === eachView?.view_id
                      }
                    />
                  );
                })}
              </>
            )}
          </div>
          {/* Standard Views */}

          {/* Private Views */}
          <div className={classes.addViewBodySubCntnr}>
            <div className={classes.viewLabel}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.PRIVATE_VIEW}
              />
            </div>

            <div id="modal_body" className={classes.manageCardMainContainer}>
              {loading ? (
                Array.from({ length: 5 }).map((item, index) => (
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={"55px"}
                    stackWidth={"100%"}
                  />
                ))
              ) : (
                <>
                  {privateViews?.length ? (
                    <>
                      {privateViews?.map((eachView, index) => {
                        const pinnedViewIds =
                          leadType === STRINGS.PEOPLE &&
                          selector?.payload?.pinned_view
                            .filter((item) => item?.view_id)
                            .map((view) => {
                              return view.view_id;
                            });
                        const defaultViewIds = selector?.payload?.default_view?.view_id;
                        const owners =
                          eachView?.owner_name === STRINGS.ME
                            ? actionList
                            : actionLists;
                        return (
                          <>
                            {/* <div> */}
                            <ManageViewCard
                              label={eachView?.view_name}
                              onwerName={eachView?.owner_name || ""}
                              onClick={() =>
                                handleCreateTempActiveView(eachView)
                              }
                              isSelected={active_view === eachView?.view_id}
                              actionOnClick={(e) =>
                                handleMouseEnter(
                                  eachView,
                                  index,
                                  e,
                                  owners,
                                  true,
                                  eachView?.is_pin
                                )
                              }
                              isPinned={pinnedViewIds?.includes(
                                eachView?.view_id
                              )}
                              isDefault={defaultViewIds?.includes(
                                eachView?.view_id
                              )}
                            />
                            {/* </div> */}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <EmptyViewCard
                      label={STRINGS.NO_PRIVATE_VIEW_CREATED}
                      onClick={() => {
                        setRenameViewPopup(true);
                        setIsRename(false);
                        setViewType("private");
                      }}
                    />
                  )}
                </>
              )}
              <CustomStyledMenu
                anchorEl={privateViewCardMenu}
                open={Boolean(privateViewCardMenu)}
                onClose={() => setPrivateViewCardMenu(null)}
                noMargin={true}
                isRightAlign={true}
                arrow
              >
                <div className={classes.toolTipContainer}>
                  {viewOwners?.map((action, index) => {
                    return !(
                      viewDetail?.view_id ===
                      selector?.payload?.default_view
                        ?.view_id && index === 0
                    ) ?
                      <div
                        key={action.type}
                        onClick={() =>
                          isPin === 1
                            ? index === 0
                              ? handleUnpinView(viewDetail, STRINGS.TEAM_ID)
                              : action.action(viewDetail, type)
                            : action.action(viewDetail, type)
                        }
                        // onClick={() => action.action(viewDetail, type)}
                        className={
                          actionList?.length - 1 === index
                            ? classes.borderFlex
                            : classes.noBorderFlex
                        }
                      >
                        <IconComponent
                          color={GREY.TERTIARY}
                          iconLabel={action.icon}
                          fontSize={fontSize.LG}
                        />
                        <TypographyInline
                          label={
                            isPin === 1
                              ? index === 0
                                ? STRINGS.UNPIN_THIS_VIEW
                                : action.type
                              : action.type
                          }
                          size={"sm"}
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                        />
                      </div>
                      : null
                  })}
                </div>
              </CustomStyledMenu>
            </div>
          </div>
          {/* Private Views */}

          {/* Team Views */}
          {/* <div className={classes.addViewBodySubCntnr}>
            <div className={classes.viewLabel}>
              <TypographyInline
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                size={"sm"}
                label={STRINGS.TEAM_VIEW}
              />
            </div>

            <div id="modal_body" className={classes.manageCardMainContainer}>
              {loading ? (
                Array.from({ length: 5 }).map((item, index) => (
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={"55px"}
                    stackWidth={"100%"}
                  />
                ))
              ) : (
                <>
                  {teamViews?.length ? (
                    <>
                      {teamViews?.map((eachView, index) => {
                        const owners =
                          eachView?.owner_name === STRINGS.ME ||
                            userDetail === STRINGS.ADMIN_USER
                            ? actionList
                            : actionLists;

                        const pinnedViewIds = selector?.payload?.pinned_view
                          .filter((item) => item?.view_id)
                          .map((view) => {
                            return view.view_id;
                          });
                        return (
                          <ManageViewCard
                            key={eachView?.view_id}
                            label={eachView?.view_name}
                            onwerName={eachView?.owner_name || ""}
                            onClick={() => handleCreateTempActiveView(eachView)}
                            isSelected={active_view === eachView?.view_id}
                            actionOnClick={(e) =>
                              handleMouseEnter(
                                eachView,
                                index,
                                e,
                                owners,
                                false,
                                eachView?.is_pin
                              )
                            }
                            isPinned={pinnedViewIds?.includes(
                              eachView?.view_id
                            )}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <EmptyViewCard
                      label={STRINGS.NO_TEAM_VIEW_CREATED}
                      setCreateViewOpen={setCreateViewOpen}
                      onClick={() => {
                        setViewType("team");
                        setIsRename(false);
                        setRenameViewPopup(true);
                      }}
                    />
                  )}
                </>
              )}
              <CustomStyledMenu
                anchorEl={privateViewCardMenu}
                open={Boolean(privateViewCardMenu)}
                onClose={() => setPrivateViewCardMenu(null)}
                noMargin={true}
                isRightAlign={true}
                arrow
              >
                <div className={classes.toolTipContainer}>
                  {viewOwners?.map((action, index) => {
                    return (
                      <div
                        key={action.type}
                        onClick={() =>
                          isPin === 1
                            ? index === 0
                              ? handleUnpinView(viewDetail, type)
                              : action.action(viewDetail, type)
                            : action.action(viewDetail, type)
                        }
                        className={
                          actionList?.length - 1 === index
                            ? classes.borderFlex
                            : classes.noBorderFlex
                        }
                      >
                        <IconComponent
                          color={GREY.TERTIARY}
                          iconLabel={action.icon}
                          fontSize={fontSize.LG}
                        />
                        <TypographyInline
                          label={
                            isPin === 1
                              ? index === 0
                                ? STRINGS.UNPIN_THIS_VIEW
                                : action.type
                              : action.type
                          }
                          size={"sm"}
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                        />
                      </div>
                    );
                  })}
                </div>
              </CustomStyledMenu>
            </div>
          </div> */}
          {/* Team Views */}
        </div>
      </CustomModal>

      {/* Add/Edit View Modal */}
      {renameViewPopup && (
        <AddViewModal
          setPublicViews={setPublicViews}
          setPrivateViews={setPrivateViews}
          setTeamViews={setTeamViews}
          getViews={getViews}
          isRename={isRename}
          leadType={leadType}
          open={renameViewPopup}
          setOpen={setRenameViewPopup}
          renamePopupValue={renamePopupValue}
          setRenamePopupValue={setRenamePopupValue}
          setViewCardMenu={setViewCardMenu}
          setPrivateViewCardMenu={setPrivateViewCardMenu}
          viewType={viewType}
        />
      )}

      <CustomModal
        variant={"error"}
        close={() => setOpenConfirmation(false)}
        open={openConfirmation}
        header={viewDeleteModalHeader}
        children={viewDeleteModalBody}
        footer={{
          right: viewDeleteModalRightFooter,
        }}
      />
    </>
  );
};

export default AllViewModal;
