import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch,  } from "react-redux";
import {
  campaignEndpoints,
  listEndPoints,
  sequenceEndPoints,
  templateEndpoints,
} from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import * as qs from "qs";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import {  postRequest, putRequest } from "../../../../Apis/apiWrapper";
import { getTemplateList } from "../../../../redux/actions/templateListActions";
import { getErrorMessage } from "../../../../utils/helpers";
import { campaignsListFetch } from "../../../../redux/actions/campaignAction";
import { getContactListData } from "../../../../redux/actions/contactListactions";
import { STRINGS } from "../strings";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import CustomModal from "../../../../components/Modals/CustomModal";
import { Button } from "../../../../components/Buttons";
import { paginationDataAction } from "../../../../redux/actions/customTableActions";
import classes from "../markComponents.module.css";
import { getSequenceList } from "../../../../redux/actions/sequenceActions";


const RemoveFolder = ({ open, setOpen, IDs, type,val}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.fold
  const [loading, setLoading] = useState(false);
  const deleteRecord = async () => {
    if(type===STRINGS.LIST_REMT){
      try{
        setLoading(true);
        const payload = {
            list_ids: IDs,
            type:STRINGS.SMALL_FILE,
          };
          const response = await putRequest(
            navigate,
            listEndPoints.removeFolderList,
            payload
          );
          dispatch(getContactListData({parent:id, navigate }));
          dispatch(paginationDataAction({checkedRow:[]}));
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
        setOpen(!open);
        setLoading(false);
      } 
      catch(err){
        EventEmitter.emit(
            EMITTER_ERROR,
            getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
          );
      } 
      finally {
        setOpen(!open);
        setLoading(false);
      }
    }
    if(type===STRINGS.TEMP_REM){
        try{
            setLoading(true);
            const payload = {
                template_ids: IDs,
                type:STRINGS.SMALL_FILE,
              };
              const response = await putRequest(
                navigate,
                templateEndpoints.removeTempFromFolder,
                payload
              );
              dispatch(getTemplateList({ parent:id, navigate }));
              dispatch(paginationDataAction({checkedRow:[]}));
            const resp = response?.data?.message;
            EventEmitter.emit(EMITTER_SUCCESS, resp);
            setOpen(!open);
            setLoading(false);
          } 
          catch(err){
            EventEmitter.emit(
                EMITTER_ERROR,
                getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
              );
          } 
          finally {
            setOpen(!open);
            setLoading(false);
          } 
    }
    if(type===STRINGS.REM_SEQ){
      try{
        setLoading(true);
        const payload = {
            record_ids: IDs,
            type:STRINGS.SMALL_FILE,
          };
          const response = await postRequest(
            navigate,
            sequenceEndPoints.removeSequenceFromFolder,
            payload
          );
          dispatch(getSequenceList({parent:id, navigate}))
          dispatch(paginationDataAction({checkedRow:[]}));
          const resp = response?.data?.data?.message;
          EventEmitter.emit(EMITTER_SUCCESS,resp);
          setOpen(!open);
          setLoading(false);
      }
      catch(err){
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
        );
      }
      finally {
        setOpen(!open);
        setLoading(false);
      }
    }
    if(type===STRINGS.REM_CAM){
      try{
        setLoading(true);
        const payload = {
            record_ids: IDs,
            type:STRINGS.SMALL_FILE,
          };
          const response = await postRequest(
            navigate,
            campaignEndpoints.removeCampaignFromFolder,
            payload
          );
          dispatch(campaignsListFetch({folder_id:id ,navigate}))
          dispatch(paginationDataAction({checkedRow:[]}));
          const resp = response?.data?.data?.message;
            EventEmitter.emit(EMITTER_SUCCESS,resp);
            setOpen(!open);
            setLoading(false);
      }
      catch(err){
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
        );
      }
      finally {
        setOpen(!open);
        setLoading(false);
      }
    }
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: `${STRINGS.REM_SELECTED} ${val}`,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBodyContainer}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.REGULAR}
        label={`${STRINGS.REM_DATA} ${val} ${STRINGS.FROM_FOLD}?`}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  const modalFooterRightData = (
    <div className={classes.rightFoot}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.REMOVE}
        size={"sm36"}
        variant={"primary"}
        onClick={deleteRecord}
        isLoading={loading}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      variant={"error"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default RemoveFolder;
