import {
  CAMPAIGN_CREATE_DAYS_LIST,
  COLUMNS_KEYS_VALUES,
  EMAIL_ENCODING_SALT,
  initialTimings,
  MONTH_INDEX,
  STATIC_EXPORT_ORDRE_PAIR,
  STATIC_EXPORT_ORDRE_PAIR1,
} from "../constants";
import pako from "pako";

// Dummy data create for table
export function createData(
  name,
  jobTitle,
  company,
  contactLocation,
  employees,
  industry,
  keywords,
  email,
  associatedComapny
) {
  return {
    name: name,
    jobTitle: jobTitle,
    company: company,
    contactLocation: contactLocation,
    employees: employees,
    industry: industry,
    keywords: keywords,
    email: email,
    actions: "Save",
    associatedComapny: associatedComapny,
  };
}

export const generateRandomColor = () => {
  const colors = [
    "#7954F6",
    "#3ACAF9",
    "#F6DF5D",
    "#11D69F",
    "#EE3962",
    "#F77F53",
    "#663E70",
  ];
  const index = Math.floor(Math.random(0, 100) * colors.length);
  return colors[index];
};

export const dragArrayIndexes = (array, sourceIndex, destinationIndex) => {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    if (i !== sourceIndex) {
      result.push(array[i]);
    }
    if (i === destinationIndex) {
      result.push(array[sourceIndex]);
    }
  }
  return result;
};

const decipher = () => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(EMAIL_ENCODING_SALT).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
};

export const decryptEmail = (text) => {
  const myDecipher = decipher();
  return myDecipher(text);
};

export const removeObjFromArray = (arr, obj) => {
  let filtered = arr.filter(function (el) {
    return el.id !== obj.id;
  });
  return filtered;
};

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const filterSelectedIndexes = (array, type = "index") => {
  let result1 = array?.filter((item) => item.isChecked);
  return result1.map((item) => item[type]);
};

export const filterTableName = (firstName, lastName) => {
  const fullName = firstName + " " + lastName;
  return `${fullName.slice(0, 20)}${fullName.length >= 21 ? "..." : ""}`;
};

export const createCompanyURL = (website) => {
  try {
    const URL1 = new URL(website);
    const hostname = URL1.hostname;
    const companyHost = hostname.split("www.")[1];
    const companyURL = `https://logo.clearbit.com/${companyHost}`;

    return companyURL;
  } catch (error) {
    return website;
  }
};

export const reorderArrayByKeys = (arrayOfObjects, keyOrder) => {
  const reorderedArray = arrayOfObjects.map((obj) => {
    const reorderedObject = {};
    keyOrder.forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        reorderedObject[key] = obj[key];
      }
    });
    return reorderedObject;
  });
  return reorderedArray;
};

export const filterQuery = (query) => {
  let filtered = {};
  for (let i in query) {
    if (i === "min_revenue" || i === "deal_min_revenue") {
      filtered[i] = Number(query[i]);
    } else if (i === "max_revenue" || i === "deal_max_revenue") {
      filtered[i] = Number(query[i]);
    } else if (i === "min_lead_log_date_time") {
      filtered[i] = Number(query[i]);
    } else if (i === "max_lead_log_date_time") {
      filtered[i] = Number(query[i]);
    } else if (i === "offset") {
      filtered[i] = Number(query[i]);
    } else if (i === "page") {
      filtered[i] = Number(query[i]);
    } else if (i === "limit") {
      filtered[i] = Number(query[i]);
    } else if (i === "search" || i === "deal_name") {
      filtered[i] = query[i];
    } else if (i === "employee_number" && query[i]?.length) {
      filtered[i] = query[i]?.map((item) => Number(item));
    } else if (
      (i === "name" && query[i]?.length) ||
      (i === "sort" && query[i]?.length) ||
      (i === "e_verify_max_date" && query[i]?.length) ||
      (i === "e_verify_min_date" && query[i]?.length) ||
      (i === "updated_min_date" && query[i]?.length) ||
      (i === "updated_max_date" && query[i]?.length) ||
      (i === "created_min_date" && query[i]?.length) ||
      (i === "created_max_date" && query[i]?.length) ||
      (i === "direction" && query[i]?.length)
    ) {
      filtered[i] = query[i];
    } else if (query[i]?.length) {
      filtered[i] = [...new Set(query[i])];
    }
  }
  return JSON.parse(JSON.stringify(filtered));
};

export const filterDynamicQuery = (query, properties) => {
  let filtered = {};
  for (let id in query) {
    if (
      id !== "isApiCall" &&
      id !== "page" &&
      id !== "limit" &&
      id !== "sort" &&
      id !== "direction" &&
      id !== "exist_fields" &&
      id !== "not_exist_fields" &&
      id !== "search"
    ) {
      let temp_id = id?.replace("-not", "")?.replace("cb-", "");
      if (properties?.[temp_id]?.field_type?.[0] === "Date Picker") {
        if (
          (query[id]?.from && query[id]?.from?.length) ||
          (query[id]?.to && query[id]?.to?.length)
        ) {
          filtered[`from-${id}`] = query[id]?.from;
          filtered[`to-${id}`] = query[id]?.to;
        }
      } else if (properties?.[temp_id]?.field_type?.[0] === "Number") {
        if (
          (query[id]?.min && Object?.keys(query?.[id])?.length) ||
          Object?.keys(query?.[id])?.length
        ) {
          filtered[`min-${id}`] = Number(query[id]?.min);
          filtered[`max-${id}`] = Number(query[id]?.max);
        }
      } else if (
        (temp_id === "deal_name" || temp_id === "name") &&
        temp_id?.length
      ) {
        filtered[id] = query[id];
      } else if (
        properties?.[temp_id]?.field_type?.[0] === "Multiple Checkbox"
      ) {
        filtered[id] = query[id]?.anyOfList || query?.[id];
      } else {
        if (Object.keys(query[id])?.length) {
          if (query[id]?.anyOf) {
            filtered[id] = query[id]?.anyOfList || [];
          }
          if (query[id]?.notAnyOf) {
            filtered[`${id}-not`] = query[id]?.notAnyOfList || [];
          }
          if (query[id]?.isKnown) {
            filtered.exist_fields = [
              ...new Set([...(filtered?.exist_fields || []), id]),
            ];
          }
          if (query[id]?.isUnknown) {
            filtered.not_exist_fields = [
              ...new Set([...(filtered?.not_exist_fields || []), id]),
            ];
          }
        }
      }
    } else {
      if (id !== "isApiCall") {
        filtered[id] = query[id];
      }
    }
  }
  return JSON.parse(JSON.stringify(filtered));
};

export const filterColumnQueryKeys = (query) => {
  const columns = Object.keys(query);
  const not_a_column = [
    "seniority",
    "exist_fields",
    "not_exist_fields",
    "name",
    "employee_number",
    "email_verification",
    "e_verify_min_date",
    "e_verify_max_date",
    "search",
    "limit",
    "sort",
    "page",
    "direction",
    "company_ids",
    "min_revenue",
    "max_revenue",
    "contact_lists",

    "file_path",
    "updated_min_date",
    "updated_max_date",
    "created_min_date",
    "created_max_date",
    "min_lead_log_date_time",
    "max_lead_log_date_time",
  ];

  // handling exist_fields here.
  if (columns?.includes("exist_fields")) {
    for (let i in query?.exist_fields || []) {
      if (!columns.includes(query?.exist_fields[i])) {
        columns.push(query?.exist_fields[i]);
      }
    }
  }

  // handling not_exist_fields here.
  if (columns?.includes("not_exist_fields")) {
    for (let i in query?.not_exist_fields || []) {
      if (!columns.includes(query?.not_exist_fields[i])) {
        columns.push(query?.not_exist_fields[i]);
      }
    }
  }

  const temp_columns = [];
  // removing _not from array.
  for (let i in columns) {
    if (columns?.[i]?.split("_")?.[columns?.[i]?.split("_")?.length - 1] === "all") {
      temp_columns.push(columns?.[i]?.replace("_all", ""));
    }
    else if (
      columns?.[i]?.split("_")?.[columns?.[i]?.split("_")?.length - 1] !== "not"
    ) {
      temp_columns.push(columns[i]);
    }
  }
  //removing keys which is not a column.
  const temp_columns1 = [];
  for (let i in temp_columns) {
    if (!not_a_column.includes(temp_columns[i])) {
      temp_columns1.push(temp_columns[i]);
    }
  }
  return temp_columns1;
};

export function removeElementFromArray(arr, elementToRemove) {
  const indexToRemove = arr.indexOf(elementToRemove);

  if (indexToRemove !== -1) {
    arr.splice(indexToRemove, 1);
  }

  return arr;
}

export const sortArrayWithValue = (array, key) => {
  return array?.sort((a, b) => a[key] - b[key]);
};

export const findSortedValue = (value, items) => {
  return items?.find((item) => item.id === value);
};

export const filterItemsForSorting = (items, value) => {
  if (!value) {
    return items;
  }
  return items?.filter((item) => item.id !== value);
};

export const csvDownload = (data, name, delimiter) => {
  console.log({ data });
  try {
    const items = data;
    const filename = name || `contact_export.csv`;
    const d = delimiter || `,`;

    const header = Array.from(
      new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
    );

    let csv = items.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName] || "")).join(d)
    );

    // update csv headers here.

    const old_headers = header;

    console.log("old_headers", old_headers);
    const new_headers = old_headers.map(
      (item) => STATIC_EXPORT_ORDRE_PAIR[item]
    );

    console.log("new_headers", new_headers);

    csv.unshift(new_headers.join(d));
    csv = csv.join("\r\n");

    const blob = new Blob([csv], {
      type: "text/plain;charset=utf-8",
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("errir-------", error);
  }
};
export const csvDownload1 = (data, name, delimiter) => {
  console.log({ data });
  try {
    const items = data;
    const filename = name || `contact_export.csv`;
    const d = delimiter || `,`;

    const header = Array.from(
      new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
    );

    let csv = items.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName] || "")).join(d)
    );

    // update csv headers here.

    const old_headers = header;

    console.log("old_headers", old_headers);
    const new_headers = old_headers.map(
      (item) => STATIC_EXPORT_ORDRE_PAIR1[item]
    );
    if (new_headers[0] === "Last Name") {
      let temp = new_headers[0];
      new_headers[0] = new_headers[1];
      new_headers[1] = temp;
    }
    console.log("new_headers", new_headers);

    csv.unshift(new_headers.join(d));
    csv = csv.join("\r\n");

    const blob = new Blob([csv], {
      type: "text/plain;charset=utf-8",
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("errir-------", error);
  }
};

export const processContactNumberFormat = (country, number) => {
  const valid_countries = ["united states", "us", "canada"];

  try {
    if (!valid_countries.includes(country.toLowerCase().trim())) {
      return number.replace(/["'`,]/g, "");
    } else {
      // Process contact number here.

      number = number.replace("+1", "");
      // Remove any non-digit characters from the phone number
      number = number.replace(/\D/g, "");
      // Format the phone number
      const formattedPhoneNumber = `(${number.slice(0, 3)}) ${number.slice(
        3,
        6
      )}-${number.slice(6)}`;
      return formattedPhoneNumber;
    }
  } catch (error) {
    return number;
  }
};
export function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
        : Math.abs(Number(labelValue));
}

export const generateExportData = (data) => {
  const newArray = [];
  console.log(1);
  for (let i = 0; i < data?.length; i++) {
    console.log(2, i);
    let eachObj = {};
    for (let j in data[i]) {
      console.log(
        "eachhhh",
        COLUMNS_KEYS_VALUES[j],
        "-----",
        j,
        "-----",
        data[i][j]
      );
      eachObj[COLUMNS_KEYS_VALUES[j]] = data[i][j];
    }
    newArray.push(eachObj);
    eachObj = {};
  }
  console.log(4);

  console.log("removedIdArray", newArray);
  return newArray;
};

export const JSONtoQueryString = (JSON) => {
  return (
    "?" +
    Object.keys(JSON)
      .map((key) => key + "=" + JSON[key])
      .join("&")
  );
};

export const getSelectedColumns = (items) => {
  const columns = items
    ?.filter((item) => item.isChecked)
    ?.map((item) => item.id);
  return [...new Set(["last_name", ...columns])];
};

export const getSelectedColumnsCompany = (items) => {
  const columns = items
    ?.filter((item) => item.isChecked)
    ?.filter((item) => item.companyField)
    ?.map((item) => item.id);
  return [...columns];
};

export const filterUserName = (name) => {
  return name?.length > 15 ? `${name?.slice(0, 15)}...` : name;
};

// Campaigns Helper

export const createTimingsFromPayload = (timings) => {
  if (!timings) {
    return false;
  }
  const updatedTimings = {};
  for (let i in timings) {
    updatedTimings[timings[i]?.day] = timings[i];
  }

  const result = [];

  for (let i in CAMPAIGN_CREATE_DAYS_LIST) {
    console.log("CAMPAIGN_CREATE_DAYS_LIST", timings[i]);
    if (updatedTimings[CAMPAIGN_CREATE_DAYS_LIST[i].day]) {
      const eachTiming = updatedTimings[CAMPAIGN_CREATE_DAYS_LIST[i].day];
      const day = CAMPAIGN_CREATE_DAYS_LIST?.filter(
        (timing) => timing?.day === eachTiming?.day
      )?.[0]?.id;

      const start =
        parseInt(eachTiming?.start) <= 12
          ? eachTiming?.start
          : parseInt(eachTiming?.start) - 12;
      const startMinute = parseInt(eachTiming?.start_min) || 0;
      const endMinute = parseInt(eachTiming?.end_min) || 0;
      const end =
        eachTiming?.end <= 12 ? eachTiming?.end : eachTiming?.end - 12;
      const startType = eachTiming?.start <= 12 ? "AM" : "PM";
      const endType = eachTiming?.end <= 12 ? "AM" : "PM";
      let eachDay = {
        startTime: start,
        endTime: end,
        startType,
        endType,
        checked: true,
        day,
        startMinute,
        endMinute,
      };
      result.push(eachDay);
    }
  }

  for (let i in initialTimings) {
    const element = result?.filter(
      (item) => item?.day === initialTimings[i].day
    );
    if (!element?.length) {
      const eachObj = { ...initialTimings[i], checked: false };
      result.push(eachObj);
    }
  }

  return result;
};

export const createTimingsForPayload = (timings) => {
  const filteredTimings = timings?.filter((timing) => timing?.checked);
  console.log("timings_________", timings, filteredTimings);
  const result = {};
  for (let i in filteredTimings) {
    const day = CAMPAIGN_CREATE_DAYS_LIST?.filter(
      (item) => item?.id === filteredTimings[i].day
    ).map((item) => item.day)[0];
    const start =
      filteredTimings[i].startType === "AM"
        ? filteredTimings[i].startTime === 12
          ? filteredTimings[i].startTime + 12
          : filteredTimings[i].startTime
        : filteredTimings[i].startTime === 12
          ? filteredTimings[i].startTime
          : filteredTimings[i].startTime + 12;
    const end =
      filteredTimings[i].endType === "AM"
        ? filteredTimings[i].endTime === 12
          ? filteredTimings[i].startTime + 12
          : filteredTimings[i].endTime
        : filteredTimings[i].endTime === 12
          ? filteredTimings[i].startTime
          : filteredTimings[i].endTime + 12;
    const start_min = filteredTimings[i]?.startMinute;
    const end_min = filteredTimings[i]?.endMinute;
    const check = filteredTimings[i]?.checked;

    const eachTiming = { day, start, end, start_min, end_min, check };
    result[i?.toString()] = eachTiming;
  }
  return result;
};

export const validationErrorMessage = (timings) => {
  const validTimings = timings;

  for (let i in validTimings) {
    if (validTimings[i]?.start > validTimings[i]?.end) {
      console.log(validTimings[i], "=====>");
      validTimings[i].message =
        "Start Time and End Time should be of same day.";
    }
    if (
      validTimings[i]?.start === validTimings[i]?.end &&
      validTimings[i]?.start_min > validTimings[i]?.end_min
    ) {
      validTimings[i].message = "Time difference should atleast be of one hour";
    }
    if (!validTimings[i].message) {
      validTimings[i].message = "";
    }
  }
  return Object.values(timings);
};

export const getErrorMessage = (errors, commonError) => {
  console.log("errors", errors);
  for (let i in errors) {
    if (errors[i]?.length) {
      return errors[i];
    }
  }
  return commonError || "No Error Message from Server";
};

//file upload helper funcv
export const handleOnChange = async (e) => {
  const file = e.target.files[0];
  console.log(file.size);
  let chunkSize = 1024 * 1024 * 1; //1MB Chunk size
  let fileSize = file.size;
  let currentChunk = 1;
  let totalChunks = Math.ceil(fileSize / chunkSize, chunkSize);

  while (currentChunk <= totalChunks) {
    let offset = (currentChunk - 1) * chunkSize;
    let currentFilePart = file.slice(offset, offset + chunkSize);

    console.log("Current chunk number is ", currentChunk);
    console.log("Current chunk data", currentFilePart);

    currentChunk++;
  }
};

export const match_import_fields = (
  item,
  peopleArray,
  companyArray,
  peopleDynamicArray,
  companyDynamicArray
) => {
  const split_regex = /\-|\_|\ /;
  const temp_item = item?.trim()?.toLowerCase();
  let string = temp_item?.split(split_regex)?.join("");
  // debugger;
  // Mapping People Fields.
  for (let i = 0; i < peopleArray?.length; i++) {
    let mappingArray = peopleArray[i]?.mapping_array;
    let tempStr = string;
    if (string.length) {
      mappingArray.forEach((element) => {
        if (tempStr?.includes(element)) {
          tempStr = tempStr.replace(element, "");
        }
      });
    }
    // string = tempStr;
    if (!tempStr?.length) {
      return {
        property_type: "people",
        property_type_label: "People",
        property_value: peopleArray?.[i]?.["id"],
      };
    }
  }

  // Mapping Company Fields.
  for (let i = 0; i < companyArray?.length; i++) {
    let mappingArray = companyArray[i]?.mapping_array;
    let tempStr = string;
    if (string.length) {
      mappingArray.forEach((element) => {
        if (tempStr?.includes(element)) {
          tempStr = tempStr.replace(element, "");
        }
      });
    }
    if (!tempStr?.length) {
      return {
        property_type: "company",
        property_type_label: "Company",
        property_value: companyArray?.[i]?.["id"],
      };
    }
  }

  // Mapping People Dynamic Fields.
  for (let i = 0; i < peopleDynamicArray?.length; i++) {
    let mappingArray = peopleDynamicArray[i]?.mapping_array;
    let tempStr = string;
    if (string.length) {
      mappingArray.forEach((element) => {
        if (tempStr?.includes(element)) {
          tempStr = tempStr.replace(element, "");
        }
      });
    }
    if (!tempStr?.length) {
      return {
        property_type: "people",
        property_type_label: "People",
        property_value: peopleDynamicArray?.[i]?.["id"],
      };
    }
  }

  // Mapping Company Dynamic Fields.
  for (let i = 0; i < companyDynamicArray?.length; i++) {
    let mappingArray = companyDynamicArray[i]?.mapping_array;
    let tempStr = string;
    if (string.length) {
      mappingArray.forEach((element) => {
        if (tempStr?.includes(element)) {
          tempStr = tempStr.replace(element, "");
        }
      });
    }
    if (!tempStr?.length) {
      return {
        property_type: "company",
        property_type_label: "Company",
        property_value: companyDynamicArray?.[i]?.["id"],
      };
    }
  }

  // if not match then default return
  return {
    property_value: "",
    property_type: "people",
    property_type_label: "People",
  };
};
export const match_import_fields_csv = (item, peopleArray, companyArray) => {
  console.log({ item }, { peopleArray }, { companyArray });
  const split_regex = /\-|\_|\ /;
  const temp_item = item?.trim()?.toLowerCase();
  const string = temp_item?.split(split_regex)?.join("");

  for (let i = 0; i < peopleArray?.length; i++) {
    const each_processed_element = peopleArray?.[i]?.["id"]
      ?.trim()
      ?.toLowerCase()
      ?.split(split_regex)
      .join("");
    if (string === each_processed_element) {
      return {
        property_type: "people",
        property_value: peopleArray?.[i]?.["id"]?.toLowerCase(),
      };
    }
  }

  for (let i = 0; i < companyArray?.length; i++) {
    const each_processed_element = companyArray?.[i]?.["id"]
      ?.trim()
      ?.toLowerCase()
      ?.split(split_regex)
      .join("");
    if (string === each_processed_element) {
      return {
        property_type: "company",
        property_value: companyArray?.[i]?.["id"],
      };
    }
  }

  return { property_value: "", property_type: "people" };
};

export const handleGoogleAuthentication = () => {
  const URL =
    "https://" +
    process.env.REACT_APP_DEV_REGISTRATION_LINK_WITHOUT_PROTOCAL +
    "/auth/google-login" +
    "?host=" +
    window.location.host;
  const anchor = document.createElement("a");
  anchor.href = URL;
  anchor.click();
};

export const handleMicrosoftAuthentication = () => {
  const URL =
    "https://" +
    process.env.REACT_APP_DEV_REGISTRATION_LINK_WITHOUT_PROTOCAL +
    "/auth/microsoft-login" +
    "?host=" +
    window.location.host;
  const anchor = document.createElement("a");
  anchor.href = URL;
  anchor.click();
};

export const handleGoogleAuth = () => {
  const URL =
    "https://" +
    process.env.REACT_APP_DEV_REGISTRATION_LINK_WITHOUT_PROTOCAL +
    "/auth/connect-inbox/gmail/" +
    window.location.host +
    "?tenant_path=" +
    encodeURIComponent(`${window.location.pathname}?tab=Integrations`);
  const anchor = document.createElement("a");
  anchor.href = URL;
  anchor.click();
};

export const handleMicrosoftAuthInitialize = () => {
  const URL =
    "https://" +
    process.env.REACT_APP_DEV_REGISTRATION_LINK_WITHOUT_PROTOCAL +
    "/auth/connect-inbox/outlook/" +
    window.location.host +
    "?tenant_path=" +
    encodeURIComponent(`${window.location.pathname}?tab=Integrations`);
  const anchor = document.createElement("a");
  anchor.href = URL;
  anchor.click();
};
export const removeTagStyleFromHtml = (html) => {
  let temp_body = html;
  temp_body = temp_body.replaceAll("4px 8px", "0px");
  temp_body = temp_body.replaceAll("small", "inherit");
  temp_body = temp_body.replaceAll("rgb(255, 233, 233)", "transparent");
  temp_body = temp_body.replaceAll("rgb(255, 72, 72)", "inherit");
  return temp_body;
};

export const addTagStyleInHtml = (html) => {
  if (!html?.length) {
    return "";
  }

  function getPosition(string, subString, index) {
    return string?.includes(subString)
      ? string.split(subString, index).join(subString).length
      : -1;
  }

  let all_tags_fetched = false;
  let current_span_tag = 1;
  while (!all_tags_fetched) {
    const start_occurence = getPosition(html, "<span", current_span_tag);
    if (start_occurence === html.length || start_occurence === -1) {
      all_tags_fetched = true;
    } else {
      let parsed_tag;
      const end_occurence = getPosition(html, "</span>", current_span_tag);
      let each_tag = html?.slice(start_occurence, end_occurence);
      if (each_tag?.includes("{{") && each_tag?.includes("}}")) {
        // this tag is valid.
        parsed_tag = each_tag?.replace("transparent", "rgb(255, 233, 233)");
        console.log("ParsedTag________________", parsed_tag);
        parsed_tag = parsed_tag?.replace("inherit", "rgb(255, 72, 72)");
        parsed_tag = parsed_tag?.replace("0px", "4px 8px");
        html = html.replace(each_tag, parsed_tag);
      }
      // do this in last.
      current_span_tag += 1;
    }
  }
  return html;
};

export const textEllipsesHandler = (value, sliceLength) => {
  let result;
  if (typeof value === "string") {
    result = value?.slice(0, sliceLength);
    if (value.length > sliceLength) {
      result += "...";
    }
    return result;
  }
  return value;
};

export const Logger = (logValue, message = "Logger Log ") => {
  return process.env.NODE_ENV === "development"
    ? console.log(message, logValue)
    : null;
};

export const timeStampToDateTime = (timeStamp) => {
  if (timeStamp) {
    let timeStmp = timeStamp?.toString()?.includes("T")
      ? timeStamp
      : parseInt(timeStamp) <= 9999
        ? timeStamp
        : parseInt(timeStamp);
    let tempDateTime = new Date(timeStmp);
    let month = MONTH_INDEX[tempDateTime.getMonth()] || "--";
    let date = tempDateTime.getDate() || "--";
    let year = tempDateTime.getFullYear() || "--";
    let time =
      tempDateTime.toTimeString().split(" ")[0].split(":")[0] +
      ":" +
      tempDateTime.toTimeString().split(" ")[0].split(":")[1];
    let dateTime = `${month} ${date}, ${year}, ${time}`;
    return dateTime;
  } else {
    return "--";
  }
};
export const timeStampToDate = (timeStamp) => {
  if (timeStamp) {
    let timeStmp = timeStamp?.toString()?.includes("T")
      ? timeStamp
      : parseInt(timeStamp) <= 9999
        ? timeStamp
        : parseInt(timeStamp);
    let tempDateTime = new Date(timeStmp);
    let month = MONTH_INDEX[tempDateTime.getMonth()];
    let date = tempDateTime.getDate();
    let year = tempDateTime.getFullYear();
    let dateTime = `${month} ${date}, ${year}`;
    return dateTime;
  } else {
    return "--";
  }
};
export const timeStampToTime = (timeStamp) => {
  let timeStmp = timeStamp?.toString()?.includes("T")
    ? timeStamp
    : parseInt(timeStamp) <= 9999
      ? timeStamp
      : parseInt(timeStamp);
  let tempDateTime = new Date(timeStmp);
  let time =
    tempDateTime.toTimeString().split(" ")[0].split(":")[0] +
    ":" +
    tempDateTime.toTimeString().split(" ")[0].split(":")[1];
  let dateTime = `${time}`;
  return dateTime;
};

export const dateStringStamp = (dateStamp) => {
  let dateString = new Date(dateStamp);
  let formatedDate = `${dateString.getFullYear()}-${dateString.getMonth() < 10 ? "0" : ""
    }${dateString.getMonth() + 1}-${dateString.getDate() < 10 ? "0" : ""
    }${dateString.getDate()}`;
  return formatedDate;
};

export const innerBorder = (color) => {
  return `inset -1px 0 0 ${color}, inset 0 -1px 0 ${color}, inset 1px 0 0 ${color}, inset 0 1px 0 ${color}`;
};

export const innerLeftBorder = (color, width = "2px") => {
  return `inset ${width} 0px 0px 0px ${color}`;
};

export const innerTopBorder = (color, width = "1px") => {
  return `inset 0px ${width} 0px 0px ${color}`;
};

export const innerBottomBorder = (color, width = "1px") => {
  return `inset 0px -${width} 0px 0px ${color}`;
};

export const FIELD_TYPE_LABELS = [
  { id: "text_input", label: "Text Input" },
  { id: "multi_text_input", label: "Multi-line Text Input" },
  { id: "rich_text_editor", label: "Rich Text Editor" },
  { id: "single_select", label: "Single Select" },
  { id: "multiple_select", label: "Multiple Select" },
  // { id: "multiple_checkbox", label: "Multiple Checkbox" },
  { id: "date_picker", label: "Date Picker" },
  { id: "number", label: "Number" },
];

export const getDropdownArrayObj = (array, objKey = "") => {
  const newArray = array?.map((obj) => {
    const { objKey, _id, ...rest } = obj;
    return { label: objKey, id: _id, ...rest };
  });
  console.log("NEWARR ", newArray);
  return newArray;
};

export function replaceKeyInArrayOfObj(array = [], oldKey, newKey) {
  if (array) {
    let newArr = array?.map((obj) => {
      const newObj = {};
      for (const key in obj) {
        if (key === oldKey) {
          newObj[newKey] = obj[key];
        } else {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    });
    return newArr;
  }
  return array;
}

export function debounceHelper(func, delay) {
  let timer;

  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export const generateBreadCrumbDescription = (payload) => {
  const new_description =
    (payload?.file_count
      ? payload?.file_count === 1
        ? `${payload?.file_count} File`
        : `${payload?.file_count} Files`
      : "") +
    `${payload?.file_count && payload?.folder_count ? ", " : " "}` +
    (payload?.folder_count
      ? payload?.folder_count === 1
        ? `${payload?.folder_count} Folder`
        : `${payload?.folder_count} Folders`
      : "");
  return new_description;
};

export const processStatsKeyValuePairs = (payload = {}) => {
  const stats_keys = {
    open: payload?.open?.value || 0,
    sent: payload?.sent?.value || 0,
    click: payload?.click?.value || 0,
    bounce: payload?.bounce?.value || 0,
    deliver: payload?.deliver?.value || 0,
    reply: payload?.reply?.value || 0,
    unsubscribe: payload?.unsubscribe?.value || 0,
    lead: payload?.lead?.value || 0,
    meeting_book: payload?.meeting_book?.value || 0,
  };
  return stats_keys;
};

export function paginateAndSort(
  array,
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue = "",
  searchKeys = []
) {
  try {
    // Calculate the start and end indices based on the page and pageSize
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Sort the array based on the sortBy and sortOrder parameters
    if (sortBy?.length && sortOrder?.length) {
      array.sort((obj1, obj2) => {
        let firstEle = obj1[sortBy]?.toString()?.toLowerCase();
        let secondEle = obj2[sortBy]?.toString()?.toLowerCase();

        // Handle null and undefined cases
        if (firstEle === null || firstEle === undefined) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (secondEle === null || secondEle === undefined) {
          return sortOrder === "asc" ? 1 : -1;
        }

        // Handle different data types
        if (typeof firstEle !== typeof secondEle) {
          return typeof firstEle < typeof secondEle ? -1 : 1;
        }

        // Use default comparison for other types (numbers and strings)
        if (firstEle < secondEle) {
          return sortOrder === "asc" ? -1 : 1;
        } else if (firstEle > secondEle) {
          return sortOrder === "asc" ? 1 : -1;
        } else {
          return 0;
        }
      });
    }

    // Search in Data
    if (searchValue?.length && searchKeys?.length) {
      try {
        let tempArr = [];
        for (let index = 0; index < searchKeys.length; index++) {
          let temp_arr = array?.filter((item) => {
            return item[searchKeys[index]]
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase());
          });
          tempArr = mergeArraysAndRemoveDuplicates(
            tempArr,
            temp_arr,
            searchKeys[0]
          );
        }
        return array?.length > 0
          ? {
            records: tempArr?.slice(startIndex, endIndex),
            total_records: tempArr?.length,
          }
          : [];
      } catch (error) {
        console.log("error------> ", error);
      }
    }
    // Search in Data
    return array?.length > 0
      ? {
        records: array?.slice(startIndex, endIndex),
        total_records: array?.length,
      }
      : [];
  } catch (error) {
    return array || [];
  }
}

export const convertBase64 = (file) => {
  const error = {};

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = () => {
      reject(error);
    };
  });
};

export function mergeArraysAndRemoveDuplicates(array1, array2, keyToCheck) {
  const mergedArray = array1.concat(array2);

  const uniqueArray = mergedArray.reduce((result, obj) => {
    if (!result.some((item) => item[keyToCheck] === obj[keyToCheck])) {
      result.push(obj);
    }
    return result;
  }, []);

  return uniqueArray;
}

export const filterCheckInArrayOfObject = (data, keysToCheck, selectedKey) => {
  // this variable will be returned
  let filteredArr = [];
  // this variable will be returned

  // storing all the key of object in array.
  let keysArr = Object?.keys(keysToCheck);
  // storing all the key of object in array.

  // storgin all the key values into single array
  let keysArrValue = [];
  keysArr?.forEach((item) => {
    keysArrValue = [...keysArrValue, ...keysToCheck[item]];
  });

  // storgin all the key values into single array

  try {
    // filtering data, first loop is on value of keys and second is on data we are receiving as parameter also selected key is clicked filter key coming as a parameter.
    keysArrValue?.map((item) => {
      data?.map((itemData) => {
        if (itemData[selectedKey]?.toString() === item) {
          filteredArr = [...filteredArr, itemData];
        }
      });
    });
    // filtering data, first loop is on value of keys and second is on data we are receiving as parameter also selected key is clicked filter key coming as a parameter.
  } catch (error) {
    console.log("error--------------> ", error);
  }
  return filteredArr;
};

export const filterArrayCheck = (data, keysToCheck, selectedKey) => {
  // this variable will be returned
  let filteredArr = [];
  // this variable will be returned

  // storing all the key of object in array.
  let keysArr = Object?.keys(keysToCheck);
  // storing all the key of object in array.
  // storgin all the key values into single array
  let keysArrValue = [];
  keysArr?.forEach((item) => {
    keysArrValue = [...keysArrValue, ...keysToCheck[item]];
  });

  // storgin all the key values into single array
  try {
    // filtering data, first loop is on value of keys and second is on data we are receiving as parameter also selected key is clicked filter key coming as a parameter.
    keysArrValue?.map((item) => {
      data?.map((itemData) => {
        if (itemData["user_id"]?.toString() === item) {
          filteredArr = [...filteredArr, itemData];
        }
      });
    });
    // filtering data, first loop is on value of keys and second is on data we are receiving as parameter also selected key is clicked filter key coming as a parameter.
  } catch (error) {
    console.log("error--------------> ", error);
  }
  return filteredArr;
};

export const generateSignatureHTML = (data) => {
  const name = `<p style="font-weight:500;font-size:18px;color: #212833;margin:0;" >${data?.first_name} ${data?.last_name}</p>`;
  const job_title = `<p style="font-weight:400;font-size:14px;color: #626b7e;margin:0;" >${data?.job_title}</p>`;
  const company_name = `<p style="font-weight:400;font-size:14px;color: #626b7e;margin:0;" >${data?.company_name}</p>`;
  const phone_number = `<p style="font-weight:400;font-size:14px;color: #626b7e;margin:0;" >${data?.phone_number}</p>`;
  const address = `<span style="font-weight:400;font-size:14px;color: #626b7e;margin:0;" >${data?.address_line_1 || ""
    }${data?.address_line_1 && data?.address_line_2 ? ", " : ""}${data?.address_line_2 || ""
    }${(data?.address_line_1 || data?.address_line_2) && data?.city ? ", " : ""}${data?.city || ""
    }${data?.city && data?.state ? ", " : ""}<br>${data?.state || ""}${(data?.city || data?.state) && data?.country ? ", " : ""
    }${data?.country || ""}${data?.country ? ", " : ""}${data?.zip_code || ""
    } </span>`;
  return `
    <div>
      ${name}
      ${job_title}
      ${company_name}
      ${phone_number}
      ${address}
    </div>`;
};

export const dropdownValuesFromId = (items, id) => {
  let tempItems = [];
  items?.forEach((item) => {
    if (item?.id === id && item?.field_type?.toString() === "Single Select") {
      tempItems = item?.value;
    }
  });
  return tempItems;
};
//Edit booked meeting

export const TimeStamp = (hours, minutes) => {
  if (hours.toString().length === 1) {
    hours = "0" + hours;
  }
  if (minutes.toString().length === 1) {
    minutes = "0" + minutes;
  }

  return hours + ":" + minutes;
};

export const NotTimeStamp = (hoursTime, minutesTime) => {
  if (minutesTime >= 45) hoursTime++;
  hoursTime = hoursTime % 24;
  minutesTime += 15;
  minutesTime = minutesTime % 60;
  if (hoursTime.toString().length === 1) {
    hoursTime = "0" + hoursTime;
  }
  if (minutesTime.toString().length === 1) {
    minutesTime = "0" + minutesTime;
  }

  return hoursTime + ":" + minutesTime;
};

export const convertTime12to24Format = (available, range) => {
  const avail = available?.[0];
  let [time, modifier] = avail.split(" ");
  if (range === "To" && time === "12" && modifier === "AM") {
    time = "23:59";
  } else if (time === "12") {
    time = "0";
  }
  if (modifier === "PM") {
    time = parseInt(time, 10) + 12;
  }
  if (time < 10) {
    time = "0" + time;
  }
  return `${time}`;
};

export const filterTableValue = (value) => {
  let result;
  if (typeof value === "string") {
    result = value?.slice(0, 15);
    if (value.length > 15) {
      result += "...";
    }
    return result;
  }
  return value;
};

export const descriptionValue = (value) => {
  let result;
  if (typeof value === "string") {
    result = value?.slice(0, 50);
    if (value?.length > 50) {
      result += "...";
    }
    return result;
  }
  if (typeof value?.props?.children === "string") {
    result = value?.props?.children?.slice(0, 100);
    if (value?.props?.children.length > 50) {
      result += "...";
    }
    return result;
  }
  return value?.props?.children || value;
};

export const convertFormat = (value) => {
  let tempDateTime = new Date(value);
  let month = MONTH_INDEX[tempDateTime.getMonth()] || "--";
  let date = tempDateTime.getDate() || "--";
  let year = tempDateTime.getFullYear() || "--";

  let dateTime = `${month} ${date}, ${year}`;
  return dateTime;
};

export const convertTime12to24 = (available) => {
  let [time, modifier] = String(available).split(" ");

  if (time === "12") {
    time = "0";
  }

  if (modifier === "PM") {
    time = parseInt(time, 10) + 12;
  }
  if (time < 10) {
    time = "0" + time;
  }
  return `${time}`;
};

//Time chunks in preview -Schedule section
export function customiseTime(mini_chunk) {
  let hrs = parseInt(Number(mini_chunk));
  let min = Math.round((Number(mini_chunk) - hrs) * 60);
  if (min.toString() === "0") min = "00";
  if (hrs < 10) hrs = "0" + hrs;
  let clocktime = hrs + ":" + min;
  clocktime = convertTime24to12(clocktime);
  return clocktime;
}

export function convertTime24to12(clocktime) {
  // Check correct time format and split into components
  clocktime = clocktime.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [
    clocktime,
  ];

  if (clocktime.length > 1) {
    clocktime = clocktime.slice(1);
    clocktime[5] = +clocktime[0] < 12 ? " AM" : " PM";
    clocktime[0] = +clocktime[0] % 12 || 12;
  }
  return clocktime.join("");
}

export const dateTimeStringEmailLog = (timeStamp) => {
  if (timeStamp) {
    let timeStmp = parseInt(timeStamp);
    let tempDateTime = new Date(timeStmp);
    let month = MONTH_INDEX[tempDateTime.getMonth()] || "--";
    let date = tempDateTime.getDate() || "--";
    let year = tempDateTime.getFullYear() || "--";
    let time =
      tempDateTime.toTimeString().split(" ")[0].split(":")[0] +
      ":" +
      tempDateTime.toTimeString().split(" ")[0].split(":")[1];
    let dateTime = `${month} ${date}, ${year} ${time} GMT+5:30`;
    return dateTime;
  } else {
    return "--";
  }
};

export const getContactsNames = (data = [], enclosingRequired = true) => {
  let temp_data = [...data];

  let records = [];
  temp_data.forEach((item) => {
    let obj = {};
    obj.id = item?._id || item.id;
    if (enclosingRequired) {
      obj.label = `${item?.first_name || ""} ${item?.label || ""}${item?.name || ""
        } ${item?.email?.length ? "<" + item?.email + ">" : ""}`;
    } else {
      obj.label = `${item?.first_name || ""} ${item?.label || ""}${item?.name || ""
        } ${item?.email || ""}`;
    }
    obj.email = item?.email;
    obj.name =
      `${item?.first_name || ""} ${item?.last_name || ""}` ||
      `${item?.email || ""}`;

    records.push(obj);
  });
  return records;
};

export const convertByteToKb = (bytes) => {
  const KB = Math.floor(bytes / 1024);
  if (KB == 0) {
    return `${bytes} bytes`;
  } else {
    return `${KB} Kb`;
  }
};

export const generateNameFromEmail = (email, name = "") => {
  if (name) return name;
  const email_pre = email?.split("@")?.[0];
  return email_pre?.split(".").join(" ").toUpperCase();
};

export function isValidUrl(url) {
  // Regular expression for a simple URL validation
  const urlPattern =
    /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)|localhost(\:\d+)?(\/[\w-]+)*\/?(\?[\w%&=]+)?(#\w+)?$/;
  // Test the provided string against the regular expression
  return urlPattern.test(url);
}

export function trimObjectValues(obj) {
  if (typeof obj !== "object" || obj === null) {
    // If obj is not an object or is null, return it as is
    return obj;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "string") {
        // If the value is a string, trim it
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object") {
        // If the value is an object, recursively trim its values
        obj[key] = trimObjectValues(obj[key]);
      }
      // Note: Numbers, booleans, and other types are left unchanged
    }
  }

  return obj;
}

export const dataReferenceMapping = (items = [], references = {}) => {
  const new_items = [];
  for (let i in items) {
    const item = items[i];
    const new_item = { ...item };
    for (let j in references) {
      if (item[j]) {
        new_item[references[j]] = item[j];
        // break;
        // item[i][]
      }
    }
    new_items.push(new_item);
  }
  return new_items;
};

export function preloadImages(imageLinks = [], batchSize, interval) {
  var index = 0;

  function loadBatch() {
    for (var i = 0; i < batchSize && index < imageLinks.length; i++, index++) {
      var image = new Image();
      image.src = imageLinks[index];
    }

    if (index < imageLinks.length) {
      setTimeout(loadBatch, interval);
    }
  }

  loadBatch();
}

function removeCommentedStyles(cssText) {
  return cssText.replace(/\/\*[^]*?\*\//g, ""); // Regex to match /* ... */
}

function replaceSubstring(
  mediaStringElement,
  startIndex,
  endIndex,
  replacement
) {
  const before = mediaStringElement.substring(0, startIndex + 1);
  const after = mediaStringElement.substring(endIndex);

  const replacedString = before + replacement + after;
  return replacedString;
}

export const removeStylesFromHeadHtml = (html = "", id) => {
  if (html.includes("<style")) {
    const styleTagRegex = /<style\b[^>]*>([\s\S]*?)<\/style>/gi;
    const only_style_tag_content_array = html.match(styleTagRegex);

    const results = [];

    for (let i in only_style_tag_content_array) {
      const only_style_tag_content = only_style_tag_content_array[i];
      const styleContentRegex = /<style\b[^>]*>([\s\S]*?)<\/style>/i;
      const mediaQueryRegex = /@media[^{]*{(?:[^{}]*{[^{}]*})*[^{}]*}/g;

      const contentMatch = only_style_tag_content.match(styleContentRegex);
      const mediaQueryBlocks = contentMatch[1].match(mediaQueryRegex);

      let complete_string = contentMatch[1];

      if (mediaQueryBlocks) {
        for (const eachFilterElement of mediaQueryBlocks) {
          complete_string = complete_string.replace(eachFilterElement, "");
          const openingBraceIndex = eachFilterElement.indexOf(
            "{",
            eachFilterElement
          );
          const closingBraceIndex = eachFilterElement.lastIndexOf(
            "}",
            eachFilterElement
          );
          const rules = [];
          let cssString = contentMatch[1].replace(/\/\*[\s\S]*?\*\//g, "");
          const regex =
            /(?:\/\*(?:[\s\S]*?)\*\/)|(?:\s*([^{}]+)\s*{([^{}]+)})/gm;
          let match;
          let parsedResult = "";
          while ((match = regex.exec(cssString)) !== null) {
            if (!match[0].startsWith("/*") && !match[0].includes("//")) {
              if (match[1] && match[2]) {
                const selector = id + " " + match[1].trim();
                const styles = match[2]
                  .split(";")
                  .filter(Boolean)
                  .map((prop) => prop.trim());
                parsedResult =
                  parsedResult + `#${selector}` + `{ ${styles?.join(";")} }`;
                rules.push({ selector, styles });
              }
            }
          }
          const res = replaceSubstring(
            eachFilterElement,
            openingBraceIndex,
            closingBraceIndex,
            parsedResult
          );
          results.push(res);
        }
        if (complete_string.length) {
          const rules = [];
          let cssString = complete_string.replace(/\/\*[\s\S]*?\*\//g, "");
          const regex =
            /(?:\/\*(?:[\s\S]*?)\*\/)|(?:\s*([^{}]+)\s*{([^{}]+)})/gm;
          let match;
          let parsedResult = "";
          while ((match = regex.exec(cssString)) !== null) {
            if (!match[0].startsWith("/*") && !match[0].includes("//")) {
              if (match[1] && match[2]) {
                const selector = id + " " + match[1].trim();
                const styles = match[2]
                  .split(";")
                  .filter(Boolean)
                  .map((prop) => prop.trim());
                parsedResult =
                  parsedResult + `#${selector}` + `{ ${styles?.join(";")} }`;
                rules.push({ selector, styles });
              }
            }
          }
          results.push(parsedResult);
        }
      } else {
        const rules = [];
        let cssString = contentMatch[1].replace(/\/\*[\s\S]*?\*\//g, "");
        const regex = /(?:\/\*(?:[\s\S]*?)\*\/)|(?:\s*([^{}]+)\s*{([^{}]+)})/gm;
        let match;
        let parsedResult = "";
        while ((match = regex.exec(cssString)) !== null) {
          if (!match[0].startsWith("/*") && !match[0].includes("//")) {
            if (match[1] && match[2]) {
              const selector = id + " " + match[1].trim();
              const styles = match[2]
                .split(";")
                .filter(Boolean)
                .map((prop) => prop.trim());
              parsedResult =
                parsedResult + `#${selector}` + `{ ${styles?.join(";")} }`;
              rules.push({ selector, styles });
            }
          }
        }
        results.push(parsedResult);
      }
    }
    const endresult = results.filter((item) => item).join("");

    html = html.replaceAll(/<style\b[^>]*>[\s\S]*?<\/style>/gi, ``);

    html = html.replaceAll("<head", `<div`);
    html = html.replaceAll("</head>", `</div>`);

    html = html.replaceAll("<body", `<div`);
    html = html.replaceAll("</body>", `</div>`);

    html = html.replaceAll("<html", `<div`);
    html = html.replaceAll("</html>", `</div>`);

    html = html + `<style>${endresult}</style>`;
    return html;
  } else {
    return html;
  }
};

export const getContactHeading = (details) => {
  let heading = "";
  if (details?.first_name && details?.last_name) {
    heading = details?.first_name + " " + details?.last_name;
  } else if (details?.first_name) {
    heading = details?.first_name;
  } else if (details?.last_name) {
    heading = details?.last_name;
  } else if (details?.email) {
    heading = details?.email;
  } else if (details?.direct_phone_number) {
    heading = details?.direct_phone_number;
  } else if (details?.contact_linkedin_url) {
    heading = details?.contact_linkedin_url;
  }

  return heading;
};

export const getCompanyHeading = (details) => {
  let heading = "";
  if (details?.company_name) {
    heading = details?.company_name;
  } else if (details?.company_website) {
    heading = details?.company_website;
  } else if (details?.company_linkedin_url) {
    heading = details?.contact_linkedin_url;
  }
  return heading;
};

export function decompressString(compressedString) {
  try {
    // Convert base64 string to Uint8Array
    const compressedUint8Array = Uint8Array.from(atob(compressedString), (c) =>
      c.charCodeAt(0)
    );

    // Decompress using pako
    const decompressedArray = pako.inflate(compressedUint8Array, {
      to: "string",
    });
    return decompressedArray;
  } catch (error) {
    console.error("Error decompressing content:", error);
    return null; // Handle errors appropriately in your application
  }
}

export function compressString(inputString) {
  try {
    // Convert the input string to a Uint8Array
    const inputUint8Array = new TextEncoder().encode(inputString);

    // Compress using pako
    const compressedUint8Array = pako.deflate(inputUint8Array, {
      to: "string",
    });

    // Convert the compressed data to base64
    const compressedString = btoa(
      String.fromCharCode.apply(null, compressedUint8Array)
    );

    return compressedString;
  } catch (error) {
    console.error("Error compressing content:", error);
    return null; // Handle errors appropriately in your application
  }
}

export function deepObjectEquality(obj1, obj2) {
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepObjectEquality(obj1[key], obj2[key])) {
      return false;
    }
  }

  const arrayKeys1 = keys1.filter((key) => Array.isArray(obj1[key]));
  const arrayKeys2 = keys2.filter((key) => Array.isArray(obj2[key]));

  if (arrayKeys1.length !== arrayKeys2.length) {
    return false;
  }

  for (const key of arrayKeys1) {
    const index = arrayKeys2.indexOf(key);
    if (index === -1) {
      return false;
    }

    const arr1 = obj1[key].sort().join(",");
    const arr2 = obj2[key].sort().join(",");

    if (arr1 !== arr2) {
      return false;
    }
  }

  return true;
}

export const calculateSizeInBytes = (str) => {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(str);
  return bytes.length;
};

export const getTimeStampForTimeZone = (timeZone) => {
  const now = new Date();
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
  });

  const formattedDate = formatter.format(now);
  const timestamp = new Date(formattedDate).getTime();

  return timestamp;
};

export const hextToRgbaWithOpacity = (hexColor, opacity) => {
  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  // Convert to RGBA
  const rgbaColor = "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";
  return rgbaColor;
};


//US Format amount
export function formatCurrency(amount) {
  // Use toLocaleString to format the number with commas and currency symbol
  return parseInt(amount)?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

// capitalize the first letter of string.
export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// export const handleScroll = (scrollTop) => {

//   // Do something with the scroll position, e.g., log it
//   console.log('Scroll position:', scrollTop);
//   var style = document.createElement('style');

//   // Append CSS rules to the style element
//   style.appendChild(document.createTextNode(`
//   #modal_body::-webkit-scrollbar {
//     background-color: #ffffff !important;
//     width: 5px;
//     height: 5px;
//     z-index: 1000
//   }

//   #modal_body::-webkit-scrollbar-thumb {
//     background-color: #CBCBCB !important;
//     height: 5px;
//     width: 5px;
//   }
// `));

//   document.head.appendChild(style);
// };

// export const handleMouseOut = () => {
//   // Do something when the mouse leaves the container
//   console.log('Mouse left the container');

//   var style = document.createElement('style');

//   // Append CSS rules to the style element
//   style.appendChild(document.createTextNode(`
//   #modal_body::-webkit-scrollbar {
//     background-color: #ffffff !important;
//     height: 0px;
//     width: 0px;
//   }

//   #modal_body::-webkit-scrollbar-thumb {
//     background-color: transparent !important;
//     height: 0px;
//     width: 0px;
//   }
// `));

//   document.head.appendChild(style);
// };
