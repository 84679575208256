import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "./styles";
import IconComponent from "../Icon/IconComponent";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import { BRAND, GREY } from "../../utils/constants/colors";
import TypographyInline from "../Typography/TypographyInline";
import { STRINGS } from "../strings";
import { decompressString, filterDynamicQuery, textEllipsesHandler } from "../../utils/helpers";
import { filterMultipleList } from "../../redux/actions/filterActions";
import { postRequest } from "../../Apis";
import { dealsEndpoints } from "../../utils/constants/httpConstants";
import { useNavigate } from "react-router-dom";

const DynamicValueFilter = ({ id = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterMultipleListSelector = useSelector(
    (state) => state?.filterMultipleList?.payload
  );

  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList?.data
  );

  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );

  let propertiesPayload = localStorage.getItem("properties") ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))?.properties_key_value : getPropertiesListSelector?.properties_key_value;
  let payload = filterDynamicQuery(
    filterMultipleListSelector,
    propertiesPayload
  );

  const [isKnown, setIsKnown] = useState(false);
  const [isUnknown, setIsUnknown] = useState(false);
  const [anyOf, setAnyOf] = useState("");
  const [anyOfValue, setAnyOfValue] = useState("");
  const [notAnyOf, setNotAnyOf] = useState("");
  const [notAnyOfValue, setNotAnyOfValue] = useState("");
  const [type, setType] = useState("");
  const [numberRange, setNumberRange] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [dealNameValue, setDealNameValue] = useState("");
  const [checkboxValue, setCheckboxValue] = useState([]);
  const raw_id = id?.replace("cb-", "");

  const objectTypes = [STRINGS.NUMBER, STRINGS.DATE_PICKER];

  const handleRemoveKnownUnknownFilter = () => {
    if (isKnown) {
      let temp_payload = {
        ...filterMultipleListSelector,
        [id]: {
          ...filterMultipleListSelector?.[id],
          isKnown: false,
        },
      };
      dispatch(filterMultipleList(temp_payload));
    } else {
      let temp_payload = {
        ...filterMultipleListSelector,
        [id]: {
          ...filterMultipleListSelector?.[id],
          isUnknown: false,
        },
      };
      dispatch(filterMultipleList(temp_payload));
    }
  };

  const handleRemoveIsAnyOfFilter = () => {
    let temp_payload = {
      ...filterMultipleListSelector,
      [id]: {
        ...filterMultipleListSelector?.[id],
        anyOf: false,
        anyOfList: [],
      },
    };
    dispatch(filterMultipleList(temp_payload));
  };

  const handleRemoveIsNotAnyOfFilter = () => {
    let temp_payload = {
      ...filterMultipleListSelector,
      [id]: {
        ...filterMultipleListSelector?.[id],
        notAnyOf: false,
        notAnyOfList: [],
      },
    };
    dispatch(filterMultipleList(temp_payload));
  };

  const handleRemoveNumberRangeFilter = () => {
    try {
      let temp_payload = {
        ...filterMultipleListSelector,
      };
      delete temp_payload?.[id];
      dispatch(filterMultipleList({ ...temp_payload }));
    } catch (error) {
      console.log('error______', error);
    }

  };

  const handleRemoveDateRangeFilter = () => {
    let temp_payload = {
      ...filterMultipleListSelector,
    };
    delete temp_payload?.[id];
    dispatch(filterMultipleList(temp_payload));
  };

  const handleRemoveDealNameFilter = () => {
    let temp_payload = {
      ...filterMultipleListSelector,
    };
    delete temp_payload?.deal_name;
    dispatch(filterMultipleList(temp_payload));
  };

  const handleRemoveCheckboxFilter = () => {
    try {
      let temp_payload = {
        ...filterMultipleListSelector,
      };
      temp_payload[id] = [];
      dispatch(filterMultipleList({ ...temp_payload }));
    } catch (error) {
      console.log('error______', error);
    }
  }

  const conditionHandler = async (id) => {
    let field_type = propertiesPayload?.[raw_id]?.field_type[0]?.toString(0);
    setType(field_type);
    switch (field_type) {
      case STRINGS.NUMBER:
        setNumberRange({
          min: payload?.[`min-${id}`],
          max: payload?.[`max-${id}`],
        });
        break;

      case STRINGS.DATE_PICKER:
        setDateRange({
          from: payload?.[`from-${id}`],
          to: payload?.[`to-${id}`],
        });
        break;

      case STRINGS.MULTIPLE_CHECKBOX:
        let temp_val = [...(payload?.[id] || [])]?.toString();
        let final_val = temp_val?.replace(",", ", ");
        // setCheckboxFilter(true);
        setCheckboxValue(final_val)
        break;

      default:
        if (payload?.exist_fields?.includes(id)) {
          setIsKnown(true);
          setIsUnknown(false);
          setAnyOf(false);
          setNotAnyOf(false);
          setAnyOfValue([]);
          setNotAnyOfValue([]);
        } else if (payload?.not_exist_fields?.includes(id)) {
          setIsUnknown(true);
          setIsKnown(false);
          setAnyOf(false);
          setNotAnyOf(false);
          setAnyOfValue([]);
          setNotAnyOfValue([]);
        } else if (id === STRINGS.DEAL_NAME_SMALL) {
          if (payload?.deal_name?.length) {
            setDealNameValue(payload?.deal_name);
          }
        } else {
          if (payload?.[id]?.length) {
            setAnyOf(true);
            setIsKnown(false);
            setIsUnknown(false);
            if (id === "deal_owner") {
              let temp_val = [...(payload?.[id] || [])];
              let final_val = tenantUsersListSelector?.filter((user) => {
                if (temp_val?.includes(user?._id)) {
                  return user;
                }
              });

              let display_value = final_val
                ?.map((val) => {
                  return `${val?.first_name} ${val?.last_name}`;
                })
                ?.toString()
                ?.replace(",", ", ");

              setAnyOfValue(display_value);
            } else if (id === "associated_contact") {
              let temp_val = [...(payload?.[id] || [])];
              let response_contacts = [];
              try {
                const response = await postRequest(
                  navigate,
                  dealsEndpoints.getAssociatedContact,
                  {
                    record_ids: temp_val,
                  }
                );
                response_contacts = [...response?.data?.data?.records];
              } catch (error) {
                console.log("Error----->>>>>>> ", error);
              }
              // storing associated contact into the required format.
              let associated_contact = response_contacts?.filter(item => temp_val?.includes(item?._id))?.map(item => `${item?.first_name || ""} ${item?.last_name || ""}`);
              let final_val = associated_contact?.toString()?.replace(",", ", ");
              setAnyOfValue(final_val)
            } else {
              let temp_val = [...(payload?.[id] || [])]?.toString();
              let final_val = temp_val?.replace(",", ", ");
              setAnyOfValue(final_val);
            }
          } else {
            setAnyOfValue([]);
            setIsKnown(false);
            setIsUnknown(false);
          }

          if (payload?.[`${id}-not`]?.length) {
            setNotAnyOf(true);
            setIsKnown(false);
            setIsUnknown(false);
            if (id === "deal_owner") {
              let temp_val = [...(payload?.[`${id}-not`] || [])];
              let final_val = tenantUsersListSelector?.filter((user) => {
                if (temp_val?.includes(user?._id)) {
                  return user;
                }
              });

              let display_value = final_val
                ?.map((val) => {
                  return `${val?.first_name} ${val?.last_name}`;
                })
                ?.toString()
                ?.replace(",", ", ");

              setNotAnyOfValue(display_value);
            } else if (id === "associated_contact") {
              let temp_val = [...(payload?.[`${id}-not`] || [])];
              let response_contacts = [];
              try {
                const response = await postRequest(
                  navigate,
                  dealsEndpoints.getAssociatedContact,
                  {
                    record_ids: temp_val,
                  }
                );
                response_contacts = [...response?.data?.data?.records];
              } catch (error) {
                console.log("Error----->>>>>>> ", error);
              }
              // storing associated contact into the required format.
              // storing associated contact into the required format.
              let associated_contact = response_contacts?.filter(item => temp_val?.includes(item?._id))?.map(item => `${item?.first_name || ""} ${item?.last_name || ""}`);
              let final_val = associated_contact?.toString()?.replace(",", ", ");
              setNotAnyOfValue(final_val)
            } else {
              let temp_value = [...(payload?.[`${id}-not`] || [])]?.toString();
              let final_value = temp_value?.replace(",", ", ");
              setNotAnyOfValue(final_value);
            }
          } else {
            setNotAnyOfValue([]);
            setIsKnown(false);
            setIsUnknown(false);
          }
        }
        break;
    }
  };

  useEffect(() => {
    if (id?.length) {
      conditionHandler(id);
    }
  }, [id, filterMultipleListSelector]);

  return (
    <>
      {/* UI for all filter label instead of number and date picker object type */}
      {!objectTypes?.includes(type) ? (
        <>
          {/* label for isknown or isUnknown */}
          {isKnown || isUnknown ? (
            <div style={styles.selectedDynamicFilterBox}>
              <div
                style={styles.cancelIcon}
                onClick={handleRemoveKnownUnknownFilter}
              >
                <IconComponent
                  iconLabel={ICON_LABELS.CANCEL}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                />
              </div>

              <div style={styles.filterValues}>
                <TypographyInline
                  label={propertiesPayload?.[id]?.property_name}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={GREY.PRIMARY}
                  isNoWrap={true}
                  isCapitalise={true}
                />
                <TypographyInline
                  label={STRINGS.IS_EQUAL_TO}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                  isNoWrap={true}
                />
                <TypographyInline
                  label={isKnown ? STRINGS.KNOWN : STRINGS.UNKNOWN}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={BRAND.PRIMARY}
                  isNoWrap={true}
                />
              </div>
            </div>
          ) : null}

          {/* label for is any of */}
          {anyOf && anyOfValue?.length ? (
            <div style={styles.selectedDynamicFilterBox}>
              <div
                style={styles.cancelIcon}
                onClick={handleRemoveIsAnyOfFilter}
              >
                <IconComponent
                  iconLabel={ICON_LABELS.CANCEL}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                />
              </div>

              <div style={styles.filterValues}>
                <TypographyInline
                  label={propertiesPayload?.[id]?.property_name}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={GREY.PRIMARY}
                  isNoWrap={true}
                  isCapitalise={true}
                />
                <TypographyInline
                  label={STRINGS.IS_EQUAL_TO}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                  isNoWrap={true}
                />
                <TypographyInline
                  label={textEllipsesHandler(anyOfValue, 45)}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={BRAND.PRIMARY}
                  isNoWrap={true}
                  isEllipses={true}
                />
              </div>
            </div>
          ) : null}

          {/* label for is not any of */}
          {notAnyOf && notAnyOfValue?.length ? (
            <div style={styles.selectedDynamicFilterBox}>
              <div
                style={styles.cancelIcon}
                onClick={handleRemoveIsNotAnyOfFilter}
              >
                <IconComponent
                  iconLabel={ICON_LABELS.CANCEL}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                />
              </div>

              <div style={styles.filterValues}>
                <TypographyInline
                  label={propertiesPayload?.[id]?.property_name}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={GREY.PRIMARY}
                  isNoWrap={true}
                  isCapitalise={true}
                />
                <TypographyInline
                  label={STRINGS.IS_NOT_EQUAL_TO}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                  isNoWrap={true}
                />
                <TypographyInline
                  label={textEllipsesHandler(notAnyOfValue, 45)}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={BRAND.PRIMARY}
                  isNoWrap={true}
                  isEllipses={true}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      {/* UI for Number Range */}
      {type === STRINGS.NUMBER ? (
        <div style={styles.selectedDynamicFilterBox}>
          <div
            style={styles.cancelIcon}
            onClick={handleRemoveNumberRangeFilter}
          >
            <IconComponent
              iconLabel={ICON_LABELS.CANCEL}
              color={GREY.TERTIARY}
              fontSize={fontSize.MD}
            />
          </div>

          <div style={styles.filterValues}>
            <TypographyInline
              label={propertiesPayload?.[id]?.property_name}
              size={"sm"}
              fontWeight={fontWeight.SEMIBOLD}
              color={GREY.PRIMARY}
              isNoWrap={true}
              isCapitalise={true}
            />
            <TypographyInline
              label={STRINGS.IS_IN_BETWEEN}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.SECONDARY}
              isNoWrap={true}
            />
            <TypographyInline
              label={`${numberRange?.min} and ${numberRange?.max}`}
              size={"sm"}
              fontWeight={fontWeight.SEMIBOLD}
              color={BRAND.PRIMARY}
              isNoWrap={true}
            />
          </div>
        </div>
      ) : null}

      {/* UI for Date Range */}
      {type === STRINGS.DATE_PICKER ? (
        <div style={styles.selectedDynamicFilterBox}>
          <div style={styles.cancelIcon} onClick={handleRemoveDateRangeFilter}>
            <IconComponent
              iconLabel={ICON_LABELS.CANCEL}
              color={GREY.TERTIARY}
              fontSize={fontSize.MD}
            />
          </div>

          <div style={styles.filterValues}>
            <TypographyInline
              label={propertiesPayload?.[id]?.property_name}
              size={"sm"}
              fontWeight={fontWeight.SEMIBOLD}
              color={GREY.PRIMARY}
              isNoWrap={true}
              isCapitalise={true}
            />
            <TypographyInline
              label={STRINGS.IS_IN_BETWEEN}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.SECONDARY}
              isNoWrap={true}
            />
            <TypographyInline
              label={`${dateRange?.from} and ${dateRange?.to}`}
              size={"sm"}
              fontWeight={fontWeight.SEMIBOLD}
              color={BRAND.PRIMARY}
              isNoWrap={true}
            />
          </div>
        </div>
      ) : null}

      {/* UI for Deal Name */}
      {dealNameValue?.length ? (
        <div style={styles.selectedDynamicFilterBox}>
          <div style={styles.cancelIcon} onClick={handleRemoveDealNameFilter}>
            <IconComponent
              iconLabel={ICON_LABELS.CANCEL}
              color={GREY.TERTIARY}
              fontSize={fontSize.MD}
            />
          </div>

          <div style={styles.filterValues}>
            <TypographyInline
              label={propertiesPayload?.deal_name?.property_name}
              size={"sm"}
              fontWeight={fontWeight.SEMIBOLD}
              color={GREY.PRIMARY}
              isNoWrap={true}
              isCapitalise={true}
            />
            <TypographyInline
              label={STRINGS.IS_EQUAL_TO}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.SECONDARY}
              isNoWrap={true}
            />
            <TypographyInline
              label={textEllipsesHandler(dealNameValue, 45)}
              size={"sm"}
              fontWeight={fontWeight.SEMIBOLD}
              color={BRAND.PRIMARY}
              isNoWrap={true}
            />
          </div>
        </div>
      ) : null}

      {/* UI for Checkbox FIlter */}
      {checkboxValue?.length ?
        <div style={styles.selectedDynamicFilterBox}>
          <div style={styles.cancelIcon} onClick={handleRemoveCheckboxFilter}>
            <IconComponent
              iconLabel={ICON_LABELS.CANCEL}
              color={GREY.TERTIARY}
              fontSize={fontSize.MD}
            />
          </div>

          <div style={styles.filterValues}>
            <TypographyInline
              label={propertiesPayload?.[raw_id]?.property_name}
              size={"sm"}
              fontWeight={fontWeight.SEMIBOLD}
              color={GREY.PRIMARY}
              isNoWrap={true}
              isCapitalise={true}
            />
            <TypographyInline
              label={STRINGS.IS_EQUAL_TO}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={GREY.SECONDARY}
              isNoWrap={true}
            />
            <TypographyInline
              label={checkboxValue}
              size={"sm"}
              fontWeight={fontWeight.SEMIBOLD}
              color={BRAND.PRIMARY}
              isNoWrap={true}
            />
          </div>
        </div> : null
      }
    </>
  );
};

export default DynamicValueFilter;
