import { Box } from "@mui/system";
import React, { useState } from "react";
import parse from "html-react-parser";
import {
  DYNAMIC_TAGS,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import {
  getErrorMessage,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import { campaignEndpoints, sequenceEndPoints } from "../../../../utils/constants/httpConstants";
import { useSelector } from "react-redux";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../strings";
import CustomModal from "../../../../components/Modals/CustomModal";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { generateSignatureHTML } from "../../../../utils/helpers";

import classes from "../markComponents.module.css";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
const PreviewMail = ({ subject, body, open, setOpen }) => {

  const navigate = useNavigate();
  const [parsedBody, setParsedBody] = useState("");
  const [parsedSubject, setParsedSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const signatureSelector = useSelector((state) => state.getSignature);
  const userDetailsSelector = useSelector((state) => state.userDetails.payload)
  const data = signatureSelector?.payload?.data?.signature;
  const handleSendMailToMyself = async () => {
    // send mail to myself.
    try {
      setLoading(true);
      const signature = document.getElementById(
        STRINGS.SIGNATURE_DETAILS
      ).innerHTML;
      // const finalBody = addSignature(parsedBody);
      const payload = {
        subject: parsedSubject,
        body: `<div> ${parsedBody} ${signature} </div>`
      };
      const response = await postRequest(
        navigate,
        sequenceEndPoints?.sendMailtoSelf,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(async () => {
    setIsLoading(true);
    let final_fields = [];
    let temp_body = body || "";
    let temp_subject = subject || "";

    try {
      const response = await getRequest(
        navigate,
        campaignEndpoints.getDefaultValues
      );
      let defaultValues = response?.data?.data?.[0];
      DYNAMIC_TAGS?.forEach(ele => {
        if (ele?.id === "company_name") {
          final_fields = [...final_fields, { ...ele, dummyValue: userDetailsSelector?.company_name || defaultValues?.[ele?.id] || ele?.dummyValue }];
        }
        else if (defaultValues?.[ele?.id]) {
          final_fields = [...final_fields, { ...ele, dummyValue: defaultValues?.[ele?.id] }];
        } else {
          final_fields = [...final_fields, ele]
        }
      })
    } catch (error) {
      console.log("Error_______ ", error);
    }

    for (let i in final_fields) {
      temp_body = temp_body.replaceAll(
        `{{${final_fields[i]?.id}}}`,
        final_fields[i]?.dummyValue
      );
      temp_subject = temp_subject.replaceAll(
        `{{${final_fields[i]?.id}}}`,
        final_fields[i]?.dummyValue
      );
    }

    // this is for removing tags colors.
    temp_body = removeTagStyleFromHtml(temp_body);
    // this is for removing tags colors.

    setParsedBody(temp_body);
    setParsedSubject(temp_subject);
    setIsLoading(false);
  }, []);

  const signature_payload = (
    <div id={STRINGS.SIGNATURE_DETAILS}>
      {parse(generateSignatureHTML(data))}
    </div>
  );
  const addSignature = (body, data) => {
    const signature = generateSignatureHTML(data);
    return body.concat(signature);
  };


  const modalHeaderData = {
    heading: {
      id: STRINGS.LISTID,
      label: STRINGS.PREVIEW,
    },
  };
  const modalBodyData = (
    <>
      {
        isLoading ? <div className={classes.previewLoading} >
          <SkeletonLoader type="rounded" /></div> : <Box>
          <Box className={classes.disFlex}>
            <Box className={classes.modalTitle}>
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.SUBJECT2}
              />
            </Box>
            <Box>
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={parsedSubject}
              />
            </Box>
          </Box>
          <Box className={classes.bodyPrevw} >
            <div id="editor-preview" >

              <Box id="previewMailBodyData">
                {parse(parsedBody || "")}
              </Box>
              <Box>
                {signature_payload}
              </Box>
            </div>

          </Box>
        </Box>
      }
    </>
  );
  const modalFooterRightData = (
    <div className={classes.rightFoot}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.SENDMAIL}
        size={"sm36"}
        variant={"primary"}
        onClick={handleSendMailToMyself}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = (
    <div>

    </div>
  );
  return (
    <CustomModal
      open={open}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData
      }}
      width={"37.5vw"}
    />
  );
};

export default PreviewMail;
