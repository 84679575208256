import {
  GET_GROUPS_LIST_BY_OBJECT_TYPE_FAIL,
  GET_GROUPS_LIST_BY_OBJECT_TYPE_REQUEST,
  GET_GROUPS_LIST_BY_OBJECT_TYPE_SUCCESS,
  GET_GROUPS_LIST_FAIL,
  GET_GROUPS_LIST_REQUEST,
  GET_GROUPS_LIST_SUCCESS,
  GET_PROPERTIES_FILTERS_FAIL,
  GET_PROPERTIES_FILTERS_REQUEST,
  GET_PROPERTIES_FILTERS_SUCCESS,
  GET_PROPERTIES_LIST_REQUEST,
  GET_PROPERTIES_LIST_SUCCESS,
} from "../constants/propertiesConstants";

export const getGroupListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_GROUPS_LIST_REQUEST:
      return { loading: true };
    case GET_GROUPS_LIST_SUCCESS:
      return { loading: false, data: action.payload.data, error: null };
    case GET_GROUPS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getGroupListByOBjectTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_GROUPS_LIST_BY_OBJECT_TYPE_REQUEST:
      return { loading: true };
    case GET_GROUPS_LIST_BY_OBJECT_TYPE_SUCCESS:
      return { loading: false, data: action.payload.data, error: null };
    case GET_GROUPS_LIST_BY_OBJECT_TYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getPropertiesListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROPERTIES_LIST_REQUEST:
      return { loading: true };
    case GET_PROPERTIES_LIST_SUCCESS:
      return { loading: false, data: action.payload.data, error: null };
    case GET_GROUPS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allPropertiesFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROPERTIES_FILTERS_REQUEST:
      return { loading: true };
    case GET_PROPERTIES_FILTERS_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case GET_PROPERTIES_FILTERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};