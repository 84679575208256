import { Box } from "@mui/system";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  allFilters,
  filterHeaderSearchIndustry,
} from "../../redux/actions/filterActions";
import { filterHeaderIndustry } from "../../redux/store/storeConstants";
import { filterKeys } from "../../utils/constants/filters";
import { leadsEndpoints } from "../../utils/constants/httpConstants";
import { CustomAutoComplete } from "../Autocomplete";
import { styles } from "./styles";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import { STRINGS } from "./strings";

const FilterTypeIndustry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);
  const selectedIndustryFilter = useSelector(
    (state) => state.filterHeaderIndustry?.payload
  );

  const handleIndustryChange = (item, filter) => {
    let payload = {
      ...selectedIndustryFilter,
      [item]: !selectedIndustryFilter[item],
      isKnown: false,
      isUnknown: false,
    };

    // clear data if column is empty.
    const unCheckPayload = {};
    if (!payload?.anyOf) {
      unCheckPayload[filter] = [];
    }
    if (!payload?.notAnyOf) {
      unCheckPayload[filter + "_not"] = [];
    }
    // clear data if column is empty.

    dispatch(
      allFilters({
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      })
    );

    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        ...unCheckPayload,
        exist_fields: [...allFiltersSelector.exist_fields].filter(
          (e) => e !== filter
        ),
        not_exist_fields: [...allFiltersSelector.not_exist_fields].filter(
          (e) => e !== filter
        ),
      }).toString(),
    });

    dispatch(filterHeaderSearchIndustry(payload));
  };

  const handleExistFields = (
    existing_type,
    filter,
    queryInsertKey,
    notFilterKey
  ) => {
    // "isKnown",
    // "industry",
    // "exist_fields",
    // "not_exist_fields"
    // existing_type = isKnown/ isUnknown
    // filter = job_title/industry/ seniority..

    let payload = {
      ...filterHeaderIndustry?.payload,
      [existing_type]: !selectedIndustryFilter[existing_type],
    };

    dispatch(filterHeaderSearchIndustry(payload));
    if (payload?.[existing_type]) {
      const queryPayload = {
        [queryInsertKey]: [...allFiltersSelector?.[queryInsertKey], filter],
        [notFilterKey]: [...allFiltersSelector?.[notFilterKey]].filter(
          (e) => e !== filter
        ),
        [filter]: [],
        [filter + "_not"]: [],
      };

      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    } else {
      const queryPayload = {
        [queryInsertKey]: [...allFiltersSelector?.[queryInsertKey]].filter(
          (e) => e !== filter
        ),
      };
      dispatch(allFilters(queryPayload));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector,
          ...queryPayload,
        }).toString(),
      });
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterContainer}>
        <div style={styles.jobTitleHead}>
          <Checkbox
            checked={selectedIndustryFilter?.anyOf == undefined ? selectedIndustryFilter?.payload?.anyOf : selectedIndustryFilter?.anyOf}
            onClick={() => handleIndustryChange("anyOf", filterKeys.industry)}
            label={STRINGS.IS_ANY_OF}
          />
        </div>
        {selectedIndustryFilter.anyOf ? (
          <Box>
            <CustomAutoComplete
              filter={filterKeys.industry}
              type=""
              endPoint={leadsEndpoints.getIndustryFilters}
            />
          </Box>
        ) : null}
        <div style={styles.jobTitleHead}>
          <Checkbox
            checked={selectedIndustryFilter?.notAnyOf == undefined ? selectedIndustryFilter?.payload?.notAnyOf : selectedIndustryFilter?.notAnyOf}
            onClick={() =>
              handleIndustryChange("notAnyOf", filterKeys.industry)
            }
            label={STRINGS.IS_NOT_ANY_OF}
          />
        </div>
        {selectedIndustryFilter.notAnyOf ? (
          <Box>
            <CustomAutoComplete
              filter={filterKeys.industry}
              type="_not"
              endPoint={leadsEndpoints.getIndustryFilters}
            />
          </Box>
        ) : null}
        <div style={styles.jobTitleHead}>
          <Checkbox
            checked={selectedIndustryFilter?.isKnown == undefined ? selectedIndustryFilter?.payload?.isKnown : selectedIndustryFilter?.isKnown}
            onClick={() =>
              handleExistFields(
                "isKnown",
                filterKeys.industry,
                "exist_fields",
                "not_exist_fields"
              )
            }
            label={STRINGS.IS_KNOWN}
          />
        </div>

        <div style={styles.jobTitleHead}>
          <Checkbox
            checked={selectedIndustryFilter?.isUnknown == undefined ? selectedIndustryFilter?.payload?.isUnknown : selectedIndustryFilter?.isUnknown}
            onClick={() =>
              handleExistFields(
                "isUnknown",
                filterKeys.industry,
                "not_exist_fields",
                "exist_fields"
              )
            }
            label={STRINGS.IS_UNKNOWN}
          />
        </div>
      </Box>
    </Box>
  );
};

export default FilterTypeIndustry;
