import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getRequest, postRequest } from "../../../Apis";

import { Button } from "../../../components/Buttons";
import {
  COUNTRY_LIST,
  DATE_FORMAT_LIST,
  EMITTER_SUCCESS,
  TIMEZONE_LIST,
} from "../../../utils/constants";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import { DASHBOARD_PATH, LOGIN_PATH } from "../../../utils/constants/routes";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { nameValidation } from "../../../utils/validations";
import {
  linkedInURLValidation,
  numberValidation,
  phoneValidation,
} from "../../../utils/validations/inputValidations";

import SuspenseImage from "../../../components/Image/SuspenseImage";
import {
  NEW_LOGIN_REACHIQ_LOGO,
  GREENTICK,
  RED,
} from "../../../utils/constants/assets";
import { logoutUser } from "../../../redux/actions/authActions";
import CompanyForm from "./CompanyForm";
import { STRINGS } from "../strings";
import Typography from "../../../components/Typography/Typography";
import { BRAND, GREY } from "../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import InputField from "../../../components/InputFields/InputField";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import { Mixpanel } from "../../../Apis/Mixpanel";
import { styles } from "../style";

const ProfileForm = ({ activeStep, setActiveStep, isProfileSetting }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = styles();
  const [idVal, setIdVal] = useState([]);
  let offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone_objChange = TIMEZONE_LIST?.filter(
    (item) => item?.id === offset
  );
  const [valueState, setValueState] = useState([
    timezone_objChange?.[0]?.label,
  ]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [linkedInURL, setLinkedInURL] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState([]);
  const [dateFormat, setDateFormat] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validations, setValidations] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    linkedInURL: "",
    valueState: "",
    phoneNumber: "",
    city: "",
    state: "",
    country: "",
  });
  const [timezoneList, setTimezoneList] = useState(TIMEZONE_LIST);
  const [countryList, setCountrylist] = useState(COUNTRY_LIST || []);

  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const saveDetailsHandler = async () => {
    const firstNameValidation = nameValidation(firstName);
    const lastNameValidation = nameValidation(lastName);
    const jobTitleValidation = nameValidation(jobTitle);
    const linkedInURLValidate = linkedInURLValidation(linkedInURL);
    const timeZoneValidation = nameValidation(valueState);
    const phoneNumberValidation = phoneValidation(phoneNumber);
    const cityValidation = nameValidation(city);
    const stateValidation = nameValidation(state);
    const countryValidation = nameValidation(country);
    const dateFormatValidation = nameValidation(dateFormat);
    if (
      firstNameValidation.isValid &&
      lastNameValidation.isValid &&
      jobTitleValidation.isValid &&
      linkedInURLValidate.isValid &&
      timeZoneValidation.isValid &&
      phoneNumberValidation.isValid &&
      cityValidation.isValid &&
      stateValidation.isValid &&
      countryValidation.isValid &&
      dateFormatValidation.isValid
    ) {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        job_title: jobTitle,
        contact_linkedin_url: linkedInURL,
        timezone: valueState[0],
        phone_number: phoneNumber?.trim(),
        city: city,
        state: state,
        country: country[0],
        date_format: dateFormat[0],
      };
      try {
        setIsLoading(true);
        const response = await postRequest(
          navigate,
          authEndpoints.updateUserDetails,
          payload
        );
        Mixpanel.track(STRINGS.USER_DETAIL);

        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setValidations({
          firstName: "",
          lastName: "",
          jobTitle: "",
          valueState: "",
          phoneNumber: "",
          linkedInURL: "",
          city: "",
          state: "",
          country: "",
          dateFormat: "",
        });
        setIsLoading(false);
        {
          location.pathname === "/onboarding-form" && navigate(DASHBOARD_PATH);
        }
      } catch (error) {
        const errors = error?.data?.data?.errors;
        setIsLoading(false);
        setValidations({
          firstName: errors?.first_name || "",
          lastName: errors?.last_name || "",
          jobTitle: errors?.job_title || "",
          valueState: errors?.timezone || "",
          phoneNumber: errors?.phone_number || "",
          linkedInURL: errors?.contact_linkedin_url || "",
          city: errors?.city || "",
          state: errors?.state || "",
          country: errors?.country || "",
          dateFormat: errors?.date_format || "",
        });
      }
    } else {
      setValidations({
        firstName: firstNameValidation.message,
        lastName: lastNameValidation.message,
        jobTitle: jobTitleValidation.message,
        linkedInURL: linkedInURLValidate.message,
        valueState: timeZoneValidation.message,
        phoneNumber: phoneNumberValidation.message,
        city: cityValidation.message,
        state: stateValidation.message,
        country: countryValidation.message,
        dateFormat: dateFormatValidation.message,
      });
    }
  };

  const logoutHandler = async () => {
    dispatch(logoutUser());
    navigate(LOGIN_PATH);
  };
  useEffect(() => {
    (async () => {
      try {
        const response = await getRequest(
          navigate,
          authEndpoints.getUserDetails
        );
        let userDetail = response?.data?.data;
        setFirstName(userDetail?.first_name);
        setLastName(userDetail?.last_name);
        setJobTitle(userDetail?.job_title);
        setLinkedInURL(userDetail?.contact_linkedin_url);
        setValueState([userDetail?.timezone]);
        setIdVal([userDetail?.timezone]);
        setPhoneNumber(userDetail?.phone_number);
        setCity(userDetail?.city);
        setState(userDetail?.state);
        setCountry([userDetail?.country]);
        setDateFormat([userDetail?.date_format]);
        setIsLoading(false);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [navigate]);

  const steps = [
    {
      label: "Account Settings",
      component: (
        <CompanyForm activeStep={activeStep} setActiveStep={setActiveStep} />
      ),
    },
    {
      label: "User Profile",
      component: (
        <ProfileForm activeStep={activeStep} setActiveStep={setActiveStep} />
      ),
    },
  ];
  const searchHandler = (e, property) => {
    let countryValue = countryList;
    let TimeoneValue = timezoneList;

    if (property === "countrylist") {
      let data = countryValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setCountrylist(COUNTRY_LIST);
      } else {
        setCountrylist(data);
      }
    } else if (property === "timezoneList") {
      let data = TimeoneValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setTimezoneList(TIMEZONE_LIST);
      } else {
        setTimezoneList(data);
      }
    }
  };
  return (
    <Box
      className={
        isProfileSetting
          ? classes.isProfileSettingFormMainContainer
          : classes.profileFormMainContainer
      }
    >
      <Box
        className={
          isProfileSetting
            ? classes.isProfileSettingFormContainer
            : classes.profileFormContainer
        }
      >
        {location.pathname === "/onboarding-form" ? (
          <Box className={classes.intitalFormHeader}>
            <SuspenseImage
              className={classes.logoIcon}
              src={NEW_LOGIN_REACHIQ_LOGO}
            />
            <Box className={classes.initialFormSteps}>
              {/* Account setting option */}
              <Box
                className={
                  activeStep === 1
                    ? classes.stepLabelCompletedContainer
                    : classes.stepLabelActiveContainer
                }
              >
                {activeStep === 1 && (
                  <SuspenseImage src={GREENTICK} className={classes.tickIcon} />
                )}

                <Typography
                  size={"md"}
                  color={activeStep === 0 ? BRAND.PRIMARY : GREY.SECONDARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={steps[0].label}
                />
              </Box>
              <hr className={classes.horizontalLine} />
              {/* User Profile option */}
              <Box
                className={
                  activeStep === 1
                    ? classes.stepLabelActiveContainer
                    : classes.stepLabelNotActiveContainer
                }
              >
                {activeStep === 1 && (
                  <SuspenseImage src={RED} className={classes.tickIconred} />
                )}

                <Typography
                  size={"md"}
                  color={activeStep === 1 ? BRAND.PRIMARY : GREY.SECONDARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={steps[1].label}
                />
              </Box>
            </Box>
            <Box onClick={logoutHandler} className={classes.logoutContainer}>
              <Button
                LeftIcon={ICON_LABELS.LOGOUTS}
                label={STRINGS.LOGOUT}
                size={"sm36"}
                variant={"ghost"}
                onClick={logoutHandler}
              />
            </Box>
          </Box>
        ) : (
          ""
        )}
        {!location.pathname === "/onboarding-form" ? (
          ""
        ) : (
          <Box className={classes.SubHeader}>
            <Typography
              size={"lg"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.USER_PROFILE}
            />
            {isProfileSetting && (
              <Typography
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.THIS_APPLIES}
              />
            )}
          </Box>
        )}


        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.FIRST_NAME}
              />

              <InputField
                placeholder={STRINGS.ENter_First}
                size={"md40"}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setValidations({
                    ...validations,
                    firstName: "",
                  });
                }}
                variant={
                  validations?.firstName?.length > 1
                    ? STRINGS.ERROR
                    : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.firstName ? validations?.firstName : ""
                }
              />
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.LAST_NAME}
              />
              <InputField
                placeholder={STRINGS.Enter_Last}
                size={"md40"}
                value={lastName}
                onChange={(e) => {
                  setLastName(e?.target?.value);
                  setValidations({
                    ...validations,
                    lastName: "",
                  });
                }}
                variant={
                  validations?.lastName ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.lastName ? validations?.lastName : ""
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.JOB_TITLE}
              />
              <InputField
                placeholder={STRINGS.ENTER_JOB}
                size={"md40"}
                value={jobTitle}
                onChange={(e) => {
                  setJobTitle(e?.target?.value);
                  setValidations({
                    ...validations,
                    jobTitle: "",
                  });
                }}
                variant={
                  validations?.jobTitle ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.jobTitle?.length !== 0
                    ? validations?.jobTitle
                    : ""
                }
              />
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.LINKED}
              />
              <InputField
                placeholder={STRINGS.Enter_linked}
                size={"md40"}
                value={linkedInURL}
                onChange={(e) => {
                  setLinkedInURL(e?.target?.value);
                  setValidations({
                    ...validations,
                    linkedInURL: "",
                  });
                }}
                variant={
                  validations?.linkedInURL ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.linkedInURL?.length !== 0
                    ? validations?.linkedInURL
                    : ""
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.TIMEZONED}
              />
              <Box>
                <AutoCompleteCustom
                  size={"md40"}
                  LeftIcon=""
                  items={timezoneList || []}
                  valueState={[valueState]}
                  setValueState={setValueState}
                  papermenuclass={classes.papermenuclass}
                  idState={idVal}
                  setIdState={setIdVal}
                  variant={
                    validations?.valueState ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations?.valueState?.length !== 0
                      ? validations?.valueState
                      : ""
                  }
                  isSearchEnable={true}
                  handleSearch={(event) => {
                    searchHandler(event, "timezoneList");
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.PHONE}
              />
              <InputField
                placeholder={STRINGS.ENter_phone}
                size={"md40"}
                // type={"number"}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e?.target?.value);
                  setValidations({
                    ...validations,
                    phoneNumber: "",
                  });
                }}
                variant={
                  validations?.phoneNumber ? STRINGS.ERROR : STRINGS.DEFAULT
                }
                errormessage={
                  validations?.phoneNumber?.length !== 0
                    ? validations?.phoneNumber
                    : ""
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.CITY}
              />
              <Box>
                <InputField
                  placeholder={STRINGS.Enter_CITY}
                  size={"md40"}
                  value={city}
                  onChange={(e) => {
                    setCity(e?.target?.value);
                    setValidations({
                      ...validations,
                      city: "",
                    });
                  }}
                  variant={validations?.city ? STRINGS.ERROR : STRINGS.DEFAULT}
                  errormessage={
                    validations?.city?.length !== 0 ? validations?.city : ""
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.STATE}
              />
              <InputField
                placeholder={STRINGS.STATE}
                size={"md40"}
                value={state}
                onChange={(e) => {
                  setState(e?.target?.value);
                  setValidations({
                    ...validations,
                    state: "",
                  });
                }}
                variant={validations?.state ? STRINGS.ERROR : STRINGS.DEFAULT}
                errormessage={
                  validations?.state?.length !== 0 ? validations?.state : ""
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.twoFieldsCotnainer}>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COUNTRY}
              />
              <Box>
                <AutoCompleteCustom
                  size={"md40"}
                  LeftIcon=""
                  items={countryList}
                  valueState={[country]}
                  setValueState={setCountry}
                  papermenuclass={classes.papermenuclass}
                  idState={country}
                  setIdState={setCountry}
                  variant={
                    validations?.country ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations?.country?.length !== 0
                      ? validations?.country
                      : ""
                  }
                  isSearchEnable={true}
                  handleSearch={(event) => {
                    searchHandler(event, "countrylist");
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.fieldErrorContainer}>
            <Box className={classes.fieldContainer}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.DATE_FORMAT}
              />
              <Box>
                <AutoCompleteCustom
                  size={"md40"}
                  LeftIcon=""
                  items={DATE_FORMAT_LIST}
                  valueState={[dateFormat]}
                  setValueState={setDateFormat}
                  papermenuclass={classes.papermenuclass}
                  idState={dateFormat}
                  setIdState={setDateFormat}
                  variant={
                    validations?.dateFormat ? STRINGS.ERROR : STRINGS.DEFAULT
                  }
                  errormessage={
                    validations?.dateFormat?.length !== 0
                      ? validations?.dateFormat
                      : ""
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className={
            isProfileSetting
              ? classes.buttonContainer
              : classes.buttonContainer1
          }
        >
          {userDetailsSelector?.payload?.role === "admin-user" &&
            !isProfileSetting && (
              <Button
                label={STRINGS.BACK}
                size={"sm36"}
                variant={"ghost"}
                onClick={() => setActiveStep(0)}
              />
            )}
          <Box>
            <Button
              label={STRINGS.SAVE_DETAILS}
              size={"sm36"}
              variant={"primary"}
              onClick={saveDetailsHandler}
              isLoading={isLoading}
              data-tour="savedetails"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileForm;
