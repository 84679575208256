import React from "react";
import CustomTable from "../../../components/Table/CustomTable";
import SearchField from "../../../components/InputFields/SearchField";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import { useSelector } from "react-redux";
import { ICON_LABELS } from "../../../utils/constants/UI";
import {
  groupListTableHeads,
  groupsFilterKeys,
} from "../../../utils/constants";
import DeleteGroupModal from "./DeleteGroupModal";
import classes from "./PropComponent.module.css";
import { paginateAndSort } from "../../../utils/helpers";

const GroupListScreen = ({
  createGroupModalHandler,
  handleDebounce,
  editGroupModalHandler,
  grpData,
  setGrpData,
  dltBtnLoading,
  deleteGroupHandler,
  openDltGroupModal,
  setOpenDltGroupModal,
  searchValue,
}) => {
  const getGroupListSelector = useSelector((state) => state.getGroupList);
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const deleteGroupModalHandler = (row) => {
    setGrpData(row);
    setOpenDltGroupModal(true);
  };

  const page = paginationDataSelector?.data?.query?.page || 1;
  const pageSize = paginationDataSelector?.data?.query?.limit || 10;
  const sortBy = paginationDataSelector?.data?.query?.sort?.id || "";
  const sortOrder = paginationDataSelector?.data?.query?.sort?.direction;
  const tableBodyData =
    paginateAndSort(
      getGroupListSelector?.data?.hits,
      page,
      pageSize,
      sortBy,
      sortOrder,
      searchValue,
      groupsFilterKeys
    ) || [];

  const groupListTableData = {
    heads: groupListTableHeads,
    body: tableBodyData?.records || [],
    operations: [
      {
        id: STRINGS.EDIT_SMALL,
        label: STRINGS.EDIT,
        iconLabel: ICON_LABELS.EDIT,
        function: (row) => editGroupModalHandler(row),
      },
      {
        id: STRINGS.DELETE_SMALL,
        label: STRINGS.DELETE,
        iconLabel: ICON_LABELS.DELETE,
        function: (row) => deleteGroupModalHandler(row),
        variant: "error",
      },
    ],
    actions: {},
    total_records: tableBodyData?.total_records,
    isLoading: getGroupListSelector?.loading,
    disable_key: STRINGS.OPERATION,
    is_selection_not_allowed: true,
  };

  return (
    <div className={classes.scrnMainCtnr}>
      {/* Search Bar, Filters and Create Group Button */}
      <div className={classes.tableUpprContainer}>
        <div className={classes.searchField}>
          <SearchField
            size={"sm36"}
            placeholder={STRINGS.SEARCH}
            onChange={handleDebounce}
          />
        </div>
        <div>
          <Button
            label={STRINGS.CREATE_GROUP}
            size={"sm36"}
            variant={"primary"}
            onClick={createGroupModalHandler}
          />
        </div>
      </div>

      <div className={classes.listTableContainer}>
        <CustomTable tableData={groupListTableData} />
      </div>

      {/* Delete Group Modal */}
      <DeleteGroupModal
        open={openDltGroupModal}
        groupName={grpData?.grp_name}
        close={() => setOpenDltGroupModal(!openDltGroupModal)}
        deleteGroupHandler={deleteGroupHandler}
        btnLoading={dltBtnLoading}
      />
    </div>
  );
};

export default GroupListScreen;
