import {
    TEMPLATE_LIST_REQUEST,
    TEMPLATE_LIST_SUCCESS,
    TEMPLATE_LIST_FAIL,
    TEMPLATE_GET_REQUEST,
    TEMPLATE_GET_SUCCESS,
    TEMPLATE_GET_FAIL,
    GET_TEMPLATE_FILTERS_SUCCESS,
    GET_TEMPLATE_FILTERS_FAIL,
    GET_TEMPLATE_FILTERS_REQUEST
} from "../constants/templateListConstants"

export const getTemplateTableListReducer=(state={},action) => {
    switch(action.type){
        case TEMPLATE_LIST_REQUEST:
            return { loading: true };
          case TEMPLATE_LIST_SUCCESS:
            return { loading: false, data: action.payload, error: null };
          case TEMPLATE_LIST_FAIL:
            return { loading: false, error: action.payload };
          default:
            return state;
    }
}
export const getAllTempReducer=(state={},action)=>{
  switch(action.type){
    case TEMPLATE_GET_REQUEST:
      return {loading:true};
    case TEMPLATE_GET_SUCCESS:
      return {
        loading:false, data:action.payload,error:null
      }
    case TEMPLATE_GET_FAIL:
      return {loading:false,error:action.payload};
    default:
      return state;
  }
}

export const allTemplateFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TEMPLATE_FILTERS_REQUEST:
      return { loading: true };
    case GET_TEMPLATE_FILTERS_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case GET_TEMPLATE_FILTERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};