export const STRINGS = {
  INVITE_USER: "Invite User",
  IS_EQUAL_TO: "is equal to",
  IS_ALL_EQUAL_TO: "match all of",
  IS_NOT_EQUAL_TO: "is not equal to",
  REVENUE: "Revenue",
  IS_BETWEEN: "is between",
  SEARCH: "Search...",
  KNOWN: "Known",
  UNKNOWN: "Unknown",
  NUMBER: "Number",
  DATE_PICKER: "Date Picker",
  IS_IN_BETWEEN: "is in between",
  DEAL_NAME_SMALL: "deal_name",
  NO_RECORD_FOUND: "No Record Found",
  MULTIPLE_CHECKBOX: "Multiple Checkbox",
  CREATE_PIPELINE: "Create a pipeline",
  DEALS_SMALL: "deals",
  ADMIN_USER: "admin-user",
  LOADING: "Loading",
  DATE_COLON: "Date:",
  CANCEL: "Cancel",
  APPLY: "Apply"
};
