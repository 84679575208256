export const MESSAGE_INVALID_NAME_LENGTH = "This is a required field.";
export const MESSAGE_INVALID_NAME_LENGTH_SUFFIX = "is a required field.";
export const MESSAGE_INVALID_LENGTH =
  "Record name must be greater than 3 characters.";
export const MESSAGE_INVALID_LENGTH_SUFFIX =
  "must be greater than 3 characters.";
export const MESSAGE_SCHEDULE = "Schedule Date is required, Go Step 4";
export const MESSAGE_INVALID_PASSWORD =
  "Password length should be greater than or equal to 8 characters.";
export const MESSAGE_INVALID_EMAIL_PATTERN = "Please enter valid email.";
export const MESSAGE_INVALID_EMAIL_LENGTH = "Email is a required field.";
export const MESSAGE_INVALID_PASSWORD_LENGTH = "Password is required.";
export const MESSAGE_CONFIRMED_INVALID_PASSWORD_LENGTH =
  "Confirm Password is required.";
export const NEW_PASSWORD_LENGTH =
  "Please provide your new password to proceed.";
export const MESSAGE_INVALID_CONF_PASSWORD_LENGTH =
  "Confirm Password should be greater than or equal to 8.";
export const MESSAGE_INVALID_NEW_PASSWORD_LENGTH =
  "New Password should be greater than or equal to 8.";
export const MESSAGE_INVALID_DOMAIN = "Please enter valid domain.";
export const MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH =
  "New password and confirm password should be same.";
export const CONFIRM_PASSWORD_LENGTH =
  "Please provide your confirm password to proceed.";
export const MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH =
  "Old Password and New Password should not be same.";
export const MESSAGE_PAST_DATE_NOT_ALLOWED = "Past date not allowed.";
export const DYNAMIC_FIELD_FORMAT =
  "Only _,- and alphanumeric values are allowed.";

export const MESSAGE_INVALID_URL = "Please enter a valid URL";
export const MESSAGE_INVALID_NUMBER = "Please enter a valid Phone Number";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong.";

export const UNAUTHORIZED_USER_MESSAGE = "Unauthorized User";

export const MESSAGE_ZIPCODE_ERROR_MESSAGE =
  "Postal/Zip should not be less than 4 digits.";
export const MESSAGE_REQUIRED_FIELD = "This is a required field.";

export const MESSAGE_HOURLY_LIMIT_EXCEED =
  "Hourly limit cannot exceed more than 50";

export const MESSAGE_DAILY_LIMIT_EXCEED =
  "Daily limit cannot exceed more than 500";

// Dashboard Errors
export const EMPTY_DASHBOARD_FIELD_ERROR = "Please enter the Dashboard name";
export const DASHBOARD_NAME_LONG_LENGTH_ERROR =
  "Dashboard name should be less than 50 characters.";
export const DASHBOARD_NAME_LENGTH_ERROR =
  "Dashboard name length must be at least 3 characters long.";
export const WIDGET_CREATION_SUCCESS_MESSAGE = "Widget Created Successfully";
export const DASHBOARD_DELETED_SUCCESSFULLY = "Dashboard Deleted";
export const EMPTY_WIDGET_FIELD_ERROR = "Please enter the Widget Name";
export const WIDGET_NAME_LONG_LENGTH_ERROR =
  "Dashboard name length must be less than or equal to 25 characters long.";
export const WIDGET_NAME_LENGTH_ERROR =
  "Dashboard name length must be at least 3 characters long.";
//Campaigns Validations

export const SENDER_FIRSTNAME_LENGTH =
  "Please enter sender’s first name to proceed.";
export const SENDER_LASTNAME_LENGTH =
  "Please enter sender’s last name to proceed.";
export const SENDER_JOBTITLE = "Please enter sender’s job title to proceed.";
export const SENDER_COMPANYNAME =
  "Please enter sender’s company name to proceed.";
export const SENDER_PHONENUMBER =
  "Please enter sender’s phone number to proceed.";
export const SENDER_ADDRESS =
  "Please enter sender’s complete address to proceed.";
export const SENDER_CITY = "Please enter sender’s city to proceed.";
export const SENDER_STATE = "Please enter sender’s state to proceed";
export const SENDER_POSTAL =
  "Please enter sender’s postal/zip code to proceed.";
export const SENDER_COUNTRY = "Please enter sender’s country to proceed.";
export const LABEL_MAX_LENGTH =
  "Label should not exceed 50 characters in length. ";
export const SELECT_LIST = "Please select any one list.";
export const SELECT_FOLDER = "Please select any  Folder";
export const SELECT_ATLEAST_LIST = "Please select at least one  List";
export const SELECT_TEMPLATE = "Please select at least one  Template";
export const SERVER_MESSAGE_ABSENCE = "Server message is not present";
export const SERVER_MESSAGE_MISPLACED = "Server error message misplaced.";
export const MESSAGE_ALL_FIELD_REQUIRED = "All fields are required.";
export const MESSAGE_FOLDER_DELETE = "Folders deleted successfully";
export const MESSAGE_FOLDERS_DELETE = "Folders deleted";
export const SEQUENCE_NAME_LENGTH_ERROR =
  "Sequence name should be atleast 3 characters.";
export const SEQUENCE_NAME_LONG_LENGTH_ERROR =
  "Sequence name should be less than or equal to 50";
export const FOLDER_NAME_LENGTH_ERROR =
  "Folder name should be atleast 3 characters.";
export const FOLDER_NAME_LONG_LENGTH_ERROR =
  "Folder name should be less than or equal to 50";
export const LIST_NAME_LENGTH_ERROR =
  "List name should be atleast 3 characters.";
export const LIST_NAME_LONG_LENGTH_ERROR =
  "List name should be less than or equal to 50";
export const TEMP_NAME_LENGTH_ERROR =
  "Template name should be atleast 3 characters.";
export const TEMP_NAME_LONG_LENGTH_ERROR =
  "Template name cannot be more than 150 characters";
export const MESSAGE_SUBJECT_REQUIRED = "Subject is required.";

export const MESSAGE_DELETED_SUCCESSFULLY = "Successfully Deleted";
export const MESSAGE_TEST_MAIL_SENT = "Test Mail sent";
export const IMPORT_FILE_UPLOAD_SUCCESS =
  "File Uploaded Successfully, Map the column Headers in your file.";
export const IMPORT_COMPANY_WEBSITE_MANDATORY =
  "Company website is mandatory, if company data is included.";
export const IMPORT_COMPANY_WEBSITE_EMAIL_MANDATORY =
  "Company website and email is mandatory, if company and people data is included.";
export const IMPORT_FILE_EMAIL =
  "Email is mandatory, if people data is included.";
export const ATLEAS_ONE_FIELD = "Map atleast one field.";
export const FILE_SIZE_ERROR = "File size should be less than 1gb";
export const INVALID_FILE_ERROR = "Select File Type is Invalid.";
export const INVALID_FORMAT_IMAGE =
  "Please choose the image format to upload in JPG or PNG";
export const CANT_EXCEED_100WORDS = "Cannot exceed more than 100 words";
export const CANT_EXCEED_50WORDS = "Cannot exceed more than 50 words";
export const HOURLY_DAILY_LIMIT =
  "Hourly limit should not be greater than daily limit";
export const DAILY_DAILY_LIMIT =
  "Daily limit should not be less than hourly limit";
export const INVALID_URL = "Invalid URL";
export const INVALID_DATE_TIME =
  "The date or time selected is in the past. Please select a future date or time.";
export const SPECIAL_CHARATER_CHECK =
  "The property name should not contain any special characters."
export const PHONE_NUMBER_INVALID = "Enter a valid Phone Number";

export const FORMS_FIELD_RESET_MESSAGE = "Forms Fields Reset Successfully"
export const BLOCKED_SCREEN_MESSAGE = "Regrettably, your account has been suspended for security purposes. Kindly reach out to us at support@reachiq.io for additional assistance.";
export const FLAGGED_SCREEN_MESSAGE = "You organization account is temporary suspended. Please contact to our support team.";
export const INACTIVE_SCREEN_MESSAGE = "We have received your request. It is under process. We will contact you soon.";
export const IMAGE_UPLOAD_SIZE_WARNING = "Image size too big, max 500KB Allowed.";
export const INVALID_IMAGE_TYPE = "Invalid image type.";
export const UPLOADING_IMAGE = "Uploading Image...";
export const IMAGE_UPLOADED_MESSAGE = "Image Uploaded Successfully.";
export const DUPLICATE_STAGE_NAME = "Duplicate Stage name is not allowed";