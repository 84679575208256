import { Styles } from "../Styles";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import CustomTable from "../../../components/Table/CustomTable";
import { DASHBOARD_ACTIVITY_LOGS_TABLE_HEADERS } from "../../../utils/constants";
import { getRequest } from "../../../Apis";
import { dashboardEndpoints } from "../../../utils/constants/httpConstants";
import classes from "./ActivityLogsPopup.module.css";
import { useNavigate } from "react-router-dom";
import { timeStampToDateTime } from "../../../utils/helpers";

const resolveUrl = (url, options = {}) => {
  const optionKeys = Object.keys(options);
  if (optionKeys.length === 0) return url;
  let another_string = "";
  for (let key in optionKeys) {
    if (
      options[optionKeys[key]] !== undefined &&
      options[optionKeys[key]] !== null
    )
      another_string +=
        String(optionKeys[key] + "=" + String(options[optionKeys[key]])) + "&";
  }
  const appendable_string = another_string.slice(0, -1);
  console.log(url);

  return url + "?" + appendable_string;
};

export const ActivityLogsPopup = (props) => {
    console.log("PRP",props)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [activityLogData, setActivityLogData] = useState([]);
  const defaultPayload = {
    page: 1,
    limit: 10,
    dashboard_id: props?.dashBoard?._id,
  };

  const handlesAllDataManagement = async (url) => {
    try {
      setLoading(true);
      const response = await getRequest(navigate,`${url}?page=1&limit=10&dashboard_id=${props?.dashBoard?._id}`);

      setActivityLogData(response?.data?.data?.records || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const modalHeaderData = {
    heading: {
      id: "activity_logs_modal",
      label: STRINGS.ACTIVITY_LOGS,
    },
    description: {
      id: "activity_logs_desc",
      label: STRINGS.DATA_WITHIN,
    },
  };

  const records = activityLogData?.total_records || 0;

  const customTableData = {
    heads: DASHBOARD_ACTIVITY_LOGS_TABLE_HEADERS,
    body: activityLogData,
    total_records: records || 0,
    isLoading: loading,
    is_selection_not_allowed: true,
  };

  useEffect(() => {
    handlesAllDataManagement(dashboardEndpoints.getDashboardActivityLogs);
  }, []);

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      header={modalHeaderData}
    //   footer={{}}
      width={"62.813vw"}
    >
      <div className={classes.tableContainer}>
        <div className={classes.headerCont}>
          <div className={classes.head1}>
            <TypographyInline
              label={STRINGS.ACTION_DET}
              size={"sm"}
              fontWeight={fontWeight.MEDIUM}
              color={GREY.PRIMARY}
            />
          </div>
          <div className={classes.head2}>
            <TypographyInline
              label={STRINGS.PERFORMED}
              size={"sm"}
              fontWeight={fontWeight.MEDIUM}
              color={GREY.PRIMARY}
            />
          </div>
          <div className={classes.head3}>
            <TypographyInline
              label={STRINGS.TIME}
              size={"sm"}
              fontWeight={fontWeight.MEDIUM}
              color={GREY.PRIMARY}
            />
          </div>
        </div>
        <div className={classes.heightSet}>
        {activityLogData?.map((item) => {
          return (
            <div className={classes.headerCont1}>
              <div className={`${classes.head11} ${classes.disClFlex}`}>
                <TypographyInline
                  label={item?.subject}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={GREY.SECONDARY}
                />
                <TypographyInline
                  label={item?.message}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                />
              </div>
              <div className={classes.head22}>
               <TypographyInline
                  label={item?.owner_name}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                />
              </div>
              <div className={classes.head33}>
              <TypographyInline
                  label={timeStampToDateTime(item?.created_at)}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                />
              </div>
            </div>
          );
        })}
        </div>
      </div>
    </CustomModal>
  );
};
