import { getRequest, postRequest } from "../../Apis";
import { crmEndpoints } from "../../utils/constants/httpConstants";
import { compressString } from "../../utils/helpers";
import {
  GET_GROUPS_LIST_BY_OBJECT_TYPE_FAIL,
  GET_GROUPS_LIST_BY_OBJECT_TYPE_REQUEST,
  GET_GROUPS_LIST_BY_OBJECT_TYPE_SUCCESS,
  GET_GROUPS_LIST_FAIL,
  GET_GROUPS_LIST_REQUEST,
  GET_GROUPS_LIST_SUCCESS,
  GET_PROPERTIES_FILTERS_FAIL,
  GET_PROPERTIES_FILTERS_SUCCESS,
  GET_PROPERTIES_LIST_FAIL,
  GET_PROPERTIES_LIST_REQUEST,
  GET_PROPERTIES_LIST_SUCCESS,
} from "../constants/propertiesConstants";

export const getGroupsList =
  (sentQuery = {}, navigate) =>
    async (dispatch) => {
      try {
        dispatch({ type: GET_GROUPS_LIST_REQUEST });
        const response = await getRequest(navigate, crmEndpoints.getGroupList);

        dispatch({
          type: GET_GROUPS_LIST_SUCCESS,
          payload: { ...response?.data },
        });
      } catch (err) {
        dispatch({ type: GET_GROUPS_LIST_FAIL, payload: err });
      }
    };


export const getGroupsListByObjectType =
  (sentQuery = {}, navigate) =>
    async (dispatch) => {
      try {
        dispatch({ type: GET_GROUPS_LIST_BY_OBJECT_TYPE_REQUEST });
        const response = await postRequest(
          navigate,
          crmEndpoints.getGroupByType,
          sentQuery
        );

        dispatch({
          type: GET_GROUPS_LIST_BY_OBJECT_TYPE_SUCCESS,
          payload: { ...response?.data, query: sentQuery },
        });
      } catch (err) {
        dispatch({ type: GET_GROUPS_LIST_BY_OBJECT_TYPE_FAIL, payload: err });
      }
    };

export const getPropertiesList =
  (sentQuery = {}, navigate) =>
    async (dispatch) => {
      try {
        dispatch({ type: GET_PROPERTIES_LIST_REQUEST });
        const response = await postRequest(
          navigate,
          crmEndpoints.getProperty,
          sentQuery
        );

        const properties_key_value = {};
        const reference_key_value = {};
        for (let i in response?.data?.data?.hits) {
          properties_key_value[response?.data?.data?.hits?.[i]?.id] = response?.data?.data?.hits?.[i];
          if (response?.data?.data?.hits?.[i]?.reference_field) {
            reference_key_value[response?.data?.data?.hits?.[i]?.reference_field] = response?.data?.data?.hits?.[i]?.id;
          }
          // properties_key_value[response?.data?.data?.hits?.[i]?. ] = response?.data?.data?.hits?.[i];
        }

        // storing properties in locastorage to reduce the run time of building dynamic query and api call of crm screens.
        localStorage.setItem("properties", compressString(JSON.stringify({
          ...response?.data?.data,
          query: sentQuery,
          properties_key_value: properties_key_value || {},
          reference_key_value: reference_key_value || {}
        })));

        dispatch({
          type: GET_PROPERTIES_LIST_SUCCESS,
          payload: {
            data: {
              ...response?.data?.data,
              query: sentQuery,
              properties_key_value: properties_key_value || {},
              reference_key_value: reference_key_value || {}
            },
          },
        });
      } catch (err) {
        dispatch({ type: GET_PROPERTIES_LIST_FAIL, payload: err });
      }
    };

// action for storing all filters of crm module.
export const allPropertiesFilters = (sentQuery) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROPERTIES_FILTERS_SUCCESS,
      payload: sentQuery,
    });
  } catch (err) {
    dispatch({ type: GET_PROPERTIES_FILTERS_FAIL, payload: err });
  }
};
