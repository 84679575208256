import React from "react";
import {
    BASE,
    BORDER,
    BRAND,
    GREY,
    PRIMARY,
} from "../../utils/constants/colors";
import { fontSize, fontWeight, lineHeight } from "../../utils/constants/UI";
import { borderRadius } from "../../utils/constants/UI/uiConstants";
import CircularLoader from "../Loaders/CircularLoader";
import classes from "./Button.module.css";
import { innerBorder } from "../../utils/helpers";
import CustomTooltip from "../Tooltip/CustomTooltip";
import Image from "../Image/Image";

const SIZE_MAPPING = {
    xs28: {
        fontSize: fontSize.XS,
        fontWeight: fontWeight.MEDIUM,
        lineHeight: lineHeight.XS,
        width: "16px",
        iconSize: "16px",
        height: "16px",
        padding: "6px",
        textPadding: "6px 0px",
    },
    sm36: {
        fontSize: fontSize.SM,
        fontWeight: fontWeight.MEDIUM,
        lineHeight: lineHeight.SM,
        width: "16px",
        iconSize: "16px",
        height: "16px",
        padding: "10px",
        textPadding: "10px 0px",
    },
    md40: {
        fontSize: fontSize.SM,
        fontWeight: fontWeight.MEDIUM,
        lineHeight: lineHeight.SM,
        width: "20px",
        iconSize: "20px",
        height: "20px",
        padding: "10px",
        textPadding: "10px 0px",
    },
    lg44: {
        fontSize: fontSize.MD,
        fontWeight: fontWeight.MEDIUM,
        lineHeight: lineHeight.MD,
        width: "20px",
        iconSize: "20px",
        height: "20px",
        padding: "12px",
        textPadding: "12px 0px",
    },
    primary: {
        color: BRAND.PRIMARY,
    },
    ghost: {
        color: BRAND.PRIMARY,
    },
    text: {
        color: GREY.SECONDARY,
    },
};

const ImageButton = ({
    LeftIcon,
    backgroundColor,
    borderColor,
    isLoading,
    size,
    variant,
    disabled,
    tooltipLabel = "",
    ...props
}) => {

    const styles = {
        primary: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: isLoading
                ? PRIMARY.W_400
                : disabled
                    ? BORDER.DISABLE
                    : backgroundColor || BRAND.PRIMARY,
            color: disabled ? GREY.DISABLED : BASE.WHITE,
            gap: "4px",
            padding: SIZE_MAPPING[size]?.padding,
            borderRadius: borderRadius.primary,
            border: borderColor ? innerBorder(borderColor) : "none",
            whiteSpace: "nowrap",
            cursor: !disabled ? "pointer" : "not-allowed",
            fontSize: SIZE_MAPPING[size].fontSize,
            fontWeight: SIZE_MAPPING[size].fontWeight,
            lineHeight: SIZE_MAPPING[size].lineHeight,
        },
        ghost: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: isLoading
                ? PRIMARY.W_400
                : disabled
                    ? BORDER.DISABLED
                    : BASE.WHITE,
            color: isLoading ? BASE.WHITE : disabled ? GREY.DISABLED : GREY.SECONDARY,
            gap: "4px",
            padding: SIZE_MAPPING[size]?.padding,
            borderRadius: borderRadius.primary,
            border: "none",
            whiteSpace: "nowrap",
            boxShadow:
                !disabled &&
                `inset -1px 0 0 ${BORDER.DEFAULT}, inset 0 -1px 0 ${BORDER.DEFAULT}, inset 1px 0 0 ${BORDER.DEFAULT}, inset 0 1px 0 ${BORDER.DEFAULT}`,
            cursor: !disabled ? "pointer" : "not-allowed",
            fontSize: SIZE_MAPPING[size].fontSize,
            fontWeight: SIZE_MAPPING[size].fontWeight,
            lineHeight: SIZE_MAPPING[size].lineHeight,
        },
        text: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: "transparent",
            color: !disabled ? GREY.SECONDARY : GREY.DISABLED,
            gap: "4px",
            padding: SIZE_MAPPING[size]?.textPadding,
            borderRadius: borderRadius.primary,
            border: "none",
            whiteSpace: "nowrap",
            cursor: !disabled ? "pointer" : "not-allowed",
            fontSize: SIZE_MAPPING[size].fontSize,
            fontWeight: SIZE_MAPPING[size].fontWeight,
            lineHeight: SIZE_MAPPING[size].lineHeight,
        },
        active: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: PRIMARY.W_50,
            color: BRAND.PRIMARY,
            gap: "4px",
            padding: SIZE_MAPPING[size]?.padding,
            borderRadius: borderRadius.primary,
            border: "none",
            whiteSpace: "nowrap",
            boxShadow:
                !disabled &&
                `inset -1px 0 0 ${BRAND.PRIMARY}, inset 0 -1px 0 ${BRAND.PRIMARY}, inset 1px 0 0 ${BRAND.PRIMARY}, inset 0 1px 0 ${BRAND.PRIMARY}`,
            cursor: !disabled ? "pointer" : "not-allowed",
            fontSize: SIZE_MAPPING[size].fontSize,
            fontWeight: SIZE_MAPPING[size].fontWeight,
            lineHeight: SIZE_MAPPING[size].lineHeight,
        },
        iconStyle: {
            fontSize: SIZE_MAPPING[size].iconSize,
            color:
                (variant === "ghost" || props?.variant === "text") &&
                (disabled ? GREY.DISABLED : GREY.TERTIARY),
        },
        loaderIcon: {
            width: SIZE_MAPPING[size].width,
            height: SIZE_MAPPING[size].height,
            margin: "0px",
        },
    };

    return (
        <CustomTooltip title={tooltipLabel}>
            <button
                style={styles[variant]}
                {...props}
                className={!disabled && classes[variant]}
                disabled={disabled || isLoading}
            >
                {LeftIcon ? (
                    isLoading ? (
                        <CircularLoader
                            style={styles.loaderIcon}
                            loadercolor={SIZE_MAPPING[variant]?.color}
                        />
                    ) : (
                        <Image
                            id={classes.icon}
                            src={LeftIcon}
                            style={styles.iconStyle}
                        />
                    )
                ) : null}
            </button>
        </CustomTooltip>
    );
};

export default ImageButton;

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in button then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props description :-
// label : Label you need to display on Button.
// LeftIcon : image src displayed on left of the label.
// isLoading : this prop will enable loader in button.
// size : this prop is for the size of button.
// variant : this prop is used for the variant(primary, ghost, text, active) of button.
// disabled : this prop will have value true/false.
