import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import RGL, { WidthProvider } from "react-grid-layout";
import { Styles } from "./Styles";
import { TopBar } from "./components/TopBar";
import { AddWidget } from "./components/AddWidget";
import { EmptyDashboard } from "./components/EmptyDashboard";
import { Widget } from "./components/Widget";
import { Modal } from "@mui/material";
import { CreateDashboardPopUp } from "./components/createDashboardPopUp";
import {
  getChartData,
  getCurrentDashboard,
  getDashboardList,
  getDashboardWidgetList,
  getWidgetCoordinates,
} from "../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UpdateDashboardPopUp } from "./components/updateDashboardPopUp";
import { SetDefaultDashboardPopUp } from "./components/setDefaultDashboardPopUp";
import { CloneDashboardPopUp } from "./components/cloneDashboardPopUp";
import {
  dashboardEndpoints,
  dashboardWidgetEndpoints,
  onboardingEndpoint,
} from "../../utils/constants/httpConstants";
import { postRequest } from "../../Apis";
import {
  DASHBOARD_LIST_PATH,
  LOGIN_PATH,
} from "../../utils/constants/routes";
import { getErrorMessage, replaceKeyInArrayOfObj } from "../../utils/helpers";
import { deleteRequest, getRequest, putRequest } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../utils/constants";
import { RemoveDashboardPopUp } from "./components/removeDashboardPopUp";
import { Button } from "../../components/Buttons";
import { STRINGS } from "./strings";
import { ICON_LABELS, SHADOW } from "../../utils/constants/UI";
import CustomHeader from "../../components/Header/CustomHeader";
import { AutoCompleteCustom } from "../../components/Autocomplete/AutoCompleteCustom";
import CustomMenu from "../../components/Menu/CustomMenu";
import WidgetDetails from "./components/widgetDetails";
import {
  DASHBOARD_DELETED_SUCCESSFULLY,
  DASHBOARD_NAME_LENGTH_ERROR,
  DASHBOARD_NAME_LONG_LENGTH_ERROR,
  EMPTY_DASHBOARD_FIELD_ERROR,
  EMPTY_WIDGET_FIELD_ERROR,
  WIDGET_CREATION_SUCCESS_MESSAGE,
  WIDGET_NAME_LONG_LENGTH_ERROR,
} from "../../utils/constants/messages";
import { RemovePopUp } from "./components/removePopUp";
import { ClonePopUp } from "./components/clonePopUp";
import ShareDashboardModal from "./components/ShareDashboardModal";
import SkeletonLoader from "../../components/Loaders/SkeletonLoader";
import DashboardSkeleton from "./components/DashboardSkeleton";
import { BRAND } from "../../utils/constants/colors";

const defaultLayout = [
  { h: 2, i: "0", w: 3, x: 0, y: 0, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "1", w: 3, x: 3, y: 0, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "2", w: 3, x: 0, y: 2, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "3", w: 3, x: 3, y: 2, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "4", w: 3, x: 0, y: 4, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "5", w: 3, x: 3, y: 4, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "6", w: 3, x: 0, y: 6, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "7", w: 3, x: 3, y: 6, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "8", w: 3, x: 0, y: 8, minW: 2, minH: 2, maxH: 3 },
  { h: 2, i: "9", w: 3, x: 3, y: 8, minW: 2, minH: 2, maxH: 3 },
];

const DashboardScreen = () => {
  const classes = Styles();
  const [checked, setChecked] = useState(false);
  const [shareDashboard, setShareDashBoard] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardListSelector = useSelector(
    (state) => state.getDashboardDetails
  );
  const dashboardWidgetListSelector = useSelector(
    (state) => state.getDashboardWidgetDetails
  );
  const currentDashboardSelector = useSelector(
    (state) => state.getCurrentDashboard
  );

  const userDetailsSelector = useSelector((state) => state?.userDetails);

  const originalItems = [
    "line",
    "pie",
    "bar-horizontal",
    "bar-vertical",
    "table",
    "box",
  ];

  const ReactGridLayout = WidthProvider(RGL);
  const location = useLocation();
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [createDashboardFlag, setCreateDashboardFlag] = useState(false);
  const [createDashboardModalFlag, setCreateDashboardModalFlag] =
    useState(false);
  const [listDetails, setListDetails] = useState([]);
  const [dashBoard, setDashBoard] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [typeReport, setTypeReport] = useState("");
  const [dashboardRemoveFlag, setDashboardRemoveFlag] = useState(false);
  const [dashboardUpdateFlag, setDashboardUpdateFlag] = useState(false);
  const [dashboardDefaultFlag, setDashboardDefaultFlag] = useState(false);
  const [dashboardCloneFlag, setDashboardCloneFlag] = useState(false);
  const [createErr, setCreateErr] = useState("");
  const [updateErr, setUpdateErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateLayoutFlag, setUpdateLayoutFlag] = useState(false);
  const [isTourOpen, setTourOpen] = useState(false);
  const [allRefresh, setAllRefresh] = useState(false)
  const [stepId, setStepId] = useState(location.state?.steps || null);

  const [dashboardstep, setDashboardstep] = useState(0);
  const [report, setReport] = useState(["Select Report"]);
  const [campaignList, setCampaignList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  const [widgetType, setWidgetType] = useState(false);
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);
  const classboarding = isTourOpen ? "onboarding" : "";
  const [guidemodal, setGuidemodal] = useState(false);
  const onboardingPage = location?.pathname?.trim()?.split("/")[2];
  const [totalstep, setTotalstep] = useState(0);
  const [newDashName, setNewDashName] = useState("");
  const [dashboardName, setDashboardName] = useState([]);
  const [dashboardId, setDashboardId] = useState([]);

  const [widgetChartData, setWidgetChartData] = useState([]);
  const [widgetDetailFlag, setWidgetDetailFlag] = useState(false);
  const [clickedWidgetDetail, setClickedWidgetDetail] = useState({});

  const [removeFlag, setRemoveFlag] = useState(false);
  const [cloneFlag, setCloneFlag] = useState(false);
  const [cloneErr, setCloneErr] = useState("");
  const [dashboardErr, setDashboardErr] = useState("");
  const [headerWidgetName, setHeaderWidgetName] = useState("");
  const [widgetIndex, setWidgetIndex] = useState(0);
  const [cloneSent, setCloneSent] = useState({});

  let nextelement = document.body.querySelector(
    'button[data-tour-elem="right-arrow"]'
  );

  const handleDetail = async () => {
    setWidgetDetailFlag(!widgetDetailFlag);
  };

  const handleWidgetDetail = async () => {
    if (widgetType) {
      setWidgetDetailFlag(!widgetDetailFlag);
    } else {
      dispatch(
        getDashboardWidgetList(
          navigate,
          clickedWidgetDetail?.dashboard_id,
          true
        )
      );
      setWidgetDetailFlag(!widgetDetailFlag);
    }
  };

  const getStatus = async () => {
    try {
      const response = await getRequest(
        navigate,
        onboardingEndpoint.onboardingStatus
      );

      setTotalstep(response?.data?.data?.total_complate_step || 0);
    } catch (error) { }
  };

  const closedModal = () => {
    setGuidemodal(!guidemodal);
  };
  const closeTour = () => {
    setTourOpen(false);
    setStepId(null);
    setDashboardstep(0);

    navigate(location.pathname, { replace: true });
  };

  const complteTour = async () => {
    const payload = {
      step_name: "dashboard",
      step_id: stepId,
    };
    try {
      await postRequest(navigate, onboardingEndpoint.onboardingSteps, payload);

      setTourOpen(false);
      setStepId(null);
      setDashboardstep(0);

      setGuidemodal(true);
      getStatus();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLayoutChange = (temp) => {
    setLayouts(temp);
  };

  const handleCreatePopUp = () => {
    handleClose();
    setCreateDashboardModalFlag(
      createDashboardModalFlag && dashboardstep === 0 ? false : true
    );
    setCreateErr("");
  };

  const handleUpdatePopUp = () => {
    handleClose();
    setDashboardUpdateFlag(!dashboardUpdateFlag);
    setUpdateErr("");
  };

  const handleDefaultPopUp = () => {
    handleClose();
    setDashboardDefaultFlag(!dashboardDefaultFlag);
    setUpdateErr("");
  };

  const handleRemovePopUp = () => {
    handleClose();
    setDashboardRemoveFlag(!dashboardRemoveFlag);
  };

  const handleClonePopUp = () => {
    handleClose();
    setDashboardCloneFlag(!dashboardCloneFlag);
    setCreateErr("");
  };

  const handlePinPopUp = async () => {
    try {
      const filterData = dashboardListSelector?.payload?.records?.filter(
        (item) => {
          if (item._id === dashboardId[0]) return item;
        }
      );
      const payload = {
        _id: filterData[0]?._id,
        dashboard_name: filterData[0].dashboard_name,
        dashboard_type:
          filterData[0].dashboard_type == "team"
            ? "public-read"
            : filterData[0].dashboard_type,
        is_default_dashboard: filterData[0]?.is_default_dashboard,
        pin: filterData[0].pin == 0 || !filterData[0].pin ? 1 : 0,
      };
      const response = await putRequest(
        navigate,
        dashboardEndpoints?.updateDashboard,
        payload
      );
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        dispatch(getDashboardList(navigate));
      }
    } catch (e) { }
  };

  // const handleShareDashboard = () => {
  //   handleClose();
  //   setShareDashBoard(!shareDashboard);
  // };

  const handleAllRefresh = async () => {
    handleClose();
    if (widgets?.length > 0) {
      setAllRefresh(true)
      let widgetIdArr = [];
      dashboardWidgetListSelector?.payload?.records.map((temp, i) => {
        return widgetIdArr.push({
          id: temp._id,
        });
      });
      let payload = {
        widget_ids: [...widgetIdArr],
      };
      await dispatch(getChartData(navigate, payload, true, true));
      setAllRefresh(false);
    }
  };

  const onRemoveItem = (itemIndex) => {
    setItems(items.filter((d, i) => i !== itemIndex));
  };

  const onAddItem = (itemId, type, filters) => {
    setItems([...items, itemId]);
    let payload = {
      widget_type: itemId,
      data_type: type,
      dashboard_id: dashBoard._id,
      dashboard_name: dashBoard.dashboard_name,
      filters: filters,
    };
    createDashboardWidget(payload);
    setUpdateLayoutFlag(true);
  };

  const handleWidget = () => {
    setWidgetType(false);
    setChecked(checked && dashboardstep === 0 ? false : true);
    setReport(["Select Report"]);
  };

  const handleListItems = (temp) => {
    setDashBoard(temp);
    dispatch(getCurrentDashboard(temp));
  };

  const createDashboardWidget = async (payload) => {
    setLoading(true);
    try {
      const URL = dashboardWidgetEndpoints?.createDashboardWidget;
      const response = await postRequest(navigate, URL, payload);
      setDashboardName([payload?.dashboard_name]);
      setDashboardId([payload?.dashboard_id]);
      if (response?.status === 201) {
        EventEmitter.emit(EMITTER_SUCCESS, WIDGET_CREATION_SUCCESS_MESSAGE);
        await dispatch(
          getDashboardWidgetList(navigate, payload.dashboard_id, true)
        );
        setLoading(false);
      }
    } catch (error) {
      console.log("error---->>> ", error);
      // log user out here.
      if (error?.status === 401) {
        // navigate from this action
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.message || error?.message
        );
        navigate(LOGIN_PATH);
      } else {
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.message || error?.message
        );
      }
      setLoading(false);
    }
  };

  const createDashboardApiCall = async (payload, func) => {
    try {
      const URL = dashboardEndpoints?.createDashboard;
      const response = await postRequest(navigate, URL, payload);
      if (response?.status === 201) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        func();
        setLoading(false);
      }
      setDashboardName([payload?.dashboard_name]);
      setDashboardId([payload?._id]);
      setNewDashName(payload?.dashboard_name);
      await dispatch(getDashboardList(navigate, { limit: 25 }));
      nextelement.click();
    } catch (error) {
      // log user out here.
      if (error?.status === 401) {
        // navigate from this action
        navigate(LOGIN_PATH);
      }
      setLoading(false);
      setCreateErr(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const createDashboard = async (payload, func, e) => {
    e?.preventDefault();
    if (payload.dashboard_name?.trim()?.length > 0) {
      if (payload.dashboard_name?.trim()?.length < 3) {
        setCreateErr(DASHBOARD_NAME_LENGTH_ERROR);
      } else {
        if (payload.dashboard_name?.trim()?.length > 50) {
          setCreateErr(DASHBOARD_NAME_LONG_LENGTH_ERROR);
        } else {
          setLoading(true);
          createDashboardApiCall(payload, func);
        }
      }
    } else {
      setCreateErr(EMPTY_DASHBOARD_FIELD_ERROR);
    }
  };

  const cloneDahsboardApiCall = async (payload, func) => {
    try {
      const URL = dashboardEndpoints?.cloneDashboard;
      const response = await postRequest(navigate, URL, payload);
      if (response?.status === 201) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        func();
        setLoading(false);
      }
      setDashboardName([payload?.dashboard_name]);
      setDashboardId([payload?._id]);
      setNewDashName(payload?.dashboard_name);
      await dispatch(getDashboardList(navigate, false));
    } catch (error) {
      // log user out here.
      if (error?.status === 401) {
        // navigate from this action
        navigate(LOGIN_PATH);
      }
      setLoading(false);
      setCreateErr(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const cloneDashboard = async (payload, func) => {
    if (payload.dashboard_name?.trim()?.length > 0) {
      if (payload.dashboard_name?.trim()?.length < 3) {
        setCreateErr(DASHBOARD_NAME_LENGTH_ERROR);
      } else {
        if (payload.dashboard_name?.trim()?.length > 50) {
          setCreateErr(DASHBOARD_NAME_LONG_LENGTH_ERROR);
        } else {
          setLoading(true);
          cloneDahsboardApiCall(payload, func);
        }
      }
    } else {
      setCreateErr(EMPTY_DASHBOARD_FIELD_ERROR);
    }
  };

  const updateDashboardApiCall = async (payload, func) => {
    try {
      const URL = dashboardEndpoints?.updateDashboard;
      const response = await putRequest(navigate, URL, payload);
      if (response?.status === 200) {
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        func();
        setLoading(false);
      }
      setDashboardName([payload?.dashboard_name]);
      setDashboardId([payload?._id]);
      setNewDashName(payload?.dashboard_name);
      await dispatch(getDashboardList(navigate, false));
      let updateDashboard = currentDashboardSelector?.payload;
      updateDashboard.dashboard_type = payload.dashboard_type;
      dispatch(getCurrentDashboard(updateDashboard));
    } catch (error) {
      setLoading(false);
      setUpdateErr(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  const updateDashboard = async (payload, func, e) => {
    e?.preventDefault();
    if (payload.dashboard_name?.trim()?.length > 0) {
      if (payload.dashboard_name?.trim()?.length < 3) {
        setUpdateErr(DASHBOARD_NAME_LENGTH_ERROR);
      } else {
        if (payload.dashboard_name?.trim()?.length > 50) {
          setUpdateErr(DASHBOARD_NAME_LONG_LENGTH_ERROR);
        } else {
          setLoading(true);
          updateDashboardApiCall(payload, func);
        }
      }
    } else {
      setUpdateErr(EMPTY_DASHBOARD_FIELD_ERROR);
    }
  };

  const deleteDashboard = async (payload, func) => {
    setLoading(true);
    try {
      const URL = dashboardEndpoints?.deleteDashboard;
      const response = await deleteRequest(URL, payload);
      if (response?.status === 200) {
        func();
        EventEmitter.emit(EMITTER_SUCCESS, DASHBOARD_DELETED_SUCCESSFULLY);
        setLoading(false);
      }
      dispatch(getDashboardList(navigate, false));
      dispatch(getCurrentDashboard());
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message || error?.message);
      setLoading(false);
    }
  };

  const setDefaultDashboard = async (payload, func) => {
    setLoading(true);
    try {
      const URL = dashboardEndpoints?.setDefaultDashboard;
      const response = await putRequest(navigate, URL, payload);
      if (response?.status === 200) {
        func();
        setLoading(false);
      }
      setDashboardName([payload?.dashboard_name]);
      setDashboardId([payload?._id]);
      setNewDashName(payload?.dashboard_name);
      await dispatch(getDashboardList(navigate, { page: 1, limit: 25 }));
    } catch (error) {
      setLoading(false);
      setUpdateErr(
        getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
      );
    }
  };

  useEffect(() => {
    dispatch(getDashboardList(navigate, { limit: 25 }));
    setReport([STRINGS.SELECT_REPORT]);
    (async () => {
      try {
        const responseCampaign = await getRequest(
          navigate,
          dashboardEndpoints.getCampaignList
        );
        setCampaignList(responseCampaign?.data?.data);
        const responseSequence = await getRequest(
          navigate,
          dashboardEndpoints.getSequenceList
        );
        setSequenceList(responseSequence?.data?.data);
      } catch (error) { }
    })();
  }, [dispatch, navigate]);

  useEffect(() => {
    let temp = dashboardListSelector?.payload?.records;
    if (temp?.length <= 0) {
      setCreateDashboardFlag(true);
    } else if (temp?.length > 0) {
      // storing default icon key and icon label in dropdown list.
      const all_dashboard_list = [...temp];
      let refined_dashboard_list = all_dashboard_list?.map((item) => {
        if (item?.is_default_dashboard) {
          return { ...item, iconLabel: ICON_LABELS.BOOKMARK }
        } else {
          return { ...item }
        }
      })
      setListDetails(refined_dashboard_list);
      setCreateDashboardFlag(false);
      // check for redirection to current dashboard only if performed any action on dashboard and did not created new dashboard.
      if (localStorage.getItem("dashboardid")) {
        if (currentDashboardSelector?.payload?._id?.length) {
          // check for updated/rename/created dashboard and redirecting to updated dashboard
          if (newDashName === "") {
            const data = temp?.filter((item) => item?.dashboard_name === localStorage.getItem("dashboardid"));
            handleListItems(data[0]);
            setDashboardName([data[0]?.dashboard_name]);
            setDashboardId([data[0]?._id]);
          } else {
            let newDashboard = temp?.filter(
              (item) => item?.dashboard_name === newDashName
            );
            handleListItems(newDashboard[0]);
            setDashboardName([newDashboard[0]?.dashboard_name]);
            setDashboardId([newDashboard[0]?._id]);
          }
        }
        // redirects to default dashboard or 1st index dashboard if refresh the page.
        else {
          const data = temp?.filter((item) => item?.dashboard_name === localStorage.getItem("dashboardid"));
          handleListItems(data[0]);
          setDashboardName([data[0]?.dashboard_name]);
          setDashboardId([data[0]?._id]);
        }
      } else {
        if (currentDashboardSelector?.payload?._id?.length) {
          // check for updated/rename/created dashboard and redirecting to updated dashboard
          if (newDashName === "") {
            handleListItems(currentDashboardSelector?.payload);
            setDashboardName([
              currentDashboardSelector?.payload?.dashboard_name,
            ]);
            setDashboardId([currentDashboardSelector?.payload?._id]);
          } else {
            let newDashboard = temp?.filter(
              (item) => item?.dashboard_name === newDashName
            );
            handleListItems(newDashboard[0]);
            setDashboardName([newDashboard[0]?.dashboard_name]);
            setDashboardId([newDashboard[0]?._id]);
          }
        }
        // redirects to default dashboard or 1st index dashboard if refresh the page.
        else {
          let loggedInUserDefaultDashboard = temp.filter(
            (item) => item.is_default_dashboard
          );
          let loggedInUserPinDashboard = temp.filter(
            (item) => item.pin
          );
          if (loggedInUserDefaultDashboard.length) {
            handleListItems(loggedInUserDefaultDashboard[0]);
            setDashboardName([loggedInUserDefaultDashboard[0]?.dashboard_name]);
            setDashboardId([loggedInUserDefaultDashboard[0]?._id]);
          } else if (loggedInUserPinDashboard.length) {
            handleListItems(loggedInUserPinDashboard[0]);
            setDashboardName([loggedInUserPinDashboard[0]?.dashboard_name]);
            setDashboardId([loggedInUserPinDashboard[0]?._id]);
          } else {
            handleListItems(temp[0]);
            setDashboardName([temp[0]?.dashboard_name]);
            setDashboardId([temp[0]?._id]);
          }
        }
      }
    }
  }, [dashboardListSelector]);

  useEffect(() => {
    if (dashBoard?._id) {
      dispatch(getDashboardWidgetList(navigate, dashBoard._id, false));
    }
  }, [dashBoard]);

  useEffect(() => {
    let tempRecord = dashboardWidgetListSelector?.payload?.records;
    let tempLayout = dashboardWidgetListSelector?.payload?.layout;
    if (tempRecord?.length > 0) {
      setWidgets(tempRecord);
      setLayouts(tempLayout);
    }
  }, [dashboardWidgetListSelector]);

  useEffect(() => {
    if (widgets?.length > 0) {
      let widgetIdArr = [];
      let campaignWidgetCount = 0;
      let sequenceWidgetCount = 0;
      dashboardWidgetListSelector?.payload?.records.map((temp, i) => {
        temp.data_type === "campaign" && campaignWidgetCount++;
        temp.data_type === "sequence" && sequenceWidgetCount++;
        return widgetIdArr.push({
          id: temp._id,
        });
      });
      let payload = {
        widget_ids: [...widgetIdArr],
      };
      if (dashboardWidgetListSelector.loading === false) {
        dispatch(getChartData(navigate, payload, true));
      } else {
        dispatch(getChartData(navigate, payload, false));
      }
    }
  }, [widgets, dispatch, navigate]);

  useEffect(() => {
    if (stepId !== null) {
      setTourOpen(true);
    }
    if (stepId === null) {
      setDashboardstep(0);
    }
    if (dashboardstep > 0 && dashboardstep < 4) {
      setCreateDashboardModalFlag(true);
    } else {
      setCreateDashboardModalFlag(false);
    }
    if (dashboardstep > 4) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [stepId, dashboardstep]);


  useEffect(() => {
    if (updateLayoutFlag) {
      handleLayout(layouts);
      setUpdateLayoutFlag(false);
    }
  }, [layouts, updateLayoutFlag]);

  useEffect(() => {
    if (dashboardName?.length) {
      let allDashboardList = dashboardListSelector?.payload?.records;
      let dashboardData = allDashboardList?.filter(
        (item) => item?.dashboard_name === dashboardName[0]
      );

      if (dashboardData?.length) {
        setDashBoard(dashboardData[0]);
        dispatch(getCurrentDashboard(dashboardData[0]));
        dispatch(getWidgetCoordinates({}));
      }
    }
  }, [dashboardName]);

  const handleLayout = (obj) => {
    let payload = {
      dashboard_id: dashBoard._id,
      layout: obj,
    };
    updateDashboardLayout(payload);
  };

  const updateDashboardLayout = async (payload) => {
    try {
      const URL = dashboardEndpoints?.layoutDashboard;
      await putRequest(navigate, URL, payload);
    } catch (error) { }
  };

  const dashboardRef = useRef();
  dashboardRef.current = dashBoard;

  const layoutRef = useRef();
  layoutRef.current = layouts;

  const showListDashboard = () => {
    navigate(DASHBOARD_LIST_PATH);
  };

  const handleWidgetResize = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    let obj = {
      w: document.getElementById(placeholder.i)?.clientWidth,
      h: document.getElementById(placeholder.i)?.clientHeight,
      i: placeholder.i,
    };
    dispatch(getWidgetCoordinates(obj));
  };

  const handleWidgetResizeStop = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    let obj = {
      w: document.getElementById(newItem.i)?.clientWidth,
      h: document.getElementById(newItem.i)?.clientHeight,
      i: newItem.i,
    };
    if (userDetailsSelector?.payload?.email === dashBoard?.owner_email) {
      dispatch(getWidgetCoordinates(obj));
      handleLayout(layout);
    }
  };

  const headerData = [{ id: "dashboard", label: STRINGS.DASHBOARD }];

  const dashboardActionsList = [
    {
      id: "set_default",
      label: STRINGS.SET_AS_DEFAULT,
      iconLabel: ICON_LABELS.BOOKMARK,
      function: handleDefaultPopUp,
      isVisible:
        // userDetailsSelector.payload?.email ===
        //   currentDashboardSelector.payload?.owner_email &&
        !currentDashboardSelector.payload?.is_default_dashboard,
    },
    {
      id: "edit_dashboard",
      label: STRINGS.EDIT_DASHBOARD,
      iconLabel: ICON_LABELS.EDIT,
      function: handleUpdatePopUp,
      isVisible:
        userDetailsSelector.payload?.email ===
        currentDashboardSelector.payload?.owner_email,
    },
    {
      id: "clone_dashboard",
      label: STRINGS.CLONE_DASHBOARD,
      iconLabel: ICON_LABELS.CONTENT_COPY,
      function: handleClonePopUp,
      isVisible: true,
    },
    {
      id: "pin_dashboard",
      label: currentDashboardSelector.payload?.pin === 1 ? STRINGS.UNPIN : STRINGS.PIN,
      iconLabel: ICON_LABELS.PUSH_PIN,
      function: handlePinPopUp,
      isVisible:
        userDetailsSelector.payload?.email ===
        currentDashboardSelector.payload?.owner_email,
    },
    {
      id: "refresh_all_reports",
      label: STRINGS.REFRESH_ALL_REPORTS,
      iconLabel: ICON_LABELS.REFRESH,
      function: handleAllRefresh,
      isVisible: dashboardWidgetListSelector?.payload?.records?.length ? true : false,
    },
    {
      id: "delete_dashboard",
      label: STRINGS.DELETE_DASHBOARD,
      iconLabel: ICON_LABELS.DELETE,
      function: handleRemovePopUp,
      isVisible:
        (userDetailsSelector.payload?.email ===
          currentDashboardSelector.payload?.owner_email) && !currentDashboardSelector.payload?.is_default_dashboard,
      variant: "error",
    },
  ];

  const handleRemove = async (val) => {
    setTypeReport(val.widget_name);
    setNewDashName("");
    await handleClose();
    setRemoveFlag(!removeFlag);
  };
  const handleRemove1 = async () => {
    setNewDashName("");
    await handleClose();
    setRemoveFlag(!removeFlag);
  };

  const handleClone = (val) => {
    setCloneSent(val)
    setTypeReport(val.widget_name);
    handleClose();
    setCloneFlag(!cloneFlag);
    setCloneErr("");
  };

  const handleClone1 = () => {
    handleClose();
    setCloneFlag(!cloneFlag);
    setCloneErr("");
  };

  const cloneWidget = async (
    name,
    selectedValue,
    selectedValue1,
    dashboardName,
    idClone,
  ) => {
    let payload;
    if (selectedValue === "new") {
      payload = {
        widget_type: clickedWidgetDetail.widget_type,
        data_type: clickedWidgetDetail.data_type,
        dashboard_id: `${dashboardName === "" ? dashBoard._id : dashboardName}`,
        widget_name: name,
        new_dashboard: selectedValue === "new",
        dashboard_type: selectedValue1,
        filters: idClone
      }
    }
    else {
      payload = {
        widget_type: clickedWidgetDetail.widget_type,
        data_type: clickedWidgetDetail.data_type,
        dashboard_id: `${dashboardName === "" ? dashBoard._id : dashboardName}`,
        widget_name: name,
        new_dashboard: selectedValue === "new",
        dashboard_type: selectedValue1,
        filters: idClone
      }
    }


    if (selectedValue === "new") {
      if (dashboardName.length) {
        await cloneDashboardWidget(payload);
        setNewDashName(dashboardName);
        setUpdateLayoutFlag(true);
      } else {
        setDashboardErr(EMPTY_DASHBOARD_FIELD_ERROR);
      }
    } else {
      await cloneDashboardWidget(payload);
      setUpdateLayoutFlag(true);
    }
  };

  const cloneDashboardWidgetApiCall = async (payload) => {
    if (payload.widget_name.length > 50) {
      setCloneErr(WIDGET_NAME_LONG_LENGTH_ERROR);
    } else {
      setLoading(true);
      try {
        const URL = dashboardWidgetEndpoints?.cloneDashboardWidget;
        const response = await postRequest(navigate, URL, payload);
        if (response?.status === 201) {
          await dispatch(getDashboardWidgetList(navigate, dashBoard._id, true));
          handleClone1();
          setLoading(false);
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          await dispatch(getDashboardList(navigate, false));
          // navigate(0)
        }
      } catch (error) {
        // log user out here.
        if (error?.status === 401) {
          // navigate from this action
          navigate(LOGIN_PATH);
        }
        if (error?.status === 409) {
          // navigate from this action
          setDashboardErr(
            getErrorMessage(
              error?.data?.data?.errors || {},
              error?.data?.message
            )
          );
        } else {
          setCloneErr(
            getErrorMessage(
              error?.data?.data?.errors || {},
              error?.data?.message
            )
          );
        }
        setLoading(false);
      }
    }
  };

  const cloneDashboardWidget = async (payload) => {
    if (payload.widget_name.length === 0) {
      setCloneErr(EMPTY_WIDGET_FIELD_ERROR);
    } else if (payload.dashboard_id.length === 0) {
      setDashboardErr(EMPTY_DASHBOARD_FIELD_ERROR);
    } else if (payload.dashboard_id.length < 3) {
      setDashboardErr(DASHBOARD_NAME_LENGTH_ERROR);
    } else if (payload.dashboard_id.length > 50) {
      // alert("IN")
      setDashboardErr(DASHBOARD_NAME_LONG_LENGTH_ERROR);
    } else {
      cloneDashboardWidgetApiCall(payload);
    }
  };

  const deleteDashboardWidget = async () => {
    setLoading(true);
    try {
      let payload = {
        id: clickedWidgetDetail._id,
        dashboard_id: dashBoard._id,
        layout_index: widgetIndex,
      };
      const URL = dashboardWidgetEndpoints?.deleteDashboardWidget;
      const response = await deleteRequest(URL, payload);
      setDashboardName([dashBoard?.dashboard_name]);
      setDashboardId([dashBoard?._id]);
      await dispatch(getDashboardWidgetList(navigate, dashBoard._id, true));
      if (response?.status === 200) {
        handleRemove1();
        setUpdateLayoutFlag(true);
        setLoading(false);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      }
    } catch (error) {
      // log user out here.
      if (error?.status) {
        // navigate from this action
        handleRemove1();
        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.dashboardContainer} onbeforeunload>
        <div id="widgetTooltip"></div>
        {/* Create Dashboard Modal */}
        <CreateDashboardPopUp
          loading={loading}
          open={createDashboardModalFlag}
          onClose={handleCreatePopUp}
          handleCreatePopUp={handleCreatePopUp}
          createDashboard={createDashboard}
          createErr={createErr}
          dashboardstep={dashboardstep}
        />
        {/* Create Dashboard Modal */}

        {/* Delete Dashboard Modal */}
        <RemoveDashboardPopUp
          open={dashboardRemoveFlag}
          onClose={setDashboardRemoveFlag}
          loading={loading}
          dashBoard={dashBoard}
          handleRemovePopUp={handleRemovePopUp}
          deleteDashboard={deleteDashboard}
        />
        {/* Delete Dashboard Modal */}

        {/* Edit Dashboard Modal */}
        <UpdateDashboardPopUp
          loading={loading}
          open={dashboardUpdateFlag}
          onClose={handleUpdatePopUp}
          updateErr={updateErr}
          dashBoard={dashBoard}
          updateDashboard={updateDashboard}
          handleUpdatePopUp={handleUpdatePopUp}
        />
        {/* Edit Dashboard Modal */}

        {/* Set as default Dashboard Modal */}
        <SetDefaultDashboardPopUp
          open={dashboardDefaultFlag}
          onClose={handleDefaultPopUp}
          dashBoard={dashBoard}
          loading={loading}
          updateDashboard={setDefaultDashboard}
          handleDefaultPopUp={handleDefaultPopUp}
        />
        {/* Set as default Dashboard Modal */}

        {/* Clone Dashboard Modal */}
        <CloneDashboardPopUp
          open={dashboardCloneFlag}
          onClose={handleClonePopUp}
          dashBoard={dashBoard}
          loading={loading}
          createErr={createErr}
          cloneDashboard={cloneDashboard}
          handleClonePopUp={handleClonePopUp}
        />
        {/* Clone Dashboard Modal */}
        {/* Share Dashboard Modal */}
        {shareDashboard && (
          <ShareDashboardModal
            open={shareDashboard}
            dashboardID={dashboardId}
            widget={widgets}
            setOpen={setShareDashBoard}
            width={"34.740vw"}
          />
        )}

        {/* Share Dashboard Modal */}
        {/* Remove Widget Modal */}
        <RemovePopUp
          open={removeFlag}
          onClose={() => setRemoveFlag(false)}
          loading={loading}
          headerName={headerWidgetName}
          handleRemove={handleRemove}
          type={typeReport}
          removeWidget={deleteDashboardWidget}
        />
        {/* Remove Widget Modal */}

        {/* Clone Widget Modal */}
        <ClonePopUp
          open={cloneFlag}
          onClose={handleClone1}
          cloneErr={cloneErr}
          dashboardErr={dashboardErr}
          loading={loading}
          headerName={headerWidgetName}
          type={typeReport}
          wholeWidget={cloneSent}
          handleClone={handleClone}
          cloneWidget={cloneWidget}
        />
        {/* Clone Widget Modal */}

        {dashboardListSelector?.loading ? (
          <>
            <div className={classes.headerContainer}>
              <div className={classes.widthHeader}>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={14}
                />
              </div>
              <div className={classes.widthRightHeader1}>
                <SkeletonLoader
                  type="circular"
                  skeletonWidth={"100%"}
                  skeletonHeight={32}
                />
              </div>
            </div>
            <div className={classes.skeletonDisplay1}>
              <div className={classes.dataHeader}>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={14}
                />
              </div>
              <div className={classes.skeletonRightDisplay}>
                <div className={classes.dataHeader1}>
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={14}
                  />
                </div>
                <div className={classes.dataHeader2}>
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={14}
                  />
                </div>
                <div className={classes.dataHeader3}>
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={14}
                  />
                </div>
                <div className={classes.dataHeader4}>
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={14}
                  />
                </div>
              </div>
            </div>
            <DashboardSkeleton />
          </>
        ) : (
          <>
            {/* Body of Dashboard Screen */}
            {dashboardListSelector?.payload?.records?.length > 0 ? (
              <>
                <CustomHeader items={headerData} />
                <Box
                  className={`${classes.lowerContainer} ${dashBoard?._id === widgets[0]?.dashboard_id &&
                    widgets?.length > 0 &&
                    classes.dashboardLayoutSaved
                    }`}
                  cssclass={classboarding}
                >
                  <Box>
                    <Box className={classes.dashobardOperationsContainer}>
                      <div>
                        <AutoCompleteCustom
                          size={"sm36"}
                          items={replaceKeyInArrayOfObj(
                            listDetails,
                            "dashboard_name",
                            "label"
                          )}
                          LeftIcon={
                            dashBoard?.pin === 1
                              ? ICON_LABELS.PUSH_PIN
                              : (dashBoard?.is_default_dashboard ? ICON_LABELS.BOOKMARK : ICON_LABELS.GRID_VIEW)
                          }
                          isColor={dashBoard?.pin === 1 ? true : (dashBoard?.is_default_dashboard ? true : false)}
                          valueState={dashboardName}
                          setValueState={setDashboardName}
                          idState={dashboardId}
                          setIdState={setDashboardId}
                          paperWidth={`${document.getElementById("custom-dropdown")
                            ?.clientWidth
                            }px`}
                        />
                      </div>
                      <Box className={classes.dashAfterAction}>
                        <Button
                          label={STRINGS.ADD_WIDGETS}
                          LeftIcon={ICON_LABELS.ADD_WIDGET}
                          variant={"ghost"}
                          size={"sm36"}
                          disabled={
                            userDetailsSelector.payload?.email !==
                            currentDashboardSelector.payload?.owner_email
                          }
                          onClick={handleWidget}
                        />
                        <Button
                          label={STRINGS.ACTIONS}
                          RightIcon={ICON_LABELS.ACTION_ARROW}
                          variant={"ghost"}
                          size={"sm36"}
                          onClick={handleClick}
                        />
                        <Button
                          label={STRINGS.VIEW_ALL_DASHBOARDS}
                          LeftIcon={ICON_LABELS.GRID_VIEW}
                          variant={"ghost"}
                          size={"sm36"}
                          onClick={showListDashboard}
                        />
                        <Button
                          label={STRINGS.CREATE_DASHBOARD}
                          LeftIcon={ICON_LABELS.ADD}
                          size={"sm36"}
                          variant={"primary"}
                          onClick={handleCreatePopUp}
                        />
                        <CustomMenu
                          onClose={() => setAnchorEl(null)}
                          anchor={anchorEl}
                          open={Boolean(anchorEl)}
                          items={dashboardActionsList}
                          paperMenuClass={classes.paperMenuClass}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <div className={classes.container}>
                    <Box className={classes.subContainer}>
                      {dashboardWidgetListSelector?.loading || allRefresh ? (
                        <DashboardSkeleton />
                      ) : (
                        <Box
                          className={classes.gridMainContainer}
                          id="myContainer"
                        >
                          {dashBoard?._id === widgets[0]?.dashboard_id &&
                            dashboardWidgetListSelector?.payload?.records
                              ?.length > 0 ? (
                            <div
                              id="widget-conatiner"
                              className={classes.gridcontainer}
                            >
                              <ReactGridLayout
                                isDraggable={true}
                                isResizable={true}
                                onResize={handleWidgetResize}
                                onResizeStop={handleWidgetResizeStop}
                                onDragStop={handleWidgetResizeStop}
                                containerPadding={[0, 0]}
                                layout={
                                  layouts?.length > 0 ? layouts : defaultLayout
                                }
                                transformScale={1}
                                draggableCancel={".no-drag"}
                                autoSize={true}
                                cols={6}
                                isDroppable={false}
                                className={classes.layout}
                                rowHeight={250}
                                onLayoutChange={onLayoutChange}
                              >
                                {widgets?.map((temp, i) => (
                                  <div
                                    className={classes.widgetContain}
                                    id={i}
                                    key={i}
                                    data-grid={{
                                      w: 3,
                                      h: 2,
                                      x: i * 3,
                                      y: i * 2,
                                      minW: 2,
                                      minH: 2,
                                      maxH: 3,
                                    }}
                                  >
                                    <Widget
                                      widgetDetails={temp}
                                      index={i}
                                      dashBoard={dashBoard}
                                      onRemoveItem={onRemoveItem}
                                      setUpdateLayoutFlag={setUpdateLayoutFlag}
                                      report={report}
                                      campaignList={campaignList}
                                      sequenceList={sequenceList}
                                      setCampaignList={setCampaignList}
                                      setSequenceList={setSequenceList}
                                      setIsWidgetLoading={setIsWidgetLoading}
                                      isWidgetLoading={isWidgetLoading}
                                      setWidgetChartData={setWidgetChartData}
                                      setNewDashName={setNewDashName}
                                      setClickedWidgetDetail={
                                        setClickedWidgetDetail
                                      }
                                      setWidgetIndex={setWidgetIndex}
                                      handleRemove={() => handleRemove(temp)}
                                      handleClone={() => handleClone(temp)}
                                      handleDetail={handleDetail}
                                      setHeaderWidgetName={setHeaderWidgetName}
                                      setDashboardName={setDashboardName}
                                      setDashboardId={setDashboardId}
                                    />
                                  </div>
                                ))}
                              </ReactGridLayout>
                            </div>
                          ) : (
                            <EmptyDashboard
                              openwidget={handleWidget}
                              createDashboardFlag={createDashboardFlag}
                              handleCreatePopUp={handleCreatePopUp}
                            />
                          )}
                        </Box>
                      )}

                      <AddWidget
                        report={report}
                        setReport={setReport}
                        in={checked}
                        close={handleWidget}
                        isTourOpen={isTourOpen}
                      >
                        <TopBar
                          items={items}
                          onRemoveItem={onRemoveItem}
                          onAddItem={onAddItem}
                          originalItems={originalItems}
                          close={handleWidget}
                          report={report}
                          dashboard={dashBoard}
                          campaignList={campaignList}
                          sequenceList={sequenceList}
                          widgetType={widgetType}
                          setWidgetType={setWidgetType}
                          dashboardstep={dashboardstep}
                          closeTour={closeTour}
                          complteTour={complteTour}
                        />
                      </AddWidget>

                      <WidgetDetails
                        loading={loading}
                        ownerName={dashBoard?.dashboard_name}
                        handleDetail={handleWidgetDetail}
                        widgetDetails={clickedWidgetDetail}
                        chartData={widgetChartData}
                        widgetDetailFlag={widgetDetailFlag}
                        setWidgetDetailFlag={setWidgetDetailFlag}
                        report={report}
                        campaignList={campaignList}
                        sequenceList={sequenceList}
                        setCampaignList={setCampaignList}
                        setSequenceList={setSequenceList}
                      />
                    </Box>
                  </div>
                </Box>
              </>
            ) : (
              <EmptyDashboard
                openwidget={handleWidget}
                createDashboardFlag={createDashboardFlag}
                handleCreatePopUp={handleCreatePopUp}
                isEmpty={true}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DashboardScreen;
