import { Box } from '@mui/material';
import React from 'react';
import SkeletonLoader from '../../../../components/Loaders/SkeletonLoader';

const CampaignStepSkeleton = () => {

    const steps = [{},{},{},{},{}]

    return <Box>
        { steps.map((step, index)=>{
            return <Box>
                <SkeletonLoader variant="rounded" skeletonHeight={60} skeletonWidth={100} />
            </Box>
        }) }
    </Box>
}

export default CampaignStepSkeleton;