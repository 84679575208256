export const STRINGS = {
  CANCEL: "Cancel",
  RESET: "Reset",
  RESET_COLUMNS: "Reset the Columns",
  DEFAULT_COLUMN_STATE:
    "Resetting current columns, will restore the default column state.",
  DELETE: "Delete",
  SAVE: "Save",
  REPORT: "Report a Bug",
  GET_MORE_CREDITS: "Get More Credits",
  CREDIT_CONTENT: "It seems you've reached your maximum credit limit.",
  // CREDIT_CONTENT2: "maximum limit to add users.",
  REACH_FOR_CREDITS:
    "Please reach out to support@reachiq.io to request for more credits.",
  REQUEST_FOR_MORE_CREDITS: "Request for more credits",
  ERR_MESSAGE:
    "You have already requested free credits. Please contact us at support@reachiq.io",
  RESET_ALL_PROPERTIES: "Reset all Properties",
  RESET_ALL_PROPERTIES_DESC: "Are you sure you want to reset all properties?",
  COLUMN_RESET: "Columns Reset successfully",

};
