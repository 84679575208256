export const STRINGS = {
  SEND: "Send Reset Password Link ",
  RESET_YOUR_PASSWORD: "Forgot Password",
  PLEASE_ENTER_EMAIL: "Please enter your email address below.",
  INSTRUCTIONS: "We will send you instructions to reset your password.",
  EMAIL: "Email ",
  PRIVACY: " Privacy",
  AND: "and",
  TERMS: " Terms.",
  REMEMEBER: "I think, I remember",
  TO_GET_STARTED: "You can reset your password now if you forgot it.",
  RESET_PASSWORD: "Reset Password",
  PASSWORD: " New Password",
  password: "password",
  CONFIRM_PASSWORD: "Confirm Password",
  TEXT: "text",
  DO_YOU_WANT: "Don't want to reset password?",
  LOG_IN: "Log in",
  MY_PASSWORD: "my password",
  ERROR: "error",
  DEFAULT: "default",
  ENTER_EMAIL: "Enter Email",
  ENTER_PASSWORD: "Enter New Password",
  ENTER_CONFIRM_PASS: "Enter Confirm Password",
};
