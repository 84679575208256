import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  DEBOUNCE_DELAY,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  contactListHeadData,
} from "../../utils/constants";
import { useNavigate, createSearchParams, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listEndPoints } from "../../utils/constants/httpConstants";
import { getRequest, postRequest, putRequest } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import BulkDeleteModal from "./components/Template/BulkDeleteModal";
import CreateCommonFolder from "./components/common/CreateCommonFolder";
import {
  debounceHelper,
  generateBreadCrumbDescription,
  getErrorMessage,
} from "../../utils/helpers";
import { getContactListData } from "../../redux/actions/contactListactions";
import RemoveFolder from "./components/common/RemoveFolder";
import { LIST_PATH, MARKETING_PROSPECT_SCREEN_PATH, PEOPLE_SCREEN_PATH } from "../../utils/constants/routes";
import { STRINGS } from "./strings";
import { Button } from "../../components/Buttons";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import SearchField from "../../components/InputFields/SearchField";
import { GREY, WARNING } from "../../utils/constants/colors";
import TypographyInline from "../../components/Typography/TypographyInline";
import CustomTable from "../../components/Table/CustomTable";
import ListDeleteFolderModal from "./components/Lists/ListDeleteFolderModal";
import { tableEndpointChange } from "../../redux/actions/tableActions";
import { allFilters } from "../../redux/actions/filterActions";
import CustomHeader from "../../components/Header/CustomHeader";
import { paginationDataAction } from "../../redux/actions/customTableActions";
import CreateListModal from "./components/Lists/CreateListModal";
import CustomStyledMenu from "../../components/Menu/CustomStyledMenu";
import FolderSkeletonLoader from "./components/common/FolderSkeletonLoader";
import IconComponent from "../../components/Icon/IconComponent";
import {
  DEFAULT_ERROR_MESSAGE,
  SELECT_ATLEAST_LIST,
  SELECT_FOLDER,
  SELECT_LIST,
} from "../../utils/constants/messages";
import classes from "./marketing.module.css";
import EmptyScreen from "./EmptyScreen";

const ListsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const { id } = useParams();
  const [isActive, setIsActive] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [bulkDelete, setBulkDelete] = useState(false);
  const [isLoadData, setIsLoadData] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [removeFolder, setRemoveFolder] = useState(false);
  const [getMoveData, setGetMoveData] = useState([]);
  const [folderEddit, setFolderEddit] = useState("");
  const [itemId, setItemId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModal1, setShowDeleteModal1] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditModal1, setShowEditModal1] = useState(false);
  const [editInitialValue, setEditInitialValue] = useState("");
  const [delName, setDelName] = useState(null);
  const [deletePayload, setDeletePayload] = useState(null);
  const [viewType, setViewType] = useState("");
  const [myRecord, setMyRecord] = useState();
  const [myName, setMyName] = useState("");
  const [view, setView] = useState("");
  const [permission, setPermission] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isMount, setIsMount] = useState(false);
  const [isOperationPermitted, setIsOperationPermitted] = useState(true);
  const [headerItems, setHeaderItems] = useState([
    { id: STRINGS.LIST_TEXT, label: STRINGS.LIST_TEXT, path: LIST_PATH },
  ]);
  const sortSelector = useSelector((state) => state.getPaginationQuery);
  const getContactListSelector = useSelector(
    (state) => state.getContactDataList
  );
  const userDetailsSelector = useSelector((state) => state?.userDetails);

  const handleEdit = async (list, val) => {
    setFolderEddit(val);
    setItemId(list?._id);
    setEditInitialValue(list?.list_name);
    setViewType(list?.permission);
    setShowEditModal(true);
  };

  const handleDelete = async (list, val) => {
    setFolderEddit(val);
    setShowDeleteModal(true);
    setDelName(list?.list_name);
    setDeletePayload(list?._id);
  };

  const deleteRecord = (row, head) => {
    handleClose();
    setMyRecord(row?._id);
    setMyName(row?.list_name);
    setShowDeleteModal1(true);
  };

  const editFolder = (row, head) => {
    setMyRecord(row?._id);
    setMyName(row?.list_name);
    setView(row?.permission);
    setShowEditModal1(true);
  };

  const openCreateModal = () => {
    setAnchorEl(null);
    setOpen2(true);
  };

  const backHandle = (id) => {
    setIsActive(id);
  };

  const removeListFolder = () => {
    if (sortSelector?.data?.checkedRow?.length > 0) {
      setRemoveFolder(true);
    } else {
      EventEmitter.emit(EMITTER_ERROR, SELECT_LIST);
    }
  };

  function handleBulkDelete() {
    if (sortSelector?.data?.checkedRow?.length > 0) {
      setBulkDelete(true);
    } else {
      EventEmitter.emit(EMITTER_ERROR, SELECT_LIST);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsActive("");
    setAnchorEl(null);
  };

  function handleFolder() {
    setOpen2(true);
  }

  const hitMoveList = async () => {
    try {
      setIsLoadData(true);
      if (userDetailsSelector?.payload?.role !== STRINGS.ADMIN_USER) {
        const rsp = await postRequest(navigate, listEndPoints.getMoveList, {
          owner_ids: [],
        });
        setGetMoveData(rsp?.data?.data?.records || []);
      } else {
        let userIdArr = getContactListSelector?.data?.data?.records
          ?.filter((item) =>
            sortSelector?.data?.checkedRow?.includes(item?._id)
          )
          ?.map((item) => {
            return item?.user_id;
          });
        const rsp = await postRequest(navigate, listEndPoints.getMoveList, {
          owner_ids: userIdArr,
        });
        setGetMoveData(rsp?.data?.data?.records || []);
      }
    } catch (error) {
    } finally {
      setIsLoadData(false);
    }
  };

  const filterMoveTemplate = async (valID) => {
    if (sortSelector?.data?.checkedRow?.length > 0 && valID) {
      try {
        const payload = {
          list_ids: sortSelector?.data?.checkedRow,
          parent: valID,
        };
        setIsLoad(true);
        const response = await putRequest(
          navigate,
          listEndPoints.moveToFolderList,
          payload
        );

        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        navigate(`${LIST_PATH}/${valID}`);

        setIsLoad(false);
        setAnchorEl(null);
        setIsActive("");
        dispatch(getContactListData({ parent: id }, navigate));
        dispatch(paginationDataAction({ checkedRow: [] }));
      } catch (err) {
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
        );
        setIsLoad(false);
      }
    } else if (sortSelector?.data?.checkedRow?.length > 0) {
      EventEmitter.emit(EMITTER_ERROR, SELECT_FOLDER);
    } else {
      EventEmitter.emit(EMITTER_ERROR, SELECT_ATLEAST_LIST);
    }
  };

  const handleSearch = () => {

    try {
      // setSearchValue(event.target.value);
      dispatch(
        paginationDataAction({
          ...sortSelector?.data,
          checkedRow: [],
          query: {
            ...sortSelector?.data?.query,
            search: searchValue,
            page: 1,
          },
        })
      );
    } catch (e) {
      EventEmitter.emit(EMITTER_ERROR, DEFAULT_ERROR_MESSAGE);
    }
  };

  const listTableData = {
    heads: contactListHeadData,
    body: getContactListSelector?.data?.data?.records || [],
    operations: [
      {
        id: STRINGS.SMALL_DELETE,
        label: STRINGS.DELETE,
        iconLabel: ICON_LABELS.DELETE,
        variant: "error",
        function: (row, head) => {
          if (row?.type === STRINGS.SMALL_FOLDER) deleteRecord(row, head);
          else handleDelete(row, head);
        },
      },
      {
        id: STRINGS.EDIT,
        label: STRINGS.COMMON_EDIT,
        iconLabel: ICON_LABELS.EDIT,
        function: (row, head) => {
          if (row?.type === STRINGS.SMALL_FOLDER) editFolder(row, head);
          else handleEdit(row, head);
        },
      },
    ],
    actions: {
      marketing_list: {
        function: (a, b) => {
          // dispatch empty rows.
          dispatch(paginationDataAction({}));

          if (a.type === STRINGS.FILE) {
            dispatch(tableEndpointChange(STRINGS.SAVED_CONTACTS));
            dispatch(allFilters({ contact_lists: [a?._id] }));
            dispatch(getContactListData({ type: STRINGS.FILE }, navigate));
            navigate({
              pathname: MARKETING_PROSPECT_SCREEN_PATH,
              search: createSearchParams({
                contact_lists: a?._id,
              }).toString(),
            });
          } else
            navigate(`${LIST_PATH}/${a._id}`, {
              state: {
                folderName: a?.list_name,
              },
            });
        },

        loading: true,
      },
    },
    sortHandler: {},
    total_records: getContactListSelector?.data?.total_records || 0,
    isLoading: getContactListSelector?.loading,
    disable_key: STRINGS.DISABLE_KEY,
  };


  const isBulkOperationAllowed = (type = STRINGS.FILE) => {
    try {
      if (sortSelector?.data?.checkedRow?.length) {
        // check if only one type exists.
        const records = getContactListSelector?.data?.data?.records || [];
        const newRecords = [
          ...new Set(
            records
              .filter((item) =>
                sortSelector?.data?.checkedRow?.includes(item._id || item?.id)
              )
              ?.map((item) => item?.type)
          ),
        ];
        if (newRecords?.length >= 2) {
          return false;
        }
        if (
          type === STRINGS.SMALL_FOLDER &&
          newRecords.includes(`${STRINGS.SMALL_FOLDER}`)
        ) {
          return false;
        }

        // check for unique user_id in admin case.
        const selected_ids = sortSelector?.data?.checkedRow;
        const unique_records = [
          ...new Set(
            records
              ?.filter((item) => selected_ids.includes(item?._id))
              .map((item) => item?.user_id)
          ),
        ];
        if (unique_records?.length >= 2 && type === STRINGS.SMALL_FOLDER) {
          return false;
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const handleListCreate = () => {
    setOpenList(true);
  };

  const value = {
    create: STRINGS.CREATE_LISST,
    content: STRINGS.LIST_CONT,
    body: [STRINGS.LIST_BD],
    foot: STRINGS.CREATE_LISST,

  }

  const handleFolderSearch = async (e) => {
    e?.stopPropagation();
    try {
      let userIdArr = getContactListSelector?.data?.data?.records
        ?.filter((item) => sortSelector?.data?.checkedRow?.includes(item?._id))
        ?.map((item) => {
          return item?.user_id;
        });
      const role = userDetailsSelector?.payload?.role;
      setIsLoadData(true);
      const rsp = await postRequest(navigate, listEndPoints.getMoveList, {
        search: e.target.value,
        owner_ids: role === STRINGS.ADMIN_USER ? userIdArr : {},
      });
      setGetMoveData(rsp?.data?.data?.records || []);
    } catch (e) {
      EventEmitter.emit(EMITTER_ERROR, DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsLoadData(false);
    }
  };

  const handleDebounceFolderSearch = debounceHelper(
    handleFolderSearch,
    DEBOUNCE_DELAY
  );

  const getTypeFromIds = () => {
    const type = getContactListSelector?.data?.data?.records?.filter((item) =>
      sortSelector?.data?.checkedRow?.includes(item?._id || item?.id)
    )?.[0]?.type;
    return type;
  };

  const disableMoveTo = () => {
    const records = getContactListSelector?.data?.data?.records;
    const selected_ids = sortSelector?.data?.checkedRow;
    const unique_records = [
      ...new Set(
        records
          ?.filter((item) => selected_ids?.includes(item?._id))
          ?.map((item) => item?.user_id)
      ),
    ];
    if (unique_records?.length <= 1) {
      return true;
    }
    return false;
  };

  disableMoveTo();

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          await dispatch(
            getContactListData({
              ...sortSelector?.data?.query,
              parent: id,
              // search: "",
            })
          );

          // call folder detail api here and set header Items here.
          const get_folder_details_payload = await getRequest(
            navigate,
            listEndPoints.getFolderDetail + `?${STRINGS.ID}=${id}`
          );


          console.log({ get_folder_details_payload }, headerItems, "YO")
          // condition to not add new header item on every re-render.
          const route_length = window.location.pathname
            ?.split(LIST_PATH)?.[1]
            ?.split("/");
          let new_header_items;
          headerItems?.length > 1 && headerItems.pop();
          new_header_items = [
            ...headerItems,
            {
              id: id,
              label: get_folder_details_payload?.data?.data?.list_name || "",
            },
          ];
          if (headerItems.length !== (route_length.length)) {
            setHeaderItems(new_header_items);
          }
          setIsOperationPermitted(
            get_folder_details_payload?.data?.data?.operation
          );
          setPermission(get_folder_details_payload?.data?.data?.permission);
        } else {
          setIsOperationPermitted(true);
          setHeaderItems([
            {
              id: STRINGS.TYPE_TEMP, label: STRINGS.LIST_TEXT, path: LIST_PATH
            },
          ]);

          await dispatch(
            getContactListData({ ...sortSelector?.data?.query }, navigate)
          );
        }
      } catch (error) { }
    })();
  }, [sortSelector?.data?.query, navigate]);


  useEffect(() => {
    setSearchValue("");
    setIsMount(false);
  }, [navigate])

  // debounce search api call for search
  useEffect(() => {
    if (isMount) {
      let getData;
      getData = setTimeout(() => {
        handleSearch();
      }, 300);
      return () => clearTimeout(getData);
    } else {
      setIsMount(true);
    }
  }, [searchValue]);

  return (
    <>
      <Box className={classes.listContainer}>
        <CustomHeader
          items={headerItems}
          description={
            getContactListSelector?.loading
              ? STRINGS.LOADING
              : generateBreadCrumbDescription(getContactListSelector?.data)
          }
        />
        {getContactListSelector?.data?.is_empty ?
          <EmptyScreen
            data={value}
            handleOpen={handleListCreate}
            handleFolder={handleFolder}
          />

          :

          <>
            <Box className={classes.templateSearchContainer}>
              <Box className={classes.templateSearchLeft}>
                <Box className={classes.commonWidth}>
                  <SearchField
                    autoComplete="off"
                    size={"sm36"}
                    value={searchValue}
                    placeholder={STRINGS.SEARCH_LIST}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </Box>

                {!isBulkOperationAllowed(STRINGS.SMALL_FOLDER) ? null : (
                  <div onClick={hitMoveList}>
                    <Button
                      label={STRINGS.MOVE_TO}
                      LeftIcon={ICON_LABELS.DRIVE_FILE_MOVE}
                      variant={"ghost"}
                      size={"sm36"}
                      disabled={!isBulkOperationAllowed(STRINGS.SMALL_FOLDER)}
                      onClick={
                        sortSelector?.data?.checkedRow?.length
                          ? handleClick
                          : () => { }
                      }
                    />
                  </div>
                )}

                <CustomStyledMenu
                  width="400px"
                  anchorEl={anchorEl}
                  open={open1}
                  onClose={handleClose}
                >
                  <Box className={classes.movingContainer}>
                    <Box className={classes.movingHeader}>
                      <Box className={classes.moveStyle}>
                        <TypographyInline
                          size={"md"}
                          color={GREY.PRIMARY}
                          fontWeight={fontWeight.MEDIUM}
                          label={STRINGS.MOVE_TO_FOLDER}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.moveStyle1}>
                      <SearchField
                        autoComplete="off"
                        size={"sm36"}
                        placeholder={STRINGS.SEARCH_FOLDER}
                        onChange={handleDebounceFolderSearch}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    </Box>
                    <Box className={classes.moveDataHeight}>
                      {isLoadData ? (
                        <Box className={classes.containSkeleton}>
                          <FolderSkeletonLoader />
                        </Box>
                      ) : (
                        getMoveData?.map((val, id) => {
                          return (
                            <Box
                              className={
                                isActive === val._id
                                  ? classes.activeMovingContent
                                  : classes.movingContent
                              }
                              onClick={() => backHandle(val._id)}
                            >
                              <IconComponent
                                color={WARNING.W_500}
                                fontSize={fontSize.LG}
                                iconLabel={ICON_LABELS.FOLDER}
                              />
                              <Box
                                className={
                                  isActive === val._id
                                    ? classes.movDataCont1
                                    : classes.movDataCont
                                }
                              >
                                <TypographyInline
                                  size={"sm"}
                                  color={GREY.SECONDARY}
                                  fontWeight={fontWeight.REGULAR}
                                  label={val.name}
                                />
                              </Box>
                            </Box>
                          );
                        })
                      )}
                    </Box>
                    <Box className={classes.movFooter}>
                      <Box className={classes.movCr} onClick={openCreateModal}>
                        <Button
                          label={STRINGS.CREATE_FOLDER}
                          LeftIcon={ICON_LABELS.ADD}
                          size={"sm36"}
                          variant="text"
                        />
                      </Box>

                      <Box className={classes.movBtnGen}>
                        <Button
                          label={STRINGS.COMMON_CANCEL}
                          variant={"ghost"}
                          size={"sm36"}
                          onClick={handleClose}
                        />

                        <Button
                          label={STRINGS.MOVE}
                          size={"sm36"}
                          variant={"primary"}
                          disabled={!isActive?.length}
                          onClick={() => filterMoveTemplate(isActive)}
                          isLoading={isLoad}
                        />
                      </Box>
                    </Box>
                  </Box>
                </CustomStyledMenu>

                {isBulkOperationAllowed() &&

                  <div>
                    <Button
                      label={STRINGS.DELETE}
                      LeftIcon={ICON_LABELS.DELETE}
                      variant={"ghost"}
                      size={"sm36"}
                      onClick={handleBulkDelete}
                      disabled={!isBulkOperationAllowed()}
                    />
                  </div>
                }
                {id?.length &&


                  (isBulkOperationAllowed() &&

                    <Box>
                      <Button
                        label={STRINGS.RM_FROM_FOLDER}
                        variant={"ghost"}
                        size={"sm36"}
                        onClick={removeListFolder}
                        disabled={!sortSelector?.data?.checkedRow?.length}
                      />
                    </Box>
                  )}
              </Box>
              <Box className={classes.rightDisplay}>
                {!id?.length ? (
                  <Button
                    label={STRINGS.CREATE_FOLD}
                    LeftIcon={ICON_LABELS.CREATE_NEW_FOLDER}
                    variant={"ghost"}
                    size={"sm36"}
                    onClick={handleFolder}
                  />
                ) : null}

                <Button
                  label={STRINGS.CREATE_LIST}
                  LeftIcon={ICON_LABELS.ADD}
                  variant={"primary"}
                  size={"sm36"}
                  onClick={handleListCreate}
                  disabled={!isOperationPermitted}
                />
              </Box>
            </Box>
            <Box className={classes.searchAndTableContainer}>
              <div className={classes.tableStyle}>
                <CustomTable tableData={listTableData} />
              </div>
            </Box>
          </>
        }
        {open2 && (
          <CreateCommonFolder
            open={open2}
            setOpen={setOpen2}
            endFunction={hitMoveList}
          />
        )}
        {bulkDelete && (
          <BulkDeleteModal
            open={bulkDelete}
            setOpen={setBulkDelete}
            IDs={sortSelector?.data?.checkedRow}
            type={
              getTypeFromIds() === STRINGS.SMALL_FOLDER
                ? STRINGS.BULK_DEL_TYPE
                : STRINGS.DEL
            }
            val={STRINGS.LIST_TEXT}
            itemType={getTypeFromIds()}
          />
        )}
        {showEditModal && (
          <CreateListModal
            open={showEditModal}
            setOpen={setShowEditModal}
            listNameValue={editInitialValue}
            itemId={itemId}
            parent={id}
            permission={viewType || STRINGS.PRIVATE_ID}
          />
        )}
        {showEditModal1 && (
          <CreateCommonFolder
            open={showEditModal1}
            setOpen={setShowEditModal1}
            recordId={myRecord}
            nameFold={myName}
            view={view}
            endFunction={hitMoveList}
          />
        )}
        {showDeleteModal && (
          <ListDeleteFolderModal
            open={showDeleteModal}
            setOpen={setShowDeleteModal}
            name={delName}
            id1={deletePayload}
            folderID={folderEddit}
            val={STRINGS.LIST_CREATE}
          />
        )}
        {showDeleteModal1 && (
          <ListDeleteFolderModal
            open={showDeleteModal1}
            setOpen={setShowDeleteModal1}
            recordId={myRecord}
            name={myName}
            val={STRINGS.COMMON_FOLDER}
          />
        )}

        {removeFolder && (
          <RemoveFolder
            open={removeFolder}
            setOpen={setRemoveFolder}
            IDs={sortSelector?.data?.checkedRow}
            type={STRINGS.LIST_REMT}
            val={STRINGS.LIST_TEXT}
          />
        )}

        {openList && (
          <CreateListModal
            open={openList}
            setOpen={setOpenList}
            permission={permission || STRINGS.PRIVATE_ID}
            parent={id}
          />
        )}
        {/* </Modal> */}
      </Box>
    </>
  );
};

export default ListsScreen;
