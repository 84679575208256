export const BRAND = {
  PRIMARY: "#FF4848",
  SECONDARY: "#1E2939",
};

export const BASE = {
  WHITE: "#FFFFFF",
  BLACK: "#18181C",
  BACKGROUND: "#F5F5F5",
  BACKGROUND_LIGHT: "#F8F8F8",
  TRANSPARENT: "transparent"
};

export const GREY = {
  PRIMARY: "#222124",
  SECONDARY: "#5F5F61",
  TERTIARY: "#A1A0A3",
  QUATINARY: "#5A5C60",
  DISABLED: "#CBCBCB",
};

export const BORDER = {
  LIGHT: "#EFEFEF",
  DEFAULT: "#E6E6E6",
  HOVER: "#E0E0E0",
  DARK: "#DBDBDB",
  DISABLED: "#F4F4F4",
};

export const ACTIONS = {
  DEFAULT: "#F1F1F1",
  HOVER: "#F6F6F6",
  ALTERNATE: "#E8E8E8",
  DARK: "#404040",
  DISABLED: "#EEEEEE",
};

export const PRIMARY = {
  W_900: "#1F0000",
  W_800: "#791D1D",
  W_700: "#A52B2B",
  W_600: "#D23A3A",
  W_500: "#FF4848",
  W_400: "#FF6D6D",
  W_300: "#FF9191",
  W_200: "#FFB6B6",
  W_100: "#FFC8C8",
  W_50: "#FFECEC",
};

export const SUCCESS = {
  W_900: "#102A19",
  W_800: "#1B4729",
  W_700: "#21713B",
  W_600: "#307F4A",
  W_500: "#01A63E",
  W_400: "#5EC37F",
  W_300: "#8CD4A4",
  W_200: "#BAE5C8",
  W_100: "#D1EEDB",
  W_50: "#E8F6ED",
};

export const HELP = {
  W_900: "#071D4A",
  W_800: "#0B2866",
  W_700: "#1442A7",
  W_600: "#1650CF",
  W_500: "#2080F6",
  W_400: "#608DF1",
  W_300: "#88AAF5",
  W_200: "#AFC6F8",
  W_100: "#D7E3FC",
  W_50: "#EBF1FD",
};

export const ERROR = {
  W_900: "#170404",
  W_800: "#450B0B",
  W_700: "#920E0E",
  W_600: "#A11A1A",
  W_500: "#E73F3F",
  W_400: "#E45858",
  W_300: "#EB8282",
  W_200: "#F1ABAB",
  W_100: "#F8D5D5",
  W_50: "#FCEAEA",
};

export const WARNING = {
  W_900: "#3C2604",
  W_800: "#6B470B",
  W_700: "#B6760E",
  W_600: "#E49614",
  W_500: "#F4A118",
  W_400: "#FFC564",
  W_300: "#FFD38B",
  W_200: "#FFDB9E",
  W_100: "#FFE9C5",
  W_50: "#FFF0D8",
};

export const ORANGE = {
  W_900: "#771D1D",
  W_800: "#8A2C0D",
  W_700: "#B43403",
  W_600: "#D03801",
  W_500: "#FF5A1F",
  W_400: "#FF8A4C",
  W_300: "#FDBA8C",
  W_200: "#FCD9BD",
  W_100: "#FEECDC",
  W_50: "#FFF8F1",
};
export const TEAL = {
  W_900: "#014451",
  W_800: "#05505C",
  W_700: "#036672",
  W_600: "#047481",
  W_500: "#0694A2",
  W_400: "#1EC3D0",
  W_300: "#7EDCE2",
  W_200: "#AFECEF",
  W_100: "#EDFAFA",
  W_50: "#F0FFFF",
};
export const INDIGO = {
  W_900: "#322A78",
  W_800: "#463BA8",
  W_700: "#5448CF",
  W_600: "#5D55F0",
  W_500: "#6774F4",
  W_400: "#8FA3FA",
  W_300: "#B3C5FB",
  W_200: "#CCDAFD",
  W_100: "#E4ECFE",
  W_50: "#F0F5FF",
};
export const PURPLE = {
  W_900: "#2D0969",
  W_800: "#4B1E97",
  W_700: "#6D2CDA",
  W_600: "#7F3BF3",
  W_500: "#9162FA",
  W_400: "#AD95FB",
  W_300: "#CBC0FE",
  W_200: "#DDD8FF",
  W_100: "#EEECFF",
  W_50: "#F7F6FF",
};
export const PINK = {
  W_900: "#74193C",
  W_800: "#98144A",
  W_700: "#BE115C",
  W_600: "#D51E68",
  W_500: "#E64593",
  W_400: "#F07DB7",
  W_300: "#F7B3D8",
  W_200: "#F9D0E7",
  W_100: "#FBE7F2",
  W_50: "#FCF1F7",
};
export const INTER_DIMENSIONAL_BLUE = {
  W_900: "#0A012D",
  W_800: "#15025A",
  W_700: "#1F0387",
  W_600: "#2A04B4",
  W_500: "#3405E1",
  W_400: "#5D36E7",
  W_300: "#8568ED",
  W_200: "#AE9BF3",
  W_100: "#D6CDF9",
  W_50: "#F6F3FF",
};
export const NAVY = {
  W_900: "#131B24",
  W_800: "#1A2430",
  W_700: "#273647",
  W_600: "#34485F",
  W_500: "#415A77",
  W_400: "#677B92",
  W_300: "#8D9CAD",
  W_200: "#B3BDC9",
  W_100: "#C6CED6",
  W_50: "#ECEFF1",
};

export const CREAMY_BROWN = {
  W_900: "#1D111B",
  W_800: "#392236",
  W_700: "#563451",
  W_600: "#73456C",
  W_500: "#8F5687",
  W_400: "#A5789F",
  W_300: "#BC9AB7",
  W_200: "#D2BCCF",
  W_100: "#E9DDE7",
  W_50: "#F5EFF4",
};

