import React, { useState, useEffect } from 'react';
import classes from './DateRangePicker.module.css';
import { AutoCompleteCustom } from '../../Autocomplete/AutoCompleteCustom';
import { DAYNAMES, MONTHS } from '../../../utils/constants';
import TypographyInline from '../../Typography/TypographyInline';
import { BASE, BRAND, GREY } from '../../../utils/constants/colors';
import { fontWeight } from '../../../utils/constants/UI';

function compareDates(date1, date2) {
  if (date2 === -1) return false;
  return (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear());
}

const years = [];
for (let i = 1900; i < 2100; i++) {
  years.push({
    id: String(i),
    label: String(i)
  })
}


const ClickableCalendar = (props) => {


  const { initDate, minDate, startDate, endDate, setDate, setStartDate, setEndDate, id = "" } = props;

  const [monthValue, setMonthValue] = useState([]);
  const [monthId, setMonthId] = useState([]);
  const [yearValue, setYearValue] = useState([]);
  const [yearId, setYearId] = useState([]);
  const [renderedMonth, setRenderedMonth] = useState([]);

  const referenceDate = new Date(initDate);

  const handlesDateSelection = (e) => {

    if (endDate !== -1) {
      setStartDate(new Date(`${referenceDate.getFullYear()}-${referenceDate.getMonth() + 1}-${e.target.textContent}`));
      return;
    }
    const datish = new Date(`${referenceDate.getFullYear()}-${referenceDate.getMonth() + 1}-${e.target.textContent}`)
    if (compareDates(startDate, datish)) {
      setEndDate(new Date(`${referenceDate.getFullYear()}-${referenceDate.getMonth() + 1}-${e.target.textContent}`));
      return;
    }
    if (datish < startDate) {
      setStartDate(new Date(`${referenceDate.getFullYear()}-${referenceDate.getMonth() + 1}-${e.target.textContent}`));
      return;
    }
    setEndDate(new Date(`${referenceDate.getFullYear()}-${referenceDate.getMonth() + 1}-${e.target.textContent}`));
  }

  const handlesMonthChange = (data) => {
    let year = referenceDate.getFullYear();
    let month = parseInt(data?.id[0]) + 1;
    setDate(new Date(`${year}-${month}-01`));
  }

  const handlesYearChange = (data) => {
    console.log("Data ", data);
    setDate(new Date(`${data?.id?.[0]}-01-01`));
  }

  useEffect(() => {
    setMonthId([`${referenceDate.getMonth()}`]);
    setMonthValue([MONTHS?.[referenceDate.getMonth()]?.label]);

    setYearId([initDate.getFullYear()]);
    setYearValue([initDate.getFullYear()]);

    setRenderedMonth(() => {
      const renderThis = [];
      const thisDay = new Date(`${referenceDate.getFullYear()}-${referenceDate.getMonth() + 1}-${1}`);
      let gap = thisDay.getDay();
      while (gap) {
        renderThis.push(new Date(thisDay.getTime() - (86400000) * gap));
        gap--;
      }
      let k = renderThis.length;
      const k_f = k;
      while (k <= 42) {
        renderThis.push(new Date(thisDay.getTime() + (k - k_f) * 86400000))
        k++;
      }
      const actualArray = [];
      for (let i = 0; i < 6; i++) {
        actualArray.push(renderThis.slice(7 * i, 7 * (i + 1)));
      }
      return actualArray;
    });
  }, [initDate]);


  return (
    <div className={classes.container}>
      <div className={classes.controlBar}>
        <div className={classes.containsAutoComplete}>
          <AutoCompleteCustom
            valueState={monthValue}
            setValueState={setMonthValue}
            size="sm36"
            placeholder={"Select Month"}
            items={MONTHS.slice(minDate.getMonth(), 12)}
            idState={monthId}
            setIdState={setMonthId}
            onChange={(data) => { handlesMonthChange(data) }}
            LeftIcon={""}
          />
        </div>
        <div className={classes.containsAutoComplete}>
          <AutoCompleteCustom
            valueState={yearValue}
            setValueState={setYearValue}
            size="sm36"
            placeholder={"Select Month"}
            items={years.slice(minDate.getFullYear() - 1900, -1)}
            idState={yearId}
            setIdState={setYearId}
            onChange={(data) => { handlesYearChange(data) }}
            LeftIcon={""}
          />
        </div>
      </div>

      <div className={classes.actualCalendar}>
        <div className={classes.calendarRow}>
          {DAYNAMES.map((item, index) => {
            return (<div key={`${item}${index}${id}`} className={classes.calendarCellHeader}>
              <TypographyInline label={item} color={GREY.TERTIARY} size="sm" fontWeight={fontWeight.BOLD} />
            </div>);
          })}
        </div>
        {renderedMonth.map((item, index) => {
          return (<div className={classes.calendarRow}>
            {item.map((it, ink) => {
              if (it.getMonth() === referenceDate.getMonth()) {
                let cls = null;
                let isSelected = false;
                if (compareDates(it, startDate) && compareDates(it, endDate)) { cls = classes.calendarCellLiveBoth; isSelected = true }
                else if (compareDates(it, startDate)) { cls = classes.calendarCellLiveLeft; isSelected = true }
                else if (compareDates(it, endDate)) { cls = classes.calendarCellLiveRight; isSelected = true }
                else if (it > startDate && it < endDate) cls = classes.calendarCellBetween;
                else cls = classes.calendarCell;
                return (<div className={cls} onClick={handlesDateSelection} >
                  <TypographyInline label={String(it.getDate()).padStart(2, '0')} color={isSelected ? BASE.WHITE : BRAND.SECONDARY} size="sm" fontWeight={fontWeight.REGULAR} />
                </div>);
              }
              else return <div className={classes.calendarCellDead}></div>
            })}
          </div>)
        })}
      </div>
    </div>
  )
}

export default ClickableCalendar