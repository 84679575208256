import React, { useEffect, useState } from "react";
import classes from "./Deals.module.css";
import { NOTE_CRM } from "../../../utils/constants/assets";
import ContactDetailEmptyScreen from "../Contacts/components/ContactDetailEmptyScreen";
import { STRINGS } from "../strings";
import CreateNoteCrmPopUp from "./components/CreateNoteCrmPopUp";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { BRAND } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { Button } from "../../../components/Buttons";
import ListingNoteCard from "./components/ListingNoteCard";
import SkeletonLoaderCRMScreens from "../Contacts/components/SkeletonLoaderCRMScreens";
import { decompressString } from "../../../utils/helpers";

const ContactNoteScreen = ({ dealDetails, dealId, getNoteLogList = () => { }, noteListData = [], setNoteListData = () => { }, screenLoading = false, totalNotes = 0 }) => {
  const [createFlag, setCreateFlag] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const emptyScreenButtons = [
    {
      id: STRINGS.NOTE,
      label: STRINGS.CREATE_NOTE,
      variant: "primary",
      disabled: !Object.keys(dealDetails)?.length,

      onClick: () => {
        setCreateFlag(true);
      },
    },
  ];

  const createTaskOpenHandler = () => {
    setCreateFlag(true);
  };

  const onScrollApiHandler = () => {
    const scrollableDiv = document?.getElementsByClassName("allNoteList")[0];
    const isAtBottom =
      scrollableDiv?.scrollTop >=
      scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;
    if (isAtBottom && totalNotes > noteListData?.length) {
      setPageCount(pageCount + 1);
      getNoteLogList(true, pageCount + 1);
    }
  };

  useEffect(() => {
    if (dealDetails) {
      getNoteLogList();
    }
  }, [dealDetails]);
  return (
    <div
      className={`${classes.noteContainer} allNoteList`}
      id="comments_body"
      onScroll={onScrollApiHandler}
    >
      {screenLoading ? (
        <SkeletonLoaderCRMScreens
          loaderHeight="42px"
          accordionLengths={10}
          buttonsLength={1}
        />
      ) : (
        <>
          {" "}
          {/* Checking the length of the Note list */}
          {noteListData?.length ? (
            <div className={classes.taskListContainer}>
              <div className={classes.HeadTaskContainerSubHead}>
                <TypographyInline
                  label={"All Notes"}
                  size={"md"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={BRAND.SECONDARY}
                />
                <div className={classes.WIdthButton}>
                  <Button
                    label={STRINGS.CREATE_NOTE}
                    variant={"primary"}
                    size={"sm36"}
                    onClick={createTaskOpenHandler}
                  />
                </div>
              </div>
              {/* Sending Task list to the ListingTaskCard*/}
              {noteListData?.map((logData, index) => {
                return (
                  <div key={`log${index}`}>
                    <ListingNoteCard
                      dealId={dealId}
                      id={`log${index}`}
                      // logData={logData}
                      logData={logData?.is_compressed ? { ...logData, notes_body: decodeURIComponent(decompressString(logData.notes_body)) } : logData}
                      noteListData={noteListData}
                      setNoteListData={setNoteListData}
                      expanded={!index}
                      dealDetails={dealDetails}
                      getNoteLogList={getNoteLogList}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classes.TaskHead}>
              <ContactDetailEmptyScreen
                src={NOTE_CRM}
                label={STRINGS.NOTE_DESC}
                buttonItems={emptyScreenButtons}
              />
            </div>
          )}
        </>
      )}

      {createFlag && (
        <CreateNoteCrmPopUp
          open={createFlag}
          close={setCreateFlag}
          deal_id={dealId}
          dealDetails={dealDetails}
          getNoteLogList={getNoteLogList}
        />
      )}
    </div>
  );
};

export default ContactNoteScreen;
