import { getRequest } from "../../Apis";
import { authEndpoints } from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";
import { BRANDING_GET_DETAILS_REQUEST, BRANDINGLIST_GET_DETAILS_SUCCESS, BRANDING_LIST_GET_DETAILS_FAIL, } from "../constants/brandingDetailConstants";


export const getBrandDetailsList = (sentQuery,navigate) => async (dispatch) => {
    try { 
      dispatch({type:BRANDING_GET_DETAILS_REQUEST});
      const response = await getRequest(navigate, authEndpoints.brandKitDetaiks)
            
      dispatch({
        type:BRANDINGLIST_GET_DETAILS_SUCCESS,
        payload: {...response?.data, query:sentQuery},
      });
    } catch (err) {
      if(err?.status === 401){
        navigate( LOGIN_PATH )
      }
  
      dispatch({ type:BRANDING_LIST_GET_DETAILS_FAIL , payload: err });
    }
  };
  