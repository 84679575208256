import { makeStyles } from "@mui/styles";
import { TABLE_SCROLL_BAR } from "../../../utils/constants/styles";
import {
  BASE,
  BORDER,
  BRAND,
  GREY,
  SUCCESS,
} from "../../../utils/constants/colors";
import { SHADOW } from "../../../utils/constants/UI";
import {
  SCROLL_BAR,
  fontSize,
  fontWeight,
} from "../../../utils/constants/UI/uiConstants";
import { innerLeftBorder } from "../../../utils/helpers";

export const styles = makeStyles({
  GapTop: {
    marginBottom: "8px"
  },
  dropdownMenuList: {
    width: "9.063vw",
    // marginTop: "-291px",
    // marginLeft: "116px",
    ...SCROLL_BAR,
  },
  Meetingmes: {
    paddingTop: "8px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  CancelMeet: {
    paddingTop: "12px",
    display: "flex",
    gap: "8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  organizerlist: {
    alignItems: "center",
    gap: "12px",
    padding: "8px 12px",
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
      boxShadow: innerLeftBorder(BRAND.PRIMARY),
    },
  },
  filterFooter: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    justifyContent: "flex-end",
    padding: "1rem",
    borderTop: `1px solid ${BORDER.DEFAULT}`,
  },
  skeletonPadding: {
    padding: "8px 12px",
  },
  filter: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "16px",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
  },
  filterered: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    // padding: "0px 16px 16px 16px",
    maxHeight: "200px",
    overflow: "auto",
    ...SCROLL_BAR,
    "&:hover": {
      // backgroundColor: BASE.BACKGROUND_LIGHT,
      // boxShadow: innerLeftBorder(BRAND.PRIMARY),
    },
  },
  loaderContainer: {
    alignSelf: "center",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
  },
  horizontalLine: {
    width: "60px",
    height: "1px",
    border: "none",
    backgroundColor: "#E6E6E6",
    marginRight: "10px",

  },
  horizontalLine1: {
    width: "23px",
    height: "1px",
    border: "none",
    backgroundColor: "#E6E6E6",
    marginRight: "10px",
  },
  paddGroupIcon: {
    border: "1px solid #DBDBDB",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    width: "36px",
    cursor: "pointer",

  },
  toggIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40px",
    // width:"36px"
  },
  image: {
    height: "5.885vw",
    width: "5.885vw",
  },
  gBox: {
    // width: "33.646vw",
    padding: "44px",
    // height: "19.479vw",
    // background: "#FFFFFF",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "4px",
  },
  containGmailBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    height: "calc(100vh - 64px)",
  },
  multiMailContainer: {
    background: "#FFFFFF",
    barderRadius: "8px",
    width: "26.563vw",
    marginRight: "2rem",
  },
  multiMailHeader: {
    display: "flex",
    justifyContent: "center",
    padding: "16px 0",
    borderBottom: "1px solid #DBDBDB",
  },
  multiMailContent: {
    display: "flex",
    flexDirection: "column",
    padding: "32px",
  },
  mulitMailContentInside: {
    background: "#F8F8F8",
    border: "1px solid #DBDBDB",
    borderRadius: "6px",
    padding: ".625vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: ".833vw",
  },
  multiMailLine: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    marginTop: ".833vw",
  },
  mulitMailBtn: {
    marginTop: ".833vw",
  },
  imagess: {
    maxWidth: "20px",
    margin: "auto",
  },

  GridClass: {
    margin: "auto",
    marginTop: "1rem",
  },
  images: {
    maxWidth: "20px",
    // margin: "15px auto 0",
  },
  redastrik: {
    color: "#FF4848",
  },
  cnclPrevBtnnone: {
    display: "none",
    color: "#626B7E",
    background: "#F7F9FB",
    fontWeight: "500",
    lineHeight: "17px",
    textAlign: "center",
    height: "49px",
    width: "5.573vw",
    borderRadius: "8px",
    fontSize: "clamp(12px ,0.729vw, 24px)",
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },

    border: "1px solid #D3DAE3",
    cursor: "pointer",
    "@media (max-width: 1470px)": {
      height: "30px",
      width: "5.573vw",
      borderRadius: "5px",
      // fontSize: "12px",
    },
  },
  Chunks: {
    width: "100%",
    overflow: "auto",
    // height: "30vh",
    height: "20vh",
    ...SCROLL_BAR,
  },
  dropdownLabel: {
    padding: "10px 18px 13px 10px !important",
    "&:focus": {
      backgroundColor: "red",
    },
  },
  dropdownElement: {
    maxHeight: "20vh !important",
    ...TABLE_SCROLL_BAR,
  },

  folderRightSide: {
    display: "flex",
    // marginTop: "15px",
    marginLeft: "1rem",
    marginRight: "1rem",
    justifyContent: "space-between",
    "@media (max-width:1700px)": {
      // marginTop: "10px",
    },
  },
  GRID_HEAD: {
    justifyContent: "center",
    marginTop: "0!important",
    //testing height
    // height:"70vh"
  },
  DeleteIcon: {
    color: "#626B7E",
    width: "1em",
    height: "1em",
    fontSize: "1rem !important",
    // marginLeft: "1rem",
  },
  SearchIcon: {
    fontSize: "medium",
    marginLeft: "12px",
    color: "#626B7E",
  },
  // displayFlex: {
  //   display: "flex",
  // },
  searchIcon: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "350px",
    backgroundColor: "#fff",
    border: "1px solid #D3DAE3",
    borderRadius: "5px",
    "@media (max-width:1700px)": {
      height: "35px",
      width: "320px",
    },
  },
  box: {
    alignSelf: "center",
  },
  MeetingTitle: {
    fontFamily: "Inter",
    fontSize: "clamp(1rem,1.042vw,1.3rem)",
    lineHeight: "24px",

    // marginRight: "81px",
    fontWeight: "600",
    letterSpacing: "0.5px",
    color: "#0f2f51",
    alignSelf: "center",
    // "@media (max-width: 1470px)": {
    //   width: "26vw",
    //   marginRight: "60px",
    // },
  },
  headerContainer: {
    backgroundColor: "#FFFFFF",
    padding: "0px 16px 0px 16px",
    // borderBottom: "1px solid #d3dae3",
    marginBottom: "16px",
    // height: "64px",

    // boxShadow: " 0px 2px 6px rgba(0, 0, 0, 0.07)",
    // boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.02)",
    "@media (max-width:1470px)": {
      // height: "56px",
    },
  },
  hline: {
    border: `1px solid ${BORDER.DEFAULT}`,
    height: "6.875vw",
  },
  hWidth: {
    border: "1px solid #E6E6E6",
    width: "6.042vw",
  },
  headerUpperContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "12px",
  },
  headerLowerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  templateTableContent: {
    marginTop: "10px",
    marginLeft: "20px",
    marginRight: "20px",
    "@media (max-width:1470px)": {
      marginTop: "6px",
    },
    // background:"#D3DAE3",
    // opacity:"30%",
  },
  // paperContainer: {
  //   marginTop: "22px",
  //   width: "100%",
  //   marginBottom: 2,
  //   overflowX: "auto",
  //   boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.07)",
  //   ...TABLE_SCROLL_BAR,
  // },
  headerButton: {
    backgroundColor: "#FF4848",
    color: "white",
    border: "none",
    padding: "12px 30px",
    // fontWeight:'500',
    borderRadius: "6px",
    // marginRight: "20px",
    fontSize: "clamp(12px,0.729vw,16px)",

    "&:hover": {
      background: "#0F2F51",
    },
    // height: "5vh",
    cursor: "pointer",
    // marginTop: "10px",
    // marginBottom: "6px",
  },
  default_tabStyle: {
    color: "black",
    fontSize: 11,
    backgroundColor: "blue",
  },

  active_tabStyle: {
    fontSize: 11,
    color: "white",
    backgroundColor: "#FF4848",
  },
  TimeZoneData: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#212833",
  },
  TimeZone: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#626B7E",
  },
  Box: {
    display: "flex",
    // justifyContent: "center",
    padding: "2rem 0rem 2rem 0rem",

    // "@media (max-width: 1470px)": {
    //   padding: "0rem",
    //   paddingTop: "1rem !important",
    // },
  },
  HR: {
    marginTop: "19px",
    height: "1px",
  },
  YourInfo: {
    // width: "40vw",
    // height: "366px",

    borderRight: "0.708129px solid #D3DAE3",
    // "@media (max-width: 1470px)": {
    //   height: "40vh",
    //   width: "16vw",
    // },
    // "@media (min-width: 1470px) and (max-width:1700px)":{
    //   height: "420px",
    // }
    // "@media (max-width: 1920px) (min-width:1460px)": {
    //   height: "420px",

    // }
  },
  footer: {
    fontWeight: "400",
    fontSize: "8.49755px",
    color: "#212833",
    borderRadius: "0 0 8px 8px",
    padding: "15px",
    background: "#F7F9FB",
    width: "100%",

    textAlign: "center",
    "@media (min-width: 1470px)": {
      position: "absolute",
      bottom: "0",
    },
    // "@media (max-width: 1920px) (min-width: 1460px)": {
    //   marginTop: "100px"    },
  },
  edit: {
    color: "#FF4848",
    fontSize: "12px",
    fontWeight: "400",
    paddingLeft: "2rem",
    paddingTop: "0.5rem",
    paddingRight: "2rem",
    borderRadius: "4px",
    paddingBottom: "0.5rem",
    border: "1px solid #FF4848",
    background: "white",
    "@media (max-width: 1470px)": {
      fontSize: "10px",
      paddingRight: "1.2rem",
      paddingLeft: "1.2rem",
    },
  },
  Require: {
    height: "15px",
    width: "15px",
    marginRight: "0.6rem",
    borderRadius: "5px",
    background: "#FFFFFF",
    padding: "0.2rem",
    border: "1.5px solid #D3DAE3",
    display: "flex",
    justifyContent: "center",
  },
  RequireText: {
    color: "#626B7E",
    fontSize: "clamp(10px,0.625vw,14px)",
  },
  Content: {
    display: "flex",
    justifyContent: "flex-end",
  },
  Required: {
    fontWeight: "600",
    fontSize: "12px",
    color: "black",
  },
  Confirmed: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "1rem",
    paddingLeft: "0rem",
    "@media (max-width: 1470px)": {
      paddingTop: "0.5rem",
      paddingLeft: "0rem",
    },
    // "@media (max-width: 1920px)": {
    //   paddingTop: "3rem",
    //   // paddingLeft:"0rem"
    // }
  },
  confirm: {
    color: "white",
    fontSize: "12px",
    fontWeight: "400",
    paddingLeft: "2rem",
    paddingTop: "0.5rem",
    paddingRight: "2rem",
    borderRadius: "4px",
    paddingBottom: "0.5rem",
    border: "1px solid #FF4848",
    background: "#FF4848",
    "@media (max-width: 1470px)": {
      fontSize: "10px",
      paddingRight: "1.2rem",
      paddingLeft: "1.2rem",
    },
  },
  previewInput: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "32px",
    border: "0.777512px solid #D3DAE3",
    borderRadius: "4.6px",
    padding: "0.5rem",
    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  first: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#212833",
    marginTop: "18px",
    marginBottom: "8px",
  },
  Last: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#212833",
    marginTop: "12px",
    marginBottom: "8px",
  },
  containImage: {
    padding: "32px 0",
  },
  containEachInfo: {
    display: "flex",
    paddingTop: "1.5rem",
  },
  containIcon: {
    display: "flex",
    alignItems: "center",
    paddingRight: "12px",
  },
  Your: {
    color: "white",
    fontSize: "10.8px",
    fontWeight: "600",
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 1470px)": {
      padding: "0.7rem",
    },
  },
  stepper: {
    display: "grid",
    gridTemplateColumns: "1fr 4fr",
  },
  stepperdiv: {
    width: "251px",
    display: "flex",
    flexDirection: "column",
  },
  steplabp: {
    paddingRight: "10px",
    margin: 0,
    // color: "grey",
    // fontWeight: "bold",
    textAlign: "center",
    marginTop: "6px",
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "clamp(12px,0.729vw,30px)",

    color: "#626B7E",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "100px 100px 100px 100px 100px 3fr",

    // padding: "10px"
  },
  gridItem: {
    backgroundColor: " rgba(255, 255, 255, 0.8)",
    border: "1px solid #D3DAE3 ",
    padding: "40px",
    // fontSize: "30px",
    textAlign: "center",
    "@media (max-width: 1470px)": {
      padding: "10px",
    },
  },

  griditemRed: {
    backgroundColor: "#FFE9E9",
    border: "0.385955px solid #FF4848 ",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // padding: "40px",
    // fontSize: "30px",
    textAlign: "center",
    "@media (max-width: 1470px)": {
      //  width:"71px",
      height: "53px",
      padding: "0px",
    },
  },
  steplabs: {
    paddingRight: "1.2rem",
    margin: 0,
    marginTop: "6px",

    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "clamp(0.9rem,0.7vw,2.5rem)",

    color: "#212833",
    "@media (max-width: 1470px)": {
      fontSize: "clamp(0.85rem,0.7vw,2.5rem)",
    },
  },

  scheduling: {
    // border: "1px solid #FF4848",
    // width: "214px",
    height: "40px",
    background: "#FF4848",
    border: "1px solid #FF4848",
    borderRadius: "30px",
    fontWeight: "500",
    padding: "0 3.125vw",
    justifyContent: "center",
    display: "flex",
    color: "white",
    alignItems: "center",
    fontSize: "clamp(13px,0.729vw,0.95rem)",
    position: "relative",
    zIndex: "9",

    // paddingTop: "0.7rem",
    "@media (max-width: 1700px)": {
      height: "35px",
    },
  },
  auto: {
    // border: "1px solid #FF4848",
    // width: "214px",
    height: "40px",
    background: "#FF4848",
    border: "1px solid #FF4848",
    borderRadius: "30px",
    justifyContent: "center",
    display: "flex",
    fontWeight: "500",
    color: "white",
    fontSize: "14px",
    paddingTop: "0.5rem",
    marginRight: "150px",
  },
  Overview: {
    // border: "1px solid #FF4848",
    // width: "214px",
    height: "40px",
    background: "#FFE9E9",
    border: "1px solid #FF4848",
    borderRadius: "30px",
    justifyContent: "center",
    display: "flex",
    fontWeight: "500",
    color: "#FF4848",
    fontSize: "14px",
    padding: "0 3.125vw",
    // paddingTop: "0.5rem",
    alignItems: "center",
    position: "relative",
    zIndex: "9",
    // marginLeft:"81px"
    "@media (max-width: 1700px)": {
      height: "35px",
    },
  },
  Overview1: {
    // width: "214px",
    height: "40px",
    // background: "none",
    border: "1px solid #D3DAE3",
    borderRadius: "30px",
    justifyContent: "center",
    display: "flex",
    fontWeight: "500",
    color: "#D3DAE3",
    padding: "0 3.125vw",
    fontSize: "clamp(13px,0.729vw,0.95rem)",
    position: "relative",
    zIndex: "9",
    background: "#F7F9FB",

    alignItems: "center",
    "@media (max-width: 1700px)": {
      height: "35px",
    },
    // marginLeft:"81px"
  },
  INPUT: {
    width: "96%",
    margin: "12px 16px 16px 16px",
    background: "white",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    height: "27px",
  },
  CalendarBackground: {
    // width: "96.7%",
    //  height: "47.5vh",
    //  marginLeft:"5px",
    background: "#F7F9FB",
    // padding: "2rem",
    // marginTop: "22px",
    // backgroundImage: `url(${
    //   // process.env.PUBLIC_URL + "/assets/images/Calendar.svg"
    // })`,
  },
  OverviewImage: {
    width: "14.67px",
    height: "11.17px",
    marginRight: "10px !important",
    marginTop: "4px",
  },
  toggle: {
    width: "56px",
    height: "30px",
    cursor: "pointer",
    "@media (max-width: 1470px)": {
      width: "45px",
      height: "30px",
    },
  },

  containers: {
    padding: "1.5rem",
    borderBottom: "1px solid #D3DAE3",
    "@media (max-width: 1470px)": {
      padding: "1rem",
    },
  },
  flexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  labelCOntainerLink: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  Create: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "clamp(1.2rem,1.563vw,1.9rem)",
    // lineHeight: "36px",
    justifyContent: "center",
    color: "#0F2F51",
    marginTop: "1rem",
  },
  showFolderPath: {
    marginLeft: "30px",
    marginTop: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  // textStyling: {
  //   fontFamily: "Inter",
  //   fontStyle: "normal",
  //   fontWeight: "600",
  //   fontSize: "20px",
  //   lineHeight: "24px",

  //   /* Text & Background/Brand Secondary */

  //   color: "#0F2F51",

  //   "@media (max-width: 1470px)": {
  //     fontSize: "15px",
  //   },
  // },

  pTags: {
    marginTop: "4px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(0.85rem,0.938vw,1.1rem)",
    // lineHeight: "24px",
    /* or 167% */
    color: "#626B7E",
    textAlign: "center",
    "@media (max-width: 1470px)": {
      fontSize: "15px",
    },
  },
  inputs: {
    height: "2.344vw",
    width: "100%",
    // cursor: "pointer",
    padding: "0.5rem",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    // marginTop: "0.5rem",
    background: "white",
    fontSize: "clamp(14px,0.833vw,16px)",
    fontWeight: "400",
    color: "#626b7e!important",
    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  inputss: {
    height: "2.344vw",
    width: "100%",
    // cursor: "pointer",
    padding: "0.5rem",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    // marginTop: "0.5rem",
    background: "  #F7F9FB",
    fontSize: "clamp(14px,0.833vw,16px)",
    fontWeight: "400",
    color: "#626b7e !important",
    opacity: "0.5",
    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  headerTempContentborderAuto: {
    color: "#212833",
    paddingTop: "0",

    padding: "16px 0",
    fontWeight: "600",
    fontSize: "clamp(16px,1.042vw,24px)",
    lineHeight: "24.2px",
    // margin:"0 1.042vw"
  },
  contain: {
    top: "0px",
    left: "106px",
    height: "60px",
    backgroundColor: "#fff",
    opacity: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  HEADBOX: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  button: {
    fontSize: "clamp(0.75rem,0.833vw,1rem)",
    background: "#ff4848",
    padding: "1rem 2rem",
    color: "white",
    cursor: "pointer",
    border: "1px solid #ff4848",
    borderRadius: "8px",
    // marginRight:"30px"
    "&:hover": {
      background: "#0F2F51",
      border: "1px solid #0F2F51",
    },
    "@media (max-width: 1470px)": {
      padding: "0.8rem 1rem",
      fontSize: "14px",
    },
  },
  // marginTop: {
  //   marginTop: "250px",

  //   "@media (max-width: 1470px)": {
  //     marginTop: "150px",
  //   },
  // },
  Display: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    gap: "12px",
    marginBottom: "10px",
    //  paddingLeft:"20px",
  },
  Displays: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    paddingLeft: "20px",
  },
  Display1: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    paddingTop: "10px",
  },
  DisplayCreate: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    paddingTop: "22px",
  },
  //Aijaz

  addTemplateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  addTempHead: {
    fontWeight: "600",
    marginTop: "15px",
    color: "#0F2F51",
    fontSize: "20px",
  },
  addTempPara: {
    marginTop: "15px",
    color: "#626B7E",
    fontSize: "14px",
  },
  addTempPara1: {
    marginTop: "10px",
    color: "#626B7E",
    fontSize: "14px",
  },
  addTempButton: {
    backgroundColor: "#FF4848",
    marginTop: "20px",
    color: "white",
    border: "none",
    borderRadius: "5px",
    fontSize: "12px",
    width: "170px",
    height: "50px",
    cursor: "pointer",
  },
  modalContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "43.5vw",
    height: "85vh",
    borderRadius: "4px",
    "@media (max-width: 1470px)": {
      width: "58vw",
    },
    ...TABLE_SCROLL_BAR,
  },
  createHeader: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F7F9FB",
  },
  headerData: {
    marginLeft: "25px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  headerData1: {
    fontWeight: "600",
    fontSize: "20px",
    color: "#0F2F51",
  },
  headerData2: {
    color: "#626B7E",
    fontSize: "12px",
    marginTop: "3px",
  },
  headerBtn: {
    marginRight: "25px",
    marginTop: "19px",
    marginBottom: "8px",
    height: "35px",
    width: "35px",
    background: "#D3DAE3",
    borderRadius: "6px",
    opacity: "90%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  clearIconData: {
    // background:"#626B7E",
    // color:"blue",
    height: "18px",
    width: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // opacity:"220%"
  },

  labelContent: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "14px",
    marginTop: "30px",
  },
  displayFlexSub: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "25px",
  },
  HeadSub: {
    display: "flex",
    gap: "8px",
  },
  labelContent1: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "14px",
    marginTop: "20px",
  },
  labelContent23: {
    color: "#212833",
    fontSize: "14px",
    lineHeight: "16.94px",
    fontWeight: "500",
    marginRight: "30px",
    "@media (max-width: 1470px)": {
      fontSize: "12px",
      lineHeight: "14.94px",
      marginRight: "16px",
    },
    //  marginTop:"20px",
    display: "flex",
  },
  labelContent2: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "12px",
    marginTop: "20px",
    display: "flex",
  },

  sharedOption: {
    color: "#626B7E",
    fontSize: "11px",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  sharedOption1: {
    color: "#626B7E",
    fontSize: "11px",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  inputFieldContent: {
    border: "1px solid #D3DAE3",
    marginTop: "6px",
    marginRight: "25px",
    borderRadius: "4px",
  },
  inputValue: {
    border: "none",
    width: "534px",
    height: "45px",
    fontSize: "14px",
    marginLeft: "6px",
  },
  editorContent: {
    marginTop: "6px",
    marginRight: "25px",
    border: "1px solid #D3DAE3",
    borderRadius: "4px",
    minHeight: "18rem",
  },
  displayFlex: {
    display: "flex",
    marginTop: "16px",
    marginBottom: "16px",
    justifyContent: "space-between",
    // marginRight: "25px",
    gap: "8px",
    width: "100%",
  },
  GAP: {
    display: "flex",
    gap: "8px",

    width: "21.615vw",
  },
  tabeHead: {
    height: "100vh",
  },
  innerTable: {
    height: "calc(100% - 70px)",
  },
  innerTable1: {
    height: "calc(100% - 80px)",
  },
  tableUi: {
    borderRadius: "6px",
    // borderTopLeftRadius: "6px",
    // borderTopRightRadius: "6px",
    gap: "16px",
    margin: "16px 16px",
    background: BASE.WHITE,
    boxShadow: SHADOW.xs,
    height: "calc(100% - 100px)",
    ...SCROLL_BAR,
  },
  tableUi1: {
    borderRadius: "6px",
    border: "1px solid #E6E6E6",
    // borderTopLeftRadius: "6px",
    // borderTopRightRadius: "6px",
    gap: "16px",
    margin: "16px 16px",
    background: BASE.WHITE,
    boxShadow: SHADOW.xs,
    height: "calc(100% - 33px)",
    ...SCROLL_BAR,
  },
  dropdownContent: {
    width: "300px",
    marginTop: "6px",
    height: "45px",
    border: "1px solid #D3DAE3",
    fontWeight: "400",
    fontSize: "16px",
    paddingLeft: "8px",
    cursor: "no-drop",
  },
  dropdownContentData: {
    border: "1px solid #D3DAE3",
  },
  dropdownContent1: {
    border: "1px solid #D3DAE3",
    width: "300px",
    marginTop: "6px",
    height: "45px",
    marginLeft: "55px",
    paddingLeft: "8px",
  },
  footerTemplate: {
    marginTop: "50px",
    borderTop: "1px solid #D3DAE3",
    paddingBottom: "15px",
  },
  footerData: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    marginLeft: "25px",
    marginRight: "25px",
  },
  footerBtn: {
    backgroundColor: "#FF4848",
    color: "white",
    border: "none",
    borderRadius: "7px",
    fontSize: "12px",
    width: "170px",
    height: "45px",
    cursor: "pointer",
  },
  footerCancelBtn: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #626B7E",
    color: "#626B7E",
    borderRadius: "7px",
    fontSize: "12px",
    width: "100px",
    height: "45px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  footerRight: {
    display: "flex",
    alignItems: "center",
  },
  footerRightData: {
    color: "#626B7E",
    fontSize: "14px",
  },

  headerContent: {
    color: "#0F2F51",
    // marginLeft: "20px",
    fontWeight: "600",
    // marginTop: "2vh",
    fontSize: "clamp(16px, 1.042vw, 30px)",
    "@media (max-width:1470px)": {
      // marginTop: "1.7vh",
    },
  },
  // headerButton: {
  //   backgroundColor: "#FF4848",
  //   color: "white",
  //   border: "none",
  //   borderRadius: "6px",
  //   marginRight: "20px",
  //   fontSize: "14px",
  //   width: "10vw",
  //   // height: "5vh",
  //   cursor: "pointer",
  //   marginTop: "10px",
  //   marginBottom: "6px",
  //   "@media (max-width: 1470px)": {
  //     fontSize: "12px",
  //     marginTop: "6px",
  //     borderRadius: "4px",
  //   },
  // },
  showFolderContainer: {
    background: "#fff",
    width: "231px",
    height: "193px",
    marginLeft: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.07)",
    "@media (max-width: 1470px)": {
      width: "164px",
      height: "127px",
    },
  },
  imageHeight: {
    height: "42px",
    width: "42px",
    marginLeft: "22px",
    marginTop: "25px",
    cursor: "pointer",
    "@media (max-width: 1470px)": {
      width: "30px",
      height: "30px",
      marginLeft: "12px",
      marginTop: "15px",
    },
  },
  footerDatta: {
    marginLeft: "15px",
    marginTop: "20px",
    "@media (max-width:1470px)": {
      marginTop: "9px",
      marginLeft: "5px",
    },
  },
  showFolderContainer1: {
    background: "#d3dae3",
    width: "231px",
    height: "193px",
    marginLeft: "12px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.07)",
    "@media (max-width: 1470px)": {
      width: "164px",
      height: "127px",
    },
  },
  imageView: {
    height: "42px",
    width: "42px",
    "@media (max-width: 1470px)": {
      width: "27.69px",
      height: "27.69px",
    },
  },
  folderOptions: {
    display: "flex",
    justifyContent: "space-between",
  },
  folderIcon: {
    color: "#FFC87F",
    marginLeft: "12px",
    marginTop: "18px",
    "@media (max-width: 1470px)": {
      marginLeft: "7px",
      marginTop: "10px",
    },
  },
  folderIcon1: {
    color: "#626B7E",
    // marginLeft: "12px",
    // marginTop: "18px",
  },
  vertIcon: {
    color: "#626B7E",
    marginTop: "17px",
    marginRight: "10px",
    cursor: "pointer",
    "@media (max-width: 1470px)": {
      marginLeft: "7px",
      marginTop: "8px",
    },
  },
  folderName: {
    display: "flex",
    flexDirection: "column",
  },
  nameStyle: {
    marginTop: "18px",
    marginLeft: "22px",
    fontWeight: "600",
    color: "#0F2F51",
    fontSize: "16px",
    lineHeight: "19px",
    cursor: "pointer",
    wordWrap: "break-word",
    "@media (max-width: 1470px)": {
      marginLeft: "12px",
      marginTop: "7px",
      fontSize: "12px",
    },
  },
  nameStyle1: {
    marginTop: "5px",
    marginLeft: "12px",
    color: "#626B7E",
    fontSize: "12px",
    cursor: "pointer",
    wordWrap: "break-word",
  },
  endPart: {
    marginTop: "25px",
    borderTop: "1px solid #D3DAE3",
    display: "flex",
    alignItems: "center",
    "@media (max-width:1470px)": {
      marginTop: "10px",
    },
  },
  imageSet: {
    height: "25px",
    width: "25px",
    border: "1px solid #D3DAE3",
    borderRadius: "50px",
    marginLeft: "12px",
    marginTop: "6px",
  },
  spanStyle: {
    marginLeft: "2px",
    color: "#626B7E",
    marginTop: "20px",
    lineHeight: "17px",
    fontSize: "14px",
    whiteSpace: "nowrap",
    "@media (max-width: 1470px)": {
      marginLeft: "0px",
      marginTop: "9px",
      fontSize: "12px",
    },
  },

  viewAll: {
    // marginTop:"23px",
    // marginLeft:"50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  viewAllText: {
    // marginLeft:"8px",
    height: "19px",
    color: "#212833",
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "8px",
    "@media (max-width:1470px)": {
      fontSize: "12px",
      height: "15px",
    },
  },
  dialog: {
    position: "fixed",
    top: 0,
    right: 0,
    marginLeft: "50vw",
  },
  common: {
    display: "flex",
    flexDirection: "column",
  },
  commonFont: {
    fontSize: "10px",
  },
  commonArrow: {
    marginLeft: "6px",
    fontSize: "15px",
  },
  commonHead: {
    display: "flex",
    alignItems: "center",
  },
  stepStyle: {
    display: "flex",
    alignItems: "center",
    background: "#F7F9FB",
    height: "35px",
    justifyContent: "center",
    width: "80px",
    borderRadius: "6px",
  },
  container: {
    width: "calc(100vw - 8.5rem)",
    padding: "1rem ",
    paddingTop: "0",
  },
  container1: {
    backgroundColor: "#f9fafb",
    padding: 0,
    height: "100vh",
  },
  paperContainer: {
    width: "100%",
    marginBottom: 2,
    overflowX: "auto",
    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.07)",
    ...TABLE_SCROLL_BAR,
  },
  tableContainer: {
    height: "calc(100vh - 30.5rem) !important",
    width: "100%",
    position: "relative",
    ...TABLE_SCROLL_BAR,
    "@media (max-width: 1470px)": {
      height: "calc(100vh - 22.9rem) !important",
    },
  },
  rowHeight: {
    height: "56px",
    "@media (max-width:1470px)": {
      height: "50px",
    },
  },
  rowHeight1: {
    height: "56px",
    "@media (max-width:1470px)": {
      height: "52px",
    },
  },
  padCell: {
    padding: "0px 8px !important",
  },
  tableContiner12: {
    height: "calc(100vh - 18rem) !important",
    width: "100%",
    "@media (max-width:1470px)": {
      height: "calc(100vh - 14rem) !important",
    },
    position: "relative",
    ...TABLE_SCROLL_BAR,
  },
  tableHeadings: {
    color: "#0F2F51",
    fontWeight: "600",
    fontSize: "14px",
    background: "white",
    alignSelf: "center",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    padding: "0px 1px !important",
    // border:"2px solid red"
  },
  folderContainer: {
    // marginTop:"100px",
    background: "#F7F9FB",

    // height:"285px"
  },
  // templateContent: {
  //   marginLeft: "25px",
  // },
  templateContent: {
    // marginLeft: "25px",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    justifyContent: "center",
    height: "40px",
    width: "120px",
    border: "none",
    background: "none",
    cursor: "pointer",
    "@media (max-width:1700px)": {
      height: "32px",
      width: "100px",
    },
  },
  templateAction: {
    fontSize: "14px",
    color: "#626B7E",
    marginLeft: "5px",
  },
  // imageSet: {
  //   marginLeft: "0.8rem",
  // },
  templateMove: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "120px",
    marginLeft: "8px",
    backgroundColor: "white",
    border: "1px solid #D3DAE3",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#d3dae3",
    },
    "@media (max-width:1700px)": {
      height: "35px",
      width: "100px",
      marginLeft: "5px",
    },
  },
  tempFolder: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "20px",
  },
  // folderRightSide: {
  //   display: "flex",
  //   marginTop: "15px",
  // },
  // searchIcon: {
  //   display: "flex",
  //   alignItems: "center",
  //   height: "35px",
  //   width: "300px",
  //   backgroundColor: "#fff",
  //   border: "1px solid #D3DAE3",
  //   borderRadius: "5px",
  // },
  tempFolderButton: {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    marginLeft: "10px",
    border: "1px solid #D3DAE3",
    background: "#fff",
    height: "35px",
    borderRadius: "5px",
    color: "#626B7E",
    cursor: "pointer",
  },
  showFolder: {
    marginTop: "10px",
    display: "flex",
    overflowX: "auto",
    paddingBottom: "15px",
    "@media (max-width:1470px)": {
      paddingBottom: "12px",
    },
  },
  // showFolderPath: {
  //   // marginLeft:"20px",
  //   marginTop: "25px",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "flex-start",
  //   background: "#E7ECF2",
  // },
  textStyling: {
    marginLeft: "20px",
    color: "#0F2F51",
    opacity: "50%",
    fontSize: "20px",
    cursor: "pointer",
  },
  folderStyling: {
    color: "#0F2F51",
    fontSize: "20px",
    marginLeft: "10px",
  },
  commonSpanTable: {
    fontSize: "14px",
    color: "#626B7E",
    cursor: "pointer",
    fontWeight: "400",
  },
  commonSpanTable1: {
    fontSize: "14px",
    color: "#0F2F51",
    cursor: "pointer",
    fontWeight: "600",
    // "@media (max-width:1470px)":{
    //   fontSize: "13px"
    // }
  },
  loadingOnlyTable: {
    height: "calc(100vh - 27rem)",
  },
  loadingOnlyTable1: {
    height: "calc(100vh - 19rem)",
  },
  headerCreateTemplate: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    // height: "64px",
    background: BASE.WHITE,
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    // borderBottom:"1px solid #D3DAE3",
    "@media (max-width: 1470px)": {
      // height: "50px",
    },
  },

  headerCreateTemplate1: {
    height: "61px",
    background: "#ffffff",
    borderBottom: "1px solid #D3DAE3",
    borderTop: "1px solid #D3DAE3",
    "@media (max-width: 1470px)": {
      height: "47px",
    },
  },
  headerTempContent: {
    color: "#0F2F51",
    marginLeft: "18px",
    paddingTop: "20px",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24.2px",
    "@media (max-width: 1470px)": {
      fontSize: "16px",
      lineHeight: "20.2px",
      paddingTop: "14px",
      marginLeft: "10px",
    },
  },
  headerTempContent1: {
    // color:"#0F2F51",
    marginLeft: "18px",
    paddingTop: "19px",
    "@media (max-width: 1470px)": {
      paddingTop: "13px",
      marginLeft: "10px",
    },
    // fontWeight:"600",
  },
  headerTempContent2: {
    color: "#212833",

    padding: "0px 16px 16px 16px",

    fontWeight: "600",
    fontSize: "clamp(16px,1.042vw,24px)",
    lineHeight: "24.2px",
    "@media (max-width: 1470px)": {
      fontSize: "16px",
      lineHeight: "20.2px",
      paddingTop: "14px",
      marginLeft: "13px",
    },
  },
  redActive: {
    borderRadius: "12px",
    width: "12px",
    height: "12px",
    background: BRAND.PRIMARY,
  },
  greenActive: {
    borderRadius: "12px",
    width: "12px",
    height: "12px",
    background: SUCCESS.W_500,
  },
  nonActive: {
    borderRadius: "12px",
    width: "12px",
    height: "12px",
    background: GREY.DISABLED,
  },
  headerTempContentborder: {
    color: "#212833",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    // borderBottom: "1px solid #D3DAE3",

    padding: "16px",
    fontWeight: "600",
    fontSize: "clamp(16px,1.042vw,24px)",
    lineHeight: "24.2px",
    // margin: "0 1.042vw",
  },
  headerSpan: {
    color: "#0F2F51",
    opacity: "50%",
    fontSize: "16px",
    lineHeight: "19.36px",
    fontWeight: "400",
    "@media (max-width: 1470px)": {
      fontSize: "14px",
      lineHeight: "16.2px",
    },
  },
  headerSpan1: {
    color: "#0F2F51",
    fontSize: "16px",
    lineHeight: "19.36px",
    fontWeight: "400",
    "@media (max-width: 1470px)": {
      fontSize: "14px",
      lineHeight: "16.2px",
    },
  },
  gridcontainer: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto auto",
    // backgroundColor: "#2196F3"
  },
  GridContainer: {
    // borderRadius:"8px",
    // background: "#F7F9FB",
    position: "relative",
    color: "black",
    // marginTop: "26px",
    // paddingLeft:"26px",
    // paddingRight:"37px",
    // paddingLeft: "29px",
    // paddingRight: "29px",
    // paddingBottom: "36px",
    "@media (min-width: 1470px) and (max-width: 1700px)": {
      paddingBottom: "17px",
    },
    // paddingTop: "36px",
    // paddingTop: "15px",
    borderRadius: "0 0 8px 8px",
  },
  Padding: {
    // padding: "1.198vw 1.667vw",
    padding: "28px 36px 36px 36px",

    // width: "60vw",

    // "@media (max-width: 1470px)": {
    //   padding: "0.8rem",
    //   width: "20vw",
    // },
  },
  griditem: {
    backgroundColor: BASE.BACKGROUND_LIGHT,
    border: "0.39px solid #E6E6E6",
    // padding: "25px",
    fontSize: "30px",
    textAlign: "center",
    // width:"100px",
    // height: "4.740vw",
    height: "4vw",
    "@media (min-width: 1470px) and (max-width: 1700px)": {
      // height: "81px",
    },
    "@media (max-width: 1470px)": {
      //  width:"71px",
      height: "53px",
      padding: "0px",
    },
  },
  griditem2: {
    backgroundColor: BASE.BACKGROUND_LIGHT,
    border: "0.880259px solid #D3DAE3",
    // padding: "25px",
    fontSize: "30px",
    textAlign: "center",
    // width:"100px",
    // height: "4.740vw",
    height: "2vw",
    "@media (min-width: 1470px) and (max-width: 1700px)": {
      // height: "81px",
    },
    "@media (max-width: 1470px)": {
      //  width:"71px",
      height: "62px",
      padding: "0px",
    },
  },
  templateCreationData: {
    display: "flex",
    // marginTop: "30px",
    justifyContent: "space-between",
    // marginLeft: "132px",
    "@media (max-width: 1470px)": {
      // display: "block",
      // marginLeft: "305px",
      // height:"70vh",
      // overflow:"auto",
      // ...TABLE_SCROLL_BAR,
    },
  },
  // Step: {
  //   "@media (max-width: 1470px)": {
  //     fontSize: "clamp(1rem,0.938vw,1.2rem)",
  //     width: "max-content",
  //   },
  // },
  grid: {
    margin: "auto",
    marginTop: "1rem",
  },
  Important: {
    paddingTop: "0px !important",
    marginTop: "0px !important",
  },
  Content: {
    justifyContent: "center",
  },
  BORDERTIGHT: {
    borderRight: "1px solid #D3DAE3",
  },
  textImp: {
    border: `1px solid ${BORDER.LIGHT}`
    // fontSize: "12px",
    // fontWeight: "600",
    // color: "#FF4848",
    // marginBottom: "1rem",
    // padding: "3px",
    // paddingBottom: "0px",
  },
  PreviewText: {
    border: "none",
    resize: "none",
    fontSize: "14px",
    marginLeft: "10px !important",
    fontWeight: "400",
    color: "#5F5F61",
    overflowWrap: "break-word",
  },
  templateCreationPage: {
    // height: "calc(100vh - 13.021vw)",
    ...TABLE_SCROLL_BAR,
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
    "@media (min-width: 1470px) and (max-width: 1700px)": {
      height: "calc(100vh - 177px)",
      overflow: "auto",
      ...SCROLL_BAR,
    },
    "@media (min-width: 1360px) and (max-width: 1470px)": {},

    "@media  (max-width: 1470px)": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",

      // width: "70%",

      // ...TABLE_SCROLL_BAR,
      "&::-webkit-scrollbar": {
        width: "2px",
        height: "2px",
      },
    },
    background: "#ffffff",
    borderRadius: "6px",
  },
  templateHt: {
    height: "calc(100vh - 177px)"
  },
  test: {
    // test
    "@media (min-width: 1470px) and (max-width: 1700px)": {
      // height: "calc(100vh - 17.5vw)",
      overflow: "auto",
      ...SCROLL_BAR,
    },
    overflow: "auto",
    height: "calc(100% - 57px )",

    // height: "calc(100vh - 17.2vw)",
    ...SCROLL_BAR,
  },
  templateCreationPage1: {
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
    // paddingBottom: "20px",
    // ...TABLE_SCROLL_BAR,
    height: "calc(100vh - 177px)",
    // height: "calc(100vh - 13.021vw)",
    // ...SCROLL_BAR,
    // "@media (max-width: 1470px)": {
    //   height: "calc(100vh - 13.2vw)",
    // },
    // "@media (min-width: 1470px) and (max-width: 1700px)": {
    //   // height: "590px",
    //   height: "calc(100vh - 13.021vw)",
    //   overflow: "auto",
    //   ...TABLE_SCROLL_BAR,
    // },
    // "@media (min-width: 1360px) and (max-width: 1470px)": {},

    // "@media  (max-width: 1470px)": {
    //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",

    //   // width: "70%",

    //   // ...TABLE_SCROLL_BAR,
    //   "&::-webkit-scrollbar": {
    //     width: "2px",
    //     height: "2px",
    //   },
    // },

    background: "#ffffff",
    borderRadius: "6px",
  },
  Powered: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
    paddingBottom: "16px",
  },

  // templateCreationPage1: {
  //   background: "red",
  //   borderRadius: "12px",
  //   ...TABLE_SCROLL_BAR,
  //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
  //   paddingBottom: "20px",
  //   "@media (min-width:400px) and (max-width: 768px)": {
  //     background: "purple",
  //   },
  //   "@media (min-width:768px) and (max-width: 1280px)": {
  //     background: "green",
  //     height: "calc(100vh - 27vh)",
  //   },
  //   "@media (min-width:1280px) and (max-width: 1720px)": {
  //     height: "calc(100vh - 27vh)",
  //     background: "yellow",
  //     ...TABLE_SCROLL_BAR,
  //   },
  //   "@media (min-width: 1720px) and (min-width:2400px)": {
  //     background: "black",
  //   },
  // },
  noBorder: {
    color: "#FF4848",

    backgroundColor: "#FF4848",
    height: "200px",
  },
  MuiPickersDay: {
    color: "#FF4848",
    backgroundColor: "#FF4848",
  },
  MarginLeft: {
    margin: "0px 0px 0px 0px !important",
  },
  textEvent: {
    background: "white",
    border: "none",
    resize: "none",
    color: "#222124",
    fontWeight: "600",
    fontSize: "14px",
    marginLeft: "10px",
  },
  previewTemplate: {
    height: "calc(100vh - 177px)",
    ...SCROLL_BAR,
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",

    // width: "43%",
    // marginRight: "211px",
    //  height: "100%",
    // background: "#ffffff",
    borderRadius: "6px",
    color: "#D3DAE3",
    background: GREY.DISABLED,
    padding: "30px",
    // padding: "2.802vw 2.656vw",
    "@media (max-width: 1600px)": {
      // padding: "1.85vw 1.5vw",
      // padding: "1.2vw 1.5vw",
    },
  },
  previewSchedule: {
    // width: "720px",
    // marginRight: "211px",
    // height: "743px",
    // background: "#ffffff",
    borderRadius: "12px",

    color: "#D3DAE3",
    padding: "2rem",
    background: "#D3DAE3",
    // paddingTop: "5rem",
    // paddingLeft: "3rem",

    "@media (max-width: 1470px)": {
      // width: "70%",
      // height: "430px",
      marginRight: "0px",
      padding: "1rem",
      // paddingLeft: "1rem",
      // paddingTop: "2rem",
      // paddingBottom:"2rem",

      // paddingRight: "1rem",
      // marginTop:"22px"
    },
  },
  textSchedule: {
    fontWeight: "300",
    fontSize: "clamp(12px,0.729vw,14px)",
    color: "#626B7E",
    marginTop: "12px",
    // "@media (max-width: 1470px)": {
    //   marginTop: "8px",
    // },
  },
  timeAcc: {
    fontWeight: fontWeight.MEDIUM,
    padding: "8px 10px !important",
    fontSize: fontSize.SM,
    color: GREY.SECONDARY,
    display: "flex",
    justifyContent: "center",
    marginBottom: "5px !important",
    // width: "200px",
    borderRadius: "6px",
    border: `1px solid ${BORDER.DEFAULT}`,
    "@media (max-width: 1470px)": {
      padding: "0.3rem",
      margin: "4px !important",
      fontSize: "10px",
    },
    "&:hover": {
      color: (props) => props.background || "#FF4848",

      // color: "#008296",
      cursor: "pointer",
      borderColor: (props) => props.background || "#FF4848",
    },
  },
  need: {
    fontWeight: "600",
    fontSize: "clamp(10px,0.625vw,14px)",
    color: " #212833",
    paddingBottom: "7px",
  },
  UTC: {
    fontWeight: "400",
    fontSize: "clamp(9px,0.521vw,12px)",
    color: " #212833",
    background: "#EAEEF3",
    marginBottom: "10px",
    borderRadius: "4px",
    lineHeight: "27px",
    textAlign: "center",
  },
  works: {
    fontWeight: "600",
    fontSize: "clamp(10px,0.625vw,14px)",
    color: " #212833",
    paddingTop: "1rem",
    paddingBottom: "10px",
  },
  childrenContainer: {
    // width: "calc(100% - 5.6rem)",
    background: BORDER.DEFAULT,
    height: "100vh",
  },
  mins: {
    fontWeight: fontWeight.MEDIUM,
    padding: "8px 10px",
    display: "inline-flex",
    marginRight: "5px",
    justifyContent: "center",
    margin: "10px 0px",
    fontSize: fontSize.SM,
    color: GREY.SECONDARY,
    borderRadius: "6px",
    border: `1px  solid ${BORDER.DEFAULT}`,
    "@media (max-width: 1470px)": {
      padding: "0.3rem",
      margin: "4px",
    },
    "&:hover": {
      color: "#ff4848",
      cursor: "pointer",
      borderColor: (props) => props.background || "#FFFF",
    },
  },
  arrowicon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  textName: {
    fontWeight: "700",
    fontSize: "clamp(1rem,1.042vw,20px)",
    color: "black",
  },
  FORMM: {
    borderRadius: "0 0 8px 8px",
    background: BASE.BACKGROUND_LIGHT,
    color: "black",
    // maxHeight: "26.563vw",
    // minHeight: "26.563vw",
    marginTop: "10px",
    ...SCROLL_BAR,

    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.06)",
  },
  heads: {
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    margin: "16px 24px 0px 24px",
    background: "#008296",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // padding: "16px 16px 0px 16px",
  },
  TIMEBEST: {
    padding: " 12px 0px",
  },
  ContainHeads: {
    display: "flex",
    paddingTop: "1rem",
  },
  HeadSteps: {
    margin: "16px",
    height: "100vh",

  },
  lastcss: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingTop: "8px",
  },
  ContainHeadName: { display: "flex", flexDirection: "column", gap: "4px" },
  DIVCONT: {
    padding: "1rem",
    background: (props) => props.background || "#FFFF",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
  },
  disCn: {
    height: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  FORM: {
    margin: "0px 24px",

    // width: "100%",
    // height: "366px",
    background: "white",
    // marginTop: "30px",
    display: "flex",
    // "@media (max-width: 1470px)": {
    //   marginTop: "8px",
    //   height: "40vh",
    // },
    // "@media (max-width: 1920px) (min-width:1460px)": {

    //   height: "420px",
    // }
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px"
  },
  IMAGE: {
    width: "74px",
    height: "74px",
    marginTop: "22px",
    "@media (max-width: 1470px)": {
      width: "50px",
      height: "50px",
      marginTop: "8px",
    },
  },
  inputSchedule: {
    width: "95%",
    marginTop: "22px",
    marginLeft: "14px",
    background: "white",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    height: "27px",
    "@media (max-width: 1470px)": {
      marginTop: "8px",
    },
  },
  inputSchedulee: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    cursor: "default",
    // width: "95%",
    margin: "12px 16px 16px 16px",
    padding: "4px",
    // justifyContent: "center",
    background: "white",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    height: "27px",
    fontSize: "clamp(12px,0.729vw,14px)",
    color: "#626B7E",
    fontWeight: "",
    paddingLeft: "0.5rem",
    "@media (max-width: 1470px)": {
      fontSize: "clamp(12px,0.729vw,14px)",
      color: "#626B7E",
      fontWeight: "400",
    },
    "@media (max-width: 1600px)": {
    },
  },
  previewScheduled: {
    // width: "91%",
    // height: "617px",
    // height: "586px",
    background: "white",
    borderRadius: "10px",
    // maxHeight: "30.521vw",
    // minHeight: "30.521vw",
    paddingTop: "15px",
    position: "relative",
    // ...TABLE_SCROLL_BAR
    // "@media (max-width: 1470px)": {
    //   height: "430px",
    // },
    // "@media (max-width: 1920px) (min-width:1460px)": {
    //   height: "690px",
    // }
  },
  previewTemplatess: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
    // marginLeft: "380px",
    marginTop: "40px",
    // height: "38vh",
    // width: "1018px",
    // marginRight: "211px",
    // height: "367px",
    background: "#ffffff",
    borderRadius: "15px",
    "@media (max-width: 1470px)": {
      // width: "54%",
      // height: "55vh",
      // marginLeft: "296px",
    },
  },
  CalendarFixing: {
    background: "#F7F9FB",
    borderRadius: "0 0 10px 10px",
  },
  PaddingYour: {
    padding: "28px",
  },
  internalName: {
    display: "flex",
    padding: "2rem",

    paddingLeft: "0rem",
    paddingBottom: "0rem",
    "@media (max-width: 1470px)": {
      paddingTop: "1rem",
    },
  },
  // MuiButtonBase-root-MuiPickersDay-root.Mui-selected
  materialuiDrop: {
    width: "43.698vw",
    height: "20vh",
    ...SCROLL_BAR,
    marginTop: "5px",
  },
  StaticDatePickers: {
    " &   .MuiPickersCalendarHeader-root": {
      backgroundColor: "#FF4848 !important",
      padding: "0.938vw!important",
      // opacity: "0.1",
      marginTop: "0px",
      paddingTop: "5px",
      paddingBottom: "5px",
      maxHeight: "initial!important",
    },
    " &   .MuiPickersCalendarHeader-label": {
      color: "white !important",
    },
  },
  // StaticDatePicker: {
  //   " &  .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
  //     background: (props) => props.background || "#FF4848",
  //   },
  //   " &   .MuiPickersCalendarHeader-root": {

  //     paddingTop: "1px",
  //     paddingBottom: "1px",
  //     maxHeight: "initial!important",
  //     marginTop: "0px",
  //   },
  //   " & .MuiPickersCalendarHeader-label": {
  //     fontSize: "clamp(0.6rem,0.75vw,1rem) !important",
  //     color: "#222124 !important",
  //   },
  //   " & .MuiPickersArrowSwitcher-button": {
  //     color: "#222124 !important",
  //   },

  //   " & .MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button":
  //     {
  //       color: "#0F2F51  !important",
  //     },
  // },
  StaticDatePicker: {
    "& .css-sf5t6v, .PrivatePickersSlideTransition-root,.MuiDayPicker-slideTransition":
    {
      // comment for testing
      // minHeight: "280px !important",
    },
    background: "transparent",
    "& .css-epd502, .MuiPickerStaticWrapper-content,.MuiCalendarPicker-root": {
      width: "100%",
      height: "100%",
      margin: "0",
    },
    "& .MuiDayPicker-header,.MuiDayPicker-weekContainer": {
      justifyContent: "space-evenly",
      margin: "0px",
    },
    "& .MuiDayPicker-weekDayLabel": {
      fontFamily: "'Inter', sans-serif",
      color: "#212833",
      fontSize: "clamp(13px,0.787vw, 17px)",
      fontWeight: "400",
    },
    "& .MuiPickersDay-dayWithMargin": {
      fontFamily: "'Inter', sans-serif",
      color: "#222124",
      fontSize: "clamp(13px,0.787vw, 17px)",
      margin: "5px 2px;",
      fontWeight: "400",
    },
    "& .Mui-selected[aria-selected='true']": {
      background: (props) => props.background || "#FF4848",
      // border: "0px",
      color: "#fff",
      border: "0!important",
      boxShadow: SHADOW.md,
      flex: "none",
      "&:hover": {
        background: (props) => props.background,
      },
    },
    "& .MuiButtonBase-root:not(.Mui-selected)": {
      border: "0!important",
    },
    "& .MuiPickersCalendarHeader-root": {
      background: BASE.WHITE,
      borderTop: "1px solid rgba(15, 47, 81, 0.15)",
      marginTop: "0px",
      color: GREY.PRIMARY,
      // padding: "10px 10px 10px 1.302vw",
      // testing
      padding: "20px ",

      maxHeight: "initial",
    },
    "& .MuiSvgIcon-fontSizeMedium,.MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,.MuiIconButton-root-MuiPickersArrowSwitcher-button":
    {
      color: GREY.PRIMARY,
    },
    "& .MuiIconButton-root-MuiPickersArrowSwitcher-button.Mui-disabled": {
      color: "rgba(255,255,255,0.5)",
    },
    "& .MuiPickersCalendarHeader-label": {
      fontWeight: "500",
      fontSize: "clamp(13px,0.833vw,0.833vw)",
    },
    "& .MuiYearPicker-root": {
      ...SCROLL_BAR,
    },
    "& .MuiPickersCalendarHeader-switchViewIcon": {
      display: "none",
    },
    "& .MuiPickersDay-root": {
      "&:focus.Mui-selected": {
        background: (props) => props.background,
      },
    },
  },
  DatePicker: {
    // borderRight: "0.708129px solid #D3DAE3",
    "& .MuiTypography-root-MuiDayPicker-weekDayLabel": {
      color: "#212833 !important",
      fontFamily: "'Inter', sans-serif!important",
    },
    "& .MuiPickerStaticWrapper-content": {
      borderRadius: "0 0 0 10px",
      minWidth: "auto",
    },
    "& .MuiButtonBase-root-MuiPickersDay-root": {
      color: "#212833",
      fontFamily: "'Inter', sans-serif!important",
    },
    "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
      color: "#fff !important",
    },
    "& .PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition": {
      minHeight: "200px !important",
      overflow: "hidden !important"
    },
    "& .MuiPickersArrowSwitcher-spacer": {
      width: "auto",
    },
    "& .Mui-selected": {
      background: (props) => `${props.background} !important` || "#FF4848",
      // border: "0px",
      color: "#fff!important",
      border: "0!important",
      flex: "none",
    },
    "& .MuiButtonBase-root:not(.Mui-selected)": {
      border: "0!important",
    },
    "& .MuiPickersDay-dayWithMargin": {
      fontFamily: "'Inter', sans-serif!important",
      color: "#222124",
      fontSize: "14px !important",
    },
    "& .css-epd502,.MuiCalendarPicker-root": {
      width: "auto",
      margin: "0",
    },
    " & .MuiPickersCalendarHeader-switchViewButton": {
      display: "none",
    },
    "& .MuiStaticDatePicker-calendarContainer": {
      maxHeight: "300rem !important", // Set your desired height for the calendar
    },

    // "@media (max-width: 1470px)": {
    //   height: "40vh",
    //   overflowY: "auto",
    //   ...TABLE_SCROLL_BAR,
    //   "&::-webkit-scrollbar": {
    //     width: "0.5px",
    //     height: "0.5px",
    //   },
    // },
  },

  Auto: {
    // width: "978px",
    // height: "166px",
    // background: "#F7F9FB",
    padding: "1.042vw",
    // marginLeft: "22px",
    marginTop: "1.042vw",
    // marginRight: "22px",
  },
  previewContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  PickersDay: {
    marginBottom: "100px",
  },
  fromBox: {
    // width: "80px",
    // height: "40px",
    // padding: "1rem",
    fontSize: "clamp(14px,0.833vw,16px)",
    // fontSize: "clamp(0.8rem, 0.7vw, 3rem)",
    fontWeight: "400",
    color: "#626B7E",
    // "@media (max-width: 1470px)": {
    //   padding: "0.3rem",
    // },
    padding: "8px",
  },
  DeleteHeadPara: {
    background: "#D3DAE3",
    borderRadius: "36px",
    width: "1.823vw",
    height: "1.823vw",
    display: "flex",
    justifyContent: "center",

    alignItems: "center",
  },
  DeleteHead: {
    cursor: "pointer",
    // width: "30px",
    // height: "30px",
    borderRadius: "36px",
    color: "#626B7E",
    // paddingLeft: "3rem",
    // "@media (max-width: 1470px)": {
    //   paddingLeft: "0.6rem",
    // },

    // paddingTop: "9px",
  },

  footerCreateTemplate: {
    width: "calc(100% - 7rem)",
    bottom: "0",
    display: "flex",
    padding: "15px 0",
    position: "absolute",
    background: "#FFFFFF",
    borderRadius: "6px",
    marginBottom: "16px",
    justifyContent: "space-between",
  },

  MarginRight: {
    margin: "0px 0px 0px 0px !important",
  },
  footerCreateTemplatess: {
    borderTop: "1px solid #D3DAE3",

    marginTop: "360px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerCreateTemplates: {
    paddingTop: "0px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "@media (max-width: 1470px)": {
      paddingTop: "0px",
    },
  },
  Background: {
    background: "white",
  },
  ICONCOLOR: {
    color: "#626B7E",
  },
  commonMarginMain: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "20px",
    marginLeft: "16px",
    marginRight: "16px",

    "@media (max-width: 1470px)": {
      // marginTop: "10px",
      marginLeft: "18px",
    },
  },
  WIDTH: {
    width: "50%",
  },
  // commonMargin: {
  //   marginTop: "14px",
  //   marginLeft: "20px",
  //   marginRight: "20px",

  //   "@media (max-width: 1470px)": {
  //     // marginTop: "10px",
  //     marginLeft: "18px",
  //   },
  // },
  checkboxes: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#626B7E",
    marginTop: "8px",
  },
  Privacy: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px",
    background: "#F7F9FB",
    borderRadius: "6px",
    marginTop: "22px",
    marginBottom: "22px",
  },
  Cpatca: {
    fontWeight: "400",
    fontSize: "clamp(12px,0.729vw,14px)",
    color: "#626B7E",
    marginTop: "8px",
  },
  CPATCHAs: {
    fontWeight: "600",
    color: "#212833",
    fontSize: "clamp(14px,0.938vw,18px)",
  },
  CPATCHA: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    background: "#FFFFFF",
    borderRadius: "6px",
    border: `1px solid ${BORDER.LIGHT}`
  },
  Top: {
    marginTop: "22px",
    marginBottom: "22px",
  },
  materialUICSS: {
    display: "flex",
    paddingBottom: "0.833vw",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "12px",
  },
  commonMarginDisplay: {
    alignItems: "center",
    marginTop: "16px", marginLeft: "16px",
    marginRight: "16px",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1470px)": {
      // marginTop: "10px",
      // marginLeft: "18px",
    },
  },
  commonMarginDisplayAdd: {
    alignItems: "center",
    margin: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
  CancelDisplay: {
    alignItems: "center",

    display: "flex",
    justifyContent: "space-between",
    border: `1px solid ${BORDER.LIGHT}`,
    padding: "1rem 1rem 1rem 1rem",
    borderRadius: "6px",
  },
  CancelDisplays: {
    border: `1px solid ${BORDER.LIGHT}`,
    padding: "1rem 1rem 1rem 1rem",
    borderRadius: "6px",
  },
  textAuto: {
    background: "white",
    border: "none",
    resize: "none",
    fontSize: "14px",
    marginLeft: "10px",
    fontWeight: "600",
    color: "#5F5F61",
  },
  textAutoLocation: {
    background: "white",
    border: "none",
    resize: "none",
    fontSize: "14px",
    marginLeft: "10px",
    fontWeight: "400",
    color: "#5F5F61",
  },
  commonMarginDisplaytabs: {
    borderBottom: "1px solid #E6E6E6",
    marginTop: "16px",
    marginLeft: "16px",
    marginRight: "16px",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1470px)": {
      // marginTop: "10px",
      marginLeft: "18px",
    },
  },
  paddingTOP: {
    paddingTop: "10px",
  },
  SEND_TEST: {
    marginTop: "16px",
    width: "max-content",
  },
  commonMargin: {
    marginTop: "16px",
    marginLeft: "16px",
    marginRight: "16px",

    "@media (max-width: 1470px)": {
      // marginTop: "10px",
      marginLeft: "18px",
    },
  },
  commonMargin2: {
    marginTop: "16px",
    marginLeft: "16px",
    marginRight: "16px",
    // width: "95%",
    "@media (max-width: 1470px)": {
      // marginTop: "10px",
      marginLeft: "18px",
    },
  },

  commonLabells: {
    "& .MuiButtonBase-root-MuiTab-root": {
      textTransform: "none !important",
      color: "#FF4848 !important",
      borderBottom: "2px solid #FF4848 !important",
    },
  },

  commonLabel: {
    color: "#212833",
    fontSize: "14px",
    lineHeight: "16.94px",
    fontWeight: "500",
    "@media (max-width: 1470px)": {
      fontSize: "12px",
      lineHeight: "14.94px",
    },
  },
  commonLabelCur: {
    color: "#212833",
    fontSize: "14px",
    lineHeight: "16.94px",
    fontWeight: "500",
    cursor: "pointer",
    "@media (max-width: 1470px)": {
      fontSize: "12px",
      lineHeight: "14.94px",
    },
  },
  commonLabel2: {
    color: "#212833",
    fontSize: "14px",
    lineHeight: "16.94px",
    fontWeight: "500",
    marginBottom: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginRight: "0.5rem",
    "@media (max-width: 1470px)": {
      fontSize: "12px",
      lineHeight: "14.94px",
    },
  },

  commonLabelcommonLabel: {
    color: "#626B7E",
    fontSize: "14px",
    fontWeight: "400",
    "@media (max-width: 1470px)": {
      fontSize: "12px",
    },
  },
  commonLabell: {
    marginTop: "1rem",
    color: "#212833",
    display: "flex",
    fontSize: "14px",
    lineHeight: "16.94px",
    borderBottom: "1px solid #EFEFEF",
    paddingBottom: "16px",
    fontWeight: "500",
    "@media (max-width: 1470px)": {
      fontSize: "12px",
      lineHeight: "14.94px",
    },
  },
  CaptchTop: {
    marginTop: "1rem",
  },
  PaddingAll: {
    padding: "20px",
  },
  Bottom: {
    paddingBottom: "12px",
  },
  White: {
    background: "white",
  },
  previewd: {
    position: "relative",
    background: "white",
    padding: "16px",
    borderRadius: "10px",
    overflow: "auto",
    height: "100%",
    ...SCROLL_BAR
  },
  automated: {
    // width: "978px",
    // height: "83px",
    background: "#F7F9FB",
    // marginLeft: "22px",
    marginTop: "1.042vw",
    padding: "1.042vw",
    // marginRight: "22px",
    // "@media (max-width: 1470px)": {},
  },
  materialui: {
    width: "100%",
    border: "1px solid #D3DAE3",
    borderRadius: "4px",
    color: "#626B7E",
    padding: "0 0.8rem",
    "@media (max-width: 1470px)": {
      // width: "188px",
      border: "1px solid #D3DAE3",
    },
    "& .MuiSelect-select": {
      paddingLeft: "0.5rem",
    },
  },
  commonTextBoxes: {
    marginTop: "4px",
    marginLeft: "16px",
    marginRight: "16px",
    // marginBottom: "70px",

    "@media (max-width: 1470px)": {
      marginTop: "8px",
      marginLeft: "18px",
    },

    "& .MuiAutocomplete-tag": {
      backgroundColor: "#D3DAE3",
      borderRadius: "4px",
      color: "#626B7E",
      fontSize: "clamp(14px,0.833vw,16px)",
    },
    "& .MuiOutlinedInput-input": {
      "@media (max-width: 1470px)": {
        padding: "0.2px 4px 3px 6px !important",
      },
    },
  },
  commonTextBoxesAdd: {
    marginTop: "4px",
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "16px"
  },
  Gap: {
    paddingBottom: "24px",
  },
  commonTextBoxesD: {
    marginTop: "4px",
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "24px",
    border: "1px solid #E0E0E0",
    borderRadius: "6px",
    "@media (max-width: 1470px)": {
      marginTop: "8px",
      marginLeft: "18px",
    },
  },
  commonTextBoxes3: {
    marginTop: "12px",
    marginLeft: "30px",
    marginRight: "22px",
    marginBottom: "20px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    // border:"1px solid #D3DAE3",
    "@media (max-width: 1470px)": {
      marginTop: "8px",
      marginLeft: "13px",
      marginRight: "6px",
    },
  },
  commonTextBoxes1: {
    marginTop: "4px",
    // "@media (max-width: 1470px)": {
    //   marginTop: "8px",
    // },
  },
  CONFIRM: {
    fontWeight: "400",
    fontSize: "clamp(12px,0.729vw,16px)",
    color: "#626B7E",
    marginTop: "8px",
  },
  TESTEMAIL: {
    fontWeight: "400",
    fontSize: "clamp(12px,0.729vw,16px)",
    color: "#626B7E",
    marginTop: "8px",
  },
  Test: {
    "& .MuiOutlinedInput-input": {
      color: "#626b7e !important",
    },
    fontWeight: "400 !important",
    fontFamily: "Inter !important",
    fontSize: "clamp(12px, 0.729vw, 20px) !important",
    color: "#626B7E !important",
  },
  CANCEL: {
    width: "160px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    background: "#D3DAE3",
    color: "#626B7E",
    cursor: "pointer",
    border: "none",
    marginBottom: "10px",
    marginTop: "18px",
    padding: "10px 20px",
    fontSize: "clamp(12px,0.729vw,16px)",
  },
  HR1: {
    // marginTop: "12px",
    borderRadius: "6px",
    border: "none",
    backgroundColor: "#FF4848",
    // height: "18px",
    width: "80%",
    height: "15%",
    objectFit: "cover",
  },
  HR2: {
    marginTop: "10px",
    borderRadius: "3px",
    backgroundColor: "#626B7E",
    // height: "5px",
    opacity: "0.5",
    width: "70%",
    height: "10%",
    objectFit: "cover",
  },
  HR3: {
    marginTop: "4px",
    borderRadius: "3px",
    backgroundColor: "#626B7E",
    // height: "5px",
    opacity: "0.5",
    width: "70%",
    height: "10%",
    objectFit: "cover",
  },
  HR4: {
    borderRadius: "3px",
    marginTop: "4px",
    backgroundColor: "#626B7E",
    // height: "5px",
    opacity: "0.5",
    width: "70%",
    height: "10%",
    objectFit: "cover",
  },
  EMAIL: {
    fontWeight: "400",
    fontSize: "clamp(12px,0.729vw,16px)",
    color: "#626B7E",
    marginTop: "8px",
  },
  DateTIME: {
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
    color: "black",
    borderBottom: "0.708129px solid #D3DAE3",
    padding: "7px",
  },
  pointer: {
    cursor: "pointer",
    // margin: "10px 10px",
    width: "calc(100% - 10px)",
  },
  textBoxx: {
    width: "680px",
    height: "185px",
    border: "1px solid #D3DAE3",
    color: "#626b7e!important",
    fontSize: "clamp(14px,0.833vw,16px)",
    "@media (max-width: 1470px)": {
      // width:"588px"
    },
  },
  Buton: {
    width: "207px",
    height: "41px",
    background: "#FFE9E9",
    border: "none",
    color: "#FF4848",
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "8px",
  },
  privacyC: {
    fontWeight: "600",
    fontSize: "clamp(14px,0.938vw,18px)",
  },
  pageLink: {
    fontSize: "clamp(14px,0.833vw,18px)",
    color: "#626B7E",
    fontWeight: "400",
  },
  SchedulePage: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  justify: {
    padding: "16px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #EFEFEF"
  },

  justifys: {
    marginTop: "1rem",
  },
  justifyStart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    marginTop: "16px"
  },
  justifyed: {
    padding: "0px 0px 16px 0px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #EFEFEF",
  },
  justifyPadding: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 16px 0px 16px",
  },
  ACtiveStep: {
    // marginTop: "11px",
    color: "#0F2F51",
    fontSize: "clamp(16px,1.042vw,22px)",
    fontWeight: "600",
    // marginLeft: "200px",
    // "@media (max-width: 1470px)": {
    //   marginLeft: "105px",
    // },
  },
  previewSubTit: {
    margin: "16px 16px 0px 16px",
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: "22px",
    background: "white",

  },
  widthH: {
    width: "100%",
    // height: "370px",
    // height:"35vh",
    background: "white",
    // marginTop: "20px",
    display: "flex",
    boxShadow: " 0px 16.9951px 24.0764px rgba(0, 0, 0, 0.04)",
    borderRadius: "0 0 10px 10px",
    "@media (max-width: 1470px)": {
      marginTop: "10px",
      height: "262px",
    },
  },
  previewSub: {
    // width: "92%",

    // height: "100%",
    background: "white",
    borderRadius: "8px",
    overflow: "auto",
    ...SCROLL_BAR,
    // padding: "2rem",
    paddingTop: "1rem",
    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  SideBar: {
    padding: "0.811vw 0.990vw",
    paddingTop: "0.9rem",
    flex: "auto",
    "@media (max-width: 1470px)": {
      padding: "1rem",
    },
  },

  BoxWidthset: {
    width: "85px",
    "@media (max-width: 1470px)": {
      width: "75px",
    },
  },

  textBox: {
    borderRadius: "4px",
    padding: "0.5rem",
    background: "white",
    width: "100%",
    height: "2.344vw",
    border: "1px solid #D3DAE3",
    color: "#626b7e!important",
    fontSize: "clamp(14px,0.833vw,16px)",
    "&:disabled": {
      background: "#F7F9FB",
    },
    "&::placeholder": {
      color: "rgba(98, 107, 126,0.5)",
    },
  },
  commonLabel1: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "0.6rem",
    // paddingTop:"1rem",
    color: "#212833",
    fontSize: "14px",
    lineHeight: "16.94px",
    fontWeight: "500",
    "@media (max-width: 1470px)": {
      fontSize: "12px",
      lineHeight: "14.94px",
    },
  },
  textBox1: {
    width: "100%",
    border: "1px solid #D3DAE3",

    "&  .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      background: "transparent !important",
    },
    "& .MuiSelect-select": {
      paddingLeft: "10px",
    },

    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  rightFolderContent: {
    width: "50%",
    marginLeft: "16px",
    "@media (max-width: 1470px)": {
      marginLeft: "16px",
    },
  },
  paperMenu: {
    marginTop: "5px !important",
    width: "21.406vw",
    height: "20vh",
    ...SCROLL_BAR,
  },
  paperMenuu: {
    marginTop: "5px !important",
    width: "21.406vw",
    height: "10vh",
    ...SCROLL_BAR,
  },
  prevHead: {
    height: "45px",
    background: "#D3DAE3",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    "@media (max-width: 1470px)": {
      height: "40px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  prevCont: {
    marginLeft: "320px",
    color: "#626B7E",
    fontWeight: "500",
    height: "19px",
    fontSize: "16px",
    lineHeight: "19.36px",
    "@media (max-width: 1470px)": {
      marginLeft: "210px",
      lineHeight: "16.36px",
      height: "16px",
      fontSize: "14px",
    },
  },
  prevCancel: {
    marginLeft: "320px",
    color: "#626B7E",
    fontWeight: "500",
    height: "19px",
    fontSize: "16px",
    lineHeight: "19.36px",
    cursor: "pointer",
    "@media (max-width: 1470px)": {
      marginLeft: "210px",
      lineHeight: "16.36px",
      height: "16px",
      fontSize: "14px",
    },
  },
  prevLeftMag: {
    marginLeft: "18px",
    color: "#626B7E",
    width: "9px",
    marginTop: "5px",

    "@media (max-width: 1470px)": {
      marginLeft: "10px",
      marginTop: "5px",
    },
  },
  commonPreviews: {
    marginLeft: "18px",
    marginTop: "10px",
    paddingTop: "5px",
    color: "#626B7E",
    fontWeight: "500",
    lineHeight: "19.36px",
    "@media (max-width: 1470px)": {
      marginLeft: "10px",
      marginTop: "8px",
      paddingTop: "4px",
      lineHeight: "16.36px",
    },
  },
  bodyData: {
    marginLeft: "18px",
    marginTop: "10px",
    paddingTop: "5px",
    // color:"#626B7E",
    // fontWeight:"500",
    // lineHeight:"19.36px",
    overflow: "auto",
    ...TABLE_SCROLL_BAR,
    "@media (max-width: 1470px)": {
      marginLeft: "10px",
      marginTop: "8px",
      paddingTop: "4px",
      // lineHeight:"16.36px",
    },
  },
  commonDataPrev: {
    height: "40px",
    borderBottom: "1px solid #D3DAE3",
    "@media (max-width: 1470px)": {
      height: "35px",
    },
  },
  btnPrev: {
    paddingRight: "16px",
    // marginRight: "30px",
    // "@media (max-width: 1470px)": {
    //   marginTop: "13px",
    //   marginRight: "8px",
    // },
  },
  btnPrev2: {
    marginRight: "16px",
    marginLeft: "16px",
    // "@media (max-width: 1470px)": {
    //   marginTop: "13px",
    //   marginRight: "8px",
    // },
  },
  btnPrevv: {
    marginTop: "1px",
    marginRight: "10px",
    // marginLeft:"45px",
    "@media (max-width: 1470px)": {
      marginTop: "2px",
      marginRight: "8px",
    },
  },
  btnPrev1: {
    marginTop: "20px",
    // marginRight:"30px",
    "@media (max-width: 1470px)": {
      marginTop: "10px",
      // marginRight:"8px"
    },
  },
  // commonBtnStyl: {
  //   background: "#FF4848",
  //   height: "49px",
  //   width: "167px",
  //   borderRadius: "8px",
  //   fontWeight:"500",
  //   color: "#ffffff",
  //   border: "none",
  //   cursor: "pointer",
  //   "&:hover": {
  //     background: "#0F2F51",
  //   },
  //   "@media (max-width: 1470px)": {
  //     height: "40px",
  //     width: "150px",
  //     borderRadius: "6px",
  //     fontSize: "12px",
  //   },
  // },
  commonBtnStyl: {
    // fontWeight: "500",
    background: "#FF4848",
    height: "49px",
    width: "100px",
    borderRadius: "8px",
    fontWeight: "500",
    color: "#ffffff",
    border: "none",
    fontSize: "clamp(12px ,0.729vw, 24px)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      background: "#0F2F51",
    },
    "@media (max-width: 1470px)": {
      height: "30px",
      width: "75px",
      borderRadius: "5px",
      fontSize: "12px",
    },
  },
  commonBtnStyl1: {
    background: "#FF4848",
    height: "49px",
    fontWeight: "500",
    width: "5.573vw",
    borderRadius: "8px",
    color: "#ffffff",
    border: "none",
    cursor: "pointer",
    fontSize: "clamp(12px ,0.729vw, 24px)",
    "&:hover": {
      background: "#0F2F51",
    },

    "@media (max-width: 1470px)": {
      height: "30px",
      // width: "100px",
      borderRadius: "5px",
      // fontSize: "12px",
    },
  },
  commonBtnStyl1load: {
    background: "#FF4848",
    height: "49px",
    fontWeight: "500",
    width: "5.573vw",
    borderRadius: "8px",
    color: "#ffffff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#0F2F51",
    },

    "@media (max-width: 1470px)": {
      height: "30px",
      // width: "100px",
      borderRadius: "5px",
      fontSize: "12px",
    },
  },
  addMorebtn: {
    border: "0",
    background: "#ff4848",
    color: "#fff",
    borderRadius: "8px",
    padding: "10px",
    cursor: "pointer",
  },

  BoxBack: {
    position: "absolute",
    top: "50%",
    left: "51%",
    boxShadow: " 0px 6px 10px rgba(0, 0, 0, 0.14)",
    background: "white",
    borderRadius: "11px",
    border: "0.421976px solid #D3DAE3",
    transform: "translate(-100%, -50%)",
    maxWidth: "251px",
    padding: "16px",
    minHeight: "200px",
    maxHeight: "286px",

    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "1px",
      height: "1px",
    },
    "&::-webkit-scrollbar-track ": {
      innerHeight: "1px",
      outerHeight: "1px",
    },
    // "&::-webkit-scrollbar-thumb": {
    //   background: "#626B7E",
    //   borderRadius: "10px",
    // },
  },
  cnclPrevBtn: {
    color: "#626B7E",
    background: "#F7F9FB",
    fontWeight: "500",
    lineHeight: "17px",
    textAlign: "center",
    height: "49px",
    width: "5.573vw",
    borderRadius: "8px",
    fontSize: "clamp(12px ,0.729vw, 24px)",
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },

    border: "1px solid #D3DAE3",
    cursor: "pointer",
    "@media (max-width: 1470px)": {
      height: "30px",
      width: "5.573vw",
      borderRadius: "5px",
      // fontSize: "12px",
    },
  },
  /*******UI */

  HeaderTop: {
    padding: "1.302vw 2.604vw",
    // padding: "1.302vw 2vw",
    borderBottom: "1px solid #D3DAE3",
  },
  HeaderWrap: {
    alignItems: "center",
    position: "relative",
    "&::after": {
      content: '""',
      display: "block",
      width: "45%",
      height: "1px",
      background: "#D3DAE3",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-45%, -50%)",
    },
  },

  scheduleForm: {
    height: "calc(100vh - 14.25vw)",
    ...SCROLL_BAR,
    // "@media (min-width: 1900px)": {
    //   height: "calc(100vh - 19.25vw)",
    // },
    // "@media (min-width: 1470px) and (max-width: 1700px)": {
    //   // height: "calc(100vh - 12.25vw)",
    //   ...TABLE_SCROLL_BAR,
    // },
    //  "&::-webkit-scrollbar": {
    //   width: "2px",
    //   height: "2px",
    // },
  },
  CalendarApi: {
    padding: "32px 0px 24px 0px",
    display: "flex",
    justifyConent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  CalendarApiFooter: {
    display: "flex",
    justifyConent: "center",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  previewAutomation: {
    background: "#fff",
    // boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.07)",
    borderRadius: "6px",
    // padding: "1.042vw",
    height: "100%",
  },
  dropDownIcon: {
    position: "absolute",
    right: "9px",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#626B7E",
    height: "16px !important",
    width: "16px !important",
  },
  dropDownIcons: {
    position: "absolute",
    right: "35px",
    pointerEvents: "none",
    color: "#626B7E",
  },
  tabPrimary: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "clamp(12px,0.833vw, 22px )",
    color: "#626b7e",
    lineHeight: "19px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    padding: "12px 24px",
    gap: "10px",
    alignItems: "center",
    // border: "1px solid #d3dae3",
    borderBottom: "none",
    borderRadius: "16px 16px 0px 0px",
    background: "#FFFFFF",
    cursor: "pointer",
  },

  radiodisplay: {
    display: "flex",
  },
  radioButtonBox: {
    marginTop: "4px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  dateselect: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4px",
    marginLeft: "20px",
    marginRight: "20px",
    alignItems: "center",

    "@media (max-width: 1470px)": {
      // marginTop: "10px",
      marginLeft: "18px",
    },
  },
  arrowbox: {
    margin: "auto 8px",
  },
  tabSelected: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "clamp(12px,0.833vw, 22px )",
    color: "#FFFFFF",
    lineHeight: "19px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    padding: "12px 24px",
    gap: "10px",
    alignItems: "center",
    borderBottom: "none",
    borderRadius: "16px 16px 0px 0px",
    borderBottom: "2px solid #FF4848",
    cursor: "pointer",
  },
  editImage: {
    //display: "contents",
    width: "80px",
    height: "80px",
    objectFit: "contain",
    borderRadius: "50%",
    //alignSelf: "baseline",
  },
  previewImage: {
    //display: "contents",
    width: "32px",
    height: "32px",
    objectFit: "contain",
    borderRadius: "50%",
    backgroundColor: "#F1F1F1",
    //alignSelf: "baseline",
  },
  previewImage2: {
    //display: "contents",
    width: "70px",
    height: "70px",
    objectFit: "contain",
    borderRadius: "50%",
    backgroundColor: "#F1F1F1",
    //alignSelf: "baseline",
  },
  editIcon: {
    display: "contents",
    objectFit: "contain",
  },
  editImageAvatar: {
    position: "relative",
    height: "80px",
    width: "80px",
    borderRadius: "50%",
    backgroundColor: "#F1F1F1",
  },
  editImageAvatar1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // position: "relative",
    height: "32px",
    width: "32px",
    borderRadius: "50%",
    backgroundColor: "#F1F1F1",
  },
  editImageButton: {
    position: "absolute",
    bottom: "0px",
    marginLeft: "4px",
  },
  imageavatar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  commonMarginDisplayAvatar: {
    marginTop: "16px",
    marginLeft: "16px",
    marginRight: "16px",
    display: "flex",
    justifyContent: "flex-start",
    "@media (max-width: 1470px)": {
      // marginTop: "10px",
      marginLeft: "18px",
    },
    gap: "40px",
  },
  hrLine: {
    width: "864px",
    height: "1px",
    border: "none",
    color: "#EFEFEF",
    align: "center",
  },
  avatarImage: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "105px",
    marginBottom: "5px",
  },
  HeadContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "50px",
  },
  HeadContainer2: {
    height: "100vh",
    background: BASE.BACKGROUND,
  },
  SubHeadConatiner: {
    boxShadow: SHADOW.md,
    padding: "32px",
    width: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: BASE.WHITE,
    borderRadius: "6px",
  },
  SubHeadConatinerErr: {
    padding: "32px",
    height: "150px",
    width: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: BASE.WHITE,
    borderRadius: "6px",
  },
  footerContain: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    width: "100%",
    paddingTop: "8px",
  },
  textarea: {
    paddingTop: "16px",
    width: "100%",
  },
  availDropdown: {
    width: "16.458vw",
  },
  toFromDropdown: {
    width: "10.417vw",
  },
  Center: {
    display: "flex",
    justifyContent: "column",
    justifyContent: "center",
    alignItem: "center",
  },
  validPadding: {
    marginTop: "16px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  POINTER: {
    cursor: "no-drop"
  },
  PaddingToggle: {
    paddingTop: "12px"
  },
  heightCalendar: {
    height: "300rem !important"
  },
  dFlx: {
    display: "flex",
    gap: "5px"
  },
  dColmn: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2px"
  },
  mLft: {
    marginLeft: "10px"
  },
  groupStepMain: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  groupStepContainer: {
    borderRadius: "6px",
    backgroundColor: "#FFFFFF",
    width: "60.15625vw",
  },
  groupStepHeader: {
    padding: "16px",
    display: "flex",
    borderBottom: "1px solid #E6E6E6",
  },
  groupDFlex: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  groupAddMember: {
    padding: "24px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  groupTableHead: {
    width: "100%",
    background: "linear-gradient(0deg, #F6F6F6, #F6F6F6),linear-gradient(0deg, #E6E6E6, #E6E6E6)",
    borderTop: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6"
  },
  groupTableBody: {
    width: "100%",
    maxHeight: "calc(100vh - 376px)"
  },
  groupTableHeadInside: {
    display: "flex",
  },
  groupTableHeadInside1: {
    display: "flex",
    borderBottom: "1px solid #E6E6E6"
  },
  groupWidth: {
    width: "25%",
    padding: "12px",
    borderRight: "1px solid #E6E6E6"
  },
  groupWidth1: {
    width: "25%",
    padding: "12px",
    display: "flex",
    justifyContent: "flex-end",
  },
  groupBodyWidth: {
    width: "25%",
    padding: "12px",
    display: "flex",
  },
  groupWidth2: {
    width: "25%",
    padding: "12px",
    borderRight: "1px solid #E6E6E6",
    display: "flex",
    justifyContent: "flex-end",
  },
  footerStyle: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    justifyContent: "flex-end",
  },
  eachContent: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    borderBottom: "1px solid #EFEFEF",
  },
  leftContentSty: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  dFlxContent: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  dFlxRightContent: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  redActiveMember: {
    borderRadius: "8px",
    width: "8px",
    height: "8px",
    background: BRAND.PRIMARY,
  },
  greenActiveMember: {
    borderRadius: "8px",
    width: "8px",
    height: "8px",
    background: SUCCESS.W_500,
  },
  widthHeader: {
    width: "10px"
  },
  centerDMember: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 120px)"
  },
  teamList: {
    width: "100%",
    background: "#F4F4F4",
    borderRadius: "6px",
    padding: "4px",
    display: "flex",
    gap: "4px",
    alignItems: "center",
    flexWrap: "wrap"
  },
  individualList: {
    background: "#E8E8E8",
    borderRadius: "6px",
    opacity: "50%",
    padding: "4px 6px",
  },
  padTooltip: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  padTooltipContain: {
    maxHeight: "12vh",
    overflow: "auto",
  }

});
