import React from "react";
import { STRINGS } from "../strings";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import Typography from "../../../components/Typography/Typography";
import { Button } from "../../../components/Buttons";
import Image from "../../../components/Image/Image";
import { PROPERTIES_EMPTY_SCREEN } from "../../../utils/constants/assets";
import classes from "./PropComponent.module.css"

const EmptyGroupScreen = ({ createGroupModalHandler }) => {
  return (
    <div className={classes.emptyPropertyBody}>
      <Image src={PROPERTIES_EMPTY_SCREEN} />
      <div className={classes.emptyPropertyLabels}>
        <Typography
          color={GREY.PRIMARY}
          fontWeight={fontWeight.SEMIBOLD}
          label={STRINGS.EMPTY_GROUP_SCREEN_HEADING}
          size={"xl"}
        />
        <div className={classes.emptyPropertyDesc}>
          <Typography
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.EMPTY_GROUP_SCREEN_DESC}
            size={"sm"}
          />
        </div>
      </div>
      <div>
        <Button
          label={STRINGS.CREATE_GROUP}
          size={"md40"}
          variant={"primary"}
          onClick={createGroupModalHandler}
        />
      </div>
    </div>
  );
};

export default EmptyGroupScreen;
