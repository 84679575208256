import { makeStyles } from "@mui/styles";
import { commonStyles } from "../../theme/globalStyles";
import {
  LEFT_IMAGE,
  LOGIN_BACKGROUND,
  RIGHT_IMAGE,
} from "../../utils/constants/assets";
import {
  SCROLL_BAR,
  fontSize,
  fontWeight,
} from "../../utils/constants/UI/uiConstants";
import { BORDER, BRAND, GREY, PRIMARY } from "../../utils/constants/colors";

export const styles = makeStyles({
  TypoHead: {
    marginLeft: "0.2rem",
  },
  container: {
    display: "flex",

    // new design css
    flexDirection: "column",
    height: "100vh",
    alignItems: "center",
    justifyContent: "space-around",
    ...SCROLL_BAR,
    background: `url(${LEFT_IMAGE}) right top no-repeat ,url(${RIGHT_IMAGE}) left bottom no-repeat`,
    // "@media (min-width: 768px)": {
    //   backgroundSize: "300px",
    //   // backgroundColor: "red",
    // },
  },
  bottomContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem",
    flexDirection: "column",

    height: "50vh",
  },
  MainContainer: {
    display: "flex",
    padding: "12px 0px 0px 0px",
    width: "100%",
    gap: "16px",
    paddingBottom: "12px",
  },
  displayHead:{
    display:"flex",
    flexDirection:"column",
    gap:"8px"
  },
  MainContainers: {
    display: "flex",
    padding: "60px 0px 0px 0px",
    width: "100%",
    gap: "16px",
    paddingBottom: "12px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  Top: {
    paddingTop: "60px",
  },
  FOUR: {
    paddingBottom: "4px",
  },
  WiDTH: {
    width: "100%",
  },
  hovering: {
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  leftContainer: {
    width: "420px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "100px",
    paddingBottom: "0rem",
    height: "100%",

    background: "white",
    "@media (max-width: 768px)": {
      width: "100%",
      height: "100vh",
    },
  },
  rightContainer: {
    width: "100%",
    background: `url(${LOGIN_BACKGROUND}) 0 0 no-repeat rgba(211, 218, 227, 0.2)`,
    // backgroundColor: "rgba(211, 218, 227, 0.2)",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundBlendMode: "multiply",
  },
  title: commonStyles.largeTitle,
  para: commonStyles.largePara,
  iconPara: {
    fontWeight: "300",
    color: "#212833",
    alignSelf: "center",
    fontSize: "clamp(0.9rem,1vw,2.7rem)",
  },

  eachIconContainer: {
    display: "flex",
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "0.5rem",
  },
  analyticsImage: {
    // width: "75%",
    // height: "100%",
    objectFit: "contain",
    marginTop: "1rem",
    "@media (max-width: 1500px)": {
      width: "90%",
    },
  },
  containAnalyticsImage: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    // width: "clamp(80px,10vw,200px)",
    // height: "clamp(80px,10vh,200px)",
    objectFit: "contain",
  },
  reachIqTitle: {
    fontWeight: "700",
    color: "#212833",
    alignSelf: "center",
    fontSize: "clamp(0.9rem,1.4vw,2.7rem)",
  },
  containLogo: {
    display: "flex",
    justifyContent: "center",
  },
  containLogos: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "4px",
    paddingBottom: "32px",
  },
  termsPara: {
    fontSize: "clamp(12px, 0.729vw, 30px)",
    color: "#626B7E",
    fontWeight: "400",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  socialAuthBtn: {
    // fontFamily: "Roboto",
    background: "white",
    fontWeight: fontWeight.REGULAR,
    border: "1px solid #E6E6E6",
    boxShadow:
      " 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04)",
    height: "40px",
    display: "flex",
    alignItems: "center",
    // fontFamily: "inter_400",
    fontSize: fontSize.SM,
    borderRadius: "6px",
    width: "100%",
    padding: "15px",
    justifyContent: "center",
    color: GREY.PRIMARY,
    cursor: "pointer",
  },
  socialAuthIcon: {
    marginRight: "12px",
    objectFit: "contain",
    width: "clamp(1.4rem,1.7vw,3.5rem)",
    height: "clamp(1.4rem,1.2vh,2.5rem)",
  },
  emailSignIn: {
    color: "#ABB4BD",
    fontWeight: "400",
    fontSize: "clamp(0.8rem,0.8vw,2.4rem)",
    textAlign: "center",
    position: "relative",
    whiteSpace: "nowrap",
  },
  signInSpan: {
    background: "white",
    zIndex: 2,
    padding: "0 5px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      borderBottom: "1px solid #ddd",
      top: "55%",
      left: 0,
      zIndex: -1,
    },
  },
  containEmailSignIn: {
    padding: "1rem",
  },

  containEachInput: {
    background: "#fcfcfd",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0.5rem 1rem",
    border: "1px solid #D3DAE3",
    borderRadius: "8px",
    marginTop: "1rem",
  },
  eachInput: {
    border: "none",
    background: "#fcfcfd",
    fontWeight: "700",
    fontSize: "clamp(14px,1vw,42px)",
  },
  eachInputTitle: {
    color: "#ABB4BD",
    fontWeight: "400",
    fontSize: "clamp(14px,1vw,42px)",
  },
  containForgotPassword: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "4px",
  },
  forgotPassword: {
    border: "none",
    background: "white",
    fontWeight: "400",
    fontSize: "clamp(12px, 0.729vw, 30px)",
    cursor: "pointer",
    "& :hover": {
      color: PRIMARY.W_600,
    },
  },

  hover: {
    "& :hover": {
      color: PRIMARY.W_600,
    },
  },
  loginBtn: commonStyles.redButtonWithShadow,
  flex: {
    display: "flex",
    justifyContent: "center",
    // paddingTop: "16px",
    paddingTop: "0.7rem",
  },
  noAccountText: {
    color: "#6E6D7A",
    fontWeight: "400",
    fontSize: "clamp(12px, 0.729vw, 30px)",
    // cursor: "pointer",
  },
  authToggle: {
    marginLeft: "0.5rem",
    border: "none",
    color: "#ff4848",
    background: "unset",
    fontWeight: "400",
    fontSize: "clamp(12px, 0.729vw, 30px)",
    cursor: "pointer",
    textDecoration: "none",
  },
  Gap: {
    padding: "24px 0px ",
    display: "flex",
    alignItems: "center",
    columnGap: "20px",
  },
  Border: {
    width: "23vw",
    borderTop: `1px solid ${BORDER.LIGHT}`,
  },
  containLoginBtn: {
    display: "flex",
    justifyContent: "center",
    // margin: "1rem 0rem 1rem 0rem",
    marginTop: "24px",
  },
  email: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  pass: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "12px",
  },
  containPolicy: {
    display: "flex",
    justifyContent: "center",

    paddingTop: "1rem",
  },
  privacynotLink: {
    fontSize: "clamp(12px,0.8vw,36px)",
    color: "#626B7E",
    fontWeight: "500",
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
  },
  privacyLink: {
    fontSize: fontSize.XS,
    fontWeight: fontWeight.REGULAR,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: GREY.SECONDARY,
    transition: "100ms",
    "&:hover": {
      color: BRAND.PRIMARY,
    },
  },
  privacyLinkSeperatorText: {
    fontSize: fontSize.SM,
    fontWeight: fontWeight.REGULAR,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: "#626B7E",
  },
  inputLabelText: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontSize: "clamp(12px, 0.729vw, 24px)",
    fontWeight: "500",
    marginBottom: "4px",
    color: "#212833",
  },
});
