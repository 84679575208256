import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { allFilters } from "../../redux/actions/filterActions";
import { CloseIcon } from "../Icon/MuiIcons";
import { styles } from "./styles";
import IconComponent from "../Icon/IconComponent";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import { BRAND, GREY } from "../../utils/constants/colors";
import { STRINGS } from "../strings";
import TypographyInline from "../Typography/TypographyInline";

const CheckBoxValueFilter = ({
  value,
  filter,
  type = "",
  array,
  filterKey,
  clearAction,
  defaultActionValue,
  filterType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);

  const getValueFromIndex = (array, index, type) => {
    const item = array?.filter(
      (item) => item?.[type]?.toString() === index?.toString()
    );
    return item?.[0]?.label || item?.[0]?.type;
  };

  const handleRemoveFilter = () => {
    const query = { [filterKey]: [] };
    dispatch(allFilters(query));
    dispatch(clearAction(defaultActionValue));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        [filterKey]: [],
      }).toString(),
    });
  };

  return (
    <Box sx={styles.selectedFilterBox}>
      <div style={styles.cancelIcon} onClick={handleRemoveFilter}>
        <IconComponent
          iconLabel={ICON_LABELS.CANCEL}
          color={GREY.TERTIARY}
          fontSize={fontSize.MD}
        />
      </div>
      <div style={styles.filterValues}>
        <TypographyInline
          label={filter}
          size={"sm"}
          fontWeight={fontWeight.SEMIBOLD}
          color={GREY.PRIMARY}
          isNoWrap={true}
        />
        <TypographyInline
          label={
            type === "_not" ? STRINGS.IS_NOT_EQUAL_TO : STRINGS.IS_EQUAL_TO
          }
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
          isNoWrap={true}
        />
        {value?.map((item, index) => {
          return (
            <TypographyInline
              label={`${getValueFromIndex(array, item, filterType)}${value?.length > index + 1 ? ", " : "."
                }`}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={BRAND.PRIMARY}
              isNoWrap={true}
            />
          );
        })}
      </div>
    </Box>
  );
};

export default CheckBoxValueFilter;
