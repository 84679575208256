import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useParams, useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/Messages/ErrorMessage";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../utils/constants";
import { HOME_PATH, LOGIN_PATH } from "../../utils/constants/routes";
import {
  HOST_URL,
  authEndpoints,
  protocol,
} from "../../utils/constants/httpConstants";
import { makeStyles } from "@mui/styles";
import { STRINGS } from "./strings";
import { Button } from "../../components/Buttons";
import { POST } from "../../Apis/apiWrapper";

const styles = makeStyles({
  box: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const EmailVerification = () => {
  const classes = styles();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = params?.id;

  const createUrl = (domain, email) => {
    let subDomain = domain?.trim().split(".")[0];
    let redirectedUrl = `${protocol}${subDomain}.${HOST_URL}${LOGIN_PATH}/${email}`;
    window.location.href = redirectedUrl;
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await POST(authEndpoints.Authroute, {
          verification_token: token,
        });

        if (res) {
          setTimeout(() => {
            createUrl(res?.data?.data?.tenant_url, res?.data?.data?.email);
          }, 1500);
          CustomEventEmitter.emit(
            EMITTER_SUCCESS,
            res?.data?.message || STRINGS.EMAIL_VERIFIED
          );
        }
      } catch (error) {
        setLoading(false);

        setError(error?.response);
      } finally {
      }
    })();
  }, [navigate, token]);

  return (
    <Box className={classes.box}>
      {error && (
        <Box>
          <ErrorMessage message={error.data.message} />
          <Button
            label={STRINGS.GO_HOME}
            size={"md40"}
            onClick={() => navigate(HOME_PATH)}
            variant={"primary"}
            isLoading={loading}
          />
        </Box>
      )}
    </Box>
  );
};

export default EmailVerification;
