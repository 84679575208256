import { getRequest } from "../../Apis";
import { SEQUENCE_PATH } from "../../utils/constants/routes";
import { GET_ALL_STEPS_FAIL, GET_ALL_STEPS_REQUEST, GET_ALL_STEPS_SUCCESS } from "../constants/stepsConstants";

export const getAllSteps = (URL, navigate) => async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_STEPS_REQUEST });
      const response = await getRequest(navigate, URL );

      if( response?.data?.data?.seq_steps?.length ){
        dispatch({
          type: GET_ALL_STEPS_SUCCESS,
          payload: response?.data,
        });
      }else{
        navigate(SEQUENCE_PATH)
      }
  
    } catch (err) {
      dispatch({
        type: GET_ALL_STEPS_FAIL,
        payload: err?.response?.data,
      });
    }
  }
  