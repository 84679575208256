import React, { useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { csvDownload, csvDownload1, filterQuery, getSelectedColumns, getSelectedColumnsCompany, processContactNumberFormat, reorderArrayByKeys } from "../../../utils/helpers";
import { contactListEndPoints } from "../../../utils/constants/httpConstants";
import { postRequest } from "../../../Apis";
import { EMITTER_ERROR, EMITTER_SUCCESS, STATIC_COLUMN_ORDER } from "../../../utils/constants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { COMPANY_SCREEN_PATH } from "../../../utils/constants/routes";
import { ALL_COLUMNS, ALL_EXP, EXPORT_SEL, SELECTD_EXP, SELECTED_ALL_COLUMNS, SELECTED_COLUMNS, SELECTED_SELECTED_COLUMNS } from "../../../utils/constants/assets";
import { checkLogin } from "../../../redux/actions/authActions";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import CreditsForRefferalModal from "../../../components/Modals/CreditsForRefferalModal";
import classes from "./LeadsComponent.module.css";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { companyDataFetch, tableDataFetch } from "../../../redux/actions/tableActions";
import { paginationDataAction } from "../../../redux/actions/customTableActions";

const ExportList = [
  {
    field: STRINGS.EXPORT_ALL_COLUMNS,
    selected_icon: ALL_EXP,
    normal_icon: EXPORT_SEL,
  },
  {
    field: STRINGS.EXPORT_SELECT,
    selected_icon: SELECTD_EXP,
    normal_icon: EXPORT_SEL,
  },
];

const ExportModal = ({
  open,
  setOpen,
  leadType = STRINGS.PEOPLE,
  bulkSelected = false,
  record_ids = [],
  columns,
  setSelectedIds,
  setBulkSelected
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(STRINGS.EXPORT_ALL_COLUMNS);
  const [credits, setcredits] = useState(false);

  const paginationDataSelector = useSelector((state) => state.getPaginationQuery);
  const allFiltersSelector = useSelector((state) => state.allFilters);

  const tableEndpointChangeSelector = useSelector(
    (state) => state?.tableEndpointChange
  );
  const companyTableEndpointChangeSelector = useSelector(
    (state) => state?.companyTableEndpointChange
  );

  const tablePayload = useSelector((state) => state?.tableDataFetch);

  const [exporting, setExporting] = useState(false);
  const handleSelection = (item) => {
    setSelected(item.field);
  };

  function convertToDisplayName(str) {
    // Split the string at underscores
    let words = str.split('_');

    // Capitalize the first letter of each word
    words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with a space between them
    let result = words.join(' ');

    return result;
  }

  const handleExport = async () => {
    const recordIds = record_ids?.length ? record_ids : paginationDataSelector?.data?.checkedRow;
    const payload = bulkSelected
      ? {
        ...filterQuery(allFiltersSelector?.payload),
        action_type: STRINGS.EXPORT_DATA,
        list_id: STRINGS.MY_LIST,
      }
      : {
        record_ids: recordIds,
        action_type: STRINGS.EXPORT_DATA,
        list_id: STRINGS.MY_LIST,
      };
    if (selected === STRINGS.EXPORT_SELECT) {
      payload[STRINGS.TABLE_COLUMNS] = getSelectedColumns(columns);
    } else {
      delete payload.table_columns
    }

    // check for emails and add in key.
    const email_ids = tablePayload?.columns?.filter(item => recordIds.includes(item?._id) && item.email)?.map(item => item?.email);
    payload.email_ids = email_ids || [];


    if (window.location.pathname === COMPANY_SCREEN_PATH && selected === STRINGS.EXPORT_SELECT) {
      payload[STRINGS.TABLE_COLUMNS] = getSelectedColumnsCompany(columns);
    }
    const peopleURL = bulkSelected
      ? tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
        ? contactListEndPoints.bulkAlreadySaveToList
        : contactListEndPoints.bulkSaveToList
      : tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
        ? contactListEndPoints.alreadySaveToList
        : contactListEndPoints.exportList;

    const companyURL = bulkSelected
      ? companyTableEndpointChangeSelector?.payload === STRINGS.SAVED_COMPANIES
        ? contactListEndPoints.companyBulkExport
        : contactListEndPoints.companybulkSaveToList
      : companyTableEndpointChangeSelector?.payload === STRINGS.SAVED_COMPANIES
        ? contactListEndPoints.companyExportList
        : contactListEndPoints.companySaveToList;



    const URL = leadType === STRINGS.PEOPLE ? peopleURL : companyURL;
    try {
      setExporting(true);
      const response = await postRequest(navigate, URL, payload);
      setSelectedIds && setSelectedIds([]);
      // dispatch(checkLogin(navigate));
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message || "");
      setExporting(false);
      setOpen(false);
      dispatch(paginationDataAction({}));
      leadType === STRINGS.PEOPLE ? dispatch(tableDataFetch(allFiltersSelector?.payload, navigate, tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT)) : dispatch(companyDataFetch(allFiltersSelector?.payload, navigate, companyTableEndpointChangeSelector?.payload === STRINGS.SAVED_COMPANIES));

      if (!bulkSelected) {
        // condition here if table_columns are present, pass table columns.
        let data = response?.data?.data;
        let columns_order = payload?.table_columns || STATIC_COLUMN_ORDER;
        data = reorderArrayByKeys(response?.data?.data, columns_order)
        console.log("DG", data)
        // sanitize contact numbers based on countries.
        const formatted_data = [];

        for (let i in data) {
          let each_data = data?.[i] || {};
          if (each_data?.direct_phone_number) {
            const direct_phone_number = processContactNumberFormat(each_data?.person_country, each_data?.direct_phone_number);
            each_data.direct_phone_number = direct_phone_number;

          }
          if (each_data?.mobile_number) {
            const mobile_number = processContactNumberFormat(each_data?.person_country, each_data?.mobile_number);
            each_data.mobile_number = mobile_number;
          }
          if (each_data?.company_phone_number) {
            const company_phone_number = processContactNumberFormat(each_data?.company_country, each_data?.company_phone_number);
            each_data.company_phone_number = company_phone_number;

          }
          // let text =convertToDisplayName(each_data)
          formatted_data.push(each_data)
        }

        // download csv.
        csvDownload1(formatted_data, leadType === STRINGS.PEOPLE ? STRINGS.CONTACT_EXPORT : STRINGS.COMPANY_EXPORT);
      }

    } catch (err) {
      setExporting(false);
      if (err?.status === 404) {
        setcredits(true)
      } else {
        EventEmitter.emit(EMITTER_ERROR, err?.data?.message || STRINGS.SESSION);
      }
    }
  };


  const modalHeaderData = {
    heading: {
      id: STRINGS.EXPORT_MODAL_ID,
      label: STRINGS.EXPORT,
    },
  };

  const modalFooterRightData = (
    <div className={classes.exportModalFooter}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={() => setOpen(!open)}
      />

      <Button
        label={STRINGS.EXPORT}
        size={"sm36"}
        variant={"primary"}
        onClick={handleExport}
        isLoading={exporting}
      />
    </div>
  );

  return (
    <Box>
      <CustomModal
        header={modalHeaderData}
        footer={{ right: modalFooterRightData }}
        open={open}
        close={() => setOpen(!open)}
        width="30vw"
      >
        <Box className={classes.flex1}>
          {ExportList?.map((item) => {
            return (
              <Box className={classes.containEachAction}
                key={item.field}
                onClick={() => handleSelection(item)}
              >
                <img
                  src={
                    selected === item.field
                      ? item.selected_icon
                      : item.normal_icon
                  }
                  fontSize="20px"
                  name={item.icon}
                  alt=""
                />
                <TypographyInline
                  label={item.field}
                  size={"md"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                />
              </Box>
            );
          })}
        </Box>
      </CustomModal>

      {credits ?
        <CreditsForRefferalModal open={credits} close={setcredits} /> : ""}

    </Box>
  );

};

export default ExportModal;
