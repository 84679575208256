import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../../strings";
import classes from "./CrmComp.module.css";
import { Button } from "../../../../components/Buttons";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { BRAND, GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import TimeSelector from "../../../../components/TimeSelector/TimeSelector";
import { HtmlEditor } from "../../../../components";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate } from "react-router";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { getContactsNames } from "../../../../utils/helpers";
import CustomAutoCompleteChip from "../../../../components/Autocomplete/CustomAutoCompleteChip";
import { MESSAGE_REQUIRED_FIELD } from "../../../../utils/constants/messages";

const LogEmailModal = ({
  open,
  close,
  contactDetails,
  contactId,
  getEmailLogList,
}) => {
  const navigate = useNavigate();

  const [logEmailDate, setLogEmailDate] = useState(new Date());
  const [logEmailTime, setLogEmailTime] = useState("00:00");
  const [createEmailLogLoading, setCreateEmailLogLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [contacted, setContacted] = useState({});
  const [descriptionErr, setDescriptionErr] = useState("");

  const createLogEmailHandler = async () => {
    try {
      const editor = document?.getElementById(STRINGS.LOG_EMAIL_EDITOR_ID);
      if (editor?.innerHTML?.length && editor?.innerText?.trim()?.length) {
        setDescriptionErr("");
        let processingDateTime = `${logEmailDate?.toDateString()} ${logEmailTime}`;
        let processingDateTimeMs = new Date(processingDateTime).getTime();

        let payload = {
          body: encodeURIComponent(editor?.innerHTML),
          company_id: contactId,
          processing_date_time: processingDateTimeMs,
          to_emails: [contacted?.email],
        };

        setCreateEmailLogLoading(true);
        const response = await postRequest(
          navigate,
          crmEndpoints.createEmailLog,
          payload
        );
        getEmailLogList();
        close();
        setCreateEmailLogLoading(false);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } else {
        setDescriptionErr(MESSAGE_REQUIRED_FIELD)
      }
    } catch (error) {
      setCreateEmailLogLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("error ->>>>> ", error);
    }
  };

  const getContactsList = async (value = "") => {
    try {
      const response = await getRequest(
        navigate,
        crmEndpoints.getEmailSuggestions + `?suggestion=${value}`
      );
      setContactList(response?.data?.data?.records);
      return response?.data?.data?.records;
    } catch (error) { }
  };

  const logEmailModalHeader = {
    heading: {
      id: STRINGS.LOG_EMAIL_SMALL,
      label: STRINGS.LOG_EMAIL,
    },
  };

  const logEmailModalLeftFooter = <div></div>;

  const logEmailModalRightFooter = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={close}
      />
      <Button
        label={STRINGS.CREATE}
        variant={"primary"}
        size={"sm36"}
        onClick={createLogEmailHandler}
        isLoading={createEmailLogLoading}
      />
    </div>
  );

  useEffect(() => {
    let getData;
    getData = setTimeout(() => {
      getContactsList(searchValue);
    }, 300);

    return () => clearTimeout(getData);
  }, [searchValue]);

  return (
    <div>
      <CustomModal
        close={close}
        open={open}
        header={logEmailModalHeader}
        footer={{
          left: logEmailModalLeftFooter,
          right: logEmailModalRightFooter,
        }}
        width={"40vw"}
      >
        <div className={classes.logEmailBody}>
          {/* contact dropdown */}
          <div className={classes.inputLabelField}>
            <div>
              <TypographyInline
                fontWeight={fontWeight.MEDIUM}
                color={GREY.QUATINARY}
                size={"sm"}
                label={STRINGS.CONTACTED}
                isRequired
              />
            </div>
            <CustomAutoCompleteChip
              items={getContactsNames(contactList)}
              onChange={(data) => {
                setContacted(data);
              }}
              inputOnchange={(value) => {
                setSearchValue(value);
              }}
              placeholder={STRINGS.TYPE_TO_SEARCH}
              defaultValues={contacted}
              multiple={false}
            />
          </div>

          {/* date time field */}
          <div className={classes.dateTimeFiledContainer}>
            <div
              className={`${classes.inputLabelField} ${classes.inputLabelWidth}`}
            >
              <div>
                <TypographyInline
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.QUATINARY}
                  size={"sm"}
                  label={STRINGS.DATE}
                  isRequired
                />
              </div>
              <div>
                <CustomDatePicker
                  size={"sm36"}
                  datePickerId="emailLogModalDatePicker"
                  value={logEmailDate}
                  onChange={(dateValue) => {
                    setLogEmailDate(new Date(dateValue));
                  }}
                />
              </div>
            </div>
            <div
              className={`${classes.inputLabelField} ${classes.inputLabelWidth}`}
            >
              <div>
                <TypographyInline
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.QUATINARY}
                  size={"sm"}
                  label={STRINGS.TIME}
                  isRequired
                />
              </div>
              <div>
                <TimeSelector time={logEmailTime} setTime={setLogEmailTime} />
              </div>
            </div>
          </div>

          {/* description */}
          <div
            className={`${classes.inputLabelField} ${classes.inputLabelWidth}`}
          >
            <div>
              <TypographyInline
                fontWeight={fontWeight.MEDIUM}
                color={GREY.QUATINARY}
                size={"sm"}
                label={STRINGS.DESCRIPTION}
                isRequired
              />
            </div>
            <div className={descriptionErr
              ? classes.htmlEditorError
              : classes.htmlEditor}>
              <HtmlEditor
                isAITagNotVisible
                isFocusNotRequired
                isAddTagNotVisible
                isMeetingTagNotVisible
                id={STRINGS.LOG_EMAIL_EDITOR_ID}
                editorHeight={"8.625vw"}
              />
              {descriptionErr ? (
                <TypographyInline
                  label={descriptionErr || ""}
                  color={BRAND.PRIMARY}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                />
              ) : null}
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default LogEmailModal;
