import React, { useEffect, useState } from "react";
import { BASE, GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { Box } from "@mui/material";
import { Button } from "../../../components/Buttons";
import { styles } from "./styles";
import { STRINGS } from "../strings";
import { postRequest } from "../../../Apis";
import { meetingsEndpoint } from "../../../utils/constants/httpConstants";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import Image from "../../../components/Image/Image";
import AvatarName from "../../../components/Avatar/AvatarName";
import TextArea from "../../../components/InputFields/TextArea";
import { verifyDomain } from "../../../redux/actions/authActions";

const CancelMeetingScreen = () => {
  const classes = styles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [record, setRecord] = useState("");
  const [cancelData, setCancelData] = useState("");
  const [loading, setLoading] = useState(false);
  const [textReason, setTextReason] = useState("");
  const [error, setError] = useState("");

  const Location = useLocation();
  const Tokens = Location?.search?.split(STRINGS.EVENT_LOC_ID);

  const handleInputChange = (e) => {
    setTextReason(e.target.value);
  };
  useEffect(() => {
    (async () => {
      const host = window.location.host;
      let hostLength = host?.trim()?.split(".");
      setLoading(true);
      if (hostLength.length > 1) {
        const subDomain = host?.trim()?.split(".")[0] + ".reachiq.ai";
        await verifyDomain(subDomain, navigate);

        (async () => {
          try {
            const payload = {
              eventId: Tokens[1],
            };

            const response = await postRequest(
              navigate,
              meetingsEndpoint.meetingCancel,
              payload
            );
            setLoading(false);
            setCancelData(response?.data?.data);
          } catch (error) {
            setLoading(false);
            setError(error?.data?.message);
          }
        })();
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoader(true);

      const payload = {
        eventId: Tokens[1],
        reason: textReason,
        imageURL: cancelData?.imageURL,
      };
      const response = await postRequest(
        navigate,
        meetingsEndpoint.cancelMeeting,
        payload
      );

      setRecord(response?.data?.success);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setLoader(false);
    } catch (error) {
      EventEmitter.emit(
        EMITTER_ERROR,
        error?.response?.data?.message || error?.message
      );
    }
  };
  return (
    <Box className={classes.HeadContainer2}>
      <Box className={classes.HeadContainer}>
        {error ? (
          <Box className={classes.SubHeadConatinerErr}>
            <TypographyInline
              size={"sm"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.BOLD}
              label={error}
            />
          </Box>
        ) : (
          <Box className={classes.SubHeadConatiner}>
            {loading ? (
              Array.from({ length: 1 })?.map(() => (
                <SkeletonLoader
                  type="circular"
                  skeletonWidth={70}
                  skeletonHeight={70}
                  stackWidth={70}
                />
              ))
            ) : cancelData?.avatar_image_url ? (
              <Image
                src={cancelData?.avatar_image_url}
                className={classes.previewImage2}
                alt={STRINGS.SELECTED_IMG}
              />
            ) : (
              <AvatarName size={"extraLarge"} name={cancelData?.organizer_name} />
            )}

            <Box className={classes.CancelMeet}>
              {loading ? (
                Array.from({ length: 2 })?.map(() => (
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"30%"}
                    skeletonHeight={"20px"}
                    stackWidth={"100%"}
                    stackHeight={"100%"}
                  />
                ))
              ) : (
                <>
                  <TypographyInline
                    size={"xl"}
                    color={GREY.PRIMARY}
                    fontWeight={fontWeight.BOLD}
                    label={`${STRINGS.CANCEL_MEET} ${cancelData?.organizer_name}`}
                  />

                  <TypographyInline
                    size={"sm"}
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    label={cancelData?.scheduled_date}
                  />
                </>
              )}
            </Box>

            {record ? (
              <Box className={classes.Meetingmes}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={STRINGS.MEETING_CANCELLED}
                />
                <TypographyInline
                  size={"sm"}
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={STRINGS.MEETING_CANCELLED_2}
                />
              </Box>
            ) : (
              <>
                {loading ? (
                  Array.from({ length: 1 })?.map(() => (
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"100%"}
                      skeletonHeight={"136px"}
                      stackWidth={"100%"}
                      stackHeight={"100%"}
                    />
                  ))
                ) : (
                  <Box className={classes.textarea}>
                    <TextArea
                      variant={"default"}
                      isResize={true}

                      placeholder={STRINGS.ADD_REASON}
                      value={textReason}
                      fullWidth
                      name="Message"
                      maxLength={500}
                      onChange={handleInputChange}
                    />
                  </Box>
                )}
                <Box className={classes.footerContain}>
                  {loading ? (
                    Array.from({ length: 1 })?.map(() => (
                      <SkeletonLoader
                        type="rounded"
                        skeletonWidth={"100%"}
                        skeletonHeight={"35px"}
                        stackWidth={"100%"}
                        stackHeight={"100%"}
                      />
                    ))
                  ) : (
                    <Button
                      label={STRINGS.CANCEL_MEETING_CAPS}
                      size={"md40"}
                      variant={"primary"}
                      isLoading={loader}
                      onClick={handleSubmit}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CancelMeetingScreen;
