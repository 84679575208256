import React from "react";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { Button } from "../../../components/Buttons";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import classes from "./PropComponent.module.css";
import TypographyInline from "../../../components/Typography/TypographyInline";

const DeletePropertyModal = ({
  open,
  close,
  deleteGroupHandler = () => { },
  btnLoading = false,
  propertyName,
}) => {
  const modalHeaderData = {
    heading: {
      id: STRINGS.CRT_PROP_SMALL,
      label: STRINGS.DELETE + ` "${propertyName}"`,
    },
  };

  const modalFooterRightData = (
    <div className={classes.createPropFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={close}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        isLoading={btnLoading}
        onClick={() => {
          deleteGroupHandler();
        }}
      />
    </div>
  );

  return (
    <CustomModal
      open={open}
      close={close}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
      variant={STRINGS.ERROR_SMALL}
      width={"27.865vw"}
    >
      <TypographyInline
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={STRINGS.DELETE_DESC_PROPERTY}
        size={"sm"}
      />
    </CustomModal>
  );
};

export default DeletePropertyModal;
