import React from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  EMITTER_SUCCESS,
  tempHeadCells,
  tempHeadDealsCells,
} from "../../../utils/constants";
import {
  dynamicColumnUpdateHeaders,
  dynamicUpdatedColumn,
} from "../../../redux/actions/tableActions";
import { STRINGS } from "../strings";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import EventEmitter from "../../../utils/emitter/EventEmitter";

const styles = makeStyles({
  modalFooterRightData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  modalBody: {
    padding: "4px 16px 16px 16px",
  },
});

const ResetDynamicColumns = ({ open, setOpen, action = () => {} }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const querySelector = useSelector(
    (state) => state?.allDynamicFilters?.payload
  );

  //   helper for rest columns
  const handleResetColumn = async () => {
    let columns = [...tempHeadCells, ...tempHeadDealsCells];
    await dispatch(
      dynamicColumnUpdateHeaders(JSON.parse(JSON.stringify(columns)))
    );
    await dispatch(dynamicUpdatedColumn(JSON.parse(JSON.stringify(columns))));
    // dispatch according to screen and action should be pass from parent.
    dispatch(action(querySelector, navigate));

    setOpen(!open);
    EventEmitter.emit(EMITTER_SUCCESS, STRINGS.COLUMN_RESET);
  };

  const handleChange = async () => {
    setOpen(!open);
  };

  const modalHeaderData = {
    heading: {
      id: "head",
      label: STRINGS.RESET_THE_COLUMNS,
    },
    alertIcon: {
      id: "restore",
      label: ICON_LABELS.RESTORE,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <TypographyInline
        size={"md"}
        color={GREY.PRIMARY}
        fontWeight={fontWeight.SEMIBOLD}
        label={STRINGS.RESET_ALL_PROPERTIES}
      />
      <TypographyInline
        size={"sm"}
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={STRINGS.RESET_ALL_PROPERTIES_DESC}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        size={"sm36"}
        variant={"ghost"}
        onClick={handleChange}
      />

      <Button
        label={STRINGS.RESET}
        size={"sm36"}
        variant={"primary"}
        onClick={handleResetColumn}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      close={handleChange}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
      variant={"alert"}
      remove_padding
    />
  );
};

export default ResetDynamicColumns;
