import { getRequest, postRequest } from "../../Apis";
import { patchRequest } from "../../Apis/apiWrapper";
import { taskEndpoints } from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";
import {
  GET_TASK_SEARCH_FAIL,
  GET_TASK_SEARCH_REQUEST,
  GET_TASK_SEARCH_SUCCESS,
  GET_TASK_TABLE_FAIL,
  GET_TASK_TABLE_REQUEST,
  GET_TASK_TABLE_SUCCESS,
  UPDATE_TASK_TABLE_REQUEST,
  UPDATE_TASK_TABLE_SUCCESS,
  UPDATE_TASK_TABLE_FAIL,
} from "../constants/taskConstants";

export const getTaskTable =
  (navigate, payload, data = {}) =>
  async (dispatch) => {
    try {
      if (Object.keys(data).length) {
        dispatch({
          type: GET_TASK_TABLE_SUCCESS,
          payload: { data, query: payload },
        });
      } else {
        dispatch({ type: GET_TASK_TABLE_REQUEST });
        const URL = taskEndpoints?.getTaskTable;
        const response = await postRequest(navigate, URL, payload);
        dispatch({
          type: GET_TASK_TABLE_SUCCESS,

          payload: { ...response?.data, query: payload },
        });
      }
    } catch (err) {
      // log user out here.
      if (err?.status === 401) {
        // navigate from this action
        navigate(LOGIN_PATH);
      }
      dispatch({ type: GET_TASK_TABLE_FAIL, payload: err });
    }
  };

export const getTaskSearch =
  (endpoint, param, navigate) => async (dispatch) => {
    try {
      dispatch({ type: GET_TASK_SEARCH_REQUEST });
      const URL = endpoint + param;
      const response = await getRequest(navigate, URL);
      dispatch({
        type: GET_TASK_SEARCH_SUCCESS,
        payload: response?.data,
      });
    } catch (err) {
      // log user out here.
      if (err?.status === 401) {
        // navigate from this action
        navigate(LOGIN_PATH);
      }
      dispatch({ type: GET_TASK_SEARCH_FAIL, payload: err });
    }
  };

export const updateTaskTable = (navigate, payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TASK_TABLE_REQUEST });
    const URL = taskEndpoints?.updateTask;

    const response = await patchRequest(navigate, URL, payload);

    dispatch({
      type: UPDATE_TASK_TABLE_SUCCESS,

      payload: response,
    });
  } catch (err) {
    // log user out here.
    if (err?.status === 401) {
      // navigate from this action
      navigate(LOGIN_PATH);
    }
    dispatch({ type: UPDATE_TASK_TABLE_FAIL, payload: err });
  }
};
