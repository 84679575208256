import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  BLOCKED_CONTACTS_LIST,
  CAMPAIGN_CREATE_DAYS_LIST,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  initialTimings,
  TIMEZONE_LIST,
} from "../../../../utils/constants";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate, useParams } from "react-router-dom";
import { campaignEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { useDispatch } from "react-redux";
import {
  dateValidation,
  nameValidation,
} from "../../../../utils/validations/inputValidations";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import {
  createTimingsForPayload,
  createTimingsFromPayload,
  findSortedValue,
  getTimeStampForTimeZone,
  handleGoogleAuth,
  handleMicrosoftAuthInitialize,
} from "../../../../utils/helpers";
import Stepper from "@mui/material/Stepper";
import SkippedContact from "./SkippedContact";
import { Button } from "../../../../components/Buttons";
import CustomModal from "../../../../components/Modals/CustomModal";
import {
  ACTIVE_IMG,
  DONE_IMG,
  GOOGLE_ICON,
  MICROSOFT_IMAGE,
  UNSELECTED_IMG,
} from "../../../../utils/constants/assets";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import { BORDER, BRAND, GREY } from "../../../../utils/constants/colors";
import InputField from "../../../../components/InputFields/InputField";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import Image from "../../../../components/Image/Image";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import EspSkeleton from "../common/EspSkeleton";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import { paginationDataAction } from "../../../../redux/actions/customTableActions";
import {
  MESSAGE_ALL_FIELD_REQUIRED,
  SERVER_MESSAGE_ABSENCE,
  SERVER_MESSAGE_MISPLACED,
} from "../../../../utils/constants/messages";
import classes from "../markComponents.module.css";
import { STRINGS } from "../strings";
import TimeScheduler from "../../../../components/TimeScheduler/TimeScheduler";

const millisecondsToDateStringWithoutNumber = (date) => {
  const schedule_date = new Date(date);
  const result = `${schedule_date.getFullYear()}-${schedule_date.getMonth() <= 9 ? "0" : ""
    }${schedule_date.getMonth() + 1}-${schedule_date.getDate() <= 9 ? "0" : ""
    }${schedule_date.getDate()}`;
  return result;
};

const CreateCampaignModal = ({
  open,
  setOpen,
  nextstep,
  setPayload = () => { },
  payload,
  ...props
}) => {
  const { id } = useParams();
  const date = new Date();
  const currentDate = `${date.getFullYear()}-${date.getMonth() + 1 <= 9 ? "0" : ""
    }${date.getMonth() + 1}-${date.getDate() <= 9 ? "0" : ""}${date.getDate()}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sequence, setSequence] = useState([payload?.sequence_id] || []);
  const [name, setName] = useState(
    payload?.name ? STRINGS.COPYOF + " " + payload?.name : ""
  );
  const [scheduleDate, setScheduleDate] = useState(null);
  let offset1 = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone_objChange = TIMEZONE_LIST?.filter(
    (item) => item?.id === offset1
  );
  const [timezone, setTimeZone] = useState([
    payload?.timezone || timezone_objChange[0]?.id,
  ]);
  const [loading, setLoading] = useState(true);
  const [sequenceList, setSequenceList] = useState([]);
  const [signList, setSignList] = useState([]);
  const [selectedList, setSelectedList] = useState([payload?.list_id] || []);
  const [contactList, setContactList] = useState([]);
  const [espList, setEspList] = useState([]);
  const [mailState, setMailState] = useState([]);
  const [idMailState, setIdMailState] = useState([]);
  const [signState, setSignState] = useState([STRINGS.DEF_SIGNATURE]);
  const [idSignState, setIdSignState] = useState([""]);
  const [mailList, setMailList] = useState([]);
  const [outState, setOutState] = useState([]);
  const [itemData, setItemData] = useState({});
  const [idOutState, setIdOutState] = useState([]);
  const [outList, setOutList] = useState([]);
  const [isEnableList, setIsEnableList] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedEsp, setSelectedEsp] = useState(payload?.sender_esp || 2);
  const [getTimings, setGetTimings] = useState(
    createTimingsFromPayload(payload?.timings) || [...initialTimings]
  );
  const [creating, setCreating] = useState(false);
  const [creating1, setCreating1] = useState(false);
  const [editTiming, setEditTiming] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [stepActivity, setStepActivity] = useState(false);
  const [blockedList, setBlockedList] = useState([]);
  const [isEligibleLoad, setIsEligibleLoad] = useState(false);
  const [sequenceLabel, setSequenceLabel] = useState("");
  const [listLabel, setListLabel] = useState("");
  const [validations, setValidations] = useState({
    name: "",
    scheduleDate: "",
  });
  const [sendVia, setSendVia] = useState("");
  const [timezoneList, setTimezoneList] = useState([...TIMEZONE_LIST]);

  const espConnectionList = [
    {
      id: 1,
      label: STRINGS.CONNECT_GOOGLE,
      icon: GOOGLE_ICON,
    },
    {
      id: 2,
      label: STRINGS.CONNECT_OUTLOOK,
      icon: MICROSOFT_IMAGE,
    },
  ];
  const [error, setError] = useState(false);

  const handleCreateCampaign = async (startNow = false) => {
    if (startNow) {
      setCreating(true);
      setScheduleDate(currentDate);
    } else {
      setCreating1(true);
    }
    const validatedName = nameValidation(name);
    const scheduledDate = startNow
      ? currentDate
      : millisecondsToDateStringWithoutNumber(scheduleDate);
    const validatedDate = dateValidation(scheduledDate);
    if (validatedName.isValid && validatedDate.isValid) {
      setValidations({ name: "", scheduleDate: "" });
      const dateArray = scheduledDate?.split("-");
      const offset = TIMEZONE_LIST.filter(
        (item) => item.id === timezone?.[0]
      )?.[0]?.offset;
      const actualTimings = createTimingsForPayload(getTimings);
      const newScheduleDate = `${parseInt(dateArray[2])}-${parseInt(
        dateArray[1]
      )}-${parseInt(dateArray[0])}`;
      const emailVal = !(selectedEsp == 2 || selectedEsp == 5)
        ? itemData.email
        : selectedEsp == 2
          ? mailState[0]
          : outState[0];
      try {
        let payload;
        id
          ? (payload = {
            parent: id,
            name: name,
            sequence_id: sequence?.[0] || "",
            list_id: selectedList?.[0] || "",
            // this date is not right...
            scheduled_date: newScheduleDate,
            from_email: emailVal,
            timezone: timezone?.[0],
            timings: actualTimings,
            offset,
            other_signature_id: idSignState[0],
            sender_esp: Number(selectedEsp),
          })
          : (payload = {
            name: name,
            sequence_id: sequence?.[0] || "",
            list_id: selectedList?.[0] || "",
            // this date is not right...
            scheduled_date: newScheduleDate,
            from_email: emailVal,
            timezone: timezone?.[0],
            timings: actualTimings,
            other_signature_id: idSignState[0],
            offset,
            sender_esp: Number(selectedEsp),
          });

        if (startNow) {
          payload.schedule_now = true;
        }
        idSignState?.[0]?.length < 1 && delete payload.other_signature_id;
        const response = await postRequest(
          navigate,
          campaignEndpoints.createCampaign,
          payload
        );
        dispatch(paginationDataAction({ query: {}, checkedRow: [] }));
        EventEmitter.emit(
          EMITTER_SUCCESS,
          response?.data?.message || SERVER_MESSAGE_ABSENCE
        );
        setCreating(false);
        setCreating1(false);
        setOpen(false);
        setPayload({});
      } catch (error) {
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.message || SERVER_MESSAGE_MISPLACED
        );
        setCreating(false);
        setCreating1(false);
      } finally {
        setCreating(false);
        setCreating1(false);
        setOpen(false);
        setPayload({});
      }
    } else {
      setCreating(false);
      setCreating1(false);
      setValidations({
        name: validatedName.message,
        scheduleDate: EventEmitter.emit(EMITTER_ERROR, validatedDate.message),
      });
    }
  };

  const convertData = (val) => {
    const data = TIMEZONE_LIST.filter((value) => {
      return value?.id === val;
    });
    return data[0]?.label;
  };

  const isNextStepDisabled = () => {
    if (
      !steps[activeStep]?.requiredFields?.filter((item) => !item?.length)
        ?.length
    ) {
      return false;
    }
    return true;
  };

  const handleStepAddition = async () => {
    try {
      const currentStep = steps?.[activeStep];

      if (currentStep.label === STRINGS.CAMP_BASIC_DETAILS) {
        const nameValidated = nameValidation(name);
        if (nameValidated?.isValid) {
          setStepActivity(true);
          let isCampExist;
          try {
            isCampExist = await postRequest(
              navigate,
              campaignEndpoints.isCampExist,
              { name: name }
            );
            if (isCampExist.status === 200) {
              setValidations({ name: "", scheduleDate: "" });
            }
          } catch (error) {
            if (error?.status === 409) {
              setValidations({
                name: error?.data?.message,
                scheduleDate: "",
              });
            }
            setStepActivity(false);
          }
          if (isCampExist.status === 200) {
            const sequenceResponse = await getRequest(
              navigate,
              campaignEndpoints.sequenceList
            );
            const sequences = sequenceResponse?.data?.data?.map(function (item) {
              return { id: item._id, label: item.name };
            });
            const listResponse = await getRequest(
              navigate,
              campaignEndpoints.contactlist
            );
            const contactsLists = listResponse?.data?.data?.map(function (item) {
              return { id: item._id, label: item.list_name };
            });
            setStepActivity(false);
            if (!sequences?.length || !contactsLists?.length) {
              setIsEnableList(true);
            } else {
              setIsEnableList(false);
            }
            if (!payload?.sequence_id) {
              setSequence([sequences?.[0]?.id] || "");
              setSequenceLabel(sequences?.[0]?.label || "");
            } else {
              const value = sequences?.filter(
                (item) => item?.id === payload.sequence_id
              );
              setSequence([value?.[0]?.id] || "");
              setSequenceLabel(value?.[0]?.label || "");
            }
            if (!payload?.list_id) {
              setSelectedList([contactsLists?.[0]?.id] || []);
              setListLabel(contactsLists?.[0]?.label || "");
            } else {
              const value = contactsLists?.filter(
                (item) => item?.id === payload.list_id
              );
              setSelectedList([value?.[0]?.id] || "");
              setListLabel(value?.[0]?.label || "");
            }
            setContactList(contactsLists);
            setSequenceList(sequences);
          } else {
            setStepActivity(false);
          }
        } else {
          setValidations({
            name: nameValidated?.message,
          });
        }
      }

      if (currentStep.label === STRINGS.TIMINGS) {
        setEditTiming(false);
      }
      const noValueItems = currentStep.requiredFields?.filter(
        (item) => !item?.length
      );
      if (noValueItems?.length) {
        EventEmitter.emit(EMITTER_ERROR, MESSAGE_ALL_FIELD_REQUIRED);
      } else {
        if (currentStep.label === STRINGS.SELECT_SEQ_LIST) {
          // api all in case of list api.
          try {
            setStepActivity(true);
            const payload = { list_id: selectedList?.[0] };
            const response = await postRequest(
              navigate,
              campaignEndpoints.blockedContactList,
              payload
            );
            setBlockedList(response?.data?.data || []);
            // after success this will be done.
            setActiveStep(activeStep + 1);
            setErrorMessage("");
          } catch (error) {
          } finally {
            setStepActivity(false);
          }
        } else {
          setActiveStep(activeStep + 1);
          setErrorMessage("");
        }
      }
    } catch (error) { }
  };

  const sequenceName = findSortedValue(sequence?.[0], sequenceList);
  const listName = findSortedValue(selectedList?.[0], contactList);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response3 = await getRequest(navigate, campaignEndpoints.EspList);
        const espLists = response3?.data?.data?.map(function (item) {
          return {
            id: item?.esp,
            label: item.name,
            image: item.image,
            email: item.email ? item.email : "",
          };
        });
        setEspList(espLists);
        setSelectedEsp(espLists[0].id);
        setItemData(espLists[0]);
        setSendVia(espLists[0].label);

        setTimeZone([payload?.timezone || timezone_objChange[0]?.id]);
        if (espLists[0].id == 2) {
          handleMailApi(espLists[0].id, "gmail");
        } else if (espLists[0].id == 5) {
          handleMailApi(espLists[0].id, "outlook");
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();

    if (nextstep === 3) {
      setActiveStep(1);
    }
    if (nextstep === 5) {
      setActiveStep(2);
    }
    if (nextstep === 6) {
      setActiveStep(3);
    }
    if (nextstep === 7) {
      setActiveStep(4);
    }
  }, [navigate, nextstep]);

  useEffect(() => {
    (async () => {
      const signList = await getRequest(
        navigate,
        campaignEndpoints.campSignList
      );
      const signatureArray = signList?.data?.data?.map(function (item) {
        return {
          id: item?._id,
          signature: item.signature,
          label: item.email ? item.email : "",
        };
      });
      let addSelect = { id: "", label: STRINGS.DEF_SIGNATURE };
      setSignList([addSelect, ...signatureArray]);


      if (Object.keys(payload)?.length) {
        const signature_details = signatureArray?.filter(item => item?.id === payload?.other_signature_id);
        const signature_id = [signature_details?.id || ""];
        const signature_label = [signature_details?.label || STRINGS.DEF_SIGNATURE];
        setSignState(signature_id);
        setIdSignState(signature_label);
      }
    })();
  }, []);

  useEffect(() => {
    setOutState(outList?.length > 0 ? [outList[0].label] : []);
    setMailState(mailList?.length > 0 ? [mailList[0].label] : []);
  }, [mailList, outList]);

  const handleGetData = (newTimings) => {
    setGetTimings(newTimings);
    // console.log("NWTIMING",newTimings)
    const messageLen = newTimings?.filter((item) => item?.message?.length > 0);
    if (messageLen?.length > 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleEligibleHours = async () => {
    const date = new Date();
    const currentDate = `${date.getFullYear()}-${date.getMonth() + 1 <= 9 ? "0" : ""
      }${date.getMonth() + 1}-${date.getDate() <= 9 ? "0" : ""}${date.getDate()}`;
    const scheduledDate = currentDate;
    const val = createTimingsForPayload(getTimings);
    const dateArray = scheduledDate?.split("-");
    const offset = TIMEZONE_LIST.filter(
      (item) => item.id === timezone?.[0]
    )?.[0]?.offset;
    // const actualTimings = createTimingsForPayload(getTimings);
    const newScheduleDate = `${parseInt(dateArray[2])}-${parseInt(
      dateArray[1]
    )}-${parseInt(dateArray[0])}`;
    const emailVal = !(selectedEsp == 2 || selectedEsp == 5)
      ? itemData.email
      : selectedEsp == 2
        ? mailState[0]
        : outState[0];

    try {
      const payload = {
        timings: val,
        name: name,
        sequence_id: sequence?.[0] || "",
        list_id: selectedList?.[0] || "",
        // this date is not right...
        scheduled_date: newScheduleDate,
        from_email: emailVal,
        timezone: timezone?.[0],
        offset,
        sender_esp: Number(selectedEsp),
      };
      setIsEligibleLoad(true);
      const response = await postRequest(
        navigate,
        campaignEndpoints.eligibleHours,
        payload
      );
      if (response?.status === 200) {
        setEditTiming(false);
      }
    } catch (err) {
      if (err?.status === 403) {
        EventEmitter.emit(
          EMITTER_SUCCESS,
          "We've extended the timeframe to ensure the success of the campaign."
        );
        setEditTiming(false);
      } else EventEmitter.emit(EMITTER_ERROR, err?.data?.message);
    } finally {
      setIsEligibleLoad(false);
    }
  };

  const handleMailApi = async (id, name) => {
    let label = name.toLowerCase();
    let mail_id = parseInt(id);
    if (mail_id === 2) {
      setOutState([]);
      const response = await getRequest(
        navigate,
        `${campaignEndpoints.mailList}?esp=${mail_id}`
      );
      // console.log("RFGT",response)
      const mailLists = response?.data?.data?.[label]?.map(function (
        item,
        ind
      ) {
        return {
          id: ind + 1,
          label: item.email,
        };
      });
      setMailList(mailLists);
    }
    if (mail_id === 5) {
      setMailState([]);
      const response = await getRequest(
        navigate,
        `${campaignEndpoints.mailList}?esp=${mail_id}`
      );
      const mailLists = response?.data?.data["outlook"]?.map(function (
        item,
        ind
      ) {
        return {
          id: ind + 1,
          label: item.email,
        };
      });
      setOutList(mailLists);
    }
  };

  const handleEditTimings = () => {
    setEditTiming(!editTiming);
  };

  const handleConnectMail = (type) => {
    if (type === 1) {
      handleGoogleAuth();
    } else {
      handleMicrosoftAuthInitialize();
    }
  };

  const searchedTimezoneList = (event) => {
    let all_timezone = [...TIMEZONE_LIST];

    if (event?.target?.value) {
      let filtered_timezone = all_timezone?.filter((item) =>
        item?.label
          ?.toLowerCase()
          ?.includes(event?.target?.value?.toLowerCase())
      );
      return setTimezoneList(filtered_timezone?.slice(0, 100));
    } else {
      return setTimezoneList(all_timezone);
    }
  };

  const step1 = (
    <Box>
      <div className={classes.div}>
        <TypographyInline
          size={"md"}
          color={GREY.PRIMARY}
          fontWeight={fontWeight.SEMIBOLD}
          label={STRINGS.CAMP_BASIC_DETAILS}
        />
        <TypographyInline
          size={"sm"}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.STEP15}
        />
      </div>
      <Box className={classes.containStep}>
        <div className={classes.subTitles}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.COMMON_NAME}
          />
        </div>

        <div className={classes.mTop}>
          <InputField
            value={name}
            placeholder={STRINGS.ENTER_CAMP_NAME}
            size={"sm36"}
            onChange={(e) => setName(e.target.value)}
          />
          {validations?.name && (
            <ErrorMessage message={validations?.name || ""} />
          )}
        </div>
        <Box className={classes.containEachInfoo}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.SENDVIA}
          />

          {loading ? (
            <EspSkeleton />
          ) : espList?.length ? (
            <ul
              className="ul1"
              id="modal_body"
              style={{ paddingBottom: "12px" }}
            >
              {espList?.map((item) => {
                return (
                  <li className={classes.sendViaContainer}>
                    <input
                      type="checkbox"
                      style={{ display: "none" }}
                      id={`${STRINGS.ESP}${item.id}`}
                      className="input"
                      checked={selectedEsp === item?.id}
                      value={item?.label}
                      name={item.id}
                      onClick={(e) => {
                        setSelectedEsp(item?.id);
                        setItemData(item);
                        setSendVia(item?.label);
                        handleMailApi(item?.id, item?.label);
                      }}
                      readOnly
                    />
                    <label
                      className="label"
                      style={{
                        padding:
                          item.label === STRINGS.CONNECT_GOOGLE
                            ? "22px 8px"
                            : ".729 .833vw",
                        textAlign: "center",
                      }}
                      for={`${STRINGS.ESP}${item.id}`}
                    >
                      <Image
                        width={
                          item.label === STRINGS.CONNECT_GOOGLE
                            ? "89px"
                            : "100px"
                        }
                        height={
                          item.label === STRINGS.CONNECT_GOOGLE
                            ? "28px"
                            : "70px"
                        }
                        src={item?.image}
                        alt={STRINGS.ESP}
                      />
                    </label>
                  </li>
                );
              })}
            </ul>
          ) : (
            <Box className={classes.containNavigationBtns}>
              <Box className={classes.bodyContainerEsp}>
                {espConnectionList?.map((val) => {
                  return (
                    <button
                      className={classes.socialAuthBtn}
                      onClick={() => handleConnectMail(val?.id)}
                    >
                      <Image
                        src={val.icon}
                        className={classes.socialAuthIcon}
                      />
                      {val.label}
                    </button>
                  );
                })}
              </Box>
            </Box>
          )}
          {espList?.length ? (
            <Box className={classes.segementList}>
              {espList?.map((item) => {
                if (
                  (selectedEsp == 2 || selectedEsp == 5) &&
                  item?.id == selectedEsp
                ) {
                  return (
                    <Box className={classes.mailStyle}>
                      <TypographyInline
                        size={"xs"}
                        color={GREY.QUATINARY}
                        fontWeight={fontWeight.MEDIUM}
                        label={`${STRINGS.SEND_CAMPAIGN_WITH}`}
                      />
                      <AutoCompleteCustom
                        LeftIcon=""
                        valueState={item?.id == 5 ? [outState] : [mailState]}
                        setValueState={
                          item?.id == 5 ? setOutState : setMailState
                        }
                        idState={item?.id == 5 ? idOutState : idMailState}
                        setIdState={
                          item?.id == 5 ? setIdOutState : setIdMailState
                        }
                        items={item?.id == 5 ? outList : mailList}
                        papermenuclass={classes.paperMenu}
                        size={"md40"}
                      />
                    </Box>
                  );
                } else if (item?.id == selectedEsp) {
                  return (
                    <Box className={classes.mailStyle}>
                      <TypographyInline
                        size={"xs"}
                        color={GREY.QUATINARY}
                        fontWeight={fontWeight.MEDIUM}
                        label={`${STRINGS.SEND_CAMPAIGN_WITH}`}
                      />
                      <Box className={classes.previewStepDetailSubContainer1}>
                        <div>
                          <TypographyInline
                            size={"sm"}
                            color={GREY.DISABLED}
                            fontWeight={fontWeight.REGULAR}
                            label={itemData.email}
                          />
                        </div>
                      </Box>
                    </Box>
                  );
                }
              })}

              <Box className={classes.mailStyle}>
                <TypographyInline
                  size={"xs"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={`${STRINGS.SELECT_SIGNATURE}`}
                />
                <AutoCompleteCustom
                  LeftIcon=""
                  valueState={signState}
                  setValueState={setSignState}
                  idState={idSignState}
                  setIdState={setIdSignState}
                  items={signList}
                  papermenuclass={classes.paperMenu}
                  size={"md40"}
                />
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );

  const step2 = (
    <Box>
      <div className={classes.div}>
        <TypographyInline
          size={"md"}
          color={GREY.PRIMARY}
          fontWeight={fontWeight.SEMIBOLD}
          label={STRINGS.SELECT_SEQ_LIST}
        />
        <TypographyInline
          size={"sm"}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.STEP25}
        />
      </div>
      <Box className={classes.containStep}>
        <div className={classes.stepTwoLabelSequence}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.SEQ_TOBE_USED}
          />
        </div>
        <Box className={classes.mTop}>
          <AutoCompleteCustom
            items={sequenceList}
            LeftIcon=""
            valueState={[sequenceLabel]}
            setValueState={setSequenceLabel}
            idState={[sequence]}
            setIdState={setSequence}
            papermenuclass={classes.paperMenu}
            size={"sm36"}
          />
        </Box>

        <Box>
          <div className={classes.stepTwoLabelLists}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.LIST_TOBE_USED}
            />
          </div>

          <Box className={classes.mTop}>
            <AutoCompleteCustom
              items={contactList}
              LeftIcon=""
              valueState={[listLabel]}
              setValueState={setListLabel}
              idState={[selectedList]}
              setIdState={setSelectedList}
              papermenuclass={classes.paperMenu}
              size={"sm36"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const step3 = (
    <Box>
      {blockedList?.length ? (
        <Box>
          <div className={classes.div}>
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.SKIPPED_CONTACTS}
            />
            <TypographyInline
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.STEP35}
            />
          </div>
          <Box className={classes.containStep}>
            <SkippedContact
              heads={BLOCKED_CONTACTS_LIST}
              data={blockedList}
              pagination={false}
              tableContainerHeight={"100%"}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <div className={classes.div}>
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.SKIPPED_CONTACTS}
            />
            <TypographyInline
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.STEP35}
            />
          </div>
          <div className={classes.skipContactText}>
            <TypographyInline
              size={"sm"}
              color={BRAND.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.NO_CONTACT}
            />
          </div>
        </Box>
      )}
    </Box>
  );

  const step4 = (
    <Box>
      <div className={classes.div}>
        <TypographyInline
          size={"md"}
          color={GREY.PRIMARY}
          fontWeight={fontWeight.SEMIBOLD}
          label={STRINGS.TIMINGS}
        />
        <TypographyInline
          size={"sm"}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.STEP45}
        />
      </div>
      <Box className={classes.containStep}>
        <Box className={classes.timingBodyContainer}>
          <Box className={classes.widthStyle}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.TIMEZONE}
            />
            <AutoCompleteCustom
              items={timezoneList}
              valueState={timezone}
              idState={timezone}
              setIdState={setTimeZone}
              papermenuclass={classes.paperMenu}
              size={"sm36"}
              isSearchEnable={true}
              handleSearch={searchedTimezoneList}
            />
          </Box>
          <Box className={classes.widthStyle}>
            <Box className={classes.flexJustifyBetween}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.SCHEDULE_DATE}
              />
            </Box>

            <Box className={classes.bgcol}>
              <CustomDatePicker
                minDate={new Date(getTimeStampForTimeZone(timezone))}
                value={scheduleDate}
                onChange={(newValue) => {
                  setScheduleDate(newValue?.$d?.toString());
                }}
                size={"sm36"}
              />
            </Box>
          </Box>
        </Box>

        {editTiming ? (
          <Box>
            <TimeScheduler
              payload={getTimings}
              getData={(e) => handleGetData(e)}
            />
            <Box className={classes.editScheduleButtonContainer2}>
              <div>
                <Button
                  label={STRINGS.RESET}
                  size={"sm36"}
                  variant={"ghost"}
                  onClick={() => {
                    setGetTimings([...initialTimings]);
                  }}
                  LeftIcon={ICON_LABELS.RESTART_ALT}
                />
              </div>
              <div className={classes.padLeft}>
                <Button
                  LeftIcon={ICON_LABELS.SAVE}
                  label={STRINGS.SAVE}
                  size={"sm36"}
                  variant={"primary"}
                  disabled={error}
                  onClick={handleEligibleHours}
                  isLoading={isEligibleLoad}
                />
              </div>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box className={classes.WeekDays}>
              {CAMPAIGN_CREATE_DAYS_LIST?.map((eachDay, index) => {
                return (
                  <Box
                    key={eachDay.id}
                    className={
                      !editTiming &&
                        !getTimings?.filter((timing) => timing.day === index)?.[0]
                          ?.checked
                        ? classes.containDates
                        : classes.containDates2
                    }
                  >
                    <Box className={classes.containEach}>
                      {getTimings?.filter((timing) => timing.day === index)?.[0]
                        ?.checked ? (
                        <TypographyInline
                          size={"sm"}
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.SEMIBOLD}
                          isCapitalise={true}
                          label={eachDay.day}
                        />
                      ) : null}
                    </Box>
                    <Box className={classes.containEach}>
                      <Box className={classes.containDropDown}>
                        {getTimings?.filter(
                          (timing) => timing.day === index
                        )?.[0]?.checked ? (
                          <Box>
                            <TypographyInline
                              size={"sm"}
                              color={GREY.SECONDARY}
                              fontWeight={fontWeight.MEDIUM}
                              label={`${getTimings?.[
                                getTimings?.findIndex(
                                  (obj) => obj.day === index
                                )
                              ]?.startTime?.toString()}:${getTimings?.[
                                getTimings?.findIndex(
                                  (obj) => obj.day === index
                                )
                              ]?.startMinute <= 9
                                ? "0"
                                : ""
                                }${getTimings?.[
                                  getTimings?.findIndex(
                                    (obj) => obj.day === index
                                  )
                                ]?.startMinute?.toString()}${getTimings?.[
                                  getTimings?.findIndex(
                                    (obj) => obj.day === index
                                  )
                                ]?.startType
                                }`}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                    <Box className={classes.containEach}>
                      <Box className={classes.containDropDown}>
                        {getTimings?.filter(
                          (timing) => timing.day === index
                        )?.[0]?.checked ? (
                          <Box className={classes.containDate}>
                            <TypographyInline
                              size={"sm"}
                              color={GREY.SECONDARY}
                              fontWeight={fontWeight.MEDIUM}
                              label={`${getTimings?.[
                                getTimings?.findIndex(
                                  (obj) => obj.day === index
                                )
                              ]?.endTime?.toString()}:${getTimings?.[
                                getTimings?.findIndex(
                                  (obj) => obj.day === index
                                )
                              ]?.endMinute <= 9
                                ? "0"
                                : ""
                                }${getTimings?.[
                                  getTimings?.findIndex(
                                    (obj) => obj.day === index
                                  )
                                ]?.endMinute?.toString()}
                            ${getTimings?.[
                                  getTimings?.findIndex(
                                    (obj) => obj.day === index
                                  )
                                ]?.endType
                                }`}
                            />
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <Box className={classes.editScheduleButtonContainer}>
              <div>
                <Button
                  label={STRINGS.EDIT}
                  size={"sm36"}
                  variant={"primary"}
                  onClick={handleEditTimings}
                />
              </div>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );

  const step5 = (
    <Box>
      <div className={classes.div}>
        <TypographyInline
          size={"md"}
          color={GREY.PRIMARY}
          fontWeight={fontWeight.SEMIBOLD}
          label={STRINGS.PREVIEW}
        />
        <TypographyInline
          size={"sm"}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.STEP55}
        />
      </div>
      <Box className={classes.previewMainContiner}>
        {/* Step1 Container */}
        <Box className={classes.previewStepMainContainer}>
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.STEP1}
          />
          <Box className={classes.previewStepDetailsContainer}>
            <Box className={classes.previewStepDetailSubContainer}>
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.CAM_NAME}
              />
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={name}
              />
            </Box>
            <Box className={classes.previewStepDetailSubContainer}>
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.SENVIA}
              />
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={sendVia}
              />
            </Box>
            <Box className={classes.previewStepDetailSubContainer}>
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.EMAIL}
              />
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={
                  !(selectedEsp == 2 || selectedEsp == 5)
                    ? itemData.email
                    : selectedEsp == 2
                      ? mailState[0]
                      : outState[0]
                }
              />
            </Box>
          </Box>
        </Box>
        {/* Step2 Container */}
        <Box className={classes.previewStepMainContainer}>
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.STEP2}
          />
          <Box className={classes.previewStepDetailsContainer}>
            <Box className={classes.previewStepDetailSubContainer}>
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.SEQUEN}
              />
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={sequenceName?.label}
              />
            </Box>
            <Box className={classes.previewStepDetailSubContainer}>
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.LIS}
              />
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={listName?.label}
              />
            </Box>
          </Box>
        </Box>
        {/* Step3 Container */}
        <Box className={classes.previewStepMainContainer}>
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.STEP3}
          />
          <Box className={classes.previewStepDetailsContainer}>
            <Box className={classes.previewStepDetailSubContainer}>
              <TypographyInline
                size={"sm"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.SKPCON}
              />
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={`${blockedList.length} ${blockedList.length > 1 ? STRINGS.CONTACTS : STRINGS.CONTACT
                  }`}
              />
            </Box>
          </Box>
        </Box>
        {/* Step4 Container */}
        <Box className={classes.previewStepMainContainer}>
          <div className={classes.previewStepLabel}>
            <TypographyInline
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.STEP4}
            />
          </div>
          <Box className={classes.previewStepDetailsMainContainer}>
            <Box className={classes.previewStepDetailsContainer1}>
              <Box className={classes.previewStepDetailSubContainer1}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.SCHDAT}
                />
                <TypographyInline
                  size={"sm"}
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={
                    (scheduleDate &&
                      millisecondsToDateStringWithoutNumber(scheduleDate)) ||
                    ""
                  }
                />
              </Box>
              <Box className={classes.previewStepDetailSubContainer1}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.TMZN}
                />
                <TypographyInline
                  size={"sm"}
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={convertData(timezone?.[0]) || ""}
                />
              </Box>
            </Box>
            <Box className={classes.previewStep4DetailsContainer}>
              <Box>
                <TypographyInline
                  size={"sm"}
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.DY}
                />
              </Box>
              <Box className={classes.previewStep4DetailLowerContainer}>
                {getTimings.map((weekDays, index) => {
                  return (
                    weekDays.checked && (
                      <Box>
                        <TypographyInline
                          size={"sm"}
                          color={GREY.PRIMARY}
                          fontWeight={fontWeight.MEDIUM}
                          isCapitalise={true}
                          label={CAMPAIGN_CREATE_DAYS_LIST[weekDays?.day]?.day}
                        />

                        <TypographyInline
                          size={"sm"}
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.MEDIUM}
                          label={`${getTimings[index].startTime}:${getTimings[index].startMinute
                            }${getTimings[index].startMinute < 10 ? "0" : ""}${getTimings[index].startType
                            } - ${getTimings[index].endTime}:${getTimings[index].endMinute
                            }${getTimings[index].endMinute < 10 ? "0" : ""}${getTimings[index].endType
                            }`}
                        />
                      </Box>
                    )
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const steps = [
    {
      label: STRINGS.CAMP_BASIC_DETAILS,
      component: step1,
      requiredFields: [name?.trim(), espList],
    },
    {
      label: STRINGS.SELECT_SEQ_LIST,
      component: step2,
      requiredFields: [sequence, selectedList],
    },
    {
      label: STRINGS.SKIPPED_CONTACTS,
      component: step3,
      requiredFields: [],
    },
    {
      label: STRINGS.TIMINGS,
      component: step4,
      requiredFields: [timezone],
    },
    {
      label: STRINGS.PREVIEW,
      component: step5,
      requiredFields: [scheduleDate],
    },
  ];

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEAD,
      label: STRINGS.CREATE_CAMPAIGN,
    },
    description: {
      id: STRINGS.HEAD,
      label: STRINGS.CAMPAIGN_DESCRIPTION,
    },
  };

  const modalFooterLeftData = (
    <div>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => {
          setOpen(false);
          setPayload(false);
        }}
      />
    </div>
  );

  const modalFooterRightData = (
    <div>
      <Box className={classes.campBtnStyle}>
        {activeStep === 0 ? (
          <Box></Box>
        ) : (
          <Box className={classes.backBtnContainer}>
            <Button
              label={STRINGS.BACK}
              variant={"ghost"}
              size={"sm36"}
              onClick={() => {
                setActiveStep(activeStep - 1);
                setEditTiming(false);
              }}
            />
          </Box>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            label={STRINGS.START_CAMP}
            size={"sm36"}
            variant={"primary"}
            onClick={() =>
              activeStep === steps.length - 1
                ? handleCreateCampaign(true)
                : handleStepAddition()
            }
            isLoading={creating}
          />
        ) : null}
        <>
          <Button
            label={
              activeStep === steps.length - 1
                ? STRINGS.SCHEDULE_CAMPAIGN
                : STRINGS.NEXT
            }
            size={"sm36"}
            variant={"primary"}
            onClick={() =>
              activeStep === steps.length - 1
                ? handleCreateCampaign()
                : handleStepAddition()
            }
            isLoading={creating1 || stepActivity}
            disabled={isNextStepDisabled() || editTiming || isEnableList}
          />
        </>
      </Box>
    </div>
  );

  const modalBodyData = (
    <div>
      <Box>
        <Stepper
          className={classes.heightStyle}
          activeStep={activeStep}
          orientation="vertical"
        >
          <div className={classes.stepper}>
            <Box className={classes.stepsContainer}>
              <div className={classes.stepperdiv}>
                {steps.map((step, index) => (
                  <div key={step.label} className={classes.marginDiv}>
                    <div className={classes.steplab}>
                      <div className={classes.steplabs}>
                        <TypographyInline
                          size={"sm"}
                          isEllipses={true}
                          color={
                            activeStep === index ? GREY.PRIMARY : GREY.SECONDARY
                          }
                          fontWeight={
                            activeStep === index
                              ? fontWeight.MEDIUM
                              : fontWeight.REGULAR
                          }
                          label={step.label}
                        />
                      </div>
                      <div className={classes.divv}>
                        <span>
                          <div>
                            {activeStep === index ? (
                              <Image
                                src={ACTIVE_IMG}
                                alt={STRINGS.SMALL_ACTIVE}
                                className={classes.MarginImage}
                              />
                            ) : activeStep > index ? (
                              <Image
                                src={DONE_IMG}
                                alt={STRINGS.SMALL_DONE}
                                className={classes.MarginImage}
                              />
                            ) : (
                              <Image
                                src={UNSELECTED_IMG}
                                alt={STRINGS.UNSEL}
                                className={classes.MarginImage}
                              />
                            )}
                          </div>
                        </span>
                        {!(steps.length - 1 === index) && (
                          <div className={classes.vertHt}></div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Box className={classes.containEachStep}>
                {steps?.[activeStep]?.component}
              </Box>
              {/* Active Component */}

              {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
              {/* Active Component */}
            </Box>
          </div>
        </Stepper>
      </Box>
    </div>
  );

  return (
    <CustomModal
      remove_padding={true}
      className={classes.modalConiner}
      open={open}
      close={() => {
        setOpen(false);
        setPayload(false);
      }}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
      width={"63vw"}
    />
  );
};

export default CreateCampaignModal;
