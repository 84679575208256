import React from "react";
import Image from "../../../../components/Image/Image";
import { Modal, Slide } from "@mui/material";
import classes from "./CrmComp.module.css";
import IconComponent from "../../../../components/Icon/IconComponent";
import { ICON_LABELS } from "../../../../utils/constants/UI";

const ImageViewModal = ({ setOpenModal, openModal, imageUrl }) => {
  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Slide
        in={openModal || false}
        direction="down"
        timeout={{ enter: 500, exit: 500 }}
      >
        <div className={classes.CenterView}>
          <div className={classes.CenterViewSubHead}>
            <div className={classes.iconStyle}>
              <IconComponent
                iconLabel={ICON_LABELS.CLOSE_BUTTON}
                onClick={() => setOpenModal(false)}
              />
            </div>
            <Image src={imageUrl} alt="" className={classes.ImageView} />
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default ImageViewModal;
