import { makeStyles } from "@mui/styles";
import {
  DASHBOARD_BG_IMAGE,
  WIDGET_CORNER,
} from "../../utils/constants/assets";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import { ACTIONS, BASE, BORDER, GREY } from "../../utils/constants/colors";
import { SHADOW, borderRadius } from "../../utils/constants/UI/uiConstants";
import { innerBottomBorder } from "../../utils/helpers";

export const Styles = makeStyles({
  dashboardContainer: {
    height: "100vh",
    overflow: "auto",
    // borderRadius: "12px",
    ...TABLE_SCROLL_BAR,
  },
  root: {
    fontFamily: "Inter",
    color: "#626B7E",
  },

  gridContainerBottomMargin: {
    marginBottom: "24px",
  },
  imageLblContainer: {
    background: "#FFFFFF",
    textAlign: "center",
    marginBottom: "8px",
  },
  container: {
    padding: "1rem",
    paddingBottom: "0",
    bgcolor: "background.default",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "calc(100% - 55px)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    background: "#EBEFF4",
  },
  gridcontainer: {
    background: `url('${DASHBOARD_BG_IMAGE}')`,
    paddingBottom: "260px",
    backgroundRepeat: "repeat-y",
    backgroundSize: "100% 260px",
    backgroundPosition: "1px -0.005%",
  },
  bgwhite: {
    background: "white",
    borderRadius: "6px",
  },
  layout: {
    position: "relative",
    "&:hover": {
      "& .react-grid-item": {
        cursor: "grab",
      },
    },
  },
  widget: {
    width: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    background: "#F7F9FB",
    height: "68px",
    padding: "0 15px",
    color: "#212833",
    // position: "fixed",
    top: "0",
    width: "100%",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  addwidgetbnt: {
    cursor: "pointer",
  },
  addwidgetbntDisable: {
    cursor: "not-allowed",
  },
  emptywrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  addbtn: {
    background: "#ffffff",
    color: "#000",
    padding: "10px 15px",
    borderRadius: "6px",
    fontSize: "14px",
    cursor: "pointer",
  },
  image: { maxWidth: "100%" },
  widgettitle: { color: "#212833", fontSize: "calc(16px,1.042vw,24px)" },
  flexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
    // marginLeft: "20px",
    // marginRight: "20px",
  },
  campaignTitle: {
    fontWeight: "500",
    fontSize: "20px",
    letterSpacing: "0.8px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#0f2f51",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontWeight: "500",
    fontSize: "18px",
    letterSpacing: "0.8px",
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#0f2f51",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  actionMenu: {
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "17px !important",
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    color: "#626B7E !important",
    alignSelf: "center !important",
  },
  createCampaignBtn: {
    background: "#ff4848",
    padding: "0.75rem 1rem",
    color: "white",
    cursor: "pointer",
    border: 0,
    borderRadius: "8px",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  cancelDashboardButton: {
    backgroundColor: "#FF4848",
    border: 0,
    borderRadius: "6px",
    fontWeight: "400",
    padding: "8px 12px 8px 12px",
    display: "flex",
    fontSize: "14px",
    margin: "18px 18px 18px 0px",
    lineHeight: "1.5",
    color: "#ffffff",
    cursor: "pointer",
    alignItems: "center",
    "&:hover": {
      background: "#d3dae3",
    },
    "&:hover .modal-btn": {
      background: "#d3dae3",
    },
  },

  button: {
    marginRight: "10px",
    backgroundColor: "white",
    border: 0,
    borderRadius: "6px",
    fontWeight: "400",
    padding: "8px 12px 8px 12px",
    display: "flex",
    fontSize: "14px",
    marginLeft: "2px",
    lineHeight: "1.5",
    color: "#626b7e",
    cursor: "pointer",
    marginBottom: "0.5rem",
    alignItems: "center",
    marginTop: "0.5rem",
    "&:hover": {
      background: "#d3dae3",
    },
    "&:hover .modal-btn": {
      background: "#d3dae3",
    },
  },
  disabledButton: {
    marginRight: "10px",
    backgroundColor: "#D3DAE3",
    border: 0,
    borderRadius: "6px",
    fontWeight: "400",
    padding: "8px 12px 8px 12px",
    display: "flex",
    fontSize: "14px",
    marginLeft: "2px",
    lineHeight: "1.5",
    color: "#626b7e",
    cursor: "no-drop",
    marginBottom: "0.5rem",
    alignItems: "center",
    marginTop: "0.5rem",
  },
  button1: {
    backgroundColor: "white",
    border: 0,
    borderRadius: "6px",
    fontWeight: "400",
    padding: "8px 12px 8px 12px",
    display: "flex",
    fontSize: "14px",
    // marginLeft: "2px",
    lineHeight: "1.5",
    color: "#626b7e",
    cursor: "pointer",
    marginBottom: "0.5rem",
    alignItems: "center",
    marginTop: "0.5rem",
    "&:hover": {
      background: "#d3dae3",
    },
    "&:hover .modal-btn": {
      background: "#d3dae3",
    },
  },
  modalWidgetDetails: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "none",
    // width: "clamp(1000px, 75vw, 1398px)",
    // height: "85vh",
    borderRadius: "12px",
  },
  modalWidgetDetailsTable: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "none",
    width: "60vw",
    height: "90vh",
    borderRadius: "12px",
  },
  widgetDetailBody: {
    height: "calc(100% - 113px)",
  },
  containModalHead: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    background: "#D3DAE3",
    color: "#0F2F51",
    borderRadius: "12px 12px 0px 0px",
  },
  containModalBottom: {
    height: "calc(100% - 65px)",
    ...TABLE_SCROLL_BAR,
  },
  widgetBodyContainer: {
    height: "calc(100% - 81px)",
    overflow: "auto",
    ...TABLE_SCROLL_BAR,
  },
  containMain: {
    display: "flex",
    width:"100%",
    gap:"16px",
    // gridTemplateColumns: "1.5fr 1fr",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 16px 16px 16px",
  },
  // containMain: {
  //   display: "grid",
  //   gridTemplateColumns: "1.5fr 1fr",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   padding: "16px",
  // },
  containMainTable: {
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px",
    width: "100%",
    height: "100%",
  },
  containBtns: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem 1.5rem 1rem 1rem",
    borderTop: "1px solid #D3DAE3",
  },
  containWidgetBtns: {
    position: "relative",
    // background: "#FFFFFF",
    width: "100%",
    bottom: "0px",
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px",
    borderTop: "1px solid #D3DAE3",
  },
  containBox: {
    padding:"0px 16px",
    display: "flex",
    width:"100%",
    // margin:"auto"
    justifyContent: "center",
    // alignItems: "center",
    // position: "relative",
  },
  tableWidth: {
    width: "100%",
  },
  warningWidgetTitle: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    marginBottom: "4px",
    // marginLeft: "28px",
  },
  rangeWidgetTitle: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    margin: "0px 0px 7px 0px",
  },
  createWidgetListTitle: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    textTransform: "capitalize",
    // marginLeft: "1rem",
  },
  createListTitle: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
  },
  selectionBox: {
    border: "1px solid #D3DAE3",
    width: "298px",
    height: "45px",
    // marginLeft: "28px",
    marginTop: "10px",
    padding: "0.5rem 0rem 0.5rem",
    borderRadius: "6px",
    color: "#626B7E !important",
    "& .MuiSelect-select": {
      paddingLeft: "10px",
    },
  },
  selectionBoxAddWidget: {
    border: "1px solid #D3DAE3",
    width: "100%",
    height: "45px",
    // marginLeft: "28px",
    marginTop: "10px",
    padding: "0.5rem 0rem 0.5rem",
    borderRadius: "6px",
    color: "#626B7E !important",
    "& .MuiSelect-select": {
      paddingLeft: "10px",
    },
  },
  dropDownIcon: {
    position: "absolute",
    right: "5px",
    pointerEvents: "none",
    color: "#626B7E",
  },
  selectionCustomBox: {
    width: "150px",
    // marginLeft: "36px",
    // marginTop: "5px",
    borderRadius: "4px",
  },
  selectionCustomBox2: {
    width: "150px",
    // marginLeft: "10px",
    // marginTop: "5px",
    borderRadius: "4px",
  },
  closeBtn: {
    background: "white",
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "13px",
    border: "1px solid #D3DAE3",
    padding: "0.7rem 1.5rem",
    borderRadius: "8px",
    cursor: "pointer",
    height: "40px",
    alignSelf: "center",
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },
  },
  redBtn: {
    cursor: "pointer",
    background: "#FF4848",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "0.5rem 1.2rem",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  loaderButton: {
    cursor: "pointer",
    background: "#FF4848",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "5px 15.6px",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  setDefaultLoaderButton: {
    cursor: "pointer",
    background: "#FF4848",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "5px 44.33px",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  removeLoaderButton: {
    cursor: "pointer",
    background: "#FF4848",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "5px 23.15px",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  cloneLoaderButton: {
    cursor: "pointer",
    background: "#FF4848",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "5px 34.46px",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  updateLoaderButton: {
    cursor: "pointer",
    background: "#FF4848",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "6px 21px",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  widgetDetailLoader: {
    cursor: "pointer",
    background: "#FF4848",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "4.5px 37.89px",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  widgetDetailCloseLoader: {
    cursor: "pointer",
    background: "#FF4848",
    color: "#FFFFFF",
    fontSize: "14px",
    padding: "4.5px 74.12px",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  statBox: {
    display:"grid",
    gridTemplateColumns:"1fr 1fr",
    rowGap:"12px",
    columnGap:"7px",
    backgroundColor: "#F8F8F8",
    padding: "2.083vw",
    height: "20vw",
    borderRadius: "12px",
    overflow: "auto",
    ...TABLE_SCROLL_BAR,
  },
  widthSt:{
    width:"45%"
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "clamp(550px, 30vw, 650px)",
    maxHeight: "40vw",
    borderRadius: "12px",
    ...TABLE_SCROLL_BAR,
  },
  cloneModal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "none",
    width: "clamp(550px, 30vw, 650px)",
    maxHeight: "85vh",
    borderRadius: "12px",
  },
  icon: {
    color: "#626B7E",
    fontSize: "25px",
    cursor: "pointer",
  },
  warningTitle: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    // padding: "0.5rem 0 0rem 0rem",
  },
  containInput: {
    padding: "0.5rem 0rem 0rem",
  },
  warningBox: {
    padding: "1rem 1.5rem 0rem 1.5rem",
  },
  warningRenameBox: {
    padding: "1rem 20px 1rem 20px",
  },
  headerText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#626B7E",
    padding: "1rem 0rem 2rem 0rem",
  },
  headerCloneText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#626B7E",
    padding: "0.5rem 0rem 0.5rem 0rem",
  },
  radioButtonLabel: {
    alignSelf: "center",
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "14px",
    padding: "0.5rem 1rem 0.5rem 0.25rem",
  },
  containRadioBtns: {
    padding: "0.5rem 0 0.5rem 0.5rem",
    display: "flex",
  },
  eachRadioContainer: {
    paddingRight: "1.5rem",
  },
  everyoneRadioContainer: {
    paddingRight: "1.5rem",
    marginLeft: "25px",
  },
  rightToolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    cursor: "pointer",
  },
  leftToolbar: {
    // marginLeft: 24,
    marginRight: -12,
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "capitalize",
  },
  WidgetBox: {
    background: "white",
    // borderRadius: "6px",
    width: "100%",
    height: "100%",
    boxSizing: "content-box",
    border: "1px solid #FFFFFF",
    // cursor: "grab",
    "&:hover": {
      border: "1px solid #FF4848",
      boxShadow: "0px 5px 6px #E7E7E7",
      // cursor: "grab",
    },
    "&:hover:after": {
      bottom: "0.75vw",
      content: `url('${WIDGET_CORNER}')`,
      height: "7px",
      position: "absolute",
      right: "0.7vw",
      width: "7px",
    },
  },
  topBox: {
    display: "flex",
    justifyContent: "left",
    marginLeft: "24px",
    marginRight: "32px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "12px",
    alignItems: "center",
    paddingBottom: "15px",
    overflowX: "scroll",
    cursor: "pointer",
    ...TABLE_SCROLL_BAR,
  },
  bottomBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 2.5rem 0 2rem",
  },
  bottomTableBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 1.5rem 1.5rem 1.5rem",
  },
  svgContainer: {
    position: "relative",
    left: "0px",
    right: "0px",
    top: "0px",
    bottom: "0px",
    margin: "0px",
  },
  toolBarNumber: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#212833",
    marginLeft: "2px",
  },
  toolBarNumber1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#212833",
    marginLeft: "1.5vw",
  },
  toolBarNames: {
    display: "flex",
    justifyContent: "flex-start",
    marginRight: "30px",
    marginBottom: "5px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#626B7E",
    whiteSpace: "nowrap",
    alignItems: "center",
    gap: "4px",
  },
  widgetToolBar11: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  widgetDetailHeader: {
    padding: "16px",
    borderBottom: "1px solid #D3DAE3",

  },
  widgetToolTip: {
    background: "#FFFFFF",
    border: "1px solid #D3DAE3",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "6px",
  },
  widgetToolTipTop: {
    padding: "10px",
    backgroundColor: "#FFFFFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    display: "flex",
    alignItems: "center",
    color: "#212833",
  },
  widgetToolTipTopVBar1: {
    padding: "10px 10px 3px 10px",
    backgroundColor: "#F7F9FB",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    // display: "flex",
    color: "#212833",
  },
  widgetToolTipTopVBar2: {
    padding: "0px 10px 10px 10px",
    backgroundColor: "#F7F9FB",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    // display: "flex",
    color: "#626B7E",
  },
  widgetToolTipBottom: {
    padding: "10px",
    backgroundColor: "#F7F9FB",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#0F2F51",
  },
  widgetToolTipBottomVBar: {
    paddingBottom: "10px",
    backgroundColor: "#FFFFFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#212833",
  },
  widgetToolTipCircle: {
    margin: "0.2rem 7px 0.2rem 0",
    height: "8px",
    width: "8px",
    borderRadius: "50%",
  },
  widgetToolTipContent: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 10px 0px 10px",
  },
  widgetToolTipContent2: {
    display: "flex",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#212833",
  },
  widgetToolTipContent3: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#626B7E",
  },
  widgetToolTipContent4: {
    display: "flex",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#212833",
  },
  menuBox: {
    width: "29ch",
    boxShadow: "0px 14px 49px rgba(0, 0, 0, 0.14) !important",
    marginTop: "10px",
  },
  createDashboardSubContainerLabel: {
    padding: "20px 20px 5px 20px",
  },
  createDashboardSubContainerLowerLabel: {
    padding: "5px 20px 10px 20px",
  },
  lowerContainer: {
    marginTop: "16px",
    overflow: "auto",
    ...TABLE_SCROLL_BAR,
  },

  //Skeleton style
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    boxShadow: innerBottomBorder(BORDER.LIGHT),
    backgroundColor: BASE.WHITE,
    height: "64px",
  },

  widthHeader: {
    width: "100px",
  },
  dataHeader: {
    width: "100px",
    padding: "6px 20px",
    background: "#FFFFFF",
    borderRadius: "6px",
  },
  dataHeader1: {
    width: "100px",
    padding: "6px 20px",
    background: "#FFFFFF",
    borderRadius: "6px",
  },
  widgetContain:{
    boxShadow:SHADOW.md,
  },
  dataHeader2:{
    width:"100px",
    padding:"6px 20px",
    background:"#FFFFFF",
    borderRadius:"6px"
  },
  dataHeader3: {
    width: "150px",
    padding: "6px 20px",
    background: "#FFFFFF",
    borderRadius: "6px",
  },
  dataHeader4: {
    width: "150px",
    padding: "6px 20px",
    background: "#FFFFFF",
    borderRadius: "6px",
  },
  widthHeader1: {
    width: "120px",
  },
  widthHeader2: {
    width: "100px",
  },
  widthHeader3: {
    width: "200px",
  },
  widthHeader18: {
    width: "18px",
  },
  widthHeader15: {
    width: "15px",
  },
  widthHeader8: {
    width: "8px",
  },
  widthRightHeader: {
    width: "56px",
  },
  widthRightHeader1: {
    width: "32px",
  },
  skeletonMarginTop: {
    marginTop: "16px",
  },

  heightSkeletonContainer: {
    padding: "16px",
  },
  skeletonDisplay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  skeletonDisplay1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  skeletonRightDisplay: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  skeletonRightDisplay1: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  skeletonBodyStlye: {
    width: "100%",
    marginTop: "22px",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    alignItems: "center",
    gap: "10px",
  },
  skeletonWithinBody: {
    backgroundColor: "#FFFFFF",
    padding: "16px",
    width: "98.5%",
    height: "450px",
  },
  contentSkeletonStyle: {
    marginTop: "70px",
    display: "flex",
    flexDirection: "column",
    gap: "22px",
  },
  contentSkeletonStyle1: {
    margin: "50px 0 50px 50px",
    display: "flex",
    // flexDirection:"column",
    gap: "60px",
  },
  widthHeader500: {
    width: "500px",
  },
  widthHeader5: {
    width: "5px",
  },

  //Skeleton style

  dashBoardMainImage: {
    height: "31.67vh%",
    width: "25.573vw",
  },
  menuListRoot: {
    zIndex: "9999999!important",
  },
  drodownContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "16px",
    alignItems: "center",
    justifyContent:"flex-start",
  },
  addWidgetTitle: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#212833",
    padding: "16px",
  },
  addWidgetBody: {},
  loaderStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "20px",
  },
  widgetName: {
    textTransform: "capitalize",
  },
  btnnext: {
    background: "none",
    border: "0",
    color: "#ff4848",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "no-drop",
    opacity: "0.5",
  },
  dashboardListSearch: {
    padding: "4px 8px",
    borderRadius: "6px",
    border: "1px solid #D3DAE3",
    width: "100%",
  },
  dashboardListMenu: {
    ...TABLE_SCROLL_BAR,
    width: "29ch",
    boxShadow: "0px 14px 49px rgba(0, 0, 0, 0.14)",
  },
  dashAfterAction: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  saveDashboardButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: "16px",
  },
  dashboardOwnerNotSame: {
    height: "calc(100vh - 110px)",
  },
  dashboardOwnerSame: {
    height: "calc(100vh - 164px)",
  },
  dashboardLayoutSaved: {
    height: "calc(100vh - 91px)",
  },
  dashobardOperationsContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0rem 1rem",
  },
  subContainer: {
    height: "100%",
  },
  screenLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "calc(100vw - 8rem)",
  },
  gridMainContainer: {
    height: "100%",
  },
  addWidgetBodyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    padding: "0px 0px 16px 0px",
  },
  selectWidgetLabel: {
    padding: "0px 0px 16px 0px",
  },
  modalBodyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  permissionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  footerRightData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    marginLeft: "auto",
  },
  modalFieldLabelContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  gridItemHeight: {
    height: "100%",
  },
  widgetTypeBox: {
    height: "calc(100% - 64px)",
  },
  pieChartImage: {
    paddingLeft: "20px",
  },
  datePickerContainer: {
    display: "flex",
    paddingTop: "10px",
    columnGap: "10px",
  },
  listDropdown: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  widgetTopBtn: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    paddingTop: "24px",
  },
  widgetDetailFilter: {
    width: "18.073vw",
  },
  customFilter:{
    height: "30vh",
    width: "32.042vw",
    marginTop: "3px",
    overflowY: "auto",
    // ...SCROLL_BAR,
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track ": {
      innerHeight: "5px",
      outerHeight: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: GREY.DISABLED,
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      backgroundColor: GREY.QUATINARY,
    },
    "@media (max-width:1600px)": {
      width:"30.042vw",
    },
  },

  widgetToolBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding:"24px",
    textTransform: "capitalize",
  },
  toolBarDisabledIcon: {
    cursor: "not-allowed",
  },
  toolbarIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    padding: "3px 6px",
    borderRadius: borderRadius.primary,
    "&:hover": {
      backgroundColor: ACTIONS.DISABLED,
    },
  },
  topBoxMainContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    margin: "2px",
  },
  topBoxMarginTotalEnrolled: {
    margin: "0vw 5vw 0vw 6vw",
  },
  topBoxMargin: {
    margin: "0vw 0vw 0vw 0vw",
  },
  statsValue: {
    paddingLeft: "2px",
  },
  topBoxContainer: {
    width: "6vw",
  },
  emptyDashboardContainer: {
    height: "calc(100vh - 64px)",
  },
  imageContentContainer: {
    display: "flex",
    flexDirection: "column",
    // paddingTop: "70px",
    gap: "32px",
    alignItems: "center",
  },
  emptyDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyDashboardDescription: {
    width: "100%",
  },
  buttonContainer: {
    width: "60%",
  },
  buttonContainer1: {
    width: "50%",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems:"center",
    // gap: "16px",
  },
  emptyWidgetCotnainer: {
    height: "calc(100vh - 150px)",
  },
  paddingTopper16:{
    paddingTop:"16px"
  },
  paddingTopper4:{
    paddingTop:"4px"
  },
  bodyShareContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  flexShare: {
    display: "flex",
    gap: "6px",
  },
  paddingShare: {
    paddingTop: "6px",
  },
  borderLine: {
    margin: "14px 0 6px 0",
    borderBottom: "1px solid #EFEFEF",
  },
  paperMenuClass: {
    boxShadow: `${SHADOW.tags} !important`,
    width:"15vw"
  },
  // paperMenuClass1: {
  //   boxShadow: `${SHADOW.tags} !important`,
  //   width:"15vw"
  // },
});
