export const STRINGS = {
    UNSUBSCRIBE: "Unsubscribe",
    MARK_AS_LEAD: "Mark as lead",
    SKIP_CONTACT: "skipcontact",
    SMALL_REPEAT: "repeat",
    ONE_FR: "1fr",
    ALPHANUMERIC: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
    SMALL_DATE: "date",
    DATE: "Date",
    STATS_DATA: "stats_data",
    SMALL_ACTION: "action",
    ACTION: "Action",
    SMALL_REPLY: "reply",
    STEP_ID: "step_id",
    CAMPAIGN_ID: "campaign_id",
    VIEW_EMAIL: "View Email",
    SMALL_EMAIL: "email",
    VIEW_REPLY: "View Reply",
    STEP: "Step",
    COPYOF: "Copy of",
    GMAIL: "Gmail",
    TIMINGS: "Timings",
    SELECT_SEQ_LIST: "Select Sequence and List",
    STEP15: "Step 1 / 5",
    STEP25: "Step 2 / 5",
    STEP35: "Step 3 / 5",
    STEP45: "Step 4 / 5",
    STEP55: "Step 5 / 5",
    COMMON_NAME: "Name*",
    ENTER_CAMP_NAME: "Enter Campaign Name",
    SENDVIA: "Send Via*",
    ESP: "esp",
    MAILGUN: "Mailgun",
    CONNECT_GOOGLE: "Connect Google",
    SMALL_FOLDER_NAME: "foldername",
    SMALL_EDITOR: "editor",
    HEAD: "head",
    DESC: "desc",
    SMALL_ACTIVE: "active",
    SMALL_DONE: "done",
    UNSEL: "unsel",
    SEQ_TOBE_USED: "Sequence to be used",
    LIST_TOBE_USED: "List to be used",
    SKIPPED_CONTACTS: "Skipped Contacts",
    NO_CONTACT: "No Contacts Skipped from selected list.",
    SCHEDULE_DATE: "Schedule Date",
    TIMEZONE: "Time Zone",
    DAYS: "Days",
    START_TIME: "Start Time",
    END_TIME: "End Time",
    EDIT: "Edit",
    PREVIEW: "Preview",
    STEP1: "Step 1:",
    CAM_NAME: "Campaign Name:",
    SENVIA: "Send Via:",
    STEP2: "Step 2:",
    SEQUEN: "Sequence:",
    LIS: "List:",
    STEP3: "Step 3:",
    SKPCON: "Skipped Contacts:",
    STEP4: "Step 4:",
    SCHDAT: "Schedule Date:",
    TMZN: "Time Zone:",
    DY: "Days:",
    CONTACTS: "Contacts",
    CONTACT: "Contact",
    CAMP_BASIC_DETAILS: "Campaign Basic Details",
    CREATE_CAMPAIGN: "Create Campaign",
    CAMPAIGN_DESCRIPTION: "Follow 5 simple Steps to create your campaign",
    CANCEL: "Cancel",
    SCHEDULE_CAMPAIGN: "Schedule Campaign",
    NEXT: "Next",
    BACK: "Back",
    DEL: "del",
    SMALL_FILE: "file",
    FOLDEL: "foldDel",
    SMALL_FOLDER: "folder",
    CMFOL: "campaignfolder",
    SQFOL: "sequencefolder",
    BULK_DEL_TYPE: "foldListDel",
    DLSQ: "delSeq",
    CMBULKDEL: "campaignBulkDelete",
    LISTID: "_name",
    DELETE_SELECTED: "Delete",
    DELETE_BULK: "Are you sure you want to delete the selected",
    DELETE: "Delete",
    TEAM_ID: "team",
    PRIVATE_ID: "private",
    TEAM_LABEL: "My Team",
    PRIVATE_LABEL: "Private",
    TYPE_TEMP: "template",
    TEMPLATES: "Templates",
    ID: "_id",
    SMALL_CREATE: "create",
    EDIT_TEMPLATE: "Edit Email Template",
    CREATE_EMAIL_TEMPLATE: "Create Email Template",
    SIGNATURE_DETAILS: "signature-details",
    CREATE_TEMP: "createtemplate",
    TYPETEMPLATE: "Type Template name ",
    OWNER: "Owner",
    TEMPLATE_OWNER: "templateowner",
    FOLDER: "Folder",
    SHARED_WITH: "Shared with*",
    SUBJECT1: "Subject*",
    SUBJECT2: "Subject:",
    FOLDER_SUB: "foldersubject",
    SMALL_SUBJECT: "subject",
    BODY: "Body",
    FOLDER_BODY: "folderbody",
    TEMPLATE_PREVIEW: "tempatepreview",
    TO: "To:",
    PRMLDATA: "previewMailBodyData",
    SMALL_UNDEFINED: "undefined",
    SEND_TEST_MAIL: "Send test email",
    TEMP_UPDATE: "Update Template",
    SAVE_TEMP: "Save Template",
    SAVE_TEMPLATE: "savetemplate",
    EMAIL_TEMPLATE: "Email Template",
    TEMPLATE_NAME1: "Template Name*",
    DEL_CONFIRM1: "Are you sure you want to Delete this Folder?",
    DEL_CONFIRM: "Are you sure you want to Delete this Template?",
    CLONE_SEQUENCE: "Clone Sequence",
    EDIT_SEQUENCE: "Edit Sequence",
    CREATE_SEQ: "Create Sequence",
    TYPESEQUENCE: "Type Sequence name",
    SHARE_FOLDER: "sharefolder",
    SMALL_CLONE: "clone",
    CLONE: "Clone",
    UPDATE: "Update",
    CREATE: "Create",
    FLODER_UPDATE: "floderupdate",
    TYPE_CAMP: "campaign",
    EDIT_FOLD: "Edit Folder",
    CREATE_FOLDER: "Create Folder",
    ENTER_FOLDER: "Enter Folder Name*",
    ENTER_FOLDER_PLACEHODER: "Type Folder Name",
    LIST_REMT: "listRemove",
    TEMP_REM: "templateRemove",
    REM_SEQ: "sequenceRemove",
    REM_CAM: "campaignRemove",
    REM_SELECTED: "Remove selected",
    REM_DATA: "Are you sure you want to remove",
    FROM_FOLD: "from Folder",
    REMOVE: "Remove",
    UPDATE_LIST: "Edit List",
    CREATE_LISST: "Create List",
    LISTNAME: "Contact List Name*",
    TYPELIST: "Type Contact List name",
    DELETE_CONFIRMATION: "Are you sure you want to Delete this",
    BODY2: "Body:",
    VIEW_MORE: "View more",
    VIEW_LESS: "View less",
    TITLE2: "Title:",
    NOTES2: "Notes:",
    CHOOSE_HEAD: "Choose a Step",
    CHOOSE_STEPS_TEXT:
        "Sequences are a series of automated or manual touchpoints and activities, designed to drive deeper engagement with your prospects.",
    ADD: "Add",
    SMALL_EDIT: "edit",
    USER_SEQ_STEPS: "/user/sequence/steps/",
    CALL: "Call",
    SMALL_STEP: "step",
    INTERVALS: "Interval (in days)*",
    CALL_VAL: "Call contact to follow up",
    NOTES: "Notes",
    SAVE: "Save",
    NEW_TEMPLATE: "New Template",
    RE2: "Re:",
    AUTOMATED_EMAIL: "Automated email",
    TEMPLATE: "Template",
    ENTER_SUBJECT: "Enter Subject",
    TEMPLATE_NAME: "Template name:",
    SAVE_NEW_TEMPLATE: "Save as a new Template",
    TODO: "To Do",
    TASK_TITLE: "Task Title*",
    SENDMAIL: "Send mail to myself",
    RESET: "Reset",
    START_CAMP: "Start Campaign Now",
    START_TYPE: "startType",
    TYPE_SEQ: "Sequence",
    END_TYPE: "endType",
    CONNECT_OUTLOOK: "Connect Outlook",
    AI_ASSISTANT: "AI Assistant",
    YOUR_OFFERINGS: "Your Offerings/Services",
    CHALLENGES_FACED: "Challenges faced by your audience",
    SOLUTIONS: "Solutions",
    GET_STARTED: "Get Started with below functions",
    CONTEXT_TO_ADD: "Context to add",
    YOUR_OFFERINGS1: "Enter your services you offer in your product.",
    CHALLENGES_FACED1: "List all the challenges that your target customer encounters.",
    SOLUTIONS1: "Describe the solutions you offer to address your customer's problems.",
    GET_STARTED1: "Get Started with below functions",
    CONTEXT_TO_ADD1: "Please provide additional context",
    RESPONSE: "Response",
    ADD_TO_TEMPLATE: "Add to Template",
    COPY_ANSWER: "Copy Answer",
    GENERATE_RESPONSE: "Generate Response",
    AI_RESPONSE: "AI Response",
    CREDITS: "Credits",
    JOB_DESCRIPTION: "Job description",
    MEETING_BOOK: "Booked Meetings",
    WEBSITE_URL: "Website url",
    COPIED: "Copied",
    INCLUDE_GMAIL_SIGN: "Inlcude Gmail Signature",
    REQUIRED_FIELD: "This is a required field",
    CREDITS_AVAILABLE: "Credits Available:",
    DELIVER_OPTIMAL: "We deliver optimal results within a matter of",
    SECONDS: "seconds.",
    REGENERATE: "Regenerate",
    ANSWER_THE_PROMPTS: "Answer the Prompts",
    GET_THE_BEST: "Get the best preview results by filling in several",
    INPUT_LEFT: "inputs on the left",
    WORDS300: "(300 words)",
    SEND_CAMPAIGN_WITH: "Send campaign with",
    GENERATING: "Generating",
    MEETING_URL: "Meeting URL",
    GENERATE: "Generate",
    CREDIT10: "10 credits will be deducted. Are you sure you want to regenerate response?",
    TEST_EMAIL: "Test email will be delivered from your default mailbox to ",
    SELECT_TEMPLATE: "Select Template",
    ADD_TO: "Add to",
    WARNING: "Warning",
    PLEASE_WARNING: "Time will increase",
    OK: "Ok",
    EXTENDED: "We've extended the timeframe to ensure the success of the campaign.",
    ERROR: "error",
    DEFAULT: "default",
    STEPS: "steps",
    TYPESUBJECT: "Enter subject",
    EMAIL: "Email:",
    TASK_TTL: "Task Title",
    REGEN_RESP: "Regenerate Response",
    REQ_CRED: "Request for more credits",
    SUCCESS_REQ: "Request sent successfully",
    DEFINE_TARGET: "Define your target audience",
    IDENTIFY_YOUR: "Identify your ideal audience – their demographics, interests, and needs.",
    OUTLINE_SOLUTIONS: "Outline the solutions",
    ADDRESS_CHALLENGES: "Address the challenges you aim to resolve for your potential customers.",
    CURRENT_PROMO: "Current Promotional Offers(optional)",
    DO_YOU_HAVE: "Do you have any ongoing promotional offers?",
    ADD_INFO: "Additional information",
    FEEL_FREE: "Feel free to share any additional context or details you find relevant.",
    SELECT_SIGNATURE: "Select signature",
    DEF_SIGNATURE: "default signature",
    MARKETING: "Marketing",
    SUBJECT_SMALL: "subject",
    VIEW_PROFILE: "View Profile",
    PERSONAL_INFORMATION: "Personal Information",
    NOTAPPLICABLE: "N/A",
    COMPANY_INFORMATION: "Company Information",
    COMPANY_WEBSITE: "Company Website",
    ANNUAL_REVENUE: "annual_revenue",
    TECHNOLOGIES: "Technologies",
    VIEW_ALL: "View All",
    SHOW_MORE: "Show more...",
    SHOW_LESS: "Show less...",
    MOBILE_NUMBER: "Mobile Number",
    DIRECT_PHONE_NUMBER: "direct_phone_number",
    CORPORATE_NUMBER: "Corporate Number",
    COMPANY_PHONE_NUMBER: "company_phone_number",
    COPIED_TEXT_CNTR: "copiedTextContainer",
    SENT_SMALL: "sent",
    OPEN_SMALL: "open",
    ADD_AS_THREAD: "Add as thread"
}