import React from "react";
import classes from "./CrmComp.module.css";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";

const ListSkeletonLoader = ({ length = 2 }) => {
  return (
    <div className={classes.listLoaderContainer}>
      <div >
        {Array.from({ length: length }).map((index) => {
          return (
            <div key={`contactListLogLoader${index}`} className={classes.paddingBottom} >
              <div className={classes.flexWithGap} >
                <div  >
                  <SkeletonLoader
                    type="circular"
                    skeletonWidth={40}
                    skeletonHeight={40}
                  />
                </div>
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"100%"}
                  skeletonHeight={"20px"}
                  stackWidth={"60%"}
                />
              </div>
              <div className={classes.loaderBg} >
                <div className={classes.listContactLoaderContainer} >
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={"20px"}
                    stackWidth={"60%"}
                  />
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={"20px"}
                    stackWidth={"60%"}
                  />

                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ListSkeletonLoader;
