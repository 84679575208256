import React from "react";
import { ACTIONS, BASE, BRAND, GREY, HELP } from "../../utils/constants/colors";
import { fontSize, fontWeight } from "../../utils/constants/UI";
import IconComponent from "../Icon/IconComponent";
import TypographyInline from "../Typography/TypographyInline";
import classes from "./FilterTab.module.css";

const FilterTab = ({
  leftIcon,
  rightIcon,
  label,
  selectedValue = 0,
  isActive,
  ...props
}) => {
  const styles = {
    mainContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: props?.size === "large" ? "10px" : "6px 10px",
      gap: "6px",
      backgroundColor: Boolean(selectedValue) ? HELP.W_50 : BASE.WHITE,
      borderRadius: "6px",
      cursor: props?.disabled ? "not-allowed" : "pointer",
      border: "none",
    },
    selectedMainContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: props?.size === "large" ? "10px" : "6px 10px",
      gap: "6px",
      backgroundColor: ACTIONS.ALTERNATE,
      borderRadius: "6px",
      cursor: props?.disabled ? "not-allowed" : "pointer",
      border: "none",
    },
    leftIconStyle: {
      fontSize: fontSize.MD,
      color: GREY.TERTIARY,
    },
    rightIconStyle: {
      fontSize: fontSize.MD,
      color: props?.disabled ? GREY.TERTIARY : GREY.SECONDARY,
    },
    selectedValueCOntainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 10px",
      backgroundColor: BRAND.SECONDARY,
      borderRadius: "14px",
    },
  };

  return (
    <button
      style={isActive ? styles.selectedMainContainer : styles.mainContainer}
      className={!selectedValue && classes.mainContainer}
      {...props}
    >
      {leftIcon && (
        <IconComponent
          iconLabel={leftIcon}
          id={classes.lefticon}
          style={styles.leftIconStyle}
        />
      )}
      {label && <TypographyInline
        id={classes.label}
        label={label}
        size="sm"
        color={props?.disabled ? GREY.TERTIARY : GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        isNoWrap
      />}
      {Boolean(selectedValue) ? (
        <div style={styles.selectedValueCOntainer}>
          <TypographyInline
            label={selectedValue}
            size="xs"
            color={BASE.WHITE}
            fontWeight={fontWeight.SEMIBOLD}
            isNoWrap
          />
        </div>
      ) : null}
      {rightIcon && (
        <IconComponent
          iconLabel={rightIcon}
          id={classes.righticon}
          style={styles.rightIconStyle}
        />
      )}
    </button>
  );
};

export default FilterTab;

// ------------- NOTE ----------------
// Props Description:
// leftIcon :- Icon displayed on left of the label.
// rightIcon :- Icon displayed on right of the label.
// label :- This is labe of filter tab.
// selectedValue :- This prop has count of selected value.
// size :- Pass size as large if you want more top bottom padding.
