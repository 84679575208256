import React, { useState } from "react";
import { Box } from "@mui/system";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { contactListEndPoints } from "../../../utils/constants/httpConstants";
// import { getErrorMessage } from "../../../utils/helpers";
import { filterQuery } from "../../../utils/helpers";
import { postRequest } from "../../../Apis";
import { tableDataFetch } from "../../../redux/actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { Button } from "../../../components/Buttons";
import classes from "./LeadsComponent.module.css";
import { paginationDataAction } from "../../../redux/actions/customTableActions";


const AddCrmConfirmationModal = ({
  open,
  setOpen,
  bulkSelected,
  record_ids,
  setcredits = () => { }
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paginationDataSelector = useSelector((state) => state.getPaginationQuery);
  const allFiltersSelector = useSelector((state) => state.allFilters);
  const tableEndpointChangeSelector = useSelector(
    (state) => state?.tableEndpointChange
  );

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };


  const handleCrm = async () => {
    setLoading(true);
    const recordIds = record_ids?.length ? record_ids : paginationDataSelector?.data?.checkedRow;
    const payload = bulkSelected
      ? {
        ...filterQuery(allFiltersSelector?.payload),
      }
      : {
        record_ids: recordIds,
      };
    const peopleURL = bulkSelected
      ? tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
        ? contactListEndPoints.bulkSavedSelectecCrmList
        : contactListEndPoints.bulkCrmList
      : tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
        ? contactListEndPoints.savedSelectecCrmList
        : contactListEndPoints.addSelectedCrmList;
    try {
      const response = await postRequest(navigate, peopleURL, payload)
      dispatch(paginationDataAction({}));
      dispatch(tableDataFetch({ ...allFiltersSelector?.payload || {} }, navigate));
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message)
      setOpen(!open)
    }
    catch (err) {
      if (err?.status === 404) {
        setcredits(true);
      } else {
        EventEmitter.emit(EMITTER_ERROR, err?.data?.message);
      }
    }
    finally {
      setOpen(!open);
      setLoading(false);

    }
  }

  const modalHeaderData = {
    heading: {
      id: STRINGS.ADD_TO_CRM,
      label: STRINGS.ADD_TO_CRM,
    },
  };

  const modalFooterRightData = (
    <Box className={classes.footerData}>
      <Button variant={"ghost"} size={"sm36"} onClick={handleClose} label={STRINGS.CANCEL} />
      <Button variant={"primary"} size={"sm36"} label={STRINGS.ADD} onClick={handleCrm} isLoading={loading} />
    </Box>
  )

  return (
    <CustomModal
      open={open}
      close={() => setOpen(!open)}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }} >
      <Box >
        <TypographyInline label={STRINGS.CRM_CONFIRMATION} color={GREY.SECONDARY} size={"sm"} />
      </Box>
    </CustomModal>
  );
};

export default AddCrmConfirmationModal;
