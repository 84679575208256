import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  BOX_CHART_IMAGE,
  HBAR_CHART_IMAGE,
  LINE_CHART_IMAGE,
  PIE_CHART_IMAGE,
  TABLE_CHART_IMAGE,
  VBAR_CHART_IMAGE,
} from "../../../utils/constants/assets";
import WidgetDetails from "./widgetDetails";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { STRINGS } from "../strings";
import Image from "../../../components/Image/Image";
import TypographyInline from "../../../components/Typography/TypographyInline";

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: theme.spacing(2),
  },
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
  },
  addWidgetTypes: {
    color: "#212833",
    fontStyle: "normal !important",
    fontSize: "clamp(12px ,0.729vw, 24px) !important",
    fontWeight: "600 !important",
    display: "flex",
    justifyContent: "left",
    marginTop: "5px !important",
    fontFamily: "Inter !important",
    lineHeight: "24px !important",
    letterSpacing: "0 !important",
    whiteSpace: "nowrap",
  },
  reportImage: {
    width: "13vw",
  },
  enabledWidget: {
    cursor: "pointer",
    filter: "none",
  },
  disabledWidget: {
    cursor: "no-drop",
    filter: "grayscale(1)",
  },
  rootGrid: {},
  imageLblContainer: {
    display:"flex",
    flexDirection:"column",
    gap:"8px"
  }
}));

const widgetNames = [
  {
    id: "campaign",
    label: "Campaign",
    name: "Line Chart",
    desc: "My prospect’s engagement on my recent campaigns this month.",
  },
  {
    id: "sequence",
    label: "Sequence",
    name: "Pie Chart",
    desc: "My prospect’s engagement on my recent sequences this month.",
  },
  {
    id: "activity",
    label: "Activity",
    name: "Bar Chart",
    desc: "Activity leaderboard of my team with type breakdown.",
  },
  {
    id: "task",
    label: "Task",
    name: "Vertical Chart",
    desc: "Daily tasks engagement with my prospects.",
  },
  {
    id: "unsubscribe",
    label: "Unsubscribe",
    name: "Table Chart",
    desc: "It is a long established fact that a reader will be distracted by the readable content.",
  },
  {
    id: "box",
    label: "Box",
    name: "Summary Chart",
    desc: "It is a long established fact that a reader will be distracted by the readable content.",
  },
];

export const AddList = ({
  onAddItem,
  close,
  report,
  dashboard,
  campaignList,
  sequenceList,
  widgetType,
  setWidgetType,
  dashboardstep,
  closeTour,
  complteTour,
}) => {
  const classes = useStyles();
  const [widgetDetailFlag, setWidgetDetailFlag] = useState(false);
  const [list,setList] = useState([])

  const handleDetail = () => {
    setWidgetDetailFlag(widgetDetailFlag && dashboardstep === 0 ? false : true);
  };

  const handleChange = (e) => {
    setWidgetType(e);
    handleDetail();
  };

  useEffect(()=>{
    if(report[0]=== STRINGS.LEADS_REPORT || report[0] === STRINGS.UNSUBSCRIBE_REPORT)
    {
      setList([
        {
        id: "table",
        label: STRINGS.TABLE_CHART,
        src: TABLE_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.UNSUBSCRIBE_REPORT ||
          report[0] === STRINGS.LEADS_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "line",
        label: STRINGS.LINE_CHART,
        src: LINE_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "pie",
        label: STRINGS.PIE_CHART,
        src: PIE_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "bar-horizontal",
        label: STRINGS.BAR_CHART,
        src: HBAR_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "bar-vertical",
        label: STRINGS.VERTICAL_CHART,
        src: VBAR_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT ||
          report[0] === STRINGS.ACTIVITY_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "box",
        label: STRINGS.SUMMARY_CHART,
        src: BOX_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT,
        onClick: (e) => handleChange(e.target.id),
      }])
    }
    else if(report[0] === STRINGS.ACTIVITY_REPORT){
      setList(
        [
        {
          id: "bar-vertical",
          label: STRINGS.VERTICAL_CHART,
          src: VBAR_CHART_IMAGE,
          isEnabled:
            report[0] === STRINGS.CAMPAIGN_REPORT ||
            report[0] === STRINGS.SEQUENCE_REPORT ||
            report[0] === STRINGS.ACTIVITY_REPORT,
          onClick: (e) => handleChange(e.target.id),
        },
        {
          id: "line",
          label: STRINGS.LINE_CHART,
          src: LINE_CHART_IMAGE,
          isEnabled:
            report[0] === STRINGS.CAMPAIGN_REPORT ||
            report[0] === STRINGS.SEQUENCE_REPORT,
          onClick: (e) => handleChange(e.target.id),
        },
        {
          id: "pie",
          label: STRINGS.PIE_CHART,
          src: PIE_CHART_IMAGE,
          isEnabled:
            report[0] === STRINGS.CAMPAIGN_REPORT ||
            report[0] === STRINGS.SEQUENCE_REPORT,
          onClick: (e) => handleChange(e.target.id),
        },
        {
          id: "bar-horizontal",
          label: STRINGS.BAR_CHART,
          src: HBAR_CHART_IMAGE,
          isEnabled:
            report[0] === STRINGS.CAMPAIGN_REPORT ||
            report[0] === STRINGS.SEQUENCE_REPORT,
          onClick: (e) => handleChange(e.target.id),
        },
        {
          id: "table",
          label: STRINGS.TABLE_CHART,
          src: TABLE_CHART_IMAGE,
          isEnabled:
            report[0] === STRINGS.UNSUBSCRIBE_REPORT ||
            report[0] === STRINGS.LEADS_REPORT,
          onClick: (e) => handleChange(e.target.id),
        },
        {
          id: "box",
          label: STRINGS.SUMMARY_CHART,
          src: BOX_CHART_IMAGE,
          isEnabled:
            report[0] === STRINGS.CAMPAIGN_REPORT ||
            report[0] === STRINGS.SEQUENCE_REPORT,
          onClick: (e) => handleChange(e.target.id),
        }]
      )
    }
    else{
       setList([
      {
        id: "line",
        label: STRINGS.LINE_CHART,
        src: LINE_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "pie",
        label: STRINGS.PIE_CHART,
        src: PIE_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "bar-horizontal",
        label: STRINGS.BAR_CHART,
        src: HBAR_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "bar-vertical",
        label: STRINGS.VERTICAL_CHART,
        src: VBAR_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT ||
          report[0] === STRINGS.ACTIVITY_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "box",
        label: STRINGS.SUMMARY_CHART,
        src: BOX_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.CAMPAIGN_REPORT ||
          report[0] === STRINGS.SEQUENCE_REPORT,
        onClick: (e) => handleChange(e.target.id),
      },
      {
        id: "table",
        label: STRINGS.TABLE_CHART,
        src: TABLE_CHART_IMAGE,
        isEnabled:
          report[0] === STRINGS.UNSUBSCRIBE_REPORT ||
          report[0] === STRINGS.LEADS_REPORT,
        onClick: (e) => handleChange(e.target.id),
      }
    ])  
    }
  },[report])

  useEffect(() => {
    if (dashboardstep > 6) {
      setWidgetDetailFlag(true);
    } else {
      setWidgetDetailFlag(false);
    }
  }, [dashboardstep]);

  // const WIDGET_TYPE_LIST = [
  //   {
  //     id: "line",
  //     label: STRINGS.LINE_CHART,
  //     src: LINE_CHART_IMAGE,
  //     isEnabled:
  //       report[0] === STRINGS.CAMPAIGN_REPORT ||
  //       report[0] === STRINGS.SEQUENCE_REPORT,
  //     onClick: (e) => handleChange(e.target.id),
  //   },
  //   {
  //     id: "pie",
  //     label: STRINGS.PIE_CHART,
  //     src: PIE_CHART_IMAGE,
  //     isEnabled:
  //       report[0] === STRINGS.CAMPAIGN_REPORT ||
  //       report[0] === STRINGS.SEQUENCE_REPORT,
  //     onClick: (e) => handleChange(e.target.id),
  //   },
  //   {
  //     id: "bar-horizontal",
  //     label: STRINGS.BAR_CHART,
  //     src: HBAR_CHART_IMAGE,
  //     isEnabled:
  //       report[0] === STRINGS.CAMPAIGN_REPORT ||
  //       report[0] === STRINGS.SEQUENCE_REPORT,
  //     onClick: (e) => handleChange(e.target.id),
  //   },
  //   {
  //     id: "bar-vertical",
  //     label: STRINGS.VERTICAL_CHART,
  //     src: VBAR_CHART_IMAGE,
  //     isEnabled:
  //       report[0] === STRINGS.CAMPAIGN_REPORT ||
  //       report[0] === STRINGS.SEQUENCE_REPORT ||
  //       report[0] === STRINGS.ACTIVITY_REPORT,
  //     onClick: (e) => handleChange(e.target.id),
  //   },
  //   {
  //     id: "table",
  //     label: STRINGS.TABLE_CHART,
  //     src: TABLE_CHART_IMAGE,
  //     isEnabled:
  //       report[0] === STRINGS.UNSUBSCRIBE_REPORT ||
  //       report[0] === STRINGS.LEADS_REPORT,
  //     onClick: (e) => handleChange(e.target.id),
  //   },
  //   {
  //     id: "box",
  //     label: STRINGS.SUMMARY_CHART,
  //     src: BOX_CHART_IMAGE,
  //     isEnabled:
  //       report[0] === STRINGS.CAMPAIGN_REPORT ||
  //       report[0] === STRINGS.SEQUENCE_REPORT,
  //     onClick: (e) => handleChange(e.target.id),
  //   },
  // ];
  return (
    <>
      <WidgetDetails
        ownerName={dashboard?.dashboard_name}
        report={report}
        campaignList={campaignList}
        sequenceList={sequenceList}
        widgetType={widgetType}
        handleDetail={handleDetail}
        onAddItem={onAddItem}
        widgetDetailFlag={widgetDetailFlag}
        setWidgetDetailFlag={setWidgetDetailFlag}
        close={close}
        closeTour={closeTour}
        complteTour={complteTour}
      />
      <div className={classes.root}>
        {list.map((widget, index) => {
          return (
            <div
              key={index}
              className={`${classes.rootGrid} ${
                index === widgetNames.length - 1
                  ? classes.gridContainerBottomMargin
                  : ""
              }`}
            >
              <div className={classes.imageLblContainer} >
                <Image
                  src={widget?.src}
                  id={widget?.id}
                  alt=""
                  className={`${classes.reportImage} ${
                    widget.isEnabled
                      ? classes.enabledWidget
                      : classes.disabledWidget
                  } `}
                  onClick={widget?.isEnabled ? widget?.onClick : null}
                />
                <TypographyInline
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={widget?.label}
                  size={"sm"}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
