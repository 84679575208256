import { SEQUENCE_LIST_FAIL, SEQUENCE_LIST_REQUEST, SEQUENCE_LIST_SUCCESS } from "../constants/sequenceConstants";

export const sequenceListReducer = (state = {}, action) => {
    switch (action.type) {
      case SEQUENCE_LIST_REQUEST:
        return { loading: true };
      case SEQUENCE_LIST_SUCCESS:
        return { loading: false, data: action.payload, error: null };
      case SEQUENCE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };