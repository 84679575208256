import {
    TEMPLATE_FOLDER_REQUEST,
    TEMPLATE_FOLDER_SUCCESS,
    TEMPLATE_FOLDER_FAIL
} from "../constants/templateFolderConstants"

export const getTempListReducer=(state={},action) => {
    switch(action.type){
        case TEMPLATE_FOLDER_REQUEST:
            return { loading1: true };
          case TEMPLATE_FOLDER_SUCCESS:
            return { loading1: false, data: action.payload, error: null };
          case TEMPLATE_FOLDER_FAIL:
            return { loading1: false, error: action.payload };
          default:
            return state;
    }
}