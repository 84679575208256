import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const styles = makeStyles({
  textfield: {
    background: "#FFFFFF",
    margin: "0.8rem 0 0 0",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D3DAE3 !important",
      borderRadius: "6px",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#D3DAE3"
    },
    "&  .MuiOutlinedInput-root .MuiInputBase-root .MuiOutlinedInput-root .Mui-disabled .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#D3DAE3",
      },
    "& .MuiOutlinedInput-input": {
      // padding:"0.7rem 1rem",
      color: "#626b7e",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#626b7e",
    },
    // imput value
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#1D2029",
    },
    "& .MuiInputLabel-outlined": {
      color: "#626b7e",
    },
    //onselect label
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ABB4BD",
    },
    // on select border
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff4848",
      borderWidth: "0.1rem",
    },
  },
  isCompanyTextField: {
    background: "#FFFFFF",
    margin: "0.8rem 0 0 0",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D3DAE3 !important",
      borderRadius: "6px",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#D3DAE3"
    },
    "&  .MuiOutlinedInput-root .MuiInputBase-root .MuiOutlinedInput-root .Mui-disabled .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#D3DAE3",
      },
    "& .MuiOutlinedInput-input": {
      // padding:"0.7rem 1rem",
      color: "#626b7e",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#626b7e",
      marginLeft: "8%",
    },
    // imput value
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#1D2029",
    },
    "& .MuiInputLabel-outlined": {
      color: "#626b7e",
    },
    //onselect label
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ABB4BD",
    },
    // on select border
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff4848",
      borderWidth: "0.1rem",
    },
  },
  textTransform: {
    textTransform: "capitalize !important",
  },
  initialFormTextField: {
    background: "#FFFFFF",
    margin: "0.8rem 0 0 0",
    width: "100%",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D3DAE3 !important",
      borderRadius: "4px",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#D3DAE3"
    },
    "&  .MuiOutlinedInput-root .MuiInputBase-root .MuiOutlinedInput-root .Mui-disabled .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#D3DAE3",
      },
    "& .MuiOutlinedInput-input": {
      // padding:"0.7rem 1rem",
      color: "#626b7e",
      fontSize: "14px",
      "@media(max-width: 1600px)": {
        fontSize: "12px",
      },
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#626b7e",
    },
    // imput value
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#1D2029",
    },
    "& .MuiInputLabel-outlined": {
      color: "#626b7e",
    },
    //onselect label
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#ABB4BD",
    },
    // on select border
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff4848",
      borderWidth: "0.1rem",
    },
  },
});

const CustomTextField = ({ background, ...props }) => {
  const classes = styles();

  return (
    <TextField
      {...props}
      autoComplete="off"
      className={
        props?.isInitialForm ? classes.initialFormTextField : classes.textfield
      }
      InputProps={{
        style: {
          fontSize: props.fontSize || "14px",
          backgroundColor: props.backgroundColor,
          fontWeight: props.fontWeight || "500",
          height: props.height,
          borderColor: props.borderColor,
          textTransform: props.textTransform || "none",
          paddingLeft: props.isCompanyForm && "8%",
        },
        classes: { input: props.textTransform && classes.textTransform },
      }}
      InputLabelProps={{ style: { fontSize: "14px", fontWeight: "500" } }}
      placeholder={props.val ? props.val : ""}
    />
  );
};

export default CustomTextField;
