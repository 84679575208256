import React from "react";
import { makeStyles } from "@mui/styles";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import { CloseIcon } from "../Icon/MuiIcons";

const styles = makeStyles({
  modal: {
    // position: "fixed",
    // top: "50%",
    // left: "50%",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    // transform: "translate(-50%, -50%)",
    overflowY: "auto",
    // backgroundColor: "white",
    border: "none",
    // width: "35vw",
    borderRadius: "4px",
    padding: "1rem",
    "&:focus-visible": {
      outline: "0",
    },
    "& .MuiInputBase-input": {
      padding: "0.859vw 0.729vw",
    },
    "& textArea": {
      height: "5.208vw !important",
      resize: "none",
      overflowY: "auto",
      ...TABLE_SCROLL_BAR,
    },
  },
  image: {
    width: "30vw",
    height: "60vh",
    padding: "1.2rem",
    background: "white",
  },
  icon: {
    color: "grey",
    cursor: "pointer",
    position: "absolute",
    top: "20%",
    left: "63.7%",
  },
});
const ImageUploadedPreview = ({ imagebs, setClick, click }) => {
  const classes = styles();
  const handleChange = async () => {
    setClick(!click);
  };
  return (
    <div className={classes.modal}>
      <CloseIcon
        fontSize="medium"
        className={classes.icon}
        onClick={handleChange}
      />
      <img src={imagebs} alt="" className={classes.image} />
    </div>
  );
};

export default ImageUploadedPreview;
