import { Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  IMPORT_COLUMN_PEOPLE_PROPERTY_COLUMNS,
  IMPORT_CONTACT_HEADERS,
  EMITTER_ERROR,
  DEBOUNCE_DELAY,
} from "../../../utils/constants";
import { getRequest } from "../../../Apis";
import {
  crmEndpoints,
  importEndpoints,
} from "../../../utils/constants/httpConstants";
import classes from "./Crm.module.css";
import CustomHeader from "../../../components/Header/CustomHeader";
import { STRINGS } from "../strings";
import MappingFieldModal from "./components/MappingFieldModal";
import SearchField from "../../../components/InputFields/SearchField";
import { Button } from "../../../components/Buttons";
import CustomTable from "../../../components/Table/CustomTable";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import {
  FILE_SIZE_ERROR,
  INVALID_FILE_ERROR,
} from "../../../utils/constants/messages";
import { debounceHelper } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { EmptyScreen } from "../../../components";
import { SALES_EMPTY_SCREEN } from "../../../utils/constants/assets";

const ContactsImportScreen = () => {
  const navigate = useNavigate();

  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const [file, setFile] = useState(null);
  const [highLighted, setHighlighted] = useState(false);
  const [dynamicFields, setDynamicFields] = useState(
    IMPORT_COLUMN_PEOPLE_PROPERTY_COLUMNS || []
  );
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [peopleDynamicFields, setPeopleDynamicFields] = useState([]);
  const [companyDynamicFields, setCompanyDynamicFields] = useState([]);
  const [peopleCustomFields, setPeopleCustomFields] = useState([]);
  const [companyCustomFields, setCompanyCustomFields] = useState([]);
  const [importedFiles, setImportedFiles] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false)
  const [tableLodaing, setTableLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortId, setSortId] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const getImportedFiles = async (e) => {
    try {
      setSearchValue(e?.target?.value);
      setTableLoading(true);
      let query = `?page=${page || 1}&limit=${limit}`;

      if (sortDirection?.length && sortId?.length) {
        query = query + `&sort=${sortId}&direction=${sortDirection}`;
      }
      if (e?.target?.value) {
        query = query + `&search=${e?.target?.value}`;
      }

      const response = await getRequest(
        navigate,
        crmEndpoints.getImportedFiles + query
      );

      setImportedFiles(response?.data?.data?.imported_files);
      setTableCount(response?.data?.data?.total_records);
      setIsEmpty(response?.data?.data?.is_empty)
      setTableLoading(false);
      // EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
    } catch (error) {
      console.log("error", error);
      setTableLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleFileUpload = async (e) => {
    // checking for 1gb here
    e?.preventDefault();
    const fileSize = e?.target?.files?.[0]?.size / (1024 * 1024);
    const uploadedFile = e?.dataTransfer?.files?.[0] || e?.target?.files?.[0];
    if (fileSize >= 1024) {
      EventEmitter.emit(EMITTER_ERROR, FILE_SIZE_ERROR);
      e.target.value = "";
      return;
    }
    if (uploadedFile?.type !== "text/csv") {
      EventEmitter.emit(EMITTER_ERROR, INVALID_FILE_ERROR);
      e.target.value = "";
      return;
    }
    setFile(uploadedFile);
    if (uploadedFile) {
      // await readFileChunk(1000, 0, uploadedFile);
      setShowMappingModal(true);
      e.target.value = "";
    }
  };

  const headerItems = [
    { id: STRINGS.IMPORT_SMALL, label: STRINGS.IMPORT_CONTACTS },
  ];

  const importTableData = {
    heads: IMPORT_CONTACT_HEADERS,
    body: importedFiles,
    actions: {},
    total_records: tableCount,
    isLoading: tableLodaing,
    is_selection_not_allowed: true,
  };

  const debounceSearch = debounceHelper(getImportedFiles, DEBOUNCE_DELAY);

  useEffect(() => {
    (async () => {
      await getImportedFiles();
    })();
    (async () => {
      try {
        if (peopleDynamicFields.length || peopleCustomFields.length || companyDynamicFields.length || companyCustomFields.length) {
          // crm mapping api already called.
        } else {
          const endPoint = importEndpoints.getCrmFieldlMapping;
          const response = await getRequest(navigate, endPoint);
          const records = response?.data?.data || [];

          setPeopleDynamicFields([...records?.people_predefined_field]);
          setPeopleCustomFields([...records?.people_custom_field]);
          setCompanyDynamicFields([...records?.company_predefined_field]);
          setCompanyCustomFields([...records?.company_custom_field]);

          const all_fields = IMPORT_COLUMN_PEOPLE_PROPERTY_COLUMNS;
          setDynamicFields(all_fields);
        }

      } catch (error) {
        console.log("error", error);
      }
    })();
  }, [navigate, page, limit, sortId, sortDirection]);

  useEffect(() => {
    setPage(paginationDataSelector?.data?.query?.page);
    if (paginationDataSelector?.data?.query?.limit) {
      setLimit(paginationDataSelector?.data?.query?.limit);
    }
    if (paginationDataSelector?.data?.query?.sort?.id) {
      setSortId(paginationDataSelector?.data?.query?.sort?.id);
      setSortDirection(paginationDataSelector?.data?.query?.sort?.direction);
    }
  }, [
    paginationDataSelector?.data?.query?.page,
    paginationDataSelector?.data?.query?.limit,
    paginationDataSelector?.data?.query?.sort,
  ]);

  return (
    <div className={classes.mainImportContainer}>
      {/* Custom Fields Mapping modal */}
      <Modal open={showMappingModal}>
        <MappingFieldModal
          file={file}
          setFile={setFile}
          open={showMappingModal}
          setOpen={setShowMappingModal}
          dynamicFields={dynamicFields}
          peopleDynamicFields={peopleDynamicFields}
          companyDynamicFields={companyDynamicFields}
          predefinedPeopleCustomFields={peopleCustomFields}
          predefinedCompanyCustomFields={companyCustomFields}
          getImportedFilesList={getImportedFiles}
          setPage={setPage}
          setLimit={setLimit}
          setSortId={setSortId}
          setSortDirection={setSortDirection}
        />
      </Modal>

      {/* Custom Fields Mapping modal */}

      <CustomHeader items={headerItems} />
      {
        isEmpty ?
          <>
            <EmptyScreen
              imageSrc={SALES_EMPTY_SCREEN}
              label={STRINGS.IMPORT_A_FILE}
              description={STRINGS.YOU_CAN_UPLOAD}
              buttonLabel={STRINGS.UPLOADOR}
              onClick={() => { document.getElementById("file-upload").click() }}
            />
            <div>

              <input
                id="file-upload"
                type="file"
                className={classes.fileUploadInput}
                onChange={handleFileUpload}
                accept=".csv"
              />
            </div>
          </>
          :
          <div
            onDragEnter={() => {
              setHighlighted(true);
            }}
            onDragLeave={() => {
              setHighlighted(false);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={handleFileUpload}
            className={classes.dragDropContainer}
          >
            <div className={classes.importTableMainContainer}>
              <div className={classes.importTableHeaderContainer}>
                <div className={classes.importSearchBar}>
                  <SearchField
                    size={"sm36"}
                    placeholder={STRINGS.SEARCH_FILE_NAME}
                    onChange={debounceSearch}
                  />
                </div>
                <div>
                  <Button
                    size={"sm36"}
                    variant={"primary"}
                    label={STRINGS.IMPORT}
                    onClick={() => {
                      document.getElementById("file-upload").click();
                    }}
                  />
                </div>
              </div>
              <div className={classes.importTableContainer}>
                <CustomTable tableData={importTableData} />
              </div>
            </div>
            <input
              id="file-upload"
              type="file"
              className={classes.fileUploadInput}
              onChange={handleFileUpload}
              accept=".csv"
            />
          </div>
      }
    </div>
  );
};

export default ContactsImportScreen;
