import { makeStyles } from "@mui/styles";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import {
  SCROLL_BAR,
  fontSize,
  fontWeight,
} from "../../utils/constants/UI/uiConstants";
import { LEFT_IMAGE, RIGHT_IMAGE } from "../../utils/constants/assets";
import { BORDER, BRAND, GREY } from "../../utils/constants/colors";

export const styles = makeStyles({
  logoIcon: {
    "@media(max-width: 1600px)": {
      height: "25px",
    },
  },
  SubHeader: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    width: "100%",
  },
  MarginTOp: {
    padding: "16px 0px 0px 16px",
  },

  initialFormMainContainer: {
    height: "100vh",
    backgroundColor: "#F8F8F8",
    // display: "flex",

    // new design css
    flexDirection: "column",
    // height: "100vh",
    alignItems: "center",
    justifyContent: "space-around",
    ...SCROLL_BAR,
    background: `url(${LEFT_IMAGE}) right top no-repeat ,url(${RIGHT_IMAGE}) left bottom no-repeat`,
  },
  intitalFormHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "70px",
    alignItems: "center",
    "@media(max-width: 1600px)": {
      // padding: "15px 20px",
    },
  },
  TypoHead: {
    marginLeft: "0.2rem",
  },
  privacyLink: {
    fontSize: fontSize.XS,
    fontWeight: fontWeight.REGULAR,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: GREY.SECONDARY,
    transition: "100ms",
    "&:hover": {
      color: BRAND.PRIMARY,
    },
  },
  hovering: {
    textDecoration: "none",
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  containPolicy: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "247px",
    paddingBottom: "24px",
  },
  initialFormSteps: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  horizontalLine: {
    width: "60px",
    height: "1px",
    border: "none",
    backgroundColor: "#D3DAE3",
  },
  stepLabelActiveContainer: {
    display: "flex",
    alignItems: "center",
    padding: "12px 20px",
    gap: "10px",
    whiteSpace: "nowrap",
    // backgroundColor: "#FF4848",
    // border: "1px solid #FF4848",
    // boxShadow: "0px 2px 3px rgb(98 107 126 / 11%)",
    borderRadius: "30px",
    "@media(max-width: 1600px)": {
      padding: "6px 20px",
    },
  },
  buttonContainer: {
    display: "flex",
    // gap: "12px",
    padding: "0px 0px 16px 16px",
  },
  buttonContainer1: {
    display: "flex",
    gap: "12px",
    padding: "0px 0px 16px 16px",
  },
  stepLabelNotActiveContainer: {
    display: "flex",
    alignItems: "center",
    padding: "12px 60px",
    gap: "10px",
    whiteSpace: "nowrap",
    // backgroundColor: "#FFFFFF",
    // border: "1px solid #D3DAE3",
    // boxShadow: "0px 2px 3px rgb(98 107 126 / 11%)",
    borderRadius: "30px",
    "@media(max-width: 1600px)": {
      padding: "6px 20px",
    },
  },
  stepLabelCompletedContainer: {
    display: "flex",
    alignItems: "center",
    padding: "12px 20px",
    gap: "10px",
    whiteSpace: "nowrap",
    // backgroundColor: "#FFE9E9",
    // border: "1px solid #FF4848",
    // boxShadow: "0px 2px 3px rgb(98 107 126 / 11%)",
    borderRadius: "30px",
    "@media(max-width: 1600px)": {
      padding: "6px 20px",
    },
  },
  activeStepLabel: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#A1A0A3",
    "@media(max-width: 1600px)": {
      fontSize: "12px",
    },
  },
  notActiveStepLabel: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#D3DAE3",
    "@media(max-width: 1600px)": {
      fontSize: "12px",
    },
  },
  completeStepLabel: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#FF4848",
    "@media(max-width: 1600px)": {
      fontSize: "12px",
    },
  },
  logoutContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  logoutLabel: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#212833",
    "@media(max-width: 1600px)": {
      fontSize: "12px",
    },
  },
  tickIcon: {
    width: "20px",
    height: "20px",
  },
  papermenuclass: {
    width: "34%",
    ...SCROLL_BAR,
    marginTop: "5px",
    height: "20vh",
  },
  tickIconred: {
    width: "20px",
    height: "20px",
  },
  stepsBodyMainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    // height: "calc(100vh - 110px)",
    overflow: "auto",
    ...TABLE_SCROLL_BAR,
    "@media(max-width: 1600px)": {
      // height: "calc(100vh - 70px)",
    },
    // "@media (max-height:650px)": {
    //   alignItems: "flex-start",
    // },
  },
  profileFormMainContainer: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    width: "934px",
  },
  isProfileSettingFormMainContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    margin: "16px 0px 16px 16px",
    height: "100%",
  },
  profileFormLabel: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#212833",
    "@media(max-width: 1600px)": {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  profileFormContainer: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 3px rgba(98, 107, 126, 0.11)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "42px 32px 42px 32px",
    gap: "12px",
  },
  isProfileSettingFormContainer: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 3px rgba(98, 107, 126, 0.11)",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    // padding: "16px",
    gap: "20px",
    height: "100%",
    overflow: "auto",
    ...SCROLL_BAR,
  },
  twoFieldsCotnainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "16px",
    width: "100%",
    padding:"0px 16px",

  },
  fieldErrorContainer: {
    width: "50%",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
  },
  inputLabel: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#212833",
    "@media(max-width: 1600px)": {
      fontSize: "12px",
    },
  },
  autoCompleteFontSize: {
    fontSize: "14px !important",
    ...TABLE_SCROLL_BAR,
    "@media(max-width: 1600px)": {
      fontSize: "12px !important",
    },
  },
  containDomainInput: {
    display: "flex",
    position: "relative",
  },
  subDomainText: {
    left: "2%",
    position: "absolute",
    color: "#1D2029",
    fontFamily: "inter",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "clamp(12px, 0.729vw, 30px)",
    zIndex: "10",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
});
