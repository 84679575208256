import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomHeader from "../../../components/Header/CustomHeader";
import {
  ACCOUNT_PATH,
  CRM_PATH,
  DEALS_PATH,
  DEFAULT_PROTECTED_PATH,
} from "../../../utils/constants/routes";
import { STRINGS } from "../strings";
import classes from "./Crm.module.css";
import {
  DUMMY_COMPANY2,
  DUMMY_CONTACT2,
  DUMMY_DEALS2,
} from "../../../utils/constants/assets";
import { Button } from "../../../components/Buttons";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { BRAND, GREY } from "../../../utils/constants/colors";
import IconComponent from "../../../components/Icon/IconComponent";
import { useState } from "react";
import {
  accountEndPoints,
  crmEndpoints,
  formsEndpoints,
  meetingsEndpoint,
  taskEndpoints,
} from "../../../utils/constants/httpConstants";
import { getRequest } from "../../../Apis";
import InputFieldWoBorder from "../../../components/InputFields/InputFieldWoBorder";
import {
  COUNTRY_LIST,
  DEFAULT_COMPANY_FIELD_ORDER,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../../utils/constants";
import { AutoCompleteWoBorder } from "../../../components/Autocomplete/AutoCompleteWoBorder";
import IconOutlinedHover from "../../../components/Icon/IconOutlinedHover";
import { postRequest, putRequest } from "../../../Apis/apiWrapper";
import { useSelector } from "react-redux";
import {
  createCompanyURL,
  dropdownValuesFromId,
  formatCurrency,
  generateNameFromEmail,
  getCompanyHeading,
  replaceKeyInArrayOfObj,
  textEllipsesHandler,
  timeStampToDate,
} from "../../../utils/helpers";
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";
import TextArea from "../../../components/InputFields/TextArea";
import {
  AccordionDetails,
  AccordionSummary,
  HtmlEditor,
} from "../../../components";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { Accordion } from "@mui/material";
import Tabs from "../../../components/Tabs/Tabs";
import AvatarOutlined from "../../../components/Avatar/AvatarOutlined";
import Image from "../../../components/Image/Image";
import EmailSendModal from "./components/EmailSendModal";
import AvatarName from "../../../components/Avatar/AvatarName";
import CreateMeetingPopUp from "./components/CreateMeetingPopUp";
import CreateTaskCrmPopUp from "./components/CreateTaskCrmPopUp";
import CreateNoteCrmPopUp from "./components/CreateNoteCrmPopUp";
import ContactDetailTimelineScreen from "./AccountDetailTimelineScreen";
import AccountAttachmentScreen from "./AccountAttachmentScreen";
import AccountDetailMeetingScreen from "./AccountDetailMeetingScreen";
import AccountDetailEmailScreen from "./AccountDetailEmailScreen";
import AccountDetailTaskScreen from "./AccountDetailTaskScreen";
import AccountDetailNoteScreen from "./AccountDetailNoteScreen";
import ListSkeletonLoader from "../Components/ListSkeletonLoader";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";
import CreateContactDialog from "./components/CreateContactDialog";
import PropertyActionsModal from "./components/PropertyActionsModal";
import {
  urlValidation,
  nameValidation,
} from "../../../utils/validations/inputValidations";
import AddDealDialog from "../Deals/components/AddDealDialog";
import CustomTooltip from "../../../components/Tooltip/CustomTooltip";

const CompanyDetailScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const getPropertiesListSelector = useSelector(
    (state) => state.getPropertiesList
  );

  const [inputData, setInputData] = useState({});
  const [inputDataId, setInputDataId] = useState({});
  const [inputError, setInputError] = useState({});
  const [contactDetails, setContactDetails] = useState({});
  const [loading, setLoading] = useState(0);
  const [data, setData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [locationTab, setLocationTab] = useState("");
  const [openEmailSendModal, setOpenEmailSendModal] = useState(false);
  const [createFlag, setCreateFlag] = useState(false);
  const [createNoteFlag, setCreateNoteFlag] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const [assigneeList, SetAssigneeList] = useState([]);
  const checkEmpty = [];
  const [companyOpen, setCompanyOpen] = useState(true);
  const [dealOpen, setDealOpen] = useState(true);
  const [contactList, setContactList] = useState([]);
  const [cListLoading, setCListLoading] = useState(true);
  const [dealListLoading, setDealListLoading] = useState(true);
  const [timelineApiCall, setTimelineApiCall] = useState(false);
  const [openActionProperty, setOpenActionProperty] = useState(false);
  const [openAddCompnay, setOpenAddCompany] = useState(false);
  const [openAddDeals, setOpenAddDeals] = useState(false);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const [requiredOrder, setRequiredOrder] = useState({});
  const [deals, setDeals] = useState([]);

  // states for timeline screen
  const [timelineDataList, setTimelineDataList] = useState([]);
  const [timelineScreenLoading, setTimelineScreenLoading] = useState(false);
  const [totalTimeline, setTotalTimeline] = useState(0);

  // states for task screens
  const [taskListData, setTaskListData] = useState("");
  const [taskScreenLoading, setTaskScreenLoading] = useState(true);
  const [totalTasks, setTotaltasks] = useState(0);
  const locationTabs = location?.search?.split("?tabs=");
  const UpdatedLocation = locationTabs[1] == STRINGS.CALLS_SMALL;

  // states for email screen
  const [emailLogListData, setEmailLogListData] = useState([]);
  const [emailScreenLoading, setEmailScreenLoading] = useState(true);
  const [totalEmails, setTotalEmails] = useState(0);

  // states for meeting screen
  const [meetingListData, setMeetingListData] = useState("");
  const [meetingScreenLoading, setMeetingScreenLoading] = useState(true);
  const [totalMeetings, setTotalMeetings] = useState(0);

  // satates for note screen
  const [noteListData, setNoteListData] = useState("");
  const [noteScreenLoading, setNoteScreenLoading] = useState(true);
  const [totalNotes, setTotalNotes] = useState(0);
  //searchHandler country
  const [countryList, setCountrylist] = useState(COUNTRY_LIST || []);

  const headerItems = [
    { id: STRINGS.SALES_SMALL, label: STRINGS.ACCOUNTS, path: ACCOUNT_PATH },
    {
      id: STRINGS.SALES_SMALL,
      label: loader ? STRINGS.LOADING : getCompanyHeading(contactDetails),
    },
  ];

  // api handler of all internal screens
  const getTimelineLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${crmEndpoints.getCompanyTimelineFeed}/${params?.id
        }?size=${10}&page=${count}`;
      !isOnScroll && setTimelineScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalTimeline(response?.data?.data?.total_records);
      if (isOnScroll) {
        setTimelineDataList([
          ...timelineDataList,
          ...response?.data?.data?.records,
        ]);
      } else setTimelineDataList(response?.data?.data?.records);
      !isOnScroll && setTimelineScreenLoading(false);
    } catch (error) {
      setTimelineScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const getTaskLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${taskEndpoints.getCompanyCrmTask}/${params?.id}?step_type=${UpdatedLocation ? 3 : 2
        }&page=${count}`;
      !isOnScroll && setTaskScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotaltasks(response?.data?.data?.total_records);
      if (isOnScroll) {
        setTaskListData([...taskListData, ...response?.data?.data?.records]);
      } else setTaskListData(response?.data?.data?.records);
      !isOnScroll && setTaskScreenLoading(false);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    } finally {
      setTaskScreenLoading(false);
    }
  };

  const getEmailLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url =
        crmEndpoints.getCompanyEmails +
        `?company_id=${id || params?.id}&page=${count}`;
      !isOnScroll && setEmailScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalEmails(response?.data?.data?.total_records);
      if (isOnScroll) {
        setEmailLogListData([
          ...emailLogListData,
          ...response?.data?.data?.records,
        ]);
      } else setEmailLogListData(response?.data?.data?.records);
      !isOnScroll && setEmailScreenLoading(false);
    } catch (error) {
      setEmailScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  const getMeetingLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${meetingsEndpoint.getCrmMeetingsAccount}?company_id=${params?.id}&page=${count}`;
      !isOnScroll && setMeetingScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalMeetings(response?.data?.data?.total_records);
      if (isOnScroll) {
        setMeetingListData([
          ...meetingListData,
          ...response?.data?.data?.crmMeetingsRecord,
        ]);
      } else setMeetingListData(response?.data?.data?.crmMeetingsRecord);
      !isOnScroll && setMeetingScreenLoading(false);
    } catch (error) {
      setMeetingScreenLoading(false);
      console.log("Error ->>>> ", { error });
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const getNoteLogList = async (isOnScroll = false, count = 0, id) => {
    try {
      let url = `${crmEndpoints.getCompanyNotes}/${params?.id}&page=${count}`;
      !isOnScroll && setNoteScreenLoading(true);
      const response = await getRequest(navigate, url);
      setTotalNotes(response?.data?.data?.total_records);
      if (isOnScroll) {
        setNoteListData([...noteListData, ...response?.data?.data?.records]);
      } else setNoteListData(response?.data?.data?.records);
      !isOnScroll && setNoteScreenLoading(false);
    } catch (error) {
      setNoteScreenLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };
  // api handler of all internal screens

  const tabData = [
    {
      label: STRINGS.TIMELINE,
      id: STRINGS.TIMELINE_ID,
      component: (
        <ContactDetailTimelineScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          getTimelineLogList={getTimelineLogList}
          dataList={timelineDataList}
          screenLoading={timelineScreenLoading}
          totalTimeline={totalTimeline}
        />
      ),
      path: `${location?.pathname}?tabs=timeline`,
    },
    {
      label: STRINGS.CALLS,
      id: STRINGS.CALLS_SMALL,
      component: (
        <AccountDetailTaskScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          screenLoading={taskScreenLoading}
          taskListData={taskListData}
          setTaskListData={setTaskListData}
          totalTasks={totalTasks}
          getTaskLogList={getTaskLogList}
        />
      ),

      path: `${location?.pathname}?tabs=calls`,
    },
    {
      label: STRINGS.EMAILS,
      id: STRINGS.EMAILS_SMALL,
      component: (
        <AccountDetailEmailScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          emailLogListData={emailLogListData}
          getEmailLogList={getEmailLogList}
          screenLoading={emailScreenLoading}
          totalEmails={totalEmails}
        />
      ),
      path: `${location?.pathname}?tabs=emails`,
    },
    {
      label: STRINGS.MEETINGS,
      id: STRINGS.MEETINGS_SMALL,
      component: (
        <AccountDetailMeetingScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          getMeetingLogList={getMeetingLogList}
          meetingListData={meetingListData}
          setMeetingListData={setMeetingListData}
          screenLoading={meetingScreenLoading}
          totalMeetings={totalMeetings}
        />
      ),
      path: `${location?.pathname}?tabs=meetings`,
    },
    {
      label: STRINGS.NOTES,
      id: STRINGS.NOTE_SMALL,
      component: (
        <AccountDetailNoteScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          getNoteLogList={getNoteLogList}
          noteListData={noteListData}
          setNoteListData={setNoteListData}
          screenLoading={noteScreenLoading}
          totalNotes={totalNotes}
        />
      ),
      path: `${location?.pathname}?tabs=note`,
    },
    {
      label: STRINGS.TASKS,
      id: STRINGS.TASKS_SMALL,
      component: (
        <AccountDetailTaskScreen
          contactDetails={contactDetails}
          contactId={params?.id}
          getTaskLogList={getTaskLogList}
          screenLoading={taskScreenLoading}
          taskListData={taskListData}
          setTaskListData={setTaskListData}
          totalTasks={totalTasks}
        />
      ),
      path: `${location?.pathname}?tabs=tasks`,
    },
    {
      label: STRINGS.ATTACHMENTS,
      id: STRINGS.ATTACHMENTS_SMALL,
      component: (
        <AccountAttachmentScreen
          contactDetails={contactDetails}
          contactId={params?.id}
        />
      ),

      path: `${location?.pathname}?tabs=attachments`,
    },
  ];

  const getContactDetails = async () => {
    try {
      let id = params?.id;
      setLoader(true);
      const response = await getRequest(
        navigate,
        `${crmEndpoints.getCompanyDetails}/${id}`
      );
      setContactDetails(response?.data?.data || {});
      setInputData(response?.data?.data);
      setInputDataId(response?.data?.data);
    } catch (error) {
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const getContactList = async () => {
    try {
      const response = await getRequest(
        navigate,
        `${accountEndPoints.getContactsByCompany}/${params?.id}`
      );
      setContactList(response?.data?.data?.records || []);
    } catch (error) {
      console.log("erroor", error);
    } finally {
      setCListLoading(false);
    }
  };

  const getDealsList = async () => {
    try {
      const response = await getRequest(
        navigate,
        `${accountEndPoints.getDealsByCompany}/${params?.id}`
      );
      setDeals(response?.data?.data?.records || []);
    } catch (error) {
      console.log("erroor", error);
    } finally {
      setDealListLoading(false);
    }
  };

  const getFieldsOrder = async () => {
    try {
      const response = await getRequest(navigate, formsEndpoints.getFormsField);
      let columns = [];
      let required_obj = {};
      response?.data?.data?.Company?.sort(
        (obj1, obj2) => obj1.order - obj2.order
      )?.forEach((element) => {
        columns.push(element?.field_id);
        required_obj = {
          ...required_obj,
          ...{ [element.field_id]: element?.required },
        };
      });
      setRequiredOrder(required_obj);
      setFieldsOrder(columns || []);
    } catch (error) {
      console.log("Error------->>>>>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const ICONS = [
    {
      id: 1,
      icon: ICON_LABELS.EMAIL,
      variant: "orange",
      iconName: STRINGS.EMAILS,
      onClick: () => {
        setOpenEmailSendModal(true);
      },
    },
    {
      id: 2,
      icon: ICON_LABELS.CALL,
      variant: "green",
      iconName: STRINGS.CALLS,
      onClick: () => {
        setOpenTask(true);
        setType("call");
      },
    },
    {
      id: 3,
      icon: ICON_LABELS.TASK,
      variant: "yellow",
      iconName: "Tasks",
      onClick: () => {
        setOpenTask(true);
        setType(STRINGS.TASKS_SMALL);
      },
    },
    {
      id: 4,
      icon: ICON_LABELS.STICKY_NOTE,
      variant: "blue",
      iconName: "Notes",
      onClick: () => {
        setCreateNoteFlag(true);
      },
    },
    {
      id: 5,
      icon: ICON_LABELS.EVENT_NOTE,
      variant: "purple",
      iconName: "Meetings",
      onClick: () => {
        setCreateFlag(true);
      },
    },
  ];

  // validate add company required fields.
  const validationHandler = (inputData) => {
    // getting all the required fields id and storing it into array.
    let required_fields = [];
    let error_arr = [];
    let input_error = { ...inputError };

    Object.keys(requiredOrder)?.forEach((element, index) => {
      if (requiredOrder?.[element]) {
        required_fields.push(element);
      }
    });

    // loop on required ids for checking validation according to field_type
    required_fields?.forEach((element, index) => {
      if (element !== "company_website") {
        let validate = nameValidation(inputData?.[element]);

        if (validate?.isValid) {
          error_arr?.filter((item) => item !== element);
          input_error = { ...input_error, [element]: "" };
        } else {
          error_arr = [...error_arr, element];
          input_error = { ...input_error, [element]: validate?.message };
        }
      }
    });

    // storing the error into state for displaying error for company_website
    const validatedCompanyWebsite = urlValidation(inputData?.company_website);
    if (validatedCompanyWebsite?.isValid) {
      error_arr?.filter((item) => item !== "company_website");
      input_error = { ...input_error, company_website: "" };
    } else {
      error_arr = [...error_arr, "company_website"];
      input_error = {
        ...input_error,
        company_website: validatedCompanyWebsite?.message,
      };
    }
    setInputError({ ...input_error });

    // return value according to error key check in error_arr.
    if (error_arr?.length) {
      return false;
    } else {
      return true;
    }
  };

  const handleContactEditApi = async (e) => {
    const payload = {
      query: {
        ...inputData,
        company_id: params?.id,
      },
    };
    e?.preventDefault();

    if (validationHandler(inputData)) {
      setLoading(true);
      try {
        const URL = crmEndpoints.updateCompany;

        const response = await putRequest(navigate, URL, payload);

        setLoading(false);
        setData(false);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        let id = params?.id;
        const response2 = await getRequest(
          navigate,
          `${crmEndpoints.getCompanyDetails}/${id}`
        );
        setLoader(false);
        setContactDetails(response2?.data?.data || {});
        setInputData(response2?.data?.data);
        setInputDataId(response2?.data?.data);
      } catch (error) {
        console.log(error);
        setLoading(false);

        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    }
  };

  const handleResetContDetails = () => {
    setInputData(contactDetails);
    setData(false);
    setInputError({});
  };

  const searchHandler = (e, property) => {
    let countryValue = countryList;

    if (property?.id === STRINGS.COMPANY_COUNTRY_SMALL) {
      let data = countryValue?.filter((item) =>
        item?.label?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      );
      if (e?.target?.value === "" || undefined) {
        setCountrylist(COUNTRY_LIST);
      } else {
        setCountrylist(data);
      }
    }
  };

  const getValuesOfDropdown = (prop) => {
    if (prop?.id === STRINGS.COMPANY_COUNTRY_SMALL) {
      let country_list = countryList?.map((item) => {
        return {
          label: item?.label,
          id: item?.id,
        };
      });

      return country_list;
    } else {
      return replaceKeyInArrayOfObj(prop?.value, "labelValue", "label");
    }
  };

  const type_id_components = (prop) => {
    switch (prop?.field_type[0]) {
      case STRINGS.DATE_PICKER:
        return (
          <CustomDatePicker
            size={"sm36"}
            value={inputData?.[prop?.id] || new Date()}
            onChange={(dateValue) => {
              setInputData({ ...inputData, [prop?.id]: dateValue });
            }}
          />
        );

      case STRINGS.NUMBER:
        return (
          <InputFieldWoBorder
            size={"sm36"}
            type={"number"}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            value={inputData?.[prop?.id] || ""}
            variant={
              !Object.keys(contactDetails || {}).length
                ? "disabled"
                : inputError[prop?.id]?.length
                  ? "error"
                  : "default"
            }
            placeholder={STRINGS.NUMBER_PLACEHOLDER}
          />
        );

      case STRINGS.MULTI_LINE_TEXT_INPUT:
        return (
          <TextArea
            rows={"5"}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            value={inputData?.[prop?.id] || ""}
          />
        );

      case STRINGS.RICH_TEXT_EDITOR:
        return (
          <>
            <div
              className={
                inputError[prop?.id]
                  ? classes.htmlEditorError
                  : classes.htmlEditor
              }
            >
              <HtmlEditor
                editorHeight={"12vh"}
                previosData={inputData[prop?.id] || ""}
                onInput={() => {
                  setInputData({
                    ...inputData,
                    [prop?.id]: document?.getElementById(STRINGS.EDITOR_ID)
                      ?.innerHTML,
                  });
                  setData(true);
                }}
                isAITagNotVisible
                isAddTagNotVisible
                isMeetingTagNotVisible
                isFocusNotRequired
              />
            </div>{" "}
            {inputError[prop?.id] ? (
              <TypographyInline
                label={inputError[prop?.id] || ""}
                color={BRAND.PRIMARY}
                size={"sm"}
                fontWeight={fontWeight.REGULAR}
              />
            ) : null}
          </>
        );

      case STRINGS.SINGLE_SELECT:
        return (
          <AutoCompleteWoBorder
            dropdownId="preview_dropdown"
            items={getValuesOfDropdown(prop)}
            size={"sm36"}
            valueState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputDataId[prop?.id]]
                : [inputData?.[prop?.id]]
            }
            setValueState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                })
                : setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                });
              setData(true);
            }}
            idState={
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? [inputData?.[prop?.id]]
                : [inputDataId?.[prop?.id]]
            }
            setIdState={(data) => {
              prop?.id === STRINGS.COMPANY_ID_SMALL
                ? setInputData({
                  ...inputData,
                  [prop?.id]: data[0],
                })
                : setInputDataId({
                  ...inputDataId,
                  [prop?.id]: data[0],
                });
            }}
            isSearchEnable={
              prop?.property_name === STRINGS.COMPANY_COUNTRY_CAPS
            }
            handleSearch={(event) => {
              searchHandler(event, prop);
            }}
          />
        );

      case STRINGS.MULTIPLE_SELECT:
        return (
          <AutoCompleteWoBorder
            dropdownId="preview_dropdown"
            items={replaceKeyInArrayOfObj(
              prop?.value || [],
              "labelValue",
              "label"
            )}
            size={"sm36"}
            multiple
            valueState={inputData?.[prop?.id]}
            setValueState={(data) => {
              setInputData({
                ...inputData,
                [prop?.id]: data,
              });
              setData(true);
            }}
            idState={inputDataId[prop?.id]}
            setIdState={(data) => {
              setInputDataId({
                ...inputDataId,
                [prop?.id]: data,
              });
            }}
          />
        );

      default:
        return (
          <InputFieldWoBorder
            size={"sm36"}
            placeholder={STRINGS.ENTER_INPUT_VALUE}
            value={inputData?.[prop?.id] || ""}
            onChange={(e) => {
              setInputData({
                ...inputData,
                [prop?.id]: e.target.value,
              });
            }}
            variant={
              !Object.keys(contactDetails || {}).length
                ? "disabled"
                : inputError[prop?.id]?.length
                  ? "error"
                  : "default"
            }
            errormessage={inputError[prop?.id]}
          />
        );
    }
  };

  const handleContactNavigate = (item) => {
    navigate(`${CRM_PATH}/${item?._id}?tabs=timeline`);
  };
  const handleDealNavigate = (item) => {
    navigate(`${DEALS_PATH}/${item?._id}?tabs=timeline`);
  };

  const postAction = (tab) => {
    let currentTab = location.search.replace("?tabs=", "");
    if (tab === currentTab || currentTab === STRINGS.TIMELINE_SMALL) {
      switch (currentTab) {
        case STRINGS.EMAILS_SMALL:
          return getEmailLogList();
        case STRINGS.CALLS_SMALL:
          return getTaskLogList();
        case STRINGS.MEETINGS_SMALL:
          return getMeetingLogList();
        case STRINGS.NOTE_SMALL:
          return getNoteLogList();
        case STRINGS.TASKS_SMALL:
          return getTaskLogList();
        default:
          getTimelineLogList();
          break;
      }
    }
  };

  useEffect(() => {
    setLoader(true);
    getFieldsOrder();

    (async () => {
      await getContactDetails();
    })();

    // get contacts list
    (async () => {
      await getContactList();
    })();

    // get deals list
    (async () => {
      await getDealsList();
    })();
  }, [navigate]);

  useEffect(() => {
    const UpdatedLocation = location?.search?.split("?tabs=");
    if (tabData.map((tab) => tab.id).includes(UpdatedLocation[1])) {
      setLocationTab(UpdatedLocation[1]);
    } else {
      // invalid tab
      navigate(DEFAULT_PROTECTED_PATH);
    }
  }, [location]);

  useEffect(async () => {
    if (timelineApiCall) {
      let url = `${crmEndpoints.getCompanyTimelineFeed}/${params?.id}`;
      const response = await getRequest(navigate, url);
      setTimelineApiCall(false);
    }
  }, [timelineApiCall]);

  useEffect(() => {
    searchHandler();
  }, [countryList]);

  return (
    <div>
      <CustomHeader items={headerItems} />

      {/* Layout Container contains all three container */}
      <div className={classes.detailLayoutContainer}>
        {/* Contact Detail Container */}

        <div id="modal_body" className={classes.contactDetailLeftContainer}>
          <>
            <div
              id="modal_body"
              className={classes.contactDetailLeftSubContainer}
            >
              <div className={classes.contactDetailMainContainer}>
                <div>
                  {loader ? (
                    Array.from({ length: 1 })?.map(() => (
                      <SkeletonLoader
                        type="circular"
                        skeletonWidth={80}
                        skeletonHeight={80}
                      />
                    ))
                  ) : contactDetails?.company_website ? (
                    <Image
                      onError={(e) => {
                        e.target.src = DUMMY_COMPANY2;
                      }}
                      className={classes.image}
                      src={createCompanyURL(contactDetails?.company_website)}
                      alt=""
                    />
                  ) : (
                    <AvatarOutlined size={"extraLarge"}>
                      <Image src={DUMMY_COMPANY2} alt="" />
                    </AvatarOutlined>
                  )}
                </div>

                <div className={classes.contactDetailNameDesig}>
                  {loader ? (
                    Array.from({ length: 1 })?.map(() => (
                      <SkeletonLoader
                        type="rounded"
                        skeletonWidth={"100%"}
                        skeletonHeight={"30px"}
                        stackWidth={"30%"}
                      />
                    ))
                  ) : (
                    <>
                      <div className={classes.contactNameContainer}>
                        <TypographyInline
                          color={GREY.PRIMARY}
                          fontWeight={fontWeight.BOLD}
                          size={"xl"}
                          label={getCompanyHeading(contactDetails)}
                        />
                      </div>
                    </>
                  )}
                  {loader ? (
                    Array.from({ length: 1 })?.map(() => (
                      <SkeletonLoader
                        type="rounded"
                        skeletonWidth={"100%"}
                        skeletonHeight={"20px"}
                        stackWidth={"60%"}
                      />
                    ))
                  ) : (
                    <>
                      <div className={classes.displayAbout}>
                        <TypographyInline
                          color={GREY.SECONDARY}
                          fontWeight={fontWeight.REGULAR}
                          size={"sm"}
                          label={contactDetails?.industry || ""}
                        />
                      </div>
                    </>
                  )}{" "}
                </div>

                <div className={classes.aboutContactHead}>
                  {loader
                    ? Array.from({ length: 5 })?.map(() => (
                      <SkeletonLoader
                        type="circular"
                        skeletonWidth={46}
                        skeletonHeight={46}
                        stackWidth={46}
                        stackHeight={46}
                      />
                    ))
                    : Object.keys(contactDetails || {}).length
                      ? ICONS?.map((i) => (
                        <div>
                          <IconOutlinedHover
                            variant={i?.variant}
                            iconLabel={i?.icon}
                            color={i?.variant}
                            iconName={i?.iconName}
                            size="huge"
                            onClick={i?.onClick}
                          />
                        </div>
                      ))
                      : null}
                </div>
              </div>

              <div className={classes.aboutContact}>
                {loader ? (
                  Array.from({ length: 1 })?.map(() => (
                    <div>
                      <SkeletonLoader
                        type="rounded"
                        skeletonWidth={"30%"}
                        skeletonHeight={"20px"}
                        stackWidth={"100%"}
                      />
                    </div>
                  ))
                ) : (
                  <div className={classes.DisplayFlexAbout}>
                    <TypographyInline
                      color={GREY.PRIMARY}
                      fontWeight={fontWeight.MEDIUM}
                      size={"sm"}
                      label={STRINGS.ABOUT_COMPANY}
                    />
                  </div>
                )}
              </div>

              {loader || getPropertiesListSelector?.loading ? (
                <div className={classes.addContactFormLoaderContainer}>
                  {Array.from({ length: 10 })?.map((index) => {
                    return (
                      <div key={`addContact${index}`}>
                        <SkeletonLoader
                          type="rounded"
                          skeletonWidth={"100%"}
                          skeletonHeight={"42px"}
                          stackWidth={"100%"}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={classes.aboutContact}>
                  <form
                    className={classes.inputForm}
                    onChange={() => setData(true)}
                    onSubmit={handleContactEditApi}
                  >
                    {fieldsOrder?.map((field) => {
                      return (
                        <div className={classes.inputLabelField}>
                          <div className={classes.labelButtonContainer}>
                            <TypographyInline
                              label={
                                getPropertiesListSelector?.data
                                  ?.properties_key_value?.[field]
                                  ?.property_name || ""
                              }
                              color={GREY.SECONDARY}
                              size={"sm"}
                              fontWeight={fontWeight.MEDIUM}
                              isRequired={requiredOrder?.[field]}
                            />
                          </div>
                          {getPropertiesListSelector?.data?.hits?.length &&
                            type_id_components(
                              getPropertiesListSelector?.data
                                ?.properties_key_value?.[field]
                            )}
                        </div>
                      );
                    })}
                  </form>
                </div>
              )}
            </div>
            {data ? (
              <div className={classes.aboutFooter}>
                <Button
                  label={STRINGS.CANCEL}
                  size={"sm36"}
                  variant={"ghost"}
                  onClick={() => {
                    handleResetContDetails();
                  }}
                />
                <Button
                  label={STRINGS.SAVE}
                  isLoading={loading}
                  size={"sm36"}
                  variant={"primary"}
                  onClick={() => {
                    handleContactEditApi();
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </>
        </div>

        {/* Contact Activities Container */}
        <div className={classes.contactDetailContainerHead}>
          <div className={classes.contactDetailContainerSubHead}>
            <Tabs
              items={tabData}
              selected={`${location?.pathname}${location?.search}`}
              selectedTabFunc={() => { }}
            />
          </div>
          <div id="modal_body" className={classes.contactDetailContainer}>
            {tabData?.map((item, index) => {
              return item?.id === locationTab && item?.component;
            })}
          </div>
        </div>

        {/* Contact Companies Container */}
        <div className={classes.contactDetailRightContainer}>
          {/* Contacts Accordian */}
          <Accordion
            className={classes.accrodionRoot}
            onChange={() => setCompanyOpen(!companyOpen)}
            expanded={companyOpen}
          >
            <AccordionSummary className={classes.accordionSummary}>
              <div
                className={
                  companyOpen
                    ? classes.summaryContainer
                    : classes.summaryContainer
                }
              >
                <TypographyInline
                  label={STRINGS.CONTACTS}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.SECONDARY}
                />
                <div className={classes.summaryRightSection}>
                  <div>
                    <Button
                      size={"sm36"}
                      variant={"text"}
                      label={STRINGS.ADD}
                      LeftIcon={ICON_LABELS.ADD}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenAddCompany(true);
                      }}
                    />
                  </div>
                  <div className={classes.verticalLine} />
                  <div className={classes.accordionIcon}>
                    <IconComponent
                      color={GREY.TERTIARY}
                      fontSize={fontSize.DXS}
                      iconLabel={
                        companyOpen
                          ? ICON_LABELS.ACTION_ARROW
                          : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className={classes.accordionDetail}
              id="modal_body"
            >
              {cListLoading ? (
                <ListSkeletonLoader />
              ) : contactList.length ? (
                contactList?.map((item) => {
                  return (
                    <div
                      key={item.email}
                      className={
                        item?.email?.length ||
                          item?.corporate_phone_number?.length
                          ? `${classes.flexEachContactTopMain}`
                          : `${classes.flexEachContactTopMainTo}`
                      }
                    >
                      <div
                        className={classes.flexEachContactTop}
                        onClick={() => handleContactNavigate(item)}
                      >
                        <AvatarName
                          name={` ${generateNameFromEmail(
                            item?.email,
                            item?.first_name
                              ? item?.first_name + " " + item.last_name
                              : ""
                          )}`}
                          size={"medium"}
                        />
                        <div>
                          <TypographyInline
                            color={GREY.PRIMARY}
                            label={
                              `${item?.first_name || STRINGS.USER} ${item.last_name || ""
                              }` || ""
                            }
                            size={"sm"}
                            fontWeight={fontWeight.SEMIBOLD}
                          />
                        </div>
                      </div>

                      <div className={classes.flexEachContact}>
                        <div
                          className={classes.FlexHeadWithGap}
                          id="modal_body"
                        >
                          <IconComponent
                            color={GREY.TERTIARY}
                            fontSize={fontSize.DXS}
                            iconLabel={ICON_LABELS.EMAIL}
                          />
                          <TypographyInline
                            color={GREY.PRIMARY}
                            label={`${STRINGS.EMAIL}:`}
                            size={"sm"}
                            fontWeight={fontWeight.SEMIBOLD}
                          />

                          <CustomTooltip
                            title={item?.email?.length > 20 ? item?.email : ""}
                          >
                            <div className={classes.dealNameContainer}>
                              <TypographyInline
                                color={GREY.TERTIARY}
                                label={
                                  textEllipsesHandler(item?.email, 20) || "--"
                                }
                                size={"sm"}
                                fontWeight={fontWeight.SEMIBOLD}
                              />
                            </div>
                          </CustomTooltip>
                        </div>

                        <div
                          className={classes.FlexHeadWithGap}
                          id="modal_body"
                        >
                          <IconComponent
                            color={GREY.TERTIARY}
                            fontSize={fontSize.DXS}
                            iconLabel={ICON_LABELS.CALL}
                          />
                          <TypographyInline
                            color={GREY.PRIMARY}
                            label={`${STRINGS.PHONE_NUMBER}:`}
                            size={"sm"}
                            fontWeight={fontWeight.SEMIBOLD}
                          />
                          <TypographyInline
                            color={GREY.TERTIARY}
                            label={item?.corporate_phone_number || "--"}
                            size={"sm"}
                            fontWeight={fontWeight.SEMIBOLD}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                //  when no contact exists.
                <div className={classes.noComanyFoundContainer}>
                  <AvatarOutlined size={"extraLarge"}>
                    <Image src={DUMMY_CONTACT2} alt="" />
                  </AvatarOutlined>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={STRINGS.NO_CONTACT_ADDED}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {openAddCompnay && (
            <CreateContactDialog
              open={openAddCompnay}
              close={() => setOpenAddCompany(false)}
              getContactDetails={getContactList}
            />
          )}

          {/* Deals Accordian */}
          <Accordion
            className={classes.accrodionRoot}
            onChange={() => setDealOpen(!dealOpen)}
            expanded={dealOpen}
          >
            <AccordionSummary className={classes.accordionSummary}>
              <div
                className={
                  dealOpen
                    ? classes.summaryContainerOpen
                    : classes.summaryContainer
                }
              >
                <TypographyInline
                  label={STRINGS.DEALS}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.SECONDARY}
                />
                <div className={classes.summaryRightSection}>
                  <div>
                    <Button
                      size={"sm36"}
                      variant={"text"}
                      label={STRINGS.ADD}
                      LeftIcon={ICON_LABELS.ADD}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenAddDeals(true);
                      }}
                    />
                  </div>
                  <div className={classes.verticalLine} />
                  <div className={classes.accordionIcon}>
                    <IconComponent
                      color={GREY.TERTIARY}
                      fontSize={fontSize.DXS}
                      iconLabel={
                        dealOpen
                          ? ICON_LABELS.ACTION_ARROW
                          : ICON_LABELS.KEYBOARD_ARROW_RIGHT
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className={classes.accordionDetail}
              id="modal_body"
            >
              {dealListLoading ? (
                <ListSkeletonLoader />
              ) : deals?.length ? (
                deals?.map((deal) => {
                  return (
                    <div key={deal._id} className={classes.pipelineOuter}>
                      <div
                        className={classes.companyBodyContainer}
                        onClick={() => handleDealNavigate(deal)}
                      >
                        <TypographyInline
                          color={GREY.PRIMARY}
                          label={deal?.deal_name}
                          size={"sm"}
                          fontWeight={fontWeight.SEMIBOLD}
                        />
                        <TypographyInline
                          color={GREY.SECONDARY}
                          label={formatCurrency(deal?.deal_amount)}
                          size={"sm"}
                          fontWeight={fontWeight.MEDIUM}
                        />
                      </div>
                      <div className={classes.displayColumnPipeline}>
                        <div className={classes.flexEachCompanyClosedPipeline}>
                          <TypographyInline
                            color={GREY.TERTIARY}
                            label={STRINGS.EXPECT_CLOSED_DATE}
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                          />
                          <TypographyInline
                            color={GREY.PRIMARY}
                            label={
                              deal?.closed_date?.length
                                ? `${timeStampToDate(deal?.closed_date)}`
                                : "--"
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                          />
                        </div>
                        <div className={classes.flexEachContactPipeline}>
                          <div className={classes.PipelineFlex}>
                            <TypographyInline
                              color={GREY.TERTIARY}
                              label={STRINGS.PIPELINENAME}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                            <TypographyInline
                              color={GREY.PRIMARY}
                              label={deal?.pipeline_name}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />{" "}
                          </div>
                          <div className={classes.PipelineFlex}>
                            <TypographyInline
                              color={GREY.TERTIARY}
                              label={STRINGS.STAGE_NAME}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                            <TypographyInline
                              color={GREY.PRIMARY}
                              label={deal?.stage_name || "--"}
                              size={"sm"}
                              fontWeight={fontWeight.REGULAR}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                //  when no contact exists.
                <div className={classes.noComanyFoundContainer}>
                  <AvatarOutlined size={"extraLarge"}>
                    <Image src={DUMMY_DEALS2} alt="" />
                  </AvatarOutlined>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    label={STRINGS.NO_DEAL_ADDED}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {openAddDeals && (
            <AddDealDialog
              open={openAddDeals}
              setOpen={() => setOpenAddDeals(false)}
              getContactDetails={getContactDetails}
              getDealsListed={getDealsList}
              company_id={params?.id}
              updateDeal={true}
            />
          )}
        </div>

        {/* All Screens Modal which will open on click of icons. */}
        {createFlag && (
          <CreateMeetingPopUp
            open={createFlag}
            close={setCreateFlag}
            contact_id={params?.id}
            assigneeList={assigneeList}
            setMeetingListData={() => { }}
            contactDetails={contactDetails}
            checkEmptyConnect={checkEmpty?.isConnectionEmpty}
            SetAssigneeList={SetAssigneeList}
            getMeetings={() => postAction(STRINGS.MEETINGS_SMALL)}
          />
        )}

        {openTask && (
          <CreateTaskCrmPopUp
            open={openTask}
            close={setOpenTask}
            contact_id={params?.id}
            setTaskListData={() => { }}
            type={type}
            contactDetails={contactDetails}
            setTimelineApiCall={setTimelineApiCall}
            getTasks={() =>
              postAction(
                type === "call" ? STRINGS.CALLS_SMALL : STRINGS.TASKS_SMALL
              )
            }
          />
        )}

        {createNoteFlag && (
          <CreateNoteCrmPopUp
            open={createNoteFlag}
            close={setCreateNoteFlag}
            setNoteListData={() => { }}
            contact_id={params?.id}
            contactDetails={contactDetails}
            setTimelineApiCall={setTimelineApiCall}
            getNotes={() => postAction(STRINGS.NOTE_SMALL)}
          />
        )}

        {openEmailSendModal && (
          <EmailSendModal
            open={openEmailSendModal}
            close={() => setOpenEmailSendModal(false)}
            contactId={params?.id}
            setTimelineApiCall={setTimelineApiCall}
            getEmails={() => postAction(STRINGS.EMAILS_SMALL)}
            contactDetails={contactDetails}
          />
        )}

        {openActionProperty && (
          <PropertyActionsModal
            open={openActionProperty}
            close={setOpenActionProperty}
            firstName={contactDetails?.company_name}
            lastName={contactDetails?.last_name}
            contactId={params?.id}
            getFieldsOrder={getFieldsOrder}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyDetailScreen;
