export const STRINGS = {
  SEARCH_NAME: "Search Name",
  IS_ANY_OF: "Is any of",
  IS_ALL_OF: "Is all of",
  IS_NOT_ANY_OF: "Is not any of",
  NOT_ANY_OF: "notAnyOf",
  IS_KNOWN: "Is known",
  IS_UNKNOWN: "Is unknown",
  CONTACT: "Contact",
  COMPANY: "Company",
  APPLY: "Apply",
  CANCEL: "Cancel",
  FROM: "From",
  TO: "To",
  CLEAR: "Clear",
  SUBMIT: "Submit",
  RESET: "Reset",
  DEAL_OWNER_SMALL: "deal_owner",
  ASSOCIATED_CONTACT_SMALL: "associated_contact",
  ANY_OF_LIST_SMALL: "anyOfList",
  NOT_ANY_OF_LIST_SMALL: "notAnyOfList",
  EMPLOYEE_NUMBER_SMALL: "number_of_employees",
  SENIORITY_SMALL: "seniority",
};
