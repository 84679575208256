import { BRANDING_GET_REQUEST, BRANDINGLIST_GET_SUCCESS, BRANDING_LIST_GET_FAIL, } from "../constants/bradingKitConstants";

export const brandKitListReducer = (state = {}, action) => {
    switch (action.type) {
      case BRANDING_GET_REQUEST:
        return { loading: true };
  
      case BRANDINGLIST_GET_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case BRANDING_LIST_GET_FAIL:
        return { loading: false, error: action.payload };

       
    
      default:
        return state;
    }
  };
  
 
  