import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../Apis";
import { importEndpoints } from "../../utils/constants/httpConstants";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../utils/constants";
import CustomModal from "../../components/Modals/CustomModal";
import { Button } from "../../components/Buttons";
import { STRINGS } from "./strings";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import TypographyInline from "../../components/Typography/TypographyInline";

const styles = makeStyles({
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "28vw",
    borderRadius: "12px",
  },
  headerModal: {
    height: "72px",
    background: "#D3DAE3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerData1: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginLeft: "18px",
  },
  headerData2: {
    color: "#626B7E",
    marginRight: "18px",
    fontWeight: "600",
    cursor: "pointer",
  },
  delContent: {
    height: "70px",
    display: "flex",
    paddingLeft: "18px",
    paddingTop: "27px",
  },
  delContentWithin: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#626B7E",
    lineHeight: "17px",
  },
  delFooter: {
    height: "66px",
    borderTop: "1px solid #D3DAE3",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cnclBtn: {
    marginRight: "10px",
    height: "37px",
    borderRadius: "6px",
    width: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    border: "1px solid #D3DAE3",
    color: "#626B7E",
    cursor: "pointer",
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },
  },
  crtBtn: {
    marginRight: "18px",
    height: "37px",
    color: "#FFFFFF",
    background: "#FF4848",
    borderRadius: "6px",
    width: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    cursor: "pointer",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  containBtns: {
    paddingTop: "1rem",
    justifyContent: "flex-end",
    display: "flex",
    marginTop: "10px",
  },
  closeBtn: {
    background: "white",
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "13px",
    border: "none",
    padding: "0.7rem 1.5rem",
    borderRadius: "4px",
    cursor: "pointer",
    height: "40px",
    alignSelf: "center",
  },
  containIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  para: {
    color: "#616A7D",
    fontSize: "15px",
    fontWeight: "500",
  },
  deleteBtn: {
    backgroundColor: "#FFFFFF",
    color: "#626B7E",
    border: "1px solid #D3DAE3",
    height: "30px",
    width: "80px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  cancelBtn: {
    backgroundColor: "#FF4848",
    color: "#FFFFFF",
    fontSize: "11px",
    height: "30px",
    width: "80px",
    borderRadius: "3px",
    border: "none",
    marginLeft: "10px",
    cursor: "pointer",
  },
  iconBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#626B7E",
    border: "1px solid #D3DAE3",
  },
  FLEXED: {
    display: "flex",
    gap: "4px",
  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
});

const BlacklistDeleteModal = ({ open, setOpen, val, setData }) => {
  const classes = styles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const deleteRecord = async () => {
    try {
      const payload = {
        domain_name: val?.domain_name,
      };
      setLoading(true);
      const response = await postRequest(
        navigate,
        importEndpoints.deleteBlacklistDomains,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      const response1 = await getRequest(
        navigate,
        importEndpoints.getBlackListDomains
      );
      setData(response1?.data?.data?.blocked_domains);
    
      setOpen(!open);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.message);
    } finally {
      setLoading(false);
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: `${STRINGS.DELETE} ${val?.domain_name}`,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <TypographyInline
        size={"sm"}
        color={GREY.QUATINARY}
        fontWeight={fontWeight.MEDIUM}
        label={STRINGS.DEL_DESC}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.FLEXED}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(!open)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={deleteRecord}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      variant={"error"}
      close={() => setOpen(false)}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};
export default BlacklistDeleteModal;
