import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { allFilters } from "../../../redux/actions/filterActions";
import { getPeopleBasicDetails } from "../../../redux/actions/peopleActions";
import { applyUpdateColumn, tableDataFetch, tableEndpointChange, updateColumnHeaders } from "../../../redux/actions/tableActions";
import { HandleClearAllFilterFromStore } from "../../../redux/store/storeHelper";
import { tempHeadCells } from "../../../utils/constants";
import FilterTab from "../../../components/FilterTab/FilterTab";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { paginationDataAction } from "../../../redux/actions/customTableActions";
import { STRINGS } from "../strings";
import * as qs from "qs";
import classes from "./LeadsComponent.module.css";
import IconComponent from "../../../components/Icon/IconComponent";

const ContactDropdownModal = ({ clearSearch, header }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(null);
  const querySelector = useSelector((state) => state?.peopleBasicDetails?.payload);
  const tableEndpointChangeSelector = useSelector((state) => state?.tableEndpointChange);
  const [text, setText] = useState(tableEndpointChangeSelector?.payload || STRINGS.NEW_CONTACTS);
  const [dropDownText, setDropDownText] = useState(tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT ? STRINGS.NEW_CONTACTS : STRINGS.SAVED_CONTACT);
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const handleChange = async () => {
    const newText = text;
    setText(dropDownText);
    setDropDownText(newText);
    await HandleClearAllFilterFromStore(dispatch, location, navigate);
    await dispatch(getPeopleBasicDetails(navigate));
    await dispatch(
      paginationDataAction({
        checkedRow: [],
        query: {},
      })
    );
    setTimeout(() => {
      dispatch(tableEndpointChange(dropDownText));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...querySelector?.default_view?.query_string,
        }).toString(),
      });
      dispatch(allFilters(querySelector?.default_view?.query_string || {}));
      dispatch(
        tableDataFetch(
          querySelector?.default_view?.query_string || {},
          navigate
        )
      );
      dispatch(applyUpdateColumn([...tempHeadCells]));
      dispatch(updateColumnHeaders([...tempHeadCells]));
    }, 50);
    clearSearch()
    setOpen(false);
  };

  useEffect(() => {
    if (queryObject?.contact_lists) {
      setText(STRINGS.SAVED_CONTACT);
      setDropDownText(STRINGS.NEW_CONTACTS);
    } else {
      setText(tableEndpointChangeSelector?.payload || STRINGS.NEW_CONTACTS);
      tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT ? setDropDownText(STRINGS.NEW_CONTACTS) : setDropDownText(STRINGS.SAVED_CONTACT)
    }
  }, [tableEndpointChangeSelector]);

  return (
    <div className={classes.filterTab}>
      <FilterTab
        label={text}
        leftIcon={ICON_LABELS.CONTACT_PAGE}
        rightIcon={ICON_LABELS.ACTION_ARROW}
        onClick={(e) => setOpen(e?.currentTarget)}
        size="large"
      />
      <CustomStyledMenu
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        width={dropDownText === STRINGS.NEW_CONTACTS ? "175px" : "165px"}
        noMargin={true}
        marginTop={"5px !important"}
      // marginLeft={"-9px !important"}
      >
        <Box className={classes.contactListMenuItem} onClick={handleChange}>
          <IconComponent
            iconLabel={ICON_LABELS.CONTACT_PAGE}
            color={GREY.TERTIARY}
            fontSize={fontSize.MD}
          />
          <TypographyInline
            size={"sm"}
            label={dropDownText}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
          />
        </Box>
      </CustomStyledMenu>
    </div>
  );
};

export default ContactDropdownModal;
