import React, {useState, useEffect} from 'react'

import { Styles } from "../Styles";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";
import InputField from "../../../components/InputFields/InputField";
import { CustomTextArea } from "../../../components";
import { AutoCompleteCustom } from "../../../components/Autocomplete/AutoCompleteCustom";
import TextArea from '../../../components/InputFields/TextArea';

export const DashboardDetailsPopup = (props) => {
  const classes = Styles();
  console.log("PRR",props)

  const [dashboardDescription, setDashboardDescription] = useState("");
  const [dashboardOwner, setDashboardOwner] = useState(props.dashBoard.owner_name || "");
  const [dashboardName, setDashboardName] = useState(props.dashBoard.dashboard_name || "");
  const [dashboardOwnerId, setDashboardOwnerId] = useState("1");


  const owners = [
    {
      id: "1",
      label: String(dashboardOwner)
    }
  ];


  const handlesDescriptionChanges = (e)=>{
    setDashboardDescription(e.target.value);
  }

  const handlesUpdates = (e)=>{
    let payload = {
      _id: props.dashBoard?._id,
      dashboard_name: dashboardName,
      dashboard_description:dashboardDescription,
      dashboard_type:props?.dashBoard?.dashboard_type == "team" ? "public-read" : props?.dashBoard?.dashboard_type,
      is_default_dashboard: props.dashBoard?.is_default_dashboard,
    };
    props.updateDashboard(payload, props.handlesDashboardDetailsPopup, e);
  }

  const cancelHandle = () => {
    props.handlesDashboardDetailsPopup();
  };

  const modalHeaderData = {
    heading: {
      id: "set_default_modal",
      label: STRINGS.DASHBOARD_DETAILS,
    },
  };

  

  const modalFooterRightData = (
    <div className={classes.footerRightData}>
      <Button
        label={STRINGS.CLOSE}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.UPDATE}
        size={"sm36"}
        variant={"primary"}
        onClick={handlesUpdates}
        isLoading={props.loading}
      />
    </div>
  );

  useEffect(()=>{
    setDashboardName(props.dashBoard.dashboard_name);
    setDashboardOwner(props.dashBoard.owner_name);
    setDashboardOwnerId("1");
  }, [props.dashBoard])

  return (
    <CustomModal
      open={props?.open}
      close={props?.onClose}
      header={modalHeaderData}
      footer={{ right: modalFooterRightData }}
      width={"36.604vw"}
    >
      <form
        onSubmit={(e) => handlesUpdates(e)}
        className={classes.modalBodyContainer}
      >
        <div
          className={classes.modalFieldLabelContainer}
          data-tour="dashboardname"
        >
          <TypographyInline
            label={STRINGS.DASHBOARD_NAME}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
            isRequired
          />
          <InputField
            size={"sm36"}
            value={dashboardName}
            errormessage={props.updateErr.length !== 0 ? props.updateErr : ""}
            variant={props.updateErr.lenselectedOwnerIdgth ? "error" : "default"}
            onChange={(e) => setDashboardName(e.target.value)}
            placeholder={STRINGS.PLACEHOLDER_CREATE_DASHBOARD}
          />
        </div>
        <div className={classes.modalFieldLabelContainer}>
          <TypographyInline
            label={STRINGS.DASHBOARD_OWNER}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
            isRequired
          />
          <AutoCompleteCustom 
            valueState={dashboardOwner}
            setValueState={setDashboardOwner}
            size={"sm36"}
            items={owners}
            idState={[dashboardOwnerId]}
            setIdState={ setDashboardOwnerId }
            LeftIcon = {""}
            variant = {"disabled"}
          />
        </div>
        <div
          className={classes.permissionsContainer}
          data-tour="sharedashboard"
        >
          <TypographyInline
            label={STRINGS.DASHBOARD_DESCRIPTION}
            size={"sm"}
            fontWeight={fontWeight.MEDIUM}
            color={GREY.QUATINARY}
          />
          <TextArea
            placeholder = {STRINGS.DASHBOARD_DESCRIPTION}
            value = {dashboardDescription}
            onChange = {handlesDescriptionChanges}
            isResize={true}
          />
        </div>
      </form>
    </CustomModal>
  );
};
