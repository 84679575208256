import { useSelector } from "react-redux";
import { getRequest, postRequest } from "../../Apis";
import { EMITTER_SUCCESS } from "../../utils/constants";
import {
  dashboardEndpoints,
  dashboardWidgetEndpoints,
} from "../../utils/constants/httpConstants";
import { LOGIN_PATH } from "../../utils/constants/routes";
import EventEmitter from "../../utils/emitter/EventEmitter";
import {
  GET_CHART_DATA_FAIL,
  GET_CHART_DATA_REQUEST,
  GET_CHART_DATA_SUCCESS,
  GET_CURRENT_DASHBOARD,
  GET_DASHBOARD_LIST_FAIL,
  GET_DASHBOARD_LIST_REQUEST,
  GET_DASHBOARD_LIST_SUCCESS,
  GET_DASHBOARD_WIDGET_LIST_FAIL,
  GET_DASHBOARD_WIDGET_LIST_REQUEST,
  GET_DASHBOARD_WIDGET_LIST_SUCCESS,
  GET_WIDGET_COORDINATES,
} from "../constants/dashboardConstants";

const resolveUrl = (url, options = {}) => {
  const optionKeys = Object.keys(options);
  if (optionKeys.length === 0) return url;
  let another_string = "";
  for (let key in optionKeys) {
    if (
      options[optionKeys[key]] !== undefined &&
      options[optionKeys[key]] !== null
    )
      another_string +=
        String(optionKeys[key] + "=" + String(options[optionKeys[key]])) + "&";
  }
  const appendable_string = another_string.slice(0, -1);

  return url + "?" + appendable_string;
};

export const getDashboardList = (
  navigate,
  options = { page: 1, limit: 10 }
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DASHBOARD_LIST_REQUEST });
      let endpointURL = dashboardEndpoints?.getDashboard;
      let processedUrl = resolveUrl(endpointURL, options);
      const response = await getRequest(navigate, processedUrl);
      dispatch({
        type: GET_DASHBOARD_LIST_SUCCESS,
        payload: response?.data?.data,
      });
    } catch (err) {
      // log user out here.
      // if (err?.status === 401) {
      //   // navigate from this action
      //   navigate(LOGIN_PATH);
      // }
      dispatch({ type: GET_DASHBOARD_LIST_FAIL, payload: err });
    }
  };
};

export const getDashboardWidgetList =
  (navigate, param, flag = false) =>
  async (dispatch) => {
    if (flag) {
      try {
        const URL =
          dashboardWidgetEndpoints?.getDashboardWidget +
          "?" +
          "page=1&limit=10&dashboard_id=" +
          param;
        const response = await getRequest(navigate, URL);
        dispatch({
          type: GET_DASHBOARD_WIDGET_LIST_SUCCESS,
          payload: response?.data?.data,
        });
      } catch (err) {
        // log user out here.
        if (err?.status === 401) {
          // navigate from this action
          navigate(LOGIN_PATH);
        }
        dispatch({ type: GET_DASHBOARD_WIDGET_LIST_FAIL, payload: err });
      }
    } else {
      try {
        dispatch({ type: GET_DASHBOARD_WIDGET_LIST_REQUEST });
        const URL =
          dashboardWidgetEndpoints?.getDashboardWidget +
          "?" +
          "page=1&limit=10&dashboard_id=" +
          param;
        const response = await getRequest(navigate, URL);
        dispatch({
          type: GET_DASHBOARD_WIDGET_LIST_SUCCESS,
          payload: response?.data?.data,
        });
      } catch (err) {
        // log user out here.
        if (err?.status === 401) {
          // navigate from this action
          navigate(LOGIN_PATH);
        }
        dispatch({ type: GET_DASHBOARD_WIDGET_LIST_FAIL, payload: err });
      }
    }
  };

export const getChartData =
  (navigate, payload, flag, isEventEmitter, prevData = {}) =>
  async (dispatch) => {
    if (flag) {
      try {
        const URL = dashboardWidgetEndpoints?.getChartData;
        const response = await postRequest(navigate, URL, payload);
        dispatch({
          type: GET_CHART_DATA_SUCCESS,
          payload: response?.data?.data,
        });
        isEventEmitter &&
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } catch (err) {
        // log user out here.
        if (err?.status === 401) {
          // navigate from this action
          navigate(LOGIN_PATH);
        }
        dispatch({ type: GET_CHART_DATA_FAIL, payload: err });
      }
    } else {
      try {
        dispatch({ type: GET_CHART_DATA_REQUEST });
        const URL = dashboardWidgetEndpoints?.getChartData;
        const response = await postRequest(navigate, URL, payload);
        dispatch({
          type: GET_CHART_DATA_SUCCESS,
          payload: response?.data?.data,
        });
      } catch (err) {
        // log user out here.
        if (err?.status === 401) {
          // navigate from this action
          navigate(LOGIN_PATH);
        }
        dispatch({ type: GET_CHART_DATA_FAIL, payload: err });
      }
    }

    if (Object.keys(prevData).length > 0) {
      try {
        const URL = dashboardWidgetEndpoints?.getChartData;
        const response = await postRequest(navigate, URL, payload);

        for (let key in prevData) {
          if (key == Object.keys(response?.data?.data)[0]) {
            prevData[key] = Object.values(response?.data?.data)[0];
          }
        }

        dispatch({
          type: GET_CHART_DATA_SUCCESS,
          payload: prevData,
        });

        isEventEmitter &&
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } catch (err) {
        // log user out here.
        if (err?.status === 401) {
          // navigate from this action
          navigate(LOGIN_PATH);
        }
        dispatch({ type: GET_CHART_DATA_FAIL, payload: err });
      }
    }
  };

export const getWidgetCoordinates = (payload) => async (dispatch) => {
  dispatch({
    type: GET_WIDGET_COORDINATES,
    payload: payload,
  });
};

export const getCurrentDashboard = (payload) => async (dispatch) => {
  dispatch({
    type: GET_CURRENT_DASHBOARD,
    payload: payload,
  });
};
