import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  ALL_COLUMNS,
  EACH_BATCH_SIZE,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
} from "../../utils/constants";
import * as Papaparse from "papaparse";
import { getErrorMessage, match_import_fields_csv } from "../../utils/helpers";
import { Uploader } from "../../utils/helpers/Uploader";
import { ErrorMessage } from "../../components";
import Checkbox from "../../components/Buttons/Checkbox/Checkbox";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { postRequest } from "../../Apis";
import { importEndpoints } from "../../utils/constants/httpConstants";
import { useNavigate } from "react-router-dom";
import ProgressEmitter from "../../components/Loaders/ProgressEmitter";
import { Button } from "../../components/Buttons";
import { STRINGS } from "./strings";
import CustomModal from "../../components/Modals/CustomModal";
import Typography from "../../components/Typography/Typography";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import { getImportCsv } from "../../redux/actions/csvActions";
import { AutoCompleteCustom } from "../../components/Autocomplete/AutoCompleteCustom";
import { SCROLL_BAR } from "../../utils/constants/UI/uiConstants";
import { Mixpanel } from "../../Apis/Mixpanel";
import CreditsForRefferalModal from "../../components/Modals/CreditsForRefferalModal";

const styles = makeStyles({
  paperMenu: {
    width: "11.979vw",
    ...SCROLL_BAR,
    height: "20vh",
  },
  DIsplay: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  loading: {
    backgroundColor: "#ff4848",
    color: "white",
    fontSize: "14px",
    padding: "0.4rem 1rem",
    border: "none",
    borderRadius: "4px",
    marginLeft: "0.6rem",
    cursor: "pointer",
    fontWeight: "400",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "60vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },
  flex: {
    display: "flex",
    paddingTop: "1rem",
  },
  containCrossIcon: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    alignItems: "center",
    height: "2rem",
  },
  icon: {
    color: "#7c7d7e",
    fontSize: "20px",
  },
  title: {
    color: "#102f51",
    fontSize: "clamp(1.1rem, 1.1vw, 3rem)",
    fontWeight: "400",
    alignSelf: "center",
  },
  importModalColumnsContainer: {
    borderBottom: "none",
    border: "1px solid #E6E6E6 ",
    borderRadius: "4px",
    overflow: "auto",
    background: "white",

    ...TABLE_SCROLL_BAR,
  },
  eachColumn: {
    display: "grid",
    paddingBottom: "1rem",
    borderBottom: "1px solid #E6E6E6",
    // gridTemplateColumns: "0.6fr 1fr 0.9fr 0.7fr ",
    gridTemplateColumns: "0.9fr 1.5fr 2.7fr",
    background: "#FFFFFF",
    padding: "0 1rem",
    paddingTop: "1rem",
    "@media (max-width: 768px)": {
      margin: "0 10vw",
    },
  },

  containEachColumnName: {
    display: "flex",
    marginRight: "1.5rem",
    borderRadius: "4px",
    alignItems: "center",

    // flexDirection: "column",
    // justifyContent: "center",
  },
  containBtns: {
    display: "flex",
    columnGap: 8,
    justifyContent: "flex-end",
  },
  modalFooterRightData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  column_label: {
    alignSelf: "center",
  },
  dropdownContainer: {
    // width: "12vw",
    height: "2.5rem",
  },
  iconContainer: {
    width: "2rem",
    cursor: "pointer",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "0.6fr 1fr 0.9fr 1fr",
    background: "#F6F6F6",
    borderBottom: "1px solid #E6E6E6 ",
    borderRadius: "4px 4px 0 0",
  },
  headerTitle: {
    color: "#102f51",
    fontSize: "clamp(0.85rem, 0.8vw, 2rem)",
  },
  headerTitles: {
    color: "#102f51",
    fontSize: "clamp(0.85rem, 0.8vw, 2rem)",
  },
  flexJustifyCenter: {
    paddingLeft: "1rem",
    display: "flex",
    alignItems: "center",
  },
  dropdown: {
    padding: "0.5rem 0",
  },
  containDropdowns: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    paddingTop: "1rem",
  },
  TypoLinecss: {
    padding: "12px",
    borderRight: "1px solid #E6E6E6",
  },
  TypoLinecss2: {
    padding: "12px",
  },
});

const MappingCsvFieldModal = ({
  open,
  setOpen,
  file,
  columns,
  setFile,
  setOpened,
  opened,
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const userDetails = userDetailsSelector?.payload;
  const [openCredit, setOpenCredit] = useState(false);

  const [allColumns, setAllColumns] = useState(columns || []);
  const [newColumns, setNewColumns] = useState([...ALL_COLUMNS]);
  const [Flag, setFlag] = useState(false);

  const [valueState, setValueState] = useState([]);
  const [state, setState] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const fillEmpty = STRINGS.FILL_EMPTY;
  const uploadType = STRINGS.CREATE_AND_UPDATE;
  const selectMatchColumnsOnly = false;
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [columnsUploading, setColumnsUploading] = useState(false);
  const [allFixedColumns, setAllFiedColumns] = useState([]);
  const newResulted = ALL_COLUMNS?.map((item) => {
    return {
      ...item,
      id: item.column,
      label: item.column,
      isCustomField: false,
    };
  });
  
  async function readFileChunk(time, index, fil) {
    let reader = new FileReader();
    let blob = fil.slice(
      EACH_BATCH_SIZE * index,
      EACH_BATCH_SIZE * (index + 1)
    );
    reader.onload = async function (e) {
      try {
        const each_parsed_string = e.target.result;
        const ParsedRes = Papaparse.parse(each_parsed_string);
        const result = [];
        const tempData = ParsedRes.data[0];
        const property_values = [];
        for (let i = 0; i < tempData?.length; i++) {
          let eachObj = { column: tempData?.[i], selected: true };
          const each_property_value = match_import_fields_csv(
            tempData?.[i],
            newResulted,
            []
          );

          eachObj.property_value = each_property_value.property_value;
          eachObj.value = each_property_value.property_value;
          eachObj.property_type = each_property_value.property_type;
          let each_property_label = "";

          eachObj.property_label = each_property_label;

          each_property_value?.property_value &&
            property_values.push(each_property_value?.property_value);
          result.push(eachObj);
        }

        //  key point

        const newResult = result?.map((item) => {
          return {
            ...item,
            id: item.column,
            label: item.column,
            isCustomField: false,
          };
        });
        const dataValue= newResult.filter((item)=> !item.value)
        setAllFiedColumns(newResult);
        setAllColumns(dataValue);
        const data = ALL_COLUMNS?.map((item, i) => {
          return {
            ...item,
            column: item?.column,
            property_value: newResult?.filter(
              (prop_value) => prop_value?.value === item?.column?.toLowerCase()
            )?.[0]?.property_value,
            selected:
              newResult?.filter(
                (selected) => selected?.value === item?.column?.toLowerCase()
              )?.[0]?.selected || true,

            value: newResult?.filter((item11) => {
              if (item11?.value === item?.column?.toLowerCase()) {
                item.column = item11?.value;
                return item.column;
              }
            })?.[0]?.column,
          };
        });

        setNewColumns(data);
        // make file sending endpoint here.
        // return await getRequest(navigate, campaignEndpoints.contactlist );
      } catch (error) {}
    };
    reader.readAsText(blob);
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const initiateUploadingProcess = () => {
    let percentage = undefined;
    const videoUploaderOptions = {
      fileName: file?.name || STRINGS.FOO,
      file: file,
      initialEndpoint: importEndpoints.uploadFileInitialEndpoint,
      multipartEndpoint: importEndpoints.uploadFileMultipartEndpoint,
      finalEndpoint: importEndpoints.uploadFilefinalEndpoint,
      navigate: navigate,
    };
    const uploader = new Uploader(videoUploaderOptions);

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          percentage >= uploadPercentage && setUploadPercentage(percentage);
        }
      })

      .onError((error) => {
        console.error(error);
      });
    uploader.start();
  };

  useEffect(() => {
    initiateUploadingProcess();
    (async () => {
      await readFileChunk(1000, 0, file);
    })();
  }, [file]);

  useEffect(() => {
    (async () => {
      await handlePropertyValueChange(state[0], valueState);
    })();
  }, [valueState]);

  const handlePropertyValueChange = (index, e) => {
    const currentValue = e[0];
    const itemsClone = [...newColumns];
    const prevArray = itemsClone.slice(0, index);
    const nextArray = itemsClone.slice(index + 1);
    const currentElement = itemsClone[index];
    currentElement.value =
      allColumns?.filter((item) => item?.column === currentValue)?.[0]?.label ||
      currentValue;
    if (allFixedColumns?.length > 0) {
      const new_all_columns = allFixedColumns.filter(
        (item) => item.column !== currentValue
      );
     const valueData= itemsClone.map((item)=> item?.value )
     const  finalData= new_all_columns?.filter((item)=> !valueData.includes(item.column) )
      setAllColumns(finalData);
      setNewColumns([...prevArray, currentElement, ...nextArray]);
    }

    setNewColumns([...prevArray, currentElement, ...nextArray]);
  };

  const handleClose = () => {
    setUploadPercentage(0);

    setFile(null);
    setOpen(!open);
  };
  const handleCheckBoxClick = (column) => {
    const newColumn = { ...column };
    newColumn.selected = column?.selected ? false : true;
    newColumn.value = newColumn.selected ? newColumn.value :  undefined

    const all_columns_new = [];
    for (let i in newColumns) {
      if (newColumns?.[i]?.column !== newColumn?.column) {
        all_columns_new.push(newColumns[i]);
      } else {
        all_columns_new.push(newColumn);
      }
    }
    if(!newColumn.selected){
      const myAddCol = allFixedColumns?.filter((item)=> item.column === column.value)
      if(myAddCol.length){
        setAllColumns([...allColumns,myAddCol[0]] || [])
        }
      }
    
    setNewColumns(all_columns_new);
  };

  const handleOpened = () => {
    setOpenCredit(true);
  };
  const handleSubmit = async () => {
    for (let i in newColumns) {
      const id = newColumns[i]?.value;
      const filteredColumns = allFixedColumns?.map((item) => {
        if (id === item.column) {
          return true;
        }
        return false;
      });

      const index = filteredColumns.indexOf(true);
      newColumns[i].index = index;
    }

    for (let i in newColumns) {
      allFixedColumns.map((item) => item.column).indexOf(newColumns[i].value);
    }

    // now manage all validations using checkSelectedFields function.
    const selected_proprty_values = newColumns?.filter(
      (item) => item?.selected === true
    );

    console.log({ selected_proprty_values });
    if (selected_proprty_values?.length <= 0) {
      setErrorMessage({ message: STRINGS.MAP_ATLEAST });

      if (allColumns?.length > 1000) {
        setErrorMessage({ message: STRINGS.LIMIT });
      }
    } else {
      const file_path = localStorage.getItem(STRINGS.UPLOAD_FILE);

      const people_fields = [];
      const company_fields = [];
      for (let i in newColumns) {
        if (newColumns?.[i]?.property_type === STRINGS.PEOPLE) {
          if (newColumns?.[i].selected && newColumns[i]?.value) {
            people_fields.push({
              field_name: newColumns?.[i]?.label,
              column_index: Number(newColumns?.[i]?.index),
            });
          }
        } else {
          if (newColumns?.[i].selected && newColumns[i]?.value) {
            newColumns?.[i]?.property_value &&
              company_fields.push({
                field_name: newColumns?.[i]?.label,
                column_index: Number(newColumns?.[i]?.index),
              });
          }
        }
      }

      // make api call here.
      try {
        setColumnsUploading(true);
        const payload = {
          file_path,
          file_name: file?.name,
          data_for_type: STRINGS.PEOPLE,

          people_fields,
          company_fields,
        };

        localStorage.removeItem(STRINGS.UPLOAD_FILE);

        const response = await postRequest(
          navigate,
          importEndpoints.updateFieldCsvMapping,
          payload
        );

        Mixpanel.track(STRINGS.CSV_UPLOAD_SUCCESS);

        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setOpen(false);
        await dispatch(getImportCsv({}, navigate));
      } catch (error) {
        EventEmitter.emit(
          EMITTER_ERROR,
          getErrorMessage(error?.data?.data?.errors || {}, error?.data?.message)
        );
      } finally {
        setColumnsUploading(false);
        setOpen(false);
        setFlag(false);
        setOpened(!opened);
      }
    }
  };

  const cancelHandle = () => {
    setFlag(!Flag);
    setOpened(!opened);
  };
  const handleConfirmTask = () => {
    const selected_proprty_values = newColumns?.filter(
      (item) => item?.selected === true && item?.value !== undefined
    );
    const hasSelectedTrue = newColumns.some((obj) => obj.selected === true);

    if (!hasSelectedTrue) {
      setErrorMessage({ message: STRINGS.MAP_ATLEAST });
    } else if (selected_proprty_values?.length <= 0) {
      setErrorMessage({ message: "Select atleast one column header" });
    } else {
      setErrorMessage("");
      setFlag(!Flag);
    }
  };
  const isDisable = newColumns.filter((item)=> (!item.selected || (item.selected && item.value)))
  const isSelected = isDisable.filter((item) => item.selected)

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEAD,
      label: STRINGS.CONFIRMATION,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <Box className={classes.warningBox}>
        <Typography
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.ARE_YOU_SURE}
        />
      </Box>
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={cancelHandle}
      />
      <Button
        label={STRINGS.SUBMIT}
        variant={"primary"}
        size={"sm36"}
        onClick={
          allColumns?.length >= userDetails?.available_credits
            ? handleOpened
            : handleSubmit
        }
        isLoading={columnsUploading}
      />
    </div>
  );

  const isHandleDisable = () =>{
    if(isDisable.length<2){
      return true
    }
    else if(isSelected.length){
      return false
    }
    else{
      return true
    }
  }

  console.log("NC",newColumns)

  const modalFooterLeftData = <div></div>;

  const modalHeaderData1 = {
    heading: {
      id: STRINGS.HEAD,
      label: STRINGS.MAP_THE_COLUMN,
    },
  };

  const modalBodyData1 = (
    <div className={classes.modalBody}>
      <Box className={classes.importModalColumnsContainer}>
        <Box className={classes.header}>
          <div className={classes.TypoLinecss}>
            <Typography
              size={"sm"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.MATCHED}
            />
          </div>
          <div className={classes.TypoLinecss}>
            <Typography
              size={"sm"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.REACHIQ_PROPERTY}
            />
          </div>
          <div className={classes.TypoLinecss2}>
            <Typography
              size={"sm"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.COLUMN_HEADER}
            />
          </div>
        </Box>

        {newColumns?.map((eachColumn, i) => {
          return eachColumn?.column?.length ? (
            <Box key={eachColumn?.column}>
              <Box className={classes.eachColumn}>
                <Box className={classes.flexJustifyCenter}>
                  <Checkbox
                    checked={ eachColumn?.selected }
                    onClick={() => handleCheckBoxClick(eachColumn)}
                  />
                </Box>
                <Box className={classes.containEachColumnName}>
                  <Typography
                    size={"sm"}
                    color={GREY.SECONDARY}
                    fontWeight={fontWeight.REGULAR}
                    label={eachColumn?.columnLable || ""}
                  />
                </Box>
                <Box className={classes.dropdownContainer}>
                  <AutoCompleteCustom
                    items={allColumns}
                    LeftIcon=""
                    handleClick={() =>
                      setState([
                        i,
                        eachColumn?.property_value ||
                          eachColumn?.column ||
                          eachColumn?.property_type,
                      ])
                    }
                    valueState={[eachColumn?.value] || valueState[0]}
                    setValueState={setValueState}
                    papermenuclass={classes.paperMenu}
                    size={"sm36"}
                  />
                </Box>
              </Box>
            </Box>
          ) : null;
        })}
        <Box></Box>
      </Box>
      {errorMessage?.message?.length ? (
        <ErrorMessage padding={"12px"} message={errorMessage.message} />
      ) : (
        ""
      )}
    </div>
  );
  const modalFooterRightData1 = (
    <div className={classes.modalFooterRightData}>
      <Box className={classes.containBtns}>
        <div className={classes.modalFooterRightData}>
          <Button
            label={STRINGS.CANCEL}
            size={"sm36"}
            variant={"ghost"}
            onClick={handleClose}
          />

          <Button
            label={STRINGS.SUBMIT}
            size={"sm36"}
            variant={"primary"}
            disabled={isHandleDisable()}
            onClick={handleConfirmTask}
          />
        </div>
      </Box>
    </div>
  );
  const modalFooterLeftData1 = <div></div>;

  return (
    <Box>
      {/* Upload Progress bar modal */}
      <Modal open={uploadPercentage !== 100 && open}>
        <ProgressEmitter
          name={file?.name}
          uploadPercentage={uploadPercentage}
          refreshRequired={true}
        />
      </Modal>
      {/* Upload Progress bar modal */}
      <>
        <CustomModal
          width={"42.188vw"}
          close={handleClose}
          open={uploadPercentage === 100 && open}
          header={modalHeaderData1}
          children={modalBodyData1}
          footer={{
            left: modalFooterLeftData1,
            right: modalFooterRightData1,
          }}
        />
      </>

      <CustomModal
        close={handleConfirmTask}
        open={Flag}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
      />

      {opened ? (
        <CreditsForRefferalModal open={openCredit} close={setOpenCredit} />
      ) : (
        ""
      )}
    </Box>
  );
};
export default MappingCsvFieldModal;
