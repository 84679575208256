import React, { useEffect, useState } from "react";
import { STRINGS } from "../../strings";
import { HtmlEditor } from "../../../../components";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import CustomModal from "../../../../components/Modals/CustomModal";
import { Button } from "../../../../components/Buttons";
import classes from "./CrmComp.module.css";
import { Box } from "@mui/material";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import TimeSelector from "../../../../components/TimeSelector/TimeSelector";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import {
  DEBOUNCE_DELAY,
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  OUTCOME_LIST,
  options,
} from "../../../../utils/constants";
import {
  crmEndpoints,
  meetingsEndpoint,
} from "../../../../utils/constants/httpConstants";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import {
  compressString,
  debounceHelper,
  getContactsNames,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import {
  numberCrmValidation,
  stringLengthValidation,
} from "../../../../utils/validations/inputValidations";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import CustomAutoCompleteChip from "../../../../components/Autocomplete/CustomAutoCompleteChip";
import TextArea from "../../../../components/InputFields/TextArea";

const CreateLogMeetingPopUp = ({
  open,
  close,
  contact_id,
  setMeetingListData,
  assigneeList,
  contactDetails,
  SetAssigneeList,
}) => {
  const navigate = useNavigate();
  const [currentFocus, setCurrentFocus] = useState("");
  const [dueTime, setDueTime] = useState("00:00");
  const [outcomeType, setOutcomeType] = useState([""] || []);
  const [outcomeTypeId, setOutcomeTypeId] = useState([""] || []);
  const [durationType, setDurationType] = useState([""] || []);
  const [durationId, setDurationId] = useState([""] || []);
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDiscription] = useState("");
  const [validations, setValidations] = useState({});
  const [fromContacts, setFromContacts] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [meetingNotes, setMeetingNotes] = useState("");
  const [dueDate, setDueDate] = useState(new Date());

  const cancelHandle = () => {
    close(!open);
  };

  const getContactsList = async (value = "") => {
    try {
      const response = await getRequest(
        navigate,
        crmEndpoints.getEmailSuggestions + `?suggestion=${value}`
      );
      SetAssigneeList(response?.data?.data?.records);
      return response?.data?.data?.records;
    } catch (error) { }
  };

  useEffect(() => {
    setOutcomeType([OUTCOME_LIST[0]?.label]);
    setOutcomeTypeId([OUTCOME_LIST[0]?.id]);
    setDurationType([options[0]?.label]);
    setDurationId([options[0]?.id]);
    let hoursTime = new Date().getHours();
    let minutesTime = new Date().getMinutes();
    if (minutesTime >= 45) hoursTime++;
    hoursTime = hoursTime % 24;
    minutesTime += 15;
    minutesTime = minutesTime % 60;
    if (hoursTime.toString().length === 1) {
      hoursTime = "0" + hoursTime;
    }
    if (minutesTime.toString().length === 1) {
      minutesTime = "0" + minutesTime;
    }

    const setTiming = hoursTime + ":" + minutesTime;

    setDueTime(setTiming);
  }, []);

  const createHandle = async () => {
    setLoading(true);

    const IdByContacts = fromContacts?.filter((item) => item.id);

    const validatedTimezone = stringLengthValidation(outcomeType[0]);
    const validatedDuration = numberCrmValidation(durationId[0]);
    const customEditorValue = document?.getElementById("editor_task")?.innerHTML || "";

    if (validatedTimezone?.isValid && validatedDuration?.isValid) {
      try {
        let dateObj = dueDate;
        const NewDate = dueTime?.split(":").map(Number);
        let m = NewDate[1];
        let h = NewDate[0];
        let totalms = h * 60 * 60 * 1000 + m * 60 * 1000;
        let month = new Date(dateObj)?.getUTCMonth() + 1;
        let day = new Date(dateObj)?.getUTCDate();
        let year = new Date(dateObj)?.getUTCFullYear();
        let newdate = year + "-" + month + "-" + day;

        let date;
        if (dueTime) {
          date = new Date(newdate).getTime() + totalms;
        } else {
          date = new Date(newdate).getTime();
        }

        const UpdateDate = new Date(date);
        const formattedDate = UpdateDate.toISOString().slice(0, -5); // Remove the last three digits for milliseconds
        const payload = {
          duration_options: durationId[0],
          attendee_name: IdByContacts[0]?.name,
          outcome: outcomeType[0],
          complete_time: date !== null ? formattedDate : "",
          description: compressString(
            encodeURIComponent(removeTagStyleFromHtml(customEditorValue) || "")
          ),
          attendee_email: IdByContacts[0]?.email,
          contact_id: IdByContacts[0]?.id || contact_id,
          notes: meetingNotes,
        };

        const responseGet = await postRequest(
          navigate,
          meetingsEndpoint.createLogMeetingCrm,
          payload
        );

        EventEmitter.emit(EMITTER_SUCCESS, responseGet?.data?.message);
        close(!open);

        let url2 = `${meetingsEndpoint.getCrmMeetings}?attendee_email=${contactDetails?.email}&contact_id=${contact_id}`;
        const response = await getRequest(navigate, url2);
        setMeetingListData(response?.data?.data?.scheduledMeetings);
      } catch (error) {
        // setLoading(false)
        close(!open);

        EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      }
    } else {
      setLoading(false);
      setValidations({
        timezoneType: validatedTimezone?.message,
        durationId: validatedDuration?.message,
      });
    }
  };

  const handleInputChange = (e) => {
    setMeetingNotes(e.target.value);
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.LOG_MEETING,
      label: STRINGS.LOG_MEETING,
    },
  };

  const modalBodyData = (
    <Box className={classes.modalBody}>
      <div className={classes.flexHead}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.ATTENDEE}
        />

        <CustomAutoCompleteChip
          items={getContactsNames(assigneeList)}
          onChange={(data) => {
            setFromContacts([data]);
          }}
          inputOnchange={(value) => {
            setSearchValue(value);
            debounceSearch(value);
          }}
          placeholder={"Select"}
          variant={"default"}
          defaultValues={fromContacts}
          multiple={false}
        />
      </div>
      <div className={classes.flexHeadPad}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.OUTCOME}
        />

        <AutoCompleteCustom
          paperWidth="40vw"
          items={OUTCOME_LIST}
          LeftIcon=""
          valueState={outcomeType}
          setValueState={setOutcomeType}
          idState={outcomeTypeId}
          setIdState={setOutcomeTypeId}
          papermenuclass={classes.papermenuclassmeeting}
          variant={
            validations?.outcomeType?.length > 1
              ? STRINGS.ERROR
              : STRINGS.DEFAULT
          }
          errormessage={
            validations?.outcomeType?.length !== 0
              ? validations?.outcomeType
              : ""
          }
          size={"sm36"}
        />
      </div>
      <Box className={classes.FLEXED}>
        <Box className={classes.halfwidth}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DATE}
            />

            <CustomDatePicker
              disablePast={true}
              value={dueDate}
              onChange={(newValue) => {
                setDueDate(newValue?.$d);
              }}
              size={"sm36"}
            />
          </div>
        </Box>
        <Box className={classes.halfwidth}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.TIME}
            />
            <Box>
              <TimeSelector time={dueTime} setTime={setDueTime} />
            </Box>
          </div>
        </Box>
      </Box>
      <div className={classes.flexHeadPad}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.MEDIUM}
          label={STRINGS.DURATION}
        />

        <AutoCompleteCustom
          paperWidth="40vw"
          valueState={durationType}
          setValueState={setDurationType}
          idState={durationId}
          setIdState={setDurationId}
          size={"md40"}
          items={options}
          LeftIcon=""
          papermenuclass={classes.papermenuclassmeeting}
          variant={
            validations?.durationId?.length > 1
              ? STRINGS.ERROR
              : STRINGS.DEFAULT
          }
          errormessage={
            validations?.durationId?.length !== 0 ? validations?.durationId : ""
          }
        />
      </div>
      <Box className={classes.warningRenameBoxes}>
        <div className={classes.descriptionPadding}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.DESCRIPTION}
              isRequired
            />
            <Box className={classes.editorContent}>
              <HtmlEditor
                id="editor_task"
                setCurrentFocus={setCurrentFocus}
                currentFocus={currentFocus}
                subject={subject}
                setSubject={setSubject}
                isMeetingTagNotVisible={true}
                isAddTagNotVisible={true}
                isFocusNotRequired
                isAITagNotVisible
                editorHeight="8vh"
                onInput={() => {
                  setDiscription(
                    document?.getElementById("editor_task")?.innerHTML
                  );
                }}
              />
            </Box>
          </div>
        </div>
        <div className={classes.descriptionPadding}>
          <div className={classes.flexHead}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.MEETING_NOTES}
            />
            <Box className={classes.textarea}>
              <TextArea
                variant={"default"}
                isResize={true}
                placeholder={STRINGS.ADD_REASON}
                value={meetingNotes}
                fullWidth
                name="Message"
                maxLength={500}
                onChange={handleInputChange}
              />
            </Box>
          </div>
        </div>
      </Box>
    </Box>
  );

  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        onClick={cancelHandle}
        variant={"ghost"}
        size={"sm36"}
      />
      <Button
        label={STRINGS.CREATE}
        size={"sm36"}
        variant={"primary"}
        disabled={!description.length}
        onClick={createHandle}
        isLoading={loading}
      />
    </div>
  );

  const modalFooterLeftData = <div></div>;
  const debounceSearch = debounceHelper(getContactsList, DEBOUNCE_DELAY);

  return (
    <CustomModal
      width={"41.667vw"}
      close={cancelHandle}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default CreateLogMeetingPopUp;
