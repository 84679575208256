import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { STRINGS } from "../../strings";
import { Button } from "../../../../components/Buttons";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import classes from "./CrmComp.module.css";
import { deleteRequest } from "../../../../Apis/apiWrapper";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import { getSalesContactsList } from "../../../../redux/actions/salesTableAction";
import { useDispatch, useSelector } from "react-redux";
import { filterQuery } from "../../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../../utils/constants";

const DeleteModal = ({ open, setOpen, record_ids, setSearchField }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const querySelector = filterQuery(
    useSelector((state) => state?.allFilters?.payload)
  );
  const deleteMethod = async () => {
    setLoading(true);
    try {
      const payload = {
        query: {
          ids: record_ids,
        },
      };
      const URL = crmEndpoints?.deleteBulk;
      const response = await deleteRequest(URL, payload, navigate);
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setLoading(false);
      dispatch(getSalesContactsList(querySelector, navigate));
      setSearchField("");
      setOpen(false);
    } catch (error) {
      setLoading(false);
      setOpen(false);
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.DELETE,
      label: STRINGS.DELETE,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <TypographyInline
        size={"sm"}
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={STRINGS.ARE_YOU_SURE_DELETE}
      />
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.DELETE}
        variant={"primary"}
        size={"sm36"}
        onClick={() => deleteMethod()}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      variant={"error"}
      close={() => setOpen(false)}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default DeleteModal;
