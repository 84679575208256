
import React from "react";
import { useSelector } from "react-redux";
import { BRAND, GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import { borderRadius } from "../../utils/constants/UI/uiConstants";
import classes from "./Button.module.css";
import TypographyInline from "../Typography/TypographyInline";

const PaginationNumberButton = ({ item, id = "", ...props }) => {

  const styles = {
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: borderRadius.paginationButton,
      height: "32px",
      width: "32px",
      border: "none",
      background: "transparent",
      cursor: "pointer",
    },
    selectedButtonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: borderRadius.paginationButton,
      height: "32px",
      width: "32px",
      border: "none",
      background: "transparent",
      cursor: "pointer",
      boxShadow: `inset -2px 0 0 ${BRAND.PRIMARY}, inset 0 -2px 0 ${BRAND.PRIMARY}, inset 2px 0 0 ${BRAND.PRIMARY}, inset 0 2px 0 ${BRAND.PRIMARY}`,
    },
  };

  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery.data
  );
  const idSelect = paginationDataSelector
  const allFiltersSelector = useSelector((state) => state.allFilters);
  let selectedPage;
  if (id) {
    selectedPage = allFiltersSelector?.payload?.page || idSelect?.[id]?.query?.page || 1;
  }
  else {
    selectedPage = allFiltersSelector?.payload?.page ||
      idSelect?.query?.page ||
      1;
  }
  return (
    <button
      id="pageNumber"
      value={item}
      style={
        selectedPage === item
          ? styles.selectedButtonContainer
          : styles.buttonContainer
      }
      className={`${selectedPage === item
        ? classes.selectedButtonContainer
        : classes.buttonContainer
        }`}
      {...props}
    >
      <TypographyInline
        size={"xs"}
        color={GREY.SECONDARY}
        label={item}
        fontWeight={fontWeight.MEDIUM}
        id={classes.number}
      />
    </button>
  );
};

export default PaginationNumberButton;
