import React, { useState } from "react";
import CustomModal from "../../../../components/Modals/CustomModal";
import { Button } from "../../../../components/Buttons";
import { STRINGS } from "../../strings";
import classes from "./DealsComp.module.css";
import { HtmlEditor } from "../../../../components";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import { crmEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../../utils/constants";
import { compressString } from "../../../../utils/helpers";

const CreateNoteCrmPopUp = ({
  open,
  close,
  deal_id,
  dealDetails,
  getNoteLogList,
}) => {
  console.log({ deal_id });
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const createHandle = async () => {
    setLoading(true);
    let payload = {
      query: {
        body: compressString(encodeURIComponent(description || "")),
        deal_id: deal_id,
      },
    };
    if (dealDetails?.company_id) {
      payload.query.company_id = dealDetails?.company_id;
    }
    let url = `${crmEndpoints.createNotes}`;
    const res = await postRequest(navigate, url, payload);
    EventEmitter.emit(EMITTER_SUCCESS, res.data.message);
    setLoading(false);
    close(false);
    getNoteLogList();
  };
  const cancelHandle = () => {
    close(!open);
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.NOTE,
      label: STRINGS.NOTES,
    },
  };
  const modalBodyData = (
    <div className={classes.noteDetailsContainerSub}>
      <div className={classes.editorContent}>
        <HtmlEditor
          isMeetingTagNotVisible={true}
          isAddTagNotVisible={true}
          isAITagNotVisible
          isFocusNotRequired
          id={"id-editor"}
          onInput={(event) => {
            setDescription(event?.target?.innerHTML);
          }}
          editorHeight={"8.625vw"}
        />
      </div>
    </div>
  );
  const modalFooterRightData = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        onClick={cancelHandle}
        variant={"ghost"}
        size={"sm36"}
      />
      <Button
        disabled={description === ""}
        label={STRINGS.CREATE}
        size={"sm36"}
        variant={"primary"}
        onClick={createHandle}
        isLoading={loading}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      width={"40vw"}
      close={cancelHandle}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};
export default CreateNoteCrmPopUp;
