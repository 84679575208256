import { Box } from "@mui/material";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {  CustomTextField } from "../../../components";
import { emailValidation, nameValidation } from "../../../utils/validations";
import { numberValidation, urlValidation } from "../../../utils/validations/inputValidations";
import { postRequest } from "../../../Apis";
import { useNavigate } from "react-router-dom";
import { contactListEndPoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import classes from "./LeadsComponent.module.css";


const CreateContactModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [website, setWebsite] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [validations, setValidations] = useState({
    firstName: "",
    lastName: "",
    email: "",
    number: "",
    country: "",
    city: "",
    state: "",
    jobTitle: "",
    website: "",
    linkedinUrl: "",
  });

  const handleCreateContact = async (e) => {
    e.preventDefault();
    const validatedFirstName = nameValidation(firstName);
    const validatedlasttName = nameValidation(lastName);
    const validatedEmail = emailValidation(email);
    const validatedNumber = numberValidation(number);
    const validatedCountry = nameValidation(country);
    const validatedState = nameValidation(state);
    const validatedCity = nameValidation(city);
    const validatedJobtitle = nameValidation(jobTitle);
    const validatedLinkedinURL = urlValidation(linkedinUrl);
    const validatedWebsite = urlValidation(website);

    if (
      validatedFirstName.isValid &&
      validatedlasttName.isValid &&
      validatedEmail.isValid &&
      validatedNumber.isValid &&
      validatedCountry.isValid &&
      validatedState.isValid &&
      validatedCity.isValid &&
      validatedJobtitle.isValid &&
      validatedLinkedinURL.isValid &&
      validatedWebsite.isValid
    ) {
      setLoading(true);
      setValidations({
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        country: "",
        city: "",
        state: "",
        jobTitle: "",
        website: "",
        linkedinUrl: "",
      });
      try {
        const payload = {
          first_name: firstName,
          last_name: lastName,
          job_title: jobTitle,
          person_city: city,
          person_state: state,
          person_country: country,
          direct_phone_number: number,
          contact_linkedin_url: linkedinUrl,
          company_website: website,
          email,
        };
        const response = await postRequest(
          navigate,
          contactListEndPoints.createContact,
          payload
        );

        EventEmitter.emit(
          EMITTER_SUCCESS,
          response?.data?.message ||  STRINGS.SUCCESS_MSG_MISPLACED
        );
      } catch (error) {
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.message ||  STRINGS.ERROR_MSG_MISPLACED
        );
      } finally {
        setLoading(false);
      }
    } else {
      setValidations({
        firstName: validatedFirstName.message,
        lastName: validatedlasttName.message,
        email: validatedEmail.message,
        number: validatedNumber.message,
        country: validatedCountry.message,
        city: validatedCity.message,
        state: validatedState.message,
        jobTitle: validatedJobtitle.message,
        linkedinUrl: validatedLinkedinURL.message,
        website: validatedWebsite.message,
      });
    }

  };

  return (
    <Box className={classes.modal}>
      <Box className={classes.containModalHead}>
        <h4 className={classes.createListTitle}>{STRINGS.CREATE_CONTACT}</h4>
        <ClearIcon
          fontSize="14"
          style={classes.icon}
          onClick={() => setOpen(!open)}
        />
      </Box>

      <Box component="form" onSubmit={handleCreateContact}>
        <Box className={classes.contain2inputs}>
          <Box className={classes.eachInputLeftBox}>
            <CustomTextField
              label={ STRINGS.FIRST_NAME_CAPS}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.firstName} />
          </Box>

          <Box className={classes.eachInputRightBox}>
            <CustomTextField
              label={ STRINGS.LAST_NAME_CAPS}
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.lastName} />
          </Box>
        </Box>
        <Box className={classes.contain2inputs}>
          <Box className={classes.eachInputLeftBox}>
            <CustomTextField
              label={ STRINGS.EMAIL}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.email} />
          </Box>
          <Box className={classes.eachInputRightBox}>
            <CustomTextField
              type="number"
              label={ STRINGS.CONTACT_NUMBER}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.number} />
          </Box>
        </Box>
        <Box className={classes.contain2inputs}>
          <Box className={classes.eachInputLeftBox}>
            <CustomTextField
              label={ STRINGS.JOB_TITLE}
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.jobTitle} />
          </Box>
          <Box className={classes.eachInputRightBox}>
            <CustomTextField
              label={ STRINGS.PERSON_CITY_CAPS}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.city} />
          </Box>
        </Box>
        <Box className={classes.contain2inputs}>
          <Box className={classes.eachInputLeftBox}>
            <CustomTextField
              label={ STRINGS.PERSON_STATE_CAPS}
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.state} />
          </Box>
          <Box className={classes.eachInputRightBox}>
            <CustomTextField
              label={ STRINGS.PERSON_COUNTRY_CAPS}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.country} />
          </Box>
        </Box>
        <Box className={classes.contain2inputs}>
          <Box className={classes.eachInputLeftBox}>
            <CustomTextField
              label={ STRINGS.LINKEDIN_URL}
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.linkedinUrl} />
          </Box>
          <Box className={classes.eachInputLeftBox}>
            <CustomTextField
              label={ STRINGS.WEBSITE}
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              fullWidth
              required
            />
            <ErrorMessage message={validations.website} />
          </Box>
        </Box>

        <Box className={classes.flexEnd}>
          <div>
            {" "}
            <Button
              label={STRINGS.CREATE_CONTACT}
              size={"sm36"}
              variant={"primary"}
              onClick={handleCreateContact}
              isLoading={loading}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateContactModal;
