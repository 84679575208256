import { Tooltip } from "@mui/material";
import React from "react";
import { BASE, BRAND, GREY } from "../../utils/constants/colors";
import { SHADOW } from "../../utils/constants/UI";
import classes from "./Tooltip.module.css"
import {
  borderRadius,
  fontSize,
  fontWeight,
  lineHeight,
} from "../../utils/constants/UI/uiConstants";

const SIZE_MAPPING = {
  bottom: {
    margin: "0px ",
  },
  bottomLeft: {
    margin: "0px",
    left: "7rem",
  },
  light: {
    backgroundColor: `${BASE.WHITE} `,
    color: `${GREY.PRIMARY} `,
  },
  dark: {
    backgroundColor: `${BRAND.SECONDARY} `,
    color: `${BASE.WHITE} `,
  },
};


const CustomTooltip = ({ theme = "dark", variant = "bottom", ...props }) => {
  const styles = {
    arrow: {
      color: SIZE_MAPPING[theme]?.backgroundColor,
      "&:before": {
        boxShadow: `${SHADOW.md} `,
      },
    },
    popper: {
      zIndex: (props?.nozindex ? "20 " : null),
      "& .MuiTooltip-tooltip": {
        // position: "absolute",
        top: SIZE_MAPPING[variant]?.top,
        left: SIZE_MAPPING[variant]?.left,
        margin: SIZE_MAPPING[variant]?.margin,
      },
    },
    tooltip: {
      whiteSpace: "normal",
      wordBreak: "break-word",
      backgroundColor: SIZE_MAPPING[theme]?.backgroundColor,
      boxShadow: `${SHADOW.md} `,
      color: SIZE_MAPPING[theme]?.color,
      borderRadius: `${borderRadius.primary} `,
      padding: "8px 12px",
      fontSize: `${fontSize.XS} `,
      lineHeight: lineHeight.XS,
      fontWeight: `${fontWeight.MEDIUM} `,
      maxWidth: "15.625vw ",
      maxHeight: "15vh",
    },
  };

  return (
    <Tooltip
      arrow
      placement={variant}
      componentsProps={{ arrow: { sx: styles.arrow }, popper: { sx: styles.popper }, tooltip: { sx: styles.tooltip, id: "modal_body" } }}
      classes={{
        arrow: classes.arrow,
        popper: classes.popper,
        tooltip: classes.tooltip,
      }}
      {...props}
    />
  );
};

export default CustomTooltip;

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in tooltip then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props description :-
// variant :- these are following values 'bottom-end'| 'bottom-start'| 'bottom'| 'left-end'| 'left-start'| 'left'| 'right-end'| 'right-start'| 'right'| 'top-end'| 'top-start'| 'top'.
// theme :- these also have two values either light or dark.
