import React, { useState, useRef, useEffect } from "react";
import moduleClasses from "./TimeSelector.module.css";
import classes from "./TimeSelectorStyles";
import IconComponent from "../Icon/IconComponent";
import { ICON_LABELS, fontSize } from "../../utils/constants/UI";
import { GREY } from "../../utils/constants/colors";

const hourList = [];
const minuteList = ["00", "15", "30", "45"];
for (let i = 0; i < 24; i++) hourList.push(i);

const finalList = [];
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 4; j++) {
    finalList.push(
      String(hourList[i]).padStart(2, "0") +
      ":" +
      String(minuteList[j]).padStart(2, "0")
    );
  }
}

function TimeSelector({
  time = "00:00",
  setTime = () => { },
  removeBorder = false,
  isAbsolute = true
}) {
  const [displayList, setDisplayList] = useState(false);
  const [listIndex, setListIndex] = useState(0);
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");
  const timeRef = useRef(null);
  const hoursRef = useRef();
  const minutesRef = useRef();
  const superContainer = useRef();
  const container = useRef();
  const inputContainer = useRef();

  useEffect(() => {
    if (hour.length === 0 || minute.length === 0) setListIndex(-1);
    let a = Number.parseInt(hour);
    let b = Number.parseInt(minute);
    if (b % 15) setListIndex(-1);
    else setListIndex(a * 4 + b / 15);
  }, [hour, minute]);

  useEffect(() => {
    if (time?.length) {
      let splitTime = time?.split(":");
      let hrs = splitTime[0];
      let min = splitTime[1];
      setHour(hrs);
      setMinute(min);
    }
  }, [time]);

  useEffect(() => {
    if (displayList) {
      setChange();
    }
  }, [displayList]);

  const setChange = () => {
    const lh = timeRef.current;
    const selectedHour = timeRef.current?.querySelector("#selectedTime");
    if (selectedHour) {
      lh.scrollTo({
        top: selectedHour.offsetTop - lh.offsetTop,
      });
    }
  };

  const handlesOpens = (e) => {
    if (e.target !== hoursRef.current && e.target !== minutesRef.current) {
      setDisplayList((cur) => {
        if (!cur) hoursRef.current.focus();
        return !cur;
      });
    } else if (e.target === hoursRef.current) {
      setDisplayList(true);
      hoursRef.current.focus();
    } else if (e.target === minutesRef.current) {
      setDisplayList(true);
      minutesRef.current.focus();
    }
  };

  const setsTimeByClick = (e) => {
    let x = e.target.textContent;
    setDisplayList(false);
    setHour(x.slice(0, 2));
    setMinute(x.slice(3, 5));
    setTime(x.slice(0, 2) + ":" + x.slice(3, 5));
  };

  const handlesBlur = (e) => {
    setHour(hour.padStart(2, "0"));
    setMinute(minute.padStart(2, "0"));
    setTime(hour.padStart(2, "0") + ":" + minute.padStart(2, "0"));
  };

  const handleTimeChangeArrowKey = (e) => {
    if (displayList) {
      console.log(e);
      if (Number.parseInt(e.keyCode) === 38) {
        if (listIndex === 0) {
          setHour("23");
          setMinute("45");
        } else {
          setHour(
            String(Number.parseInt((listIndex - 1) / 4)).padStart(2, "0")
          );
          setMinute(
            String(Number.parseInt((listIndex - 1) % 4) * 15).padStart(2, "0")
          );
          setTime(
            String(Number.parseInt((listIndex - 1) / 4)).padStart(2, "0") +
            ":" +
            String(Number.parseInt((listIndex - 1) % 4) * 15).padStart(2, "0")
          );
        }
      } else if (Number.parseInt(e.keyCode) === 40) {
        setHour(
          String(Number.parseInt(((listIndex + 1) % 96) / 4)).padStart(2, "0")
        );
        setMinute(
          String(Number.parseInt((listIndex + 1) % 4) * 15).padStart(2, "0")
        );
        setTime(
          String(Number.parseInt(((listIndex + 1) % 96) / 4)).padStart(2, "0") +
          ":" +
          String(Number.parseInt((listIndex + 1) % 4) * 15).padStart(2, "0")
        );
      } else if (Number.parseInt(e.keyCode) === 13) {
        if (e.target === hoursRef.current) {
          setHour(hour.padStart(2, "0"));
          setTime(hour.padStart(2, "0") + ":" + minute);
          e.target.blur();
          minutesRef.current.focus();
        } else if (e.target === minutesRef.current) {
          setMinute(minute.padStart(2, "0"));
          setTime(hour + ":" + minute.padStart(2, "0"));
          e.target.blur();
          setDisplayList(false);
        }
      }
    }
  };

  const handlesHoursTextChange = (e) => {
    let x = e.target.value;
    if (x.length === 0) {
      setHour("");
      setTime("" + ":" + minute);
    }
    if (x.length === 1) {
      if (x[0] >= "0" && x[0] <= "9") {
        setHour(x.padStart(2, "0"));
        setTime(x.padStart(2, "0") + ":" + minute);
      }
    } else if (x.length === 2) {
      if (x[1] >= "0" && x[1] <= "9") {
        setHour(x);
        setTime(x + ":" + minute);
      }
    } else {
      if (x[2] >= "0" && x[2] <= "9") {
        if (Number.parseInt(x.slice(1, 3)) <= 23) {
          setHour(x.slice(1, 3));
          setTime(x.slice(1, 3) + ":" + minute);
        } else {
          setHour("00");
          setTime("00" + ":" + minute);
        }
      }
    }
  };

  const handlesMinutesTextChange = (e) => {
    let x = e.target.value;
    if (x.length === 0) {
      setMinute("");
      setTime(hour + ":" + "");
    }
    if (x.length === 1) {
      if (x[0] >= "0" && x[0] <= "9") {
        setMinute(x.padStart(2, "0"));
        setTime(hour + ":" + x.padStart(2, "0"));
      }
    } else if (x.length === 2) {
      if (x[1] >= "0" && x[1] <= "9") {
        setMinute(x.slice(1, 3));
        setTime(hour + ":" + x.slice(1, 3));
      }
    } else {
      if (x[2] >= "0" && x[2] <= "9") {
        if (Number.parseInt(x.slice(1, 3)) <= 59) {
          setMinute(x.slice(1, 3));
          setTime(hour + ":" + x.slice(1, 3));
        } else {
          setMinute("00");
          setTime(hour + ":" + "00");
        }
      }
    }
  };

  return (
    <div
      ref={superContainer}
      style={classes.superContainer}
      onKeyDown={handleTimeChangeArrowKey}
      tabIndex="0"
    >
      <div
        ref={container}
        style={removeBorder ? classes.woBorderContainer : classes.container}
        className={
          removeBorder ? moduleClasses.woBorderContainer : classes.container
        }
        onClick={handlesOpens}
        onBlur={(e) => {
          if (displayList) {
            let xim;
            if (
              e.relatedTarget === superContainer.current ||
              e.relatedTarget === container.current ||
              e.relatedTarget === minutesRef.current ||
              e.relatedTarget === hoursRef.current ||
              e.relatedTarget === inputContainer.current
            )
              xim = 1;
            else setDisplayList(false);
          }
        }}
      >
        <div ref={inputContainer} style={classes.inputContainer}>
          <input
            ref={hoursRef}
            style={classes.timeInput}
            type="text"
            value={hour}
            onChange={handlesHoursTextChange}
            onBlur={handlesBlur}
            onClick={(e) => {
              e.target.setSelectionRange(
                e.target.value.length,
                e.target.value.length
              );
              e.target.focus();
              setDisplayList(true);
            }}
            onKeyDown={(e) => {
              if (Number(e.keyCode) === 39) {
                e.preventDefault();
                minutesRef.current.focus();
              } else if (
                Number(e.keyCode) === 38 ||
                Number(e.keyCode) === 37 ||
                Number(e.keyCode) === 40
              )
                e.preventDefault();
            }}
          />
          <div
            style={{
              color: "#5f5f61",
              fontSize: "inherit",
              lineHeight: "20px",
            }}
            id={moduleClasses.colon}
          >
            :
          </div>
          <input
            ref={minutesRef}
            style={classes.timeInput}
            type="text"
            value={minute}
            onChange={handlesMinutesTextChange}
            onBlur={handlesBlur}
            onClick={(e) => {
              e.target.setSelectionRange(
                e.target.value.length,
                e.target.value.length
              );
              e.target.focus();
              setDisplayList(true);
            }}
            onKeyDown={(e) => {
              if (Number(e.keyCode) === 37) {
                e.preventDefault();
                hoursRef.current.focus();
              } else if (
                Number(e.keyCode) === 38 ||
                Number(e.keyCode) === 39 ||
                Number(e.keyCode) === 40
              )
                e.preventDefault();
            }}
          />
        </div>
        <IconComponent
          iconLabel={ICON_LABELS.ACTION_ARROW}
          fontSize={fontSize.SM}
          color={GREY.SECONDARY}
        />
      </div>
      {displayList ? (
        <div style={isAbsolute ? classes.listContainer : classes.listContainerRelative}>
          <div className="list_hours" style={classes.listHours} ref={timeRef}>
            {finalList.map((item, index) => {
              if (listIndex !== -1) {
                if (listIndex === index) {
                  return (
                    <div
                      id="selectedTime"
                      style={classes.itemDivActive}
                      onClick={setsTimeByClick}
                      onKeyDown={handleTimeChangeArrowKey}
                    >
                      {item}
                    </div>
                  );
                }
              }
              return (
                <div
                  style={classes.itemDiv}
                  onClick={setsTimeByClick}
                  onKeyDown={handleTimeChangeArrowKey}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default TimeSelector;
