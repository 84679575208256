export const STRINGS = {
  DASHBOARD: "Dashboard",
  DASHBOARDS: "Dashboards",
  ALL_DASHBOARD: "All Dashboards",
  CREATE_DASHBOARD: "Create Dashboard",
  CREATE: "Create",
  CREATE_NEW_DASHBOARD: "Create New Dashboard",
  ADD_WIDGETS: "Add Widgets",
  ACTIONS: "Actions",
  COMMON_CLONE: "Clone",
  SAVE_DASHBOARD: "Save Dashboard Layout",
  CREATE_WIDGET: "Create Widget",
  SAVE_REPORT: "Save Report",
  SAVE_ADD: "Save & Add",
  SET_DEFAULT: "Set As Default",
  SET_AS_DEFAULT: "Set as Default",
  EDIT_DASHBOARD: "Edit Dashboard",
  CLONE_DASHBOARD: "Clone Dashboard",
  REFRESH_ALL_REPORTS: "Refresh All Reports",
  DELETE_DASHBOARD: "Delete Dashboard",
  SELECT_REPORT: "Select Report",
  SELECT_WIDGET: "Select Widget",
  CAMPAIGN_REPORT: "Campaign Report",
  SEQUENCE_REPORT: "Sequence Report",
  ACTIVITY_REPORT: "Activity Report",
  ACTIVITY_REPORT_SMALL: "activity Report",
  UNSUBSCRIBE_REPORT: "Unsubscribe Report",
  LEADS_REPORT: "Leads Report",
  LINE_CHART: "Line Chart",
  PIE_CHART: "Pie Chart",
  BAR_CHART: "Bar Chart",
  VERTICAL_CHART: "Vertical Chart",
  TABLE_CHART: "Table Chart",
  SUMMARY_CHART: "Summary Chart",
  CANCEL: "Cancel",
  SAVE: "Save",
  PLACEHOLDER_CREATE_DASHBOARD: "Type dashboard name",
  PERMISSIONS_LABEL: "Shared with",
  PRIVATE_LABEL: "Private",
  EVERYONE_LABEL: "Team",
  DELETE_WARNING_TEXT:
    "Are you sure you want to delete this? This action cannot be undone.",
  DELETE: "Delete",
  DELETE_SMALL: "delete",
  SAVE_CHANGES: "Save Changes",
  UPDATE: "Update",
  DASHBOARD_NAME: "Dashboard Name",
  DASHBOARD_OWNER_EMAIL: "Dashboard Owner Email",
  SET_DEFAULT: "Set as Default",
  SET_DEFAULT_HEADER_LABEL: "Set this as your default dashboard",
  SET_DEFAULT_BODY_LABEL:
    "Set this dashboard as your default view for all your report dashboards.",
  FROM: "From",
  APPLY_FILTER: "Apply Filter",
  APPLY_NEW: "Apply",
  RESET: "Reset",
  SAVE_REPORT: "Save Report",
  DATE_RANGE: "Date Range",
  CAMPAIGNS: "Campaigns",
  SEQUENCES: "Sequences",
  TO: "To",
  TODAY: "Today",
  WEEK: "This Week",
  MONTH: "This Month",
  YESTERDAY: "Yesterday",
  CUSTOM_RANGE: "Custom Range",
  REFRESH: "Refresh",
  REFRESHING: "Refreshing...",
  CLONE_WIDGET: "Clone Widget",
  REMOVE_WIDGET: "Remove Widget",
  REMOVE: "Remove",
  REMOVE_WIDGET_BODY_LABEL:
    " You are about to remove this report. Do you wish to continue?",
  APPLY: "Clone",
  CLONE_WIDGET_WARNING_LABEL:
    "This widget will be saved to your report. You can also add it to a dashboard.",
  REPORT_NAME: "Report Name",
  PLACEHOLDER_WIDGET: "Type Widget Name",
  WIDGET_CLONE_DASHBOARD_PERMISSION: "Add this widget to dashboard?",
  ADD_TO_EXISTING_DASHBOARD: "Add to existing dashboard",
  ADD_TO_NEW_DASHBOARD: "Add to new dashboard",
  RECENT_CAMPAIGNS: "Recent Campaigns",
  RECENT_SEQUENCES: "Recent Sequences",
  DAILY_TASK_REPORT: "Daily Task Report",
  EMPTY_DASHBOARD_DESCRIPTION:
    " Add any reports you want. Tell a story with",
  EMPTY_DASHBOARD_SUB_DESCRIPTION: "your data and share it with your team.",
  EMPTY_WIDGET_DESCRIPTION: "Incorporate different widgets to compile a report on campaigns and sequences,",
  EMPTY_WDIGET_SUBDESCRIPTION: "making it easy to access your lead and unsubscribes reports.",
  ADD_WIDGETS_TO_DASHBOARD: "Add Widgets",
  SELECT_CAMPAIGNS: "Select Campaigns",
  SELECT_SEQUENCES: "Select Sequences",
  DASHBOARD_EDIT_ACCESS: "Who can access this dashboard?",
  SEARCH: "Search",
  MANAGE_ACCESS: "Manage Dashboard Access",
  DASHBOARD_OWNER: "Dashboard Owner",
  DASHBOARD_DESCRIPTION: "Dashboard Description",
  CLOSE: "Close",
  DASHBOARD_DETAILS: "Dashboard Details",
  ACTIVITY_LOGS: "Activity Logs",
  VIEW_ALL_DASHBOARDS: "View all Dashboards",
  SHARE_THIS_DASHBOARD: "Share this Dashboard",
  EMAIL_THIS_DASHBOARD: "Email this Dashboard",
  IS_THIS_RECURRING: "Is this a recurring email?",
  NO_RECURRING: "No, this email will only be sent once",
  YES_RECURRING: "Yes, this is a recurring email",
  INTERNAL_RECIPIENTS: "Internal Recipients*",
  OTHER_RECIPIENTS: "Other Recipients*",
  EMAIL_SUBJECT: "Email Subject*",
  MESSAGE: "Message",
  WRITE_MSG_HERE: "Write message here",
  REPORTS_IN_EXPORT: "Reports in export",
  SCHEDULE: "Schedule",
  TIME_DAY: "Time of day*",
  INCLUDE_ALL_EXPORTS: "Include all exports",
  INCLUDE_SELECTED_EXPORTS: "Include selected exports",
  ADD_OTHER_RECIPIENTS: "Add other recipients",
  ENTERSUBJECT: "Enter Email Subject",
  EDIT: "Edit",
  SETAS_DEF: "Set as default",
  PIN: "Pin",
  UNPIN: "Unpin",
  MANAGE_ACCESS: "Manage Access",
  OWNER: "Owner:",
  ASSIGNED: "Assigned:",
  DATE: "Date:",
  ARE_SURE: "Are you sure you want to delete all selected dashboards? This cannot be undone.",
  DATA_WITHIN: "The information in this activity log is accessible for 12 months.",
  GET_STARTED: "Get started by creating your personalized",
  PERSONALIZED: "dashboard here",
  ACTION_DET: "Action Details",
  PERFORMED: "Performed by",
  TIME: "Time",
  DISABLE_KEY: "operation",
  PERMISSIONS: "Permissions:",
  CLEAR_ALL: "Clear all",
  DELETE_BULK_DASHBOARD_HEADING: "Delete selected dashboards?",
  WIDGET_DETAILS_DESC: "The following results pertain to all campaigns currently active in your account."
};
