import { BRANDING_GET_DETAILS_REQUEST, BRANDINGLIST_GET_DETAILS_SUCCESS, BRANDING_LIST_GET_DETAILS_FAIL, } from "../constants/brandingDetailConstants";

export const brandingDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case BRANDING_GET_DETAILS_REQUEST:
        return { loading: true };
  
      case BRANDINGLIST_GET_DETAILS_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case BRANDING_LIST_GET_DETAILS_FAIL:
        return { loading: false, error: action.payload };

       
    
      default:
        return state;
    }
  };
  
 
  