import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import { ACTIONS, BRAND, GREY } from "../../utils/constants/colors";
import { fontSize, fontWeight, lineHeight } from "../../utils/constants/UI";
import classes from "./RadioButton.module.css"


export default function RadioButton({ items, ...props }) {
  const styles = {
    formControlLabel: {
      marginLeft: "0px !important",
      gap: "4px",
      "& .MuiTypography-root": {
        color: props?.labelColor || GREY.SECONDARY,
        fontSize: fontSize.SM,
        fontWeight: fontWeight.REGULAR,
        lineHeight: lineHeight.SM,
      },
      "& .MuiSvgIcon-root": {
        height: "18px",
        width: "18px",
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
      },
    },
    radioButton: {
      padding: "4px",
      height: "18px",
      width: "18px",
      "&.Mui-checked": {
        color: BRAND.PRIMARY,
        cursor: "pointer",
      },
      "&.Mui-disabled": {
        color: ACTIONS.DISABLED,
      },
    },
  }

  return (
    <FormControl>
      <RadioGroup
        value={props?.value}
        onChange={(e) => props?.onChange(e)}
        row={props?.iscolumnview === "true" ? false : true}
        {...props}
      >
        {items.map((item, index) => {
          return (
            <FormControlLabel
              sx={styles.formControlLabel}
              className={classes.formControlLabel}
              key={index}
              value={item?.id}
              control={<Radio style={styles.radioButton} className={classes.radioButton} />}
              label={item?.label}
              disabled={item?.disabled}
              checked={item?.checked}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

// ------------- NOTE ----------------
// Props Description:-
// items :- This shole be of type array of object like items = [{id: "", label: "", checked: "", disabled: ""}, {}].
// iscolumnview:- This prop is used for displaying radio group as vertical alignment. If this prop not passed then initially redio button will be aligned in row.
