import React from "react";
import { useState } from "react";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import classes from "./CrmComp.module.css";
import { ICON_LABELS, fontWeight } from "../../../utils/constants/UI";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import AvatarName from "../../../components/Avatar/AvatarName";
import InputField from "../../../components/InputFields/InputField";
import IconButton from "../../../components/Buttons/IconButton";
import { useSelector } from "react-redux";
import { dateTimeStringEmailLog } from "../../../utils/helpers";

const AddComment = ({
  createCommentHandler = () => {},
  value,
  setValue = () => {},
  isLoading = false,
  commentsList = [],
  onScrollHandler = () => {},
  updateCommentHandler = () => {},
  deleteModalHandler = () => {},
}) => {
  const userDetailsSelector = useSelector(
    (state) => state?.userDetails?.payload
  );
  const tenantUsersListSelector = useSelector(
    (state) => state?.tenantUsersList?.payload?.tenant_user_list?.data?.data
  );
  const [isDetail, setIsDetail] = useState(false);
  const [isEditable, setIsEditable] = useState("");
  const [editedValue, setEditedValue] = useState({});

  const onScrollApiHandler = (e) => {
    const scrollableDiv = document.getElementById("comments_body");
    const isAtBottom =
      scrollableDiv.scrollTop >=
      scrollableDiv.scrollHeight - scrollableDiv.clientHeight;

    if (isAtBottom) {
      onScrollHandler();
    }
  };

  const commentLastUpdateTimeHandler = (data) => {
    const givenTimestamp = data?.updated_at;
    const currentTimestamp = Date.now();
    const timeDifference = currentTimestamp - givenTimestamp;
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    if (hours >= 24) {
      return dateTimeStringEmailLog(givenTimestamp);
    } else if (hours >= 1) {
      return `${parseInt(hours)} ${
        hours > 1 ? "hrs" : "hr"
      } ${minutes} min ago `;
    } else if (!hours && minutes) {
      if (minutes === 1) {
        return `${minutes} min ago`;
      } else {
        return `${minutes} mins ago`;
      }
    } else {
      return STRINGS.JUST_NOW;
    }
  };

  const getCommentUserName = (comment) => {
    let name = "";
    tenantUsersListSelector?.forEach((element) => {
      if (element?._id === comment?.user_id) {
        name = `${element?.first_name || "-"} ${element?.last_name || "-"}`;
      }
    });
    return name;
  };

  return (
    <>
      {isDetail || commentsList?.length ? (
        <div
          onClick={() => {
            setIsDetail(true);
          }}
          className={classes.commentSectionMainContainer}
        >
          {/* Comment Label and Comment Input Container */}
          <div className={classes.commentLabelInputContainer}>
            <TypographyInline
              color={GREY.PRIMARY}
              label={STRINGS.COMMENT}
              size={"md"}
              fontWeight={fontWeight.SEMIBOLD}
            />
            <form
              className={classes.commentInputContainer}
              onSubmit={(e) => {
                e.preventDefault();
                if (value.length) {
                  createCommentHandler();
                  setValue("");
                }
              }}
            >
              <AvatarName
                name={`${userDetailsSelector?.first_name} ${userDetailsSelector?.last_name}`}
                size={"small"}
              />
              <InputField
                size={"sm36"}
                rows={1}
                placeholder={STRINGS.ADD_A_COMMENT}
                value={value}
                onChange={(e) => setValue(e?.target?.value)}
              />
              <div>
                <IconButton
                  LeftIcon={ICON_LABELS.SEND}
                  isLoading={isLoading}
                  size={"sm36"}
                  variant={"primary"}
                />
              </div>
            </form>
          </div>

          {/* Mapping all the comments. */}
          {commentsList?.length ? (
            <div
              id="comments_body"
              className={
                commentsList?.length < 6
                  ? classes.allCommentsFitContainer
                  : classes.allCommentsContainer
              }
              onScroll={onScrollApiHandler}
            >
              {commentsList?.map((comment, index) => {
                return (
                  // Each comment
                  <div
                    className={classes.eachCommentContainer}
                    key={`logHandler${Math.random() * index}`}
                  >
                    {/* Each comment user detail and edit delete button. */}
                    <div
                      className={classes.commentListDetailOperationContainer}
                    >
                      <div className={classes.commentUserDetail}>
                        <AvatarName
                          name={getCommentUserName(comment)}
                          size={"small"}
                        />
                        <TypographyInline
                          size={"xs"}
                          fontWeight={fontWeight.SEMIBOLD}
                          color={GREY.SECONDARY}
                          label={getCommentUserName(comment)}
                        />
                      </div>

                      <div className={classes.commentActionButtons}>
                        <div>
                          <IconButton
                            LeftIcon={ICON_LABELS.EDIT}
                            onClick={() => {
                              setIsEditable(comment?.id);
                              setEditedValue({
                                [comment.id]: comment?.comments,
                              });
                            }}
                            size={"xs28"}
                            variant={"ghost"}
                          />
                        </div>
                        <div>
                          <IconButton
                            LeftIcon={ICON_LABELS.DELETE}
                            size={"xs28"}
                            variant={"ghost"}
                            onClick={() => {
                              deleteModalHandler(comment);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* COmment label or condition for label changes to input on click of edit button  */}
                    <div>
                      {isEditable === comment?.id ? (
                        <form
                          className={classes.editableComment}
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (editedValue[comment?.id]) {
                              updateCommentHandler(comment, editedValue);
                              setIsEditable("");
                            }
                          }}
                        >
                          <InputField
                            size={"sm36"}
                            rows={1}
                            placeholder={STRINGS.ADD_A_COMMENT}
                            // value={editedValue[comment?.id]}
                            value={editedValue[comment?.id]}
                            onKeyDown={(e) => {
                              e?.stopPropagation();
                            }}
                            onChange={(e) => {
                              e?.preventDefault();
                              setEditedValue({
                                [isEditable]: e?.target?.value,
                              });
                            }}
                            autoFocus="autoFocus"
                          />
                          <div>
                            <IconButton
                              LeftIcon={ICON_LABELS.SEND}
                              isLoading={isLoading}
                              size={"sm36"}
                              variant={"primary"}
                            />
                          </div>
                        </form>
                      ) : (
                        <TypographyInline
                          size={"sm"}
                          fontWeight={fontWeight.MEDIUM}
                          color={GREY.SECONDARY}
                          label={comment?.comments}
                        />
                      )}
                    </div>

                    {/* updated time display */}
                    <div>
                      <TypographyInline
                        color={GREY.SECONDARY}
                        label={commentLastUpdateTimeHandler(comment)}
                        size={"xs"}
                        fontWeight={fontWeight.MEDIUM}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : (
        <div className={classes.addCommentButton}>
          <Button
            size={"sm36"}
            label={STRINGS.ADD_COMMENT}
            LeftIcon={ICON_LABELS.COMMENT}
            variant={"text"}
            onClick={() => {
              setIsDetail(true);
            }}
          />
        </div>
      )}
    </>
  );
};

export default AddComment;

// Props Description :-
// createCommentHandler :- api call handler for creating comment
// value :- comment input value state.
// setValue = comment input setValue state.
// isLoading = button Loading prop for create comment button.
// commentsList = api response of commentLists and it should be in a array of object.
// onScrollHandler = this prop is used for api call on scroll od div container.
//  updateCommentHandler = update api handler,
// deleteModalHandler = this hendler will call when clicked in delete.
