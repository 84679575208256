import React from "react";
import { Styles } from "../Styles";
import SkeletonLoader from "../../../components/Loaders/SkeletonLoader";

const DashboardSkeleton = () => {
  const classes = Styles();
  const arr = [1, 2, 3, 4, 5];
  const arr1 = [1,1,1,1,1,1];
  return (
    <div className={classes.heightSkeletonContainer}>
      <div className={classes.skeletonBodyStlye}>
        {arr.map((val) => {
          return (
            <div className={classes.skeletonWithinBody}>
              <div className={classes.skeletonDisplay}>
                <div className={classes.widthHeader}>
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={"100%"}
                    skeletonHeight={14}
                  />
                </div>
                <div className={classes.skeletonRightDisplay}>
                  <div className={classes.widthHeader18}>
                    <SkeletonLoader
                      type="circular"
                      skeletonWidth={"100%"}
                      skeletonHeight={18}
                    />
                  </div>
                  <div className={classes.widthHeader15}>
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={"100%"}
                      skeletonHeight={20}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${classes.skeletonRightDisplay1} ${classes.skeletonMarginTop}`}
              >
                {arr.map((itm) => {
                  return (
                    <div className={classes.skeletonRightDisplay}>
                      <div className={classes.widthHeader8}>
                        <SkeletonLoader
                          type="circular"
                          skeletonWidth={"100%"}
                          skeletonHeight={8}
                        />
                      </div>
                      <div className={classes.widthRightHeader}>
                        <SkeletonLoader
                          type="rounded"
                          skeletonWidth={"100%"}
                          skeletonHeight={14}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {(val===1 || val ===4) ? 
              <div className={classes.contentSkeletonStyle}>
                {arr1?.map((itm) => {
                  return (
                    <div className={classes.skeletonRightDisplay1}>
                      <div className={classes.widthHeader8}>
                        <SkeletonLoader
                          type="circular"
                          skeletonWidth={"100%"}
                          skeletonHeight={8}
                        />
                      </div>
                      <div className={classes.widthHeader500}>
                        <SkeletonLoader
                          type="rounded"
                          skeletonWidth={"100%"}
                          skeletonHeight={14}
                        />
                      </div>

                    </div>)
                })}
              </div> : 
              (val === 2 ?
              
                <div className={classes.contentSkeletonStyle1}>
                {arr1?.map((itm) => {
                  return (
                <div className={classes.skeletonRightDisplay1}>
                      <div className={classes.widthHeader8}>
                        <SkeletonLoader
                          type="rounded"
                          skeletonWidth={"100%"}
                          skeletonHeight={300}
                        />
                      </div>
                    </div>)
                })}
                </div> 
                 : 

                <div className={classes.contentSkeletonStyle}>
                {arr1?.map((itm) => {
                  return (
                <div className={classes.skeletonRightDisplay1}>
                      <div className={classes.widthHeader8}>
                        <SkeletonLoader
                          type="circular"
                          skeletonWidth={"100%"}
                          skeletonHeight={8}
                        />
                      </div>
                      <div className={classes.widthHeader500}>
                        <hr/>
                      </div>
                    </div>)
                })}
                </div>
                )            

              }
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardSkeleton;
