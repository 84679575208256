import { makeStyles } from "@mui/styles";
import { BACKGROUND_COLOR } from "../../../../theme/colors";
import { INVISIBLE_SCROLL_BAR } from "../../../../utils/constants/styles";
import { BORDER, GREY, PRIMARY } from "../../../../utils/constants/colors";
import { fontSize } from "../../../../utils/constants/UI";
import {
  SCROLL_BAR,
  fontWeight,
} from "../../../../utils/constants/UI/uiConstants";

export const styles = makeStyles({
  flex: {
    display: "flex",
  },
  campaignAuto: {
    overflow: "auto",
    ...SCROLL_BAR,
    // padding: "16px",
  },

  BORDER: { border: "1px solid #D3DAE3",borderRadius:"6px" },
  SubHeader: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    width: "100%",
  },
  socialAuthIcon: {
    marginRight: "12px",
    objectFit: "contain",
    width: "clamp(1.4rem,1.7vw,3.5rem)",
    height: "clamp(1.4rem,1.2vh,2.5rem)",
  },
  socialAuthBtn: {
    fontFamily: "Roboto",
    background: "white",
    border: "1px solid #D3DAE3",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontSize: fontSize.SM,
    borderRadius: "6px",
    width: "100%",
    padding: "8.5px 10px 8.5px 0px",
    justifyContent: "center",
    color: GREY.PRIMARY,
    cursor: "pointer",
  },
  pTag: {
    margin: "1rem 0rem",
  },
  Save: {
    background: "#ff4848",

    marginLeft: "0rem",
    padding: "0.7rem 1.5rem",
    border: "1px solid #ff4848",
    color: "white",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      transition: "300ms",
      background: "#ff4848",
      color: "white",
    },
  },
  hoverData:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    borderRadius:"6px",
    cursor:"pointer",
    padding:"2px",
    "&:hover": {
      transition: "300ms",
      background: "#ECEFF1",
      color: "white",
    },
  },
  hoverData1:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  materialui: {
    width: "100%",

    height: "49px !important",
    border: "1px solid #D3DAE3",
    borderRadius: "4px",
    color: "#626B7E",
    padding: "0 0.8rem",
    "@media (max-width: 1470px)": {
      border: "1px solid #D3DAE3",
    },
  },
  flexMobile: {
    display: "flex",
    paddingRight: "1rem",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      paddingRight: 0,
    },
  },
  flexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    margin: "16px 0px 0px 16px",
    borderRadius: "6px",
    height:"100%",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  title: {
    fontWeight: "600",
    color: "#102F51",
    fontSize: "20px",
    alignSelf: "center",
  },
  security: {
    background: "white",
    // padding: "16px",
    height: "100%",
    overflow: "scroll",
    borderRadius: "6px",
    ...SCROLL_BAR,
  },
  subTitle: {
    color: "#102F51",
    fontWeight: "700",
    fontSize: "15px",
    alignSelf: "center",
  },
  containEachSecurity: {
    borderRadius: "8px",

    marginRight: "1rem",
    height: "auto",

    "@media (max-width: 768px)": {
      width: "100%",
      margin: "1rem 0",
    },
  },
  dFlexSecurity:{
   display:"flex",
   flexDirection:"column",
   gap:"4px",
  },
  deleteAccountBox: {
    padding: "1.2rem",
    border: "1px solid #D3DAE3",
    borderRadius: "8px",
    margin: "1rem 0",
    width: "97.8%",
    marginRight: "1rem",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  securityInput: {
    margin: "1rem 0 0",
    width: "100%",
    border: "1px solid #D3DAE3",
    borderRadius: "4px",
    padding: "14px 17px",
    color: "#626B7E!important",
    fontWeight: "400",
    fontSize: "clamp(12px, 0.729vw, 16px)",
    "&::placeholder": {
      color: "rgba(98, 107, 126, 0.5)",
      fontSize: "clamp(12px, 0.729vw, 16px)",
      fontWeight: "400",
    },
  },
  keysInput: {
    margin: "0.2rem 0",
    display: "block",
    width: "100%",
    border: "1px solid #d3dae3",
    borderRadius: "6px",
    padding: "13px 17px",
    fontWeight: "300",
    fontSize: "14px",
  },
  keysInput1: {
    margin: "0.2rem 0",
    display: "block",
    width: "94%",
    border: "none",
    padding: "7px 10px",
    fontWeight: "300",
    fontSize: "14px",
  },
  keysInput2: {
    margin: "0.2rem 0",
    display: "block",
    width: "94%",
    border: "none",

    fontWeight: "300",
    fontSize: "14px",
  },
  deleteIcon: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    border: "1px solid #D3DAE3",
    background: "#fff",
    borderRadius: "6px",
    color: "#626B7E",
    height: "37px",
    cursor: "pointer",
    marginLeft: "15px",
    "&:hover": {
      backgroundColor: "#d3dae3",
    },
  },
  deleteRight: {
    marginLeft: "3px",
    marginRight: "15px",
  },
  FLEX: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    paddingTop: "4px",
  },
  paddingBtn: {
    width: "max-content",
    display: "flex",
    paddingTop: "16px",
    paddingBottom: "4px",
  },
  mxWd:{
    width: "max-content",
  },
  paddingBtnn: {
    display: "flex",
    paddingTop: "16px",
    paddingBottom: "4px",
    justifyContent: "space-between",
    gap: "8px",
  },
  WidthBox: {
    width: "50%",
  },
  sucurityBtn: {
    background: "#ff4848",
    padding: "10px 20px",
    border: "1px solid #ff4848",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "400",
    borderRadius: "6px",
    marginTop: "16px",
    cursor: "pointer",
    "&:hover": {
      transition: "300ms",
      background: "white",
      color: "#ff4848",
    },
  },
  securityPara: {
    fontWeight: "300",
    letterSpacing: "0.32px",
    color: "#626b7e",
    fontSize: "14px",
    lineHeight: "1.5",
  },
  containSecurityBoxes: {
    display: "flex",
    flexWrap: "wrap",
  },
  containSecurityBoxesWidth: {
    width: "40%",
    paddingLeft:"16px",
    paddingTop:"16px",
    display:"flex",
    flexDirection:"column",
    gap:"16px",

  },
  securityPhoneBox: {
    marginRight: "0",
  },
  paddingData1: {
    padding: "10px 20px",
  },
  logoutBtn: {
    background: "#ff4848",
    padding: "10px 20px",
    border: "1px solid #ff4848",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "400",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      transition: "300ms",
      background: "white",
      color: "#ff4848",
    },
  },
  greyOutBtn: {
    background: "#D3DAE3",
    padding: "10px 20px",
    border: "1px solid #D3DAE3",
    color: "#626B7E",
    fontSize: "16px",
    fontWeight: "400",
    borderRadius: "6px",
  },
  date: {
    color: "#626b7e",
    fontSize: "10px",
    alignSelf: "center",
  },
  containPasswordheading: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "16px",
    // paddingBottom: "10px",
  },

  // Notifications Styles

  notificationSubTitle: {
    color: "#102F51",
    fontSize: "14px",
    fontWeight: "700",
  },
  notificationPara: {
    fontWeight: "300",
    fontSize: "13px",
    width: "100%",
    lineHeight: "1.5",
    margin: "0.5rem 0",
  },
  containGetNotified: {
    padding: "1.5rem 0",
    width: "50%",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  containEmailSend: {
    background: "#f9fafb",
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    borderRadius: "6px",
    "@media (max-width: 768px)": {
      width: "100%",
      padding: "1rem",
    },
  },
  containEmail: {
    alignSelf: "center",
  },
  notifications: {
    background: "white",
    padding: "1rem 1.5rem",
    margin: "1.5rem 0",
    "@media (max-width: 768px)": {
      background: "unset",
    },
  },
  apps: {
    height:"100%",
    background: "white",
    // padding: "1rem",
    // margin: "1.5rem 0 0 0",
    marginTop: "0",
    overflow: "auto",
    ...SCROLL_BAR,
    borderRadius: "6px",
    "@media (max-width: 768px)": {
      background: "unset",
    },
  },
  notificationAction: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "0 1rem",
    borderRight: "1px solid #d3dae3",
    color: "#0B1534",
    alignSelf: "center",
    cursor: "pointer",
  },
  selectedNotificationAction: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "0 1rem",
    color: "#ff4848",
    alignSelf: "center",
  },
  notificationTopics: {
    border: "1px solid #D3DAE3",
    borderRadius: "6px",
    padding: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
  notificationInput: {
    border: "none",
    background: BACKGROUND_COLOR,
    width: "100%",
    paddingLeft: "0.5rem",
    fontWeight: "300",
  },
  containNotificationInput: {
    background: BACKGROUND_COLOR,
    width: "20vw",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    padding: "0.5rem",
    display: "flex",
    justifyContent: "flex-start",
  },
  containNotificationTopics: {
    padding: "1rem",
    background: BACKGROUND_COLOR,
    borderRadius: "4px",
    height: "35vh",
    overflow: "scroll",
    ...INVISIBLE_SCROLL_BAR,
  },

  // Account Default Styles
  accrdionDescription: {
    color: "#616A7D",
    fontWeight: "300",
    fontSize: "14px",
    borderTop: "1px solid #F3F5F7",
    padding: "0.8rem 1rem",
  },
  accountDefaultPara: {
    fontWeight: "400",
    color: "#626B7E",
    fontSize: "14px",
    alignSelf: "center",
    background: "#F9FAFB",
    padding: "0.8rem 1.5rem",
    borderRadius: "4px",
  },
  accountDefaultFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  accountDefaultContainer: {
    background: "white",
    padding: "1rem",
    borderRadius: "4px",
    height: "82vh",
    overflow: "scroll",
    "@media (max-width: 768px)": {
      background: "unset",
    },
    ...INVISIBLE_SCROLL_BAR,
  },
  containInputs: {
    padding: "1rem 0",
    display: "flex",
    flexWrap: "wrap",
  },
  inputTitle: {
    fontWeight: "300",
    fontSize: "13px",
    color: "#102F51",
    letterSpacing: "0.6px",
    alignSelf: "center",
    marginRight: "0.4rem",
  },
  accountDefaultInput: {
    background: "white",
    border: "1px solid #D3DAE3",
    padding: "1rem",
    borderRadius: "4px",
    width: "100%",
    margin: "1rem 0",
    fontWeight: "400",
    fontSize: "12px",
  },
  containInputTitle: {
    display: "flex",
  },
  inputIcon: {
    color: "#ff4848",
    width: "16px",
    height: "16px",
  },
  eachInputBox: {
    width: "30vw",
    marginRight: "1rem",
    "@media (max-width: 768px)": {
      width: "80vw !important",
    },
  },
  redText: {
    color: "#ff4848",
    cursor: "pointer",
  },
  containKeysInput: {
    width: "100%",
  },
  scrollContainer: {
    height: "calc(100vh - 6rem)",
    // padding:"0 0 24px 0",
  },
  grid4to1: {
    display: "grid",
    gridTemplateColumns: "6fr 3fr",
  },

  /// Campaign Setting
  headerWrap: {
    padding:"16px 16px 0px 16px",
    borderBottom: "1px solid #D3DAE3",
    // paddingBottom: "1rem",
  },
  headerWrapCamp: {
    padding:"16px",
    borderBottom: "1px solid #D3DAE3",
    // paddingBottom: "1rem",
  },
  afterTitle: {
    paddingTop: "1rem",
  },
  tabData: {
    borderBottom: "2px solid red",
    borderRadius: "1px",
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 12px",
    cursor: "pointer",
  },
  tabData1: {
    // borderBottom:"2px solid red",
    // borderRadius:"1px",
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 12px",
    cursor: "pointer",
  },
  tabBody: {
    // paddingLeft:"28px",
    background: "#FFFFFF",
    height: "44px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  containerData: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 3px rgba(98, 107, 126, 0.11)",
    borderRadius: "6px",
  },
  bodyContainer: {
    padding: "32px 32px 16px 32px",
    display: "grid",
    gridTemplateColumns: "23.7% 23.7% 23.7% 23.7%",
    gap: "10px",
  },
  bodyContainer1: {
    padding: "32px 32px 16px 32px",
    display: "grid",
    gridTemplateColumns: "23.7% 23.7% 23.7% 23.7%",
    backgroundColor:"white",
    gap: "10px",
  },
  boxContent:{
    padding:"24px",
    display:"flex",
    flexDirection:"column",
    gap:"16px",
    background: "#F8F8F8",
    borderRadius: "8px",
  },
  connectedContainer:{
    paddingTop: "1rem",
    display: "grid",
    width:"65%",
    gridTemplateColumns: "50% 50%",
    gap:"10px"
  },
  mainCont:{
    display:"flex",
    width:"100%",
    gap:"16px"
  },
  leftSectCont:{
    width:"35%",
    padding:".833vw",
    // paddingRight:"16px",
    borderRight:"1px solid #EFEFEF",
    display:"flex",
    flexDirection:"column",
    gap:"16px",

  },
  boxData: {
    padding: "1.667vw",
    background: "#F8F8F8",
    borderRadius: "8px",
    // border:"1px solid #DBDBDB",
    // width:"30%",
  },
  boxData1: {
    // padding: "1.667vw",
    background: "#FFFFFF",
    borderRadius: "8px",
    border:"1px solid #DBDBDB",
    // width:"30%",
  },
  boxData2: {
    // padding: "1.667vw",
    background: "#FFFFFF",
    borderRadius: "8px",
    border:"1px solid #DBDBDB",
    height:"175px",

    // width:"30%",
  },
  headerData: {
    borderBottom: "1px solid #D3DAE3",
    height: "70px",
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    color: "#212833",
    fontWeight: "500",
    fontSize: "clamp(14px,.833vw,22px)",
  },
  bodyData: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0px 30px 30px",
  },
  bodyWithin: {
    display: "flex",
    justifyContent: "flex-start",
  },
  commonLabel: {
    color: "#212833",
    fontSize: "clamp(12px,.729vw,20px)",
    fontWeight: "500",
  },
  marginInput: {
    marginTop: "8px",
  },
  mainSeparator: {
    width: "50%",
    border: "1px solid #D3DAE3",
    borderRadius: "6px",
  },
  SEND: {
    display: "flex",
    gap: "4px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  PADDING: {
    padding: "1.5rem 1rem 1rem 1rem",
  },
  mainSeparator2: {
    width: "50%",
    border: "1px solid #D3DAE3",
    borderRadius: "6px",
    marginTop: "30px",
  },
  commonPadding: {
    padding: "1rem 1rem 1.5rem 1rem",
  },
  commonBtop: {
    borderTop: "1px solid #D3DAE3",
  },
  commonLimit: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "8px",
    padding: "0 1rem",
  },
  commonLimit1: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "8px",
  },
  boxInput: {
    width: "100%",
    border: "1px solid #D3DAE3",
    padding: "5px 5px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
  },
  Connect: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  boxInput1: {
    width: "50%",
    padding: "5px 5px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
  },
  signatue: {
    "& h4": {
      fontSize: "16px",
      color: "#212833",
      fontWeight: "600",
      marginBottom: "6px",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
      color: "#626B7E",
    },
  },
  paddingBtCamp:{
   padding:"16px 0px 10px 16px"
  },
  paddingBtCamp1:{
   padding:"0px 0px 0px 16px"
  },
  campaignField: {
    background: "#F8F8F8",
    borderRadius: "6px",
    padding: "16px",
    width:"73vw",
    "& label": {
      fontSize: "14px",
      color: "#212833",
      fontWeight: "500",
      marginBottom: "8px",
      display: "block",
    },
  },
  widSet:{
   width:"73.833vw",
   paddingLeft:"16px"
  },
  GAP: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  previewHeader: {
    background: GREY.QUATINARY,
    margin: "-20px -20px 20px",
    padding: "15px 20px",
    borderRadius: "6px 6px 0 0",
  },
  settingPreview: {
    background: "#fff",
    height: "100%",
    borderRadius: "6px",
    padding: "20px",
    "& h5": {
      fontWeight: "500",
      fontSize: "20px",
      color: "#212833",
    },
    "& p,& span": {
      fontSize: "14px",
      fontWeight: fontWeight.REGULAR,
      color: GREY.SECONDARY,
    },
  },
  seprator: {
    // borderTop: "1px solid #D3DAE3",
    // marginTop: "16px",
    paddingTop: "12px",
    "& textarea": {
      border: "1px solid #D3DAE3",
      borderRadius: "12px",
      height: "clamp(75px,6.250vw, 150px)",
      width: "100%",
      resize: "none",
      padding: "14px 17px",
      fontSize: "clamp(14px,0.833vw,18px)",
      color: "#626B7E",
      "&::placeholder": {
        color: "rgba(98, 107, 126, 0.5)",

        fontWeight: "400",
      },
    },
    "& h6": {
      fontSize: "14px",
      color: "#212833",
      fontWeight: "500",
    },
    "& input": {
      width: "100%",
      border: "1px solid #D3DAE3",
      borderRadius: "4px",
      padding: "14px 17px",
      color: "#626B7E",
      fontWeight: "400",
      fontSize: "clamp(14px,0.833vw,18px)",
      "&::placeholder": {
        color: "rgba(98, 107, 126, 0.5)",

        fontWeight: "400",
      },
    },
  },
  cancellbtn: {
    background: "#F7F9FB",
    padding: "10px 20px",
    borderRadius: "6px",
    border: "1px solid #D3DAE3",
    fontSize: "16px",
    fontWeight: "400",
    color: "#626B7E",
    cursor: "pointer",
    margin: "15px 0 0 0",
  },
  savebtn: {
    background: "#ff4848",
    padding: "10px 20px",
    border: "1px solid #ff4848",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "400",
    borderRadius: "6px",
    margin: "15px 0 0 0px",
    cursor: "pointer",
    "&:hover": {
      transition: "300ms",
      background: "white",
      color: "#ff4848",
    },
  },
  connectedshow: {
    borderRadius: "4px",
    border: "1px solid #D3DAE3",
    padding: "9.5px 17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      fontSize: "16px",
      fontWeight: "400",
      color: "#626B7E",
    },
  },
  connectGoogle: {
    background: "white",
    border: "1px solid #D3DAE3",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontSize: "16px",
    borderRadius: "6px",
    width: "100%",
    height: "45px",
    color: "#626B7E",
    cursor: "pointer",
    paddingLeft: "17px",
  },
  margTopp: {
    marginTop: "2px",
    color: GREY.SECONDARY,
    fontWeight: fontWeight.REGULAR,
    fontSize: fontSize.SM,
  },
  mTop: {
   marginTop:"16px"
  },
  mBot:{
   marginBottom:"8px"
  },
  appHead: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "20px",
    paddingTop: "16px",
  },
  gmailAppHead: {
    display: "flex",
    justifyContent: "space-between",
  },
  insideBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
  },
  insideBox1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background:"#F8F8F8",
    padding:".833vw",
    borderTopRightRadius:"8px",
    borderTopLeftRadius:"8px",
    borderBottom:"1px solid #DBDBDB",
  },
  socialAuthBtn: {
    background: "white",
    boxShadow:
      "0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04)",
    border: "1px solid #E6E6E6",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontSize: fontSize.SM,
    borderRadius: "6px",
    width: "100%",
    height: "36px",
    padding: "15px",
    justifyContent: "center",
    color: GREY.PRIMARY,
    cursor: "pointer",
    // fontFamily: "Roboto",
  },
  socialAuthDisable: {
    background: GREY.DISABLED,
    boxShadow:
      "0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04)",
    border: "1px solid #E6E6E6",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontSize: fontSize.SM,
    borderRadius: "6px",
    width: "100%",
    height: "36px",
    padding: "15px",
    justifyContent: "center",
    color: GREY.SECONDARY,
    cursor:"no-drop",
    // fontFamily: "Roboto",
  },
  socialAuthIcon: {
    marginRight: "12px",
    objectFit: "contain",
    width: "clamp(1.4rem,1.7vw,3.5rem)",
    height: "clamp(1.4rem,1vh,2.5rem)",
  },
  displayInsideData: {
    display: "flex",
    gap: "4px",
  },
  paddingTopStyle: {
    paddingTop: "1.667vw",
  },
  paddingTopStyle16: {
    paddingTop: ".833vw",
  },
  padding8ts:{
    paddingTop:"8px"
  },
  bottomStyle: {
    display: "flex",
    gap: "2.083vw",
    paddingTop: "6px",
  },
  bottomRightSect: {
    display: "flex",
    gap: "8px",
    paddingTop: "6px",
    alignItems: "center",
  },
  imgHt: {
    width: "85.6px",
    height:"20.95px",
    objectFit: "contain",
    // marginLeft:"-11px",
  },
  connInsideBox:{
    margin:".833vw",
    padding:".833vw",
    borderRadius:"6px",
    border:"1px solid #EFEFEF",
  },
  dsBtwn:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderBottom:"1px solid #EFEFEF",
    padding:"0 0 .833vw 0",
  },
  dsBtwn1:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    padding:".833vw 0 .833vw 0",
  },
  stschp:{
    background:"#ECEFF1",
    padding:"2px 8px",
    borderRadius:"4px",
  },
  centrData:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding:"16px"
  },

  paddingSts:{
    padding:".833vw 0 0 .833vw",
    display:"flex",
    alignItems:"center",
    gap:"4px"
  },
  padding16:{
    padding:"16px"
  },

  hrdlStyle:{
    display:"flex",
    gap:"4px",
  },
  borderRightStyl:{
    borderRight:"1px solid #EFEFEF",
    paddingRight:"4px",

  },
  widthHandle: {
    width: "100%",
  },
  inputHand: {
    display: "flex",
    // justifyContent:"space-between",
    alignItems: "center",
    gap: "6px",
  },
  padding4ts:{
    paddingTop:"4px"
  },
  menuContainer: {
    padding: ".833vw",
  },
  dispFlx: {
    display: "flex",
    justifyContent: "space-between",
    gap: "6px",
  },
  dispFlx1: {
    display: "flex",
    alignItems:"center",
    width:"100%",
    // justifyContent: "space-between",
    gap: "6px",
  },
  dspColflx: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  dspColflx1: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width:"50%"
  },
  dspFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "6p",
    padding: ".833vw",
    borderTop: "1px solid #E6E6E6",
  },
  dspFooterContent: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  hoverStyleFooter: {
    cursor:"pointer",
    "&:hover":{
      color:PRIMARY.W_500
    }
  },
  paddingTopStyle6: {
    paddingTop: "6px",
  },
  widthStyle:{
    width:"6vw"
  },
  imageHtWd:{
    height:"48px",
    width:"114px",
  },
  widthFix:{
    width:"105px"
  },

  ///Connected Esp Style

  connectedContainerEsp:{
    margin:"1.667vw 10vw .833vw 10vw",
    display:"flex",
    flexDirection:"column",
    gap:"16px",
  },
  bodyConnected:{
    width:"100%",
    border:"1px solid #DBDBDB",
    borderRadius:"8px",
  },
  bodyConnected1:{
    width:"100%",
    border:"1px solid #DBDBDB",
    // borderLeft:"1px solid #DBDBDB",
    // borderRight:"1px solid #DBDBDB",
    borderRadius:"8px",
    // borderTopRightRadius:"8px",

  },
  displaySpaceBetwn:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    // borderBottom:"1px solid #DBDBDB",
    padding:"16px",
  },
  displaySpaceBetwn1:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderBottom:"1px solid #DBDBDB",
    padding:"16px",
  },
  displaySpaceBetwnData:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderBottom:"1px solid #DBDBDB",
    paddingBottom:"16px",
  },
  dFlexGap:{
    display:"flex",
    alignItems:"center",
    gap:"4px"
  },
  dFlexGap1:{
    display:"flex",
    alignItems:"center",
    gap:"16px"
  },
  disSpace:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  disSpace2:{
    display:"flex",
    justifyContent:"space-between",
    // alignItems:"center"
  },
  disSpace1:{
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
    gap:"8px",
  },
  paddingStyl:{
    padding:"0 16px 16px 16px"
  },
  flxDt:{
    display:"flex",
    alignItems:"center",
    gap:"2px",
    borderRight:"1px solid #E8E8E8",
    paddingRight:"4px"
  },
  flxDt1:{
    display:"flex",
    alignItems:"center",
    gap:"2px",
  },
  optionSty:{
    display:"flex",
    flexDirection:"column",
    gap:"12px",
    padding:"8px 12px"
  },
  optionCont:{
    display:"flex",
    gap:"4px",
    alignItems:"center",
    cursor:"pointer",
    whiteSpace:"nowrap"
  },
  optionCont1:{
    display:"flex",
    gap:"4px",
    alignItems:"center",
    padding:"8px 0"
  },
  dGrid:{       
    display:"grid",
    gridTemplateColumns:"1fr 1fr"
  },
  borSet1:{
    borderRight:"1px solid #EFEFEF",
    backgroundColor:"#FFFFFF",
    borderRadius:"6px",
  },
  borSet:{
    borderRight:"1px solid #EFEFEF",
  },
  backgrdContent:{
   background: "#F8F8F8",
   padding:"16px",
   display:"flex",
   flexDirection:"column",
   gap:"8px",
   borderRadius:"0 0 6px 6px",

  },
  noRecordSty:{
    display:"flex",
    flexDirection:"column",
    gap:"6px",
    justifyContent:"center",
    alignItems:"center",
    height:"calc(100vh - 270px)"
  },
  noImageSty:{
    height:"20vh"
  },
  isPrimaryStyle:{
    backgroundColor:PRIMARY.W_50,
    padding:"2px 8px",
    borderRadius:"4px",
    
  },
  marginBottomIfErr:{
    marginBottom: "20px"
  },
  borderDataSet:{
    borderRadius:"6px",
    border:"1px solid #d3dae3"
  }
});
