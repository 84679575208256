import React from "react";
import classes from "./screens.module.css";
import Image from "../Image/Image";
import TypographyInline from "../Typography/TypographyInline";
import { Button } from "../Buttons";
import { GREY } from "../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";

const EmptyScreen = ({
  imageSrc = "",
  label = "",
  description = "",
  buttonLabel = "",
  onClick = () => { },
  leftIcon = ICON_LABELS.ADD,
  disabled = false,
  buttonsList = []
}) => {
  return (
    <div className={classes.emptyPropertyBody}>
      {imageSrc ? <Image src={imageSrc} /> : null}
      {label || description ? (
        <div className={classes.emptyPropertyLabels}>
          {label ? (
            <TypographyInline
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={label}
              size={"xl"}
            />
          ) : null}
          {description ? (
            <div className={classes.emptyPropertyDesc}>
              <TypographyInline
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={description}
                size={"sm"}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {buttonLabel ? (
        <div>
          <Button
            label={buttonLabel}
            LeftIcon={leftIcon}
            size={"md40"}
            variant={"primary"}
            onClick={onClick}
            disabled={disabled}
          />
        </div>
      ) :
        <div className={classes.buttonsContainer} >
          {
            buttonsList?.map((item, index) => {
              return <div key={`emptyButton${index}`} >
                <Button
                  label={item?.buttonLabel}
                  LeftIcon={item?.leftIcon}
                  size={item?.size || "md40"}
                  variant={item?.variant || "primary"}
                  onClick={item?.onClick}
                  disabled={item?.disabled}
                />
                {item?.is_file && <input
                  id="file-upload"
                  type="file"
                  style={{ display: "none", cursor: "pointer" }}
                  onChange={item?.onChange}
                  accept=".csv"
                />}
              </div>
            })
          }
        </div>
      }
    </div>
  );
};

export default EmptyScreen;

// Props Description :-
// imageSrc: image src you need to display on empty screen.
// label: label of empty screen.
// description :- sub label comes under the label of empty screen.
// buttonLabel:- this is label of button of empty screen.
// onClick :- onCLick event of button present on empty screen.
// leftIcon :- left icon is the icon for button of empty screen.
// disabled :- this is the key  for button disabled.
// buttonsList :- this is array of buttons if there are more than two buttons. 