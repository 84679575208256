import { makeStyles } from "@mui/styles";
import React from "react";
import { ACTIONS, BASE, GREY, PRIMARY } from "../../utils/constants/colors";
import { fontSize, fontWeight, ICON_LABELS } from "../../utils/constants/UI";
import IconComponent from "../Icon/IconComponent";
import TypographyInline from "../Typography/TypographyInline";

const styles = makeStyles({
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    backgroundColor: (props) =>
      props?.isActive ? PRIMARY.W_500 : ACTIONS.DEFAULT,
    gap: "4px",
    borderRadius: "6px 6px 0px 0px",
    "&:hover": {
      backgroundColor: (props) => !props?.isActive && ACTIONS.ALTERNATE,
    },
  },
  viewNameButton: {
    cursor: "pointer",
    background: "transparent",
    padding: (props) => (props?.isDefault ? "10px 16px" : "10px 0px 10px 16px"),
    border: "none",
    whiteSpace: "nowrap",
  },
  tabIconButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    background: "transparent",
    padding: "10px 16px 10px 0px",
    border: "none",
  },
  defaultIconContainer: {
    position: "relative",
  },
  defaultIcon: {
    position: "absolute",
    top: "-2px",
    right: "2px",
  },
});

const ViewTab = ({
  isActive,
  isDefault,
  tabLabel,
  isPin,
  RightIcon,
  buttonClick = () => {},
  iconClick = () => {},
}) => {
  const classes = styles({ isActive, isDefault });
  return (
    <div className={classes.tabContainer}>
      <button className={classes.viewNameButton} onClick={buttonClick}>
        <TypographyInline
          color={isActive ? BASE.WHITE : GREY.SECONDARY}
          fontWeight={fontWeight.MEDIUM}
          label={tabLabel}
          size={"sm"}
        />
      </button>
      {!isDefault && RightIcon && (
        <button onClick={iconClick} className={classes.tabIconButton}>
          <IconComponent
            color={isActive ? BASE.WHITE : GREY.SECONDARY}
            fontSize={fontSize.MD}
            iconLabel={RightIcon}
          />
        </button>
      )}
      {(isDefault || isPin) && (
        <div className={classes.defaultIcon}>
          <IconComponent
            iconLabel={isDefault ? ICON_LABELS.BOOKMARK : ICON_LABELS.PUSH_PIN}
            color={isActive ? BASE.WHITE : GREY.QUATINARY}
            fontSize={fontSize.XS}
          />
        </div>
      )}
    </div>
  );
};

export default ViewTab;
