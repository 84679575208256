export const STRINGS = {
  string: "string",
  MEETINGS: "Meetings",
  CREATE_MEETING: "Create Meeting URL",
  CANCEL: "Cancel",
  CREATE: "Create Meeting",
  NEXT: "Next",
  INCLUDE_CANCEL:
    "Include cancel and reschedule links in the event description",
  CANCEL_RESCHEDULE: "Cancel & Reschedule",
  uRL: "url",
  CONNECT_WITH_GOOGLE_CALENDAR: "Connect With Google Calendar",
  CONNECT_WITH_OUTLOOK_CALENDAR: "Connect With Outlook Calendar",

  CONNECT_GMAIL_ACC: "Connect your Gmail Account in order",
  CONNECT_GMAIL_ACC2: "to start scheduling meetings",

  CONNECT_OUTLOOK_ACC: "Connect your Outlook Account in order",
  CONNECT_OUTLOOK_ACC2: "to start scheduling meetings",

  ENTER_FIRST_NAME: "Enter First Name",
  COPY_OF: "Copy of",
  CLONE: "clone",
  USER: "user",
  ADD_HOURS: "Add Hours",
  ENTER_EMAIL: "Enter Email",
  NINEAM: "9 AM",
  NINEPM: "9 PM",
  ENTER_LAST_NAME: "Enter last name",
  ENTER_PHONE_NUM: "Enter Phone number",
  ENTER_MOBILE_NUM: "Enter Mobile number",
  ENTER_STREET_ADDRESS: "Enter Street Address",
  ENTER_INTERNAL: "Enter your Internal Name",
  ENTER_EVENT: "Enter your Event Title",
  ENTER_LOCATION: "Enter your Location",
  ENTER_SCHEDULE: "Enter your Schedule Title",
  CALENDAR_CONFIM_BTN: "Calendar Confirm Button Clicked",
  ADD: "Add",
  CREATE_MEETING_BTN: " Create Meeting Button Clicked",
  MEETING_DELETE_SUCCESS: " Meeting Deleted Successfully",
  BOOKED_SUCCESS: "Booked Meeting Edited Successfully ",
  MEETING_CLONED: "Meeting Cloned",
  REACHIQ: "ReachIQ",
  EVENT_SUMMARY: "event_summary",
  UPDATE: "Update",
  DELETE: "Delete",
  EDIT_URL: "Edit Meeting URL",
  CREATE_URL: "Create Meeting URL",
  DELETE_MEETING: "Delete Meeting",
  CANCEL_MEETING: "Cancel ",
  CANCEL_MEETING_CAPS: "Cancel Meeting",
  SAVE: "Save",
  DISABLED: "disabled",
  DISABLE_KEY: "operation",
  BACK: "Back",
  HEAD: "Head",
  first_name: "first_name",

  CANCEL_MEET: "Meeting cancelled with",

  INITIAL_TIME: "00:00",

  MEETING_CANCELLED: " Your email has been canceled, and a",
  MEETING_CANCELLED_2: "  confirmation has been sent to your inbox.",
  last_name: "last_name",
  PLEASE_SELECT_TIMEZONE: "Please select the timezone.",
  PLEASE_SELECT_THE_DURATION: "Please select the duration.",
  BOOKED_MEETINGS: "Booked Meetings",
  BOOKED: "booked",
  MEETINGS_URL: "Meetings URL",
  SEARCH_MEETINGS: "Search Meetings",
  PLEASE_SELECT: "Please select any one Meeting",
  DELETE_SELECTED: "Delete Selected Meetings",
  ARE_YOU_SURE_DELETE_SELECTED_MEETINGS:
    "Are you sure you want to Delete the Selected Meetings?",
  ARE_YOU_SURE_SELETE_SCHEDULED:
    "Are you sure you want to Delete this Scheduled Meeting?",
  AUTOMATION: "Automation",
  SELECT_MORE_PROPERTY: "Select more property",
  CONFIRM: "Confirm",
  OVERVIEW: "Overview",
  TEAM_MEMBERS: "Team Members",
  SCHEDULING: "Scheduling",
  FOOTER: "Powered By Connectome",
  CONNECT: "Connect Now",
  REQUIRE: "Required",
  REQUIRED: "This is a required field",
  SUNDAY: "sunday",
  SUN_CAPS: "Sunday",
  MON_CAPS: "Monday",
  TUES_CAPS: "Tuesday",
  WED_CAPS: "Wednesday",
  THU_CAPS: "Thursday",
  FRI_CAPS: "Friday",
  SAT_CAPS: "Saturday",

  tools: "tools",
  SEND_TEST: "Send test email",
  CLONE_MEETING: "Clone Meeting",
  MONDAY: "monday",
  task_list: "task_list",
  TUESDAY: "tuesday",
  WEDNESDAY: "wednesday",
  SAVE_ADD: "Clone",
  THURSDAY: "thursday",
  FRIDAY: "friday",
  SATURDAY: "saturday",
  EVERYDAY: "everyday",
  WEEKDAYS: "weekdays",
  WEEKEND: "weekend",
  SUCCESS: "success",
  ORGANIZER: "Organizer",
  DESCRIPTION: "Description",
  EVENT_TITLE: "Event Title",
  LOCATION: "Location",
  SIMPLE_USER: "simple-user",
  DAY: "day",
  BOOKED_TRUE: "?booked=true",
  TEST_MAIL_SENT: "Test Mail sent",
  PLEASE_SELECT_TIME: "Please select the timings of same day",
  URL: "demo.reachiq.ai/auth/meetings",
  LENGTH_GREATER_THREE: "Length should be greater than 3 characters.",
  SUCCESSFULLY_DELETED: "Successfully Deleted",
  GOOGLE_CAPTCHA: "The Google captcha field is required.",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  SELECT_DATE_TIME: "Select Date & Time",
  HOW_LONG: "How long do you need?",
  INTERNAL_NAME: "Internal Name",
  SCHEDULE_PAGEINK: "Schedule Page Link",
  AVAILABILITY_DAY: " You have deleted Availability of that day",
  MEETING_NAME: " Meeting Name",
  WHAT_WORK_BEST: "What time works best?",
  NO_TIME_SLOT: " No available Time Slots",
  SCHEDULE_MEETING: "Schedule meeting with",
  FILL_ALL_REQUIRED: "Please complete all mandatory fields below.  ",
  FIRST_NAME: "First Name",
  ADD_CONTACT: "Add Contact Property",
  MEETING_HEAD: "meetings",
  CONFIRMATION_EMAIL: "Confirmation Email",
  CONFIRM_ATTENDEE:
    "   Send a confirmation email to attendees immediately after they schedule a meeting.",
  LAST_NAME: "Last Name",
  EMAIL: "Email",
  EMAIL_ID: "Email ID",
  EMAIL_ADDRESS: "Your Email Address",
  STREET_ADDRESS: "Street Address",
  PHONE_NO: "Phone Number",
  FAX_NO: "Fax number",
  YOUR_INFORMATION: "Your Information",
  DATE: "Date",
  POWERERD_BY_REACHIQ: "Powered by ReachIQ",
  POWERERD_BY: "Powered by",
  DURATION: "Duration",
  MINS: "mins",
  TIME: "Time",
  TIMEZONE: "TimeZone",
  EDIT: "Edit Information",
  MAKE_IT: "Go Back",
  MEETING_BOOKED: "Meeting Booked with",
  MEETING_DETAILS: "Meeting Details",
  SCHEDULE_TITLE: "Schedule Title",
  YOUR_TIME_ZONE: "Your Time Zone*",
  AVAILABILITY_WINDOW: " Availability Window*",
  CAPTCHA: "CAPTCHA",
  FROM: "From",
  ENTER_SCHEDULE_PAGE: "Enter your Schedule page link",
  INCLUDE_CAPTCHA:
    "  Include a CAPTCHA challenge to your booking form to prevent spam.",
  TEST_MAIL: "Test email will be sent to",
  ERROR: "error",
  DEFAULT: "default",
  LIST: "List",
  MEET_LIST: "Meetings",
  LOADING: "Loading",
  BOOK_LIST: "Booked Meetings",
  SEARCH_BOOKED: "Search Booked Meetings",
  ZERO_BOKKED_MEETING: "0 Booked Meeting",
  ZERO_MEETING: "0 Meeting",
  UNDEFINED: "undefined/undefined",
  TO: "To",
  SCHEDULE: "Schedule",
  ADD_SETTINGS: "Additional Settings",
  WHEN_MEETING_CAN_BE_SCHEDULED: "When a meeting can be scheduled",
  SET_THE_PERIOD:
    "Set a period of rolling weeks, or a custom date range, people can book in your calendar",
  MINIMUM_NOTICE_TIME: "Minimum notice time",
  BUFFER_TIME: "Buffer time",
  START_TIME_INCREMENT: "Start time increment",
  PERIOD_IN_WEEKS: "Over a period of rolling weeks",
  CUSTOM_RANGE: "Custom Date Range",
  MINIMUM_AMOUNT_OF_TIME:
    "Minimum amount of time before a meeting can be booked",
  PADDING_AROUND_YOUR_MEETINGS:
    "Padding around your meetings you can't be booked for",
  SET_FREQUENCY: "Set the frequency of your meetings start times.",
  INITIAL: "initials",
  IMAGE: "image",
  EDIT_IMAGE: "Edit",
  ERR_MESSAGE:
    "The image you are trying to upload exceeds the maximum allowed size of 200 KB.   ",
  AVT_ERR_MESSAGE: "Please upload custom image",
  FIFTEEN_MIN: "15 Minutes",
  THIRTY_MIN: "30 Minutes",
  FORTYFIVE_MIN: "45 Minutes",
  ONE_HOUR: "1 Hour",
  ONE_5_HOUR: "1 Hour 30 Minutes",
  TWO_HOUR: "2 Hour",
  THREE_HOUR: "3 Hour",
  NO_BUFFER_TIME: "No Buffer Time",
  THIS_WEEK: "This Week",
  THIS_AND_THAT_WEEK: "This Week and Next Week",
  THREE_WEEKS: "3 Weeks",
  FOUR_WEEKS: "4 Weeks",
  FIVE_WEEKS: "5 Weeks",
  SIX_WEEKS: "6 Weeks",
  ORGANIZER_AVATAR: "Organizer Avatar",
  COMPANY_AVATAR: "Company Avatar",
  CUSTOM_IMAGE: "Custom Image",
  MOBILE_NO: "Mobile Number",
  SELECTED_IMG: "Selected Image",
  IMG_BASE64: "data:image/png;base64,",
  GMAIL_TRUE: "gmail_access=true",
  CUSTOM_DATE_RANGE: "custom_date_range",
  CUSTOM_WEEKS: "custom_weeks",
  SMALL_EDIT: "edit",
  SMALL_CLONE: "clone",
  SCROLL_CONTAINER: "scroll_container",
  WEEKS: " Weeks",
  SMALL_FROM: "from",
  EDITOR: "editor",
  IMG_INPUT: "imageInput",
  CALENDAR_TRUE: "calendar_connect=true",
  CAL_CONNECT: "calendar-connect",
  OUTLOOK_TRUE: "outlook_connect=true",
  GLOGO: "GLogo",
  SMALL_UNDEFINED: "undefined",
  SMALL_HEAD: "head",
  ATTENDEE: "Attendee",
  ADD_REASON: "Add reason",
  CANCEL_MEETING_WITH: "Cancel meeting with",
  EVENT_ID: "e32i7fc08t9ogao4b133g5l83c",
  DELETE_THE: "Delete the",
  ORGANIZER_LIST: "Organizer List",
  APPLY: "Apply",
  CLEAR: "Clear",
  FORM: "Form",
  NOT_REQ: "Not Required",
  FALSE: "false",
  COPY_LINK: "Copy Link",
  COPIED_LINK: "Link Copied",
  PLEASE_CHOOSE_IMAGE: "Please choose the image format to upload in JPG or PNG",
  EVENT_LOC_ID: "eventId=",
  TOKEN_ID: "token=",
  SIGN_IN_WITH: "Sign in with",
  CONNECT_WITH_ANOTHER: "Connect with another Inbox",
  LINE: "-",
  OR: "Or",
  CONNECTED_WITH: "Connected with",
  ARE_YOU_SURE: "Are you sure you want to Delete this Meeting URL?",
  ARE_YOU_SURE_TO_CANCEL: "Are you sure you want to cancel this meeting?",
  STILL_MEET: "Are you still want to edit this meeting?",
  EDIT_MEET: "Edit meeting",
  UPDATE_MEET: "Update meeting",
  CHOOSE_A_MEET_TYPE: "Choose a meeting type",
  ONE_ON_ONE: "One-on-One",
  GROUP: "Group",
  CONTACTS_HAVE: "Contacts have an option to",
  ARRANGE_MEET: "arrange a meeting with an",
  INDIVIDUAL_TEAM: "individual from your team",
  ARRANGE_GRP: "arrange a meeting with multiple",
  PEOPLE_TEAM: "people from your team",
  CRATE: "Create",
  CHOOSE_T_TEAM:
    "Choose the team members you want to participate in this group meeting.",
  ADD_MEMB: "Add Members",
  ADD_TEAM_MEM: "Add Team Member(s)",
  CAL_CONN: "Calendar Connected",
  CAL_NOT_CONN: "Calendar Not-Connected",
  SCHEDULE_FREE: "Schedule your own meetings with ReachIQ for free",
  CONNECT_GOOGLE: "Continue with Google",
  CONNECT_OUTLOOK: "Sign in with Microsoft",
  SIGN_WORK: "Sign with work email",
  GREEN: "green",
  RED: "red",
  MYDATA: "myData",
  MEET_ID: "meetID",
  ALL_UR_MEETINGS:
    "All your meetings – in ONE place. Take complete control of your calendar, today!",
};
