import React, { useEffect, useState } from "react";
import { InputWrapper, Root, StyledTag } from ".";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import { useDispatch } from "react-redux";
import { allFilters } from "../../redux/actions/filterActions";
import { useLocation, useNavigate } from "react-router-dom";
import { invitedUsersList } from "../../redux/actions/authActions";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { emailValidation } from "../../utils/validations";
import ErrorMessage from "../Messages/ErrorMessage";
import { postRequest } from "../../Apis";
import { authEndpoints } from "../../utils/constants/httpConstants";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../utils/constants";
import { LOGIN_PATH } from "../../utils/constants/routes";

import { Button } from "../Buttons";
import { STRINGS } from "../strings";

const MultiSelectTextField = ({ type, filter }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [inputPayload, setInputPayload] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  let { getRootProps, getInputProps, getTagProps, value, setAnchorEl } =
    useAutocomplete({
      id: "customized-hook",
      inputValue: inputPayload,
      multiple: true,
      options: [{ name: "" }],
      getOptionLabel: (option) => option.name,

    });
    const [Value, setValue] = useState(value);

  let inputValue = getInputProps()?.value;
  useEffect(() => {
    if (value?.length >= 0) {
      const params = value?.map((x) => x?.name);
      dispatch(allFilters({ [filter + type]: params }));
      navigate({ pathname: location.pathname });
    }
  }, [inputValue, value, dispatch, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validatedEmail = emailValidation(inputPayload);
    if (validatedEmail.isValid) {
      setErrMsg("");
      value.push({ name: inputPayload?.trim() });
      setInputPayload("");
    } else {
      setErrMsg(validatedEmail.message);
    }
  };

  const handleInviteUsers = async () => {
    const validatedEmail = emailValidation(inputPayload);
    if (value?.length || validatedEmail.isValid) {
      try {
        setLoading(true);
        const user_emails = [...new Set(value?.map((email) => email?.name))];
        if (validatedEmail.isValid) {
          user_emails.push(inputPayload);
        }
        const payload = { user_emails: user_emails || [validatedEmail] };
        const response = await postRequest(
          navigate,
          authEndpoints.inviteUsers,
          payload
        );
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        setValue([])
        dispatch(invitedUsersList(navigate));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.data?.message || "Something went wrong!"
        );
        if (error?.status === 401) {
          navigate(LOGIN_PATH);
        }
      }
    } else {
      setErrMsg("Email is a required field.");
    }
  };

  return (
    <>
      <Root>
        <div {...getRootProps()}>
          <InputWrapper ref={setAnchorEl} style={styles.Test}>
            {Value?.map((option, index) => (
              <StyledTag label={option.name} {...getTagProps({ index })} />
            ))}
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ width: "100%" }}
            >
              <input
                style={styles.input1}
                placeholder="Enter Email Addresses"
                {...getInputProps()}
                onChange={(e) => setInputPayload(e.target.value)}
              />
            </Box>
          </InputWrapper>
        </div>
      </Root>
      {errMsg && <ErrorMessage message={errMsg} />}
      <div style={styles.Button}>
        {" "}
        <Button
          label={STRINGS.INVITE_USER}
          size={"sm36"}
          variant={"primary"}
          onClick={handleInviteUsers}
          isLoading={loading}
        />
      </div>
    </>
  );
};

export default React.memo(MultiSelectTextField);
