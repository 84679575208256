import React from "react";
import { GREY, HELP, INDIGO, NAVY, ORANGE, PINK, PURPLE, SUCCESS, TEAL, WARNING } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import { ERROR } from "../../utils/constants/colors";
import TypographyInline from "../Typography/TypographyInline";

const SIZE_MAPPING = {
  orange: {
    labelColor: ORANGE.W_500,
    backgroundColor: ORANGE.W_50,
  },
  teal: {
    labelColor: TEAL.W_500,
    backgroundColor: TEAL.W_100,
  },
  pink: {
    labelColor: PINK.W_500,
    backgroundColor: PINK.W_50,
  },
  purple: {
    labelColor: PURPLE.W_500,
    backgroundColor: PURPLE.W_50,
  },
  yellow: {
    labelColor: WARNING.W_600,
    backgroundColor: WARNING.W_50,
  },
  green: {
    labelColor: SUCCESS.W_500,
    backgroundColor: SUCCESS.W_50,
  },
  blue: {
    labelColor: HELP.W_500,
    backgroundColor: INDIGO.W_50,
  },
  navy: {
    labelColorColor: GREY.SECONDARY,
    background: NAVY.W_50,
  },
  red: {
    labelColor: ERROR.W_500,
    backgroundColor: ERROR.W_50,
  },
};

const StatusChip = ({ label, variant, ...props }) => {
  const classes = {
    mainContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "4px 12px",
      gap: "2px",
      backgroundColor: SIZE_MAPPING[variant]?.backgroundColor,
      borderRadius: "6px",
      border: "none",
      width: "fit-content",
      textTransform: "capitalize",
    },
  };

  return (
    <div style={classes.mainContainer} {...props}>
      <TypographyInline
        isNoWrap={true}
        id="label"
        label={label}
        size="sm"
        color={SIZE_MAPPING[variant]?.labelColor}
        fontWeight={fontWeight.SEMIBOLD}
      />
    </div>
  );
};

export default StatusChip;

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in StatusChip then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props Description:
// variant :- color of status chip mentioned in figma.
// label :- You want to display on chip.
