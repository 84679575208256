export const STRINGS = {
  PAGINATION_PAGE_LABEL: "Showing",
  PAGINATION_PAGE_RESULTS_LABEL: "of 1000 results",
  COPIED_LABEL: "Copied",
  STEPS: "Steps",
  DOTS: "...",
  MULTIPLE: "Multiple",
  OVERDUE_TEXT: "Overdue",
  EXPORT: "Export",
  NO_RECORD_FOUND: "No Record Found!",
  DEFAULT:"Default",
  
};
