import React, { useEffect, useState } from "react";
import { STRINGS } from "../../strings";
import { HtmlEditor } from "../../../../components";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import CustomModal from "../../../../components/Modals/CustomModal";
import { Button } from "../../../../components/Buttons";
import classes from "./CrmComp.module.css";
import { Box } from "@mui/material";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import TimeSelector from "../../../../components/TimeSelector/TimeSelector";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { AutoCompleteCustom } from "../../../../components/Autocomplete/AutoCompleteCustom";
import {
  DEBOUNCE_DELAY,
  EMITTER_SUCCESS,
  TIMEZONE_LIST,
  options,
} from "../../../../utils/constants";
import {
  crmEndpoints,
  meetingsEndpoint,
} from "../../../../utils/constants/httpConstants";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import {
  compressString,
  debounceHelper,
  getContactsNames,
  removeTagStyleFromHtml,
} from "../../../../utils/helpers";
import {
  numberCrmValidation,
  stringLengthValidation,
} from "../../../../utils/validations/inputValidations";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { useSelector } from "react-redux";
import InputField from "../../../../components/InputFields/InputField";
import {
  CALENDAR_LOGO,
  OUTLOOK_CONNECT,
} from "../../../../utils/constants/assets";
import Image from "../../../../components/Image/Image";
import { CRM_PATH } from "../../../../utils/constants/routes";
import CustomAutoCompleteChip from "../../../../components/Autocomplete/CustomAutoCompleteChip";
import TextArea from "../../../../components/InputFields/TextArea";
import WarningModalMeetingCrm from "./WarningModalMeetingCrm";

const CreateMeetingPopUp = ({
  open,
  close,
  contact_id,
  assigneeList,
  contactDetails,
  checkEmptyConnect,
  SetAssigneeList,
  getMeetings = () => { },
}) => {
  const navigate = useNavigate();
  const [currentFocus, setCurrentFocus] = useState("");
  const [dueTime, setDueTime] = useState("00:00");
  const [timezoneType, setTimezoneType] = useState([""] || []);
  const [timezoneTypeId, setTimezoneTypeId] = useState([""] || []);
  const [durationType, setDurationType] = useState([""] || []);
  const [durationId, setDurationId] = useState([""] || []);
  const [mailState, setMailState] = useState([]);
  const [idMailState, setIdMailState] = useState([]);
  const [mailList, setMailList] = useState([]);
  const [checkEmpty, setCheckEmpty] = useState([]);
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDiscription] = useState("");
  const [validations, setValidations] = useState({});
  const [eventTitle, setEventTitle] = useState("");
  const [fromContacts, setFromContacts] = useState([]);
  const [meetingNotes, setMeetingNotes] = useState("");
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [forceCreate, setForceCreate] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const userDet = useSelector((state) => state.userDetails);
  const [dueDate, setDueDate] = useState(new Date());
  let offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone_objChange = TIMEZONE_LIST?.filter(
    (item) => item?.id === offset
  );
  const cancelHandle = () => {
    close(!open);
  };
  const getContactsList = async (value = "") => {
    try {
      const response = await getRequest(
        navigate,
        crmEndpoints.getEmailSuggestions + `?suggestion=${value}`
      );
      SetAssigneeList(response?.data?.data?.records);
      return response?.data?.data?.records;
    } catch (error) { }
  };

  const createHandle = async () => {
    setLoading(true);
    const IdByContacts = fromContacts?.filter((item) => item.id);

    const validatedAttendeeName = stringLengthValidation(IdByContacts[0]?.label);
    const validatedTimezone = stringLengthValidation(timezoneType[0]);
    const validatedDuration = numberCrmValidation(durationId[0]);
    const validatedEvent = numberCrmValidation(eventTitle);

    const customEditorValue = description?.length
      ? `<div>${description}</div>`
      : "";
    if (
      validatedAttendeeName?.isValid &&
      validatedTimezone?.isValid &&
      validatedDuration?.isValid &&
      validatedEvent.isValid
    ) {
      try {
        let dateObj = dueDate;
        const NewDate = dueTime?.split(":").map(Number);
        let m = NewDate[1];
        let h = NewDate[0];
        let totalms = h * 60 * 60 * 1000 + m * 60 * 1000;
        let month = new Date(dateObj)?.getUTCMonth() + 1;
        let day = new Date(dateObj)?.getUTCDate();
        let year = new Date(dateObj)?.getUTCFullYear();
        let newdate = year + "-" + month + "-" + day;

        let date;
        if (dueTime) {
          date = new Date(newdate).getTime() + totalms;
        } else {
          date = new Date(newdate).getTime();
        }

        const UpdateDate = new Date(date);
        const formattedDate = UpdateDate.toISOString().slice(0, -5); // Remove the last three digits for milliseconds
        const timezone_attendee = TIMEZONE_LIST?.filter(
          (item) => item?.id === timezoneTypeId[0]
        );
        const payload = {
          duration_options: durationId[0],
          attendee_name: IdByContacts[0]?.name,
          attendee_timezone: timezone_attendee[0],
          complete_time: date !== null ? formattedDate : "",
          description: compressString(
            encodeURIComponent(removeTagStyleFromHtml(customEditorValue) || "")
          ),
          organizer_timezone: timezone_objChange[0],
          attendee_email: IdByContacts[0]?.email,
          from_email: userDet?.payload?.email,
          company_id: contact_id,
          force_create: forceCreate,
          event_title: eventTitle,
          from_email: mailState[0],
          notes: meetingNotes,
        };
        if (IdByContacts) {
          payload.contact_id = IdByContacts[0]?.id;
        }
        const responseGet = await postRequest(
          navigate,
          meetingsEndpoint.createCrmMeeting,
          payload
        );

        EventEmitter.emit(EMITTER_SUCCESS, responseGet?.data?.message);
        close(!open);

        getMeetings();
      } catch (error) {
        setLoading(false);

        if (error?.status === 412) {
          setErrMessage(error?.data?.message);

          setOpenWarningModal(true);
        }
      }
    } else {
      setLoading(false);
      setValidations({
        attendeeType: validatedAttendeeName?.message,

        timezoneType: validatedTimezone?.message,
        durationId: validatedDuration?.message,
        eventTitle: validatedEvent?.message,
      });
    }
  };

  const handleInputChange = (e) => {
    setMeetingNotes(e.target.value);
  };
  //handle Google function
  const handleGoogle = () => {
    const URL =
      "https://" +
      process.env.REACT_APP_DEV_REGISTRATION_LINK_WITHOUT_PROTOCAL +
      "/auth/connect-inbox/gmail/" +
      window.location.host +
      "?tenant_path=" +
      encodeURIComponent(`${CRM_PATH}`);
    const anchor = document.createElement("a");
    anchor.href = URL;
    anchor.click();
  };
  //handle Microsoft function

  const handleMicrosoft = () => {
    const URL =
      "https://" +
      process.env.REACT_APP_DEV_REGISTRATION_LINK_WITHOUT_PROTOCAL +
      "/auth/connect-inbox/outlook/" +
      window.location.host +
      "?tenant_path=" +
      encodeURIComponent(`${CRM_PATH}`);
    const anchor = document.createElement("a");
    anchor.href = URL;
    anchor.click();
  };

  //Custom Modal Details --------header,Body,footerLeft and footerRight---------start--------
  const modalHeaderData = {
    heading: {
      id: STRINGS.LOG_MEETING,
      label: STRINGS.CREATE_MEETING,
    },
  };
  const modalBodyData =
    !checkEmpty?.isConnectionEmpty && !checkEmptyConnect ? (
      <Box className={classes.modalBody}>
        <div className={classes.flexHeadTitle}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={`${STRINGS.TITLE}*`}
          />
          <InputField
            size={"sm36"}
            type="text"
            placeholder={STRINGS.ENTER_EVENT_TITLE}
            value={eventTitle}
            onChange={(event) => setEventTitle(event.target.value)}
            variant={
              validations?.eventTitle?.length > 1
                ? STRINGS.ERROR
                : STRINGS.DEFAULT
            }
            errormessage={
              validations?.eventTitle?.length !== 0
                ? validations?.eventTitle
                : ""
            }
          />{" "}
        </div>
        <div className={classes.flexHead}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.ATTENDEE}
          />

          <CustomAutoCompleteChip
            items={getContactsNames(assigneeList)}
            onChange={(data) => {
              setFromContacts([data]);
            }}
            inputOnchange={(value) => {
              debounceSearch(value);
            }}
            placeholder={"Select"}
            variant={
              validations?.attendeeType?.length
                ? STRINGS.ERROR
                : STRINGS.DEFAULT
            }
            errormessage={
              validations?.attendeeType?.length !== 0
                ? validations?.attendeeType
                : ""
            }
            defaultValues={fromContacts}
            multiple={false}
          />
        </div>
        <div className={classes.flexHeadPad}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.ATTENDEE_TIMEZONE}
          />

          <AutoCompleteCustom
            paperWidth="40vw"
            items={TIMEZONE_LIST}
            LeftIcon=""
            valueState={timezoneType}
            setValueState={setTimezoneType}
            idState={timezoneTypeId}
            setIdState={setTimezoneTypeId}
            papermenuclass={classes.papermenuclassmeeting}
            variant={
              validations?.timezoneType?.length > 1
                ? STRINGS.ERROR
                : STRINGS.DEFAULT
            }
            errormessage={
              validations?.timezoneType?.length !== 0
                ? validations?.timezoneType
                : ""
            }
            size={"sm36"}
          />
        </div>
        <div className={classes.flexHeadPad}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.CONNECTED_WITH}
          />

          <AutoCompleteCustom
            paperWidth="40vw"
            LeftIcon=""
            iconSrc={mailList?.iconSrc}
            valueState={[mailState]}
            setValueState={setMailState}
            idState={idMailState}
            setIdState={setIdMailState}
            items={mailList}
            papermenuclass={classes.paperMenu}
            size={"md40"}
          />
        </div>
        <Box className={classes.FLEXED}>
          <Box className={classes.halfwidth}>
            <div className={classes.flexHead}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.DATE}
              />

              <CustomDatePicker
                disablePast={true}
                value={dueDate}
                onChange={(newValue) => {
                  setDueDate(newValue?.$d);
                }}
                size={"sm36"}
              />
            </div>
          </Box>
          <Box className={classes.halfwidth}>
            <div className={classes.flexHead}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.TIME}
              />
              <Box>
                <TimeSelector time={dueTime} setTime={setDueTime} />
              </Box>
            </div>
          </Box>
        </Box>
        <div className={classes.flexHeadPad}>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={STRINGS.DURATION}
          />

          <AutoCompleteCustom
            paperWidth="40vw"
            valueState={durationType}
            setValueState={setDurationType}
            idState={durationId}
            setIdState={setDurationId}
            size={"md40"}
            items={options}
            LeftIcon=""
            papermenuclass={classes.papermenuclassmeeting}
            variant={
              validations?.durationId?.length > 1
                ? STRINGS.ERROR
                : STRINGS.DEFAULT
            }
            errormessage={
              validations?.durationId?.length !== 0
                ? validations?.durationId
                : ""
            }
          />
        </div>
        <Box className={classes.warningRenameBoxes}>
          <div className={classes.descriptionPadding}>
            <div className={classes.flexHead}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.DESCRIPTION}
              />
              <Box className={classes.editorContent}>
                <HtmlEditor
                  isFocusNotRequired
                  isAddTagNotVisible={true}
                  isMeetingTagNotVisible={true}
                  id="editor_task"
                  setCurrentFocus={setCurrentFocus}
                  currentFocus={currentFocus}
                  subject={subject}
                  setSubject={setSubject}
                  isAITagNotVisible
                  editorHeight="8vh"
                  onInput={() => {
                    setDiscription(
                      document?.getElementById("editor_task")?.innerHTML
                    );
                  }}
                />
              </Box>
            </div>
          </div>
          <div className={classes.descriptionPadding}>
            <div className={classes.flexHead}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.MEETING_NOTES}
              />
              <Box className={classes.textarea}>
                <TextArea
                  variant={"default"}
                  isResize={true}
                  placeholder={STRINGS.ADD_REASON}
                  value={meetingNotes}
                  fullWidth
                  name={STRINGS.MESSAGE}
                  maxLength={500}
                  onChange={handleInputChange}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    ) : (
      <Box>
        {" "}
        <Box className={classes.containGmailBtn}>
          <Box className={classes.gBox}>
            <Image
              src={CALENDAR_LOGO}
              className={classes.image}
              alt={STRINGS.GLOGO}
            />
            <Box className={classes.CalendarApi}>
              <TypographyInline
                size={"xl"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.SEMIBOLD}
                label={STRINGS.CONNECT_WITH_GOOGLE_CALENDAR}
              />
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.CONNECT_GMAIL_ACC}
              />
            </Box>
            <Box className={classes.CalendarApiFooter}>
              <Button
                label={STRINGS.CONNECT}
                size={"sm36"}
                variant={"primary"}
                onClick={() => {
                  handleGoogle();
                }}
              />
            </Box>
          </Box>
          <Box>
            <hr className={classes.hline} />
          </Box>
          <Box className={classes.gBox}>
            <Image
              src={OUTLOOK_CONNECT}
              className={classes.image}
              alt={STRINGS.GLOGO}
            />
            <Box className={classes.CalendarApi}>
              <TypographyInline
                size={"xl"}
                color={GREY.PRIMARY}
                fontWeight={fontWeight.SEMIBOLD}
                label={STRINGS.CONNECT_WITH_OUTLOOK_CALENDAR}
              />
              <TypographyInline
                size={"sm"}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.CONNECT_OUTLOOK_ACC}
              />
            </Box>
            <Box className={classes.CalendarApiFooter}>
              <Button
                label={STRINGS.CONNECT}
                size={"sm36"}
                variant={"primary"}
                onClick={() => handleMicrosoft()}
              />
            </Box>
          </Box>
        </Box>{" "}
      </Box>
    );
  const modalFooterRightData =
    !checkEmpty?.isConnectionEmpty && !checkEmptyConnect ? (
      <div className={classes.modalFooterRightData}>
        <Button
          label={STRINGS.CANCEL}
          onClick={cancelHandle}
          variant={"ghost"}
          size={"sm36"}
        />
        <Button
          label={STRINGS.CREATE}
          size={"sm36"}
          variant={"primary"}
          onClick={createHandle}
          isLoading={loading}
        />
      </div>
    ) : (
      ""
    );
  const modalFooterLeftData = <div></div>;

  //Custom Modal Details --------header,Body,footerLeft and footerRight---------end--------

  const debounceSearch = debounceHelper(getContactsList, DEBOUNCE_DELAY);
  useEffect(() => {
    if (forceCreate) {
      createHandle();
    }
  }, [forceCreate]);

  useEffect(() => {
    setTimezoneTypeId([TIMEZONE_LIST[0]?.id]);
    setTimezoneType([TIMEZONE_LIST[0]?.label]);
    setDurationType([options[0]?.label]);
    setDurationId([options[0]?.id]);
    let hoursTime = new Date().getHours();
    let minutesTime = new Date().getMinutes();
    if (minutesTime >= 45) hoursTime++;
    hoursTime = hoursTime % 24;
    minutesTime += 15;
    minutesTime = minutesTime % 60;
    if (hoursTime.toString().length === 1) {
      hoursTime = "0" + hoursTime;
    }
    if (minutesTime.toString().length === 1) {
      minutesTime = "0" + minutesTime;
    }

    const setTiming = hoursTime + ":" + minutesTime;

    setDueTime(setTiming);
    (async () => {
      const responseData = await postRequest(
        navigate,
        meetingsEndpoint.meetingEmailList,
        { type: STRINGS.CRM }
      );
      const mailLists = responseData?.data?.data?.map(function (item) {
        return {
          id: item?.imap_id,
          label: item.email,
        };
      });
      setIdMailState([mailLists[0]?.id]);
      setMailState([mailLists[0]?.label]);
      setMailList(mailLists);
    })();

    (async () => {
      const responseData = await getRequest(
        navigate,
        meetingsEndpoint.isCalendarConnected
      );
      setCheckEmpty(responseData?.data?.data);
    })();
  }, []);
  useEffect(() => {
    if (Object?.keys(contactDetails?.contactData || {})?.length) {
      let contact = getContactsNames([contactDetails?.contactData]);
      setFromContacts(contact[0]);
      SetAssigneeList((oldArr) => [...oldArr, ...contact]);
    }
  }, [contactDetails]);
  return (
    <>
      {" "}
      <CustomModal
        width={"41.667vw"}
        close={cancelHandle}
        open={open}
        header={modalHeaderData}
        children={modalBodyData}
        footer={{
          left: modalFooterLeftData,
          right: modalFooterRightData,
        }}
      />
      {openWarningModal && (
        <WarningModalMeetingCrm
          open={openWarningModal}
          close={setOpenWarningModal}
          setForceCreate={setForceCreate}
          errMessage={errMessage}
        />
      )}
    </>
  );
};

export default CreateMeetingPopUp;
