import React, { useState } from "react";
import { fontSize, fontWeight } from "../../utils/constants/UI/uiConstants";
import classes from "./AutoComplete.module.css";
import { BRAND, GREY, PRIMARY } from "../../utils/constants/colors";
import { Menu, MenuItem } from "@mui/material";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import { ICON_LABELS } from "../../utils/constants/UI";
import TypographyInline from "../Typography/TypographyInline";
import IconComponent from "../Icon/IconComponent";
import InputFieldWoBorder from "../InputFields/InputFieldWoBorder";
import { STRINGS } from "../strings";

const SIZE_MAPPING = {
  xs34: {
    padding: "8px 10px",
  },
  sm34: {
    padding: "8px 10px",
  },
  sm36: {
    padding: "8px 10px",
  },
  md40: {
    padding: "10px",
  },
  lg44: {
    padding: "10px",
  },
};

export const AutoCompleteWoBorder = ({
  items = [],
  subItems = [],
  subItemsLabel = "Sub Label",
  multiple = false,
  isLoading = false,
  size,
  valueState = [],
  setValueState = () => {},
  idState = [],
  setIdState = () => {},
  handleClick = () => {},
  paperWidth,
  dropdownId = "",
  onChange,
  isSearchEnable = false,
  handleSearch = () => {},
  ...props
}) => {
  const styles = {
    dropdownMainContainer: {
      width: "100%",
      cursor: "pointer",
    },
    paperMenu: {
      maxHeight: props?.paperHeight || "30vh",
      width:
        paperWidth ||
        document?.getElementById(dropdownId || "custom-dropdown")?.clientWidth,
      // marginTop: "3px",
      marginTop: "10px",

      boxShadow:
        "0px 12px 8px 0px rgba(24, 24, 28, 0.02), 0px 0px 16px 0px rgba(24, 24, 28, 0.12) !important",
    },
    selectedItem: {
      padding: multiple ? "0px" : SIZE_MAPPING[size]?.padding,
      backgroundColor: PRIMARY.W_50,
      gap: "4px",
      boxShadow: `inset 2px 0px 0px 0px ${BRAND.PRIMARY}`,
    },
    defaultItem: {
      padding: multiple ? "0px" : "8px 10px",
      gap: "4px",
    },
    checkboxContainer: {
      padding: multiple && "8px 0px 10px 8px",
    },
    labelContainer: {
      padding: multiple && "8px 0px 10px 8px",
      width: "100%",
    },
  };
  const [isVisible, setIsVisible] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const onClickHandler = (label, id) => {
    if (multiple) {
      if (valueState?.includes(label)) {
        let newSelectedValue = valueState?.filter((val) => val !== label);
        let newSelectedId = idState?.filter((val) => val !== id);
        setValueState(newSelectedValue);
        setIdState(newSelectedId);
        if (onChange) {
          onChange({ id: newSelectedId, label: newSelectedValue });
        }
      } else {
        setValueState([...valueState, label]);
        setIdState([...idState, id]);
        if (onChange) {
          onChange({
            id: [...idState, id],
            label: [...valueState, label],
          });
        }
      }
    } else {
      setValueState([label]);
      setIdState([id]);
      setIsVisible(false);
      if (onChange) {
        onChange({ id: [id], label: [label] });
      }
    }
  };

  return (
    <div
      style={styles.dropdownMainContainer}
      className={classes.dropdownMainContainer}
      id={dropdownId || "custom-dropdown"}
    >
      <InputFieldWoBorder
        onClick={(e) => {
          if (props?.variant !== "disabled") {
            handleClick();
            setIsVisible(true);
            setAnchor(e?.currentTarget);
          }
        }}
        size={size}
        value={valueState}
        placeholder={props?.placeholder || "Select..."}
        RightIcon={ICON_LABELS.ACTION_ARROW}
        LeftIcon=""
        readOnly={!onChange}
        id={classes.inputField}
        {...props}
      />
      {isVisible && (
        <Menu
          onClose={() => setIsVisible(false)}
          anchorEl={anchor}
          open={isVisible}
          PaperProps={{ style: styles.paperMenu, id: "modal_body" }}
        >
          {isLoading ? (
            <div style={styles.defaultItem}>
              <TypographyInline
                label={"Loading..."}
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
              />
            </div>
          ) : (
            <div>
              {isSearchEnable && (
                <div className={classes.searchFieldContainer}>
                  <InputFieldWoBorder
                    size={"sm36"}
                    onChange={(event) => {
                      handleSearch(event);
                    }}
                    onKeyDown={(event) => event.stopPropagation()}
                    placeholder={STRINGS.SEARCH}
                  />
                </div>
              )}
              {items?.length ? (
                <div>
                  {items?.map((item, index) => {
                    return item?.label?.toString()?.trim()?.length ? (
                      <>
                        <MenuItem
                          style={
                            idState?.filter(
                              (val) => val === (item._id || item?.id)
                            )?.length
                              ? styles.selectedItem
                              : styles.defaultItem
                          }
                          className={
                            idState?.filter(
                              (val) => val === (item._id || item?.id)
                            )?.length
                              ? classes.selectedItem
                              : classes.defaultItem
                          }
                          key={index}
                        >
                          {multiple && (
                            <div style={styles.checkboxContainer}>
                              <Checkbox
                                size={fontSize.SM}
                                checked={idState?.includes(
                                  item?.id || item?._id
                                )}
                                onChange={() =>
                                  onClickHandler(
                                    item?.label,
                                    item?.id || item?._id
                                  )
                                }
                              />
                            </div>
                          )}
                          {item?.iconLabel && (
                            <IconComponent
                              fontSize={fontSize.MD}
                              color={item?.iconColor || GREY.TERTIARY}
                              iconLabel={item?.iconLabel}
                              onClick={() =>
                                onClickHandler(
                                  item?.label,
                                  item?.id || item?._id
                                )
                              }
                            />
                          )}
                          <div style={styles.labelContainer}>
                            <TypographyInline
                              label={item?.label?.toString() || ""}
                              size={size.slice(0, 2)}
                              fontWeight={fontWeight.REGULAR}
                              color={GREY.SECONDARY}
                              onClick={() =>
                                onClickHandler(
                                  item?.label,
                                  item?.id || item?._id
                                )
                              }
                            />
                          </div>
                        </MenuItem>
                      </>
                    ) : null;
                  })}
                </div>
              ) : (
                <div style={styles.defaultItem}>
                  <TypographyInline
                    color={GREY.PRIMARY}
                    size={"sm"}
                    label={STRINGS.NO_RECORD_FOUND}
                    fontWeight={fontWeight.REGULAR}
                  />
                </div>
              )}

              {/* Looping when subItems are available. */}
              {subItems?.length ? (
                <>
                  <div className={classes.subItemLabel}>
                    <TypographyInline
                      color={GREY.PRIMARY}
                      label={subItemsLabel}
                      size={"sm"}
                      fontWeight={fontWeight.SEMIBOLD}
                      isEllipses
                    />
                  </div>
                  {subItems?.map((item, index) => {
                    return item?.label?.trim()?.length ? (
                      <>
                        <MenuItem
                          style={
                            idState?.filter(
                              (val) => val === (item._id || item?.id)
                            )?.length
                              ? styles.selectedItem
                              : styles.defaultItem
                          }
                          className={
                            idState?.filter(
                              (val) => val === (item._id || item?.id)
                            )?.length
                              ? classes.selectedItem
                              : classes.defaultItem
                          }
                          key={index}
                        >
                          {multiple && (
                            <div style={styles.checkboxContainer}>
                              <Checkbox
                                size={fontSize.SM}
                                checked={valueState?.includes(item?.label)}
                                onChange={() =>
                                  onClickHandler(
                                    item?.label,
                                    item?.id || item?._id
                                  )
                                }
                              />
                            </div>
                          )}
                          {item?.iconLabel && (
                            <IconComponent
                              fontSize={fontSize.MD}
                              color={item?.iconColor || GREY.TERTIARY}
                              iconLabel={item?.iconLabel}
                              onClick={() =>
                                onClickHandler(
                                  item?.label,
                                  item?.id || item?._id
                                )
                              }
                            />
                          )}
                          <div style={styles.labelContainer}>
                            <TypographyInline
                              label={item?.label || ""}
                              size={size.slice(0, 2)}
                              fontWeight={fontWeight.REGULAR}
                              color={GREY.SECONDARY}
                              onClick={() =>
                                onClickHandler(
                                  item?.label,
                                  item?.id || item?._id
                                )
                              }
                            />
                          </div>
                        </MenuItem>
                      </>
                    ) : null;
                  })}
                </>
              ) : null}
            </div>
          )}
        </Menu>
      )}
    </div>
  );
};

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in button then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props description :-
// items :- This should contain values for dropdown to display in the form of array of object have id and labes as key of objects.
// multiple :- Will make dropdown as multiple.
// isLoading :- Loading state of dropdown.
// size :- size of dropdown mentioned in figma.
// These below four props are used for values and id of selected value in dropdown. And these must be passed from parent component.
// valueState :- state for selected label
// setValueState :- set state for selected label.
// idState :- state for selected id.
// setIdState :- set state for selected id.
// paperWidth: "This prop is used for menu paper width."
