import React, { useEffect, useState } from "react";
import { STRINGS } from "../../strings";
import { Button } from "../../../../components/Buttons";
import CustomModal from "../../../../components/Modals/CustomModal";
import classes from "./CrmComp.module.css";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { ERROR, GREY } from "../../../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../../../utils/constants/UI";
import CustomAutoCompleteChip from "../../../../components/Autocomplete/CustomAutoCompleteChip";
import { debounceHelper, getContactsNames } from "../../../../utils/helpers";
import { DEBOUNCE_DELAY, EMITTER_ERROR, EMITTER_SUCCESS, MAX_FILE_UPLOAD_LIMIT, getFileExtension, imageType } from "../../../../utils/constants";
import { getRequest, postRequest } from "../../../../Apis";
import { authEndpoints, crmEndpoints, meetingsEndpoint } from "../../../../utils/constants/httpConstants";
import { useNavigate } from "react-router";
import InputField from "../../../../components/InputFields/InputField";
import { HtmlEditor } from "../../../../components";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { nameValidation } from "../../../../utils/validations";
import CustomMenu from "../../../../components/Menu/CustomMenu";
import CustomDatePicker from "../../../../components/DatePicker/CustomDatePicker";
import TimeSelector from "../../../../components/TimeSelector/TimeSelector";
import { Box } from "@mui/material";
import Image from "../../../../components/Image/Image";
import SkeletonLoaderCRMScreens from "../../Contacts/components/SkeletonLoaderCRMScreens";
import { GMAIL_IMG, OUTLOOK_CONNECT } from "../../../../utils/constants/assets";
import { Uploader } from "../../../../utils/helpers/Uploader";

const EmailSendModal = ({
  open = false,
  close = () => { },
  contactId = "",
  getEmails = () => { },
  data = {},
  setPageCount = () => { },
  replyType = "",
  contactDetails

}) => {
  const navigate = useNavigate();

  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [ccVisible, setCcVisible] = useState(false);
  const [bccVisible, setBccVisible] = useState(false);
  const [connectedInbox, setConnectedInbox] = useState([]);
  const [toContacts, setToContacts] = useState([]);
  const [ccContacts, setCcContacts] = useState([]);
  const [bccContacts, setBccContacts] = useState([]);
  const [fromContacts, setFromContacts] = useState({});
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [errors, setErrors] = useState({});
  const [sendEmailOperationTarget, setSendEmailOperationTarget] =
    useState(null);
  const [scheduleTimeSelectModal, setScheduleTimeSelectModal] = useState(false);
  const [sendEmailDate, setSendEmailDate] = useState(new Date());
  const [sendEmailTime, setSendEmailTime] = useState("00:00");
  const [selectedTo, setSelectedTo] = useState([]);
  const [selectedCc, setSelectedCc] = useState([]);
  const [selectedBcc, setSelectedBcc] = useState([]);
  const [selectedFrom, setSelectedFrom] = useState([]);
  const [attachments, setAttachments] = useState({});
  const [loading, setLoading] = useState(true);
  const [isInboxConnected, setIsInboxConnected] = useState(false);
  const isReply = Boolean(Object.keys(data)?.length);

  const validateRequiredFieldHandler = (send_now) => {
    // checking required fields
    const validateBody = nameValidation(encodeURIComponent(document.getElementById(STRINGS.EMAIL_SEND_MODAL_EDITOR_ID)?.innerHTML));
    const validateSubject = nameValidation(subject);
    const validateToContacts = nameValidation(toContacts);
    const validateFromContacts = nameValidation(fromContacts?.email);

    if (
      validateBody?.isValid &&
      validateFromContacts?.isValid &&
      validateSubject?.isValid &&
      validateToContacts?.isValid
    ) {
      sendEmailHandler(send_now);
    } else {
      setErrors({
        toContacts: validateToContacts?.message,
        fromContacts: validateFromContacts?.message,
        subject: validateSubject?.message,
        body: validateBody?.message,
      });
      setSendEmailLoading(false);
    }
  };

  const sendEmailHandler = async (send_now, reply_type = replyType) => {
    try {
      let toEmails = toContacts?.map((item) => {
        return item?.email;
      });
      let ccEmails = ccContacts?.map((item) => {
        return item?.email;
      });
      let bccEmails = bccContacts?.map((item) => {
        return item?.email;
      });
      let processing_date_time = new Date().getTime();

      if (!send_now) {
        let processingDateTime = `${sendEmailDate?.toDateString()} ${sendEmailTime}`;
        processing_date_time = new Date(processingDateTime).getTime();
      }

      setErrors({});

      let payload = {
        body: encodeURIComponent(document.getElementById(STRINGS.EMAIL_SEND_MODAL_EDITOR_ID)?.innerHTML),
        company_id: contactId,
        subject: subject,
        send_now: send_now,
        from_email: fromContacts?.email,
        to_emails: toEmails,
        cc_emails: reply_type === "reply" ? [] : ccEmails,
        bcc_emails: reply_type === "reply" ? [] : bccEmails,
        processing_date_time: processing_date_time,
      };
      setSendEmailLoading(true);
      const response = await postRequest(
        navigate,
        crmEndpoints.createEmail,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      getEmails(false, 0);
      setSendEmailLoading(false);
      close();
    } catch (error) {
      setSendEmailLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  const replyEmailHandler = async (reply_type = replyType) => {
    try {
      let toEmails = toContacts?.map((item) => {
        return item?.email;
      });
      let ccEmails = ccContacts?.map((item) => {
        return item?.email;
      });
      let bccEmails = bccContacts?.map((item) => {
        return item?.email;
      });

      let payload = {
        body: encodeURIComponent(document.getElementById(STRINGS.EMAIL_SEND_MODAL_EDITOR_ID)?.innerHTML),
        subject: subject,
        from_email: fromContacts?.email,
        to_emails: toEmails,
        cc_emails: reply_type === "reply" ? [] : ccEmails,
        bcc_emails: reply_type === "reply" ? [] : bccEmails,
        message_id: data?.message_id,
      };
      setErrors({});

      setSendEmailLoading(true);
      const response = await postRequest(
        navigate,
        crmEndpoints.replyOnEmail,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      getEmails(false, 0);
      setSendEmailLoading(false);
      close();
    } catch (error) {
      setSendEmailLoading(false);
      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
      console.log("Error ->>>> ", error);
    }
  };

  const getContactsList = async (value = "") => {
    try {
      const response = await getRequest(
        navigate,
        crmEndpoints.getEmailSuggestions + `?suggestion=${value}`
      );
      setContactList(response?.data?.data?.records);
      return response?.data?.data?.records;
    } catch (error) { }
  };

  const createSendActionHandler = (e) => {
    if (isReply) {
      setPageCount(0);
      replyEmailHandler();
    } else {
      setSendEmailOperationTarget(e?.currentTarget);
    }
  };

  const sendEmailModalHeader = {
    heading: {
      id: isReply ? STRINGS.REPLY_EMAIL_SMALL : STRINGS.EMAIL,
      label: isReply ? STRINGS.REPLY_EMAIL : STRINGS.EMAIL,
    },
  };

  const handleAttachmentsUpload = (e) => {
    try {
      // upload files here.
      let files = e.target.files || [];
      const temp_files = {};

      let sumOfsize = 0;

      // file size check.
      for (let i in files) {
        sumOfsize += (files[i].size || 0);
      }

      if (sumOfsize < MAX_FILE_UPLOAD_LIMIT) {
        for (let i = 0; i < files.length; i++) {
          const extension = getFileExtension(files[i]?.name);
          const videoUploaderOptions = {
            fileName: files?.[i]?.name || STRINGS.FOO,
            size: files?.[i]?.size || 0,
            file: files?.[i],
            company_id: contactId,
            typeImage: imageType[extension] ? "image" : "doc",
            initialEndpoint: crmEndpoints.uploadCrmFileInitialEndpoint,
            multipartEndpoint: crmEndpoints.uploadCrmFileMultipartEndpoint,
            finalEndpoint: crmEndpoints.uploadCrmFilefinalEndpoint,
            navigate: navigate,
          };
          if (contactDetails?.company_id) {
            videoUploaderOptions.company_id = contactDetails?.company_id
          }

          // uploader 
          const uploader = new Uploader(videoUploaderOptions);
          uploader.start();

          if (uploader.fileName) {
            temp_files[uploader.fileName] = {
              uploader,
              percentage: 0,
              isFinish: false
            }
          }
        }

        setAttachments(temp_files);
      } else {
        EventEmitter.emit(EMITTER_ERROR, STRINGS.FILE_SIZE_TOO_LARGE);

      }



    } catch (error) {
      console.log('error', error);
    }
  }

  const sendEmailModalRightFooter = (
    isInboxConnected ? <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={close}
      />
      <Button
        label={isReply ? STRINGS.SEND : STRINGS.CREATE}
        variant={"primary"}
        size={"sm36"}
        RightIcon={isReply ? "" : ICON_LABELS.ACTION_ARROW}
        onClick={createSendActionHandler}
        isLoading={sendEmailLoading}
      />
    </div> : null
  );

  const scheduleEmailModalHeader = {
    heading: {
      id: STRINGS.SECHEDULE_SEND_SMALL,
      label: STRINGS.SCHEDULE_SEND,
    },
  };

  const scheduleEmailModalRightFooter = (
    <div className={classes.modalFooterRightData}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => {
          setScheduleTimeSelectModal(false);
        }}
      />
      <Button
        label={STRINGS.SCHEDULE}
        variant={"primary"}
        size={"sm36"}
        onClick={() => {
          validateRequiredFieldHandler(false);
          setScheduleTimeSelectModal(false);
        }}
        isLoading={sendEmailLoading}
      />
    </div>
  );

  const emailSendOperation = [
    {
      id: STRINGS.SEND_NOW_SMALL,
      label: STRINGS.SEND_NOW,
      iconLabel: ICON_LABELS.SEND,
      function: () => validateRequiredFieldHandler(true),
    },
    {
      id: STRINGS.SECHEDULE_SEND_SMALL,
      label: STRINGS.SCHEDULE_SEND,
      iconLabel: ICON_LABELS.SEND_AND_ARCHIVE,
      function: () => setScheduleTimeSelectModal(true),
    },
  ];

  const debounceSearch = debounceHelper(getContactsList, DEBOUNCE_DELAY);

  useEffect(() => {

    if (open) {
      (async () => {
        const response = await getContactsList();

        // Filling all the values in the fields when we are replying on the mail.
        if (isReply) {

          let to_emails = data?.to_emails;
          let cc_emails = data?.cc_emails;
          let bcc_emails = data?.bcc_emails;
          let from_email = data?.from_email;

          // vairable for array of object to be stored as a default values of autocomplete.
          let selected_to = [];
          let selected_cc = [];
          let selected_bcc = [];
          let selected_from = { email: from_email };

          // storing all the emails into an array and appending all into contactLists array.
          let arr = [];
          to_emails?.forEach((element) => {
            let obj = {};
            obj.email = element;
            arr.push(obj);
            selected_to.push(obj);
          });
          cc_emails?.forEach((element) => {
            let obj = {};
            obj.email = element;
            arr.push(obj);
            selected_cc.push(obj);
          });
          bcc_emails?.forEach((element) => {
            let obj = {};
            obj.email = element;
            arr.push(obj);
            selected_bcc.push(obj);
          });
          // visibility on if cc or bcc emails are available.
          if (cc_emails?.length && replyType === "reply_all")
            setCcVisible(true);
          if (bcc_emails?.length && replyType === "reply_all")
            setBccVisible(true);

          setSelectedTo(selected_to);
          if (replyType === "reply_all") {
            setSelectedCc(selected_cc);
            setSelectedBcc(selected_bcc);
          }
          setSelectedFrom(selected_from);

          setToContacts(selected_to);
          setCcContacts(selected_cc);
          setBccContacts(selected_bcc);
          setFromContacts(selected_from);
          setContactList([...arr, ...response]);
          setSubject(data?.subject);
          document.getElementById(STRINGS.EMAIL_SEND_MODAL_EDITOR_ID).innerHTML = data?.body;
        }
      })();

      (async () => {
        try {
          const limit = await getRequest(
            navigate,
            authEndpoints?.getApiKeyDetails
          );
          let gmail_inboxes = limit?.data?.data?.inboxes.gmail;
          let outlook_inboxes = limit?.data?.data?.inboxes.outlook;
          let all_inboxes_data = [];
          gmail_inboxes.forEach((element) => {
            all_inboxes_data = [...all_inboxes_data, { ...element }];
          });
          outlook_inboxes.forEach((element) => {
            all_inboxes_data = [...all_inboxes_data, { ...element }];
          });
          setConnectedInbox(all_inboxes_data);
          if (all_inboxes_data.length) {
            setIsInboxConnected(true)
          } else {
            setIsInboxConnected(false)
          }
          setSelectedFrom(all_inboxes_data?.filter(item => item?.is_primary)?.[0]);
        } catch (err) {
          setIsInboxConnected(false);

        } finally {
          setLoading(false);
        }
        // const response = await getRequest(navigate, campaignEndpoints.EspList);


      })();
    }
  }, [open]);

  const handleGoogle = () => {
    const URL =
      "https://" +
      process.env.REACT_APP_DEV_REGISTRATION_LINK_WITHOUT_PROTOCAL +
      "/auth/connect-inbox/gmail/" +
      window.location.host +
      "?tenant_path=" +
      encodeURIComponent(`${window.location.pathname + window.location.search}`);

    const anchor = document.createElement("a");
    anchor.href = URL;
    anchor.click();
  };

  const handleMicrosoft = () => {
    const URL =
      "https://" +
      process.env.REACT_APP_DEV_REGISTRATION_LINK_WITHOUT_PROTOCAL +
      "/auth/connect-inbox/outlook/" +
      window.location.host +
      "?tenant_path=" +
      encodeURIComponent(`${window.location.pathname + window.location.search}`);

    const anchor = document.createElement("a");
    anchor.href = URL;
    anchor.click();
  };

  return (
    <div>
      {/* Modal for Creat/Send Email  */}
      <CustomModal
        close={close}
        open={open}
        header={sendEmailModalHeader}
        footer={isInboxConnected && {
          right: sendEmailModalRightFooter,
        }}
        width={"50.417vw"}
      >
        {loading ? <SkeletonLoaderCRMScreens accordionLengths={6} buttonsLength={0} /> : !isInboxConnected ? <Box>
          <Box className={classes.containGmailBtn}>
            <Box className={classes.gBox}>
              <Image
                src={GMAIL_IMG}
                className={classes.image}
                alt={STRINGS.GLOGO}
              />
              <Box className={classes.CalendarApi}>
                <TypographyInline
                  size={"xl"}
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={STRINGS.CONNECT_WITH_GOOGLE_CALENDAR}
                />
                <TypographyInline
                  size={"sm"}
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={STRINGS.CONNECT_GMAIL_ACC}
                />
              </Box>
              <Box className={classes.CalendarApiFooter}>
                <Button
                  label={STRINGS.CONNECT}
                  size={"sm36"}
                  variant={"primary"}
                  onClick={() => {
                    handleGoogle();
                  }}
                />
              </Box>
            </Box>
            <Box>
              <hr className={classes.hline} />
            </Box>
            <Box className={classes.gBox}>
              <Image
                src={OUTLOOK_CONNECT}
                className={classes.image}
                alt={STRINGS.GLOGO}
              />
              <Box className={classes.CalendarApi}>
                <TypographyInline
                  size={"xl"}
                  color={GREY.PRIMARY}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={STRINGS.CONNECT_WITH_OUTLOOK_CALENDAR}
                />
                <TypographyInline
                  size={"sm"}
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={STRINGS.CONNECT_OUTLOOK_ACC}
                />
              </Box>
              <Box className={classes.CalendarApiFooter}>
                <Button
                  label={STRINGS.CONNECT}
                  size={"sm36"}
                  variant={"primary"}
                  onClick={() => handleMicrosoft()}
                />
              </Box>
            </Box>
          </Box>
        </Box>
          : <div className={classes.logEmailBody}>
            {/* To Field */}
            <div className={classes.sendEmailLabelFieldContainer}>
              <div className={classes.sendEmailModalLabelDiv}>
                <TypographyInline
                  color={GREY.SECONDARY}
                  label={STRINGS.TO_CAPITAL}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                />
              </div>
              {(selectedTo?.length || !isReply) && (
                <CustomAutoCompleteChip
                  items={getContactsNames(contactList)}
                  onChange={(data) => {
                    setToContacts([...data]);
                  }}
                  inputOnchange={(value) => {
                    setSearchValue(value);
                    if (!isReply) {
                      debounceSearch(value);
                    }
                  }}
                  placeholder={STRINGS.TYPE_TO_SEARCH}
                  errormessage={errors?.toContacts}
                  variant={errors?.toContacts ? "error" : "default"}
                  defaultValues={getContactsNames(selectedTo)}
                  multiple
                />
              )}
              <div className={classes.ccBccText}>
                {!ccVisible ? (
                  <div
                    className={classes.ccBccLabel}
                    onClick={() => setCcVisible(true)}
                  >
                    <TypographyInline
                      color={GREY.SECONDARY}
                      label={STRINGS.CC}
                      size={"sm"}
                      fontWeight={fontWeight.SEMIBOLD}
                    />
                  </div>
                ) : null}
                {!bccVisible ? (
                  <div
                    className={classes.ccBccLabel}
                    onClick={() => setBccVisible(true)}
                  >
                    <TypographyInline
                      color={GREY.SECONDARY}
                      label={STRINGS.BCC}
                      size={"sm"}
                      fontWeight={fontWeight.SEMIBOLD}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            {/* CC Field */}
            {ccVisible && (
              <div className={classes.sendEmailLabelFieldContainer}>
                <div className={classes.sendEmailModalLabelDiv}>
                  <TypographyInline
                    color={GREY.SECONDARY}
                    label={STRINGS.CC}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
                {(selectedCc?.length || !isReply) && (
                  <CustomAutoCompleteChip
                    items={getContactsNames(contactList)}
                    onChange={(data) => {
                      setCcContacts([...data]);
                    }}
                    inputOnchange={(value) => {
                      setSearchValue(value);
                      if (!isReply) {
                        debounceSearch(value);
                      }
                    }}
                    placeholder={STRINGS.TYPE_TO_SEARCH}
                    defaultValues={getContactsNames(selectedCc)}
                    multiple
                  />
                )}
              </div>
            )}

            {/* BCC Field */}
            {bccVisible && (
              <div className={classes.sendEmailLabelFieldContainer}>
                <div className={classes.sendEmailModalLabelDiv}>
                  <TypographyInline
                    color={GREY.SECONDARY}
                    label={STRINGS.BCC}
                    size={"sm"}
                    fontWeight={fontWeight.SEMIBOLD}
                  />
                </div>
                {(selectedBcc?.length || !isReply) && (
                  <CustomAutoCompleteChip
                    items={getContactsNames(contactList)}
                    onChange={(data) => {
                      setBccContacts([...data]);
                    }}
                    inputOnchange={(value) => {
                      setSearchValue(value);
                      if (!isReply) {
                        debounceSearch(value);
                      }
                    }}
                    placeholder={STRINGS.TYPE_TO_SEARCH}

                    defaultValues={getContactsNames(selectedBcc)}
                  />
                )}
              </div>
            )}

            {/* From Field */}
            <div className={classes.sendEmailLabelFieldContainer}>
              <div className={classes.sendEmailModalLabelDiv}>
                <TypographyInline
                  color={GREY.SECONDARY}
                  label={STRINGS.FROM}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                />
              </div>
              {(
                <CustomAutoCompleteChip
                  items={getContactsNames(connectedInbox, false)}
                  onChange={(data) => {
                    setFromContacts(data);
                  }}
                  placeholder={STRINGS.TYPE_TO_SEARCH}
                  errormessage={errors?.fromContacts}
                  variant={errors?.fromContacts ? "error" : "default"}
                  defaultValues={selectedFrom}
                  multiple={false}
                />
              )}
            </div>

            {/* Subject Field */}
            <div className={classes.sendEmailSubjectBodyContainer}>
              <div className={classes.sendEmailModalLabelDiv}>
                <TypographyInline
                  color={GREY.SECONDARY}
                  label={STRINGS.SUBJECT}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  isRequired
                />
              </div>
              <InputField
                size={"sm36"}
                onChange={(e) => {
                  setSubject(e?.target?.value);
                }}
                value={subject}
                placeholder={STRINGS.TYPE_SUBJECT}
                errormessage={errors?.subject}
                variant={errors?.subject ? "error" : "default"}
              />
            </div>
            {/* Body Field */}
            <div className={classes.sendEmailSubjectBodyContainer}>
              <div className={classes.sendEmailModalLabelDiv}>
                <TypographyInline
                  color={GREY.SECONDARY}
                  label={STRINGS.BODY}
                  size={"sm"}
                  fontWeight={fontWeight.SEMIBOLD}
                  isRequired
                />
              </div>
              <div className={classes.errorAndEditor}>
                <div className={classes.htmlEditor}>
                  <HtmlEditor
                    onInput={(data) => setBody(data?.target?.innerHTML)}
                    editorHeight={"20vh"}
                    id={STRINGS.EMAIL_SEND_MODAL_EDITOR_ID}
                    isAITagNotVisible
                    isAddTagNotVisible
                    isMeetingTagNotVisible
                    onAttachmentChange={handleAttachmentsUpload}
                    attachments={Object.keys(attachments).map((item, index) => { return { name: item, id: attachments[item].fileKey || index } })}

                  />
                </div>
                {errors?.body ? (
                  <div>
                    <TypographyInline
                      label={errors?.body}
                      color={ERROR.W_500}
                      size={"xs"}
                      fontWeight={fontWeight.REGULAR}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>}

      </CustomModal>

      {/* Create/Send Email Operations */}
      <CustomMenu
        items={emailSendOperation}
        anchor={sendEmailOperationTarget}
        open={Boolean(sendEmailOperationTarget)}
        onClose={() => {
          setSendEmailOperationTarget(null);
        }}
        paperMenuClass={classes.scheduleNowOperation}
      />

      {/* Schedule Time Select Modal */}
      <CustomModal
        close={() => setScheduleTimeSelectModal(false)}
        open={scheduleTimeSelectModal}
        header={scheduleEmailModalHeader}
        footer={{
          right: scheduleEmailModalRightFooter,
        }}
        width={"35.260vw"}
      >
        <div className={classes.scheduleTimeMainContainer}>
          {/* Select Date */}
          <div className={classes.inputLabelField}>
            <TypographyInline
              color={GREY.SECONDARY}
              label={STRINGS.DUE_DATE}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
            />
            <CustomDatePicker
              size={"sm36"}
              value={sendEmailDate}
              onChange={(dateValue) => {
                setSendEmailDate(new Date(dateValue));
              }}
            />
          </div>

          {/* Select Time */}
          <div className={classes.inputLabelField}>
            <TypographyInline
              color={GREY.SECONDARY}
              label={STRINGS.TIME}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
            />
            <TimeSelector
              time={sendEmailTime}
              setTime={(time) => {
                setSendEmailTime(time);
              }}
              isAbsolute={false}
            />
          </div>
        </div>
      </CustomModal>
    </div >
  );
};

export default EmailSendModal;
