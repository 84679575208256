import React, { useEffect, useState } from "react";
import CustomStyledMenu from "../../../components/Menu/CustomStyledMenu";
import { SHADOW, fontWeight } from "../../../utils/constants/UI";
import { useSelector } from "react-redux";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { STRINGS } from "../strings";
import Checkbox from "../../../components/Buttons/Checkbox/Checkbox";
import classes from "./PropComponent.module.css";
import SearchField from "../../../components/InputFields/SearchField";

export const GroupNameFilterMenu = ({
  anchorEl,
  setAnchorEl,
  groupNameFilterHandler,
  selectedGroupName,
  setSelectedGroupName,
  setSelectedId,
}) => {
  const getGroupListSelector = useSelector((state) => state.getGroupList);
  const allPropertiesFiltersSelector = useSelector(
    (state) => state.allPropertiesFilters
  );

  const [groupsList, setGroupsList] = useState(
    getGroupListSelector?.data?.hits
  );

  const onChangeHandler = (e) => {
    if (
      allPropertiesFiltersSelector?.data?.query?.grp_name?.includes(
        e?.target?.value
      )
    ) {
      let tempGrpName = [
        ...allPropertiesFiltersSelector?.data?.query?.grp_name,
      ];
      let indexOfValue = tempGrpName.indexOf(e?.target?.value);
      tempGrpName?.splice(indexOfValue, 1);
      setSelectedGroupName(tempGrpName);
      groupNameFilterHandler(tempGrpName);
    } else {
      setSelectedGroupName((oldArray) => [...oldArray, e?.target?.value]);
      groupNameFilterHandler([...selectedGroupName, e?.target?.value]);
    }
  };

  const searchGroupsHandler = (e) => {
    // storing all groupListName
    let tempGrpLstName = getGroupListSelector?.data?.hits
      ?.filter((item) => item?.grp_name)
      ?.map((value) => {
        return value?.grp_name;
      });
    // storing all groupListName

    // storing group list name according to typed value.
    let searchGrpList = tempGrpLstName?.filter((item) =>
      item?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
    );
    // storing group list name according to typed value.

    // storing all data of group name which was typed.
    let grpList = getGroupListSelector?.data?.hits?.filter((item) =>
      searchGrpList?.includes(item?.grp_name)
    );
    // storing all data of group name which was typed.

    setGroupsList(grpList);
  };

  useEffect(() => {
    if (Boolean(anchorEl)) setGroupsList(getGroupListSelector?.data?.hits);
  }, [getGroupListSelector?.data?.hits, Boolean(anchorEl)]);

  return (
    <CustomStyledMenu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
        setSelectedId("");
      }}
      arrow={true}
      width={"14.948vw"}
      shadow={SHADOW.xs}
      noMargin={true}
      isRightAlign={true}
      marginTop={"11px !important"}
    >
      <div id={"modal_body"} className={classes.filterMenuContainer}>
        <div className={classes.groupNameSearchField}>
          <SearchField
            size={"sm36"}
            placeholder={STRINGS.GROUP_NAME_PLACEHOLDER}
            onChange={searchGroupsHandler}
          />
        </div>
        {getGroupListSelector?.loading ? (
          <div className={classes.loadingText}>
            <TypographyInline
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.LOADING}
              size={"sm36"}
            />
          </div>
        ) : (
          <div className={classes.grpFilterMenuMainContainer}>
            {groupsList?.map((group, index) => {
              return (
                <div className={classes.fieldTypeList} key={index}>
                  <Checkbox
                    checked={allPropertiesFiltersSelector?.data?.query?.grp_name?.includes(
                      group?.grp_name
                    )}
                    value={group?.grp_name}
                    label={group?.grp_name}
                    onChange={onChangeHandler}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </CustomStyledMenu>
  );
};
