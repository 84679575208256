import React from "react";
import { MultiSelectTextField } from ".";
import { importEndpoints } from "../../../utils/constants/httpConstants";
import { emailValidation } from "../../../utils/validations";
import CustomModal from "../../../components/Modals/CustomModal";
import { STRINGS } from "../strings";

const BlacklistContactModal = ({ open, setOpen, setRecords, setMaxRecords }) => {

  const modalHeaderData = {
    heading: {
      id: STRINGS.HEADER_ID,
      label: STRINGS.BLACKLIST_EMAILS,
    },
  };
  const modalBodyData = (
    <div >
      <MultiSelectTextField
        open={open}
        setOpen={setOpen}
        validationFunction={emailValidation}
        endPoint={importEndpoints.blackListContact}
        payloadKey={STRINGS.EMAILS}
        placeholder={STRINGS.ENTER_MULTIPLE_EMAILS}
        setRecords={setRecords}
        setMaxRecords={setMaxRecords}
      />
    </div>
  );

  return (
    <CustomModal
      width={"50vw"}
      close={() => setOpen(false)}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
    />
  );
};

export default BlacklistContactModal;
