import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styles } from "./styles";
import {
  MAIL_IMAGE,
  NEW_LOGIN_REACHIQ_LOGO,
} from "../../utils/constants/assets";
import { emailValidation } from "../../utils/validations/inputValidations";
import { Anchor, RightsTag } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordMailSend } from "../../redux/actions/authActions";
import ThankYouScreen from "../DefaultScreens/ThankYouScreen";
import { PRIVACY_POLICY_LINK, TERMS_SERVICE_LINK } from "../../utils/constants";
import { STRINGS } from "./strings";
import Button from "../../components/Buttons/Button";
import TypographyInline from "../../components/Typography/TypographyInline";
import { BRAND, GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import InputField from "../../components/InputFields/InputField";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "../../utils/constants/routes";
import Image from "../../components/Image/Image";

const ForgotPasswordScreen = () => {
  const classes = styles();
  const navigate = useNavigate();

  const mailSendSelector = useSelector((state) => state.resetPasswordMailSend);
  const { error, payload } = mailSendSelector;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [validations, setValidations] = useState({ email: "" });
  const [showThankYou, setShowThankYou] = useState(false);
  const [load, setLoad] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validatedEmail = emailValidation(email);
    if (validatedEmail.isValid) {
      setValidations({ email: "" });
      setLoad(true);

      //dispatch here for email
      await dispatch(resetPasswordMailSend(email));
      setLoad(false);
    } else {
      setValidations({ email: validatedEmail.message });
    }
  };

  useEffect(() => {
    if (payload) {
      setShowThankYou(true);
    }
    return () => {
      dispatch(resetPasswordMailSend(email || "", true));
    };
  }, [payload, dispatch]);

  const handleNavigate = () => {
    navigate(LOGIN_PATH);
  };
  return showThankYou ? (
    <ThankYouScreen email={email} image={MAIL_IMAGE} resend={true} />
  ) : (
    <Box className={classes.container}>
      <Box className={classes.topContainer}>
        <Box className={classes.containLogo}>
          <Image src={NEW_LOGIN_REACHIQ_LOGO} />
        </Box>
        <Box className={classes.modal} component="form" onSubmit={handleSubmit}>
          <div className={classes.resetTitle}>
            <TypographyInline
              size={"dxs"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.RESET_YOUR_PASSWORD}
            />{" "}
          </div>
          <Box className={classes.containInstructions}>
            <TypographyInline
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.PLEASE_ENTER_EMAIL}
            />{" "}
            <TypographyInline
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.INSTRUCTIONS}
            />{" "}
          </Box>
          <Box className={classes.email}>
            <TypographyInline
              size={"sm"}
              color={GREY.QUATINARY}
              fontWeight={fontWeight.MEDIUM}
              label={STRINGS.EMAIL}
            />{" "}
            <InputField
              placeholder={STRINGS.ENTER_EMAIL}
              size={"md40"}
              value={email}
              onChange={(e) => handleEmail(e)}
              errormessage={
                validations.email?.length > 1 || error?.message
                  ? validations.email || error?.message
                  : ""
              }
              variant={
                validations.email?.length > 1 || error?.message
                  ? STRINGS.ERROR
                  : STRINGS.DEFAULT
              }
            />
          </Box>


          <Box className={classes.btnContainer}>
            <Button
              label={STRINGS.SEND}
              size={"md40"}
              variant={"primary"}
              isLoading={load}
            />
          </Box>
          <Box className={classes.containInstruct}>
            <TypographyInline
              size={"sm"}
              color={GREY.TERTIARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.REMEMEBER}
            />{" "}
            <Box className={classes.mypass}>
              <TypographyInline
                size={"sm"}
                color={BRAND.PRIMARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.MY_PASSWORD}
                onClick={handleNavigate}
              />{" "}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.bottomContainer}>
        <div className={classes.containPolicy}>
          <RightsTag className={classes.termsPara} />
          {/* Privacy and Terms. */}
          <Anchor
            href={PRIVACY_POLICY_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            <div className={classes.hovering}>
              {" "}
              <TypographyInline
                label={STRINGS.PRIVACY}
                color={GREY.SECONDARY}
                size={"xs"}
                fontWeight={fontWeight.SEMIBOLD}
              />
            </div>
          </Anchor>
          <div className={classes.TypoHead}>
            <TypographyInline
              label={STRINGS.AND}
              color={GREY.TERTIARY}
              size={"xs"}
              fontWeight={fontWeight.REGULAR}
            />
          </div>
          <Anchor
            href={TERMS_SERVICE_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            <div className={classes.hovering}>
              {" "}
              <TypographyInline
                label={STRINGS.TERMS}
                color={GREY.SECONDARY}
                size={"xs"}
                fontWeight={fontWeight.SEMIBOLD}
              />
            </div>
          </Anchor>
        </div>
      </Box>
    </Box>
  );
};

export default ForgotPasswordScreen;
