import React, { useState } from 'react'
import CustomModal from '../../../../components/Modals/CustomModal'
import { STRINGS } from '../../strings';
import { useSelector } from 'react-redux';
import { decompressString } from '../../../../utils/helpers';
import TypographyInline from '../../../../components/Typography/TypographyInline';
import { GREY } from '../../../../utils/constants/colors';
import { fontWeight } from '../../../../utils/constants/UI';
import { AutoCompleteCustom, CustomDatePicker, HtmlEditor, InputField, TextArea } from '../../../../components';
import classes from "./FormsComp.module.css"
import { PREIVEW_FORMS_DROPDOWN_VALUES } from '../../../../utils/constants';

const PreviewModal = ({ fields = [], open = false, close = () => { } }) => {

    const getPropertiesListSelector = useSelector(
        (state) => state.getPropertiesList?.data
    );

    const propertiesKeyValue = localStorage.getItem("properties") ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))?.properties_key_value : getPropertiesListSelector?.properties_key_value;
    const [singleSelect, setSingleSelect] = useState([]);
    const [singleSelectId, setSingleSelectId] = useState([]);
    const [multipleSelect, setMultipleSelect] = useState([]);
    const [multipleSelectId, setMultipleSelectId] = useState([]);

    const modalHeaderData = {
        heading: {
            id: STRINGS.PREVIEW_SMALL,
            label: STRINGS.PREVIEW,
        },
    };

    const type_id_components = (field) => {
        switch (propertiesKeyValue?.[field?.field_id]?.field_type[0]) {
            case STRINGS.DATE_PICKER:
                return (
                    <CustomDatePicker
                        size={"sm36"}
                    />
                );

            case STRINGS.NUMBER:
                return (
                    <InputField
                        size={"sm36"}
                        type={"number"}
                        placeholder={`Enter ${propertiesKeyValue?.[field?.field_id]?.property_name}`}
                    />
                );

            case STRINGS.MULTI_LINE_TEXT_INPUT:
                return (
                    <TextArea
                        rows={"5"}
                    />
                );

            case STRINGS.RICH_TEXT_EDITOR:
                return (
                    <div className={classes.htmlEditor}>
                        <HtmlEditor
                            editorHeight={"12vh"}
                            isAITagNotVisible
                            isAddTagNotVisible
                            isMeetingTagNotVisible
                            isFocusNotRequired
                        />
                    </div>
                );

            case STRINGS.SINGLE_SELECT:
                return (
                    <AutoCompleteCustom
                        dropdownId="preview_dropdown"
                        size={"sm36"}
                        items={PREIVEW_FORMS_DROPDOWN_VALUES}
                        idState={singleSelectId}
                        setIdState={setSingleSelectId}
                        valueState={singleSelect}
                        setValueState={setSingleSelect}
                    />
                );

            case STRINGS.MULTIPLE_SELECT:
                return (
                    <AutoCompleteCustom
                        dropdownId="preview_dropdown"
                        size={"sm36"}
                        items={PREIVEW_FORMS_DROPDOWN_VALUES}
                        idState={multipleSelectId}
                        setIdState={setMultipleSelectId}
                        valueState={multipleSelect}
                        setValueState={setMultipleSelect}
                    />
                );

            default:
                return (
                    <InputField
                        size={"sm36"}
                        placeholder={`Enter ${propertiesKeyValue?.[field?.field_id]?.property_name}`}
                    />
                );
        }
    };

    return (
        <CustomModal header={modalHeaderData} open={open} close={close} width={"40vw"}  >
            <div className={classes.fieldsPreviewBody} >
                {
                    fields?.map((field, index) => {
                        return <div key={`forms_preview_${index}`} className={classes.inputLabelContainer} >
                            <TypographyInline label={propertiesKeyValue?.[field?.field_id]?.property_name} color={GREY.QUATINARY} size={"sm"} fontWeight={fontWeight.MEDIUM} isRequired={field?.required} />
                            {type_id_components(field)}
                        </div>
                    })
                }
            </div>
        </CustomModal>
    )
}

export default PreviewModal