import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  CreateListModal,
  EditColumnModal,
  FilterStrip,
  PeopleHeader,
} from "./components";
import { PeopleTable } from "./components";
import {
  DEBOUNCE_DELAY,
  DROPDOWN_PEOPLE_HEADER_SORTING,
  tempHeadCells,
} from "../../utils/constants";
import { styles } from "./styles";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  tableDataFetch,
  tableEndpointChange,
} from "../../redux/actions/tableActions";
import TablePlaceholder from "./components/TablePlaceholder";
import { debounceHelper, filterQuery } from "../../utils/helpers";
import ContactDropdownModal from "./components/ContactDropdownModal";
import { HandleClearAllFilterFromStore } from "../../redux/store/storeHelper";
import { getRequest } from "../../Apis";
import {
  listEndPoints,
} from "../../utils/constants/httpConstants";
import CustomHeader from "../../components/Header/CustomHeader";
import { STRINGS } from "./strings";
import * as qs from "qs";
import { allFilters } from "../../redux/actions/filterActions";
import { LIST_PATH, PEOPLE_SCREEN_PATH } from "../../utils/constants/routes";
import CustomStyledMenu from "../../components/Menu/CustomStyledMenu";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import { Button } from "../../components/Buttons";
import { GREY } from "../../utils/constants/colors";
import SearchField from "../../components/InputFields/SearchField";
import TypographyInline from "../../components/Typography/TypographyInline";
import { AutoCompleteCustom } from "../../components/Autocomplete/AutoCompleteCustom";
import AddContactDialog from "./components/AddContactDialog";
import { paginationDataAction } from "../../redux/actions/customTableActions";

const PeopleScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tableDataSelector = useSelector((state) => state.tableDataFetch);
  const countSelector = useSelector((state) => state.peopleBasicDetails);
  const querySelector = useSelector((state) => state?.allFilters?.payload);
  const allFiltersSelector = useSelector((state) => state.allFilters);

  const columnsSelector = useSelector(
    (state) => state.ApplyUpdateColumn?.columns
  );
  const dataFetchSelector = useSelector((state) => state.tableDataFetch);
  const queryLength = Object.keys(filterQuery(querySelector)).length;
  const tableEndpointChangeSelector = useSelector(
    (state) => state.tableEndpointChange
  );
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const savedContactsTrue =
    tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
      ? true
      : false;
  const { loading, columns } = tableDataSelector;
  const [querySelectorState, setQuerySelectorState] = useState(null);
  const [activeViewId, setActiveViewId] = useState(0);
  const [searchField, setSearchField] = useState("");
  const [sortingDirectionId, setSortingDirectionId] = useState([]);

  const [selectedBulkValue, setSelectedBulkValue] = useState("");

  const [createListModal, setCreateListModal] = useState(false);
  const [sortByEL, setSortByEL] = useState(null);
  const [addAnotherFilter, setAddAnotherFilter] = useState(false);
  const [headerItems, setHeaderItems] = useState([]);
  const nextstep = 0;
  const liststep = 0;
  const stepId = location.state?.steps || null;
  const [bulkSelected, setBulkSelected] = useState(false);
  const [sortingValue, setSortingValue] = useState([]);
  const [isEditContact, setIsEditContact] = useState(false);
  const [sortingId, setSortingId] = useState([]);
  const [sortingDirection, setSortingDirection] = useState([]);
  const [addContactOpen, setAddContactOpen] = useState(false);
  const { payload } = countSelector;
  const queryObject = qs.parse(window.location?.search?.slice(1));
  const queryObjectLength = Object.keys(queryObject).length;

  const handleApply = () => {
    if (
      (sortingId && sortingDirectionId && queryObjectLength) ||
      (sortingId &&
        sortingDirectionId &&
        tableEndpointChangeSelector.payload === STRINGS.SAVED_CONTACT)
    ) {
      const paginationQuery = {
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          sort: {
            id: sortingId[0],
            direction: sortingDirectionId[0]
          },
        },
      }
      dispatch(paginationDataAction(paginationQuery));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector?.payload,
          sort: sortingId[0],
          direction: sortingDirectionId[0],
        }).toString(),
      });
    }
  };

  const sortingModal = (
    <div style={styles.sortingModal}>
      <div style={styles.sortingDropdownContainer}>
        <TypographyInline
          label={STRINGS.RELEVANCE}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
        />
        <AutoCompleteCustom
          size={"sm36"}
          valueState={sortingValue}
          LeftIcon={""}
          setValueState={setSortingValue}
          idState={sortingId}
          setIdState={setSortingId}
          items={[...tempHeadCells]}
          papermenuclass={styles.paperMenu2}
        />
      </div>
      <div style={styles.sortingDropdownContainer}>
        <TypographyInline
          label={STRINGS.ORDER}
          size={"sm"}
          fontWeight={fontWeight.REGULAR}
          color={GREY.SECONDARY}
        />
        <AutoCompleteCustom
          size={"sm36"}
          LeftIcon={""}
          valueState={sortingDirection}
          setValueState={setSortingDirection}
          idState={sortingDirectionId}
          setIdState={setSortingDirectionId}
          items={DROPDOWN_PEOPLE_HEADER_SORTING}
          paperHeight={"fit-content"}
          papermenuclass={styles.paperMenu2}
        />
      </div>

      <div style={styles.containApplyBtn}>
        <Button
          label={STRINGS.APPLY}
          size={"xs28"}
          variant={"primary"}
          onClick={handleApply}
          disabled={!(sortingId.length && sortingDirection.length)}
        />
      </div>
    </div>
  );
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (e?.target?.value) {
      dispatch(allFilters({ search: e?.target?.value, page: 1, limit: paginationDataSelector?.data?.query?.limit || 25 }));
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...allFiltersSelector?.payload,
          search: e?.target?.value,
        }).toString(),
      });
    } else {
      dispatch(allFilters({ search: e?.target?.value }));
      const query = {
        ...allFiltersSelector?.payload,
        search: e?.target?.value,
      };
      delete query?.page;
      delete query?.limit;
      delete query?.search;
      navigate({
        pathname: location.pathname,
        search: createSearchParams(query).toString(),
      });
    }
  };

  const clearSearch = () => {
    setSearchField("")
  }

  const sortFunction = () => {
    setSortingValue([])
    setSortingId([])
    setSortingDirection([])
    setSortingDirectionId([])
  }

  const debounceSearch = debounceHelper(handleSearchSubmit, DEBOUNCE_DELAY);


  const headerDescription = `${loading || dataFetchSelector?.loading
    ? STRINGS.LOADING
    : queryObjectLength
      ? payload?.active_view?.is_main_view
        ? dataFetchSelector?.totalRecords?.toLocaleString() || ""
        : dataFetchSelector?.totalRecords?.toLocaleString() ||
        payload?.total_record?.toLocaleString() || ""
      : tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
        ? dataFetchSelector?.totalRecords?.toLocaleString() || ""
        : ""
    }
  ${queryObjectLength ||
      tableEndpointChangeSelector?.payload === STRINGS.SAVED_CONTACT
      ? STRINGS.CONTACTS
      : ""
    }`;

  // add contact modal open handler
  const addContactHandler = async () => {
    setAddContactOpen(!addContactOpen);
  };

  useEffect(() => {
    setQuerySelectorState(querySelector);
    if (
      JSON.stringify(querySelector) !== JSON.stringify(querySelectorState) &&
      queryLength
    ) {
      const filteredQuery = filterQuery(querySelector);
      if (
        Object.keys(filteredQuery)?.length <= 3 &&
        filteredQuery.sort &&
        filteredQuery.direction &&
        filteredQuery.sort &&
        !savedContactsTrue
      ) {
        delete filteredQuery[STRINGS.SORT];
        delete filteredQuery.direction;
        delete filterQuery.sort;

        HandleClearAllFilterFromStore(dispatch, location, navigate);
      }

      if (
        Object.keys(filteredQuery)?.length <= 2 &&
        filteredQuery.sort &&
        filteredQuery.direction &&
        !savedContactsTrue
      ) {
        delete filteredQuery[STRINGS.SORT];
        delete filteredQuery.direction;

        HandleClearAllFilterFromStore(dispatch, location, navigate);
      }

      if (
        Object.keys(filteredQuery)?.length <= 1 &&
        filteredQuery.page &&
        !savedContactsTrue
      ) {
        delete filteredQuery.page;

        HandleClearAllFilterFromStore(dispatch, location, navigate);
      }
      (async () => {
        queryObject?.contact_lists &&
          (await dispatch(tableEndpointChange(STRINGS.SAVED_CONTACT)));
        // handlling only sort, page filter in URLS.
        setActiveViewId(0);
        dispatch(tableDataFetch(filteredQuery, navigate));
      })();
    }

    if (
      countSelector?.payload?.active_view?.view_id !==
      countSelector?.payload?.default_view?.view_id &&
      activeViewId !== 1
    ) {
      setActiveViewId(1);
    }

    return () => {
      localStorage.removeItem(STRINGS.ACTIVE_VIEW_ID);
    };
  }, [
    navigate,
    querySelectorState,
    querySelector,
    dispatch,
    queryLength,
    countSelector,
    activeViewId,
    location,
    stepId,
    nextstep,
  ]);

  useEffect(() => {
    if (Object.keys(paginationDataSelector?.data?.query || {}).length) {
      dispatch(
        allFilters({
          ...paginationDataSelector?.data?.query,
          sort: paginationDataSelector?.data?.query?.sort?.id || "",
          direction: paginationDataSelector?.data?.query?.sort?.direction || "",
        })
      );
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...querySelector,
          ...paginationDataSelector?.data?.query,
          sort: paginationDataSelector?.data?.query?.sort?.id || "",
          direction: paginationDataSelector?.data?.query?.sort?.direction || "",
        }).toString(),
      });
    }
  }, [
    paginationDataSelector?.data?.query?.page,
    paginationDataSelector?.data?.query?.limit,
    paginationDataSelector?.data?.query?.sort,
  ]);

  useEffect(() => {
    // This useEffect is for setting headers, if any list filter is applied.
    if (queryObject?.contact_lists) {
      (async () => {
        try {
          // now make the necessary api call.
          const get_folder_details_payload = await getRequest(
            navigate,
            listEndPoints.getFolderDetail + `?_id=${queryObject?.contact_lists}`
          );
          const name = get_folder_details_payload?.data?.data?.list_name;
          const newHeaderItems = [
            {
              id: STRINGS.PEOPLE_HEADER,
              label: STRINGS.CONTACT_LST,
              path: LIST_PATH,
            },
            { id: name, label: name },
          ];
          setHeaderItems(newHeaderItems);
        }
        catch (error) { }
      })();
    }
    else {
      setHeaderItems([
        { id: STRINGS.PEOPLE_HEADER, label: STRINGS.PEOPLE },
      ])
    }
  }, [queryObject?.contact_lists, navigate]);

  return (
    <Box sx={styles.container}>
      <CustomHeader items={headerItems} description={headerDescription} />
      <PeopleHeader nextstep={nextstep} liststep={liststep} header={headerItems} setHeader={setHeaderItems} />
      <Box sx={styles.justifyContentCenter}>
        <Box sx={styles.filterContain}>
          <ContactDropdownModal
            header={headerItems}
            clearSearch={clearSearch}
          />
          <FilterStrip
            isOpenFromOutside={addAnotherFilter}
            setOpenFromOutside={setAddAnotherFilter}
            clearSort={sortFunction}
            clearSearch={clearSearch}
          />
        </Box>
      </Box>

      {/* All Contact Label */}
      <div style={styles.talbeActionsContainer}>
        {/* Table Search Field */}
        <form onSubmit={handleSearchSubmit} style={styles.commonWidth}>
          <SearchField
            size={"sm36"}
            value={searchField}
            LeftIcon={ICON_LABELS.SEARCH}
            onChange={(e) => {
              debounceSearch(e);
              setSearchField(e?.target?.value);
            }}
            placeholder="Search "
          />
        </form>

        <div style={styles.tableActionRightContainer} >
          {/* Edit Column Modal */}
          <EditColumnModal />

          {/* Create List BUtton */}
          <div>
            <Button
              label={STRINGS.CREATE_LIST}
              LeftIcon={ICON_LABELS.PLAYLIST_ADD}
              size={"sm36"}
              variant={"ghost"}
              onClick={() => setCreateListModal(createListModal ? false : true)}
            />
          </div>

          {/* Sort By Button */}
          <div>
            <Button
              LeftIcon={ICON_LABELS.SORT}
              RightIcon={ICON_LABELS.ACTION_ARROW}
              label={STRINGS.SORT_BY}
              size={"sm36"}
              variant={"ghost"}
              disabled={(!(queryObjectLength > 0) && tableEndpointChangeSelector.payload !== STRINGS.SAVED_CONTACT)}
              onClick={(e) => setSortByEL(e.currentTarget)}
            />
            <CustomStyledMenu
              noMargin={true}
              open={Boolean(sortByEL)}
              anchorEl={sortByEL}
              onClose={() => setSortByEL(null)}
            >
              {sortingModal}
            </CustomStyledMenu>
          </div>

          {/* Add Contact Button */}
          {savedContactsTrue && <Button
            LeftIcon={ICON_LABELS.ADD}
            label={STRINGS.ADD_CONTACTS}
            size={"sm36"}
            variant="primary"
            onClick={addContactHandler}
          />}
          {/* Add Contact Dialog */}
          {addContactOpen && (<AddContactDialog open={addContactOpen} setOpen={setAddContactOpen} isEdit={isEditContact} setIsEdit={setIsEditContact} />)}
        </div>
      </div>
      {loading || savedContactsTrue ? (
        <PeopleTable
          heads={columnsSelector || tempHeadCells}
          records={tableDataSelector?.columns || []}
          savedContactsTrue={savedContactsTrue}
          selectedBulkValue={selectedBulkValue}
          setSelectedBulkValue={setSelectedBulkValue}
          bulkSelected={bulkSelected}
          setBulkSelected={setBulkSelected}
          isEditContact={isEditContact}
          setIsEditContact={setIsEditContact}
          addContactHandler={addContactHandler}
        />
      ) : columns && queryLength > 0 ? (
        false ? (
          <></>
        ) : (
          <PeopleTable
            heads={columnsSelector || tempHeadCells}
            records={queryLength > 0 ? tableDataSelector?.columns : []}
            selectedBulkValue={selectedBulkValue}
            setSelectedBulkValue={setSelectedBulkValue}
            bulkSelected={bulkSelected}
            setBulkSelected={setBulkSelected}
            isEditContact={isEditContact}
            setIsEditContact={setIsEditContact}
            addContactHandler={addContactHandler}
          />
        )
      ) : (
        <TablePlaceholder
          records={countSelector?.payload?.total_record}
          setAddAnotherFilter={setAddAnotherFilter}
        />
      )}

      {createListModal && <CreateListModal open={createListModal} setOpen={setCreateListModal} />}
    </Box>
  );
};

export default React.memo(PeopleScreen);
