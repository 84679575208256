import { createSearchParams } from "react-router-dom";
import { getRequest, postRequest } from "../../Apis";
import { crmEndpoints } from "../../utils/constants/httpConstants";
import {
  SALES_CONTACTS_TABLE_FAIL,
  SALES_CONTACTS_TABLE_REQUEST,
  SALES_CONTACTS_TABLE_SUCCESS,
  SALES_FILTERS_SUCCESS,
  SALES_FILTERS_FAIL,
  GET_CONTACT_DETAIL_REQUEST,
  GET_CONTACT_DETAIL_SUCCESS,
} from "../constants/salesTableConstants";
import {
  dataReferenceMapping,
  decompressString,
  filterColumnQueryKeys,
  filterDynamicQuery,
  filterQuery,
  getSelectedColumns,
} from "../../utils/helpers";
import { paginationDataAction } from "./customTableActions";
import { applyUpdateColumn } from "./tableActions";
import { tempHeadCells } from "../../utils/constants";

// action for getting contact list data of crm. 
export const getSalesContactsList =
  (sentQuery, navigate) => async (dispatch, getState) => {
    try {
      const table_columns = getSelectedColumns(
        getState().dynamicColumnUpdate?.columns?.filter(item => !item.is_deal) || [...tempHeadCells]
      );
      // got the table columns here, will send them from here.
      const getPropertiesListSelector = getState()?.getPropertiesList?.data;

      // storing all properties into below variable.
      let propertiesPayload = localStorage.getItem("properties") ? JSON.parse(decompressString(localStorage.getItem("properties") || ""))?.properties_key_value : getPropertiesListSelector?.properties_key_value;

      // updating custom table query and checked row.
      dispatch(
        paginationDataAction({
          checkedRow: [],
          query: {
            ...getState()?.getPaginationQuery.data?.query,
          },
        })
      );
      const filteredQuery = filterDynamicQuery(
        sentQuery?.query,
        propertiesPayload
      );

      // Add columns with respective filter.
      const filteredQueryKeys = filterColumnQueryKeys(filteredQuery);
      for (let i in filteredQueryKeys) {
        if (!table_columns.includes(filteredQueryKeys[i])) {
          table_columns.push(filteredQueryKeys[i]);
        }
      }

      // dispatch columns
      const columns_clone = [...getState().dynamicColumnUpdate?.columns || [...tempHeadCells]];
      for (let i in columns_clone) {
        if (table_columns.includes(columns_clone?.[i]?.["id"])) {
          columns_clone[i].isChecked = true;
        }
      }
      dispatch(applyUpdateColumn(columns_clone));

      let query = {
        table_columns,
        ...filteredQuery,
      };

      try {
        dispatch({ type: SALES_CONTACTS_TABLE_REQUEST });
        const response = await postRequest(
          navigate,
          crmEndpoints.getContact,
          query
        );

        const properties_reference_key_value = getState()?.getPropertiesList?.data?.reference_key_value;
        const items = response?.data?.data?.records || [];

        const records = dataReferenceMapping(items, properties_reference_key_value);
        dispatch({
          type: SALES_CONTACTS_TABLE_SUCCESS,
          payload: { ...response?.data?.data, records: records, query: filteredQuery },
        });
      } catch (err) {
        dispatch({ type: SALES_CONTACTS_TABLE_FAIL, payload: err });
      }
    } catch (error) {
      console.log("error------------------> ", error);
    }
  };

// action for storing all filters of crm module.
export const allSalesFilters =
  (sentQuery, navigate) => async (dispatch, getState) => {
    let query = {
      ...getState()?.allSalesFilters?.payload,
      ...sentQuery,
    };
    try {
      dispatch({
        type: SALES_FILTERS_SUCCESS,
        payload: query,
      });
      navigate({
        pathname: window.location.pathname,
        search: createSearchParams(query).toString(),
      });
    } catch (err) {
      dispatch({ type: SALES_FILTERS_FAIL, payload: err });
    }
  };
