import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../Apis";
import { CircularLoader } from "../../../components";
import { EMITTER_ERROR } from "../../../utils/constants";
import { importEndpoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { getErrorMessage, JSONtoQueryString } from "../../../utils/helpers";
import { styles } from "../styles";
import {
  NO_RECORD_IMAGE,
  NO_RECORD_UPDATE,
} from "../../../utils/constants/assets";
import { useSelector } from "react-redux";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import IconComponent from "../../../components/Icon/IconComponent";
import { GREY } from "../../../utils/constants/colors";
import TypographyInline from "../../../components/Typography/TypographyInline";

const CommonBlacklistTable = ({
  selectedField,
  heads,
  data,
  handleDelete,
  maxRecords,
  page,
  setPage,
  setData,
  query,
  widgetHeight,
  widgetWidth,
  isDashboardTable,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allRecordsFetched, setAllRecordsFetched] = useState(false);

  const [statusCsv, setStatusCsv] = useState("Process Again");
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const userDetails = userDetailsSelector?.payload;
  const filterValue = (item, type) => {
    if (item === undefined || item === null) return "--";
    if (item === 0) return "0";
    if (type === "date") {
      const date = new Date(item);
      const date_string =
        date?.toLocaleDateString() + " , " + date?.toLocaleTimeString();
      return date_string;
    }
    if (type === "user") {
      return item?.name;
    }
    return item?.length > 20 ? item?.slice(0, 20) + "..." : item;
  };

  const filterTooltipValue = (item, type) => {
    if (type === "date") {
      return "";
    }
    if (type === "user") {
      return item?.name;
    }
    return item?.length > 20 ? item : "";
  };

  const handleScroll = async (e) => {
    if (
      e?.currentTarget.offsetHeight + e?.currentTarget.scrollTop >=
      e?.currentTarget.scrollHeight - 10
    ) {
      if (!loading && !allRecordsFetched) {
        try {
          const newQuery = { ...query, page: page + 1 };
          const queryString = JSONtoQueryString(newQuery);
          setLoading(true);
          const response = await getRequest(
            navigate,
            selectedField?.endPoint + queryString
          );
          setData([...data, ...response?.data?.data?.[selectedField?.key]]);
          setPage(page + 1);
          if (
            [...data, ...response?.data?.data?.[selectedField?.key]]?.length >=
            maxRecords
          ) {
            setAllRecordsFetched(true);
          }
        } catch (error) {
          EventEmitter.emit(
            EMITTER_ERROR.at,
            getErrorMessage(
              error?.data?.data?.errors || {},
              error?.data?.message
            )
          );
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleProcessAgain = (id, processing_count) => {
    if (processing_count > 5) {
      EventEmitter.emit(
        EMITTER_ERROR,
        "you have reached the maximum number of retry"
      );
    } else {
      (async () => {
        let payload = {
          record_id: id,
        };
        try {
          const response = await postRequest(
            navigate,
            importEndpoints.updateFieldCsvMapping,
            payload
          );
          if (response?.status === 200) {
            setStatusCsv("processed");
            const response = await getRequest(
              navigate,
              importEndpoints.getImportedFiles
            );
            setData(response?.data?.data?.imported_files);
            setStatusCsv("process Again");
          }
        } catch (error) {
          EventEmitter.emit(
            EMITTER_ERROR,
            error?.data?.message || "Something went wrong!"
          );
        } finally {
        }
      })();
      // }
      //  else {
      //   console.log("set");
      // }
    }
  };
  return (
    <Box
      sx={{
        ...styles.containCommonTable,
        height: isDashboardTable ? widgetHeight : "calc(100vh - 18rem)",
        width: isDashboardTable ? "100%" : widgetWidth,
      }}
      onScroll={!isDashboardTable ? handleScroll : () => { }}
    >
      {/* Table Common Header */}
      <Box
        sx={
          isDashboardTable
            ? styles.containTableHeadFixed
            : styles.containTableHead
        }
      >
        <Box sx={{ width: "auto" }}>
          <Box
            sx={
              isDashboardTable
                ? {
                  ...styles.containTableDashboardHeads,
                  gridTemplateColumns: `repeat(${heads?.length},1fr)`,
                }
                : {
                  ...styles.containTableHeads,
                  gridTemplateColumns: `repeat(${heads?.length},1fr)`,
                }
            }
          >
            {heads?.map((head, index) => {
              return (
                head?.isChecked && (
                  <Box
                    key={index}
                    sx={{ ...styles.cotnainEachHead, width: head?.width }}
                  >
                    <h4 style={styles.tableHead}>{head?.label}</h4>
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      </Box>
      {/* Table Common Header */}

      {/*  Table Body as per the matched key  */}
      <Box
        sx={
          data?.length
            ? isDashboardTable
              ? styles.dashboardTableBody1
              : ""
            : isDashboardTable
              ? styles.dashboardTableBody
              : { height: "calc(100% - 48px)" }
        }
      >
        {data?.length ? (
          <Box>
            {data?.map((item, index) => {
              return (
                <Box sx={{ background: "white" }} key={index}>
                  <Box
                    sx={{ width: isDashboardTable ? "100%" : "fit-content" }}
                  >
                    <Box
                      sx={
                        isDashboardTable
                          ? {
                            ...styles.eachTableRowDashboard,
                            gridTemplateColumns: `repeat(${heads?.length},1fr)`,
                          }
                          : {
                            ...styles.eachTableRow,
                            gridTemplateColumns: `repeat(${heads?.length},1fr)`,
                          }
                      }
                    >
                      {heads?.map((eachHead, index) => {
                        if (eachHead.id === "delete") {
                          return (
                            <Box
                              sx={{
                                ...styles.eachBlock,
                                width: eachHead?.width,
                              }}
                              key={index}
                            >
                              <div
                                style={
                                  userDetails?.role == "admin-user"
                                    ? styles.actionIconsContainer
                                    : styles.actionIconsDisabledContainer
                                }
                              >
                                <IconComponent
                                  disabled={
                                    userDetails?.role == "admin-user"
                                      ? false
                                      : true
                                  }
                                  color={GREY.TERTIARY}
                                  fontSize={fontSize.MD}
                                  iconLabel={ICON_LABELS.DELETE}
                                  onClick={() =>
                                    userDetailsSelector?.payload?.role ===
                                      "admin-user"
                                      ? handleDelete(item)
                                      : () => { }
                                  }
                                />
                              </div>
                            </Box>
                          );
                        }
                        if (eachHead.id === "action") {
                          return (
                            <Box sx={styles.eachTableRecord}>
                              {item?.status === "Interrupted" ? (
                                <Box>
                                  {userDetailsSelector?.payload?.role ===
                                    "admin-user" ? (
                                    <span
                                      style={styles.commonSpanProcess}
                                      onClick={() =>
                                        handleProcessAgain(
                                          item?._id,
                                          item?.processing_count
                                        )
                                      }
                                    >
                                      {statusCsv}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginLeft: "22px",
                                      }}
                                    >
                                      --
                                    </span>
                                  )}
                                </Box>
                              ) : (
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginLeft: "22px",
                                  }}
                                >
                                  --
                                </span>
                              )}
                            </Box>
                          );
                        } else
                          return (
                            eachHead?.isChecked && (
                              <Box
                                sx={
                                  isDashboardTable
                                    ? {
                                      ...styles.dashboardEachBlock,
                                      width: eachHead?.width,
                                    }
                                    : {
                                      ...styles.eachBlock,
                                      width: eachHead?.width,
                                    }
                                }
                                // sx={{ width: eachHead?.width }}
                                key={index}
                              >
                                <Tooltip
                                  title={filterTooltipValue(
                                    item?.[eachHead?.id],
                                    eachHead?.type
                                  )}
                                >
                                  <p style={styles.eachTableRecord}>
                                    {filterValue(
                                      item?.[eachHead?.id],
                                      eachHead?.type
                                    )}
                                  </p>
                                </Tooltip>
                              </Box>
                            )
                          );
                      })}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <div
            style={
              isDashboardTable
                ? {
                  ...styles.containDashboardCommonTable,
                  height: !isDashboardTable && widgetHeight,
                }
                : {
                  ...styles.containCommonTables,
                  height: "100%",
                }
            }
          >
            <img src={NO_RECORD_UPDATE} alt="" style={styles.searchImage} />
            <TypographyInline
              size={"md"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.MEDIUM}
              label={"No Record Found"}
            />
          </div>
        )}
      </Box>
      {/*  Table Body as per the matched key  */}
      {loading ? (
        <Box sx={styles.loaderContainer}>
          <CircularLoader />
        </Box>
      ) : null}

    </Box>
  );
};

export default CommonBlacklistTable;
