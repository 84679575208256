import React from "react";
import { makeStyles } from "@mui/styles";
import { fontSize, fontWeight, lineHeight } from "../../utils/constants/UI";

export const styles = makeStyles({
  micro: {
    fontSize: fontSize.MICRO,
    lineHeight: lineHeight.MICRO,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  xs: {
    fontSize: fontSize.XS,
    lineHeight: lineHeight.XS,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  sm: {
    fontSize: fontSize.SM,
    lineHeight: lineHeight.SM,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  md: {
    fontSize: fontSize.MD,
    lineHeight: lineHeight.MD,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  lg: {
    fontSize: fontSize.LG,
    lineHeight: lineHeight.LG,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  xl: {
    fontSize: fontSize.XL,
    lineHeight: lineHeight.XL,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  dxs: {
    fontSize: fontSize.DXS,
    lineHeight: lineHeight.DXS,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  dsm: {
    fontSize: fontSize.DSM,
    lineHeight: lineHeight.DSM,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  dlg: {
    fontSize: fontSize.DLG,
    lineHeight: lineHeight.DLG,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
  dxl: {
    fontSize: fontSize.DXL,
    lineHeight: lineHeight.DXL,
    fontWeight: (props) => props?.fontWeight || fontWeight.REGULAR,
    color: (props) => props?.color,
    // width: "inherit",
    whiteSpace: (props) => (props?.isEllipses || props?.isNoWrap) && "nowrap",
    overflow: (props) => props?.isEllipses && "hidden",
    textOverflow: (props) => props?.isEllipses && "ellipsis",
    wordBreak: (props) => !props?.isEllipses && "break-all",
  },
});

const Typography = ({
  size,
  color,
  label,
  fontWeight,
  isRequired = false,
  isEllipses = false,
  ...props
}) => {
  const classes = styles({ color, fontWeight, isEllipses });
  return (
    <p className={classes[size]} {...props}>
      {`${label}${isRequired ? "*" : ""}`}
    </p>
  );
};

export default Typography;
