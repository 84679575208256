import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styles } from "./styles";
import { STRINGS } from "../../strings";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { BRAND, GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import Typography from "../../../../components/Typography/Typography";
import AllAppData from "./AllAppData";
import ConnectedEspList from "./ConnectedEspList";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const ApiKeys = () => {
  const location=useLocation()
  const navigate=useNavigate()
  const classes = styles();
  const [values, setValues] = useState(1);
  const headerText = [
    {
      id: 1,
      label: STRINGS.CONNECTED,
    },
    {
      id: 2,
      label: STRINGS.ALLAPPS,
    },
  ];
  const handleChange=(val)=>{
     setValues(val)
  }
  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ tab: STRINGS.INTEGRATIONS }).toString(),
    });
  }, [navigate]);
  return (
    <Box className={classes.container}>
      <Box className={classes.scrollContainer}>
        <Box className={classes.apps}>
          <Box className={classes.headerWrap}>
            <TypographyInline
              size={"lg"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.CONNECT_WITH_APPS}
            />
            <TypographyInline
              size={"sm"}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={STRINGS.APP_HEAD}
            />
            <Box className={classes.tabBody}>
            {headerText.map((item) => {
            return (
              <Box
                className={
                  values === item.id ? classes.tabData : classes.tabData1
                }
                onClick={() => handleChange(item.id)}
              >
                <Typography
                  size={"sm"}
                  color={values === item.id ? BRAND.PRIMARY : GREY.SECONDARY}
                  fontWeight={
                    values === item.id
                      ? fontWeight.SEMIBOLD
                      : fontWeight.REGULAR
                  }
                  label={item.label}
                />
              </Box>
            );
          })}
            </Box>
          </Box>
          {values===1 ? 
            <ConnectedEspList/>
            :
            <AllAppData/>
          }
        </Box>
      </Box>
    </Box>
  );
};
export default ApiKeys;