import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { authEndpoints, campaignEndpoints } from "../../../../utils/constants/httpConstants";
import { getErrorMessage } from "../../../../utils/helpers";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { postRequest } from "../../../../Apis";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { STRINGS } from "../../strings";
import CustomModal from "../../../../components/Modals/CustomModal";
import { Button } from "../../../../components/Buttons";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { GREY } from "../../../../utils/constants/colors";
import { fontWeight } from "../../../../utils/constants/UI";
import { deleteRequest } from "../../../../Apis/apiWrapper";
import { useSelector } from "react-redux";
const styles = makeStyles({
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "28vw",
    borderRadius: "12px",
  },
  headerModal: {
    height: "72px",
    background: "#D3DAE3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerData1: {
    color: "#212833",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginLeft: "18px",
  },
  headerData2: {
    color: "#626B7E",
    marginRight: "18px",
    fontWeight: "600",
    cursor: "pointer",
  },
  delContent: {
    display: "flex",
  },
  delContentWithin: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#626B7E",
    lineHeight: "17px",
  },
  delFooter: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "4px",
    alignItems: "center",
  },
  cnclBtn: {
    marginRight: "10px",
    height: "37px",
    borderRadius: "6px",
    width: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    border: "1px solid #D3DAE3",
    color: "#626B7E",
    cursor: "pointer",
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },
  },
  crtBtn: {
    marginRight: "18px",
    height: "37px",
    color: "#FFFFFF",
    background: "#FF4848",
    borderRadius: "6px",
    width: "85px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    cursor: "pointer",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  containBtns: {
    paddingTop: "1rem",
    justifyContent: "flex-end",
    display: "flex",
  },
  closeBtn: {
    background: "white",
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "13px",
    border: "none",
    padding: "0.7rem 1.5rem",
    borderRadius: "4px",
    cursor: "pointer",
    height: "40px",
    alignSelf: "center",
  },
  containIcon: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "1rem",
  },
  para: {
    color: "#616A7D",
    fontSize: "15px",
    fontWeight: "500",
  },
});
const DisconnectModal = ({ open, setOpen, setDetails, setReload,reload }) => {
  console.log({setDetails})
  const classes = styles();
  const navigate = useNavigate()
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const [loading, setLoading] = useState(false);
  const deleteRecord = async () => {
    try {
      setLoading(true);
      if(setDetails.esp == 2 || setDetails.esp == 5){
        const response = await postRequest(
          navigate,
          authEndpoints.disconnectEsp,
          {
            email:setDetails?.email,
            // user_email:userDetailsSelector?.payload?.email
          }
        );
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
      }
      else{
        const response = await deleteRequest(
          authEndpoints.deleteEsp,
          {
            esp:setDetails.esp,
            // user_email:userDetailsSelector?.payload?.email
          },
          navigate
        );
        const resp = response?.data?.message;
        EventEmitter.emit(EMITTER_SUCCESS, resp);
      }
      setLoading(false);
    } catch (err) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
      );
    } finally {
      setReload(reload+1);
      setOpen(!open);
      setLoading(false);
    }
  };

  const modalHeaderData = {
    heading: {
      id: STRINGS.DIS_GMAIL,
      label: `${STRINGS.DISCONNECT} `,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBody}>
      <Box className={classes.delContent}>
        <TypographyInline
          size={"sm"}
          color={GREY.QUATINARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.ARE_YOU_SURE_DISCONNECT}
        />
      </Box>
    </div>
  );
  const modalFooterRightData = (
    <Box className={classes.delFooter}>
      <Button
        label={STRINGS.CANCEL}
        variant={"ghost"}
        size={"sm36"}
        onClick={() => setOpen(false)}
      />
      <Button
        label={STRINGS.DISCONNECT}
        variant={"primary"}
        size={"sm36"}
        onClick={deleteRecord}
        isLoading={loading}
      />
    </Box>
  );
  const modalFooterLeftData = <div></div>;
  return (
    <CustomModal
      close={() => setOpen(false)}
      open={open}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default DisconnectModal;
