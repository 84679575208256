export const STRINGS = {
  LIST_TEXT: "Marketing Lists",
  LIST_CREATE: "List",
  LIST_REMT: "listRemove",
  TOUR_LIST: "createlist",
  TEMPLATE_TEXT: "Templates",
  TEMPLATE_CREATE: "Template",
  SUBJECT: "Subject*",
  SUBJECT1: "Subject:",
  ERROR: "error",
  BULK_DEL_TYPE: "foldListDel",
  DEFAULT: "default",
  SEND_FIRST_NAME: "Sender's first name*",
  JOB_TITLE: "Job title*",
  ENTER_JOB_TITLE: "Enter Job title",
  CAMPANY_NAME: "Company name*",
  PHONE_NUMBER: "Phone number",
  ADDRESS_LINE: "Address line 1*",
  CITY: "City*",
  SAVED_CONTACTS: "Saved Contacts",
  STATE: "State*",
  COUNTRY: "Country*",
  POSTAL: "Postal/Zip*",
  UNSUSCRIBE: "Unsubscribe",
  ENTER_POSTAL: "Enter Zipcode",
  ENTER_COUNTRY: "Enter Country",
  ENTER_STATE: "Enter State",
  ENTER_CITY: "Enter City",
  ENTER_ADD1: "Enter Address line 1",
  ADDRESS_LINE2: "Address line 2",
  ENTER_ADD2: "Enter Address line 2",
  ENTER_PHON_NUM: "Enter Phone number",
  ENTER_CAMPANY_NAME: "Enter Company name",
  ENTER_SEND_FIRST_NAME: "Enter sender's first name",
  SEND_LAST_NAME: "Sender's last name*",
  ENTER_SEND_LAST_NAME: "Enter sender's last name",
  EMAIL_SIGNATURE: "Email Signature",
  CAMPAIGN_DEFAULT: "Campaign Defaults",
  SIGNATURE_PREVIEW: "Signature Preview",
  SEQUENCE_TEXT: "Sequences",
  SMALL_SEQUENCES: "sequences",
  SEQUENCE_CREATE: "Sequence",
  DEL_SEQ: "delSeq",
  REM_SEQ: "sequenceRemove",
  CREATE_SEQ: "Create Sequence",
  SEQUENCE_NAME: "Sequence Name*",
  TYPE_SEQ: "Sequence",
  TYPE_TEMP: "template",
  TYPE_CREATE: "create",
  TYPE_CAMP: "campaign",
  TOUR_SEQUENCE: "createsequance",
  CAMPAIGN_TEXT: "Campaigns",
  CAMPAIGNS_TEXT: "Campaign",
  CAMPAIGN_CREATE: "Campaign",

  TOUR_CAMPAIGN: "createcampaigns",
  COMMON_CREATE: "Create",
  CREATE_TEMPATE: "Create Template",
  COMMON_CANCEL: "Cancel",
  COMMON_UPDATE: "Update",
  COMMON_EDIT: "Edit",
  COMMON_SAVE: "Save",
  FOLDER_DETAILS: "Folder Details",
  CREATE_FOLDER: "Create Folder",
  CREATE_FOLD: "Create Folder",
  CREATE_LIST: "Create List",
  UPDATE_LIST: "Edit List",
  MOVE_TO: "Move to",
  DELETE: "Delete",
  PAUSE: "Pause",
  RESUME: "Resume",
  SMALL_DELETE: "delete",
  EDIT: "edit",
  STEPS_CREATED: "steps created",
  STATISTICS: "STATISTICS",
  COPYOF: "Copy of",
  MOVEUP: "Move Up",
  MOVEDOWN: "Move Down",
  DELETE_SELECTED: "Delete selected",
  DELETE_BULK: "Are you sure you want to delete the selected",
  COMMON_FOLDER: "Folder",
  MOVE: "Move",
  BACK: "Back",
  VIEW_STATUS: "View By Status",
  ADD_WIDGETS: "Add Widgets",
  ACTIONS: "Actions",
  SET_DEFAULT: "Set as Default",
  COMMON_CLONE: "Clone",
  CLONE: "clone",
  SAVE_DASHBOARD: "Save Dashboard Layout",
  CREATE_WIDGET: "Create Widget",
  SAVE_REPORT: "Save Report",
  SAVE_ADD: "Save & Add",
  SAVE_DETAILS: "Save Details",
  UPDATE_PASSWORD: "Update Password",
  FILTER: "Filter",
  APPLY: "Apply",
  ADD_CONTACT: "Add Emails",
  ADD_FILE: "Add File",
  ADD_DOMAIN: "Add Domain",
  REQUIRED: "This is a required field.",
  VIEW_CANT_25CHAR: "View Name can't be more than 25 charecters.",
  FOLDER_NAME_THREE: "Folder name should be atleast 3 characters.",
  SEQUENCE_NAME_EXCEED: "Sequence name should be less than or equal to 50",
  TEAM_ID: "team",
  PRIVATE_ID: "private",
  TEAM_LABEL: "My Team",
  PRIVATE_LABEL: "Private",
  CREATE_LISST: "Create List",
  LISTID: "_name",
  LISTNAME: "Contact List Name*",
  TYPELIST: "Type Contact List name",
  TYPESUBJECT: "Type Subject",
  TYPESEQUENCE: "Type Sequence name",
  SHARED_WITH: "Shared with*",
  DEL: "del",
  FILE: "file",
  SEARCH_FOLDER: "Search Folder",
  SEARCH_LIST: "Search List",
  TRUE: "true",
  FOLDER_ACCESS: "Folder Access",
  COMMON_VIEWALL: "View all",
  FOLDER_MNGMT: "Folder Management",
  DESELECT: "Deselect All",
  SELECT_ALL: "Select All",
  SMALL: "small",
  FIRST: "first",
  VERTICAL_ICON: "Vertical Icon",

  SMALL_FOLDER: "folder",
  RECORD_NOT_FOUND: "Records not found.",
  SELECT_ATLEAST_SEQUENCE: "Please select at least one  Sequence",
  SELECT_ANY_LIST: "Please select any one list.",
  MOVE_TO_FOLDER: "Move to folder",
  NOFOLDER_FOUND: "No folder found!",
  RM_FROM_FOLDER: "Remove from Folder",
  ADMIN_USER: "admin-user",
  ATLEAST_ONE_FOLDER: "Select atleast one folder",
  REQUIRED_FIELD: "This is a required field.",
  ATLEAST_THREE: "Sequence name length must be at least 3 characters long",
  EXCEED_CHAR: "Sequence Name cannot be more than 150 characters",
  TEMP_EXCEED_CHAR: "Template Name cannot be more than 150 characters",
  EDIT_TEMPLATE: "Edit Email Template",
  CREATE_EMAIL_TEMPLATE: "Create Email Template",
  SOMETHING_WENT_WRONG: "Something went wrong",

  COMMON_ADD: "Add",
  BODY: "Body",
  PREVIEW: "Preview",
  ENTER_TEMPLATE_NAME: "Enter template name",
  CALL: "Call",
  TODO: "To Do",
  DELETE_STEP_CONFIRMATION: "Are you sure you want to delete this step?",
  STEP: "step",
  TEMP_FOLD: "Template Folders",
  SEARCH_TEMPLATE: "Search",
  SEARCH: "Search",
  DEFAULT_NAME: "Lennister Ann",

  SEARCH_CAMPAIGN: "Search Campaign",
  TIMINGS: "Timings",
  REPLY: "reply",
  CONNECT_GOOGLE: "Connect with Google",
  CONNECT_YOUR_GMAIL:
    "Connect your Gmail inbox in order to start your campaigns",
  CONNECT: "Connect",
  COMMON_NEXT: "Next",
  CRT_SEQ: "Create Sequence",
  SEQ_CONT: "Create automated sequences of emails,call reminders,",
  SEQ_BD1: "& to-do lists to never leave another $$ on the table.",
  SEQ_BD2: "Automatic follow ups and task reminders",
  SEQ_BD3: "Build full-proof email sequences",
  SEQ_BD4: "Hyper-personalize your campaigns",

  PIN: "pin",
  COMMON_PIN: "Pin",
  DAYS_AFTER_STEP: "Days after Step number",
  DAY_AFTER_STEP: "Day after Step number",
  ADD_STEP: "Add Step",
  SEQUENCE_NAME_THREE: "Sequence name should be atleast 3 characters.",
  CREATE_NEW_LIST: "Create New List",
  LIST_NAME: "List Name",
  CREATE_CAMPAIGN: "Create Campaign",

  CONTACTS: "Contacts",
  CONTACT: "Contact",
  TOTAL_ENROL: "total_enrolled",
  PREVIEW_TEMP:
    "This is a preview for an email template. Hit edit if you wish to update the template.",
  PLZ_SEL_FOL: "Please select any Folder",
  PLZ_SEL_TEMP: "Please select at least one  Template",
  TEMP_PRV: "Template Preview",
  TO: "To:",
  TEMP_REM: "templateRemove",
  UNDEFINED: "undefined",
  DISABLE_KEY: "operation",
  CAMPAIGN_OVERVIEW_ID: "sequence",
  CAMPAIGN_OVERVIEW_LABEL: "Sequence",
  CAMPAIGN_CONTACTS_ID: "contacts",
  CAMPAIGN_CONTACTS_LABEL: "Contacts",
  CAMPAIGN_ACTIVITY_ID: "activity",
  CAMPAIGN_ACTIVITY_LABEL: "Activity Logs",
  CAMPAIGN_SETTINGS_ID: "settings",
  CAMPAIGN_SETTINGS_LABEL: "Settings",
  SENT: "Sent",
  DELIVER: "Delivered",
  MARKED_LEAD: "Marked as Lead",
  BOUNCED: "Bounces",
  UNSUBSCRIBED: "Unsubscribes",
  UNENROLL: "Unenroll",
  CAMPAIGN_INFO: "Campaign Information",
  UPDATE_CAMPAIGN_NAME: "Update Campaign Name",
  CAMPAIGN_NAME: "Campaign Name",
  OWNER_NAME: "Owner Name",
  EDIT_SCHEDULE: "Edit Schedule",
  SELECT_DATE: "Select Date",
  TIMEZONE: "Timezone",
  DAYS: "Days",
  START_TIME: "Start Time",
  END_TIME: "End Time",
  LOADING: "loading",
  STEPS: "steps",
  SEQUENCE_ID: "sequence_id",
  //
  STEP_ID: "step_id",
  ID: "_id",
  USER: "{user}",
  YOU: "You",
  TOTAL: "Total",
  COMPANY: "Company",
  PROCESS_DATE_TIME: "processing_date_time",
  SMALL_SENT: "sent",
  SMALL_DELIVER: "deliver",
  INTERRUPTED: "interrupted",
  CAMPAIGN_EDIT: "campaignedit",
  START_TYPE: "startType",
  END_TYPE: " endType",
  UL: "ul",
  COMMON_STEP: "Step",
  UNENROLL_CONFIRMATION: "Are you sure you want to unenroll this contact?",
  CANCEL: "Cancel",
  EDIT_CAMPAIGN: "Edit Campaign",
  OWNER_LIST: "Owner List",
  CLEAR: "Clear",
  OWNER: "Owner",
  SAVED_CONTACT: "Saved Prospects",
  EMAIL: "Email",
  LIST_SHOULDBE_THREE: "List name length must be at least 3 characters long",
  LIST_SHOULDBE_FIFTY: "List name cannot be more than 50 characters",
  LIST_CONT: "Start creating your lists in order to manage your contacts",
  LIST_BD: "to whom you wish to reach out for business opportunities.",
  CRT_CMPGS: "Create Campaigns",
  CMPGS_CONT: "Reach out to your potential prospects by",
  CMP_BD1: "Schedule campaigns in 3 simple steps",
  CMP_BD2: "Segregate campaigns in different folders",
  CMP_BD3: "Send emails to prospects from all time zones",
  MARKETING: "Marketing",
  ANY_REACH: "This applies across any reachiq accounts you have.",
  SEARCH_CONTACTS: "Search Contacts",
  NO_RECORD_FOUND: "No Record Found"
};
