import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { authEndpoints } from "../../utils/constants/httpConstants";
import { Anchor, FullScreenLoader, RightsTag } from "../../components";
import { PRIVACY_POLICY_LINK, TERMS_SERVICE_LINK } from "../../utils/constants";
import { makeStyles } from "@mui/styles";
import {
  // LANDING_PAGE,
  LEFT_IMAGE,
  NEW_LOGIN_REACHIQ_LOGO,
  RIGHT_IMAGE,
} from "../../utils/constants/assets";
import {
  confirmPasswordvalidation,
  passwordValidation,
} from "../../utils/validations";
import { useDispatch, useSelector } from "react-redux";

import { resetPassword } from "../../redux/actions/authActions";
import { FORGOT_PASSWORD_PATH, LOGIN_PATH } from "../../utils/constants/routes";
import InputField from "../../components/InputFields/InputField";
import Typography from "../../components/Typography/Typography";
import { BRAND, GREY } from "../../utils/constants/colors";
import { ICON_LABELS, fontWeight } from "../../utils/constants/UI";
import { STRINGS } from "./strings";
import { Button } from "../../components/Buttons";
import { SCROLL_BAR, fontSize } from "../../utils/constants/UI/uiConstants";
import Image from "../../components/Image/Image";
import { POST } from "../../Apis/apiWrapper";

const styles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    background: `url(${LEFT_IMAGE}) right top no-repeat ,url(${RIGHT_IMAGE}) left bottom no-repeat`,
    height: "100vh",
    ...SCROLL_BAR,
   
  },
  hovering: {
    "& :hover": {
      color: BRAND.PRIMARY,
    },
    marginRight: "4px",
  },
  hover: {
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  leftContainer: {
    width: "420px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    paddingTop: "100px",
    "@media (max-width: 768px)": {
      width: "100%",
      height: "100vh",
    },
  },
  privacyLinked: {
    fontSize: fontSize.SM,
    fontWeight: fontWeight.REGULAR,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: BRAND.PRIMARY,
    transition: "100ms",
    "& :hover": {
      color: "#D23A3A",
    },
  },
  privacyLink: {
    fontSize: fontSize.XS,
    fontWeight: fontWeight.REGULAR,
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: "#626B7E",
    transition: "100ms",
    "&:hover": {
      color: BRAND.PRIMARY,
    },
  },
  privacynotLink: {
    fontSize: fontSize.XS,
    fontWeight: fontWeight.REGULAR,
    color: "#626B7E",
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
  },
  margins: { marginBottom: "4px", marginTop: "16px" },

  containInstructions: {
    paddingTop: "60px",
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
    textAlign: "center",
  },

  marginBoott: {
    marginBottom: "4px",
  },

  paddingBott: {
    paddingTop: "32px",
  },
  felx: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
    alignItems: "center",
  },
  logo: {
    
    objectFit: "contain",
  },
  containLogo: {
    display: "flex",
    justifyContent: "center",
  },
  termsPara: {
    fontSize: "clamp(12px, 0.729vw, 30px)",
    color: "#626B7E",
    fontWeight: "400",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  containPolicy: {
    display: "flex",
    justifyContent: "center",
  },

  flex: {
    display: "flex",
    justifyContent: "center",
  },

  containLoginBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: "24px",
  },
  bottomContainer: {
    height: "50vh",
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem",
    flexDirection: "column",
  },

  inputLabelText: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontSize: "clamp(12px, 0.729vw, 24px)",
    fontWeight: "500",
    marginBottom: "4px",
    color: "#212833",
    paddingTop: "0.6rem",
  },
});

const UpdatePasswordScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetPasswordSelector = useSelector((state) => state.resetPassword);
  const { loading, payload, error } = resetPasswordSelector;
  const classes = styles();
  const params = useParams();
  const [checking, setChecking] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validations, setValidations] = useState({
    password: "",
    confirmPassord: "",
  });
  const [passwordType, setPasswordType] = useState(STRINGS.password);
  const [confirmpasswordType, setConfirmPasswordType] = useState(
    STRINGS.password
  );

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const validatedPassword = passwordValidation(password);
    const validatedComfirmPassword = confirmPasswordvalidation(
      password,
      confirmPassword
    );

    if (validatedPassword.isValid && validatedComfirmPassword.isValid) {
      const formdata = {
        verification_token: params.id,
        password: password,
      };
      const response = await dispatch(resetPassword(formdata, navigate));
      console.log({ response });
    
    } else {
      setValidations({
        password: validatedPassword.message,
        confirmPassord: validatedComfirmPassword.message,
      });
    }
  };

  const togglePassword = () => {
    if (passwordType === STRINGS.password) {
      setPasswordType(STRINGS.TEXT);
      return;
    }

    setPasswordType(STRINGS.password);
  };
  const toggleConfirmPassword = () => {
    if (confirmpasswordType === STRINGS.password) {
      setConfirmPasswordType(STRINGS.TEXT);
      return;
    }

    setConfirmPasswordType(STRINGS.password);
  };
  useEffect(() => {
    if (payload) {
      setPassword("");
      setConfirmPassword("");
      navigate(LOGIN_PATH);
    }
    if (error) {
      setValidations({
        password: error?.data?.errors?.password || "",
      });
    }
    if (!password.length) {
      POST(authEndpoints.verifyTokenForResetPassword, {
        verification_token: params.id,
      })
        .then((response) => {
         
        })
        .catch((err) => {
          navigate(FORGOT_PASSWORD_PATH);
        })
        .finally(() => {
          setChecking(false);
        });
    }
  }, [payload, error, navigate, params]);

  return checking ? (
    <FullScreenLoader />
  ) : (
    <Box component="main" className={classes.container}>
      <Box className={classes.leftContainer}>
        <Box>
          <div className={classes.containLogo}>
            <Image src={NEW_LOGIN_REACHIQ_LOGO} className={classes.logo} />
          </div>
          {/* Main auth form here */}

          <Box className={classes.containInstructions}>
            <Typography
              size={"dxs"}
              color={GREY.PRIMARY}
              fontWeight={fontWeight.SEMIBOLD}
              label={STRINGS.RESET_PASSWORD}
            />{" "}
            <Box className={classes.marginBoott}>
              <Typography
                size={"sm"}
                color={GREY.TERTIARY}
                fontWeight={fontWeight.REGULAR}
                label={STRINGS.TO_GET_STARTED}
              />{" "}
            </Box>
          </Box>
          <Box
            component="form"
            onSubmit={handleUpdatePassword}
            className={classes.paddingBott}
          >
            <div className={classes.marginBoott}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.PASSWORD}
              />{" "}
            </div>

            <InputField
              variant={validations.password ? STRINGS.ERROR : STRINGS.DEFAULT}
              errormessage={validations.password ? validations.password : ""}
              placeholder={STRINGS.ENTER_PASSWORD}
              size={"md40"}
              value={password}
              type={passwordType}
              RightIcon={
                passwordType === STRINGS.password
                  ? ICON_LABELS.VISIBILITY_OFF
                  : ICON_LABELS.VISIBILITY_ON
              }
              rightOnClick={() => togglePassword()}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className={classes.margins}>
              <Typography
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.CONFIRM_PASSWORD}
              />{" "}
            </div>

            <InputField
              variant={
                validations.confirmPassord ? STRINGS.ERROR : STRINGS.DEFAULT
              }
              errormessage={
                validations.confirmPassord ? validations.confirmPassord : ""
              }
              placeholder={STRINGS.ENTER_CONFIRM_PASS}
              size={"md40"}
              type={confirmpasswordType}
              RightIcon={
                confirmpasswordType === STRINGS.password
                  ? ICON_LABELS.VISIBILITY_OFF
                  : ICON_LABELS.VISIBILITY_ON
              }
              rightOnClick={() => toggleConfirmPassword()}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <Box className={classes.containLoginBtn}>
              <Button
                label={STRINGS.RESET_PASSWORD}
                size={"md40"}
                variant={"primary"}
                isLoading={loading}
              />
            </Box>
            <Box className={classes.felx}>
              <Typography
                label={STRINGS.DO_YOU_WANT}
                color={GREY.SECONDARY}
                size={"sm"}
                fontWeight={fontWeight.MEDIUM}
              />
              <Anchor
                href={LOGIN_PATH}
                className={classes.privacyLinked}
                target="_blank"
              >
                <Typography
                  label={STRINGS.LOG_IN}
                  color={BRAND.PRIMARY}
                  size={"sm"}
                  fontWeight={fontWeight.REGULAR}
                />
              </Anchor>
            </Box>
          </Box>
        </Box>
        {/* Main auth form here */}
        <div className={classes.bottomContainer}>
          <div className={classes.containPolicy}>
            <RightsTag className={classes.termsPara} />
            {/* Privacy and Terms. */}
            <Anchor
              href={PRIVACY_POLICY_LINK}
              className={classes.privacyLink}
              target="_blank"
            >
              <div className={classes.hovering}>
                <Typography
                  label={STRINGS.PRIVACY}
                  color={GREY.SECONDARY}
                  size={"xs"}
                  fontWeight={fontWeight.SEMIBOLD}
                />
              </div>
            </Anchor>
            <div className={classes.TypoHead}>
              <Typography
                label={STRINGS.AND}
                color={GREY.TERTIARY}
                size={"xs"}
                fontWeight={fontWeight.MEDIUM}
              />
            </div>
            <Anchor
              href={TERMS_SERVICE_LINK}
              className={classes.privacynotLink}
              target="_blank"
            >
              <div className={classes.hover}>
                {" "}
                <Typography
                  label={STRINGS.TERMS}
                  color={GREY.SECONDARY}
                  size={"xs"}
                  fontWeight={fontWeight.SEMIBOLD}
                />
              </div>

              {/* Terms. */}
            </Anchor>
          </div>
        </div>
      </Box>
    </Box>
  );
  
};

export default UpdatePasswordScreen;
