import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  CROWN_ICON,
  DUMMY_COMPANY_IMAGE,
  DUMMY_CONTACT2,
  EMAIL_ICON,
  PHONE_ICON,
} from "../../../utils/constants/assets";
import {
  EMITTER_ERROR,
  USER_MODAL_COMPANY_DETAIL,
} from "../../../utils/constants";
import { Anchor, FullScreenLoader } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { getPeopleUserDetails } from "../../../redux/actions/peopleActions";
import {
  convertToInternationalCurrencySystem,
  createCompanyURL,
  filterUserName,
} from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../../Apis";
import { contactListEndPoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import AvatarName from "../../../components/Avatar/AvatarName";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { ICON_LABELS, fontSize, fontWeight } from "../../../utils/constants/UI";
import { BASE, BRAND, GREY, SUCCESS } from "../../../utils/constants/colors";
import { Button } from "../../../components/Buttons";
import { STRINGS } from "../strings";
import AvatarOutlined from "../../../components/Avatar/AvatarOutlined";
import Image from "../../../components/Image/Image";
import IconComponent from "../../../components/Icon/IconComponent";
import CustomTooltip from "../../../components/Tooltip/CustomTooltip";
import {
  LinkedInIcon,
  TwitterIcon,
  FacebookIcon,
} from "../../../components/Icon/MuiIcons";
import CustomDialog from "../../../components/Dialog/CustomDialog";
import classes from "./LeadsComponent.module.css";
import { tableDataFetch } from "../../../redux/actions/tableActions";

const UserDetailModal = ({
  user,
  tableData = [],
  setShowUserDetailModal,
  isCompanyTable,
  modalOpen,
  setIsActive,
  setUserDetailOpen = () => { },
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userDetailsSelector = useSelector((state) => state?.peopleUserDetails);
  const [open, setOpen] = useState(modalOpen || false);
  const [technologies, setTechnologies] = useState([]);
  const [record, setRecord] = useState({});
  const [unlocking, setUnlocking] = useState(false);
  const [clipboardValue, setClipBoardValue] = useState({});

  const { loading, payload } = userDetailsSelector;

  const filterValue = (key, item) => {
    if (key === STRINGS.ANNUAL_REVENUE) {
      return convertToInternationalCurrencySystem(item)?.toString();
    }
    return item;
  };

  useEffect(() => {
    if (payload) {
      try {
        
      
      if (Array.isArray(payload?.company_technologies)) {
      

        setTechnologies(payload?.company_technologies?.join()?.slice(0, 5));
        payload.company_technologies= payload?.company_technologies?.join()


      } else {

        setTechnologies(payload?.company_technologies?.split(" ")?.slice(0, 5));
      }
    } catch (error) {
      // alert(JSON.stringify(error))
        console.log({error},"--------------->")
    }
    }
    return () => {
      setRecord({});
    };
  }, [payload]);
console.log({technologies})
  useEffect(() => {
    setOpen(modalOpen);
    if (modalOpen) {
      dispatch(getPeopleUserDetails(user._id, navigate));
    }
  }, [modalOpen]);

  const handleShowMore = () => {

    try {
      
    
    if (Array.isArray(payload?.company_technologies)) {
      
      console.log(payload?.company_technologies)

      setTechnologies(payload?.company_technologies?.join()?.slice(0, 5));
      payload.company_technologies= payload?.company_technologies?.join()


    } else {
      console.log(payload?.company_technologies)

      setTechnologies(payload?.company_technologies?.split(","));
    }} catch (error) {
      // alert(JSON.stringify(error))

    }
  };

  const showAll = () => {
    try {
      
   
    if (Array.isArray(payload?.company_technologies)) {
      setTechnologies(payload?.company_technologies?.join()?.slice(0, 5));
      payload.company_technologies= payload?.company_technologies?.join()


    } else {
      setTechnologies(payload?.company_technologies?.split(" "));
    } } catch (error) {
      // alert(JSON.stringify(error))

    }
  };

  const handleShowLess = () => {
    try {
      
   
    if (Array.isArray(payload?.company_technologies)) {
      console.log(payload?.company_technologies)
      setTechnologies(payload?.company_technologies?.join()?.slice(0, 5));
      payload.company_technologies= payload?.company_technologies?.join()

    } else {
      console.log(payload?.company_technologies)

      setTechnologies(payload?.company_technologies?.split(",")?.slice(0, 5));
    }
  } catch (error) {
    // alert(JSON.stringify(error))

  }
  };

  const handleUnlockEmail = async () => {
    try {
      setUnlocking(true);
      const response = await postRequest(
        navigate,
        contactListEndPoints.singleEmailUnlock,
        { record_id: user?._id }
      );
      setRecord(response?.data?.data);
      setUnlocking(false);

      if (tableData.length) {
        const newData = JSON.parse(JSON.stringify(tableData));
        const updatedRecord = newData?.filter((eachRecord) => {
          return eachRecord?._id === user?._id;
        })[0];
        updatedRecord.email = response?.data?.data?.email;
        const result = newData?.map((eachRecord) => {
          return eachRecord?._id === user?._id ? updatedRecord : eachRecord;
        });
        dispatch(tableDataFetch({}, navigate, true, result));
      }
    } catch (err) {
      EventEmitter.emit(
        EMITTER_ERROR,
        err?.data?.message || STRINGS.SOMETHING_WENT_WRONG
      );

      setUnlocking(false);
    }
  };

  const handleCopyText = (value, id) => {
    try {
      setClipBoardValue({ id: id, value: value });
      navigator?.clipboard?.writeText(value);

      setTimeout(() => {
        setClipBoardValue({});
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };
  const isArray= Array.isArray(payload?.company_technologies)

  const USER_MODAL_COMPANY_INFO = [
    {
      icon: <FacebookIcon className={classes.socialIcon} />,
      component: payload?.company_facebook_url,
    },
    {
      icon: <TwitterIcon className={classes.socialIcon} />,
      component: payload?.company_twitter_url,
    },
    {
      icon: <LinkedInIcon className={classes.socialIcon} />,
      component: payload?.company_linkedin_url,
    },
  ];
  const closeUserModal = () => {
    if (isCompanyTable) {
      setShowUserDetailModal(false);
      setOpen(false);
      setIsActive("");
    } else {
      setUserDetailOpen(false);
    }
  };
  const dialogHeader = {
    heading: {
      label: STRINGS.VIEW_PROFILE,
    },
  };
console.log({technologies})

// const updatedTech= technologies?.split(",")
const isArrayTechnologies= Array.isArray(technologies)

  return (
    <>
      <CustomDialog
        open={open}
        keepMounted={true}
        onClose={!isCompanyTable && closeUserModal}
        width={"500px"}
        header={dialogHeader}
      >
        {loading ? (
          <FullScreenLoader />
        ) : (
          <Box className={classes.containUserModal}>
            <Box className={classes.containPersonalDetails}>
              {payload?.first_name || payload?.last_name ? (
                <AvatarName
                  name={payload?.first_name + " " + (payload?.last_name || "")}
                  size="XXL"
                />
              ) : (
                <AvatarOutlined size={"XXL"}>
                  <Image
                    src={DUMMY_CONTACT2}
                    alt=""
                    style={{
                      height: "78px",
                      width: "78px",
                    }}
                  />
                </AvatarOutlined>
              )}
              <Box className={classes.headingContainer}>
                <TypographyInline
                  label={(payload?.first_name || "") + " " + (payload?.last_name || "")}
                  size={"md"}
                  fontWeight={fontWeight.SEMIBOLD}
                  color={GREY.PRIMARY}
                />
                <TypographyInline
                  label={payload?.job_title || ""}
                  size={"xs"}
                  fontWeight={fontWeight.REGULAR}
                  color={BRAND.SECONDARY}
                />
              </Box>
            </Box>

            <Box className={classes.socialIconsContainer1}>
              {payload?.contact_linkedin_url ? (
                <div className={classes.containLinkedin}>
                  <Anchor
                    href={payload?.contact_linkedin_url}
                    target={STRINGS.BLANK}
                  >
                    <LinkedInIcon className={classes.socialIcon} />
                  </Anchor>
                </div>
              ) : null}
            </Box>

            <Box className={classes.containBasicInfo}>
              <Box
                className={
                  payload?.email || Object.keys(record).length
                    ? classes.basicInfoUnset
                    : classes.basicInfoBlur
                }
              >
                <div className={classes.containCrownInfo}>
                  <TypographyInline
                    label={STRINGS.PERSONAL_INFORMATION}
                    size={"sm"}
                    fontWeight={fontWeight.MEDIUM}
                    color={GREY.PRIMARY}
                  />
                </div>
                <div className={classes.containEachInfo}>
                  <div className={classes.basicInfoLeft}>
                    <AvatarOutlined size={"small"}>
                      <Image src={EMAIL_ICON} />
                    </AvatarOutlined>
                    <div>
                      <TypographyInline
                        label={STRINGS.EMAIL}
                        size={"sm"}
                        fontWeight={fontWeight.SEMIBOLD}
                        color={GREY.SECONDARY}
                      />
                      <div className={classes.labelIconContainer}>
                        <TypographyInline
                          label={
                            record?.email ||
                            payload?.email ||
                            STRINGS.NOTAPPLICABLE
                          }
                          size={"sm"}
                          fontWeight={fontWeight.REGULAR}
                          color={GREY.SECONDARY}
                        />
                        <div
                          onClick={() =>
                            handleCopyText(
                              record?.email ||
                              payload?.email ||
                              STRINGS.NOTAPPLICABLE,
                              STRINGS.EMAIL_SMALL
                            )
                          }
                          className={classes.copyIcon}
                        >
                          {clipboardValue?.value === payload?.email &&
                            clipboardValue?.id === STRINGS.EMAIL_SMALL ? (
                            <div
                              id={STRINGS.COPIED_TEXT_CNTR}
                              className={classes.copiedTextContainer}
                            >
                              <IconComponent
                                fontSize={fontSize.SM}
                                color={SUCCESS.W_500}
                                iconLabel={ICON_LABELS.DONE}
                              />
                              <TypographyInline
                                isEllipses
                                color={SUCCESS.W_500}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.COPIED_LABEL}
                                size={"xs"}
                              />
                            </div>
                          ) : (
                            <IconComponent
                              color={GREY.SECONDARY}
                              fontSize={fontSize.MD}
                              iconLabel={ICON_LABELS.CONTENT_COPY}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {record?.direct_phone_number || payload?.direct_phone_number ? (
                  <div className={classes.containEachInfo}>
                    <div className={classes.basicInfoLeft}>
                      <AvatarOutlined size={"small"}>
                        <Image src={PHONE_ICON} />
                      </AvatarOutlined>
                      <div>
                        <TypographyInline
                          label={STRINGS.MOBILE_NUMBER}
                          size={"sm"}
                          fontWeight={fontWeight.SEMIBOLD}
                          color={GREY.SECONDARY}
                        />
                        <div className={classes.labelIconContainer}>
                          <TypographyInline
                            label={
                              record?.direct_phone_number ||
                              payload?.direct_phone_number ||
                              STRINGS.NOTAPPLICABLE
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                            color={GREY.SECONDARY}
                          />
                          <div
                            onClick={() =>
                              handleCopyText(
                                record?.direct_phone_number ||
                                payload?.direct_phone_number ||
                                STRINGS.NOTAPPLICABLE,
                                STRINGS.DIRECT_PHONE_NUMBER
                              )
                            }
                            className={classes.copyIcon}
                          >
                            {clipboardValue?.value ===
                              payload?.direct_phone_number &&
                              clipboardValue?.id ===
                              STRINGS.DIRECT_PHONE_NUMBER ? (
                              <div
                                id={STRINGS.COPIED_TEXT_CNTR}
                                className={classes.copiedTextContainer}
                              >
                                <IconComponent
                                  fontSize={fontSize.SM}
                                  color={SUCCESS.W_500}
                                  iconLabel={ICON_LABELS.DONE}
                                />
                                <TypographyInline
                                  isEllipses
                                  color={SUCCESS.W_500}
                                  fontWeight={fontWeight.MEDIUM}
                                  label={STRINGS.COPIED_LABEL}
                                  size={"xs"}
                                />
                              </div>
                            ) : (
                              <IconComponent
                                color={GREY.SECONDARY}
                                fontSize={fontSize.MD}
                                iconLabel={ICON_LABELS.CONTENT_COPY}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : payload?.email || Object.keys(record).length ? null : (
                  <div className={classes.containEachInfo}>
                    <div className={classes.basicInfoLeft}>
                      <AvatarOutlined size={"small"}>
                        <Image src={PHONE_ICON} />
                      </AvatarOutlined>
                      <div>
                        <TypographyInline
                          label={STRINGS.MOBILE_NUMBER}
                          size={"sm"}
                          fontWeight={fontWeight.SEMIBOLD}
                          color={GREY.SECONDARY}
                        />
                        <div className={classes.labelIconContainer}>
                          <TypographyInline
                            label={
                              record?.direct_phone_number ||
                              payload?.direct_phone_number ||
                              STRINGS.NOTAPPLICABLE
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                            color={GREY.SECONDARY}
                          />
                          <div
                            onClick={() =>
                              handleCopyText(
                                record?.direct_phone_number ||
                                payload?.direct_phone_number ||
                                STRINGS.NOTAPPLICABLE,
                                STRINGS.DIRECT_PHONE_NUMBER
                              )
                            }
                            className={classes.copyIcon}
                          >
                            {clipboardValue?.value ===
                              payload?.direct_phone_number &&
                              clipboardValue?.id ===
                              STRINGS.DIRECT_PHONE_NUMBER ? (
                              <div
                                id={STRINGS.COPIED_TEXT_CNTR}
                                className={classes.copiedTextContainer}
                              >
                                <IconComponent
                                  fontSize={fontSize.SM}
                                  color={SUCCESS.W_500}
                                  iconLabel={ICON_LABELS.DONE}
                                />
                                <TypographyInline
                                  isEllipses
                                  color={SUCCESS.W_500}
                                  fontWeight={fontWeight.MEDIUM}
                                  label={STRINGS.COPIED_LABEL}
                                  size={"xs"}
                                />
                              </div>
                            ) : (
                              <IconComponent
                                color={GREY.SECONDARY}
                                fontSize={fontSize.MD}
                                iconLabel={ICON_LABELS.CONTENT_COPY}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {record?.company_phone_number ||
                  payload?.company_phone_number ? (
                  <div className={classes.containEachInfo}>
                    <div className={classes.basicInfoLeft}>
                      <AvatarOutlined size={"small"}>
                        <Image src={PHONE_ICON} />
                      </AvatarOutlined>
                      <div>
                        <TypographyInline
                          label={STRINGS.CORPORATE_NUMBER}
                          size={"sm"}
                          fontWeight={fontWeight.SEMIBOLD}
                          color={GREY.SECONDARY}
                        />
                        <div className={classes.labelIconContainer}>
                          <TypographyInline
                            label={
                              record?.company_phone_number ||
                              payload?.company_phone_number ||
                              STRINGS.NOTAPPLICABLE
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                            color={GREY.SECONDARY}
                          />
                          <div
                            onClick={() =>
                              handleCopyText(
                                record?.company_phone_number ||
                                payload?.company_phone_number ||
                                STRINGS.NOTAPPLICABLE,
                                STRINGS.COMPANY_PHONE_NUMBER
                              )
                            }
                            className={classes.copyIcon}
                          >
                            {clipboardValue?.value ===
                              payload?.company_phone_number &&
                              clipboardValue?.id ===
                              STRINGS.COMPANY_PHONE_NUMBER ? (
                              <div
                                id={STRINGS.COPIED_TEXT_CNTR}
                                className={classes.copiedTextContainer}
                              >
                                <IconComponent
                                  fontSize={fontSize.SM}
                                  color={SUCCESS.W_500}
                                  iconLabel={ICON_LABELS.DONE}
                                />
                                <TypographyInline
                                  isEllipses
                                  color={SUCCESS.W_500}
                                  fontWeight={fontWeight.MEDIUM}
                                  label={STRINGS.COPIED_LABEL}
                                  size={"xs"}
                                />
                              </div>
                            ) : (
                              <IconComponent
                                color={GREY.SECONDARY}
                                fontSize={fontSize.MD}
                                iconLabel={ICON_LABELS.CONTENT_COPY}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : payload?.email || Object.keys(record).length ? null : (
                  <div
                    className={
                      !unlocking
                        ? classes.containEachInfo
                        : classes.containUnlockedInfo
                    }
                  >
                    <div className={classes.basicInfoLeft}>
                      <AvatarOutlined size={"small"}>
                        <Image src={PHONE_ICON} />
                      </AvatarOutlined>
                      <div>
                        <TypographyInline
                          label={STRINGS.CORPORATE_NUMBER}
                          size={"sm"}
                          fontWeight={fontWeight.SEMIBOLD}
                          color={GREY.SECONDARY}
                        />
                        <div className={classes.labelIconContainer}>
                          <TypographyInline
                            label={
                              record?.company_phone_number ||
                              payload?.company_phone_number ||
                              STRINGS.NOTAPPLICABLE
                            }
                            size={"sm"}
                            fontWeight={fontWeight.REGULAR}
                            color={GREY.SECONDARY}
                          />
                          <div
                            onClick={() =>
                              handleCopyText(
                                record?.company_phone_number ||
                                payload?.company_phone_number ||
                                STRINGS.NOTAPPLICABLE,
                                STRINGS.COMPANY_PHONE_NUMBER
                              )
                            }
                            className={classes.copyIcon}
                          >
                            {clipboardValue?.value ===
                              payload?.company_phone_number &&
                              clipboardValue?.id ===
                              STRINGS.COMPANY_PHONE_NUMBER ? (
                              <div
                                id={STRINGS.COPIED_TEXT_CNTR}
                                className={classes.copiedTextContainer}
                              >
                                <IconComponent
                                  fontSize={fontSize.SM}
                                  color={SUCCESS.W_500}
                                  iconLabel={ICON_LABELS.DONE}
                                />
                                <TypographyInline
                                  isEllipses
                                  color={SUCCESS.W_500}
                                  fontWeight={fontWeight.MEDIUM}
                                  label={STRINGS.COPIED_LABEL}
                                  size={"xs"}
                                />
                              </div>
                            ) : (
                              <IconComponent
                                color={GREY.SECONDARY}
                                fontSize={fontSize.MD}
                                iconLabel={ICON_LABELS.CONTENT_COPY}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Box>

              {unlocking ? (
                <Box className={classes.FLEX}>
                  <div className={classes.unloackButton}>
                    <Button
                      label={""}
                      size={"sm36"}
                      variant={"primary"}
                      isLoading={unlocking}
                    />
                  </div>
                </Box>
              ) : payload?.email || Object.keys(record).length ? null : (
                <Box className={classes.containLockedInfo}>
                  <Image src={CROWN_ICON} width="100%" height="30px" />
                  <div className={classes.unlockLabelContainer}>
                    <TypographyInline
                      label={STRINGS.UNLOCK_BASIC_INFORMATION}
                      size={"md"}
                      fontWeight={fontWeight.SEMIBOLD}
                      color={BASE.BLACK}
                    />
                  </div>
                  <div className={classes.unlockLabelContainer}>
                    <TypographyInline
                      label={STRINGS.UNLOCK_BASIC_INFORMATION_DESC}
                      size={"xs"}
                      fontWeight={fontWeight.REGULAR}
                      color={GREY.SECONDARY}
                    />
                  </div>
                  <div className={classes.unloackButton}>
                    <Button
                      LeftIcon={ICON_LABELS.LOCK_PERSON}
                      label={STRINGS.UNLOCK}
                      onClick={() => handleUnlockEmail()}
                      size={"sm36"}
                      variant={"primary"}
                    />
                  </div>
                </Box>
              )}
            </Box>

            {/* Account Information */}
            <Box className={classes.containAccountsInfo}>
              <Box className={classes.containAccountsTitle}>
                <TypographyInline
                  label={STRINGS.COMPANY_INFORMATION}
                  size={"sm"}
                  fontWeight={fontWeight.MEDIUM}
                  color={GREY.PRIMARY}
                />
              </Box>
              <Box className={classes.flexJustifyBetween}>
                <Box className={classes.flexDis}>
                  <AvatarOutlined size={"large"}>
                    <Image
                      onError={(e) => (e.target.src = DUMMY_COMPANY_IMAGE)}
                      src={
                        createCompanyURL(payload?.company_website) ||
                        DUMMY_COMPANY_IMAGE
                      }
                      className={classes.comapnyImage}
                      alt=""
                    />
                  </AvatarOutlined>
                  <div className={classes.flexDis}>
                    <CustomTooltip
                      title={
                        payload?.company_name?.length >= 15
                          ? payload?.company_name
                          : ""
                      }
                    >
                      <TypographyInline
                        label={filterUserName(payload?.company_name) || ""}
                        size={"md"}
                        fontWeight={fontWeight.SEMIBOLD}
                        color={GREY.PRIMARY}
                      />
                    </CustomTooltip>
                  </div>
                </Box>
                <Box className={classes.socialIconsContainer}>
                  {USER_MODAL_COMPANY_INFO?.map((item) => {
                    return (
                      item?.component && (
                        <div className={classes.containFacebook}>
                          <Anchor href={item?.component} target={STRINGS.BLANK}>
                            {item.icon}
                          </Anchor>
                        </div>
                      )
                    );
                  })}
                </Box>
              </Box>
              <Box className={classes.userDetailContain}>
                {USER_MODAL_COMPANY_DETAIL.map((item) => {
                  return payload?.[item?.id] ? (
                    <Box
                      className={classes.userEachDetailContain}
                      key={item.id}
                    >
                      {
                        <IconComponent
                          color={GREY.TERTIARY}
                          fontSize={fontSize.MD}
                          iconLabel={item.icon}
                        />
                      }
                      {item?.id === STRINGS.COMPANY_WEBSITE ? (
                        <Anchor
                          href={payload?.[item?.id]}
                          className={classes.link}
                          target={STRINGS.BLANK}
                        >
                          {payload?.[item?.id] || STRINGS.NOTAPPLICABLE}
                        </Anchor>
                      ) : (
                        <TypographyInline
                          label={`${filterValue(item?.id, payload?.[item?.id]) ||
                            STRINGS.NOTAPPLICABLE
                            } ${filterValue(item?.id, payload?.[item?.id]) &&
                            item?.text
                            } `}
                          size={"sm"}
                          fontWeight={fontWeight.REGULAR}
                          color={GREY.SECONDARY}
                        />
                      )}
                    </Box>
                  ) : null;
                })}
              </Box>
            </Box>
            {technologies?.length ? (
              <Box className={classes.containAccountsInfo}>
                <Box className={classes.containAccountsTitle}>
                  <TypographyInline
                    label={STRINGS.TECHNOLOGIES}
                    size={"sm"}
                    fontWeight={fontWeight.MEDIUM}
                    color={GREY.PRIMARY}
                  />
                  {/* {payload?.company_technologies?.split(" ")?.length > 5 && (
                    <div className={classes.pointerCursor} >
                      <TypographyInline
                        label={STRINGS.VIEW_ALL}
                        size={"sm"}
                        fontWeight={fontWeight.MEDIUM}
                        color={BRAND.PRIMARY}
                        onClick={showAll}
                      />
                    </div>
                  )} */}

                  {isArray  ?payload?.company_technologies?.length > 5&& (
                    <div className={classes.pointerCursor} >
                      <TypographyInline
                        label={STRINGS.VIEW_ALL}
                        size={"sm"}
                        fontWeight={fontWeight.MEDIUM}
                        color={BRAND.PRIMARY}
                        onClick={showAll}
                      />
                    </div>) :payload?.company_technologies?.split(" ")?.length > 5 && (
                    <div className={classes.pointerCursor} >
                      <TypographyInline
                        label={STRINGS.VIEW_ALL}
                        size={"sm"}
                        fontWeight={fontWeight.MEDIUM}
                        color={BRAND.PRIMARY}
                        onClick={showAll}
                      />
                    </div>
                  )}
                </Box>
                {isArrayTechnologies ?
                
                technologies?.map((technology, index) => {
                  return (
                    <Box key={index} className={classes.flexDisplay}>
                      <AvatarName name={technology} size={"small"} />
                      <Box className={classes.containTechPara}>
                        <TypographyInline
                          label={technology}
                          size={"sm"}
                          fontWeight={fontWeight.REGULAR}
                          color={GREY.SECONDARY}
                        />
                      </Box>
                    </Box>
                  );
                }
              
              
              ):technologies?.split(",")?.map((technology, index) => {
                return (
                  <Box key={index} className={classes.flexDisplay}>
                    <AvatarName name={technology} size={"small"} />
                    <Box className={classes.containTechPara}>
                      <TypographyInline
                        label={technology}
                        size={"sm"}
                        fontWeight={fontWeight.REGULAR}
                        color={GREY.SECONDARY}
                      />
                    </Box>
                  </Box>
                );
              })
              
              
              }
              
                {  technologies?.length !==
                  payload?.company_technologies?.split(",")?.length &&
                  payload?.company_technologies?.split(",")?.length > 5 ? (
                  <Button
                    size={"xs28"}
                    label={STRINGS.SHOW_MORE}
                    variant={"text"}
                    onClick={handleShowMore}
                  />
                ) : payload?.company_technologies?.split(",")?.length > 5 ? (
                  <Button
                    size={"xs28"}
                    label={STRINGS.SHOW_LESS}
                    variant={"text"}
                    onClick={handleShowLess}
                  />
                ) : null}









              </Box>
            ) : null}
          </Box>
        )}
      </CustomDialog>
    </>
  );
};

export default React.memo(UserDetailModal);
