import { makeStyles } from "@mui/styles";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import { BASE, ACTIONS, GREY, ERROR, ORANGE, WARNING } from "../../utils/constants/colors";
import { SHADOW } from "../../utils/constants/UI";
import {
  SCROLL_BAR,
  fontSize,
  fontWeight,
} from "../../utils/constants/UI/uiConstants";

export const Styles = makeStyles({

  HIGH:{
    width:"max-content",
    borderRadius:"4px",
padding:"4px 12px 4px 12px",
backgroundColor: ERROR.W_50
  },MEDIUM:{borderRadius:"4px",
    width:"max-content",

    padding:"4px 12px 4px 12px",
    backgroundColor: ORANGE.W_50
  },LOW:{borderRadius:"4px",
    width:"max-content",

    padding:"4px 12px 4px 12px",
    backgroundColor: WARNING.W_50
  },
  modalFooterRightData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  Notes: {
    color: GREY.PRIMARY,
    fontWeight: fontWeight.MEDIUM,
    fontSize: fontSize.SM,
  },
  // flex: {
  //   display: "flex",
  // },
  HEADBOX: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
    // height: "calc(100% - 57px);",
    // display: "flex",

    // justifyContent: "center",
    // alignItems: "center",
  },
  Display1: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    paddingTop: "10px",
  },
  DisplayCreate: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    paddingTop: "22px",
  },
  button: {
    fontSize: "clamp(0.75rem,0.833vw,1rem)",
    background: "#ff4848",
    padding: "1rem 2rem",
    color: "white",
    cursor: "pointer",
    border: "1px solid #ff4848",
    borderRadius: "8px",
    // marginRight:"30px"
    "&:hover": {
      background: "#0F2F51",
      border: "1px solid #0F2F51",
    },
    "@media (max-width: 1470px)": {
      padding: "0.75rem 1.5rem",
      fontSize: "14px",
    },
  },
  pTags: {
    marginTop: "4px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(0.85rem,0.938vw,1.1rem)",
    // lineHeight: "24px",
    /* or 167% */
    color: "#626B7E",
    textAlign: "center",
    "@media (max-width: 1470px)": {
      fontSize: "15px",
    },
  },
  Create: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "clamp(1.2rem,1.563vw,1.9rem)",
    // lineHeight: "36px",
    justifyContent: "center",
    color: "#0F2F51",
    marginTop: "1rem",
  },
  DISPLAY: {
    display: "flex",
    flexDirection: "column",
  },
  Display: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    // paddingTop:"14px",
  },
  tableBox: {
    width: "100%",
    padding: "1rem",
    paddingTop: "0",
  },
  header: {
    margin: "1rem",
    background: "#FFFFFF",
    height: "70vh",
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 1470px)": {
      height: "60vh",
    },
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "4px",
  },
  Props: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "16px",
    color: " #F7F9FB",
    paddingLeft: "12px",
    textTransform: "capitalize",
  },
  SpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "0.5rem",
  },
  FLexBottom: {
    display: "flex",
    paddingBottom: "0.5rem",
  },
  marginRight: {
    marginRight: "10px",
  },
  taskTitle: {
    fontWeight: "600",
    fontSize: "clamp(16px, 1.042vw, 30px)",
    letterSpacing: "0.8px",
    fontFamily: "Inter",
    fontStyle: "normal",
    // marginLeft: '10px',
    color: "#0f2f51",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  BulkDelete: {
    width: "92px",
  },
  Test: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D3DAE3",
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-root .MuiInputBase-input-MuiOutlinedInput-input": {
      color: "#626B7E",
    },
    "& .MuiOutlinedInput-root .MuiInputBase-root-MuiInput-root": {
      color: "blue",
    },
    "& .MuiInputBase-root .MuiInput-root MuiInputBase-colorPrimary .MuiInputBase-formControl .MuiInputBase-sizeSmall .MuiInputBase-root-MuiInput-root":
      {
        fontSize: "12px !important",
      },
  },
  titleBar1: {
    padding: "0.5rem 1rem",
    background: "white",
    borderBottom: `1px solid ${ACTIONS.DEFAULT}`,
  },
  titleBar2: {
    margin: "16px 16px 0px 16px",
    background: BASE.WHITE,
    padding: "16px 16px",
    borderRadius:"6px 6px 0 0",
  },
  flexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
    // marginLeft: "20px",
    // marginRight: "20px",
  },
  disableBtn: {
    display: "flex",
    justifyContent: "center",

    // width: "90px",
    padding: "0.75rem 1.5rem",
    color: "#D3DAE3",
    background: "#F7F9FB",
    border: "1px solid #D3DAE3",
    // cursor: "pointer",
    fontSize: "clamp(12px ,0.833vw,14px)",

    borderRadius: "8px",
  },
  redBtn: {
    display: "flex",
    justifyContent: "center",
    background: "#ff4848",
    // width: "90px",
    padding: "0.75rem 1.5rem",
    color: "white",
    cursor: "pointer",
    fontSize: "clamp(12px ,0.833vw,14px)",
    border: 0,
    borderRadius: "8px",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  FLEX: {
    display: "flex",
    gap: "8px",
  },
  FLEXs: {
    display: "flex",
    gap: "8px",
    width:"6.146vw"
  },
  FLEXS: {
    display: "flex",
    gap: "8px",
    paddingBottom: "12px",
    borderBottom: `1px solid ${ACTIONS.DEFAULT}`,
  },
  tableui: {
    gap: "16px",
    margin: "0px 16px",
    background: BASE.WHITE,
    boxShadow: SHADOW.xs,
    height: "calc(100% - 56px - 120px)",
    ...SCROLL_BAR,
  },
  redBtn1: {
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    // lineHeight: "19px",
    display: "flex",
    background: "#ff4848",
    padding: "0.7rem 1rem",
    color: "white",
    cursor: "pointer",
    border: 0,
    borderRadius: "8px",
    "&:hover": {
      background: "#0F2F51",
    },
  },
  normalBtn: {
    backgroundColor: "white",
    border: "1px solid #d3dae3",
    borderRadius: "6px",
    fontWeight: "400",
    padding: "0.75rem 1rem",
    fontSize: "12px",
    display: "flex",
    marginRight: "10px",
    lineHeight: "1.5",
    color: "#626b7e",
    cursor: "pointer",
    "&:hover": {
      background: "#d3dae3",
    },
  },
  greyBtn: {
    fontWeight: "400",
    fontSize: "clamp(12px ,0.833vw,14px)",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "17px",
    background: "#626B7E",
    padding: "0.75rem 1rem",
    color: "white",
    cursor: "pointer",
    border: 0,
    borderRadius: "6px",
    "&:hover": {
      background: "#FF4848",
    },
  },
  greyBtn1: {
    fontWeight: "400",
    fontSize: "clamp(12px ,0.833vw,14px)",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "17px",
    background: "#D3DAE3",
    padding: "0.75rem 1.5rem",
    color: "#626B7E",
    cursor: "pointer",
    border: 0,
    borderRadius: "6px",
    // "&:hover": {
    //   background: "#FF4848",
    // },
    marginRight: "10px",
  },

  actionBtn: {
    justifyContent: "space-between",
    // width: "10vw",
    alignItems: "center",
    height: "40px",
    display: "flex",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "17px",
    margin: "0px 10px",
    width: "9.5vw",
    background: "#FFFFFF",
    padding: "0.5rem",
    cursor: "pointer",
    border: "1px solid #D3DAE3",
    borderRadius: "6px",
    color: "#626B7E",
    "@media (max-width:1700px)": {
      height: "35px",
      padding: "0.5rem",
    },
    "&:hover": {
      background: "#D3DAE3",
    },
  },
  SearchIcon: {
    color: "#626B7E",
    fontSize: "medium",
    marginLeft: "12px",
  },
  searchIcon: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "300px",
    backgroundColor: "#fff",
    border: "1px solid #D3DAE3",
    borderRadius: "5px",
    "@media (max-width:1700px)": {
      height: "35px",
      width: "250px",
    },
  },
  modalSelectALL: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "none",
    width: "30vw",
    // maxHeight: "88vh",
    borderRadius: "12px",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "none",
    width: "42vw",
    // maxHeight: "88vh",
    borderRadius: "12px",
  },
  modal1: {
    position: "fixed",
    top: "30%",
    "@media (max-width:1700px)": {
      top: "31%",
    },
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "40vw",
    maxHeight: "85vh",
    borderRadius: "12px",
    ...TABLE_SCROLL_BAR,
  },

  modal1Task: {
    position: "fixed",
    top: "50%",
    "@media (max-width:1700px)": {
      top: "50%",
    },
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "40vw",
    maxHeight: "85vh",
    borderRadius: "12px",
    ...TABLE_SCROLL_BAR,
  },

  containModalHead: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1.5rem 1.3rem 1.5rem 1.3rem",
    background: "#D3DAE3",
    borderRadius: "12px 12px 0px 0px",
  },
  containModalBottom: {
    height: "calc(100vh - 15rem)",
    ...TABLE_SCROLL_BAR,
  },
  containModalHead1: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1.5rem 1.3rem 1.5rem 1.3rem",
    background: "#1B2431",

    "@media (max-width:1700px)": {
      padding: "1rem 1rem 1rem 1rem",
    },
  },
  createListTitle: {
    color: "#0F2F51",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "clamp(16px ,1.042vw,18px)",
    lineHeight: "24px",
  },
  createListTitle1: {
    display: "flex",
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "clamp(16px ,1.042vw,18px)",
    lineHeight: "24px",
  },
  taskType: {
    display: "flex",
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(14px ,0.833vw,16px)",
    lineHeight: "19px",
    textAlign: "center",
  },
  Scroll_preview: {
    // height: "35vh",
    overflow: "auto",
    ...TABLE_SCROLL_BAR,
    "@media (max-width: 1470px)": {
      // height: "53vh",
      overflow: "auto",
      ...TABLE_SCROLL_BAR,
    },
  },
  indexBox: {
    animationDelay: "5s",
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    // padding: "8px 10px 8px 12px",
  },
  companyDetails: {
    display: "flex",

    padding: "16px 0px",
  },
  companyDetailss: {
    flexDirection: "column",
    gap: "16px",
    display: "flex",
    fontWeight: "500",
    padding: "16px 0px",
  },
  companyDetailsTop: {
    opacity: "0.5",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "clamp(12px, 0.833vw, 14px)",
    lineHeight: "17px",
    margin: "0px 0px 4px 0px",
  },
  companyDetailsBottom: {
    paddingTop: "0.5rem",

    textTransform: "none",

    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },

  companyDetailsBox: {
    //   margin: "15px 7vw 20px 0px",
    width: "50%",
    
  },
  companyDetailsBoxGap: {
    width: "50%",
    display:"flex",
    gap:"4px"
   
  },
  companyDetailsBox2: {
    //   margin: "15px 7vw 20px 0px",
    width: "50%",
  },
  PRIORITY: {
    gap:"4px",
    padding: "1rem 0rem",
    display: "flex",
    borderTop: `1px solid ${ACTIONS.DEFAULT}`,
    borderBottom: `1px solid ${ACTIONS.DEFAULT}`,
  },
  PRIORITYWOBOrder: {
    gap:"4px",
    padding: "1rem 0rem",
    display: "flex",
    // borderTop: `1px solid ${ACTIONS.DEFAULT}`,
    // borderBottom: `1px solid ${ACTIONS.DEFAULT}`,
  },
  companyDetailsBoxLast: {
    //   margin: "15px 0vw 20px 0px",
    width: "50%",
    "@media (max-width:1700px)": {
      margin: "0px 0vw 0px 0px",
    },
  },
  halfwidth: {
    width: "49%",
    display: "flex",
    gap: "4px",
    flexDirection: "column",
  },
  paperMenu2: {
    width: "20.469vw !important",
    marginTop: "4px",
  },
  FLEXED: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "16px",
  },
  warningRenameBox: {
    paddingTop: "16px",
    display: "flex",
    gap: "4px",
    flexDirection: "column",
    // padding: "1rem 1.5rem 0rem",
  },
  commonWidth: {
    width: "21.615vw",
  },
  warningRenameBoxes: {
    paddingTop: "16px",
    display: "flex",
    gap: "4px",
    flexDirection: "column",
  },
  WIDTH: {
    width: "96px",
  },
  WidthContent:{ width:"10vw"},
  WidthContent2:{
    width:"10.292vw"
  },
  width: {
    width: "100%",
  },
  widthAssignee:{
    width: "100%",
gap:"4px",
display:"flex",
flexDirection:"column"
  },
  paperMenu: {
    width: "96px",
    ...SCROLL_BAR,
    marginTop: "3px",
    height: "13vh",
  },
  tooltipBox: {
    padding: "1rem 1rem 0rem 1rem",
  },
  PADDINGSIX: {
    paddingTop: "16px",
  },
  gapped:{
    gap: "4px",
    flexDirection:"column",
    display: "flex",
  },
  tooltipBox1: {
    padding: "16px 20px",
    gap: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  warningTitleCurrent: {
    color: "#000000",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "clamp(14px ,0.833vw,16px)",
    lineHeight: "17px",
    padding: "0.5rem 0 0rem 0rem",
  },
  HEIGHT: {
    height: "100vh",
  },
  warningTitleDue: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "clamp(12px ,0.833vw,14px)",
    lineHeight: "17px",
    paddingTop: "16px",
    paddingBottom: "4px",
  },
  warningTitle: {
    color: "#626B7E",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(12px ,0.833vw,14px)",
    lineHeight: "17px",
    padding: "0.5rem 0 0.5rem 0rem",
  },
  warningTitleTask: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "clamp(12px ,0.833vw,14px)",
    lineHeight: "17px",
    //padding: "1.3rem 0 0.5rem 0rem",
    marginBottom: "4px",
  },
  Pad: {
    padding: "2px 13px 0px 0px",
  },
  RadioButtonUncheckedIcon: {
    fontSize: "40px",
    color: "#D3DAE3",
  },
  CheckCircleIcon: {
    fontSize: "40px",
    color: "#80F2BA",
  },
  Flex: {
    display: "flex",
  },
  warningTitle1: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "clamp(12px ,0.833vw,14px)",
    lineHeight: "17px",
    //padding: "1.3rem 0 0.6rem 0rem",
    width: "clamp(275px,20vw,400px)",
    // margin: "0.5rem 0",
    paddingTop: "16px",
    paddingBottom: "4px",
  },
  containInput: {
    display: "flex",
    padding: "0.2rem 0rem 0rem",
  },
  DatePicker: {
    " & .Mui-selected .MuiButtonBase-root-MuiPickersDay-root .Mui-selected": {
      background: "#ff4848!important",
    },

    "& .Mui-selected": {
      background: "#ff4848!important",

      color: "#fff!important",
      border: "0!important",
      flex: "none",
    },
    "& .MuiButtonBase-root:not(.Mui-selected)": {
      border: "0!important",
    },
    "& .MuiPickersDay-dayWithMargin": {
      fontFamily: "'Inter', sans-serif!important",
      color: "#626B7E",
    },
    "& .css-epd502,.MuiCalendarPicker-root": {
      width: "auto",
      margin: "0",
    },
    " & .MuiPickersCalendarHeader-switchViewButton": {
      display: "none",
    },

    // "@media (max-width: 1470px)": {
    //   height: "40vh",
    //   overflowY: "auto",
    //   ...TABLE_SCROLL_BAR,
    //   "&::-webkit-scrollbar": {
    //     width: "0.5px",
    //     height: "0.5px",
    //   },
    // },
  },

  dateInput: {
    "& .Mui-selected": {
      background: "#ff4848 !important",
    },
    paddingLeft: "15px",
    paddingRight: "15px",
    // paddingTop:"4px",
    borderRadius: "4px",
    width: "49%",
    border: "1px solid #D3DAE3",
    display: "flex",
    height: "45px",
    alignItems: "center",
    // padding: "0rem 0rem 0.5rem",
  },
  FullWidth: {
    width: "100%",
  },
  timeInput: {
    "& .Mui-selected": {
      background: "#ff4848 !important",
    },
    borderRadius: "4px",
    // width: "49%",
    border: "1px solid #D3DAE3",
    paddingLeft: "15px",
    paddingRight: "15px",

    // marginLeft: "10px",
    display: "flex",
    height: "35px",
    alignItems: "center",
  },

  containBtns: {
    display: "flex",
    columnGap: 8,
    justifyContent: "flex-end",
    padding: "1rem 1rem 1rem 1rem",
    borderTop: `1px solid ${ACTIONS.DEFAULT}`,
  },
  containBtnn: {
    columnGap: 8,
    padding: "1rem 1.5rem 1rem 1rem",
    display: "flex",
    justifyContent: "flex-end",
    borderTop: `1px solid ${ACTIONS.DEFAULT}`,
  },
  containBtns1: {
    display: "flex",
    columnGap: 8,
    justifyContent: "flex-end",
  },
  containBtns2: {
    display: "flex",
    justifyContent: "flex-start",
    columnGap: 8,
  },
  betweenBtne: {
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    // padding: "1rem 1.5rem 1rem 1.5rem",
    // borderTop: `1px solid ${ACTIONS.DEFAULT}`,
    // background: "#0F2F51",
    "@media (max-width:1700px)": {
      // padding: "1rem 1rem 1rem 1rem",
    },
  },
  betweenBtns: {
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    // padding: "1rem 1.5rem 1rem 1.5rem",
    borderTop: `1px solid ${ACTIONS.DEFAULT}`,
    // background: "#0F2F51",
    "@media (max-width:1700px)": {
      // padding: "1rem 1rem 1rem 1rem",
    },
  },
  betweenBtnss: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 1.5rem 1rem 1.5rem",
    background: "white",
    borderTop: `1px solid ${ACTIONS.DEFAULT}`,
    marginTop: "1rem",

    "@media (max-width:1700px)": {
      padding: "1rem 1rem 1rem 1rem",
    },
  },
  betweenBtnend: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem 1.5rem 1rem 1.5rem",
    background: "white",
    borderTop: `1px solid ${ACTIONS.DEFAULT}`,
    marginTop: "1rem",

    "@media (max-width:1700px)": {
      padding: "1rem 1rem 1rem 1rem",
    },
  },
  icon: {
    color: "#626B7E",
    fontSize: "25px",
    cursor: "pointer",
  },
  icon1: {
    color: "#626B7E",
    fontSize: "22px",
    cursor: "pointer",
  },
  textBox1: {
    width: "100%",
    border: "1px solid #D3DAE3",
    // "@media (max-width: 1470px)": {
    //   width: "20vw",
    // },
    "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      background: "transparent !important",
    },
  },
  dropdownElement: {
    maxHeight: "100% !important",
    maxWidth: "100% !important",
    ...TABLE_SCROLL_BAR,
  },
  selectIcon: {
    marginRight: "10px",
  },
  dropdownLabel: {
    padding: "13px 18px 10px 14px !important",
    fontSize: "clamp(12px, 0.729vw, 30px)",
  },
  closeBtn: {
    background: "white",
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "clamp(12px ,0.833vw,14px)",
    border: "1px solid #D3DAE3",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    cursor: "pointer",
    height: "40px",
    marginRight: "10px",
    alignSelf: "center",
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },
  },

  coseBtn: {
    cursor: "auto",
    background: "#FF4848",
    color: "#FFFFFF",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "clamp(14px ,0.833vw,14px)",
    border: "1px solid #FF4848",
  },
  closeBtn1: {
    display: "flex",
    // background: "#0F2F51",
    color: "#D3DAE3",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    // lineHeight: "19px",
    border: "1px solid #D3DAE3",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    cursor: "pointer",
    marginRight: "10px",
    alignSelf: "center",
    "@media (max-width:1700px)": {
      padding: "0.5rem 1rem",
    },
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },
  },

  closeBtnTask: {
    display: "flex",
    background: "#FFFFFF",
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    // lineHeight: "19px",
    border: "1px solid #D3DAE3",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    cursor: "pointer",
    marginLeft: "10px",
    alignSelf: "center",
    "@media (max-width:1700px)": {
      padding: "0.5rem 1rem",
    },
  },
  closeBtn12: {
    display: "flex",
    color: "#ffffff",
    background: "#626B7E",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    // lineHeight: "19px",
    border: "1px solid #D3DAE3",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    cursor: "pointer",
    marginRight: "10px",
    alignSelf: "center",
    "@media (max-width:1700px)": {
      padding: "0.5rem 1rem",
    },
  },
  closeBtn1white: {
    cursor: "pointer",
    display: "flex",
    background: "white",
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "clamp(12px,0.833vw,14px)",
    fontFamily: "Inter",
    fontStyle: "normal",
    // lineHeight: "19px",
    border: "1px solid #D3DAE3",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    marginRight: "10px",
    alignSelf: "center",
    "@media (max-width:1700px)": {
      padding: "0.5rem 1rem",
    },
    "&:hover": {
      color: "#FF4848",
      border: "1px solid #FF4848",
    },
  },
  closeBtnRed: {
    cursor: "pointer",
    display: "flex",
    background: "#ff4848",
    color: "white",
    fontWeight: "500",
    fontSize: "clamp(12px,0.833vw,14px)",
    fontFamily: "Inter",
    fontStyle: "normal",
    // lineHeight: "19px",
    border: "1px solid #ff4848",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    marginRight: "10px",
    alignSelf: "center",
    "@media (max-width:1700px)": {
      padding: "0.5rem 1rem",
    },
    "&:hover": {
      color: "white",
      background: "#0F2F51",
      border: "1px solid #0F2F51",
    },
  },
  closeBtnGreen: {
    display: "flex",
    background: "#13CA7A",
    color: "white",
    fontWeight: "500",
    fontSize: "clamp(12px,0.833vw,14px)",
    fontFamily: "Inter",
    fontStyle: "normal",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "19px",
    border: "1px solid #13CA7A",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    cursor: "pointer",
    // marginRight: "10px",
    alignSelf: "center",
    "@media (max-width:1700px)": {
      padding: "0.5rem 1rem",
    },
    // "&:hover": {
    //   color: "white",
    //   border: "1px solid #0F2F51",
    //   background: "#0F2F51",
    // },
  },
  selectionBox: {
    color: "#626B7E",
    // border: "1px solid #D3DAE3",
    fontSize: "14px",
    height: "45px",

    padding: "0.5rem 0rem 0.5rem",
    borderRadius: "6px",
    // "& .MuiSelect-select": {
    //   fontSize: "14px",
    //   width: "100%",
    //   paddingLeft: "10px",
    // },
    minWidth: "100% !important",
    border: "1px solid #D3DAE3",
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "&  .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      background: "#F7F9FB !important",
      color: "#626B7E !important",
    },
    "& .MuiSelect-select": {
      width: "100%",
      paddingLeft: "10px",
    },

    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  selectionBoxEdit: {
    color: "#626B7E",
    border: "1px solid #D3DAE3",
    fontSize: "14px",
    height: "45px",
    background: "#F7F9FB",
    // marginTop: "10px",
    padding: "0.5rem 0rem 0.5rem",
    borderRadius: "6px",
    // "& .MuiSelect-select": {
    //   fontSize: "14px",
    //   width: "100%",
    //   paddingLeft: "10px",
    // },
    minWidth: "100% !important",
    // border: "1px solid #D3DAE3",
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "&  .MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
      background: "#F7F9FB !important",
      color: "#626B7E !important",
    },
    "& .MuiSelect-select": {
      width: "100%",
      paddingLeft: "10px",
    },

    "@media (max-width: 1470px)": {
      width: "100%",
    },
  },
  selectionBox1: {
    minWidth: "20.5vw",
    border: "1px solid #D3DAE3",
    height: "45px",
    // marginLeft: "28px",
    marginTop: "10px",
    borderRadius: "6px",
    padding: "0.5rem 0rem 0.5rem",
    "& .MuiSelect-select": {
      paddingLeft: "10px",
    },
  },
  selectionCustomBox: {
    width: "137px",
    marginLeft: "36px",
    marginTop: "5px",
    borderRadius: "4px",
  },
  selectionCustomBox2: {
    width: "137px",
    marginLeft: "15px",
    marginTop: "5px",
    borderRadius: "4px",
  },
  dropDownIcon: {
    position: "absolute",
    right: "5px",
    pointerEvents: "none",
    color: "#626B7E",
  },
  rangeWidgetTitle: {
    color: "#212833",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    margin: "0px 0px 7px 0px",
  },
  warningBox: {
    padding: "0.5rem 1.5rem 0rem 1.5rem",
  },
  warningBox1: {
    // background: "#0F2F51",
    // padding: "1rem 1.5rem 1rem 1.5rem",
    borderTop: `1px solid ${ACTIONS.DEFAULT}`,
  },
  warningBox1white: {
    background: "#FFFFFF",
    // padding: "1rem 0rem 1rem 0rem",

    borderBottom: `1px solid ${ACTIONS.DEFAULT}`,
  },
  warningBox1whites: {
    paddingBottom: "0rem",
    background: "#FFFFFF",
    padding: "1rem 0rem 1rem 0rem",
  },
  showMoreButton: {
    paddingTop: "10px",
    fontWeight: 400,
    fontSize: "clamp(12px,0.833vw,14px)",
    color: "#FF4848",
    cursor: "pointer",
  },
  headerTextDelete: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#626B7E",
    padding: "0rem 0rem 1rem 0rem",
  },
  headerText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#626B7E",
    padding: "1rem 0rem 2rem 0rem",
  },
  headerText1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#FF4848",
    padding: "1.5rem 0rem 0.5rem 0rem",

    "@media (max-width:1700px)": {
      padding: "0.5rem 0rem 0.5rem 0rem",
    },
  },
  headerText3: {
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "29px",
    color: "black",
    padding: "0.5rem 0rem 1.5rem 0rem",
    "@media (max-width:1700px)": {
      padding: "0.5rem 0rem 0.8rem 0rem",
    },
  },
  headerText2: {
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "29px",
    color: "#FFFFFF",
    padding: "0.5rem 0rem 1rem 0rem",
    "@media (max-width:1700px)": {
      padding: "0.5rem 0rem 0.8rem 0rem",
    },
  },
  editorContent: {
    marginTop:"4px",
    border: "1px solid #D3DAE3",
    borderRadius: "6px",
    // minHeight: "18rem",
  },
  container: {
    padding: "1rem",
    paddingTop: "0",
  },
  paperContainer: {
    width: "100%",
    marginBottom: 2,
    overflowX: "auto",
    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.07)",
  },
  tableContainer: {
    height: "calc(100vh - 26rem)",
    maxWidth: "92vw",
    position: "relative",
    ...TABLE_SCROLL_BAR,
  },
  tableHeadings: {
    // color: "#0F2F51",
    fontWeight: "600",
    fontSize: "14px",
    background: "white",
    alignSelf: "center",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
  },
  dataText: {
    color: "#626B7E",
    textTransform: "capitalize",
    fontWeight: "400",
    fontSize: "14px",
    alignSelf: "center",
    textOverflow: "ellipsis",
    textAlign: "center",
    whiteSpace: "nowrap",
    paddingLeft: "8px",
  },
  email: {
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "12px",
    alignSelf: "center",
    textOverflow: "ellipsis",
    paddingLeft: "5px",
  },
  dataTextName: {
    color: "#102F51",
    fontWeight: "600",
    fontSize: "14px",
    alignSelf: "center",
    paddingLeft: "5px",
    cursor: "pointer",
    letterSpacing: "0.5px",
    whiteSpace: "nowrap",
  },
  stepsTxt: {
    color: "#626B7E",
    background: "#F7F9FB",
    padding: "12px 1rem",
    borderRadius: "8px",
    whiteSpace: "nowrap",
    fontSize: "12px",
  },
  headIcons: {
    cursor: "pointer",
    paddingLeft: "5px",
    color: "#626B7E",
    alignSelf: "center",
  },
  // selectedIcons: {
  //   alignSelf: "center",
  //   margin: "5px",
  //   color: "#626B7E",
  // },
  date: {
    color: "#626B7E",
    fontSize: "12px",
    paddingLeft: "5px",
  },
  comapnyImage: {
    width: "30px",
    height: "30px",
    objectFit: "contain",
    padding: "2px",
    border: "1px solid #D1D1D1",
    borderRadius: "50%",
    marginRight: "5px",
    alignSelf: "center",
  },
  whiteBackground: {
    background: "white",
  },
  nameHeader: {
    display: "flex",
    padding: "1rem",
    boxShadow: "2px 1px 6px #0000000F",
  },
  otherHeader: {
    display: "flex",
    padding: "0 1rem",
    justifyContent: "space-between",
  },
  otherHeader1: {
    display: "flex",
    padding: "1rem",
    justifyContent: "center",
  },
  middleWare: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flex: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  circleIcon: {
    border: "1px solid #D3DAE3",
    borderRadius: "50%",
    background: "#F7F9FB",
    height: "40px",
    width: "40px",
    padding: "7px",
  },
  circleIcon1: {
    border: "1px solid #D3DAE3",
    borderRadius: "50%",
    background: "#D3DAE3",
    height: "40px",
    width: "40px",
    padding: "7px",
  },
  selectedIcons: {
    alignSelf: "center",
    margin: "5px",
    color: "#626B7E",
  },
  eachSelectedAction: {
    display: "flex",
    marginRight: "2rem",
    background: "#FFE9E9",
    padding: "3px 1rem 3px 0.6rem",
    borderRadius: "6px",
    cursor: "pointer",
  },
  selectedActionText: {
    color: "#ff4848",
    fontSize: "14px",
    alignSelf: "center",
    paddingBottom: "2px",
    cursor: "pointer",
  },
  menuBox: {
    boxShadow: "0px 14px 49px rgba(0, 0, 0, 0.14) !important",
  },
  toolTipContainer: {
    background: "white",
    boxShadow: "0px 9px 66px #00000029",
    borderRadius: "12px",
    zIndex: 1,
    // width: "25vw",
  },
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "red !important",
      border: "1px solid red",
    },
  },
  inputContainer: {
    width: "100%",
  },
  input: {
    width: "100% !important",
    //upali
    height: "36px",
    //vhange the height
    borderRadius: "4px",
    // backgroundColor:"#F7F9FB !important",
    outline: "none",
    border: "1px solid #D3DAE3 !important",

    padding: "1rem !important",
  },
  input1: {
    width: "30vw !important",
    padding: "1.5rem 1rem !important",
  },
  listBox: {
    // width: "83%",
    //upali
    position: "relative !important",
    border: "none !important",
  },
  marginTop: {
    marginTop: "1rem",
  },
  inviteBtn: {
    background: "#ff4848",
    border: "none",
    color: "white",
    width: "10vw",
    padding: "1rem 2.5rem",
    marginTop: "1rem",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
  },
  tasks: {
    background: "red",
  },
  boarding: {
    zIndex: "999999!important",
  },

  red: { color: "red" },
});
