import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  EMITTER_ERROR,
  EMITTER_INFO,
  EMITTER_SUCCESS,
  EMITTER_WARNING,
  SNACKBAR_CONFIG as config,
  tenants_api_endpoints,
} from "../utils/constants";
import CustomEventEmitter from "../utils/emitter/EventEmitter";
import DashboardLayout from "../components/Sidebar/DashboardLayout";
import { HOME_PATH, SIGNUP_PATH } from "../utils/constants/routes";
import { Loading, InvalidRouteScreen } from "../screens";
import { authRoutes } from "./authRoutes";
import { userRoutes } from "./userRoutes";
import { SNACKBAR_CLOSE_ICON } from "../utils/constants/assets";
import { SnackbarCloseIcon } from "../components/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin, verifyDomain } from "../redux/actions/authActions";
import { initialRoutes } from "./initialRoutes";
import {
  HOST_URL,
  protocol,
} from "../utils/constants/httpConstants";
import { FullScreenLoader } from "../components";
import { defaultRoutes } from "./defaultRoutes";

const Router = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const userDetails = userDetailsSelector?.payload;
  const navigate = useNavigate();
  const [checkLoginDone, setCheckLoginDone] = useState(false);
  const [checkDomainValidation, setCheckDomainValidation] = useState(true);
  const [checkValidateSubdomainDone, setCheckValidateSubdomainDone] = useState(false);
  const [isRefreshTokenCall, setIsrefreshTokenCall] = useState(false);
  const [checkToken, setCheckToken] = useState(false);

  useEffect(() => {
    // validate subdomain here.
    if (
      !checkValidateSubdomainDone
      // && !window.location.href.includes("auth/register")
    ) {
      setCheckToken(true);
      const host = window.location.host;
      let hostLength = host?.trim()?.split(".");
      if (hostLength.length > 1) {
        const subDomain = host?.trim()?.split(".")[0] + ".reachiq.ai";

        (async () => {
          try {
            await dispatch(verifyDomain(subDomain, navigate));
            setIsrefreshTokenCall(true);
          } catch (error) {
            setIsrefreshTokenCall(false);
            setCheckToken(false);
            setCheckDomainValidation(false);
            console.log("error---->", error);
          }
        })();
        setCheckValidateSubdomainDone(true);
      } else {
        window.location.href = `${protocol}${HOST_URL}${SIGNUP_PATH}`;
      }
    } else {
      setCheckDomainValidation(false);
    }

    if (window.location.pathname === "/") {
      setCheckToken(false);

    }

    // refresh token api call here.
    if (
      isRefreshTokenCall &&
      !checkLoginDone &&
      !userDetails &&
      !window.location.href.includes("auth/meetings") &&
      !(window.location.pathname === "/") &&
      !window.location.href.includes("auth/register") &&
      !window.location.href.includes("auth/google-signup") &&
      !window.location.href.includes("auth/reset_password") &&
      !window.location.href.includes("auth/unsubscribe-successful") &&
      !window.location.href.includes("auth/forgot_password") &&
      !window.location.href.includes("/cp") &&
      !window.location.href.includes("/cancel-meeting")
    ) {
      (async () => {
        await dispatch(checkLogin(navigate, tenants_api_endpoints));
        setCheckToken(false);
      })();
      setCheckLoginDone(true);
      // check conflict 2.
    } else {
      setCheckToken(false)
    }

    CustomEventEmitter.on(EMITTER_SUCCESS, (message) => {
      enqueueSnackbar(message, {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
        action: (key) => (
          <SnackbarCloseIcon
            src={SNACKBAR_CLOSE_ICON}
            onClick={() => {
              closeSnackbar(key);
            }}
          />
        ),
      });
    });
    CustomEventEmitter.on(EMITTER_ERROR, (message) => {
      enqueueSnackbar(message, {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
        action: (key) => (
          <SnackbarCloseIcon
            src={SNACKBAR_CLOSE_ICON}
            onClick={() => {
              closeSnackbar(key);
            }}
          />
        ),
      });
    });
    CustomEventEmitter.on(EMITTER_INFO, (message) => {
      enqueueSnackbar(message, {
        variant: "info",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
      });
    });
    CustomEventEmitter.on(EMITTER_WARNING, (message) => {
      enqueueSnackbar(message, {
        variant: "warning",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
      });
    });
    //for alert messages - ends
    return () => {
      CustomEventEmitter.removeEventListener("alert_success");
      CustomEventEmitter.removeEventListener("alert_error");
      CustomEventEmitter.removeEventListener("alert_info");
      CustomEventEmitter.removeEventListener("alert_warning");
    };
  }, [enqueueSnackbar, closeSnackbar, isRefreshTokenCall]);


  return checkToken || checkDomainValidation ? (
    <FullScreenLoader />
  ) : (
    <Routes>
      {/* Base Route, starting of the application */}
      <Route path={HOME_PATH} element={<Loading />} />

      {/* All Auth routes  */}
      {authRoutes.map(({ path, component: Component }, index) => (
        <Route path={path} element={<Component />} key={index} exact />
      ))}

      {/* All Authorized, user routes */}
      {userRoutes.map(({ path, component: Component }, index) => {
        return (
          <Route
            path={path}
            element={
              <DashboardLayout>
                <Component />
              </DashboardLayout>
            }
            key={index}
            exact
          />
        );
      })}

      {/* Initial Routes */}
      {initialRoutes.map(({ path, component: Component }, index) => {
        return <Route path={path} element={<Component />} key={index} exact />;
      })}

      {/* Default Routes */}
      {defaultRoutes.map(({ path, component: Component }, index) => {
        return <Route path={path} element={<Component />} key={index} exact />;
      })}

      {/* default error route */}
      <Route path="*" element={<InvalidRouteScreen />} />
    </Routes>
  );
};

export default Router;