import { makeStyles } from "@mui/styles";
import { INVISIBLE_SCROLL_BAR } from "../../utils/constants/styles";

export const styles = makeStyles({
  modal1: {
    position: "fixed",
    top: "10%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    // backgroundColor: "white",
    border: "none",
    // width: "75vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "75vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },
  containCrossIcon: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    alignItems: "center",
    cursor: "pointer",
    height: "2rem",
  },
  icon: {
    color: "#7c7d7e",
    fontSize: "20px",
  },
  title: {
    color: "#102f51",
    fontSize: "clamp(1.1rem, 1.1vw, 3rem)",
    fontWeight: "400",
    alignSelf: "center",
  },
  container: { position: "relative", display: "flex" },
  LinearLoaderContainer: { width: "100%", alignSelf: "center" },
  stepSkeletonBox: {
    margin: "0rem 20%",
    overflow: "auto",
    height: "calc(100% - 11rem)",
    ...INVISIBLE_SCROLL_BAR,
  },
  marginTop2Rem: { marginTop: "2rem" },
  ProgressIndicator: {
    display: "grid",
    height: "100%",
    alignItems: "center",
    padding: "1.5rem ",
  },
});
