import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { getRequest, postRequest } from "../../../../Apis";
import { useNavigate } from "react-router-dom";
import { authEndpoints } from "../../../../utils/constants/httpConstants";
import Image from "../../../../components/Image/Image";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  ESP_IMAGES_BY_INDEX,
} from "../../../../utils/constants";
import {
  CONNECTED_TICK,
  NO_RECORD_FOUND_IMAGE,
} from "../../../../utils/constants/assets";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import {
  BRAND,
  GREY,
  PRIMARY,
  SUCCESS,
} from "../../../../utils/constants/colors";
import {
  ICON_LABELS,
  fontSize,
  fontWeight,
} from "../../../../utils/constants/UI";
import { STRINGS } from "../../strings";
import IconComponent from "../../../../components/Icon/IconComponent";
import SkeletonLoader from "../../../../components/Loaders/SkeletonLoader";
import CustomStyledMenu from "../../../../components/Menu/CustomStyledMenu";
import DisconnectModal from "./DisconnectModal";
import EditInboxModal from "./EditInboxModal";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import { getErrorMessage } from "../../../../utils/helpers";
import CustomTooltip from "../../../../components/Tooltip/CustomTooltip";
import { Button } from "../../../../components/Buttons";
import SignatureModal from "./SignatureModal";
const ConnectedEspList = () => {
  const classes = styles();
  const [esp, setEsp] = useState([]);
  const [gmailList, setGmailList] = useState([]);
  const [optionAnchorEl, setOptionAnchorEl] = useState(null);
  const [optionAnchorEl1, setOptionAnchorEl1] = useState(null);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [dataRow, setDataRow] = useState({});
  const [reload, setReload] = useState(1);
  const [hideData, setHideData] = useState(false);
  const [hideEsp, setHideEsp] = useState([]);
  const [hideEspData, setHideEspData] = useState(false);
  const [outlookList, setOutlookList] = useState([]);
  const [disconnect, setDisconnect] = useState(false);
  const [openSignModal,setOpenSignModal] = useState(false)
  const [edit, setEdit] = useState(false);
  const optionOpen = Boolean(optionAnchorEl);
  const optionOpen1 = Boolean(optionAnchorEl1);
  const navigate = useNavigate();
  const skeletonList = [1, 2, 3, 4, 5, 6];
  useEffect(() => {
    showlist();
  }, [reload]);

  const showlist = async () => {
    try {
      setIsSkeleton(true);
      const response = await getRequest(
        navigate,
        authEndpoints?.getApiKeyDetails
      );
      setEsp(response?.data?.data?.other_api_details);
      setGmailList(response?.data?.data?.inboxes?.gmail);
      setOutlookList(response?.data?.data?.inboxes?.outlook);
    } catch (e) {
    } finally {
      setIsSkeleton(false);
    }
  };

  const openSignature = (row) =>{
    setOpenSignModal(true)
    setDataRow(row);
  }

  const openOption = (event, row) => {
    setOptionAnchorEl(event?.currentTarget);
    setDataRow(row);
  };
  const openOption1 = (event, row) => {
    setOptionAnchorEl1(event?.currentTarget);
    setDataRow(row);
  };
  const handleClose = () => {
    setOptionAnchorEl(null);
    setOptionAnchorEl1(null);
  };

  const openDisconnect = () => {
    handleClose();
    setDisconnect(!disconnect);
  };
  const openDisconnect1 = (data) => {
    setDataRow(data)
    handleClose();
    setDisconnect(!disconnect);
  };

  const openEdit = () => {
    handleClose();
    setEdit(!edit);
  };

  const makePrim = async () => {
    try {
      const response = await postRequest(navigate, authEndpoints.makePrimary, {
        email: dataRow?.email,
        // user_email:userDetailsSelector?.payload?.email
      });
      const resp = response?.data?.message;
      EventEmitter.emit(EMITTER_SUCCESS, resp);
      setReload(reload + 1);
      handleClose();
    } catch (err) {
      EventEmitter.emit(
        EMITTER_ERROR,
        getErrorMessage(err?.data?.data?.errors || {}, err?.data?.message)
      );
    } finally {
    }
  };

  const toggleHandle = (data) => {
    if (hideEsp.includes(data)) {
      setHideEsp(hideEsp.filter((id) => id !== data));
    } else {
      setHideEsp([...hideEsp, data]);
    }
  };

  const optionContent = (
    <div className={classes.optionSty}>
      <div className={classes.optionCont}>
        <IconComponent
          iconLabel={ICON_LABELS.EDIT}
          color={GREY.TERTIARY}
          fontSize={fontSize.SM}
          onClick={openEdit}
        />
        <TypographyInline
          size={"sm"}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.EDIT}
          onClick={openEdit}
        />
      </div>
      {!dataRow?.is_primary && (
        <div className={classes.optionCont} onClick={makePrim}>
          <IconComponent
            iconLabel={ICON_LABELS.BOOKMARK}
            color={GREY.TERTIARY}
            fontSize={fontSize.SM}
          />
          <TypographyInline
            size={"sm"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={STRINGS.MAKE_IT_PRIMARY}
          />
        </div>
      )}
      <div className={classes.optionCont}>
        <IconComponent
          iconLabel={ICON_LABELS.LINKOFF}
          color={PRIMARY.W_500}
          fontSize={fontSize.SM}
          onClick={openDisconnect}
        />
        <TypographyInline
          size={"sm"}
          color={PRIMARY.W_500}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.DISCONNECT}
          onClick={openDisconnect}
        />
      </div>
    </div>
  );

  const optionEspContent = (
    <div className={classes.optionSty}>
      <div className={classes.optionCont}>
        <IconComponent
          iconLabel={ICON_LABELS.EDIT}
          color={GREY.TERTIARY}
          fontSize={fontSize.SM}
          onClick={openEdit}
        />
        <TypographyInline
          size={"sm"}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.EDIT}
          onClick={openEdit}
        />
      </div>
      <div className={classes.optionCont}>
        <IconComponent
          iconLabel={ICON_LABELS.LINKOFF}
          color={PRIMARY.W_500}
          fontSize={fontSize.SM}
          onClick={openDisconnect}
        />
        <TypographyInline
          size={"sm"}
          color={PRIMARY.W_500}
          fontWeight={fontWeight.REGULAR}
          label={STRINGS.DISCONNECT}
          onClick={openDisconnect}
        />
      </div>
    </div>
  );
  return (
    <>
      {isSkeleton ? (
        <div className={classes.connectedContainerEsp}>
          {skeletonList?.map((item) => {
            return (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"100%"}
                skeletonHeight={150}
              />
            );
          })}
        </div>
      ) : !gmailList?.length > 0 &&
        !esp?.length > 0 &&
        !outlookList?.length > 0 ? (
        <div className={classes.noRecordSty}>
          <div>
            <Image className={classes.noImageSty} src={NO_RECORD_FOUND_IMAGE} />
          </div>
          <TypographyInline
            color={GREY.PRIMARY}
            fontWeight={fontWeight.SEMIBOLD}
            label={STRINGS.NO_RECORD_FOUND}
            size={"xl"}
          />
        </div>
      ) : (
        <div className={classes.connectedContainerEsp}>
          {gmailList?.length > 0 && (
            <div
              className={
                !hideData ? classes.bodyConnected1 : classes.bodyConnected
              }
            >
              <div
                className={
                  !hideData
                    ? classes.displaySpaceBetwn
                    : classes.displaySpaceBetwn1
                }
                onClick={() => setHideData(!hideData)}
              >
                <div className={classes.dFlexGap}>
                  <div>
                    <Image
                      src={ESP_IMAGES_BY_INDEX?.[2]}
                      alt={"Gmail"}
                      className={classes.imgHt}
                    />
                  </div>
                  <div className={classes.stschp}>
                    <TypographyInline
                      size={"xs"}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.SEMIBOLD}
                      label={gmailList?.length}
                    />
                  </div>
                </div>
                <div className={classes.dFlexGap1}>
                  <div className={classes.displayInsideData}>
                    <Image src={CONNECTED_TICK} alt={"Tick"} />
                    <TypographyInline
                      size={"sm"}
                      color={SUCCESS.W_500}
                      fontWeight={fontWeight.REGULAR}
                      label={STRINGS.CONNECTED}
                    />
                  </div>
                  <div className={classes.hoverData}>
                    <IconComponent
                      iconLabel={ICON_LABELS.EXPAND_MORE}
                      color={GREY.TERTIARY}
                      fontSize={fontSize.LG}
                    />
                  </div>
                </div>
              </div>
              {hideData && (
                <div className={classes.backgrdContent}>
                  {gmailList?.map((item) => {
                    return (
                      <div className={classes.borSet1}>
                        <div className={classes.padding16}>
                          <div className={classes.displaySpaceBetwnData}>
                            <div className={classes.dFlexGap}>
                              <TypographyInline
                                size={"sm"}
                                color={BRAND.SECONDARY}
                                fontWeight={fontWeight.SEMIBOLD}
                                label={item?.email}
                              />
                              {item?.is_primary && (
                                <div className={classes.isPrimaryStyle}>
                                  <TypographyInline
                                    size={"xs"}
                                    color={BRAND.PRIMARY}
                                    fontWeight={fontWeight.MEDIUM}
                                    label={STRINGS.PRIMARY}
                                  />
                                </div>
                              )}
                            </div>
                            <div className={classes.dFlexGap}>
                              <div className={classes.hoverData}>
                                <IconComponent
                                  iconLabel={ICON_LABELS.MORE_VERT}
                                  color={GREY.TERTIARY}
                                  fontSize={fontSize.LG}
                                  onClick={(e) => openOption(e, item)}
                                /> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={classes.paddingStyl}>
                          <div className={classes.disSpace}>
                          <div className={classes.disSpace1}>
                            <div>
                              <TypographyInline
                                size={"xs"}
                                color={GREY.PRIMARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={`${STRINGS.EMIALSENDINGLIMIT}:`}
                              />
                            </div>
                            <div className={classes.dFlexGap}>
                              <div className={classes.flxDt}>
                                <TypographyInline
                                  size={"xs"}
                                  color={GREY.TERTIARY}
                                  fontWeight={fontWeight.MEDIUM}
                                  label={`${STRINGS.HOURLY}:`}
                                />
                                <TypographyInline
                                  size={"xs"}
                                  color={GREY.PRIMARY}
                                  fontWeight={fontWeight.SEMIBOLD}
                                  label={
                                    item?.hourly_limit
                                      ? item?.hourly_limit
                                      : "0"
                                  }
                                />
                              </div>
                              <div className={classes.flxDt1}>
                                <TypographyInline
                                  size={"xs"}
                                  color={GREY.TERTIARY}
                                  fontWeight={fontWeight.MEDIUM}
                                  label={`${STRINGS.DAILY}:`}
                                />
                                <TypographyInline
                                  size={"xs"}
                                  color={GREY.PRIMARY}
                                  fontWeight={fontWeight.SEMIBOLD}
                                  label={
                                    item?.daily_limit ? item?.daily_limit : "0"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                          <Button label={STRINGS.EMAIL_SIGNATURE} variant={"ghost"} size={"xs28"} onClick={() =>openSignature(item)}/>
                          </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {outlookList?.length > 0 && (
            <div
              className={
                !hideEspData ? classes.bodyConnected1 : classes.bodyConnected
              }
            >
              <div
                className={
                  !hideEspData
                    ? classes.displaySpaceBetwn
                    : classes.displaySpaceBetwn1
                }
                onClick={() => setHideEspData(!hideEspData)}
              >
                <div className={classes.dFlexGap}>
                  <div>
                    <Image
                      src={ESP_IMAGES_BY_INDEX?.[5]}
                      alt={"Outlook"}
                      className={classes.imgHt}
                    />
                  </div>
                  <div className={classes.stschp}>
                    <TypographyInline
                      size={"xs"}
                      color={GREY.SECONDARY}
                      fontWeight={fontWeight.SEMIBOLD}
                      label={outlookList?.length}
                    />
                  </div>
                </div>
                <div className={classes.dFlexGap1}>
                  <div className={classes.displayInsideData}>
                    <Image src={CONNECTED_TICK} alt={"Tick"} />
                    <TypographyInline
                      size={"sm"}
                      color={SUCCESS.W_500}
                      fontWeight={fontWeight.REGULAR}
                      label={STRINGS.CONNECTED}
                    />
                  </div>
                  <div className={classes.hoverData}>
                    <IconComponent
                      iconLabel={ICON_LABELS.EXPAND_MORE}
                      color={GREY.TERTIARY}
                      fontSize={fontSize.LG}
                    />
                  </div>
                </div>
              </div>
              {hideEspData && (
                <div className={outlookList?.length !== 1 && classes.dGrid}>
                  {outlookList?.map((item) => {
                    return (
                      <div className={classes.borSet}>
                        <div className={classes.padding16}>
                          <div className={classes.displaySpaceBetwnData}>
                            <div className={classes.dFlexGap}>
                              <TypographyInline
                                size={"sm"}
                                color={BRAND.SECONDARY}
                                fontWeight={fontWeight.SEMIBOLD}
                                label={item?.email}
                              />
                              {item?.is_primary && (
                                <div className={classes.isPrimaryStyle}>
                                  <TypographyInline
                                    size={"xs"}
                                    color={BRAND.PRIMARY}
                                    fontWeight={fontWeight.MEDIUM}
                                    label={STRINGS.PRIMARY}
                                  />
                                </div>
                              )}
                            </div>
                            <div className={classes.dFlexGap}>
                              <div className={classes.hoverData}>
                              
                                <IconComponent
                                  iconLabel={ICON_LABELS.MORE_VERT}
                                  color={GREY.TERTIARY}
                                  fontSize={fontSize.LG}
                                  onClick={(e) => openOption(e, item)}
                                /> 
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className={classes.paddingStyl}>
                          <div className={classes.disSpace}>
                            <div>
                              <TypographyInline
                                size={"xs"}
                                color={GREY.TERTIARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={STRINGS.EMIALSENDINGLIMIT}
                              />
                            </div>
                            <div className={classes.dFlexGap}>
                              <div className={classes.flxDt}>
                                <TypographyInline
                                  size={"xs"}
                                  color={GREY.TERTIARY}
                                  fontWeight={fontWeight.MEDIUM}
                                  label={`${STRINGS.HOURLY}:`}
                                />
                                <TypographyInline
                                  size={"xs"}
                                  color={GREY.PRIMARY}
                                  fontWeight={fontWeight.SEMIBOLD}
                                  label={
                                    item?.hourly_limit
                                      ? item?.hourly_limit
                                      : "0"
                                  }
                                />
                              </div>
                              <div className={classes.flxDt1}>
                                <TypographyInline
                                  size={"xs"}
                                  color={GREY.TERTIARY}
                                  fontWeight={fontWeight.MEDIUM}
                                  label={`${STRINGS.DAILY}:`}
                                />
                                <TypographyInline
                                  size={"xs"}
                                  color={GREY.PRIMARY}
                                  fontWeight={fontWeight.SEMIBOLD}
                                  label={
                                    item?.daily_limit
                                      ? item?.daily_limit
                                      : "0"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {esp?.length > 0 &&
            esp?.map((item, index) => {
              return (
                (item.esp != 2 || item.esp != 5) &&
                <div
                  key={index}
                  className={
                    !hideEsp.includes(item?.esp)
                      ? classes.bodyConnected1
                      : classes.bodyConnected
                  }
                >
                  <div
                    className={
                      !hideEsp.includes(item?.esp)
                        ? classes.displaySpaceBetwn
                        : classes.displaySpaceBetwn1
                    }
                    onClick={() => toggleHandle(item?.esp)}
                  >
                    <div>
                      <Image
                        src={ESP_IMAGES_BY_INDEX?.[parseInt(item?.esp)]}
                        alt={"7"}
                        className={classes.imgHt}
                      />
                    </div>
                    <div className={classes.dFlexGap1}>
                      <div className={classes.displayInsideData}>
                        <Image src={CONNECTED_TICK} alt={"Tick"} />
                        <TypographyInline
                          size={"sm"}
                          color={SUCCESS.W_500}
                          fontWeight={fontWeight.REGULAR}
                          label={STRINGS.CONNECTED}
                        />
                      </div>
                      <div className={classes.hoverData}>
                        <IconComponent
                          iconLabel={ICON_LABELS.EXPAND_MORE}
                          color={GREY.TERTIARY}
                          fontSize={fontSize.LG}
                          onClick={() => toggleHandle(item?.esp)}
                        />
                      </div>
                    </div>
                  </div>
                  {hideEsp.includes(item?.esp) && (
                    <>
                      <div className={classes.padding16}>
                        <div className={classes.displaySpaceBetwnData}>
                          <div>
                            <TypographyInline
                              size={"sm"}
                              color={BRAND.SECONDARY}
                              fontWeight={fontWeight.SEMIBOLD}
                              label={item?.key}
                            />
                            <TypographyInline
                              size={"sm"}
                              color={GREY.SECONDARY}
                              fontWeight={fontWeight.MEDIUM}
                              label={item?.email ? item?.email : ""}
                            />
                          </div>
                          <div className={classes.dFlexGap}>
                            <div className={classes.hoverData}>
                              <IconComponent
                                iconLabel={ICON_LABELS.MORE_VERT}
                                color={GREY.TERTIARY}
                                fontSize={fontSize.LG}
                                onClick={(e) => openOption1(e, item)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.paddingStyl}>
                        <div className={classes.disSpace}>
                          <div>
                            <TypographyInline
                              size={"xs"}
                              color={GREY.TERTIARY}
                              fontWeight={fontWeight.MEDIUM}
                              label={STRINGS.EMIALSENDINGLIMIT}
                            />
                          </div>
                          <div className={classes.dFlexGap}>
                            <div className={classes.flxDt}>
                              <TypographyInline
                                size={"xs"}
                                color={GREY.TERTIARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={`${STRINGS.HOURLY}:`}
                              />
                              <TypographyInline
                                size={"xs"}
                                color={GREY.PRIMARY}
                                fontWeight={fontWeight.SEMIBOLD}
                                label={
                                  item?.hourly_limit ? item?.hourly_limit : "0"
                                }
                              />
                            </div>
                            <div className={classes.flxDt1}>
                              <TypographyInline
                                size={"xs"}
                                color={GREY.TERTIARY}
                                fontWeight={fontWeight.MEDIUM}
                                label={`${STRINGS.DAILY}:`}
                              />
                              <TypographyInline
                                size={"xs"}
                                color={GREY.PRIMARY}
                                fontWeight={fontWeight.SEMIBOLD}
                                label={
                                  item?.daily_limit ? item?.daily_limit : "0"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      )}
      <CustomStyledMenu
        width="11vw"
        anchorEl={optionAnchorEl}
        open={optionOpen}
        onClose={handleClose}
      >
        {optionContent}
      </CustomStyledMenu>
      <CustomStyledMenu
        width="11vw"
        anchorEl={optionAnchorEl1}
        open={optionOpen1}
        onClose={handleClose}
      >
        {optionEspContent}
      </CustomStyledMenu>
      {disconnect && (
        <DisconnectModal
          open={disconnect}
          setOpen={setDisconnect}
          reload={reload}
          setReload={setReload}
          setDetails={dataRow}
        />
      )}
      {edit && (
        <EditInboxModal
          open={edit}
          setOpen={setEdit}
          setDetails={dataRow}
          reload={reload}
          setReload={setReload}
        />
      )}
      {openSignModal && (
        <SignatureModal
          open={openSignModal}
          setOpen={setOpenSignModal}
          dataRow={dataRow}
        />
      )

      }
    </>
  );
};

export default ConnectedEspList;
