import React from "react";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import {
  allFilters,
  filterHeaderSearchCompany,
} from "../../redux/actions/filterActions";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { filterSelectedIndexes, getUniqueListBy } from "../../utils/helpers";
import Checkbox from "../Buttons/Checkbox/Checkbox";

const FilterTypeCompanySize = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyHeaderSelector = useSelector(
    (state) => state?.filterHeaderCompany?.payload
  );
  const allFiltersSelector = useSelector((state) => state?.allFilters?.payload);

  const handleChange = (item) => {
    item.isChecked = !item.isChecked;

    const result = getUniqueListBy([...companyHeaderSelector, item], "index");
    dispatch(filterHeaderSearchCompany(result));
    const new_employee_number = filterSelectedIndexes(result, "index");
    const resultant = [...new_employee_number];
    const payload = [...new Set(resultant)];
    const queryPayload = { employee_number: payload };
    dispatch(allFilters(queryPayload));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector,
        employee_number: payload,
      }).toString(),
    });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterContainer}>
        {companyHeaderSelector?.map((item) => {
          return (
            <Box key={item.type} sx={styles.flex}>
              <Checkbox
                onClick={() => handleChange(item)}
                checked={item.isChecked}
                label={item.type}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default FilterTypeCompanySize;
