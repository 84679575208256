import { Menu } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paginationDataAction } from "../../redux/actions/customTableActions";
import {

  BORDER,
  BRAND,
  GREY,
  PRIMARY,
} from "../../utils/constants/colors";
import { fontSize, fontWeight } from "../../utils/constants/UI";
import { SHADOW, borderRadius } from "../../utils/constants/UI/uiConstants";
import IconComponent from "../Icon/IconComponent";
import { innerLeftBorder } from "../../utils/helpers";
import classes from "./Table.module.css"
import TypographyInline from "../Typography/TypographyInline";



const CustomPaginationDropdown = ({ items, id = "", ...props }) => {
  const styles = {
    mainContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: `1px solid ${BORDER.DEFAULT}`,
      padding: "6px",
      borderRadius: borderRadius.primary,
      cursor: "pointer",
    },
    selectedItem: {
      padding: "8px 10px",
      backgroundColor: PRIMARY.W_50,
      boxShadow: innerLeftBorder(BRAND.PRIMARY),
    },
    defaultItem: {
      padding: "8px 10px",
    },
    paperMenu: {
      boxShadow: "0px 4px 8px -2px rgba(24, 24, 28, 0.1),0px 2px 4px -2px rgba(24, 24, 28, 0.06) !important",
      marginTop: "-45px !important"
    }
  }
  const dispatch = useDispatch();
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const idSelect = paginationDataSelector?.data

  const [selectedValue, setSelectedValue] = useState(
    id ? paginationDataSelector?.data?.[id]?.query?.limit || 25 : paginationDataSelector?.data?.query?.limit || 25
  );
  const [isVisible, setIsVisible] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const clickHandler = (event) => {
    setAnchor(event.currentTarget);
    setIsVisible(true);
  };

  const onItemClickHandler = (item) => {
    setSelectedValue(item);
    setIsVisible(false);
    setAnchor(null);
    let nestedTable = {
      ...paginationDataSelector.data,
      checkedRow: [],
      query: {
      }
    }
    if (id) {
      nestedTable[id] = {
        ...paginationDataSelector.data?.[id],
        checkedRow: [],
        query: {
          ...paginationDataSelector?.data?.[id]?.query,
          limit: item,
          page: 1,
        }
      }
    }
    else {
      nestedTable = {
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          ...paginationDataSelector?.data?.query,
          limit: item,
          page: 1,
        }
      }
    }
    dispatch(
      paginationDataAction({
        ...nestedTable
      },
      )
    );
  };

  return (
    <>
      <div
        id="paginationDropdownContainer"
        onClick={clickHandler}
        style={styles.mainContainer}
        className={classes.mainContainer}
      >
        <TypographyInline
          label={(id ? idSelect?.[id]?.query?.limit || 25 : idSelect?.query?.limit || 25)}
          color={GREY.SECONDARY}
          fontWeight={fontWeight.MEDIUM}
          size={"xs"}
        />
        <IconComponent
          iconLabel={"keyboard_arrow_down"}
          color={GREY.SECONDARY}
          fontSize={fontSize.MD}
        />
      </div>
      {isVisible && (
        <Menu
          onClose={() => setIsVisible(false)}
          anchorEl={anchor}
          open={isVisible}
          style={styles.dropdownValues}
          classes={{ paper: props?.paperMenuClass || styles.paperMenu }}
          PaperProps={{
            sx: {
              marginTop: "-45px !important",
              boxShadow: `${SHADOW.tags} !important`,
            }
          }}
        >
          {items?.map((item, index) => {
            return (
              <div
                style={
                  selectedValue === item.label
                    ? styles.selectedItem
                    : styles.defaultItem
                }
                className={selectedValue === item.label
                  ? classes.selectedItem
                  : classes.defaultItem}
                key={index}
                onClick={() => onItemClickHandler(item.label)}
              >
                <TypographyInline
                  label={item.label}
                  size={"xs"}
                  fontWeight={fontWeight.REGULAR}
                  color={GREY.SECONDARY}
                />
              </div>
            );
          })}
        </Menu>
      )}
    </>
  );
};

export default CustomPaginationDropdown;
