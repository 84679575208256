let liveHost =
  window.location.host.split(".")[window.location.host.split(".").length - 2] +
  "." +
  window.location.host.split(".")[window.location.host.split(".").length - 1];

// local host name here
let localHost = liveHost[1];

//Base URL
export const BASE_URL = process.env.REACT_APP_GATEWAY;
export const PORT = "";
export const HOSTNAME = localHost;
const host = "reachiq.ai";
export const HOST_URL = host;
export const protocol = "http://";
export const REGISTRATION_LINK = process.env.REACT_APP_DEV_REGISTRATION_LINK;

//Endpoints

export const authEndpoints = {
  register: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "register",
  connectInboxVerify:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "connect-inbox",
  login: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "login",
  verifyEmail: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "verify-email",
  verifyDomain:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "validate-subdomain",
  logout: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "logout",
  checkLogin: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "refresh-token",
  sendMailForResetPassword:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "reset-password",
  verifyTokenForResetPassword:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "reset-password/verify-token",
  resetPassword: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "update-password",
  changePassword:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "change-password",
  verifyUserEmail:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "verify-user-email",
  updateApiDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "update-api-details",
  getApiKeyDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "get-api-details",
  verifyInvitedUser:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "verify-invitation-token",
  invitedUserRegistration:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "user-register",
  getUserDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "get-user-details",
  updateUserDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "update-user-details",
  registerGoogleUser:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "google/user-register",
  CalendarMeeting: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "meetings",
  GoogleSignupToken:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "google-signup?token=",
  Authroute: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "verify-email",
  RequestForCredits:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "req-for-credits",
  // admin routes
  userList: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "users-list",
  inviteUsers: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "invite-users",
  invitedUsersList:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "invited-users-list",
  deleteInvitation:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "delete-invitation",
  updateUserStatus:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "change-user-status",
  createAccountSetting:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "account-setting",
  createAccountBranding:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "branding",
  accountGetDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "get-account-details",
  brandKitDetaiks:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "branding-details",
  brandKitPrimary:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "branding-details-primary",
  brandKitUpdate:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "branding/update",
  deleteBrandkit:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "branding/delete/",
  disconnectEsp:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "disconnect-inbox",
  deleteEsp: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "disconnect-esp",
  makePrimary:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "make-inbox-primary",
  pullSignatureRequest:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "pull-gmail-signatures",
  saveSignatureRequest:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "save-update-signature",
  getSignatureRequest:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "get-gmail-signature",
  checkInboxStatus:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "check-inbox-status",
  tenantUsersList: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "user-list",
  sendMailTest: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "send-test-email",
  googleOutlookUpdate:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH +
    "update-google-outlook-details",
};

export const leadsEndpoints = {
  // suggestions endpoints

  getJobFilters: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-job-titles",
  getFileNames: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-file-names",
  getIndustryFilters:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-industries",
  getLocationCityFilter:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-person-city",
  getLocationStateFilter:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-person-state",
  getLocationCountryFilter:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-person-country",
  getCompanyTechnologiesFilter:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-technologies",
  getCompanyName: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-companies",
  getSavedCompanyName:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-saved-company-names",
  getCampaignFilter:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN +
    "campaigns-suggestion-list",
  getSequenceFilter:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "sequence-suggestion-list",
  getSequenceData:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts/sequence-name",
  // searching data endpoint
  search: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/search",
  savedContactSearch:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS +
    "person/saved-contacts-search",
  savedContactCampaign:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts/leads-search",

  // people endpoints
  peopleBasic: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person",
  leadsBasic: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/leads",
  userDetails: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/details",
  savedUserDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "saved-person/details",
  // company endpoints
  companyBasic: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company",
  companySearch: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/search",
  companySavedSearch:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/saved-search",
  accountSearch:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/saved-search",
};

export const contactListEndPoints = {
  createList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactlist/create",
  getList: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-contact-list",
  getListRecordWise:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "get-list-record-wise",
  removeRecordfromList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "remove-saved-contact-list",
  getAllList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-campaign-contact-list",
  getAllListCount:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-contact-list-count",
  saveToList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/save-to-list",
  alreadySaveToList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/already-save-to-list",
  bulkAlreadySaveToList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/bulk-already-record-saved-to-list",
  bulkSaveToList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/bulk-new-record-saved-to-list",
  exportList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/save-to-list",
  bulkExportList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/bulk-new-record-saved-to-list",
  singleEmailUnlock:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/get-user-email",
  advanceExport:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/advance-people-bulk-export",
  createContact: process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "create-contact",
  moveToList: process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "move-list-contact",
  bulkMoveToList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "move-list-contact",
  deleteList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactlist/delete",
  editList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactlist/update",
  addSelectedCrmList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/save-to-crm",
  bulkCrmList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/bulk-save-to-crm",
  savedSelectecCrmList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/saved-contact-crm",
  bulkSavedSelectecCrmList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/bulk-saved-contact-crm",
  // Compnay endpoints
  companyBulkExport:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "bulk-saved-compnay-export",
  companybulkSaveToList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "company/bulknew-record-saved-to-list",
  companyExportList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "saved-compnay-export",
  companySaveToList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "company/save-to-list",
  companyCreateList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "company/contactlist/create",
  companyGetList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-account-list",
};

export const viewEndPoints = {
  createView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view/create",
  getAllView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view-all",
  deleteView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view/delete",
  pinView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/person/view/pin",
  unpinView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/person/view/unpin",
  makeDefaultView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/person/view/make-default",
  updateView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/person/view/update",
  viewUpdate:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS +
    "/person/view/update-property",

  // Company endpoints
  createCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/view/create",
  CompanyViewUpdate:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS +
    "/company/view/update-property",
  getCompanyViews:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/view-all",
  deleteCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/view/delete",
  pinCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/company/view/pin",
  unpinCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/company/view/unpin",
  makeCompanyDefaultView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/company/view/make-default",
  updateCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/company/view/update",
};

export const newLeadsEndPoints = {
  createLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "lead/view/create",
  updateLeadViewproperty:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "lead/view/update-property",
  getAllLeadView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "lead/view-all",
  deleteLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "lead/view/delete",
  pinLeadView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "lead/view/pin",
  unpinLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "lead/view/unpin",
  makeDefaultLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "lead/view/make-default",
  updateLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "lead/view/update",
  getInitialViews:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-initialviews",
};

export const accountEndPoints = {
  companyInitialViews:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "account-initial-views",
  createDefaultView:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-account-default-view",
  createLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "accounts/view/create",
  updateLeadViewproperty:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS +
    "accounts/view/update-property",
  getAllLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "accounts/view-all",
  deleteLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "accounts/view/delete",
  pinLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "accounts/view/pin",
  unpinLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "accounts/view/unpin",
  makeDefaultLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "accounts/view/make-default",
  updateLeadView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "accounts/view/update",
  getInitialViews:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "account-initial-views",
  getContactsByCompany:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-contacts-by-company",
  getDealsByCompany:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "deals-by-company",
  accountSearch:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-company-filter",
};

export const sequenceEndPoints = {
  getFolderDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "/common/get-info",
  taskListTable:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "get-records-info",
  createSequence:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/create/sequence",
  listingSequence:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/records",
  getSingle: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/get/",
  editSequence:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/update",
  // steps endpoints
  sendMailtoSelf:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/send-self",
  validateSequenceSteps:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "steps/",
  deleteStep: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "step/delete",
  createTodoStep:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "step/todo/create",
  createCallStep:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "step/call/create",
  createAutomatedEmailStep:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE +
    "step/automated_mail/create",
  updateAutomatedEmailStep:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE +
    "step/automated_mail/update",
  updateCallStep:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "step/call/update",
  updateTodoStep:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "step/todo/update",
  sortSequence: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "sort",
  deleteSequece:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/delete ",
  cloneSequence: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "clone",
  searchSequence: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "search",
  createFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/create/folder",
  getFullFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "folder/list",
  getFolder: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "folder/get",
  deleteFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "folder/delete",
  moveSequence:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/move-record",
  updateFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "folder/update",
  moveStepDown: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "step/swap",
  pinUnpin: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/pin-unpin",
  //Stats Steps
  statsSteps:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/sequence-stats",
  ownerStats:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/get-owner-name",
  removeSequenceFromFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/remove-record",
  //task Module
  editTask:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "get-company-details",
  getMoveList:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "common/get-user-folder",
  pinTaskCrm: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "pin-task",

  //comment Task Crm
  deleteTaskCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "delete-crm-task",
  createTaskCommentCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "create-task-comment",
  getTaskCommentCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "get-task-comment",
  updateTaskCommentCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "update-task-comment",

  deleteTaskCommentCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "delete-task-comment",
  getAttendeeList:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "get-tenant-users",
};

export const campaignEndpoints = {
  UNENROLL: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "unenroll-contact",
  updateScheduledCampaign:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN +
    "update_scheduled_campaign",
  getInfo: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "get-info",
  sequenceList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/sequences",
  contactlist:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/contactlist",
  createCampaign: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "create",
  getCampaignList: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list",
  deleteCampaign: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "delete",
  puaseCampaign: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "pause",
  resumeCampaign: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "resume",
  getCampaignStatus:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "is-setup-completed",
  campaignOverviewSteps:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "overview/steps",
  campaignOverviewSequence:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "overview/sequence",
  activityLogs:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "activity-logs",
  campaignContactList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts",
  eachCampaignDetail: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "get",
  pinUnpinCampaign:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "pin-unpin",
  updateCampaign: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "update",
  campaignBulkDelete:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "delete-many",
  EspList: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/esplist",
  allStats:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "steps/total-stats",
  contactwiseStat:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts/stats",
  eachContactReplies:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts/reply",
  leadOrUnsubscribeEndpoint:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts",
  getDefaultValues:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "default-values",
  saveDefaultValues:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "default-values",
  blockedContactList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "block-contacts",
  markAsLead:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts/mark-as-lead",
  markAsUnsubscribe:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN +
    "contacts/mark-as-unsubscribe",
  getMoveList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "get-user-folder",
  removeCampaignFromFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "remove-record",
  disconnectGmail:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "revoke-google-connection",
  checkAndDisconnectGoogle:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN +
    "check_google_connection_and_revoke",
  allEspList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/get-all-esp",

  mailList: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "connections-list",
  // folder endpoints
  createFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "folder/create",
  folderList: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list",
  deleteFolder: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "delete-many",
  deleteMany: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "delete-many",
  updateFolder: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "update/name",
  moveCampaignToList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "move-campaign",
  //Camp Stats

  campStats:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/campaign-stats",
  stepWise:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN +
    "list/step-wise-camapign-stats",
  campInStats:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/contact-wise-stats",
  replyStats: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts/reply",
  deleteReplyStats:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "delete-reply",
  campOwnerStats:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "common/get-owner-name",
  leadsCampaign:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contacts/lead-log",
  eligibleHours:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "check-eligible-hours",
  campSignList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "get-signatures",
  isCampExist: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "is-campaign-exists",
  showUserProf: process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "contact-profile",
  

};

export const importEndpoints = {
  createDynamicField:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "create-field",
  getDynamicField:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "get-custom-fields",
  getFieldMapping:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "get-fields-mapping",
  updateFieldMapping:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "update-crm-field-mapping",
  blackListFileFieldMapping:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT +
    "update-blacklist-field-mapping",
  domainFileFieldMapping:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT +
    "update-blacklist-domain-field-mapping",
  deleteBlacklistDomains:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "delete-blocked-domains",
  blackListContact:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "block-emails",
  blackListDomain:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "block-domains",
  uploadFileInitialEndpoint:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT +
    "uploads/initializeMultipartUpload",
  uploadFileMultipartEndpoint:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT +
    "uploads/getMultipartPreSignedUrls",
  uploadFilefinalEndpoint:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT +
    "uploads/finalizeMultipartUpload",

  getImportedFiles:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "get-imported-files",
  isBlackListDone:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "is-blacklist",
  getBlackListEmail:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "get-blacklist-emails",
  getBlackListDomains:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "get-blacklist-domains",
  //Csv enrichment
  updateFieldCsvMapping:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT +
    "update-csv-enrichment-feild-mapping",
  customField: process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "delete-field",
  getImport: process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "get-csv-data  ",
  getUser: process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "get-owner-name",
  getCrmFieldlMapping:
    process.env.REACT_APP_ENDPOINT_PREFIX_IMPORT + "get-crm-mapping",
};

export const CampaignSettingsEndpoints = {
  createCampaignSet:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "signature/update",
  getSignature:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "signature/get/default",
  unsubscribeSignature:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "signature/update",
};

export const templateEndpoints = {
  createTemplate: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "create",
  createFolderTemplate:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "create-folder",
  folderTempList: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "list",
  getFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE +
    "user-vias-template-folder",
  deleteFoldTemp: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "delete",
  updateFoldTemp:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "update-folder",
  templateList: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "list",
  deleteTemplate: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "delete",
  updateTemplate: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "update",
  moveTemplate:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "move-to-folder",
  countTemplate:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "folder/template-count",
  uploadFile:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "upload-editor-image",
  getTemplate: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "get-detail",
  getALLTemp:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "get-template-sequence",
  sentTest: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "send-test-email",
  removeTempFromFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE +
    "remove-template-from-folder",
  getMoveList:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "get-folder-move-template",
  chatGpt: process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "chatgpt",
  getOwnerList:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "get-owner-names",
  getSignature:
    process.env.REACT_APP_ENDPOINT_PREFIX_TEMPLATE + "get-gmail-sign",
};

export const listEndPoints = {
  getFolderDetail:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/get-folder-detail",
  createFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactfolder/create",
  showFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactlist/list",
  deleteFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactfolder/delete",
  updateFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactfolder/update",
  moveToFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/contactlist/move-list-to-folder",
  bulkDeleteList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactlist/delete",
  removeFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/contactlist/remove-list-from-folder",
  countList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/contactfolder/contact-list-count",
  getMoveList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/contactfolder/get-user-folder",
};

export const dashboardEndpoints = {
  getDashboard:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "dashboard-list",
  createDashboard:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "create-dashboard",
  deleteDashboard:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "delete-dashboard",
  updateDashboard:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "update-dashboard",
  layoutDashboard:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD +
    "update-dashboard-layout",
  getSequenceList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN + "list/sequences",
  getCampaignList:
    process.env.REACT_APP_ENDPOINT_PREFIX_CAMPAIGN +
    "campaigns-suggestion-list",
  getInitialReport:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "get-initial-report",
  cloneDashboard:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "clone-dashboard",
  setDefaultDashboard:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "mark-as-default",
  updateWidgetFilters:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD +
    "update-widget-filters",
  getDashboardOwners:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "get-owner-name",
  getDashboardActivityLogs:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "activity-logs",
  bulkDelete:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "bulk-actions",
  shareSvg:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "email-dashboard",
};

export const dashboardWidgetEndpoints = {
  getDashboardWidget:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "widgets-list",
  createDashboardWidget:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "create-widget",
  deleteDashboardWidget:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD +
    "delete-dashboard-widget",
  updateDashboardWidget:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD +
    "rename-dashboard-widget",
  cloneDashboardWidget:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD +
    "clone-dashboard-widget",
  getChartData:
    process.env.REACT_APP_ENDPOINT_PREFIX_USER_DASHBOARD + "get-chart-data",
};

export const taskEndpoints = {
  getTaskTable: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "get-tasks",
  getTaskListCrm: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "get-task",

  createTask: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "create-task",
  updateTask: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "update-task",
  multiUpdateTask:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "update-all-tasks",
  multiDeleteTask:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "delete-multiple-tasks",
  createTaskNameList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "get-person-names",

  getCompanyCrmTask:
    process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "get-company-task",
  getDealTask: process.env.REACT_APP_ENDPOINT_PREFIX_SEQUENCE + "get-deal-task",
};

export const meetingsEndpoint = {
  GetBrandingDetail:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-branding",

  getOrganiserList:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "organizer-list",
  postNextApi:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "meeting-validation",
  SubmitMeetingApi:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "create-meeting",
  ListMeeting: process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "meeting-list",
  EditMeeting: process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "edit-meeting",
  UpdateMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "update-meeting",
  DeleteMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "delete-meeting",
  ScheduleMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS +
    "schedulemeeting-getbranding",
  GetEventmeeting: process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-event",
  CreateCalendarEvent:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "create-event",
  SentTestMail:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "send-test-mail  ",
  allMeetingLink:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "meeting-links",
  bookedMeetingLink:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-booked-meetings",
  deleteBookedMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "delete-booked-meetings",
  editBookedMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "edit-booked-meetings",
  isCalendarConnected:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "check-connection",
  meetingEmailList:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "connections-list",
  bookedMeetingStats:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "meeting-booked-stats",
  getAllUsers: process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-all-users",
  meetingCancel:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "start-cancelling ",
  meetingStartRescheduling:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "start-rescheduling",
  cancelMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "cancel-meeting",
  rescheduleMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "reschedule-meeting",
  attendeePersonDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "person/details",
  addCommentLogCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "add-comment-log-meeting",
  deleteCommentLogCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS +
    "delete-comment-log-meeting",
  editCommentLogCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "edit-comment-log-meeting",
  getCommentLogCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-comments-log-meeting",
  getMemberList:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-users-list",

  // CRM

  getCrmMeetings:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-crm-meeting",
  createCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "create-crm-meeting",
  deleteCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "delete-crm-meeting",

  deleteLogCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "delete-log-crm-meeting",
  editCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "edit-crm-meeting",
  editLogCrmMeeting:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "edit-log-crm-meeting",
  pinMeetingCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "pin-crm-meeting",
  editCommentMeetingCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "edit-comment-meeting",
  deleteCommentMeetingCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "delete-comment-meeting",
  getCommentMeetingCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-crm-comments-meeting",
  createCommentMeetingCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "add-comment-meeting",
  createLogMeetingCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "log-crm-meeting",
  getCrmMeetingsAccount:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-crm-meeting-accounts",
  getDealsCrmlist:
    process.env.REACT_APP_ENDPOINT_PREFIX_MEETINGS + "get-crm-meeting-deals",

};

export const onboardingEndpoint = {
  onboardingStatus:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "onboarding/get",
  onboardingSteps:
    process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "onboarding/create",
};

export const feedbackForm = {
  feedback: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "contact-us",
};

export const crmEndpoints = {
  changeContactsCompany:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "change-contact-company",
  getContact: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-contact-filter",
  getEmailSuggestions:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-email-suggestions",
  deleteEmail: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-email",
  deleteThread: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-thread",
  addContact: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-contact",
  addCompany: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-company",
  updateCompany: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-company",
  createDynamic: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-dynamic",
  getGroupList: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-group",
  editGroup: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-group",
  deleteGroup: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-group",
  deleteCompany: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-company",
  getProperty: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-property",
  editProperty: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-property",
  deleteProperty: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-property",
  getGroupByType: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-group-by-type",
  getContactDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-contact-by-id",
  getPropertyByType:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-property-by-type",
  editContactPersonDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-contact",
  getImportedFiles:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-import-data",
  deleteBulk: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "bulk-delete-contact",
  deleteBulkAccount:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "bulk-delete-accounts",
  getAssigneeList: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-assignee",
  assignBulk: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "bulk-assign",
  createEmailLog:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-email-log",
  getEmailLogs: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-emails",
  createEmail: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-email",
  replyOnEmail: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "reply-on-email",
  updateEmailLog: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-email-log",
  pinEmail: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "pin-email",
  deleteEmailLog:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-email-log",
  createEmailLogComment:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-comment",
  getEmailLogComments:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-comment",
  updateEmailLogComment:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "update-comment",
  deleteEmailLogComment:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-comment",
  getEmailByThreads:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-email-by-threads",
  getCompanyEmails:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-company-emails",
  getCompanyDetails:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "company-details",
  getInitialAccountViews:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "account-initial-views",
  getColumns: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-columns",
  resetColumns:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "reset-columns-order",
  //Notes
  pinNotesCrm: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "pin-notes",
  getNotesById: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-notesById",
  getNotes: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-notes",
  createNotes: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-notes",
  editNotes: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-notes",
  deleteNotes: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-note",
  getNotesComment:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-comments-by-id",
  deleteNotesComment:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-comments",
  updateNoteCommentCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-comments",
  createNoteCommentCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-comments",
  deleteNoteCommentCrm:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-comments",
  getCompanyNotes:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-company-notes",
  //attachments
  uploadCrmFileInitialEndpoint:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM +
    "uploads/initialize-multipart-upload",
  uploadCrmFileMultipartEndpoint:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM +
    "uploads/get-multipart-presigned-urls",
  uploadCrmFilefinalEndpoint:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM +
    "uploads/finalize-multipart-upload",
  getAttachments:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "uploads/get-attachments",
  getCompanyAttachments:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM +
    "uploads/get-company-attachments",
  deleteAttachment:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "uploads/delete-attachment",

  //meetings
  getAssigneeMeetings:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "/get-active-users ",

  //timeline
  getTimelineFeed: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "timeline-feed",
  getCompanyTimelineFeed:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "company-timeline-feed",
  editPropertyAction:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-contact-columns",
  //pipelines
  createPipeline: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-pipeline",
  getPiplelineById:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-pipeline-by-id",
  getPipleline: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-pipelines",
  editPipeline: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-pipeline",
  deletePipeline: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "delete-pipeline",
  editDealsDetails: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-deals",
  editDealStage: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "edit-deal-stage",
  getAssociateContact:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-associate-contact",
  getDealsByContactIds:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "deals-by-contact",
  getPipelineWithStages:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-pipelines-with-stages",
  getAssociateNames:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-associate-owner-name",
  pipelineStageDelete:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "pipeline-stage-delete",
};

export const dealsEndpoints = {
  createDeals: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-deals",
  getDeals: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-deals",
  bulkDeleteDeals:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "bulk-deleteDeals",
  assignBulkDeals:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "bulk-assign-deals",
  getAllDealsView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "deals/view-all",
  getInitialViews:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "deals-initial-views",
  createDefaultView:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "create-deal-default-view",

  createDealView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "deals/view/create",
  updateDealView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "deals/view/update",
  updateDealViewproperty:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "deals/view/update-property",
  pinDealView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "deals/view/pin",
  unpinDealView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "deals/view/unpin",
  deleteDealView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "deals/view/delete",
  makeDefaultDealView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "deals/view/make-default",
  getTimeLineFeed:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "deal-timeline-feed",
  getDealDetails: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "deals-by-id",
  getDealsEmail: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-deals-emails",
  getNotes: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-notes-deal",
  getAttachments:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "uploads/get-deal-attachments",
  deleteAttachment:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "uploads/delete-attachment",
  getAssociatedContact:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-associate-contact",
  getAssociatedCompany:
    process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-associate-company",
  getPipelineStagesByPipelineId: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-pipeline-stages-by-pipeline-id",
  getDealInsights: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "get-deal-insights"
};

export const formsEndpoints = {
  getFormsField: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "forms/get-forms-field",
  changeFormField: process.env.REACT_APP_ENDPOINT_PREFIX_CRM + "forms/change-form-field"
}

export const crmToolEndpoints = {
  createSnip: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "create-snip",
  getTableField: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "get-listing",
  updateSnip: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "update",
  deleteSnipp: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "delete",
  createFolder: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "create-folder",
  getUserFolder: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "get-user-folder",
  moveToFolderList: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "move",
  removeRecords: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "remove-record",
  pinUnpin: process.env.REACT_APP_ENDPOINT_PREFIX_CRM_TOOL + "pin-unpin",
}

export const PRIVACY_POLICY_LINK =
  "https://react-dev.reachiq.ai/privacy-policy";

export const TERMS_SERVICE_LINK =
  "https://react-dev.reachiq.ai/term-conditions";

export const DO_NOT_SELL_LINK =
  "https://react-dev.reachiq.ai/privacy-manage-profile";

export const REACHIQ_HOME = "https://reachiq.ai";

export const REACHIQ = "https://reachiq.ai/get-early-access";
