import { GET_ALL_STEPS_FAIL, GET_ALL_STEPS_REQUEST, GET_ALL_STEPS_SUCCESS } from "../constants/stepsConstants";

export const getAllStepsReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ALL_STEPS_REQUEST:
        return { loading: true };
  
      case GET_ALL_STEPS_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case GET_ALL_STEPS_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };  