export const styles = {
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "50vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },

  bigModal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "75vw",
    maxHeight: "90vh",
    borderRadius: "4px",
    padding: "1.5rem 0.5rem",
  },
  flex: {
    display: "flex",
  },
  containCrossIcon: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    alignItems: "center",
    cursor: "pointer",
    height: "2rem",
  },
  icon: {
    color: "#7c7d7e",
    fontSize: "20px",
  },
  title: {
    color: "#102f51",
    fontSize: "clamp(1.1rem, 1.1vw, 3rem)",
    fontWeight: "400",
    alignSelf: "center",
  },
  inputContainer: {
    width: "100%",
  },
  // input: {
  //   width: "100% !important",
  //   padding: "1rem !important",
  // },
  input1: {
    width: "100% ",
    padding: "1.5rem 1rem !important",
    border: "1px solid #E0E0E0",
    borderRadius: "3px",
  },
  input: {
    width: "100% ",
    padding: "1rem !important",
  },
  // input1: {
  //   width: "30vw !important",
  //   padding: "1.5rem 1rem !important",
  // },
  BTN: {
    display: "flex",
    paddingTop: "16px",
    width: "100px !important",
    float:"left"
  },
  FORM: {
    width: "100% !important",
    // border: "1px solid #E0E0E0",
    // borderRadius: "3px",
  },
  listBox: {
    width: "83%",
  },
  marginTop: {
    marginTop: "1rem",
  },
  inviteBtn: {
    background: "#ff4848",
    border: "none",
    color: "white",
    width: "10vw",
    padding: "1rem 2.5rem",
    marginTop: "1rem",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
  },
  redBackgroundBtn: {
    background: "#ff4848",
    color: "white",
    fontSize: "clamp(0.9rem, 0.85vw. 3rem)",
    padding: "1rem 2rem",
    borderRadius: "6px",
    border: "none",
    marginTop: "1rem",
    cursor: "pointer",
  },
  containBlacklistModalItems: {
    padding: "1rem 1.5rem",
  },
  containEachInput: {
    padding: "1rem 0",
  },
  employeeModalMainContainer: {
    height: "100%",
  },
  employeeModalHeader: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#F7F9FB",
    columnGap: "5px",
  },
  headerText: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "clamp(14px, 0.938vw, 30px)",
    lineHeight: "22px",
    color: "#212833",
  },
  closeIcon: {
    width: "20px !important",
    height: "20px !important",
    color: "#626B7E",
    cursor: "pointer",
  },
  employeeDetailContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D3DAE3",
    padding: "20px 20px 20px 12px",
  },
  employeeDetail: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: "8px",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100% - 62px)",
  },
  viewMoreContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D3DAE3",
    padding: "20px 20px 20px 12px",
  },
  viewMoreText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "clamp(12px, 0.729vw, 24px)",
    lineHeight: "22px",
    color: "#212833",
    padding: "20px",
    borderBottom: "1px solid #D3DAE3",
    cursor: "pointer",
  },
  employeeModalBody: {
    height: "100%",
  },
  noRecordsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 62px)",
  },
  mainWaringHeading: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "clamp(16px, 1.042vw, 30px)",
    lineHeight: "22px",
    color: "#212833",
  },
  mainWaringDescription: {
    fontFamily: "inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "clamp(14px, 0.833vw, 26px)",
    lineHeight: "25px",
    color: "#626B7E",
    textAlign: "center",
  },
};
